import React from "react";

import { connect } from "react-redux";

// Actions
import { setMaxWaitingItems } from "../../../../../actions/shiftsActions";

// Components
import ShiftSimpleSelect from "./General/ShiftSimpleSelect";

// Material ui
import { Box, Typography } from "@mui/material";

// Utils
import { waitingList } from "../utils/constants";

// Styles
import { ShiftSelectTitles } from "../../../Styles";

const WaitListMaxReservations = (props) => {

  // Variables
  const {
    maxWaitingItems,
  } = props;

  // Acciones
  const {
    setMaxWaitingItems,
  } = props;

  return (
    <Box mt={3}>
      <Typography sx={ ShiftSelectTitles } >Reservas máximas en lista de espera</Typography>
      <Box sx={{ mt: '1rem' }}>
        <ShiftSimpleSelect
          handleChange={ setMaxWaitingItems }
          optionsArray={ waitingList } 
          value={ maxWaitingItems } 
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
      maxWaitingItems: state.v3.shifts.shiftData.maxWaitingItems,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMaxWaitingItems: (data) => dispatch(setMaxWaitingItems(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListMaxReservations);
