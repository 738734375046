import React from 'react';

import { connect } from 'react-redux';

// Actions
import { setFilterSelected } from '../../../../../actions/myBookingsActions';
import { changePage, setBookingCrountrySelected, setBookingOriginSelected, setCountrySelected } from '../../../../../actions';

// Components
import FiltersSelect from '../Selects/FiltersSelect';

// Helpers
import { bookingFiltersModal } from '../../helpers';

// Material Ui
import { Backdrop, Box, Button, Divider, Fade, Grid, Modal, Typography } from '@mui/material';

// Styles
import { 
    ButtonContainerFilterModal,
    FilterItemContainerStyles, 
    CancelButtonFilterModal,
    AcceptButtonFilterModal, 
    SubtitleFilterModal, 
    FilterModalStyles, 
    FilterNameStyles, 
    TitleFilterModal,
} from '../Table/Style/Section3TableStyles';
import { CountryFlagsArray } from '../../../../../utils/CountryFlagsArray';

const FiltersModal = ( props ) => {

    // Actions
    const { 
        setBookingCrountrySelected, 
        setBookingOriginSelected, 
        setCountrySelected, 
        setFilterSelected, 
        changePage, 
    } = props;

    // States
    const { filterSelected } = props;

    // External props
    const { handleClose, open, component } = props;

    const handleChangeFiltersSelected = ( e, selectName ) => {
        for ( let key in filterSelected ) {
            if ( selectName === key ) {
                setFilterSelected({ 
                    ...filterSelected, 
                    [selectName]: e.target.value 
                });
            }
        }
    };
    
    const handleApplyFilters = () => {
        if ( component === 'bookings' ) {
            if ( filterSelected.origin === 'WOKI' ) {
                setBookingOriginSelected( 0 );
            } else if ( filterSelected.origin === 'Restaurante' ) {
                setBookingOriginSelected( 1 );
            } else {
                setBookingOriginSelected('');
            }
            
            for (let i = 0; i < CountryFlagsArray.length; i++) {
                if ( filterSelected.country ===  CountryFlagsArray[i].name) {
                    setBookingCrountrySelected( CountryFlagsArray[i].countryCode );
                    changePage(0);
                } else {
                    if ( filterSelected.country === 'Sin filtro' ) {
                        setBookingCrountrySelected( '' );
                        changePage(0);
                    }
                } 
            }
        } else {
            for (let i = 0; i < CountryFlagsArray.length; i++) {
                if ( filterSelected.country ===  CountryFlagsArray[i].name) {
                    setCountrySelected( CountryFlagsArray[i].countryCode );
                    changePage(0);
                } else {
                    if ( filterSelected.country === 'Sin filtro' ) {
                        setCountrySelected( '' );
                        changePage(0);
                    }
                } 
            }
        }

        handleClose();
    }

    return (
        <Modal
            aria-describedby="transition-modal-description"
            aria-labelledby="transition-modal-title"
            slots={{ backdrop: Backdrop }}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            open={ open }
        >
            <Fade in={ open }>
                <Box sx={ FilterModalStyles }>
                    <Typography sx={ TitleFilterModal } variant="h6" component="h2">
                        Filtros
                    </Typography>
                    <Typography sx={ SubtitleFilterModal }>
                        Seleccioná los filtros para aplicar a tus reservas
                    </Typography>
                    {
                        component !== 'clients' ?
                            <Grid container sx={{ display: 'block' }}>
                                {
                                    bookingFiltersModal.map( ({ id, name, filterArray, selectName }) =>
                                        <Box key={ id }>
                                            <Divider sx={{ border: '0px solid #E7E7E7', width: '100%' }} />
                                            <Grid key={ id } item sx={ FilterItemContainerStyles }>
                                                <Typography sx={ FilterNameStyles }>{ name }</Typography>
                                                <FiltersSelect
                                                    handleChange={ handleChangeFiltersSelected }
                                                    value={ filterSelected[selectName] }
                                                    optionsArray={ filterArray }
                                                    selectName={ selectName } 
                                                />
                                            </Grid>
                                        </Box>  
                                    )
                                }
                            </Grid>
                        :
                            <Grid container sx={{ display: 'block', minHeight: '250px' }}>
                                <Divider sx={{ border: '0px solid #E7E7E7', width: '100%' }} />
                                <Grid item sx={ FilterItemContainerStyles }>
                                    <Typography sx={ FilterNameStyles }>País</Typography>
                                    <FiltersSelect
                                        optionsArray={ bookingFiltersModal[0].filterArray }
                                        handleChange={ handleChangeFiltersSelected }
                                        value={ filterSelected['country'] }
                                        selectName={ 'country' } 
                                    />
                                </Grid>
                            </Grid>
                    }
                    <Box sx={ ButtonContainerFilterModal }>
                        <Button 
                            sx={ CancelButtonFilterModal } 
                            onClick={ handleClose }
                        >
                            Cancelar
                        </Button>
                        <Button 
                            sx={ AcceptButtonFilterModal } 
                            onClick={ handleApplyFilters }
                        >
                            Aplicar
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        filterSelected: state.myBookingsReducer.filterSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBookingCrountrySelected: (data) => dispatch( setBookingCrountrySelected(data) ),
        setBookingOriginSelected: (data) => dispatch( setBookingOriginSelected(data) ),
        setCountrySelected: (data) => dispatch( setCountrySelected(data) ),
        setFilterSelected: (data) => dispatch( setFilterSelected(data) ),
        changePage: (data) => dispatch(changePage(data)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( FiltersModal );
