import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setPlanningReapeatEvery, setShowPlanningSnackbar } from '../../../actions';

// Components
import StartPlanningReapeatComponent from './StartPlanningReapeatComponent';
import EndPlanningRepeatComponent from './EndPlanningRepeatComponent';
import SelectWithArrows from './SelectWithArrows';

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Material Ui
import { Box, Collapse, Typography } from '@mui/material';

const AdvancedOptionsComponent = ( props ) => {

    // Actions
    const { setPlanningReapeatEvery, setShowPlanningSnackbar } = props;

    // States
    const { repeatEvery, repeatStart, repeatEnd, planningSnackbar } = props;

    const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);

    const handleIncreaseWeek = () => {
        if ( repeatEvery >= 1 && repeatEvery < 52 ) {
            setPlanningReapeatEvery( repeatEvery + 1 );
        } else {
            return;
        }
    };

    const handleDecreaseWeek = () => {
        if ( repeatEvery > 1 && repeatEvery <= 52 ) {
            setPlanningReapeatEvery( repeatEvery - 1 );
        } else {
            return;
        }
    };

    useEffect(() => {
        if ( repeatEnd ) {
            if ( repeatStart > repeatEnd ) {
                setShowPlanningSnackbar({
                    ...planningSnackbar,
                    snackbarMessage: 'Error en ingreso de fechas',
                    typeSnackbar: 'Error',
                    showSnackbar: true,
                });
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ repeatStart, repeatEnd ]);

    return (
        <Box>
            <Box
                onClick={ () => setAdvancedOptionsOpen( !advancedOptionsOpen ) }
                sx={{ 
                    textDecorationLine: 'underline',
                    alignItems: 'center',
                    cursor: 'pointer', 
                    display: 'flex', 
                    width: '180px',
                    gap: '5px'
                }}
            >
                <Typography
                    sx={{ 
                        textDecorationLine: "underline" 
                    }}
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="16px"
                    color="#0B4762"
                    my={3}
                >
                    Más opciones
                </Typography>
                {
                    advancedOptionsOpen ? (
                        <ExpandLessIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
                    ) : (
                        <ExpandMoreIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
                    )
                }
            </Box>
            <Collapse in={ advancedOptionsOpen } >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 500, fontFamily: 'Poppins' }}>
                        Repetir cada:
                    </Typography>
                    <SelectWithArrows
                        handleDecrease={ handleDecreaseWeek }
                        handleIncrease={ handleIncreaseWeek }
                        amount={ repeatEvery }
                        singularText='semana'
                        pluralText='semanas'
                        disabled={ false }
                    />
                </Box>
                <StartPlanningReapeatComponent />
                <EndPlanningRepeatComponent />
            </Collapse>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        repeatEvery: state.planningReducer.openningHoursForm.repeatEvery,
        repeatStart: state.planningReducer.openningHoursForm.repeatStart,
        repeatEnd: state.planningReducer.openningHoursForm.repeatEnd,
        planningSnackbar: state.planningReducer.planningSnackbar,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setShowPlanningSnackbar: ( planningSnackbar ) => dispatch( setShowPlanningSnackbar( planningSnackbar ) ),
        setPlanningReapeatEvery: ( data ) => dispatch( setPlanningReapeatEvery( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( AdvancedOptionsComponent );