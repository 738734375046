import React from 'react';

// Material Ui
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import IconButton from '@mui/material/IconButton';

const EditIcon = (props) => {

    const { onClick, height } = props;

    return (
        <IconButton aria-label="delete" onClick={onClick} sx={{margin: 'auto'}}>
            <ModeEditOutlineIcon 
                sx={{
                    height: height !== "" ? height : '2rem',
                    width: height !== "" ? height : '2rem',
                    fontSize: '2.5rem',
                    cursor: 'pointer', 
                    color: '#0B4762',
                }}
            />
        </IconButton>
    )
}

export default EditIcon;