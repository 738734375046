import {
  CLOSE_ERROR_DIALOG,
  INVOICES_LOADING_ERROR,
  INVOICES_LOADED,
  LOADING_INVOICES_DATA,
  PAYMENT_METHOD,
  EDIT_BILLING_INFO,
  EDITING_BILLING_INFO,
  ERROR_EDITING_BILLING_INFO,
  REFRESH_EDIT_BILLING_INFO,
} from "../../constants/invoicesConstants";
import axios from "axios";

export const loadingInvoicesData = () => ({
  type: LOADING_INVOICES_DATA,
});

export const invoicesLoaded = (data) => ({
  type: INVOICES_LOADED,
  payload: data,
});

export const invoicesLoadingError = (error) => ({
  type: INVOICES_LOADING_ERROR,
  payload: error,
});

export const closeErrorDialog = () => ({
  type: CLOSE_ERROR_DIALOG,
});

export const paymentMethod = (paymentMethod) => ({
  type: PAYMENT_METHOD,
  payload: paymentMethod,
});
export const editBillingInfo = (newFields) => ({
  type: EDIT_BILLING_INFO,
  payload: newFields,
});

export const editingBillingInfo = () => ({
  type: EDITING_BILLING_INFO,
});

export const errorEditBillingInfo = (error) => ({
  type: ERROR_EDITING_BILLING_INFO,
  payload: error,
});

export const refreshEditBillingInfo = () => ({
  type: REFRESH_EDIT_BILLING_INFO,
});

export const loadSuscription = () => {
  return async (dispatch, getState) => {
    const { invoiceReducer } = getState();
    try {
      const { billing_info } = invoiceReducer;
      const { subscriptionId } = billing_info;
      const API_URL_MP = process.env.REACT_APP_MP_API_URL;
      const API_KEY = process.env.REACT_APP_MP_API_KEY;
      const { data } = await axios.get(
        `${API_URL_MP}/preapproval/${subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      if (data) dispatch(paymentMethod(data.payment_method_id));
      else dispatch(paymentMethod("account_money"));
    } catch (error) {
      console.log(error);
      dispatch(paymentMethod("account_money"));
    }
  };
};

export const loadInvoicesData = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    try {
      dispatch(loadingInvoicesData());
      const API_URL = process.env.REACT_APP_API_URL;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      const [resBilling, resInvoices] = await Promise.all([
        axios.get(`${API_URL}/billing/info/${ idPartnerSelected }`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/billing/invoices/${ idPartnerSelected }`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      const { data: billing_info } = resBilling;
      const { data: invoices } = resInvoices;
      dispatch(
        invoicesLoaded({
          billing_info,
          invoices,
        })
      );
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(invoicesLoadingError(error.response.data));
      } else {
        dispatch(invoicesLoadingError(error.message));
      }
    }
  };
};

export const editBillingInfoDB = (newFields) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;
    
    try {
      dispatch(editingBillingInfo());
      const API_URL = process.env.REACT_APP_API_URL;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      const res = await axios.patch(
        `${API_URL}/billing/info/${ idPartnerSelected }`,
        newFields,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (newFields.cuit) {
        const res = await axios.patch(
          `${API_URL}/billing/afip/${ idPartnerSelected }`,
          { cuit: newFields.cuit },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      dispatch(editBillingInfo(newFields));
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(errorEditBillingInfo(error.response.data));
      } else {
        dispatch(errorEditBillingInfo(error.message));
      }
    }
  };
};
