import React from 'react';

const LESectorIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7_5945)">
                <path d="M17.2899 8.15605L16.0912 3.96488C16.0425 3.78892 15.9374 3.6338 15.792 3.52328C15.6467 3.41276 15.4691 3.35293 15.2865 3.35297H2.59565C2.21845 3.35297 1.89153 3.60444 1.79095 3.96488L0.59227 8.15605C0.441387 8.68414 0.84374 9.22061 1.39698 9.22061H3.24109L2.23521 16.7647H3.91168L4.4733 12.5736H13.4173L13.9705 16.7647H15.647L14.6411 9.22061H16.4852C17.0384 9.22061 17.4408 8.68414 17.2899 8.15605ZM4.69124 10.8971L4.91756 9.22061H12.9646L13.1909 10.8971H4.69124ZM2.51183 7.54414L3.23271 5.02944H14.6579L15.3787 7.54414H2.51183Z" fill="#1AAFDE"/>
            </g>
            <defs>
                <clipPath id="clip0_7_5945">
                    <rect width="19" height="19" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default LESectorIcon;