import React, { useEffect, useState } from "react";

// Components
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import ShiftSelectWithIcon from "./General/ShiftSelectWithIcon";
import { Description } from "../../Description";
import Divider from "../components/Divider";
import { Title } from "../../Title";

// Icons
import CubiertosIcon from "../../../../SvgComponents/icons/IconsPrimary/CubiertosIcon";

// Material Ui
import { Box, Stack, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { getScheduleOptions } from "../utils/functions";
import { horariosReservables } from "../utils/constants";

// Styles
import { modalStyles, modalTitle } from "../utils/styles/modalStyles";
import { SubshiftHoursContainerStyles } from "../../../Styles";
import { connect } from "react-redux";

const useStyles = makeStyles(() => (modalStyles));

const modalDescription = {
  lineHeight: '22.5px',
  fontSize: '15px',
};

const ScheduleStepModal = ( props ) => {

  const classes = useStyles();
  const [hours, setHours] = useState([]);

  // Variables
  const { subshiftName, subturno, from, to, subShifts } = props;

  // Acciones
  const { changeBookingHours, handleClose } = props;

  const [allChecked, setAllChecked] = useState( false );
  const [bookingHours, setBookingHours] = useState([]);

  useEffect(() => {

    subShifts.map( ( subshift ) => (
      subshift.id === subturno.id && setBookingHours( subshift.bookingHours )
    ));

  }, [ subShifts, subturno ]);

  const handleAllCheck = () => {
    setAllChecked( !allChecked );
  };

  const handleSwitchChange = ( active, id ) => {

    const nextHours = hours.map( hour => {
      if ( hour.id === id ) {
        // Return a new circle 50px below
        return {
          ...hour,
          active: !active,
        };
      } else {
        // No change
        return hour;              
      };
    });

    // Re-render with the new array
    setHours( nextHours );
  };

  const handleChangeControl = ( control, id ) => {
    const nextHours = hours.map( hour => {
          if ( hour.id === id ) {
              // Return a new circle 50px below
              return {
              ...hour,
              control: control,
              };
          } else {
            // No change
              return hour;              
          }
    });

    // Re-render with the new array
    setHours( nextHours );
  };

  useEffect(() => {
    
    const options = getScheduleOptions();
    
    const hoursAux = [];
    let primero = true;

    // console.log( 'Options --> ', options );

    options.map((option, index) => {
      if (option.value >= from && option.value < to){
        let active;
        let control = 0;

        if ( bookingHours.length === 0 ) {
          active = primero;
          primero = false;
        } else {
          const exist = bookingHours.find((boogkingHour) => boogkingHour.time === option.value);
          if ( typeof exist !== 'undefined' ) {
            active= true;
            control=exist.flowControl === null ? 0 : exist.flowControl;
          } else {
            active= false;
          };
        };

        hoursAux.push({
          id: index,
          schedule: option.option,
          control: control,
          active: active,
          value: option.value,
        });
      };
    });
    
    setHours( hoursAux );
  }, [subturno, from, to, bookingHours]);

  return (
    <Modal open>
      <Box className={classes.ModalContainer}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Title text="Horarios reservables" style={modalTitle} />
          <span className={classes.ShiftName}>{subshiftName}</span>
        </Box>
        <Box>
          <Description
            text="Selecciona los horarios que podrán elegir tus clientes para reservar"
            style={modalDescription}
          />
        </Box>
        <Stack direction="row" spacing="25px" justifyContent="center">
            <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }}/>
        </Stack>
        <Stack style={modalStyles.ItemsHeader}>
            <Box width="50%" display="flex" flexDirection="row" justifyContent="start" marginLeft="60px">
            <Typography className={classes.ItemsTitle}>
              Horarios
            </Typography>
            </Box>
            <Box width="50%" display="flex" flexDirection="row" justifyContent="end" marginRight="60px">
            <Typography className={classes.ItemsTitle}>
              Control de ingreso
            </Typography>
            </Box>        
        </Stack>
        <Box sx={ SubshiftHoursContainerStyles }>
          <Stack>
            <Stack direction="row" justifyContent="center" marginBottom="14px">
              <Divider mt={0} sx={{ borderColor: "#D1D1D1", width: "50%" }} />
            </Stack>
            <Stack direction="row" justifyContent="center" marginBottom="14px">
              <Box width="50%" display="flex" flexDirection={'row'} justifyContent={'start'} alignItems="center" marginLeft="50px">
                <Box marginBottom='8px'>
                  <BlueSwitch
                    onChange={() => handleAllCheck( allChecked, 'Todos' )}
                    checked={ allChecked }
                    color="default"
                  />
                </Box>
                <Title style={{ fontSize: '18px' }} text={'Todos'} />
              </Box>
              <Box width="50%" display="flex" flexDirection={'row'} justifyContent={'end'} alignItems="center" marginRight="60px">
                <ShiftSelectWithIcon
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <CubiertosIcon height="14px" width="10px" fill={allChecked ? "#041C32" : "#E7E7E7"} marginRight="10px" />
                        {` ${
                            value !== 'Todos' ? 
                              horariosReservables.find((hr) => hr.value === value).option 
                            :
                              horariosReservables[0].option 
                          }`
                        }
                      </Box>
                    );
                  }} 
                  icon={ <CubiertosIcon height="14px" width="10px" fill={ allChecked ? "#041C32" : "#E7E7E7" } /> }
                  disabled={ allChecked ? false : true }
                  handleChange={ (e) => handleChangeControl(e.target.value, 'Todos') } 
                  optionsArray={ horariosReservables } 
                  value={ 'Todos' }
                />
              </Box>
            </Stack> 
          </Stack>
          {hours.map((sub) =>
            <Stack key={`sub_shifts-${sub.id}`}>
              <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Divider mt={0} sx={{ borderColor: "#D1D1D1", width: "50%" }} />
              </Stack>
              <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Box width="50%" display="flex" flexDirection={'row'} justifyContent={'start'} alignItems="center" marginLeft="50px">
                  <Box marginBottom='8px'>
                    <BlueSwitch
                      onChange={() => handleSwitchChange( sub.active, sub.id )}
                      checked={ sub.active ? true : false }
                      color="default"
                    />
                  </Box>
                  <Title style={{ fontSize: '18px' }} text={`${ sub.schedule } hs`} />
                </Box>
                <Box width="50%" display="flex" flexDirection={'row'} justifyContent={'end'} alignItems="center" marginRight="60px">
                  <ShiftSelectWithIcon
                    renderValue={(value) => {
                      return (
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <CubiertosIcon height="14px" width="10px" fill={sub.active ? "#041C32" : "#E7E7E7"} marginRight="10px" />
                          {` ${horariosReservables.find((hr) => hr.value === value).option}`}
                        </Box>
                      );
                    }} 
                    icon={ <CubiertosIcon height="14px" width="10px" fill={ sub.active ? "#041C32" : "#E7E7E7" } /> }
                    handleChange={ (e) => handleChangeControl(e.target.value, sub.id) } 
                    disabled={ sub.active ? false : true }
                    optionsArray={ horariosReservables } 
                    value={ sub.control }
                  />
                  {/* <Select
                    labelId="demo-simple-select-label"
                    value={sub.control}
                    onChange={(e) => handleChangeControl(e.target.value, sub.id)}
                    disabled = {sub.active ? false:true}
                    sx={sub.active ? enabledSelect : disabledSelect}
                    renderValue={(value) => {
                      return (
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <CubiertosIcon height="14px" width="10px" fill={sub.active ? "#041C32" : "#E7E7E7"} marginRight="10px" />
                          {` ${horariosReservables.find((hr) => hr.value === value).option}`}
                        </Box>
                      );
                    }}
                  >
                    {
                      horariosReservables.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{display: "flex", gap: "8px"}}>
                          <CubiertosIcon height="14px" width="10px" fill={sub.active ? "#041C32" : "#E7E7E7"} />                          
                          {`${option.option}`}
                        </MenuItem>
                      ))
                    }                  
                  </Select> */}
                </Box>
              </Stack> 
            </Stack>
          )}
        </Box>        
        <Stack style={modalStyles.ButtonContainer}>
          <Button className={classes.CancelButton} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={classes.ApplyButton} onClick={() => changeBookingHours(subturno.id, hours)}>
            Aplicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    subShifts: state.v3.shifts.shiftData.subShifts,
  };
};

export default connect( mapStateToProps, null )( ScheduleStepModal );