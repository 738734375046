import React from 'react';

// Components
// import GroupsIcon from '../../../../SvgComponents/icons/IconsPrimary/Only Icons/GroupIconPrimary';
// import CubiertosIcon from '../../../../SvgComponents/icons/IconsPrimary/CubiertosIcon';
// Material Ui
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    Root: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #DBD7DC',
        backgroundColor: 'white',
        padding: '.8rem 1rem',
        borderRadius: '10px',
        height: 'auto',
        width: '95%',
    },
    NameBooking: {
        margin: '0 0 .5rem 0',
        fontSize: '1.2rem',
        fontWeight: '500',
        color: '#0B4762',
    },
    TextsContainer: {
        margin: '.2rem 0',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
    },
    Text: {
        color: '#808080',
        fontSize: '12px',
        margin: '0px',
    },
    People: {
        fontWeight: '600',
        color: '#808080',
        fontSize: '12px',
        margin: '0px',
    }
}));

const SectorsDetails = (props) => {

    const classes = useStyles();

    const {
        name,
        maxAutomatic,
        minAutomatic,
    } = props

    return (
        <div key={name} className={classes.Root}>
            <h4 className={classes.NameBooking}>{name}</h4>
            <div className={classes.TextsContainer}>
                <p className={classes.Text}>Rango aceptación automática: </p>
                <p className={classes.People}>{minAutomatic + "-" + maxAutomatic} personas</p>
            </div>
        </div>
    )
}

export default SectorsDetails
