import React, { useEffect } from 'react';

import { connect } from 'react-redux';

// Components
import RemovePunctualDate from './RemovePunctualDate';
import AddPunctualDate from './AddPunctualDate';

// Material Ui
import { Box } from '@mui/material';
import moment from 'moment';

const PunctualDatesComponent = ( props ) => {

    // External props
    const { component, setShowModal } = props;

    // States
    const { punctualDates } = props;

    useEffect(() => {
        let newDatesCopy = [];

        if ( punctualDates.newDates.length > 0  ) {
            for (let i = 0; i < punctualDates.newDates?.length; i++) {
                newDatesCopy.push( moment( punctualDates.newDates[i] ).format('DD/MM/YYYY') );
            };
        };

        if ( punctualDates.excludeDate.length > 0 && newDatesCopy.length > 0) {
            if ( newDatesCopy.includes( moment( punctualDates.excludeDate[ punctualDates.excludeDate.length - 1 ]).format('DD/MM/YYYY') ) ) {
                setShowModal( true );
            }
        }

        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ punctualDates ]);

    return (
        <Box>
            <AddPunctualDate component={ component }/>
            {
                component === 'openings' ?
                    <RemovePunctualDate />
                :
                    null
            }
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        punctualDates: state.planningReducer.punctualDates,
    };
};

export default connect( mapStateToProps, null )( PunctualDatesComponent );