import React from 'react'

const CancelRedIcon = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 25 25">
                <path id="Sustracción_21" data-name="Sustracción 21" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.514,12.514,0,0,1,12.5,25Zm.035-9.994h0l4.9,4.895,2.671-2.67-4.9-4.9,4.9-4.9L17.432,4.768l-4.9,4.9-4.9-4.9L4.968,7.439l4.9,4.9-4.9,4.9L7.639,19.9l4.9-4.895Z" fill="#eb3030"/>
            </svg>
        </div>
    )
}

export default CancelRedIcon
