import React, {useState} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Action
import {
    addBookingHourInterval,
    changeAutoAcceptancePercentage,
    changeBookingNotification,
    changeShiftConfirmationTolerance,
    changeShiftEnable,
    changeShiftMode,
    changeShiftToKnowBeforeBooking,
    changeShiftTolerance,
    closeBookingError,
    editBookingHourInterval,
    editBookingHourIntervalComplete,
    removeBookingHourInterval,
    resetAllData,
    submitGeneralInfoReservasChanges,
    toggleBookingHourIntervalEnable,
    toggleShiftConfirmationToleranceEnable
} from "../../../actions";

// Components
import TextAreaWithCondition from "../../NewComponents/TextAreaWithCondition/TextAreaWithCondition";
import SaveChangesButton from "../../NewComponents/Buttons/Save Changes/SaveChangesButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ButtonWithIcon from "../../NewComponents/ButtonWithIcon/ButtonWithIcon";
import SmallFilterButton from "./SmallFilterButton/SmallFilterButton";
import {BlueSwitch} from "../../GeneralComponents/blueSwitch";
import HoursHome from "./HoursHome";
import TurnCard from "./TurnCard";

// Constants
import {minutesOfDay, ShiftMode} from "../../../constants";

// Icon
// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";
import {Grid} from "@material-ui/core";
import ErrorDialog from "../../GeneralComponents/Modal/ErrorDialog";
import BookingModal from "../Dialogs/BookingModal";
import {ModalMode} from "../../../constants/modalMode";
import LightTooltip from "../../GeneralComponents/LightTooltip";
import {InfoOutlined} from "@mui/icons-material";
import SaveChangesModal from "../../Modals/SaveChangesModal";
import WaitListConfiguration from "./WaitListConfiguration";

const useStyles = makeStyles(() => ({
    mainContainer: {
        fontFamily: 'Poppins, sans-serif',
        height: '100%',
        marginTop: 0,
    },
    TitleTop: {
        color: '#041C32',
        margin: '0 0 .6rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
    },
    subtitle: {
        color: '#808080',
        fontSize: '15px',
        margin: '0px'
    },
    newShiftCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 150,
        height: '100%',
        minWidth: 180,
    },
    paper: {
        backgroundColor: "#FF0000",
        // borderBlockColor: "#F0F0F0",
        borderRadius: 20,
    },
    newShiftButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    newShiftIcon: {
        fontSize: '5em',
        color: grey,
    },
    text: {
        fontWeight: '600',
        color: '#848282',
        margin: '0px',
    },
    SwitchSection: {
        alignContent: 'center',
        display: 'flex',
        gap: '15px',
    },
    TitleMiddle: {
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
        margin: '0'
    },
    Subtitle: {
        fontWeight: '500',
    },
    SmallFilterButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '10%',
    },
    TitleConfigContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '10px'
    },
    TitleConfig: {
        fontWeight: '500',
    },
    Hr: {
        backgroundColor: '#B9B9B9',
        margin: '2rem 0rem',
        border: 'none',
        height: '1px',
    }
}));

const ReservasView = (props) => {
    const classes = useStyles();

    // Variables
    const {
        shifts,
        generalInfo,
        edited,
        allSectors,
        error
    } = props;

    const {
        autoAcceptancePercentage,
        bookingNotification,
        shiftsTolerance,
        confirmationTolerance,
        confirmationToleranceEnable,
        toKnowBeforeBooking,
        bookingHourIntervals,
        bookingHourIntervalIndex,
    } = generalInfo;

    // Actions
    const {
        changeShiftEnable,

        addBookingHourInterval,
        removeBookingHourInterval,
        editBookingHourInterval,
        editBookingHourIntervalComplete,
        toggleBookingHourIntervalEnable,

        changeShiftToKnowBeforeBooking,
        toggleShiftConfirmationToleranceEnable,
        changeShiftConfirmationTolerance,
        changeShiftTolerance,
        changeBookingNotification,
        changeAutoAcceptancePercentage,

        changeShiftMode,
        submitGeneralInfoReservasChanges,
        closeErrorDialog,
        resetAllData,
    } = props;

    const [enable, setEnable] = useState(autoAcceptancePercentage === 100);
    const [changeShift, setChangeShift] = useState(undefined);


    const getCubiertos = (sectors) => {
        if (sectors !== undefined && sectors.length > 0) {
            let cubiertos = 0;
            sectors.forEach(sector => cubiertos += sector.capacity);
            return cubiertos
        }
    }

    const submit = async () => {
        if (edited) {
            await submitGeneralInfoReservasChanges();
            return true;
        }
    }

    // Configuracion general
    const getPercentageOptions = () => {
        let options = [];
        for (let i = 0; i <= 100; i += 5) {
            options.push({value: i, option: `${i} %`})
        }
        return options;
    }

    const getBookingNotificationOptions = () => {
        let options = [{
            value: 30,
            option: '30 min'
        }];
        for (let i = 1; i <= 8; i++) {
            options.push({value: i * 60, option: `${i} hs`})
        }
        for (let i = 1; i <= 3; i++) {
            options.push({value: i * 60 * 24, option: `${i} día${i === 1 ? '' : 's'}`})
        }
        return options;
    }

    const getConfirmationToleranceOptions = () => {
        let options = [];
        for (let i = 1; i <= 3; i++) {
            options.push({value: i * 15, option: `${i * 15} min`})
        }
        for (let i = 1; i <= 7; i++) {
            options.push({value: i * 60, option: `${i} hs`})
        }
        for (let i = 1; i <= 2; i++) {
            options.push({value: i * 60 * 24, option: `${i} día${i === 1 ? '' : 's'}`})
        }
        return options;
    }

    const getArriveToleranceOptions = () => {
        let options = [];
        for (let i = 1; i <= 12; i++) {
            options.push({value: i * 5, option: i * 5 >= 60 ? `${i - 11} hs` : `${i * 5} min`})
        }
        return options;
    }

    const handleEnableOptions = (enable) => {
        setEnable(enable.target.checked);
        changeAutoAcceptancePercentage(enable.target.checked ? 100 : 95);
    }

    const handleConfirmationToleranceEnable = () => {
        toggleShiftConfirmationToleranceEnable();
    }

    const handleConfirmationTolerance = (e) => {
        if (e.target.value <= bookingNotification) {
            changeShiftConfirmationTolerance(e.target.value)
        }
    }

    const handleToKnowBeforeBooking = (e) => {
        if (e.target.value.length < 1000) {
            changeShiftToKnowBeforeBooking(e.target.value)
        }
    }

    const handleAutoAcceptancePercentage = (e) => {
        changeAutoAcceptancePercentage(e.target.value)
        if (e.target.value === 100) {
            setEnable(true);
        }
    }

    const handleBookingNotification = (e) => {
        changeBookingNotification(e.target.value)
        if (confirmationToleranceEnable && e.target.value < confirmationTolerance) {
            changeShiftConfirmationTolerance(e.target.value)
        }
    }

    // Configuracion de turnos
    const getNextShiftOrder = () => {
        let nextShiftOrder = 0;
        shifts.forEach(shift => {
            if (shift.order > nextShiftOrder) {
                nextShiftOrder = shift.order;
            }
        });
        return nextShiftOrder + 1;
    }

    const handleAddNewShift = () => {
        changeShiftMode({
            mode: ShiftMode.CREATE,
            shift: {
                sectors: allSectors.map(s => ({
                    name: s.name,
                    minAutomatic: 2,
                    maxAutomatic: 10,
                    capacity: s.capacity,
                    maxCapacity: s.maxCapacity,
                    alias: s.alias ?? s.name,
                })),
                subShifts: [],
                order: getNextShiftOrder(),
                opening: "08:00",
                name: "",
                days: Array(7).fill(false),
                enable: true,
                advanceStart: minutesOfDay * 10,
                advanceEnd: 30,
                startDate: moment().startOf("date"),
                endDate: moment({year: 3000}).startOf("date"),
            }
        })
    }

    const handleError = () => {
        closeErrorDialog();
    }

    const onChangeEnable = (shift) => {
        shift.enable = !shift.enable;
        console.log(moment(shift?.startDate).isAfter(moment()) ? moment(shift?.startDate).toDate() : moment().toDate())
        // Si el turno pasa de habilitado a deshabilitado se muestra el pop-up
        if (!shift.enable) {
            setChangeShift(shift);
        }
        // Caso contrario, se activa
        else {
            changeShiftEnable(shift);
        }
    }

    const onCancel = () => {
        setChangeShift(undefined);
    }

    const onFinish = (date) => {
        changeShiftEnable(changeShift, date);
        setChangeShift(undefined);
    }

    const discardChanges = () => {
        resetAllData();
        return true;
    }

    return (
        <Grid container className={classes.mainContainer}>
            <div>
                <h1 className={classes.TitleTop}>Turnos</h1>
                <p className={classes.subtitle}>Recordá que desactivar todos los sectores desactivará por
                    completo la función Reservas de tu establecimiento. No podrás recibir
                    solicitudes y la función figurará en tu perfil como “No disponible”</p>
                <div style={{padding: '1rem 0rem'}}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {
                                shifts
                                    ? shifts.map(shift => (
                                        <Grid key={"grid-" + shift['uuid']} item>
                                            <div key={"div-" + shift['uuid']}>
                                                <TurnCard
                                                    shift={shift}
                                                    cubiertos={getCubiertos(shift["sectors"])}
                                                    onChangeEnable={() => onChangeEnable({...shift})}
                                                    onClick={() => changeShiftMode({mode: ShiftMode.EDIT, shift: shift})}
                                                />
                                            </div>
                                        </Grid>))
                                    : null
                            }
                        </Grid>
                        <div style={{margin: '3rem 0rem'}}>
                            <ButtonWithIcon
                                text={"Agregar turno"}
                                onClick={handleAddNewShift}
                                icon={<AddCircleOutlineOutlinedIcon/>}
                            />
                        </div>
                        <hr className={classes.Hr}/>
                        <div className={classes.AutomaticAcceptContainer}>
                            <div className={classes.SwitchSection}>
                                <h1 className={classes.TitleMiddle}>Sistema de aceptación automática total</h1>
                                <LightTooltip
                                    placement='right'
                                    title='Determine si el sistema puede aceptar automáticamente solicitudes de reservas y asignarlas a una mesa óptima. '
                                >
                                    <InfoOutlined style={{
                                        color: '#041C32',
                                        fontSize: '16px',
                                        marginTop: '10px',
                                        cursor: 'pointer'
                                    }}/>
                                </LightTooltip>
                                <div style={{marginTop: '-.2rem'}}>
                                    <BlueSwitch
                                        checked={enable}
                                        onChange={handleEnableOptions}
                                    />
                                </div>
                            </div>
                            <div className={classes.SwitchSection}>
                                <h2 className={classes.Subtitle}
                                    style={{color: enable ? '#B9B9B9' : '#041C32'}}>Porcentaje automático</h2>
                                <div className={classes.SmallFilterButtonContainer}>
                                    <SmallFilterButton
                                        text={'Filtros'}
                                        color={'#0B4762'}
                                        value={autoAcceptancePercentage}
                                        options={getPercentageOptions()}
                                        disabled={enable}
                                        onChange={handleAutoAcceptancePercentage}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className={classes.Hr}/>
                        <WaitListConfiguration/>
                        <hr className={classes.Hr}/>
                        <div style={{margin: '20px 0px'}}>
                            <h1 className={classes.TitleTop} style={{marginTop: '30px'}}>Horarios de atención a
                                la toma de reservas</h1>
                            <HoursHome
                                // Variables
                                intervals={bookingHourIntervals}
                                editIntervalIndex={bookingHourIntervalIndex}

                                // Acciones
                                addInterval={addBookingHourInterval}
                                removeInterval={removeBookingHourInterval}
                                editInterval={editBookingHourInterval}
                                editIntervalComplete={editBookingHourIntervalComplete}
                                toggleIntervalEnable={toggleBookingHourIntervalEnable}

                                // Constantes
                                intervalName={"Horario"}
                            />
                        </div>
                        <hr className={classes.Hr}/>
                        <h1 className={classes.TitleTop} style={{marginTop: '30px'}}>Configuración de
                            reservas</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div className={classes.TitleConfigContainer}>
                                    <h3 className={classes.TitleConfig}>Pedido de confirmación</h3>
                                    <LightTooltip
                                        placement='right'
                                        title='Defina el tiempo previo a una reserva en el que se le pedirá confirmación de asistencia al cliente.'
                                    >
                                        <InfoOutlined style={{
                                            color: '#041C32',
                                            fontSize: '16px',
                                            marginTop: '0',
                                            cursor: 'pointer'
                                        }}/>
                                    </LightTooltip>
                                </div>
                                <SmallFilterButton
                                    id={'Filter-button-4'}
                                    value={bookingNotification}
                                    color={'#0B4762'}
                                    options={getBookingNotificationOptions()}
                                    disabled={false}
                                    onChange={handleBookingNotification}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.TitleConfigContainer}>
                                    <BlueSwitch
                                        checked={confirmationToleranceEnable}
                                        onClick={handleConfirmationToleranceEnable}
                                    />
                                    <h3 className={classes.TitleConfig}
                                        style={{marginLeft: '-.7rem'}}>Tolerancia de confirmación</h3>
                                    <LightTooltip
                                        placement='right'
                                        title='Defina el tiempo previo a una reserva sin confirmar que se esperará antes de darla de baja.'
                                    >
                                        <InfoOutlined style={{
                                            color: '#041C32',
                                            fontSize: '16px',
                                            marginTop: '0',
                                            cursor: 'pointer'
                                        }}/>
                                    </LightTooltip>
                                </div>
                                <SmallFilterButton
                                    color={'#0B4762'}
                                    value={confirmationTolerance === null ? "" : confirmationTolerance}
                                    options={getConfirmationToleranceOptions()}
                                    disabled={!confirmationToleranceEnable}
                                    onChange={handleConfirmationTolerance}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.TitleConfigContainer}>
                                    <h3 className={classes.TitleConfig}>Tolerancia al arribo</h3>
                                    <LightTooltip
                                        placement='right'
                                        title='Defina el tiempo que se esperará al arribo de un cliente una vez comenzada su reserva.'
                                    >
                                        <InfoOutlined style={{
                                            color: '#041C32',
                                            fontSize: '16px',
                                            marginTop: '0',
                                            cursor: 'pointer'
                                        }}/>
                                    </LightTooltip>
                                </div>
                                <SmallFilterButton
                                    color={'#0B4762'}
                                    value={shiftsTolerance}
                                    options={getArriveToleranceOptions()}
                                    disabled={false}
                                    onChange={(e) => changeShiftTolerance(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <hr className={classes.Hr}/>
                        <h1 className={classes.TitleTop} style={{marginTop: '30px'}}>Qué saber antes de
                            venir?</h1>
                        <div style={{width: '70%', marginBottom: '120px'}}>
                            <TextAreaWithCondition
                                value={toKnowBeforeBooking}
                                onChange={handleToKnowBeforeBooking}
                                width={'100%'}
                                helperText={'Máximo 1000 caracteres'}
                            />
                        </div>
                        <Grid item>
                            {edited ? <SaveChangesButton index={2} onClick={submit}/> : null}
                        </Grid>
                    </Grid>
                </div>
                <ErrorDialog
                    open={error !== undefined}
                    title={"Atención!"}
                    body={error ?? ""}
                    subBody={""}
                    onAcceptText={"OK!"}
                    onAccept={handleError}
                    type={'ErrorsBooking'}
                />
                <BookingModal
                    show={changeShift !== undefined}
                    type={ModalMode.DISABLED}
                    minDate={moment(changeShift?.startDate).isAfter(moment()) ? moment(changeShift?.startDate).toDate() : moment().toDate()}
                    advanceStart={changeShift?.advanceStart ?? 0}
                    onCancel={onCancel}
                    onFinish={onFinish}
                />
                <SaveChangesModal
                    when={edited}
                    submit={submit}
                    handleClose={discardChanges}
                />
            </div>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        shifts: state.reservasReducer.shifts,
        allSectors: state.reservasReducer.allSectors,
        shiftMode: state.reservasReducer.mode,
        reload: state.reservasReducer.reload,
        loading: state.reservasReducer.loading,
        error: state.reservasReducer.error,
        profile: state.firebase.profile,

        edited: state.reservasReducer.edited,
        generalInfo: state.reservasReducer.generalInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeShiftEnable: (enabled, uuid) => dispatch(changeShiftEnable(enabled, uuid)),

        addBookingHourInterval: (data) => dispatch(addBookingHourInterval(data)),
        removeBookingHourInterval: (data) => dispatch(removeBookingHourInterval(data)),
        editBookingHourInterval: (data) => dispatch(editBookingHourInterval(data)),
        editBookingHourIntervalComplete: (data) => dispatch(editBookingHourIntervalComplete(data)),
        toggleBookingHourIntervalEnable: (index) => dispatch(toggleBookingHourIntervalEnable(index)),

        changeShiftToKnowBeforeBooking: (data) => dispatch(changeShiftToKnowBeforeBooking(data)),
        toggleShiftConfirmationToleranceEnable: (data) => dispatch(toggleShiftConfirmationToleranceEnable(data)),
        changeShiftConfirmationTolerance: (data) => dispatch(changeShiftConfirmationTolerance(data)),
        changeShiftTolerance: (data) => dispatch(changeShiftTolerance(data)),
        changeBookingNotification: (data) => dispatch(changeBookingNotification(data)),
        changeAutoAcceptancePercentage: (data) => dispatch(changeAutoAcceptancePercentage(data)),

        changeShiftMode: ({mode, shift}) => dispatch(changeShiftMode({mode, shift})),
        closeErrorDialog: () => dispatch(closeBookingError()),

        submitGeneralInfoReservasChanges: () => dispatch(submitGeneralInfoReservasChanges()),
        resetAllData: () => dispatch(resetAllData()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReservasView);
