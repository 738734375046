import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import TableInvoices from '../../components/MyInvoices/TableInvoices/TableInvoices';
import { Box, Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BillingInfoCard, PaymentCard, PlanCard } from '../../components/MyInvoices/Cards';
import { loadInvoicesData, loadSuscription } from '../../actions/invoiceActions';

const useStyles = makeStyles(() => ({
    MainContainer: {
        fontFamily: 'Poppins, sans-serif',
        paddingRight: '5rem',
    },
    Title: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#041C32',
        margin: '0',
        paddingBottom: '0.5rem',
    },
    InvoiceData: {
        margin: '0 0 10px 0',
        fontSize: '16px',
        fontWeight: '400',
        color: '#808080',
    },
    InvoiceCardCTA: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        borderRadius: '0 0 10px 10px',
        backgroundColor: '#0B4762',
        padding: '5px',
        height: '20%',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    InvoiceCardCTAText: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '15px',
        fontWeight: '500',
        color: '#FFFFFF',
        margin: '0',
    },
    TitlePlan: {
        fontSize: '30px',
        fontWeight: '600',
        color: '#0B4762',
        margin: '0',
    },
    helperText: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#808080',
        margin: '0',
    },
}));

const MyInvoices = (props) => {

    const classes = useStyles();

    const {
        changeTitleAppBar,
        changeShowSidebar,
        loadInvoices,
        loadSuscription,
    } = props;

    const {
        invoices,
        billing_info,
        loading,
        profile,
        paymentMethod,
    } = props;

    useEffect(() => {
        props.changeTitleAppBar("Facturación");
        props.changeShowSidebar(true);
    }, [props]);

    useEffect(() => {
        if(profile.isLoaded) {
            loadInvoices();
        }
    }, [loadInvoices, profile]);

    useEffect(() => {
        if(profile.isLoaded && billing_info.subscriptionId) {
            loadSuscription();
        }
    }, [loadSuscription, profile, billing_info]);



    return (
        <main className={classes.MainContainer}>
            <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ paddingBottom: '1.5rem' }}
                spacing={{ xs: 1, sm: 1, md: 4 }}
                columns={10}
            >
                {/* Billing info */}
                <BillingInfoCard {...billing_info}/>

                {/* Plan card */}
                <PlanCard {...billing_info}/>
                
                {/* Payment card */}
                <PaymentCard
                    paymentMethod={paymentMethod}
                    {...billing_info}
                />

            </Grid>
            <section className={classes.TableSection}>
                <TableInvoices 
                    invoices={invoices}
                />
            </section>
        </main>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        invoices: state.invoiceReducer.invoices,
        billing_info: state.invoiceReducer.billing_info,
        loading: state.invoiceReducer.loading,
        paymentMethod: state.invoiceReducer.paymentMethod,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        loadInvoices: () => dispatch(loadInvoicesData()),
        loadSuscription: () => dispatch(loadSuscription()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInvoices);
