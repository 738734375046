import {useState} from 'react';

// Components
import CombinationsList from "./CombinationsList";
import DraggableTable from "./DraggableTable";
import './DraggableMenu.css'

// Material Ui
import {Card, makeStyles, Tab, Tabs, Typography,} from '@material-ui/core';

// Icon
import {connect} from "react-redux";
import {InfoOutlined} from "@material-ui/icons";
import LightTooltip from "../../GeneralComponents/LightTooltip"
import {editSectorCancelCombineTables} from "../../../actions";

const useStyles = makeStyles(theme => ({
    card: {
        position: 'sticky',
        top: '0em',
        borderRadius: '8px',
        fontFamily: 'Poppins, sans-serif',
        minWidth: '250px',
    },
    tab: {
        fontSize: theme.typography.pxToRem(15),
        borderRadius: '0.6em 0.6em 0em 0em',
        borderColor: "DBD7DC",
        fontFamily: 'Poppins, sans-serif',
        width: '20px',
        textTransform: 'none',
        background: '#EFEFEF',
        color: '#0B4762',
        '&.MuiTab-root': {
            minWidth: '50%',
        },
        '&.Mui-selected': {
            color: '#fff',
            fontWeight: '500',
            backgroundColor: '#0B4762',
            boxShadow: '0px 2px 4px #0B476290',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
            fontWeight: '500',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1.2em',
        width: '100%',
        height: '60vh',
        paddingBotton: '1em',
    }
}))

const DraggableMenu = (props) => {
    const classes = useStyles();

    const [currentTab, setCurrentTab] = useState('mesas');
    const {combineMode} = props;
    const {changeNewTable, cancelCombination} = props;

    const tableOptions = [
        {minCapacity: 1, maxCapacity: 2, w: 14, h: 3},
        {minCapacity: 2, maxCapacity: 4, w: 14, h: 3},
        {minCapacity: 3, maxCapacity: 5, w: 14, h: 3},
        {minCapacity: 4, maxCapacity: 6, w: 14, h: 3},
        {minCapacity: 6, maxCapacity: 10, w: 14, h: 3},
        {minCapacity: 8, maxCapacity: 12, w: 14, h: 3},
    ];

    const tabs = [
        {value: 'mesas', label: 'Agregar'},
        {value: 'combinaciones', label: 'Combinar'},
    ];

    const handleTabsChange = (_event, value) => {
        setCurrentTab(value);
        if (value === tabs[0].value && combineMode) {
            cancelCombination();
        }
    };

    return (
        <Card className={classes.card}>
            <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant={"standard"}
                textColor="primary"
                indicatorColor="primary"
            >
                {tabs.map((tab) => (
                    <Tab className={classes.tab} key={tab.value} label={tab.label} value={tab.value}/>
                ))}
            </Tabs>
            {currentTab === 'mesas' && (
                <div
                    style={{
                        height: '72vh',
                        border: '2px solid #0B4762',
                        borderRadius: '0px 0px 8px 8px',
                        position: 'relative'
                    }}
                >
                    <div className={classes.container}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography
                                variant="h6"
                                component="h6"
                                gutterBottom
                                style={{
                                    padding: 0.5,
                                    color: '#0B4762',
                                    fontSize: '.9rem',
                                    margin: '1rem 0.5rem',
                                    fontFamily: 'Poppins, sans-serif',
                                }}
                            >
                                Tipos de mesas
                            </Typography>
                            <LightTooltip
                                placement='right'
                                title='Clickeá y arrastrá tu mesa hasta su posición final. Luego editá sus características.'
                            >
                                <InfoOutlined variant="small"
                                              style={{color: '#0B4762', fontSize: '18px', cursor: 'pointer'}}/>
                            </LightTooltip>
                        </div>
                        <div className={classes.flex}>
                            {
                                tableOptions.map((table, i) => (
                                    <DraggableTable
                                        key={i}
                                        {...table}
                                        showDivider={i !== tableOptions.length - 1}
                                        onDragStart={changeNewTable}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
            {currentTab === 'combinaciones' && (
                <div
                    style={{
                        height: '70vh',
                        border: '2px solid #0B4762',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <CombinationsList combineMode={combineMode}/>
                </div>
            )}
        </Card>
    );
}

const mapStateToProps = (state) => ({
    combineMode: state.sectorsReducer.sectors.combineMode,
})

const mapDispatchToProps = dispatch => {
    return {
        cancelCombination: () => dispatch(editSectorCancelCombineTables()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableMenu);
