import React from "react";

const VisibilityIcon = () => {
    return (
        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.4067 8.44786C22.9246 9.12529 22.9246 10.0398 22.4067 10.7162C20.7754 12.8457 16.8616 17.2304 12.2922 17.2304C7.72285 17.2304 3.80905 12.8457 2.17775 10.7162C1.92577 10.3918 1.78899 9.99275 1.78899 9.58201C1.78899 9.17127 1.92577 8.77223 2.17775 8.44786C3.80905 6.31832 7.72285 1.93359 12.2922 1.93359C16.8616 1.93359 20.7754 6.31832 22.4067 8.44786V8.44786Z"
                stroke="#0B4762" strokeWidth="2.18526" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12.2922 12.8605C14.1026 12.8605 15.5701 11.3929 15.5701 9.58258C15.5701 7.77225 14.1026 6.30469 12.2922 6.30469C10.4819 6.30469 9.01434 7.77225 9.01434 9.58258C9.01434 11.3929 10.4819 12.8605 12.2922 12.8605Z"
                stroke="#0B4762" strokeWidth="2.18526" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default VisibilityIcon;
