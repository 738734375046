import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Actions
import { setGroup, setSubshift, setValidationError } from "../../../../../actions/shiftsActions";

// Icons
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui 
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';

//Components
import WarningPopup, { ModalModeTurnos } from "../../WarningPopup/WarningPopup";
import ScheduleStepShiftTypes from "../components/ScheduleStepShiftTypes";
import ScheduleStepSelectDays from "../components/ScheduleStepSelectDays";
import AddCalendarButton from "../components/AddCalendarButton";
import ScheduleStepModal from "../components/ScheduleStepModal";
import AddDateButton from "../components/AddDateButton";
import SubShift from "../components/SubShift";

// Utils
import { GetBookableSectors, GetNewOpeningHour } from "../utils/ScheduleFunctions";
import { FromIsBeforeTo, HourBetweenHours, SameHour } from "../utils/functions";

// Styles
import { DecriptionShiftStyles, ShiftSelectTitles, TitleShiftStyles } from "../../../Styles";
import { dividerStyle } from "../utils/styles/generalStyles";

const useStyles = makeStyles(() => ({
  divider: dividerStyle,
}));

const ScheduleStep = (props) => {
  const classes = useStyles();

  const [showModalSubshift, setShoModalSubshift] = useState(false);
  const [showModalHours, setShowModalHours] = useState(false);
  const [subshiftName, setSubshiftName] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [id, setId] = useState("");

  // Variables
  const { isSpecial, calendar, subShifts, availableLayouts } = props;

  // Acciones
  const { setSubshift, setValidationError, setGroup } = props;

  const handleBookableHoursClick = ( subshiftName, subturno, from, to ) => {
    setSubshiftName( subshiftName );
    setShowModalHours( true );
    setSubturno( subturno );
    setId( subturno.id );
    setFrom( from );
    setTo( to );
  };

  const [paperList, setPaperList] = useState(subShifts ? subShifts : []);
  const [deleteSubShift, setDeleteSubshift] = useState(-1);
  const [subshiftId, setSubshiftId] = useState(-1);
  const [subturno, setSubturno] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDelete = (id) => {
    setShoModalSubshift(true);
    setSubshiftId(id);
  };

  const handleConfirmModal = ( subshiftId ) => {
    setDeleteSubshift( subshiftId );
    setShoModalSubshift( false );
    console.log( 'jhkaj ==> ', subShifts );
    setGroup({ 
      subShiftDelete: subshiftId,
      sectors: subShifts, 
      reset: true, 
    });
  };

  useEffect(() => {
    if ( deleteSubShift !== -1 ) {
      const newPaperList = paperList.filter(
        (paper) => paper.id !== deleteSubShift
      );
      setPaperList(newPaperList);

      setDeleteSubshift( -1 );
    };

    setSubshift( paperList );

  }, [ paperList, deleteSubShift, setSubshift ]);

  // Add subshift
  const handleButtonClick = () => {
    const subshiftFrom = GetNewOpeningHour( paperList, calendar.opening );

    // Si existen sectores, cargarlo en bookableSectors
    const subturno = {
      id: uuidv4(),
      from: subshiftFrom,
      to: subshiftFrom + 60,
      excludeTables: [],
      extraTables: [],
      excludeGroup: [],
      extraGroup: [],
      bookingHours: [{ time: subshiftFrom, flowControl: null }],
      bookableSectors: GetBookableSectors( availableLayouts ),
      allowsWaitList: true,
    };

    setPaperList(( paperList ) => [ ...paperList, subturno ]);

  };

  const changeFrom = (id, val) => {
    setValidationError("");

    if (HourBetweenHours(val, calendar.opening, calendar.closing)) {
      if (SameHour(val, id, paperList, true)) {
        setValidationError("error:subShiftSameHour");
      } else if (!FromIsBeforeTo(val, paperList.find((paper) => paper.id === id).to)) {
        setValidationError("error:subShiftFromBeforeTo");
      } else {
        const paperToModify = paperList.find((paper) => paper.id === id);
        let bookingHours = paperToModify.bookingHours.filter((hour) => hour.time >= val && hour.time <= paperToModify.to);
        if(bookingHours.length === 0) {
          bookingHours = [{ time: val, flowControl: null }];
        }
        const nextPaperList = paperList.map((paper) => {
          if (paper.id === id) {
            return {
              ...paper,
              from: val,
              bookingHours,
            };
          } else {
            // No change
            return paper;
          }
        });
        setPaperList(nextPaperList);
      }
    } else {
      setValidationError("error:subShiftShiftHours");
    }
  };

  const changeTo = (id, val) => {
    setValidationError("");

    if (HourBetweenHours(val, calendar.opening, calendar.closing)) {
      if ( SameHour(val, id, paperList, false) ) {
        setValidationError( "error:subShiftSameHour" );
      } else if ( !FromIsBeforeTo(paperList.find((paper) => paper.id === id).from, val) ) {
        setValidationError( "error:subShiftFromBeforeTo" );
      } else {
        const paperToModify = paperList.find((paper) => paper.id === id);
        let bookingHours = paperToModify.bookingHours.filter((hour) => hour.time <= val && hour.time >= paperToModify.from);
        if (bookingHours.length === 0) {
          bookingHours = [{ time: paperToModify.from, flowControl: null }];
        }
        const nextPaperList = paperList.map((paper) => {
          if ( paper.id === id ) {
            return {
              ...paper,
              to: val,
              bookingHours,
            };
          } else {
            // No change
            return paper;
          };
        });
        // Re-render with the new array
        setPaperList( nextPaperList );
      }
    } else {
      setValidationError("error:subShiftShiftHours");
    }
  };

  const changeBookingHours = (id, hours) => {
    console.log( hours );
    const bookingHours = [];

    // eslint-disable-next-line array-callback-return
    hours.map((hour) => {
      if ( hour.active ) {
        bookingHours.push({
          time: hour.value,
          flowControl: hour.control === 0 ? null : hour.control,
        });
      }
    });

    const nextPaperList = paperList.map((paper) => {
      if (paper.id === id) {
        return {
          ...paper,
          bookingHours: bookingHours,
        };
      } else {
        // No change
        return paper;
      };
    });

    // Re-render with the new array
    setPaperList(nextPaperList);

    setShowModalHours(false);
  };

  return (
    <Box>
      <Grid container spacing={2} style={{ marginBottom: "50px" }}>
        <Grid item xs={12} sm={12}>
          {
            showModalHours ? (
                <ScheduleStepModal
                  handleClose={() => setShowModalHours(false)}
                  changeBookingHours={ changeBookingHours }
                  subshiftName={ subshiftName }
                  subturno={ subturno }
                  from={ from }
                  to={ to }
                />
              ) 
            : 
              null
          }
          <Box sx={{ mb: '20px' }} >
            <Typography sx={ TitleShiftStyles } >Tipo de turno y horarios</Typography>
            <Typography sx={ DecriptionShiftStyles } >
              Selecciona el tipo de turno y define sus horarios de inicio y fin.
            </Typography>
          </Box>
          <ScheduleStepShiftTypes />
          <Divider className={classes.divider} />
          <Collapse in={ !isSpecial }>
            <ScheduleStepSelectDays />
            <Divider className={classes.divider} />
          </Collapse>
          {/* { 
            !isSpecial ? (
              <>
                <ScheduleStepSelectDays />
                <Divider className={classes.divider} />
              </>
              ) 
            : 
              null
          } */}
          <Box>
            <Typography sx={ ShiftSelectTitles } >Fechas puntuales</Typography>
            <Box sx={{ marginTop: "32px", marginBottom: "24px" }}>
              <AddCalendarButton
                componenteHijo={ AddCircleOutlineOutlinedIcon }
                titleButton="Agregar fecha"
                type={"agregar"}
              />
            </Box>
            {
              !isSpecial ? (
                  <AddCalendarButton
                    titleButton="Excluir fecha"
                    componenteHijo={RemoveCircleOutlineOutlinedIcon}
                    type={"excluir"}
                  />
                ) 
              : 
                null
            }
          </Box>
          <Divider className={classes.divider} />
          <Box sx={{ marginBottom: "32px" }}>
            <Typography sx={ TitleShiftStyles } >Subturnos y horarios reservables</Typography>
            <Typography sx={ DecriptionShiftStyles } >
              Configura los bloques de ingreso y los horarios reservables que podrán elegir tus clientes
            </Typography>
          </Box>
          <Box>
            <Stack direction="column" alignItems="left" textAlign="left">
              <Collapse in={ paperList.length > 0 ? true : false}>
                {
                  paperList.map((paper, index) => (
                    <SubShift
                      handleBookableHoursClick={ handleBookableHoursClick }
                      handleDelete={ handleDelete }
                      changeFrom={ changeFrom }
                      changeTo={ changeTo }
                      subturno={ paper }
                      key={ paper.id }
                      index={ index }
                    />
                  ))
                }
              </Collapse>
              <Paper
                elevation={0}
                style={{
                  width: "204px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
              >
                <AddDateButton
                  onClick={handleButtonClick}
                  titleButton="Agregar subturno"
                  componenteHijo={AddCircleOutlineOutlinedIcon}
                />
              </Paper>
            </Stack>
          </Box>
          {showModalSubshift ? (
            <WarningPopup
              onclickDelete={() => handleConfirmModal(subshiftId)}
              onclickBack={() => setShoModalSubshift(false)}
              onCancel={() => setShoModalSubshift(false)}
              type={ ModalModeTurnos.DELETE_SUBSHIFT }
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    availableLayouts: state.v3.shifts.shiftData.availableLayouts,
    reservableSectors: state.v3.shifts.reservableSectors,
    isSpecial: state.v3.shifts.shiftData.isSpecial,
    subShifts: state.v3.shifts.shiftData.subShifts,
    calendar: state.v3.shifts.shiftData.calendar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGroup: ({ reset, subShiftDelete, sector }) => dispatch( setGroup({ reset, subShiftDelete, sector }) ),
    setValidationError: (data) => dispatch(setValidationError(data)),
    setSubshift: (data) => dispatch(setSubshift(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleStep);
