export const STREET = 0;
export const WOKI = 1;
export const DYNAMIC_LINK = 2;
export const BROUGHT_BY_PARTNER = 3;

export const getNameOrigin = (origin) => {
    switch (origin) {
        case WOKI:
            return "Woki";
        case STREET:
            return "Agregado manual";
        case DYNAMIC_LINK:
            return "Link"
        case BROUGHT_BY_PARTNER:
            return "Mis clientes"
        default:
            return "";
    }
}

