import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
    },
    text: {
        fontSize: '9px',
        margin: '0px',
        textTransform: 'uppercase',
        marginBottom: '5px'
    },
    inputContainer: {
        width: '200px',
        height: '30px',
        background: 'white',
        border: '1px solid #D0D0D0',
        borderRadius: '5px',
        paddingLeft: '2px'
    },
    input: {
        width: '194px',
        height: '28px',
        border: 'none',
        outline: 'none'
    },
    error: {
        color: 'red',
        fontSize: '8px',
    }
}));

const Input = (props) => {

    const classes = useStyles();

    const {value, error, helperText} = props

    const {onChange} = props

    return (
        <div className={classes.root}>
            <p className={classes.text}>{props.name}</p>
            <div className={classes.inputContainer}>
                <input type="text" className={classes.input} value={value} onChange={onChange}/>
                {error ? <p className={classes.error}>{helperText}</p> : null}
            </div>
        </div>
    )
}

export default Input
