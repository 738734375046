import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import SettingsNumbers from '../../GeneralComponents/SettingsNumbers';
import CheckIconPrimary from '../../SvgComponents/icons/IconsPrimary/Only Icons/CheckIconPrimary';

const useStyles = makeStyles(() => ({
    root: {
        width: '450px',
        height: '170px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        backgroundColor: '#F0F0F0',
        padding: '10px 0px 10px 15px',
    },
    title: {
        margin: '0px',
        fontSize: '11px',
        fontWeight: '600',
    },
    text: {
        margin: '0px',
        fontSize: '9px',
        color: '#848282',
        marginTop: '3px',
        textAlign: 'justify',
    },
    header: {
        margin: '0px',
    },
    check: {
        height: '18px',
        width: '18px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        margin: '7px 5px 0px 0px',
        backgroundColor: 'white',
        cursor: 'pointer'
    }
}));

const SettingsGroup = (props) => {

    const classes = useStyles();

    const {checked, bigGroup, maxBigGroup} = props;

    const {onChangeEnable, onChangeBigGroup, onChangeMaxBigGroup} = props;

    return (
        <div className={classes.root}>
            <h4 className={classes.header}>Ajustes de grupos</h4>
            <Grid container spacing={2} style={{padding: '10px'}}>
                <Grid item xs={6} style={{paddingRight: '10px'}}>
                    <p className={classes.title}>Grupo grande</p>
                    <p className={classes.text}>Define la cantidad máxima de personas en un grupo para aceptación
                        automática de solicitud</p>
                    <div style={{marginTop: '10px'}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <div className={classes.check} style={{display: 'block'}} onClick={onChangeEnable}>
                                    <CheckIconPrimary checked={checked}/>
                                </div>
                            </Grid>
                            <Grid item xs={10} style={{marginLeft: '-3px'}}>
                                <SettingsNumbers name={"Personas"} value={bigGroup} onChange={onChangeBigGroup}
                                                 disabled={!checked}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={6} style={{paddingRight: '25px'}}>
                    <p className={classes.title}>Capacidad máxima de grupo</p>
                    <p className={classes.text}>Define la cantidad máxima de personas permitidas en un grupo</p>
                    <div style={{marginTop: '24px'}}>
                        <SettingsNumbers name={"Personas"} value={maxBigGroup} onChange={onChangeMaxBigGroup}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SettingsGroup
