import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";
import MenuWeb from "../../components/MenuWeb";

function MenuWebPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Carta - Web")
        props.changeShowSidebar(true);
    }, [])


    return (
        < >
            <Grid container>
                <Grid item xs={12}>
                    <MenuWeb/>
                </Grid>
            </Grid>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(MenuWebPage);