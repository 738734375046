import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Modal, Typography } from "@mui/material";
import WarningIcon from "../../../SvgComponents/icons/WarningIcon";

const BUTTON_COMMON_STYLES = {
  width: '240px',
  height: "50px",
  fontFamily: "Poppins, sans-serif !important",
  borderRadius: "8.46px !important",
  fontWeight: '600 !important',
  fontSize: '1rem !important',
  lineHeight: "30px !important",
  textTransform: "none !important",
};

const useStyles = makeStyles(() => ({
  ModalContainer: {
      transform: 'translate(-50%, -50%)',
      fontFamily: 'Poppins, sans-serif',
      backgroundColor: 'white',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '1.5rem 1rem',
      alignItems: 'center',
      position: 'absolute',
      borderRadius: '10px',
      textAlign: 'center',
      display: 'flex',
      minWidth: '600px',
      left: '50%',
      top: '50%',
      '&:focus-visible': {
          outline: 'none !important'
      }
  },
  IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
  },
  OutlineButton: {
    ...BUTTON_COMMON_STYLES,
    border: "1px solid #D1D1D1 !important",
    color: "#041C32 !important",
    "&:hover": {
      backgroundColor: "#FFF !important",
      color: "#041C32 !important",  
    },  
  },
  RedButton: {
    ...BUTTON_COMMON_STYLES,
    border: "1px solid #FB3F4A !important",
    backgroundColor: "#FB3F4A !important",
    color: "#FFF !important",
    "&:hover": {
      backgroundColor: "#FB3F4A !important",
      color: "#FFF !important",  
    },  
  },
  BlueButton: {
    ...BUTTON_COMMON_STYLES,
    border: "1px solid #0B4762 !important",
    backgroundColor: "#0B4762 !important",
    color: "#FFF !important",
  },
}));

export const ModalModeTurnos = {
  DELETE_SHIFT: 'delete_shift',
  EDIT_SHIFT: 'edit_shift',
  DEACTIVATE_WAIT_LIST: 'deactivate_wait_list',
  EXIT_WITHOUT_SAVING: 'exit_without_saving',
  DISABLE_SHIFT: 'disable_shift',
  DELETE_SUBSHIFT: 'delete_subshift',
}

export const WarningPopup = ({ type, iconsSize = 83, onclickDelete, onclickBack, onclickConfirm, onCancel, children, open = true }) => {
  const classes = useStyles();

  const GetRedButtonText = (type) => {
    switch (type) {
      case ModalModeTurnos.DELETE_SHIFT:
      case ModalModeTurnos.EDIT_SHIFT:
      case ModalModeTurnos.DISABLE_SHIFT:
        return "Eliminar reservas"; 
      case ModalModeTurnos.DELETE_SUBSHIFT:
        return "Eliminar horario";
      case ModalModeTurnos.DEACTIVATE_WAIT_LIST:
        return "Desactivar"; 
      default:
        break;
    }
  }
  
  const GetBlueButtonText = (type) => {
    switch (type) {
      case ModalModeTurnos.EDIT_SHIFT:        
      case ModalModeTurnos.DELETE_SHIFT:
      case ModalModeTurnos.DISABLE_SHIFT:
        return "Conservar reservas";      
      case ModalModeTurnos.EXIT_WITHOUT_SAVING:        
        return "Guardar cambios";  
      default:
        break;
    }
  }  

  const GetOutlineButtonText = (type) => {
    switch (type) {
      case ModalModeTurnos.DEACTIVATE_WAIT_LIST:
      case ModalModeTurnos.DELETE_SUBSHIFT:
        return "Volver";    
      case ModalModeTurnos.EXIT_WITHOUT_SAVING:        
        return "Continuar sin guardar";  
      default:
        break;
    }
  } 

  const GetTitle = () => {
    switch (type) {
      case ModalModeTurnos.EDIT_SHIFT:
      case ModalModeTurnos.DELETE_SHIFT:
      case ModalModeTurnos.DISABLE_SHIFT:
        return 'Aplicar cambio';
      case ModalModeTurnos.DELETE_SUBSHIFT:
        return 'Estas eliminando un subturno';
      case ModalModeTurnos.DEACTIVATE_WAIT_LIST:
        return "Estás desactivando la lista de espera";    
      case ModalModeTurnos.EXIT_WITHOUT_SAVING:        
        return "Tenés cambios sin guardar";  
      default:
        break;
    }
  }

  const GetSubtitle = () => {
    switch (type) {
      case ModalModeTurnos.EDIT_SHIFT:
      case ModalModeTurnos.DELETE_SHIFT:
      case ModalModeTurnos.DISABLE_SHIFT:
        return 'Puede que algunas de tus reservas sean afectadas por los cambios realizados. ¿Qué deseas hacer con ellas?';
      case ModalModeTurnos.DELETE_SUBSHIFT:
        return 'Tené en cuenta que se va a eliminar este horario y toda su configuración';
      case ModalModeTurnos.DEACTIVATE_WAIT_LIST:
        return "Tené en cuenta que si la desactivas, se eliminarán todas las listas de espera activas para este turno";    
      case ModalModeTurnos.EXIT_WITHOUT_SAVING:        
        return "¿Seguro que querés continuar?";  
      default:
        break;
    }
  }

  return (
    <Modal open={ open }>
      <Box className={classes.ModalContainer}>
        <div className={classes.IconCross} onClick={onCancel}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
            </svg>
        </div> 
        <Box display="flex" justifyContent="center">
          <WarningIcon size={iconsSize} />
        </Box>
        <Box
          mt="16px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
        >
          <Typography
            fontSize="1.4rem"
            color="#041C32"
            fontWeight="600"
            fontFamily="Poppins"
          >
            {GetTitle()}
          </Typography>
          <Typography
            fontSize=".9rem"
            color="#808080"
            fontWeight="400"
            fontFamily="Poppins"
            marginTop={'8px'}
            align="center"
          >
            {GetSubtitle()}
          </Typography>
        </Box>
        <Content>
          {children}  
        </Content>        
        <ButtonsCointainer>
          {
            type === ModalModeTurnos.DELETE_SUBSHIFT || type === ModalModeTurnos.DEACTIVATE_WAIT_LIST || type === ModalModeTurnos.EXIT_WITHOUT_SAVING ? 
              <OutlineButton text={GetOutlineButtonText(type)} onClick={onclickBack} /> 
            : 
              null
          }
          {
            type === ModalModeTurnos.DELETE_SHIFT || type === ModalModeTurnos.EDIT_SHIFT || type === ModalModeTurnos.EXIT_WITHOUT_SAVING || type === ModalModeTurnos.DISABLE_SHIFT  ? 
              <BlueButton text={GetBlueButtonText(type)} onClick={onclickConfirm} /> 
            : 
              null
          }
          {
            type === ModalModeTurnos.DELETE_SUBSHIFT || type === ModalModeTurnos.DELETE_SHIFT || type === ModalModeTurnos.EDIT_SHIFT || type === ModalModeTurnos.DEACTIVATE_WAIT_LIST || type === ModalModeTurnos.DISABLE_SHIFT ? 
              <RedButton text={GetRedButtonText(type)} onClick={onclickDelete} /> 
            : 
              null
          }
        </ButtonsCointainer>
      </Box>
    </Modal>
  );
};

const Content = ({ children }) => (
  <Box
    mt="20px"
    display="flex"
    justifyContent="center"
    flexDirection="column"
    textAlign="center"
    height='100px'
  >
    {children}
  </Box>
);

const RedButton = ({ text, onClick, sx }) => {
  const classes = useStyles();
  return (
    <Button className={classes.RedButton} onClick={onClick} sx={{ ...sx }}>
      {text}
    </Button>
  );
};

const OutlineButton = ({ text, onClick, sx }) => {
  const classes = useStyles();
  return (
    <Button className={classes.OutlineButton} onClick={onClick} sx={{ ...sx }}>
      {text}
    </Button>
  );
};

const BlueButton = ({ text, onClick, sx }) => {
  const classes = useStyles();
  return (
    <Button className={classes.BlueButton} onClick={onClick} sx={{ ...sx }}>
      {text}
    </Button>
  );
};

const ButtonsCointainer = ({ children }) => (
  <Box
    position="absolute"
    bottom="35px"
    left={0}
    right={0}
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    gap="25px" 
    // marginLeft={'8px'}   
    // marginRight={'8px'}
  >
    {children}
  </Box>
);

WarningPopup.RedButton = RedButton;
WarningPopup.OutlineButton = OutlineButton;
WarningPopup.BlueButton = BlueButton;
WarningPopup.ButtonsCointainer = ButtonsCointainer;
WarningPopup.Content = Content;

export default WarningPopup;
