export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const GENERAL_INFORMATION_ROUTE = "/informacion-general";
export const MENU_WOKI_ROUTE = "/carta-woki";
export const MENU_WEB_ROUTE = "/carta-web";
export const SHARED_MENU_ROUTE = "/sharepartner";
export const PHOTOS_ROUTE = "/fotos";
export const FILTERS_ROUTE = "/filtros";
export const ADDITIONAL_INFORMATION_ROUTE = "/informacion-adicional";

export const SECTORS_ROUTE = "/sectors";
export const MY_CLIENTS_ROUTE = "/mesaya/clientes";
export const MY_STATISTICS_ROUTE = "/proximamente";
export const MY_CONFIGURATION_ROUTE = "/mesaya/configuracion";

export const BOOKING_SHIFTS_ROUTE = "/reservas";
export const BOOKING_CLIENTS_ROUTE = "/reservas/clientes";
export const BOOKING_STATISTICS_ROUTE = "/proximamente";

export const NOTIFICATION_ROUTE = "/proximamente";
export const MESSAGES_ROUTE = "/mensajes-clientes";

export const OPENING_ROUTE = "/horarios";
export const CLOSE_PERIODS_ROUTE = "/proximamente";
export const DAY_SCHEDULE_ROUTE = "/proximamente";

export const MY_PLAN_ROUTE = "/finanzas";
export const STATISTICS_ROUTE = "/proximamente";
export const MARKETING_ROUTE = "/proximamente";
export const DISCOUNT_ROUTE = "/discount";
export const HELP_ROUTE = "/help";

export const QR_ROUTE = "/qr";

export const USERS_ROUTE = "/usuarios";
export const USER_MYINVOICES_ROUTE = "/facturacion";
export const USER_ACCOUNTS_ROUTE = "/cuentas";
export const CLIENTS_STATISTICS_ROUTE = "/clientes/estadisticas";
export const USER_ACCOUNT_ROUTE = "/cuenta";
export const COMING_SOON_ROUTE = "/proximamente";
export const MY_BOOKINGS_ROUTE = "/mis-reservas";
export const MY_CLIENTS2_ROUTE = "/mis-clientes";

export const OPTIONAL_ROUTE = '/optional';

export const BOOKING_POLITICS_ROUTE = '/booking-politics';
export const CANCEL_POLITICS_ROUTE = '/cancel-politics';

/** V3 */
export const SECTORS_ROUTE_V3 = "/v3/sectors";
export const SHIFTS_ROUTE_V3 = "/v3/shifts";
