import {
    CLOSE_ERROR_DIALOG,
    HOME_DATA_LOADED,
    HOME_DATA_LOADING_ERROR,
    LOADING_HOME_DATA
} from "../../constants/homeConstants";
import axios from "axios";

export const loadingHomeData = () => ({
    type: LOADING_HOME_DATA,
})

export const homeDataLoaded = (data) => ({
    type: HOME_DATA_LOADED,
    payload: data
})

export const homeDataLoadingError = (error) => ({
    type: HOME_DATA_LOADING_ERROR,
    payload: error
})

export const loadHomeData = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { homeReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {link} = homeReducer;
        try {
            if (link === '') {
                dispatch(loadingHomeData())

                const API_URL = process.env.REACT_APP_API_URL;
                const auth = getFirebase().auth();
                const token = await auth.currentUser.getIdToken(true);
                const res = await axios.get(`${API_URL}/establishment/dynamicLink/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
                const {data} = res;
                dispatch(homeDataLoaded(data));
            }
        } catch (error) {
            console.log(error);
            if (error.response?.data) {
                dispatch(homeDataLoadingError(error.response.data));
            } else {
                dispatch(homeDataLoadingError(error.message));
            }
        }
    }
}
