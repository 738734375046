export const GeneralContainerStyles = {
    padding: '0 .5rem .5rem .5rem',
    border: '1px solid #D1D1D1',
    borderRadius: '0.6rem',
    background: '#FFF',
    maxWidth: '80rem',
    mb: '.8rem'
};

export const PlanningDataContainerStyles = {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
    mt: '1rem'
};

export const PlanningGreyDataContainerStyles = {
    alignItems: 'center',
    m: '.5rem 0 .8rem 0',
    display: 'flex',
    gap: '5px',
};

export const PlanningNameStyles = {
    fontFamily: 'Poppins',
    width: 'max-content',
    fontSize: '1.1rem',
    color: '#041C32',
    fontWeight: 500,
    '@media (min-width: 1500px)': {
        fontSize: '1.2rem',
    } 
};

export const PlanningHoursStyles = {
    fontFamily: 'Poppins',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '.9rem',
    flexWrap: 'wrap',
    color: '#041C32',
    fontWeight: 400,
    display: 'flex',
    '@media (min-width: 1500px)': {
        fontSize: '1.1rem',
    } 
};

export const PlanningHours2Styles = {
    fontFamily: 'Poppins',
    width: 'max-content',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '.9rem',
    color: '#041C32',
    fontWeight: 400,
    display: 'flex',
    '@media (min-width: 1500px)': {
        fontSize: '1.1rem',
    }  
};

export const PlanningDaysTextStyles = {
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#041C32',
    fontWeight: 400,
    mr: '.5rem'
};

export const DaysContainerStyles = {
    alignItems: 'center',
    display: 'flex',
};

export const PlanningTitleDescriptionTextStyles = {
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#818181',
    fontWeight: 400,
    float: 'left',
};

export const PlanningDescriptionTextStyles = {
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#818181',
    fontWeight: 400,
};