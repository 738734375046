import {
    ADD_MENU_ITEM,
    ADD_PAYMENT_METHOD,
    CHANGE_RESTO_ABOUT_US,
    CHANGE_RESTO_ADDRESS,
    CHANGE_RESTO_CITY,
    CHANGE_RESTO_COUNTRY,
    CHANGE_RESTO_DESCRIPTION,
    CHANGE_RESTO_NAME,
    CHANGE_RESTO_PRICE_LEVEL,
    CHANGE_RESTO_PROVINCE,
    CHANGE_RESTO_ZIP_CODE,
    EDIT_GENERAL_INFO_ERROR,
    EDIT_GENERAL_INFO_SUCCESS,
    FETCH_GENERAL_INFO_ERROR,
    FETCH_GENERAL_INFO_SUCCESS,
    PARTNERS,
    REMOVE_MENU_ITEM,
    REMOVE_PAYMENT_METHOD,
    START_FETCH_GENERAL_INFO,
    TOGGLE_GENERAL_INFO_LOADING,
} from "../../constants";
import {arrayEquals} from "../../utils/arrayHelpers";
import axios from "axios";

export const editGeneralInfoSuccess = () => ({
    type: EDIT_GENERAL_INFO_SUCCESS,
});
export const toggleGeneralInfoLoading = () => ({
    type: TOGGLE_GENERAL_INFO_LOADING,
});
export const editGeneralInfoError = (data) => ({
    type: EDIT_GENERAL_INFO_ERROR,
    payload: data,
});
export const changeRestoName = (data) => ({
    type: CHANGE_RESTO_NAME,
    payload: data,
});
export const changeRestoAboutUs = (data) => ({
    type: CHANGE_RESTO_ABOUT_US,
    payload: data,
});
export const changeRestoDescription = (data) => ({
    type: CHANGE_RESTO_DESCRIPTION,
    payload: data,
});
export const changeRestoAddress = (data) => ({
    type: CHANGE_RESTO_ADDRESS,
    payload: data,
});
export const changeRestoZipcode = (data) => ({
    type: CHANGE_RESTO_ZIP_CODE,
    payload: data,
});
export const changeRestoCity = (data) => ({
    type: CHANGE_RESTO_CITY,
    payload: data,
});
export const changeRestoProvince = (data) => ({
    type: CHANGE_RESTO_PROVINCE,
    payload: data,
});
export const changeRestoCountry = (data) => ({
    type: CHANGE_RESTO_COUNTRY,
    payload: data,
});
export const changeRestoPricelevel = (data) => ({
    type: CHANGE_RESTO_PRICE_LEVEL,
    payload: data,
});

export const addMenuItem = (data) => ({
    type: ADD_MENU_ITEM,
    payload: data,
});

export const removeMenuItem = (data) => ({
    type: REMOVE_MENU_ITEM,
    payload: data,
});

export const addPaymentMethod = (data) => ({
    type: ADD_PAYMENT_METHOD,
    payload: data,
});
export const removePaymentMethod = (data) => ({
    type: REMOVE_PAYMENT_METHOD,
    payload: data,
});


export const startFetchGeneralInfo = () => ({
    type: START_FETCH_GENERAL_INFO,
});

export const fetchGeneralInfoSuccess = (data) => ({
    type: FETCH_GENERAL_INFO_SUCCESS,
    payload: data,
});

export const fetchGeneralInfoError = (data) => ({
    type: FETCH_GENERAL_INFO_ERROR,
    payload: data,
});


export const fetchGeneralInfo = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        // const { profile } = firebase;
        const { idPartnerSelected } = editReducer;

        try {
            ensurePartnerExist( idPartnerSelected )
            dispatch(startFetchGeneralInfo());

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            const res = await axios.get(`${API_URL}/establishment/general-info/${idPartnerSelected}`, {headers: {Authorization: `Bearer ${token}`}})
            const { data } = res;

            dispatch( fetchGeneralInfoSuccess(data) );

        } catch (error) {
            console.log(error);
            dispatch(fetchGeneralInfoError(error));
        }
    }
}
function ensurePartnerExist(partner) {
    if (!partner) {
        throw new Error('Partner not found');
    }
}

export const submitGeneralInfoChanges = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { generalInfoReducer, firestore, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { editReducer } = generalInfoReducer;
        const { ordered } = firestore;
        const originalPartner = ordered[PARTNERS][0];

        const {
            restoName,
            restoAboutUs,
            restoDescription,
            restoAddress,
            zipCode,
            city,
            province,
            country,
            priceLevel,
            menus,
            paymentMethods,
        } = editReducer;

        dispatch(toggleGeneralInfoLoading());

        let updateData = {};

        if (restoName !== originalPartner["displayName"] && restoName.trim() !== "")
            updateData["displayName"] = restoName.trim();
        if (restoAboutUs.trim() !== originalPartner["aboutUs"])
            updateData["aboutUs"] = restoAboutUs.trim();
        if (restoDescription.trim() !== originalPartner["description"])
            updateData["description"] = restoDescription.trim();
        if (restoAddress.trim() !== originalPartner["address"])
            updateData["address"] = restoAddress.trim();
        if (zipCode.trim() !== originalPartner["zipCode"]) updateData["zipCode"] = zipCode.trim();
        if (city.trim() !== originalPartner["city"]) updateData["city"] = city.trim();
        if (province.trim() !== originalPartner["province"])
            updateData["province"] = province.trim();
        if (country.trim() !== originalPartner["country"]) updateData["country"] = country.trim();
        if (priceLevel !== originalPartner["price_level"])
            updateData["price_level"] = priceLevel;

        if (JSON.stringify(menus) !== JSON.stringify(originalPartner["menus"]))
            updateData["menus"] = menus;
        if (!arrayEquals(paymentMethods, originalPartner["paymentMethods"]))
            updateData["paymentMethods"] = paymentMethods;

        if ( idPartnerSelected ) {
            try {
                const API_URL = process.env.REACT_APP_API_URL;
                const auth = getFirebase().auth();
                const token = await auth.currentUser.getIdToken(true);
                const res = await axios.patch(`${API_URL}/establishment/general-info/${ idPartnerSelected }`, updateData, {headers: {Authorization: `Bearer ${token}`}});
                if (res.status > 300) {
                    throw new Error(res.data);
                }
                dispatch(editGeneralInfoSuccess());
            } catch (error) {
                console.log(error);
                dispatch(editGeneralInfoError(error));
            }
            dispatch(editGeneralInfoSuccess());
        } else {
            dispatch(
                editGeneralInfoError("El usuario no tiene un partner asociado.")
            );
        }
    };
};
