import React from 'react';

// Icons
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';

// Styles
import { DateButtonContainerStyles, DateButtonCrossStyles, DateButtonTextStyles } from '../Styles';
import moment from 'moment';

const DateButton = ({ date, onDelete }) => {
    return (
        <Box sx={ DateButtonContainerStyles } >
            <Typography sx={ DateButtonTextStyles } >
                { date.length !== 2 ? date : `${ moment(date[0]).format('DD/MM/YYYY') } - ${ moment(date[1]).format('DD/MM/YYYY') }` }
            </Typography>
            <IconButton sx={{ p: '.2rem' }} onClick={ onDelete } >
                <ClearRoundedIcon sx={ DateButtonCrossStyles } />
            </IconButton>
        </Box>
    )
}

export default DateButton;