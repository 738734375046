import React from 'react'

const orange = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 55.715 55.715">
                <g id="link" transform="translate(-10.341 31.841) rotate(-45)">
                    <path id="Trazado_648" data-name="Trazado 648" d="M23.342,12.375H14.069a11.819,11.819,0,0,0,0,23.638H25.888A11.819,11.819,0,0,0,37.036,20.254H33.767a3.831,3.831,0,0,0-.986.123,7.879,7.879,0,0,1-6.893,11.7H14.069a7.879,7.879,0,1,1,0-15.758h6.048a15.787,15.787,0,0,1,3.23-3.94Z" transform="translate(0 0)" fill="#ff8f1f"/>
                    <path id="Trazado_649" data-name="Trazado 649" d="M25.321,12.375A11.819,11.819,0,0,0,14.173,28.133H18.5a7.879,7.879,0,0,1,6.822-11.819H37.14a7.879,7.879,0,1,1,0,15.758H31.092a15.837,15.837,0,0,1-3.23,3.94H37.14a11.819,11.819,0,0,0,0-23.638Z" transform="translate(8.446)" fill="#ff8f1f"/>
                </g>
            </svg>
        </div>
    )
}

export default orange
