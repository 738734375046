import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import 'moment/locale/en-gb';
import dayjs from 'dayjs';

// Actions
import { setPlanningRangeHours } from '../../../actions';

// Material Ui
import { Box, Collapse, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styles
import { CheckedStyle } from '../Styles';

const StartPlanningReapeatComponent = ( props ) => {

    // Actions
    const { setPlanningRangeHours } = props;

    // States
    const { openningHoursForm } = props;

    const [value, setValue] = useState( openningHoursForm.repeatStart !== null ? dayjs( openningHoursForm.repeatStart ) : dayjs( Date.now() ) );
    const [optionAhora, setOptionAhora] = useState( 
        dayjs( openningHoursForm.repeatStart ).format('DD/MM/YYYY') === dayjs( new Date() ).format('DD/MM/YYYY') ? 'ahora' : 'el' 
    );

    const handleChange = ( event ) => {
        if ( event.target.value === 'ahora' ) {
            setValue( dayjs( Date.now() ) );
        } 

        setOptionAhora( event.target.value );
    };

    const TextFieldStyles = {
        width: '165px',
        '& .MuiInputBase-root': {
            fontFamily: 'Poppins !important',
            borderRadius: '.5rem',
            fontSize: '.9rem',
            fontWeight: 500,
            color: '#041C32'
        },
        '& .MuiInputBase-input': {
            padding: '10px 8px 10px 14px'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.4rem',
            color: '#041C32',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0B4762 !important',
        }
    };

    useEffect(() => {
        if ( optionAhora === 'ahora' ) {
            setPlanningRangeHours({
                ...openningHoursForm,
                repeatStart: dayjs( Date.now() )
            });
        } else {
            setPlanningRangeHours({
                ...openningHoursForm,
                repeatStart: value 
            });
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ optionAhora, value ]);

    return (
        <Box sx={{ padding: '1rem 0' }}>
            <Typography 
                sx={{ 
                    fontFamily: 'Poppins', 
                    m: '.5rem 0 .8rem 0',
                    fontSize: '1.1rem', 
                    color: '#041C32', 
                    fontWeight: 500,
                }}
            >
                Comienza
            </Typography>
            <Box>
                <RadioGroup
                    onChange={ handleChange }
                    value={ optionAhora }
                    name="radio-ahora"
                >
                    <Stack direction="row" spacing={2}>
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='ahora' 
                            label={
                                <Typography 
                                    sx={{ 
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500 
                                    }}
                                >Ahora</Typography>
                            } 
                        />
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='el' 
                            label={
                                <Typography 
                                    sx={{ 
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500 
                                    }}
                                >El</Typography>
                            } 
                        />
                        <Collapse in={ optionAhora !== 'ahora' } orientation="horizontal" >
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale="en-gb">
                                <DatePicker
                                    renderInput={ ( params ) => <TextField {...params} /> }
                                    disabled={ optionAhora === 'ahora' ? true : false }
                                    onChange={ ( newValue ) => setValue(newValue) }
                                    minDate={ dayjs( new Date() ) }
                                    sx={ TextFieldStyles }
                                    format='DD/MM/YYYY'
                                    value={ value }
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiPickersCalendarHeader-label': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersYear-yearButton': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiTypography-root': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersDay-root': {
                                                    fontFamily: 'Poppins !important',
                                                }
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Collapse>
                    </Stack>
                </RadioGroup>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        openningHoursForm: state.planningReducer.openningHoursForm,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setPlanningRangeHours: ( data ) => dispatch( setPlanningRangeHours( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( StartPlanningReapeatComponent );
