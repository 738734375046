import React from 'react';
import {ClickAwayListener, Grid, Grow, makeStyles, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
    },
    text: {
        fontSize: '9px',
        margin: '0px',
        textTransform: 'uppercase',
        marginBottom: '5px'
    },
    dropdownContainer: {
        width: '200px',
        height: '30px',
        background: 'white',
        border: '1px solid #D0D0D0',
        borderRadius: '5px',
    },
    iconContainer: {
        textAlign: 'center',
        borderLeft: '1px solid #D0D0D0',
        height: '24px',
        marginTop: '2px',
        cursor: 'pointer'
    }
}));

const CountriesDropdown = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const {options, value} = props

    const {onChange} = props

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, key) => {

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (key){
            onChange(key)
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <p className={classes.text}>{props.name}</p>
            <div className={classes.dropdownContainer} ref={anchorRef}>
                <Grid container>
                    <Grid item xs={10} style={{padding: '3px 0px 0px 15px'}}>
                        {value}
                    </Grid>
                    <Grid item xs={2}>
                        <div
                            className={classes.iconContainer}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon style={{color: '#848282'}}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Popper style={{width: '14em'}} open={open} anchorEl={anchorRef.current} role={undefined} transition
                    disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {
                                        Object.keys(options).map((key) => (
                                            <MenuItem key={key} onClick={e => handleClose(e, key)}>{options[key]}</MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default CountriesDropdown
