import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Actions
import {
  changePrimaryTitleAppBar,
  changeShowSidebar,
  changeTitleAppBar,
} from "../../../actions/sidebarActions";
import { fetchSectors } from "../../../actions/shiftsActions";

// Components
import ProgressIndicator from "../../../components/GeneralComponents/ProgressIndicator";
import ShiftsCreationPage from "./ShiftsCreationPage";
import ShiftsViewPage from "./ShiftsViewPage";

// Constants
import { SHIFTS_VIEW_MODE } from "../../../constants/shiftsConstants";

const HEADER_PROPS = {
  VIEW: {
    title: "Mis turnos",
    subtitle: "Editá y creá los turnos de tu establecimiento",
  },
  NEW: {
    title: "Crear turno",
    subtitle: " Creá los turnos de tu establecimiento",
  },
  EDIT: {
    title: "Editar turno",
    subtitle: " Editá los turnos de tu establecimiento",
  },
};

const PAGES = {
  [SHIFTS_VIEW_MODE.VIEW]: ShiftsViewPage,
  [SHIFTS_VIEW_MODE.NEW]: ShiftsCreationPage,
  [SHIFTS_VIEW_MODE.EDIT]: ShiftsCreationPage,
};

const ShiftsPage = (props) => {
  const [search, setSearch] = useState(true);
  // Variables
  const {
    idPartnerSelected,
    viewMode,  
    loading,
  } = props;

  // Actions
  const {    
    changeTitleAppBar,
    changePrimaryTitleAppBar,
    changeShowSidebar,
    fetchSectors,
  } = props;

  useEffect(() => {
    const { title, subtitle } = HEADER_PROPS[viewMode];
    changeTitleAppBar(title);
    changeShowSidebar(true);
    changePrimaryTitleAppBar(subtitle);
  }, [
    viewMode,
    changeTitleAppBar,
    changePrimaryTitleAppBar,
    changeShowSidebar,
  ]);

  useEffect(() => {
    if ( idPartnerSelected ) {
      fetchSectors();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ idPartnerSelected ]);
  
  
  useEffect(() => {
    if (idPartnerSelected) {

      if ( search ) {
        setSearch( false );
      };

    };    
  },[ search, idPartnerSelected ]);    
  
  const Page = PAGES[viewMode];

  // const discardChanges = () => {
  //   return true;
  // }

  return <>
    <Page />
    <ProgressIndicator open={loading}/>
  </>;
};

const mapStateToProps = (state) => {
  return {
    idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
    viewMode: state.v3.shifts.viewMode,
    loading: state.v3.shifts.loading,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
    changePrimaryTitleAppBar: (data) =>
      dispatch(changePrimaryTitleAppBar(data)),
    changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    fetchSectors: () => dispatch(fetchSectors()),    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsPage);
