import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    Root: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#0B4762',
        margin: '0 .4rem .2rem 0',
        borderRadius: '30px',
        textAlign: 'center',
        minWidth: '105px',
        padding: '.3rem',
        height: 'auto',
        ['@media (max-width: 1600px)']: {
            marginRight: '.5rem',
        },
    },
    Title: {
        fontSize: '.9rem',
        fontWeight: '600',
        color: 'white',
        margin: '0',
    },
    Hours: {
        margin: '-.2rem 0 0 0',
        color: 'white',
    },
    Span: {
        fontWeight: '400',
        fontSize: '10px'
    }
}));

const SubturnosDetails = (props) => {

    const classes = useStyles();

    const {nro, openTime, closeTime, fix} = props

    return (
        <div key={nro}>
            {!fix ?
                <div className={classes.Root}>
                    <h4 className={classes.Title}>Subturno {nro}</h4>
                    <p className={classes.Hours}><span className={classes.Span}>{openTime}</span> - <span
                        className={classes.Span}>{closeTime}</span></p>
                </div>
                :
                <div className={classes.Root} style={{ marginTop: '0px'}}>
                    <h4 className={classes.Title}>Subturno {nro}</h4>
                    <p className={classes.Hours}><span className={classes.Span}>{openTime}</span> - <span className={classes.Span}>{closeTime}</span></p>
                </div>
                }
        </div>
    )
}

export default SubturnosDetails
