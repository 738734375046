import { FILTER_SELECTED, SHOW_SIDEBAR, SIDEBAR_BOOKING_ID, SORT_TYPE } from "../../constants/myBookingsConstants";

const initState = {
    showSidebar: false,
    sidebarBookingId: '',
    filterSelected: {
        country: '',
        sector: '',
        origin: '',
        shift: '',
    },
    filterCounter: 0,
    sortType: '',
}

const myBookingsReducer = ( state = initState, action ) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return { ...state, showSidebar: action.payload }
        case SIDEBAR_BOOKING_ID:
            return { ...state, sidebarBookingId: action.payload }
        case FILTER_SELECTED:
            const diff = Object.values( action.payload ).reduce( (prev, curr) => ( curr !== '' && curr !== 'Sin filtro' ) ? prev + 1 : prev, 0 )  
            return { 
                ...state, 
                filterSelected: action.payload, 
                filterCounter: diff 
            }
        case SORT_TYPE:
            return { ...state, sortType: action.payload }
        default:
            return state;
    }
}

export default myBookingsReducer;