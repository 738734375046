import React from "react";
import {connect} from "react-redux";

// Actions
import {changeSectorAlias, changeSectorMaxGroup, changeSectorMode, changeSectorType} from "../../../actions";

// Components
import InputWithCondition from "../../NewComponents/InputWhitCondition/InputWithCondition";
import FilterButton from "../../GeneralInfo/Info General/FilterButton/FilterButton";
import RedTrashIcon from "../../SvgComponents/icons/TrashIcons/RedTrashIcon";

// Constants
import {stringToType, typeToString} from "../../../constants/sectorsTypes";
import {SECTOR_MODE} from "../../../constants";

// Material Ui
import {Grid} from "@material-ui/core";

// import DeleteIcon from "@material-ui/icons/Delete";
// import {IconButton} from "@mui/material";

const SectorConfigurationForm = (props) => {

    const {sector, mode} = props;
    const {changeSectorName, changeSectorType, changeSectorMode, changeSectorMaxGroup} = props;

    const options = [
        'Salón',
        'Adentro',
        'Cava',
        'Patio',
        'Terraza',
        'Bar',
        'VIP'
    ];

    const maxGroupOptions = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50
    ];

    if (maxGroupOptions.includes(sector.maxGroup) === false) {
        maxGroupOptions.push(sector.maxGroup);
        maxGroupOptions.sort((a, b) => a - b);
    }

    return (
        <Grid container justify={"flex-end"} direction={"row"} spacing={2} style={{paddingRight: '4rem'}}>
            <Grid item xs={12} md={2}>
                <InputWithCondition
                    error={sector.alias === undefined || sector.alias.trim() === ""}
                    onChange={(e) => e.target.value.includes('.') ? null : changeSectorName(e.target.value)}
                    value={sector.alias}
                    id="sector-name"
                    helperText={""}
                    text="Sector"
                    width="100%"
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <FilterButton
                    text={'Tipo de sector'}
                    color={'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'}
                    fontColor={'rgb(128, 128, 128)'}
                    options={options}
                    value={typeToString(sector.type)}
                    onChange={(e) => changeSectorType(stringToType(e.target.value))}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <FilterButton
                    text={'Grupo maximo'}
                    color={'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'}
                    fontColor={'rgb(128, 128, 128)'}
                    options={maxGroupOptions}
                    value={sector.maxGroup}
                    onChange={(e) => changeSectorMaxGroup(e.target.value)}
                />
            </Grid>
            {
                mode === SECTOR_MODE.DELETE || mode === SECTOR_MODE.EDIT
                    ?
                    <div style={{display: 'flex', alignItems: 'center', paddingTop: '.3rem'}}>
                        <RedTrashIcon
                            onClick={() => changeSectorMode({mode: SECTOR_MODE.DELETE, sector: sector})}
                        />
                    </div>
                    :
                    null
            }
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        sector: state.sectorsReducer.sectors.sector,
        mode: state.sectorsReducer.sectors.mode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSectorName: (name) => dispatch(changeSectorAlias(name)),
        changeSectorType: (sectorType) => dispatch(changeSectorType(sectorType)),
        changeSectorMaxGroup: (maxGroup) => dispatch(changeSectorMaxGroup(maxGroup)),
        changeSectorMode: ({mode, sector}) => dispatch(changeSectorMode({mode, sector})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorConfigurationForm);
