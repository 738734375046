import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowDownPrimary';
import ArrowUpPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowUpPrimary';

const useStyles = makeStyles(() => ({
    Dropdown: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        display: 'inline-block',
        width: '150px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        position: 'absolute',
        zIndex: 2,
        color: (props) => props.disable ? '#AFAFAF' : '#0076FF'
    },
    DropdownBtn: {
        padding: '5px 0px 0px 10px',
        justifyContent: 'space-between',
        display: 'flex',
        color: (props) => props.disable ? '#AFAFAF' : '#0076FF',
        cursor: (props) => props.disable ? 'default' : 'pointer',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    DropdownContent: {
        padding: '0px 10px 10px 10px',
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '0px 0px 20px 20px',
        boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.2)',
        borderTop: 'none',
        transitionDelay: '.9s',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    DropdownItem: {
        padding: '3px 0px',
        cursor: 'pointer',
        transition: 'all .3s',
        color: '#0076FF',
        "&:hover": {
            backgroundColor: '#f4f4f4'
        },
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    name: {
        fontSize: '10px',
        color: '#848282',
        margin: '5px 36px 2px 10px',
        fontFamily: 'Montserrat',
        textAlign: 'right',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
}));

const MinutesDropdown = (props) => {

    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState('31px');
    const [display, setDisplay] = useState('none');
    const [rotate, setRotate] = useState('rotate(0deg)');

    const classes = useStyles(props);

    const handleContent = () => {
        setOpen(!open);
        setHeight('auto');
        setDisplay('block');
        setRotate('rotate(180deg)');

        if (height === "auto" || display === "block" || rotate === 'rotate(180deg)') {
            setHeight('31px');
            setDisplay('none');
            setRotate('rotate(0deg)');
        }
    }

    const {value, disable, options} = props;
    const {onSelect} = props;

    const handleSelect = (value) => {
        onSelect(value);
        handleContent()
    }

    const getValue = () => {
        return options.find(o => o.value === value)?.name;
    }

    console.log('Options --> ', options);

    return (
        <div>
            <div className={classes.Dropdown} style={{height: height, zIndex: open ? 4 : 2}}>
                <div className={classes.DropdownBtn} onClick={() => disable ? null : handleContent()}>
                    <div>{getValue()}</div>
                    <div onClick={() => disable ? null : handleContent()} style={{justifyContent: 'end'}}>
                        {open ?
                            <div style={{paddingRight: '18px'}}>
                                <ArrowUpPrimary 
                                    stroke={disable ? '#AFAFAF' : undefined}
                                    cursor={disable ? 'default' : 'pointer'} width={"20px"}
                                    height={"20px"}
                                />
                            </div>
                            :
                            <div style={{paddingRight: '5px'}}>
                                <ArrowDownPrimary 
                                    stroke={disable ? '#AFAFAF' : undefined}
                                    cursor={disable ? 'default' : 'pointer'} width={"20px"}
                                    height={"20px"}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className={classes.DropdownContent} style={{display: display}}>
                    {options.map(option =>
                        <div 
                            key={option.name} 
                            className={classes.DropdownItem}
                            onClick={() => handleSelect(option.value)}
                        >{option.name}</div>
                    )}
                </div>
            </div>
            <div style={{position: 'relative', zIndex: 1, top: '36px'}}>
                <p className={classes.name}>{props.name}</p>
            </div>
        </div>
    )
}

export default MinutesDropdown
