import React, {useEffect, useRef} from 'react';

// Componenets
import {BlueSwitch} from '../../GeneralComponents/blueSwitch';

// Material Ui
import {Grid, makeStyles} from '@material-ui/core';

// Utils
import {dayToChar} from "../../../utils/formatters";
import {InfoOutlined} from "@material-ui/icons";
import moment from "moment";
import LightTooltip from "../../GeneralComponents/LightTooltip";
import {DisableSwitch} from "../../GeneralComponents/DisableSwitch";

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        borderRadius: '6px',
        margin: '.5rem 0',
        height: '190px',
        width: '230px',
        zIndex: 2,
        position: 'relative',
    },
    TopOptions: {
        padding: '.5rem .5rem .5rem .6rem',
        border: '1px solid #DBD7DC',
        borderRadius: '6px 6px 0 0',
        height: '80%',
    },
    NameContainer: {
        padding: '0px 10px 0px 5px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    Name: {
        fontWeight: '600',
        fontSize: '20px',
        margin: 'auto 0',
    },
    CutleryText: {
        margin: '0 0 1rem 0',
        color: '#808080',
        fontSize: '11px',
    },
    SeeMoreContainer: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#848282',
        fontSize: '12px',
        height: '20%',
    },
    TextsContainer: {
        marginTop: '.3rem'
    },
    SubshiftsContainer: {
        paddingLeft: '5px',
        paddingTop: '2px',
        color: '#0076FF',
        display: 'flex',
    },
    HoursText: {
        margin: '.5rem 0rem 0rem 0rem',
        fontWeight: '400',
        color: '#808080',
        fontSize: '14px',
    },
    DayContainer: {
        justifyContent: 'space-around',
        margin: '0rem .1rem',
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        height: '19px',
        color: 'white',
        width: '19px',
    },
    SeeMore: (props) => ({
        borderRadius: '0 0 6px 6px',
        backgroundColor: props.shift.deleted ? '#808080' : '#0B4762',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: props.shift.deleted ? 'default' : 'pointer',
        color: '#FFFFFF',
        fontSize: '14px',
        display: 'flex',
        height: '100%',
        margin: '0',
    }),
}));

const TurnCard = (props) => {

    const classes = useStyles(props);

    const {shift, cubiertos} = props;
    const [startDate, setStartDate] = React.useState(false);
    const [endDate, setEndDate] = React.useState(false);

    const {onChangeEnable, onClick} = props;
    const elementRef = useRef();

    useEffect(() => {
        if (shift.name.length > 11) {
            const nameElement = elementRef.current;
            nameElement.innerHTML = nameElement.innerHTML.substring(0, 10);
            let addDot = document.createElement('span');
            addDot.innerHTML = "...";
            nameElement.appendChild(addDot);
        }
        if (shift.startDate) {
            setStartDate(moment(shift.startDate).isAfter(moment()))
        }
        if (shift.endDate && shift.deleted) {
            setEndDate(moment(shift.endDate).isAfter(moment()))
        }
    }, [shift]);

    const returnSubshifts = (index) => {
        if (shift.subShifts.length > 1) {
            return (
                <h4 className={classes.HoursText} key={index}>
                    {shift.subShifts[0].from} - {shift.subShifts[0].to} | {shift.subShifts[shift.subShifts.length - 1].from} - {shift.subShifts[shift.subShifts.length - 1].to}
                </h4>
            )
        } else {
            return (
                <h4 className={classes.HoursText} key={index}>
                    {shift.subShifts[0].from} - {shift.subShifts[0].to}
                </h4>
            )
        }
    }

    const getTooltip = () => {
        if (shift.deleted) {
            return "Esta es la fecha en la que se eliminará el turno."
        }
        if (shift.predecessor) {
            return "Esta es la fecha en la cual entrarán en vigencia los cambios programados."
        } else {
            return "Esta es la fecha en la que entrará en vigencia el turno."
        }
    }

    return (
        <div key={shift.uuid} style={{position: 'relative', top: '40px'}}>
            <div
                style={{
                    backgroundColor: shift.deleted ? '#EB252E' : '#0B4762',
                    height: '40px',
                    width: '50%',
                    position: 'absolute',
                    top: '-30px',
                    zIndex: 1,
                    borderRadius: '8px 8px 0 0',
                    display: startDate || endDate ? 'flex' : 'none',
                    justifyContent: 'space-around',
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: '12px'
                }}
            >
                <p style={{
                    color: 'white',
                    marginTop: '8px',
                    textAlign: 'center'
                }}>{shift.deleted ? moment(shift.endDate).format("DD/MM/YY") : moment(shift.startDate).format("DD/MM/YY")}</p>
                <LightTooltip
                    placement='right'
                    title={getTooltip()}
                >
                    <InfoOutlined style={{color: 'white', fontSize: '18px', marginTop: '8px', cursor: 'pointer'}}/>
                </LightTooltip>
            </div>
            <div key={shift.name} className={classes.root}>
                <div className={classes.TopOptions}>
                    <Grid container className={classes.NameContainer}>
                        <Grid item xs={9} style={{textAlign: 'left',}}>
                            <h1
                                className={classes.Name}
                                ref={elementRef}
                                style={{
                                    color: (shift.enable && !shift.deleted) ? '#0B4762' : '#B9B9B9'
                                }}
                            >{shift.name}</h1>
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{marginTop: '-.4rem'}}>
                                {shift.deleted
                                    ? <DisableSwitch checked={shift.enable}
                                                     onChange={null}
                                                     color="default"/>
                                    : <BlueSwitch
                                        checked={shift.enable}
                                        onChange={onChangeEnable} color="default"/>}
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.TextsContainer}>
                        <div className={classes.SubshiftsContainer}>
                            {
                                returnSubshifts()
                            }
                        </div>
                        <div style={{paddingLeft: '5px', paddingTop: '5px'}}>
                            <p className={classes.CutleryText}>Cubiertos máximos reservables: {cubiertos}</p>
                        </div>
                        <div>
                            <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
                                {shift.days?.map((day, index) => (
                                    <Grid key={`letra-${index}`} item>
                                        <div
                                            key={`${index}`}
                                            style={{
                                                backgroundColor: day ? (shift.enable && !shift.deleted) ? '#0B4762' : '#B2B2B2' : (shift.enable && !shift.deleted) ? '#AFAFAF' : '#F0EFEF',
                                                color: day ? (shift.enable && !shift.deleted) ? '#FFFFFF' : '#FFFFFF' : (shift.enable && !shift.deleted) ? '#FFFFFF' : '#BFBBBD'
                                            }}
                                            className={classes.DayContainer}
                                        >
                                            <p style={{margin: '0px', fontSize: '12px'}}>
                                                {
                                                    dayToChar(index)
                                                }
                                            </p>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </div>
                </div>
                <div
                    className={classes.SeeMoreContainer}
                    onClick={() => shift.deleted ? null : onClick()}
                >
                    <p className={classes.SeeMore}>Ver más</p>
                </div>
            </div>
        </div>
    )
}

export default TurnCard
