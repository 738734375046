export const CHANGE_STATISTICS_COUNTRY_SELECTED = 'CHANGE_STATISTICS_COUNTRY_SELECTED';
export const CHANGE_STATISTICS_ROWS_PER_PAGE = 'CHANGE_STATISTICS_ROWS_PER_PAGE';
export const MORE_INFO_FILTERS_SELECTED = 'MORE_INFO_FILTERS_SELECTED';
export const EXPORT_STATISTICS_SUCCESS = 'EXPORT_STATISTICS_SUCCESS';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const CHANGE_STATISTICS_ORDER = 'CHANGE_STATISTICS_ORDER';
export const START_EXPORT_STATISTICS = 'START_EXPORT_STATISTICS';
export const CHANGE_STATISTICS_QUERY = 'CHANGE_STATISTICS_QUERY';
export const EXPORT_STATISTICS_ERROR = 'EXPORT_STATISTICS_ERROR';
export const CHANGE_STATISTICS_DATE = 'CHANGE_STATISTICS_DATE';
export const START_FETCH_STATISTICS = 'START_FETCH_STATISTICS';
export const FETCH_STATISTICS_ERROR = 'FETCH_STATISTICS_ERROR';
export const CHANGE_STATISTICS_PAGE = 'CHANGE_STATISTICS_PAGE';
export const CLOSE_ERROR = 'CLOSE_ERROR';
