import React, { useState } from "react";

import { connect } from "react-redux";

// Actions
import { changeTurnoMaintainBookings } from "../../../actions/shiftsActions";

// Components
import WarningPopup, { ModalModeTurnos } from "../../V3/Shifts/WarningPopup/WarningPopup";
import DatePickerDialog from "./DatePickerDialog";
import EditModal from "./EditModal";

// Constants
import { ModalMode } from "../../../constants/modalMode";

const BookingModal = ( props ) => {

    // Actions
    const { changeTurnoMaintainBookings } = props;

    const { show, type, minDate, advanceStart } = props;

    const [openLastModal, setOpenLastModal] = useState( false );
    const {onFinish, onCancel} = props;

    const [showDateSelectorDialog, setShowDateSelectorDialog] = useState(false);
    const handleCancelChanges = () => {
        setShowDateSelectorDialog(false);
        onCancel();
    };

    const handleShowLastModal = () => {
        setOpenLastModal( !openLastModal );
    };

    const handleContinueWithChanges = ( deleteBookings ) => {
        if ( deleteBookings ) {
            changeTurnoMaintainBookings( false );
        } else {
            changeTurnoMaintainBookings( true );
        };

        onFinish( minDate.toISOString() );
    };

    const handleScheduleChange = () => {
        setShowDateSelectorDialog(true);
    }

    const handleDateSelected = (date) => {
        setShowDateSelectorDialog(false);
        onFinish(date);
    }

    const getTitle = () => {
        if (type === ModalMode.DISABLED) {
            return "Estas deshabilitando un turno";
        }
        if (type === ModalMode.CANCEL_EDITION) {
            return "Estas cancelando una edición";
        }
        if (type === ModalMode.CANCEL_CREATION) {
            return "Estas cancelando la creación de un turno";
        }
        return "¡Atención!";
    }

    return (
        <>
            <EditModal
                onAccept={() => handleShowLastModal()}
                onClick={ handleScheduleChange }
                onCancel={ handleCancelChanges }
                title={ getTitle() }
                open={ show }
                type={ type }
            />
            <DatePickerDialog
                onCancel={ handleCancelChanges }
                onAccept={ handleDateSelected }
                open={ showDateSelectorDialog }
                advanceStart={ advanceStart }
                minDate={ minDate }
                type={ type }
            />
            <WarningPopup 
                onclickConfirm={() => handleContinueWithChanges( false )}
                onclickDelete={() => handleContinueWithChanges( true )}
                // onclickBack={() => handleShowLastModal()}
                type={ ModalModeTurnos.DISABLE_SHIFT }                    
                onCancel={ handleShowLastModal }
                open={ openLastModal }
            /> 
        </>
    )
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        changeTurnoMaintainBookings: ( data ) => dispatch( changeTurnoMaintainBookings( data ) ),
    }
};

export default connect( null, mapDispatchToProps )( BookingModal );
