import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";
import Image from "../../assets/images/not-found.svg";

const NoData = ({
  ButtonIcon,
  buttonOnClick,
  buttonText,
  collection,
  description,
  title,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      textAlign="center"
      position="absolute"
      top="0"
      left="0"
      sx={{ fontFamily: "Poppins !important" }}
    >
      <Box>
        <img
          src={Image}
          alt={`No ${collection}`}
          style={{ width: "200px", marginBottom: "1rem" }}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{
          color: "#0B4762",
          fontSize: "1.5rem",
          fontWeight: 700,
          opacity: 0.7,
          fontFamily: "Poppins",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "#0B4762",
          fontSize: "1rem",
          fontFamily: "Poppins",
          marginTop: "1rem",
          marginBottom: "1rem",
          width: "80vw",
          maxWidth: "300px",
          opacity: 0.7,
        }}
      >
        {description}
      </Typography>

      <Button
        style={{
          marginTop: "2rem",
          minWidth: "11rem",
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "#0B4762",
          textTransform: "none",
          borderRadius: "10px",
          padding: "6px 12px",
          letterSpacing: "0",
          height: "2.5rem",
          color: "white",
        }}
        variant="contained"
        startIcon={ButtonIcon && <ButtonIcon />}
        onClick={buttonOnClick}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

NoData.propTypes = {
  collection: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonOnClick: PropTypes.func.isRequired,
  ButtonIcon: PropTypes.node,
};

export default NoData;
