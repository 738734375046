import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import {Responsive, WidthProvider} from "react-grid-layout";

// Actions
import {
    addCombineTable,
    changeEditingTable,
    changeSectorTableProperties,
    removeCombineTable,
    sectorAddTable,
    sectorNewTable,
    sectorRemoveTable
} from "../../../actions";

// Components
import TableForm from "./TableForm";
import Table from "./Table";

// Material Ui
import {Box} from "@mui/material";

// Styles
import './GridTable.css';
import DeleteTableWithCombination from "./DeleteTableWithCombination";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const TABLE_STATE = {
    EDITING: 0,
    RESERVABLE: 1,
    NO_RESERVABLE: 2,
    SELECCIONADA: 3,
    NO_SELECCIONADA: 4
}

const GridTable = (props) => {
    const {
        tables,
        combineMode,
        newCombineTables,
        editingTable,
        sector,
        sectors,
        isBannerActive
    } = props;

    const {
        showSnackbar,
        changeSectorTableProperties,
        addTable,
        removeTable,
        newTable,
        addCombineTable,
        removeCombineTable,
        changeEditingTable
    } = props;

    const [ignoreClick, setIgnoreClick] = useState(false);
    const [showDeleteTableWithCombinations, setShowDeleteTableWithCombinations] = React.useState(false);


    //Drag Callbacks
    const handleOnDrag = (layout, oldItem, newItem, placeholder, e, element) => {
        setIgnoreClick(true);
    }

    const handleOnDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
        changeSectorTableProperties({tableName: newItem.i, point: {x: newItem.x, y: newItem.y}});
        e.preventDefault();
        e.stopPropagation();
    }

    //Drop Callbacks
    const handleDrop = (layout = [], table = {}, e) => {
        newTable.x = table?.x >= 7 ? table?.x - 7 : table.x ?? 0;
        newTable.y = table?.y >= 1.5 ? table?.y - 1.5 : table?.y ?? 0;
        addTable({...newTable})
    }

    // Table edit
    const handleOnTableClick = (e, table) => {
        e.preventDefault();
        e.stopPropagation();
        if (combineMode) {
            if (getTableState(table) === TABLE_STATE.NO_RESERVABLE) {
                return;
            }
            if (newCombineTables.tables.includes(table.name)) {
                removeCombineTable(table);
            } else {
                addCombineTable(table);
            }
        } else {
            changeEditingTable(table);
        }
    }

    const handleOnCloseTableForm = () => {
        changeEditingTable(undefined);
    }

    const handleOnSaveTable = (alias, minCapacity, maxCapacity, autoAssign, shared) => {
        changeSectorTableProperties({
            tableName: editingTable.name,
            alias: alias,
            minCapacity: minCapacity,
            maxCapacity: maxCapacity,
            autoAssign: autoAssign,
            shared: shared
        });
        handleOnCloseTableForm();
    }

    const handleOnDeleteTable = () => {
        if (editingTable.combinesWith.length > 0) {
            setShowDeleteTableWithCombinations(true);
        } else {
            onDeleteTable();
        }
    }

    const onDeleteTable = () => {
        setShowDeleteTableWithCombinations(false);
        removeTable(editingTable.name);
        handleOnCloseTableForm();
        if (sectors.find(s => s.name === sector.name) !== undefined) {
            showSnackbar();
        }
    }

    // Render Tables
    const getTableState = (table) => {
        if (editingTable && editingTable.name === table.name) {
            return TABLE_STATE.EDITING;
        }
        if (combineMode) {
            if (newCombineTables.tables.includes(table.name)) {
                return TABLE_STATE.SELECCIONADA;
            } else {
                return TABLE_STATE.RESERVABLE;
            }
        } else {
            if (!table.autoAssign) {
                return TABLE_STATE.NO_RESERVABLE
            }
            return TABLE_STATE.DEFAULT;
        }
    }

    const getTableStyle = (table) => {
        const style = {
            borderRadius: table.circle ? "50%" : "10%",
        }
        switch (getTableState(table)) {
            case TABLE_STATE.EDITING:
                return {...style, borderColor: "#0B4762", backgroundColor: "#0B4762", color: "#FFFFFF"};
            case TABLE_STATE.RESERVABLE:
                return {...style, borderColor: "#B2B2B2", backgroundColor: "#FFFFFF", color: "#B2B2B2"};
            case TABLE_STATE.NO_RESERVABLE:
                return {...style, borderColor: "#B2B2B2", backgroundColor: "#DBD7DC", color: "#B2B2B2"};
            case TABLE_STATE.SELECCIONADA:
                return {...style, borderColor: "#0B4762", backgroundColor: "#0B4762", color: "#FFFFFF"};
            case TABLE_STATE.NO_SELECCIONADA:
                return {...style, borderColor: "#B2B2B2", backgroundColor: "#FFFFFF", color: "#B2B2B2"};
            default:
                return {...style, borderColor: "#0B4762"};

        }
    }

    const children = useMemo(() => {
        return tables.map(table => {
            return <Table
                className="react-grid-inner-table"
                key={table.name}
                style={getTableStyle(table)}
                table={table}
                ignoreClick={ignoreClick}
                onTableClick={handleOnTableClick}
                resetignoreclick={() => setIgnoreClick(false)}
                data-grid={{
                    i: table.alias,
                    x: table.point.x,
                    y: table.point.y,
                    w: table.box.width,
                    h: table.box.height,
                    isResizable: table.isResizable,
                    isDraggable: !combineMode
                }}
            />
        });
    }, [tables, editingTable, combineMode, newCombineTables, ignoreClick]);

    return (
        <Box sx={{position: 'relative', width: '98%'}}>
            <div id="sector-grid-layout">
                <ResponsiveReactGridLayout
                    className="layout"
                    cols={{lg: 210, md: 180, sm: 150, xs: 125, xxs: 100}}
                    breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                    rowHeight={15}
                    droppingItem={newTable}
                    draggableHandle=".react-grid-inner-table"
                    compactType={null}
                    preventCollision={true}
                    isDroppable={true}
                    onDrop={handleDrop}
                    onDrag={handleOnDrag}
                    onDragStop={handleOnDragStop}
                >
                    {children}
                </ResponsiveReactGridLayout>
            </div>
            {editingTable
                ? <TableForm
                    open={true}
                    table={editingTable}
                    tables={tables}
                    onClose={handleOnCloseTableForm}
                    onSave={handleOnSaveTable}
                    onDelete={handleOnDeleteTable}
                    isBannerActive={isBannerActive}
                />
                : null
            }
            <DeleteTableWithCombination open={showDeleteTableWithCombinations}
                                        onCancel={() => setShowDeleteTableWithCombinations(false)}
                                        onAccept={() => onDeleteTable()}/>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        tables: state.sectorsReducer.sectors.sector?.tables ?? [],
        sectors: state.sectorsReducer.sectors.sectors ?? [],
        sector: state.sectorsReducer.sectors.sector ?? {},
        newTable: state.sectorsReducer.sectors.newTable,
        editingTable: state.sectorsReducer.sectors.editingTable,
        combineMode: state.sectorsReducer.sectors.combineMode,
        newCombineTables: state.sectorsReducer.sectors.newCombineTables,
        combinedTables: state.sectorsReducer.sectors.combinedTables,
        isBannerActive: !state.sidebarManager.isActive,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        changeSectorTableProperties:
            ({
                 tableName,
                 alias,
                 minCapacity,
                 maxCapacity,
                 autoAssign,
                 box,
                 shared,
                 point
             }) => dispatch(changeSectorTableProperties({
                tableName,
                alias,
                minCapacity,
                maxCapacity,
                autoAssign,
                box,
                shared,
                point
            })),
        addTable: ({i, minCapacity, maxCapacity, x, y, w, h, circle, isResizable}) => dispatch(sectorAddTable({
            i,
            minCapacity,
            maxCapacity,
            x,
            y,
            w,
            h,
            circle,
            isResizable
        })),
        removeTable: (tableName) => dispatch(sectorRemoveTable(tableName)),
        setNewTable: ({minCapacity, maxCapacity, h, w, isResizable}) => dispatch(sectorNewTable({
            minCapacity,
            maxCapacity,
            h,
            w,
            isResizable
        })),
        changeEditingTable: (table) => dispatch(changeEditingTable(table)),
        addCombineTable: (data) => dispatch(addCombineTable(data)),
        removeCombineTable: (data) => dispatch(removeCombineTable(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridTable);
