import React from 'react';
import {Card, CardContent, Grid, Paper, Typography} from "@material-ui/core";
import CurveLineChart from "./Charts/CurveLineChart/CurveLineChart";
import {makeStyles} from "@material-ui/core/styles";
import WokiPrimary from '../SvgComponents/Icons Tables/wokiPrimary';
import Purple from '../SvgComponents/Icons Tables/purple';
import Orange from '../SvgComponents/Icons Tables/orange';
import Help from '../SvgComponents/Icons Tables/Help';

const useStyle = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(0deg, #4E00CC10, #915AFF10)',
        border: 0,
        borderRadius: 20,
        boxShadow: '0 3px 6px 2px rgba(0, 0, 0, .16)',
        fontFamily: 'Montserrat',
        width: '350px'
    },
    paperPercentage: {
        border: 0,
        borderRadius: 20,
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10
    },
    title: {
        fontWeight: 'bold',
        padding: 2,
        margin: '0px'
    },
    statisticTitle: {
        fontWeight: 'lighter',
        color: '#848282',
        padding: 4,
        margin: '0px',
        fontSize: '11px'
    },
    statisticClients: {
        fontWeight: 'bold',
        fontSize: 35,
        padding: 2
    },
    subtitle: {
        margin: '0px',
        color: '#848282',
        fontSize: '13px',
        fontWeight: '500',
        marginTop: '-5px',
        paddingLeft: 2,
        marginBottom: '10px'
    }
}))

const ClientsCard = (props) => {
    const classes = useStyle();
    const {
        title,
        subtitle,
        helperText,
        woki,
        percentageWoki,
        wokiData,
        link,
        percentageLink,
        linkData,
        street,
        percentageStreet,
        streetData
    } = props;

    return (
        <div style={{position: 'relative'}}>
            <Card className={classes.root}>
                <CardContent>
                    <div style={{position: 'relative', height: '60px'}}>
                        <div style={{position: 'absolute'}}>
                            <h3 className={classes.title}>{title}</h3>
                            <p className={classes.subtitle}>{subtitle}</p>
                        </div>
                        <div style={{right: '-15px', position: 'absolute', zIndex: 100, top: '-15px'}}>
                            <Help
                                texts={helperText}/>
                        </div>
                    </div>
                    <Grid container direction={"column"} alignItems={"flex-start"} spacing={1}>
                        <Grid item style={{width: '100%'}}>
                            <StatisticCard id={`clientsWoki-${title}`}
                                           value={woki}
                                           percentage={percentageWoki}
                                           data={wokiData}
                                           title={"A través de Woki"} icon={'woki'}/>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <StatisticCard id={`clientsStreet-${title}`}
                                           value={street}
                                           percentage={percentageStreet}
                                           data={streetData}
                                           icon={'purple'} title={"Agregados manualmente"}
                                           lineColor={'#915AFF'}/>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <StatisticCard id={`clientsLink-${title}`} value={link} percentage={percentageLink}
                                           data={linkData}
                                           icon={'orange'} title={"Agregados por link"} lineColor={'#FF8F1F'}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

const StatisticCard = (props) => {
    const classes = useStyle()
    const {icon, value, percentage, data, title, id, lineColor} = props;

    return (
        <Grid
            container
            justify={'flex-start'}
            direction={'column'}
            alignItems={'flex-start'}
            style={{width: '100%'}}
        >
            <Grid item>
                <Paper style={{height: '35px', borderRadius: '12px 12px 0px 0px', paddingBottom: '5px'}}>
                    <p className={classes.statisticTitle}>{title}</p>
                </Paper>
            </Grid>
            <Grid item style={{width: '100%', marginTop: '-7px'}}>
                <Paper style={{borderRadius: '12px', height: '95px'}}>
                    <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        justify={'flex-start'}
                        spacing={1}
                    >
                        <Grid item xs={2} style={{paddingLeft: '18px', paddingBottom: '45px'}}>
                            {icon === 'woki' && <WokiPrimary width={"47px"} height={"52"}/>}
                            {icon === 'purple' && <Purple width={"47px"} height={"52"}/>}
                            {icon === 'orange' && <Orange width={"47px"} height={"52"}/>}
                        </Grid>
                        <Grid
                            item xs={4}
                            justify={'flex-end'}
                            style={{
                                textAlign: 'center',
                                paddingBottom: '30px',
                                paddingLeft: '10px'
                            }}
                        >
                            <Typography className={classes.statisticClients}>{value}</Typography>
                            <Typography
                                style={{
                                    padding: 2,
                                    color: '#15D615',
                                    fontWeight: '800',
                                    fontSize: '18px'
                                }}
                            >{`${percentage.toFixed(0)}%`}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                padding: '7px 0px',
                                marginLeft: '-10px',
                                borderRadius: '20px',
                                marginTop: '-30px'
                            }}>
                            <CurveLineChart id={id} data={data} lineColor={lineColor}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ClientsCard;