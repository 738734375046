import {
    ADD_MENU_ITEM,
    ADD_PAYMENT_METHOD,
    CHANGE_RESTO_ABOUT_US,
    CHANGE_RESTO_ADDRESS,
    CHANGE_RESTO_CITY,
    CHANGE_RESTO_COUNTRY,
    CHANGE_RESTO_DESCRIPTION,
    CHANGE_RESTO_NAME,
    CHANGE_RESTO_PRICE_LEVEL,
    CHANGE_RESTO_PROVINCE,
    CHANGE_RESTO_ZIP_CODE,
    CLOSE_ERROR_DIALOG,
    EDIT_GENERAL_INFO_ERROR,
    EDIT_GENERAL_INFO_SUCCESS,
    FETCH_GENERAL_INFO_ERROR,
    FETCH_GENERAL_INFO_SUCCESS,
    REMOVE_MENU_ITEM,
    REMOVE_PAYMENT_METHOD,
    RESET_ALL_DATA,
    START_FETCH_GENERAL_INFO,
    TOGGLE_GENERAL_INFO_LOADING,
} from "../../constants";
import {deepEqual} from "../../utils/objectHelpers";


const initState = {
    restoName: "",
    restoAboutUs: "",
    restoDescription: "",
    restoAddress: "",
    zipCode: "",
    city: "",
    province: "",
    country: "",
    priceLevel: 0,
    menus: [],
    paymentMethods: [],

    originalInfo: {},
    edited: false,
    loading: false,
    error: "",
};

const editReducer = (state = initState, action) => {
    const excludeKeys = ["error", "loading", "edited", "originalInfo"];
    let newState = {};
    switch (action.type) {
        case START_FETCH_GENERAL_INFO:
            newState = {
                ...state,
                loading: true,
                error: ""
            };
            break;

        case FETCH_GENERAL_INFO_SUCCESS:
            newState = {
                ...state,
                loading: false,
                edited: false,
                restoName: action.payload.displayName,
                restoAboutUs: action.payload.aboutUs,
                restoDescription: action.payload.description,
                restoAddress: action.payload.address,
                zipCode: action.payload.zipCode,
                city: action.payload.city,
                province: action.payload.province,
                country: action.payload.country,
                priceLevel: action.payload.priceLevel,
                menus: action.payload.menus,
                paymentMethods: action.payload.paymentMethods,
                originalInfo: {
                    restoName: action.payload.displayName,
                    restoAboutUs: action.payload.aboutUs,
                    restoDescription: action.payload.description,
                    restoAddress: action.payload.address,
                    zipCode: action.payload.zipCode,
                    city: action.payload.city,
                    province: action.payload.province,
                    country: action.payload.country,
                    priceLevel: action.payload.priceLevel,
                    menus: action.payload.menus,
                    paymentMethods: action.payload.paymentMethods,
                }
            }
            break;

        case FETCH_GENERAL_INFO_ERROR:
            newState = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;


        case EDIT_GENERAL_INFO_SUCCESS:
            newState = {
                ...state, loading: false, error: "", originalInfo: {
                    restoName: state.restoName,
                    restoAboutUs: state.restoAboutUs,
                    restoDescription: state.restoDescription,
                    restoAddress: state.restoAddress,
                    zipCode: state.zipCode,
                    city: state.city,
                    province: state.province,
                    country: state.country,
                    priceLevel: state.priceLevel,
                    menus: {...state.menus},
                    paymentMethods: [...state.paymentMethods],
                }
            };
            break;
        case EDIT_GENERAL_INFO_ERROR:
            newState = {...state, loading: false, error: action.payload};
            break;
        case TOGGLE_GENERAL_INFO_LOADING:
            newState = {...state, loading: !state.loading};
            break;
        case CLOSE_ERROR_DIALOG:
            newState = {...state, error: ""};
            break;

        case CHANGE_RESTO_NAME:
            newState = {
                ...state,
                restoName: action.payload,
            };
            break;
        case CHANGE_RESTO_ABOUT_US:
            newState = {
                ...state,
                restoAboutUs: action.payload,
            };
            break;
        case CHANGE_RESTO_DESCRIPTION:
            newState = {
                ...state,
                restoDescription: action.payload,
            };
            break;
        case CHANGE_RESTO_ADDRESS:
            newState = {
                ...state,
                restoAddress: action.payload,
            };
            break;
        case CHANGE_RESTO_ZIP_CODE:
            newState = {
                ...state,
                zipCode: action.payload,
            };
            break;
        case CHANGE_RESTO_CITY:
            newState = {
                ...state,
                city: action.payload,
            };
            break;
        case CHANGE_RESTO_PROVINCE:
            newState = {
                ...state,
                province: action.payload,
            };
            break;
        case CHANGE_RESTO_COUNTRY:
            newState = {
                ...state,
                country: action.payload,
            };
            break;
        case CHANGE_RESTO_PRICE_LEVEL:
            newState = {
                ...state,
                priceLevel: action.payload,
            };
            break;
        case ADD_MENU_ITEM:
            let m = {...state.menus};
            m[action.payload.name] = action.payload.url;
            newState = {
                ...state,
                menus: {...m},
            };
            break;
        case REMOVE_MENU_ITEM:
            let rm = {...state.menus};
            delete rm[action.payload];
            newState = {
                ...state,
                menus: {...rm},
            };
            break;

        case ADD_PAYMENT_METHOD:
            newState = {
                ...state,
                paymentMethods: [...state.paymentMethods, action.payload],
            };
            break;
        case REMOVE_PAYMENT_METHOD:
            newState = {
                ...state,
                paymentMethods: state.paymentMethods.filter(v => v !== action.payload),
            };
            break;


        case RESET_ALL_DATA:
            return initState;
        default:
            newState = state;
            break;
    }
    const edited = !deepEqual(newState.originalInfo, newState, excludeKeys);
    return {...newState, edited: edited};
};

export default editReducer;
