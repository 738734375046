export const CREATED = 0
export const EDITED_BY_CLIENT = 1
export const REJECTED = 2
export const EDITED_BY_PARTNER = 3
export const ACCEPTED = 4
export const CONFIRM = 5
export const NO_ARRIVED = 6
export const ARRIVED = 7
export const DELETED_BY_USER = 8
export const DELETED_BY_PARTNER = 9

export const getNameBookingItemState = (state) => {
    switch (state) {
        case CREATED:
            return "Creado";
        case EDITED_BY_CLIENT:
            return "Editado";
        case EDITED_BY_PARTNER:
            return "Editado";
        case ACCEPTED:
            return "Aceptado";
        case CONFIRM:
            return "Confirmado";
        case DELETED_BY_USER:
            return "Cancelado";
        case DELETED_BY_PARTNER:
            return "Eliminado";
        case REJECTED:
            return "Rechazado";
        case ARRIVED:
            return "Ingresado";
        case NO_ARRIVED:
            return "No vino";
        default:
            return "";

    }
}