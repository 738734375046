export const PlanningIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1095 24.7372H4.52632C3.8563 24.7372 3.21372 24.471 2.73994 23.9972C2.26617 23.5235 2 22.8809 2 22.2109V7.05293C2 6.38291 2.26617 5.74033 2.73994 5.26655C3.21372 4.79278 3.8563 4.52661 4.52632 4.52661H19.6843C20.3543 4.52661 20.9969 4.79278 21.4706 5.26655C21.9444 5.74033 22.2106 6.38291 22.2106 7.05293V12.1056" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9472 26.0002C22.2872 26.0002 23.5724 25.4679 24.5199 24.5203C25.4675 23.5727 25.9998 22.2876 25.9998 20.9475C25.9998 19.6075 25.4675 18.3223 24.5199 17.3748C23.5724 16.4272 22.2872 15.8949 20.9472 15.8949C19.6071 15.8949 18.322 16.4272 17.3744 17.3748C16.4269 18.3223 15.8945 19.6075 15.8945 20.9475C15.8945 22.2876 16.4269 23.5727 17.3744 24.5203C18.322 25.4679 19.6071 26.0002 20.9472 26.0002Z" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1582 2V7.05264" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.05273 2V7.05264" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 12.1056H22.2106" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9473 19.0426V20.9424L22.2104 22.2056" stroke={ fill } strokeWidth="2.08696" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
