export const CountryFlagsArray = [
    {
        name: 'Argentina',
        countryCode: 'AR',
        code: '+54',
        emoji: '🇦🇷',
    },
    {
        countryCode: 'BR',
        name: 'Brasil',
        code: '+55',
        emoji: '🇧🇷',
    },
    {
        countryCode: 'EC',
        name: 'Ecuador',
        code: '+593',
        emoji: '🇪🇨',
    },
    {
        countryCode: 'CL',
        name: 'Chile',
        code: '+56',
        emoji: '🇨🇱',
    },
    {
        countryCode: 'UY',
        name: 'Uruguay',
        code: '+598',
        emoji: '🇺🇾',
    },
    {
        countryCode: 'VE',
        name: 'Venezuela',
        code: '+58',
        emoji: '🇻🇪',
    },
    {
        countryCode: 'BO',
        name: 'Bolivia',
        code: '+591',
        emoji: '🇧🇴',
    },
    {
        countryCode: 'PE',
        name: 'Peru',
        code: '+51',
        emoji: '🇵🇪',
    },
    {
        countryCode: 'CO',
        name: 'Colombia',
        code: '+57',
        emoji: '🇨🇴',
    },
    {
        countryCode: 'PY',
        name: 'Paraguay',
        code: '+595',
        emoji: '🇵🇾',
    },
    {
        name: 'Afganistán',
        countryCode: 'AF',
        code: '+93',
        emoji: '🇦🇫',
    },
    {
        countryCode: 'AL',
        name: 'Albania',
        code: '+355',
        emoji: '🇦🇱',
    },
    {
        countryCode: 'DZ',
        name: 'Argelia',
        code: '+213',
        emoji: '🇩🇿',
    },
    // {
    //     name: 'Samoa Americana',
    //     countryCode: 'AS',
    //     code: '+1 684',
    //     emoji: '🇦🇸',
    // },
    {
        countryCode: 'AD',
        name: 'Andorra',
        code: '+376',
        emoji: '🇦🇩',
    },
    {
        countryCode: 'AO',
        name: 'Angola',
        code: '+244',
        emoji: '🇦🇴',
    },
    {
        countryCode: 'AI',
        name: 'Anguila',
        code: '+1 264',
        emoji: '🇦🇮',
    },
    // {
    //     name: "Antigua y Barbuda",
    //     countryCode: 'AG',
    //     code: "+1 268",
    //     emoji: '🇦🇬',
    // },
    {
        countryCode: 'AM',
        name: 'Armenia',
        code: '+374',
        emoji: '🇦🇲',
    },
    {
        countryCode: 'AW',
        name: 'Aruba',
        code: '+297',
        emoji: '🇦🇼',
    },
    {
        countryCode: 'AC',
        name: 'Ascensión',
        code: '+247',
        emoji: '🇦🇨',
    },
    {
        countryCode: 'AU',
        name: 'Australia',
        emoji: '🇦🇺',
        code: '+61',
    },
    {
        countryCode: 'AT',
        name: 'Austria',
        code: '+43',
        emoji: '🇦🇹',
    },
    {
        countryCode: 'AZ',
        name: 'Azerbaiyán',
        code: '+994',
        emoji: '🇦🇿',
    },
    {
        countryCode: 'BS',
        name: 'Bahamas',
        code: '+1 242',
        emoji: '🇧🇸',
    },
    {
        countryCode: 'BH',
        name: 'Baréin',
        code: '+973',
        emoji: '🇧🇭',
    },
    {
        name: 'Bangladesh',
        countryCode: 'BD',
        code: '+880',
        emoji: '🇧🇩',
    },
    {
        countryCode: 'BB',
        name: 'Barbados',
        code: '+1 246',
        emoji: '🇧🇧',
    },
    {
        countryCode: 'BY',
        name: 'Bielorrusia',
        code: '+375',
        emoji: '🇧🇾',
    },
    {
        countryCode: 'BE',
        name: 'Bélgica',
        code: '+32',
        emoji: '🇧🇪',
    },
    {
        countryCode: 'BZ',
        name: 'Belice',
        code: '+501',
        emoji: '🇧🇿',
    },
    {
        countryCode: 'BJ',
        name: 'Benín',
        code: '+229',
        emoji: '🇧🇯',
    },
    {
        countryCode: 'BM',
        name: 'Bermudas',
        code: '+1 441',
        emoji: '🇧🇲',
    },
    {
        countryCode: 'BT',
        name: 'Bután',
        code: '+975',
        emoji: '🇧🇹',
    },
    {
        countryCode: 'BW',
        name: 'Botsuana',
        code: '+267',
        emoji: '🇧🇼',
    },
    // {
    //     name: 'British Indian Ocean Territory',
    //     countryCode: 'IO',
    //     code: '+246',
    //     emoji: '🇮🇴',
    // },
    // {
    //     name: 'Islas Vírgenes Británicas',
    //     countryCode: 'VG',
    //     code: '+1 284',
    //     emoji: '🇻🇬',
    // },
    {
        countryCode: 'BN',
        name: 'Brunéi',
        code: '+673',
        emoji: '🇧🇳',
    },
    {
        countryCode: 'BG',
        name: 'Bulgaria',
        code: '+359',
        emoji: '🇧🇬',
    },
    // {
    //     name: 'Burkina Faso',
    //     countryCode: 'BF',
    //     code: '+226',
    //     emoji: '🇧🇫',
    // },
    // {
    //     countryCode: 'BI',
    //     name: 'Burundi',
    //     code: '+257',
    //     emoji: '🇧🇮',
    // },
    {
        countryCode: 'KH',
        name: 'Camboya',
        code: '+855',
        emoji: '🇰🇭',
    },
    {
        countryCode: 'CM',
        name: 'Camerún',
        code: '+237',
        emoji: '🇨🇲',
    },
    {
        countryCode: 'CA',
        name: 'Canadá',
        emoji: '🇨🇦',
        code: '+1',
    },
    // {
    //     countryCode: 'CV',
    //     name: 'Cape Verde',
    //     code: '+238',
    //     emoji: '🇨🇻',
    // },
    // {
    //     name: 'Cayman Islands',
    //     countryCode: 'KY',
    //     code: '+345',
    //     emoji: '🇰🇾',
    // },
    // {
    //     name: 'Central African Republic',
    //     countryCode: 'CF',
    //     code: '+236',
    //     emoji: '🇨🇫',
    // },
    // {
    //     countryCode: 'TD',
    //     code: '+235',
    //     name: 'Chad',
    //     emoji: '🇹🇩',
    // },
    {
        countryCode: 'CN',
        name: 'China',
        code: '+86',
        emoji: '🇨🇳',
    },
    // {
    //     name: 'Christmas Island',
    //     countryCode: 'CX',
    //     code: '+61',
    //     emoji: '🇨🇽',
    // },
    {
        countryCode: 'KM',
        name: 'Comoros',
        code: '+269',
        emoji: '🇰🇲',
    },
    // {
    //     name: 'Cook Islands',
    //     countryCode: 'CK',
    //     code: '+682',
    //     emoji: '🇨🇰',
    // },
    {
        name: 'Costa Rica',
        countryCode: 'CR',
        code: '+506',
        emoji: '🇨🇷',
    },
    {
        countryCode: 'HR',
        name: 'Croacia',
        code: '+385',
        emoji: '🇭🇷',
    },
    {
        countryCode: 'CU',
        name: 'Cuba',
        code: '+53',
        emoji: '🇨🇺',
    },
    {
        countryCode: 'CW',
        name: 'Curazao',
        code: '+599',
        emoji: '🇨🇼',
    },
    {
        countryCode: 'CY',
        name: 'Chipre',
        code: '+537',
        emoji: '🇨🇾',
    },
    {
        name: "República Checa",
        countryCode: 'CZ',
        code: "+420",
        emoji: '🇨🇿',
    },
    {
        countryCode: 'DK',
        name: 'Dinamarca',
        code: '+45',
        emoji: '🇩🇰',
    },
    // {
    //     name: 'Diego Garcia',
    //     countryCode: 'DG',
    //     code: '+246',
    //     emoji: '🇩🇬',
    // },
    // {
    //     countryCode: 'DJ',
    //     name: 'Djibouti',
    //     code: '+253',
    //     emoji: '🇩🇯',
    // },
    {
        countryCode: 'DM',
        name: 'Dominica',
        code: '+1 767',
        emoji: '🇩🇲',
    },
    {
        name: 'República Dominicana',
        countryCode: 'DO',
        code: '+1 809',
        emoji: '🇩🇴',
    },
    {
        countryCode: 'EG',
        name: 'Egipto',
        code: '+20',
        emoji: '🇪🇬',
    },
    {
        name: 'El Salvador',
        countryCode: 'SV',
        code: '+503',
        emoji: '🇸🇻',
    },
    // {
        // name: 'Equatorial Guinea',
        // countryCode: 'GQ',
        // code: '+240',
        // emoji: '🇬🇶',
        // },
        // {
        // countryCode: 'ER',
        // name: 'Eritrea',
        // code: '+291',
        // emoji: '🇪🇷',
    // },
    {
        countryCode: 'EE',
        name: 'Estonia',
        code: '+372',
        emoji: '🇪🇪',
    },
    {
        countryCode: 'ET',
        name: 'Etiopía',
        code: '+251',
        emoji: '🇪🇹',
    },
    {
        name: 'Islas Malvinas',
        countryCode: 'FK',
        code: '+500',
        emoji: '🇫🇰',
    },
    // {
        // name: 'Islas Feroe',
        // countryCode: 'FO',
        // code: '+298',
        // emoji: '🇫🇴',
    // },
    {
        countryCode: 'FJ',
        code: '+679',
        name: 'Fiyi',
        emoji: '🇫🇯',
    },
    {
        countryCode: 'FI',
        name: 'Finlandia',
        code: '+358',
        emoji: '🇫🇮',
    },
    {
        countryCode: 'FR',
        name: 'Francia',
        code: '+33',
        emoji: '🇫🇷',
    },
    {
        name: 'Guayana Francesa',
        countryCode: 'GF',
        code: '+594',
        emoji: '🇬🇫',
    },
    // {
    //     name: 'Polinesia Francesa',
    //     countryCode: 'PF',
    //     code: '+689',
    //     emoji: '🇵🇫',
    // },
    {
        countryCode: 'GA',
        name: 'Gabón',
        code: '+241',
        emoji: '🇬🇦',
    },
    {
        countryCode: 'GM',
        name: 'Gambia',
        code: '+220',
        emoji: '🇬🇲',
    },
    {
        countryCode: 'GE',
        name: 'Georgia',
        code: '+995',
        emoji: '🇬🇪',
    },
    {
        countryCode: 'DE',
        name: 'Alemania',
        code: '+49',
        emoji: '🇩🇪',
    },
    {
        countryCode: 'GH',
        name: 'Ghana',
        code: '+233',
        emoji: '🇬🇭',
    },
    {
        countryCode: 'GI',
        name: 'Gibraltar',
        code: '+350',
        emoji: '🇬🇮',
    },
    {
        countryCode: 'GR',
        name: 'Grecia',
        code: '+30',
        emoji: '🇬🇷',
    },
    {
        countryCode: 'GL',
        name: 'Groenlandia',
        code: '+299',
        emoji: '🇬🇱',
    },
    {
        countryCode: 'GD',
        name: 'Granada',
        code: '+1 473',
        emoji: '🇬🇩',
    },
    {
        name: 'Guadalupe',
        countryCode: 'GP',
        code: '+590',
        emoji: '🇬🇵',
    },
    {
        countryCode: 'GU',
        code: '+1 671',
        name: 'Guam',
        emoji: '🇬🇺',
    },
    {
        countryCode: 'GT',
        name: 'Guatemala',
        code: '+502',
        emoji: '🇬🇹',
    },
    {
        countryCode: 'GN',
        name: 'Guinea',
        code: '+224',
        emoji: '🇬🇳',
    },
    // {
    //     name: 'Guinea-Bissau',
    //     countryCode: 'GW',
    //     code: '+245',
    //     emoji: '🇬🇼',
    // },
    {
        countryCode: 'GY',
        name: 'Guyana',
        code: '+595',
        emoji: '🇬🇾',
    },
    {
        countryCode: 'HT',
        name: 'Haití',
        code: '+509',
        emoji: '🇭🇹',
    },
    {
        countryCode: 'HN',
        name: 'Honduras',
        code: '+504',
        emoji: '🇭🇳',
    },
    {
        name: 'Hong Kong SAR China',
        countryCode: 'HK',
        code: '+852',
        emoji: '🇭🇰',
    },
    {
        countryCode: 'HU',
        name: 'Hungría',
        code: '+36',
        emoji: '🇭🇺',
    },
    {
        countryCode: 'IS',
        name: 'Islandia',
        code: '+354',
        emoji: '🇮🇸',
    },
    {
        countryCode: 'IN',
        name: 'India',
        code: '+91',
        emoji: '🇮🇳',
    },
    {
        countryCode: 'ID',
        name: 'Indonesia',
        code: '+62',
        emoji: '🇮🇩',
    },
    {
        countryCode: 'IR',
        name: 'Irán',
        code: '+98',
        emoji: '🇮🇷',
    },
    {
        countryCode: 'IQ',
        code: '+964',
        name: 'Irak',
        emoji: '🇮🇶',
    },
    {
        countryCode: 'IE',
        name: 'Irlanda',
        code: '+353',
        emoji: '🇮🇪',
    },
    {
        countryCode: 'IL',
        name: 'Israel',
        code: '+972',
        emoji: '🇮🇱',
    },
    {
        countryCode: 'IT',
        name: 'Italia',
        code: '+39',
        emoji: '🇮🇹',
    },
    {
        countryCode: 'JM',
        name: 'Jamaica',
        code: '+1 876',
        emoji: '🇯🇲',
    },
    {
        countryCode: 'JP',
        name: 'Japón',
        code: '+81',
        emoji: '🇯🇵',
    },
    {
        countryCode: 'JO',
        name: 'Jordania',
        code: '+962',
        emoji: '🇯🇴',
    },
    {
        name: 'Kazajistán',
        countryCode: 'KZ',
        code: '+7 7',
        emoji: '🇰🇿',
    },
    {
        countryCode: 'KE',
        name: 'Kenia',
        code: '+254',
        emoji: '🇰🇪',
    },
    // {
    //     countryCode: 'KI',
    //     name: 'Kiribati',
    //     code: '+686',
    //     emoji: '🇰🇮',
    // },
    {
        countryCode: 'KW',
        name: 'Kuwait',
        code: '+965',
        emoji: '🇰🇼',
    },
    {
        name: 'Kirguistán',
        countryCode: 'KG',
        code: '+996',
        emoji: '🇰🇬',
    },
    {
        countryCode: 'LA',
        code: '+856',
        name: 'Laos',
        emoji: '🇱🇦',
    },
    {
        countryCode: 'LV',
        name: 'Letonia',
        code: '+371',
        emoji: '🇱🇻',
    },
    {
        countryCode: 'LB',
        name: 'Líbano',
        code: '+961',
        emoji: '🇱🇧',
    },
    {
        countryCode: 'LS',
        name: 'Lesoto',
        code: '+266',
        emoji: '🇱🇸',
    },
    {
        countryCode: 'LR',
        name: 'Liberia',
        code: '+231',
        emoji: '🇱🇷',
    },
    {
        countryCode: 'LY',
        name: 'Libia',
        code: '+218',
        emoji: '🇱🇾',
    },
    // {
        // name: 'Liechtenstein',
        // countryCode: 'LI',
        // code: '+423',
        // emoji: '🇱🇮',
    // },
    {
        countryCode: 'LT',
        name: 'Lituania',
        code: '+370',
        emoji: '🇱🇹',
    },
    {
        name: 'Luxemburgo',
        countryCode: 'LU',
        code: '+352',
        emoji: '🇱🇺',
    },
    // {
        // name: 'Macau SAR China',
        // countryCode: 'MO',
        // code: '+853',
        // emoji: '🇲🇴',
    // },
    {
        countryCode: 'MK',
        name: 'Macedonia del Norte',
        code: '+389',
        emoji: '🇲🇰',
    },
    {
        name: 'Madagascar',
        countryCode: 'MG',
        code: '+261',
        emoji: '🇲🇬',
    },
    {
        countryCode: 'MW',
        name: 'Malaui',
        code: '+265',
        emoji: '🇲🇼',
    },
    {
        countryCode: 'MY',
        name: 'Malasia',
        code: '+60',
        emoji: '🇲🇾',
    },
    {
        countryCode: 'MV',
        name: 'Maldivas',
        code: '+960',
        emoji: '🇲🇻',
    },
    {
        countryCode: 'ML',
        code: '+223',
        name: 'Malí',
        emoji: '🇲🇱',
    },
    {
        countryCode: 'MT',
        name: 'Malta',
        code: '+356',
        emoji: '🇲🇹',
    },
    // {
    //     countryCode: 'MH',
    //     name: 'Marshall Islands',
    //     code: '+692',
    //     emoji: '🇲🇭',
    // },
    // {
    //     countryCode: 'MQ',
    //     name: 'Martinique',
    //     code: '+596',
    //     emoji: '🇲🇶',
    // },
    // {
    //     name: 'Mauritania',
    //     countryCode: 'MR',
    //     code: '+222',
    //     emoji: '🇲🇷',
    // },
    // {
    //     countryCode: 'MU',
    //     name: 'Mauritius',
    //     code: '+230',
    //     emoji: '🇲🇺',
    // },
    // {
    //     countryCode: 'YT',
    //     name: 'Mayotte',
    //     code: '+262',
    //     emoji: '🇾🇹',
    // },
    {
        countryCode: 'MX',
        name: 'México',
        code: '+52',
        emoji: '🇲🇽',
    },
    // {
    //     name: 'Micronesia',
    //     countryCode: 'FM',
    //     code: '+691',
    //     emoji: '🇫🇲',
    // },
    {
        countryCode: 'MD',
        name: 'Moldavia',
        code: '+373',
        emoji: '🇲🇩',
    },
    {
        countryCode: 'MC',
        name: 'Mónaco',
        code: '+377',
        emoji: '🇲🇨',
    },
    {
        countryCode: 'MN',
        name: 'Mongolia',
        code: '+976',
        emoji: '🇲🇳',
    },
    {
        name: 'Montenegro',
        countryCode: 'ME',
        code: '+382',
        emoji: '🇲🇪',
    },
    {
        name: 'Montserrat',
        countryCode: 'MS',
        code: '+1664',
        emoji: '🇲🇸',
    },
    {
        countryCode: 'MA',
        name: 'Marruecos',
        code: '+212',
        emoji: '🇲🇦',
    },
    {
        countryCode: 'MM',
        name: 'Myanmar',
        emoji: '🇲🇲',
        code: '+95',
    },
    {
        countryCode: 'NA',
        name: 'Namibia',
        code: '+264',
        emoji: '🇳🇦',
    },
    // {
    //     countryCode: 'NR',
    //     name: 'Nauru',
    //     code: '+674',
    //     emoji: '🇳🇷',
    // },
    {
        countryCode: 'NP',
        name: 'Nepal',
        code: '+977',
        emoji: '🇳🇵',
    },
    {
        name: 'Países Bajos',
        countryCode: 'NL',
        code: '+31',
        emoji: '🇳🇱',
    },
    {
        countryCode: 'KN',
        code: "+1 869",
        name: "Nevis",
        emoji: '🇰🇳',
    },
    {
        name: 'Nueva Caledonia',
        countryCode: 'NC',
        code: '+687',
        emoji: '🇳🇨',
    },
    {
        name: 'Nueva Zelanda',
        countryCode: 'NZ',
        code: '+64',
        emoji: '🇳🇿',
    },
    {
        countryCode: 'NI',
        name: 'Nicaragua',
        code: '+505',
        emoji: '🇳🇮',
    },
    {
        countryCode: 'NE',
        name: 'Níger',
        code: '+227',
        emoji: '🇳🇪',
    },
    {
        countryCode: 'NG',
        name: 'Nigeria',
        code: '+234',
        emoji: '🇳🇬',
    },
    {
        countryCode: 'NU',
        name: 'Niue',
        code: '+683',
        emoji: '🇳🇺',
    },
    {
        name: 'Corea del Norte',
        countryCode: 'KP',
        code: '+850',
        emoji: '🇰🇵',
    },
    // {
    //     name: 'Northern Mariana Islands',
    //     countryCode: 'MP',
    //     code: '+1 670',
    //     emoji: '🇲🇵',
    // },
    {
        countryCode: 'NO',
        name: 'Noruega',
        code: '+47',
        emoji: '🇳🇴',
    },
    {
        countryCode: 'OM',
        name: 'Omán',
        code: '+968',
        emoji: '🇴🇲',
    },
    {
        countryCode: 'PK',
        name: 'Pakistán',
        code: '+92',
        emoji: '🇵🇰',
    },
    {
        countryCode: 'PW',
        name: 'Palau',
        code: '+680',
        emoji: '🇵🇼',
    },
    {
        countryCode: 'PA',
        name: 'Panamá',
        code: '+507',
        emoji: '🇵🇦',
    },
    {
        name: 'Filipinas',
        countryCode: 'PH',
        code: '+63',
        emoji: '🇵🇭',
    },
    {
        countryCode: 'PL',
        name: 'Polonia',
        code: '+48',
        emoji: '🇵🇱',
    },
    {
        countryCode: 'PT',
        name: 'Portugal',
        code: '+351',
        emoji: '🇵🇹',
    },
    {
        name: 'Puerto Rico',
        countryCode: 'PR',
        code: '+1 787',
        emoji: '🇵🇷',
    },
    {
        countryCode: 'QA',
        name: 'Qatar',
        code: '+974',
        emoji: '🇶🇦',
    },
    {
        countryCode: 'RE',
        name: 'Reunión',
        code: '+262',
        emoji: '🇷🇪',
    },
    {
        countryCode: 'RO',
        name: 'Rumania',
        code: '+40',
        emoji: '🇷🇴',
    },
    {
        countryCode: 'RU',
        name: 'Rusia',
        emoji: '🇷🇺',
        code: '+7',
    },
    // {
    //     countryCode: 'RW',
    //     name: 'Rwanda',
    //     code: '+250',
    //     emoji: '🇷🇼',
    // },
    {
        countryCode: 'WS',
        name: 'Samoa',
        code: '+685',
        emoji: '🇼🇸',
    },
    {
        name: 'San Marino',
        countryCode: 'SM',
        code: '+378',
        emoji: '🇸🇲',
    },
    {
        name: 'Arabia Saudita',
        countryCode: 'SA',
        code: '+966',
        emoji: '🇸🇦',
    },
    {
        countryCode: 'SN',
        name: 'Senegal',
        code: '+221',
        emoji: '🇸🇳',
    },
    {
        countryCode: 'RS',
        name: 'Serbia',
        code: '+381',
        emoji: '🇷🇸',
    },
    {
        countryCode: 'SG',
        name: 'Singapur',
        code: '+65',
        emoji: '🇸🇬',
    },
    {
        countryCode: 'SK',
        name: 'Eslovaquia',
        code: '+421',
        emoji: '🇸🇰',
    },
    {
        countryCode: 'SI',
        name: 'Eslovenia',
        code: '+386',
        emoji: '🇸🇮',
    },
    {
        name: 'Islas Salomón',
        countryCode: 'SB',
        code: '+677',
        emoji: '🇸🇧',
    },
    {
        name: 'Sudáfrica',
        countryCode: 'ZA',
        code: '+27',
        emoji: '🇿🇦',
    },
    {
        name: 'Corea del Sur',
        countryCode: 'KR',
        code: '+82',
        emoji: '🇰🇷',
    },
    {
        countryCode: 'ES',
        name: 'España',
        code: '+34',
        emoji: '🇪🇸',
    },
    {
        countryCode: 'LK',
        name: 'Sri Lanka',
        code: '+94',
        emoji: '🇱🇰',
    },
    {
        countryCode: 'SD',
        name: 'Sudán',
        code: '+249',
        emoji: '🇸🇩',
    },
    {
        countryCode: 'SR',
        name: 'Surinam',
        code: '+597',
        emoji: '🇸🇷',
    },
    {
        countryCode: 'SZ',
        name: 'Esuatini',
        code: '+268',
        emoji: '🇸🇿',
    },
    {
        countryCode: 'SE',
        name: 'Suecia',
        code: '+46',
        emoji: '🇸🇪',
    },
    {
        countryCode: 'CH',
        name: 'Suiza',
        code: '+41',
        emoji: '🇨🇭',
    },
    {
        countryCode: 'SY',
        name: 'Siria',
        code: '+963',
        emoji: '🇸🇾',
    },
    {
        countryCode: 'TW',
        name: 'Taiwán',
        code: '+886',
        emoji: '🇹🇼',
    },
    {
        countryCode: 'TJ',
        name: 'Tayikistán',
        code: '+992',
        emoji: '🇹🇯',
    },
    {
        countryCode: 'TZ',
        name: 'Tanzania',
        code: '+255',
        emoji: '🇹🇿',
    },
    {
        countryCode: 'TH',
        name: 'Tailandia',
        code: '+66',
        emoji: '🇹🇭',
    },
    {
        countryCode: 'TG',
        code: '+228',
        name: 'Togo',
        emoji: '🇹🇬',
    },
    {
        countryCode: 'TK',
        name: 'Tokelau',
        code: '+690',
        emoji: '🇹🇰',
    },
    {
        countryCode: 'TO',
        name: 'Tonga',
        code: '+676',
        emoji: '🇹🇴',
    },
    // {
    //     name: 'Trinidad and Tobago',
    //     countryCode: 'TT',
    //     code: '+1 868',
    //     emoji: '🇹🇹',
    // },
    {
        countryCode: 'TN',
        name: 'Túnez',
        code: '+216',
        emoji: '🇹🇳',
    },
    {
        countryCode: 'TR',
        name: 'Turquía',
        code: '+90',
        emoji: '🇹🇷',
    },
    {
        name: 'Turkmenistán',
        countryCode: 'TM',
        code: '+993',
        emoji: '🇹🇲',
    },
    {
        countryCode: 'TV',
        name: 'Tuvalu',
        code: '+688',
        emoji: '🇹🇻',
    },
    // {
        // name: 'Islas Vírgenes de los Estados Unidos',
        // countryCode: 'VI',
        // code: '+1 340',
        // emoji: '🇻🇮',
    // },
    {
        countryCode: 'UG',
        name: 'Uganda',
        code: '+256',
        emoji: '🇺🇬',
    },
    {
        countryCode: 'UA',
        name: 'Ucrania',
        code: '+380',
        emoji: '🇺🇦',
    },
    {
        name: 'Emiratos Árabes Unidos',
        countryCode: 'AE',
        code: '+971',
        emoji: '🇦🇪',
    },
    {
        name: 'Reino Unido',
        countryCode: 'GB',
        code: '+44',
        emoji: '🇬🇧',
    },
    {
        name: 'Estados Unidos',
        countryCode: 'US',
        emoji: '🇺🇸',
        code: '+1',
    },
    {
        name: 'Uzbekistán',
        countryCode: 'UZ',
        code: '+998',
        emoji: '🇺🇿',
    },
    {
        countryCode: 'VU',
        name: 'Vanuatu',
        code: '+678',
        emoji: '🇻🇺',
    },
    {
        countryCode: 'VN',
        name: 'Vietnam',
        code: '+84',
        emoji: '🇻🇳',
    },
    {
        countryCode: 'YE',
        name: 'Yemen',
        code: '+967',
        emoji: '🇾🇪',
    },
    {
        countryCode: 'ZM',
        name: 'Zambia',
        code: '+260',
        emoji: '🇿🇲',
    },
    {
        countryCode: 'ZW',
        name: 'Zimbabue',
        code: "+263",
        emoji: '🇿🇼',
    }
];

export const showCountryFlag = (code) => {
    for (let i = 0; i < CountryFlagsArray.length; i++) {
        if ( code === CountryFlagsArray[i].countryCode ) {
            return CountryFlagsArray[i].emoji;
        }
    }
};