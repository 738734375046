import React, {useState} from 'react';

// Components
import TextArea from '../GeneralComponents/Input General/TextArea';

// Constants
// import {BOTH_TYPE, MESAYA_TYPE, RESERVA_TYPE} from "../../constants";
//Icons Primary
// import Delete from "../SvgComponents/delete";
// import ReservasIconClient from '../SvgComponents/icons/IconsPrimary/Only Icons/ReservasIconClient';
// import MesaYaIconClient from '../SvgComponents/icons/IconsPrimary/Only Icons/MesaYaIconClient';
//Icon Disabled
import SimpleTrashIcon from '../SvgComponents/icons/TrashIcons/SimpleTrashIcon';
import EditIcon from '../SvgComponents/icons/EditIcon/EditIcon';
import SaveIcon from '../SvgComponents/icons/SaveIcon/SaveIcon';

// import ReservasIconClientDisabled from '../SvgComponents/icons/IconsPrimary/Only Icons/Disabled Icons/ReservasIconClientsDisabled';
// import MesaYaIconClientDisabled from '../SvgComponents/icons/IconsPrimary/Only Icons/Disabled Icons/MesaYaIconClientDisabled';
// Material Ui
import {makeStyles} from '@material-ui/core/styles';

// import {Button, IconButton} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    MessageContainer: {
        padding: '.5rem .5rem .2rem .5rem',
        border: '1px solid #DBD7DC',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        borderRadius: '8px',
        display: 'flex',
        width: '100%',
        gap: '8px'
    }
}));

const MessageCard = (props) => {

    const classes = useStyles();

    const {message, type, editing} = props

    const [msgState, setMsgState] = useState(message);
    const [typeState, setTypeState] = useState(type);

    const {
        onDelete,
        onEditingComplete,
        onStartEditing,
    } = props

    const onMessageChange = (e) => {
        setMsgState(e.target.value);
    }

    // const onTypeReservasChange = () => {
    //     if (typeState === BOTH_TYPE)
    //         setTypeState(MESAYA_TYPE)
    //     else if (typeState === MESAYA_TYPE)
    //         setTypeState(BOTH_TYPE)
    // }

    // const onTypeMesaYaChange = () => {
    //     if (typeState === BOTH_TYPE)
    //         setTypeState(RESERVA_TYPE)
    //     else if (typeState === RESERVA_TYPE)
    //         setTypeState(BOTH_TYPE)
    // }

    return (
        <div className={classes.MessageContainer}>
            <TextArea
                height={"42px"}
                width={"100%"}
                text={msgState}
                onChange={onMessageChange}
                disabled={!editing}
                characters={25}
            />
            {/* <Grid container style={{width: '100%', backgroundColor: 'tomato'}}>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={1} style={{paddingTop: '10px', marginLeft: '50px'}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <div
                                onClick={() => editing ?  onTypeMesaYaChange() : null}>
                                {typeState === MESAYA_TYPE || typeState === BOTH_TYPE ? <MesaYaIconClient/> :
                                    <MesaYaIconClientDisabled/>}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div onClick={() => editing ? onTypeReservasChange() : null}>
                                {typeState === RESERVA_TYPE || typeState === BOTH_TYPE ? <ReservasIconClient/> :
                                    <ReservasIconClientDisabled/>}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                </Grid>
            </Grid> */}
            <div style={{display: editing ? 'none' : 'flex', marginTop: '-1rem'}}>
                <EditIcon
                    onClick={() => editing ? msgState && msgState.trim() !== "" ? onEditingComplete({
                        message: msgState,
                        type: typeState,
                    }) : null : onStartEditing()}
                    height={'1.7rem'}
                    width={'1.7rem'}
                />
                <SimpleTrashIcon
                    onClick={onDelete}
                    height={'1.7rem'}
                    width={'1.7rem'}
                />
            </div>
            <div style={{display: editing ? 'flex' : 'none', marginTop: '-1rem'}}>
                <SaveIcon
                    onClick={() => editing ? msgState && msgState.trim() !== "" ? onEditingComplete({
                        message: msgState,
                        type: typeState,
                    }) : null : onStartEditing()}
                    height={'1.7rem'}
                    width={'1.7rem'}
                />
            </div>
            {/* <Button
                onClick={() => editing ? msgState && msgState.trim() !== "" ? onEditingComplete({
                    message: msgState,
                    type: typeState,
                }) : null : onStartEditing()}
                style={{
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                    color: editing ? '#15D615' : '#848282',
                    padding: '0px 0px 2px 0px',
                    textTransform: 'none',
                    borderRadius: '15px',
                    marginBottom: '5px'
                }}
            >{editing ? "Listo!" : "Editar"}</Button> */}
            {/* <IconButton className={classes.deleteIcon} onClick={onDelete}>
                <Delete height={'12px'} width={'12px'}/>
            </IconButton> */}
        </div>
    )
}

export default MessageCard
