import { 
    CLOSE_ERROR_DIALOG, 
    RESET_ALL_DATA,
    RESERVA_ROLE, 
    MESAYA_ROLE, 
    ADMIN_ROLE, 
} from "../../constants";
import {
    CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE,
    CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE,
    CHANGE_EDIT_USER_ACCOUNT_MY_ROLE,
    LOADING_USER_ACCOUNTS_SUCCESSFUL,
    CHANGE_EDIT_USER_ACCOUNT_ENABLE,
    CHANGE_EDIT_USER_ACCOUNT_EMAIL,
    CHANGE_EDIT_USER_ACCOUNT_NAME,
    EDIT_USER_ACCOUNTS_SUCCESSFUL,
    TOGGLE_LOADING_USER_ACCOUNTS,
    USER_SET_ID_PARTNER_SELECTED,
    USERS_CHANGE_USER_ROLE,
    USER_CHANGE_USER_ROLE,
    LOADING_USER_ACCOUNTS,
    EDIT_USER_ACCOUNT,
    SHOW_ERROR_DIALOG,
    SHOW_USER_ERROR_MODAL,
} from "../../constants/userAccountsConstants";
import {deepCopy, deepCopyObject} from "../../utils/arrayHelpers";
import {deepEqual} from "../../utils/objectHelpers";


const initState = {
    idPartnerSelected: localStorage.getItem( 'partnerSelected' ) ? localStorage.getItem( 'partnerSelected' ) : '', 
    userRole: 'mesa-ya',
    originalUsers: [],
    editUserIndex: -1,
    edited: false,
    users: [],

    showErrorModal: false,
    loading: true,
    error: ""
}

const editReducer = (state = initState, action) => {
    let editUser = {}
    let usersEdits = []

    switch (action.type) {
        case LOADING_USER_ACCOUNTS:
            return { ...state, loading: true }
        case LOADING_USER_ACCOUNTS_SUCCESSFUL:
            return { ...state, loading: false, users: deepCopy(action.payload), originalUsers: deepCopy(action.payload) }
        case SHOW_USER_ERROR_MODAL:
            return { ...state, showErrorModal: action.payload.value }
        case SHOW_ERROR_DIALOG:
            return { ...state, loading: false, error: action.payload }
        case CLOSE_ERROR_DIALOG:
            return { ...state, loading: false, error: "" }
        case EDIT_USER_ACCOUNTS_SUCCESSFUL:
            return {...state, edited: false, editUserIndex: -1 }
        case EDIT_USER_ACCOUNT:
            return { ...state, edited: true, editUserIndex: action.payload }
        case CHANGE_EDIT_USER_ACCOUNT_ENABLE:
            let indexEdit = state.users.findIndex(user => user.id === action.payload.id)
            if ( indexEdit !== -1 ) {
                editUser = deepCopyObject(state.users[indexEdit])
                editUser = deepCopyObject(changeProperty(editUser, 'enable', action.payload.enable))
                usersEdits = [...state.users]
                usersEdits[indexEdit] = {...editUser}
                return {
                    ...state,
                    users: deepCopy(usersEdits),
                    edited: !deepEqual(state.originalUsers.find(user => user.id === editUser.id), editUser)
                }
            }
            return {...state}
        case CHANGE_EDIT_USER_ACCOUNT_NAME:
            editUser = { ...state.users[ state.editUserIndex ] }
            editUser = deepCopyObject( changeProperty( editUser, 'name', action.payload ) )
            usersEdits = [...state.users]
            usersEdits[state.editUserIndex] = {...editUser}
            return {
                ...state,
                users: deepCopy( usersEdits ),
                edited: !deepEqual( state.originalUsers.find( user => user.id === editUser.id ), editUser )
            }
        case CHANGE_EDIT_USER_ACCOUNT_EMAIL:
            editUser = {...state.users[state.editUserIndex]}
            editUser = deepCopyObject(changeProperty(editUser, 'email', action.payload))
            usersEdits = [...state.users]
            usersEdits[state.editUserIndex] = {...editUser}
            return {
                ...state,
                users: deepCopy(usersEdits),
                edited: !deepEqual(state.originalUsers.find(user => user.id === editUser.id), editUser)
            }
        case CHANGE_EDIT_USER_ACCOUNT_MY_ROLE:
            editUser = { ...state.users[ state.editUserIndex ] }
            editUser = deepCopyObject(changeRole( editUser, MESAYA_ROLE ))
            usersEdits = [ ...state.users ]
            usersEdits[ state.editUserIndex ] = { ...editUser }
            return {
                ...state,
                users: deepCopy(usersEdits),
                edited: !deepEqual(state.originalUsers.find(user => user.id === editUser.id), editUser)
            }
        case CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE:
            editUser = {...state.users[state.editUserIndex]}
            editUser = deepCopyObject(changeRole(editUser, RESERVA_ROLE))
            usersEdits = [...state.users]
            usersEdits[state.editUserIndex] = {...editUser}
            return {
                ...state,
                users: deepCopy(usersEdits),
                edited: !deepEqual(state.originalUsers.find(user => user.id === editUser.id), editUser)
            }
        case CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE:
            editUser = {...state.users[state.editUserIndex]}
            editUser = deepCopyObject(changeRole(editUser, ADMIN_ROLE))
            usersEdits = [...state.users]
            usersEdits[state.editUserIndex] = {...editUser}
            return {
                ...state,
                users: deepCopy(usersEdits),
                edited: !deepEqual(state.originalUsers.find(user => user.id === editUser.id), editUser)
            }
        case TOGGLE_LOADING_USER_ACCOUNTS:
            return {...state, loading: !state.loading}
        case RESET_ALL_DATA:
            return {...initState}

        case USER_CHANGE_USER_ROLE:
            return { ...state, userRole: action.payload.userRole }
        case USERS_CHANGE_USER_ROLE:
            let usersArrayCopy = [ ...state.users ];
            const userArray = usersArrayCopy.find( item => item.id === action.payload.userId );
            // let userPartnerIndex;
            // let userIndex;

            if ( userArray ) {
                const partnerArray = userArray.partners.find( partner => partner.uuid === action.payload.partnerId );
                if ( partnerArray ) {
                    partnerArray.role = action.payload.role;
                }
            };

            state.userRole = action.payload.role;

            // usersArrayCopy.forEach( item => {
            //     item.partners.forEach( partner => {
            //         if ( partner.uuid === action.payload.partnerId ) {
            //             partner.role = action.payload.role;
            //         }
            //     });
            // });

            // for ( let i = 0; i < usersArrayCopy.length; i++ ) {
            //     console.log( usersArrayCopy[i] );
            //     for ( let j = 0; j < usersArrayCopy[i].partners.length; j++ ) {
            //         console.log( usersArrayCopy[i].partners[j] );
            //         if ( usersArrayCopy[i].partners[j].uuid === action.payload.partnerId ) {
            //             console.log( usersArrayCopy[i].partners[j], i, j );
            //             userPartnerIndex = j;
            //             userIndex = i;
            //         };
            //     };
            // };

            // console.log(  userPartnerIndex  );
            // console.log(  userIndex  );
            // console.log( usersArrayCopy[ userIndex ].partners[ userPartnerIndex ] );
            
            // usersArrayCopy[ userIndex ].partners[ userPartnerIndex ].role = action.payload.role;

            // console.log( usersArrayCopy[ userIndex ].partners[ userPartnerIndex ].role );

            // console.log( usersArrayCopy );
            
            return {
                ...state,
                users: [ ...usersArrayCopy ]
            }
        case USER_SET_ID_PARTNER_SELECTED:
            localStorage.setItem( 'partnerSelected', action.payload.id );
            return {
                ...state,
                editing: false,
                idPartnerSelected: action.payload.id
            }
        default:
            return state;
    }
}

/**
 *
 * Cambia la propiedad del objeto enviado, y devuelve el objeto modificado
 *
 * @param user {Object} Usuario que actualmente se esta editando
 * @param property {String} Propiedad a editar del objeto
 * @param value Nuevo valor de la propiedad
 */
const changeProperty = (user, property, value) => {
    let editUser = {...user}
    editUser[property] = value;
    return {...editUser}
}

const changeRole = (user, role) => {
    let editUser = {...user}
    let roles = [...editUser.role]
    let exist = roles.findIndex(r => r === role)
    if (exist !== -1) roles.splice(exist, 1)
    else roles.push(role)
    editUser['role'] = [...roles]
    return {...editUser}
}

export default editReducer;
