import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { setOpenSidebar } from '../../../actions/myBookingsActions';

// Components
import SpecialClientSidebar from '../../MyClients/components/SpecialClientSidebar';
import SidebarData from './SidebarData';

// Helpers
import { onSetSidebarClientData } from '../../MyClients/helpers';
import { onSetSidebarData } from '../Section3/helpers';

// Icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Material Ui
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';

// Styles
import { 
    TitleSidebarDataStyles,
    CreatedAtClientStyles,
    TextDataSidebarStyles,
    ItemSidebarContainer,
    NameSectionContainer, 
    GeneralDataContainer,
    nameContainerSidebar,
    DataContainerStyles,
    IconNameTextStyles, 
    IconNameContainer, 
    AliasTextStyles, 
    PhotoNameStyles,
    IconNameStyles, 
    NameTextStyles,
} from './Styles/StyleComponents';

const Sidebar = ( props ) => {

    // Actions
    const { setOpenSidebar } = props;

    // States
    const { isActive, showSidebar, sidebarBookingId } = props;

    // External props
    const { bookings, component } = props;

    const [userDataObject, setUserDataObject] = useState([]);
    const [firstNameWord, setFirstNameWord] = useState('');
    const [userData, setUserData] = useState({});
    const special = false;

    const SidebarDataContainerStyles = { 
        paddingTop: isActive ? '1rem' : '5.5rem',
        paddingLeft: '.5rem',
    };

    const toggleDrawer = ( event ) => {
        if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' )) {
            return;
        };
        
        setOpenSidebar( false );
    };

    useEffect(() => {
        for (let i = 0; i < bookings.length; i++) {
            if ( bookings[i].id === sidebarBookingId ) {
                setUserData( bookings[i] );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sidebarBookingId ]);

    useEffect(() => {
        if ( userData ) {
            if ( userData.name && component === 'bookings' ) {
                let parts = userData.name.match(/^([a-zA-Z])[^\s]*(.*)$/);
                if ( parts[1] !== null ) {
                    setFirstNameWord( parts[1] );
                };
    
                setUserDataObject( onSetSidebarData( userData ) );
            } else {
                if ( userData.name ) {
                    let parts = userData.name?.match(/^([a-zA-Z])[^\s]*(.*)$/);
                    if ( parts[1] !== null ) {
                        setFirstNameWord( parts[1] );
                    };
        
                    setUserDataObject( onSetSidebarClientData( userData ) );
                }
            }
        };
    }, [userData, component]);

    return (
        <Drawer
            sx={{ boxSizing: 'border-box', zIndex: '1202' }}
            onClose={ (e) => toggleDrawer(e) }
            open={ showSidebar }
            anchor={ 'right' }
        >
            <Box sx={ SidebarDataContainerStyles }>
                <IconButton onClick={ (e) => toggleDrawer(e) }>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            <Box sx={ GeneralDataContainer } role="presentation" >
                {
                    component !== 'clients' ?
                        <Box sx={ NameSectionContainer }>
                            <Box sx={ IconNameContainer }>
                                <Box sx={ IconNameStyles }>
                                    <Typography sx={ IconNameTextStyles }>{ firstNameWord }</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='h6' sx={ NameTextStyles }>{ userData.name } { userData.lastname }</Typography>
                                <Typography sx={ AliasTextStyles } >{ userData.alias }</Typography>
                            </Box>
                        </Box>
                    :
                        <Box sx={ NameSectionContainer }>
                            {
                                ( userData.photo === null || !userData.photo ) ?
                                    <Box sx={ IconNameContainer }>
                                        <Box sx={ IconNameStyles }>
                                            <Typography sx={ IconNameTextStyles }>{ firstNameWord }</Typography>
                                        </Box>
                                    </Box>
                                :
                                    <img src={ userData.photo } alt="userPhoto" style={ PhotoNameStyles }/>
                            }
                            <Box>
                                <Box sx={ nameContainerSidebar }>
                                    {/* Arreglo de bug de falta de apellidos */}
                                    <Typography variant='h6' sx={ NameTextStyles }>{ userData.name } { userData.lastname }</Typography>
                                    { special && <SpecialClientSidebar /> }
                                </Box>
                                <Typography sx={ CreatedAtClientStyles } >
                                    Creado el { moment( userData.createdAt ).format('DD/MM/YYYY') }
                                </Typography>
                            </Box>
                        </Box>
                }
                {
                    component !== 'clients' ?
                        <Box sx={ DataContainerStyles }>
                            {
                                userDataObject.map(({ id, name, BookingIcon, data }) => 
                                    <Box key={ id }>
                                        <Box sx={ ItemSidebarContainer } >
                                            <Box sx={{ width: '40%', display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <BookingIcon/>
                                                <Typography sx={ TitleSidebarDataStyles }>{ name }</Typography>
                                            </Box>
                                            <Box sx={{ width: '60%', display: 'flex' }}>
                                                <Typography sx={ TextDataSidebarStyles }>
                                                    { data }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider/>
                                    </Box>
                                )
                            }
                        </Box>
                    :
                        <Box sx={ DataContainerStyles }>
                            {
                                userDataObject.map(({ id, name, BookingIcon, data, nationality }) => 
                                    <Box key={ id }>
                                        <Box sx={ ItemSidebarContainer } >
                                            <Box sx={{ width: '40%', display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <BookingIcon/>
                                                <Typography sx={ TitleSidebarDataStyles }>{ name }</Typography>
                                            </Box>
                                            <Box sx={{ width: '60%' }}>
                                                <SidebarData 
                                                    nationality={ nationality } 
                                                    data={ data } 
                                                />
                                            </Box>
                                        </Box>
                                        <Divider/>
                                    </Box>
                                )
                            }
                        </Box>    
                }
            </Box>
        </Drawer>
    )
}

const mapStateToProps = (state) => {
    return {
        sidebarBookingId: state.myBookingsReducer.sidebarBookingId,
        showSidebar: state.myBookingsReducer.showSidebar,
        isActive: state.sidebarManager.isActive,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenSidebar: (data) => dispatch(setOpenSidebar(data)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Sidebar );