export const START_FETCH_ALL_FILTERS = "START_FETCH_ALL_FILTERS";
export const FETCH_ALL_FILTERS_SUCCESS = "FETCH_ALL_FILTERS_SUCCESS";
export const FETCH_ALL_FILTERS_ERROR = "FETCH_ALL_FILTERS_ERROR";
export const START_FETCH_FILTERS = "START_FETCH_FILTERS";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR";

export const ADD_FOOD_FILTER = "ADD_FOOD_FILTER";
export const REMOVE_FOOD_FILTER = "REMOVE_FOOD_FILTER";
export const ADD_ENVIRONMENT_FILTER = "ADD_ENVIRONMENT_FILTER";
export const REMOVE_ENVIRONMENT_FILTER = "REMOVE_ENVIRONMENT_FILTER";
export const ADD_FEATURE_FILTER = "ADD_FEATURE_FILTER";
export const REMOVE_FEATURE_FILTER = "REMOVE_FEATURE_FILTER";
export const ADD_OTHER_FILTER = "ADD_OTHER_FILTER";
export const REMOVE_OTHER_FILTER = "REMOVE_OTHER_FILTER";
export const ADD_TAG_ITEM = "ADD_TAG_ITEM";
export const REMOVE_TAG_ITEM = "REMOVE_TAG_ITEM";

export const START_SAVE_FILTERS = "START_SAVE_FILTERS";
export const SAVE_FILTERS_SUCCESS = "SAVE_FILTERS_SUCCESS";
export const SAVE_FILTERS_ERROR = "SAVE_FILTERS_ERROR";
