import React from 'react'

const GroupIcon = (props) => {
    return (
        <div>
            <svg id="Grupo_1055" data-name="Grupo 1055" width={props.width} height={props.height} viewBox="0 0 25.5 25">
                <ellipse id="Elipse_56" data-name="Elipse 56" cx="3.57" cy="3.5" rx="3.57" ry="3.5" transform="translate(1.02 1)" fill="#848282"/>
                <path id="Trazado_288" data-name="Trazado 288" d="M7.581,31.871V26.629a4.371,4.371,0,0,1,1.281-3.085A2.9,2.9,0,0,0,7.169,23H3.879A2.854,2.854,0,0,0,1,25.823v3.226a.4.4,0,0,0,.227.361l1.435.7.394,5.416a.408.408,0,0,0,.41.374H7.581a.408.408,0,0,0,.41-.375l.191-2.62A1.208,1.208,0,0,1,7.581,31.871Z" transform="translate(-1 -14.129)" fill="#848282"/>
                <ellipse id="Elipse_57" data-name="Elipse 57" cx="3.57" cy="3.5" rx="3.57" ry="3.5" transform="translate(17.34 1)" fill="#848282"/>
                <path id="Trazado_289" data-name="Trazado 289" d="M45.167,31.871V26.629a4.371,4.371,0,0,0-1.281-3.085A2.9,2.9,0,0,1,45.578,23h3.29a2.854,2.854,0,0,1,2.879,2.823v3.226a.4.4,0,0,1-.227.361l-1.435.7-.394,5.416a.408.408,0,0,1-.41.374H45.167a.408.408,0,0,1-.41-.375l-.191-2.62A1.208,1.208,0,0,0,45.167,31.871Z" transform="translate(-26.248 -14.129)" fill="#848282"/>
                <path id="Trazado_290" data-name="Trazado 290" d="M25.992,23H22.7A3.67,3.67,0,0,0,19,26.629v5.242a.4.4,0,0,0,.331.4l1.746.342.392,6.142a.408.408,0,0,0,.41.378h4.935a.408.408,0,0,0,.41-.378l.392-6.142,1.746-.342a.405.405,0,0,0,.331-.4V26.629A3.67,3.67,0,0,0,25.992,23Z" transform="translate(-11.597 -14.129)" fill="#848282"/>
                <path id="Trazado_291" data-name="Trazado 291" d="M26.7,8.258a3.67,3.67,0,0,0,3.7-3.629,3.7,3.7,0,0,0-7.4,0A3.67,3.67,0,0,0,26.7,8.258Z" transform="translate(-13.952 -1)" fill="#848282"/>
            </svg>
        </div>
    )
}

export default GroupIcon
