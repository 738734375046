import {
    SAVING_DELETE_PLANNING_DATA_SUCCESS,
    SAVING_EDIT_PLANNING_DATA_SUCCESS,
    EDIT_OPEN_HOUR_INTERVAL_COMPLETE,
    PLANNING_SET_SPECIAL_DATES_FORM,
    SAVING_EDIT_PLANNING_DATA_FAIL,
    EDIT_OPEN_HOUR_INTERVAL_ENABLE,
    SAVING_PLANNING_DATA_SUCCESS,
    EDIT_HAPPY_INTERVAL_COMPLETE,
    EDIT_HAPPY_INTERVALS_CANCEL,
    PLANNING_CHANGE_WEEk_REPEAT,
    PLANNING_DELETE_SINGLE_DATE,
    EDIT_HAPPY_HOUR_SUCCESSFUL,
    EDIT_HAPPY_INTERVAL_ENABLE,
    EDIT_OPEN_INTERVALS_CANCEL,
    LOAD_PLANNING_DATA_SUCCESS,
    SAVING_PLANNING_DATA_FAIL,
    PLANNING_CHANGE_FROM_HOUR,
    PLANNING_SET_CLOSING_FORM,
    PLANNING_SET_RANGE_HOURS,
    PLANNING_ADD_SINGLE_DATE,
    PLANNING_CHANGE_TO_HOUR,
    EDIT_OPEN_HOUR_INTERVAL,
    PLANNINGS_SHOW_SNACKBAR,
    LOAD_PLANNING_DATA_FAIL,
    PLANNING_RESTART_DATES,
    PLANNING_SELECTED_TYPE,
    PLANNING_REMOVE_DATES,
    REMOVE_HAPPY_INTERVAL,
    PLANNING_EXCLUDE_DATE,
    PLANNING_ADD_NEW_DATE,
    REMOVE_OPEN_INTERVAL,
    SAVING_PLANNING_DATA,
    PLANNING_CHANGE_DATE,
    CHANGE_PLANNING_MODE,
    PLANNING_SHOW_ERRORS,
    EDIT_HAPPY_INTERVAL,
    RESET_PLANNING_DATA,
    LOAD_PLANNING_DATA,
    CLOSE_ERROR_DIALOG,
    ADD_HAPPY_INTERVAL,
    PLANNING_VIEW_MODE,
    ADD_OPEN_INTERVAL,
    PLANNING_SET_NAME,
    PLANNING_ID_EDIT,
    FETCH_PLANNINGS,
    EDIT_PLANNING,
    PLANNING_ADD_ALL_DATES,
    PLANNING_REMOVE_ALL_DATES,
    PLANNING_REMOVE_ALL_EXCLUDE_DATES,
    PLANNING_ADD_ALL_EXCLUDE_DATES,
} from "../../constants";

import axios from "axios";

export const startLoadingPlanning = () => ({
    type: LOAD_PLANNING_DATA,
});

export const loadPlanningDataSuccess = (data) => ({
    type: LOAD_PLANNING_DATA_SUCCESS,
    payload: data,
});

export const loadPlanningDataFail = (data) => ({
    type: LOAD_PLANNING_DATA_FAIL,
    payload: data,
});

export const savingPlanningData = () => ({
    type: SAVING_PLANNING_DATA,
});

export const savingPlanningDataSuccess = () => ({
    type: SAVING_PLANNING_DATA_SUCCESS,
});

export const savingPlanningDataFail = (error) => ({
    type: SAVING_PLANNING_DATA_FAIL,
    payload: error,
});

export const savingEditPlanningDataSuccess = () => ({
    type: SAVING_EDIT_PLANNING_DATA_SUCCESS,
});

export const savingEditPlanningDataFail = (error) => ({
    type: SAVING_EDIT_PLANNING_DATA_FAIL,
    payload: error,
});

export const savingDeletePlanningDataSuccess = () => ({
    type: SAVING_DELETE_PLANNING_DATA_SUCCESS
});

export const closeErrorDialog = () => ({
    type: CLOSE_ERROR_DIALOG
});

export const changePlanningMode = ({ mode, planning }) => ({
    type: CHANGE_PLANNING_MODE,
    payload: { 
        mode, 
        planning 
    }
});

export const setPlanningViewMode = ( data ) => ({
    type: PLANNING_VIEW_MODE,
    payload: data
});

export const addOpenInterval = (data) => ({
    type: ADD_OPEN_INTERVAL,
    payload: data,
});
export const removeOpenInterval = (data) => ({
    type: REMOVE_OPEN_INTERVAL,
    payload: data,
});

export const editOpenIntervalsCancel = (data) => ({
    type: EDIT_OPEN_INTERVALS_CANCEL,
    payload: data,
});

export const editOpenHourInterval = (data) => ({
    type: EDIT_OPEN_HOUR_INTERVAL,
    payload: data
})

export const editOpenHourIntervalComplete = (data) => ({
    type: EDIT_OPEN_HOUR_INTERVAL_COMPLETE,
    payload: data,
})

export const toggleOpenHourIntervalEnable = (index) => ({
    type: EDIT_OPEN_HOUR_INTERVAL_ENABLE,
    payload: index
})

export const addHappyInterval = (data) => ({
    type: ADD_HAPPY_INTERVAL,
    payload: data,
});
export const removeHappyInterval = (data) => ({
    type: REMOVE_HAPPY_INTERVAL,
    payload: data,
});
export const editHappyIntervalsCancel = (data) => ({
    type: EDIT_HAPPY_INTERVALS_CANCEL,
    payload: data,
});

export const editHappyHourInterval = (data) => ({
    type: EDIT_HAPPY_INTERVAL,
    payload: data
})

export const editHappyIntervalComplete = (data) => ({
    type: EDIT_HAPPY_INTERVAL_COMPLETE,
    payload: data,
})

export const toggleHappyIntervalEnable = (index) => ({
    type: EDIT_HAPPY_INTERVAL_ENABLE,
    payload: index
})

export const editHappyHourSuccessful = () => ({
    type: EDIT_HAPPY_HOUR_SUCCESSFUL
})


export const setPlanningSelected = (data) => ({
    type: PLANNING_SELECTED_TYPE,
    payload: data,
});

export const setPlanningRangeHours = ( data ) => ({
    type: PLANNING_SET_RANGE_HOURS,
    payload: data,
});

export const setSpecialDatesForm = ( data ) => ({
    type: PLANNING_SET_SPECIAL_DATES_FORM,
    payload: data,
});

export const setClosingDatesForm = ( data ) => ({
    type: PLANNING_SET_CLOSING_FORM,
    payload: data,
});

export const setFromRangeHours = ({ id, fromHour, arrayDate }) => ({
    type: PLANNING_CHANGE_FROM_HOUR,
    payload: {
        arrayDate,
        fromHour,
        id, 
    },
});

export const setToRangeHours = ({ id, toHour, arrayDate }) => ({
    type: PLANNING_CHANGE_TO_HOUR,
    payload: {
        arrayDate,
        toHour,
        id, 
    },
});

export const setPlanningReapeatEvery = ( data ) => ({
    type: PLANNING_CHANGE_WEEk_REPEAT,
    payload: data,
});

export const setNewPunctualDate = ( data ) => ({
    type: PLANNING_ADD_NEW_DATE,
    payload: data,
});

export const setNewExcludeDate = ( data ) => ({
    type: PLANNING_EXCLUDE_DATE,
    payload: data,
});

export const onDeleteDate = ({ dateArray, id }) => ({
    type: PLANNING_REMOVE_DATES,
    payload: {
        dateArray, 
        id
    }
});

export const setChangePlanningDate = ({ id, newDate, dateArray }) => ({
    type: PLANNING_CHANGE_DATE,
    payload: {
        id, 
        newDate, 
        dateArray
    }
});

export const setSingleDate = ({ newDate, dateArray, range }) => ({
    type: PLANNING_ADD_SINGLE_DATE,
    payload: {
        newDate,
        dateArray,
        range
    }
});

export const onDeleteSingleDate = ({ id, dateArray, typeDate }) => ({
    type: PLANNING_DELETE_SINGLE_DATE,
    payload: {
        id,
        dateArray,
        typeDate
    }
});

export const setGetPlanningData = ({ arrayDate, getData }) => ({
    type: FETCH_PLANNINGS,
    payload: {
        arrayDate, 
        getData
    }
});

export const setPlanningName = ( data ) => ({
    type: PLANNING_SET_NAME,
    payload: data
});

export const setEditPlanning = ({ id, typeArray }) => ({
    type: EDIT_PLANNING,
    payload: {
        id,
        typeArray
    }
});

export const setPlanningIdEdit = ( id ) => ({
    type: PLANNING_ID_EDIT,
    payload: id
});

export const setShowPlanningErrors = ({ errorMessage, type }) => ({
    type: PLANNING_SHOW_ERRORS,
    payload: {
        errorMessage,
        type
    }
});

export const setShowPlanningSnackbar = ( planningSnackbar ) => ({
    type: PLANNINGS_SHOW_SNACKBAR,
    payload: planningSnackbar
});

export const setRestartHours = () => ({
    type: PLANNING_RESTART_DATES
});

export const setAllDates = ( data ) => ({
    type: PLANNING_ADD_ALL_DATES,
    payload: data
});

export const setDeleteAllDates = ({ value, typeDate }) => ({
    type: PLANNING_REMOVE_ALL_DATES,
    payload:{ 
        value,
        typeDate 
    }
});

export const setAllExcludeDates = ( data ) => ({
    type: PLANNING_ADD_ALL_EXCLUDE_DATES,
    payload: data
});

export const setDeleteAllExcludeDates = ({ value, typeDate }) => ({
    type: PLANNING_REMOVE_ALL_EXCLUDE_DATES,
    payload: {
        value, 
        typeDate
    } 
});

export const ensurePartnerExist = ( partner ) => {
    if (!partner) {
        throw new Error('Partner not found');
    }
};

export const getPlanning = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        dispatch( startLoadingPlanning() );

        try {

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken( true );

            const res = await axios.get(`${ API_URL }/establishment/planning/${ idPartnerSelected }`, {
                headers: { Authorization: `Bearer ${ token }` }
            });

            const { data } = res;

            dispatch( setGetPlanningData({ arrayDate: 'specialDates', getData: data.specialDates }) );
            dispatch( setGetPlanningData({ arrayDate: 'opennings', getData: data.openings }) );
            dispatch( setGetPlanningData({ arrayDate: 'closings', getData: data.closings }) );
            
            dispatch( loadPlanningDataSuccess(data) );
        } catch (error) {
            console.log(error);
            dispatch( loadPlanningDataFail(error.message) );
            dispatch( setShowPlanningErrors({
                errorMessage: error.response.data,
                type: 'Error'
            }));
        }
    }
};

export const resetPlanningData = () => ({
    type: RESET_PLANNING_DATA,
});

// export const putNewOpeningDate = () => {

//     return async (dispatch, getState, { getFirebase }) => {
//         const { firebase } = getState();
//         const { profile } = firebase;

//         try {
//             ensurePartnerExist( profile.partner );
//             dispatch( startLoadingPlanning() );

//             const API_URL = process.env.REACT_APP_API_URL;
//             const auth = getFirebase().auth();
//             const token = await auth.currentUser.getIdToken(true);
//             await axios.put(`${API_URL}/users/${profile.partner}`, newUser, {headers: {Authorization: `Bearer ${token}`}})

//             const { data } = res;

//             console.log( 'DATA --> ', data );

//             dispatch( loadPlanningDataSuccess(data) );

//         } catch (error) {
//             console.log(error);
//             dispatch( loadPlanningDataFail(error.message) );
//         }
//     }

// };

// export const fetchPlanning = () => {
//     return async (dispatch, getState, {getFirebase}) => {
//         const {firebase} = getState();
//         const {profile} = firebase;

//         try {
//             ensurePartnerExist(profile.partner)
//             dispatch(startLoadingPlanning());
//             const API_URL = process.env.REACT_APP_API_URL;
//             const auth = getFirebase().auth();
//             const token = await auth.currentUser.getIdToken(true);
//             const res = await axios.get(`${API_URL}/establishment/general-info/${profile.partner}`, {headers: {Authorization: `Bearer ${token}`}})
//             const {data} = res;
//             dispatch(loadPlanningDataSuccess(data));
//         } catch (error) {
//             console.log(error);
//             dispatch(loadPlanningDataFail(error.message));
//         }
//     }
// };

// export const savePlanning = () => {
//     return async (dispatch, getState, {getFirebase}) => {
//         const {planningReducer, firebase} = getState();
//         const {happyIntervals, openIntervals} = planningReducer;

//         const {profile} = firebase;


//         dispatch(savingPlanningData());

//         let updateData = {};
//         updateData["happyHourIntervals"] = happyIntervals
//         updateData["openingHoursIntervals"] = openIntervals
//         try {
//             ensurePartnerExist(profile.partner);
//             const API_URL = process.env.REACT_APP_API_URL;
//             const auth = getFirebase().auth();
//             const token = await auth.currentUser.getIdToken(true);
//             const res = await axios.patch(`${API_URL}/establishment/general-info/${profile.partner}`, updateData, {headers: {Authorization: `Bearer ${token}`}});
//             if (res.status > 300) {
//                 throw new Error(res.data);
//             }
//             dispatch(savingPlanningDataSuccess());
//             dispatch(fetchPlanning());
//             return true;
//         } catch (error) {
//             if (error.response?.data === 'Days length in TimeInterval can\'t be less than 1') {
//                 dispatch(savingPlanningDataFail("Los horarios deben tener al menos un día seleccionado"));
//             } else {
//                 dispatch(savingPlanningDataFail(error.message));
//             }
//             return false;
//         }
//     }
// };


