import React from 'react';

import { connect } from 'react-redux';

// Actions
import { setOpenSidebar } from '../../../../../actions/myBookingsActions';

// Components
import TableRowClients from '../../../../MyClients/TableRowClients';
import TableHeadSection3 from './TableHeadSection3';
import TableRowSection3 from './TableRowSection3';

// Helpers
// import { CreateData, getComparator, stableSort } from '../../helpers';

// Material Ui
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

// Styles
import { TableContainerStyles } from './Style/Section3TableStyles';

const GeneralTableSection3 = ( props ) => {

    // Actions
    const { setOpenSidebar } = props;

    // Externar props
    const { 
        headCellsTable,
        onChangeOrder, 
        totalResults,
        rowsPerPage,
        component, 
        loading,
        orderBy,
        items, 
        order,
        page, 
    } = props;
    
    const handleClickSidebar = () => {
        setOpenSidebar( true );
    };

    const handleRequestSort = ( head, type ) => {
        console.log( '-----> ', head, type );
        onChangeOrder( head, type );
    };

    return (
        <>
            <TableContainer sx={{ borderRadius: '0', backgroundColor: '#FFFFFF' }}>
                <Table sx={ TableContainerStyles } size="small">
                    <TableHeadSection3
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={ handleRequestSort }
                        headCellsTable={ headCellsTable }
                        totalResults={ totalResults }
                        rowsPerPage={ rowsPerPage }
                        orderBy={ orderBy }
                        order={ order }
                        page={ page }
                    />
                    {
                        component !== 'clients' ?
                            <TableBody>
                                {
                                    loading
                                        ? headCellsTable.map((item) => (
                                                <TableRow key={item.id}>
                                                    {headCellsTable.map((headCell) => (
                                                        <TableCell key={headCell.id}>
                                                            <Skeleton />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        : items.map((item, index) => (
                                            <TableRowSection3
                                                bookingSpecialRelationship={ item.specialRelationship }
                                                bookingFoodRestictions={ item.foodRestrictions } 
                                                bookingNationality={ item.phoneISOCode } 
                                                bookingClientNote={ item.clientComment } 
                                                bookingClientLastname={ item.lastname } 
                                                bookingPartnerNote={ item.partnerNote } 
                                                bookingAmountPeople={ item.quantity }
                                                bookingCreateDate={ item.createdAt } 
                                                bookingOptional={ item.optionals }
                                                bookingPhoneNumber={ item.phone } 
                                                bookingClientName={ item.name } 
                                                clientNote={ item.clientNote } 
                                                onClick={ handleClickSidebar }
                                                bookingOrigin={ item.origin }
                                                bookingSector={ item.sector }
                                                bookingTables={ item.table }
                                                bookingState={ item.state } 
                                                bookingShift={ item.shift } 
                                                bookingDate={ item.date } 
                                                bookingHour={ item.hour }
                                                bookingTags={ item.tags }
                                                bookingLESector={ '' }
                                                bookingId={ item.id }
                                                bookingLEHour={ '' }
                                                key={ index }
                                            />
                                        ))
                                }
                            </TableBody>
                        :
                            <TableBody>
                                {
                                    loading
                                        ? headCellsTable.map((item) => (
                                                <TableRow key={item.id}>
                                                    {headCellsTable.map((headCell) => (
                                                        <TableCell key={headCell.id}>
                                                            <Skeleton />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        : items.map((item, index) => (
                                            <TableRowClients 
                                                clientSpecialRelationship={ item.specialRelationship }
                                                clientFoodRestictions={ item.foodRestrictions }
                                                clientAlias={ item.alias ? item.alias : '' }
                                                clientTotalBookings={ item.bookings.length } 
                                                clientCancellations={ item.cancellations } 
                                                clientFutureReservations={ item.active } 
                                                clientNationality={ item.nationality } 
                                                clientLastBooking={ item.lastVisit }
                                                // Arreglo de bug 
                                                clientLastName={ item.lastname }
                                                clientNote={ item.clientNote }
                                                onClick={ handleClickSidebar }
                                                clientVisits={ item.incomes } 
                                                clientNoShows={ item.fouls } 
                                                clientEmail={ item.email }
                                                clientPhone={ item.phone } 
                                                clientName={ item.name }
                                                clientTags={ item.tags }
                                                clientId={ item.id }
                                                // clientPunctuation={ '5.5' } 
                                                // clientReviews={ '123' }
                                                key={ item.id }
                                            />
                                        ))
                                }
                            </TableBody>
                    }
                </Table>
            </TableContainer>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenSidebar: (data) => dispatch(setOpenSidebar(data)),
    };
};

export default connect( null, mapDispatchToProps )(GeneralTableSection3);