import React from 'react';

// Icons
import DeleteIconModal from '../../SvgComponents/icons/Optional/DeleteIconModal';
import ClearIcon from '@mui/icons-material/Clear';

// Material Ui
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';

// Styles
import { 
    DOModalButtonsContainerStyles, 
    DOModalTextContainerStyles, 
    DOButtonBackModalStyles, 
    DeleteOptionalModal, 
    CrossDOModalStyles, 
    DOIconModalStyles, 
    DOModalSubtitle, 
    DOModalTitle, 
} from '../../Optional/Styles/OptionalStyles';


const PlanningModal = ( props ) => {

    // Actions
    

    // External props
    const { 
        backButtonText, 
        nextButtonText, 
        description,
        onDelete,
        onClick, 
        setShow, 
        title, 
        color,
        type, 
        show, 
    } = props;

    const handleOnClose = () => {
        if ( type === 'delete' ) {
            setShow({
                ...show,
                deleteModal: false
            });
            onDelete();
        } else if ( 'editCreate' ) {
            setShow({
                ...show,
                editCreateModal: false
            });
            onClick();
        } else {
            setShow({
                ...show,
                excludeDate: false
            });
        }
    };

    const handleCancel = () => {
        setShow( false );
    };

    return (
        <Modal
            slots={{ backdrop: Backdrop }}
            open={ show }
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ show }>
                <Box sx={ DeleteOptionalModal }>
                    <Box sx={ CrossDOModalStyles } onClick={ handleCancel }>
                        <ClearIcon sx={{ color: '#041C32' }}/>
                    </Box>
                    <Box sx={ DOIconModalStyles }>
                        <DeleteIconModal/>
                    </Box>
                    <Box sx={ DOModalTextContainerStyles }>
                        <Typography sx={ DOModalTitle }>
                            { title } 
                        </Typography>
                        <Typography sx={ DOModalSubtitle }>
                            { description } 
                        </Typography>
                    </Box>
                    <Box sx={ DOModalButtonsContainerStyles }>
                        <Button onClick={ handleCancel } sx={ DOButtonBackModalStyles }>
                            { backButtonText }
                        </Button>
                        <Button 
                            sx={{
                                backgroundColor: color === 'red' ? '#FB3F4A' : '#0B4762',  
                                textTransform: 'none',
                                fontFamily: 'Poppins',
                                borderRadius: '8px',
                                fontSize: '.9rem',
                                fontWeight: 600,
                                color: 'white',
                                width: '100%',
                                p: '.3rem 0',
                                '&:hover': {
                                    backgroundColor: color === 'red' ? '#FB3F4A !important' : '#0B4762 !important',  
                                }
                            }} 
                            onClick={ handleOnClose }
                        >
                            { nextButtonText }
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default PlanningModal;