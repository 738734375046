export const OptionalSelectArray = [
    {
        id: 0,
        option: 'Selector de cantidad',
        value: 'quantity_selector'
    },
    {
        id: 1,
        option: 'Click único',
        value: 'unique_selector'
    },
    {
        id: 2,
        option: 'Opción múltiple',
        value: 'multiple_selector'
    },
    {
        id: 3,
        option: 'Sí/No',
        value: 'yes_no'
    },
    {
        id: 4,
        option: 'Sí/No con nota',
        value: 'yes_with_note'
    },

];

export const showOptionSelectedName = ( option ) => {
    switch ( option ) {
        case 'yes_with_note':
            return 'Sí/No con nota';
        case 'multiple_selector':
            return 'Opción múltiple';
        case 'yes_no':
            return 'Sí/No';
        case 'unique_selector':
            return 'Click único';
        case 'quantity_selector':
            return 'Selector de cantidad';
        default:
            break;
    }
};

export const saveOptionSelectedName = ( option ) => {
    switch ( option ) {
        case 'Sí/No con nota':
            return 'yes_with_note';
        case 'Opción múltiple':
            return 'multiple_selector';
        case 'Sí/No':
            return 'yes_no';
        case 'Click único':
            return 'unique_selector';
        case 'Selector de cantidad':
            return 'quantity_selector';
        default:
            break;
    }
};