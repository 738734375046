import React, {useState,} from 'react';
import Button from '@material-ui/core/Button';
import {TextField,} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useFirebase} from 'react-redux-firebase';
import {validatePassword,} from '../../../utils/validation';
import {
    chageEditUserPassword,
    chageEditUserPasswordTwo,
    editPasswordCancel,
    editPasswordSuccess,
    setErrorInNewPassword,
    setErrorInNewPasswordTwo,
} from '../../../actions';
import {connect} from 'react-redux';

function EditUserDialog(props) {
    const firebase = useFirebase();
    const [loading, setLoading] = useState(false);
    const {
        editPasswordSuccess, editPasswordCancel, chageEditUserPassword, chageEditUserPasswordTwo,
        newPasswordDialog, newPassword, newPasswordTwo,
        newPasswordError, newPasswordTwoError,
        setErrorInNewPassword, setErrorInNewPasswordTwo,
    } = props;

    function checkPassword() {
        if (!validatePassword(newPassword)) {
            setErrorInNewPassword(true);
            return false;
        } else {
            setErrorInNewPassword(false);
            return true;
        }
    }

    function checkPasswordTwo() {
        if (!validatePassword(newPasswordTwo) || newPasswordTwo !== newPassword) {
            setErrorInNewPasswordTwo(true);
            return false;
        } else {
            setErrorInNewPasswordTwo(false);
            return true;
        }
    }

    const submitPassword = async () => {
        setLoading(true);
        // Realizar validaciones
        if (checkPassword() && checkPasswordTwo()) {
            let user = firebase.auth().currentUser;

            await user.updatePassword(newPassword).then(function () {
                editPasswordSuccess();
            }).catch(function (error) {
                editPasswordCancel();
            });
        }
        setLoading(false);
    }

    return (
        <div>
            <Dialog open={newPasswordDialog} onClose={() => editPasswordCancel()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Cambiar contraseña</DialogTitle>
                <DialogContent>
                    <TextField value={newPassword} error={newPasswordError} onBlur={checkPassword} onChange={e => chageEditUserPassword(e.target.value)} autoFocus margin="dense" id="password1" label="Nueva contraseña" type="password" fullWidth />
                    <TextField value={newPasswordTwo} error={newPasswordTwoError} onBlur={checkPasswordTwo} onChange={e => chageEditUserPasswordTwo(e.target.value)} margin="dense" id="password2" label="Repetir nueva contraseña" type="password" fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => editPasswordCancel()} color="primary">Cancelar</Button>
                    <Button onClick={() => submitPassword()} color="primary">Modificar</Button>
                </DialogActions>
                {loading ? <LinearProgress /> : null}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        newPasswordDialog: state.authReducer.newPasswordDialog,
        newPassword: state.authReducer.newPassword,
        newPasswordTwo: state.authReducer.newPasswordTwo,
        newPasswordError: state.authReducer.newPasswordError,
        newPasswordTwoError: state.authReducer.newPasswordTwoError,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        editPasswordSuccess: () => dispatch(editPasswordSuccess()),
        editPasswordCancel: () => dispatch(editPasswordCancel()),
        chageEditUserPassword: (data) => dispatch(chageEditUserPassword(data)),
        chageEditUserPasswordTwo: (data) => dispatch(chageEditUserPasswordTwo(data)),

        setErrorInNewPassword: (data) => dispatch(setErrorInNewPassword(data)),
        setErrorInNewPasswordTwo: (data) => dispatch(setErrorInNewPasswordTwo(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserDialog);
