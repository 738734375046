import React from 'react';
import ButtonWithIcon from '../NewComponents/ButtonWithIcon/ButtonWithIcon';

// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import DiscountItem from './DiscountItem';

const useStyles = makeStyles(() => ({
    MainContainer: {
        fontFamily: 'Poppins, sans-serif',
        paddingRight: '5rem',
        '@media (min-width: 1700px)': {
            paddingRight: '15rem',
        },
        height: '90vh',
    },
    TitleContainer: {
        justifyContent: 'space-between',
        padding: '0rem 2rem 1rem .5rem',
        display: 'flex',
    },
    DiscountItemsContainer: {

    }
}));

const Discount = () => {

    const classes = useStyles();

    return (
        <main className={classes.MainContainer}>
            <div className={classes.TitleContainer}>
                <h1 style={{margin: '0px', fontWeight: '600', fontSize: '1.6rem'}}>Descuentos</h1>
                <ButtonWithIcon
                    text={'Agregar descuento'}
                    icon={<AddCircleOutlineOutlinedIcon/>}
                    minWidth={'10%'}
                    margin={'0'}
                />
            </div>
            <div className={classes.DiscountItemsContainer}>
                <DiscountItem/>
            </div>
        </main>
    )
}

export default Discount;
