import {
    ADD_SECTOR_COMBINE_TABLES,
    CHANGE_COMBINATION_MAX_CAPACITY,
    CHANGE_COMBINATION_MIN_CAPACITY,
    CHANGE_COMBINATION_NAME,
    CHANGE_EDITING_TABLE,
    CHANGE_SECTOR_MAX_GROUP,
    CHANGE_SECTOR_MODE,
    CHANGE_SECTOR_NAME,
    CHANGE_SECTOR_TYPE,
    CHANGE_TABLE_PROPERTIES,
    CLOSE_SECTOR_ERROR,
    DELETE_SECTOR_FAILURE,
    DELETE_SECTOR_SUCCESS,
    LOAD_SECTORS_FAILURE,
    LOAD_SECTORS_SUCCESS,
    REMOVE_SECTOR_COMBINE_TABLES,
    RESET_ALL_DATA,
    SAVING_SECTOR,
    SAVING_SECTOR_FAILURE,
    SAVING_SECTOR_SUCCESS,
    SECTOR_ADD_TABLE,
    SECTOR_CANCEL_COMBINE_TABLES,
    SECTOR_DELETE_COMBINE_TABLES,
    SECTOR_EDIT_COMBINE_TABLES,
    SECTOR_MODE,
    SECTOR_NEW_TABLE,
    SECTOR_REMOVE_TABLE,
    SECTOR_SAVE_COMBINE_TABLES,
    START_DELETE_SECTOR,
    START_LOAD_SECTORS,
    START_NEW_TABLE_COMBINATION
} from "../../constants";
import {arrayEquals, arrayOfObjectsEquals, deepCopyObject} from "../../utils/arrayHelpers";
import {deepEqual} from "../../utils/objectHelpers";
import {v4 as uuidv4} from 'uuid';

const emptyCombinationTable = {
    name: "",
    minCapacity: 0,
    maxCapacity: 0,
    tables: [],
};

const initialState = {
    sectors: [],
    mode: SECTOR_MODE.VIEW,
    sector: undefined,
    originalSector: undefined,
    newTable: undefined,
    editingTable: undefined,
    combinedTables: [],
    combineMode: false,
    newCombineTables: emptyCombinationTable,
    editCombineTables: emptyCombinationTable,
    combinationAlreadyExists: "",

    edited: false,

    loading: false,
    error: "",
};

function removeCombinationFromTables(tables, combination) {
    const tablesCopy = [...tables];
    const combinationCopy = deepCopyObject(combination);
    combinationCopy.tables.sort((a, b) => a.localeCompare(b));
    combinationCopy.tables.forEach((tableName) => {
        const table = tablesCopy.find(t => t.name === tableName);
        if (table) {
            table.combinesWith = table.combinesWith.filter(t => !arrayEquals(t.tables.sort((a, b) => a.localeCompare(b)), combinationCopy.tables.filter(t => t !== tableName)));
        }
    });
    return tablesCopy;
}

function checkCombinationAlreadyExist(allCombinations, combination) {
    if (allCombinations.some(c => c.name === combination.name)) {
        return "Ya existe ese nombre";
    }
    const combinationCopy = deepCopyObject(combination);
    combinationCopy.tables.sort((a, b) => a.localeCompare(b));
    const existsCombination = allCombinations.some(c => arrayEquals(c.tables.sort((a, b) => a.localeCompare(b)), combinationCopy.tables));
    if (existsCombination) {
        return "Ya existe esa combinación";
    }
}

const sectors = (state = initialState, action) => {
    let editTable;
    let tablesEdited;
    switch (action.type) {
        case START_LOAD_SECTORS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case LOAD_SECTORS_SUCCESS:
            return {
                ...state,
                sectors: action.payload,
                loading: false,
                error: "",
            };
        case LOAD_SECTORS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case START_DELETE_SECTOR:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case DELETE_SECTOR_SUCCESS:
            return {
                ...state,
                mode: SECTOR_MODE.VIEW,
                loading: false,
                error: "",
            };
        case DELETE_SECTOR_FAILURE:
            return {
                ...state,
                loading: false,
                mode: SECTOR_MODE.EDIT,
                error: action.payload,
            };
        case CHANGE_SECTOR_MODE:
            return {
                ...state,
                sector: deepCopyObject({
                    ...action.payload.sector,
                    tables: action.payload.sector?.tables
                }),
                originalSector: deepCopyObject({
                    ...action.payload.sector,
                    tables: action.payload.sector?.tables
                }),
                combinedTables: getCombinedTables(action.payload.sector?.tables ?? []),
                mode: action.payload.mode,
                loading: false,
                error: "",
                edited: false,
                combineMode: false,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                editCombineTables: deepCopyObject(emptyCombinationTable),
                combinationAlreadyExists: "",
            };
        case SAVING_SECTOR:
            return {
                ...state,
                loading: true,
                error: "",
                combineMode: false,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                editCombineTables: deepCopyObject(emptyCombinationTable),
            };
        case SAVING_SECTOR_SUCCESS:
            return {
                ...state,
                sector: undefined,
                mode: SECTOR_MODE.VIEW,
                originalSector: undefined,
                loading: false,
                error: "",
                edited: false,
            };
        case SAVING_SECTOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLOSE_SECTOR_ERROR:
            return {...state, error: "", combinationAlreadyExists: ""}

        case CHANGE_SECTOR_NAME:
            return {
                ...state,
                sector: {
                    ...state.sector,
                    alias: action.payload,
                },
                loading: false,
                error: "",
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    alias: action.payload,
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    alias: action.payload,
                }.tables),
            };
        case CHANGE_SECTOR_MAX_GROUP:
            return {
                ...state,
                sector: {
                    ...state.sector,
                    maxGroup: action.payload,
                },
                loading: false,
                error: "",
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    maxGroup: action.payload,
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    maxGroup: action.payload,
                }.tables),
            };
        case CHANGE_SECTOR_TYPE:
            return {
                ...state,
                sector: {
                    ...state.sector,
                    type: action.payload,
                },
                loading: false,
                error: "",
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    type: action.payload,
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    type: action.payload,
                }.tables),
            };
        case CHANGE_EDITING_TABLE:
            return {
                ...state,
                editingTable: action.payload,
            };
        case CHANGE_TABLE_PROPERTIES:
            editTable = state.sector.tables.find(table => table.name === action.payload.tableName);
            editTable.alias = action.payload.alias ?? editTable.alias;
            editTable.minCapacity = action.payload.minCapacity ?? editTable.minCapacity;
            editTable.maxCapacity = action.payload.maxCapacity ?? editTable.maxCapacity;
            editTable.autoAssign = action.payload.autoAssign ?? editTable.autoAssign;
            editTable.shared = action.payload.shared ?? editTable.shared;
            editTable.point = action.payload.point ?? editTable.point;
            editTable.box = action.payload.box ?? editTable.box;
            tablesEdited = [...state.sector.tables];
            tablesEdited[action.payload.index] = editTable;
            return {
                ...state,
                sector: {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                },
                loading: false,
                error: "",
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }.tables),
            };
        case SECTOR_ADD_TABLE:
            tablesEdited = [...state.sector.tables];
            const newTableAdded = {
                name: uuidv4(),
                alias: action.payload.i,
                autoAssign: true,
                combinesWith: [],
                shared: false,
                minCapacity: action.payload.minCapacity,
                maxCapacity: action.payload.maxCapacity,
                point: {x: action.payload.x, y: action.payload.y},
                box: {width: action.payload.w, height: action.payload.h},
                isResizable: action.payload.isResizable,
                circle: action.payload.circle,
                priority: false,
            };
            tablesEdited.push(newTableAdded);
            return {
                ...state,
                sector: {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                },
                editingTable: newTableAdded,
                loading: false,
                error: "",
                newTable: undefined,
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }.tables),
            };
        case SECTOR_REMOVE_TABLE:
            tablesEdited = state.sector.tables.filter(t => t.name !== action.payload);
            tablesEdited = tablesEdited.map(t => ({
                ...t,
                combinesWith: t.combinesWith.filter(c => !c.tables.includes(action.payload)),
            }));
            return {
                ...state,
                sector: {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                },
                combinedTables: getCombinedTables(tablesEdited),
                loading: false,
                error: "",
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }) || !arrayOfObjectsEquals(state.originalSector.tables, {
                    ...state.sector,
                    tables: tablesEdited,
                    capacity: tablesEdited.reduce((acc, table) => acc + (table.autoAssign ? table.maxCapacity : 0), 0),
                    maxCapacity: tablesEdited.reduce((acc, table) => acc + table.maxCapacity, 0),
                }.tables),
            };
        case SECTOR_NEW_TABLE:
            return {
                ...state,
                newTable: {
                    ...action.payload,
                    i: state.sector.tables.length > 0
                        ? (parseInt(state.sector.tables[state.sector.tables.length - 1].alias) + 1).toString()
                        : "1",
                },
            };

        case START_NEW_TABLE_COMBINATION:
            return {
                ...state,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                combineMode: true,
            }

        case CHANGE_COMBINATION_NAME:
            return {
                ...state,
                newCombineTables: {
                    ...state.newCombineTables,
                    name: action.payload,
                }
            }

        case CHANGE_COMBINATION_MIN_CAPACITY:
            return {
                ...state,
                newCombineTables: {
                    ...state.newCombineTables,
                    minCapacity: action.payload,
                }
            }

        case CHANGE_COMBINATION_MAX_CAPACITY:
            return {
                ...state,
                newCombineTables: {
                    ...state.newCombineTables,
                    maxCapacity: action.payload,
                }
            }

        case ADD_SECTOR_COMBINE_TABLES:
            let newCombination = {...state.newCombineTables};
            newCombination.tables.push(action.payload.name);
            newCombination.name = newCombination.name === "" ? action.payload.alias : newCombination.name + "/" + action.payload.alias;
            newCombination.minCapacity = newCombination.minCapacity + action.payload.minCapacity;
            newCombination.maxCapacity = newCombination.maxCapacity + action.payload.maxCapacity;
            return {
                ...state,
                newCombineTables: deepCopyObject(newCombination),
            }

        case REMOVE_SECTOR_COMBINE_TABLES:
            let newCombination2 = {...state.newCombineTables};
            newCombination2.tables = newCombination2.tables.filter(t => t !== action.payload.name);
            newCombination2.name = state.sector.tables.filter(t => newCombination2.tables.includes(t.name)).map(t => t.alias).join("/");
            const table = state.sector.tables.find(t => t.name === action.payload.name);
            newCombination2.minCapacity = newCombination2.minCapacity - table.minCapacity;
            newCombination2.maxCapacity = newCombination2.maxCapacity - table.maxCapacity;
            return {
                ...state,
                newCombineTables: deepCopyObject(newCombination2),
            }

        case SECTOR_SAVE_COMBINE_TABLES:
            let combination = {...state.newCombineTables};
            combination.maxCapacity = Number(combination.maxCapacity);
            combination.minCapacity = Number(combination.minCapacity);
            let combinedTables = [...state.combinedTables];
            tablesEdited = state.sector.tables.map(t => deepCopyObject(t));

            // En caso de estar editando la combinacion, se "elimina" de la comprobacion de duplicados
            if (!deepEqual(state.editCombineTables, emptyCombinationTable)) {
                const editCombinationTable = state.editCombineTables;
                editCombinationTable.tables.sort((a, b) => a.localeCompare(b));
                combinedTables = combinedTables.filter(t => {
                    t.tables.sort((a, b) => a.localeCompare(b));
                    return !deepEqual(t, editCombinationTable);
                })
            }

            const combinationAlreadyExists = checkCombinationAlreadyExist(combinedTables, combination);
            // Si la combination ya existe, se lanza un error
            if (combinationAlreadyExists) {
                return {
                    ...state,
                    combinationAlreadyExists: combinationAlreadyExists,
                }
            }

            // Si se estaba editando una combinacion y se guardan los cambios
            // se debe eliminar la combinacion vieja y agregar la nueva
            if (!deepEqual(state.editCombineTables, emptyCombinationTable)) {
                tablesEdited = removeCombinationFromTables(tablesEdited, state.editCombineTables);
            }

            // Se agrega la nueva combinacion a cada mesa de la combinacion
            /*combination.tables.forEach(table => {
                tablesEdited[tablesEdited.findIndex(t => t.name === table)].combinesWith.push({
                    ...combination,
                    tables: combination.tables.filter(t => t !== table),
                });
            })*/

            tablesEdited.forEach((tableEdited) => {
                if (combination.tables.includes(tableEdited.name)) {
                    const tables = combination.tables.filter(t => t !== tableEdited.name);
                    const newCombinationToAdd = {
                        maxCapacity: combination.maxCapacity,
                        minCapacity: combination.minCapacity,
                        name: combination.name,
                        tables
                    };
                    tableEdited.combinesWith.push(deepCopyObject(newCombinationToAdd))
                }
            })
            return {
                ...state,
                sector: {
                    ...state.sector,
                    tables: tablesEdited,
                },
                loading: false,
                combineMode: false,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                editCombineTables: deepCopyObject(emptyCombinationTable),
                combinedTables: getCombinedTables(tablesEdited),
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    tables: tablesEdited,
                }) || !arrayOfObjectsEquals(state.originalSector.tables, tablesEdited),
            }

        case SECTOR_CANCEL_COMBINE_TABLES:
            return {
                ...state,
                combineMode: false,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                editCombineTables: deepCopyObject(emptyCombinationTable),
            }

        case SECTOR_DELETE_COMBINE_TABLES:
            tablesEdited = [...state.sector.tables];
            tablesEdited = removeCombinationFromTables(tablesEdited, action.payload);
            return {
                ...state,
                sector: {
                    ...state.sector,
                    tables: tablesEdited,
                },
                combineMode: false,
                newCombineTables: deepCopyObject(emptyCombinationTable),
                editCombineTables: deepCopyObject(emptyCombinationTable),
                combinedTables: getCombinedTables(tablesEdited),
                edited: !deepEqual(state.originalSector, {
                    ...state.sector,
                    tables: tablesEdited,
                }) || !arrayOfObjectsEquals(state.originalSector.tables, tablesEdited),
            }

        case SECTOR_EDIT_COMBINE_TABLES:
            return {
                ...state,
                combineMode: true,
                newCombineTables: deepCopyObject(action.payload),
                editCombineTables: deepCopyObject(action.payload),
            }
        case RESET_ALL_DATA:
            return initialState;
        default:
            return state;
    }
}

const getCombinedTables = (tables) => {
    const combinedTables = [];
    tables.forEach((table) => {
        if (table.combinesWith.length > 0) {
            table.combinesWith.forEach((combinedTable) => {
                const combinedTableSorted = deepCopyObject(combinedTable);
                combinedTableSorted.tables.push(table.name)
                combinedTableSorted.tables.sort((a, b) => a.localeCompare(b));
                if (!combinedTables.some(t => deepEqual({...t, name: undefined}, {
                    ...combinedTableSorted,
                    name: undefined
                }))) {
                    combinedTables.push(combinedTableSorted);
                }
            })
        }
    });
    return combinedTables;
}

export default sectors;
