import {Grid, Paper} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MesaYaTop from "../MesaYaConfig/MesaYa Top/MesaYaTop";
import GeneralIcon from "../SvgComponents/icons/GeneralIcon/GeneralIcon";

const useStyles = makeStyles(() => ({
    appBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: 30,
        marginBottom: 50,
        border: 0,
        position: 'relative',
        zIndex: '1',
        height: '85px',
        width: '98%',
        marginLeft: 20,
        borderRadius: '40px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    },
    unselectedChip: {
        backgroundColor: "#FFFFFF",
        color: "#0076FF",
        fontWeight: '900',
        fontSize: '14px',
        marginTop: 5,
        marginRight: 20,
        marginBottom: 5,
        fontFamily: 'Montserrat',
        width: 'auto',
        padding: '10px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer'
    },
    selectedChip: {
        backgroundColor: "#0076FF",
        color: "#FFFFFF",
        fontSize: '14px',
        marginTop: 5,
        marginRight: 20,
        marginBottom: 5,
        fontFamily: 'Montserrat',
        width: 'auto',
        padding: '10px 20px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer'
    },
}))

const DateSelector = (props) => {
    const classes = useStyles();
    const {date, isMY} = props;
    const {changeDate} = props;

    return (
        <Paper className={classes.appBar} elevation={3}>
            <Grid container justify={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Typography style={{margin: 5}} variant={'h4'}>
                        {isMY ?
                            <div
                                style={{
                                    margin: '0px',
                                    position: 'absolute',
                                    zIndex: '2',
                                    marginTop: '-80px',
                                    marginLeft: '-40px'
                                }}>
                                <MesaYaTop/>
                            </div>
                            :
                            <div
                                style={{
                                    margin: '0px',
                                    position: 'absolute',
                                    zIndex: '2',
                                    marginTop: '-80px',
                                    marginLeft: '-40px',
                                }}
                            >
                                <GeneralIcon name="Reservas"/>
                            </div>
                        }
                    </Typography>
                </Grid>
                {/*<Grid item style={{marginBottom: '25px'}}>
                    {Object.keys(DATE_FILTER).map((key) => (
                        <Chip label={key}
                              className={DATE_FILTER[`${key}`] === date ? classes.selectedChip : classes.unselectedChip}
                              key={key} clickable={false} onClick={() => changeDate(DATE_FILTER[`${key}`])}/>
                    ))}
                </Grid>*/}
            </Grid>
        </Paper>
    );
}

export default DateSelector;
