import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { setSidebarBookingId } from '../../../../../actions/myBookingsActions';

// Helpers
import { ShowBookingsTableIcons, handleShowBookingOrigin, handleShowBookingState } from '../../helpers';

// Icons
import AmountPeopleIcon from '../../../../SvgComponents/Icons Tables/MyBookings/AmountPeopleIcon';

// Material Ui
import { Box, TableCell, TableRow, Typography } from '@mui/material';

// Styles
import { 
    TablePhoneContainerStyles,
    AmountPeopleContainer, 
    ShiftSectorContainer, 
    // OriginTextStyles,
    CNContainerStyles, 
    TableTextsStyles,
    ShiftTextStyles, 
    TextsStyles,
} from './Style/Section3TableStyles';

// Utils
import { showCountryFlag } from '../../../../../utils/CountryFlagsArray';

const TableRowSection3 = ( props ) => {

    // Actions
    const { setSidebarBookingId } = props;

    // External props
    const { 
        bookingSpecialRelationship,
        bookingFoodRestictions,
        bookingClientLastname, 
        bookingAmountPeople,
        bookingPartnerNote,
        bookingPhoneNumber,
        bookingNationality,
        bookingClientName, 
        bookingCreateDate,
        bookingClientNote,
        bookingOptional,
        bookingLESector,
        bookingLEHour,
        bookingTables,
        bookingSector,
        bookingOrigin,
        bookingState, 
        bookingShift, 
        bookingDate, 
        bookingHour,
        bookingTags,
        clientNote,
        bookingId,
        onClick 
    } = props;

    const handleShowSidebar = (e, id) => {
        setSidebarBookingId( id );

        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return;
        }

        onClick();
    }

    return (
        <TableRow key={ bookingId }>
            <TableCell sx={{ p: '.5rem', boxSizing: 'border-box' }}>
                <Box 
                    onClick={ (e) => handleShowSidebar(e, bookingId) } 
                    sx={ CNContainerStyles }
                >
                    <Typography variant="p" align="left" color='#2A2742' sx={ TextsStyles }>
                        { bookingClientName } { bookingClientLastname }
                    </Typography>
                    <Box sx={{ height: '30px' }}>
                        {
                            // Respetar orden
                            ShowBookingsTableIcons(
                                bookingSpecialRelationship, 
                                bookingPartnerNote,
                                bookingClientNote, 
                                bookingFoodRestictions,
                                clientNote,
                                bookingOptional,
                                bookingLESector, 
                                bookingLEHour,
                                bookingTags,
                            )
                        }
                    </Box>
                </Box>
            </TableCell>
            <TableCell align='center'>
                <Box sx={ TablePhoneContainerStyles }>
                    <Box className="flagContainer">{ showCountryFlag( bookingNationality ) }</Box>
                    <Typography variant="p" align="left" color='#2A2742' sx={ TextsStyles }>
                        { bookingPhoneNumber }
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align='center'>
                { handleShowBookingState( bookingState ) }
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' sx={ TextsStyles }>{ bookingDate }</Typography>
            </TableCell>
            <TableCell align='center'>
                    {
                        bookingShift ? 
                            <Box sx={ ShiftSectorContainer }>
                                <Typography variant='subtitle2' color='#0B4762' sx={ ShiftTextStyles }>
                                    { bookingShift }
                                </Typography>
                            </Box>
                        :
                            null
                    }
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ bookingHour }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Box sx={ AmountPeopleContainer }>
                    <AmountPeopleIcon/>
                    <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>
                        { bookingAmountPeople } personas
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align='center'>
                {
                    bookingSector ?
                        <Box sx={ ShiftSectorContainer }>
                            <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ bookingSector }</Typography>
                        </Box>
                    :
                        null
                }
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TableTextsStyles }>
                    { 
                        bookingTables?.map( ( table, index ) =>  
                            (index + 1) === bookingTables.length ? <span key={ index }>{`${ table }`}</span> : <span key={ index }>{`${table}-`}</span>
                    )}
                </Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ handleShowBookingOrigin(bookingOrigin) }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ moment(bookingCreateDate.substring(0,10)).format('DD/MM/YYYY') }</Typography>
            </TableCell>
        </TableRow>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSidebarBookingId: ( data ) => dispatch(setSidebarBookingId( data )),
    };
};

export default connect( null, mapDispatchToProps )( TableRowSection3 );