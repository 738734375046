import React, { useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { changeBookingHistoryPage, changeBookingHistoryRowsPerPage, changeBookingsOrder, fetchExportBookings } from '../../../actions';
import { setBookingsPeriodSelected } from '../../../actions/myBookingsActions';

// Components
import GeneralTableSection3 from './components/Table/GeneralTableSection3';
import TableTopSection3 from './components/Table/TableTopSection3';
import DateRangeSelector from '../components/DateRangeSelector';
import Sidebar from '../components/Sidebar';

// Helpers
import { bookingStatesSelectOptions, headCellsBookingTable, moreInfoBookingsSelectOptions } from './helpers';

// Material Ui
import { Box } from '@mui/material';

// Styles
import { BoxTableContainer, DateFilterContainer } from './components/Table/Style/Section3TableStyles';

const MyBookingsSection3 = (props) => {

    // Actions
    const { 
        setBookingsPeriodSelected,
        fetchExportBookings, 
        changeBookingsOrder, 
        changeRowsPerPage, 
        changePage, 
    } = props;

    // States
    const { 
        dateRangeSelected,
        totalDocuments,
        rowsPerPage,
        orderType,
        orderBy,
        history, 
        loading,
        page, 
    } = props;

    const [selectedPeriod, setSelectedPeriod] = useState('');

    const handleChangePage = (event, newPage) => {
        changePage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        changeRowsPerPage(parseInt(event.target.value, 10));
        changePage(0);
    };

    const handleChangeOrder = (orderBy, orderType) => {
        changeBookingsOrder({ orderBy, orderType });
    };

    return (
        <Box sx={ BoxTableContainer }>
            <Box sx={ DateFilterContainer }>
                <DateRangeSelector
                    setDateRange={ setBookingsPeriodSelected }
                    setSelectedPeriod={ setSelectedPeriod }
                    selectedPeriod={ selectedPeriod }
                    dateRange={ dateRangeSelected }
                    component={ 'bookings' }
                />
            </Box>
            <TableTopSection3
                handleChangeRowsPerPage={ handleChangeRowsPerPage } 
                bookingStatesArray={ bookingStatesSelectOptions }
                moreInfoArray={ moreInfoBookingsSelectOptions }
                exportBookings={ fetchExportBookings }
                handleChangePage={ handleChangePage }
                totalBookings={ history.length }
                totalResults={ totalDocuments } 
                rowsPerPage={ rowsPerPage }
                component='bookings'
                page={ page }
            />
            <GeneralTableSection3 
                headCellsTable={ headCellsBookingTable } 
                onChangeOrder={ handleChangeOrder }
                loading={ loading }
                orderBy={ orderBy }
                order={ orderType }
                items={ history }
            />
            <Sidebar bookings={ history } component={ 'bookings' }/>
        </Box> 
    )
}

const mapStateToProps = (state) => {
    return {
        dateRangeSelected: state.bookingHistoryReducer.dateRangeSelected,
        totalDocuments: state.bookingHistoryReducer.totalDocuments,
        rowsPerPage: state.bookingHistoryReducer.rowsPerPage,
        orderType: state.bookingHistoryReducer.orderType,
        history: state.bookingHistoryReducer.history,
        loading: state.bookingHistoryReducer.loading,
        orderBy: state.bookingHistoryReducer.orderBy,
        page: state.bookingHistoryReducer.page,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeBookingsOrder: ({ orderBy, orderType }) => dispatch( changeBookingsOrder({orderBy, orderType}) ),
        setBookingsPeriodSelected: (data) => dispatch(setBookingsPeriodSelected(data)),
        changePage: (page) => dispatch(changeBookingHistoryPage(page)),
        changeRowsPerPage: (rowsPerPage) =>
        dispatch(changeBookingHistoryRowsPerPage(rowsPerPage)),
        fetchExportBookings: () => dispatch( fetchExportBookings() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( MyBookingsSection3 );
