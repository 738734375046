import React, { useState, useEffect }  from 'react';

import { connect } from 'react-redux';

// Actions
import { setSubshift } from '../../../../../actions/shiftsActions';

// Icons
import TrashPlanningIcon from '../../../../SvgComponents/icons/Planning/TrashPlanningIcon';
import { ClockIcon } from '../../../../SvgComponents/icons';

// Components
import SubshiftHoursComponent from './General/SubshiftHoursComponent';
import ShiftHoursSelect from './General/ShiftHoursSelect';
import AddDateButton from './AddDateButton';

// Material Ui
import { Box, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { getScheduleOptions } from '../utils/functions';
import { 
  ShiftSubtitleModalStyles, 
  ChipHourContainerStyle, 
  ShiftTitleModalStyles, 
  ShubShiftStyles 
} from '../../../Styles';

const SubShift = ( props ) => {

  // Variables
  const {
    subShifts,
    subturno,
    index,
  } = props;

  // Acciones
  const {
    handleBookableHoursClick,
    handleDelete,
    setSubshift,
    changeFrom,
    changeTo,
  } = props;

  const hours = getScheduleOptions();

  const [paperList, setPaperList] = useState(subShifts ? subShifts : []);
  const [subshiftHours, setSubshiftHours] = useState([]);
  const [bookingHours, setBookingHours] = useState([]);

  useEffect(() => {
    setPaperList( subShifts );

    subShifts.map( ( subshift ) => (
      subturno.id === subshift.id && setBookingHours( subshift.bookingHours )
    ));

  }, [subShifts, subturno]);
  

  const getHours = ( time ) => {
    const hour = hours.find( option => option.value === time );
    return hour.option;
  };

  const subshiftName = `Subturno ${index + 1}`;

  useEffect(() => {
    
    const options = getScheduleOptions();
    
    const hoursAux = [];
    let primero = true;

    // console.log( 'Options --> ', options );

    options.map((option, index) => {
      if (option.value >= subturno.from && option.value < subturno.to){
        let active;
        let control = 0;

        if (subturno.bookingHours.length === 0) {
          active = primero;
          primero = false;
        } else {
          const exist = subturno.bookingHours.find((boogkingHour) => boogkingHour.time === option.value);
          if (typeof exist !== 'undefined') {
            active= true;
            control=exist.flowControl === null ? 0 : exist.flowControl;
          } else {
            active= false;
          }
        };

        hoursAux.push({
          schedule: option.option,
          value: option.value,
          control: control,
          active: active,
          id: index,
        });
      };
    });
    
    setSubshiftHours( hoursAux );
    // console.log('subturno: ', subturno);
  }, [ subturno, subturno.from, subturno.to ]);

  useEffect(() => {
    if ( subshiftHours !== [] ) {
      const bookingHours = [];
      
      subshiftHours.map((hour) => {
        if ( hour.active ) {
          bookingHours.push({
            time: hour.value,
            flowControl: hour.control === 0 ? null : hour.control,
          });
        }
      });
    }
  }, [ subshiftHours ]);

  const handleChangeActiveHour = ( time ) => {

    let newSubshiftHours = [ ...subshiftHours ];
    let newBookingsHours = [];

    const subshiftIndex = newSubshiftHours.findIndex( item => item.value === time );

    newSubshiftHours[ subshiftIndex ].active = !newSubshiftHours[ subshiftIndex ].value;

    newSubshiftHours.map( ( subshift ) => (
      subshift.active === true &&
        newBookingsHours.push({
          time: subshift.value,
          flowControl: subshift.control === 0 ? null : subshift.control,
        })
    ));
    
    setBookingHours( newBookingsHours );

    const nextPaperList = paperList.map((paper) => {
      if ( paper.id === subturno.id ) {
        return {
          ...paper,
          bookingHours: newBookingsHours,
        };
      } else {
        // No change
        return paper;
      };
    });

    // Re-render with the new array
    setPaperList( nextPaperList );

  };
  
  useEffect(() => {
    if ( paperList.length !== 0 ) {
      setSubshift( paperList );
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paperList ]);

  return (
    <Box key={ subturno.id } sx={ ShubShiftStyles }>
      <Box style={{ display: 'flex' }}>
        <Stack alignItems="center" direction="row" spacing={3} sx={{marginTop: 0}}>
          <Box>
            <Typography sx={ ShiftTitleModalStyles }>
              { subshiftName }
            </Typography>
          </Box>
          <Box borderLeft="1px solid #D1D1D1" height="17.85px"></Box>
          <Box>
            <Typography sx={ ShiftSubtitleModalStyles }>
              Inicio
            </Typography>
          </Box>
          <ShiftHoursSelect 
            handleChange={ (e) => changeFrom( subturno.id, e.target.value ) } 
            value={ subturno.from } 
            optionsArray={ hours }
          />
          {/* <Select
            label=""
            value={subturno.from}
            onChange={(e) => changeFrom(subturno.id, e.target.value)}
            sx={select}
            inputProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                color: "#041C32",
              },
            }}
            MenuProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                maxHeight: 200,
              },
            }}
          >
            {
              hours.map((option) => (
                <MenuItem key={option.value} value={option.value}>{`${option.option} HS`}</MenuItem>
              ))
            }
          </Select> */}
          <Box>
            <Typography sx={ ShiftSubtitleModalStyles }>
              Fin
            </Typography>
          </Box>
          <ShiftHoursSelect 
            handleChange={ (e) => changeTo(subturno.id, e.target.value) } 
            value={ subturno.to } 
            optionsArray={ hours }
          />
          {/* <Select
            label=""
            value={subturno.to}
            onChange={(e) => changeTo(subturno.id, e.target.value)}
            sx={select}
            inputProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                color: "#041C32",
              },
            }}
            MenuProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                maxHeight: 200,
              },
            }}
          >
            {
              hours.map((option) => (
                <MenuItem key={option.value} value={option.value}>{`${option.option} HS`}</MenuItem>
              ))
            }
          </Select> */}
        </Stack>
        <Box 
          sx={{
            alignItems: 'center', 
            paddingTop: '.3rem', 
            marginLeft: 'auto',
            display: 'flex', 
          }}
        >
          <IconButton 
            onClick={() => handleDelete( subturno.id )}
            sx={{ 
              justifyContent: 'center', 
              alignItems: 'center', 
              display: 'flex', 
            }} 
          >
            <TrashPlanningIcon />
            {/* <TrashIcon 
              style={{ cursor: 'pointer' }} 
              color={ '#FB3F4A' }  
              size={ 20 }  
            /> */}
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', margin: '16px 0' }}>
          <AddDateButton 
            onClick={() => handleBookableHoursClick(subshiftName, subturno, subturno.from, subturno.to)} 
            titleButton="Horarios reservables y control de ingreso"
            componenteHijo={ ClockIcon }
          />
      </Box>
      <Box sx={ ChipHourContainerStyle } >
        {
            bookingHours.map(( bookingHour ) => (
              <SubshiftHoursComponent
                handleActiveChange={ () => handleChangeActiveHour( bookingHour.time ) }
                showCross={ bookingHours.length > 1 ? true : false }
                bookingHour={ bookingHour } 
                key={ bookingHour.time }
                getHours={ getHours }
              />
              // <Box key={ bookingHour.time } sx={ bookingHourStyle }>
              //     <Typography sx={ ShiftChipHourModalStyles }>
              //       {`${getHours( bookingHour.time )}hs`}
              //     </Typography>
              //     <VerticalLineIcon height='17px' />
              //     <Box display="flex" flexDirection="row" alignItems="center" marginLeft="5px">
              //       <CubiertosIcon height="16.76px" width="13.41px" fill="#0B4762" />
              //       <Typography sx={ ShiftChipHourModalStyles }>
              //         { bookingHour.flowControl === 0 || bookingHour.flowControl === null ? 'Ilim' : bookingHour.flowControl }
              //       </Typography>
              //     </Box>
              //     <IconButton sx={{ p: '4px' }}>
              //       <CloseIcon sx={{ fontSize: '1.1rem' }}/>
              //     </IconButton>
              // </Box>
            ))
        }
      </Box>
    </Box>
  )
};

const mapStateToProps = (state) => {
  return {
    subShifts: state.v3.shifts.shiftData.subShifts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubshift: (data) => dispatch( setSubshift(data) ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)( SubShift );