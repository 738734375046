import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import DinnersClubIcons from "../../SvgComponents/icons/PaymentsOptions/DinnersClubIcons";
import VisaCreditoIcon from "../../SvgComponents/icons/PaymentsOptions/VisaCreditoIcon";
import MercadopagoIcon from "../../SvgComponents/icons/PaymentsOptions/MercadopagoIcon";
import Americanexpress from "../../SvgComponents/icons/PaymentsOptions/Americanexpress";
import VisaDebitoIcon from "../../SvgComponents/icons/PaymentsOptions/VisaDebitoIcon";
import MastercardIcon from "../../SvgComponents/icons/PaymentsOptions/MastercardIcon";
import CordobesaIcon from "../../SvgComponents/icons/PaymentsOptions/CordobesaIcon";
import ArgencardIcon from "../../SvgComponents/icons/PaymentsOptions/ArgencardIcon";
import FalabellaIcon from "../../SvgComponents/icons/PaymentsOptions/FalabellaIcon";
import CencosudIcon from "../../SvgComponents/icons/PaymentsOptions/CencosudIcon";
import DiscoverIcon from "../../SvgComponents/icons/PaymentsOptions/DiscoverIcon";
import MaestroIcon from "../../SvgComponents/icons/PaymentsOptions/MaestroIcon";
import NaranjaIcon from "../../SvgComponents/icons/PaymentsOptions/NaranjaIcon";
import CuentaDNI from "../../SvgComponents/icons/PaymentsOptions/CuentaDNIIcon";
import BitcoinIcon from "../../SvgComponents/icons/PaymentsOptions/BitcoinIcon";
import NativaIcon from "../../SvgComponents/icons/PaymentsOptions/NativaIcon";
import ModoIcons from "../../SvgComponents/icons/PaymentsOptions/ModoIcons";
import CabalIcon from "../../SvgComponents/icons/PaymentsOptions/CabalIcon";
import JcbIcon from "../../SvgComponents/icons/PaymentsOptions/JcbIcon";

const useStyles = makeStyles(() => ({
  MainContainer: {
    fontFamily: "Poppins, sans-serif",
    paddingRight: "5rem",
  },
  Title: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#041C32",
    margin: "0",
    paddingBottom: "0.5rem",
  },
  InvoiceData: {
    margin: "0 0 10px 0",
    fontSize: "16px",
    fontWeight: "400",
    color: "#808080",
  },
  InvoiceCardCTA: {
    position: "absolute",
    bottom: "0",
    right: "0",
    borderRadius: "0 0 10px 10px",
    backgroundColor: "#0B4762",
    padding: "5px",
    height: "20%",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  InvoiceCardCTAText: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "15px",
    fontWeight: "500",
    color: "#FFFFFF",
    margin: "0",
  },
  TitlePlan: {
    fontSize: "30px",
    fontWeight: "600",
    color: "#0B4762",
    margin: "0",
  },
  helperText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#808080",
    margin: "0",
  },
  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "4rem",
    width: "4rem",
    backgroundColor: "#0B4762",
    borderRadius: "10px",
    padding: "5px",
  },
  iconText: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
    fontWeight: "300",
    color: "#0B4762",
    margin: "0",
    textAlign: "center",
  },
}));

export const providerPaymentIdsList = {
  master: {
    name: "Mastercard",
    icon: <MastercardIcon fill={"#fff"} />,
  },
  visa: {
    name: "Visa",
    icon: <VisaCreditoIcon fill={"#fff"} />,
  },
  cencosud: {
    name: "Cencosud",
    icon: <CencosudIcon fill={"#fff"} />,
  },
  cabal: {
    name: "Cabal",
    icon: <CabalIcon fill={"#fff"} />,
  },
  account_money: {
    name: "MercadoPago",
    icon: <MercadopagoIcon fill={"#fff"} />,
  },
  debvisa: {
    name: "Visa Débito",
    icon: <VisaDebitoIcon fill={"#fff"} />,
  },
  debmaster: {
    name: "Mastercard Débito",
    icon: <MastercardIcon fill={"#fff"} />,
  },
  amex: {
    name: "American Express",
    icon: <Americanexpress fill={"#fff"} />,
  },
  diners: {
    name: "Diners",
    icon: <DinnersClubIcons fill={"#fff"} />,
  },
  discover: {
    name: "Discover",
    icon: <DiscoverIcon fill={"#fff"} />,
  },
  jcb: {
    name: "JCB",
    icon: <JcbIcon fill={"#fff"} />,
  },
  naranja: {
    name: "Naranja",
    icon: <NaranjaIcon fill={"#fff"} />,
  },
  nativa: {
    name: "Nativa",
    icon: <NativaIcon fill={"#fff"} />,
  },
  cordobesa: {
    name: "Cordobesa",
    icon: <CordobesaIcon fill={"#fff"} />,
  },
  cuentadni: {
    name: "Cuenta DNI",
    icon: <CuentaDNI fill={"#fff"} />,
  },
  cmr: {
    name: "CMR",
    icon: <FalabellaIcon fill={"#fff"} />,
  },
  debargencard: {
    name: "Argencard Débito",
    icon: <ArgencardIcon fill={"#fff"} />,
  },
  credit_card: {
    name: "Tarjeta de crédito",
    icon: <VisaCreditoIcon fill={"#fff"} />,
  },
  debit_card: {
    name: "Tarjeta de débito",
    icon: <VisaDebitoIcon fill={"#fff"} />,
  },
  prepaid_card: {
    name: "Tarjeta Prepaga",
    icon: <VisaCreditoIcon fill={"#fff"} />,
  },
};

export const PaymentCard = (props) => {
  const [payment, setPayment] = React.useState({
    name: "",
    icon: <MercadopagoIcon fill={"#fff"} />,
  });

  const { paymentMethod = "account_money", subscriptionId } = props;

  useEffect(() => {
    const { name, icon } = providerPaymentIdsList[paymentMethod];
    setPayment({
      name,
      icon,
    });
  }, [paymentMethod]);

  const classes = useStyles();

  return (
    <Grid item xs={12} lg={3}>
      <Box
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #DBD7DC",
          borderRadius: "10px",
          padding: "0px 10px 0px 10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          height: "15em",
          position: "relative",
        }}
      >
        <Box padding={"20px"}>
          <h3 className={classes.Title}>Medio de pago actual</h3>
          <Box
            paddingTop={"10px"}
            display="flex"
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Box className={classes.iconBox}>
              {paymentMethod ? payment.icon : ""}
            </Box>
            <p className={classes.iconText}>{payment.name}</p>
          </Box>
        </Box>
        <div
          className={classes.InvoiceCardCTA}
          onClick={() => {
            window.open(
              `https://www.mercadopago.com.ar/subscriptions/v0/${subscriptionId}/admin`,
              "_blank"
            );
          }}
        >
          <p className={classes.InvoiceCardCTAText}>Cambiar medio de pago</p>
        </div>
      </Box>
    </Grid>
  );
};
