import {
    BOTH_TYPE,
    CANCEL_EDITING_MESSAGE,
    DELETE_NEW_MESSAGE,
    EDIT_MESSAGE_SUCCESSFUL,
    END_EDITING_MESSAGE,
    PARTNERS,
    RESET_ALL_DATA,
    START_CREATION_NEW_MESSAGE,
    START_EDITING_MESSAGE,
    TOGGLE_LOADING_CREATION,
} from '../../constants';
import {deepCopy, deepCopyObject} from "../../utils/arrayHelpers";
import {deepEqual} from "../../utils/objectHelpers";

const initState = {
    messages: [],
    originalMessages: [],

    edited: false,
    editedIndex: -1,

    newMessageDialog: false,
    newMessage: '',
    newMessageType: BOTH_TYPE,
    newMessageError: false,

    loading: false,
    error: '',
}

const messagesReducer = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (action.meta && action.meta.collection && action.meta.collection === PARTNERS && !action.meta.subcollections && action.payload.ordered) {
                let data = action.payload.ordered[0];
                return {
                    ...state,
                    messages: data ? deepCopy(data["messages"]) : [],
                    originalMessages: data ? deepCopy(data["messages"]) : []
                }
            }
            return {...state}

        case START_EDITING_MESSAGE:
            return {...state, editedIndex: action.payload}
        case CANCEL_EDITING_MESSAGE:
            return {...state, editedIndex: -1}
        case DELETE_NEW_MESSAGE:
            let deletedMessage = action.payload
            let messageWithoutDelete = state.messages.filter(message => !deepEqual(message, deletedMessage))
            return {...state, messages: deepCopy(messageWithoutDelete), editedIndex: -1, edited: true}
        case END_EDITING_MESSAGE:
            let editMessage = {...action.payload}
            let editedMessages = deepCopy(state.messages)
            editedMessages[state.editedIndex] = deepCopyObject(editMessage)
            return {
                ...state,
                messages: editedMessages,
                edited: !deepEqual(state.messages[state.editedIndex], editMessage),
                editedIndex: -1
            }

        case EDIT_MESSAGE_SUCCESSFUL:
            return {...state, edited: false, loading: false}

        case START_CREATION_NEW_MESSAGE:
            let newMessage = {
                message: "",
                type: BOTH_TYPE
            }
            let messagesUpdate = !state.messages || state.messages.length === 0 ? [] : deepCopy(state.messages)
            messagesUpdate.push(newMessage)
            return {...state, messages: deepCopy(messagesUpdate), editedIndex: messagesUpdate.length - 1}

        case TOGGLE_LOADING_CREATION:
            return {...state, loading: !state.loading}

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default messagesReducer;
