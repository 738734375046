import React from 'react'

const MesaYaIconPrimary = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="35.603" height="36.916" viewBox="0 0 40.603 41.916"
                 fill={props.fill}>
                <g id="Grupo_1243" data-name="Grupo 1243" transform="translate(-606.626 -306.743)">
                    <path id="Path_547" data-name="Path 547"
                          d="M645.069,328.8A18.239,18.239,0,0,1,611.7,338.973h2.869a15.937,15.937,0,1,0,8.1-25.565l-.255.071c-.09.026-.178.054-.271.081-.124.038-.25.08-.371.121a15.785,15.785,0,0,0-3.849,1.9h-3.635a18.3,18.3,0,0,1,6.852-4.1c.21-.07.422-.135.635-.2.11-.033.221-.064.335-.094l.336-.086c.563-.14,1.132-.251,1.711-.337h0a18.528,18.528,0,0,1,5.379-.01v.014a18.055,18.055,0,0,1,8.163,3.381l.023-.021.771-.772a1.928,1.928,0,0,1,2.727,0h0l1.078,1.077a1.926,1.926,0,0,1,0,2.725v0l-.77.767-.024.026A18.084,18.084,0,0,1,645.069,328.8Z"
                          transform="translate(2.16 1.628)"/>
                    <path id="Trazado_599" data-name="Trazado 599"
                          d="M639.57,320.156A12.662,12.662,0,0,0,621.1,317.24h-9.891a.754.754,0,0,0-.686.441l-.773,1.581a.765.765,0,0,0,.355,1.03.848.848,0,0,0,.344.086h7.832a12.947,12.947,0,0,0-1.593,3.739h-8.519a.787.787,0,0,0-.7.441l-.76,1.569a.776.776,0,0,0,.357,1.042.825.825,0,0,0,.342.074h8.924a12.574,12.574,0,0,0,.587,3.75h-5.711a.754.754,0,0,0-.686.441l-.773,1.57a.774.774,0,0,0,.355,1.041.849.849,0,0,0,.344.086h7.98a12.672,12.672,0,0,0,21.144-13.974ZM629.077,337.17a10.155,10.155,0,1,1,10.149-10.162A10.159,10.159,0,0,1,629.077,337.17Z"
                          transform="translate(0 3.291)"/>
                    <path id="Path_549" data-name="Path 549"
                          d="M620.267,310.187h7.368a1.723,1.723,0,0,0,1.723-1.723h0a1.722,1.722,0,0,0-1.723-1.721h-7.368a1.723,1.723,0,0,0,0,3.444Z"
                          transform="translate(5.099 0)"/>
                </g>
            </svg>
        </div>
    )
}

MesaYaIconPrimary.defaultProps = {
    fill: "#D0D0D0"
}

export default MesaYaIconPrimary
