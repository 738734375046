export const CHANGE_FILTERS_BOOKINGSTATES_SELECTED = 'CHANGE_FILTERS_BOOKINGSTATES_SELECTED';
export const CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE = 'CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE';
export const CHANGE_BOOKING_HISTORY_FILTER_ORIGIN = 'CHANGE_BOOKING_HISTORY_FILTER_ORIGIN';
export const CHANGE_BOOKING_HISTORY_FILTER_STATE = 'CHANGE_BOOKING_HISTORY_FILTER_STATE';
export const BOOKINGS_MORE_INFO_FILTERS_SELECTED = 'BOOKINGS_MORE_INFO_FILTERS_SELECTED';
export const CHANGE_BOOKING_HISTORY_FILTER_DATE = 'CHANGE_BOOKING_HISTORY_FILTER_DATE';
export const FETCH_BOOKINGS_FOR_TODAY_SUCCESS = 'FETCH_BOOKINGS_FOR_TODAY_SUCCESS';
export const CHANGE_BOOKINGS_COUNTRY_SELECTED = 'CHANGE_BOOKINGS_COUNTRY_SELECTED';
export const CHANGE_FILTERS_MOREINFO_SELECTED = 'CHANGE_FILTERS_MOREINFO_SELECTED';
export const CHANGE_BOOKINGS_ORIGIN_SELECTED = 'CHANGE_BOOKINGS_ORIGIN_SELECTED';
export const START_FETCHING_BOOKING_HISTORY = 'START_FETCHING_BOOKING_HISTORY';
export const FETCH_BOOKING_HISTORY_SUCCESS = 'FETCH_BOOKING_HISTORY_SUCCESS';
export const FETCH_BOOKING_HISTORY_FAILURE = 'FETCH_BOOKING_HISTORY_FAILURE';
export const CHANGE_BOOKING_HISTORY_QUERY = 'CHANGE_BOOKING_HISTORY_QUERY';
export const CHANGE_BOOKING_HISTORY_PAGE = 'CHANGE_BOOKING_HISTORY_PAGE';
export const CHANGE_BOOKINGS_ORDER  = 'CHANGE_BOOKINGS_ORDER';

export const BookingOrigin = {
    Manual: 0,
    Woki: 1,
    Link: 2,
    'Tus clientes': 3,
}

export const ReversBookingOrigin = {
    0: 'Manual',
    1: 'Woki',
    2: 'Link',
    3: 'Tus clientes',
}

export const BookingFinalStates = {
    ARRIVED: "arrived",
    NO_SHOW: 'noShow',
    REJECTED: 'rejected',
    AUTO_REJECTED: 'autoRejected',
    DELETED_BY_PARTNER: 'deletedByPartner',
    DELETED_BY_USER: 'deletedByUser'
}

export const ReversBookingFinalStates = {
    "arrived": 'Ingresado',
    'noShow': 'No vino',
    'rejected': 'Rechazado',
    'autoRejected': 'Auto Rechazado',
    'deletedByPartner': 'Eliminado',
    'deletedByUser': 'Cancelado',
}


