import React from 'react';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

const PlanningSnackbar = ({ open, message, handleClose, type }) => {

    const action = (
        <React.Fragment>
            <IconButton
                onClick={ handleClose }
                aria-label="close"
                color="inherit"
                size="small"
                sx={{ 
                    backgroundColor: type === 'Error' ? '#FA626A !important' : '#105B7D !important' 
                }}
            >
                <CloseIcon fontSize="small" sx={{ color: 'white' }}/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{ 
                vertical: 'bottom', 
                horizontal: 'right' 
            }}
            autoHideDuration={ 4000 }
            onClose={ handleClose }
            message={ message }
            action={ action }
            open={ open }
            sx={{
                '.MuiPaper-root': {
                    backgroundColor: type === 'Error' ? '#FB3F4A !important' : '#0B4762 !important',
                    fontFamily: 'Poppins',
                    borderRadius: '8px',
                    color: 'white',
                }
            }}
        />
    )
}

export default PlanningSnackbar;