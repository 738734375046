import React from 'react'

const WokiPrimary = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 53 50.338">
                <g id="Grupo_727" data-name="Grupo 727" transform="translate(-63.971 -197.12)">
                    <g id="Layer_1" data-name="Layer 1" transform="translate(63.971 197.12)">
                    <path id="Trazado_279" data-name="Trazado 279" d="M191.167,63.153c-.488,3.985-2.155,7.182-3.97,7.934a123.573,123.573,0,0,0,1.126-15.106c-.031-.452-.047-1.1-.06-1.891h0C190.452,54.357,191.75,58.415,191.167,63.153Z" transform="translate(-138.307 -39.964)" fill="#0076ff"/>
                    <path id="Trazado_280" data-name="Trazado 280" d="M4.111,71.09C2.3,70.338.627,67.141.138,63.153c-.58-4.738.718-8.8,2.9-9.063h0c-.013.786-.026,1.439-.057,1.891A123.611,123.611,0,0,0,4.111,71.09Z" transform="translate(0 -39.964)" fill="#0076ff"/>
                    <path id="Trazado_281" data-name="Trazado 281" d="M59.815,31.124v0c-.34,2.719-.731,5.276-1.144,7.169-.564,2.6-1.429,5.613-4.223,7.971s-7.514,4.069-15.8,4.072c-16.566,0-18.888-6.837-20.021-12.043-.413-1.894-.8-4.45-1.141-7.169a123.611,123.611,0,0,1-1.126-15.109c.031-.452.044-1.1.057-1.891.076-4.121.172-11.873,3.061-13.348A7.937,7.937,0,0,1,24.9.246a.483.483,0,0,0-.065.013c-5.571,1.2-5.461,7.919-5.626,9.666s-.326,22.281,3.168,28.016c2.983,4.894,12.732,5.252,15.521,5.252.475,0,.747-.01.752-.01s.277.01.752.01c2.787,0,12.536-.358,15.521-5.252,3.494-5.735,3.33-26.269,3.165-28.016S58.036,1.46,52.468.259A.485.485,0,0,0,52.4.246a7.927,7.927,0,0,1,5.417.533c2.889,1.476,2.985,9.227,3.061,13.348.013.786.029,1.439.06,1.891A123.586,123.586,0,0,1,59.815,31.124Z" transform="translate(-12.073 0)" fill="#0076ff"/>
                    <path id="Trazado_282" data-name="Trazado 282" d="M67.905,10.465c-.016-.17-.029-.373-.044-.609-.227-3.562-.969-7.315-4.761-8.133A77.7,77.7,0,0,0,49.67.6h-.332A77.7,77.7,0,0,0,35.909,1.723c-3.79.817-4.534,4.571-4.761,8.133-.013.235-.026.439-.044.609-.2,2.165-.2,22.082,3.019,27.373,2.136,3.5,8.958,4.753,14.631,4.753.447,0,.7-.01.713-.01h.078c.01,0,.266.01.713.01,5.67,0,12.492-1.248,14.628-4.753C68.112,32.547,68.109,12.63,67.905,10.465ZM36.6,15.951c-.469-4.46.683-8.237,2.572-8.436s3.8,3.256,4.269,7.717-.683,8.237-2.572,8.436S37.068,20.411,36.6,15.951ZM49.5,37.094c-7.646,0-9.485-8.121-9.485-8.121H58.989s-1.838,8.121-9.485,8.121ZM62.41,15.951c-.469,4.46-2.38,7.915-4.269,7.717s-3.041-3.975-2.572-8.436,2.38-7.915,4.269-7.717S62.879,11.49,62.41,15.951Z" transform="translate(-22.927 -0.444)" fill="#0076ff"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default WokiPrimary
