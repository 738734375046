import React from 'react';

import { connect } from 'react-redux';

// Actions
import { 
  changeClosing, 
  changeDays, 
  changeIsSpecial, 
  changeOpening, 
  setValidationError 
} from '../../../../../actions/shiftsActions';

// Components
import ShiftSimpleSelect from './General/ShiftSimpleSelect';
import ShiftHoursSelect from './General/ShiftHoursSelect';

// Constants
import { SHIFTS_VIEW_MODE } from '../../../../../constants/shiftsConstants';

// Material Ui
import { Typography, Box } from "@mui/material";

// Utils
import { HandleChangeHours } from '../utils/ScheduleFunctions';
import { getScheduleOptions } from '../utils/functions';
import { TYPESHIFTARRAY } from '../utils/constants';

// Styles
import { ShiftSelectTitles, StartEndShiftContainerStyles } from '../../../Styles';

const ScheduleStepShiftTypes = ( props ) => {
    // Variables
  const {
    validationError,
    isSpecial,
    calendar,
    viewMode,
  } = props;

  // Acciones
  const {
    setValidationError,
    changeIsSpecial,
    changeOpening,
    changeClosing,
    changeDays,
  } = props;

  const hours = getScheduleOptions();

  const handleChangeType = ( isSpecial ) => {

    console.log( 'isSpecial --> ', isSpecial );
    changeIsSpecial( isSpecial );

    if ( isSpecial ) {
      changeDays([]);
    }; 
  };

  return (
    <Box>
      <Box 
        sx={{ 
          alignItems: 'center', 
          width: 'max-content',
          display: 'flex', 
          gap: '15px',
          mb: '2rem'
        }}
      >
        <Typography sx={ ShiftSelectTitles }>Tipo de turno</Typography>
        <ShiftSimpleSelect
          disabled={ viewMode === SHIFTS_VIEW_MODE.EDIT }
          handleChange={ handleChangeType } 
          optionsArray={ TYPESHIFTARRAY }
          value={ isSpecial }
          type='TypeShift'
        />
      </Box>
      <Box sx={ StartEndShiftContainerStyles }>
        <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', gap: '15px' }} >
          <Typography sx={ ShiftSelectTitles }>Inicio del turno</Typography>
          <ShiftHoursSelect
            handleChange={ 
              (e) => HandleChangeHours( true, e.target.value, calendar.closing, validationError, changeOpening, setValidationError ) 
            } 
            disabled={ viewMode === SHIFTS_VIEW_MODE.EDIT }
            error={ validationError === 'error:hours' }
            value={ calendar.opening }
            optionsArray={ hours }
            type='hours'
          />
        </Box>
        <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', gap: '15px' }} >
          <Typography sx={ ShiftSelectTitles }>Fin del turno</Typography>
          <ShiftHoursSelect
            handleChange={ 
              (e) => HandleChangeHours( false, calendar.opening, e.target.value, validationError, changeClosing, setValidationError ) 
            } 
            disabled={ viewMode === SHIFTS_VIEW_MODE.EDIT }
            error={ validationError === 'error:hours' }
            value={ calendar.closing }
            optionsArray={ hours }
            type='hours'
          />
        </Box>        
      </Box>      
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    validationError: state.v3.shifts.validationError,
    isSpecial: state.v3.shifts.shiftData.isSpecial,
    calendar: state.v3.shifts.shiftData.calendar,
    viewMode: state.v3.shifts.viewMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setValidationError: (data) => dispatch(setValidationError(data)),
    changeIsSpecial: (data) => dispatch(changeIsSpecial(data)),
    changeOpening: (data) => dispatch(changeOpening(data)),
    changeClosing: (data) => dispatch(changeClosing(data)),
    changeDays: (data) => dispatch(changeDays(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleStepShiftTypes);