import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Materia Ui
import { FormControl, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';

// Styles
import { FiltersSelectStyles } from '../Table/Style/Section3TableStyles';

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            border: '1px solid #0B4762',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            width: '200px',
        },
    },
};

const FiltersSelect = ({ optionsArray, handleChange, value, selectName }) => {
    return (
        <FormControl sx={{ maxWidth: 240 }} key={ optionsArray.id }>
            <Select
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                renderValue={(selected) => {
                    if ( selected === undefined || selected === '' ) {
                        return (
                            <Typography 
                                sx={{ 
                                    fontFamily: 'Poppins',
                                    color: '#2A2742',
                                    fontWeight: 500,
                                    ml: '.5rem' 
                                }}
                            >Seleccionar</Typography>
                        );
                        
                    } else return selected; 
                }}
                onChange={ (e) => handleChange(e, selectName) }
                sx={ FiltersSelectStyles }
                input={<OutlinedInput />}
                MenuProps={ MenuProps }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    selectName === 'country' ?
                        <MenuItem 
                            sx={{ minHeight: '35px !important' }}
                            value={ 'Sin filtro' } 
                        >
                            <ListItemText 
                                primary={ 'Sin filtro' } 
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    :
                        null
                }
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            value={ selectName === 'country' ? item.name : item.option } 
                            sx={{ minHeight: '35px !important' }}
                            key={ selectName === 'country' ? item.name : item.id } 
                        >
                            <ListItemText 
                                primary={ selectName === 'country' ? item.name : item.option } 
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default FiltersSelect;