import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';
import Reservas from '../../components/Reservas';
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";

function ReservasPage(props) {
    const {changePrimaryTitleAppBar, changeTitleAppBar} = props

    useEffect(() => {
        changeTitleAppBar("Reservas")
        props.changeShowSidebar(true);
    }, [changePrimaryTitleAppBar, changeTitleAppBar])

    return (
        <Grid container style={{height: '100%', width: '95%'}}>
            <Grid item xs={12}>
                <Reservas/>
            </Grid>
        </Grid>
    );
}
const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar()),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservasPage);
