import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {Grid,} from "@material-ui/core";

import {PARTNERS} from "../../constants";
import {
    addMesaYaSector,
    changeMesaYaAcceptanceRadius,
    changeMesaYaBiggroup,
    changeMesaYaClosetime,
    changeMesaYaMaxcapacity,
    changeMesaYaSectorEnable,
    changeMesaYaSectorFull,
    changeMesaYaStayingRadius,
    changeMesaYaTolerance,
    editMesaYaCancel,
    removeMesaYaSector,
    submitMesaYaChanges,
    toggleMesaYaBiggroupEnable,
} from "../../actions";

import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import GralDialog from "../GeneralComponents/gralDialog";
import MesaYaTop from "./MesaYa Top/MesaYaTop";
import SectorsMesaYa from "./Sectores/SectorsMesaYa";
import SettingsZones from "./Configuracion/SettingsZones";
import SettingsGroup from "./Configuracion/SettingsGroup";
import SettingsTime from "./Configuracion/SettingsTime";
import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";

const useStyles = makeStyles(() => ({
    mainContainer: {
        marginTop: 20,
        padding: 16,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    submitButton: {
        marginLeft: 10,
        background: "#4caf50",
        color: "#fff",
        "&:hover": {
            background: "#357a38",
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    bigGroupContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    closetimeContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    text: {
        margin: '0px',
        color: '#848282',
        fontWeight: '600',
        fontFamily: 'Montserrat'
    },
    description: {
        margin: '0px',
        color: '#848282',
        fontFamily: 'Montserrat',
        fontSize: '12px'
    }
}));

function MesaYaConfigForm(props) {
    const classes = useStyles();

    // Variables
    const {
        acceptanceRadius,
        stayingRadius,
        bigGroup,
        bigGroupEnable,
        maxCapacity,
        closeTime,
        tolerance,
        MYsectors,
        sectors,
        haveChanges,
    } = props;
    // Actions
    const {
        changeMesaYaAcceptanceRadius,
        changeMesaYaStayingRadius,
        changeMesaYaBiggroup,
        toggleMesaYaBiggroupEnable,
        changeMesaYaMaxcapacity,
        changeMesaYaClosetime,
        changeMesaYaTolerance,
        submitMesaYaChanges,
        changeMesaYaSectorEnable,
    } = props;

    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');


    const onDisable = (sectorName, enable) => {
        if (enable) {
            changeMesaYaSectorEnable(sectorName, enable);
        } else {
            setDialogTitle(sectorName);
            setShowDialog(true);
        }
    }

    const onAcceptDisable = () => {
        changeMesaYaSectorEnable(dialogTitle, false);
        setShowDialog(false);
        setDialogTitle('');
    }

    const onCancelDisable = () => {
        setShowDialog(false);
        setDialogTitle('');
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={3}>
                    <div>
                        <MesaYaTop/>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div style={{marginLeft: '30px', paddingTop: '50px'}}>
                        <p className={classes.text}>Desde aquí podrás administrar las configuraciones</p>
                        <p className={classes.text}>de Mesa Ya! de tu establecimiento.</p>
                    </div>
                </Grid>
                <Grid item xs={12} style={{fontFamily: 'Montserrat', paddingLeft: '15px'}}>
                    <h1 style={{marginBottom: '10px'}}>Sectores</h1>
                    <p className={classes.description}>Activá o desactivá la función Mesa Ya! para cada uno de los sectores del establecimiento.</p>
                    <p className={classes.description}>Los sectores desactivados no aparecerán en tu perfil como
                        disponibles para recibir solicitudes de Mesa Ya!</p>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10}>
                <Grid container spacing={2} className={classes.mainContainer}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {Object.entries(MYsectors)
                                .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                                .map((entry) => (
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SectorsMesaYa
                                            key={entry[0]}
                                            name={entry[0]}
                                            enable={entry[1]["enable"]}
                                            priority={sectors.find((s) => s.name === entry[0])?.priority}
                                            capacity={sectors.find((s) => s.name === entry[0])?.capacity}
                                            tables={sectors.find((s) => s.name === entry[0])?.tables.length}
                                            onEnableChange={() => onDisable(entry[0], !entry[1]["enable"])}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                        <div style={{marginTop: '20px'}}>
                            <p className={classes.description}>Recordá que desactivar todos los sectoresdesactivará por
                                completo la función Mesa Ya de tu establecimiento</p>
                            <p className={classes.description}>No podrás recibir solicitudes y la función figurará en tu
                                perfil como "No disponible"</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{fontFamily: 'Montserrat'}}>
                        <h1>Configuraciones generales</h1>
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <SettingsZones
                            acceptanceRadius={acceptanceRadius / 100}
                            onChangeAcceptanceRadius={(value) => {
                                if (value > stayingRadius / 100) {
                                    changeMesaYaStayingRadius(value);
                                }
                                if (value < 1) {
                                    changeMesaYaAcceptanceRadius(1);
                                }
                                else {
                                    changeMesaYaAcceptanceRadius(value);
                                }
                            }}
                            stayingRadius={stayingRadius / 100}
                            onChangeStayingRadius={(value) => {
                                if (value >= acceptanceRadius / 100) {
                                    changeMesaYaStayingRadius(value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <SettingsGroup
                            checked={bigGroupEnable && Number(bigGroup) > 0}
                            onChangeEnable={toggleMesaYaBiggroupEnable}
                            bigGroup={bigGroup}
                            onChangeBigGroup={(value) => {
                                if (value > maxCapacity) changeMesaYaMaxcapacity(value)
                                Number(value) <= 0
                                    ? changeMesaYaBiggroup(1)
                                    : changeMesaYaBiggroup(value)
                            }
                            }
                            maxBigGroup={maxCapacity}
                            onChangeMaxBigGroup={(value) => {
                                if (value < bigGroup) Number(value) > 0 ? changeMesaYaBiggroup(value) : changeMesaYaBiggroup(1)
                                Number(value) > 0
                                    ? changeMesaYaMaxcapacity(value)
                                    : changeMesaYaMaxcapacity(1)
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <SettingsTime
                            tolerance={tolerance}
                            onChangeTolerance={(value) => value > 0 ? changeMesaYaTolerance(value) : null}
                            closeTime={closeTime}
                            onChangeCloseTime={(value) => changeMesaYaClosetime(value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {haveChanges ? <SaveChangesButton onClick={submitMesaYaChanges}/> : null}
                    </Grid>
                </Grid>
            </Grid>
            <GralDialog
                onAccept={onAcceptDisable}
                onCancel={onCancelDisable}
                open={showDialog}
                body={"Dejará de ser visible para tus clientes"}
                subBody={"¿Deseás continuar?"}
                title={dialogTitle}/>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        acceptanceRadius: state.mesayaReducer.editReducer.acceptanceRadius,
        bigGroupEnable: state.mesayaReducer.editReducer.bigGroupEnable,
        sectorsLoading: state.mesayaReducer.editReducer.sectorsLoading,
        stayingRadius: state.mesayaReducer.editReducer.stayingRadius,
        maxCapacity: state.mesayaReducer.editReducer.maxCapacity,
        haveChanges: state.mesayaReducer.editReducer.haveChanges,
        closeTime: state.mesayaReducer.editReducer.closeTime,
        tolerance: state.mesayaReducer.editReducer.tolerance,
        MYsectors: state.mesayaReducer.editReducer.MYsectors,
        bigGroup: state.mesayaReducer.editReducer.bigGroup,
        sectors: state.mesayaReducer.editReducer.sectors,
        loading: state.mesayaReducer.editReducer.loading,
        partner: state.firestore.ordered[PARTNERS],
        profile: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeMesaYaAcceptanceRadius: (data) =>
            dispatch(changeMesaYaAcceptanceRadius(data)),
        changeMesaYaStayingRadius: (data) =>
            dispatch(changeMesaYaStayingRadius(data)),
        changeMesaYaBiggroup: (data) => dispatch(changeMesaYaBiggroup(data)),
        toggleMesaYaBiggroupEnable: (data) => dispatch(toggleMesaYaBiggroupEnable(data)),
        changeMesaYaMaxcapacity: (data) => dispatch(changeMesaYaMaxcapacity(data)),
        changeMesaYaClosetime: (data) => dispatch(changeMesaYaClosetime(data)),
        changeMesaYaTolerance: (data) => dispatch(changeMesaYaTolerance(data)),
        removeMesaYaSector: (data) => dispatch(removeMesaYaSector(data)),
        addMesaYaSector: (data) => dispatch(addMesaYaSector(data)),
        submitMesaYaChanges: () => dispatch(submitMesaYaChanges()),
        editMesaYaCancel: () => dispatch(editMesaYaCancel()),
        changeMesaYaSectorFull: (sectorName, full) =>
            dispatch(changeMesaYaSectorFull(sectorName, full)),
        changeMesaYaSectorEnable: (sectorName, enable) =>
            dispatch(changeMesaYaSectorEnable(sectorName, enable)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        return [
            {
                collection: PARTNERS,
                doc: props.idPartnerSelected,
            },
        ];
    })
)(MesaYaConfigForm);
