import moment from "moment";

export const PlanningOptionsArray = [
    {
        id: 0,
        option: 'Horario de apertura'
    },
    {
        id: 1,
        option: 'Fechas especiales'
    },
    {
        id: 2,
        option: 'Cierre programado'
    }
];

export const daysArray = [
    {
        id: 0,
        day: 'D'
    },
    {
        id: 1,
        day: 'L'
    },
    {
        id: 2,
        day: 'M'
    },
    {
        id: 3,
        day: 'M'
    },
    {
        id: 4,
        day: 'J'
    },
    {
        id: 5,
        day: 'V'
    },
    {
        id: 6,
        day: 'S'
    }
];

export const handleSetDateRanges = ( startDate, endDate, steps = 1 ) => {
    const date = new Date(startDate.getTime());

    const dates = [];

    while ( date <= endDate ) {
        dates.push( moment( date ).format('DD/MM/YYYY') );
        date.setDate( date.getDate() + 1);
    }

    return dates;
};

export const ChangeMinutesToHHmm = ( numberOfMinutes ) => { 
    let duration = moment.duration( numberOfMinutes, 'minutes' );
    
    let hh = ( duration.years()*(365*24)) + (duration.months()*(30*24)) + (duration.days()*24) + (duration.hours() );
    
    let mm = duration.minutes();

    if ( (`${ hh }`).length === 1 ) {
        hh = '0' + hh;
    }
    
    if ( mm === 0 ) {
        return hh + ':00 hs';
    } else {
        return hh + ':' + mm + 'hs';
    }
};

export const handleSetNewDates = ( array ) => {
    let newSingleDates = [];
    let newRangeArray = [];
    for ( let j = 0; j < array.length; j++ ) {
        if ( array[ j + 1 ] ) {
            const diff = moment( array[j] ).diff( moment( array[j + 1] ), 'days' );
            
            if ( diff === -1 || diff === 0 ) {
                newRangeArray.push( moment( array[j] ).format() );
            } else if ( diff !== -1 && diff !== 0 && newRangeArray.length > 0 ) {
                newRangeArray.push( moment( array[j] ).format() );
            } else {
                newSingleDates.push( moment( array[j] ).format() );
            }
            
        } else {
            newSingleDates.push( moment( array[j] ).format() );
        }
    };

    if ( newRangeArray.length > 0 ) {
        for (let i = 0; i < newRangeArray.length; i++) {
            newSingleDates.push( newRangeArray[i] );
        }
    }

    return newSingleDates;
};

export const handleSetNewSingleDates = ( arrayValue ) => {

    const separatedArrays = [];

    let currentStartDate = null;
    let currentEndDate = null;

    for ( const dateStr of arrayValue ) {
        const date = moment( dateStr );

        if (!currentStartDate) {
            currentStartDate = date;
            currentEndDate = date;
        } else {

            const differenceInDays = Math.abs( currentEndDate.diff( date, 'days' ) );

            if ( differenceInDays <= 1 ) {
                currentEndDate = date;
            } else {
                if ( currentStartDate.format() === currentEndDate.format() ) {
                    separatedArrays.push( currentStartDate.format() );
                } else {
                    separatedArrays.push([
                        currentStartDate.format(),
                        currentEndDate.format()
                    ]);
                };

                currentStartDate = date;
                currentEndDate = date;
            };
        };
    };

    if ( currentStartDate && currentEndDate ) {
        if ( currentStartDate.format() === currentEndDate.format() ) {
            separatedArrays.push( currentStartDate.format() );
        } else {
            separatedArrays.push([
                currentStartDate.format(),
                currentEndDate.format()
            ]);
        };
    };

    return separatedArrays;
};