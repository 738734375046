export const BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE = 'BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE';
export const BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE = 'BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE';
export const BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR = 'BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR';
export const BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES = 'BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES';
export const BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES = 'BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES';
export const BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1 = 'BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1';
export const BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2 = 'BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2';
export const BOOKING_POLITICS_BEFORE_GOING_MESSAGE = 'BOOKING_POLITICS_BEFORE_GOING_MESSAGE';
export const SAVING_BOOKING_POLITICS_DATA_SUCCESS = 'SAVING_BOOKING_POLITICS_DATA_SUCCESS';
export const BOOKING_POLITICS_ARRIVAL_TOLERANCE = 'BOOKING_POLITICS_ARRIVAL_TOLERANCE';
export const LOAD_BOOKING_POLITICS_DATA_SUCCESS = 'LOAD_BOOKING_POLITICS_DATA_SUCCESS';
export const SAVING_BOOKING_POLITICS_DATA_FAIL = 'SAVING_BOOKING_POLITICS_DATA_FAIL';
export const LOAD_BOOKING_POLITICS_DATA_FAIL = 'LOAD_BOOKING_POLITICS_DATA_FAIL';
export const BOOKING_POLITICS_SAVE_BACK_DATA = 'BOOKING_POLITICS_SAVE_BACK_DATA';
export const BOOKING_POLITICS_ADD_NEW_HOUR = 'BOOKING_POLITICS_ADD_NEW_HOUR'; 
export const BOOKING_POLITICS_BEFORE_GOING = 'BOOKING_POLITICS_BEFORE_GOING';
export const BOOKING_POLITICS_CHANGE_HOURS = 'BOOKING_POLITICS_CHANGE_HOURS';
export const BOOKING_POLITICS_FIXED_SHIFT = 'BOOKING_POLITICS_FIXED_SHIFT';
export const SAVING_BOOKING_POLITICS_DATA = 'SAVING_BOOKING_POLITICS_DATA';
export const BOOKING_POLITICS_REMOVE_HOUR = 'BOOKING_POLITICS_REMOVE_HOUR';
export const BOOKING_POLITICS_CHANGE_DAYS = 'BOOKING_POLITICS_CHANGE_DAYS';
export const LOAD_BOOKING_POLITICS_DATA = 'LOAD_BOOKING_POLITICS_DATA';
export const BOOKING_SET_EDITING_HOUR = 'BOOKING_SET_EDITING_HOUR';
export const BOOKING_SET_ENABLE_HOUR = 'BOOKING_SET_ENABLE_HOUR';