import React, { useState, useEffect } from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { setRotationControlByGroup } from '../../../../../actions/shiftsActions';

// Components
import AdvancedOptionsCustomizeRotationByGroupModal from './AdvancedOptionsCustomizeRotationByGroupModal';
import ShiftGroupComponent from './General/ShiftGroupComponent';

// Icons
import { PeopleIcon, TableIcon } from '../../../../SvgComponents/icons';

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { dwellTimeGroups, rotationOptionsDefaultValue } from '../utils/constants';
import { 
    GetRotationControlByGroups, 
    GetAdvancedModalOptions, 
    GetDwellTimeOptions,  
    GetGroupArray, 
} from '../utils/advancedOptionsFunctions';

const AdvancedOptionsRotationConfig = ( props ) => {
    
    const [showModal, setShowModal] = useState( false );

    // Action
    const { setRotationControlByGroup } = props;

    // States
    const { dwellTimeByGroup } = props;

    // Variables
    const {
        reservableSectors,
        rotationControl,
        title,
        type,
    } = props;

    const newReservableSectors = reservableSectors?.filter( (reservableSector) => reservableSector.available );

    let reservableSector = newReservableSectors?.length > 0 ? newReservableSectors[0] : null;

    const [rotationControlGroup, setRotationControlGroup] = useState([]);
    const [rotationArray, setRotationArray] = useState([]);
    const [lastName, setLastName] = useState(0);

    useEffect(() => {       
        const newArray = GetDwellTimeOptions( 
            dwellTimeByGroup, 
            dwellTimeGroups, 
            rotationControl?.rotationControlByGroup, 
            rotationOptionsDefaultValue 
        ); 

        setRotationControlGroup(
            GetAdvancedModalOptions(
                reservableSector?.id, 
                newArray, 
                rotationControl?.rotationControlByGroup, 
                rotationOptionsDefaultValue, 
                'group'
            )
        );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ JSON.stringify( rotationControl?.rotationControlByGroup ) ]);
    
    useEffect(() => {
        if ( type === 'groups' ) {
            setRotationArray( GetGroupArray( rotationControl?.rotationControlByGroup ) );
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rotationControl ]); 

    const handleChangeRotationGroupActive = ( id ) => {
        let newRotationControlGroup = [ ...rotationControlGroup ];

        let rotationControlIndex = newRotationControlGroup.findIndex( 
            rotation => rotation.id.toString() === id  
        );

        if ( rotationControlIndex === '-1' ) {
            return;
        }

        if ( rotationControlGroup[0].active ) {
            for (let i = 0; i < newRotationControlGroup.length; i++) {
                newRotationControlGroup[ i ].active = false;
            }
        } else {
            newRotationControlGroup[ rotationControlIndex ].active = false;
        }
        
        let newGroupArray = [];

        for (let i = 0; i < newRotationControlGroup.length; i++) {
            if ( newRotationControlGroup[i].active  ) {
                newGroupArray.push({
                    value: newRotationControlGroup[i].value,
                    id: i
                });
            };
        };

        setLastName( rotationControlGroup.length );

        GetRotationControlByGroups( 
            newRotationControlGroup, 
            rotationControl?.rotationControlByGroup, 
            setRotationControlByGroup 
        );
    };

    useEffect(() => {
        let newArray = [];

        if ( rotationControl ) {
            const groupArray = Object.values( rotationControl?.rotationControlByGroup );
            const keysGroup = Object.keys( rotationControl?.rotationControlByGroup );

            for (let i = 0; i < groupArray.length; i++) {
                let clave = groupArray[i];
                
                for (let j = 0; j < keysGroup.length; j++) {
                    if ( i === j && clave !== -1 ) {
                        newArray.push({ 
                            id: keysGroup[j], 
                            value: clave 
                        });
                    };
                };
            };

            setRotationArray( newArray );
        } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rotationControl ]);

    useEffect(() => {
        setLastName( rotationControlGroup.length );
    }, [ rotationControlGroup ]);

    return (
        <Box>
            {
                showModal && type === 'groups' ? 
                    <AdvancedOptionsCustomizeRotationByGroupModal 
                        handleClose={() => setShowModal(false)} 
                    />   
                : 
                    null
            }
                <Stack
                    sx={{ cursor: "pointer" }}              
                    alignItems="center"
                    direction="row"
                    spacing={ 1 }
                >
                    { type === 'groups' ? <PeopleIcon /> : <TableIcon />}
                    <Typography
                        sx={{ textDecoration: "underline" }}
                        onClick={() => setShowModal(true)}
                        fontFamily="Poppins"
                        fontWeight="500"
                        fontSize="15px"
                        color="#0B4762"
                        my={3}
                    >
                        { title }
                    </Typography>
                    <Box
                        borderRadius={ 50 }
                        bgcolor="#35C18F"
                        height="11.5px"
                        width="11.5px"
                    ></Box>
                </Stack>
                <TransitionGroup style={{ display: 'flex', flexDirection: 'column' }}>
                    <Collapse 
                        sx={{ mb: rotationArray.length > 0 ? '1rem' : 0 }}
                        in={ rotationArray.length > 0 ? true : false }
                    >
                        {
                            rotationControlGroup[0]?.active ?
                                <ShiftGroupComponent
                                    handleChangeRotationActive={ handleChangeRotationGroupActive }
                                    rotation={ rotationControlGroup[0] }
                                    lastName={ 'Todos' }
                                    key={ 'Todos' } 
                                    type={ type }
                                    all={ true } 
                                />
                            :
                                rotationArray.map( ( rotation, index ) => (
                                    <ShiftGroupComponent
                                        handleChangeRotationActive={ handleChangeRotationGroupActive }
                                        lastName={ lastName - 1 }
                                        rotation={ rotation }
                                        key={ index }
                                        type={ type }
                                        all={ false }
                                    />
                                ))
                        } 
                    </Collapse>
                </TransitionGroup>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {  
        dwellTimeByGroup: state.v3.shifts.shiftData.dwellTimeByGroup,
        rotationControl: state.v3.shifts.shiftData.rotationControl,          
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRotationControlByGroup: (data) => dispatch( setRotationControlByGroup( data ) ),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)( AdvancedOptionsRotationConfig );