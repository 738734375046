export const EDIT_FINANCES_SUCCESS = "EDIT_FINANCES_SUCCESS";
export const EDIT_FINANCES_CANCEL = "EDIT_FINANCES_CANCEL";
export const TOGGLE_FINANCES_LOADING = "TOGGLE_FINANCES_LOADING";
export const EDIT_FINANCES_ERROR = "EDIT_FINANCES_ERROR";
export const CHANGE_NAME_FINANCES = "CHANGE_NAME";
export const CHANGE_LAST_NAME_FINANCES = "CHANGE_LAST_NAME";
export const CHANGE_CUIT_FINANCES = "CHANGE_CUIT";
export const CHANGE_LEGAL_FINANCES = "CHANGE_LEGAL_FINANCES";
export const CHANGE_BUSINESS_NAME_FINANCES = "CHANGE_BUSINESS_NAME_FINANCES";
export const CHANGE_FANTASY_NAME_FINANCES = "CHANGE_FANTASY_NAME_FINANCES";
export const CHANGE_ADDRESS_FINANCES = "CHANGE_ADDRESS";
export const CHANGE_COUNTRY_FINANCES = "CHANGE_COUNTRY";
export const CHANGE_ZIP_CODE_FINANCES = "CHANGE_ZIP_CODE";
export const CHANGE_CITY_FINANCES = "CHANGE_CITY";
export const CHANGE_EMAIL_FINANCES = "CHANGE_EMAIL_FINANCES";
export const CHANGE_PHONE_FINANCES = "CHANGE_PHONE_FINANCES";

export const RESET_STEP_FINANCES = "RESET_STEP_FINANCES";
export const NEXT_STEP_FINANCES = "NEXT_STEP_FINANCES";
export const PREV_STEP_FINANCES = "PREV_STEP_FINANCES";

export const CHANGE_PLAN_FINANCES = "CHANGE_PLAN_FINANCES";
