import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from '../../actions/sidebarActions';
import Discount from '../../components/Discount';

const DiscountPage = (props) => {

    useEffect(() => {
        props.changeTitleAppBar("Descuentos");
        changePrimaryTitleAppBar("Configuración de descuentos");
        props.changeShowSidebar(true);
    }, [props]);

    return (
        <Discount/>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar()),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(DiscountPage);
