import React from "react";
import {Redirect, Route,} from "react-router-dom";
import {signOut,} from '../../actions';
import {connect} from 'react-redux';
import {SHARED_MENU_ROUTE} from "../../constants";
import { isAdminRole } from "../../utils/roles";

function GuardRoute(props) {
    const {type, signOut, ...rest} = props;
    // console.log("PROPS")
    // console.log(props)
    if (type === 'private' && ((!props.auth.uid && !props.profile) || (props.auth.isEmpty && props.auth.isLoaded))) {
        return <Redirect to='/login'/>
    } else if (type === 'private' && !props.profile.isEmpty &&  !isAdminRole(props.profile)) {
        signOut();
        return <Redirect to='/login'/>
    } else if (type === 'public' && !props.profile.isEmpty && isAdminRole(props.profile) && props.path !== SHARED_MENU_ROUTE) {
        return <Redirect to='/'/>
    }
    return (
        <Route {...rest} />
    );
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardRoute);