import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { closeErrorDialog, getPlanning, resetAllData } from "../../actions";

// Components
import ShowPlanningsComponent from "./components/ShowPlanningsComponent";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import PlanningForm from "./components/PlanningForm";

// import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";
// import ButtonWithIcon from "../NewComponents/ButtonWithIcon/ButtonWithIcon";
// import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
// import SaveChangesModal from "../Modals/SaveChangesModal";
// import OpeningHoursForm from "./OpeningHours";
// import HappyHoursForm from "./HappyHours";

// Constants
// import { ModalMode } from "../../constants/modalMode";

// Material Ui
import { Box } from "@mui/material";

// Styles
import { PlaningContainerStyles } from "./Styles";

const Planning = ( props ) => {

    // Actions
    const { 
        // closeErrorDialog,
        // resetAllData, 
        getPlanning, 
    } = props;

    // States
    const {
        idPartnerSelected,
        specialDates, 
        opennings,
        closings,
        viewMode,
        loading,
        reload,
        // edited, 
        // error 
    } = props;

    const [planningView, setPlanningView] = useState('view');

    useEffect(() => {
        if ( reload || idPartnerSelected ) {
            getPlanning();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, idPartnerSelected]);

    // const discardChanges = () => {
    //     resetAllData();
    //     return true;
    // };

    return (
        <Box sx={ PlaningContainerStyles }>
            {
                !loading ?
                    viewMode === 'VIEW' ?
                        <ShowPlanningsComponent 
                            planningsSpecialDates={ specialDates }
                            setPlanningView={ setPlanningView }
                            planningsOpennings={ opennings }
                            planningsClosings={ closings }
                        />
                    :
                        <PlanningForm planningView={ planningView } />
                        // <Box>
                        //     <OpeningHoursForm/>
                        //     <Divider sx={ DividerStyles }/>
                        //     <HappyHoursForm/>
                        // </Box>
                :
                    null
            }
            <ProgressIndicator open={ loading }/>
            {/* { edited &&
                <Box
                    sx={{
                        position: 'absolute', 
                        right: '4rem',
                    }}
                >
                    <ButtonWithIcon
                        onClick={ () => handleSavePlanning() }
                        text={ 'Crear horario' }
                        icon={ null }
                    /> 
                </Box> 
            } */}
            {/* <ProgressIndicator open={loading}/>
            <SaveChangesModal
                when={edited}
                submit={submit}
                handleClose={discardChanges}
            />
            <ErrorDialog 
                onAccept={closeErrorDialog}
                type={ModalMode.ERROR}
                open={error !== ''} 
                body={error ?? ''} 
                title={"Error"} 
            /> */}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        specialDates: state.planningReducer.specialDates,
        opennings: state.planningReducer.opennings,
        closings: state.planningReducer.closings,
        viewMode: state.planningReducer.viewMode,
        loading: state.planningReducer.loading,
        reload: state.planningReducer.reload,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeErrorDialog: () => dispatch( closeErrorDialog() ),
        resetAllData: () => dispatch( resetAllData() ),
        getPlanning: () => dispatch( getPlanning() ),
        // submit: () => dispatch(savePlanning()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
