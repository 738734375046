import React, {useEffect, useState} from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core";

// Chart
import c3 from "c3";
// import 'c3/c3.css';
import './GraphicsSection.css';
import {BookingOrigin, ReversBookingOrigin} from "../../../constants/bookingHistoryConstants";
import FilterButton from "../../GeneralInfo/Info General/FilterButton/FilterButton";

const useStyles = makeStyles(() => ({
    GeneralContainer: {
        border: '1px solid #DBD7DC',
        backgroundColor: 'white',
        borderRadius: '10px',
        // padding: '1rem',
        marginBottom: '1rem',
        width: '100%',
    },
    Title: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.2rem',
        fontWeight: '500',
        color: '#041C32',
        margin: '1rem'
    },
    Divider: {
        margin: '1rem 0',
        width: '100%',
        height: '1px',
        borderBottom: '1px solid #DBD7DC'
    },
    Chart: {
        width: '100%',
    },
    Selector: {
        justifyContent: 'flex-start',
        marginLeft: '1rem',
        marginBottom: '1rem',
        maxWidth: '12%',
    }
}));

const GraphicsSection = (props) => {

    const classes = useStyles();
    const [origin, setOrigin] = useState("1");

    const {data} = props;


    useEffect(() => {
        const dataSet = Object.keys(data ?? {}).map(key => key === origin ? [ReversBookingOrigin[Number(key)], ...Object.values(data[key] ?? {})] : []);
        const getPointColorByOrigin = () => {
            switch (Number(origin)) {
                case BookingOrigin['Tus clientes']:
                    return '#EEB115';
                case BookingOrigin.Woki:
                    return '#1AAFDE';
                case BookingOrigin.Link:
                    return '#F97F59';
                case BookingOrigin.Manual:
                    return '#B2B2B2';
                default:
                    return '#B2B2B2';
            }
        }
        const getColorsByOrigin = () => {
            const colors = {};
            Object.keys(data ?? {}).map(key => {
                switch (Number(key)) {
                    case BookingOrigin['Tus clientes']:
                        colors[ReversBookingOrigin[Number(key)]] = '#EEB115';
                        break;
                    case BookingOrigin.Woki:
                        colors[ReversBookingOrigin[Number(key)]] = '#1AAFDE';
                        break;
                    case BookingOrigin.Link:
                        colors[ReversBookingOrigin[Number(key)]] = '#F97F59';
                        break;
                    case BookingOrigin.Manual:
                        colors[ReversBookingOrigin[Number(key)]] = '#B2B2B2';
                        break;
                    default:
                        colors[ReversBookingOrigin[Number(key)]] = '#B2B2B2';
                }
            });
            return colors;
        }
        console.log(dataSet);
        console.log("KEYS: ", Object.keys(data["0"] ?? {}));
        c3.generate({
            bindto: document.getElementById("dinersByOriginAndDate"),
            data: {
                type: 'spline',
                x: 'x',
                columns: [
                    ['x', ...Object.keys(data !== undefined ? data["0"] : {})],
                    ...dataSet
                ],
                empty: {
                    label: {
                        text: "Sin datos"
                    }
                },
                colors: getColorsByOrigin()
            },
            axis: {
                x: {
                    type: isNaN(Number(Object.keys(data["0"] ?? {})[0])) ? "category" : undefined,
                    centered: isNaN(Number(Object.keys(data["0"] ?? {})[0])) ? true : undefined,
                    show: true,
                    tick: {
                        culling: {
                            max: 60 // the number of tick texts will be adjusted to less than this value
                        }
                    }
                },
                y: {
                    show: true,
                }
            },
            grid: {
                y: {
                    show: true
                }
            },
            legend: {
                show: false
            },
            size: {
                height: 300,
            },
            interaction: {
                enabled: true
            },
            tooltip: {
                show: true,
            },
            point: {
                show: true,
                r: 6,
            }
        });
        document.documentElement.style.setProperty('--dot-color', getPointColorByOrigin());
    }, [data, origin]);

    const handleOnOriginChange = (e) => {
        const value = e.target.value;
        setOrigin(`${BookingOrigin[value]}`);
    }
    return (
        <section className={classes.GeneralContainer}>
            <h1 className={classes.Title}>Ingresos por origen</h1>
            <div className={classes.Divider}></div>
            <div className={classes.Selector}>
                <FilterButton text={"Origen"} value={ReversBookingOrigin[origin]}
                              options={[...Object.keys(BookingOrigin)]}
                              onChange={handleOnOriginChange}/>
            </div>
            <div className={classes.Chart} id={"dinersByOriginAndDate"}/>
        </section>
    )
}

export default GraphicsSection
