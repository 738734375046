import {HOME_DATA_LOADED, HOME_DATA_LOADING_ERROR, LOADING_HOME_DATA} from "../../constants/homeConstants";
import {CLOSE_ERROR_DIALOG, RESET_ALL_DATA} from "../../constants";

const initState = {
    link: '',
    loading: false,
    error: undefined,
}

const homeReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_HOME_DATA:
            return {...state, loading: true, error: undefined};
        case HOME_DATA_LOADED:
            return {...state, loading: false, link: action.payload.link, error: undefined};
        case HOME_DATA_LOADING_ERROR:
            return {...state, loading: false, error: action.payload};
        case CLOSE_ERROR_DIALOG:
            return {...state, error: undefined};
        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default homeReducer;
