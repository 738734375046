// Material Ui
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const TableHeadSection3 = ( props ) => {

    // External props
    const { order, orderBy, onRequestSort, headCellsTable } = props;
    
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };

    const createSortHandler = (cell) => {
        const isAsc = orderBy === cell && order === 'asc';
        onRequestSort(cell, isAsc ? 'desc' : 'asc');
    };

    return (
        <TableHead className="table-header">
            <TableRow>
                {
                    headCellsTable.map((headCell) => (
                        <TableCell
                            sx={{
                                cursor: ( headCell.id !== 'phone' && headCell.id !== 'origin' ) ? 'pointer' : '',
                                fontFamily: 'Poppins', 
                                width: 'max-content',
                                fontSize: '.8rem', 
                                color: '#969696',
                                fontWeight: 500,
                            }}
                            sortDirection={orderBy === headCell.id ? order : false}
                            key={ headCell.id }
                            align='center'
                        >
                            {
                                ( headCell.id !== 'phone' && headCell.id !== 'origin' ) ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={(e) => createSortHandler(headCell.id)}
                                    >
                                        { headCell.columnName }
                                        {
                                            ( orderBy === headCell.id ) ? (
                                                <Box component="span" sx={ visuallyHidden }>
                                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                                </Box>
                                            ) : null
                                        }
                                    </TableSortLabel>
                                ) : headCell.columnName
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}

export default TableHeadSection3;