import {
    ADD_ENVIRONMENT_FILTER,
    ADD_FEATURE_FILTER,
    ADD_FOOD_FILTER,
    ADD_OTHER_FILTER,
    ADD_TAG_ITEM,
    FETCH_ALL_FILTERS_ERROR,
    FETCH_ALL_FILTERS_SUCCESS,
    FETCH_FILTERS_ERROR,
    FETCH_FILTERS_SUCCESS,
    REMOVE_ENVIRONMENT_FILTER,
    REMOVE_FEATURE_FILTER,
    REMOVE_FOOD_FILTER,
    REMOVE_OTHER_FILTER,
    REMOVE_TAG_ITEM,
    SAVE_FILTERS_ERROR,
    SAVE_FILTERS_SUCCESS,
    START_FETCH_ALL_FILTERS,
    START_FETCH_FILTERS,
    START_SAVE_FILTERS
} from "../../constants/filtersConstants";
import {deepEqual} from "../../utils/objectHelpers";
import {RESET_ALL_DATA} from "../../constants";

const initState = {
    allFilters: undefined,
    food: [],
    environment: [],
    features: [],
    others: [],
    tags: [],

    originalInfo: {},

    loading: false,
    edited: false,
    error: undefined,
}

const filtersReducer = (state = initState, action) => {
    let newState;
    const excludeKeys = ["loading", "edited", "error", "allFilters", "originalInfo"];
    switch (action.type) {
        case START_FETCH_ALL_FILTERS:
            newState = {
                ...state,
                loading: true,
                error: ""
            };
            break;
        case FETCH_ALL_FILTERS_SUCCESS:
            newState = {
                ...state,
                allFilters: action.payload,
                loading: false,
                error: ""
            };
            break;
        case FETCH_ALL_FILTERS_ERROR:
            newState = {
                ...state,
                loading: false,
                error: action.payload,
                allFilters: undefined,
            };
            break;
        case START_FETCH_FILTERS:
            newState = {
                ...state,
                loading: true,
                error: ""
            };
            break;
        case FETCH_FILTERS_SUCCESS:
            newState = {
                ...state,
                ...action.payload.characteristics,
                tags: action.payload.tags,
                originalInfo: {...action.payload.characteristics, tags: action.payload.tags},
                loading: false,
                error: ""
            };
            break;
        case FETCH_FILTERS_ERROR:
            newState = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case ADD_FOOD_FILTER:
            newState = {
                ...state,
                food: [...state.food, action.payload],
            };
            break;
        case REMOVE_FOOD_FILTER:
            newState = {
                ...state,
                food: state.food.filter(item => item !== action.payload),
            };
            break;
        case ADD_ENVIRONMENT_FILTER:
            newState = {
                ...state,
                environment: [...state.environment, action.payload],
            };
            break;
        case REMOVE_ENVIRONMENT_FILTER:
            newState = {
                ...state,
                environment: state.environment.filter(item => item !== action.payload),
            };
            break;
        case ADD_FEATURE_FILTER:
            newState = {
                ...state,
                features: [...state.features, action.payload],
            };
            break;
        case REMOVE_FEATURE_FILTER:
            newState = {
                ...state,
                features: state.features.filter(item => item !== action.payload),
            };
            break;
        case ADD_OTHER_FILTER:
            newState = {
                ...state,
                others: [...state.others, action.payload],
            };
            break;
        case REMOVE_OTHER_FILTER:
            newState = {
                ...state,
                others: state.others.filter(item => item !== action.payload),
            };
            break;

        case ADD_TAG_ITEM:
            newState = {
                ...state,
                tags: [...state.tags, action.payload],
            };
            break;
        case REMOVE_TAG_ITEM:
            newState = {
                ...state,
                tags: state.tags.filter(item => item !== action.payload),
            };
            break;

        case START_SAVE_FILTERS:
            newState = {
                ...state,
                loading: true,
                error: ""
            };
            break;
        case SAVE_FILTERS_SUCCESS:
            newState = {
                ...state,
                loading: false,
                error: "",
                originalInfo: {
                    food: state.food,
                    environment: state.environment,
                    features: state.features,
                    others: state.others,
                }
            };
            break;
        case SAVE_FILTERS_ERROR:
            newState = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;
        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
    return {...newState, edited: !deepEqual(newState.originalInfo, newState, excludeKeys)};
}

export default filtersReducer;
