import React from "react";

const TableIcon = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5921 5.64573L17.3303 1.23396C17.279 1.04874 17.1683 0.885459 17.0153 0.769122C16.8623 0.652785 16.6754 0.58981 16.4832 0.589844H3.12441C2.72735 0.589844 2.38323 0.85455 2.27735 1.23396L1.01558 5.64573C0.856759 6.20161 1.28029 6.76631 1.86264 6.76631H3.80382L2.74499 14.7075H4.5097L5.10088 10.2957H14.5156L15.0979 14.7075H16.8626L15.8038 6.76631H17.745C18.3273 6.76631 18.7509 6.20161 18.5921 5.64573ZM5.33029 8.53102L5.56852 6.76631H14.0391L14.2773 8.53102H5.33029ZM3.03617 5.00161L3.795 2.35455H15.8215L16.5803 5.00161H3.03617Z"
        fill="#0B4762"
      />
    </svg>
  );
};

TableIcon.defaultProps = {
  width: "19",
  height: "15",
};

export default TableIcon;
