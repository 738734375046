import React, {useEffect, useRef} from 'react';

// Material Ui
import {Grid, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Poppins, sans-serif',
        margin: '0rem .5rem 2rem .5rem',
        backgroundColor: 'white',
        borderRadius: '6px',
        height: '190px',
        width: '230px',
    },
    sector: {
        fontWeight: '600',
        fontSize: '20px',
        color: '#0B4762',
        margin: '0',
    },
    TextsTop: {
        height: '80%',
        border: '1px solid #DBD7DC',
        borderRadius: '6px 6px 0 0',
        padding: '1rem 1rem .5rem 1rem'
    },
    TextsContainer: {
        height: '4rem',
        display: 'flex',
        marginTop: '.5rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    priority: {
        marginBottom: '.2rem',
        lineHeight: '0px',
        fontSize: '14px',
        color: '#848282',
    },
    seeMore: {
        borderRadius: '0 0 6px 6px',
        backgroundColor: '#0B4762',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#FFFFFF',
        fontSize: '14px',
        display: 'flex',
        height: '100%',
        margin: '0',
    }
}));

const Sectors = (props) => {

    const classes = useStyles();
    const {name, capacity, tables, type, maxGroup} = props
    const {onClick} = props;
    const elementRef = useRef();

    useEffect(() => {
        if (name.length > 18) {
            const nameElement = elementRef.current;
            nameElement.innerHTML = nameElement.innerHTML.substring(0, 16);
            let addDot = document.createElement('span');
            addDot.innerHTML = "...";
            nameElement.appendChild(addDot);
        }
    }, [name]);

    return (
        <div className={classes.root} onClick={onClick}>
            <Grid container spacing={0} style={{height: '100%'}}>
                <Grid
                    item
                    xs={12}
                    className={classes.TextsTop}
                >
                    <h2
                        className={classes.sector}
                        ref={elementRef}
                    >{name}</h2>
                    <div className={classes.TextsContainer}>
                        <p className={classes.priority}>Capacidad máxima: {capacity}</p>
                        <p className={classes.priority}>Grupo máximo: {maxGroup ?? '-'}</p>
                        <p className={classes.priority}>Tipo de sector: {type}</p>
                        <p className={classes.priority}>Cantidad de mesas: {tables}</p>
                    </div>
                </Grid>
                <Grid item xs={12} style={{height: '20%'}}>
                    <p className={classes.seeMore}>Editar mesas</p>
                </Grid>
            </Grid>
        </div>
    )
}

export default Sectors
