import {
    CHANGE_ADDRESS_FINANCES,
    CHANGE_BUSINESS_NAME_FINANCES,
    CHANGE_CITY_FINANCES,
    CHANGE_COUNTRY_FINANCES,
    CHANGE_CUIT_FINANCES,
    CHANGE_EMAIL_FINANCES,
    CHANGE_FANTASY_NAME_FINANCES,
    CHANGE_LAST_NAME_FINANCES,
    CHANGE_LEGAL_FINANCES,
    CHANGE_NAME_FINANCES,
    CHANGE_PHONE_FINANCES,
    CHANGE_PLAN_FINANCES,
    CHANGE_ZIP_CODE_FINANCES,
    EDIT_FINANCES_CANCEL,
    EDIT_FINANCES_ERROR,
    EDIT_FINANCES_SUCCESS,
    NEXT_STEP_FINANCES,
    PARTNERS,
    PREV_STEP_FINANCES,
    RESET_STEP_FINANCES,
    TOGGLE_FINANCES_LOADING,
} from "../../constants";

import {deepCopyObject} from "../../utils/arrayHelpers";
import {deepEqual} from "../../utils/objectHelpers";

export const editFinancesCancel = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const {firestore} = getState();
        const {ordered} = firestore;
        const originalPartner = ordered[PARTNERS][0];

        dispatch(editFinancesCancelAction(originalPartner));
    };
};

export const editFinancesSuccess = () => ({
    type: EDIT_FINANCES_SUCCESS,
});

export const editFinancesCancelAction = (data) => ({
    type: EDIT_FINANCES_CANCEL,
    payload: data,
});

export const toggleFinancesLoading = () => ({
    type: TOGGLE_FINANCES_LOADING,
});

export const editFinancesError = (data) => ({
    type: EDIT_FINANCES_ERROR,
    payload: data,
});

export const changeFinancesName = (data) => ({
    type: CHANGE_NAME_FINANCES,
    payload: data,
});

export const changeFinancesLastName = (data) => ({
    type: CHANGE_LAST_NAME_FINANCES,
    payload: data,
});

export const changeFinancesCuit = (data) => ({
    type: CHANGE_CUIT_FINANCES,
    payload: data,
});

export const changeFinancesEmail = (data) => ({
    type: CHANGE_EMAIL_FINANCES,
    payload: data,
});

export const changeFinancesPhone = (data) => ({
    type: CHANGE_PHONE_FINANCES,
    payload: data,
});

export const changeFinancesAddress = (data) => ({
    type: CHANGE_ADDRESS_FINANCES,
    payload: data,
});

export const changeFinancesCity = (data) => ({
    type: CHANGE_CITY_FINANCES,
    payload: data,
});

export const changeFinancesCountry = (data) => ({
    type: CHANGE_COUNTRY_FINANCES,
    payload: data,
});

export const changeFinancesZipCode = (data) => ({
    type: CHANGE_ZIP_CODE_FINANCES,
    payload: data,
});

export const changeFinancesBusinessName = (data) => ({
    type: CHANGE_BUSINESS_NAME_FINANCES,
    payload: data,
});

export const changeFinancesFantasyName = (data) => ({
    type: CHANGE_FANTASY_NAME_FINANCES,
    payload: data,
});

export const changeFinancesLegal = (data) => ({
    type: CHANGE_LEGAL_FINANCES,
    payload: data,
});

export const resetStepFinances = () => ({
    type: RESET_STEP_FINANCES
})

export const prevStepFinances = (data) => ({
    type: PREV_STEP_FINANCES,
    payload: data,
});

export const nextStepFinances = (data) => ({
    type: NEXT_STEP_FINANCES,
    payload: data,
});

export const changeFinancesPlan = (data) => ({
    type: CHANGE_PLAN_FINANCES,
    payload: data,
});


/**
 * Crea o actualiza los datos del cliente
 * @returns {(function(*, *, {getFirebase: *, getFirestore: *}): Promise<boolean>)|*}
 */
export const submitFinancesChanges = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebaseReact = getFirebase();
        const { financesReducer, firestore, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { editReducer } = financesReducer;
        const { ordered } = firestore;

        const originalPartner = ordered[PARTNERS][0];

        const {
            businessName,
            fantasyName,
            last_name,
            clientId,
            zip_code,
            country,
            address,
            legal,
            phone,
            email,
            cuit,
            city,
            name,
        } = editReducer;

        dispatch(toggleFinancesLoading());

        let updateData = {};
        let increaseClient =
            originalPartner["billing_info"] !== undefined
                ? deepCopyObject(originalPartner["billing_info"])
                : {};

        if (
            originalPartner["billing_info"] === undefined ||
            name !== originalPartner["billing_info"]["name"]
        ) {
            updateData["billing_info.name"] = name;
            increaseClient.name = name;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            last_name !== originalPartner["billing_info"]["last_name"]
        ) {
            updateData["billing_info.last_name"] = last_name;
            increaseClient.last_name = last_name;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            cuit !== originalPartner["billing_info"]["cuit"]
        ) {
            updateData["billing_info.cuit"] = cuit;
            increaseClient.cuit = cuit;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            email !== originalPartner["billing_info"]["email"]
        ) {
            updateData["billing_info.email"] = email;
            increaseClient.email = email;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            phone !== originalPartner["billing_info"]["phone"]
        ) {
            updateData["billing_info.phone"] = phone;
            increaseClient.phone = phone;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            address !== originalPartner["billing_info"]["address"]
        ) {
            updateData["billing_info.address"] = address;
            increaseClient.address = address;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            city !== originalPartner["billing_info"]["city"]
        ) {
            updateData[`billing_info.city`] = city;
            increaseClient.city = city;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            country !== originalPartner["billing_info"]["country"]
        ) {
            updateData["billing_info.country"] = country;
            increaseClient.country = country;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            zip_code !== originalPartner["billing_info"]["zip_code"]
        ) {
            updateData["billing_info.zip_code"] = zip_code;
            increaseClient.zip_code = zip_code;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            legal !== originalPartner["billing_info"]["legal"]
        ) {
            updateData["billing_info.legal"] = legal;
            increaseClient.legal = legal;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            businessName !== originalPartner["billing_info"]["businessName"]
        ) {
            updateData["billing_info.businessName"] = businessName;
            increaseClient.businessName = businessName;
        }
        if (
            originalPartner["billing_info"] === undefined ||
            fantasyName !== originalPartner["billing_info"]["fantasyName"]
        ) {
            updateData["billing_info.fantasyName"] = fantasyName;
            increaseClient.fantasyName = fantasyName;
        }

        if ( idPartnerSelected ) {
            // Si no existe el clientId se da de alta por primera vez en increase
            if (clientId === undefined) {
                // console.log("Creando datos en increase");
                increaseClient.externalId = idPartnerSelected;
                let result = await createClientIncrease(firebaseReact, increaseClient);
                if (result) {
                    dispatch(editFinancesSuccess());
                    return true;
                } else {
                    dispatch(
                        editFinancesError("Ha ocurrido un error al guardar los datos")
                    );
                }
            }
            // Caso contrario se actualizan los datos en increase
            else {
                if (!deepEqual(increaseClient, originalPartner["billing_info"])) {
                    // console.log("Actualizando datos increase");
                    increaseClient.clientId = originalPartner["billing_info"]["clientId"];
                    increaseClient.partnerId = idPartnerSelected;
                    let result = await updateClientIncrease(firebaseReact, increaseClient);
                    if (result) {
                        dispatch(editFinancesSuccess());
                        return true;
                    } else {
                        dispatch(
                            editFinancesError("Ha ocurrido un error al actualizar los datos")
                        );
                    }
                } else {
                    dispatch(editFinancesSuccess());
                    return true;
                }
            }
        } else {
            dispatch(editFinancesError("El usuario no tiene un partner asignado"));
        }
        return false;
    };
};

export const checkPaymentMethod = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const { financesReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { editReducer } = financesReducer;

        dispatch(toggleFinancesLoading());

        const {clientId} = editReducer;

        const data = {
            clientId: clientId,
            partnerId: idPartnerSelected,
        };

        try {
            let res = await checkPaymentIncrease(firebaseReact, data);
            if (res) {
                dispatch(editFinancesSuccess());
                return true;
            } else {
                dispatch(
                    editFinancesError(
                        "Ha ocurrido un error al ingresar el método de pago. Por favor reintente luego"
                    )
                );
                return false;
            }
        } catch (error) {
            dispatch(
                editFinancesError(
                    "Ha ocurrido un error al ingresar el método de pago. Por favor reintente luego"
                )
            );
        }

        return false;
    };
};

export const updateSubscription = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const { financesReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {editReducer} = financesReducer;

        dispatch(toggleFinancesLoading());

        const {newPlanId, planId} = editReducer;

        let data = {
            planId: newPlanId,
            partnerId: idPartnerSelected,
        };

        try {
            // Si ya hay una suscripcion definida y no se establecio una nueva, no se modifico nada
            if (
                planId !== undefined &&
                (newPlanId === undefined || newPlanId === "")
            ) {
                return;
            }
            // Si no hay suscripcion definida y se define una nueva suscripcion, se crea la suscripcion en increase
            if (planId === undefined && newPlanId !== undefined && newPlanId !== "") {
                return await createSubscription(firebaseReact, data);
            }
            // Si hay una suscripcion definida, pero se define una nueva suscripcion se hace el cambio de suscripcion
            if (
                planId !== undefined &&
                planId !== newPlanId &&
                newPlanId !== undefined
            ) {
                delete data.planId;
                data.newPlanId = newPlanId;
                return await changeSubscription(firebaseReact, data)
            }
        } catch (error) {
            dispatch(
                editFinancesError(
                    "Ha ocurrido un error al suscribirse. Por favor reintente luego"
                )
            );
        } finally {
            dispatch(toggleFinancesLoading());
        }
        return false;
    };
};

export const cancelSubscription = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        dispatch(toggleFinancesLoading());

        try {
            let data = {
                partnerId: idPartnerSelected,
            };
            return await _cancelSubscription(firebaseReact, data);
        } catch (error) {
            dispatch(
                editFinancesError(
                    "Ha ocurrido un error al cancelar la suscripción. Por favor reintente luego"
                )
            );
        } finally {
            dispatch(toggleFinancesLoading());
        }
        return false;
    };
};

export const canChangeSubscription = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        dispatch(toggleFinancesLoading());
        try {
            let data = {
                partnerId: idPartnerSelected,
            };

            return await checkCanChangeSubscription(firebaseReact, data);
        } catch (error) {
            dispatch(
                editFinancesError(
                    "Ha ocurrido un error. Por favor reintente luego"
                )
            );
        } finally {
            dispatch(toggleFinancesLoading());
        }
        return false;
    }
}

const createClientIncrease = async (firebase, data) => {
    try {
        const callableReturnMessage = firebase.app()
            .functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION)
            .httpsCallable("createClient");
        let result = await callableReturnMessage(data);
        return result.data.result;
    } catch (error) {
        console.error(error);
    }
    return false;
};

const updateClientIncrease = async (firebase, data) => {
    try {
        const callableReturnMessage = firebase.app()
            .functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION)
            .httpsCallable("updateClient");
        let result = await callableReturnMessage(data);
        return result.data.result;
    } catch (error) {
        console.error(error);
    }
    return false;
};

const checkPaymentIncrease = async (firebase, data) => {
    const callableReturnMessage = firebase.app()
        .functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION)
        .httpsCallable("checkPaymentMethod");

    let result = await callableReturnMessage(data);

    return result.data.result;
};

const checkCanChangeSubscription = async (firebase, data) => {
    const callableReturnMessage = firebase.app().functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION).httpsCallable("canChangeSubscription");

    const result = await callableReturnMessage(data);
    return result.data.result;
}

const createSubscription = async (firebase, data) => {
    const callableFunction = firebase.app().functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION).httpsCallable("subscribeToPlan");

    let result = await callableFunction(data);
    return result.data;
};

const changeSubscription = async (firebase, data) => {
    const callableFunction = firebase.app()
        .functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION)
        .httpsCallable("changeSubscribedPlan");

    let result = await callableFunction(data);
    return result.data.result;
};

const _cancelSubscription = async (firebase, data) => {
    const callableFunction = firebase.app()
        .functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION)
        .httpsCallable("cancelSubscription");

    let result = await callableFunction(data);
    return result.data.result;
};
