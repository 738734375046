import {
    CANCEL_EDITING_MESSAGE,
    DELETE_NEW_MESSAGE,
    EDIT_MESSAGE_SUCCESSFUL,
    END_EDITING_MESSAGE,
    PARTNERS,
    START_CREATION_NEW_MESSAGE,
    START_EDITING_MESSAGE,
    TOGGLE_LOADING_CREATION,
} from '../../constants';

export const startEditingMessage = (data) => ({
    type: START_EDITING_MESSAGE,
    payload: data
})

export const cancelEditingMessage = () => ({
    type: CANCEL_EDITING_MESSAGE,
})

export const deleteMessage = (data) => ({
    type: DELETE_NEW_MESSAGE,
    payload: data
})

export const endEditingMessage = (data) => ({
    type: END_EDITING_MESSAGE,
    payload: data
})

export const editMessageSuccessful = () => ({
    type: EDIT_MESSAGE_SUCCESSFUL,
})

export const startCreationNewMessage = () => ({
    type: START_CREATION_NEW_MESSAGE
})

export const toggleMessageLoading = () => ({
    type: TOGGLE_LOADING_CREATION
})

export const updateMessages = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestoreReact = getFirestore();
        const {messagesReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {messages} = messagesReducer;

        dispatch(toggleMessageLoading)

        try {
            await firestoreReact.collection(PARTNERS).doc( idPartnerSelected ).update({
                messages: messages
            })
            dispatch(editMessageSuccessful())
            return true;
        } catch (e) {
            console.error(e)
            return false;
        }
    }
}
