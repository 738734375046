import React, {useEffect} from "react";
import {connect} from "react-redux";

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";

// Components
import SectorsForm from "../../components/SectorsAndTables";

// Material Ui
import {Grid} from "@material-ui/core";

function SectorsPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Sectores y Mesas")
        props.changeShowSidebar(true);
        props.changePrimaryTitleAppBar("Configuración de las mesas de tu establecimiento")
    }, [props])

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <SectorsForm/>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsPage);
