import React, { useEffect } from 'react';

import { connect } from 'react-redux';

// Components
import ScheduledClosingsForm from './Forms/ScheduledClosingsForm';
import CreatePlanningLayout from '../layout/CreatePlanningLayout';
import OpenningHoursForm from './Forms/OpenningHoursForm';
import SpecialDatesForm from './Forms/SpecialDatesForm';
import { resetPlanningData } from '../../../actions';

const PlanningForm = ( props ) => {

    // Actions
    const { resetPlanningData } = props;

    // External props
    const { planningView } = props;

    // States
    const { planningSelected, viewMode } = props;

    useEffect(() => {
        if ( viewMode === 'NEW' ) {
            resetPlanningData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ planningView ]);

    const handleShowForm = () => {
        switch ( planningSelected ) {
            case 'Horario de apertura':
                return <OpenningHoursForm planningView={ planningView } />;
            case 'Fechas especiales':
                return <SpecialDatesForm planningView={ planningView } />;
            default:
                return <ScheduledClosingsForm planningView={ planningView } />;
        }
    }

    return (
        <CreatePlanningLayout>
            {
                handleShowForm()
            }
        </CreatePlanningLayout>
    )
}



const mapDispatchToProps = ( dispatch ) => {
    return {
        resetPlanningData: () => dispatch( resetPlanningData() )
    };
};

const mapStateToProps = (state) => {
    return {
        planningSelected: state.planningReducer.planningSelected,
        viewMode: state.planningReducer.viewMode,
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( PlanningForm );