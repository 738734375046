import React, { useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { setEditMultipleClicksOptional, setEditOneClickOptional, setRemoveMultipleClicksOptional, setRemoveOneClickOptional } from '../../../actions/optionalActions';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { Box, IconButton, TextField } from '@mui/material';

const OptionComponent = ( props ) => {

    // Actions
    const { 
        setRemoveMultipleClicksOptional, 
        setEditMultipleClicksOptional,
        setRemoveOneClickOptional,
        setEditOneClickOptional 
    } = props;

    // External props
    const { optionText, id, optionSelected } = props;

    const [inputWidth, setInputWidth] = useState( 0 );

    const handleDeleteOption = () => {
        if ( optionSelected === 'Click único' ) {
            setRemoveOneClickOptional({ 
                id: id 
            });
        } else {
            setRemoveMultipleClicksOptional({ 
                id: id 
            });
        }
    };

    const handleChangeOptionText = ( e ) => {
        setInputWidth( e.target.value.length );
        if ( e.target.value.length <= 30 ) {
            if ( optionSelected === 'Click único' ) {
                setEditOneClickOptional({
                    id,
                    newText: e.target.value,
                });
            } else {
                setEditMultipleClicksOptional({
                    id,
                    newText: e.target.value,
                });
            }
        }
    }

    return (
        <Box 
            sx={{
                justifyContent: 'space-between',
                border: optionText === '' ? '1px solid #FB3F4A' : '1px solid #D1D1D1',
                alignItems: 'center',
                maxWidth: '16.25rem',
                borderRadius: '8px',
                textAlign: 'left',
                p: '.3rem .5rem',
                display: 'flex',
                width: 'auto',
                '&:hover': {
                    border: '1px solid #0B4762'
                }
            }}
        >
            <TextField
                onChange={ handleChangeOptionText }
                sx={{
                    '& .MuiInputBase-input': {
                        width: `${ inputWidth }ch`,
                        boxSizing: 'border-box',
                        fontFamily: 'Poppins',
                        maxWidth: '240px',
                        fontSize: '.9rem',
                        whiteSpace: 'pre',
                        minWidth: '8rem',
                        color: '#041C32',
                        fontWeight: 500,
                        p: '0 0 0 14px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }}
                }
                value={ optionText } 
                size="small"
                label=""
            />
            <IconButton onClick={ handleDeleteOption } >
                <CloseIcon sx={{ color: '#041C32', height: '1rem', width: '1rem' }} />
            </IconButton>
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEditMultipleClicksOptional: ({ id, newText }) => dispatch( setEditMultipleClicksOptional({ id, newText }) ),
        setEditOneClickOptional: ({ id, newText }) => dispatch( setEditOneClickOptional({ id, newText }) ),
        setRemoveMultipleClicksOptional: ({ id }) => dispatch( setRemoveMultipleClicksOptional({ id }) ),
        setRemoveOneClickOptional: ({ id }) => dispatch( setRemoveOneClickOptional({ id }) ),
    };
};

export default connect( null, mapDispatchToProps )( OptionComponent );