import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Actions
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {
    removeEnvironmentFilter,
    addEnvironmentFilter,
    removeFeatureFilter,
    removeOtherFilter,
    removeFoodFilter,
    addFeatureFilter,
    fetchAllFilters,
    addOtherFilter,
    addFoodFilter,
    removeTagItem,
    fetchFilters,
    resetAllData,
    saveFilters,
    addTagItem,
} from "../../actions";

// Components
import InputWithCondition from '../../components/NewComponents/InputWhitCondition/InputWithCondition';
import ProgressIndicator from "../../components/GeneralComponents/ProgressIndicator";
import ChipsWithCross from '../../components/Filters/ChipsWithCross/ChipsWithCross';
import AddButton from '../../components/NewComponents/Buttons/Add/AddButton';
import SaveChangesModal from "../../components/Modals/SaveChangesModal";
import ChipFilters from '../../components/Filters/Chips/ChipFilters';

// import SaveChangesButton from "../../components/NewComponents/Buttons/Save Changes/SaveChangesButton";

// Constants
import {PARTNERS} from "../../constants";

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import { Box, Button } from '@mui/material';
import {Grid} from '@material-ui/core';

// Styles
import { SaveUserButtonStyles } from '../../components/Users/Styles';

const useStyles = makeStyles(() => ({
    GeneralContainerFilters: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#FCFCFC',
        paddingRight: '3rem',

    },
    titleGeneralInfo: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.6rem',
        fontWeight: '600',
        color: '#041C32'
    },
    subtitleGeneralInfo: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32'
    },
    CategoriesContainer: {
        paddingBottom: '4rem',
        paddingRight: '2rem',
        flexFlow: 'wrap',
        display: 'flex',
        gap: '15px',
        '@media (min-width: 1700px)': {
            paddingRight: '7rem',
        },
    },
    ItemsContainer: {
        display: 'flex',
        gap: '15px'
    },
    AddNewTagsContainer: {
        flexFlow: 'wrap',
        display: 'flex',
        width: '70%',
        gap: '15px'
    },
    NewTagsContainer: {
        flexFlow: 'wrap',
        display: 'flex',
        width: '100%',
        gap: '15px'
    }
}));

const FiltersPages = (props) => {

    const classes = useStyles();
    const [newTagName, setNewTagName] = useState('');
    // const [newTags, setNewTags] = useState([{
    //     nameTag: ''
    // }]);

    const {
        allFilters,
        food,
        environment,
        features,
        others,
        tags,
        loading,
        // error,
        edited,
        partner
    } = props;

    // Categorias y caracteristicas distintivas disponibles
    const {
        fetchFilters,
        fetchAllFilters,
        saveFilters,
        addFoodFilter,
        removeFoodFilter,
        addEnvironmentFilter,
        removeEnvironmentFilter,
        addFeatureFilter,
        removeFeatureFilter,
        addOtherFilter,
        removeOtherFilter,
        addTagItem,
        removeTagItem,
        resetAllData,
    } = props;

    useEffect(() => {
        props.changeTitleAppBar("Filtros")
        props.changeShowSidebar(true);
        if (partner) {
            fetchAllFilters();
            fetchFilters();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);

    const createNewTag = () => {
        if (newTagName.trim() !== '') {
            addTagItem(newTagName);
            setNewTagName('');
        }
    }

    const handleDeleteNewTag = (tag) => {
        removeTagItem(tag);
    }
    let handleFoodFilter = (filter, checked) => {
        if (checked) {
            addFoodFilter(filter);
        } else {
            removeFoodFilter(filter);
        }
    }

    let handleEnvironmentFilter = (filter, checked) => {
        if (checked) {
            addEnvironmentFilter(filter);
        } else {
            removeEnvironmentFilter(filter);
        }
    }

    let handleFeatureFilter = (filter, checked) => {
        if (checked) {
            addFeatureFilter(filter);
        } else {
            removeFeatureFilter(filter);
        }
    }

    let handleOtherFilter = (filter, checked) => {
        if (checked) {
            addOtherFilter(filter);
        } else {
            removeOtherFilter(filter);
        }
    }

    const discardChanges = () => {
        resetAllData();
        return true;
    }

    return (
        <div
            className={classes.GeneralContainerFilters}
        >
            <h1 className={classes.titleGeneralInfo}>Tipo de comidas</h1>
            <div className={classes.CategoriesContainer}>
                {allFilters
                    ? allFilters.food?.map(
                        (filter) => (
                            <ChipFilters
                                key={filter}
                                text={filter}
                                checked={
                                    food && food.includes(filter)
                                }
                                disabled={food.length >= 3}
                                onChange={handleFoodFilter}
                            />
                        )
                    )
                    : null
                }
            </div>
            <hr style={{border: '1px solid #B2B2B2'}}/>
            <br/>
            <h1 className={classes.titleGeneralInfo}>Ambiente</h1>
            <div className={classes.CategoriesContainer}>
                {
                    allFilters
                        ? allFilters.environment.map(filter => (
                            <ChipFilters
                                key={filter}
                                text={filter}
                                disabled={environment.length >= 3}
                                checked={
                                    environment && environment.includes(filter)
                                }
                                onChange={handleEnvironmentFilter}
                            />
                        ))
                        : null
                }
            </div>
            <hr style={{border: '1px solid #B2B2B2'}}/>
            <br/>
            <div className={classes.CategoriesContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h1 className={classes.titleGeneralInfo}>Cuenta con</h1>
                        <div className={classes.ItemsContainer}>
                            {
                                allFilters
                                    ? allFilters.features.map(filter => (
                                        <ChipFilters
                                            key={filter}
                                            text={filter}
                                            checked={
                                                features && features.includes(filter)
                                            }
                                            onChange={handleFeatureFilter}
                                        />
                                    ))
                                    : null
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <h1 className={classes.titleGeneralInfo}>Otros</h1>
                        <div className={classes.ItemsContainer}>
                            {
                                allFilters
                                    ? allFilters.others.map(filter => (
                                        <ChipFilters
                                            key={filter}
                                            text={filter}
                                            checked={
                                                others && others.includes(filter)
                                            }
                                            onChange={handleOtherFilter}
                                        />
                                    ))
                                    : null
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
            <hr style={{border: '1px solid #B2B2B2'}}/>
            <br/>
            <h1 className={classes.titleGeneralInfo}>Tags</h1>
            <div className={classes.CategoriesContainer}>
                <div className={classes.AddNewTagsContainer}>
                    <InputWithCondition
                        text={'Nombre del tag nuevo'}
                        helperText={'Ej: vista al mar, churros, playa.'}
                        width={'40%'}
                        onChange={(e) => setNewTagName(e.target.value)}
                        onEnter={createNewTag}
                        value={newTagName}
                    />
                    <AddButton text={'Agregar'} onClick={createNewTag}/>
                </div>
                <div className={classes.NewTagsContainer}>
                    {tags ?
                        tags.map((tag, index) =>
                            <ChipsWithCross
                                key={index}
                                text={tag}
                                onDelete={() => handleDeleteNewTag(tag)}/>
                        )
                        :
                        null
                    }
                </div>
            </div>
            <ProgressIndicator open={loading}/>
            <Box 
                sx={{
                    display: edited ? 'block' : 'none',
                    transform: 'translateY(-50%)',
                    position: 'fixed',
                    bottom: '2rem',
                    left: '50%',
                    zIndex: 4, 
                }}
            >
                <Button
                    sx={ SaveUserButtonStyles } 
                    onClick={ saveFilters } 
                >
                    Guardar cambios
                </Button>
            </Box>
            {/* {edited ? <SaveChangesButton onClick={saveFilters}/> : null} */}
            <SaveChangesModal
                when={edited}
                submit={saveFilters}
                handleClose={discardChanges}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        allFilters: state.filtersReducer.allFilters,
        food: state.filtersReducer.food,
        environment: state.filtersReducer.environment,
        features: state.filtersReducer.features,
        others: state.filtersReducer.others,
        tags: state.filtersReducer.tags,

        loading: state.filtersReducer.loading,
        error: state.filtersReducer.error,
        edited: state.filtersReducer.edited,

        partner: state.firestore.ordered[PARTNERS],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),

        addFoodFilter: (data) => dispatch(addFoodFilter(data)),
        removeFoodFilter: (data) => dispatch(removeFoodFilter(data)),
        addEnvironmentFilter: (data) => dispatch(addEnvironmentFilter(data)),
        removeEnvironmentFilter: (data) => dispatch(removeEnvironmentFilter(data)),
        addFeatureFilter: (data) => dispatch(addFeatureFilter(data)),
        removeFeatureFilter: (data) => dispatch(removeFeatureFilter(data)),
        addOtherFilter: (data) => dispatch(addOtherFilter(data)),
        removeOtherFilter: (data) => dispatch(removeOtherFilter(data)),
        addTagItem: (data) => dispatch(addTagItem(data)),
        removeTagItem: (data) => dispatch(removeTagItem(data)),

        fetchFilters: () => dispatch(fetchFilters()),
        fetchAllFilters: () => dispatch(fetchAllFilters()),
        saveFilters: () => dispatch(saveFilters()),
        resetAllData: () => dispatch(resetAllData()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPages);
