import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";

// Actions
import {
    addNewImage,
    closeErrorDialog,
    removeImage,
    replaceFacadeImage,
    resetAllData,
    updateImages,
} from "../../actions";

// Constants
import {PARTNERS} from "../../constants";

// Components
import Photos from "./Photos";
import SaveChangesModal from "../Modals/SaveChangesModal";
import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";

// Material UI
import {makeStyles} from '@material-ui/core';
import Requirements from "./Requirements";
import ImageSelectorContainer from "./ImageSelectorContainer";
import Thumbnail from "./Thumbnail";
import {ModalMode} from "../../constants/modalMode";
import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
import CustomSnakbar from "../GeneralComponents/CustomSnakbar";


const useStyles = makeStyles(() => ({
    SectionTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.4rem',
        fontWeight: '600',
        marginTop: '0'
    },
}));

function PhotosForm(props) {
    const classes = useStyles();

    // Variables
    const {
        loading,
        images,
        edited,
        error,
    } = props;
    // Actions
    const {
        addImage,
        replaceFacadeImage,
        removeImage,
        updateImages,
        closeErrorDialog,
        resetAllData,
    } = props;

    const [imagesError, setImagesError] = useState("");

    const [mockImages, setMockImages] = useState([]);

    useEffect(() => {
        if (images.length < 11) {
            const aux = [];
            for (let i = 0; i < 11 - images.length; i++) {
                aux.push(null);
            }
            setMockImages([...aux]);
        }
        else {
            setMockImages([]);
        }
    }, [images])

    const getFacade = () => {
        if (images.length > 0) {
            if (typeof images[0] === 'string') {
                return images[0];
            } else if (images[0] !== null) {
                return URL.createObjectURL(images[0]);
            }
        }
        return null
    };

    const validImage = (file) => {
        if (file.size > 5000000) {
            setImagesError("El tamaño máximo de la imagen es de 5MB");
            return false;
        }
        return true;
    }

    const handleAddNewFacadeImage = (file) => {
        if (file === undefined) {
            return;
        }
        if (!validImage(file)) {
            console.log("Invalid image");
            return
        }
        if (getFacade() !== null) {
            replaceFacadeImage(file);
        } else {
            addImage(file);
        }
    }

    const handleAddImage = (file) => {
        if (file !== undefined && validImage(file)) {
            addImage(file);
        }
    }

    const handleRemoveFacadeImage = () => {
        if (getFacade() !== null)
            removeImage(0);
    }

    const onSave = async () => {
        if (images.length === 0) {
            setImagesError("Debe agregar al menos una imagen");
            return;
        }
        if (images.length > 0) {
            if (images[0] === null) {
                setImagesError("Debe agregar una imagen de portada");
                return;
            }
        }
        await updateImages();
        return true;
    }

    const discardChanges = () => {
        resetAllData();
        return true;
    }

    function getSrc(img) {
        if (img !== null) {
            if (typeof img === 'string') {
                return img;
            } else {
                return URL.createObjectURL(img);
            }
        }
        return null;
    }

    console.log("Imagenes : ", images.length)
    console.log("Imagenes mock: ", mockImages.length)
    return (
        <div>
            <div style={{maxWidth: '98%'}}>
                <h1
                    className={classes.SectionTitle}
                >Foto de portada</h1>
                <div style={{display: 'flex', height: '450px'}}>
                    <Photos img={getFacade()} onHandleDrop={handleAddNewFacadeImage}
                            onRemove={handleRemoveFacadeImage}/>
                    <div style={{marginLeft: '20px', width: '100%'}}>
                        <Requirements
                            onChangePhoto={handleAddNewFacadeImage}/>
                    </div>
                </div>
            </div>
            <ImageSelectorContainer
                style={{marginTop: '50px', marginBottom: '50px'}}
                title={"Fotos del establecimiento"}
                addImage={handleAddImage}
                canAdd={images.length < 11}
            >
                {images.slice(1).concat(mockImages).map((img, index) => (
                    <Thumbnail
                        src={getSrc(img)}
                        alt={`image-${index + 1}`}
                        onRemove={() => removeImage(index + 1)}/>
                ))}
            </ImageSelectorContainer>
            {!edited
                ? null
                : <SaveChangesButton onClick={onSave}/>}
            <SaveChangesModal
                when={false}
                submit={onSave}
                handleClose={discardChanges}
            />
            <CustomSnakbar
                open={imagesError !== ""}
                duration={3000}
                onClose={() => setImagesError("")}
                message={imagesError}
            />
            <ErrorDialog open={error !== undefined && error !== ""} title={"Error al guardar los cambios"}
                         body={error ?? ""}
                         type={ModalMode.ERROR} onAccept={closeErrorDialog}/>
            <ProgressIndicator open={loading}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,

        images: state.photosReducer.images,
        edited: state.photosReducer.edited,
        loading: state.photosReducer.loading,
        error: state.photosReducer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addImage: (data) => dispatch(addNewImage(data)),
        replaceFacadeImage: (data) => dispatch(replaceFacadeImage(data)),
        removeImage: (data) => dispatch(removeImage(data)),
        updateImages: (data) => dispatch(updateImages(data)),

        closeErrorDialog: () => dispatch(closeErrorDialog()),
        resetAllData: () => dispatch(resetAllData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosForm);
