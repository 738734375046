import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';

import GeneralInfoForm from '../../components/GeneralInfo';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";

function GeneralInformationPage(props) {
    useEffect(() => {
        props.changeTitleAppBar("Información general")
        props.changeShowSidebar(true);
    }, [props])


    return (
        <Grid container>
            <Grid item xs={12}>
                <GeneralInfoForm/>
            </Grid>
        </Grid>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(GeneralInformationPage);