import React from 'react';

const ClientNoShowsIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_184_3265)">
                <path d="M12.7077 12.7075C11.4968 13.6305 10.0224 14.1418 8.50016 14.1667C3.54183 14.1667 0.708496 8.5 0.708496 8.5C1.58958 6.85801 2.81163 5.42343 4.29266 4.2925" stroke="#818181" strokeWidth="1.41667" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.0017 10.0017C9.80715 10.2104 9.57255 10.3779 9.31189 10.494C9.05122 10.6102 8.76983 10.6726 8.48451 10.6777C8.19918 10.6827 7.91577 10.6302 7.65117 10.5234C7.38657 10.4165 7.14621 10.2574 6.94442 10.0556C6.74263 9.85383 6.58356 9.61346 6.47668 9.34886C6.3698 9.08426 6.31732 8.80085 6.32235 8.51552C6.32739 8.2302 6.38984 7.94881 6.50598 7.68815C6.62213 7.42748 6.78958 7.19288 6.99836 6.99834" stroke="#818181" strokeWidth="1.41667" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M0.708496 0.70833L16.2918 16.2917" stroke="#818181" strokeWidth="1.41667" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.01245 3.00334C7.50002 2.88921 7.99921 2.83216 8.49995 2.83334C13.4583 2.83334 16.2916 8.5 16.2916 8.5C15.8616 9.30439 15.3489 10.0617 14.7616 10.7596" stroke="#818181" strokeWidth="1.41667" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_184_3265">
                    <rect width="17" height="17" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ClientNoShowsIcon;