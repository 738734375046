import React from 'react';
import {Typography} from "@mui/material";
import './Table.css';

const Table = React.forwardRef(({style, className, dataGrid, ...props}, ref) => {
    const {table, ignoreClick} = props;
    const {onTableClick, resetignoreclick} = props;
    const {alias, minCapacity, maxCapacity} = table;

    const handleOnTableClick = (e) => {
        onTableClick(e, table);
    }

    const handleOnClick = (e) => {
        if (ignoreClick) {
            e.stopPropagation();
            resetignoreclick();
        }
    }

    return (
        <div onClick={handleOnTableClick}>
            <div
                className={["Table", className].join(' ')}
                key={props.key}
                ref={ref}
                style={{...style}}
                onMouseDown={props.onMouseDown}
                onMouseUp={props.onMouseUp}
                onTouchStart={props.onTouchStart}
                onTouchEnd={props.onTouchEnd}
                onClick={handleOnClick}
            >
                <Typography variant="h3" sx={{fontSize: '24px'}}>
                    {alias.length > 4 ? alias.substring(0, 4) + '...' : alias}
                </Typography>
                <Typography variant='h5' sx={{fontSize: '16px'}}>
                    {`${minCapacity}-${maxCapacity}`}
                </Typography>
            </div>
        </div>
    );
});

export default Table