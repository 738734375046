import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowDownPrimary';
import ArrowUpPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowUpPrimary';
import Categories from '../../GeneralInfo/Info General/Categories';
import {getNameBookingItemState} from "../../../constants/bookingItemsState";
import {getNameQueueItemState} from "../../../constants/queueItemsState";
import {getNameOrigin} from "../../../constants/providence";

const useStyles = makeStyles(() => ({
    Dropdown: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        display: 'inline-block',
        width: '180px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        position: 'absolute',
        zIndex: '20',
        marginBottom: '20px'
    },
    DropdownBtn: {
        padding: '5px 0px 0px 10px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    DropdownContent: {
        padding: '0px 10px 10px 10px',
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '0px 0px 20px 20px',
        boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.2)',
        borderTop: 'none',
        transitionDelay: '.9s'
    },
    DropdownItem: {
        padding: '3px 0px',
        cursor: 'pointer',
        transition: 'all .3s',
        "&:hover": {
            backgroundColor: '#f4f4f4'
    },
    ArrowUp: {
        paddingRight: '20px'
    }
    },
    Filters: {
        color: '#848282',
        fontSize: '12px',
        margin: '0px',
        marginBottom: '5px'
    }
}));

const DropdownFilter = (props) => {
    const classes = useStyles();

    const {filterStateOptions, filterOriginOptions, isQueueItem, filterState, filterOrigin} = props

    const {onChange} = props

    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState('30px');
    const [display, setDisplay] = useState('none');
    const [rotate, setRotate] = useState('rotate(0deg)');
    const [values, setValues] = useState([...filterState, ...filterOrigin])

    const handleContent = () => {
        if (open) {
            onChange(values)
        }
        setOpen(!open);
        setHeight('auto');
        setDisplay('block');
        setRotate('rotate(180deg)');

        if (height === "auto" || display === "block" || rotate === 'rotate(180deg)') {
            setHeight('30px');
            setDisplay('none');
            setRotate('rotate(0deg)');
        }
    }

    const handleChange = (value) => {
        if (values.includes(value)) {
            let index = values.findIndex((v => v === value))
            if (index !== -1 ) {
                setValues([...values.filter(v => v !== value)]);
            }
        }
        else {
            setValues([...values, value])
        }
    }

    return (
        <div className={classes.Dropdown} style={{height: height}}>
            <div className={classes.DropdownBtn}>
                Filtrar
                <div onClick={() => (handleContent())}>
                    {open ?
                        <div style={{paddingRight: '18px'}}>
                            <ArrowUpPrimary width={"20px"} height={"20px"}/>
                        </div>
                        :
                        <div style={{paddingRight: '5px'}}>
                            <ArrowDownPrimary width={"20px"} height={"20px"}/>
                        </div>
                    }
                </div>
            </div>
            <div className={classes.DropdownContent} style={{display: display}}>
                <p className={classes.Filters}>Estado final</p>
                {filterStateOptions.map(state => (
                    <Categories
                        name={isQueueItem ? getNameQueueItemState(state) : getNameBookingItemState(state)}
                        checked={values.includes(state)}
                        onChange={() => handleChange(state)}
                    />
                    ))}
                <p className={classes.Filters}>Origen</p>
                <div>
                    {filterOriginOptions.map(origin => (
                        <Categories
                            name={getNameOrigin(origin)}
                            checked={values.includes(origin)}
                            onChange={() => handleChange(origin)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DropdownFilter
