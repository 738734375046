import React, { useState } from 'react';

// Components
import InputWithCondition from '../../NewComponents/InputWhitCondition/InputWithCondition';

// Constants
import { PASSWORD_MIN_LENGTH } from "../../../constants";

// Materia Ui
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Backdrop, Box, Fade, Modal } from '@mui/material';

// Utils
import { validatePassword } from "../../../utils/validation";

const useStyles = makeStyles(() => ({
    Title: {
        fontFamily: 'Poppins, sans-serif',
        margin: '0 0 .5rem 0',
        fontSize: '1.6rem',
        color: '#041C32',
    },
    InputsContainer: {
        flexDirection: 'column',
        display: 'flex',
        gap: '20px'
    },
    ButtonContainer: {
        justifyContent: 'right',
        alignItems: 'center',
        marginTop: '1rem',
        display: 'flex',
        gap: '15px'
    },
    ButtonCancel: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        backgroundColor: 'white',
        padding: '.3rem 1.6rem',
        textTransform: 'none',
        borderRadius: '10px',
        color: '#0B4762',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    ButtonCreate: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        backgroundColor: '#0B4762',
        padding: '.3rem 1.4rem',
        textTransform: 'none',
        borderRadius: '10px',
        color: 'white',
        '&:hover': {
            backgroundColor: '#0B4762 !important'
        }
    }
}))

const ChangePassword = (props) => {

    const { dialogState, dialogController, onSubmit, user } = props;

    const classes = useStyles();

    const [verifyPasswordError, setVerifyPasswordError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [verifyPassword, setVerifyPassword] = useState('');
    const [password, setPassword] = useState('');

    function clean() {
        setPasswordError(false);
        setVerifyPasswordError(false);
        setVerifyPassword('');
        setPassword('');
    };

    const submitNewPassword = () => {
        let thereIsError = false;

        if (!validatePassword(password)) {
            thereIsError = thereIsError || true;
            setPasswordError(true);
        }
        if (password !== verifyPassword) {
            thereIsError = thereIsError || true;
            setVerifyPasswordError(true);
        }
        if (!thereIsError) {
            onSubmit(user, password);
            clean();
        }
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
    };

    const onChangeVerifyPassword = (e) => {
        setVerifyPassword(e.target.value);
        setVerifyPasswordError(false);
    };

    return (
        <Modal
            aria-describedby="modal-modal-description"
            onClose={ () => dialogController() }
            aria-labelledby="modal-modal-title"
            slots={{ backdrop: Backdrop }}
            open={ !!dialogState }
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ !!dialogState }>
                <Box
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        borderRadius: '10px',
                        bgcolor: 'white',
                        border: 'none',
                        width: '575px',
                        boxShadow: 24,
                        left: '50%',
                        top: '50%',
                        p: 4,
                        '&:focus-visible': {
                            outline: 'none'
                        }
                    }}
                >
                    <h2 className={classes.Title}>Cambiar contraseña</h2>
                    <div className={classes.InputsContainer}>
                        <InputWithCondition
                            text={'Nueva contraseña'}
                            helperText={`La contraseña debe tener un mínimo de ${PASSWORD_MIN_LENGTH} caracteres`}
                            width={'100%'}
                            margin={'1rem 0'}
                            type={'password'}
                            error={passwordError}
                            value={password}
                            onChange={onChangePassword}
                        />
                        <InputWithCondition
                            text={'Repetir nueva contraseña'}
                            helperText={''}
                            width={'100%'}
                            type={'password'}
                            error={verifyPasswordError}
                            value={verifyPassword}
                            onChange={onChangeVerifyPassword}
                        />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <Button className={classes.ButtonCancel} onClick={() => dialogController()}>
                            Cancelar
                        </Button>
                        <Button className={classes.ButtonCreate} onClick={() => submitNewPassword()}>
                            Guardar cambios
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ChangePassword;
