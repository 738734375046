import React from 'react';

// Icons
import VerticalLineIcon from '../../../../../SvgComponents/icons/ShiftIcons/VerticalLineIcon';
import CubiertosIcon from '../../../../../SvgComponents/icons/IconsPrimary/CubiertosIcon';
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';

// Styles
import { DataBookingHourContainerStyles, ChipHourStyles } from '../../../../Styles';

const SubshiftHoursComponent = ({ bookingHour, getHours, handleActiveChange, showCross }) => {
    return (
        <Box 
            key={ bookingHour.time } 
            sx={{
                padding: showCross ? '4px 4px 4px 13px' : '4px 13px 4px 13px',
                border: '1px solid #0B4762',
                flexDirection: 'row',
                alignItems: 'center',
                width: 'max-content',
                borderRadius: '9px',
                display: 'flex',
                height: '35px',
                mb: '.3rem',
                gap: '5px'
            }} 
        >
            <Typography sx={ ChipHourStyles } >
                {`${ getHours( bookingHour.time ) }hs`}
            </Typography>
            <VerticalLineIcon height='17px' />
            <Box sx={ DataBookingHourContainerStyles }>
                <CubiertosIcon height="16.76px" width="13.41px" fill="#0B4762" />
                <Typography 
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '1rem',
                        color: '#0B4762',
                        fontWeight: 500,
                        mb: '-.1rem'
                    }} 
                >
                    { bookingHour.flowControl === 0 || bookingHour.flowControl === null ? 'Ilim' : bookingHour.flowControl }
                </Typography>
            </Box>
            {
                showCross ? 
                    <IconButton onClick={ handleActiveChange } sx={{ p: '4px' }} >
                        <CloseIcon sx={{ fontSize: '1rem', color: '#0B4762' }}/>
                    </IconButton>
                :
                    null
            }
        </Box>
    )
};

export default SubshiftHoursComponent;