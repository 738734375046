import React from 'react'

const RedTrashIcon = (props) => {

    const {onClick} = props;

    return (
        <div onClick={onClick} style={{cursor: 'pointer'}}>
            <svg width="40" height="40" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="61" height="61" rx="10" fill="#FF0202" fillOpacity="0.15"/>
                <rect x="0.5" y="0.5" width="60" height="60" rx="9.5" stroke="#FF0000" strokeOpacity="0.15"/>
                <path d="M41.3598 17.3485C41.0195 16.9314 40.7379 16.4497 40.5248 15.9549C39.1749 12.8264 35.5007 13.0018 35.5007 13.0018H26.7695C23.912 13.0018 22.5113 14.5804 21.8256 16.0756C21.6096 16.5476 21.3172 17.0044 20.9659 17.4001C20.3862 18.0558 19.1673 18.181 18.5413 18.3752C17.4459 18.7147 16.8485 19.5197 16.226 20.9314C16.226 20.9314 15.9562 21.5409 15.6257 22.2924C15.2949 23.0433 16.2759 23.6527 17.8191 23.6527H44.3619C45.9055 23.6527 46.7789 23.0106 46.3122 22.218L45.4687 20.7841C44.6852 19.4538 44.1586 18.9753 43.3308 18.3757C43.0075 18.1418 41.9301 18.0447 41.3598 17.3485ZM29.4898 15.7633H35.4036C36.4224 15.7633 37.0793 16.0853 37.5017 16.5129C37.8517 16.8676 37.8517 17.2683 37.6314 17.6255C37.2768 18.1997 35.4394 18.3751 33.8952 18.3751H28.2158C26.6727 18.3751 24.9092 18.2017 24.6335 17.6796C24.468 17.3654 24.4833 16.9358 24.7219 16.5611C25.3894 15.5118 27.9875 15.7633 29.4898 15.7633Z" fill="#EB252E"/>
                <path d="M41.1441 25.5625H20.966C19.4229 25.5625 18.3292 26.803 18.5208 28.334L20.7725 46.2286C20.9642 47.7596 22.372 49 23.9148 49H38.3505C39.8933 49 41.2927 47.7583 41.4759 46.2258L43.608 28.336C43.7898 26.8036 42.6882 25.5625 41.1441 25.5625ZM26.957 45.093C26.957 45.8459 26.301 46.4579 25.5456 46.4579C24.7922 46.4579 24.1807 45.8458 24.1807 45.093V29.4734C24.1807 28.7179 24.7921 28.104 25.5456 28.104C26.3016 28.104 26.957 28.7179 26.957 29.4734V45.093ZM32.6055 45.093C32.6055 45.8459 31.9915 46.4579 31.2393 46.4579C30.4846 46.4579 29.8253 45.8458 29.8253 45.093V29.4734C29.8253 28.7179 30.4846 28.104 31.2393 28.104C31.9915 28.104 32.6055 28.7179 32.6055 29.4734V45.093ZM37.9451 45.093C37.9451 45.8459 37.3311 46.4579 36.5789 46.4579C35.8242 46.4579 35.1646 45.8458 35.1646 45.093V29.4734C35.1646 28.7179 35.8242 28.104 36.5789 28.104C37.3311 28.104 37.9451 28.7179 37.9451 29.4734V45.093Z" fill="#EB252E"/>
            </svg>
        </div>
    )
}

export default RedTrashIcon