import React, {useEffect, useState,} from 'react';
import moment from "moment";
import {connect} from 'react-redux';

// Actions
import {
    changeShiftEndDate,
    changeShiftMode,
    changeShiftStartDate,
    checkCriticChanges,
    checkSubShiftsInRange,
    checkSubShiftsOverlaping,
    closeBookingError,
    submitReservasChanges,
    submitReservasDeleted,
} from '../../../actions';

// Constants
import {ShiftMode} from '../../../constants';

// Components
import BackButtonPrimary from "../../NewComponents/Buttons/Back/BackButtonPrimary";
import RedTrashIcon from '../../SvgComponents/icons/TrashIcons/RedTrashIcon';
import ProgressIndicator from "../../GeneralComponents/ProgressIndicator";
import ListoButton from "../../NewComponents/Buttons/Listo/ListoButton";
import NextButton from "../../NewComponents/Buttons/Next/NextButton";
import BackButton from "../../NewComponents/Buttons/Back/BackButton";
import BookingGralConfig from "./Steps/General/BookingGralConfig";
import SubShiftConfig from "./Steps/SubShifts/SubShiftConfig";
import SectorsConfig from "./Steps/Sectors/SectorsConfig";
import ErrorDialog from "../../GeneralComponents/Modal/ErrorDialog";
import Details from "./Steps/Resumen/Details";

// Material Ui
import {Grid, Step, StepLabel, Stepper} from '@material-ui/core';
import {ModalMode} from "../../../constants/modalMode";
import BookingModal from "../Dialogs/BookingModal";

const getModalType = (mode, shift) => {
    switch (mode) {
        case ShiftMode.CREATE:
            return ModalMode.PUBLISH;
        case ShiftMode.EDIT:
            return ModalMode.CONTINUE;
        case ShiftMode.DELETE:
            if (moment(shift.startDate).isAfter(moment())) {
                if (shift.predecessor === undefined) {
                    return ModalMode.CANCEL_CREATION
                } else {
                    return ModalMode.CANCEL_EDITION
                }
            } else {
                return ModalMode.DELETE;
            }
        default:
            return ModalMode.PUBLISH;
    }
}

function ReservasForm(props) {
    // Variables
    const {
        shift,
        shifts,
        mode,
        timeZone,
        loading,
        reducerError,
    } = props;
    // actions
    const {
        changeEditShiftStartDate,
        submitReservasChanges,
        changeShiftMode,
        closeBookingError,
        submitReservasDeleted,
        changeEditShiftEndDate
    } = props;

    const [activeStep, setActiveStep] = useState(0);

    const [confirmDialogType, setConfirmDialogType] = useState(getModalType(mode, shift));
    const [showConfirmDialog, setShowConfirmDialog] = useState(mode === ShiftMode.DELETE);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setConfirmDialogType(getModalType(mode, shift));
        if (mode === ShiftMode.DELETE) {
            setShowConfirmDialog(true);
        }
    }, [mode])

    const getSteps = () => {
        return [
            'Generales',
            'Programación',
            'Sectores',
            'Resumen'
        ]
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <BookingGralConfig/>;
            case 1:
                return <SubShiftConfig/>;
            case 2:
                return <SectorsConfig/>;
            case 3:
                return <Details
                    shift={shift}
                />;
            default:
                return null;
        }
    }

    const getResultNext = () => {
        switch (activeStep) {
            case 0:
                if (shift?.name === undefined || shift?.name === "") {
                    setError("Por favor, ingrese un nombre para el turno");
                    return false;
                }
                return true;
            case 1:
                if (shift?.subShifts?.length <= 0) {
                    setError("Por favor, configure al menos un subturno para el turno");
                    return false;
                }
                if (!shift?.days?.some(day => day === true)) {
                    setError("Por favor, seleccione al menos un día de la semana para el turno");
                    return false;
                }
                let result = checkSubShiftsOverlaping(shift?.subShifts);
                if (result) {
                    let overlap = "";
                    result.forEach((subShiftNumber, index) => {
                        overlap += `Subturno ${subShiftNumber + 1} `;
                        if (index % 2 === 0) {
                            overlap += "se solapa con ";
                        } else if (index !== result.length - 1) {
                            overlap += "y\n";
                        }
                    });
                    setError("Los siguientes subturnos se solapan:\n" + overlap);
                    return false;
                }
                if (!checkSubShiftsInRange(shift?.opening, shift?.subShifts)) {
                    setError("Alguno de los subturnos se sale del rango de " + shift?.opening + "hs. a 00:00hs");
                    return false;
                }
                return true;
            case 2:
                if (shift?.sectors?.length <= 0) {
                    setError("Por favor, seleccione al menos un sector para el turno");
                    return false;
                }
                return true;
            case 3:
                if (mode === ShiftMode.EDIT) {
                    let criticChanges = checkCriticChanges({
                        shifts,
                        shift,
                        timeZone
                    });
                    if (criticChanges) {
                        setShowConfirmDialog(true);
                        return false;
                    }
                } else if (mode === ShiftMode.CREATE) {
                    setShowConfirmDialog(true);
                    return false;
                }
                return true;
            default:
                return false;
        }
    }

    const handleDelete = () => {
        changeShiftMode({mode: ShiftMode.DELETE, shift: shift});
    }

    const handleNext = () => {
        const next = getResultNext();
        if (next && (activeStep === (getSteps().length - 1))) {
            submitReservasChanges();
        } else if (next) {
            setActiveStep((prevActiveStep) => prevActiveStep === (getSteps().length - 1) ? prevActiveStep : prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleError = () => {
        closeBookingError();
        setError(undefined);
    }

    const onCancel = () => {
        setShowConfirmDialog(false);
        if (mode === ShiftMode.DELETE) {
            changeShiftMode({mode: ShiftMode.EDIT, shift: shift});
        }
    }

    const onFinish = (date) => {
        console.log("DATE: ", date);
        setShowConfirmDialog(false);
        if (mode === ShiftMode.DELETE) {
            changeEditShiftEndDate(date);
            submitReservasDeleted();
        }
        else {
            changeEditShiftStartDate(date);
            submitReservasChanges()
        }
    }

    return (
        <div>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <div style={{
                        display: mode !== ShiftMode.CREATE ? 'flex' : 'none',
                        justifyContent: 'right',
                        alignItems: 'center'
                    }}>
                        <RedTrashIcon
                            onClick={handleDelete}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Stepper
                                activeStep={activeStep}
                                orientation={'vertical'}
                                style={{
                                    backgroundColor: 'transparent',
                                    "& .MuiStepConnectorRoot.MuiActive.MuiStepConnectorLine": {
                                        borderColor: "#0B4762",
                                    },
                                    "& .MuiStepConnectorRoot.MuiCompleted.MuiStepConnectorLine": {
                                        borderColor: "#D0D0D0",
                                    },
                                }}
                            >
                                {getSteps().map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel
                                            className={'stepLabel'}
                                            style={{
                                                color: index === activeStep ? "#0B4762" : "#D0D0D0",
                                                height: '40px',
                                            }}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={10} style={{padding: '1rem'}}>
                            {getStepContent(activeStep)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container justify={'center'} spacing={8}>
                        {activeStep === 0 ? null : activeStep === getSteps().length - 1
                            ? <Grid item><BackButtonPrimary onClick={handleBack}/> </Grid>
                            : <Grid item>
                                <BackButton onClick={handleBack}/>
                            </Grid>
                        }
                        <Grid item>
                            {activeStep === getSteps().length - 1
                                ? <ListoButton onClick={handleNext}/>
                                : <NextButton onClick={handleNext}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorDialog
                open={error !== undefined || reducerError !== undefined}
                title={"Atención!"}
                body={error ?? reducerError ?? ""}
                subBody={""}
                onAcceptText={"OK!"}
                onAccept={handleError}
                type={'ErrorsBooking'}
            />
            <BookingModal
                show={showConfirmDialog}
                type={confirmDialogType}
                minDate={moment(shifts.find(s => s.uuid === shift.uuid)?.startDate).isAfter(moment()) ? moment(shifts.find(s => s.uuid === shift.uuid)?.startDate).toDate() : moment().toDate()}
                advanceStart={shifts.find(s => s.uuid === shift.uuid)?.advanceStart}
                onCancel={onCancel}
                onFinish={onFinish}
            />
            <ProgressIndicator open={loading}/>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        shifts: state.reservasReducer.shifts,
        shift: state.reservasReducer.shift,
        mode: state.reservasReducer.mode,
        reducerError: state.reservasReducer.error,

        loading: state.reservasReducer.loading,
        timeZone: state.reservasReducer.timeZone,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // TURNO
        changeEditShiftStartDate: (data) => dispatch(changeShiftStartDate(data)),
        changeEditShiftEndDate: (data) => dispatch(changeShiftEndDate(data)),

        submitReservasChanges: () => dispatch(submitReservasChanges()),
        submitReservasDeleted: () => dispatch(submitReservasDeleted()),
        changeShiftMode: ({mode, shift}) => dispatch(changeShiftMode({mode, shift})),

        checkCriticChanges: (data) => dispatch(checkCriticChanges(data)),
        closeBookingError: () => dispatch(closeBookingError()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservasForm);
