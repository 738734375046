import React from 'react'

const ArrowUpPrimary = (props) => {
    return (
        <div style={{cursor: props.cursor}}>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 20.06 10.621"
                 stroke={props.stroke}
                 transform={props.transform}>
                <path id="Trazado_324" data-name="Trazado 324" d="M7,0,0,6.717l7,6.106"
                      transform="translate(14.94 1.5) rotate(90)" fill="none" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="3"/>
            </svg>
        </div>
    )
}

ArrowUpPrimary.defaultProps = {
    stroke: "#0076ff",
    cursor: 'pointer',
    transform: "translate(5.5 0)"
}

export default ArrowUpPrimary
