import moment from "moment";
import { GetFormattedDate } from "../../Shifts/Wizard/utils/functions";

export const GetSectors = (availableLayouts, sectors) => {
    const newSectors = [];

    availableLayouts.map((availableLayout) => {        
        const sector = sectors.find(sector => sector.id === availableLayout.sectorId);
        const layout = sector.layouts.find(layout => layout.uuid === availableLayout.id);

        if (typeof sector !== 'undefined' && typeof layout !== 'undefined') {
            newSectors.push(`${sector.name} (${layout.name})`);
        }
        
    })

    return newSectors;
}

export const GetPublish = (startDate, endDate) => {
    if (endDate === null) {
        return `${GetFormattedDate(startDate)} | Finaliza: Nunca`;
    } else {
        return `${GetFormattedDate(startDate)} | Finaliza el ${GetFormattedDate(endDate)}`;
    }
    
}

export const IsProgrammedEdit = (startDate) => {
    const isAfter = moment(startDate).isAfter(new Date());
    return isAfter;
}

export const IsProgrammedDeleteDisable = (endDate, deleted, enable, successor) => {
    const programmedDelete = enable && !deleted && (typeof successor === 'undefined' || successor !== null);
    return (programmedDelete || !enable) && moment(endDate).isAfter(new Date());
}