import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import {changeVisibilityCategory, deleteCategory, editCategory} from "../../actions";
import {connect} from "react-redux";
import Sort from "../SvgComponents/sort";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    categoryContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 500,
    },
    textfield: {
        flexGrow: 2,
    },
    orderIcon: {
        marginRight: 10,
    },
}));

function CategoryTile(props) {
    const classes = useStyles();

    // Variables
    const {category, loading} = props;
    // Actions
    const {editCategory, deleteCategory, changeVisibilityCategory} = props;

    const [name, setName] = useState(category.name);
    const [nameError, setNameError] = useState(false);
    const [showDialog, setShowDialog] = useState(false);


    const submitDelete = () => {
        deleteCategory(category.id);
    };

    const openDialog = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    const onUpdateName = () => {
        if (category.name !== name) {
            editCategory(category.id, name)
        }
    }

    return (
        <div className={classes.categoryContainer}>
            {
                <div className={classes.orderIcon}>
                    <Sort/>
                </div>
            }

            <TextField
                disabled={loading}
                error={nameError}
                className={classes.textfield}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => onUpdateName()}
                margin="dense"
                id="txt-category-name"
                type="text"
            />
            <div>
                {category.visibility === undefined || category.visibility
                    ?
                    <IconButton
                        disabled={loading}
                        onClick={() => changeVisibilityCategory(category.id, false)}
                        aria-label={"visibility-category"}
                    >
                        <Visibility/>
                    </IconButton>
                    : <IconButton
                        disabled={loading}
                        onClick={() => changeVisibilityCategory(category.id, true)}
                        aria-label={"visibility-category-off"}
                    >
                        <VisibilityOff/>
                    </IconButton>}
                <IconButton
                    disabled={loading}
                    onClick={openDialog}
                    aria-label="delete"
                >
                    <DeleteIcon/>
                </IconButton>
            </div>

            <Dialog open={showDialog}>
                <DialogTitle id="alert-dialog-title">{"Eliminar sector"}</DialogTitle>
                <DialogContent>
                    <Typography>{`¿Seguro desea eliminar la sección \"${category.name}\"?`}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={closeDialog}>
                        Cancelar
                    </Button>
                    <Button disabled={loading} color="secondary" onClick={submitDelete}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.menuReducer.categoriesReducer.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editCategory: (id, name) => dispatch(editCategory(id, name)),
        changeVisibilityCategory: (id, visibility) => dispatch(changeVisibilityCategory(id, visibility)),
        deleteCategory: (id) => dispatch(deleteCategory(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTile);
