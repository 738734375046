export const PlaningContainerStyles = {
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: '#FCFCFC',
    paddingRight: '5rem',
    height: '90vh',
    '@media (min-width: 1700px)': {
        paddingRight: '15rem',
    },
};

export const DividerStyles = {
    backgroundColor: '#B9B9B9',
    marginTop: '2rem',
    border: 'none',
    height: '1px',
};

export const NoPlanningContainerStyles = { 
    justifyContent: 'center', 
    flexDirection: 'column', 
    alignItems: 'center', 
    margin : 'auto', 
    display: 'flex',  
    width: '25rem', 
    gap: '25px',
};

export const PlanningMainContainer = {
    fontFamily: 'Poppins, sans-serif',
    paddingRight: '5rem',
    minWidth: '50rem',
    height: '85vh',
    '@media (min-width: 1500px)': {
        maxWidth: '51rem',
        width: '100%',
    }
};

export const ShowAllPlanningsContainerStyles = {
    width: '100%',
    paddingBottom: '1rem'
}

export const OptionalTitleStyles = {
    marginBottom: '1.5rem',
    fontFamily: 'Poppins',
    fontSize: '1.1rem',
    color: '#041C32',
    fontWeight: 500,
    '@media(min-width: 1400px)': {
        fontSize: '1.4rem',
    }
};

export const OptionalSubtitleStyles = {
    margin: '1rem 0 .5rem 0',
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#041C32',
    fontWeight: 500,
    '@media(min-width: 1400px)': {
        fontSize: '1.1rem',
    }
};

export const PlanningSelectStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    fontWeight: 500,
    width: '15rem',
    m: '1rem 0',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } ,
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const AddNewButtonStyles = {
    backgroundColor: 'transparent',
    fontFamily: 'Poppins',
    textTransform: 'none',
    alignItems: 'center',
    width: 'max-content',
    cursor: 'pointer',
    display: 'flex',
    padding: 0,
    gap: '4px',
    '&:hover': {
        backgroundColor: 'transparent',
    }
};

export const PlanningHoursSelectStyles = {
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    fontWeight: 500,
    width: '8rem',
    m: '1rem 0',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } ,
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const DaysContainer = {
    alignItems: 'center',
    margin: '1rem 0',
    display: 'flex',
    height: '35px',
};

export const CheckedStyle = {
    '&.Mui-checked': {
        color: '#0B4762 !important',
    },
}

export const TypeDateSelectStyles = {  
    paddingTop: '0 !important', 
    position: 'relative',
}

export const TransitionGroupStyles = {
    backgroundColor: '#FCFCFC',
    paddingLeft: '.2rem',
    alignItems: 'center', 
    flexWrap: 'wrap', 
    display: 'flex',
    gap: '10px'
};

export const DatesContainerStyles = {
    height: '40px',
    width: 'max-content',
    float: 'left',
    display: 'flex', 
    flexWrap: 'wrap'
};

export const AddDatesContainerStyles = {
    paddingLeft: '1.7rem',
    paddingTop: '2rem', 
    flexWrap: 'wrap', 
};

export const DateButtonsContainerStyles = { 
    flexWrap: 'wrap', 
    display: 'flex', 
    width: '100%', 
    gap: '10px' 
};

export const DateButtonContainerStyles = {
    border: '1px solid #0B4762',
    justifyContent: 'center',
    padding: '.4rem .5rem',
    borderRadius: '.5rem',  
    position: 'relative', 
    alignItems: 'center',
    width: 'max-content',
    display: 'flex',
    gap: '10px'
};

export const DateButtonTextStyles = { 
    fontFamily: 'Poppins', 
    fontSize: '.9rem', 
    color: '#041C32', 
    fontWeight: 500, 
};

export const DateButtonCrossStyles = { 
    fontSize: '1.1rem', 
    cursor: 'pointer', 
    color: '#041C32', 
};