import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import WokiTop from '../../SvgComponents/icons/GeneralIcon/WokiTop';

const useStyles = makeStyles(() => ({
    root: {
        margin: '15px 10px',
        width: '100%'
    },
    description: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        color: '#0076FF',
        paddingTop: '17px'
    }
}));

const InfoGral = ({firstLine, secondLine}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={2} md={2}>
                    <div style={{paddingTop: '10px', paddingLeft: '10px'}}>
                        <WokiTop/>
                    </div>
                </Grid>
                <Grid item xs={10} md={10}>
                    <div className={classes.description}>
                        <p style={{margin: 0}}>{firstLine}</p>
                        <p style={{margin: '5px 0px 0px 0px'}}>{secondLine}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default InfoGral
