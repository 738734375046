import React from 'react';
import {Grid, makeStyles, Switch} from '@material-ui/core';
import MesaYaIconPrimary from '../../SvgComponents/icons/IconsPrimary/Only Icons/MesaYaIconPrimary';
import CheckIconPrimary from '../../SvgComponents/icons/IconsPrimary/Only Icons/CheckIconPrimary';
//import MesaYaIcon from '../../SvgComponents/icons/IconsPrimary/Only Icons/Disabled Icons/MesaYaIcon';

const useStyles = makeStyles(() => ({
    IconContainer: {
        width: '51px',
        height: '51px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        zIndex: '2',
        backgroundColor: 'white',
        position: 'absolute',
        paddingTop: '6px',
        paddingLeft: '6px'
    },
    SwitchContainer: {
        width: '100px',
        height: '31px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '18px',
        zIndex: '1',
        position: 'relative',
        backgroundColor: (props) => props.enable ? 'white' : '#F0F0F0'
    }
}));

const SwitchWithIcon = (props) => {

    const classes = useStyles(props);

    const {enable} = props;

    const {onEnableChange} = props;

    return (
        <div>
            <Grid container >
                <Grid item xs={5}>
                    <div className={classes.IconContainer}>
                        <MesaYaIconPrimary fill={!enable ? '#D0D0D0' : '#0076ff'}/>
                        <div style={{marginTop: '-30px', marginLeft: '11px', display: enable ? 'block' : 'none'}}>
                            <CheckIconPrimary/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={7} style={{paddingTop: '10px', marginLeft: '-25px'}}>
                    <div className={classes.SwitchContainer}>
                        <Grid container>
                            <Grid item xs={3}/>
                            <Grid item xs={9} style={{textAlign: 'right', marginTop: '-4px'}}>
                                <Switch color='primary' checked={enable} onChange={onEnableChange}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SwitchWithIcon
