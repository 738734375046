import { 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE, 
    BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR, 
    BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES, 
    BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2, 
    BOOKING_POLITICS_BEFORE_GOING_MESSAGE, 
    SAVING_BOOKING_POLITICS_DATA_SUCCESS,
    BOOKING_POLITICS_ARRIVAL_TOLERANCE, 
    LOAD_BOOKING_POLITICS_DATA_SUCCESS,
    SAVING_BOOKING_POLITICS_DATA_FAIL,
    LOAD_BOOKING_POLITICS_DATA_FAIL,
    BOOKING_POLITICS_BEFORE_GOING, 
    BOOKING_POLITICS_ADD_NEW_HOUR,
    BOOKING_POLITICS_CHANGE_HOURS,
    BOOKING_POLITICS_FIXED_SHIFT,
    BOOKING_POLITICS_REMOVE_HOUR,
    SAVING_BOOKING_POLITICS_DATA,
    BOOKING_POLITICS_CHANGE_DAYS,
    LOAD_BOOKING_POLITICS_DATA,
    BOOKING_SET_EDITING_HOUR, 
    BOOKING_SET_ENABLE_HOUR,
    BOOKING_POLITICS_SAVE_BACK_DATA,
} from "../../constants/politicsConstants";

const initState = {
    bookingPoliticsHours: [
        {
            id: 0,
            start: 600,
            end: 1320,
            days: [0,3,4,5,6],
            enable: true,
            editing: false,
        }
    ],
    fixedShifts: true,
    arrivalTolerance: true,
    arrivalToleranceMinutes: 15,
    penaltyToleranceMinutes: 10,
    beforeGoing: true,
    beforeGoingMessage: '',
    cominucationWithMR: true,
    defaultMessage1: true,
    defaultMessage1Message: '',
    defaultMessage2: true,
    defaultMessage2Message: '',
    loading: false,
    editing: false,
    error: ''
};

const bookingPoliticsReducer = (state = initState, action) => {
    switch ( action.type ) {
        case LOAD_BOOKING_POLITICS_DATA:
            return {...state, loading: true, editing: false, error: '' };
        case LOAD_BOOKING_POLITICS_DATA_SUCCESS: 
            return { ...state, loading: false, editing: false };
        case LOAD_BOOKING_POLITICS_DATA_FAIL:
            return { ...state, loading: false, error: action.payload };
        case SAVING_BOOKING_POLITICS_DATA:
            return { ...state, loading: true, error: '' };
        case SAVING_BOOKING_POLITICS_DATA_SUCCESS:
            return { ...state, loading: false, edited: false };
        case SAVING_BOOKING_POLITICS_DATA_FAIL:
            return { ...state, loading: false, error: action.payload };
        case BOOKING_SET_ENABLE_HOUR:
            let hourArrayCopy = [ ...state.bookingPoliticsHours ];
            const indexHourArrayCopy = hourArrayCopy.findIndex( hour => hour.id === action.payload.id );
            
            if ( indexHourArrayCopy === -1 ) {
                return state;
            }

            hourArrayCopy[indexHourArrayCopy].enable = action.payload.enableHour;

            return { 
                ...state,  
                editing: true, 
                bookingPoliticsHours: [ ...hourArrayCopy ] 
            }
        case BOOKING_SET_EDITING_HOUR:
            let hourArrayCopy1 = [ ...state.bookingPoliticsHours ];
            const indexHourArrayCopy1 = hourArrayCopy1.findIndex( hour => hour.id === action.payload.id );
            
            if ( indexHourArrayCopy1 === -1 ) {
                return state;
            }

            hourArrayCopy1[indexHourArrayCopy1].editing = action.payload.editingHour;

            return { 
                ...state, 
                editing: true, 
                bookingPoliticsHours: [ ...hourArrayCopy1 ] 
            }
        case BOOKING_POLITICS_ADD_NEW_HOUR:
            return { 
                ...state,  
                editing: true, 
                bookingPoliticsHours: [
                    ...state.bookingPoliticsHours,
                    action.payload.newHours
                ] 
            }
        case BOOKING_POLITICS_REMOVE_HOUR:
            let hourArrayCopy2 = [];
            //push and refresh id, index and name
            state.bookingPoliticsHours.forEach(( item ) => {
                if ( item.id !== action.payload.id ) {
                    item.id = hourArrayCopy2.length;
                    item.name = `Horario ${ hourArrayCopy2.length + 1 }`;
                    hourArrayCopy2.push( item );
                }
            });

            return { 
                ...state,  
                editing: true, 
                bookingPoliticsHours: [ ...hourArrayCopy2 ] 
            }
        case BOOKING_POLITICS_CHANGE_HOURS: 
            return {
                ...state,
                bookingPoliticsHours: action.payload.newArray
            }
        case BOOKING_POLITICS_CHANGE_DAYS:
            let hourArrayCopy3 = [ ...state.bookingPoliticsHours ];
            const hourArrayCopy3Index = hourArrayCopy3.findIndex( item => item.id === action.payload.id );

            if ( hourArrayCopy3Index === -1 ) {
                return state;
            }

            hourArrayCopy3[ hourArrayCopy3Index ].days = action.payload.newDaysArray;

            return {
                ...state,
                bookingPoliticsHours: [ ...hourArrayCopy3 ]
            }
        case BOOKING_POLITICS_FIXED_SHIFT:
            return { ...state, editing: true, fixedShifts: action.payload.fixedShifts }
        case BOOKING_POLITICS_ARRIVAL_TOLERANCE:
            return { ...state, editing: true, arrivalTolerance: action.payload.arrivalTolerance }
        case BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES:
            return { ...state, editing: true, arrivalToleranceMinutes: action.payload.arrivalToleranceMinutes }
        case BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES:
            return { ...state, editing: true, penaltyToleranceMinutes: action.payload.penaltyToleranceMinutes }
        case BOOKING_POLITICS_BEFORE_GOING:
            return { ...state, editing: true, beforeGoing: action.payload.beforeGoing }
        case BOOKING_POLITICS_BEFORE_GOING_MESSAGE:
            return { ...state, editing: true, beforeGoingMessage: action.payload.beforeGoingMessage }
        case BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR:
            return { ...state, editing: true, cominucationWithMR: action.payload.cominucationWithMR }
        case BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1:
            return { ...state, editing: true, defaultMessage1: action.payload.defaultMessage1 }
        case BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE:
            return { ...state, editing: true, defaultMessage1Message: action.payload.defaultMessage1Message }
        case BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2:
            return { ...state, editing: true, defaultMessage2: action.payload.defaultMessage2 }
        case BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE:
            return { ...state, editing: true, defaultMessage2Message: action.payload.defaultMessage2Message }
        case BOOKING_POLITICS_SAVE_BACK_DATA:
            let backData = action.payload.backData;

            console.log( backData );

            
            let backArrayHours = [];
            
            if ( backData.attentionIntervals ) {
                for (let i = 0; i < backData.attentionIntervals.length; i++) {
                    backArrayHours.push({
                        end: backData.attentionIntervals[i].open + backData.attentionIntervals[i].duration,
                        enable: backData.attentionIntervals[i].enable,
                        start: backData.attentionIntervals[i].open,
                        days: backData.attentionIntervals[i].days,
                        editing: false,
                        id: i,
                    });
                }
                
                state.bookingPoliticsHours = backArrayHours;
            } else {
                state.bookingPoliticsHours = [];
            }

            if ( backData.shiftsTolerance === null ) {
                state.arrivalToleranceMinutes = 0;
                state.arrivalTolerance = false;
            } else {
                state.arrivalToleranceMinutes = backData.shiftsTolerance;
                state.arrivalTolerance = true;
            };

            state.fixedShifts = backData.fixedShifts;
            
            if ( backData.messageToNotBookableGroup === null ) {
                state.defaultMessage1Message = '';
                state.defaultMessage1 = false;
            } else {
                state.defaultMessage1Message = backData.messageToNotBookableGroup;
                state.defaultMessage1 = true;
            };

            if ( backData.messageToNotBookableTimes === null ) {
                state.defaultMessage2Message = '';
                state.defaultMessage2 = false;
            } else {
                state.defaultMessage2Message = backData.messageToNotBookableGroup;
                state.defaultMessage2 = true;
            };

            if ( backData.changeTolerance === null ) {
                state.penaltyToleranceMinutes = 0;
            } else {
                state.penaltyToleranceMinutes = backData.changeTolerance;
            }
            state.cominucationWithMR = backData.notifyManualBookings;
            state.beforeGoingMessage = backData.toKnowBeforeBooking;

            return { ...state, editing: false }
        default:
            return state;
    }
};

export default bookingPoliticsReducer;