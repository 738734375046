import React from 'react';

// Components
import searchImage from '../SvgComponents/images/imgWindow.png';
import DragFile from "../GeneralComponents/dragFile";
import "./Photo.css"

// Material Ui
import {makeStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const useStyle = makeStyles(() => ({
    root: {},
    title: {
        fontSize: '1.2rem',
        fontWeight: '500',
        color: '#041C32',
        margin: '0px',
    },
    text: {
        fontWeight: '500',
        fontSize: '.8rem',
        color: '#041C32',
    },
    closeContainer: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        backgroundColor: '#0B4762',
    }
}));

const Photos = (props) => {

    const classes = useStyle();

    const {img} = props;
    const {onHandleDrop, onRemove} = props;

    return (
        <div className={"facade"}>
            <div className={"image-container"}>
                <DragFile handleDrop={onHandleDrop}>
                    <img src={img ? img : searchImage} alt='facade img'/>
                </DragFile>
            </div>
            <div className={"image-text"}>{img ? null :
                "Arrastre la imagen aquí"}</div>
            <div className={classes.closeContainer} onClick={onRemove}>
                <CloseIcon style={{color: 'white'}} fontSize={'small'}/>
            </div>
        </div>
    )
}

export default Photos
