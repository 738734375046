import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import {createCategory, sortCategories, toggleCategoriesDialog,} from "../../../actions";
import {connect} from "react-redux";
import CategoryTile from "../categoryTile";

import DragSortableList from "react-drag-sortable";

const useStyles = makeStyles(() => ({
    categoryContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 500,
    },
    createContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textfield: {
        flexGrow: 2,
    },
}));

function CategoriesDialog(props) {
    const classes = useStyles();
    const [createMode, setCreateMode] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);

    // Variables
    const {categoriesDialog, loading} = props;
    let {categories} = props;

    //Actions
    const {toggleCategoriesDialog, createCategory, sortCategories} = props;

    const handleKeyDown = (event, open) => {
        if (event.key === "Enter" && !open) {
            submitNewCategory();
        }
    };

    const submitNewCategory = () => {
        if (name.length <= 0) {
            setNameError(true);
        } else {
            setNameError(false);
            setCreateMode(false);
            createCategory(
                name,
                categories && categories.length > 0
                    ? Math.max.apply(
                    Math,
                    categories.map(function (category) {
                        return category.order;
                    })
                ) + 1
                    : 0
            );
            setName("");
        }
    };

    const onSort = (sortedList) => {
        sortCategories(sortedList);
    };

    let placeholder = <div className="placeholderContent">COLOCAR AQUÍ</div>;

    return (
        <div>
            <Dialog
                open={categoriesDialog}
                onClose={toggleCategoriesDialog}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{"Secciones"}</DialogTitle>
                <DialogContent>
                    <DragSortableList
                        items={
                            categories
                                ? categories.map((category) => ({
                                    content: (
                                        <CategoryTile key={category.name} category={category}/>
                                    ),
                                    id: category.id,
                                    rank: category.order,
                                }))
                                : null
                        }
                        onSort={onSort}
                        type="vertical"
                        dropBackTransitionDuration={0.3}
                        placeholder={placeholder}
                    />
                    <div className={classes.createContainer}>
                        {createMode ? (
                            <TextField
                                onKeyDown={handleKeyDown}
                                disabled={loading}
                                error={nameError}
                                className={classes.textfield}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="dense"
                                id="txt-new-category-name"
                                label="Sección"
                                type="text"
                            />
                        ) : (
                            <div/>
                        )}
                        {createMode ? (
                            <div className={classes.buttonContainer}>
                                <IconButton
                                    onClick={submitNewCategory}
                                    disabled={loading}
                                    aria-label="edit"
                                >
                                    <DoneIcon/>
                                </IconButton>
                                <IconButton
                                    disabled={loading}
                                    onClick={() => setCreateMode(!createMode)}
                                    aria-label="edit"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        ) : (
                            <IconButton
                                disabled={loading}
                                onClick={() => setCreateMode(!createMode)}
                                aria-label="create"
                            >
                                <AddIcon/>
                            </IconButton>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCategoriesDialog}>Cerrar</Button>
                </DialogActions>
                {loading ? <LinearProgress/> : null}
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        categoriesDialog: state.menuReducer.categoriesReducer.categoriesDialog,
        loading: state.menuReducer.categoriesReducer.loading,
        categories: state.menuReducer.categoriesReducer.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCategoriesDialog: () => dispatch(toggleCategoriesDialog()),
        createCategory: (data, order) => dispatch(createCategory(data, order)),
        sortCategories: (data) => dispatch(sortCategories(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDialog);
