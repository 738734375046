import React from 'react';

// Components
import ShieldDataIcon from '../../SvgComponents/icons/Home/ShieldDataIcon';
import ChainDataIcon from '../../SvgComponents/icons/Home/ChainDataIcon';
import WokiDataIcon from '../../SvgComponents/icons/Home/WokiDataIcon';
import LightTooltip from "../../GeneralComponents/LightTooltip";

// Icon
import {InfoOutlined} from "@mui/icons-material";

// Material Ui
import LinearProgress from '@mui/material/LinearProgress';
import {makeStyles} from "@material-ui/core";
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ManualDataIcon from "../../SvgComponents/icons/Home/ManualDataIcon";

function LinearProgressWithLabel(props) {

    const classes = useStyles();
    const {colorProgress, icon, label} = props;
    const data = {...props};

    delete data.colorProgress;

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{marginRight: '1rem', marginBottom: '-.3rem'}}>
                {icon === 'shield' ?
                    <ShieldDataIcon/>
                    : icon === 'woki' ?
                        <WokiDataIcon/>
                        :
                        icon === 'chain'
                            ? <ChainDataIcon/>
                            : <ManualDataIcon/>
                }
            </Box>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress
                    {...data}
                    variant="determinate"
                    sx={{
                        backgroundColor: '#F4F5F6',
                        borderRadius: '10px',
                        height: '.7rem',
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: `${colorProgress}`,
                        },
                    }}
                />
            </Box>
            <Box sx={{minWidth: 35}}>
                <h2 className={classes.LabelProgress}>
                    {label}
                </h2>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
    LabelProgress: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        fontSize: '1rem',
        color: '#808080',
    },
    TextProgress: {
        fontFamily: 'Poppins, sans-serif',
        margin: '-1rem 0 0 3rem',
        textAlign: 'left',
        fontWeight: '500',
        color: '#808080',
    },
    ClientsTextsContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    CustomTooltip: {
        fontFamily: 'Poppins, sans-serif',
        marginTop: '-1rem',
        fontSize: '1rem'
    }
}));

const LinearProgressSection = (props) => {
    const classes = useStyles();

    const {
        woki,
        manual,
        link,
        myClients,
        total,
    } = props;

    return (
        <section>
            <div>
                <LinearProgressWithLabel value={total === 0 ? 0 : (100 * myClients) / total} label={myClients} colorProgress={'#EEB115'}
                                         icon={'shield'}/>
                <div className={classes.ClientsTextsContainer}>
                    <p className={classes.TextProgress}>Tus clientes</p>
                    <LightTooltip
                        placement='right'
                        title='Clientes propios del establecimiento que no abonan el servicio de reservas.'
                    >
                        <InfoOutlined
                            style={{color: '#808080', fontSize: '16px', margin: '-1rem 0 0 .3rem', cursor: 'pointer'}}/>
                    </LightTooltip>
                </div>
            </div>
            <div>
                <LinearProgressWithLabel value={total === 0 ? 0 : (100 * woki) / total} label={woki} colorProgress={'#1AAFDE'}
                                         icon={'woki'}/>
                <p className={classes.TextProgress}>A través de Woki</p>
            </div>
            <div>
                <LinearProgressWithLabel value={total === 0 ? 0 : (100 * link) / total} label={link} colorProgress={'#F97F59'}
                                         icon={'chain'}/>
                <p className={classes.TextProgress}>A través de link</p>
            </div>
            <div>
                <LinearProgressWithLabel value={total === 0 ? 0 : (100 * manual) / total} label={manual} colorProgress={'#B2B2B2'}
                                         icon={'manual'}/>
                <p className={classes.TextProgress}>Manuales</p>
            </div>
        </section>
    )
}

export default LinearProgressSection;
