import React, { useEffect, useState } from 'react';

import { firestoreConnect } from 'react-redux-firebase';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import html2canvas from 'html2canvas';
import { compose } from 'redux';

// Actions
import { changePrimaryTitleAppBar, makeActive, toggleDrawer } from '../../actions/sidebarActions';
import { changePlanningMode, changeSectorMode, changeShiftMode, closeErrorDialog, saveSector, setPartnerSelectedId } from '../../actions';
import { changeShiftsViewMode, setShowExitWithoutSaving } from "../../actions/shiftsActions";
import { changeOptionalMode, setRestartOptionalForm } from '../../actions/optionalActions';
import { goBackToViewMode, saveOrUpdateLayout } from "../../actions/V3";

// Components
import ProgressIndicator from '../GeneralComponents/ProgressIndicator';
import ErrorDialog from '../GeneralComponents/Modal/ErrorDialog';
import DrawerComponent from './components/DrawerComponent';
import SaveChangesModal from '../Modals/SaveChangesModal';
import { useStyles } from './components/StyledComponents';
import Visibility from '../Banners/Visibility';
import AppBar from './components/AppBar';

// Constants
import { SHIFTS_VIEW_MODE } from "../../constants/shiftsConstants";
import { PARTNERS, PLANNING_MODE, SECTOR_MODE, ShiftMode } from '../../constants';
import { OPTIONAL_MODE } from '../../constants/optionalConstants';
import { ModalMode } from '../../constants/modalMode';

// Helpers
import { addIconAppBar, drawerItems } from './helpers';

// Material Ui
import { Box, CssBaseline } from '@mui/material';

// Utils
import { QuitEditTurno } from "../V3/Shifts/Wizard/utils/functions";

const Sidebar = ( props ) => {

    // Actions
    const { 
        setShowExitWithoutSaving,
        changePrimaryTitleAppBar,
        setRestartOptionalForm,
        goBackToSectorViewMode,
        changeShiftsViewMode,
        setPartnerSelectedId,
        saveOrUpdateLayout,
        changePlanningMode,
        changeOptionalMode,
        changeSectorMode,
        closeErrorDialog,
        changeShiftMode,
        toggleDrawer,
        saveSector, 
        makeActive,
        push,
    } = props;

    // External props
    const { children } = props;

    // States
    const {
        planningViewMode,
        shiftsViewMode,
        primaryTitle,
        layoutEdited,
        bannerHeight,
        sectorEdited,
        showSidebar,
        drawerOpen,
        sectorMode,
        shiftMode,
        isActive,
        v3Shifts,
        viewMode,
        loading,
        profile,
        layout,
        error,
        title,
        mode,
    } = props;

    let actualUrl = window.location.pathname;

    const classes = useStyles();

    const [sectorWithChangesUnSaved, setSectorWithChangesUnSaved] = useState(false);
    const [nextPath, setNextPath] = useState('');
    const [iconImg, setIconImg] = useState('');

    useEffect(() => {
        // console.log( 'PROFILE --> ', profile.partner );
        if ( localStorage.getItem( 'partnerSelected' ) !== 'undefined' && localStorage.getItem( 'partnerSelected' ) !== 'null' ) {
            setPartnerSelectedId({
                id: localStorage.getItem( 'partnerSelected' )
            });
        } else {
            if ( profile.partners && profile.partners.length > 0 ) {
                localStorage.setItem( 
                    'partnerSelected', 
                    profile.partners[0].uuid 
                );

                setPartnerSelectedId({
                    id: profile.partners[0].uuid
                });
            } else {
                localStorage.setItem( 
                    'partnerSelected', 
                    profile.partner 
                );

                setPartnerSelectedId({
                    id: profile.partner
                });
            };
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ profile ]);
    
    const handleCloseDrawer = () => {
        toggleDrawer( false );
    };

    const handleOpenDrawer = () => {
        toggleDrawer( true );
    };

    const onClickDrawer = () => {
        if ( someDialogOpen() && !drawerOpen ) {
            if ( QuitEditTurno( v3Shifts ) ) {
                setShowExitWithoutSaving(true);
            } else {
                closeAllDialogs();
            }
        } else {
            toggleDrawer( !drawerOpen );
        }
    };

    const closeSectorMode = () => {
        if ( sectorEdited && sectorMode !== SECTOR_MODE.VIEW ) {
            setSectorWithChangesUnSaved(true)
            console.log( "Actual url: ", actualUrl );
        } else {
            changeSectorMode();
        }
    };

    const closeAllDialogs = () => {
        changePrimaryTitleAppBar("");
        setRestartOptionalForm();
        changeShiftsViewMode();
        changePlanningMode();
        changeOptionalMode();
        closeSectorMode();
        changeShiftMode();
        closeLayoutMode();
    };

    const closeLayoutMode = () => {
        if (
            layoutEdited &&
            layout.name &&
            layout.tables.length > 0 &&
            mode !== SECTOR_MODE.VIEW
        ) {
            setSectorWithChangesUnSaved(true);
        } else {
            goBackToSectorViewMode();
        }
    };

    useEffect(() => {
        if ( sectorWithChangesUnSaved ) {
            push( actualUrl )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sectorWithChangesUnSaved ]);

    useEffect(() => {
        if (!sectorWithChangesUnSaved && nextPath !== '') {
            push( nextPath );
            setNextPath('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorWithChangesUnSaved, nextPath])

    const someDialogOpen = () => {
        return( 
            shiftsViewMode !== SHIFTS_VIEW_MODE.VIEW ||
            sectorMode !== SECTOR_MODE.VIEW || 
            shiftMode !== ShiftMode.VIEW || 
            viewMode !== OPTIONAL_MODE.VIEW ||
            mode !== SECTOR_MODE.VIEW ||
            planningViewMode !== PLANNING_MODE.VIEW
        )
    }

    const onClickItem = (path) => {
        addIconAppBar( path, setIconImg, changePrimaryTitleAppBar );
        toggleDrawer( false );
        setNextPath( path );
        if ( someDialogOpen() ) {
            closeAllDialogs()
        }
    };

    useEffect(() => {
        // console.log( '--> ', actualUrl );
        addIconAppBar( actualUrl, setIconImg, changePrimaryTitleAppBar );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualUrl]);

    const saveSectorChanges = () => {
        setSectorWithChangesUnSaved(false);
        html2canvas( document.querySelector('#sector-grid-layout')).then(canvas => {
            canvas.toBlob(async blob => {
                await saveSector(blob);
                return true;
            });
        });
    };

    const saveLayoutChanges = () => {
        setSectorWithChangesUnSaved(false);
        html2canvas(document.querySelector("#sector-grid-layout")).then(
            (canvas) => {
                canvas.toBlob(async (blob) => {
                    await saveOrUpdateLayout(blob);
                    return true;
                });
            }
        );
    };

    const discardChanges = () => {
        setSectorWithChangesUnSaved(false);
        // changeSectorMode();
        goBackToSectorViewMode();
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {
                !showSidebar ? 
                    <Box className={ classes.root }>{ children }</Box> 
                :
                    <Box sx={{ width: '100%' }}>
                        <CssBaseline />
                        { !isActive && <Visibility height={ bannerHeight } onClick={ makeActive }/> }
                        <AppBar
                            drawerOpen={ someDialogOpen() } 
                            primaryTitle={ primaryTitle }
                            onClick={ onClickDrawer } 
                            open={ drawerOpen }
                            iconImg={ iconImg }
                            title={ title }
                            isActive={ isActive }
                        />
                        <DrawerComponent 
                            handleOpenDrawer={ handleOpenDrawer }
                            handleClose={ handleCloseDrawer }
                            drawerItems={ drawerItems } 
                            openSidebar={ drawerOpen }
                            onClick={ onClickItem } 
                            isActive={ isActive }
                            profile={ profile }
                        />
                        <Box 
                            sx={{
                                margin: isActive ? '6rem 0rem 0rem 6rem' : '6rem 0rem 0rem 6rem',
                                width: 'calc( 100% - 8rem )',
                                backgroundColor: '#F5F5F5',
                            }}
                        >
                            { children }
                        </Box>
                    </Box>
            }
            <SaveChangesModal
                submit={ layout.id ? saveLayoutChanges : saveSectorChanges }
                onClose={() => {
                    setSectorWithChangesUnSaved( false );
                    setNextPath( '' );
                }}
                when={ sectorWithChangesUnSaved }
                handleClose={ discardChanges }
            />
            <ProgressIndicator open={ loading } />
            <ErrorDialog 
                onAccept={ closeErrorDialog }
                type={ ModalMode.ERROR }
                title={ '¡Atención!' } 
                open={ error !== '' } 
                body={ error } 
            />
        </Box>
    )
};


const mapStateToProps = state => {
    return {
        bannerHeight: state.sidebarManager.bannerHeight,
        showSidebar: state.sidebarManager.showSidebar,
        drawerOpen: state.sidebarManager.drawerOpen,
        isActive: state.sidebarManager.isActive,

        primaryTitle: state.sidebarManager.primaryTitle,
        title: state.sidebarManager.title,

        loading: state.sidebarManager.loading,
        error: state.sidebarManager.error,

        shiftMode: state.reservasReducer.mode,

        shiftsViewMode: state.v3.shifts.viewMode,

        sectorEdited: state.sectorsReducer.sectors.edited,
        sectorMode: state.sectorsReducer.sectors.mode,

        profile: state.firebase.profile,
        partner: state.firestore.ordered[PARTNERS],

        v3Shifts: state.v3.shifts,

        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,

        //V3
        layoutEdited: state.v3.sectorsLayoutsTables.edited,
        layout: state.v3.sectorsLayoutsTables.layout,
        mode: state.v3.sectorsLayoutsTables.mode,

        planningViewMode: state.planningReducer.viewMode,
        viewMode: state.optionalReducer.viewMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePrimaryTitleAppBar: ( data ) => dispatch( changePrimaryTitleAppBar(data) ),
        toggleDrawer: ( from ) => dispatch( toggleDrawer(from) ),
        closeErrorDialog: () => dispatch( closeErrorDialog() ),
        makeActive: () => dispatch( makeActive() ),
        push: ( path ) => dispatch( push(path) ),

        changeOptionalMode: () => dispatch( changeOptionalMode({ mode: OPTIONAL_MODE.VIEW, optional: undefined }) ),
        changePlanningMode: () => dispatch( changePlanningMode({ mode: PLANNING_MODE.VIEW, planning: undefined }) ),
        changeSectorMode: () => dispatch( changeSectorMode({ mode: SECTOR_MODE.VIEW, sector: undefined }) ),
        changeShiftMode: () => dispatch( changeShiftMode({ mode: ShiftMode.VIEW, shift: undefined }) ),
        changeShiftsViewMode: () => dispatch( changeShiftsViewMode( SHIFTS_VIEW_MODE.VIEW ) ),
        setRestartOptionalForm: () => dispatch( setRestartOptionalForm() ),

        saveOrUpdateLayout: ( image ) => dispatch( saveOrUpdateLayout( image ) ),
        goBackToSectorViewMode: () => dispatch( goBackToViewMode() ),
        saveSector: ( data ) => dispatch(saveSector( data )),

        setShowExitWithoutSaving: ( data ) => dispatch( setShowExitWithoutSaving( data ) ),

        setPartnerSelectedId: ({ id }) => dispatch( setPartnerSelectedId({ id }) ),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        if (props.profile && props.idPartnerSelected) {
            return [
                {
                    doc: props.idPartnerSelected,
                    collection: PARTNERS,
                },
            ]
        }
        return []
    }))(Sidebar);
