import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        width: (props) => props.rounded ? '28px' : '110px',
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: '500',
        height: '2.3rem',
        fontSize: '15px',
        display: 'flex',
    }
}));

const DaysButton = (props) => {

    const classes = useStyle(props);

    const {enable, day} = props

    const {onClick} = props

    const [color, setColor] = useState(enable ? "#0B4762" : "#FFFFFF");

    useEffect(() => {
        if(enable) {
            setColor("#0B4762")
        } else {
            setColor("#FFFFFF")
        }
    }, [enable])

    return (
        <div onClick={onClick} className={classes.root} style={{backgroundColor: color, color: enable ? '#FFFFFF' : '#0B4762'}}>
            {day}
        </div>
    )
}

export default DaysButton;
