import React from 'react'
import '../Styles/Row.css'

const Header = (props) => {

    return(
        <tr key="header-table-menu" className='textHeaderTable'>
            <th className='nameBox'>
                <div className='sortBox'/>
                <div style={{paddingLeft: '15%'}}>
                    Nombre
                </div>
            </th>
            <th className='linkBox'>
                Link
            </th>
            <th className='actionsRow' style={{marginRight: '30px'}}>
                Acciones
            </th>
        </tr>
    )
}

export default Header