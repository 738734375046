import {styled} from "@material-ui/core";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

const LightTooltip = styled(({className, children, ...props}) => (
    <Tooltip {...props} classes={{popper: className}} children={children}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#FFFFFF',
        boxShadow: theme.shadows[3],
        fontSize: '.7rem',
        fontFamily: 'Poppins, sans-serif',
        position: 'relative',
        left: '-8px',
    },
}));

export default LightTooltip;
