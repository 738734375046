import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

export const DialogModal = ({
  open,
  handleClose,
  handleAccept,
  title = "",
  description = "",
  fetchButton = false,
  acceptText = "",
  cancelText = "",
  mainButtonColor = "#0B4762",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff !important",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          textAlign: "center",
        },
      }}
      maxWidth="xs"
    >
      <CloseIcon
        sx={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          cursor: "pointer",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1.5em",
        }}
      >
        <ErrorOutlineIcon
          sx={{ color: "#FF0000", fontSize: "4rem", fontWeight: 700 }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "1.4rem" }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          variant="body1"
          fontFamily="Poppins"
          sx={{ color: "#818181", fontSize: "0.8rem", marginBottom: "2em" }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "1em",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "#0B4762",
            fontFamily: "Poppins",
            maxWidth: "12em",
            textTransform: 'none',
            borderColor: "#D1D1D1",
            '&:hover': {
                backgroundColor: "#F5F5F5",
                color: '#fff'
            }
          }}
          onClick={handleClose}
          fullWidth
        >
          {cancelText}
        </Button>
        {fetchButton ? (
          <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: mainButtonColor,
              color: "#fff",
              maxWidth: "12em",
                fontFamily: "Poppins",
                textTransform: 'none',
              '&:hover': {
                backgroundColor: mainButtonColor,
              },
            }}
            onClick={handleAccept}
            fullWidth
            loading={false}
          >
            {acceptText}
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            sx={{
              backgroundColor: mainButtonColor,
              color: "#fff",
              maxWidth: "12em",
                fontFamily: "Poppins",
                textTransform: 'none',
                '&:hover': {
                backgroundColor: mainButtonColor + ' !important',
                },
            }}
            onClick={handleAccept}
            fullWidth
          >
            {acceptText}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};
