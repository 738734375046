import React, { useEffect } from "react";
import { connect } from "react-redux";

// Constants
import { PARTNERS, SECTOR_MODE } from "../../../constants";

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { changeSectorMode, loadSectors } from "../../../actions/V3";
import {
  changePrimaryTitleAppBar,
  changeShowSidebar,
  changeTitleAppBar,
} from "../../../actions/sidebarActions";
import SectorList from "./Views/SectorList";
import { ThemeProvider, createTheme } from "@mui/material";
import LayoutManager from "./Views/LayoutManager";
// import LayoutManager from "./Views/LayoutManager";

const useStyles = makeStyles(() => ({
  mainContainer: {
    fontFamily: "Poppins, sans-serif",
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#0B4762",
      dark: "#041C32",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

function SectorsForm(props) {
  const classes = useStyles();

  // Variables
  const {  partner, mode } = props;

  // Actions
  const {
    loadSectors,
    changeTitleAppBar,
    changePrimaryTitleAppBar,
    changeShowSidebar,
  } = props;

  useEffect(() => {
    if(mode === SECTOR_MODE.VIEW){
    changeTitleAppBar("Sectores y layouts");
    changeShowSidebar(true);
    changePrimaryTitleAppBar(
      "Creá los sectores de tu establecimiento con sus respectivos layouts"
    );
    }
  }, [props, changeTitleAppBar, changePrimaryTitleAppBar, changeShowSidebar, mode]);

  useEffect(() => {
    if (partner !== undefined) {
      loadSectors(partner);
    }
  }, [partner, loadSectors]);

  return (
    <ThemeProvider theme={theme}>
      <section className={classes.mainContainer}>
        {mode === SECTOR_MODE.VIEW ? <SectorList /> : <LayoutManager/>}
      </section>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    partner: state.firestore.ordered[PARTNERS],
    loading: state.v3.sectorsLayoutsTables.loading,
    sectors: state.v3.sectorsLayoutsTables.sectors,
    mode: state.v3.sectorsLayoutsTables.mode,
    edited: state.v3.sectorsLayoutsTables.edited,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSectors: () => dispatch(loadSectors()),
    changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
    changePrimaryTitleAppBar: (data) =>
      dispatch(changePrimaryTitleAppBar(data)),
    changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    changeSectorMode: ({ mode, sector }) =>
      dispatch(changeSectorMode({ mode, sector })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorsForm);
