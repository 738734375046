import moment from "moment";
import {DYNAMIC_LINK, STREET, WOKI} from "../constants/providence";

/**
 * Calcula la diferencia en minutos entre la hora de llegada del cliente y la hora pactada de la reserva
 * @param bookingItem Item de Reserva al cual se quiere calcular
 * @return {number} Devuelve la diferencia en minutos entre que el cliente llego y la hora que tenia la reserva, en valor absoluto
 */
const getArriveDelayTime = (item) => {
    const from = moment(item.showDay ? item.showDay.seconds * 1000 : item.day.seconds * 1000);
    const to = moment(item.arrivedTimestamp.seconds * 1000);

    return Math.abs(to.diff(from, 'minutes'));
}

/**
 * Calcula el promedio de demora del cliente para una reserva
 * @param actualItems Items de reserva correspondientes al período seleccionado por el usuario
 * @param prevItems Items de reserva correspondientes al período seleccionado por el usuario
 * @return {{average: (number), percentage: (number), time: *[]}} Average: promedio que tarda en llegar un cliente a la reserva
 * Percentage: porcentaje de diferencia con el período anterior
 * Time: datos de tiempo actual, para graficar
 */
export const calculateArriveDelayTime = (actualItems, prevItems) => {
    let actualItemsTime = [];
    let prevItemsTime = [];

    actualItems.forEach((bookingItem) => {
        if (bookingItem.arrivedTimestamp) {
            const time = getArriveDelayTime(bookingItem);
            if (time) {
                actualItemsTime.push(time);
            }
        }
    })

    prevItems.forEach((bookingItem) => {
        if (bookingItem.arrivedTimestamp) {
            const time = getArriveDelayTime(bookingItem);
            if (time) {
                prevItemsTime.push(time);
            }
        }
    })

    const totalActualTime = actualItemsTime.reduce((a, b) => a + b, 0);
    const totalPrevTime = prevItemsTime.reduce((a, b) => a + b, 0);

    return {
        average: actualItemsTime.length > 0 ? totalActualTime / actualItemsTime.length : 0,
        percentage: totalPrevTime && totalPrevTime > 0 ? totalActualTime / totalPrevTime : 1,
        time: actualItemsTime,
    }
}

// Calculos Tiempo de respuesta

/**
 * Analiza de que timestamp tomar la fecha final
 * @param item Item de MY o Reserva
 * @return {moment.Moment} Retorna la fecha en la que termino ese item (Aceptado, eliminado o cancelado)
 */
const getTo = (item) => {
    if (item.acceptedTimestamp) {
        return moment(item.acceptedTimestamp.seconds * 1000);
    } else if (item.deletedByUserTimestamp) {
        return moment(item.deletedByUserTimestamp.seconds * 1000);
    } else if (item.deletedByPartnerTimestamp) {
        return moment(item.deletedByPartnerTimestamp.seconds * 1000);
    }
}

/**
 * Calcula el tiempo de respuesta de un bookingItem entre que fue creado y luego aceptado, eliminado o cancelado
 * @param item
 * @return {undefined|number} Tiempo en minutos que se tardo en responder
 */
const getResponseTimeBooking = (item) => {

    const to = getTo(item);
    const from = moment(item.createdTimestamp.seconds * 1000);

    if (from && to) {
        return (to.diff(from, 'seconds')) / 60; // Se calcula la diferencia de tiempo en segundos, y se divide por 60 para pasarla a minutos
    }

    return undefined;
}

/**
 * Calcula el tiempo de respuesta de un queueItem entre que fue creado y luego aceptado, eliminado o cancelado
 * @param item
 * @return {undefined|number} Tiempo en minutos que se tardo en responder
 */
const getResponseTimeMY = (item) => {

    const to = getTo(item);
    const from = item.inAcceptedZoneTimestamp ? moment(item.inAcceptedZoneTimestamp.seconds * 1000) : moment(item.createdTimestamp.seconds * 1000);

    if (from && to) {
        return (to.diff(from, 'seconds')) / 60; // Se calcula la diferencia de tiempo en segundos, y se divide por 60 para pasarla a minutos
    }

    return undefined;
}

/**
 * Calcula el tiempo de respuesta promedio y en porcentaje de los items para el período actual y pasado
 * @param actualItems Items de MY o Reserva del período seleccionado por el usuario
 * @param prevItems Items de MY o Reserva del período anterior al seleccionado por el usuario
 * @param {boolean} isMY Indica si los items son de MY o de Reserva
 * @return {{average: (number|number), percentage: (number|number), time: *[]}} Devuelve el tiempo promedio (average), el porcentaje respecto al periodo anterior (percentage)
 * y las diferencias de tiempo entre ambos para graficar
 */
export const calculateResponseTimeStatistic = (actualItems, prevItems, isMY) => {
    let actualItemsTime = [];
    let prevItemsTime = [];

    actualItems.forEach((item) => {
        const time = isMY ? getResponseTimeMY(item) : getResponseTimeBooking(item);
        if (time) {
            actualItemsTime.push(time);
        }
    })

    prevItems.forEach((item) => {
        const time = isMY ? getResponseTimeMY(item) : getResponseTimeBooking(item);
        if (time) {
            prevItemsTime.push(time);
        }
    })

    const totalActualTime = actualItemsTime.reduce((a, b) => a + b, 0);
    const totalPrevTime = prevItemsTime.reduce((a, b) => a + b, 0);

    return {
        average: actualItemsTime.length > 0 ? totalActualTime / actualItemsTime.length : 0,
        percentage: totalPrevTime && totalPrevTime > 0 ? totalActualTime / totalPrevTime : undefined,
        time: actualItemsTime,
    }

}

/**
 * Calcula la cantidad de usuarios de MY o Reserva para cada tipo de origen de solicitudes
 * @param actualItems Items de MY o Reserva correspondientes al período seleccionado por el usuario
 * @param prevItems Items de MY o Reserva correspondientes al período anterior al seleccionado por el usuario
 * @param {boolean} isMY Indica si los items son de MY o Reserva
 * @return {{woki: number, linkData: *[], street: number, percentageLink: (number|number), percentageStreet: (number|number), percentageWoki: (number|number), link: number, streetData: *[], wokiData: *[]}}
 * Devuelve el número de clientes, el porcentaje respecto al anterior y los datos para graficar de cada origen
 */
export const calculateTotalClients = (actualItems, prevItems, isMY) => {
    let actualWoki = 0;
    let prevWoki = 0;
    let actualLink = 0;
    let prevLink = 0;
    let actualStreet = 0;
    let prevStreet = 0;

    let wokiData = [];
    let linkData = [];
    let streetData = [];

    actualItems.forEach((item) => {
        // Solo los aceptados o no llegaron, dependiendo sea de MY o Reservas
        if (isMY ? item.acceptedTimestamp : item.noArrivedTimestamp) {
            switch (item.providence) {
                case WOKI:
                    wokiData.push(item.quantity);
                    actualWoki += 1;
                    break;
                case DYNAMIC_LINK:
                    linkData.push(item.quantity);
                    actualLink += 1;
                    break;
                case STREET:
                    streetData.push(item.quantity);
                    actualStreet += 1;
                    break;
                default:
                    break;
            }
        }
    });

    prevItems.forEach((item) => {
        // Solo los aceptados
        if (isMY ? item.acceptedTimestamp : item.noArrivedTimestamp) {
            switch (item.providence) {
                case WOKI:
                    prevWoki += 1;
                    break;
                case DYNAMIC_LINK:
                    prevLink += 1;
                    break;
                case STREET:
                    prevStreet += 1;
                    break;
                default:
                    break;
            }
        }
    });

    return {

        woki: actualWoki,
        percentageWoki: prevWoki > 0 ? actualWoki / prevWoki : 0,
        wokiData: wokiData,
        link: actualLink,
        percentageLink: prevLink > 0 ? actualLink / prevLink : 0,
        linkData: linkData,
        street: actualStreet,
        percentageStreet: prevStreet > 0 ? actualStreet / prevStreet : 0,
        streetData: streetData
    }

}

/**
 * Calcula la demora promedio estimada y real para los items
 * @param actualItems Items de MY correspondientes al período seleccionado por el usuario
 * @return {{delay: (number|number), realAverageDelay: (number|number)}} Delay: diferencia entre la demora real y estimada. RealAverageDelay: Demora promedio real
 */
export const calculateAverageDelay = (actualItems) => {
    // Toma solo los queueItem que ingresaron
    const itemsFilter = actualItems.filter((item) => item.arrivedTimestamp);

    // Tiempo verdadero que esperaron los clientes
    let realDelays = [];
    // Diferencias entre el tiempo real y el estimado por el partner
    let delays = [];
    // Tiempos estimados por el partner
    let estimateDelays = [];

    itemsFilter.forEach((item) => {
        const arrivedTimestamp = moment(item.arrivedTimestamp.seconds * 1000);
        const acceptedTimestamp = moment(item.acceptedTimestamp.seconds * 1000);
        realDelays.push(arrivedTimestamp.diff(acceptedTimestamp, 'minute'));
        delays.push(Math.abs(arrivedTimestamp.diff(acceptedTimestamp, 'minute') - item.delay));
        estimateDelays.push(item.delay);
    })

    // Diferencia entre tiempo real y estimado promedio
    let delay = delays.length > 0 ? delays.reduce((a, b) => a + b, 0) / delays.length : 0;

    // Total de las demoras reales
    let realDelayTotal = 0;
    // Total de las demoras estimadas
    let estimateDelayTotal = 0;

    realDelays.forEach((element, index) => {
        realDelayTotal += element;
        estimateDelayTotal += estimateDelays[index];
    })

    // Cambia el signo de la demora para saber si esta dando de mas o de menos
    if (estimateDelayTotal < realDelayTotal)
        delay = delay * -1;

    return {
        delay: delay,
        realAverageDelay: realDelays.length > 0 ? realDelayTotal / realDelays.length : 0,
    }
}

export const calculateTotalClientsForWeek = (items) => {
    let woki = [0,0,0,0,0,0,0];
    let link = [0,0,0,0,0,0,0];
    let street = [0,0,0,0,0,0,0];

    items.forEach(item => {
        const day = moment(item.arrivedTimestamp.seconds * 1000);

        switch (item.providence) {
            case WOKI:
                if (day.day() === 0) {
                    woki[6] += 1;
                }
                else {
                    woki[day.day() - 1] += 1;
                }
                break;
            case DYNAMIC_LINK:
                if (day.day() === 0) {
                    link[0] += 1;
                }
                else {
                    link[day.day() - 1] += 1;
                }
                break;
            case STREET:
                if (day.day() === 0) {
                    street[0] += 1;
                }
                else {
                    street[day.day() - 1] += 1;
                }
                break;
            default:
                break;
        }
    })

    return [["woki", ...woki], ["street", ...street], ["link", ...link]];
}

/**
 * Devuelve el angulo correcto segun la diferencia entre la demora promedio real, y la demora promedio estimada
 * @param delay {Number} Diferencia de la demora
 * @return {Number} Angulo a mostrar
 */
export const getDelayAngle = (delay) => {
    const minAngle = -147;
    const maxAngle = 70;
    const minTime = -15;
    const maxTime = 15;

    return (minAngle + ((maxAngle - minAngle) / (maxTime - minTime)) * (delay - minTime))
}