import React from 'react';

const DeleteIconModal = () => {
    return (
        <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.5 8.25C41.3415 8.25 33.3663 10.6693 26.5827 15.2019C19.7992 19.7345 14.5121 26.1769 11.39 33.7143C8.26787 41.2518 7.45099 49.5458 9.04263 57.5475C10.6343 65.5492 14.5629 72.8992 20.3319 78.6682C26.1008 84.4371 33.4508 88.3658 41.4525 89.9574C49.4543 91.549 57.7483 90.7321 65.2857 87.61C72.8232 84.4879 79.2655 79.2008 83.7981 72.4173C88.3307 65.6337 90.75 57.6585 90.75 49.5C90.75 44.083 89.6831 38.719 87.61 33.7143C85.537 28.7096 82.4986 24.1623 78.6682 20.3318C74.8378 16.5014 70.2904 13.463 65.2857 11.39C60.281 9.31696 54.917 8.25 49.5 8.25ZM49.5 82.5C42.9732 82.5 36.593 80.5646 31.1662 76.9385C25.7394 73.3124 21.5097 68.1585 19.012 62.1286C16.5143 56.0986 15.8608 49.4634 17.1341 43.062C18.4074 36.6606 21.5504 30.7806 26.1655 26.1655C30.7806 21.5503 36.6607 18.4074 43.062 17.1341C49.4634 15.8608 56.0986 16.5143 62.1286 19.012C68.1585 21.5097 73.3124 25.7394 76.9385 31.1662C80.5646 36.593 82.5 42.9732 82.5 49.5C82.5 58.2521 79.0232 66.6458 72.8345 72.8345C66.6458 79.0232 58.2522 82.5 49.5 82.5Z" fill="#FB3F4A"/>
            <path d="M49.5005 70.125C51.7787 70.125 53.6255 68.2782 53.6255 66C53.6255 63.7218 51.7787 61.875 49.5005 61.875C47.2223 61.875 45.3755 63.7218 45.3755 66C45.3755 68.2782 47.2223 70.125 49.5005 70.125Z" fill="#FB3F4A"/>
            <path d="M49.5005 28.875C48.4065 28.875 47.3573 29.3096 46.5837 30.0832C45.8101 30.8568 45.3755 31.906 45.3755 33V53.625C45.3755 54.719 45.8101 55.7682 46.5837 56.5418C47.3573 57.3154 48.4065 57.75 49.5005 57.75C50.5945 57.75 51.6437 57.3154 52.4173 56.5418C53.1909 55.7682 53.6255 54.719 53.6255 53.625V33C53.6255 31.906 53.1909 30.8568 52.4173 30.0832C51.6437 29.3096 50.5945 28.875 49.5005 28.875Z" fill="#FB3F4A"/>
        </svg>
    )
}

export default DeleteIconModal;