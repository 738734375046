import React from 'react';
import {Box, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import PropTypes from "prop-types";
import {visuallyHidden} from '@mui/utils';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    HeaderTitle: {
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: '600 !important',
        color: '#041C32 !important',
        fontSize: '1rem !important'
    },
}));

const TableSortableHeader = (props) => {
    const classes = useStyles();
    const {head, order, orderBy, onRequestSort} = props;

    const createSortHandler = (cell) => {
        console.log( 'Cell woki --> ', cell );
        const isAsc = orderBy === cell && order === 'asc';
        onRequestSort(cell, isAsc ? 'desc' : 'asc');
    };

    return (
        <TableHead sx={{backgroundColor: '#FBFBFC'}}>
            <TableRow>
                {head.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        className={classes.HeaderTitle}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.disableSorting ? headCell.label : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={(e) => createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableSortableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    head: PropTypes.array.isRequired,
};

export default TableSortableHeader;
