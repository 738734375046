import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Actions
import {
  changePrimaryTitleAppBar,
  changeShowSidebar,
  changeTitleAppBar,
} from "../../../../actions/sidebarActions";
// import {closeError, resetAllData, saveSector, sectorNewTable} from "../../../actions";

// Components
// import SaveChangesButton from "../../NewComponents/Buttons/Save Changes/SaveChangesButton";

import LayoutConfigurationForm from "../Components/LayoutConfigurationForm";
import { Grid } from "@mui/material";
import { SECTOR_MODE } from "../../../../constants";
import DraggableMenu from "../Components/DraggableMenu";
import GridTable from "../Components/GridTable";
import { displayNotification } from "../../../../actions/notistackActions";
import { refreshError, saveOrUpdateLayout } from "../../../../actions/V3";
// import DeleteDialog from "../DeleteDialog";
// import GridTable from "./GridTable";

// // Material Ui
// import ErrorDialog from "../../GeneralComponents/Modal/ErrorDialog";
// import {ModalMode} from "../../../constants/modalMode";
import html2canvas from "html2canvas";
import FloatingButton from "../Components/Buttons/FloatingButton";

const LayoutManager = (props) => {
  const { edited, combineMode, mode, layout, error } = props;

  const { displayNotification, refreshError, saveOrUpdateLayout } = props;

  const [showFloatingButton, setShowFloatingButton] = useState(false);

  useEffect(() => {
    if (mode === SECTOR_MODE.VIEW) return;
    props.changeTitleAppBar(
      mode === SECTOR_MODE.NEW_LAYOUT || mode === SECTOR_MODE.NEW_SECTOR
        ? "Agregar layout"
        : "Modificar layout: " + layout.name
    );
    props.changePrimaryTitleAppBar(
      mode === SECTOR_MODE.NEW_SECTOR
        ? "Agregá al menos un layout a tu sector"
        : mode === SECTOR_MODE.NEW_LAYOUT
        ? "Agregá un nuevo layout a tu sector"
        : "Modificá el layout de tu sector"
    );
    props.changeShowSidebar(true);
  }, [props, mode, layout]);

  const handleOnSave = () => {
    html2canvas(document.querySelector("#sector-grid-layout")).then(
      (canvas) => {
        canvas.toBlob(async (blob) => {
          await saveOrUpdateLayout(blob);
        });
      }
    );
  };

  useEffect(() => {
    if (error.message !== "") {
      displayNotification(error.message, error.severity ?? "info");
      refreshError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, displayNotification]);

  useEffect(() => {
    if (mode === SECTOR_MODE.VIEW) return;
    if (
      edited &&
      !combineMode &&
      layout.name.length > 0 &&
      layout.tables.length > 0
    ) {
      setShowFloatingButton(true);
    } else {
      setShowFloatingButton(false);
    }
  }, [edited, combineMode, layout, mode]);

  return (
    <>
      <LayoutConfigurationForm />
      <Grid
        style={{
          marginTop: 1,
          padding: "0em 2em 0em 1em",
        }}
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} md={3} xl={2}>
          <DraggableMenu />
        </Grid>
        <Grid item xs={12} md={9} xl={10}>
          <GridTable />
        </Grid>
      </Grid>
      {showFloatingButton && <FloatingButton onClick={handleOnSave} />}
      {/* <DeleteDialog /> */}
      {/* <ErrorDialog
      //   open={error !== ""}
      //   type={ModalMode.ERROR}
      //   title="¡Atención!"
      //   body={error}
      //   onAccept={closeError}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    edited: state.v3.sectorsLayoutsTables.edited,
    layout: state.v3.sectorsLayoutsTables.layout,
    mode: state.v3.sectorsLayoutsTables.mode,
    combineMode: state.v3.sectorsLayoutsTables.combineMode,
    loading: state.v3.sectorsLayoutsTables.loading,
    error: state.v3.sectorsLayoutsTables.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveOrUpdateLayout: (image) => dispatch(saveOrUpdateLayout(image)),
    // closeError: () => dispatch(closeError()),
    changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
    changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    changePrimaryTitleAppBar: (data) =>
      dispatch(changePrimaryTitleAppBar(data)),
    displayNotification: (message, severity) =>
      dispatch(displayNotification(message, severity)),
    refreshError: () => dispatch(refreshError()),
    // resetAllData: () => dispatch(resetAllData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutManager);
