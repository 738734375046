import React from 'react';

// Icons
import { PeopleIcon, RefreshIcon, TableIcon } from '../../../../../SvgComponents/icons';
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { Box, IconButton, Stack, Typography } from '@mui/material';

const ShiftGroupComponent = ({ rotation, type, handleChangeRotationActive, lastName, all }) => {
    return (
        <Stack sx={{ marginLeft: 0, marginRight: '8px'}}>
            <Box mt={ 2 }>
                <Stack
                    sx={{ padding: '.1rem .3rem .1rem .8rem' }}
                    border="1.25px solid #0B4762"
                    borderRadius="10px"
                    width="max-content"
                    alignItems="center"
                    direction="row"
                    spacing={ 1 }
                    ml={ 0 }
                >
                    <Box mt="1px" display="flex" alignItems="center" gap={1}>
                        { type === 'groups' ? <PeopleIcon /> : <TableIcon />}
                        <Typography
                            sx={{ 
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                color: '#041C32',
                                fontWeight: 500,
                            }}
                        >
                            { rotation.id !== '-1' ? all ? 'Todos' : rotation.id : lastName }
                        </Typography>
                    </Box>
                    <Box borderLeft="1px solid #D1D1D1" height="17.85px"></Box>
                    <Box pt="2px" display="flex" alignItems="center" gap={1}>
                        <RefreshIcon height="17px" width="15px" />
                        <Typography
                            sx={{ 
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                color: '#041C32',    
                                fontWeight: 500,
                            }}
                        >
                            { type !== 'groups' ? rotation.value : rotation.value }
                        </Typography>
                    </Box>
                    <IconButton 
                        onClick={ 
                            type === 'groups' ? 
                                (() => handleChangeRotationActive( rotation.id )) 
                            : 
                                (() => handleChangeRotationActive( rotation.id )) 
                        } 
                        sx={{ p: '4px' }} 
                    >
                        <CloseIcon sx={{ fontSize: '1rem', color: '#0B4762' }}/>
                    </IconButton>
                </Stack>
            </Box>
        </Stack>
    )
}

export default ShiftGroupComponent;