import React, { useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { setAvailableLayouts, setReservableSectors, setSubshift } from "../../../../../actions/shiftsActions";

// Components
import CustomizeReservableSectors from "../components/CustomizeReservableSectors";
import ReservableSectorCard from "../components/ReservableSectorCard";

// Material Ui
import { Divider, makeStyles } from "@material-ui/core";
import { Box, Stack, Typography } from "@mui/material";

// Utils
import { 
  AddBookableSectorsToSubshift, 
  RemoveExtraExcludeTables, 
  AddLayoutToSubshift, 
  RemoveLayout, 
  getSectors, 
} from "../utils/sectorsFunctions";

// Styles
import { DecriptionShiftStyles, ShiftSelectTitles, TitleShiftStyles } from "../../../Styles";
import { dividerStyle } from "../utils/styles/generalStyles";

const useStyles = makeStyles(() => ({
  divider: dividerStyle,  
}));

const SectorsStep = (props) => {  
  const classes = useStyles();

  // Variables
  const {
    reservableSectors,
    workWithWaitList,
    availableLayouts,
    minBookingGroup,
    maxBookingGroup,
    subShifts,
    sectors,
    groups
  } = props;

  // Acciones
  const {
    setReservableSectors,
    setAvailableLayouts,
    setSubshift,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const addAvailableLayouts = ( layoutId, minValue, maxValue, note, sectorId ) => {

    const newLayoutlayout = {
        id: layoutId,
        minCapacityAuto: minValue,
        maxCapacityAuto: maxValue,
        note: note,
        activeWaitList: workWithWaitList,
        sectorId: sectorId
    };

    const newAvailableLayouts = availableLayouts.concat(newLayoutlayout);
    setAvailableLayouts( newAvailableLayouts );

    AddLayoutToSubshift( newLayoutlayout, subShifts, setSubshift );
  }

  const removeLayoutAndAddAvailableLayout = (removeLayoutId, addLayout) => {
    // Borrar layout
    const newAvailableLayouts = availableLayouts.filter((lay) => lay.id !== removeLayoutId);
    // Agrega layout
    newAvailableLayouts.push(addLayout);
    setAvailableLayouts(newAvailableLayouts);

    AddLayoutToSubshift(addLayout, subShifts, setSubshift);
    // Quitar de reservableSectors y agregar en reservableSectors    
    // setReservableSectors( getSectors( 
    //   newAvailableLayouts, 
    //   subShifts, 
    //   sectors, 
    //   {}, 
    //   minBookingGroup, 
    //   maxBookingGroup,
    //   reservableSectors 
    // ));
    // Cambiar bookableSectors
    AddBookableSectorsToSubshift( newAvailableLayouts, workWithWaitList, subShifts, setSubshift );

  };

  useEffect(() => {
  }, [reservableSectors])

  const removeAvailableLayout = (sectorId) => {
    RemoveExtraExcludeTables(sectors, sectorId, subShifts, setSubshift);
    RemoveLayout(availableLayouts, sectorId, sectors, reservableSectors, setReservableSectors, setAvailableLayouts);    
  }

  const handleChangeNote = (note, layoutId) => {    
    const nextAvailableLayout = availableLayouts.map(layout => {
        if (layout.id === layoutId) {            
            return {
            ...layout,
            note: note,
            };
        } else {
          return layout;              
        }
        });        
    setAvailableLayouts(nextAvailableLayout);
  }

  const handleChangeMinValue = (minValue, layoutId) => {
    const nextAvailableLayout = availableLayouts.map(layout => {
        if (layout.id === layoutId) {            
            return {
            ...layout,
            minCapacityAuto: minValue,
            };
        } else {
          return layout;              
        }
        });        
    setAvailableLayouts(nextAvailableLayout);
  }

  const handleChangeMaxValue = (maxValue, layoutId) => {
    const nextAvailableLayout = availableLayouts.map(layout => {
        if (layout.id === layoutId) {            
            return {
            ...layout,
            maxCapacityAuto: maxValue,
            };
        } else {
          return layout;              
        }
        });        
    setAvailableLayouts(nextAvailableLayout);
  }

  useEffect(() => {

    if ( availableLayouts.length === 0 ) {
      const newAvailableLayouts = sectors.map((sector) => {
        // Agrego los primeros layouts
        const firsLayout = sector.layouts[0];
        // addAvailableLayouts(firsLayout.uuid, 2, 10, '', sector.id)
        return {
          id: firsLayout.uuid,
          minCapacityAuto: 1,
          maxCapacityAuto: 20,
          note: '',
          activeWaitList: workWithWaitList,
          sectorId: sector.id,
        }
      });

      setAvailableLayouts(newAvailableLayouts);

      // eslint-disable-next-line array-callback-return
      newAvailableLayouts.map((newLayoutlayout) => {
          // addAvailableLayouts(newAvailableLayout.id, newAvailableLayout.min, newAvailableLayout.max, newAvailableLayout.note, newAvailableLayout.sectorId)        
          const nextSubShifts = subShifts.map(subShift => {    
          const newBookableSectors = subShift.bookableSectors;
          const existBookableSector = newBookableSectors.find((b) => b.id === newLayoutlayout.id);

          if (typeof existBookableSector === 'undefined' || existBookableSector === null) {
            newBookableSectors.push({
              id: newLayoutlayout.id, 
              allowsWaitList: newLayoutlayout.activeWaitList,
              excludedGroup: []
            });
          }
          
          return {
            ...subShift,
            bookableSectors: newBookableSectors,
          };        
        });

        setSubshift(nextSubShifts);
      });
      
      setReservableSectors( getSectors( newAvailableLayouts, subShifts, sectors, reservableSectors, minBookingGroup, maxBookingGroup, groups ) );
    } else {
      setReservableSectors( getSectors( availableLayouts, subShifts, sectors, reservableSectors, minBookingGroup, maxBookingGroup, groups ) );
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  return (
    <Box pb={5}>
      <Box sx={{ mb: '20px' }} >
        <Typography sx={ TitleShiftStyles } >Configuración de sectores</Typography>
        <Typography sx={ DecriptionShiftStyles } >
          Selecciona los sectores que quieres habilitar para este turno
        </Typography>
      </Box>
      <Box>
        <Typography sx={ ShiftSelectTitles } >Seleccionar sectores reservables</Typography>
        <Stack spacing={3} marginTop="24px">
          {
            sectors.map((sector) => (
              <ReservableSectorCard 
                removeLayoutAndAddAvailableLayout={ removeLayoutAndAddAvailableLayout }
                removeAvailableLayout={ removeAvailableLayout } 
                handleChangeMinValue={ handleChangeMinValue } 
                handleChangeMaxValue={ handleChangeMaxValue }
                addAvailableLayouts={ addAvailableLayouts } 
                handleChangeNote={ handleChangeNote } 
                layouts={ sector.layouts } 
                sectorId={ sector.id } 
                name={ sector.name } 
                key={ sector.id } 
              />
            ))
          }          
        </Stack>
      </Box>
      <Divider className={ classes.divider } style={{ width: '100%' }} />
      <CustomizeReservableSectors />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    workWithWaitList: state.v3.shifts.shiftData.workWithWaitList,
    availableLayouts: state.v3.shifts.shiftData.availableLayouts,
    minBookingGroup: state.v3.shifts.shiftData.minBookingGroup,
    maxBookingGroup: state.v3.shifts.shiftData.maxBookingGroup,     
    reservableSectors: state.v3.shifts.reservableSectors,
    subShifts: state.v3.shifts.shiftData.subShifts,
    sectors: state.v3.shifts.sectors,
    groups: state.v3.shifts.groups,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setUpdateTables: ({ sectors }) => dispatch( setUpdateTables({ sectors }) ),
    setReservableSectors: (data) => dispatch(setReservableSectors(data)),            
    setAvailableLayouts: (data) => dispatch(setAvailableLayouts(data)),
    setSubshift: (data) => dispatch( setSubshift(data) ),      
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsStep);