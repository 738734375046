import {PASSWORD_MIN_LENGTH} from "../constants";

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const validatePassword = (password) => {
  return password && password.length >= PASSWORD_MIN_LENGTH;
};
export const validateUserName = (name) => {
  return name && name.length >= 1;
};

export const validatePhone = (phone) => {
  const re = new RegExp(/^[1-9]\d{9}$/gm);
  return re.test(phone);
};
