import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/analytics"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import {USERS_PARTNERS} from "../../constants";
import {DB_TEST, LOCAL_AUTH, LOCAL_FUNCTIONS} from "../../constants/config";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL:
      window.location.hostname === "localhost" && DB_TEST
          ? "https://localhost:4000/firestore"
          : process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  userProfile: USERS_PARTNERS,
  useFirestoreForProfile: true,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

if (LOCAL_FUNCTIONS) {
  firebase.app().functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION).useEmulator("localhost", 5001);
}

if (window.location.hostname === "localhost" && DB_TEST) {
  // console.log(
  //   "testing locally -- hitting local functions and firestore emulators"
  // );
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });

} else {
  // console.log("Usando produccion");
  firebase.firestore();
}
firebase.analytics();

export const auth = firebase.auth();
if (LOCAL_AUTH)
  auth.useEmulator('http://localhost:9099')

export const db = firebase.firestore();

export default firebase;
