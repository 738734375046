import {
    CHANGE_STATISTICS_RESUME_FILTER_DATE,
    FETCH_STATISTICS_RESUME_FAILURE,
    FETCH_STATISTICS_RESUME_SUCCESS,
    START_FETCH_STATISTICS_RESUME
} from "../../constants";
import axios from "axios";

export const startFetchStatisticsResume = () => ({
    type: START_FETCH_STATISTICS_RESUME
});

export const fetchStatisticsResumeSuccess = ({arriveStatistics, bestClients, statisticsResume, graphicData}) => ({
    type: FETCH_STATISTICS_RESUME_SUCCESS,
    payload: {arriveStatistics, bestClients, statisticsResume, graphicData}
});

export const fetchStatisticsResumeFailure = (error) => ({
    type: FETCH_STATISTICS_RESUME_FAILURE,
    payload: error
});

export const changeStatisticsResumeFilterDate = ({from, to}) => ({
    type: CHANGE_STATISTICS_RESUME_FILTER_DATE,
    payload: {from, to}
});

export const fetchStatisticsArriveResume = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, statisticsResumeReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {from, to} = statisticsResumeReducer;

        try {
            dispatch(startFetchStatisticsResume());
            const params = {
                partner: idPartnerSelected,
                from: from.format(),
                to: to.format(),
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/total`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchStatisticsResumeSuccess({arriveStatistics: data}));
        } catch (e) {
            if (e.data !== undefined) {
                dispatch(fetchStatisticsResumeFailure(e.data));
            } else {
                dispatch(fetchStatisticsResumeFailure(e));
            }
        }
    };
};

export const fetchStatisticsBestClients = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;

        try {
            dispatch(startFetchStatisticsResume());
            const params = {
                partner: idPartnerSelected,
                limit: 5,
                page: 0,
                orderBy: 'arrived',
                orderType: 'desc',
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/client`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchStatisticsResumeSuccess({bestClients: data.results}));
        } catch (e) {
            if (e.data !== undefined) {
                dispatch(fetchStatisticsResumeFailure(e.data));
            } else {
                dispatch(fetchStatisticsResumeFailure(e));
            }
        }
    };
};

export const fetchStatisticsResume = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, statisticsResumeReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {from, to} = statisticsResumeReducer;

        try {
            dispatch(startFetchStatisticsResume());
            const params = {
                partner: idPartnerSelected,
                from: from.format(),
                to: to.format(),
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/resume`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchStatisticsResumeSuccess({statisticsResume: data}));
        } catch (e) {
            if (e.data !== undefined) {
                dispatch(fetchStatisticsResumeFailure(e.data));
            } else {
                dispatch(fetchStatisticsResumeFailure(e));
            }
        }
    };
};

export const fetchGraphicData = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, statisticsResumeReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {from, to} = statisticsResumeReducer;

        try {
            dispatch(startFetchStatisticsResume());
            const params = {
                partner: idPartnerSelected,
                from: from.format(),
                to: to.format(),
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/grouped`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchStatisticsResumeSuccess({graphicData: data}));
        } catch (e) {
            if (e.data !== undefined) {
                dispatch(fetchStatisticsResumeFailure(e.data));
            } else {
                dispatch(fetchStatisticsResumeFailure(e));
            }
        }
    };
};
