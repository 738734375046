import React from 'react'

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Material Ui
import { FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { ShiftHoursSelectWithArrowsStyles } from '../../../../Styles';

const ShifHourWithArrowsSelect = ({ error = false, handleChange, disabled = false, value, optionsArray, groupKey = '' }) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                width: 'max-content',
                marginTop: '.2rem',
                minWidth: '78px',
            },
        },
    };

    const handleIncrease = () => {
        if ( groupKey === '' ) {
            if ( value < 300 && !disabled ) {
                handleChange( value + 15 );
            };
        } else {
            if ( value < 300 && !disabled ) {
                let some = value + 15;
                handleChange( groupKey, some );
            };
        };
    };

    const handleDecrease = () => {
        if ( groupKey === '' && !disabled ) {
            if ( value > 15 ) {
                handleChange( value - 15 );
            };
        } else {
            if ( value > 15 && !disabled ) {
                let some = value - 15;
                handleChange( groupKey, some );
            };
        };
    };

    const handleChangeValues = () => {
        return (
            <Grid 
                item 
                sx={{ 
                    flexDirection: 'column', 
                    p: '0 .5rem 0 .3rem', 
                    display: 'flex', 
                    gap: '2px', 
                }}
            >
                <ExpandLessIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#2A2742',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '1rem', 
                    }}
                    onClick={ disabled ? null : handleIncrease } 
                />
                <ExpandMoreIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#2A2742',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '1rem', 
                    }}
                    onClick={ disabled ? null : handleDecrease } 
                />
            </Grid>
        )
    };

    return (
        <FormControl error={ error } sx={{ width: '5rem' }}>
            <Select
                inputProps={{ 'aria-label': 'Without label' }}
                sx={ ShiftHoursSelectWithArrowsStyles }
                IconComponent={ handleChangeValues }
                input={ <OutlinedInput /> }
                onChange={ groupKey !== '' ? (e) => handleChange( groupKey, e.target.value ) : (e) => handleChange( e.target.value ) }
                MenuProps={ MenuProps }
                labelId='select-label'
                disabled={ disabled }
                value={ value }
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            value={ item.value } 
                            key={ item.value } 
                            sx={{ 
                                padding: '.5rem', 
                                height: '2.5rem' 
                            }}
                        >
                            <ListItemText 
                                primary={ item.option }
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
};

export default ShifHourWithArrowsSelect;