import React from 'react';

const LEHourIcon = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.5C3.5888 0.5 0 4.0888 0 8.5C0 12.9112 3.5888 16.5 8 16.5C12.4112 16.5 16 12.9112 16 8.5C16 4.0888 12.4112 0.5 8 0.5ZM8 14.9C4.4712 14.9 1.6 12.0288 1.6 8.5C1.6 4.9712 4.4712 2.1 8 2.1C11.5288 2.1 14.4 4.9712 14.4 8.5C14.4 12.0288 11.5288 14.9 8 14.9Z" fill="#1AAFDE"/>
            <path d="M8.79995 4.5H7.19995V8.8312L9.83435 11.4656L10.9656 10.3344L8.79995 8.1688V4.5Z" fill="#1AAFDE"/>
        </svg>
    )
}

export default LEHourIcon;