import React from "react";

const RefreshIcon = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 3.05859V7.55859H12.75"
        stroke="#0B4762"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3674 11.3101C14.8799 12.69 13.9571 13.8741 12.738 14.6839C11.519 15.4938 10.0698 15.8855 8.60873 15.8C7.1477 15.7145 5.75403 15.1565 4.63773 14.2101C3.52143 13.2636 2.74298 11.98 2.41968 10.5526C2.09639 9.12524 2.24577 7.63146 2.84531 6.29637C3.44485 4.96128 4.46206 3.85722 5.74368 3.15055C7.02529 2.44388 8.50186 2.17289 9.95088 2.37843C11.3999 2.58396 12.7429 3.25487 13.7774 4.29007L17.2499 7.56007"
        stroke="#0B4762"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

RefreshIcon.defaultProps = {
  width: "18",
  height: "19",
};

export default RefreshIcon;
