export const ModalMode = {
    PUBLISH: "publish",
    DISABLED: 'disabled',
    CONTINUE: "continue",
    CANCEL_EDITION: "cancel-edition",
    CANCEL_CREATION: "cancel-creation",
    EDIT: "edit",
    ERROR: "error",
    DELETE: "delete",
}
