import React from 'react'

const CheckIconPrimary = (props) => {
    const {checked} = props;
    return (
        <div style={{position: 'absolute', zIndex: '4', display: checked ? 'block' : 'none'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.709" height="17.709" viewBox="0 0 20.709 20.709">
                <path id="check-circle"
                        d="M17.312,8.17a.84.84,0,0,0-.243-.62L15.842,6.337a.847.847,0,0,0-1.214,0l-5.5,5.487L6.08,8.777a.847.847,0,0,0-1.214,0L3.64,9.99a.84.84,0,0,0-.243.62.823.823,0,0,0,.243.607L8.52,16.1a.829.829,0,0,0,.607.256.845.845,0,0,0,.62-.256l7.321-7.321a.823.823,0,0,0,.243-.607Zm3.4,2.184a10.13,10.13,0,0,1-1.389,5.2,10.307,10.307,0,0,1-3.768,3.768,10.13,10.13,0,0,1-5.2,1.389,10.13,10.13,0,0,1-5.2-1.389,10.307,10.307,0,0,1-3.768-3.768A10.13,10.13,0,0,1,0,10.354a10.13,10.13,0,0,1,1.389-5.2A10.307,10.307,0,0,1,5.157,1.389,10.13,10.13,0,0,1,10.354,0a10.13,10.13,0,0,1,5.2,1.389A10.307,10.307,0,0,1,19.32,5.157,10.13,10.13,0,0,1,20.709,10.354Z"
                        fill="#0076ff"
                />
            </svg>
        </div>
    )
}

CheckIconPrimary.defaultProps = {
    checked: true
}

export default CheckIconPrimary
