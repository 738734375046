import React from "react";
import QRCode from "react-qr-code";
import Printable from "../../SvgComponents/images/QR/Printable";

const QRComponent = React.forwardRef((props, ref) => {
    const {link} = props;
    return (
        <div ref={ref}>
            <Printable>
                <QRCode value={link} size={152} level={"L"}/>
            </Printable>
        </div>
    );
});

export default QRComponent;
