import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';

const InputWithCondition = (props) => {

    const {
        alignHelperText,
        backgroundColor,
        helperText,
        onChange,
        onEnter,
        value,
        width,
        error,
        text,
        type,
        id,
    } = props;

    const useStyles = makeStyles(() => ({
        root: {
            border: 'solid 3px #0ff',
            padding: '3px',

            '& .MuiOutlinedInput-root': {
                borderColor: '#D0D0D0',
                backgroundColor: 'white',
                '&:hover fieldset': {
                    borderColor: error ? 'red' : '#0B4762',
                },
                '&.Mui-focused fieldset': {
                    borderColor: error ? 'red' : '#0B4762',
                    color: error ? 'red' : '#0B4762',
                },
            },
        },
        helperText: {
            color: 'red'
        }
    }));

    const classes = useStyles();

    const onKeyPress = (e) => {
        if (e.key === 'Enter' && onEnter !== undefined) {
            onEnter();
        }
    }
    const getHelperTextStyle = () => {
        if (alignHelperText) {
            return {
                marginLeft: '0px',
                marginRight: '-80px',
                marginBottom: '10px'
            };
        } else {
            return {}
        }
    }

    return (
        <TextField
            helperText={`${helperText}`}
            label={`${text}`}
            type={type ? type : "search"}
            sx={{
                backgroundColor: backgroundColor ? backgroundColor : 'tranparent',
                fontSize: '10px',
                width: `${width}`,
                '& .MuiInputBase-input': {
                    fontFamily: 'Poppins, sans-serif !important',
                },
                '& .MuiFormHelperText-root': {
                    fontFamily: 'Poppins, sans-serif !important',
                }
            }}
            FormHelperTextProps={{
                sx: getHelperTextStyle()
            }}
            InputLabelProps={{
                style: {
                    fontFamily: 'Poppins, sans-serif',
                    color: "#808080",
                    fontSize: '.9rem',
                    marginTop: '-4px',
                },
                classes: {
                    focused: classes.cssFocused,
                },
            }}
            InputProps={{
                style: {
                    borderRadius: '8px',
                    height: '2.6rem'
                }
            }}
            classes={classes}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            error={error}
            id={id}
        />
    )
}

export default InputWithCondition;
