import React from 'react';
import {Grid,} from '@material-ui/core';

function ComingSoonPage(props) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <h1>Próximamente</h1>
            </Grid>
        </Grid>
    );
}

export default ComingSoonPage;