import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {getNameOrigin} from "../../../constants/providence";
import {Mail, Phone} from "@material-ui/icons";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import CantPersonas from "./CantPersonas";
import Search from './Search'
import DropdownFilter from "./DropdownFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    tableHeader: {
        fontSize: 36,
        fontWeight: "bold",
        marginRight: '10px'
    },
    tableHeaderDesc: {
        fontSize: 12,
        fontWeight: "normal",
        alignContent: "flex-end",
        alignItems: "end",
        alignSelf: "end",
        width: '200px'
    },
    searchContainer: {
        margin: 5,

    },
    search: {
        margin: 5
    }
}));

const ROWS_PER_PAGE = 8;

function ClientsTable(props) {
    const classes = useStyles();
    // DATA
    const {items, isQueueItem, search, tableDescription, filterStateOptions, filterOriginOptions} = props;

    const [searchValue, setSearchValue] = useState(search);
    const [filterState, setFilterState] = useState([]);
    const [filterOrigin, setFilterOrigin] = useState([]);

    //METHODS
    const {changeSearchAndFilter, getFinalState} = props;

    // FUNCIONES TABLA CLIENTES
    const getIconOrigin = (providence) => {
        return getNameOrigin(providence);
    }

    const getUserName = (usersData) => {
        if (usersData && usersData.length > 0) {
            return usersData[0].name ? usersData[0].name : usersData[0].email
        }
        return '-'
    }

    const getUserContact = (usersData) => {
        if (usersData && usersData.length > 0) {
            return (<Grid container direction={"column"}>
                <Grid item>
                    <Phone/>
                    {usersData[0].phone ? usersData[0].phone : '-'}
                </Grid>
                <Grid item>
                    <Mail/>
                    {usersData[0].email ? usersData[0].email : '-'}
                </Grid>
            </Grid>)
        }
        return '-'
    }


    const getFormatDateTime = (dateTime) => {
        return moment.unix(dateTime["seconds"]).format("HH:mm DD/MM/yyyy")
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

    // Si hubo un cambio en los items, se vuelve a la pagina 0
    useEffect(() => {
        setPage(0);
    }, [items])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //FILTRO Y BUSQUEDA


    useEffect(() => {
        let filter = {};

        if (filterState.length > 0) filter["state"] = filterState;
        if (filterOrigin.length > 0) filter["origin"] = filterOrigin;
        setPage(0);
        changeSearchAndFilter(searchValue, Object.keys(filter).length > 0 ? filter : undefined);
    }, [filterState, filterOrigin])

    const handleChange = (value) => {
        if (value?.length <= 0) {
            setFilterOrigin([]);
            setFilterState([]);
        } else {
            if (filterStateOptions.some((option) => value.indexOf(option) >= 0)) {
                setFilterState(value.filter((filter) => filterStateOptions.includes(filter)));
            } else {
                setFilterState([]);
            }
            if (filterOriginOptions.some((option) => value.indexOf(option) >= 0)) {
                setFilterOrigin(value.filter((filter) => filterOriginOptions.includes(filter)));
            } else {
                setFilterOrigin([]);
            }
        }

    }

    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // console.log("PRESS ENTER")
            setFilterOrigin([]);
            setFilterState([]);
            changeSearchAndFilter(searchValue, undefined);
        }
    }

    const clearSearch = () => {
        setSearchValue("");
        setFilterOrigin([]);
        setFilterState([]);
        changeSearchAndFilter(undefined, undefined);
    }

    return (
        <Grid container>
            <Grid container item justify={"space-between"} spacing={2} alignItems={"center"}>
                <Grid item xs={7}>
                    <div style={{display: 'flex', alignItems: 'end'}}>
                        <Typography className={classes.tableHeader}>Clientes</Typography>
                        <Typography
                            className={classes.tableHeaderDesc}>{tableDescription}</Typography>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Search
                        onKeyPress={(e) => handleKeyPress(e)}
                        value={searchValue}
                        onChange={handleChangeSearch}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DropdownFilter
                        filterStateOptions={filterStateOptions}
                        filterOriginOptions={filterOriginOptions}
                        isQueueItem={isQueueItem}
                        filterState={filterState}
                        filterOrigin={filterOrigin}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            {/*Tabla*/}
            <Grid item xs={12} style={{padding: '10px 30px 10px 0px'}}>
                <TableContainer style={{borderRadius: '20px 20px 0px 0px'}}>
                    <Table>
                        <TableHead style={{backgroundColor: 'black'}}>
                            <TableRow>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Origen</TableCell>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'left',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Nombre de usuario</TableCell>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Contacto</TableCell>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Cant. personas</TableCell>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Estado final</TableCell>
                                <TableCell
                                    style={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                >Horario de solicitud</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                                <TableRow key={item.id}>
                                    <TableCell
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >{getIconOrigin(item.providence)}</TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'left'
                                        }}
                                    >{getUserName(isQueueItem ? item.usersData : item.clientData)}</TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'left',
                                            color: '#0076FF'
                                        }}
                                    >{getUserContact(isQueueItem ? item.usersData : item.clientData)}</TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    ><CantPersonas quantity={item.quantity}/></TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >{getFinalState(item)}</TableCell>
                                    <TableCell
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >{getFormatDateTime(item.createdTimestamp)}</TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/*Paginacion*/}
            <Grid item xs={12}>
                <TablePagination
                    component="div"
                    count={items ? items.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </Grid>
        </Grid>
    );
}

export default ClientsTable;