import React, {useCallback, useEffect, useState} from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {Backdrop, Box, Button, Fade, Modal} from '@mui/material';
import {useHistory} from "react-router-dom";
import { connect } from 'react-redux';
import { setPartnerSelectedId } from '../../actions';

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '5%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        bgcolor: 'white',
        display: 'flex',
        width: '575px',
        border: 'none',
        boxShadow: 24,
        left: '50%',
        top: '50%',
        '&:focus-visible': {
            outline: 'none'
        },
    },
    Title: {
        marginBottom: '0rem',
        fontSize: '1.6rem',
        color: '#041C32',
        fontWeight: 500,
    },
    Subtitle: {
        textAlign: 'center',
        marginTop: '.5rem',
        color: '#737171',
        fontWeight: 500,
        fontSize: '1rem'
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '25px'
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    SaveChangesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: 'white !important',
    }
}));

const SaveChangesModal = ( props ) => {

    // Actions
    const { setPartnerSelectedId } = props;

    // External props
    const { when, handleClose, submit, onClose } = props;

    const classes = useStyles();

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        console.log("SaveChangesModal useEffect, when: ", when);
        if (when) {
            history.block((prompt) => {
                console.log("Path: " + prompt.pathname);
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return false;
            });
        } else {
            history.block(() => {
            });
        }

        return () => {
            history.block(() => {
            });
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (await submit) {
            setShowPrompt(false);
            const canRoute = await Promise.resolve(submit());
            if (canRoute) {
                history.block(() => {
                });
                history.push(currentPath);
            }
        }
    }, [currentPath, history, submit]);

    const handleCancel = useCallback(async () => {
        if ( handleClose ) {
            const canRoute = await Promise.resolve(handleClose());
            if ( canRoute ) {
                history.block(() => {
                });
                history.push(currentPath);
            }

            setPartnerSelectedId({
                id: localStorage.getItem( 'partnerSelected' )
            });
        }
        setShowPrompt( false );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath, history, handleClose]);

    const handleOnClose = () => {
        setShowPrompt(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Modal
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            slots={{ backdrop: Backdrop }}
            onClose={ handleOnClose }
            closeAfterTransition
            open={ showPrompt }
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ showPrompt }>
                <Box className={classes.ModalContainer}>
                    <div className={classes.IconCross} onClick={handleOnClose}>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <svg width="50" height="50" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z"
                                stroke="#EB252E" strokeWidth="5.25"/>
                            <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E" strokeWidth="5.25"
                                strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <h1 className={classes.Title}>
                            Tenés cambios sin guardar
                        </h1>
                        <h3 className={classes.Subtitle}>
                            ¿Seguro que queres continuar?
                        </h3>
                    </div>
                    <div className={classes.ButtonsComponents}>
                        <Button
                            className={classes.ContinuesButton}
                            onClick={handleCancel}
                        >Continuar sin guardar</Button>
                        <Button
                            className={classes.SaveChangesButton}
                            onClick={handleOK}
                        >Guardar cambios</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setPartnerSelectedId: ({ id }) => dispatch( setPartnerSelectedId({ id }) ),
    }
};

export default connect( null, mapDispatchToProps )( SaveChangesModal );
