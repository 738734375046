import React, { useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { closeErrorDialog, fetchBookingHistory, fetchBookingTotalArrives } from '../../actions';
import { changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';

// Components
import MyBookingsSection3 from '../../components/MyBookings/Section3/MyBookingsSection3';
// import ErrorDialog from '../../components/GeneralComponents/Modal/ErrorDialog';

// // Constants
// import { ModalMode } from '../../constants/modalMode';

const MyBookingsPage = (props) => {

    // Actions
    const {  
        // fetchStatistics, 
        changeTitleAppBar, 
        changeShowSidebar, 
        fetchHistory, 
    } = props;

    // States
    const {
        moreInfoFiltersSelected,
        bookingStatesArray,
        idPartnerSelected,
        countrySelected,
        originSelected,
        rowsPerPage,
        orderType,
        orderBy,
        loading,
        query,
        state,
        page,
        from,
        to
    } = props;

    useEffect(() => {
        changeTitleAppBar("Mis reservas");
        changeShowSidebar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ( idPartnerSelected ) {
            // fetchStatistics();
            fetchHistory();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ 
        moreInfoFiltersSelected,
        bookingStatesArray,
        idPartnerSelected, 
        countrySelected, 
        originSelected,
        rowsPerPage,
        orderType,
        orderBy, 
        state, 
        query, 
        from, 
        page, 
        to, 
    ]);

    return (
        <main>
            <MyBookingsSection3 loading={ loading }/>
            {/* <ErrorDialog 
                onAccept={ closeErrorDialog }
                type={ ModalMode.ERROR } 
                open={ error !== null } 
                body={ error ?? "" }
                title={ "Error" } 
            /> */}
        </main>
    )
};

const mapStateToProps = (state) => {
    return {
        moreInfoFiltersSelected: state.bookingHistoryReducer.moreInfoFiltersSelected,
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        bookingStatesArray: state.bookingHistoryReducer.bookingStatesArray,
        countrySelected: state.bookingHistoryReducer.countrySelected,
        originSelected: state.bookingHistoryReducer.originSelected,
        rowsPerPage: state.bookingHistoryReducer.rowsPerPage,
        orderType: state.bookingHistoryReducer.orderType,
        history: state.bookingHistoryReducer.history,
        orderBy: state.bookingHistoryReducer.orderBy,
        loading: state.bookingHistoryReducer.loading,
        query: state.bookingHistoryReducer.query,
        state: state.bookingHistoryReducer.state,
        error: state.bookingHistoryReducer.error,
        page: state.bookingHistoryReducer.page,
        from: state.bookingHistoryReducer.from,
        to: state.bookingHistoryReducer.to,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch( changeTitleAppBar(data) ),
        changeShowSidebar: (data) => dispatch( changeShowSidebar(data) ),
        fetchStatistics: () => dispatch( fetchBookingTotalArrives() ),
        closeErrorDialog: () => dispatch( closeErrorDialog() ),
        fetchHistory: () => dispatch( fetchBookingHistory() ),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBookingsPage);

