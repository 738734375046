import { getSectors } from "./sectorsFunctions";

export const SetWaitListToLayouts = (availableLayouts, waitList, setAvailableLayouts, sectorId) => {
    
    const newAvailableLayouts = availableLayouts.map(availableLayout => {
            switch (sectorId) {
                case 0:
                    return {
                        ...availableLayout,
                        activeWaitList: waitList
                    }
                
                case sectorId:
                    if (availableLayout.id === sectorId) {
                        return {
                            ...availableLayout,
                            activeWaitList: waitList
                        }
                    } else {
                        return availableLayout;
                    }
                default:
                    return availableLayout;
            }        
        })

    setAvailableLayouts(newAvailableLayouts);
}

export const SetWaitListToSubShiftBookableSectors = (subShifts, waitList, setSubshift, sectorId, subShiftId) => {
    const newSubShifts = subShifts.map(subShift => {
        if (subShift.id === subShiftId || subShiftId === 0) {
            const newBookableSectors = subShift.bookableSectors.map(bookableSector => {
                    if (bookableSector.id === sectorId || sectorId === 0) {
                        return {
                            ...bookableSector,
                            allowsWaitList: waitList,
                        }                    
                    } else {
                        return bookableSector;
                    }
                })
            return {
                ...subShift,
                bookableSectors: newBookableSectors,
            }
        } else {
            return subShift;
        }        
    })

    setSubshift(newSubShifts);
}

export const GetLayoutWaitList = (availableLayouts, reservableSectors) => {
    const newReservableSectors = [];

    reservableSectors.map((reservableSector) => {
        const layout = availableLayouts.find(availableLayout => availableLayout.id === reservableSector.id);

        if (reservableSector.tables.length > 0) {
            newReservableSectors.push({
                id: reservableSector.id,
                name: reservableSector.name,
                type: reservableSector.type,
                active: layout ? layout.activeWaitList : false,
            });
        }
    })

    return newReservableSectors;
}

export const GetLayoutSubShiftWaitList = (reservableSectors, layoutsWaitList, subShifts) => {
    
    const sectores = [];
    
    reservableSectors.map(reservableSector => {
        
        if (reservableSector.tables.length > 0) {

            const layout = layoutsWaitList.find((layoutsWait) => layoutsWait.id === reservableSector.id);

            if (layout.active) {
                const subShiftsWaitList = [];
                subShifts.map(subShift => {
                    let existe = false;
                    subShift.bookableSectors.map(bookableSector => {
                        if (bookableSector.id === reservableSector.id) {
                            if (!ExistSubshift(subShift.id, subShiftsWaitList)) {
                                subShiftsWaitList.push({
                                    id: subShift.id,
                                    enabled: bookableSector.allowsWaitList
                                })                                
                            }
                            existe = true;                            
                        } 
                    })

                    if (!existe) 
                        subShiftsWaitList.push(null);
                })

                sectores.push({
                    id: reservableSector.id,
                    name: reservableSector.name,
                    type: reservableSector.type,
                    tables: reservableSector.tables,
                    subShifts: subShiftsWaitList,
                });
            }

        }

    })

    return sectores;
}

export const ExistSubshift = (id, subshiftList) => {
    const exist = subshiftList.find((sub) => sub && sub.id === id);

    return typeof exist !== 'undefined';
}

export const SetWaitList = (availableLayouts, showOptions, setAvailableLayouts, subShifts, setSubshift) => {
    SetWaitListToLayouts(availableLayouts, showOptions, setAvailableLayouts, 0);
    SetWaitListToSubShiftBookableSectors(subShifts, showOptions, setSubshift, 0, 0);
}

export const GetReservableSectors = (reservableSectors, availableLayouts, subShifts, sectors, setReservableSectors) => {
    if (typeof reservableSectors !== 'undefined' && reservableSectors.length > 0) 
        return reservableSectors.filter((reservableSector) => reservableSector.available);
    else {
        const newReservableSectors = getSectors(availableLayouts, subShifts, sectors);
        setReservableSectors(newReservableSectors);
        return newReservableSectors.filter((reservableSector) => reservableSector.available);
    }
        
}