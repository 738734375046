export const paperDelete = {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    borderBottomRightRadius: 20,
    backgroundColor: '#FAFAFA',
    borderTopRightRadius: 20,
    justifyItems: 'right',
    paddingTop: '20px',
    marginTop: '-75px',
    paddingLeft: 44,
    height: '75px',
    width: '80px',
};

export const deleteIcon = {
    justifySelf: 'right',
    color: "#EB3030",
    padding: 5,
};

export const text = {
    fontSize: '10px',
    color: '#707070',
    margin: '0px',
};

export const Inputs = {
    backgroundColor: 'red !important',
};

export const ButtonsSection = {
    alignItems: 'center',
    marginRight: '-2rem',
    display: 'flex',
};

export const ChecksStyles = {
    '&.Mui-checked': {
        color: '#0B4762',
    }
};

export const RadioTextStyles = {
    fontFamily: 'Poppins, sans-serif', 
    fontSize: '.85rem', 
    color: '#283149',
    fontWeight: 500,
};