import {
    CHANGE_ADDRESS_FINANCES,
    CHANGE_BUSINESS_NAME_FINANCES,
    CHANGE_CITY_FINANCES,
    CHANGE_COUNTRY_FINANCES,
    CHANGE_CUIT_FINANCES,
    CHANGE_EMAIL_FINANCES,
    CHANGE_FANTASY_NAME_FINANCES,
    CHANGE_LAST_NAME_FINANCES,
    CHANGE_LEGAL_FINANCES,
    CHANGE_NAME_FINANCES,
    CHANGE_PHONE_FINANCES,
    CHANGE_PLAN_FINANCES,
    CHANGE_ZIP_CODE_FINANCES,
    EDIT_FINANCES_CANCEL,
    EDIT_FINANCES_ERROR,
    EDIT_FINANCES_SUCCESS,
    GENERAL_SETTINGS,
    NEXT_STEP_FINANCES,
    PARTNERS,
    PLANS,
    PREV_STEP_FINANCES,
    RESET_ALL_DATA,
    RESET_STEP_FINANCES,
    TOGGLE_FINANCES_LOADING,
} from "../../constants";
import {deepCopyObject} from "../../utils/arrayHelpers";

const initState = {
    name: "",
    last_name: "",
    cuit: "",
    city: "",
    address: "",
    email: "",
    phone: "",
    country: "Argentina",
    zip_code: "",
    businessName: "",
    fantasyName: "",
    legal: false,
    clientId: undefined,
    checkout_payment: "",
    paymentMethodId: undefined,
    availablesCountries: {},
    suscriptionId: "",
    newPlanId: "",
    planId: "",
    isActive: false,
    checkout: "",
    loading: false,
    error: "",

    activeStep: 0,
};

const editReducer = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === PARTNERS &&
                action.payload.ordered &&
                action.payload.ordered.length === 1
            ) {
                let data = action.payload.ordered[0];
                return {
                    ...state,
                    name:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["name"],
                    last_name:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["last_name"],
                    cuit:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["cuit"],
                    email:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["email"],
                    phone:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["phone"],
                    city:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["city"],
                    address:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["address"],
                    country:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["country"],
                    zip_code:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["zip_code"],
                    businessName: data["billing_info"] === undefined ? data["displayName"] : data["billing_info"]["businessName"],
                    fantasyName: data["billing_info"] === undefined ? data["displayName"] : data["billing_info"]["fantasyName"],
                    legal: data["billing_info"] === undefined ? false : data["billing_info"]["legal"],
                    clientId:
                        data["billing_info"] === undefined
                            ? undefined
                            : data["billing_info"]["clientId"],
                    checkout_payment:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["checkout_payment"],
                    paymentMethodId:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["paymentId"],
                    suscriptionId:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["suscriptionId"],
                    planId:
                        data["billing_info"] === undefined
                            ? ""
                            : data["billing_info"]["planId"],
                    isActive: data["isActive"],
                    checkout: data["billing_info"] === undefined
                        ? ""
                        : data["billing_info"]["checkout"],
                };
            }
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === GENERAL_SETTINGS
            ) {
                let generalData = action.payload.data["countries"];
                return {
                    ...state,
                    availablesCountries: deepCopyObject(generalData["countries"]),
                };
            }
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === PLANS
            ) {
                return {
                    ...state,
                };
            }
            return {...state};
        case EDIT_FINANCES_CANCEL:
            let data = action.payload;
            return {
                ...state,
                name: data["name"],
                last_name: data["last_name"],
                dni: data["dni"],
                city: data["city"],
                email: data["email"],
                address: data["address"],
                county: data["country"],
                zip_code: data["zip_code"],
            };
        case EDIT_FINANCES_SUCCESS:
            return {...state, loading: false, error: "", activeStep: 0};
        case EDIT_FINANCES_ERROR:
            return {...state, loading: false, error: action.payload};
        case TOGGLE_FINANCES_LOADING:
            return {...state, loading: !state.loading};
        case CHANGE_NAME_FINANCES:
            return {...state, name: action.payload};
        case CHANGE_LAST_NAME_FINANCES:
            return {...state, last_name: action.payload};
        case CHANGE_CUIT_FINANCES:
            return {...state, cuit: action.payload};
        case CHANGE_EMAIL_FINANCES:
            return {...state, email: action.payload};
        case CHANGE_PHONE_FINANCES:
            return {...state, phone: action.payload};
        case CHANGE_ADDRESS_FINANCES:
            return {...state, address: action.payload};
        case CHANGE_CITY_FINANCES:
            return {...state, city: action.payload};
        case CHANGE_COUNTRY_FINANCES:
            return {...state, country: action.payload};
        case CHANGE_ZIP_CODE_FINANCES:
            return {...state, zip_code: action.payload};
        case CHANGE_BUSINESS_NAME_FINANCES:
            return {...state, businessName: action.payload};
        case CHANGE_FANTASY_NAME_FINANCES:
            return {...state, fantasyName: action.payload};
        case CHANGE_LEGAL_FINANCES:
            return {...state, legal: action.payload};
        case CHANGE_PLAN_FINANCES:
            return {...state, newPlanId: action.payload};
        case RESET_STEP_FINANCES:
            return {...state, activeStep: 0}
        case NEXT_STEP_FINANCES:
            return {...state, activeStep: action.payload};
        case PREV_STEP_FINANCES:
            return {...state, activeStep: action.payload};
        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
};

export default editReducer;
