import React from 'react';

// Material UI
import {makeStyles} from "@material-ui/core/styles";

// Components
import SearchImgButton from "../NewComponents/Buttons/Search Image/SearchImgButton";

const useStyles = makeStyles(() => ({
    MainContainer: {
        border: '1px solid #DBD7DC',
        borderRadius: '10px',
        maxWidth: '98%'
    },
    SectionTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.4rem',
        fontWeight: '600',
        marginTop: '0'
    },
    ImagesContainer: {
        backgroundColor: 'transparent',
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: '50px',
        paddingLeft: '20px',
        width: '100%',
        '@media (max-width: 1920px)': {
            gap: '30px',
            paddingLeft: '30px'
        }
    },
    ButtonContainer: {
        marginTop: '1.2rem',
        marginLeft: '.8rem',
        marginBottom: '1.2rem',
    }
}));

const ImageSelectorContainer = (props) => {
    const classes = useStyles();

    const {title, style, canAdd} = props;

    const {addImage} = props;

    const handleOnClick = (e) => {
        if (canAdd) {
            addImage(e.target.files[0]);
        }
    }

    return (
        <div style={style}>
            <h1 className={classes.SectionTitle}>{title}</h1>
            <div className={classes.MainContainer}>
                <div className={classes.ButtonContainer}>
                    <SearchImgButton onClick={handleOnClick}/>
                </div>
                <div className={classes.ImagesContainer}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ImageSelectorContainer
