import React, { useState, useEffect, useRef } from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { changeExcludeDay, changeSpecialDay } from '../../../../../actions/shiftsActions';

// Components
import SuperDatePicker, { SuperDatePickerType } from './SuperDatePicker';
import AddDateDisplay from './AddDateDisplay';
import AddDateButton from './AddDateButton';

// Material Ui
import { Box } from '@material-ui/core';

// Utils
import { 
  GetAllSelectedDates, 
  HandleChangeDates, 
  getFormattedDate, 
  DeleteValue,

  // FormattedDate, 
  // compareDates,
  // DateExists, 
} from '../utils/ScheduleFunctions';
import ShiftSelectTypeDateMenu from './SelectTypeDateMenu';
import { DateButtonsContainerStyles, DatesContainerStyles } from '../../../../Plannig/Styles';

// const useGapStyles = makeStyles({
//     paper: {
//       marginTop: "1rem"
//     }
// });

function AddCalendarButton (props) {
  // const classes = useGapStyles();

  // Variables
  const {
    titleButton, componenteHijo, calendar, type,
  } = props;

  // Acciones
  const {
    changeSpecialDay,
    changeExcludeDay,
  } = props;

  const [selectedDates, setSelectedDates] = useState(type === 'agregar' ? calendar.specialDates : calendar.excludeDates);
      
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateValues, setDateValues] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [multiDateValues, setMultiDateValues] = useState([]);
  const [openMultiDate, setOpenMultiDate] = useState(false);
  const [rangeDateValues, setRangeDateValues] = useState([])
  const [openRangeDate, setOpenRangeDate] = useState(false);
  
  const datePickerRef = useRef();
  const multiDatePickerRef = useRef();
  const rangeDatePickerRef = useRef();

  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    setOpenDate(false);
    setOpenMultiDate(false);
    setOpenRangeDate(false);
    setAnchorEl(event.currentTarget);
  };  

  const openDateCalendar = () => {
    setAnchorEl(null);
    setOpenDate(true);
  };

  const openMultiDateCalendar = () => {
    setAnchorEl(null);
    setOpenMultiDate(true);
  };

  const openRangeDateCalendar = () => {
    setAnchorEl(null);
    setOpenRangeDate(true);
  };

  useEffect(() => {
    switch (type) {
      case 'agregar':
        HandleChangeDates( selectedDates, changeSpecialDay );
        break;
      
      case 'excluir':
        HandleChangeDates(selectedDates, changeExcludeDay);
        break;
    
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedDates ]);

  useEffect(() => {
    // AddSelectedDates();
  }, [dateValues, rangeDateValues, multiDateValues]);

  useEffect(() => {

    if (openMultiDate) {
      // multiDatePickerRef.current.openCalendar();
    }
  }, [openMultiDate, multiDatePickerRef]);

  const handleChangeMultiValues = (dates) => {

    const newSelectedDates = GetAllSelectedDates(dateValues, rangeDateValues, dates, [...selectedDates]);

    setMultiDateValues(dates);
    setSelectedDates(newSelectedDates);

  };

  const handleChangeValue = (date) => {

      const newSelectedDates = GetAllSelectedDates(date, rangeDateValues, multiDateValues, [...selectedDates]);

      setDateValues(date);
      setSelectedDates(newSelectedDates);

  };

  const handleChangeRangeValues = (rangeDateValues) => {

    const newSelectedDates = GetAllSelectedDates(dateValues, rangeDateValues, multiDateValues, [...selectedDates]);

    setRangeDateValues(rangeDateValues);
    setSelectedDates(newSelectedDates);

  };

  const handleDelete = (index) => {

    const deletValue = selectedDates[index];   

    const newSelectedDates = [...selectedDates];
    newSelectedDates.splice(index, 1);
    setSelectedDates(newSelectedDates);

    // Borrar en donde corresponda
    DeleteValue(deletValue, dateValues, multiDateValues, rangeDateValues, setDateValues, setMultiDateValues, setRangeDateValues);

  };

  // const AddSelectedDates = () => {
  //   console.log('selectedDates: ', selectedDates);
  //   const newSelectedDates = selectedDates;

  //   if (rangeDateValues.length === 2) {
  //     const range = rangeDateValues[0].toDate() + ' - ' + rangeDateValues[1].toDate();
  //     if (!newSelectedDates.includes(range))
  //         newSelectedDates.push(range);      
  //   }

  //   if (multiDateValues.length > 0) {
  //     multiDateValues.map((date) => {
  //       if (!DateExists(newSelectedDates, date.toDate()))
  //         console.log('entra 1');
  //         newSelectedDates.push(FormattedDate(date.toDate()));
  //     })
  //   }

  //   if (dateValues !== null) {
  //     if (!DateExists(newSelectedDates, dateValues.toDate()))
  //       console.log('entra 2');
  //       newSelectedDates.push(FormattedDate(dateValues.toDate()));
  //   }

  //   newSelectedDates.sort(compareDates);
  //   console.log('newSelectedDates: ', newSelectedDates);
  //   setSelectedDates(newSelectedDates);
    
  //   switch (type) {
  //     case 'agregar':
  //       HandleChangeDates(newSelectedDates, changeSpecialDay);
  //       break;
      
  //     case 'excluir':
  //       HandleChangeDates(newSelectedDates, changeExcludeDay);
  //       break;
    
  //     default:
  //       break;
  //   }    
  // };
  
  useEffect(() => {}, [titleButton, componenteHijo, calendar, type]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', '@media( min-width: 1350px)': { width: '68%' } }}>
      <TransitionGroup style={{ display: 'flex' }}>
        <Box sx={ DateButtonsContainerStyles }>
          <Box sx={ DatesContainerStyles }>     
            {/* <Popover
              classes={{ paper: classes.paper }}
              onClose={ handleClose }
              anchorEl={ anchorEl }
              anchorOrigin={{          
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={ open }
              id={ id }
            >
              <Typography sx={{ p: 1, cursor: 'pointer' }} onClick={() => openDateCalendar()}>Fecha única</Typography>
              <Typography sx={{ p: 1, cursor: 'pointer' }} onClick={() => openMultiDateCalendar()}>Fechas múltiples</Typography>
              <Typography sx={{ p: 1, cursor: 'pointer' }} onClick={() => openRangeDateCalendar()}>Rango</Typography>
            </Popover> */}

            <ShiftSelectTypeDateMenu
              openMultiDateCalendar={ openMultiDateCalendar } 
              openRangeDateCalendar={ openRangeDateCalendar } 
              openDateCalendar={ openDateCalendar } 
              handleClose={ handleClose }
              anchorEl={ anchorEl }
              open={ open }
            />

            <SuperDatePicker 
              closeDatePicker={() => setOpenDate(false)} 
              type={ SuperDatePickerType.BASIC } 
              setDateValue={ handleChangeValue } 
              reference={ datePickerRef } 
              value={ dateValues } 
              open={ openDate }  
            />

            <SuperDatePicker 
              closeDatePicker={() => setOpenMultiDate(false)} 
              setDateValue={ handleChangeMultiValues } 
              type={ SuperDatePickerType.MULTIPLE } 
              reference={ multiDatePickerRef } 
              value={ multiDateValues }  
              open={ openMultiDate }  
            />

            <SuperDatePicker 
              closeDatePicker={() => setOpenRangeDate(false)} 
              setDateValue={ handleChangeRangeValues } 
              type={ SuperDatePickerType.RANGE } 
              reference={ rangeDatePickerRef } 
              value={ rangeDateValues } 
              open={ openRangeDate }  
            />
            <Box sx={{ marginTop: '-.3rem' }}>
              <AddDateButton 
                componenteHijo={ componenteHijo }
                style={{ width: 'max-content' }}
                titleButton={ titleButton } 
                onClick={ handleOpenMenu } 
              /> 
            </Box>
          </Box>
          {
            selectedDates && selectedDates.map((date, index) => 
              <AddDateDisplay             
                formattedDate={getFormattedDate( date )}
                onDelete={() => handleDelete( index )}
                selectedDate={date } 
                key={ index } 
              />
          )} 
        </Box>
      </TransitionGroup>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
      calendar: state.v3.shifts.shiftData.calendar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      changeSpecialDay: (data) => dispatch(changeSpecialDay(data)),
      changeExcludeDay: (data) => dispatch(changeExcludeDay(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddCalendarButton);