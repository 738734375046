import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {red,} from '@material-ui/core/colors';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
} from '@material-ui/core';

import {deleteProduct, toggleDeleteProductDialog,} from '../../../actions';
import {connect} from 'react-redux';

function DeleteProductDialog(props) {
    const { deleteProductIndex, loading, error, deleteProduct, toggleDeleteProductDialog, } = props;

    const ColorLinearProgress = withStyles({
        colorPrimary: {
            backgroundColor: red[100],
        },
        barColorPrimary: {
            backgroundColor: red[700],
        },
    })(LinearProgress);

    return (
        <div>
            <Dialog
                open={deleteProductIndex !== null}
                onClose={() => toggleDeleteProductDialog(null, null)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{"¿Está seguro que desea eliminar el plato?"}</DialogTitle>
                <DialogContent>
                    {error ? <DialogContentText id="alert-dialog-error">Ha ocurrido un error al intentar eliminar el plato.</DialogContentText> : null}
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => toggleDeleteProductDialog(null, null)} color="primary" autoFocus>Cancelar</Button>
                    <Button disabled={loading} onClick={deleteProduct} color="secondary">Eliminar</Button>
                </DialogActions>
                {loading ? <ColorLinearProgress /> : null}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        deleteProductIndex: state.menuReducer.productsReducer.deleteReducer.deleteProductIndex,
        loading: state.menuReducer.productsReducer.deleteReducer.loading,
        error: state.menuReducer.productsReducer.deleteReducer.error,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteProduct: () => dispatch(deleteProduct()),
        toggleDeleteProductDialog: (category, index) => dispatch(toggleDeleteProductDialog(category, index)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProductDialog);
