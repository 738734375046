import React from 'react';

// Icon

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import LightTooltip from "../GeneralComponents/LightTooltip";
import {InfoOutlined} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    CardContainer: {
        padding: '1rem 1rem 1rem 2rem',
        backgroundColor: 'white',
        borderRadius: '12px',
    },
    TopCards: {
        justifyItems: 'center',
        display: 'flex',
        gap: '10px'
    },
    Cardtitle: {
        margin: '.5rem 0rem',
        fontWeight: '500',
        color: '#041C32',
    },
    Data: {
        fontWeight: '600',
        margin: '.5rem 0',
        fontSize: '3rem',
    }
}));

const CardsHistory = (props) => {

    const { text, data, color, BoxShadowColor, tootlTipText } = props;
    const classes = useStyles();

    return (
        <div className={classes.CardContainer} style={{boxShadow: BoxShadowColor}}>
            <div className={classes.TopCards}>
                <h3 className={classes.Cardtitle}>{text}</h3>
                <LightTooltip
                    placement='right'
                    title={tootlTipText}
                >
                    <InfoOutlined style={{color: '#041C32', fontSize: '16px', margin: '.7rem 0rem .5rem 0'}}/>
                </LightTooltip>
            </div>
            <h1 className={classes.Data} style={{color: color}}>{data}</h1>
        </div>
    )
}

export default CardsHistory;
