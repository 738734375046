const CREATED = 0;
const EDITED_BY_CLIENT = 1;
const EDITED_BY_PARTNER = 2;
const ACCEPTED = 3;
const CALLED = 4;
const DELETED_BY_USER = 5;
const DELETED_BY_PARTNER = 6;
const REJECTED = 7;
const ARRIVED = 8;
const FINISHED = 9;
const EXPIRED = 10;

const getNameQueueItemState = (state) => {
    switch (state) {
        case CREATED:
            return "Creado";
        case EDITED_BY_CLIENT:
            return "Editado";
        case EDITED_BY_PARTNER:
            return "Editado";
        case ACCEPTED:
            return "Aceptado";
        case CALLED:
            return "Llamado";
        case DELETED_BY_USER:
            return "Cancelado";
        case DELETED_BY_PARTNER:
            return "Eliminado";
        case REJECTED:
            return "Rechazado";
        case ARRIVED:
            return "Ingresado";
        case FINISHED:
            return "Finalizado";
        case EXPIRED:
            return "Expirado";
        default:
            return "";

    }
}

module.exports = {
    CREATED,
    EDITED_BY_CLIENT,
    EDITED_BY_PARTNER,
    ACCEPTED,
    CALLED,
    DELETED_BY_USER,
    DELETED_BY_PARTNER,
    REJECTED,
    ARRIVED,
    FINISHED,
    EXPIRED,
    getNameQueueItemState
};