// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material';

const drawerWidth = 280;

const openedMixin = ( theme ) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: drawerWidth,
});

const closedMixin = ( theme ) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 9px)`,
    },
    width: `calc(${theme.spacing(6)} + 9px)`,
    overflowX: 'hidden',
});

export const AppBarStyled = styled( MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isActive }) => ({
    width: open ? `calc(100% - ${drawerWidth}px)` : 'calc(100% - 56px)',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: !isActive ? '5rem' : 0,
    zIndex: theme.zIndex.drawer,
    backgroundColor: 'white',
    height: '4rem',
    ...(open && {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Drawer = styled( MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isActive }) => ({
        border: open ? 'none' : '1px solid #E7E7E7',
        justifyContent: 'center',
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        display: 'flex',
        flexShrink: 0,
        ...( open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...( !open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '& .MuiPaper-root': {
            paddingTop: !isActive ? '5rem' : 0
        }
    }),
);

export const DrawerHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const useStyles = makeStyles(( theme ) => ({
    root: {
        width: 'auto',
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 36,
        color: '#0B4762',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        whiteSpace: 'nowrap',
        paddingTop: '1rem',
        flexShrink: 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: '15px'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(5) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
        paddingTop: '15px'
    },
    toolbar: {
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    menuButtons: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontFamily: 'Poppins',
        textAlign: 'left',
        fontWeight: '600',
    },
    subtitle: {
        fontFamily: 'Poppins',
        marginLeft: '20px',
        textAlign: 'left',
        fontWeight: '600',
        color: '#0B4762',
        flexGrow: 1,
    },
    items: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '20px',
    },
    arrow: {
        paddingTop: '18px'
    },
    ItemsContainer: {
        height: '45px'
    },
    main: {
        // height: '90vh',
        margin: '6rem 0rem 0rem 6rem',
        width: 'calc( 100% - 8rem )',
        backgroundColor: '#FCFCFC',
    }
}));