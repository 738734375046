import {RESET_ALL_DATA, TOGGLE_NEW_PRODUCT_DIALOG, TOGGLE_NEW_PRODUCT_LOADING,} from '../../../constants';

const initState = {
    newProductDialog: false,
    loading: false,
    error: '',
}

const createReducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_NEW_PRODUCT_DIALOG:
            return { ...state, newProductDialog: !state.newProductDialog, };
        case TOGGLE_NEW_PRODUCT_LOADING:
            return { ...state, loading: !state.loading, };
        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default createReducer;
