import React from 'react';

// Icons
import { ClockIcon, TableIcon } from '../../../../SvgComponents/icons';
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { Box, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { GetHHMMWithText } from '../utils/functions';

const AdvancedOptionsExceptionsItem = ({props}) => {

    return (
        <>
            <Stack
                sx={{ 
                    padding: '.1rem .3rem .1rem .5rem',
                    border: '1px solid #0B4762',
                    alignItems: 'center',
                    width: 'max-content',
                    marginBottom: '16px',
                    borderRadius: '10px',
                }}
                direction="row"
                spacing={1}
            >
                <Typography
                    fontSize="15px"
                    color="#0B4762"
                    fontWeight="500"
                    fontFamily="Poppins"
                >
                    { props.layoutName }
                </Typography>
                <Box borderLeft="1px solid #D1D1D1" height="17.85px"></Box>
                <TableIcon height="16.76px" width="18px" />
                <Typography
                    fontSize="15px"
                    color="#0B4762"
                    fontWeight="500"
                    fontFamily="Poppins"
                >
                    { props.tableName }
                </Typography>
                <Box borderLeft="1px solid #D1D1D1" height="17.85px"></Box>
                <ClockIcon fill="#0B4762" width="18px" />
                <Typography
                    fontSize="15px"
                    color="#0B4762"
                    fontWeight="500"
                    fontFamily="Poppins"
                >
                    { GetHHMMWithText(props.time) }
                </Typography>
                <IconButton sx={{ p: '4px' }} >
                    <CloseIcon sx={{ fontSize: '1rem', color: '#0B4762' }}/>
                </IconButton>
            </Stack>
        </>
    )
}

export default AdvancedOptionsExceptionsItem;