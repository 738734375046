import {
  Box,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { startCreatingLayout } from "../../../../actions/V3";
import { connect } from "react-redux";

const AddLayoutCard = (props) => {
  const { startCreatingLayout } = props;

  const { sectorId } = props;

  const handleClick = () => {
    startCreatingLayout(sectorId);
  };
  return (
    <Card
      sx={{
        width: "90vw",
        maxWidth: 350,
        height: 155,
        borderRadius: "10px",
        color: "#818181",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      variant="outlined"
      onClick={handleClick}
    >
      <CardHeader
        title={
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <AddCircleOutlineIcon sx={{ fontSize: "2rem" }} />
            <Typography variant="h6" fontWeight={500} fontFamily={"Poppins"}>
              Agregar Layout
            </Typography>
          </Box>
        }
      />
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startCreatingLayout: (sectorId) => dispatch(startCreatingLayout(sectorId)),
  }
}

export default connect(null, mapDispatchToProps)(AddLayoutCard);