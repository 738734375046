import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    IconContainerAppBar: {
        boxShadow: '0.842593px 0.842593px 3.37037px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        alignContent: 'center',
        marginRight: '.3rem',
        borderRadius: '8px',
        padding: '.5rem',
        display: 'flex',
    }
}));

const IconSectionAppBar = (props) => {

    const classes = useStyles();

    const { icon } = props;

    return (
        <div className={classes.IconContainerAppBar}>
            { icon }
        </div>
    )
}

export default IconSectionAppBar;