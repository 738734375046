import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Divider, Grid, List, ListItem, ListItemText,} from '@material-ui/core';
import {switchNewPasswordDialog,} from '../../actions';
import {connect} from 'react-redux';
import EditUserDialog from '../../components/EditForms/EditUser';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";

const styles = {
    root: {
        marginTop: '20px',
    },
    textField: {
    },
    shippingCostContainer: {
        display: 'flex',
        marginTop: '10px',
        alignItems: 'center',
    },
    saveButton: {
        display: 'flex',
        marginLeft: '10px',
        height: '50px',
        background: '#4caf50',
        color: '#fff',
        '&:hover': {
            background: '#357a38',
        },
    },
    shippingCostUpdated: {
        width: '100%',
        color: '#6fbf73',
        fontSize: '0.9em',
    },
};


class UserAccountPage extends Component {
    render() {
        const { classes, switchNewPasswordDialog, changeTitleAppBar} = this.props;

        changeTitleAppBar("Ajustes")
        this.props.changeShowSidebar(true);

        return (
            <>
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Divider />
                        <List component="nav">
                            <ListItem
                                button
                                onClick={() => switchNewPasswordDialog()}
                                aria-haspopup="true"
                                aria-label={"Cambiar contraseña"}
                            >
                                <ListItemText primary={"Cambiar contraseña"} secondary={"Cambiar contraseña de usuario actual"} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <EditUserDialog />
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        switchNewPasswordDialog: () => dispatch(switchNewPasswordDialog()),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserAccountPage));
