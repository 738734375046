import React, { useEffect } from "react";

// Components
import { Drawer, DrawerHeader } from "./StyledComponents";
import ProfilesComponent from "./ProfilesComponent";
import LogoutDrawerItem from "./LogoutDrawerItem";
import CollapseItem from "./CollapseItem";
import SingleItem from "./SingleItem";

// Material Ui
import {
  ClickAwayListener,
  IconButton,
  Typography,
  debounce,
  Divider,
  List,
} from "@mui/material";

// Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";

// Styles
import { DrawerHeadStyles, MenuNameSector, PartnerNameStyles } from "../Styles";

const DrawerComponent = (props) => {
  // External props
  const {
    handleOpenDrawer,
    openSidebar,
    handleClose,
    drawerItems,
    isActive,
    onClick,
    profile,
  } = props;

  const handleChangeDrawer = () => {
    if ( openSidebar ) {
      handleClose();
    } else {
      handleOpenDrawer();
    }
  };

  const handleClickAway = () => {
    if ( openSidebar ) {
      handleClose();
    } else {
      return;
    }
  };

  const handleMouseEnter = debounce(
    () => (!openSidebar ? handleClose() : null),
    150
  );

  const handleMouseLeave = () => {
    handleMouseEnter.clear();
    if ( openSidebar ) {
      handleClose();
    }
  };

  const [partnerProfiles, setPartnerProfiles] = React.useState({
    name: "Woki",
    partners: [],
  });

  useEffect(() => {
    if ( profile.partners?.length > 1 ) {
      setPartnerProfiles({
        ...profile,
        partners: profile.partners.sort((a, b) => a.name.localeCompare(b.name)),
      });
    } else {
      setPartnerProfiles({
        name: profile.name,
        email: profile.email,
        partners: [
          {
            name: profile.name,
            role:
              profile.role && profile.role.length > 0
                ? profile.role[0]
                : "admin",
            uuid: profile.partner,
          },
        ],
      });
    }
  }, [ profile ]);

  return (
    <ClickAwayListener onClickAway={ () => handleClickAway() }>
      <Drawer
        onMouseLeave={ handleMouseLeave }
        onMouseOver={ handleMouseEnter }
        isActive={ isActive }
        open={ openSidebar }
        variant="permanent"
      >
        <DrawerHeader sx={ DrawerHeadStyles }>
          <IconButton
            sx={{ margin: openSidebar ? 0 : "auto" }}
            onClick={ handleChangeDrawer }
          >
            { openSidebar ? <ChevronLeftIcon /> : <MenuIcon /> }
          </IconButton>
          {
            openSidebar ? (
                <Typography sx={ PartnerNameStyles }>
                  { profile.name }
                </Typography>
              ) 
            : 
              null
          }
        </DrawerHeader>
        { openSidebar ? <Divider /> : null }
        <List
          sx={{
            flexDirection: "flex-start",
            p: "0 .5rem 0rem .5rem",
            overflowX: "hidden",
            overflowY: "auto",
            height: "100%",
            mb: "4.5rem",
          }}
        >
          <Typography sx={ MenuNameSector }>
            { openSidebar ? "MENÚ" : "" }
          </Typography>
          {
            drawerItems.map((item) =>
              item.subItems ? (
                <CollapseItem
                  onClickItem={(path) => onClick(path)}
                  enableOption={item.enableOption}
                  iconPrimary={item.iconPrimary}
                  subItems={item.subItems}
                  onClose={handleClose}
                  title={item.title}
                  icon={item.icon}
                  key={item.title}
                />
              ) : (
                <SingleItem
                  onClickItem={() => onClick(item.path)}
                  iconPrimary={item.iconPrimary}
                  title={item.title}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                />
              )
          )}
          { openSidebar ? <Divider sx={{ m: "1rem 0" }} /> : null }
          { 
            partnerProfiles?.partners &&
              partnerProfiles.partners.length > 1 &&
                openSidebar ? (
                    <ProfilesComponent
                      profiles={partnerProfiles.partners}
                      openSidebar={openSidebar}
                    />
                  ) 
                : 
                  null 
          }
        </List>
        <LogoutDrawerItem />
      </Drawer>
    </ClickAwayListener>
  );
};

export default DrawerComponent;
