import React from 'react'

const BookingQuantityIcon = () => {
    return (
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2223 19.25V17.2222C17.2223 16.1466 16.795 15.115 16.0345 14.3545C15.2739 13.5939 14.2423 13.1666 13.1667 13.1666H5.05558C3.97997 13.1666 2.94842 13.5939 2.18785 14.3545C1.42728 15.115 1 16.1466 1 17.2222V19.25" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.11124 1C8.03564 1 7.00408 1.42728 6.24352 2.18785C5.48295 2.94842 5.05566 3.97997 5.05566 5.05558C5.05566 6.13118 5.48295 7.16274 6.24352 7.9233C7.00408 8.68387 8.03564 9.11116 9.11124 9.11116C10.1868 9.11116 11.2184 8.68387 11.979 7.9233C12.7395 7.16274 13.1668 6.13118 13.1668 5.05558C13.1668 3.97997 12.7395 2.94842 11.979 2.18785C11.2184 1.42728 10.1868 1 9.11124 1Z" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BookingQuantityIcon