export const MyBookingsIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5417 4.16675H4.45833C3.0316 4.16675 1.875 5.32335 1.875 6.75008V24.8334C1.875 26.2601 3.0316 27.4167 4.45833 27.4167H22.5417C23.9684 27.4167 25.125 26.2601 25.125 24.8334V6.75008C25.125 5.32335 23.9684 4.16675 22.5417 4.16675Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.6665 1.58325V6.74992" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.3335 1.58325V6.74992" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.875 11.9167H25.125" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
