import { v4 as uuidv4 } from "uuid";
import axios from 'axios';

// Constants
import { 
    OPTIONAL_REQUIRE_RESPONSE, 
    OPTIONAL_SET_CLIENT_TEXT, 
    REMOVE_MULTIPLE_OPTIONAL,
    REMOVE_ONECLICK_OPTIONAL, 
    EDIT_ONECLICK_OPTIONAL,
    EDIT_MULTIPLE_OPTIONAL,
    ADD_MULTIPLE_OPTIONAL,
    ADD_ONECLICK_OPTIONAL,
    RESTART_OPTIONAL_FORM,
    GET_OPTIONAL_SUCCESS,
    CHANGE_OPTIONAL_MODE,
    START_GET_OPTIONALS,
    OPTIONAL_VIEW_MODE,
    OPTIONAL_SET_NAME, 
    OPTIONAL_SET_TYPE,
    DELETE_OPTIONAL,
    SAVE_OPTIONAL,
    EDIT_OPTIONAL,
    SET_OPTIONAL_ID_EDIT,
    SET_OPTIONAL_ARRAY_SELECTED,
} from '../../constants/optionalConstants';

// Helpers
import { saveOptionSelectedName } from "../../components/Optional/helpers";

export const setOptionalViewMode = ( data ) => ({
    type: OPTIONAL_VIEW_MODE,
    payload: data
});

export const setRequiredResponse = ( data ) => ({
    type: OPTIONAL_REQUIRE_RESPONSE,
    payload: data
});

export const setOptionalName = ( data ) => ({
    type: OPTIONAL_SET_NAME,
    payload: data
});

export const setOptionalType = ( data ) => ({
    type: OPTIONAL_SET_TYPE,
    payload: data
});

export const setClientText = ( data ) => ({
    type: OPTIONAL_SET_CLIENT_TEXT,
    payload: data
});

export const setNewOneClickOptional = () => ({
    type: ADD_ONECLICK_OPTIONAL
});

export const setNewMultipleClicksOptional = () => ({
    type: ADD_MULTIPLE_OPTIONAL
});

export const setRemoveOneClickOptional = ({ id }) => ({
    type: REMOVE_ONECLICK_OPTIONAL,
    payload: {
        id
    }
});

export const setRemoveMultipleClicksOptional = ({ id }) => ({
    type: REMOVE_MULTIPLE_OPTIONAL,
    payload: {
        id
    }
});

export const setEditOneClickOptional = ({ id, newText }) => ({
    type: EDIT_ONECLICK_OPTIONAL,
    payload: {
        id,
        newText
    }
});

export const setEditMultipleClicksOptional = ({ id, newText }) => ({
    type: EDIT_MULTIPLE_OPTIONAL,
    payload: {
        id,
        newText
    }
});

export const setSaveOptional = ( data ) => ({
    type: SAVE_OPTIONAL,
    payload: data
});

export const setRestartOptionalForm = () => ({
    type: RESTART_OPTIONAL_FORM
});

export const changeOptionalMode = ({ mode, optional }) => ({
    type: CHANGE_OPTIONAL_MODE,
    payload: {
        mode, 
        optional
    }
});

export const setDeleteOptional = ({ id }) => ({
    type: DELETE_OPTIONAL,
    payload: {
        id
    }
});

export const setEditOptional = ({ id }) => ({
    type: EDIT_OPTIONAL,
    payload: {
        id
    }
});

export const setArrayOptionalSelected = ( data ) => ({
    type: SET_OPTIONAL_ARRAY_SELECTED,
    payload: data
});

export const startGetOptionals = () => ({
    type: START_GET_OPTIONALS
});

export const getOptionalsSuccess = ( data ) => ({
    type: GET_OPTIONAL_SUCCESS,
    payload: data
});

export const getAllOptionals = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        dispatch( startGetOptionals() );

        try {

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken( true );

            const res = await axios.get(`${ API_URL }/booking/optionals/${ idPartnerSelected }`, {
                headers: { Authorization: `Bearer ${ token }` }
            });

            const data = res.data;

            dispatch( getOptionalsSuccess( data ) );

        } catch ( error ) {
            console.log( error );
        }
    }
};

export const setOptionalIdEdit = ( data ) => ({
    type: SET_OPTIONAL_ID_EDIT,
    payload: data
});

export const getOptionalById = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { optionalReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { optionalIdEdit } = optionalReducer;

        dispatch( startGetOptionals() );

        try {

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            const res = await axios.get(`${ API_URL }/booking/optionals/${ idPartnerSelected }/${ optionalIdEdit }`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const data = res.data;

            dispatch( setEditOptional(data) );
        } catch ( error ) {
            console.log( error );
        }
    }
}

export const addNewOptional = () => {
    return async (dispatch, getState, { getFirebase }) => {
        const { optionalReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { 
            arrayOptionalSelected,
            requiredResponse,
            optionSelected,
            optionalName,
            clientText 
        } = optionalReducer;

        dispatch( startGetOptionals() );

        try {

            if (!idPartnerSelected) {
                throw new Error('Partner not found');
            }

            const optionValueSelected = saveOptionSelectedName(optionSelected);

            console.log( 'Array new --> ', arrayOptionalSelected );

            const newOptional = {
                id: uuidv4(),
                type: optionValueSelected,
                name: optionalName,
                required: requiredResponse,
                question: clientText,
                values: arrayOptionalSelected
            };

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            await axios.put(`${API_URL}/booking/optionals/${ idPartnerSelected }`, newOptional, { headers: { Authorization: `Bearer ${token}` } });

        } catch (e) {
            console.log( 'Error --> ', e );
        }
    }
};

export const editOptional = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { optionalReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { 
            arrayOptionalSelected,
            requiredResponse,
            optionSelected,
            optionalIdEdit,
            optionalName,
            clientText,
        } = optionalReducer;

        const optionValueSelected = saveOptionSelectedName(optionSelected);

        try {
            
            if (!idPartnerSelected) {
                throw new Error('Partner not found');
            };

            console.log( 'Array --> ', arrayOptionalSelected );

            const updateOptional = {
                id: optionalIdEdit,
                type: optionValueSelected,
                name: optionalName,
                required: requiredResponse,
                question: clientText,
                values: arrayOptionalSelected
            };

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            await axios.patch(`${API_URL}/booking/optionals/${ idPartnerSelected }`, updateOptional, { headers: { Authorization: `Bearer ${token}` } });

        } catch (error) {
            console.log( error );
        }
    }
}

export const deleteOptional = ( id ) => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            
            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.delete(`${ API_URL }/booking/optionals/${ idPartnerSelected }/${ id }`, { headers: { Authorization: `Bearer ${token}` } })

            if (res.status > 300) {
                throw new Error(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
}
