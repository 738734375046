export const SetReservableSectors = ( reservableSectors, subShiftId, sector, enabled, setReservableSectors ) => {

    const nextReservableSectors = reservableSectors.map( reservableSector => {

        if ( reservableSector.id === sector.id ) {

            const newSubShift = reservableSector.subShifts.map(( subShift ) => {
                if ( subShift.id === subShiftId ) {
                    return SetEnabled( subShift, enabled );                
                } else {
                    return subShift;
                }
            });
            
            return {
                ...reservableSector,
                subShifts: newSubShift,
            };
        } else {
            return reservableSector;              
        };
    });

    setReservableSectors( nextReservableSectors );
};

export const RemoveBookableSubshift = (
    reservableSectors, 
    subShifts, 
    subShiftId, 
    sector, 
    addExludeTable, 
    setSubshift,
    type,
    setDisabledAllLayout
) => {
    let newSubShifts;
    if ( type !== 'Group' ) {

        newSubShifts = subShifts.map((subshift) => {
            if ( subshift.id === subShiftId ) { 
                if ( sector.tables.length === 0 ) {
                    return SetExcludeTables( subshift, sector.id, addExludeTable );
                } else {
                    return RemoveBookable( reservableSectors, subshift, sector.id )
                }        
            } else {
                return subshift;
            }
        });

        if ( type !== 'Mesa' ) {
            setDisabledAllLayout({ 
                layoutType: sector.name,
                subShiftId: subShiftId 
            });
        } else {
            setDisabledAllLayout({ 
                layoutType: sector.name,
                subShiftId: subShiftId 
            });
        };
    
    } else {

        newSubShifts = subShifts.map((subshift) => {
            if ( subshift.id === subShiftId ) {       
                return RemoveBookable( reservableSectors, subshift, sector.id );
            } else {
                return subshift;
            }
        });
    };


    for (let i = 0; i < newSubShifts.length; i++) {
        if ( newSubShifts[i] !== undefined ) {
            setSubshift( newSubShifts );
        };
    };
};

export const SetNewBookableSubshift = ( subShifts, subShiftId, sector, addExludeTable, setSubshift, type ) => {
    const newSubShifts = subShifts.map((subshift) => {
        if ( type === 'Group' ) {  
            return SetBookable( subshift, sector.id );
        } else { 
            if ( subshift.id === subShiftId ) {
                // Es Mesa
                if ( sector.tables.length === 0 ) {
                    return SetExcludeTables( subshift, sector.id, addExludeTable );
                } else{
                    return SetBookable( subshift, sector.id );
                }          
            } else {
                return subshift;
            };
        }
    });

    newSubShifts['resetGroups'] = false;

    setSubshift( newSubShifts );
};  

const SetEnabled = (subShift, enabled) => {
    return {
        ...subShift,
        enabled: !enabled
    }; 
};

const SetExcludeTables = ( subshift, sectorId, addExludeTable ) => {

    let newExcludeTables = [];

    // Incluir en exclude
    if ( addExludeTable ) {       
        if (subshift.excludeTables.length > 0) {
            newExcludeTables = subshift.excludeTables.concat(sectorId.toString());
        } else {
            newExcludeTables = newExcludeTables.concat(sectorId.toString());
        }
    
    } else {
        // Quitar de exclude
        // eslint-disable-next-line array-callback-return
        subshift.excludeTables.map((excludeTable) => {
            if (excludeTable.toString() !== sectorId.toString()) {
                newExcludeTables.push(excludeTable.toString());
            }
        });
    };

    return {
        ...subshift,
        excludeTables: newExcludeTables ? newExcludeTables : [],
    }; 

};

const RemoveBookable = (reservableSectors, subshift, sectorId) => {
    const newBookableSectors = [];
            
    // eslint-disable-next-line array-callback-return
    subshift.bookableSectors.map((bookableSector) => {
        if ( bookableSector.id !== sectorId ) {
            newBookableSectors.push(bookableSector);
        }; 
    });

    // Revisar los extraTables
    const extraTables = GetReservableTableSubshiftSector(reservableSectors, subshift.id, sectorId);
    
    return {
        ...subshift,
        bookableSectors: newBookableSectors,
        extraTables: subshift.extraTables.concat(extraTables),
    };
};

const GetReservableTableSubshiftSector = (reservableSectors, subShiftId, sectorId) => {
    const extraTables = [];
    const layoutTables = reservableSectors.find(reservableSector => reservableSector.id === sectorId).tables;
    const reservableSectorTables = reservableSectors.filter(reservableSector => reservableSector.tables.length === 0);

    // eslint-disable-next-line array-callback-return
    reservableSectorTables.map(reservableSectorTable => {
        const layoutReservableTable = layoutTables.find( layoutTable => layoutTable.id === reservableSectorTable.id );
        const subfhiftExist = reservableSectorTable.subShifts.find( subShift => subShift.id === subShiftId );

        if ( layoutReservableTable && subfhiftExist && subfhiftExist.enabled ) {
            console.log( reservableSectorTable.id.toString() );
            extraTables.push( reservableSectorTable.id.toString() );
        }
        
    });

    return extraTables;
};  

const SetBookable = ( subshift, sectorId ) => {
    const newBookableSectors = subshift.bookableSectors;

    newBookableSectors.push({
        id: sectorId,
        allowsWaitList: false,
        excludedGroup: [],
    })
    
    return {
        ...subshift,
        bookableSectors: newBookableSectors,
    };
};