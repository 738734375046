import { Snackbar, Typography } from "@mui/material";
import React from "react";
import { hideNotification } from "../../actions/notistackActions";
import { connect } from "react-redux";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Notistack(props) {
  const { open, message, hideNotification, severity } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    hideNotification();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      onClose={hideNotification}
      autoHideDuration={3000}
      sx={{ background: "#0b4762", borderRadius: "8px"}}
    >
      <Alert
        icon={false}
        severity={severity}
        onClose={handleClose}
        sx={{ background: "#0b4762", padding: "0.5em 2em 0.5em 1em", minWidth: '30em' }}
      >
        <Typography
          variant="body1"
          fontFamily={"Poppins"}
          fontWeight={600}
          fontSize={"0.9rem"}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.notistackReducer.open,
    message: state.notistackReducer.message,
    severity: state.notistackReducer.severity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideNotification: () => dispatch(hideNotification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notistack);
