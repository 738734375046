import React from 'react';

const ArrowDownPrimary = (props) => {
    return (
        <div style={{transform: 'rotate(180deg)', cursor: props.cursor}}>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 20.06 10.621"
                 transform={props.transform} stroke={props.stroke}>
                <path id="Trazado_324" data-name="Trazado 324" d="M7,0,0,6.717l7,6.106"
                      transform="translate(14.94 1.5) rotate(90)" fill="none" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="3"/>
            </svg>
        </div>
    )
}

ArrowDownPrimary.defaultProps = {
    stroke: "#0076ff",
    cursor: 'pointer',
    transform: "translate(10.94 5)"
}

export default ArrowDownPrimary
