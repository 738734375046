import {
    ADD_EDITED_IMAGE,
    ADD_NEW_PRODUCT,
    CHANGE_EDIT_PRODUCT_DAYS,
    CHANGE_EDIT_PRODUCT_DESCRIPTION,
    CHANGE_EDIT_PRODUCT_NAME,
    CHANGE_EDIT_PRODUCT_PRICE,
    CHANGE_EDIT_PRODUCT_VISIBILITY,
    CHANGE_SELECTED_CATEGORY,
    PARTNERS,
    TOGGLE_DELETE_PRODUCT_DIALOG,
    TOGGLE_DELETE_PRODUCT_LOADING,
    TOGGLE_EDIT_PRICES_DIALOG,
    TOGGLE_EDIT_PRODUCT_LOADING,
    TOGGLE_NEW_PRODUCT_DIALOG,
    TOGGLE_NEW_PRODUCT_LOADING,
} from "../../constants";
import {deepCopy, deepCopyObject} from "../../utils/arrayHelpers";

export const toggleNewProductDialog = () => ({
  type: TOGGLE_NEW_PRODUCT_DIALOG,
});
export const toggleNewProductLoading = () => ({
  type: TOGGLE_NEW_PRODUCT_LOADING,
});

export const toggleEditPricesDialog = () => ({
  type: TOGGLE_EDIT_PRICES_DIALOG,
});

export const createProduct = (product) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebaseReact = getFirebase();
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    const { name, description, category, price, image, days } = product;

    dispatch(toggleNewProductLoading());
    // Se crea el producto en la base de de datos
    let newProduct = {
      name: name,
      description: description,
      days: days,
      visibility: true,
    };
    if (price) newProduct["price"] = price;

    if (image) {
      // Se sube la imagen
      const newImage = new File([image], image.name, { type: image.type });
      const storageRef = firebaseReact.storage().ref();
      const imageRef = storageRef.child(
        "menuImages/" + idPartnerSelected + "/" + category + "/" + image.name
      );
      const snap = await imageRef.put(newImage);
      newProduct["imageUrl"] = await snap.ref.getDownloadURL();
    }

    await firestoreReact
      .collection(PARTNERS)
      .doc( idPartnerSelected )
      .collection("menu")
      .doc(category)
      .update({
        products: firebaseReact.firestore.FieldValue.arrayUnion(newProduct),
      });

    dispatch(toggleNewProductLoading());
    dispatch(toggleNewProductDialog());
  };
};

export const changeSelectedCategory = (data) => ({
  type: CHANGE_SELECTED_CATEGORY,
  payload: data,
});

// EDIT ----------------------------------------------------------------------------------------------------
export const toggleEditProductLoading = () => ({
  type: TOGGLE_EDIT_PRODUCT_LOADING,
});

export const changeEditProductName = (categoryId, productIndex, data) => ({
  type: CHANGE_EDIT_PRODUCT_NAME,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const changeEditProductDescription = (
  categoryId,
  productIndex,
  data
) => ({
  type: CHANGE_EDIT_PRODUCT_DESCRIPTION,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const changeEditProductPrice = (categoryId, productIndex, data) => ({
  type: CHANGE_EDIT_PRODUCT_PRICE,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const changeEditProductVisibility = (
  categoryId,
  productIndex,
  data
) => ({
  type: CHANGE_EDIT_PRODUCT_VISIBILITY,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const changeEditProductDays = (categoryId, productIndex, data) => ({
  type: CHANGE_EDIT_PRODUCT_DAYS,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const addEditedImage = (categoryId, productIndex, data) => ({
  type: ADD_EDITED_IMAGE,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
    data: data,
  },
});

export const addNewProduct = (categoryId) => ({
  type: ADD_NEW_PRODUCT,
  payload: categoryId
})

export const editProducts = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebaseReact = getFirebase();
    const firestoreReact = getFirestore();
    const { menuReducer, userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    const {
      categories,
      changesInCategory,
    } = menuReducer.productsReducer.editReducer;
    dispatch(toggleEditProductLoading());

    for (let i = 0; i < changesInCategory.length; i++) {
      if (changesInCategory[i]) {
        // Se edita la categoria
        let products = categories[i].products ? deepCopy(categories[i].products.filter(product => !isProductEmpty(product))) : [];
        for (let j = 0; j < products.length; j++) {
          if (products[j].newImg) {
            // Se copia la url vieja
            let oldUrl = products[j].imageUrl;
            // Se sube la imagen
            const newImage = new File(
              [products[j].newImg],
              products[j].newImg.name,
              { type: products[j].newImg.type }
            );
            const storageRef = firebaseReact.storage().ref();
            const imageRef = storageRef.child(
              "menuImages/" +
                idPartnerSelected +
                "/" +
                categories[i].id +
                "/" +
                products[j].newImg.name
            );
            const snap = await imageRef.put(newImage);
            // Se obtiene y configura la URL
            products[j].imageUrl = await snap.ref.getDownloadURL();
            // Se elimina la vieja
            try {
              if (oldUrl) {
                const imageRef = firebaseReact.storage().refFromURL(oldUrl);
                await imageRef.delete();
              }
            }
            catch (e) {

            }

            // Se quita el campo newImg
            delete products[j].newImg;
          }
          products[j].price = isNaN(Number(products[j].price)) ? null : Number(products[j].price)
        }
        await firestoreReact
          .collection(PARTNERS)
          .doc( idPartnerSelected )
          .collection("menu")
          .doc(categories[i].id)
          .update({
            products: products,
          });
      }
    }

    dispatch(toggleEditProductLoading());
  };
};

export const changeEditProductOrder = (categoryId, products) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleEditProductLoading());

    try {
      await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .collection("menu")
        .doc(categoryId)
        .update({
          products: products,
        }, {merge: true});
    } catch (error) {
      console.error(error);
    }

    dispatch(toggleEditProductLoading());
  };
};

export const changeEditAllPrice = (categoriesId, porcentaje) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { menuReducer, userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    const { categories } = menuReducer.productsReducer.editReducer;

    dispatch(toggleEditProductLoading());

    try {
      let res = categoriesId.map(async (categoryId) => {
        let category = deepCopyObject(
          categories.find((category) => category.id === categoryId)
        );

        category.products.forEach((product) => {
          const newPrice = Math.ceil(product.price * porcentaje)
          if (!isNaN(newPrice))
            product.price = newPrice;
        });

        await firestoreReact
          .collection(PARTNERS)
          .doc( idPartnerSelected )
          .collection("menu")
          .doc(categoryId)
          .update({
            products: category.products,
          });
      });

      await Promise.all(res);
    } catch (error) {
      console.error(error);
    }

    dispatch(toggleEditProductLoading());
  };
};

const isProductEmpty = (product) => {
  return product.name === ''
}

// DELETE ----------------------------------------------------------------------------------------------------
export const toggleDeleteProductDialog = (categoryId, productIndex) => ({
  type: TOGGLE_DELETE_PRODUCT_DIALOG,
  payload: {
    categoryId: categoryId,
    productIndex: productIndex,
  },
});
export const toggleDeleteProductLoading = () => ({
  type: TOGGLE_DELETE_PRODUCT_LOADING,
});

export const deleteProduct = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebaseReact = getFirebase();
    const firestoreReact = getFirestore();
    const { menuReducer, userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    const { categories } = menuReducer.productsReducer.editReducer;
    const {
      deleteProductCategory,
      deleteProductIndex,
    } = menuReducer.productsReducer.deleteReducer;

    dispatch(toggleDeleteProductLoading());

    // Eliminar
    let products = deepCopy(
      categories.find((category) => category.id === deleteProductCategory)
        .products
    );
    let imageUrl = products[deleteProductIndex].imageUrl;
    products.splice(deleteProductCategory, 1);
    // products = products.filter((val, index) => index !== deleteProductIndex || !isProductEmpty(val));

    // Eliminar producto de la base de datos
    await firestoreReact
      .collection(PARTNERS)
      .doc( idPartnerSelected )
      .collection("menu")
      .doc(deleteProductCategory)
      .update({
        products: products,
      });
    // Eliminar imagen
    if (imageUrl) {
      try {
        const imageRef = firebaseReact.storage().refFromURL(imageUrl);
        await imageRef.delete();
      }
      catch (e) {
        console.error("No se pudo eliminar la imagen: ", e);
      }

    }
    dispatch(toggleDeleteProductLoading());
    dispatch(toggleDeleteProductDialog(null, null));
  };
};
