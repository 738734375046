import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

// Component
import {BlueSwitch} from "./blueSwitch";
import TimePicker from './TimePicker';

// Icons
import TrashPlanningIcon from '../SvgComponents/icons/Planning/TrashPlanningIcon';
import EditIcon from '../SvgComponents/icons/EditIcon/EditIcon';
import SaveIcon from '../SvgComponents/icons/SaveIcon/SaveIcon';

// Material Ui
// import { Button } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { IconButton } from '@mui/material';


const useStyles = makeStyles(() => ({
    root: {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        alignContent: 'center',
        position: 'relative',
        alignItems: 'center',
        borderRadius: '8px',
        display: 'flex',
        width: '970px',
        height: '60px',
        zIndex: '3',
    },
    NameTimeSelector: {
        fontFamily: 'Poppins !important',
        fontSize: '1.1rem',
        textAlign: 'left',
        fontWeight: '500',
        width: '15%',
    },
    TimePickerContainer: {
        borderLeft: '2px solid #D0D0D0',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '-10px',
        paddingLeft: '20px',
        maxHeight: '35px',
        display: 'flex',
        width: '20%',
    },
    TimePickerContainerP: {
        fontWeight: '500'
    },
    DaysContainer: {
        borderLeft: '2px solid #D0D0D0',
        alignItems: 'center',
        paddingLeft: '1rem',
        marginLeft: '-10px',
        display: 'flex',
        height: '35px',
    },
    DayContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        margin: '0 7px',
        color: 'white',
        height: '25px',
        width: '25px',
    },
    IconsContainerTimeSelector: {
        paddingLeft: '.5rem',
        display: 'flex',
    },
    paperDelete: {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderBottomRightRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: 'white',
        justifyItems: 'right',
        marginTop: '-55px',
        paddingTop: '5px',
        paddingLeft: 35,
        height: '45px',
        width: '70px',
    },
    deleteIcon: {
        justifySelf: 'right',
        color: "#EB3030",
        padding: 5,
    },
}));

const TimeSelector = (props) => {

    const classes = useStyles();

    const {
        from,
        intervalName,
        enable,
        initTime,
        endTime,
        days,
        check,
        editionEnable,
        editing,
    } = props;

    const {
        onEnable,
        onClickEdit,
        onEditCompleted,
        onDelete
    } = props;

    const [openTime, setOpenTime] = useState(initTime.startsWith("24") ? initTime.replace("24", "00") : initTime);
    const [closeTime, setCloseTime] = useState(endTime.startsWith("24") ? endTime.replace("24", "00") : endTime);

    const [openDays, setOpenDays] = useState(days);

    useEffect(() => {
        setOpenTime(initTime.startsWith("24") ? initTime.replace("24", "00") : initTime);
        setCloseTime(endTime.startsWith("24") ? endTime.replace("24", "00") : endTime);
        setOpenDays(days);
    }, [initTime, endTime, days, check])

    const onChangeDays = (day) => {
        const cloneOpenDay = [...openDays];
        cloneOpenDay[day] = !cloneOpenDay[day];
        setOpenDays(cloneOpenDay);
    }

    const onChangeOpenTime = (value) => {
        if (value.length > 0) {
            setOpenTime(value)
        }
    }

    const onChangeCloseTime = (value) => {
        if (value.length > 0) {
            setCloseTime(value)
        }
    }

    const getLetterDay = (day) => {
        switch (day) {
            case 0:
                return 'D'
            case 1:
                return 'L'
            case 2:
                return 'M'
            case 3:
                return 'M'
            case 4:
                return 'J'
            case 5:
                return 'V'
            case 6:
                return 'S'
            default:
                return;
        }
    };

    return (
        <div key={from + "-" + intervalName} style={{marginBottom: '10px'}}>
            <div className={classes.root} style={{border: editing ? '1.37949px solid #0B4762' : '1.37949px solid #BFBBBD'}}>
                <div style={{marginTop: '-.2rem'}}>
                    <BlueSwitch
                        checked={enable}
                        onChange={() => editionEnable ? onEnable() : null}
                        color="default"
                    />
                </div>
                <h3 
                    className={classes.NameTimeSelector} style={{color: (editing || enable) ? '#041C32' : '#BFBBBD'}}>
                    { intervalName }
                </h3>
                <div className={classes.TimePickerContainer}>
                    <p className={classes.TimePickerContainerP} style={{color: editing ? '#041C32' : '#BFBBBD' }}>Desde</p>
                    <div style={{width: '75%'}}>
                        <TimePicker
                            value={openTime}
                            onChangeValue={onChangeOpenTime}
                            editable={editing}
                            disable={!editing}
                            dontChangeDisableColor={enable}
                            together
                        />
                    </div>
                </div>
                <div className={classes.TimePickerContainer}>
                    <p className={classes.TimePickerContainerP} style={{color: editing ? '#041C32' : '#BFBBBD' }}>Hasta</p>
                    <div style={{width: '75%'}}>
                        <TimePicker
                            value={closeTime}
                            onChangeValue={onChangeCloseTime}
                            editable={editing}
                            disable={!editing}
                            dontChangeDisableColor={enable}
                            together
                        />
                    </div>
                </div>
                <div style={{borderLeft: '2px solid #D0D0D0', width: '2px'}}/>
                <div className={classes.DaysContainer}>
                    {openDays?.map((day, index) =>
                        <div
                            key={`letra-${index}`}
                            onClick={() => editing ? onChangeDays(index) : null}
                            className={classes.DayContainer}
                            style={{
                                backgroundColor: enable ? day ? '#0B4762' : '#AFAFAF' : day ? '#848282' : '#AFAFAF',
                                cursor: editing ? 'pointer' : 'default',
                                fontFamily: 'Poppins, sans-serif',
                                userSelect: 'none',
                                fontWeight: '600'
                            }}
                        >
                            {
                                getLetterDay(index)
                            }
                        </div>
                    )}
                </div>
                <div
                    className={classes.IconsContainerTimeSelector}
                    style={{margin: editing ? 'auto' : 'auto .5rem auto auto'}}
                >
                    {editing ?
                        <div>
                            <SaveIcon
                                height={'2rem'}
                                paddingTop={'.3rem'}
                                onClick={() => editing ? onEditCompleted({
                                    openTime: openTime,
                                    closeTime: closeTime,
                                    days: openDays,
                                    enable: enable,
                                    checked: false
                                }): null}
                            />
                        </div>
                    :
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <EditIcon
                                onClick={() => enable ? editing ? onEditCompleted({
                                    openTime: openTime,
                                    closeTime: closeTime,
                                    days: openDays,
                                    enable: enable,
                                    checked: false
                                }) : onClickEdit() : null}
                                height={'1.5rem'}
                            />
                            <IconButton onClick={onDelete}>
                                <TrashPlanningIcon
                                    // onClick={onDelete}
                                    height={'1.5rem'}
                                />
                            </IconButton>
                        </div>
                    }
                    {/* <Button
                        onClick={() => enable ? editing ? onEditCompleted({
                            openTime: openTime,
                            closeTime: closeTime,
                            days: openDays,
                            enable: enable,
                            checked: false
                        }) : onClickEdit() : null}
                        style={{
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                            color: editing ? '#15D615' : '#848282',
                            padding: '0px 0px 2px 0px',
                            textTransform: 'none',
                            borderRadius: '15px',
                            marginLeft: '15px',
                        }}
                    >{editing ? 'Listo!' : 'Editar'}</Button> */}
                </div>
            </div>
            {/* <div style={{marginLeft: '86%', marginTop: '10px'}}>
                <div className={classes.paperDelete} style={{display: editing ? 'block' : 'none'}}>
                    <IconButton className={classes.deleteIcon} onClick={onDelete}>
                        <Delete width={"12px"} height={"12px"}/>
                    </IconButton>
                </div>
            </div> */}
        </div>
    )
}

TimeSelector.propTypes = {
    initTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
}

export default TimeSelector;
