export const FETCH_SHIFTS = "FETCH_SHIFTS";
export const FETCH_SHIFTS_SUCCESS = "FETCH_SHIFTS_SUCCESS";
export const FETCH_SHIFTS_FAILURE = "FETCH_SHIFTS_FAILURE";

export const FETCH_BOOKING_SETTINGS = "FETCH_BOOKING_SETTINGS";
export const FETCH_BOOKING_SETTINGS_SUCCESS = "FETCH_BOOKING_SETTINGS_SUCCESS";
export const FETCH_BOOKING_SETTINGS_FAILURE = "FETCH_BOOKING_SETTINGS_FAILURE";

export const FETCH_BOOKING_ALL_SECTORS = "FETCH_BOOKING_ALL_SECTORS";
export const FETCH_BOOKING_ALL_SECTORS_SUCCESS = "FETCH_BOOKING_ALL_SECTORS_SUCCESS";
export const FETCH_BOOKING_ALL_SECTORS_FAILURE = "FETCH_BOOKING_ALL_SECTORS_FAILURE";

export const RELOAD_BOOKINGS_SUCCESS = "RELOAD_BOOKINGS_SUCCESS";
export const CLOSE_BOOKING_ERROR = "CLOSE_BOOKING_ERROR";
export const CHANGE_SHIFT_MODE = "CHANGE_SHIFT_MODE";

export const TOGGLE_SHIFT_LOADING = "TOGGLE_SHIFT_LOADING";
export const SAVING_SHIFT_SUCCESS = "SAVING_SHIFT_SUCCESS";
export const SAVING_SHIFT_FAILURE = "SAVING_SHIFT_FAILURE";

export const DELETING_SHIFT_SUCCESS = "DELETING_SHIFT_SUCCESS";
export const DELETING_SHIFT_FAILURE = "DELETING_SHIFT_FAILURE";

// Edicion turno
export const CHANGE_RESERVAS_SHIFT_NAME = "CHANGE_RESERVAS_SHIFT_NAME";
export const CHANGE_RESERVAS_SHIFT_OPENTIME = "CHANGE_RESERVAS_SHIFT_OPENTIME";
export const CHANGE_RESERVAS_SHIFT_ADVANCE_START = "CHANGE_RESERVAS_SHIFT_ADVANCE_START";
export const CHANGE_RESERVAS_SHIFT_ADVANCE_END = "CHANGE_RESERVAS_SHIFT_ADVANCE_END";
export const CHANGE_RESERVAS_SHIFT_DAYS = "CHANGE_RESERVAS_SHIFT_DAYS";
export const CHANGE_RESERVAS_SHIFT_START_DATE = "CHANGE_RESERVAS_SHIFT_START_DATE";
export const CHANGE_RESERVAS_SHIFT_END_DATE = "CHANGE_RESERVAS_SHIFT_END_DATE";

// Edicion subturno especifico
export const ADD_SUB_SHIFT_TO_SHIFT = "ADD_SUB_SHIFT_TO_SHIFT";
export const REMOVE_SUB_SHIFT_TO_SHIFT = "REMOVE_SUB_SHIFT_TO_SHIFT";
export const CHANGE_SUB_SHIFT = "CHANGE_SUB_SHIFT";

// Edicion sectores de turno
export const ADD_SECTOR_TO_SHIFT = "ADD_SECTOR_TO_SHIFT";
export const REMOVE_SECTOR_FROM_SHIFT = "REMOVE_SECTOR_FROM_SHIFT";
export const CHANGE_SECTOR_ON_SHIFT = "CHANGE_SECTOR_ON_SHIFT";


// Manejo borrado turno
export const START_DELETING_SHIFT = "START_DELETING_SHIFT";
export const STOP_DELETING_SHIFT = "STOP_DELETING_SHIFT";
export const ERROR_DELETING_SHIFT = "ERROR_DELETING_SHIFT";
export const TOGGLE_DELETING_SHIFT_LOADING = "TOGGLE_DELETING_SHIFT_LOADING";


// CONFIGURACION GENERAL
export const CHANGE_AUTO_ACCEPTANCE_PERCENTAGE = "CHANGE_AUTO_ACCEPTANCE_PERCENTAGE";
export const CHANGE_WAIT_LIST_QUANTITY = "CHANGE_WAIT_LIST_QUANTITY";
export const CHANGE_WAIT_LIST_COMMENT = "CHANGE_WAIT_LIST_COMMENT";
export const CHANGE_BOOKING_NOTIFICATION = "CHANGE_BOOKING_NOTIFICATION";
export const CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING = "CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING";
export const EDIT_GENERAL_SHIFT_CANCEL = "EDIT_GENERAL_SHIFT_CANCEL";
export const CHANGE_SHIFT_TOLERANCE = "CHANGE_SHIFT_TOLERANCE";
export const CHANGE_SHIFT_CONFIRMATION_TOLERANCE = "CHANGE_SHIFT_CONFIRMATION_TOLERANCE";
export const CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE = "CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE";


// HORARIOS TOMA DE RESERVAS

export const ADD_BOOKING_HOUR_INTERVAL = "ADD_BOOKING_HOUR_INTERVAL";
export const REMOVE_BOOKING_HOUR_INTERVAL = "REMOVE_BOOKING_HOUR_INTERVAL";
export const EDIT_BOOKING_HOUR_INTERVAL = "EDIT_BOOKING_HOUR_INTERVAL";
export const EDIT_BOOKING_HOUR_INTERVAL_COMPLETE = "EDIT_BOOKING_HOUR_INTERVAL_COMPLETE";
export const EDIT_BOOKING_HOUR_INTERVAL_ENABLE = "EDIT_BOOKING_HOUR_INTERVAL_ENABLE";


export const ShiftMode = {
    VIEW: "view",
    EDIT: "edit",
    CREATE: "create",
    DELETE: "delete",
    DISABLE: "disable",
}
