import { CLOSE_NOTIFICATION, DISPLAY_NOTIFICATION } from "../../constants";

export const displayNotification = (message) => ({
    type: DISPLAY_NOTIFICATION,
    payload: message,
});

export const hideNotification = () => ({
    type: CLOSE_NOTIFICATION,
});

export const displayNotificationWithTimeout = (message, time = 3000) => {
    return (dispatch) => {
        dispatch(displayNotification(message));
        setTimeout(() => {
            dispatch(hideNotification());
        }, time);
    };
}
