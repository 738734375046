import React from 'react';
import {Alert} from "@material-ui/lab";
import AlertIcon from "../SvgComponents/icons/AlertIcon";
import {Snackbar} from "@material-ui/core";

const CustomSnakbar = (props) => {

    const {open, onClose, message, duration} = props;

    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={onClose}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            style={{marginBottom: '45px'}}
        >
            <Alert
                icon={
                    <div style={{marginTop: '5px'}}>
                        <AlertIcon width='30' height="30"/>
                    </div>
                }
                style={{
                    width: '460px',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#EB252E',
                    backgroundColor: '#F6D0D0',
                    borderRadius: '10px',
                }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnakbar;
