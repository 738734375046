import React from "react";

const BoltIcon = () => {
  return (
    <svg
      style={{ paddingTop: "3px" }}
      width="20px"
      viewBox="0 0 24 24"
      fill="#041C32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.64984 2.05957L2.53884 9.05957H5.98584C6.67584 9.05957 7.16184 9.73457 6.94384 10.3896L5.28784 15.3566L12.5858 8.05957H10.0158C9.84367 8.05953 9.67437 8.01548 9.52401 7.93161C9.37365 7.84773 9.24723 7.7268 9.15675 7.58032C9.06628 7.43383 9.01475 7.26665 9.00707 7.09465C8.99938 6.92265 9.03579 6.75154 9.11284 6.59757L11.3818 2.05957H5.64984ZM4.08384 0.65957C4.24584 0.29457 4.60684 0.0595703 5.00684 0.0595703H12.9838C13.7338 0.0595703 14.2228 0.84957 13.8868 1.52157L11.6178 6.05957H14.9758C15.8758 6.05957 16.3258 7.14757 15.6898 7.78357L3.73684 19.7366C2.98284 20.4906 1.72684 19.7146 2.06484 18.7036L4.61284 11.0596H1.01484C0.846589 11.0596 0.681002 11.0175 0.533121 10.9373C0.38524 10.857 0.259752 10.7411 0.168055 10.6C0.0763581 10.4589 0.0213569 10.2972 0.00804778 10.1295C-0.00526138 9.96173 0.0235431 9.79334 0.0918448 9.63957L4.08384 0.65957Z" />
    </svg>
  );
};

export default BoltIcon;
