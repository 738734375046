import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Icons
import FiltersButtonIcon from '../../../../SvgComponents/Icons Tables/MyBookings/FiltersButtonIcon';

// Material Ui
import { Badge, Button } from '@mui/material';

// Styles
import { BadgeSelectStyles } from '../Table/Style/Section3TableStyles';
import { deepEqual } from '../../../../../utils/objectHelpers';
import { deepCopyObject } from '../../../../../utils/arrayHelpers';

const ButtonStyles = {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '10px',
    padding: '.5rem',
    color: '#2A2742',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: 'white !important',
        border: '1px solid #0B4762',
    },
    '@media(min-width: 1500px)': {
        padding: '.5rem 1rem',
    }
}

const FilterButton = ( props ) => {

    // States
    const { filterCounter } = props;

    // External props
    const { handleClick } = props;

    return (
        <Badge 
            badgeContent={ filterCounter } 
            sx={ BadgeSelectStyles }
            onClick={ handleClick }
        >
            <Button sx={ ButtonStyles } variant="outlined" startIcon={<FiltersButtonIcon />}>
                Filtros
            </Button>
        </Badge>
    )
}

const mapStateToProps = (state) => {
    return {
        filterSelected: state.myBookingsReducer.filterSelected,
        filterCounter: state.myBookingsReducer.filterCounter,
    }
}

export default connect( mapStateToProps, null )( FilterButton );