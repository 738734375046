import React, {useEffect} from "react";
import {connect} from "react-redux";

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from "../../../actions/sidebarActions";
import {closeError, resetAllData, saveSector, sectorNewTable} from "../../../actions";

// Components
import SaveChangesButton from "../../NewComponents/Buttons/Save Changes/SaveChangesButton";
import ProgressIndicator from "../../GeneralComponents/ProgressIndicator";
import SectorConfigurationForm from "./SectorConfigurationForm";
import DraggableMenu from "./DraggableMenu";
import DeleteDialog from "../DeleteDialog";
import GridTable from "./GridTable";

// Material Ui
import {Grid, Snackbar} from "@material-ui/core";
import ErrorDialog from "../../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../../constants/modalMode";
import html2canvas from "html2canvas";
import {Alert} from "@material-ui/lab";
import AlertIcon from "../../SvgComponents/icons/AlertIcon";
import CustomSnakbar from "../../GeneralComponents/CustomSnakbar";

const SectorForm = (props) => {
    // Variables
    const {
        edited,
        loading,
        combineMode,
        error,
        combinationAlreadyExists
    } = props;
    // Actions
    const {
        setNewTable,
        saveSector,
        closeError,
    } = props;

    const [showDeleteSnackbar, setShowDeleteSnackbar] = React.useState(false);

    useEffect(() => {
        props.changeTitleAppBar("Configuración del sector");
        props.changePrimaryTitleAppBar("Configuración  de las mesas de tu establecimiento");
        props.changeShowSidebar(true);
    }, [props]);

    const handleOnSave = () => {
        html2canvas(document.querySelector('#sector-grid-layout')).then(canvas => {
            canvas.toBlob(async blob => {
                await saveSector(blob);
                return true;
            });
        });
    }

    const handleOnCloseSnackbar = () => {
        setShowDeleteSnackbar(false);
    }

    return (
        <>
            <SectorConfigurationForm/>
            <Grid
                style={{
                    marginTop: 1,
                    padding: '0em 2em 0em 1em',
                }}
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12} md={3} xl={2}>
                    <DraggableMenu
                        changeNewTable={setNewTable}
                    />
                </Grid>
                <Grid item xs={12} md={9} xl={10}>
                    <GridTable showSnackbar={() => setShowDeleteSnackbar(true)}/>
                </Grid>
            </Grid>
            {edited && !combineMode ? <SaveChangesButton onClick={handleOnSave}/> : null}
            <DeleteDialog/>
            <ErrorDialog
                open={error !== ""}
                type={ModalMode.ERROR}
                title="¡Atención!"
                body={error}
                onAccept={closeError}
            />
            <CustomSnakbar
                open={showDeleteSnackbar}
                duration={5000}
                onClose={handleOnCloseSnackbar}
                message={"Tené en cuenta que al eliminar una mesa puede que alguna reserva quede sin mesa asignada"}/>
            <Snackbar
                open={combinationAlreadyExists}
                autoHideDuration={5000}
                onClose={closeError}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                style={{marginBottom: '45px'}}
            >
                <Alert
                    icon={
                        <div style={{marginTop: '5px'}}>
                            <AlertIcon width='30' height="30"/>
                        </div>
                    }
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontSize: '14px',
                        color: '#EB252E',
                        backgroundColor: '#F6D0D0',
                        borderRadius: '10px',
                        alignItems: 'center',
                        paddingRight: '20px',
                    }}>
                    {combinationAlreadyExists}
                </Alert>
            </Snackbar>

            <ProgressIndicator open={loading}/>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        edited: state.sectorsReducer.sectors.edited,
        mode: state.sectorsReducer.sectors.mode,
        combineMode: state.sectorsReducer.sectors.combineMode,
        loading: state.sectorsReducer.sectors.loading,
        error: state.sectorsReducer.sectors.error,
        combinationAlreadyExists: state.sectorsReducer.sectors.combinationAlreadyExists,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setNewTable: ({minCapacity, maxCapacity, h, w, isResizable, circle}) => dispatch(sectorNewTable({
            minCapacity,
            maxCapacity,
            h,
            w,
            isResizable,
            circle
        })),

        saveSector: (data) => dispatch(saveSector(data)),
        closeError: () => dispatch(closeError()),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        resetAllData: () => dispatch(resetAllData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorForm);

