import {
    CHANGE_STATISTICS_COUNTRY_SELECTED,
    CHANGE_STATISTICS_ROWS_PER_PAGE,
    MORE_INFO_FILTERS_SELECTED,
    EXPORT_STATISTICS_SUCCESS,
    FETCH_STATISTICS_SUCCESS,
    CHANGE_STATISTICS_ORDER,
    CHANGE_STATISTICS_QUERY,
    EXPORT_STATISTICS_ERROR,
    START_EXPORT_STATISTICS,
    CHANGE_STATISTICS_PAGE,
    FETCH_STATISTICS_ERROR,
    START_FETCH_STATISTICS,
    CHANGE_STATISTICS_DATE,
    CLOSE_ERROR,
} from "../../constants/clientsStatisticsConstants";

import fileDownload from "js-file-download";
import axios from "axios";

export const startFetchStatistics = () => ({
    type: START_FETCH_STATISTICS
});

export const fetchStatisticsSuccess = (payload) => ({
    type: FETCH_STATISTICS_SUCCESS,
    payload: payload
});

export const fetchStatisticsError = (payload) => ({
    type: FETCH_STATISTICS_ERROR,
    payload: payload
});

export const closeStatisticsError = () => ({
    type: CLOSE_ERROR
});

export const changePage = (page) => ({
    type: CHANGE_STATISTICS_PAGE,
    payload: page
});

export const changeRowsPerPage = (rowsPerPage) => ({
    type: CHANGE_STATISTICS_ROWS_PER_PAGE,
    payload: rowsPerPage
});

export const changeOrder = ({orderBy, orderType}) => ({
    type: CHANGE_STATISTICS_ORDER,
    payload: {orderBy, orderType}
});

export const changeQuery = (query) => ({
    type: CHANGE_STATISTICS_QUERY,
    payload: query
});

export const startExportStatistics = () => ({
    type: START_EXPORT_STATISTICS
});

export const exportStatisticsSuccess = () => ({
    type: EXPORT_STATISTICS_SUCCESS
});

export const exportStatisticsFailure = (error) => ({
    type: EXPORT_STATISTICS_ERROR,
    payload: error,
});

export const fetchClientsStatitics = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { clientsStatisticsReducer, userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;
        const {
            dateRangeSelected,
            moreInfoFilters,
            countrySelected, 
            rowsPerPage, 
            orderType, 
            orderBy, 
            query, 
            page, 
        } = clientsStatisticsReducer;

        dispatch(startFetchStatistics())

        try {
            const params = {
                partner: idPartnerSelected,
                orderType: orderType,
                limit: rowsPerPage,
                orderBy: orderBy,
                page: page,
            };

            if (query !== undefined && query.trim() !== '') {
                params.query = query;
            };

            if ( countrySelected !== undefined && countrySelected.trim() !== '' ) {
                params.country = countrySelected;
            };

            if ( dateRangeSelected[0].startDate !== undefined && dateRangeSelected[0].endDate !== undefined ) {
                params.from = dateRangeSelected[0].startDate;
                params.to = dateRangeSelected[0].endDate;
            };

            if ( moreInfoFilters !== undefined ) {
                params.tags = moreInfoFilters[0].value;
                params.note = moreInfoFilters[1].value;
                params.relationship = moreInfoFilters[2].value;
                params.restriction = moreInfoFilters[3].value;
            };

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            const res = await axios.get(`${API_URL}/statistics/partnerClient`, {
                headers: { Authorization: `Bearer ${token}` },
                params: params
            });
            const data = res.data;

            dispatch(fetchStatisticsSuccess(data));

        } catch (error) {
            dispatch(fetchStatisticsError(error.message));
        }
    }
}


export const fetchStatistics = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { clientsStatisticsReducer, userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;
        const { page, rowsPerPage, orderBy, orderType, query } = clientsStatisticsReducer;

        dispatch(startFetchStatistics())
        try {
            const params = {
                partner: idPartnerSelected,
                page: page,
                limit: rowsPerPage,
                orderBy: orderBy,
                orderType: orderType,
            }

            if (query !== undefined && query.trim() !== '') {
                params.query = query;
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/client`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchStatisticsSuccess(data))
        } catch (e) {
            dispatch(fetchStatisticsError(e.message))
        }
    }
};

export const fetchFileExport = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { firebase, userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;
        const { profile } = firebase;

        dispatch(startExportStatistics());
        
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/client/export/${idPartnerSelected}`, {
                headers: {Authorization: `Bearer ${token}`},
                responseType: 'blob'
            });
            if (res.status === 200) {
                fileDownload(res.data, `Clientes ${profile.name}.xlsx`);
                dispatch(exportStatisticsSuccess());
            } else {
                dispatch(exportStatisticsFailure("Ha ocurrido un error"));
            }
        } catch (e) {
            dispatch(exportStatisticsFailure(e.message))
        }
    };
};

export const setCountrySelected = ( data ) => ({
    type: CHANGE_STATISTICS_COUNTRY_SELECTED,
    payload: data,
});

export const setClientsPeriodSelected = ( data ) => ({
    type: CHANGE_STATISTICS_DATE,
    payload: data,
});

export const setMoreInfoFilters = ( data ) => ({
    type: MORE_INFO_FILTERS_SELECTED,
    payload: data
});
