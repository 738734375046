import React from 'react';

import { DateRangePicker, defaultStaticRanges, defaultInputRanges } from 'react-date-range';
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-date-range/dist/styles.css'; // main css file
import * as locales from 'react-date-range/dist/locale';

import {
    startOfMonth,
    startOfYear,
    startOfDay,
    isSameDay,
    addMonths,
    endOfDay,
    addDays,
} from 'date-fns';
import { useMediaQuery } from '@mui/material';

const defaultRanges = [
    { ...defaultStaticRanges[0], label: 'Hoy', period: 'today' },
    { ...defaultStaticRanges[1], label: 'Ayer', period: 'yesterday' },
    {
        label: 'Últimos 7 días',
        period: 'week',
        range: () => ({
            startDate: startOfDay(addDays(new Date(), -7)),
            endDate: endOfDay(new Date()),
        }),
        isSelected( dateRange ) {
            const definedRange = this.range();
            return (
                isSameDay( dateRange.startDate, definedRange.startDate ) && isSameDay( dateRange.endDate, definedRange.endDate )
            );
        },
    },
    {
        label: 'Este mes',
        period: 'month',
    
        range: () => ({
            startDate: startOfMonth( addMonths(new Date(), 0) ),
            endDate: endOfDay( new Date() ),
        }),
        isSelected( dateRange ) {
            const definedRange = this.range();
            return (
                isSameDay( dateRange.startDate, definedRange.startDate ) && isSameDay( dateRange.endDate, definedRange.endDate )
            );
        },
    },
    {
        label: 'Este año',
        period: 'year',
        range: () => ({
            startDate: startOfYear( new Date() ),
            endDate: endOfDay( new Date() ),
        }),
        isSelected( dateRange ) {
            const definedRange = this.range();
            return (
                isSameDay( dateRange.startDate, definedRange.startDate ) && isSameDay( dateRange.endDate, definedRange.endDate )
            );
        },
    }
];

const DateRangePickerComponent = ({ dateRange, setDateRange, setSelectedPeriod }) => {

    const media = useMediaQuery('(min-width: 1500px)');

    const findSelectedPeriod = (defaultRanges, dateRange) => {
        const foundPeriod = defaultRanges.find((defaultRange) => {
            return defaultRange.isSelected(dateRange[0]);
        });

        return foundPeriod?.period;
    };

    const handleDateChange = (item) => {
        setDateRange([item.selection]);
        const period = findSelectedPeriod(defaultRanges, [item.selection]);
        setSelectedPeriod(period);
    };

    return (
        <DateRangePicker
            onChange={(item) => handleDateChange(item)}
            className='DateRangeStyles'
            staticRanges={ defaultRanges }
            rangeColors={['#2A2742']}
            locale={ locales['es'] }
            months={ media ? 2 : 1 }
            direction="horizontal"
            showSelectionPreview
            ranges={ dateRange }
            editableDateInputs
            inputRanges={[
                {
                    ...defaultInputRanges[0],
                    label: 'días hasta hoy',
                },
                {
                    ...defaultInputRanges[1],
                    label: 'días a partir de hoy',
                },
            ]}
        />
    )
}

export default DateRangePickerComponent;