import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { ShiftSimpleSelectStyles } from '../../../../Styles';
import { Box } from '@mui/system';

const ShiftSelectWithLabel = ({ error = false, optionsArray, disabled, handleChange, value, label }) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                marginTop: '.2rem',
                maxWidth: '10rem',
                minWidth: '10rem',
            },
        },
    };

    return (
        <FormControl fullWidth error={ error } sx={{ width: 'max-content', minWidth: '10rem' }}>
            <InputLabel 
                sx={{ 
                    fontFamily: 'Poppins',
                    zIndex: '0',
                    '&.Mui-focused': {
                        color: '#0B4762',
                    }, 
                }} 
                id="select-label"
            >
                { label }
            </InputLabel>
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                input={ <OutlinedInput label={ label }/> }
                IconComponent={ ExpandMoreIcon }
                sx={ ShiftSimpleSelectStyles }
                MenuProps={ MenuProps }
                labelId='select-label'
                disabled={ disabled }
                value={ value }
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            value={ item.uuid } 
                            key={ item.uuid } 
                            sx={{ 
                                display: 'flex',
                                padding: '.5rem', 
                                height: '2.5rem' 
                            }}
                        >
                            <ListItemText 
                                primary={ item.name }
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            />
                            <Box sx={{ width: '25px' }}></Box> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ShiftSelectWithLabel;