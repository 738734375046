import React from "react";
import { Icon, Stack, Typography } from "@mui/material";

const AddDateButton = ({ onClick, titleButton, componenteHijo, ...restProps }) => {
  return (
    <Stack
      sx={{ 
        alignItems: 'center',
        flexDirection: 'row', 
        width: 'max-content',
        cursor: 'pointer',
        m: '0 0 .5rem 0',
        gap: '5px',
      }}
      onClick={ onClick }
      {...restProps}
    >
      <Icon component={ componenteHijo } sx={{ fontSize: '1.1rem', color: '#0B4762' }} />
      <Typography
        sx={{ 
          textDecoration: 'underline',
          fontFamily: 'Poppins',
          color: '#0B4762',
          fontWeight: 500
        }}
      >
        { titleButton }
      </Typography>
    </Stack>
  );
};

export default AddDateButton;
