import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { setAvailableLayouts, setShowDeactivateWaitListModal, setSubshift, setWithWaitList } from "../../../../../actions/shiftsActions";

// Components
import WaitListMaxReservations from "../components/WaitListMaxReservations";
import WaitListClarifications from "../components/WaitListClarifications";
import WaitListCustomOptions from "../components/WaitListCustomOptions";
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import WaitListOperating from "../components/WaitListOperating";
import WaitListEnabling from "../components/WaitListEnabling";
import WaitListSectors from "../components/WaitListSectors";

// Constants
import { ShiftMode } from "../../../../../constants";

// Material Ui
import { Divider, makeStyles } from "@material-ui/core";
import { Box, Collapse, Typography } from "@mui/material";

// Utils
import { SetWaitList } from "../utils/waitListFunctions";

// Styles
import { DecriptionShiftStyles, TitleShiftStyles } from "../../../Styles";
import { dividerStyle } from "../utils/styles/generalStyles";

const useStyles = makeStyles(() => ({
  divider: dividerStyle,  
}));

const WaitListStep = (props) => {
  const classes = useStyles();

  // Variables
  const {
    workWithWaitList,
    availableLayouts,
    subShifts,
    mode,
  } = props;

  // Acciones
  const {
    setWithWaitList,
    setAvailableLayouts,
    setSubshift,
    setShowDeactivateWaitListModal,
  } = props;

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (workWithWaitList) {
      SetWaitList(availableLayouts, workWithWaitList, setAvailableLayouts, subShifts, setSubshift);
    }
    setShowOptions(workWithWaitList);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workWithWaitList]);

  const handleSwitchChange = () => {

    if (!showOptions === false && mode === ShiftMode.EDIT) {
      setShowDeactivateWaitListModal(true);
    } else {
      setShowOptions(!showOptions);
      setWithWaitList(!showOptions);

      SetWaitList(availableLayouts, !showOptions, setAvailableLayouts, subShifts, setSubshift);
    }    
  };

  return (
    <Box style={{ marginBottom: "50px"}}>
      <Box sx={{ ml:"-.7rem", display:"flex", alignItems: "center" }}>
        <Box sx={{ mt: '.8rem', '@media(min-width: 1361px)': { mt: '-1.2rem' } }}>
          <BlueSwitch
            onChange={() => handleSwitchChange()}
            checked={ showOptions }
            color="default"
          />
        </Box>
        <Typography sx={ TitleShiftStyles } >Lista de espera (Opcional)</Typography>
      </Box>
      <Typography sx={ DecriptionShiftStyles } >
        Habilita y configura la lista de espera para permitir a los comensales registrarse en caso de que el turno esté completo.
      </Typography>
      <Collapse in={ showOptions } sx={{ paddingTop: '2rem' }}>
        <WaitListSectors />
        <WaitListCustomOptions />
        <Divider className={ classes.divider } style={{ width: "100%" }} />
        <WaitListOperating />
        <Divider className={ classes.divider } style={{ width: "100%" }} />
        <WaitListClarifications />
        <Divider className={ classes.divider } style={{ width: "100%" }} />
        <WaitListMaxReservations />
        <Divider className={ classes.divider } style={{ width: "100%" }} />
        <WaitListEnabling />
      </Collapse>
    </Box>
  );
};


const mapStateToProps = (state) => {
  return {
      workWithWaitList: state.v3.shifts.shiftData.workWithWaitList,
      availableLayouts: state.v3.shifts.shiftData.availableLayouts,
      subShifts: state.v3.shifts.shiftData.subShifts,
      mode: state.v3.shifts.mode,      
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWithWaitList: (data) => dispatch(setWithWaitList(data)),    
    setAvailableLayouts: (data) => dispatch(setAvailableLayouts(data)),
    setSubshift: (data) => dispatch(setSubshift(data)),
    setShowDeactivateWaitListModal: (data) => dispatch(setShowDeactivateWaitListModal(data)),    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListStep);