import React from 'react';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { IconButton, Snackbar } from "@mui/material";

export const AlertSeverity = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const CustomSnackbar = ( props ) => { 

  const {        
    setShowAlert,
    showAlert,
    severity,
    message,
  } = props;

  const action = (
    <React.Fragment>
        <IconButton
            onClick={() => setShowAlert( false )}
            aria-label="close"
            color="inherit"
            size="small"
            sx={{ 
              backgroundColor: severity === AlertSeverity.ERROR ? '#FA626A !important' : '#105B7D !important' 
            }}
        >
            <CloseIcon fontSize="small" sx={{ color: 'white' }}/>
        </IconButton>
    </React.Fragment>
  );
    
  return (
    <Snackbar
      onClose={ () => setShowAlert( false ) }
      autoHideDuration={ 4000 }
      anchorOrigin={{ 
          vertical: 'bottom', 
          horizontal: 'right' 
      }}
      message={ message }
      open={ showAlert }
      action={ action }
      sx={{
          '.MuiPaper-root': {
              backgroundColor: severity === AlertSeverity.ERROR ? '#FB3F4A !important' : '#0B4762 !important',
              fontFamily: 'Poppins',
              borderRadius: '8px',
              color: 'white',
          }
      }}
    />
  );
};

export default CustomSnackbar;