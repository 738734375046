import axios from 'axios';
import { 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE, 
    BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR, 
    BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES, 
    BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1, 
    BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2, 
    BOOKING_POLITICS_BEFORE_GOING_MESSAGE, 
    SAVING_BOOKING_POLITICS_DATA_SUCCESS,
    BOOKING_POLITICS_ARRIVAL_TOLERANCE, 
    LOAD_BOOKING_POLITICS_DATA_SUCCESS,
    SAVING_BOOKING_POLITICS_DATA_FAIL,
    LOAD_BOOKING_POLITICS_DATA_FAIL,
    BOOKING_POLITICS_SAVE_BACK_DATA,
    BOOKING_POLITICS_BEFORE_GOING, 
    BOOKING_POLITICS_ADD_NEW_HOUR,
    BOOKING_POLITICS_CHANGE_HOURS,
    BOOKING_POLITICS_FIXED_SHIFT,
    SAVING_BOOKING_POLITICS_DATA,
    BOOKING_POLITICS_REMOVE_HOUR,
    BOOKING_POLITICS_CHANGE_DAYS,
    LOAD_BOOKING_POLITICS_DATA,
    BOOKING_SET_EDITING_HOUR,
    BOOKING_SET_ENABLE_HOUR,
} from '../../constants/politicsConstants';
import { openCloseTimeToOpenDuration } from '../../utils/formatters';

export const startLoadingBookingPolitics = () => ({
    type: LOAD_BOOKING_POLITICS_DATA,
});

export const loadBookingPoliticsDataSuccess = (data) => ({
    type: LOAD_BOOKING_POLITICS_DATA_SUCCESS,
    payload: data,
});

export const loadBookingPoliticsDataFail = (data) => ({
    type: LOAD_BOOKING_POLITICS_DATA_FAIL,
    payload: data,
});

export const savingBookingPoliticsData = () => ({
    type: SAVING_BOOKING_POLITICS_DATA,
});

export const savingBookingPoliticsDataSuccess = () => ({
    type: SAVING_BOOKING_POLITICS_DATA_SUCCESS,
});

export const savingBookingPoliticsDataFail = (error) => ({
    type: SAVING_BOOKING_POLITICS_DATA_FAIL,
    payload: error,
});

export const setEnableBookingPoliticHour = ({ id, enableHour }) => ({
    type: BOOKING_SET_ENABLE_HOUR,
    payload: {
        id, 
        enableHour
    }
});

export const setChangeHours = ( newArray ) => ({
    type: BOOKING_POLITICS_CHANGE_HOURS,
    payload: newArray
});

export const setEditingBookingPoliticHour = ({ id, editingHour }) => ({
    type: BOOKING_SET_EDITING_HOUR,
    payload: {
        id, 
        editingHour
    }
});

export const setNewBookingPoliticsHour = ( newHours ) => ({
    type: BOOKING_POLITICS_ADD_NEW_HOUR,
    payload: newHours
});

export const setRemoveBookingPoliticsHour = ( id ) => ({
    type: BOOKING_POLITICS_REMOVE_HOUR,
    payload: id
});

export const setEditDaysSelected = ({ id, newDaysArray }) => ({
    type: BOOKING_POLITICS_CHANGE_DAYS,
    payload: {
        id,
        newDaysArray
    }
})

export const setFixedShift = ( fixedShifts ) => ({
    type: BOOKING_POLITICS_FIXED_SHIFT,
    payload: fixedShifts,
});

export const setArrivalTolerance = ( arrivalTolerance ) => ({
    type: BOOKING_POLITICS_ARRIVAL_TOLERANCE,
    payload: arrivalTolerance,
});

export const setArrivalToleranceMinutes = ( arrivalToleranceMinutes ) => ({
    type: BOOKING_POLITICS_ARRIVAL_TOLERANCE_MINUTES,
    payload: arrivalToleranceMinutes,
});

export const setPenaltyToleranceMinutes = ( penaltyToleranceMinutes ) => ({
    type: BOOKING_POLITICS_PENALTY_TOLERANCE_MINUTES,
    payload: penaltyToleranceMinutes,
});

export const setBeforeGoing = ( beforeGoing ) => ({
    type: BOOKING_POLITICS_BEFORE_GOING,
    payload: beforeGoing,
});

export const setBeforeGoingMessage = ( beforeGoingMessage ) => ({
    type: BOOKING_POLITICS_BEFORE_GOING_MESSAGE,
    payload: beforeGoingMessage,
});

export const setCominucationWithMR = ( cominucationWithMR ) => ({
    type: BOOKING_POLITICS_BEFORE_COMUNICATION_WITH_MR,
    payload: cominucationWithMR,
});

export const setDefaultMessage1 = ( defaultMessage1 ) => ({
    type: BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1,
    payload: defaultMessage1,
});

export const setDefaultMessage1Message = ( defaultMessage1Message ) => ({
    type: BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_1_MESSAGE,
    payload: defaultMessage1Message,
});

export const setDefaultMessage2 = ( defaultMessage2 ) => ({
    type: BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2,
    payload: defaultMessage2,
});

export const setDefaultMessage2Message = ( defaultMessage2Message ) => ({
    type: BOOKING_POLITICS_BEFORE_DEFAULT_MESSAGE_2_MESSAGE,
    payload: defaultMessage2Message,
});

export const setBookingPoliticsBackDataInFront = ( backData ) => ({
    type: BOOKING_POLITICS_SAVE_BACK_DATA,
    payload: backData
});

export const getBookingsPolitics = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        dispatch( startLoadingBookingPolitics() );

        try {

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken( true );

            const res = await axios.get(`${ API_URL }/politics/booking/${ idPartnerSelected }`, {
                headers: { Authorization: `Bearer ${ token }` }
            });

            const { data } = res;

            console.log( 'DATA --> ', data );
            dispatch( setBookingPoliticsBackDataInFront({ backData: data }) );

            dispatch( loadBookingPoliticsDataSuccess( data ) );

        } catch (error) {
            console.log(error);
            dispatch( loadBookingPoliticsDataFail( error.message ) );
        }
    }
};

export const patchBookingsPolitics = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { bookingPoliticsReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { 
            arrivalToleranceMinutes,
            penaltyToleranceMinutes,
            defaultMessage1Message,
            defaultMessage2Message,
            bookingPoliticsHours, 
            beforeGoingMessage,
            cominucationWithMR,
            arrivalTolerance,
            defaultMessage1, 
            defaultMessage2,
            beforeGoing, 
            fixedShifts,
        } = bookingPoliticsReducer;

        dispatch( startLoadingBookingPolitics() );

        try {

            const newData = {
                notifyManualBookings: cominucationWithMR,
                fixedShifts: fixedShifts,
            };

            if ( arrivalToleranceMinutes < 10 || arrivalTolerance === false ) {
                newData.shiftsTolerance = null; 
            } else {
                newData.shiftsTolerance = arrivalToleranceMinutes;
            };

            if ( penaltyToleranceMinutes === 0 ) {
                newData.changeTolerance = null; 
            } else {
                newData.changeTolerance = penaltyToleranceMinutes;
            };

            if ( defaultMessage1 === false ) {
                newData.messageToNotBookableGroup = null;
            } else {
                newData.messageToNotBookableGroup = defaultMessage1Message;
            };

            if ( defaultMessage2 === false ) {
                newData.messageToNotBookableTimes = null;
            } else {
                newData.messageToNotBookableTimes = defaultMessage2Message;
            };

            if ( beforeGoing === false ) {
                newData.toKnowBeforeBooking = null;
            } else {
                newData.toKnowBeforeBooking = beforeGoingMessage;
            };

            let hoursArray = [];

            for (let i = 0; i < bookingPoliticsHours.length; i++) {
                hoursArray.push({
                    duration: openCloseTimeToOpenDuration( bookingPoliticsHours[i].start, bookingPoliticsHours[i].end ),
                    enable: bookingPoliticsHours[i].enable,
                    open: bookingPoliticsHours[i].start,
                    days: bookingPoliticsHours[i].days,
                });
            };

            if ( hoursArray.length >= 1 ) {
                newData.attentionIntervals = hoursArray;
            }

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken( true );

            console.log( 'NEW DATA --> ', newData );

            const res = await axios.patch(`${ API_URL }/politics/booking/${ idPartnerSelected }`, newData, { headers: { Authorization: `Bearer ${ token }` }});

            console.log( 'PATCH RES --> ', res );

            dispatch( loadBookingPoliticsDataSuccess() );

        } catch (error) {
            console.log(error);
            dispatch( loadBookingPoliticsDataFail( error.message ) );
        }
    }
};