import {
    ADD_MESAYA_SECTOR,
    CHANGE_MESAYA_ACCEPTANCE_RADIUS,
    CHANGE_MESAYA_BIGGROUP,
    CHANGE_MESAYA_BIGGROUP_ENABLE,
    CHANGE_MESAYA_CLOSETIME,
    CHANGE_MESAYA_MAXCAPACITY,
    CHANGE_MESAYA_STAYING_RADIUS,
    CHANGE_MESAYA_TOLERANCE,
    EDIT_MESAYA_CANCEL,
    EDIT_MESAYA_ERROR,
    EDIT_MESAYA_SUCCESS,
    PARTNERS,
    REMOVE_MESAYA_SECTOR,
    TOGGLE_MESAYA_LOADING,
    TOGGLE_MESAYA_SECTORS_LOADING,
} from "../../constants";

export const editMesaYaSuccess = () => ({
  type: EDIT_MESAYA_SUCCESS,
});
export const editMesaYaCancel = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { firestore } = getState();
    const { ordered } = firestore;
    const originalPartner = ordered[PARTNERS][0];
    dispatch(editMesaYaCancelAction(originalPartner));
  };
};
export const toggleMesaYaLoading = () => ({
  type: TOGGLE_MESAYA_LOADING,
});
export const toggleMesaYaSectorsLoading = () => ({
  type: TOGGLE_MESAYA_SECTORS_LOADING,
});
export const editMesaYaCancelAction = (data) => ({
  type: EDIT_MESAYA_CANCEL,
  payload: data,
});
export const editMesaYaError = (data) => ({
  type: EDIT_MESAYA_ERROR,
  payload: data,
});
export const changeMesaYaAcceptanceRadius = (data) => ({
  type: CHANGE_MESAYA_ACCEPTANCE_RADIUS,
  payload: data,
});
export const changeMesaYaStayingRadius = (data) => ({
  type: CHANGE_MESAYA_STAYING_RADIUS,
  payload: data,
});
export const changeMesaYaBiggroup = (data) => ({
  type: CHANGE_MESAYA_BIGGROUP,
  payload: data,
});
export const toggleMesaYaBiggroupEnable = () => ({
  type: CHANGE_MESAYA_BIGGROUP_ENABLE,
});
export const changeMesaYaMaxcapacity = (data) => ({
  type: CHANGE_MESAYA_MAXCAPACITY,
  payload: data,
});
export const changeMesaYaClosetime = (data) => ({
  type: CHANGE_MESAYA_CLOSETIME,
  payload: data,
});
export const changeMesaYaTolerance = (data) => ({
  type: CHANGE_MESAYA_TOLERANCE,
  payload: data,
});

export const addMesaYaSector = (data) => ({
  type: ADD_MESAYA_SECTOR,
  payload: data,
});
export const removeMesaYaSector = (data) => ({
  type: REMOVE_MESAYA_SECTOR,
  payload: data,
});

export const submitMesaYaChanges = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { mesayaReducer, firestore, userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    const { editReducer } = mesayaReducer;
    const { ordered } = firestore;
    const originalPartner = ordered[PARTNERS][0];

    const {
      acceptanceRadius,
      stayingRadius,
      bigGroup,
      maxCapacity,
      closeTime,
      tolerance,
    } = editReducer;

    dispatch(toggleMesaYaLoading());

    let updateData = {};

    if (acceptanceRadius !== originalPartner["acceptanceRadius"])
      updateData["acceptanceRadius"] = acceptanceRadius;
    if (stayingRadius !== originalPartner["stayingRadius"])
      updateData["stayingRadius"] = stayingRadius;
    if (maxCapacity !== originalPartner["MYmaxCapacity"])
      updateData["MYmaxCapacity"] = maxCapacity;
    if (closeTime !== originalPartner["MYcloseTime"])
      updateData["MYcloseTime"] = closeTime;
    if (tolerance !== originalPartner["MYtolerance"])
      updateData["MYtolerance"] = tolerance;

    if (bigGroup !== originalPartner["MYbigGroup"])
      updateData["MYbigGroup"] = bigGroup;
    if (updateData["MYbigGroup"] === 0) updateData["MYbigGroup"] = null;

    if ( idPartnerSelected ) {
      await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .update(updateData);
      dispatch(editMesaYaSuccess());
    } else {
      dispatch(editMesaYaError("El usuario no tiene un partner asociado."));
    }
  };
};
export const changeMesaYaSectorFull = (sectorName, full) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleMesaYaSectorsLoading());

    let updateData = {};
    updateData["MYSectors." + sectorName + ".full"] = full;
    if ( idPartnerSelected ) {
      await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .update(updateData, { merge: true });

      dispatch(toggleMesaYaSectorsLoading());
    } else {
      dispatch(editMesaYaError("El usuario no tiene un partner asociado."));
    }
  };
};
export const changeMesaYaSectorEnable = (sectorName, enable) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleMesaYaSectorsLoading());
    let updateData = {};
    updateData["MYSectors." + sectorName + ".enable"] = enable;
    if ( idPartnerSelected ) {
      await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .update(updateData, { merge: true });
      dispatch(toggleMesaYaSectorsLoading());
    } else {
      dispatch(editMesaYaError("El usuario no tiene un partner asociado."));
    }
  };
};
