import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { 
    changeTurnoMaintainBookings, 
    changeTurnoApplyFromDate, 
    setCloseDeleteSuccess, 
    submitTurnoChanges, 
    submitTurnoDeleted, 
    closeTurnosError, 
    changeTurnoMode, 
    setCloseSaveOk, 
    changeEnabled, 
} from '../../actions/shiftsActions';

// Components
import WarningPopup, { ModalModeTurnos } from './Shifts/WarningPopup/WarningPopup';
import CustomSnackbar, { AlertSeverity } from './Components/CustomSnackbar';
import ButtonWithIcon from '../NewComponents/ButtonWithIcon/ButtonWithIcon';
import ErrorDialog from '../GeneralComponents/Modal/ErrorDialog';
import { getModalType } from "./Shifts/Wizard/utils/functions";
import BookingModal from '../Reservas/Dialogs/BookingModal';
import Turno from './Components/turno';

// Constants
import { ModalMode } from '../../constants/modalMode';
import { ShiftMode } from '../../constants';

// Material Ui
import { Box } from '@mui/material';

// Styles
import { ShiftsMainContainer } from './Styles';

const editing = false;

const Data = ( props ) => {   

    // Variables
    const {
        submitTurnoChanges,
        showDeleteOk,
        shiftArray,
        showSaveOk,
        shiftData,
        isActive,
        error,
        mode,
    } = props;

    // Acciones
    const {
        changeTurnoMaintainBookings,
        changeTurnoApplyFromDate, 
        setCloseDeleteSuccess,
        submitTurnoDeleted, 
        closeTurnosError, 
        changeTurnoMode, 
        setCloseSaveOk, 
        changeEnabled,
        buttonOnClick, 
        buttonText, 
        ButtonIcon, 
    } = props;

    const [showConfirmDialog, setShowConfirmDialog] = useState( mode === ShiftMode.DELETE || mode === ShiftMode.DISABLE );
    const [confirmDialogType, setConfirmDialogType] = useState( getModalType(mode, shiftData) );
    const [showModalShift, setShowModalShift] = useState( false );

    const turnos = shiftArray;

    useEffect(() => {
        setConfirmDialogType( getModalType(mode, shiftData) );
        if ( mode === ShiftMode.DELETE || mode === ShiftMode.DISABLE ) {
            setShowConfirmDialog(true);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mode ]);

    const onCancel = () => {
        setShowConfirmDialog(false);
        setShowModalShift(false);
        if ( mode === ShiftMode.DELETE || mode === ShiftMode.DISABLE ) {
            changeTurnoMode({ mode: ShiftMode.VIEW, turno: null });
        };
    };

    const onFinish = ( date ) => {
        setShowConfirmDialog( false );
        changeTurnoApplyFromDate( date );
        // setShowModalShift(true);
        handleConfirmModal();
    };

    const submit = () => {
        if ( mode === ShiftMode.DELETE ) {
            setShowModalShift( false );
            submitTurnoDeleted();
        } else if ( mode === ShiftMode.DISABLE ) {
            changeEnabled( false );
            submitTurnoChanges();
        } else {            
            submitTurnoChanges();
        }
    };

    const handleConfirmModal = () => {
        if ( mode === ShiftMode.DELETE ) {
            changeTurnoMaintainBookings( true );
        } else {
            changeTurnoMaintainBookings( false );
        }
        
        submit();
    };

    const handleDelete = () => {
        if ( mode === ShiftMode.DELETE ) {
            changeTurnoMaintainBookings( false );
        };

        submit();
    };

    const handleBackModal = () => {
        changeTurnoMaintainBookings( true );
        submit();
    };

    const getModalMode = () => {
        if ( mode === ShiftMode.DELETE ) {
            return ModalModeTurnos.DELETE_SHIFT;
        } else {
            return ModalModeTurnos.DISABLE_SHIFT;
        };
    };
    
    return (
        <Box sx={ ShiftsMainContainer } >
            <Box sx={{ position: 'absolute', right: '2rem', top: isActive ? '5rem' : '7rem' }}>
                <ButtonWithIcon
                    icon={ ButtonIcon && <ButtonIcon /> }
                    onClick={ buttonOnClick }
                    text={ buttonText }
                />
            </Box>
            <Box sx={{ p: '3rem 0' }}>
                {
                    turnos.map(( turno ) => (
                        <Turno key={turno.id} turno={turno} editing={editing} />
                    ))
                }
            </Box>
            <CustomSnackbar 
                message={ showSaveOk ? 'Los cambios se guardaron con éxito' : 'Turno borrado con éxito' } 
                setShowAlert={ showSaveOk ? setCloseSaveOk : setCloseDeleteSuccess } 
                showAlert={ showSaveOk || showDeleteOk } 
                AlertSeverity={AlertSeverity.SUCCESS} 
            />            
            <BookingModal
                minDate={
                    shiftData && moment(shiftData.startDate).isAfter(moment()) ? 
                        moment(shiftData.startDate).toDate() 
                    : 
                        moment().toDate()
                }
                advanceStart={shiftData && shiftData.advanceStart}
                show={showConfirmDialog}
                type={confirmDialogType}
                onCancel={onCancel}
                onFinish={onFinish}
            />
            {
                showModalShift ? 
                        <WarningPopup 
                            onclickConfirm={() => handleConfirmModal()}
                            onclickBack={() => handleBackModal()}
                            onclickDelete={() => handleDelete()}
                            type={getModalMode()}                    
                            onCancel={onCancel}
                        />  
                    : 
                        null
            }
            <ErrorDialog
                onAccept={ closeTurnosError }
                type={ ModalMode.ERROR }
                onAcceptText={ "OK!" }
                open={ error !== "" }
                title={ "Atención!" }
                body={ error ?? "" }
                subBody={ "" }
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        showDeleteOk: state.v3.shifts.showDeleteOk,    
        shoModalEdit: state.v3.shifts.shoModalEdit,    
        isActive: state.sidebarManager.isActive,
        shiftArray: state.v3.shifts.shiftArray,
        showSaveOk: state.v3.shifts.showSaveOk,
        shiftData: state.v3.shifts.shiftData,
        error: state.v3.shifts.error,
        mode: state.v3.shifts.mode,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        changeTurnoApplyFromDate: ( endDate ) => dispatch(changeTurnoApplyFromDate( endDate )),
        changeTurnoMaintainBookings: ( data ) => dispatch(changeTurnoMaintainBookings( data )), 
        changeTurnoMode: ({ mode, turno }) => dispatch(changeTurnoMode({ mode, turno })), 
        setCloseDeleteSuccess: () => dispatch(setCloseDeleteSuccess()),      
        changeEnabled: ( data ) => dispatch(changeEnabled( data )),
        submitTurnoChanges: () => dispatch(submitTurnoChanges()),      
        submitTurnoDeleted: () => dispatch(submitTurnoDeleted()),     
        closeTurnosError: () => dispatch(closeTurnosError()), 
        setCloseSaveOk: () => dispatch(setCloseSaveOk()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Data);
