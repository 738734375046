import React from 'react';

// Components
import ButtonWithIcon from '../../NewComponents/ButtonWithIcon/ButtonWithIcon';
import TimeSelector from "../../GeneralComponents/TimeSelector";

// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';

// Utils
import {
    hhssToMinutes,
    MinutesTohhss,
    openCloseTimeToOpenDuration,
    openDurationTimeToOpenClose
} from "../../../utils/formatters";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'transparent',
        borderRadius: '12px',
    }
}));

const HoursHome = (props) => {

    const classes = useStyles();

    const {intervals, intervalName, editIntervalIndex} = props

    // Actions
    const {
        addInterval,
        removeInterval,
        editInterval,
        editIntervalComplete,
        toggleIntervalEnable
    } = props;

    const getDays = (days) => {
        const res = [false, false, false, false, false, false, false];
        days.forEach(day => {
            res[day] = true;
        })
        return res;
    }

    const onEditingCompleted = ({openTime, closeTime, days, enable}) => {

        const open = hhssToMinutes(openTime);
        const close = hhssToMinutes(closeTime);
        const duration = openCloseTimeToOpenDuration(open, close);
        const daysArray = days.map((day, index) => day ? index : null).filter(filter => filter !== null);

        editIntervalComplete({
            open: open,
            duration: duration,
            days: daysArray,
            index: editIntervalIndex
        })
    }

    const newInterval = () => {
        const data = {
            open: 720,
            duration: 120,
            days: [],
            enable: true
        }

        addInterval(data);
    }

    const onDeleteHappyHourInterval = async (index) => {
        await editInterval(undefined)
        await removeInterval(index)
    }

    
    return (
        <div key={intervalName} className={classes.root}>
            <div style={{marginTop: '20px'}}>
                {
                    intervals?.map((interval, index) =>
                    <div key={`${index}`} style={{margin: '0px'}}>
                            <TimeSelector
                                from={"HOURSHOME"}
                                intervalName={`${intervalName} ${index + 1}`}

                                enable={interval.enable !== undefined ? interval.enable : true}
                                onEnable={() => toggleIntervalEnable(index)}

                                initTime={MinutesTohhss(interval.open)}
                                endTime={MinutesTohhss(openDurationTimeToOpenClose(interval.open, interval.duration))}
                                days={getDays(interval.days)}

                                editing={index === editIntervalIndex}
                                editionEnable={editIntervalIndex === undefined}

                                onClickEdit={() => editInterval(index)}
                                onEditCompleted={onEditingCompleted}

                                onDelete={() => onDeleteHappyHourInterval(index)}
                            />
                        </div>)
                }
                <div style={{margin: '1rem 0'}}>
                    <ButtonWithIcon
                        minWidth={'8rem'}
                        text={"Agregar horario"}
                        onClick={newInterval}
                        icon={<AddCircleOutlineOutlinedIcon/>}
                    />
                </div>
            </div>
        </div>
    )
}

export default HoursHome
