import React from 'react';

// Material UI
import CloseIcon from "@material-ui/icons/Close";

// Components
import searchImage from "../SvgComponents/images/default_thumbnail.png";
import "./Thumbnail.css";

const Thumbnail = (props) => {

    const {src, alt, style, onRemove} = props;

    return (
        <div style={style} className={"thumbnail-container"}>
            <img src={src ?? searchImage} alt={alt ?? "img1"}/>
            {src && <div className={"remove-icon-button"} onClick={onRemove}>
                <CloseIcon style={{color: 'white', backgroundColor: '#0B4762', borderRadius: '50%'}} fontSize={'small'}/>
            </div>}
        </div>
    );
}

export default Thumbnail;
