import React from 'react';

const ClientEmailIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.16707 3.16666H15.8337C16.7046 3.16666 17.4171 3.87916 17.4171 4.74999V14.25C17.4171 15.1208 16.7046 15.8333 15.8337 15.8333H3.16707C2.29624 15.8333 1.58374 15.1208 1.58374 14.25V4.74999C1.58374 3.87916 2.29624 3.16666 3.16707 3.16666Z" stroke="#818181" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4171 4.75L9.50041 10.2917L1.58374 4.75" stroke="#818181" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ClientEmailIcon;