import React from 'react'

const InfoIcon = ({ size = "17", fill = '#041C32' }) => {
    return (
        <svg 
            style={{ margin: 'auto 0', cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 31" 
            height={ size } 
            width={ size } 
            fill="none" 
        >
            <path
                d="M15.5 1.9375C8.01035 1.9375 1.9375 8.01035 1.9375 15.5C1.9375 22.9896 8.01035 29.0625 15.5 29.0625C22.9896 29.0625 29.0625 22.9896 29.0625 15.5C29.0625 8.01035 22.9896 1.9375 15.5 1.9375ZM15.5 26.7617C9.28184 26.7617 4.23828 21.7182 4.23828 15.5C4.23828 9.28184 9.28184 4.23828 15.5 4.23828C21.7182 4.23828 26.7617 9.28184 26.7617 15.5C26.7617 21.7182 21.7182 26.7617 15.5 26.7617Z"
                fill={fill}/>
            <path
                d="M14.0469 10.1719C14.0469 10.5573 14.2 10.9269 14.4725 11.1994C14.745 11.4719 15.1146 11.625 15.5 11.625C15.8854 11.625 16.255 11.4719 16.5275 11.1994C16.8 10.9269 16.9531 10.5573 16.9531 10.1719C16.9531 9.78648 16.8 9.41687 16.5275 9.14436C16.255 8.87185 15.8854 8.71875 15.5 8.71875C15.1146 8.71875 14.745 8.87185 14.4725 9.14436C14.2 9.41687 14.0469 9.78648 14.0469 10.1719ZM16.2266 13.5625H14.7734C14.6402 13.5625 14.5312 13.6715 14.5312 13.8047V22.0391C14.5312 22.1723 14.6402 22.2812 14.7734 22.2812H16.2266C16.3598 22.2812 16.4688 22.1723 16.4688 22.0391V13.8047C16.4688 13.6715 16.3598 13.5625 16.2266 13.5625Z"
                fill={fill}/>
        </svg>
    )
}

export default InfoIcon
