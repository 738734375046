import React from 'react';

const BookingTagsIcon = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.2735" cy="0.277424" r="0.262764" fill="white" stroke="#818181" strokeWidth="0.0291961"/>
            <path d="M5.0444 16.7137C5.56763 17.2369 6.26515 17.4985 6.96281 17.4985C7.70397 17.4985 8.44512 17.215 9.01203 16.6483L15.7917 9.86858C16.2496 9.41071 16.5111 8.80036 16.5329 8.14648V3.02355C16.5329 2.47856 16.3148 1.95532 15.9225 1.56297C15.5518 1.17065 15.0287 0.952576 14.4837 0.952576H9.33894C8.70683 0.974321 8.09628 1.23602 7.63857 1.69373L0.858854 8.47344C-0.252893 9.58519 -0.296531 11.3728 0.793466 12.4628L5.0444 16.7137ZM1.92696 9.56339L8.70667 2.78367C8.88109 2.60926 9.12091 2.50023 9.33879 2.50023H14.4617C14.6144 2.50023 14.7452 2.56562 14.854 2.6529C14.9631 2.76193 15.0067 2.89271 15.0067 3.04522V8.14636C15.0067 8.38616 14.8977 8.62595 14.7233 8.80039L7.94356 15.5801C7.42032 16.1033 6.59201 16.1251 6.11242 15.6455L1.86147 11.3945C1.38188 10.893 1.40376 10.0865 1.92686 9.56325L1.92696 9.56339Z" fill="#818181"/>
            <path d="M11.6493 8.36427C12.2815 8.36427 12.9355 8.12447 13.4151 7.62312C14.396 6.64217 14.396 5.05083 13.4151 4.06973C12.4341 3.08878 10.8428 3.08878 9.86169 4.06973C8.88074 5.05067 8.88074 6.64202 9.86169 7.62312C10.3632 8.12447 11.0171 8.36427 11.6493 8.36427ZM10.9517 5.15971C11.1478 4.96355 11.3876 4.87627 11.6493 4.87627C11.9109 4.87627 12.1507 4.9634 12.347 5.15971C12.7393 5.55203 12.7393 6.16258 12.347 6.55489C11.9547 6.94721 11.3441 6.94721 10.9518 6.55489C10.5811 6.16242 10.5811 5.55203 10.9517 5.15971H10.9517Z" fill="#818181"/>
        </svg>
    )
}

export default BookingTagsIcon;