export const MyClientsIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4546 20.6363V18.4545C18.4546 17.2972 17.9949 16.1872 17.1765 15.3689C16.3582 14.5506 15.2483 14.0908 14.091 14.0908H5.36365C4.20634 14.0908 3.09643 14.5506 2.27808 15.3689C1.45974 16.1872 1 17.2972 1 18.4545V20.6363" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.72694 1C8.56962 1 7.45971 1.45974 6.64137 2.27808C5.82302 3.09643 5.36328 4.20634 5.36328 5.36365C5.36328 6.52097 5.82302 7.63088 6.64137 8.44922C7.45971 9.26757 8.56962 9.72731 9.72694 9.72731C10.8842 9.72731 11.9942 9.26757 12.8125 8.44922C13.6308 7.63088 14.0906 6.52097 14.0906 5.36365C14.0906 4.20634 13.6308 3.09643 12.8125 2.27808C11.9942 1.45974 10.8842 1 9.72694 1Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.0003 20.6366V18.4547C24.9996 17.4879 24.6778 16.5487 24.0854 15.7845C23.493 15.0204 22.6637 14.4746 21.7275 14.2329" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3638 1.14209C18.3024 1.38242 19.1344 1.92831 19.7285 2.69371C20.3226 3.4591 20.6451 4.40046 20.6451 5.36938C20.6451 6.3383 20.3226 7.27966 19.7285 8.04505C19.1344 8.81045 18.3024 9.35634 17.3638 9.59667" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
