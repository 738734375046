import {
    CHANGE_EDIT_USER_PASSWORD,
    CHANGE_EDIT_USER_PASSWORD_TWO,
    CHANGE_RESET_PASSWORD_EMAIL,
    CHANGE_RESET_PASSWORD_ERROR,
    CHANGE_SIGNIN_EMAIL,
    CHANGE_SIGNIN_ERROR,
    CHANGE_SIGNIN_PASSWORD,
    EDIT_PASSWORD_CANCEL,
    EDIT_PASSWORD_SUCCESS,
    LOGIN_ROUTE,
    SET_ERROR_NEW_PASSWORD,
    SET_ERROR_NEW_PASSWORD_TWO,
    SIGNIN_ERROR,
    SIGNIN_SUCCESS,
    SIGNOUT_ERROR,
    SIGNOUT_SUCCESS,
    SWITCH_NEW_PASSWORD_DIALOG,
    SWITCH_SIGN_FORM,
    USERS_PARTNERS,
} from '../../constants';
import 'firebase/auth';
import {push} from 'connected-react-router';

import {resetAllData,} from '../index';
import { isAdminRole } from '../../utils/roles';

export const switchSignForm = (form_index) => ({
    type: SWITCH_SIGN_FORM,
    payload: form_index
});
export const changeSigninEmail = (email) => ({
    type: CHANGE_SIGNIN_EMAIL,
    payload: email
});
export const changeSigninPassword = (password) => ({
    type: CHANGE_SIGNIN_PASSWORD,
    payload: password
});
export const changeSigninError = (error) => ({
    type: CHANGE_SIGNIN_ERROR,
    payload: error
});
//-----------------------------------------------------------------------------------------------
export const signinSuccess = () => ({
    type: SIGNIN_SUCCESS,
});
export const signinError = (error) => ({
    type: SIGNIN_ERROR,
    error: error
});
export const signIn = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const { authReducer } = getState();
        const { email, password } = authReducer;

        try {
            const signInResp = await firebase.auth().signInWithEmailAndPassword(email, password);
            const getUserResp = await firestore.collection(USERS_PARTNERS).doc(signInResp.user.uid).get();
            const data = getUserResp.data();
            if (!isAdminRole(data)) {
                dispatch(signOut());
            }

            dispatch(signinSuccess());
        } catch (error) {
            // console.log("ERROR CATCH")
            dispatch(signinError(error));
        }
    }
}
//-----------------------------------------------------------------------------------------------
export const signOutSuccess = () => ({
    type: SIGNOUT_SUCCESS,
});
export const signOutError = (error) => ({
    type: SIGNOUT_ERROR,
    error: error
});

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            localStorage.removeItem('partnerSelected');
            dispatch(signOutSuccess());
            dispatch(resetAllData());
            dispatch(push(LOGIN_ROUTE));
        }).catch((error) => {
            dispatch(signOutError(error));
        });
    }
}


//-----------------------------------------------------------------------------------------------
export const changeResetPasswordEmail = (email) => ({
    type: CHANGE_RESET_PASSWORD_EMAIL,
    payload: email
});

export const changeResetPasswordError = (error) => ({
    type: CHANGE_RESET_PASSWORD_ERROR,
    payload: error
});

export const submitResetPassword = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const { authReducer } = getState();
        const { resetPasswordEmail } = authReducer;

        firebase.auth().sendPasswordResetEmail(resetPasswordEmail);
    }
}


//CAMBIO DE CONTRASEÑA--------------------------------------------------------------------------
export const switchNewPasswordDialog = () => ({
    type: SWITCH_NEW_PASSWORD_DIALOG
});
export const editPasswordSuccess = () => ({
    type: EDIT_PASSWORD_SUCCESS,
});
export const editPasswordCancel = () => ({
    type: EDIT_PASSWORD_CANCEL,
});
export const chageEditUserPassword = (data) => ({
    type: CHANGE_EDIT_USER_PASSWORD,
    payload: data,
});
export const chageEditUserPasswordTwo = (data) => ({
    type: CHANGE_EDIT_USER_PASSWORD_TWO,
    payload: data,
});
export const setErrorInNewPassword = (data) => ({
    type: SET_ERROR_NEW_PASSWORD,
    payload: data,
});
export const setErrorInNewPasswordTwo = (data) => ({
    type: SET_ERROR_NEW_PASSWORD_TWO,
    payload: data,
});

// Delete cuenta
export const deleteAccount = (uid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

    }
}