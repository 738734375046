export const LOADING_USER_ACCOUNTS = 'LOADING_USER_ACCOUNTS';
export const LOADING_USER_ACCOUNTS_SUCCESSFUL = 'LOADING_USER_ACCOUNTS_SUCCESSFUL';

export const EDIT_USER_ACCOUNT = "EDIT_USER_ACCOUNT";
export const SHOW_ERROR_DIALOG = "SHOW_ERROR_DIALOG";

export const EDIT_USER_ACCOUNTS_SUCCESSFUL = "EDIT_USER_ACCOUNTS_SUCCESSFUL"

export const TOGGLE_LOADING_USER_ACCOUNTS = "TOGGLE_LOADING_USER_ACCOUNTS";

export const CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE = "CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE";
export const CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE = "CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE";
export const CHANGE_EDIT_USER_ACCOUNT_MY_ROLE = "CHANGE_EDIT_USER_ACCOUNT_MY_ROLE";
export const CHANGE_EDIT_USER_ACCOUNT_ENABLE = "CHANGE_EDIT_USER_ACCOUNT_ENABLE";
export const CHANGE_EDIT_USER_ACCOUNT_EMAIL = "CHANGE_EDIT_USER_ACCOUNT_EMAIL";
export const CHANGE_EDIT_USER_ACCOUNT_NAME = "CHANGE_EDIT_USER_ACCOUNT_NAME";
export const USER_SET_ID_PARTNER_SELECTED = 'USER_SET_ID_PARTNER_SELECTED';
export const USERS_CHANGE_USER_ROLE = 'USERS_CHANGE_USER_ROLE';
export const USER_CHANGE_USER_ROLE = 'USER_CHANGE_USER_ROLE';
export const SHOW_USER_ERROR_MODAL = 'SHOW_USER_ERROR_MODAL';
