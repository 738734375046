import React from 'react';

// Material Ui
import { Menu, MenuItem } from '@mui/material';

const ShiftSelectTypeDateMenu = ({ 
    openMultiDateCalendar, 
    openRangeDateCalendar, 
    openDateCalendar, 
    handleClose, 
    anchorEl, 
    open, 
}) => {
    return (
        <Menu
            aria-labelledby="demo-positioned-button"
            onClose={ handleClose }
            anchorEl={ anchorEl }
            open={ open }
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top', 
            }}
            transformOrigin={{ 
                horizontal: 'center', 
                vertical: 'bottom', 
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '.6rem',
                },
                '& .MuiButtonBase-root': {
                    borderBottom: '1px solid #E7E7E7',
                    fontFamily: 'Poppins',
                    color: '#041C32',
                    fontWeight: 400
                }
            }}
        >
            <MenuItem onClick={() => openDateCalendar()} >Fecha única</MenuItem>
            <MenuItem onClick={() => openMultiDateCalendar()}>Fechas múltiples</MenuItem>
            <MenuItem onClick={() => openRangeDateCalendar()}>Rango</MenuItem>
        </Menu>
    )
}

export default ShiftSelectTypeDateMenu;