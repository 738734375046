import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

const ShiftSelectWithIcon = ({ 
    error = false, 
    handleChange, 
    optionsArray, 
    renderValue, 
    disabled, 
    value,
    icon 
}) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                marginTop: '.2rem',
                minWidth: '78px',
                width: '10rem',
            },
        },
    };

    return (
        <FormControl error={ error } sx={{ width: 'max-content' }}>
            <Select
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                onChange={ handleChange }
                sx={{
                    fontFamily: 'Poppins',
                    borderRadius: '10px',
                    color: '#2A2742', 
                    width: '10rem',
                    fontWeight: 500,
                    '& .MuiSelect-select': {
                        padding: '10px 10px 8px 10px'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        '& :hover': {
                            borderColor: '#0B4762',
                        },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0B4762',
                        borderRadius: '10px',
                        borderWidth: '1px',
                    },
                    '& .MuiSvgIcon-root': {
                        color: disabled ? '#B9B9B9' : '#2A2742',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FB3F4A',
                    },
                    '@media (min-width: 1500px)': {
                        '& .MuiSelect-select': {
                            padding: '10px 14px 8px 10px'
                        },
                    }
                }}
                input={ <OutlinedInput /> }
                renderValue={ renderValue }
                placeholder='Seleccionar'
                MenuProps={ MenuProps }
                disabled={ disabled }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            key={ item.value } 
                            value={ item.value } 
                            sx={{ 
                                alignItems: 'center', 
                                padding: '.5rem', 
                                height: '2.5rem',
                                gap: '10px', 
                            }}
                        >
                            { icon }
                            <ListItemText 
                                primary={ item.option }
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ShiftSelectWithIcon