import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { setDwellTimeByGroup } from '../../../../../actions/shiftsActions';

// Components
import ShifHourWithArrowsSelect from './General/ShifHourWithArrowsSelect';
import { BlueSwitch } from '../../../../GeneralComponents/blueSwitch';

// Material Ui
import { Box, Collapse, Grid, Stack, Typography } from "@mui/material";

// Utils
import { dwellTimeDefaultOption, dwellTimeGroups, dwellTimeOptions } from '../utils/constants';
import { AllSameDwellTime, SetAllDwellTime } from '../utils/advancedOptionsFunctions';

// Styles
import { ShiftSelectTitles } from '../../../Styles';

export const groupSelectStyles = {  
    fontSize: "15px !important",
    fontWeight: "500 !important",
    lineHeight: "27px !important",
    letterSpacing: "0em !important",
    textAlign: "left !important",
    color: "#041C32 !important",  
    borderRadius: "9px !important",
    height: "50px !important",
}

const AdvancedOptionsGroups = (props) => {

    // Variables
    const {
        dwellTimeByGroup,
    } = props;

    // Acciones
    const {
        setDwellTimeByGroup,
    } = props;
    
    const allSameDwellTime = AllSameDwellTime(dwellTimeByGroup);

    const [allDwellTime, setAllDwellTime] = useState(allSameDwellTime.isSame ? allSameDwellTime.value : dwellTimeDefaultOption);
    const [checked, setChecked] = useState(allSameDwellTime.isSame);
    // const [dwellGroups, setDwellGroups] = useState('');   
    const [rerenders, setRerenders] = useState(0);

    useEffect(() => {
        if (allSameDwellTime.isSame || checked) {
            SetAllDwellTime(allDwellTime, dwellTimeByGroup, setDwellTimeByGroup)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDwellTime, checked]);

    // useEffect(() => {
    // }, [dwellGroups])

    const handleChangeDwellTimeGroup = ( key, value ) => {

        console.log( '----> ', key, value );
        dwellTimeByGroup[key] = value;

        const newValue = dwellTimeByGroup;
        setDwellTimeByGroup(newValue);
        setRerenders(rerenders + 1);
    };

    console.log(allDwellTime);
    console.log( 'OPTIONS --> ', dwellTimeOptions );
    console.log( 'GROUPS --> ', dwellTimeGroups );

    return (
        <>
            {/* ///////////////////////////// */}
            <Box sx={{ ml: "-.7rem !important", display: "flex", alignItems: "center", mt: '1.5rem', gap: '5px' }}>
                <Box sx={{ mt: '-.4rem' }}>
                    <BlueSwitch
                        onChange={() => setChecked(!checked)}
                        checked={ checked }
                        color="default"
                    />              
                </Box>
                <Box sx={{ mr: '.6rem' }}>
                    <Typography sx={ ShiftSelectTitles } >Establecer para todos los tamaños de grupo</Typography>
                </Box>
                <Collapse orientation='horizontal' in={ checked }>
                    <ShifHourWithArrowsSelect
                        optionsArray={ dwellTimeOptions } 
                        handleChange={ setAllDwellTime }
                        value={ allDwellTime }
                    />
                </Collapse>
            </Box>
            {/* ///////////////////////////// */}
            <Collapse in={ !checked } sx={{ pt: '1.5rem' }}>
                <Grid
                    gridTemplateColumns="repeat(3, 1fr)"
                    gridTemplateRows="repeat(4, 55px)"
                    columnGap="15px"
                    display="grid"
                    width="1031px"
                    rowGap="15px"
                >
                    {dwellTimeGroups.map((dwellTimeGroup) => {                        
                        const text = dwellTimeGroup.value === '-1' ? "+12" : dwellTimeGroup.value;
                        return (
                            <Stack key={dwellTimeGroup.value} direction="row" alignItems="center">
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins',
                                        marginRight: '.5rem',
                                        fontSize: '1rem',
                                        color: '#041C32',
                                        fontWeight: 500,
                                        width: '120px',
                                    }}
                                >
                                    {`Grupo de ${text}`}
                                </Typography>
                                <ShifHourWithArrowsSelect
                                    value={ dwellTimeByGroup[dwellTimeGroup.value] }
                                    handleChange={ handleChangeDwellTimeGroup } 
                                    groupKey={ dwellTimeGroup.value } 
                                    optionsArray={ dwellTimeOptions }
                                />
                            </Stack>
                        );
                    })}
                </Grid>
            </Collapse>
        </>
    )
}

const mapStateToProps = ( state ) => {
    return {
        dwellTimeByGroup: state.v3.shifts.shiftData.dwellTimeByGroup,
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return {
        setDwellTimeByGroup: (data) => dispatch(setDwellTimeByGroup(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedOptionsGroups);