import React from 'react';

const MinusElipseIcon = (props) => {
    const {enabled, onClick} = props;

    return (
        <div 
            onClick={ enabled ? onClick : null } 
            style={{ 
                cursor: enabled ? 'pointer' : 'default', 
                alignItems: 'center', 
                display: 'flex', 
            }}
        >
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">        
                <circle cx="18" cy="21" r="17.5" stroke={enabled ? "#0B4762" : "#D1D1D1"}/>
                <path d="M24 21.998H12C11.7348 21.998 11.4804 21.8927 11.2929 21.7052C11.1054 21.5176 11 21.2633 11 20.998C11 20.7328 11.1054 20.4785 11.2929 20.2909C11.4804 20.1034 11.7348 19.998 12 19.998H24C24.2652 19.998 24.5196 20.1034 24.7071 20.2909C24.8946 20.4785 25 20.7328 25 20.998C25 21.2633 24.8946 21.5176 24.7071 21.7052C24.5196 21.8927 24.2652 21.998 24 21.998Z" 
                    fill={enabled ? "#041C32" : "#D1D1D1"}/>
            </svg>
        </div>
        
    );
}

export default MinusElipseIcon;