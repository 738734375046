import { RRule } from "rrule";
import moment from "moment";

// Constants
import { ModalMode } from "../../../../../constants/modalMode";
import { ShiftMode } from "../../../../../constants";

// Utils
import { deepCopyObject } from "../../../../../utils/arrayHelpers";

export const singularPlural = (value, singular, plural) => {
    return value === 1 ? singular : plural;
};

export const getScheduleOptions = () => {
    const cantMinutos = Array.from( Array(97).keys(), (x) => x*15 );
    const options = [];

    cantMinutos.map(( cantMinuto, index ) => (
        options.push({
            option: GetHHMM( cantMinuto ),
            value: cantMinuto,
            id: index,
        })
    ))
    
    return options.slice(1);

};

const GetHHMM = (time) => {
    const Hours = Math.floor(time /60);
    const minutes = time % 60;

    const newHours = ('00' + Hours).slice(-2);
    const newMinutes = ('00' + minutes).slice(-2);

    return `${newHours}:${newMinutes}`;
}

export const GetHHMMWithText = (time) => {
    const Hours = Math.floor(time /60);
    const minutes = time % 60;

    if (Hours === 0 && minutes === 0) {
        return '';
    } else {
        const newHours = (Hours);
        const newMinutes = ('00' + minutes).slice(-2);

        return `${newHours} HS ${newMinutes} MIN`;
    }
}


export const getLetterDay = (day) => {
    switch (day) {
        case 0:
            return 'D'
        case 1:
            return 'L'
        case 2:
            return 'M'
        case 3:
            return 'M'
        case 4:
            return 'J'
        case 5:
            return 'V'
        case 6:
            return 'S'
        default:
            return 'D'
    }
};  

export const getRRuleDay = (day) => {
    switch (day) {
        case 0:
            return RRule.SU;
        case 1:
            return RRule.MO;
        case 2:
            return RRule.TU;
        case 3:
            return RRule.WE;
        case 4:
            return RRule.TH;
        case 5:
            return RRule.FR;
        default:
            return RRule.SA;
    };
};

export const ConvertHHMM = (time) => {
    return GetHHMM(time) + 'hs';
};

export const getSubshifts = (subShifts) => {
    const res = [];

    subShifts.map((subshift, index) => (
        res.push({
            id: subshift.id,
            name: `Subturno ${index + 1}`,
            schedule: `${ConvertHHMM(subshift.from)} - ${ConvertHHMM(subshift.to)}`
        })
    ))

    return res;
}; 

export const getModalType = (mode, shift) => {
    switch (mode) {
        case ShiftMode.CREATE:
            return ModalMode.PUBLISH;
        case ShiftMode.EDIT:
            return ModalMode.CONTINUE;
        case ShiftMode.DELETE:
            if (moment(shift.advanceStart).isAfter(moment())) {
                if (shift.predecessor === undefined) {
                    return ModalMode.CANCEL_CREATION
                } else {
                    return ModalMode.CANCEL_EDITION
                }
            } else {
                return ModalMode.DELETE;
            }
        case ShiftMode.VIEW:
            return ModalMode.CONTINUE;
        case ShiftMode.DISABLE:
            return ModalMode.DISABLED;
        default:
            return ModalMode.PUBLISH;
    }
}

export const QuitEditTurno = (v3Shifts) => {
    const deepCopyShiftData = deepCopyObject(v3Shifts.shiftData);
    // console.log('deepCopyShiftData: ', JSON.stringify(deepCopyShiftData));
    // console.log('originalEditedShiftData: ', JSON.stringify(v3Shifts.originalEditedShiftData));
    return v3Shifts.mode === ShiftMode.EDIT && JSON.stringify(deepCopyShiftData) !== JSON.stringify(v3Shifts.originalEditedShiftData);
}

export function joinArrayElements(array, separator) {
    return array.join(separator);
}

export const GetHHMM24 = (date) => {
    return moment(date).utc(false).format('HH:mm');
}

export const GetFormattedDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
}

export const GetDates = (dates) => {
    const newDates = dates.map((date) => GetFormattedDate(date));    
    return joinArrayElements(newDates, ' | ');
}

export const DateBetweenDates = (date, dateFrom, dateTo) => {    
    const range = moment().range(dateFrom, dateTo);
    return range.contains(date);
}

export const HourBetweenHours = (hour, startHour, endHour) => {
    return hour >= startHour && hour <= endHour;
}

export const SameHour = (hour, id, paperList, from) => {
    const paper = paperList.find((paper) => paper.id === id);
    if (from) {
        return paper.to === hour;
    } else {
        return paper.from === hour;
    }
}

export const FromIsBeforeTo = (from, to) => {
    return from < to;
}

export const GetMomentDate = (date) => {
    return moment(date).startOf('date').utc().toISOString();
}