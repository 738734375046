import React from 'react';

const AmountPeopleIcon = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 0C4.89567 0 4.30491 0.185207 3.80242 0.532201C3.29994 0.879195 2.9083 1.37239 2.67703 1.94942C2.44577 2.52645 2.38526 3.1614 2.50316 3.77397C2.62106 4.38654 2.91207 4.94922 3.3394 5.39086C3.76672 5.8325 4.31117 6.13326 4.90389 6.25511C5.49661 6.37696 6.11098 6.31442 6.66931 6.07541C7.22764 5.8364 7.70485 5.43164 8.0406 4.91233C8.37635 4.39301 8.55556 3.78247 8.55556 3.15789C8.55556 2.32037 8.23363 1.51715 7.6606 0.924926C7.08758 0.332706 6.31038 0 5.5 0ZM5.5 5.05263C5.1374 5.05263 4.78294 4.94151 4.48145 4.73331C4.17996 4.52511 3.94498 4.2292 3.80622 3.88298C3.66746 3.53676 3.63115 3.15579 3.70189 2.78825C3.77263 2.42071 3.94724 2.0831 4.20364 1.81811C4.46003 1.55313 4.7867 1.37267 5.14233 1.29956C5.49797 1.22646 5.86659 1.26398 6.20159 1.40739C6.53658 1.55079 6.82291 1.79365 7.02436 2.10524C7.22581 2.41682 7.33333 2.78315 7.33333 3.15789C7.33333 3.66041 7.14018 4.14234 6.79636 4.49768C6.45255 4.85301 5.98623 5.05263 5.5 5.05263ZM11 12V11.3684C11 10.1959 10.5493 9.07137 9.74707 8.24226C8.94483 7.41316 7.85676 6.94737 6.72222 6.94737H4.27778C3.14324 6.94737 2.05517 7.41316 1.25293 8.24226C0.450693 9.07137 0 10.1959 0 11.3684V12H1.22222V11.3684C1.22222 10.5309 1.54415 9.72767 2.11717 9.13545C2.6902 8.54323 3.46739 8.21053 4.27778 8.21053H6.72222C7.53261 8.21053 8.3098 8.54323 8.88283 9.13545C9.45585 9.72767 9.77778 10.5309 9.77778 11.3684V12H11Z" fill="#041C32"/>
        </svg>
    )
}

export default AmountPeopleIcon;