export const CloseSidebarButttonContainer = {
    paddinLeft: '1rem'
}

export const GeneralDataContainer = {
    padding: '0 2rem 2rem 2rem', 
    width: 600,
    '& ::-webkit-scrollbar': {
        display: 'block !important',
        backgroundColor: '#F0F0F0',
        borderRadius: '6px',
        width: '.6rem',
    },
    '& ::-webkit-scrollbar-track': {
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        boxSizing: 'border-box',
    },
    '& ::-webkit-scrollbar-thumb': {
        border: '1px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        backgroundColor: '#0B4762',
        borderRadius: '10px',
    },
    '@media(min-width: 1500px)': {
        width: 700,
    }
}

export const NameSectionContainer = {
    marginTop: '1rem',
    display: 'flex',
    gap: '20px'
}

export const IconNameContainer = {
    paddingBottom: '.5rem', 
    alignItems: 'center',
    display: 'flex',
}

export const IconNameStyles = {
    backgroundColor: '#0B4762',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    padding: '1rem',
    height: '4rem',
    width: '4rem',
}

export const PhotoNameStyles = {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '4rem',
    width: '4rem',
}

export const IconNameTextStyles = {
    fontFamily: 'Poppins',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: 'white',
}

export const nameContainerSidebar = {
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flexWrap: 'wrap', 
    display: 'flex', 
    width: '100%'
} 

export const NameTextStyles = {
    fontFamily: 'Poppins',
    marginRight: '.6rem',
    fontSize: '1.6rem',
    color: '#041C32',
    fontWeight: 600,
}

export const AliasTextStyles = {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontSize: '.9rem',
    color: '#818181',
    fontWeight: 400,
}

export const CreatedAtClientStyles = {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    marginTop: '.5rem',
    fontSize: '.9rem',
    color: '#818181',
    fontWeight: 400,
}

export const DataContainerStyles = {
    overflowY: 'auto',
    padding: '1rem 0',
    maxHeight: 470,
    '@media(min-height: 800px)': {
        maxHeight: 570,
    },
    '@media(min-height: 900px)': {
        maxHeight: 600,
    },
    '@media(min-height: 930px)': {
        maxHeight: 680,
    },
    '@media(min-height: 1000px)': {
        maxHeight: 800,
    },
}

export const ItemSidebarContainer = { 
    alignItems: 'center',
    padding: '.6rem 0', 
    display: 'flex', 
}

export const TitleSidebarDataStyles = {
    fontFamily: 'Poppins',
    fontSize: '.8rem',
    color: '#818181',
    fontWeight: 500,
}

export const TextDataSidebarStyles = {
    fontFamily: 'Poppins',
    fontSize: '.8rem',
    color: '#818181',
    fontWeight: 400,
}

export const PhoneTextSidebarStyles = {
    marginBottom: '-.2rem',
    fontFamily: 'Poppins',
    fontSize: '.8rem',
    color: '#818181',
    fontWeight: 400,
}

export const CalendarMenuStyle = {
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
};

export const ApplyButtonStyles = {
    backgroundColor: '#2A2742',
    fontFamily: 'Poppins',
    textTransform: 'none',
    padding: '.3rem 1rem',
    marginRight: '15px',
    borderRadius: '8px',
    maxWidth: '100px',
    marginTop: '15px',
    fontSize: '12px',
    fontWeight: 500,
    color: 'white',
    ':hover': {
        backgroundColor: '#2A2742 !important',
        color: 'white !important',
    },
};

export const MenuButtonStyles = {
    border: '1px solid #E3E3E3',
    backgroundColor: 'white',
    textTransform: 'none',
    justifyContent: 'end',
    fontFamily: 'Poppins',
    alignItems: 'center',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#2A2742',
    display: 'flex',
    ':hover': {
        backgroundColor: 'white !important',
        color: '#2A2742 !important',
    },
};