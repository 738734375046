import React from 'react';
import MinusElipseIcon from '../../../../SvgComponents/icons/ShiftIcons/MinusElipseIcon'
import PlusElipseIcon from '../../../../SvgComponents/icons/ShiftIcons/PlusElipseIcon';
import { Box, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    InputStyles: {
        border: "1.5px solid #0B4762",
        justifyContent: "center",
        borderRadius: "7.754px",
        alignItems: "center",
        userSelect: 'none',
        padding: "9px 0px",
        display: "flex",
        width: "67px",
        height: '50px'
    },
    labelStyle: {
        color: "var(--azul-oscuro, #041C32)",
        fontFamily: "Poppins",
        lineHeight: "normal",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: 500,
    }, 
    InputDisabledStyles: {
        border: "1.5px solid #D1D1D1",
        justifyContent: "center",
        borderRadius: "7.754px",
        alignItems: "center",
        userSelect: 'none',
        padding: "9px 0px",
        display: "flex",
        width: "67px",
        height: '50px'
    },
}));

const SettingNumbers = (props) => {
    const classes = useStyles();

    // Variables
    const {
        id,
        value,
        enabled,
    } = props;

    // Acciones
    const {
        handleChangeControlValue,
    } = props;

    const Add = (id, value) => {
        const newValue = value + 1;
        handleChangeControlValue(id, newValue);
    }

    const Substract = (id, value) => {
        if (value > 0) {
            const newValue = value - 1;
            handleChangeControlValue(id, newValue);
        }        
    }
    
    return (
        <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center', justifyContent: 'center' }}>
            <MinusElipseIcon enabled={enabled} onClick={() => Substract(id, value)} />
            <div className={enabled ? classes.InputStyles : classes.InputDisabledStyles}>
                <label className={classes.labelStyle}>{value}</label>
            </div>
            <PlusElipseIcon enabled={enabled} onClick={() => Add(id, value)} />
        </Box>
        
    )
}

export default SettingNumbers;