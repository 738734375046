import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import format from 'date-fns/format';

// Actions
import { changeBookingHistoryFilterDate, setClientsPeriodSelected } from '../../../actions';
import { setBookingsPeriodSelected } from '../../../actions/myBookingsActions';

// Components
import DateRangePickerComponent from './DateRangePickerComponent';

// Icon
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// Material Ui
import { Box, Button, Menu, Stack } from '@mui/material';

// Styles
import { ApplyButtonStyles, CalendarMenuStyle, MenuButtonStyles } from './Styles/StyleComponents';

const DateRangeSelector = ( props ) => {

    // Actions
    const { changeDate, setClientsPeriodSelected, setBookingsPeriodSelected } = props;

    // External props
    const { setSelectedPeriod, dateRange, setDateRange, selectedPeriod, component } = props;

    const [labelPeriod, setLabelPeriod] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean( anchorEl );

    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = () => {
        setAnchorEl( null );
    };

    const updateFilterStates = ( from, to ) => {
        if ( component === 'clients' ) {
            setClientsPeriodSelected([{
                startDate: from,
                endDate: to,
                key: 'selection', 
            }])
        } else {
            setBookingsPeriodSelected([{
                startDate: from,
                endDate: to,
                key: 'selection', 
            }])
            
            changeDate({ from: from , to: to });
        }
    }

    const handleApply = () => {
        updateFilterStates( 
            dateRange[0].startDate, 
            dateRange[0].endDate
        );

        handleClose();
    }

    useEffect(() => {
        switch ( selectedPeriod ) {
            case 'today':
                setLabelPeriod( 'Hoy' );
                break;
            case 'yesterday':
                setLabelPeriod( 'Ayer' );
                break;
            case 'week':
                setLabelPeriod( 'Semana' );
                break;
            case 'month':
                setLabelPeriod( 'Este mes' );
                break;
            case 'year':
                setLabelPeriod( 'Este año' );
                break;
            default:
                setLabelPeriod( '' );
                break;
        }
    }, [selectedPeriod]);

    return (
        <Box>
            <Button
                aria-controls={ open ? 'basic-menu' : undefined }
                aria-expanded={ open ? 'true' : undefined }
                endIcon={ <ExpandMoreOutlinedIcon /> }
                sx={ MenuButtonStyles }
                onClick={ handleClick }
                aria-haspopup="true"
                id="basic-button"
            >
                { labelPeriod ? labelPeriod : 'Período' } : {' '}
                {`${format(dateRange[0].startDate, 'dd/MM/yyyy')}`} - {`${format(dateRange[0].endDate, 'dd/MM/yyyy')}`}
            </Button>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={ CalendarMenuStyle }
                anchorEl={ anchorEl }
                onClose={handleClose}
                id="basic-menu"
                open={ open }
            >
                <Stack alignItems="end">
                    <Box>
                        <DateRangePickerComponent
                            setSelectedPeriod={ setSelectedPeriod }
                            setDateRange={ setDateRange }
                            dateRange={ dateRange }
                        />
                    </Box>
                    <Button sx={ ApplyButtonStyles } onClick={() => handleApply()}>
                        Aplicar
                    </Button>
                </Stack>
            </Menu>
        </Box>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setBookingsPeriodSelected: (data) => dispatch(setBookingsPeriodSelected(data)),
        setClientsPeriodSelected: (data) => dispatch(setClientsPeriodSelected(data)),
        changeDate: ({from, to}) => dispatch(changeBookingHistoryFilterDate({
            from,
            to,
        })),
    }
};

export default connect( null , mapDispatchToProps )( DateRangeSelector );

