import React from 'react';

// Material Ui
import { OutlinedInput } from '@mui/material';

// Styles
import { OutlinedInputStyles } from '../Styles/OptionalStyles';

const OptionalTextField = ({ placeholder, handleChange, value, error }) => {
    return (
        <OutlinedInput 
            onChange={ (e) => handleChange( e.target.value ) }
            placeholder={ placeholder }
            sx={ OutlinedInputStyles }
            value={ value }
            error={ error } 
        />
    )
}

export default OptionalTextField;