import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import jsPDF from "jspdf";
// import "jspdf-autotable";

// Actions
import {
  changeBookingHistoryQuery,
  changeQuery,
  fetchBookingsForToday,
  fetchExportBookingsForToday,
} from "../../../../../actions";

// Components
import TablePaginationActions from "../../../../GeneralComponents/TablePaginationActions";
import BookingStatesSelect from "../Selects/BookingStatesSelect";
import ExportButton from "../Buttons/ExportButton";
import FilterButton from "../Buttons/FilterButton";
import FiltersModal from "../Modals/FiltersModal";
import InfoSelect from "../Selects/InfoSelect";
import RestaurantIcon from '@mui/icons-material/Restaurant';
// Hooks
import useDebounce from "../../../../../hooks/useDebounce";

// Icon
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

// Material Ui
import {
  TablePagination,
  InputAdornment,
  // FormControl,
  // InputLabel,
  useMediaQuery,
  TextField,
  Box,
} from "@mui/material";

import {
  SearchFieldContainerStyles,
  TextfieldContainerStyles,
  TablePaginationStyles,
  SearchTextFieldTable,
  TopTableContainer,
  FiltersContainer,
} from "./Style/Section3TableStyles";

const TableTopSection3 = (props) => {
  // Actions
  const { changeQuery, onSearch, fetchExportBookingsForToday } = props;

  // External props
  const {
    handleChangeRowsPerPage,
    handleExportStatitics,
    handleChangePage,
    exportBookings,
    moreInfoArray,
    totalResults,
    rowsPerPage,
    component,
    mobile,
    page,
  } = props;

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const debouncedSearch = useDebounce(searchFilter, 800);

  const smallMedia = useMediaQuery("(min-width:1300px)");
  const media = useMediaQuery("(min-width:1550px)");

    const handleCloseModal = () => {
        setShowFilterModal( !showFilterModal );
    };

    useEffect(() => {
        if (component !== 'clients') {
            changeQuery( debouncedSearch );
        } else {
            onSearch( debouncedSearch );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, component]);

  const handleQuerySearch = (value) => {
    setSearchFilter(value.target.value);
  };
    // const handleClickBookingsForToday = async () => {
    //     fetchBookings();
    //     setExportData(true);
    // };

  return (
    <Box
      display={{ xs: "block", md: "flex" }}
      justifyContent="space-between"
      sx={TopTableContainer}
      alignItems="center"
      flex={1}
      p={2}
    >
      <Box sx={SearchFieldContainerStyles}>
        <TextField
          placeholder={
            smallMedia ? "Busca por nombre o nro de teléfono" : "Buscar..."
          }
          onChange={(e) => handleQuerySearch(e)}
          value={searchFilter}
          fullWidth={mobile}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          sx={SearchTextFieldTable}
        />
      </Box>
      <Box sx={FiltersContainer}>
        {component !== "clients" ? (
          <Box sx={TextfieldContainerStyles}>
            <BookingStatesSelect />
          </Box>
        ) : null}
        <Box sx={TextfieldContainerStyles}>
          <InfoSelect optionsArray={moreInfoArray} component={component} />
        </Box>
        <Box sx={TextfieldContainerStyles}>
          <FilterButton handleClick={handleCloseModal} />
        </Box>
        <Box sx={TextfieldContainerStyles}>
          {component !== "clients" ? (
            <ExportButton handleClick={exportBookings} />
          ) : (
            <ExportButton handleClick={handleExportStatitics} />
          )}
        </Box>
        <Box sx={TextfieldContainerStyles}>
          {component !== "clients" && (
            <ExportButton 
                handleClick={fetchExportBookingsForToday} 
                text="Hoy"
                icon={<RestaurantIcon/>}
            />
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={media ? "Reservas por páginas" : "Páginas"}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={[20, 40, 60, 80, 100]}
          page={totalResults <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          sx={TablePaginationStyles}
          rowsPerPage={rowsPerPage}
          count={totalResults}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          component="div"
        />
      </Box>
      <FiltersModal
        handleClose={handleCloseModal}
        open={showFilterModal}
        component={component}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    filterSelected: state.myBookingsReducer.filterSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeQuery: (query) => dispatch(changeBookingHistoryQuery(query)),
    fetchBookings: () => dispatch(fetchBookingsForToday()),
    onSearch: (query) => dispatch(changeQuery(query)),
    fetchExportBookingsForToday: () => dispatch(fetchExportBookingsForToday()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableTopSection3);
