import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { sectorTypeToString } from "../../../../utils/sectors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddOrEditSector from "./Modals/AddOrEditSector";
import { DialogModal } from "../../../Modals/DialogModal";
import { connect } from "react-redux";
import LayoutCard from "./LayoutCard";
import { deleteSector, refreshError } from "../../../../actions/V3";
import AddLayoutCard from "./AddLayoutCard";

const ITEM_HEIGHT = 48;

const Sector = (props) => {
  //Actions
  const { deleteSector, refreshError } = props;

  //Reducer props
  const { error } = props;

  //External props
  const { type, name = "", description, layouts = [], id = '' } = props;
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    title: "",
    description: "",
    acceptText: "",
    cancelText: "",
    handleAccept: () => {},
    handleClosed: () => {},
  });

  const handleCloseModal = () => {
    setOpenEditorModal(false);
  };
  const handleCloseDialogModal = () => {
    setOpenDialogModal(false);
    refreshError();
  };
  const sectorType = useMemo(() => sectorTypeToString(type), [type]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const options = [
    {
      key: "edit",
      label: "Editar",
      icon: <ModeEditOutlinedIcon />,
      color: "#041C32",
      onClick: () => {
        setAnchorEl(null);
        setOpenEditorModal(true);
        },
    },
    {
      key: "delete",
      label: "Eliminar",
      icon: <DeleteOutlineOutlinedIcon />,
      color: "#FB3F4A",
      onClick: () => {
        setAnchorEl(null);
        handleDeleteSector();
      },
    },
  ];
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteSector = () => {
    setOpenDialogModal(true);
    setModalInfo({
      title: "Estas eliminando un sector",
      description: "¿Estás seguro que querés continuar?",
      acceptText: "Eliminar",
      cancelText: "Cancelar",
      handleAccept: () => {
        deleteSector(id);
        handleCloseDialogModal();
      },
      mainButtonColor: "#FB3F4A",
    });
  };

  useEffect(() => {
    if ( error && error.action === 'deleteSector' ) {
      setOpenDialogModal(true);
      setModalInfo({
        title: "No se puede eliminar el sector",
        description: "Este sector está asociado a un turno, no podrás eliminarlo hasta que no pertenezca a ningún turno",
        acceptText: "Ir a Mis turnos",
        cancelText: "Volver",
        handleAccept: () => window.location.href = "/v3/shifts",
      });
    } else if ( error && error.action === 'deleteLayout' ) {
      setOpenDialogModal(true);
      setModalInfo({
        title: "No se puede eliminar el layout",
        description: "Este layout está asociado a un turno, no podrás eliminarlo hasta que no pertenezca a ningún turno.",
        acceptText: "Ir a Mis turnos",
        cancelText: "Volver",
        handleAccept: () => window.location.href = "/v3/shifts",
      });
    }
  }, [error]);
  
  return (
    <Box>
      <Divider sx={{ borderColor: "#E7E7E7" }} />
      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        alignItems={"flex-start"}
        sx={{ fontFamily: "Poppins !important" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={2}
          padding="0.5rem 0.5rem 0.5rem 0"
          alignItems={"center"}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#041C32",
              fontSize: "1.5rem",
              fontWeight: 500,
              fontFamily: "Poppins",
            }}
          >
            {name}
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              color: "#0B4762",
              border: "1.5px solid #0B4762",
              padding: "0.2rem 0.5rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#0B4762",
                fontSize: "0.8rem",
                fontWeight: 700,
                fontFamily: "Poppins",
              }}
            >
              {sectorType}
            </Typography>
          </Box>
          <IconButton
            sx={{
              color: "#0B4762",
              fontSize: "1.5rem",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: "#0B4762", fontSize: "1.5rem" }} />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
              style: {
                paddingBottom: "0",
                paddingTop: "0",
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
                fontFamily: "Poppins",
                fontSize: "1rem",
                borderRadius: "8px",
                border: "1px solid #D1D1D1",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              },
            }}
          >
            {options.map(({ key, label, icon, color, onClick }, idx) => (
              <MenuItem
                key={key}
                onClick={onClick ?? handleClose}
                sx={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {idx > 0 && (
                  <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  padding={"0.5rem 1rem"}
                  sx={{
                    color: color,
                    "&:hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                >
                  {icon}
                  <Typography
                    variant="h6"
                    sx={{
                      color: color,
                      fontSize: "1em",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {description && (
          <Typography
            variant="h6"
            sx={{ color: "#818181", fontSize: "1rem", fontFamily: "Poppins" }}
          >
            {description}
          </Typography>
        )}
        <Box display="flex" flexWrap="wrap" gap={2} marginTop={"0.5rem"}>
          {layouts.map((layout) => (
            <LayoutCard key={layout.id} {...layout} sectorId={id} totalLayouts={layouts.length ?? 0} />
          ))}
          <AddLayoutCard
            sectorId={id}
          />
        </Box>
      </Box>
      <AddOrEditSector
        open={openEditorModal}
        handleClose={handleCloseModal}
        isAdding={false}
        {...{ name, description, type, id }}
      />
      <DialogModal
        open={openDialogModal}
        handleClose={handleCloseDialogModal}
        {...modalInfo}
      />
    </Box>
  );
};


const mapStateToProps = (state) => {
  return {
    error: state.v3.sectorsLayoutsTables.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSector: (id) => dispatch(deleteSector(id)),
    refreshError: () => dispatch(refreshError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sector);
