export const ShiftsIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3333 4.55518H3.66667C2.19391 4.55518 1 5.74908 1 7.22184V25.8885C1 27.3613 2.19391 28.5552 3.66667 28.5552H22.3333C23.8061 28.5552 25 27.3613 25 25.8885V7.22184C25 5.74908 23.8061 4.55518 22.3333 4.55518Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.3333 1.88892V7.22225" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.66675 1.88892V7.22225" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 12.5555H25" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19 17L11.4375 24.5625L8 21.125" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
