import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { DialogModal } from "../../../Modals/DialogModal";
import {
  deleteLayout,
  startDuplicatingLayout,
  startLayoutEditMode,
} from "../../../../actions/V3";
import { connect } from "react-redux";

const LayoutCard = (props) => {
  const { deleteLayout, startLayoutEditMode, startDuplicatingLayout } = props;
  const {
    name,
    tables,
    maxGroup,
    maxCapacity,
    id,
    sectorId,
    totalLayouts = 0,
    // image,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const options = useMemo(
    () => [
      {
        key: "edit",
        label: "Editar",
        icon: <ModeEditOutlinedIcon />,
        color: "#041C32",
        onClick: (e) => {
          e.stopPropagation();
          setAnchorEl(null);
          startLayoutEditMode(id, sectorId);
        },
      },
      {
        key: "duplicate",
        label: "Duplicar",
        icon: <ContentCopyOutlinedIcon />,
        color: "#041C32",
        onClick: (e) => {
          e.stopPropagation();
          setAnchorEl(null);
          startDuplicatingLayout(id, sectorId);
        },
      },
      {
        key: "delete",
        label: "Eliminar",
        icon: <DeleteOutlineOutlinedIcon />,
        color: "#FB3F4A",
        onClick: (e) => {
          e.stopPropagation();
          setAnchorEl(null);
          setOpenConfirmModal(true);
        },
      },
    ],
    []
  );

  const ITEM_HEIGHT = 48;
  return (
    <Card
      sx={{
        width: "90vw",
        maxWidth: 350,
        height: 155,
        borderRadius: "10px",
        color: "#041C32",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
        },
      }}
      variant="outlined"
    >
      <CardHeader
        title={
          <Typography variant="h6" fontWeight={500} fontFamily={"Poppins"}>
            {name}
          </Typography>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
                style: {
                  paddingBottom: "0",
                  paddingTop: "0",
                },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                  fontFamily: "Poppins",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  border: "1px solid #D1D1D1",
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                },
              }}
            >
              {options.map(({ key, label, icon, color, onClick }, idx) => (
                <MenuItem
                  key={key}
                  onClick={onClick ?? handleClose}
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {idx > 0 && (
                    <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    padding={"0.5rem 1rem"}
                    sx={{
                      color: color,
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    {icon}
                    <Typography
                      variant="h6"
                      sx={{
                        color: color,
                        fontSize: "1em",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </>
        }
        sx={{ padding: "15px 10px 10px 25px" }}
      />
      <CardContent
        sx={{ padding: "0px 10px 10px 25px" }}
        onClick={
          startLayoutEditMode ? () => startLayoutEditMode(id, sectorId) : null
        }
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
          color={"#818181"}
          marginTop={0.5}
        >
          <Typography
            variant="body2"
            fontFamily={"Poppins"}
            fontSize={"0.8rem"}
          >
            Capacidad máxima: {maxCapacity ?? "No definido"}
          </Typography>
          <Typography
            variant="body2"
            fontFamily={"Poppins"}
            fontSize={"0.8rem"}
          >
            Grupo máximo: {maxGroup ?? "No definido"}
          </Typography>
          <Typography
            variant="body2"
            fontFamily={"Poppins"}
            fontSize={"0.8rem"}
          >
            Cantidad de mesas: {tables?.length ?? "Sin mesas"}
          </Typography>
        </Box>
        {/* <Box 
          component={"img"}
          src={image}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        /> */}
      </CardContent>
      <DialogModal
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        title="Estas eliminando un layout"
        description={
          totalLayouts > 1
            ? "¿Estás seguro que querés continuar?"
            : "Tené en cuenta que si eliminas este layout, también se eliminará el sector al que está asociado"
        }
        handleAccept={() => deleteLayout(id, sectorId, totalLayouts)}
        acceptText="Eliminar Layout"
        cancelText="Volver"
        mainButtonColor="#FB3F4A"
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteLayout: (layoutId, sectorId, totalLayouts) =>
      dispatch(deleteLayout(layoutId, sectorId, totalLayouts)),
    startLayoutEditMode: (layoutId, sectorId) =>
      dispatch(startLayoutEditMode(layoutId, sectorId)),
    startDuplicatingLayout: (layoutId, sectorId) =>
      dispatch(startDuplicatingLayout(layoutId, sectorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCard);
