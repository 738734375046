import React from 'react'

const BookingHourIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7_2054)">
                <path d="M9.50016 1.58333C7.40053 1.58333 5.3869 2.4174 3.90223 3.90207C2.41757 5.38673 1.5835 7.40036 1.5835 9.49999C1.5835 11.5996 2.41757 13.6133 3.90223 15.0979C5.3869 16.5826 7.40053 17.4167 9.50016 17.4167C11.5998 17.4167 13.6134 16.5826 15.0981 15.0979C16.5828 13.6133 17.4168 11.5996 17.4168 9.49999C17.4168 7.40036 16.5828 5.38673 15.0981 3.90207C13.6134 2.4174 11.5998 1.58333 9.50016 1.58333Z" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.5 4.75V9.5L12.6667 11.0833" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7_2054">
                    <rect width="19" height="19" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BookingHourIcon