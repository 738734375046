import {
    ADD_BOOKING_HOUR_INTERVAL,
    ADD_SECTOR_TO_SHIFT,
    ADD_SUB_SHIFT_TO_SHIFT,
    CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    CHANGE_BOOKING_NOTIFICATION,
    CHANGE_RESERVAS_SHIFT_ADVANCE_END,
    CHANGE_RESERVAS_SHIFT_ADVANCE_START,
    CHANGE_RESERVAS_SHIFT_DAYS,
    CHANGE_RESERVAS_SHIFT_END_DATE,
    CHANGE_RESERVAS_SHIFT_NAME,
    CHANGE_RESERVAS_SHIFT_OPENTIME,
    CHANGE_RESERVAS_SHIFT_START_DATE,
    CHANGE_SECTOR_ON_SHIFT,
    CHANGE_SHIFT_CONFIRMATION_TOLERANCE,
    CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE,
    CHANGE_SHIFT_MODE,
    CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING,
    CHANGE_SHIFT_TOLERANCE,
    CHANGE_SUB_SHIFT,
   CHANGE_WAIT_LIST_COMMENT,
    CHANGE_WAIT_LIST_QUANTITY, CLOSE_BOOKING_ERROR,
    DELETING_SHIFT_FAILURE,
    DELETING_SHIFT_SUCCESS,
    EDIT_BOOKING_HOUR_INTERVAL,
    EDIT_BOOKING_HOUR_INTERVAL_COMPLETE,
    EDIT_BOOKING_HOUR_INTERVAL_ENABLE,
    FETCH_BOOKING_ALL_SECTORS,
    FETCH_BOOKING_ALL_SECTORS_FAILURE,
    FETCH_BOOKING_ALL_SECTORS_SUCCESS,
    FETCH_BOOKING_SETTINGS,
    FETCH_BOOKING_SETTINGS_FAILURE,
    FETCH_BOOKING_SETTINGS_SUCCESS,
    FETCH_SHIFTS,
    FETCH_SHIFTS_FAILURE,
    FETCH_SHIFTS_SUCCESS,
    RELOAD_BOOKINGS_SUCCESS,
    REMOVE_BOOKING_HOUR_INTERVAL,
    REMOVE_SECTOR_FROM_SHIFT,
    REMOVE_SUB_SHIFT_TO_SHIFT,
    RESET_ALL_DATA,
    SAVING_SHIFT_FAILURE,
    SAVING_SHIFT_SUCCESS,
    ShiftMode,
    TOGGLE_SHIFT_LOADING,
} from '../../constants';
import {scheduleToShifts,} from '../../utils/formatters';
import {deepCopy, deepCopyObject,} from '../../utils/arrayHelpers';
import {deepEqual,} from '../../utils/objectHelpers';
import {v4 as uuidv4} from 'uuid';

const initState = {
    allSectors: [],
    shifts: [],
    shift: undefined,
    originalGeneralInfo: {},
    originalEditShift: undefined,

    // settings
    generalInfo: {
        autoAcceptancePercentage: 0,
        bookingNotification: 0,
        bookingNotificationEnabled: true,
        shiftsTolerance: 0,
        confirmationTolerance: 0,
        confirmationToleranceEnable: false,
        toKnowBeforeBooking: "",
        bookingHourIntervals: [],
        bookingHourIntervalIndex: undefined,
        maxWaitingItemsQuantity: 150,
        waitListComment: "lalalalala",
    },

    timeZone: -180,

    mode: ShiftMode.VIEW,
    edited: false,
    loading: false,
    error: undefined,
    reload: false,
}

const reservasReducer = (state = initState, action) => {
    let data;
    let generalInfo;
    switch (action.type) {
        case TOGGLE_SHIFT_LOADING:
            return {...state, loading: !state.loading};

        case SAVING_SHIFT_SUCCESS:
            return {...state, loading: false, edited: false, error: undefined};

        case SAVING_SHIFT_FAILURE:
            return {...state, loading: false, error: action.payload};

        case DELETING_SHIFT_SUCCESS:
            return {...state, loading: false, edited: false, error: undefined};

        case DELETING_SHIFT_FAILURE:
            return {...state, loading: false, error: action.payload};

        case FETCH_BOOKING_SETTINGS:
            return {...state, loading: true,};
        case FETCH_BOOKING_SETTINGS_SUCCESS:
            data = action.payload;
            generalInfo = {
                autoAcceptancePercentage: Number(data['autoAcceptancePercentage']),
                bookingNotification: data['bookingNotification'] ? Number(data['bookingNotification']) : '',
                bookingNotificationEnabled: !!data['bookingNotification'],
                shiftsTolerance: isNaN(Number(data['shiftsTolerance'])) ? null : Number(data['shiftsTolerance']),
                confirmationTolerance: data["confirmationTolerance"] !== null ? Number(data['confirmationTolerance']) : null,
                confirmationToleranceEnable: data["confirmationTolerance"] !== null,
                bookingHourIntervals: data["attentionIntervals"] ? data["attentionIntervals"] : [],
                toKnowBeforeBooking: data["toKnowBeforeBooking"] ? data["toKnowBeforeBooking"] : "",
                maxWaitingItemsQuantity: data["maxWaitingItemsQuantity"] ? data["maxWaitingItemsQuantity"] : 20,
                waitListComment: data["waitListComment"] ? data["waitListComment"] : "",
            };
            return {
                ...state,
                loading: false,
                generalInfo: {...generalInfo},
                originalGeneralInfo: {...generalInfo},
            }
        case FETCH_BOOKING_SETTINGS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case FETCH_SHIFTS:
            return {...state, loading: true};
        case FETCH_SHIFTS_SUCCESS:
            data = action.payload;
            return {
                ...state,
                shifts: [...scheduleToShifts(data)],
                allSectors: data["sectors"] ? data["sectors"] : state.allSectors,
                loading: false,
            };
        case FETCH_SHIFTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case FETCH_BOOKING_ALL_SECTORS:
            return {...state, loading: true};
        case FETCH_BOOKING_ALL_SECTORS_SUCCESS:
            return {...state, allSectors: action.payload};
        case FETCH_BOOKING_ALL_SECTORS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case RELOAD_BOOKINGS_SUCCESS:
            return {...state, reload: false};
        case CLOSE_BOOKING_ERROR: {
            return {...state, error: undefined};
        }
        case CHANGE_SHIFT_MODE:
            return {
                ...state,
                mode: action.payload.mode,
                shift: action.payload.shift,
                originalEditShift: action.payload.mode === ShiftMode.EDIT ? deepCopyObject(action.payload.shift) : undefined
            };

        // Generales
        case CHANGE_SHIFT_TOLERANCE:
            generalInfo = {...state.generalInfo, shiftsTolerance: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            };
        case CHANGE_SHIFT_CONFIRMATION_TOLERANCE:
            generalInfo = {...state.generalInfo, confirmationTolerance: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            };
        case CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE:
            let confirmationTolerance;
            if (!state.generalInfo.confirmationToleranceEnable) {
                if (state.originalGeneralInfo.confirmationTolerance !== null && state.originalGeneralInfo.confirmationTolerance < state.generalInfo.bookingNotification) {
                    confirmationTolerance = state.originalGeneralInfo.confirmationTolerance;
                } else {
                    confirmationTolerance = 15;
                }
            } else {
                confirmationTolerance = null;
            }
            generalInfo = {
                ...state.generalInfo,
                confirmationToleranceEnable: !state.generalInfo.confirmationToleranceEnable,
                confirmationTolerance: confirmationTolerance,
            };
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            }
        case CHANGE_AUTO_ACCEPTANCE_PERCENTAGE:
            generalInfo = {...state.generalInfo, autoAcceptancePercentage: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            }
        case CHANGE_BOOKING_NOTIFICATION:
            generalInfo = {...state.generalInfo, bookingNotification: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            }
        case CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING:
            generalInfo = {...state.generalInfo, toKnowBeforeBooking: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            };
        case CHANGE_WAIT_LIST_QUANTITY:
            generalInfo = {...state.generalInfo, maxWaitingItemsQuantity: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            }
        case CHANGE_WAIT_LIST_COMMENT:
            generalInfo = {...state.generalInfo, waitListComment: action.payload};
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo)
            }

        // HORARIOS TOMA DE RESERVAS
        case EDIT_BOOKING_HOUR_INTERVAL:
            return {...state, generalInfo: {...state.generalInfo, bookingHourIntervalIndex: action.payload}}
        case EDIT_BOOKING_HOUR_INTERVAL_COMPLETE:
            let editBookingHour = deepCopy(state.generalInfo.bookingHourIntervals);
            const index = action.payload.index;
            editBookingHour[index] = deepCopyObject({
                ...editBookingHour[index],
                open: action.payload.open,
                duration: action.payload.duration,
                days: action.payload.days,
            });
            generalInfo = {
                ...state.generalInfo,
                bookingHourIntervals: editBookingHour,
                bookingHourIntervalIndex: undefined
            };
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo),
            }
        case ADD_BOOKING_HOUR_INTERVAL:
            generalInfo = {
                ...state.generalInfo,
                bookingHourIntervals: [...state.generalInfo.bookingHourIntervals, action.payload],
                bookingHourIntervalIndex: state.generalInfo.bookingHourIntervals.length,
            };
            return {
                ...state,
                generalInfo: generalInfo,

                edited: !deepEqual(state.originalGeneralInfo, generalInfo),
            }
        case REMOVE_BOOKING_HOUR_INTERVAL:
            generalInfo = {
                ...state.generalInfo,
                bookingHourIntervals: state.generalInfo.bookingHourIntervals.filter((item, index) => index !== action.payload),
            }
            return {
                ...state,
                generalInfo: generalInfo,
                edited: !deepEqual(state.originalGeneralInfo, generalInfo),
            };
        case EDIT_BOOKING_HOUR_INTERVAL_ENABLE:
            const newBookingHourIntervals = deepCopy(state.generalInfo.bookingHourIntervals);
            const intervalIndex = action.payload;
            const isEnable = newBookingHourIntervals[intervalIndex]["enable"];
            newBookingHourIntervals[intervalIndex]["enable"] = isEnable !== undefined ? !isEnable : false;
            generalInfo = {...state.generalInfo, bookingHourIntervals: newBookingHourIntervals};
            return {...state, generalInfo: generalInfo, edited: !deepEqual(state.originalGeneralInfo, generalInfo),}

        // EDICION TURNO
        case CHANGE_RESERVAS_SHIFT_NAME:
            return {...state, shift: {...state.shift, name: action.payload}};
        case CHANGE_RESERVAS_SHIFT_OPENTIME:
            return {...state, shift: {...state.shift, opening: action.payload}};
        case CHANGE_RESERVAS_SHIFT_ADVANCE_START:
            return {...state, shift: {...state.shift, advanceStart: action.payload}};
        case CHANGE_RESERVAS_SHIFT_ADVANCE_END:
            return {...state, shift: {...state.shift, advanceEnd: action.payload}};
        case CHANGE_RESERVAS_SHIFT_DAYS:
            return {...state, shift: {...state.shift, days: [...action.payload]}};
        case CHANGE_RESERVAS_SHIFT_START_DATE:
            return {...state, shift: {...state.shift, startDate: action.payload}};
        case CHANGE_RESERVAS_SHIFT_END_DATE:
            return {...state, shift: {...state.shift, endDate: action.payload}};

        // Subturnos
        case ADD_SUB_SHIFT_TO_SHIFT:
            let subShiftsAdded = [...state.shift?.subShifts ?? [], {...action.payload, uuid: uuidv4()}];
            return {...state, shift: {...state.shift, subShifts: deepCopy(subShiftsAdded)}};
        case REMOVE_SUB_SHIFT_TO_SHIFT:
            let subShiftsRemoved = [...state.shift?.subShifts];
            subShiftsRemoved.splice(action.payload, 1);
            return {...state, shift: {...state.shift, subShifts: deepCopy(subShiftsRemoved)}};
        case CHANGE_SUB_SHIFT:
            let subShiftsEdited = [...state.shift?.subShifts];
            subShiftsEdited[action.payload.index].from = action.payload.from !== undefined ? action.payload.from : subShiftsEdited[action.payload.index].from;
            subShiftsEdited[action.payload.index].to = action.payload.to !== undefined ? action.payload.to : subShiftsEdited[action.payload.index].to;
            subShiftsEdited[action.payload.index].subSubShifts = action.payload.subSubShifts !== undefined ? action.payload.subSubShifts : subShiftsEdited[action.payload.index].subSubShifts;
            subShiftsEdited[action.payload.index].allowsWait = action.payload.allowsWait !== undefined ? action.payload.allowsWait : subShiftsEdited[action.payload.index].allowsWait;
            return {...state, shift: {...state.shift, subShifts: deepCopy(subShiftsEdited)}};

        // Sectores
        case ADD_SECTOR_TO_SHIFT:
            let sectorAdded = [...state.shift?.sectors ?? [], {...action.payload}];
            return {...state, shift: {...state.shift, sectors: deepCopy(sectorAdded)}};
        case REMOVE_SECTOR_FROM_SHIFT:
            let sectorsRemoved = [...state.shift?.sectors].filter(sector => sector.name !== action.payload);
            return {...state, shift: {...state.shift, sectors: deepCopy(sectorsRemoved)}};
        case CHANGE_SECTOR_ON_SHIFT:
            let sectorsEdited = [...state.shift?.sectors];
            let sectorsEditedIndex = sectorsEdited.findIndex(s => s.name === action.payload.name);
            sectorsEdited[sectorsEditedIndex].minAutomatic = action.payload.minAutomatic;
            sectorsEdited[sectorsEditedIndex].maxAutomatic = action.payload.maxAutomatic;
            return {...state, shift: {...state.shift, sectors: deepCopy(sectorsEdited)}};

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default reservasReducer;
