import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';
import {useLocation} from "react-router-dom";

import PublicMenu from '../../components/PublicMenu';
import StoresBanner from './storesBanner';
import {changeShowSidebar} from "../../actions/sidebarActions";
import {connect} from "react-redux";
import NotFound from "../NotFound";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function PublicMenuPage(props) {
    const query = useQuery();

    const type = query.get("type")
    const partnerId = query.get("partnerid")

    useEffect(() => {
        props.changeShowSidebar(false);
    }, [])

    return (
        type === "carta" && partnerId && partnerId !== ""
            ?
            (<Grid container>
                <Grid item xs={12}>
                    <StoresBanner/>
                </Grid>
                <Grid item xs={12}>
                    <PublicMenu partnerId={partnerId}/>
                </Grid>
            </Grid>)
            : <NotFound/>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(PublicMenuPage);