import React, { useState, useEffect } from 'react';

// Helper
import { BpCheckedIcon, BpIcon } from '../../helpers';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { Badge, Box, Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';

// Styles
import { BadgeSelectStyles, BookingStatesSelectStyles } from '../Table/Style/Section3TableStyles';

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            border: '1px solid #0B4762',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            marginTop: '.5rem',
            width: 'auto',
        },
    },
};

const BpCheckbox = (props) => {
    return (
        <Checkbox
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            color="default"
            disableRipple
            {...props}
        />
    );
};

const GeneralSection3Select = ({ 
    placeholderText, 
    optionsArray, 
    handleChange,
    handleClose, 
    handleOpen, 
    SelectIcon, 
    value,
}) => {

    const [checked, setChecked] = useState([]);

    useEffect(() => {
        const allOptions = optionsArray.map((c) => c.option);
        setChecked( allOptions );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckAllChange = (e) => {
        if ( e.target.checked ) {
            const allOptions = optionsArray.map((c) => c.option);
            setChecked( allOptions );
        } else {
            setChecked([]);
        };
    };
    
    const handleCountryChange = ( e, c ) => {
        if ( e.target.checked ) {
            setChecked([ ...checked, c ]);
        } else {
            setChecked( checked.filter( (item) => item !== c ) );
        };
    };

    const handleChangeFilterItem = ( value ) => {
        if ( checked.includes( value ) ) {
            setChecked( checked.filter( (item) => item !== value ) );
        } else {
            setChecked([ ...checked, value ]);
        };
    };

    return (
        <Badge 
            badgeContent={ (checked.length === optionsArray.length) ? 0 : checked.length } 
            sx={ BadgeSelectStyles }
        >
            <FormControl sx={{ maxWidth: 240 }}>
                <Select
                    sx={ BookingStatesSelectStyles }
                    IconComponent={ ExpandMoreIcon }
                    onClose={ handleClose }
                    onOpen={ handleOpen }
                    renderValue={() => {
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <SelectIcon /> 
                                <Typography 
                                    sx={{ 
                                        fontFamily: 'Poppins',
                                        fontSize: '.9rem', 
                                        color: '#2A2742',
                                        fontWeight: 500,
                                        ml: '.5rem',
                                    }}
                                >{ placeholderText }</Typography>
                            </Box>
                        );
                    }}
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={ handleChange }
                    MenuProps={ MenuProps }
                    value={ value }
                    displayEmpty
                    autoWidth
                    multiple
                >
                    <MenuItem sx={{ padding: '0 .5rem 0 0' }} value={ 'Todos' }>
                        <BpCheckbox 
                            checked={ checked.length === optionsArray.length } 
                            onChange={ handleCheckAllChange } 
                        />
                        <ListItemText 
                            primary={'Todos'} 
                            sx={{ 
                                '& .MuiTypography-root': { 
                                    fontFamily: 'Poppins !important', 
                                    fontSize: '.9rem', 
                                    color: "#041C32",
                                    fontWeight: 500
                                } 
                            }}
                        /> 
                    </MenuItem>
                    {
                        optionsArray.map(( item ) =>
                            <MenuItem 
                                onClick={ () => handleChangeFilterItem( item.option ) } 
                                sx={{ padding: '0 .5rem 0 0' }}
                                value={ item.option } 
                                key={ item.id } 
                            >
                                <BpCheckbox 
                                    checked={ checked.includes( item.option ) }
                                    onChange={(e) => handleCountryChange( e, item.option )} 
                                />
                                <ListItemText 
                                    primary={ item.option } 
                                    sx={{ 
                                        '& .MuiTypography-root': { 
                                            fontFamily: 'Poppins !important', 
                                            fontSize: '.9rem', 
                                            color: "#041C32",
                                            fontWeight: 500
                                        } 
                                    }}
                                /> 
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Badge>
    )
}

export default GeneralSection3Select;