import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Modal} from '@mui/material';
import {ModalMode} from "../../../constants/modalMode";

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        top: "50%",
        left: "50%",
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        width: '580px',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        marginBottom: '1rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#737171',
    },
    ButtonsComponents: {
        display: 'flex',
        width: '100%',
        gap: '15px',
        justifyContent: 'center',
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        width: '33%',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    DeleteButton: {
        backgroundColor: 'rgba(255, 0, 0, 0.15) !important',
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #EB252E !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#EB252E !important',
        width: '30%',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.15) !important',
        }
    },
    ProgChangeButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 0rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: 'white !important',
        width: '40%',
        '&:hover': {
            backgroundColor: '#0B4762 !important',
        }
    },
    DisabledButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#E6F4FB !important',
        padding: '.4rem 0rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        width: '33%',
        '&:hover': {
            backgroundColor: '#E6F4FB !important',
        }
    }
}));

const EditModal = (props) => {

    const classes = useStyles();
    const {open, onAccept, onCancel, title, type, onClick} = props;

    const getSubtitle = () => {
        if (type === ModalMode.CONTINUE) {
            return <h3 className={classes.Subtitle}>
                Tené en cuenta que las reservas que ya tengas para este turno pueden verse <span
                style={{fontWeight: '700'}}>modificadas</span> <span style={{color: '#EB252E', fontWeight: '700'}}>o eliminadas</span>.
                Programá el cambio para una fecha posterior que no afecte reservas ya aceptadas.
            </h3>
        }
        if (type === ModalMode.PUBLISH) {
            return <h3 className={classes.Subtitle}>
                Podes programar la publicación de este turno para una fecha posterior.
            </h3>
        }
        if (type === ModalMode.CANCEL_EDITION) {
            return <h3 className={classes.Subtitle}>
                Tené en cuenta que las reservas que ya tengas para este turno editado se <span
                style={{color: '#EB252E', fontWeight: '700'}}>eliminaran</span>.
            </h3>
        }
        if (type === ModalMode.CANCEL_CREATION) {
            return <h3 className={classes.Subtitle}>
                Tené en cuenta que las reservas que ya tengas para este turno creado se <span
                style={{color: '#EB252E', fontWeight: '700'}}>eliminaran</span>.
            </h3>
        }
        return <h3 className={classes.Subtitle}>
            Tené en cuenta que las reservas que tengas para este turno se <span
            style={{color: '#EB252E', fontWeight: '700'}}>eliminarán</span>. Programá el cambio para una fecha posterior
            que no afecte reservas ya aceptadas.
        </h3>
    }

    return (
        <Modal
            open={open}
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.ModalContainer}>
                <div className={classes.IconCross} onClick={onCancel}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                    </svg>
                </div>
                <div>
                    {
                        type === ModalMode.PUBLISH ?
                            <svg width="50" height="50" viewBox="0 0 59 59" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M29.5 3.6875C15.2455 3.6875 3.6875 15.2455 3.6875 29.5C3.6875 43.7545 15.2455 55.3125 29.5 55.3125C43.7545 55.3125 55.3125 43.7545 55.3125 29.5C55.3125 15.2455 43.7545 3.6875 29.5 3.6875ZM29.5 50.9336C17.6654 50.9336 8.06641 41.3346 8.06641 29.5C8.06641 17.6654 17.6654 8.06641 29.5 8.06641C41.3346 8.06641 50.9336 17.6654 50.9336 29.5C50.9336 41.3346 41.3346 50.9336 29.5 50.9336Z"
                                    fill="#0B4762"/>
                                <path
                                    d="M26.7344 19.3594C26.7344 20.0929 27.0258 20.7963 27.5444 21.315C28.0631 21.8336 28.7665 22.125 29.5 22.125C30.2335 22.125 30.9369 21.8336 31.4556 21.315C31.9742 20.7963 32.2656 20.0929 32.2656 19.3594C32.2656 18.6259 31.9742 17.9224 31.4556 17.4038C30.9369 16.8851 30.2335 16.5938 29.5 16.5938C28.7665 16.5938 28.0631 16.8851 27.5444 17.4038C27.0258 17.9224 26.7344 18.6259 26.7344 19.3594V19.3594ZM30.8828 25.8125H28.1172C27.8637 25.8125 27.6562 26.0199 27.6562 26.2734V41.9453C27.6562 42.1988 27.8637 42.4062 28.1172 42.4062H30.8828C31.1363 42.4062 31.3438 42.1988 31.3438 41.9453V26.2734C31.3438 26.0199 31.1363 25.8125 30.8828 25.8125Z"
                                    fill="#0B4762"/>
                            </svg>
                            :
                            <svg width="50" height="50" viewBox="0 0 59 59" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z"
                                    stroke="#EB252E" strokeWidth="5.25"/>
                                <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E"
                                      strokeWidth="5.25" strokeLinecap="round"/>
                            </svg>
                    }
                </div>
                <div>
                    <h1 className={classes.Title}>
                        {title}
                    </h1>
                    {
                        getSubtitle()
                    }
                </div>
                <div className={classes.ButtonsComponents}>
                    <Button
                        className={classes.ContinuesButton}
                        onClick={onCancel}
                    >Volver</Button>
                    {type === ModalMode.CANCEL_EDITION || type === ModalMode.CANCEL_CREATION ? null : <Button
                        className={classes.ProgChangeButton}
                        onClick={onClick}
                    >Programar {type === ModalMode.PUBLISH ? "publicación" : "cambio"}</Button>}

                    {
                        type === ModalMode.DELETE || type === ModalMode.CANCEL_EDITION || type === ModalMode.CANCEL_CREATION ?
                            <Button
                                className={classes.DeleteButton}
                                onClick={onAccept}
                            >
                                Eliminar
                            </Button>
                            : type === ModalMode.DISABLED ?
                                <Button
                                    className={classes.DisabledButton}
                                    onClick={onAccept}
                                >
                                    Deshabilitar
                                </Button>
                                : type === ModalMode.CONTINUE ?
                                    <Button
                                        className={classes.DisabledButton}
                                        onClick={onAccept}
                                    >
                                        Continuar
                                    </Button>
                                    :
                                    <Button
                                        className={classes.DisabledButton}
                                        onClick={onAccept}
                                    >
                                        Publicar ahora
                                    </Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default EditModal;
