import React, { useState } from 'react'
import { connect } from 'react-redux';

// Actions
import { addSubShift, changeShiftDays, editSubShift, removeSubShift } from "../../../../../actions";

// Components
import ButtonWithIcon from '../../../../NewComponents/ButtonWithIcon/ButtonWithIcon';
import DaySelector from "../../../../GeneralComponents/daySelector";
import SelectSubTurno from './SelectSubTurno';

// Constants
import { minutesOfDay } from "../../../../../constants";

// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui
import { Grid, makeStyles } from "@material-ui/core";

// Utils
import { hhssToMinutes, MinutesTohhss, openDurationTimeToOpenClose } from "../../../../../utils/formatters";

const useStyles = makeStyles(() => ({
    Titles: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.6rem',
        fontWeight: '500',
    }
}));

const SubShiftConfig = ( props ) => {
    const classes = useStyles();
    const { shift, originalShift } = props;
    const { subShifts, days, opening } = shift;

    const {
        changeShiftDays,
        removeSubShift,
        editSubShift,
        addSubShift,
    } = props;

    const [editingSubShift, setEditingSubShift] = useState(-1);
    const [resetSubShift, setResetSubShift] = useState(-1);

    const onAddSubShift = () => {

        let newSubShift = {
            type: subShifts && subShifts.length === 0,
        }

        if (subShifts?.length === 0) {
            newSubShift.from = opening;
            newSubShift.to = MinutesTohhss(openDurationTimeToOpenClose(hhssToMinutes(opening), 60))
        } else {
            const lastSubShift = subShifts.length - 1;
            if (subShifts[lastSubShift].to)

                if (subShifts[lastSubShift].to < opening) {
                    newSubShift.from = opening;
                } else {
                    newSubShift.from = subShifts[lastSubShift].to;
                }
            if (hhssToMinutes(subShifts[lastSubShift].to) + 60 === minutesOfDay) {
                newSubShift.to = "23:59";
            } else {
                newSubShift.to = MinutesTohhss(openDurationTimeToOpenClose(hhssToMinutes(subShifts[lastSubShift].to), 60))
            }

        }

        newSubShift.subSubShifts = 0;

        if (subShifts.every(subshift => {
            return subshift.from.slice(0, 5) !== newSubShift.from
        })) {
            addSubShift(newSubShift)
            startEditing(subShifts.length);
        };
    };

    const onDayChange = (index, value) => {
        const editDays = [...days];
        editDays[index] = value;
        changeShiftDays(editDays);
    }

    const onEditedCompleted = ({openTime, closeTime, subSubShifts, allowsWait, index}) => {
        setEditingSubShift(-1);
        editSubShift({from: openTime, to: closeTime, subSubShifts: subSubShifts, index: index, allowsWait: allowsWait});
    }

    const onChangeWaitList = (value, index) => {
        editSubShift({allowsWait: value, index: index});
    }

    const startEditing = (index) => {
        if (editingSubShift !== -1) {
            setResetSubShift(editingSubShift);
        }
        setEditingSubShift(index);
    }

    const onDelete = (subShift) => {
        removeSubShift(subShift);
    }

    return (
        <Grid container direction={'column'} alignItems={'stretch'} justify={'flex-start'} spacing={0}>
            <h1 className={classes.Titles}>Días de la semana</h1>
            <Grid item xs={11} sm={11} md={11} lg={11} xg={11}>
                <DaySelector days={days} onClick={onDayChange} spacing={0} enable marginBottom={'20px'}/>
            </Grid>
            <h1 className={classes.Titles}>Subturnos</h1>
            {subShifts ? subShifts.map((subShift, index) => (
                <div key={index} style={{width: '1200px'}}>
                    <SelectSubTurno
                        title={`Subturno  ${index + 1}`}
                        marginLeft={'0px'}
                        subShift={subShift}
                        originalSubShift={originalShift?.subShifts.find(originalSubShift => originalSubShift.uuid === subShift.uuid)}
                        opening={opening}
                        editing={editingSubShift === index}
                        reset={resetSubShift === index}
                        subShifts={subShifts.slice()}
                        onFinishReset={() => setResetSubShift(-1)}
                        onClickEdit={() => startEditing(index)}
                        onChangeWaitList={(value) => onChangeWaitList(value, index)}
                        onEditCompleted={({openTime, closeTime, subSubShifts, allowsWait}) =>
                            onEditedCompleted({
                                openTime,
                                closeTime,
                                subSubShifts,
                                allowsWait,
                                index
                            })}
                        onDelete={() => onDelete(index)}
                    />
                    <br/>
                </div>
            )) : null}
            <Grid item>
                <ButtonWithIcon
                    text={'Agregar subturno'}
                    icon={<AddCircleOutlineOutlinedIcon/>}
                    onClick={onAddSubShift}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        shift: state.reservasReducer.shift,
        originalShift: state.reservasReducer.originalEditShift
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeSubShift: (subShift) => dispatch(removeSubShift(subShift)),
        changeShiftDays: (data) => dispatch(changeShiftDays(data)),
        addSubShift: (subShift) => dispatch(addSubShift(subShift)),
        editSubShift: (data) => dispatch(editSubShift(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SubShiftConfig);
