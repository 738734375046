import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        cursor: ({selected, allowReClick}) => selected && !allowReClick ? 'default' : 'pointer',
        backgroundColor: ({selected}) => selected ? '#0B4762' : 'white',
        color: ({selected}) => selected ? 'white' : '#0B4762',
        fontFamily: 'Poppins, sans-serif',
        border: '0.92px solid #0B4762',
        justifyContent: 'center',
        padding: '.5rem 1.5rem',
        borderRadius: '27px',
        alignItems: 'center',
        width: 'max-content',
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        height: '35px',
        '&:hover': {
            backgroundColor: '#0B4762',
            color: 'white',
        }
    }
}));


const TimeFilterChip = (props) => {
    const classes = useStyles(props);
    const {label, selected, allowReClick} = props;

    const {onClick} = props;

    const handleClick = () => {
        if (selected && !allowReClick) {
            return;
        }
        onClick()
    }

    return (
        <div className={classes.root} onClick={handleClick}>
            {label}
        </div>
    );
}

TimeFilterChip.defaultProps = {
    selected: false,
    label: '',
    onClick: () => {
    },
}

export default TimeFilterChip;
