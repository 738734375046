import React from "react";
import { Icon, Stack, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const AddExceptionButton = ({ onClick, ...restProps }) => {
  return (
    <Stack
      sx={{ cursor: 'pointer', width: 'max-content' }}
      alignItems="center"
      onClick={ onClick }
      direction="row"
      spacing="5px"
      {...restProps}
    >
      <Icon
        component={AddCircleOutlineOutlinedIcon}
        sx={{ fontSize: "16px", color: "#0B4762" }}
      />
      <Typography
        sx={{
          textDecoration: "underline",
          fontFamily: 'Poppins',
          fontSize: '18px',
          color: '#0B4762',
          fontWeight: 500,
        }}
        my={3}
      >
        Agregar excepción
      </Typography>
    </Stack>
  );
};

export default AddExceptionButton;
