import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { toggleDrawer } from '../../../actions/sidebarActions';

// Components
import ParentItem from './ParentItem';
import InnerItem from './InnerItem';

// Material Ui
import { Collapse, List } from '@mui/material';

const CollapseItem = ( props ) => {

    // Actions
    const { toggleDrawer } = props;

    // External props
    const { 
        enableOption,  
        iconPrimary, 
        onClickItem,
        subItems,
        title, 
        icon, 
        key, 
    } = props;

    // States
    const { location, drawerOpen } = props; 

    const [open, setOpen] = useState( false );

    useEffect(() => {
        if ( !drawerOpen ) {
            setOpen( false );
        }
    }, [ drawerOpen ]);

    const handleClickCollapse = () => {
        if (( enableOption === undefined || enableOption ) ) { 
            if ( drawerOpen ) {
                setOpen( !open );
            } else {
                toggleDrawer( true );
                setOpen( !open );
            }
        } 
    };

    const checkSomChildActive = () => {
        return subItems.some( item => item.path === location.pathname )
    };

    const someChildActive = checkSomChildActive();

    return (
        <>
            <ParentItem
                iconBackground={ someChildActive ? '#E3F2FD' : 'transparent' }
                color={ someChildActive ? '#0B4762' : '#808080' }
                icon={ someChildActive ? iconPrimary : icon }
                handleClickCollapse={ handleClickCollapse }
                spaceTop={ open ? '6px' : '0px' }
                enableOption={ enableOption }
                drawerOpen={ drawerOpen }
                onClose={ setOpen }
                title={ title }
                key={ key }
            />
            <Collapse in={ open } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        subItems.map(( item ) => (
                            <InnerItem
                                backgroundColor={ item.path === location.pathname ? '#E3F2FD' : 'transparent' }
                                color={ item.path === location.pathname ? '#0B4762' : '#808080' }
                                onClick={ () => onClickItem( item.path ) }
                                title={ item.title }
                                key={ item.title }
                            />
                        ))
                    }
                </List>
            </Collapse>
        </>
    )
}

const mapStateToProps = state => {
    return {
        drawerOpen: state.sidebarManager.drawerOpen,
        location: state.router.location,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleDrawer: ( from ) => dispatch( toggleDrawer(from) ),
    }
};

export default connect( mapStateToProps, mapDispatchToProps )( CollapseItem );