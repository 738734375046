import React, {useEffect, useState} from 'react';

// Constants
import {minutesOfDay, SUB_SHIFT_INTERVAL, SUB_SUB_SHIFT_INTERVAL} from "../../../../../constants";

// Components
import SimpleTrashIcon from '../../../../SvgComponents/icons/TrashIcons/SimpleTrashIcon';
import EditIcon from '../../../../SvgComponents/icons/EditIcon/EditIcon';
import TimePicker from '../../../../GeneralComponents/TimePicker';

// import CheckIconPrimary from '../../../../SvgComponents/icons/IconsPrimary/Only Icons/CheckIconPrimary';
// Material Ui
import {makeStyles} from '@material-ui/core/styles';

// import {Button, IconButton} from '@material-ui/core';
// import {Delete} from "@material-ui/icons";
// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Utils
import {hhssToMinutes, MinutesTohhss} from "../../../../../utils/formatters";
import {IconButton} from '@material-ui/core';
import {BlueSwitch} from "../../../../GeneralComponents/blueSwitch";
import ErrorDialog from "../../../../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../../../../constants/modalMode";

// import CheckIconBlue from '../../../../SvgComponents/icons/BookingSubShift/CheckIcon';

const useStyles = makeStyles(() => ({
    Root: {
        boxShadow: '0px 3px 6px rgb(0 0 0 / 20%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        position: 'relative',
        alignItems: 'center',
        borderRadius: '8px',
        display: 'flex',
        height: '60px',
        zIndex: '3',
    },
    title: {
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'center',
        fontSize: '.9rem',
        width: '13%',
    },
    time: {
        borderRight: '0px solid #283149',
        alignItems: 'center',
        paddingRight: '10px',
        display: 'flex',
        height: '40px',
        width: '31%',
    },
    timeTitle: {
        paddingLeft: '.6rem',
        fontWeight: '600',
        fontSize: '1rem',
        width: '30%',
    },
    timePicker: {
        marginRight: '-18px',
        width: '70%'
    },
    Booking: {
        paddingLeft: '.5rem',
        paddingRight: '10px',
        textAlign: 'center',
        width: '12%',
    },
    fix: {
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingLeft: '10px',
        display: 'flex',
        height: '45px',
        width: '10%',
    },
    text: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        color: '#041C32',
        fontSize: '14px',
        margin: '0px',
    },
    check: {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginRight: '10px',
        cursor: 'pointer',
        height: '23px',
        margin: 'auto',
        width: '23px',
    },
    checkContainer: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        margin: '0px',
    },
    waitListTitle: {
        fontWeight: '600',
        fontSize: '.9rem',
        fontFamily: 'Poppins, sans-serif',
        marginLeft: '.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        color: ({subShift}) => subShift.allowsWait ? '#283149' : '#B2B2B2'
    },
    switchRoot: {
        marginTop: '-4px'
    }
}));

const SelectSubTurno = (props) => {

    const classes = useStyles(props);

    const {title, subShift, originalSubShift, editing, opening, subShifts, index, reset} = props

    const {from, to, check, subSubShifts, allowsWait} = subShift;

    const {allowsWait: originalAllowsWait} = originalSubShift ?? {allowsWait: false};

    const {onClickEdit, onEditCompleted, onDelete, onFinishReset, onChangeWaitList} = props

    const [openTime, setOpenTime] = useState(from);
    const [closeTime, setCloseTime] = useState(to);
    const [showModal, setShowModal] = useState(false);
    // const [allowsWaitList, setAllowsWaitList] = useState(allowsWait !== undefined ? allowsWait : false);
    const [subSubTime, setSubSubTime] = useState(MinutesTohhss(subSubShifts === 0 ? hhssToMinutes(from) : hhssToMinutes(from) + (SUB_SUB_SHIFT_INTERVAL * subSubShifts)));


    let openingTime = hhssToMinutes(opening)
    subShifts.splice(index, 1);

    useEffect(() => {
        setOpenTime(from);
        setCloseTime(to);
        setSubSubTime(MinutesTohhss(subSubShifts === 0 ? hhssToMinutes(from) : hhssToMinutes(from) + (SUB_SUB_SHIFT_INTERVAL * subSubShifts)));
    }, [from, to, check, subSubShifts])

    useEffect(() => {
        if (reset) {
            setOpenTime(from);
            setCloseTime(to);
            setSubSubTime(MinutesTohhss(subSubShifts === 0 ? hhssToMinutes(from) : hhssToMinutes(from) + (SUB_SUB_SHIFT_INTERVAL * subSubShifts)));
            // setAllowsWaitList(allowsWait !== undefined ? allowsWait : false);
            onFinishReset();
        }
    }, [reset])

    const onChangeOpenTime = (value) => {
        let time = hhssToMinutes(value);
        if (time >= openingTime) {
            setOpenTime(value)
            const newCloseTime = time + SUB_SHIFT_INTERVAL >= minutesOfDay ? time + SUB_SHIFT_INTERVAL - minutesOfDay : time + SUB_SHIFT_INTERVAL;
            onChangeCloseTime(MinutesTohhss(newCloseTime));
            setSubSubTime(value);
        } else {
            setOpenTime(prevState => prevState.slice(0, 8).endsWith(":00") ? prevState + ":01" : prevState + ":00");
        }
    }

    const onChangeSubSubShifts = (value) => {
        let openTimeValue = hhssToMinutes(openTime);
        let closeTimeValue = hhssToMinutes(closeTime);
        let valueTime = hhssToMinutes(value);

        if (closeTimeValue < openTimeValue) {
            closeTimeValue += minutesOfDay;
            if (valueTime < openTimeValue) {
                valueTime += minutesOfDay;
            }
        }
        if (openTimeValue <= valueTime && valueTime < minutesOfDay) {
            setSubSubTime(value);
            if (valueTime >= closeTimeValue) {
                const newCloseTime = valueTime + SUB_SUB_SHIFT_INTERVAL >= minutesOfDay ? valueTime + SUB_SUB_SHIFT_INTERVAL - minutesOfDay : valueTime + SUB_SUB_SHIFT_INTERVAL;
                setCloseTime(MinutesTohhss(newCloseTime));
            }
        }
    }

    const onChangeCloseTime = (value) => {
        if (hhssToMinutes(value) === hhssToMinutes(openTime)) {
            setCloseTime(MinutesTohhss(hhssToMinutes(value) - 1))
        } else {
            setCloseTime(value);
        }
    }

    const getSubSubShifts = () => {
        let subSubTimeValue = hhssToMinutes(subSubTime);
        if (subSubTimeValue < hhssToMinutes(openTime)) {
            subSubTimeValue += minutesOfDay;
        }
        let timeDiff = subSubTimeValue - hhssToMinutes(openTime);
        return timeDiff / SUB_SUB_SHIFT_INTERVAL;
    }

    const handleChangeAllowWaitList = () => {
        if (originalAllowsWait !== undefined && originalAllowsWait === true && !allowsWait === false) {
            setShowModal(true);
        }
        onChangeWaitList(!allowsWait);
    }

    const onComplete = () => {
        onEditCompleted({
            openTime: openTime.substr(0, 5),
            closeTime: closeTime.substr(0, 5),
            subSubShifts: getSubSubShifts(),
            allowsWait: allowsWait
        })
    }

    return (
        <div style={{display: 'flex'}}>
            <div
                className={classes.Root}
                style={{
                    border: editing ? '1.37949px solid rgb(11, 71, 98)' : '1.37949px solid rgb(191, 187, 189)',
                    maxWidth: '82%'
                }}
            >
                <div className={classes.title}>
                    <h3 style={{fontWeight: '600', color: editing ? '#283149' : '#B2B2B2'}}>
                        {title}
                    </h3>
                </div>
                <div
                    className={classes.time}
                    style={{
                        borderLeft: editing ? '2px solid #283149' : '2px solid #B2B2B2'
                    }}
                >
                    <p
                        className={classes.timeTitle}
                        style={{
                            color: editing ? '#283149' : '#B2B2B2',
                            width: '60%'
                        }}
                    >Ingreso a partir de: </p>
                    <div className={classes.timePicker} style={{width: "40%"}}>
                        <TimePicker
                            value={openTime}
                            onChangeValue={onChangeOpenTime}
                            editable={editing}
                            disable={!editing}
                            dontChangeDisableColor
                        />
                    </div>
                </div>
                <div
                    className={classes.time}
                    style={{
                        borderLeft: editing ? '2px solid #283149' : '2px solid #B2B2B2',
                        width: "18%"
                    }}
                >
                    <p
                        className={classes.timeTitle}
                        style={{
                            color: editing ? '#283149' : '#B2B2B2',
                            width: '40%'
                        }}
                    >Hasta: </p>
                    <div className={classes.timePicker} style={{width: "65%"}}>
                        <TimePicker
                            value={subSubTime}
                            onChangeValue={onChangeSubSubShifts}
                            disableChangeFromKeyboard={true}
                            interval={SUB_SUB_SHIFT_INTERVAL}
                            editable={editing}
                            disable={true}
                            dontChangeDisableColor
                        />
                    </div>
                </div>
                <div
                    className={classes.time}
                    style={{
                        borderRight: editing ? '2px solid #283149' : '2px solid #B2B2B2',
                        width: "15%"
                    }}
                >
                    <p
                        className={classes.timeTitle}
                        style={{
                            color: editing ? '#283149' : '#B2B2B2',
                            width: "28%"
                        }}
                    >Fin: </p>
                    <div className={classes.timePicker} style={{width: "78%", marginRight: '-1rem'}}>
                        <TimePicker
                            value={closeTime}
                            onChangeValue={onChangeCloseTime}
                            editable={editing}
                            disable={!editing}
                            dontChangeDisableColor={true}
                        />
                    </div>
                </div>
                <div className={classes.Booking}>
                    <p style={{color: editing ? '#283149' : '#B2B2B2'}}> % reservable</p>
                </div>
                <div className={classes.fix}>
                    <div style={{display: 'inline-block', marginLeft: '10px'}}>
                        <div
                            style={{
                                display: editing ? 'none' : 'flex',
                                width: '85px',
                            }}
                        >
                            <div
                                onClick={() => onClickEdit()}
                            >
                                <EditIcon height={'1.5rem'}/>
                            </div>
                            <div onClick={onDelete}>
                                <SimpleTrashIcon height={'1.5rem'}/>
                            </div>
                        </div>
                        <div
                            style={{
                                display: editing ? 'flex' : 'none',
                                justifyContent: 'center',
                                paddingTop: '0rem',
                                width: '85px',
                            }}
                            onClick={onComplete}
                        >
                            <IconButton>
                                <CheckCircleIcon height={'1.5rem'} sx={{color: '#0B4762', cursor: 'pointer'}}/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.Root}
                 style={{
                     border: '1.37949px solid rgb(191, 187, 189)',
                     marginLeft: '1rem',
                     maxWidth: '18%',
                 }}>
                <div className={classes.waitListTitle} style={{marginRight: '.2rem'}}>Lista
                    de espera
                </div>
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <BlueSwitch
                    classes={{root: classes.switchRoot}}
                    checked={allowsWait}
                    onChange={handleChangeAllowWaitList}
                    color="default"/>
                </div>
            </div>
            <ErrorDialog open={showModal}
                         title={<p>
                             <span>Estas deshabilitando una</span>
                             <br/>
                             <span>lista de espera</span></p>}
                         body={<p style={{fontFamily: 'Poppins', maxWidth: '500px'}}>
                             <span>Tené en cuenta que las reservas en lista de espera que ya tengas para este subturno se </span>
                             <span
                                 style={{color: '#EB252E', fontFamily: 'Poppins', fontWeight: 'bold'}}>eliminarán</span>
                             <span>. Al finalizar la edición, vas a poder programar tus cambios para una fecha en la cual no se afecte a tus reservas en lista de espera existentes.</span>
                         </p>}
                         type={ModalMode.ERROR}
                         onAccept={() => setShowModal(false)}/>
        </div>
    )
}

export default SelectSubTurno
