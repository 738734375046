import {
    ADD_WEB_MENU,
    CHANGE_LINK_WEB_MENU,
    CHANGE_NAME_WEB_MENU,
    DELETE_WEB_MENU,
    ERROR_CHANGE_WEB_MENU,
    PARTNERS,
    SUCCESSFUL_CHANGE_WEB_MENU,
    TOGGLE_LOADING_WEB_MENU,
    TOGGLE_WEB_MENU_ACTIVE
} from "../../../constants";
import {deepEqual} from "../../../utils/objectHelpers";

const initState = {
    menus: [],
    isMenuActive: false,
    originalMenus: [],
    haveChanges: false,
    loading: true,
    error: "",
};

const editReducer = (state = initState, action) => {
    let newEditMenus = {}
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (
                action.meta &&
                action.meta.collection &&
                action.meta.collection === PARTNERS &&
                action.payload.ordered
            ) {
                let data = action.payload.ordered[0];
                return {
                    ...state,
                    isMenuActive: data?.isMenuFromUrlEnabled ?? false,
                    menus: data?.menuFrom ?? [],
                    originalMenus: {
                        menus: data?.menuFrom ?? [],
                        isMenuActive: data?.isMenuFromUrlEnabled ?? false
                    },
                    loading: false
                }
            }
            return {...state}
        case TOGGLE_LOADING_WEB_MENU:
            return {...state, loading: !state.loading}
        case TOGGLE_WEB_MENU_ACTIVE:
            return {
                ...state,
                isMenuActive: !state.isMenuActive,
                haveChanges: !deepEqual(state.originalMenus, {menus: state.menus, isMenuActive: !state.isMenuActive})
            }
        case ADD_WEB_MENU:
            newEditMenus = {...state.menus}
            newEditMenus[""] = ""
            return {
                ...state,
                menus: {...newEditMenus},
                haveChanges: !deepEqual(state.originalMenus, {menus: newEditMenus, isMenuActive: state.isMenuActive})
            }
        case CHANGE_NAME_WEB_MENU:
            newEditMenus = {...state.menus};
            const originalLink = newEditMenus[action.payload.original];
            delete newEditMenus[action.payload.original]
            newEditMenus[action.payload.edited] = originalLink;
            return {
                ...state,
                menus: {...newEditMenus},
                haveChanges: !deepEqual(state.originalMenus, {menus: newEditMenus, isMenuActive: state.isMenuActive})
            }
        case CHANGE_LINK_WEB_MENU:
            newEditMenus = {...state.menus};
            newEditMenus[action.payload.original] = action.payload.newLink
            return {
                ...state,
                menus: {...newEditMenus},
                haveChanges: !deepEqual(state.originalMenus, {menus: newEditMenus, isMenuActive: state.isMenuActive})
            }
        case DELETE_WEB_MENU:
            newEditMenus = {...state.menus}
            delete newEditMenus[action.payload]
            return {
                ...state,
                menus: {...newEditMenus},
                haveChanges: !deepEqual(state.originalMenus, {menus: newEditMenus, isMenuActive: state.isMenuActive})
            }
        case SUCCESSFUL_CHANGE_WEB_MENU:
            return {...state, loading: false, haveChanges: false}
        case ERROR_CHANGE_WEB_MENU:
            return {...state, loading: false, error: action.payload}
    }

    return state;
}

export default editReducer
