import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';
// import { fetchPlanning } from '../../actions';

// Components
import Planning from '../../components/Plannig';

const PlanningPage = ( props ) => {

    // Actions
    const { changeTitleAppBar, changeShowSidebar } = props;

    useEffect(() => {
        changeTitleAppBar('Horarios de apertura y cierre')
        changeShowSidebar(true);
        // fetchPlanning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Planning />
    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),

        // fetchPlanning: () => dispatch(fetchPlanning()),
    }
}

export default connect(null, mapDispatchToProps)(PlanningPage);
