import { RRule } from "rrule";
import {
    EDIT_OPEN_HOUR_INTERVAL_COMPLETE,
    PLANNING_SET_SPECIAL_DATES_FORM,
    EDIT_OPEN_HOUR_INTERVAL_ENABLE,
    SAVING_PLANNING_DATA_SUCCESS,
    EDIT_HAPPY_INTERVAL_COMPLETE,
    EDIT_HAPPY_INTERVALS_CANCEL,
    PLANNING_DELETE_SINGLE_DATE,
    PLANNING_CHANGE_WEEk_REPEAT,
    EDIT_HAPPY_INTERVAL_ENABLE,
    EDIT_HAPPY_HOUR_SUCCESSFUL,
    EDIT_OPEN_INTERVALS_CANCEL,
    LOAD_PLANNING_DATA_SUCCESS,
    PLANNING_SET_CLOSING_FORM,
    PLANNING_CHANGE_FROM_HOUR,
    SAVING_PLANNING_DATA_FAIL,
    PLANNING_ADD_SINGLE_DATE,
    PLANNING_SET_RANGE_HOURS,
    EDIT_OPEN_HOUR_INTERVAL,
    PLANNING_CHANGE_TO_HOUR,
    LOAD_PLANNING_DATA_FAIL,
    PLANNINGS_SHOW_SNACKBAR,
    PLANNING_SELECTED_TYPE,
    REMOVE_HAPPY_INTERVAL,
    PLANNING_ADD_NEW_DATE,
    PLANNING_EXCLUDE_DATE,
    PLANNING_REMOVE_DATES,
    REMOVE_OPEN_INTERVAL,
    PLANNING_CHANGE_DATE,
    SAVING_PLANNING_DATA,
    CHANGE_PLANNING_MODE,
    PLANNING_SHOW_ERRORS,
    EDIT_HAPPY_INTERVAL,
    RESET_PLANNING_DATA,
    ADD_HAPPY_INTERVAL,
    LOAD_PLANNING_DATA,
    CLOSE_ERROR_DIALOG,
    PLANNING_VIEW_MODE,
    PLANNING_SET_NAME,
    ADD_OPEN_INTERVAL,
    PLANNING_ID_EDIT,
    FETCH_PLANNINGS,
    RESET_ALL_DATA,
    PLANNING_MODE,
    EDIT_PLANNING,
    SAVING_EDIT_PLANNING_DATA_SUCCESS,
    SAVING_EDIT_PLANNING_DATA_FAIL,
    PLANNING_RESTART_DATES,
    SAVING_DELETE_PLANNING_DATA_SUCCESS,
    PLANNING_ADD_ALL_DATES,
    PLANNING_REMOVE_ALL_DATES,
    PLANNING_ADD_ALL_EXCLUDE_DATES,
    PLANNING_REMOVE_ALL_EXCLUDE_DATES,
} from "../../constants";
import { deepCopy, deepCopyObject } from "../../utils/arrayHelpers";
import moment from "moment";
import dayjs from "dayjs";
import { handleSetNewDates, handleSetNewSingleDates } from "../../components/Plannig/helpers";

const initState = {
    editHappyIntervalIndex: undefined,
    editOpenIntervalIndex: undefined,
    opennings: [],
    closings: [],
    specialDates: [],
    openningHoursForm: {
        rangeHours: [],
        daysSelected: [0,1,2,3,4,5,6],
        repeatEvery: 1,
        repeatStart: new Date(),
        repeatEnd: null,
        repeatAfter: null,
        enable: true
    },
    specialDatesForm: {
        rangeHours: [],
        reason: '',
        enable: true,
        specialDates: []
    },
    closingDatesForm: {
        rangeHours: [],
        reason: '',
        enabledRangeHours: true,
        enable: true,
        specialDates: []
    },
    showSaveChanges: false,
    planningSelected: 'Horario de apertura',
    happyIntervals: [],
    openIntervals: [],
    punctualDates: {
        excludeRangeSingleDates: [],
        excludeSingleDates: [],
        excludeRangeDates: [],
        rangesSingleDates: [],
        allExcludeDates: [],
        newSingleDates: [],
        excludeDate: [],
        allDates: [],
        newDates: [],
    },
    planningIdEdit: '',
    planningName: '',
    viewMode: 'VIEW',
    planningSnackbar : {
        snackbarMessage: '',
        showSnackbar: false,
        failedComponent: '',
        typeSnackbar: '',
    },
    loading: true,
    edited: false,
    reload: true,
    error: '',
}

const planningReducer = ( state = initState, action ) => {
    switch (action.type) {
        case LOAD_PLANNING_DATA:
            return {...state, loading: true, error: '' };
        case LOAD_PLANNING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                happyIntervals: action.payload.happyHourIntervals,
                happyHourEnable: true,
                happyHourEnableOriginal: true,
                openIntervals: action.payload.openingHoursIntervals,
                reload: false
            };
        case LOAD_PLANNING_DATA_FAIL:
            return {...state, loading: false, error: action.payload };

        case SAVING_PLANNING_DATA:
            return {...state, loading: true, error: '', reload: false };
        case SAVING_PLANNING_DATA_SUCCESS:
            return {
                ...state, 
                loading: false, 
                edited: false, 
                reload: true,
                planningSnackbar : {
                    snackbarMessage: 'Horario creado con éxito',
                    typeSnackbar: 'Success',
                    failedComponent: '',
                    showSnackbar: true,
                } 
            };
        case SAVING_PLANNING_DATA_FAIL:
            return {
                ...state, 
                loading: false, 
                error: action.payload, 
                reload: false,
                planningSnackbar : {
                    snackbarMessage: 'Error al crear horario',
                    typeSnackbar: 'Error',
                    failedComponent: '',
                    showSnackbar: true,
                }  
            };
        case SAVING_EDIT_PLANNING_DATA_SUCCESS:
            return {
                ...state, 
                loading: false, 
                edited: false, 
                reload: true,
                planningSnackbar : {
                    snackbarMessage: 'Horario editado con éxito',
                    typeSnackbar: 'Success',
                    failedComponent: '',
                    showSnackbar: true,
                } 
            };
        case SAVING_EDIT_PLANNING_DATA_FAIL:
            return {
                ...state, 
                loading: false, 
                error: action.payload, 
                reload: false,
                planningSnackbar : {
                    snackbarMessage: 'Error al editar horario',
                    typeSnackbar: 'Error',
                    failedComponent: '',
                    showSnackbar: true,
                }  
            };
        case SAVING_DELETE_PLANNING_DATA_SUCCESS:
            return {
                ...state, 
                loading: false, 
                edited: false, 
                reload: true,
                planningSnackbar : {
                    snackbarMessage: 'Horario eliminado con éxito',
                    typeSnackbar: 'Success',
                    failedComponent: '',
                    showSnackbar: true,
                } 
            };

        case CLOSE_ERROR_DIALOG:
            return { ...state, error: '' };
        case EDIT_HAPPY_INTERVALS_CANCEL:
            return {...state, happyIntervals: action.payload.happyIntervals, edited: false,};
        case EDIT_HAPPY_INTERVAL:
            return {...state, editHappyIntervalIndex: action.payload};
        case EDIT_HAPPY_INTERVAL_COMPLETE:
            let editHappy = deepCopy(state.happyIntervals);
            editHappy[action.payload.index] = deepCopyObject(action.payload.interval);
            return {...state, happyIntervals: editHappy, edited: true, editHappyIntervalIndex: undefined}
        case ADD_HAPPY_INTERVAL:
            return {
                ...state,
                happyIntervals: [...state.happyIntervals, action.payload],
                edited: true,
                editHappyIntervalIndex: state.happyIntervals.length
            };
        case REMOVE_HAPPY_INTERVAL:
            return {
                ...state,
                happyIntervals: state.happyIntervals.filter((item, index) => index !== action.payload),
                edited: true,
            };
        case EDIT_HAPPY_INTERVAL_ENABLE:
            const newHappyHourIntervals = deepCopy(state.happyIntervals);
            const enable = newHappyHourIntervals[action.payload]["enable"];
            newHappyHourIntervals[action.payload]["enable"] = enable !== undefined ? !enable : false;
            return {...state, edited: true, happyIntervals: newHappyHourIntervals}
        case EDIT_HAPPY_HOUR_SUCCESSFUL:
            return {...state, edited: false, loading: false,}
        case EDIT_OPEN_INTERVALS_CANCEL:
            let data = action.payload;
            return {...state, openIntervals: data['openingIntervals'], edited: false,};
        case EDIT_OPEN_HOUR_INTERVAL:
            return {...state, editOpenIntervalIndex: action.payload};
        case EDIT_OPEN_HOUR_INTERVAL_COMPLETE:
            let editOpen = deepCopy(state.openIntervals);
            const index = action.payload.index;
            editOpen[index] = deepCopyObject(action.payload.interval);
            return {...state, openIntervals: editOpen, edited: true, editOpenIntervalIndex: undefined}
        case ADD_OPEN_INTERVAL:
            return {
                ...state,
                openIntervals: [...state.openIntervals, action.payload],
                edited: true,
                editOpenIntervalIndex: state.openIntervals.length
            };
        case REMOVE_OPEN_INTERVAL:
            return {
                ...state,
                openIntervals: state.openIntervals.filter((item, index) => index !== action.payload),
                edited: true,
            };
        case EDIT_OPEN_HOUR_INTERVAL_ENABLE:
            const newOpenHourIntervals = deepCopy(state.openIntervals);
            const intervalIndex = action.payload;
            const isEnable = newOpenHourIntervals[intervalIndex]["enable"];
            newOpenHourIntervals[intervalIndex]["enable"] = isEnable !== undefined ? !isEnable : false;
            return {...state, edited: true, openIntervals: newOpenHourIntervals}
        case RESET_ALL_DATA:
            return initState;
        
        case CHANGE_PLANNING_MODE:
            return {
                ...state,
                viewMode: action.payload.mode,
                optional: action.payload.optional,
                originalEditOptional: action.payload.mode === PLANNING_MODE.EDIT ? deepCopyObject( action.payload.optional ) : undefined
            };
        case PLANNING_VIEW_MODE: 
        return { ...state, viewMode: action.payload }
        case PLANNING_SELECTED_TYPE:
            return { ...state, planningSnackbar: { failedComponent: '' },planningSelected: action.payload };
        case PLANNING_SET_NAME:
            return { ...state, showSaveChanges: true, planningName: action.payload };
        case PLANNING_SET_RANGE_HOURS:
            return { ...state, showSaveChanges: true, openningHoursForm: action.payload };
        case PLANNING_SET_SPECIAL_DATES_FORM:
            return { ...state, showSaveChanges: true, specialDatesForm: action.payload };
        case PLANNING_SET_CLOSING_FORM:
            return { ...state, showSaveChanges: true, closingDatesForm: action.payload };
        case PLANNING_CHANGE_FROM_HOUR:
            let specialDatesHoursFromCopy = [ ...state.specialDatesForm.rangeHours ];
            let openningHoursFromCopy = [ ...state.openningHoursForm.rangeHours ];
            let closingHoursFromCopy = [ ...state.closingDatesForm.rangeHours ];

            if ( action.payload.arrayDate === 'opennings' ) {
                let openingIndexFromCopy = openningHoursFromCopy.findIndex((item) => item.id === action.payload.id );

                if ( openingIndexFromCopy === -1 ) {
                    return {
                        ...state.openningHoursForm
                    }
                };

                openningHoursFromCopy[openingIndexFromCopy].from = action.payload.fromHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    openningHoursForm: {
                        ...state.openningHoursForm,
                        rangeHours: [ ...openningHoursFromCopy ]
                    }
                };
            } else if ( action.payload.arrayDate === 'closings' ) {
                let closingIndexFromCopy = closingHoursFromCopy.findIndex((item) => item.id === action.payload.id );

                if ( closingIndexFromCopy === -1 ) {
                    return {
                        ...state.closingDatesForm
                    }
                };

                closingHoursFromCopy[closingIndexFromCopy].from = action.payload.fromHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    closingDatesForm: {
                        ...state.closingDatesForm,
                        rangeHours: [ ...closingHoursFromCopy ]
                    }
                }
            } else {
                let specialDateIndexFromCopy = specialDatesHoursFromCopy.findIndex((item) => item.id === action.payload.id );

                if ( specialDateIndexFromCopy === -1 ) {
                    return {
                        ...state.specialDatesForm
                    }
                };

                specialDatesHoursFromCopy[specialDateIndexFromCopy].from = action.payload.fromHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    specialDatesForm: {
                        ...state.specialDatesForm,
                        rangeHours: [ ...specialDatesHoursFromCopy ]
                    }
                };
            }
        case PLANNING_CHANGE_TO_HOUR:
            let specialDatesHoursToCopy = [ ...state.specialDatesForm.rangeHours ];
            let openingsHoursToCopy = [ ...state.openningHoursForm.rangeHours ];
            let closingsToCopy = [ ...state.closingDatesForm.rangeHours ];
            
            if ( action.payload.arrayDate === 'opennings' ) {
                let openingIndexToCopy = openingsHoursToCopy.findIndex((item) => item.id === action.payload.id );
                
                if ( openingIndexToCopy === -1 ) {
                    return {
                        ...state.openningHoursForm
                    }
                };
                
                openingsHoursToCopy[openingIndexToCopy].to = action.payload.toHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    openningHoursForm: {
                        ...state.openningHoursForm,
                        rangeHours: [ ...openingsHoursToCopy ]
                    }
                };
            } else if ( action.payload.arrayDate === 'closings' ) {
                let closingIndexToCopy = closingsToCopy.findIndex((item) => item.id === action.payload.id );

                if ( closingIndexToCopy === -1 ) {
                    return {
                        ...state.closingDatesForm
                    }
                }

                closingsToCopy[closingIndexToCopy].to = action.payload.toHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    closingDatesForm: {
                        ...state.closingDatesForm,
                        rangeHours: [ ...closingsToCopy ]
                    }
                }
            } else {
                let specialDateIndexToCopy = specialDatesHoursToCopy.findIndex((item) => item.id === action.payload.id );

                if ( specialDateIndexToCopy === -1 ) {
                    return {
                        ...state.specialDatesForm
                    }
                };
                
                specialDatesHoursToCopy[specialDateIndexToCopy].to = action.payload.toHour;
                
                return {
                    ...state,
                    showSaveChanges: true,
                    specialDatesForm: {
                        ...state.specialDatesForm,
                        rangeHours: [ ...specialDatesHoursToCopy ]
                    }
                }
            }
        case PLANNING_CHANGE_WEEk_REPEAT:
            return { 
                ...state, 
                showSaveChanges: true, 
                openningHoursForm: {
                    ...state.openningHoursForm,
                    repeatEvery: action.payload
                } 
            };
        case PLANNING_ADD_NEW_DATE:
            return { 
                ...state, 
                showSaveChanges: true, 
                punctualDates: {
                    ...state.punctualDates,
                    newDates: [
                        ...state.punctualDates.newDates, 
                        action.payload 
                    ],
                } 
            };
        case PLANNING_ADD_ALL_DATES:
            return {
                ...state,
                showSaveChanges: true, 
                punctualDates: {
                    ...state.punctualDates,
                    allDates: [
                        ...state.punctualDates.allDates, 
                        action.payload 
                    ],
                } 
            }
        case PLANNING_REMOVE_ALL_DATES:
            let AllDatesCopy = [ ...state.punctualDates.allDates ];

            if ( action.payload.typeDate === 'singleDate' ) {
                AllDatesCopy = AllDatesCopy.filter( item => moment( item ).format('DD/MM/YYYY') !== action.payload.value );
            } else {
                const startRange = moment( action.payload.value[0] );
                const endRange = moment( action.payload.value[1] );

                AllDatesCopy = AllDatesCopy.filter(( date ) => {
                    const momentDate = moment( date );
                    return !(momentDate.isSameOrAfter( startRange ) && momentDate.isSameOrBefore( endRange ));
                });
            }

            return {
                ...state,
                showSaveChanges: true,
                punctualDates: {
                    ...state.punctualDates,
                    allDates: [ ...AllDatesCopy ]
                }
            }
        case PLANNING_EXCLUDE_DATE:
            return { 
                ...state, 
                showSaveChanges: true, 
                punctualDates: {
                    ...state.punctualDates,
                    excludeDate: [
                        ...state.punctualDates.excludeDate,
                        action.payload
                    ]
                }  
            };
        case PLANNING_ADD_ALL_EXCLUDE_DATES:
            return {
                ...state,
                showSaveChanges: true, 
                punctualDates: {
                    ...state.punctualDates,
                    allExcludeDates: [
                        ...state.punctualDates.allExcludeDates, 
                        action.payload 
                    ],
                } 
            }
        case PLANNING_REMOVE_ALL_EXCLUDE_DATES:
            let AllExcludeDatesCopy = [ ...state.punctualDates.allExcludeDates ];

            if ( action.payload.typeDate === 'singleDate' ) {
                AllExcludeDatesCopy = AllExcludeDatesCopy.filter( item => moment( item ).format('DD/MM/YYYY') !== action.payload.value );
            } else {
                const startExcludeRange = moment( action.payload.value[0] );
                const endExcludeRange = moment( action.payload.value[1] );

                AllExcludeDatesCopy = AllExcludeDatesCopy.filter(( date ) => {
                    const momentExcludeDate = moment( date );
                    return !(momentExcludeDate.isSameOrAfter( startExcludeRange ) && momentExcludeDate.isSameOrBefore( endExcludeRange ));
                });
            }

            return {
                ...state,
                showSaveChanges: true,
                punctualDates: {
                    ...state.punctualDates,
                    allExcludeDates: [ ...AllExcludeDatesCopy ]
                }
            }
        case PLANNING_REMOVE_DATES:
            let ExcludeDatesCopy = [ ...state.punctualDates.excludeDate ];
            let NewDatesCopy = [ ...state.punctualDates.newDates ];

            if ( action.payload.dateArray === 'newDate' ) {
                NewDatesCopy = NewDatesCopy.filter( item => item !== action.payload.id );

                return {
                    ...state,
                    showSaveChanges: true,
                    punctualDates: {
                        ...state.punctualDates,
                        newDates: [ ...NewDatesCopy ]
                    }
                }
            } else {
                ExcludeDatesCopy = ExcludeDatesCopy.filter( item => item !== action.payload.id );

                console.log( ExcludeDatesCopy );

                return {
                    ...state,
                    showSaveChanges: true,
                    punctualDates: {
                        ...state.punctualDates,
                        excludeDate: [ ...ExcludeDatesCopy ]
                    }
                }
            }
        case PLANNING_CHANGE_DATE:
            let ExcludeDatesCopy1 = [ ...state.punctualDates.excludeDate ];
            let NewDatesCopy1 = [ ...state.punctualDates.newDates ];

            if ( action.payload.dateArray === 'newDate' ) {
                const NewDatesCopy1Index = NewDatesCopy1.findIndex( ( item ) => item.id === action.payload.id );

                if ( NewDatesCopy1Index === -1 ) {
                    return state.punctualDates;
                }

                NewDatesCopy1[ NewDatesCopy1Index ].date = action.payload.newDate;

                return {
                    ...state,
                    showSaveChanges: true,
                    punctualDates: {
                        ...state.punctualDates,
                        newDates: [ ...NewDatesCopy1 ]
                    }
                }
            } else {
                const ExcludeDatesCopy1Index = ExcludeDatesCopy1.findIndex( ( item ) => item.id === action.payload.id );

                if ( ExcludeDatesCopy1Index === -1 ) {
                    return state.punctualDates;
                }

                ExcludeDatesCopy1[ ExcludeDatesCopy1Index ].date = action.payload.newDate;

                return {
                    ...state,
                    showSaveChanges: true,
                    punctualDates: {
                        ...state.punctualDates,
                        excludeDate: [ ...ExcludeDatesCopy ]
                    }
                }
            }
        case PLANNING_ADD_SINGLE_DATE:
            if ( action.payload.dateArray === 'newDate' ) {
                if ( action.payload.range ) {
                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            rangesSingleDates: [
                                ...state.punctualDates.rangesSingleDates,
                                action.payload.newDate
                            ]
                        }
                    };
                } else {
                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            newSingleDates: [
                                ...state.punctualDates.newSingleDates,
                                action.payload.newDate
                            ]
                        }
                    };
                }
            } else {
                if ( action.payload.range ) {
                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            excludeRangeDates: [
                                ...state.punctualDates.excludeRangeDates,
                                action.payload.newDate
                            ]
                        }
                    }
                } else {
                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            excludeSingleDates: [
                                ...state.punctualDates.excludeSingleDates,
                                action.payload.newDate
                            ]
                        }
                    }
                }
            }
        case PLANNING_DELETE_SINGLE_DATE:
            let excludeSingleDatesCopy = [ ...state.punctualDates.excludeSingleDates ];
            let rangeSingleDatesCopy = [ ...state.punctualDates.rangesSingleDates ];
            let newSingleDatesCopy = [ ...state.punctualDates.newSingleDates ];

            if ( action.payload.dateArray === 'newDate' ) {
                if ( action.payload.typeDate === 'single' ) {
                    newSingleDatesCopy = newSingleDatesCopy.filter( item => item !== action.payload.id );
                    
                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            newSingleDates: [ ...newSingleDatesCopy ]
                        }
                    };
                } else {
                    const rangeArray = action.payload.id;
                    
                    if ( rangeSingleDatesCopy.length > 0 ) {
                        for (let i = 0; i < rangeSingleDatesCopy.length; i++) {
                            if ( 
                                rangeArray[0] === moment( rangeSingleDatesCopy[i][0] ).format('DD/MM/YYYY') && 
                                rangeArray[1] === moment(rangeSingleDatesCopy[i][ rangeSingleDatesCopy[0].length - 1 ]).format('DD/MM/YYYY')
                            ) {
                                console.log( 'entrasss' );
                                rangeSingleDatesCopy.splice( i, 1 );
                            }
                        };
                    };

                    return {
                        ...state,
                        showSaveChanges: true,
                        punctualDates: {
                            ...state.punctualDates,
                            rangesSingleDates: [ ...rangeSingleDatesCopy ]
                        }
                    };
                }

            } else {
                excludeSingleDatesCopy = excludeSingleDatesCopy.filter( item => item !== action.payload.id );

                return {
                    ...state,
                    showSaveChanges: true,
                    punctualDates: {
                        ...state.punctualDates,
                        excludeSingleDates: [ ...excludeSingleDatesCopy ]
                    }
                };
            }
        case PLANNING_RESTART_DATES:
            return {
                ...state,
                punctualDates: {
                    excludeRangeSingleDates: [],
                    excludeSingleDates: [],
                    excludeRangeDates: [],
                    rangesSingleDates: [],
                    allExcludeDates: [],
                    newSingleDates: [],
                    rangesDates: [],
                    excludeDate: [],
                    allDates: [],
                    newDates: [],
                },
                openningHoursForm: {
                    rangeHours: [],
                    daysSelected: [0,1,2,3,4,5,6],
                    repeatEvery: 1,
                    repeatStart: new Date(),
                    repeatEnd: null,
                    repeatAfter: null,
                    enable: true
                },
                specialDatesForm: {
                    rangeHours: [],
                    reason: '',
                    enable: true,
                    specialDates: []
                },
                closingDatesForm: {
                    rangeHours: [],
                    reason: '',
                    enabledRangeHours: true,
                    enable: true,
                    specialDates: []
                },
            }
        case FETCH_PLANNINGS:
            if ( action.payload.arrayDate === 'opennings' ) {
                return {
                    ...state,
                    opennings: [ ...action.payload.getData ]
                }
            } else if ( action.payload.arrayDate === 'closings' ) {
                return {
                    ...state,
                    closings: [ ...action.payload.getData ]
                }
            } else {
                return {
                    ...state,
                    specialDates: [ ...action.payload.getData ]
                }
            }
        case EDIT_PLANNING:

            let newSingleDatesArray = [];
            let specialDatesArray = [];

            if ( action.payload.typeArray === 'opennings' ) {
                
                const openningIndex = state.opennings.findIndex( item => item.id === action.payload.id );

                state.openningHoursForm.repeatEvery = RRule.fromString( state.opennings[ openningIndex ].repeat ).origOptions.interval;
                state.openningHoursForm.daysSelected = state.opennings[ openningIndex ].days;
                state.openningHoursForm.enable = state.opennings[ openningIndex ].enable;
                state.planningName = state.opennings[ openningIndex ].name;
                state.planningSelected = 'Horario de apertura';

                let backOpenningRangeHours = [];

                if ( state.opennings[ openningIndex ].timeRanges && state.opennings[ openningIndex ].timeRanges.length > 0 ) {
                    for (let i = 0; i < state.opennings[ openningIndex ].timeRanges.length; i++) {
                        backOpenningRangeHours.push({
                            id: i,
                            from: state.opennings[ openningIndex ].timeRanges[i].start,
                            to: state.opennings[ openningIndex ].timeRanges[i].end,
                        });
                    }
                };

                state.openningHoursForm.rangeHours = backOpenningRangeHours;

                if ( state.opennings[ openningIndex ].startDate !== null ) {
                    state.openningHoursForm.repeatStart = dayjs( state.opennings[ openningIndex ].startDate );
                } else {
                    state.openningHoursForm.repeatStart = null;
                }

                if ( state.opennings[ openningIndex ].endDate !== null ) {
                    state.openningHoursForm.repeatEnd = dayjs( state.opennings[ openningIndex ].endDate );
                } else {
                    state.openningHoursForm.repeatEnd = null;
                }

                if ( state.opennings[ openningIndex ].specialDates ) {

                    state.punctualDates.allDates = state.opennings[ openningIndex ].specialDates;

                    // console.log( handleSetNewSingleDates( state.opennings[ openningIndex ].specialDates ) );

                    state.punctualDates.allDates = handleSetNewDates( state.opennings[ openningIndex ].specialDates );
                    state.punctualDates.newDates = handleSetNewDates( state.opennings[ openningIndex ].specialDates );

                    if ( handleSetNewSingleDates( state.opennings[ openningIndex ].specialDates ).length > 0 ) {
                        state.punctualDates.newDates = handleSetNewSingleDates( state.opennings[ openningIndex ].specialDates ) ;
                    }

                };

                if ( state.opennings[ openningIndex ].excludeDates ) {

                    state.punctualDates.allExcludeDates = state.opennings[ openningIndex ].excludeDates;

                    // console.log( handleSetNewSingleDates( state.opennings[ openningIndex ].excludeDates ) );

                    state.punctualDates.excludeSingleDates = handleSetNewDates( state.opennings[ openningIndex ].excludeDates );
                    state.punctualDates.excludeDate = handleSetNewDates( state.opennings[ openningIndex ].excludeDates );

                    if ( handleSetNewSingleDates( state.opennings[ openningIndex ].excludeDates ).length > 0 ) {
                        state.punctualDates.excludeDate = handleSetNewSingleDates( state.opennings[ openningIndex ].excludeDates ) ;
                    }

                };

            } else if ( action.payload.typeArray === 'closings' ) {

                const closingIndex = state.closings.findIndex( item => item.id === action.payload.id );

                state.closingDatesForm.specialDates = state.closings[ closingIndex ].specialDates;
                state.punctualDates.allDates = handleSetNewDates( state.closings[ closingIndex ].specialDates );
                state.punctualDates.newDates = handleSetNewDates( state.closings[ closingIndex ].specialDates );

                if ( handleSetNewSingleDates( state.closings[ closingIndex ].specialDates ).length > 0 ) {
                    state.punctualDates.newDates = handleSetNewSingleDates( state.closings[ closingIndex ].specialDates ) ;
                }

                state.closingDatesForm.enable = state.closings[ closingIndex ].enable;
                state.closingDatesForm.reason = state.closings[ closingIndex ].reason;
                state.planningName = state.closings[ closingIndex ].name;
                state.planningSelected = 'Cierre programado';

                if ( state.closings[ closingIndex ].timeRanges.length > 0 ) {

                    let backClosingsRangeHours = [];

                    if ( state.closings[ closingIndex ].timeRanges && state.closings[ closingIndex ].timeRanges.length > 0 ) {
                        for (let i = 0; i < state.closings[ closingIndex ].timeRanges.length; i++) {
                            backClosingsRangeHours.push({
                                id: i,
                                from: state.closings[ closingIndex ].timeRanges[i].start,
                                to: state.closings[ closingIndex ].timeRanges[i].end,
                            });
                        }
                    };

                    state.closingDatesForm.rangeHours = backClosingsRangeHours;
                } else {
                    state.closingDatesForm.enabledRangeHours = false;
                }

                for (let j = 0; j < state.closings[ closingIndex ].specialDates.length; j++) {
                    specialDatesArray.push( moment( state.closings[ closingIndex ].specialDates[j] ).format() );
                    newSingleDatesArray.push( 
                        moment( state.closings[ closingIndex ].specialDates[j] ).format('DD/MM/YYYY') 
                    );
                }

                state.punctualDates.newSingleDates = newSingleDatesArray;
                state.punctualDates.newDates = specialDatesArray;

            } else {

                const specialDateIndex = state.specialDates.findIndex( item => item.id === action.payload.id );

                state.specialDatesForm.specialDates = state.specialDates[ specialDateIndex ].specialDates;
                state.punctualDates.allDates = handleSetNewDates( state.specialDates[ specialDateIndex ].specialDates );
                state.punctualDates.newDates = handleSetNewDates( state.specialDates[ specialDateIndex ].specialDates );

                if ( handleSetNewSingleDates( state.specialDates[ specialDateIndex ].specialDates ).length > 0 ) {
                    state.punctualDates.newDates = handleSetNewSingleDates( state.specialDates[ specialDateIndex ].specialDates ) ;
                }

                state.specialDatesForm.enable = state.specialDates[ specialDateIndex ].enable;
                state.specialDatesForm.reason = state.specialDates[ specialDateIndex ].reason;
                state.planningName = state.specialDates[ specialDateIndex ].name;
                state.planningSelected = 'Fechas especiales';

                for (let j = 0; j < state.specialDates[ specialDateIndex ].specialDates.length; j++) {
                    specialDatesArray.push( moment( state.specialDates[ specialDateIndex ].specialDates[j] ).format() );
                    newSingleDatesArray.push( 
                        moment( state.specialDates[ specialDateIndex ].specialDates[j] ).format('DD/MM/YYYY') 
                    );
                }

                state.punctualDates.newSingleDates = newSingleDatesArray;
                state.punctualDates.newDates = specialDatesArray;

                let backSpecialDatesRangeHours = [];

                if ( state.specialDates[ specialDateIndex ].timeRanges && state.specialDates[ specialDateIndex ].timeRanges.length > 0 ) {
                    for (let i = 0; i < state.specialDates[ specialDateIndex ].timeRanges.length; i++) {
                        backSpecialDatesRangeHours.push({
                            id: i,
                            from: state.specialDates[ specialDateIndex ].timeRanges[i].start,
                            to: state.specialDates[ specialDateIndex ].timeRanges[i].end,
                        });
                    }
                };

                state.specialDatesForm.rangeHours = backSpecialDatesRangeHours;

            }
            return {
                ...state
            }
        case PLANNING_ID_EDIT: 
            return {
                ...state,
                loading: false,
                showSaveChanges: true,
                planningIdEdit: action.payload
            }
        case RESET_PLANNING_DATA:
            return { 
                ...state,
                openningHoursForm: {
                    rangeHours: [],
                    daysSelected: [0,1,2,3,4,5,6],
                    repeatEvery: 1,
                    repeatStart: new Date(),
                    repeatEnd: null,
                    repeatAfter: null
                },
                specialDatesForm: {
                    rangeHours: [],
                    reason: '',
                    enable: true,
                    specialDates: []
                },
                closingDatesForm: {
                    rangeHours: [],
                    reason: '',
                    enabledRangeHours: true,
                    enable: true,
                    specialDates: []
                },
                showSaveChanges: false,
                planningSelected: 'Horario de apertura',
                happyIntervals: [],
                openIntervals: [],
                punctualDates: {
                    excludeRangeSingleDates: [],
                    excludeSingleDates: [],
                    excludeRangeDates: [],
                    rangesSingleDates: [],
                    allExcludeDates: [],
                    newSingleDates: [],
                    excludeDate: [],
                    rangesDates: [],
                    newDates: [],
                    allDates: [],
                },
                planningSnackbar : {
                    snackbarMessage: '',
                    showSnackbar: false,
                    failedComponent: '',
                    typeSnackbar: '',
                },
                planningIdEdit: '',
                planningName: '', 
            };
        case PLANNING_SHOW_ERRORS: 
            return {
                ...state,
                error: action.payload.errorMessage
            }
        case PLANNINGS_SHOW_SNACKBAR:
            return { ...state, planningSnackbar: action.payload }
        default:
            return state;
    }
}

export default planningReducer;

