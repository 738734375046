import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { setRotationControlByGroup } from "../../../../../actions/shiftsActions";

// Components
import TableCustomizeRotationGroupItem from "./TableCustomizeRotationGroupItem";
import { Title } from "../../Title";
import Divider from "./Divider";

// Material Ui
import { Box, Button, Modal, Stack, Typography} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { GetAdvancedModalOptions, GetDwellTimeOptions, GetRotationControlByGroups } from "../utils/advancedOptionsFunctions";
import { dwellTimeGroups, rotationOptionsDefaultValue } from "../utils/constants";

// Styles
import { modalStyles, modalTitle } from "../utils/styles/modalStyles";
import { SettingsTables2Styles } from "../../../Styles";

const useStyles = makeStyles(() => (modalStyles));

const AdvancedOptionsCustomizeRotationByGroupModal = (props) => {
  const classes = useStyles();

  // Variables
  const {
    rotationControlByGroup,
    reservableSectors,
    dwellTimeByGroup,
  } = props;

  // Acciones 
  const {
    setRotationControlByGroup,
    handleClose,
  } = props;

  const newReservableSectors = reservableSectors.filter( (reservableSector) => reservableSector.available );
  
  const [reservableSector, setReservableSector] = useState(newReservableSectors.length > 0 ? newReservableSectors[0] : null);
  const [rotationControlGroup, setRotationControlGroup] = useState([]);
  
  useEffect(() => {   
    const newArray = GetDwellTimeOptions( dwellTimeByGroup, dwellTimeGroups, rotationControlByGroup, rotationOptionsDefaultValue );
    setRotationControlGroup(
      GetAdvancedModalOptions(
        reservableSector.id, 
        newArray, 
        rotationControlByGroup, 
        rotationOptionsDefaultValue, 
        'group'
      )
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservableSector, dwellTimeByGroup]);

  const handleApplyChanges = () => {
    GetRotationControlByGroups( 
      rotationControlGroup, 
      rotationControlByGroup, 
      setRotationControlByGroup
    );
    
    handleClose();
  };

  return (
    <Modal open>
      <Box className={classes.ModalContainer}>
        <Title text="Personalizar rotación por grupos" style={modalTitle} />          
        <Stack direction="row" justifyContent="center">
          <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }}/>
        </Stack>
        <Stack style={modalStyles.ItemsHeader}>
          <Box width="10%" display="flex" flexDirection="row" justifyContent="start">
            <Typography className={classes.ItemsTitle}>                
            </Typography>
          </Box>
          <Box width="25%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Tamaño
            </Typography>
          </Box>
          <Box width="25%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Permanencia
            </Typography>
          </Box>
          <Box width="40%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Rotaciones mínimas
            </Typography>
          </Box>                
        </Stack>
        <Box sx={ SettingsTables2Styles }>
            {
              rotationControlGroup.map((rotationControl) => (
                <TableCustomizeRotationGroupItem 
                  setRotationControlGroup={ setRotationControlGroup } 
                  rotationControlByGroup={ rotationControlByGroup }
                  rotationControlGroup={ rotationControlGroup } 
                  rotationControl={ rotationControl } 
                  key={ rotationControl.id } 
                />
              ))
            }
        </Box>          
        <Stack style={modalStyles.ButtonContainer}>
          <Button className={classes.CancelButton} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={classes.ApplyButton} onClick={handleApplyChanges}>
            Aplicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {            
      reservableSectors: state.v3.shifts.reservableSectors,
      dwellTimeByGroup: state.v3.shifts.shiftData.dwellTimeByGroup,
      rotationControlByGroup: state.v3.shifts.shiftData.rotationControl.rotationControlByGroup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRotationControlByGroup: (data) => dispatch(setRotationControlByGroup(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedOptionsCustomizeRotationByGroupModal);
