import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Poppins',
    textAalign: 'left',
    lineHeight: '42px',
    fontSize: '28px',
    letterSpacing: 0,
    fontWeight: 500,
  },
}));

const Header = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <Typography variant="h1" className={classes.title} {...restProps}>
      { text }
    </Typography>
  );
};

export default Header;