import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui 
import { Box, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { ShiftSimpleSelectStyles } from '../../../../Styles';

const ShiftSimpleSelect = ({ handleChange, value, optionsArray, error = false, type, disabled }) => {
    
    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                width: 'max-content',
                marginTop: '.2rem',
                minWidth: '78px',
            },
        },
    };

    return (
        <FormControl error={ error } sx={{ width: 'max-content' }}>
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                sx={ ShiftSimpleSelectStyles }
                input={ <OutlinedInput /> }
                placeholder='Seleccionar'
                MenuProps={ MenuProps }
                disabled={ disabled }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            key={ type === 'percentage' ?  item.value : item.id } 
                            value={ item.value } 
                            sx={{ 
                                padding: '.5rem', 
                                display: 'flex',
                                height: '2.5rem' 
                            }}
                        >
                            <ListItemText 
                                primary={ 
                                    type === 'percentage' ? 
                                        `${ item.value }%` 
                                    : 
                                        type === 'hours' ? 
                                            `${ item.option }HS` 
                                        : 
                                            item.option 
                                }
                                
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            />
                            <Box sx={{ width: '34px' }}></Box> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ShiftSimpleSelect;
