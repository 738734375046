import React from 'react'
import {Grid, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        width: '160px'
    },
    text: {
        margin: '0px',
        fontSize: '14px',
        fontWeight: '500',
    },
    checkContainer: {
        height: '17px',
        width: '17px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        margin: '0px',
        backgroundColor: 'white',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1
    },
    check: {
        position: 'absolute', 
        zIndex: 2, 
        left: '-5px', 
        top: '-3px'
    }
}));

const CheckWOption = (props) => {

    const classes = useStyles();

    const {checked} = props

    const {onChange} = props

    return (
        <div className={classes.root} >
            <Grid container onClick={onChange}>
                <Grid item xs={2}>
                    <div className={classes.checkContainer}>
                            <div className={classes.check}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 30 30">
                                    <defs>
                                        <filter id="Elipse_318" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse">
                                        <feOffset dy="3" input="SourceAlpha"/>
                                            <feGaussianBlur stdDeviation="3" result="blur"/>
                                            <feFlood floodOpacity="0.161"/>
                                            <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                   {checked ? <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Elipse_318)">
                                        <circle id="Elipse_318-2" data-name="Elipse 318" cx="9.5" cy="9.5" r="9.5" transform="translate(9 6)" fill="#0076ff"/>
                                    </g> : null}
                                </svg>
                            </div>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div style={{paddingBottom: '2px'}}>
                        <p className={classes.text}>{props.name}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CheckWOption
