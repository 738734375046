import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        backgroundColor: '#0B4762',
        borderRadius: '8px',
        textAlign: 'center',
        userSelect: 'none',
        paddingTop: '8px',
        fontWeight: '600',
        cursor: 'pointer',
        fontSize: '15px',
        width: '160px',
        height: '37px',
        color: 'white',
    }
}));

const ListoButton = (props) => {
    const classes = useStyle();

    const { onClick } = props

    return (
        <div className={classes.root} onClick={onClick}>
            Finalizar
        </div>
    )
}

export default ListoButton;
