import React from 'react';
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import CurveLineChart from "./Charts/CurveLineChart/CurveLineChart";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Help from '../SvgComponents/Icons Tables/Help';

const useStyle = makeStyles(() => ({
    root: {
        background: 'linear-gradient(0deg, #4E00CC10, #915AFF10)',
        border: 0,
        borderRadius: 20,
        boxShadow: '0 3px 6px 2px rgba(0, 0, 0, .16)',
        width: '350px',
        fontFamily: 'Montserrat',
        // height: '240px'
    },
    paperPercentage: {
        border: 0,
        borderRadius: 20,
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        background: 'white'
    },
    bestTime: {
        fontWeight: 'bold',
        fontSize: 15,
        color: '#15D615'
    },
    worseTime: {
        fontWeight: 'bold',
        fontSize: 15,
        color: '#EB3030'
    },
    averageTime: {
        fontWeight: 'bold',
        fontSize: 45,
        margin: '0px',
        marginLeft: '6px',
    },
    title: {
        fontWeight: 'bold',
        margin: '0px',
        fontSize: '15px',
    },
    subtitle: {
        margin: '0px',
        color: '#848282',
        fontSize: '13px',
        fontWeight: '500',
        marginTop: '-5px',
        marginBottom: '6px'
    }
}))

const GraphicTimeCard = (props) => {
    const classes = useStyle();
    const {id, average, percentage, time, title, subtitle, helperText} = props;

    return (
        <div style={{position: 'relative'}}>
            <Card className={classes.root}>
                <CardContent>
                    <div style={{position: 'relative', height: '60px'}}>
                        <div style={{position: 'absolute'}}>
                            <h4 className={classes.title}>{title}</h4>
                            <p className={classes.subtitle}>{subtitle}</p>
                        </div>
                        <div style={{marginLeft: '-20px', position: 'absolute', zIndex: 100, marginTop: '-15px'}}>
                            <Help
                                texts={helperText}/>
                        </div>
                    </div>
                    <Grid container direction={'column'}>
                        <Grid
                            container
                            item
                            xs={12}
                            direction={"row"}
                            alignItems={"baseline"}
                            justify={'space-between'}
                            style={{marginTop: '-5px', marginBottom: '10px'}}
                        >
                            <div>
                                <p className={classes.averageTime}>
                                    {average.toFixed(0)} <span style={{fontSize: '35px'}}>{` min.`}</span>
                                </p>
                            </div>
                            {
                                percentage === undefined
                                ? (
                                        <div>
                                            <Grid container direction={"row"}>
                                                <Typography className={classes.bestTime}>
                                                    {`0 %`}
                                                </Typography>
                                            </Grid>
                                        </div>
                                    )
                                : percentage < 1
                                ?
                                (
                                    <div>
                                        <Grid container direction={"row"}>
                                            <ArrowDownward style={{color: "#15D615"}}/>
                                            <Typography className={classes.bestTime}>
                                                {`${((1 - percentage) * 100).toFixed(0)} %`}
                                            </Typography>
                                        </Grid>
                                    </div>
                                )
                                :
                                (<div>
                                        <div className={classes.paperPercentage}>
                                            <Grid container direction={"row"} alignItems={"center"}>
                                                <ArrowUpward style={{color: "#EB3030"}}/>
                                                <Typography className={classes.worseTime}>
                                                    {`${(percentage * 100).toFixed(0)}%`}
                                                </Typography>
                                            </Grid>
                                        </div>
                                        <div style={{width: '70px', paddingLeft: '10px'}}>
                                            <p
                                                style={{
                                                    margin: '0px',
                                                    fontWeight: '700',
                                                    color: '#848282'
                                                }}
                                            >vs 5 min</p>
                                        </div>
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{marginTop: '10px'}}>
                                <CurveLineChart id={id} data={time}/>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default GraphicTimeCard;