import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import CheckIconPrimary from '../../SvgComponents/icons/IconsPrimary/Only Icons/CheckIconPrimary';

const useStyles = makeStyles(() => ({
    root: {
        width: '160px'
    },
    text: {
        margin: '0px',
        fontSize: '14px',
        fontWeight: '500',
    },
    check: {
        height: '17px',
        width: '17px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        margin: '0px',
        backgroundColor: 'white',
        cursor: 'pointer'
    },
    checkContainer: {
        height: '17px',
        width: '17px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        margin: '0px',
        backgroundColor: 'white',
        cursor: 'pointer'
    }
}));

const Categories = (props) => {

    const classes = useStyles();

    const {name, checked} = props;

    const {onChange} = props;

    return (
        <div
            className={classes.root}
            onClick={() => onChange(!checked)}
        >
            <Grid container>
                <Grid item xs={2}>
                    <div className={classes.checkContainer}>
                        <div className={classes.check} style={{display: checked ? 'block' : 'none'}}>
                            <CheckIconPrimary />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div style={{paddingBottom: '2px'}}>
                        <p className={classes.text}>{name}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Categories
