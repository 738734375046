import {
    CHANGE_EDIT_USER_PASSWORD,
    CHANGE_EDIT_USER_PASSWORD_TWO,
    CHANGE_RESET_PASSWORD_EMAIL,
    CHANGE_RESET_PASSWORD_ERROR,
    CHANGE_SIGNIN_EMAIL,
    CHANGE_SIGNIN_ERROR,
    CHANGE_SIGNIN_PASSWORD,
    EDIT_PASSWORD_CANCEL,
    EDIT_PASSWORD_SUCCESS,
    RESET_ALL_DATA,
    SET_ERROR_NEW_PASSWORD,
    SET_ERROR_NEW_PASSWORD_TWO,
    SIGNIN_ERROR,
    SIGNIN_SUCCESS,
    SIGNOUT_ERROR,
    SIGNOUT_SUCCESS,
    SWITCH_NEW_PASSWORD_DIALOG,
    SWITCH_SIGN_FORM,
} from '../../constants';

const initState = {
    currentForm: 0,
    email: '',
    password: '',
    authError: null,

    resetPasswordEmail: '',
    resetPasswordError: '',

    newPasswordDialog: false,
    newPassword: '',
    newPasswordTwo: '',
    newPasswordError: false,
    newPasswordTwoError: false,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SWITCH_SIGN_FORM:
            return { ...state, currentForm: action.payload };
        case CHANGE_SIGNIN_EMAIL:
            return { ...state, email: action.payload, authError: null };
        case CHANGE_SIGNIN_PASSWORD:
            return { ...state, password: action.payload, authError: null };
        case CHANGE_SIGNIN_ERROR:
            return { ...state, authError: action.payload };
        case SIGNIN_SUCCESS:
            return { ...state, authError: null, email: '', password: '', };
        case SIGNIN_ERROR:
            return { ...state, authError: 'Error al ingresar' };
        case CHANGE_RESET_PASSWORD_EMAIL:
            return { ...state, resetPasswordEmail: action.payload, };
        case CHANGE_RESET_PASSWORD_ERROR:
            return { ...state, resetPasswordError: action.payload, };
        case SIGNOUT_SUCCESS:
            return state;
        case SIGNOUT_ERROR:
            return state;

        case SWITCH_NEW_PASSWORD_DIALOG:
            return { ...state, newPasswordDialog: !state.newPasswordDialog };
        case CHANGE_EDIT_USER_PASSWORD:
            return { ...state, newPassword: action.payload };
        case CHANGE_EDIT_USER_PASSWORD_TWO:
            return { ...state, newPasswordTwo: action.payload };
        case SET_ERROR_NEW_PASSWORD:
            return { ...state, newPasswordError: action.payload };
        case SET_ERROR_NEW_PASSWORD_TWO:
            return { ...state, newPasswordTwoError: action.payload };
        case EDIT_PASSWORD_SUCCESS:
            return { ...state, newPasswordDialog: false, newPassword: '', newPasswordTwo: '', newPasswordError: false, newPasswordTwoError: false, };
        case EDIT_PASSWORD_CANCEL:
            return { ...state, newPasswordDialog: false, newPassword: '', newPasswordTwo: '', newPasswordError: false, newPasswordTwoError: false, };

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default authReducer;
