import React from 'react'
import TimeField from "react-simple-timefield";

// Components
import DownArrow from "../SvgComponents/downArrow";
import UpArrow from "../SvgComponents/upArrow";

// Material ui
import {FormHelperText, InputBase, makeStyles, Paper} from "@material-ui/core";

// Utils
import {hhssToMinutes, MinutesTohhss} from "../../utils/formatters";

const useStyles = makeStyles({
    paper: {
        borderRadius: 20,
        maxHeight: 50,
    },
    mainContainer: {
        fontFamily: 'Poppins, sans-serif',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: '20px',
        marginLeft: '20px',
        display: 'flex',
        maxHeight: 40,
    },
    arrows: {
        maxHeight: 40,
        marginTop: '-5px',
        display: (props) => props.editable ? 'block' : 'none',
    },
    input: {
        color: (props) => {
            if (props.disable && !props.dontChangeDisableColor)
                return "#AFAFAF"
            return "#0B4762"
        },
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        fontSize: "16px",
    },
    upArrow: {
        cursor: 'pointer',
        color: (props) => {
            if (props.disable && !props.dontChangeDisableColor)
                return "#AFAFAF"
            return "#0B4762"
        },
        "&:hover": {
            backgroundColor: "transparent",
        }
    },
    downArrow: {
        cursor: 'pointer',
        color: (props) => {
            if (props.disable && !props.dontChangeDisableColor)
                return "#AFAFAF"
            return "#0B4762"
        },
        "&:hover": {
            backgroundColor: "transparent",
        }
    },
    divider: {
        backgroundColor: '#D0D0D0',
        paddingLeft: 2,
        marginLeft: 10,
        height: "30px"
    }
})

const TimePicker = (props) => {
    const classes = useStyles(props);
    const {value, onChangeValue, editable, disable, together, helper, disableChangeFromKeyboard, interval} = props;

    const handleIconClick = (v) => {
        let time = hhssToMinutes(value);
        time += v;
        if (time >= 1440) time -= 1440;
        else if (time < 0) time += 1440
        const newValue = MinutesTohhss(time);
        onChangeValue(newValue);
    }
    
    return (
        <div>
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.mainContainer}>
                    <div style={{color: editable ? '#BFBBBD' : '#041C32' }}>
                        <TimeField
                            className={classes.input}
                            style={{color: editable ? "#0B4762" : "#AFAFAF"}}
                            value={value}
                            onChange={(e, time) => {
                                if (!disableChangeFromKeyboard)
                                    onChangeValue(time)
                            }}
                            input={
                                <InputBase
                                    disabled={disable}
                                    value={value}
                                    fullWidth={false}
                                />
                            }
                        />
                    </div>
                    <div className={classes.arrows}>
                        <div className={classes.upArrow}
                            onClick={() => disable ? disableChangeFromKeyboard ? handleIconClick(interval ? interval : 15) : null : handleIconClick(interval ? -interval : 15)}>
                            <UpArrow width={12.5} height={12.5}/>
                        </div>
                        <div className={classes.downArrow}
                            onClick={() => disable ?  disableChangeFromKeyboard ? handleIconClick(interval ? -interval : -15) : null : handleIconClick(interval ? -interval : -15)}>
                            <DownArrow width={12.5} height={12.5}/>
                        </div>
                    </div>
                </div>
            </Paper>
            {helper ? <FormHelperText>{helper}</FormHelperText> : null}
        </div>
    )
}

export default TimePicker