import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setAditionalInfo } from '../../../../../actions/shiftsActions';

// Components
import TextArea from '../../../../GeneralComponents/Input General/TextArea';
import { BlueSwitch } from '../../../../GeneralComponents/blueSwitch';
import { Header } from '../../Header';

// Material Ui
import { Box, Collapse, Typography } from "@mui/material";

// Styles
import { DecriptionShiftStyles, SubtitleShiftStyles } from '../../../Styles';

const AdvancedOptionsAditionalInfo = (props) => {
    // Variables
    const {
        additionalInfo,
    } = props;

    // Acciones
    const {
        setAditionalInfo,
    } = props;

    const [enabled, setEnabled] = useState(additionalInfo !== '');

    useEffect(() => {
        
    }, [additionalInfo]);

    return (
        <>
            <Box>
                <Header text="Opcionales del turno" />
                <Typography sx={ DecriptionShiftStyles } >
                    Seleccione los opcionales que quiere mostrarle al cliente para este turno
                </Typography>
            </Box>
            <Box ml="-.7rem" display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
                <Box sx={{ mb: '-.7rem' }}>
                    <BlueSwitch 
                        onChange={() => setEnabled(!enabled)} 
                        checked={enabled} 
                        color="default" 
                    />
                </Box>
                <Box sx={{ paddingTop: "12px", paddingBottom: "4px" }}>
                    <Typography sx={ SubtitleShiftStyles } >Información adicional del turno (Opcional)</Typography>
                </Box>
            </Box>
            <Collapse in={ enabled }>
                <Box maxWidth="1220px">
                    <TextArea
                        placeholder="Ej: No se permite el ingreso de menores de 18 años."
                        onChange={(e) => setAditionalInfo(e.target.value)}
                        text={ additionalInfo }
                        characters={ 300 }
                        label={ "" }
                    />
                </Box>   
            </Collapse>           
        </>       
    )
};

const mapStateToProps = (state) => {
    return {
        additionalInfo: state.v3.shifts.shiftData.additionalInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAditionalInfo: (data) => dispatch(setAditionalInfo(data)),   
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedOptionsAditionalInfo);