import React from 'react';

import { connect } from 'react-redux';

// Actions
import { setRequiredResponse } from '../../../actions/optionalActions';

// Components
import { BlueSwitch } from '../../GeneralComponents/blueSwitch';

// Material Ui
import { Box, Typography } from '@mui/material';

// Styles
import { SwitchText } from '../Styles/OptionalStyles';

const OptionalCheckboxComponent = ( props ) => {

    // Actions
    const { setRequiredResponse } = props;

    // States
    const { requiredResponse } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BlueSwitch
                onChange={e => setRequiredResponse(e.target.checked)}
                checked={ requiredResponse }
                color="default"
            />
            <Typography sx={ SwitchText }>
                Respuesta obligatoria
            </Typography>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        requiredResponse: state.optionalReducer.requiredResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRequiredResponse: (data) => dispatch(setRequiredResponse(data)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( OptionalCheckboxComponent );