import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { cancelCombination, saveCombination, updateCombinationName } from "../../../../actions/V3";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";


const validationSchema = Yup.object({
  alias: Yup.string().required("Requerido"),
  minCapacity: Yup.number().required("Requerido").min(1, "Mínimo 1"),
  maxCapacity: Yup.number().required("Requerido").min(1, "Mínimo 1"),
});

const capacitiyOptions = Array.from({ length: 20 }, (v, i) => i + 1);

const CombinationForm = (props) => {
  const { combineTables } = props;
  const {
    cancelCombination,
    saveCombination,
    updateCombinationName,
  } = props;

  const [enableSave, setEnableSave] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: combineTables.id || uuidv4(),
      alias: combineTables.alias || "",
      minCapacity: combineTables.minCapacity || 0,
      maxCapacity: combineTables.maxCapacity || 0,
      tables: combineTables.tables || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.alias = values.alias.trim();
      saveCombination(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setValues({
      id: combineTables.id || uuidv4(),
      alias: combineTables.alias || "",
      minCapacity: combineTables.minCapacity || 0,
      maxCapacity: combineTables.maxCapacity || 0,
      tables: combineTables.tables || [],
    });
  }, [combineTables]);

  useEffect(() => {
    setEnableSave(
      Object.keys(formik.errors).length === 0 &&
      formik.values.alias.trim() !== "" &&
        formik.values.minCapacity <= formik.values.maxCapacity &&
        combineTables.tables.length >= 2
    );
  }, [formik.values, combineTables, formik.errors]);

  useEffect(() => {
    updateCombinationName(formik.values.id, formik.values.alias);
  }, [formik.values.alias]);



  return (
    <div
      style={{
        margin: "0 10px",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%",
        padding: "1em 2em",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <FormControl fullWidth>
          <TextField
            id="alias"
            name="alias"
            label="Nombre"
            variant="outlined"
            fullWidth
            value={formik.values.alias}
            onChange={formik.handleChange}
            error={formik.touched.alias && formik.errors.alias}
            size="small"
          />
          {formik.touched.alias && formik.errors.alias && (
            <FormHelperText error sx={{ color: "#f44336 !important" }}>
              {formik.errors.alias}
            </FormHelperText>
          )}
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            gap: "1em",
          }}
        >
          <FormControl fullWidth>
            <Select
              id="minCapacity"
              placeholder="Min"
              name="minCapacity"
              variant="outlined"
              fullWidth
              value={formik.values.minCapacity}
              onChange={formik.handleChange}
              error={formik.touched.minCapacity && formik.errors.minCapacity}
              size="small"
              renderValue={(selected) => selected}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {Object.keys(capacitiyOptions).map((key, idx) => (
                <MenuItem
                  key={key}
                  value={parseInt(key)}
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {idx > 0 && (
                    <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    padding={"0.5rem 1rem"}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1em",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      {key}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={formik.touched.minCapacity && formik.errors.minCapacity}
            >
              {formik.touched.minCapacity && formik.errors.minCapacity}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <Select
              id="maxCapacity"
              name="maxCapacity"
              placeholder="Max"
              variant="outlined"
              fullWidth
              value={formik.values.maxCapacity}
              onChange={formik.handleChange}
              error={formik.touched.maxCapacity && formik.errors.maxCapacity}
              size="small"
              renderValue={(selected) => selected}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              {Object.keys(capacitiyOptions).map((key, idx) => (
                <MenuItem
                  key={key}
                  value={parseInt(key)}
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {idx > 0 && (
                    <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    padding={"0.5rem 1rem"}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1em",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      {key}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={formik.touched.minCapacity && formik.errors.minCapacity}
            >
              {formik.touched.minCapacity && formik.errors.minCapacity}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor:
            enableSave ? "#0B4762" : "#D8D8D8",
            color:
              enableSave
              ? "#FFFFFF" : "#A0A0A0",
            fontFamily: "Poppins, sans-serif",
            textTransform: "none",
            fontSize: "16px",
            padding: "0.5em",
            marginTop: "1em",
            borderRadius: "8px",
          }}
          fullWidth
          disabled={!enableSave}
          onClick={formik.handleSubmit}
        >
          Listo
        </Button>
        <Button
          size={"small"}
          variant={"text"}
          onClick={cancelCombination}
          sx={{
            fontFamily: "Poppins, sans-serif",
            textTransform: "none",
            marginTop: ".3rem",
            fontWeight: "500",
            fontSize: ".9rem",
            color: "#0B4762",
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <u>Cancelar</u>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    combineTables: state.v3.sectorsLayoutsTables.newCombineTables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelCombination: () => dispatch(cancelCombination()),
    saveCombination: (data) => dispatch(saveCombination(data)),
    updateCombinationName: (id, name) => dispatch(updateCombinationName(id, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CombinationForm);
