import React, {useEffect} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from '../../actions/sidebarActions';
import {
    deleteMessage,
    endEditingMessage,
    resetAllData,
    startCreationNewMessage,
    startEditingMessage,
    updateMessages,
} from '../../actions';

// Constants
import {PARTNERS} from '../../constants';

// Component
import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";
import ButtonWithIcon from '../NewComponents/ButtonWithIcon/ButtonWithIcon';
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import MessageCard from './MessageCard';


// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Grid} from '@material-ui/core';
import SaveChangesModal from "../Modals/SaveChangesModal";

const useStyles = makeStyles(() => ({
    mainContainer: {
        marginTop: 0,
        padding: 0,
        fontFamily: 'Montserrat'
    },
    messageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%',
    },
    messageCard: {
        width: "100%",
    },
    createMessageButton: {
        marginBottom: 10,
        background: '#4caf50',
        color: '#fff',
        '&:hover': {
            background: '#357a38',
        },
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typeContainer: {
        background: grey[200],
        borderRadius: 5,
    },
    typeText: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 3,
        marginBottom: 3,
        color: 'black',
    },
    text: {
        color: '#848282',
        fontSize: '12px',
    }
}));


function MessagesDisplay(props) {
    const classes = useStyles();

    // Variables
    const {
        messages,
        editedIndex,
        edited,
        loading,
    } = props;
    // Actions
    const {
        deleteMessage,
        updateMessages,
        startCreationNewMessage,
        startEditingMessage,
        endEditingMessage,
        resetAllData
    } = props;

    const discardChanges = () => {
        resetAllData();
        return true;
    }

    useEffect(() => {
        props.changeTitleAppBar("Comunicación");
        props.changePrimaryTitleAppBar("Configuración de los mensajes predeterminados que podrás enviar a tus clientes");
        props.changeShowSidebar(true);
    }, [props]);

    return (
        <Grid container className={classes.mainContainer}>
            <Grid item xs={12} md={10} style={{paddingLeft: '20px'}}>
                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h1 style={{
                        fontFamily: 'Poppins, sans-serif',
                        margin: '0',
                        fontWeight: '600',
                        color: '#041C32'
                    }}>Mensajes</h1>
                    <div>
                        <ButtonWithIcon
                            icon={<AddCircleOutlineOutlinedIcon/>}
                            text={'Agregar mensaje'}
                            onClick={() => editedIndex === -1 ? startCreationNewMessage() : null}
                        />
                    </div>
                </Grid>
                {
                    messages?.map((message, index) => (
                        <div key={message.message} style={{width: '100%', padding: '1.2rem 0rem 0rem 0rem'}}>
                            <MessageCard
                                message={message.message}
                                type={message.type}

                                editing={editedIndex === index}

                                onDelete={() => deleteMessage(message)}
                                onStartEditing={() => startEditingMessage(index)}
                                onEditingComplete={(data) => endEditingMessage(data)}
                            />
                        </div>
                    ))
                }
                {/*<Button className={classes.createMessageButton} onClick={toggleNewMessageDialog}>Crear mensaje</Button>*/}
            </Grid>
            {edited ? <SaveChangesButton onClick={updateMessages}/> : null}
            <SaveChangesModal
                when={edited}
                submit={updateMessages}
                handleClose={discardChanges}
            />
            <ProgressIndicator open={loading}/>
        </Grid>
    );
}


const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        editedIndex: state.messagesReducer.editedIndex,
        messages: state.messagesReducer.messages,
        loading: state.messagesReducer.loading,
        edited: state.messagesReducer.edited,

        partner: state.firestore.ordered[PARTNERS],
        profile: state.firebase.profile,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteMessage: (data) => dispatch(deleteMessage(data)),
        updateMessages: () => dispatch(updateMessages()),

        startEditingMessage: (data) => dispatch(startEditingMessage(data)),
        endEditingMessage: (data) => dispatch(endEditingMessage(data)),

        startCreationNewMessage: () => dispatch(startCreationNewMessage()),

        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        resetAllData: () => dispatch(resetAllData()),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        return [{
            collection: PARTNERS,
            doc: props.idPartnerSelected,
        },];
    })
)(MessagesDisplay);
