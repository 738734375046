import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { setRotationControlByTable } from "../../../../../actions/shiftsActions";

// Components
import TableCustomizeRotationTableItem from "./TableCustomizeRotationTableItem";
import Divider from "../components/Divider";
import { Title } from "../../Title";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { Box, Button, Modal, Stack, Typography, FormControl, InputLabel, Select, MenuItem, OutlinedInput} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { GetAdvancedModalOptions, GetRotationControlByTable, GetTablesOptions } from "../utils/advancedOptionsFunctions";
import { tableRotationOptionsDefaultValue } from "../utils/constants";
import { GetStyledItem } from "../utils/modalFunctions";

// Styles
import { SettingsTablesStyles, ShiftTableSelectStyles } from "../../../Styles";
import { modalStyles } from "../utils/styles/modalStyles";


const useStyles = makeStyles(() => (modalStyles));

const AdvancedOptionsCustomizeRotationByTableModal = (props) => {
  const classes = useStyles();

  // Variables
  const {
    reservableSectors,
    rotationControlByTable,
  } = props;

  // Acciones 
  const {
    handleClose,
    setRotationControlByTable,
  } = props;

  const ITEM_PADDING_TOP = 8;
  const ITEM_HEIGHT = 48;

  const MenuProps = {
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      },
      transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
      },
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              border: '1px solid #0B4762',
              fontFamily: 'Poppins',
              borderRadius: '10px',
              maxWidth: '15.75rem',
              width: 'max-content',
              marginTop: '.2rem',
              minWidth: '78px',
          },
      },
  };

  const newReservableSectors = reservableSectors.filter((reservableSector) => reservableSector.available);  
  const [reservableSector, setReservableSector] = useState(newReservableSectors.length > 0 ? newReservableSectors[0] : null);
  const [rotationControlTable, setRotationControlTable] = useState([]);
  
  useEffect(() => {
    const newArray = GetTablesOptions(reservableSector.tables, rotationControlByTable, tableRotationOptionsDefaultValue);
    setRotationControlTable(GetAdvancedModalOptions(reservableSector.id, newArray, rotationControlByTable, tableRotationOptionsDefaultValue, 'table'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservableSector, JSON.stringify(rotationControlByTable)]);

  const handleChangeSector = (val) => {
    setReservableSector(newReservableSectors.find(reservableSector => reservableSector.id === val));
  };

  const handleApplyChanges = () => {
    GetRotationControlByTable(rotationControlTable, rotationControlByTable, setRotationControlByTable, reservableSector.tables);
    handleClose();
  };

  return (
    <Modal open>
      <Box className={classes.ModalContainer}>
        <Title text="Personalizar rotación por mesa" />
        <Stack direction="row" spacing="25px" mt={2} mb={2} justifyContent="left">
          <Box width="50%">
            <Box width="100%">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"/>
                  <Select
                    onChange={(e) => handleChangeSector(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    labelId="demo-simple-select-label"
                    IconComponent={ ExpandMoreIcon }
                    sx={ ShiftTableSelectStyles }
                    value={reservableSector.id}
                    input={ <OutlinedInput /> }
                    MenuProps={ MenuProps }
                    renderValue={(value) => {
                      return GetStyledItem(
                        newReservableSectors.find((rs) => rs.id === value).name, 
                        newReservableSectors.find((rs) => rs.id === value).type
                      );
                    }}
                  >
                      {
                        newReservableSectors.map((reservableSector) => (
                          <MenuItem key={ reservableSector.id } value={ reservableSector.id }>
                            { GetStyledItem( reservableSector.name, reservableSector.type ) }
                            <Box sx={{ width: '25px' }}></Box>
                          </MenuItem>
                        ))
                      }
                  </Select>
              </FormControl>
            </Box>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }}/>
        </Stack>
        <Stack style={ modalStyles.ItemsHeader}>
          <Box sx={{ width: '30%', display: 'flex', justifyContent: 'start' }}>
            <Typography className={classes.ItemsTitle}>
                Nombre
            </Typography>
          </Box>
          <Box sx={{ width: '15%', display: 'flex', justifyContent: 'center' }}>
            <Typography className={classes.ItemsTitle}>
                Mín
            </Typography>
          </Box>
          <Box sx={{ width: '15%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography className={classes.ItemsTitle}>
                Máx
            </Typography>
          </Box>
          <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center' }}>
            <Typography className={classes.ItemsTitle}>
                Rotaciones mínimas
            </Typography>
          </Box>                
        </Stack>
        <Box sx={ SettingsTablesStyles }>
            {
              rotationControlTable.map((rotationControl) => (
                <TableCustomizeRotationTableItem 
                  setRotationControlTable={ setRotationControlTable } 
                  rotationControlTable={ rotationControlTable } 
                  rotationControl={ rotationControl } 
                  stables={ reservableSector.tables }
                  key={ rotationControl.id } 
                />              
              ))
            }
        </Box>
        <Stack style={modalStyles.ButtonContainer}>
          <Button className={classes.CancelButton} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={classes.ApplyButton} onClick={handleApplyChanges}>
            Aplicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {            
      reservableSectors: state.v3.shifts.reservableSectors,
      rotationControlByTable: state.v3.shifts.shiftData.rotationControl.rotationControlByTable,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRotationControlByTable: (data) => dispatch(setRotationControlByTable(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedOptionsCustomizeRotationByTableModal);
