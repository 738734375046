import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useState } from "react";
import { connect } from "react-redux";
import ButtonWithIcon from "../../../NewComponents/ButtonWithIcon/ButtonWithIcon";
import { Grid } from "@mui/material";
import SectorSkeleton from "../Components/SectorSkeleton";
import NoSectors from "../Components/NoSectors";
import AddOrEditSector from "../Components/Modals/AddOrEditSector";
import Sector from "../Components/Sector";

const sectorSkletonsArr = new Array(3).fill(0);

const SectorList = (props) => {
  const { sectors, loading } = props;
  const [openAddSector, setOpenAddSector] = useState(false);

  const handleCloseAddSector = () => {
    setOpenAddSector(false);
  };

  return (
    <Grid container spacing={3}
    >
      <Grid item xs={12} style={{ zIndex: 1 }}>
        {sectors && sectors.length > 0 && <Grid container>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}>
            <ButtonWithIcon
              text={"Crear sector"}
              icon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => setOpenAddSector(true)}
            />
          </Grid>
        </Grid>}
        </Grid>
        {loading ? 
          sectorSkletonsArr.map((_, index) => (
            <SectorSkeleton key={index}/>
          ))
        : 
         sectors && sectors.length > 0 ? (
            sectors.map((sector) => (
                <Grid
                item
                xs={11}
                spacing={1}
                key={sector.id}
              >
                <Sector {...sector}/>
              </Grid>
            ))
          ) : (
            <NoSectors/>
          )}
      <AddOrEditSector
        open={openAddSector}
        handleClose={handleCloseAddSector}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    sectors: state.v3.sectorsLayoutsTables.sectors,
    loading: state.v3.sectorsLayoutsTables.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorList);
