import React from 'react'
import CheckIcons from "../SvgComponents/icons/CheckIcons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '10px'
    },
    CheckIcon: {
        position: 'absolute',
        top: '3px'
    },
    text: {
        fontWeight: '500',
        fontSize: '1rem',
        color: '#041C32',
        margin: '0',
        marginLeft: '20px',
        padding: '0',
        '@media (max-width: 1100px)': {
            fontSize: '.6rem',
        },
    },
}))

const Requirement = (props) => {
    const classes = useStyles();

    const {text} = props

    return (
        <div className={classes.root}>
            <div className={classes.CheckIcon}><CheckIcons/></div>
            <p className={classes.text}>{text}</p>
        </div>
    )
}

export default Requirement
