import React from 'react';

import { connect } from 'react-redux';

// Components
import OptionalHome from '../../components/Optional/OptionalHome';
import OptionalForm from '../../components/Optional/OptionalForm';

// Styles
import { MainContainer } from '../../components/Optional/Styles/OptionalStyles';

const OptionalPage = ( props ) => {

    // States
    const { viewMode } = props;

    return (
        <section style={ MainContainer }>
            {
                viewMode === 'VIEW' ?
                    <OptionalHome/>
                :
                    <OptionalForm/>
            }
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        viewMode: state.optionalReducer.viewMode,
    };
};

export default connect( mapStateToProps, null )( OptionalPage );