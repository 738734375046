import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Image from "../../../../assets/images/not-found.svg";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddOrEditSector from "./Modals/AddOrEditSector";

const NoSectors = ({ onButtonClick }) => {
  const [openAddSector, setOpenAddSector] = React.useState(false);

  const handleCloseAddSector = () => {
    setOpenAddSector(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      textAlign="center"
      position="absolute"
      top="0"
      left="0"
      sx={{ fontFamily: "Poppins !important" }}
    >
      <Box>
        <img
          src={Image}
          alt="No Sectors"
          style={{ width: "200px", marginBottom: "1rem" }}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{
          color: "#0B4762",
          fontSize: "1.5rem",
          fontWeight: 700,
          opacity: 0.7,
          fontFamily: "Poppins",
        }}
      >
        Aún no tienes sectores
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "#0B4762",
          fontSize: "1rem",
          fontFamily: "Poppins",
          marginTop: "1rem",
          marginBottom: "1rem",
          width: "90vw",
          maxWidth: "400px",
          opacity: 0.7,
        }}
      >
        Aún no tienes sectores creados. Hacé click en crear sector y
        configuralos
      </Typography>
      <Button
        style={{
          minWidth: "11rem",
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "#0B4762",
          textTransform: "none",
          borderRadius: "10px",
          padding: "6px 12px",
          letterSpacing: "0",
          height: "2.5rem",
          color: "white",
        }}
        variant="contained"
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => setOpenAddSector(true)}
      >
        Crear Sector
      </Button>
      <AddOrEditSector
        open={openAddSector}
        handleClose={handleCloseAddSector}
      />
    </Box>
  );
};

export default NoSectors;
