import {
    CHANGE_STATISTICS_RESUME_FILTER_DATE,
    CLOSE_ERROR_DIALOG,
    FETCH_STATISTICS_RESUME_FAILURE,
    FETCH_STATISTICS_RESUME_SUCCESS,
    RESET_ALL_DATA,
    START_FETCH_STATISTICS_RESUME
} from "../../constants";
import moment from "moment";

const initState = {
    arriveStatistics: {
        totalBookings: 0,
        woki: 0,
        manual: 0,
        link: 0,
        myClients: 0,
        'totalBookings-diners': 0,
        'woki-diners': 0,
        'manual-diners': 0,
        'link-diners': 0,
        'myClients-diners': 0,
    },
    bestClients: [],
    statisticsResume: {
        total: 0,
        arrives: 0,
        noAnswer: 0,
        noShow: 0
    },
    graphicData: {
        "0": {},
        "1": {},
        "2": {},
        "3": {},
    },
    from: moment().startOf('month'),
    to: moment().endOf('month'),

    loading: false,
    error: undefined,
}

const statisticsResumeReducer = (state = initState, action) => {
    switch (action.type) {
        case START_FETCH_STATISTICS_RESUME:
            return {...state, loading: true, error: undefined};
        case FETCH_STATISTICS_RESUME_SUCCESS:
            return {
                ...state,
                loading: false,
                arriveStatistics: action.payload.arriveStatistics ?? state.arriveStatistics,
                bestClients: action.payload.bestClients ?? state.bestClients,
                statisticsResume: action.payload.statisticsResume ?? state.statisticsResume,
                graphicData: action.payload.graphicData ?? state.graphicData,
                error: undefined
            };
        case FETCH_STATISTICS_RESUME_FAILURE:
            return {...state, loading: false, error: action.payload};
        case CHANGE_STATISTICS_RESUME_FILTER_DATE:
            return {...state, from: action.payload.from, to: action.payload.to};
        case CLOSE_ERROR_DIALOG:
            return {...state, error: undefined};
        case RESET_ALL_DATA:
            return {...initState}
        default:
            return state;
    }
}

export default statisticsResumeReducer;
