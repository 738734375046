import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setBookingStatesFilters } from '../../../../../actions';

// Icons
import BookingStatesFilter from '../../../../SvgComponents/Icons Tables/MyBookings/BookingStatesFilter';

// Component
import GeneralSection3Select from './GeneralSection3Select';

// Helpers
import { bookingStatesSelectOptions, handleChangeBookingStateFilters } from '../../helpers';

// Material Ui
import { useMediaQuery } from '@mui/material';

const BookingStatesSelect = ( props ) => {

    // Actions
    const { setBookingStatesFilters } = props;

    // States
    const { bookingStatesArray } = props;

    const media = useMediaQuery('(min-width:1550px)');

    const [closeBookingStatesSelect, setCloseBookingStatesSelect] = useState( false );
    const [bookingState, setBookingState] = useState([]);

    useEffect(() => {
        let newStatesArray = [];

        for (let i = 0; i < bookingStatesArray.length; i++) {
            newStatesArray.push( bookingStatesArray[i].label );
        };

        setBookingState( newStatesArray );
        handleChangeBookingStateFilters( newStatesArray, setBookingStatesFilters );
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = ( event ) => {
        if ( 
            event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
            event.target.value.length > bookingStatesArray.length
        ) {
            setBookingState([]);
        } else if (
            event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
            event.target.value.length < bookingStatesArray.length
        ) {
            let allOptionsArray = [];
            for (let i = 0; i < bookingStatesArray.length; i++) {
                allOptionsArray.push( bookingStatesArray[i].label );
            };

            setBookingState( allOptionsArray );
        } else {
            setBookingState( typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value );
        }
    };

    useEffect(() => {
        if ( closeBookingStatesSelect ) {
            if ( bookingState ) {
                handleChangeBookingStateFilters( bookingState, setBookingStatesFilters );
            };
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bookingState, closeBookingStatesSelect ]);

    const handleClose = () => {
        setCloseBookingStatesSelect( true );
    };

    const handleOpen = () => {
        setCloseBookingStatesSelect( false );
    };

    return ( 
        <GeneralSection3Select 
            placeholderText={ media ? 'Estado de reservas' : 'Estado'}
            optionsArray={ bookingStatesSelectOptions } 
            SelectIcon={ BookingStatesFilter }
            handleChange={ handleChange } 
            handleClose={ handleClose }
            handleOpen={ handleOpen } 
            value={ bookingState }
        /> 
    )
};

const mapStateToProps = state => {
    return {
        bookingStatesArray: state.bookingHistoryReducer.bookingStatesArray,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBookingStatesFilters: ( state ) => dispatch( setBookingStatesFilters( state ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BookingStatesSelect );