import axios from "axios";
import {
    ADD_ENVIRONMENT_FILTER,
    ADD_FEATURE_FILTER,
    ADD_FOOD_FILTER,
    ADD_OTHER_FILTER,
    ADD_TAG_ITEM,
    FETCH_ALL_FILTERS_ERROR,
    FETCH_ALL_FILTERS_SUCCESS,
    FETCH_FILTERS_ERROR,
    FETCH_FILTERS_SUCCESS,
    REMOVE_ENVIRONMENT_FILTER,
    REMOVE_FEATURE_FILTER,
    REMOVE_FOOD_FILTER,
    REMOVE_OTHER_FILTER,
    REMOVE_TAG_ITEM,
    SAVE_FILTERS_ERROR,
    SAVE_FILTERS_SUCCESS,
    START_FETCH_ALL_FILTERS,
    START_FETCH_FILTERS,
    START_SAVE_FILTERS
} from "../../constants/filtersConstants";

export const startFetchAllFilters = () => ({
    type: START_FETCH_ALL_FILTERS,
});

export const fetchAllFiltersSuccess = (data) => ({
    type: FETCH_ALL_FILTERS_SUCCESS,
    payload: data,
});

export const fetchAllFiltersError = (data) => ({
    type: FETCH_ALL_FILTERS_ERROR,
    payload: data,
});

export const startFetchFilters = () => ({
    type: START_FETCH_FILTERS,
});

export const fetchFiltersSuccess = (data) => ({
    type: FETCH_FILTERS_SUCCESS,
    payload: data,
});

export const fetchFiltersError = (data) => ({
    type: FETCH_FILTERS_ERROR,
    payload: data,
});

export const addFoodFilter = (item) => ({
    type: ADD_FOOD_FILTER,
    payload: item,
});

export const removeFoodFilter = (item) => ({
    type: REMOVE_FOOD_FILTER,
    payload: item
});

export const addEnvironmentFilter = (item) => ({
    type: ADD_ENVIRONMENT_FILTER,
    payload: item,
});

export const removeEnvironmentFilter = (item) => ({
    type: REMOVE_ENVIRONMENT_FILTER,
    payload: item
});

export const addFeatureFilter = (item) => ({
    type: ADD_FEATURE_FILTER,
    payload: item,
});

export const removeFeatureFilter = (item) => ({
    type: REMOVE_FEATURE_FILTER,
    payload: item
});

export const addOtherFilter = (item) => ({
    type: ADD_OTHER_FILTER,
    payload: item,
});

export const removeOtherFilter = (item) => ({
    type: REMOVE_OTHER_FILTER,
    payload: item
});


export const addTagItem = (item) => ({
    type: ADD_TAG_ITEM,
    payload: item,
});

export const removeTagItem = (item) => ({
    type: REMOVE_TAG_ITEM,
    payload: item
});

export const startSaveFilters = () => ({
    type: START_SAVE_FILTERS,
});

export const saveFiltersSuccess = () => ({
    type: SAVE_FILTERS_SUCCESS,
});

export const saveFiltersError = (data) => ({
    type: SAVE_FILTERS_ERROR,
    payload: data,
});

export const fetchAllFilters = () => {
    return async ( dispatch, getState, { getFirebase }) => {

        try {
            dispatch(startFetchAllFilters());
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/establishment/filters/`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            dispatch(fetchAllFiltersSuccess(data));
        } catch (error) {
            console.log(error);
            dispatch(fetchAllFiltersError(error));
        }
    }
}

function ensurePartnerExists( id ) {
    if ( !id ) {
        throw new Error('Partner does not exist');
    }
}

export const fetchFilters = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;

        try {
            dispatch(startFetchFilters());
            ensurePartnerExists( idPartnerSelected );
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/establishment/general-info/${ idPartnerSelected }`, { headers: { Authorization: `Bearer ${token}`}})
            const { data } = res;
            dispatch(fetchFiltersSuccess( data ));
        } catch (error) {
            console.log(error);
            dispatch(fetchFiltersError(error));
        }
    }
}

export const saveFilters = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { filtersReducer, userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;
        const { food, environment, features, others, tags } = filtersReducer;

        try {
            dispatch(startSaveFilters());
            ensurePartnerExists( idPartnerSelected );
            const data = {
                characteristics: {
                    food: food,
                    environment: environment,
                    features: features,
                    others: others
                },
                tags: tags
            }
            console.log("Data: ", data);
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.patch(
                `${API_URL}/establishment/general-info/${ idPartnerSelected }`,
                data,
                {headers: {Authorization: `Bearer ${token}`}});
            if (res.status > 300) {
                throw new Error(res.data);
            }
            dispatch(saveFiltersSuccess());
            return true;
        } catch (error) {
            console.log(error);
            dispatch(saveFiltersError(error.message));
            return false;
        }
    }
}
