import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    generalContainer: {
        fontFamily: 'Poppins, sans-serif',
        padding: '1rem !important',
        borderRadius: '9.50135px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        position: 'relative',
        textAlign: 'left',
        height: '9rem'
    },
    title: {
        fontWeight: '600',
        margin: '.5rem 0',
        fontSize: '1rem',
        '@media( min-width: 1200px )': {
            fontSize: '1.2rem'
        }
    },
    data: {
        position: 'absolute',
        fontSize: '2.5rem',
        fontWeight: '600',
        bottom: '.5rem',
        margin: '0',
    }
}));

const CardsTop = (props) => {

    const classes = useStyles();
    const { title, data, dataColor, shadowColor } = props;

    return (
        <div className={classes.generalContainer} style={{boxShadow: `1px 3px 4px ${shadowColor}`}}>
            <h3 className={classes.title}>{title}</h3>
            <h1 className={classes.data} style={{color: dataColor}}>{data}</h1>
        </div>
    )
}

export default CardsTop
