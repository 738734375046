import React from 'react'

const GeneralIcon = (props) => {
    const {name} = props;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="323" height="138" viewBox="0 0 423 138">
                <defs>
                    <filter id="Rectángulo_2507" x="80" y="25" width="343" height="88" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#915aff"/>
                        <stop offset="1" stopColor="#4e00cc"/>
                    </linearGradient>
                    <filter id="Elipse_276" x="0" y="0" width="138" height="138" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-2"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur-2"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Grupo_1417" data-name="Grupo 1417" transform="translate(-101 -119)">
                    <g transform="matrix(1, 0, 0, 1, 101, 119)" filter="url(#Rectángulo_2507)">
                        <rect id="Rectángulo_2507-2" data-name="Rectángulo 2507" width="325" height="70" rx="35"
                              transform="translate(89 31)" fill="#fff"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 101, 119)" filter="url(#Elipse_276)">
                        <circle id="Elipse_276-2" data-name="Elipse 276" cx="60" cy="60" r="60"
                                transform="translate(9 6)" fill="url(#linear-gradient)"/>
                    </g>
                    <text id="Reservas" transform="translate(245 203)" fill="#7c2bff" fontSize={props.fontSize}
                          fontFamily="Montserrat-SemiBold, Montserrat" fontWeight="600" letterSpacing="-0.03em">
                        <tspan x="0" y="0">{name}</tspan>
                    </text>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(137.194 141.431)">
                        <g id="Layer_1" data-name="Layer 1" transform="translate(0.806 5.396)">
                            <path id="Trazado_707" data-name="Trazado 707"
                                  d="M0,93.023V128.4a7.19,7.19,0,0,0,7.19,7.19H57.81A7.191,7.191,0,0,0,65,128.4V93.023Zm20.389,32.983a2.221,2.221,0,0,1-2.222,2.22H12.1a2.221,2.221,0,0,1-2.22-2.22v-6.07a2.221,2.221,0,0,1,2.22-2.22h6.07a2.221,2.221,0,0,1,2.222,2.22Zm2.624-24.04-5.791,9.314a1.87,1.87,0,0,1-1.365.869,1.676,1.676,0,0,1-.224.014,1.868,1.868,0,0,1-1.306-.534l-3.782-3.7a1.87,1.87,0,1,1,2.614-2.673l2.117,2.071,4.561-7.337a1.87,1.87,0,1,1,3.175,1.974Zm14.743,24.04a2.221,2.221,0,0,1-2.22,2.22h-6.07a2.221,2.221,0,0,1-2.222-2.22v-6.07a2.221,2.221,0,0,1,2.222-2.22h6.07a2.221,2.221,0,0,1,2.22,2.22Zm0-17.008a2.221,2.221,0,0,1-2.22,2.22h-6.07A2.221,2.221,0,0,1,27.244,109v-6.07a2.221,2.221,0,0,1,2.222-2.22h6.07a2.221,2.221,0,0,1,2.22,2.22Zm17.687,17.008a2.22,2.22,0,0,1-2.221,2.22H47.152a2.22,2.22,0,0,1-2.22-2.221v-6.07a2.22,2.22,0,0,1,2.221-2.22h6.069a2.22,2.22,0,0,1,2.22,2.221Zm0-17.008a2.22,2.22,0,0,1-2.221,2.22H47.152A2.22,2.22,0,0,1,44.932,109v-6.07a2.22,2.22,0,0,1,2.221-2.22h6.069a2.22,2.22,0,0,1,2.22,2.221Z"
                                  transform="translate(0 -62.607)" fill="#fff"/>
                            <path id="Trazado_708" data-name="Trazado 708"
                                  d="M65,31.6V42.444H0V31.6a7.189,7.189,0,0,1,7.191-7.19H9.106v5.312A6.493,6.493,0,0,0,15.6,36.218h1.3a6.493,6.493,0,0,0,6.493-6.494V24.411H41.606v5.312A6.493,6.493,0,0,0,48.1,36.218h1.3a6.493,6.493,0,0,0,6.493-6.494V24.411H57.81A7.19,7.19,0,0,1,65,31.6Z"
                                  transform="translate(0 -16.43)" fill="#fff"/>
                            <rect id="Rectángulo_2505" data-name="Rectángulo 2505" width="8.191" height="16.213"
                                  rx="4.095" transform="translate(12.154)" fill="#fff"/>
                            <rect id="Rectángulo_2506" data-name="Rectángulo 2506" width="8.191" height="16.213"
                                  rx="4.095" transform="translate(44.654)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

GeneralIcon.defaultProps = {
    fontSize: '50',
}

export default GeneralIcon
