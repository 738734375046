import React, { Fragment } from 'react';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

const OptionalErrorSnackbar = ({ message, open, handleClose }) => {

    const action = (
        <Fragment>
            <IconButton
                sx={{ 
                    backgroundColor: '#FA626A',
                    '&:hover': {
                        backgroundColor: '#FA626A !important',
                    } 
                }}
                onClick={ handleClose }
                aria-label="close"
                color="inherit"
                size="small"
            >
                <CloseIcon fontSize="small" sx={{ color: 'white' }} />
            </IconButton>
        </Fragment>
    );

    return (
        <Snackbar 
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{ 
                '.MuiPaper-root': { 
                    backgroundColor: '#FB3F4A', 
                    fontFamily: 'Poppins', 
                    borderRadius: '8px', 
                } 
            }} 
            autoHideDuration={ 6000 }
            onClose={ handleClose }
            message={ message } 
            action={ action }
            open={ open } 
        />
    )
}

export default OptionalErrorSnackbar;