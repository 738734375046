import React from 'react';

const VerticalLineIcon = (props) => {
    const {height} = props;
    return (
        <svg width="1" height={height} viewBox="0 0 2 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 22" d="M1 0L1 31" stroke="#041C32"/>
        </svg>
    )
}

export default VerticalLineIcon;