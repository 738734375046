import {
  ADD_NEW_SECTOR,
  ADD_NEW_TABLE,
  ADD_TABLE_TO_COMBINATION,
  BACK_TO_VIEW_MODE,
  CANCEL_COMBINATION,
  CHANGE_EDITING_TABLE_NEW,
  CHANGE_LAYOUT_MAX_GROUP,
  CHANGE_LAYOUT_NAME,
  CHANGE_SECTOR_MODE,
  CHANGE_TABLE_LAYOUT_COORDINATES,
  CHANGE_TABLE_LAYOUT_PROPERTIES,
  LAYOUT_CREATE_SUCCESS,
  LOAD_SECTORS_FAILURE,
  LOAD_SECTORS_SUCCESS,
  REFRESH_ERROR,
  REMOVE_COMBINATION,
  REMOVE_TABLE,
  REMOVE_TABLE_FROM_COMBINATION,
  RESET_ALL_DATA,
  SAVE_COMBINATION,
  SECTOR_MODE,
  START_ADDING_NEW_TABLE,
  START_CREATING_LAYOUT,
  START_DUPLICATING_LAYOUT,
  START_LAYOUT_EDIT_MODE,
  START_LOAD_SECTORS,
  START_NEW_COMBINATION,
  UPDATE_COMBINATION,
  UPDATE_COMBINATION_NAME,
} from "../../../constants";
import {
  arrayEquals,
  arrayOfObjectsEquals,
  deepCopyObject,
} from "../../../utils/arrayHelpers";
import { v4 as uuidv4 } from "uuid";
import { deepEqual } from "../../../utils/objectHelpers";

const emptyCombinationTable = {
  id: "",
  alias: "",
  minCapacity: 0,
  maxCapacity: 0,
  tables: [],
  partnerCanBook: false,
  userCanBook: true,
};

function checkCombinationAlreadyExist(allCombinations, combination) {
  if (allCombinations.some((c) => c.alias === combination.alias)) {
    return "Ya existe una combinación con ese nombre";
  }
  const combinationCopy = deepCopyObject(combination);
  combinationCopy.tables.sort((a, b) => a.localeCompare(b));
  const existsCombination = allCombinations.some((c) =>
    arrayEquals(
      c.tables.sort((a, b) => a.localeCompare(b)),
      combinationCopy.tables
    )
  );
  if (existsCombination) {
    return "Ya existe una combinación con esas mesas";
  }
}

function generateCombinedTablesFromTables(tables) {
  const combinedTables = [];
  tables.forEach((table) => {
    if (table.combinesWith && table.combinesWith.length > 0) {
      table.combinesWith.forEach((combineTable) => {
        if (!combinedTables.some((ct) => ct.id === combineTable.id)) {
          combinedTables.push({
            ...combineTable,
          });
        }
      });
    }
  });
  return combinedTables;
}

function areThereDifferencesBetweenTheLayouts(originalLayout, newLayout) {
  return (
    !deepEqual(newLayout, originalLayout) ||
    !arrayOfObjectsEquals(newLayout.tables, originalLayout.tables)
  );
}

function updateLayoutTables(layout, tables) {
  return {
    ...layout,
    tables: tables,
    maxCapacity: tables.reduce((acc, table) => acc + table.maxCapacity, 0),
  };
}

function dontHasPreviousTablesOnOtherLayouts(
  creationSector,
  sectors,
  sectorId,
  layout
) {
  const sector = sectors.find((s) => s.id === sectorId);
  if (!sector) {
    return true;
  }
  const layouts = sector.layouts ?? [];
  const hasOneLayout = layouts.length === 1;
  const editingTheOnlyLayout = hasOneLayout && layout.id === layouts[0].id;
  return creationSector || editingTheOnlyLayout;
}

const emptyLayout = {
  name: "",
  maxGroup: "",
  maxCapacity: 0,
  tables: [],
  id: "",
  uuid: "",
};

const emptySector = {
  name: "",
  id: "",
  type: "",
};

const initialState = {
  sectors: [],
  mode: SECTOR_MODE.VIEW,
  layout: {
    name: "",
    maxGroup: "",
    maxCapacity: 0,
    tables: [],
    id: "",
    uuid: "",
  },
  sector: {
    name: "",
    id: "",
    type: "",
  },
  originalLayout: undefined,
  newTable: undefined,
  editingTable: undefined,
  creatingSector: false,
  combinedTables: [],
  combineMode: false,
  newCombineTables: emptyCombinationTable,
  editCombineTables: emptyCombinationTable,
  combinationAlreadyExists: "",
  edited: false,
  loading: true,
  tableCreationMode: false,
  error: {
    action: "",
    message: "",
    severity: "info",
  },
};

const sectorsLayoutsTables = (state = initialState, action) => {
  switch (action.type) {
    //Sectors and layouts generals
    case START_LOAD_SECTORS:
      return {
        ...state,
        loading: true,
        error: { action: "", message: "", severity: "info" },
      };
    case LOAD_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.payload,
        loading: false,
        error: { action: "", message: "", severity: "info" },
      };
    case LOAD_SECTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHANGE_SECTOR_MODE:
      return {
        ...state,
        mode: action.payload.mode,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        edited: true,
        combineMode: false,
      };
    case LAYOUT_CREATE_SUCCESS:
      return {
        ...state,
        mode: SECTOR_MODE.VIEW,
        layout: deepCopyObject(emptyLayout),
        sector: deepCopyObject(emptySector),
        originalLayout: undefined,
        newTable: undefined,
        editingTable: undefined,
        creatingSector: false,
        combinedTables: [],
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinationAlreadyExists: "",
        edited: false,
        loading: false,
        error: { action: "", message: "", severity: "info" },
      };
    case START_LAYOUT_EDIT_MODE:
      let sectorToSet = state.sectors.find(
        (s) => s.id === action.payload.sectorId
      );
      sectorToSet = {
        name: sectorToSet.name,
        id: sectorToSet.id,
        type: sectorToSet.type,
        layouts: sectorToSet.layouts,
      };
      let layoutToSet = sectorToSet.layouts.find(
        (l) => l.id === action.payload.layoutId
      );
      layoutToSet = {
        name: layoutToSet.name,
        maxGroup: layoutToSet.maxGroup,
        tables: layoutToSet.tables,
        id: layoutToSet.id,
        uuid: layoutToSet.uuid,
        maxCapacity: layoutToSet.maxCapacity,
      };
      delete sectorToSet.layouts;
      return {
        ...state,
        mode: SECTOR_MODE.EDIT,
        layout: deepCopyObject(layoutToSet),
        sector: deepCopyObject(sectorToSet),
        originalLayout: deepCopyObject(layoutToSet),
        newTable: undefined,
        editingTable: undefined,
        creatingSector: false,
        combinedTables: generateCombinedTablesFromTables(layoutToSet.tables),
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinationAlreadyExists: "",
        edited: false,
        loading: false,
        error: { action: "", message: "", severity: "info" },
      };
    case START_CREATING_LAYOUT:
      let sectorForNewLayout = state.sectors.find(
        (s) => s.id === action.payload
      );
      sectorForNewLayout = {
        name: sectorForNewLayout.name,
        id: sectorForNewLayout.id,
        type: sectorForNewLayout.type,
      };
      return {
        ...state,
        mode: SECTOR_MODE.NEW_LAYOUT,
        layout: deepCopyObject({
          ...emptyLayout,
          id: uuidv4(),
          uuid: uuidv4(),
        }),
        sector: deepCopyObject(sectorForNewLayout),
        originalLayout: deepCopyObject(emptyLayout),
        newTable: undefined,
        editingTable: undefined,
        creatingSector: false,
        combinedTables: [],
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinationAlreadyExists: "",
        edited: true,
        loading: false,
        error: { action: "", message: "", severity: "info" },
      };
    case START_DUPLICATING_LAYOUT:
      let sectorForDuplicatedLayout = state.sectors.find(
        (s) => s.id === action.payload.sectorId
      );
      sectorForDuplicatedLayout = {
        name: sectorForDuplicatedLayout.name,
        id: sectorForDuplicatedLayout.id,
        type: sectorForDuplicatedLayout.type,
        layouts: sectorForDuplicatedLayout.layouts,
      };
      let layoutToDuplicate = sectorForDuplicatedLayout.layouts.find(
        (l) => l.id === action.payload.layoutId
      );
      layoutToDuplicate = {
        name: layoutToDuplicate.name + " (copy)",
        maxGroup: layoutToDuplicate.maxGroup,
        tables: layoutToDuplicate.tables.map((t) => {
          return {
            ...t,
            combinesWith: t.combinesWith.map((c) => {
              return {
                ...c,
                id: uuidv4(),
              };
            }),
          };
        }),
        id: uuidv4(),
        uuid: uuidv4(),
        maxCapacity: layoutToDuplicate.maxCapacity,
      };
      delete sectorForDuplicatedLayout.layouts;
      return {
        ...state,
        mode: SECTOR_MODE.NEW_LAYOUT,
        layout: deepCopyObject(layoutToDuplicate),
        sector: deepCopyObject(sectorForDuplicatedLayout),
        originalLayout: deepCopyObject(emptyLayout),
        newTable: undefined,
        editingTable: undefined,
        creatingSector: false,
        combinedTables: generateCombinedTablesFromTables(
          layoutToDuplicate.tables
        ),
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinationAlreadyExists: "",
        edited: true,
        loading: false,
        error: { action: "", message: "", severity: "info" },
      };
    case ADD_NEW_SECTOR:
      let uuid = uuidv4();
      let id = uuidv4();
      return {
        ...state,
        sectors: [
          ...state.sectors,
          {
            ...action.payload,
            layouts: [],
          },
        ],
        mode: SECTOR_MODE.NEW_SECTOR,
        layout: {
          name: "",
          maxGroup: "",
          tables: [],
          id,
          uuid,
        },
        sector: action.payload,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        creatingSector: true,
        combinedTables: [],
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinationAlreadyExists: "",
        edited: false,
        originalLayout: {
          name: "",
          maxGroup: "",
          tables: [],
          id,
          uuid,
        },
      };
    //Tables
    case START_ADDING_NEW_TABLE:
      let nextTableValue;
      let { tables } = state.layout;

      if (tables.length === 0) {
        nextTableValue = "1";
      } else {
        const lastTable = tables[tables.length - 1];
        const lastTableValue = parseInt(lastTable.alias);

        if (!isNaN(lastTableValue)) {
          nextTableValue = (lastTableValue + 1).toString();
        } else {
          const lastNumberInTableNames = tables
            .map((table) => parseInt(table.alias))
            .filter((num) => !isNaN(num));
          if (lastNumberInTableNames.length === 0) {
            nextTableValue = "1";
          } else {
            nextTableValue = (
              Math.max(...lastNumberInTableNames) + 1
            ).toString();
          }
        }
      }
      return {
        ...state,
        newTable: {
          ...action.payload,
          circle: action.payload.circle ?? false,
          i: nextTableValue,
        },
        tableCreationMode: true,
      };
    case ADD_NEW_TABLE:
      let tablesEdited = [...state.layout.tables];
      const newTableAdded = {
        id: uuidv4(),
        alias: dontHasPreviousTablesOnOtherLayouts(
          state.creatingSector,
          state.sectors,
          state.sector.id,
          state.layout
        )
          ? action.payload.i
          : "",
        minCapacity: action.payload.minCapacity,
        maxCapacity: action.payload.maxCapacity,
        backup: false,
        userCanBook: true,
        partnerCanBook: false,
        circle: action.payload.circle ?? false,
        box: {
          width: action.payload.w,
          height: action.payload.h,
        },
        point: {
          x: action.payload.x,
          y: action.payload.y,
        },
        combinesWith: [],
        isResizable: action.payload.isResizable,
      };
      tablesEdited.push(newTableAdded);
      return {
        ...state,
        layout: {
          ...state.layout,
          maxCapacity: tablesEdited.reduce(
            (acc, table) => acc + table.maxCapacity,
            0
          ),
          tables: tablesEdited,
        },
        loading: false,
        error: { action: "", message: "", severity: "info" },
        newTable: undefined,
        editingTable: newTableAdded,
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, tablesEdited)
        ),
        tableCreationMode: true,
      };
    case REMOVE_TABLE:
      let tablesRemoved = state.layout.tables.filter(
        (table) => table.id !== action.payload.id
      );
      tablesRemoved = tablesRemoved.map((table) => {
        return {
          ...table,
          combinesWith: table.combinesWith.filter(
            (combine) => !combine.tables.includes(action.payload.id)
          ),
        };
      });
      const combinedTablesWithoutRemoved = state.combinedTables.filter(
        (combination) => {
          return !combination.tables.includes(action.payload.id);
        }
      );
      return {
        ...state,
        layout: updateLayoutTables(state.layout, tablesRemoved),
        combinedTables: combinedTablesWithoutRemoved,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, tablesRemoved)
        ),
        editingTable: undefined,
        tableCreationMode: false,
      };
    case CHANGE_TABLE_LAYOUT_PROPERTIES:
      //1. Detect the new values
      const newValues = Object.keys(action.payload).reduce((acc, key) => {
        if (action.payload[key] !== undefined) {
          acc[key] = action.payload[key];
        }
        return acc;
      }, {});

      //2. Analyze if the id of the table is changed
      let table = state.editingTable; //current table
      const tableHasChangedId = newValues.id && newValues.id !== table.id;

      let newCombinedTables = [...state.combinedTables];
      if (newValues.backup) {
        newValues.combinesWith = [];
        newCombinedTables = newCombinedTables.filter((combineTable) => {
          if (combineTable.tables.includes(table.id)) {
            return false;
          }
          return true;
        });
      }
      if (newValues.userCanBook && (table.partnerCanBook || table.backup)) {
        newValues.partnerCanBook = false;
        newValues.backup = false;
        newCombinedTables = newCombinedTables.map((combineTable) => {
          if (combineTable.tables.includes(table.id)) {
            const tablesInCombination = state.layout.tables.filter((table) =>
              combineTable.tables.includes(table.id)
            );
            const partnerCanBook = tablesInCombination.some(
              (t) => t.partnerCanBook && t.id !== table.id
            );
            if (partnerCanBook) {
              combineTable.partnerCanBook = true;
              combineTable.userCanBook = false;
            } else {
              combineTable.partnerCanBook = false;
              combineTable.userCanBook = true;
            }
          }
          return combineTable;
        });
      }
      if (newValues.partnerCanBook && (table.userCanBook || table.backup)) {
        newValues.userCanBook = false;
        newValues.backup = false;
        newCombinedTables = newCombinedTables.map((combineTable) => {
          if (combineTable.tables.includes(table.id)) {
            combineTable.partnerCanBook = true;
            combineTable.userCanBook = false;
          }
          return combineTable;
        });
      }
      let tableIndex = state.layout.tables.findIndex((t) => t.id === table.id);
      let tablesEditedProperties = [...state.layout.tables];
      tablesEditedProperties[tableIndex] = {
        ...table,
        ...newValues,
      };

      tablesEditedProperties.forEach((t) => {
        if (t.combinesWith.length > 0) {
          const tablesWithTheEditingTableInCombination = t.combinesWith.filter(
            (combination) => combination.tables.includes(table.id)
          );
          if (tablesWithTheEditingTableInCombination.length > 0) {
            if (newValues.backup) {
              const ids = tablesWithTheEditingTableInCombination.map(
                (combination) => combination.id
              );
              t.combinesWith = t.combinesWith.filter(
                (combination) => !ids.includes(combination.id)
              );
            } else if (newValues.userCanBook) {
              tablesWithTheEditingTableInCombination.forEach((combination) => {
                let fullTablesInCombination = tablesEditedProperties.filter(
                  (t) => combination.tables.includes(t.id)
                );
                const userCanBook =
                  fullTablesInCombination.filter(
                    (t) => !t.userCanBook && t.id !== table.id
                  ).length === 0;
                if (userCanBook) {
                  combination.userCanBook = true;
                  combination.partnerCanBook = false;
                } else {
                  combination.userCanBook = false;
                  combination.partnerCanBook = true;
                }
              });
            } else if (newValues.partnerCanBook) {
              tablesWithTheEditingTableInCombination.forEach((combination) => {
                combination.userCanBook = false;
                combination.partnerCanBook = true;
              });
            }
          }
        }
      });
      // }

      if (tableHasChangedId) {
        tablesEditedProperties.forEach((table) => {
          if (table.combinesWith.length > 0) {
            table.combinesWith.forEach((combination) => {
              combination.tables = combination.tables.map((tableId) => {
                if (tableId === table.id) {
                  return newValues.id;
                }
                return tableId;
              });
            });
          }
        });

        newCombinedTables.forEach((combination) => {
          combination.tables = combination.tables.map((tableId) => {
            if (tableId === table.id) {
              return newValues.id;
            }
            return tableId;
          });
        });
      }

      return {
        ...state,
        layout: updateLayoutTables(state.layout, tablesEditedProperties),
        combinedTables: newCombinedTables,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, tablesEditedProperties)
        ),
        tableCreationMode: false,
      };
    case CHANGE_TABLE_LAYOUT_COORDINATES:
      let tableIndexNewCoordinates = state.layout.tables.findIndex(
        (table) => table.id === action.payload.id
      );
      let tablesEditedPropertiesNewCoordinates = [...state.layout.tables];
      tablesEditedPropertiesNewCoordinates[tableIndexNewCoordinates] = {
        ...state.layout.tables[tableIndexNewCoordinates],
        ...action.payload,
      };
      return {
        ...state,
        layout: updateLayoutTables(
          state.layout,
          tablesEditedPropertiesNewCoordinates
        ),
        loading: false,
        error: { action: "", message: "", severity: "info" },
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, tablesEditedPropertiesNewCoordinates)
        ),
        tableCreationMode: false,
      };
    case CHANGE_EDITING_TABLE_NEW:
      let tablesEditedWithoutEmptyAlias = [...state.layout.tables];
      tablesEditedWithoutEmptyAlias = tablesEditedWithoutEmptyAlias.filter(
        (table) => table.alias !== ""
      );
      return {
        ...state,
        layout: updateLayoutTables(state.layout, tablesEditedWithoutEmptyAlias),
        editingTable: action.payload,
        tableCreationMode: false,
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, tablesEditedWithoutEmptyAlias)
        ),
      };
    //Layouts Main props
    case CHANGE_LAYOUT_NAME:
      return {
        ...state,
        layout: {
          ...state.layout,
          name: action.payload,
        },
        edited: areThereDifferencesBetweenTheLayouts(state.originalLayout, {
          ...state.layout,
          name: action.payload,
        }),
      };
    case CHANGE_LAYOUT_MAX_GROUP:
      return {
        ...state,
        layout: {
          ...state.layout,
          maxGroup: action.payload,
        },
        edited: areThereDifferencesBetweenTheLayouts(state.originalLayout, {
          ...state.layout,
          maxGroup: action.payload,
        }),
      };
    //Combinations
    case START_NEW_COMBINATION:
      return {
        ...state,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        combineMode: true,
      };
    case UPDATE_COMBINATION_NAME:
      return {
        ...state,
        newCombineTables: {
          ...state.newCombineTables,
          alias: action.payload.alias,
        },
      };
    case ADD_TABLE_TO_COMBINATION:
      const tableId = action.payload.id;
      const idx = state.newCombineTables.tables.findIndex(
        (id) => id === tableId
      );
      if (idx !== -1) {
        return state;
      }
      const newTableName =
        state.newCombineTables.alias.length === 0
          ? action.payload.alias
          : state.newCombineTables.alias + "/" + action.payload.alias;
      const maxCapacity =
        state.newCombineTables.maxCapacity + action.payload.maxCapacity;
      const minCapacity =
        state.newCombineTables.minCapacity + action.payload.minCapacity;
      const newCombineTable = {
        ...state.newCombineTables,
        alias: newTableName,
        maxCapacity,
        minCapacity,
        tables: [...state.newCombineTables.tables, action.payload.id],
      };
      let filteredTables = state.layout.tables.filter((table) => {
        if (newCombineTable.tables.includes(table.id)) {
          return true;
        }
        return false;
      });
      newCombineTable.userCanBook = filteredTables.every(
        (table) => table.userCanBook
      );
      newCombineTable.partnerCanBook = filteredTables.every(
        (table) => table.partnerCanBook
      );
      return {
        ...state,
        newCombineTables: newCombineTable,
      };
    case REMOVE_TABLE_FROM_COMBINATION:
      const tableIdToRemove = action.payload.id;
      const index = state.newCombineTables.tables.findIndex(
        (id) => id === tableIdToRemove
      );
      if (index === -1) {
        return state;
      }
      const filterTables = state.newCombineTables.tables.filter(
        (id) => id !== tableIdToRemove
      );
      const fullFilterTables = state.layout.tables.filter((table) =>
        filterTables.includes(table.id)
      );
      let userCanBook = fullFilterTables.every((table) => table.userCanBook);
      let partnerCanBook = !userCanBook;
      return {
        ...state,
        newCombineTables: {
          ...state.newCombineTables,
          tables: filterTables,
          alias: fullFilterTables.map((table) => table.alias).join("/"),
          maxCapacity: fullFilterTables.reduce(
            (acc, table) => acc + table.maxCapacity,
            0
          ),
          minCapacity: fullFilterTables.reduce(
            (acc, table) => acc + table.minCapacity,
            0
          ),
          userCanBook,
          partnerCanBook,
        },
      };
    case CANCEL_COMBINATION:
      return {
        ...state,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combineMode: false,
      };
    case SAVE_COMBINATION:
      let newCombination = action.payload;
      newCombination.maxCapacity = Number(newCombination.maxCapacity);
      newCombination.minCapacity = Number(newCombination.minCapacity);
      newCombination.userCanBook = state.layout.tables
        .filter((table) => newCombination.tables.includes(table.id))
        .every((table) => table.userCanBook);
      newCombination.partnerCanBook = !newCombination.userCanBook;
      let combinedTables = [...state.combinedTables];
      let newTablesEdited = state.layout.tables.map((table) =>
        deepCopyObject(table)
      );

      // En caso de estar editando la combinacion, se "elimina" de la comprobacion de duplicados
      if (!deepEqual(state.editCombineTables, emptyCombinationTable)) {
        const editCombinationTable = state.editCombineTables;
        editCombinationTable.tables.sort((a, b) => a.localeCompare(b));
        combinedTables = combinedTables.filter((t) => {
          return t.id !== editCombinationTable.id;
        });
      }

      const combinationAlreadyExists = checkCombinationAlreadyExist(
        combinedTables,
        newCombination
      );
      // Si la combination ya existe, se lanza un error
      if (combinationAlreadyExists) {
        return {
          ...state,
          newCombineTables: deepCopyObject(emptyCombinationTable),
          error: {
            action: "saveCombination",
            message: combinationAlreadyExists,
            severity: "error",
          },
        };
      }

      // Si se estaba editando una combinacion y se guardan los cambios
      // se debe eliminar la combinacion vieja y agregar la nueva
      if (!deepEqual(state.editCombineTables, emptyCombinationTable)) {
        // newTablesEdited = removeCombinationFromTables(
        //   newTablesEdited,
        //   state.editCombineTables
        // );
        newTablesEdited = newTablesEdited.map((table) => {
          table.combinesWith = table.combinesWith.filter(
            (t) => t.id !== state.editCombineTables.id
          );
          return table;
        });
      }

      newTablesEdited.forEach((tableEdited) => {
        if (newCombination.tables.includes(tableEdited.id)) {
          const tables = newCombination.tables.filter(
            (t) => t.id !== tableEdited.id
          );
          const newCombinationToAdd = {
            maxCapacity: newCombination.maxCapacity,
            minCapacity: newCombination.minCapacity,
            alias: newCombination.alias,
            id: newCombination.id,
            tables,
          };
          tableEdited.combinesWith.push(deepCopyObject(newCombinationToAdd));
        }
      });
      return {
        ...state,
        layout: updateLayoutTables(state.layout, newTablesEdited),
        combinedTables: [...combinedTables, newCombination],
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combineMode: false,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, newTablesEdited)
        ),
      };
    case REMOVE_COMBINATION:
      let newTablesWithoutCombination = [...state.layout.tables];
      newTablesWithoutCombination = newTablesWithoutCombination.map((table) => {
        if (table.combinesWith.length > 0) {
          table.combinesWith = table.combinesWith.filter(
            (t) => t.id !== action.payload.id
          );
        }
        return table;
      });
      return {
        ...state,
        layout: updateLayoutTables(state.layout, newTablesWithoutCombination),
        combineMode: false,
        loading: false,
        error: { action: "", message: "", severity: "info" },
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        combinedTables: state.combinedTables.filter(
          (table) => table.id !== action.payload.id
        ),
        edited: areThereDifferencesBetweenTheLayouts(
          state.originalLayout,
          updateLayoutTables(state.layout, newTablesWithoutCombination)
        ),
      };
    case UPDATE_COMBINATION:
      return {
        ...state,
        combineMode: true,
        newCombineTables: deepCopyObject(action.payload),
        editCombineTables: deepCopyObject(action.payload),
      };
    case REFRESH_ERROR:
      return {
        ...state,
        error: {
          action: "",
          message: "",
          severity: "info",
        },
      };
    case RESET_ALL_DATA:
      return initialState;
    case BACK_TO_VIEW_MODE:
      return {
        ...state,
        combineMode: false,
        newCombineTables: deepCopyObject(emptyCombinationTable),
        editCombineTables: deepCopyObject(emptyCombinationTable),
        sector: deepCopyObject(emptySector),
        layout: deepCopyObject(emptyLayout),
        combinedTables: [],
        loading: false,
        error: { action: "", message: "", severity: "info" },
        mode: SECTOR_MODE.VIEW,
        sectors: [],
        originalLayout: deepCopyObject(emptyLayout),
      };
    default:
      return state;
  }
};

export default sectorsLayoutsTables;
