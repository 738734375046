import { itemStyle, itemType } from "./styles/modalStyles";
import { Box } from "@mui/material";

export const GetStyledItem = (name, type) => {
    return (
        <Box>
            <span style={ itemStyle }>{name}</span>
            <span style={ itemType }>{` (${type})`}</span>
        </Box>
    );
}