import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        width: '250px',
        height: '35px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '18px',
        backgroundColor: 'white',
        paddingTop: '8px',
    },
}));

const Search = (props) => {

    const classes = useStyles();

    const {value} = props

    const {onChange, onKeyPress} = props

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={2} style={{paddingLeft: '10px', paddingTop: '3px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.9" height="15.9" viewBox="0 0 28.9 28.9">
                        <path id="Trazado_671" data-name="Trazado 671" d="M28.9,26.355l-6-6a12.445,12.445,0,0,0,2.545-7.634A12.645,12.645,0,0,0,12.723,0,12.645,12.645,0,0,0,0,12.723,12.645,12.645,0,0,0,12.723,25.447,12.445,12.445,0,0,0,20.357,22.9l6,6ZM3.635,12.723a9,9,0,0,1,9.088-9.088,9,9,0,0,1,9.088,9.088,9,9,0,0,1-9.088,9.088A9,9,0,0,1,3.635,12.723Z" fill="#afafaf"/>
                    </svg>
                </Grid>
                <Grid item xs={10} style={{paddingLeft: '3px'}}>
                    <input
                        type='text'
                        placeholder="Buscar"
                        value={value}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        style={{
                            width: '100px',
                            border: 'none',
                            outline: 'none',
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Search
