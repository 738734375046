import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { setReservableSectors } from "../../../../../actions/shiftsActions";

// Components
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import ShiftSelectWithArrows from "./General/ShiftSelectWithArrows";
import ShiftSelectWithLabel from "./General/ShiftSelectWithLabel";
import TextArea from "./General/TextArea";

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { GetLayout, GetSectorLayout } from "../utils/sectorsFunctions";
import { aceptacionAutomatica } from "../utils/constants";

const ReservableSectorCard = (props) => {
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);  
  const [active, setActive] = useState(true);  
    
  
  // Variables
  const {
    availableLayouts,
    reservableSectors,
    workWithWaitList,
    maxBookingGroup,
    minBookingGroup,
    subShifts,
    sectorId,
    layouts,
    sectors,
    name,
  } = props;

  // Acciones
  const {
    removeLayoutAndAddAvailableLayout,
    removeAvailableLayout,
    handleChangeMinValue,
    handleChangeMaxValue,
    setReservableSectors,
    addAvailableLayouts,
    handleChangeNote,
  } = props;

  // const [layoutId, setLayout] = useState(layouts[0].uuid);
  const layout = GetLayout( availableLayouts, sectorId, layouts );
  const [minValue, setMinValue] = useState( 1 );
  const [maxValue, setMaxValue] = useState( 20 );
  const [note, setNote] = useState('');

  const [GroupsArray, setGroupsArray] = useState([]);

    useEffect(() => {
        let newGroupsArray = [];
        for (let i = minBookingGroup; i <= maxBookingGroup; i++) {
            newGroupsArray.push({
                id: i,
                value: i
            });
        };

        setGroupsArray( newGroupsArray );
    }, [ minBookingGroup, maxBookingGroup ]);

  const handleAdvancedOptionsClick = () => {
    setAdvancedOptionsOpen( !advancedOptionsOpen );
  };

  useEffect(() => {
    if ( availableLayouts.length > 0 ) {
      const exist = availableLayouts.find((availableLayout) => availableLayout && availableLayout.sectorId === sectorId);
      if(exist) {
        setActive(true);
      } else {
        setActive(false);
      };
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableLayouts])

  const addNewLayout = (layoutId) => {
    const newLayout = GetSectorLayout(layoutId, sectors);
    
    setNote( newLayout.note ? newLayout.note : '' );
    setMinValue( newLayout.minCapacityAuto ? newLayout.minCapacityAuto : 1 );
    setMaxValue( newLayout.maxCapacityAuto ? newLayout.maxCapacityAuto : 20 );

    return {
        id: layoutId,
        minCapacityAuto: 1,
        maxCapacityAuto: 20,
        note: '',
        activeWaitList: workWithWaitList,
        sectorId: sectorId
    };
  }

  const handleChangeActive = () => {
    setActive(!active);
    if(!active === false) {
      removeAvailableLayout(sectorId);
    } else {
      const firstLayout = layouts[0];

      addAvailableLayouts( firstLayout.uuid, 2, 20, '', sectorId );

      // Si existe
      const exist = reservableSectors.find((reservableSector) => reservableSector.id === firstLayout.uuid);

      if ( exist ) {
        // Habilitar en reservableSectors
        const newReservableSectors = reservableSectors.map((reservableSector) => {
          if (firstLayout.uuid === reservableSector.id) {
            return {
              ...reservableSector,
              available: true,
            } 
          } else {
            return reservableSector;
          }
        })

        setReservableSectors( newReservableSectors );

      } else {
        const sector = sectors.find( (sector) => sector.id === sectorId );
        let newGroupArray = [];
        
        for (let i = 0; i < GroupsArray.length; i++) {
          newGroupArray.push({
            amount: GroupsArray[i],
            enabled: false
          });
        };

        reservableSectors.push({
          tables: firstLayout.tables,
          type: firstLayout.name,
          id: firstLayout.uuid,
          name: sector.name,
          subShifts: subShifts.map((subshift) => (
            {
              id: subshift.id, 
              enabled: true
            }
          )),
          available: true,
        })

        setReservableSectors( reservableSectors );
      }
    }    
  };

  const handleChangeLayout = (layoutId) => {
    const removeLayoutId = availableLayouts.find((lay) => lay.sectorId === sectorId && lay.id !== layoutId).id;
    const newLayout = addNewLayout(layoutId);

    removeLayoutAndAddAvailableLayout(removeLayoutId, newLayout);
  };

  useEffect(() => {
    handleChangeNote(note, layout.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    handleChangeMinValue(minValue, layout.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue]);

  useEffect(() => {
    handleChangeMaxValue(maxValue, layout.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValue]);

  useEffect(() => {
    handleChangeMaxValue(layout.maxCapacityAuto ?? 20, layout.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChangeMinValue(layout.minCapacityAuto ?? 1, layout.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNote(layout.note ?? '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ 
        border: '1px solid #D1D1D1',
        borderRadius: '10px',
        maxWidth: '811px',
        bgcolor: '#FFF',
        padding: '20px'
      }}
    >
      <Box sx={{ display: 'flex' , alignItems: 'center', justifyContent: 'space-between'}}>
        <Box sx={{ width: 'max-content', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mt: '-.5rem' }}>
            <BlueSwitch
              onChange={() => handleChangeActive()}
              checked={ active }
              color="default"
            />
          </Box>
          <Typography 
            sx={{
              fontFamily: 'Poppins',
              fontSize: '1.2rem',
              color: '#041C32',
              fontWeight: 500, 
            }} 
          >
            { name }
          </Typography>
        </Box>
        <Box>
          <ShiftSelectWithLabel
            handleChange={ handleChangeLayout } 
            optionsArray={ layouts } 
            disabled={ !active } 
            value={ layout.id } 
            label={ 'Layout' }
          />
          {/* <FormControl fullWidth>
            <InputLabel sx={{ fontFamily: 'Poppins' }} id="demo-simple-select-label">Layout</InputLabel>
            <Select
              disabled={!active}
              labelId="demo-simple-select-label"
              value={layout.id}
              label="layout"
              onChange={(e) => handleChangeLayout(e.target.value)}
              sx={{ width: "300px", borderRadius: "10px", height: "2.6rem" }}
              inputProps={{
                style: {
                  fontFamily: "Poppins, sans-serif",
                  color: "#041C32",
                },
              }}
              MenuProps={{
                style: {
                  fontFamily: "Poppins, sans-serif",
                  maxHeight: 200,
                },
              }}
            >
              {
                layouts.map((layoutId) => (
                  <MenuItem key={layoutId.uuid} value={layoutId.uuid}>{layoutId.name}</MenuItem>
                ))
              }              
            </Select>
          </FormControl> */}
        </Box>
      </Box>
      <Collapse in={ active }>
        <TextArea
          placeholder="Escribí una nota adicional al sector"
          onChange={(e) => setNote(e.target.value)}
          description="Nota (Opcional)"
          label={ "Nota (Opcional)" }
          text={ layout.note }
          characters={ 90 }
        ></TextArea>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          width={'263px'}
          sx={{ cursor: "pointer" }}
          onClick={handleAdvancedOptionsClick}
        >
          <Box
            width="11.5px"
            height="11.5px"
            borderRadius={50}
            bgcolor="#35C18F"
          ></Box>
          <Typography
            fontSize="1rem"
            color="#041C32"
            fontWeight="500"
            fontFamily="Poppins"
            my={3}
            sx={{ textDecoration: "underline" }}
          >
            Configuración avanzada
          </Typography>
          {advancedOptionsOpen ? (
            <ExpandLessIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
          ) : (
            <ExpandMoreIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
          )}
        </Stack>
        <Collapse in={ advancedOptionsOpen }>
          <Stack mt={3} alignItems="center" direction="row" spacing={3}>
            <Box>
              <Typography sx={{ fontSize: '1rem', color: '#041C32', fontWeight: 500, fontFamily: 'Poppins' }} >
                Aceptación automática
              </Typography>
            </Box>
            <ShiftSelectWithArrows
              optionsArray={ aceptacionAutomatica }
              value={ layout.minCapacityAuto } 
              handleChange={ setMinValue } 
              label='Mín' 
            /> 
            <ShiftSelectWithArrows
              optionsArray={ aceptacionAutomatica }
              value={ layout.maxCapacityAuto } 
              handleChange={ setMaxValue } 
              label='Máx' 
            /> 
            {/* <FormControl>
              <InputLabel id="aceptacionMinima">Mín</InputLabel>
                <Select
                      labelId="aceptacionMinima"
                      value={layout.minCapacityAuto}
                      label="Mín"
                      onChange={(e) => setMinValue(e.target.value)}
                      sx={{
                        ...selectStyles,
                        width: "73px",
                      }}
                    >
                      {
                        aceptacionAutomatica.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.option}</MenuItem>
                        ))
                      } 
                </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="aceptacionMaxima">Max</InputLabel>
                <Select
                      labelId="aceptacionMaxima"
                      value={layout.maxCapacityAuto}
                      label="Max"
                      onChange={(e) => setMaxValue(e.target.value)}
                      sx={{
                        ...selectStyles,
                        width: "73px",
                      }}
                    >
                      {
                        aceptacionAutomatica.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.option}</MenuItem>
                        ))
                      } 
                </Select>
            </FormControl> */}
          </Stack>
        </Collapse>
      </Collapse>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {      
      availableLayouts: state.v3.shifts.shiftData.availableLayouts,
      workWithWaitList: state.v3.shifts.shiftData.workWithWaitList,
      maxBookingGroup: state.v3.shifts.shiftData.maxBookingGroup,
      minBookingGroup: state.v3.shifts.shiftData.minBookingGroup,
      reservableSectors: state.v3.shifts.reservableSectors,
      subShifts: state.v3.shifts.shiftData.subShifts,
      sectors: state.v3.shifts.sectors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReservableSectors: (data) => dispatch( setReservableSectors(data) ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservableSectorCard);