import React from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Components
import PlanningTextFieldComponent from '../PlanningTextAreaComponent';
import { BlueSwitch } from '../../../GeneralComponents/blueSwitch';
import PunctualDatesComponent from '../PunctualDatesComponent';

// Actions
import { setClosingDatesForm } from '../../../../actions';

// Material Ui
import { Box, Collapse, Divider, Typography } from '@mui/material';

// Styles
import { OptionalSubtitleStyles, TransitionGroupStyles } from '../../Styles';
import NewRangeHourComponent from '../NewRangeHourComponent';
import AddNewButton from '../AddNewButton';

const ScheduledClosingsForm = ( props ) => {

    // Actions
    const { setClosingDatesForm } = props;

    // States
    const { closingDatesForm } = props;

    const handleSetReason = ( e ) => {
        setClosingDatesForm({
            ...closingDatesForm,
            reason : e
        });
    };

    const handleAddNewHour = () => {
        setClosingDatesForm({
            ...closingDatesForm,
            rangeHours: [
                ...closingDatesForm.rangeHours,
                {
                    id: `${ closingDatesForm.rangeHours.length }`,
                    from: 540,
                    to: 960
                }
                
            ]
        });
    };

    const handleDeleteRangeHour = ( id ) => {
        let rangeHoursCopy = [ ...closingDatesForm.rangeHours ];

        rangeHoursCopy = rangeHoursCopy.filter( item => item.id !== id );

        console.log( rangeHoursCopy );

        setClosingDatesForm({
            ...closingDatesForm,
            rangeHours: [ ...rangeHoursCopy ]
        });
    };

    const handleChangeEnabledRangeHours = () => {
        setClosingDatesForm({
            ...closingDatesForm,
            enabledRangeHours: !closingDatesForm.enabledRangeHours
        });
    }

    return (
        <Box>
            <PlanningTextFieldComponent 
                value={ closingDatesForm.reason }
                setValue={ handleSetReason }
            />
            <Divider sx={{ m: '1rem 0' }}/>
            <Box>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Fecha de cierre</Typography>
                <PunctualDatesComponent component ='closings' />
            </Box>
            <Divider sx={{ m: '1rem 0 .6rem 0' }}/>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: '-.5rem', mb: '1rem' }}>
                    <BlueSwitch 
                        checked={ closingDatesForm.enabledRangeHours }
                        onChange={ handleChangeEnabledRangeHours }
                    />
                    <Typography variant='h6' sx={ OptionalSubtitleStyles }>Rango horario (Opcional)</Typography>
                </Box>
                <TransitionGroup style={ TransitionGroupStyles }>
                    {
                        closingDatesForm.rangeHours?.map( item =>
                            <Collapse key={ item.id } orientation='vertical'>
                                <NewRangeHourComponent
                                    onDelete={ () => handleDeleteRangeHour( item.id ) } 
                                    arrayDate='closings'
                                    from={ item.from }
                                    id={ item.id }
                                    to={ item.to } 
                                />    
                            </Collapse>
                        )
                    }
                </TransitionGroup>
                <Box sx={{ mt: '.5rem' }}>
                    { closingDatesForm.enabledRangeHours && <AddNewButton onClick={ handleAddNewHour } type='range'/> }
                </Box>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        closingDatesForm: state.planningReducer.closingDatesForm,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setClosingDatesForm: ( data ) => dispatch( setClosingDatesForm( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ScheduledClosingsForm );