import React from 'react';
import {InputAdornment, makeStyles, TextField} from '@material-ui/core';

//Arrows
import ArrowUpPrimary from '../SvgComponents/icons/IconsPrimary/ArrowUpPrimary';
import ArrowDownPrimary from '../SvgComponents/icons/IconsPrimary/ArrowDownPrimary';

const useStyles = makeStyles(() => ({
    price: {
        width: '120px',
        height: '31px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '18px',
        display: 'inline-block'
    },
}));

const ChangePrice = (props) => {

    const classes = useStyles();

    const {tabIndex, value} = props;

    const {onChange} = props;

    return (
        <div style={{}}>
            <div className={classes.price}>
                <div style={{display: 'flex'}}>
                    <TextField
                        tabIndex={tabIndex}
                        style={{width: '100px', marginLeft: '10px',}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <b>$</b>
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                        onChange={(e) => {
                            onChange(isNaN(Number(e.target.value)) ? 1 : Number(e.target.value))
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                        value={value}
                    />
                    <div style={{marginRight: '5px'}}>
                        <div
                            style={{marginTop: '-1px'}}
                            onClick={() => onChange(isNaN(Number(value)) ? 1 : Number(value) + 1)}>
                            <ArrowUpPrimary transform={"translate(0 0)"} width={"14px"} height={"7.6px"}/>
                        </div>
                        <div
                            onClick={() => onChange(isNaN(Number(value)) ? 0 : Number(value) - 1)}>
                            <ArrowDownPrimary width={"14px"} height={"7.6px"} transform="translate(1.8 5)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePrice
