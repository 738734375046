import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { setRotationControl } from "../../../../../actions/shiftsActions";

// Components
import AdvancedOptionsRotationConfig from "./AdvancedOptionsRotationConfig";
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import { Subtitle } from "../../Subtitle";
import { Title } from "../../Title";

// Icons
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { BoltIcon } from "../../../../SvgComponents/icons";

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { SetRotationControlEmpty } from "../utils/advancedOptionsFunctions";
import { offRotationControOptions } from "../utils/constants";

// Styles
import { DecriptionShiftStyles, SubtitleShiftStyles } from "../../../Styles";
import ShiftSimpleSelect from "./General/ShiftSimpleSelect";
import AdvancedOptionsTableRotationConfig from "./AdvancedOptionsTableRotationConfig";

const AdvancedOptionsRotationControl = (props) => {
  // Variables
  const { rotationControl } = props;

  // Acciones
  const { setRotationControl } = props;

  console.log( 'rotationControl --> ', rotationControl );

  const [rotationEnabled, setRotationEnabled] = useState(
    rotationControl !== null
  );

  const [maxEfficiency, setMaxEfficiency] = useState(false);
  const [offRotationValue, setOffRotationValue] = useState(
    rotationControl && rotationControl.advanceEndRotationControl
      ? rotationControl.advanceEndRotationControl
      : 0
  );

  const handleChangeRotationControl = () => {
    if (!rotationEnabled === true) {
      const newValue = SetRotationControlEmpty();
      setRotationControl(newValue);
    } else {
      setRotationControl(null);
    }
    
    setRotationEnabled(!rotationEnabled);
  };

  const handleChangeOffRotationValue = (value) => {
    setOffRotationValue(value);

    rotationControl.advanceEndRotationControl = value;
    setRotationControl(rotationControl);
  };

  const handleChangeMaxEfficiency = () => {
    setMaxEfficiency(!maxEfficiency);

    if (!maxEfficiency === true) {
      rotationControl.advanceEndRotationControl = 0;
      rotationControl.maxEfficiency = true;
    } else {
      rotationControl.advanceEndRotationControl = 0;
      rotationControl.maxEfficiency = false;
    }

    setRotationControl(rotationControl);
  };

  useEffect(() => {
    if (
      rotationControl &&
      (Object.keys(rotationControl?.rotationControlByGroup).length > 0 ||
      Object.keys(rotationControl?.rotationControlByTable).length > 0)
      ) {
        setMaxEfficiency(false);
      } else {
        setMaxEfficiency(true);
      }
  }, [rotationControl]);

  return (
    <>
      {/* ///////////////////////////// */}
      <Box sx={{ ml: "-.7rem", display: "flex", alignItems: "center", mb: '.5rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '-.5rem' }}>
            <Box sx={{ mt: '.2rem' }}>
              <BlueSwitch
                onChange={() => handleChangeRotationControl()}
                checked={rotationEnabled}
                color="default"
              />
            </Box>
            <Typography sx={ SubtitleShiftStyles } >Control de rotación</Typography>
          </Box>
          <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              sx={{
                  backgroundColor: '#ffe2bc',
                  borderRadius: '6px',
                  padding: '4px 8px',
              }}
          >
            <Typography 
                sx={{
                    fontFamily: 'Poppins',
                    color: '#041c32',
                    fontWeight: 500
                }}
            >
              BETA
            </Typography>
            <RocketLaunchIcon color="#f5920d" sx={{color: '#f5920d !important'}}/>
          </Box>
        </Box>
      </Box>
      <Typography sx={ DecriptionShiftStyles } >
        Activa el control de rotación para exigir un número mínimo de rotaciones para cada tamaño de grupo
      </Typography>
      {/* ///////////////////////////// */}
      <Collapse in={ rotationEnabled }>
          <Box ml="-.7rem" mt={2} display="flex" alignItems="center">
            <Box sx={{ mt: '.2rem' }}>
              <BlueSwitch
                onChange={() => handleChangeMaxEfficiency()}
                checked={ maxEfficiency }
                color="default"
              />
            </Box>
            <Box sx={{ paddingTop: "12px", paddingBottom: "4px" }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Title text="Activar máxima eficiencia" />
                <BoltIcon />
              </Stack>
            </Box>
          </Box>
          <Typography sx={ DecriptionShiftStyles } >
            Activa el control de rotación para alcanzar la máxima eficiencia de llenado
          </Typography>
          <Collapse in={ !maxEfficiency }>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', p: '1.2rem 0 .5rem 0' }}>
              <Box sx={{ mb: '.8rem' }}>
                <AdvancedOptionsRotationConfig
                  title={ "Personalizar rotación por grupos" }
                  type={ "groups" }
                />
              </Box>
              <AdvancedOptionsTableRotationConfig
                title={ "Personalizar rotación por mesas" }
                type={ "tables" }
              />
            </Box>
          </Collapse>
          <Box display="flex" alignItems="center" marginTop="16px">
            <Subtitle
              text="Seleccioná cuándo queres que se desactive el control de rotación"
              style={{ fontSize: "20px" }}
            />
            <Box style={{ marginLeft: "14px" }}>
              <ShiftSimpleSelect
                handleChange={ handleChangeOffRotationValue } 
                optionsArray={ offRotationControOptions }
                value={ offRotationValue }
              />
              {/* <FormControl>
                <Select
                  value={offRotationValue}
                  onChange={(e) => handleChangeOffRotationValue(e.target.value)}
                  sx={{
                    ...selectStyles,
                    width: "168px",
                  }}
                >
                  {offRotationControOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
          </Box>
      </Collapse>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rotationControl: state.v3.shifts.shiftData.rotationControl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRotationControl: (data) => dispatch(setRotationControl(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedOptionsRotationControl);
