import {
    ADD_BOOKING_HOUR_INTERVAL,
    ADD_SECTOR_TO_SHIFT,
    ADD_SUB_SHIFT_TO_SHIFT,
    CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    CHANGE_BOOKING_NOTIFICATION,
    CHANGE_RESERVAS_SHIFT_ADVANCE_END,
    CHANGE_RESERVAS_SHIFT_ADVANCE_START,
    CHANGE_RESERVAS_SHIFT_DAYS,
    CHANGE_RESERVAS_SHIFT_END_DATE,
    CHANGE_RESERVAS_SHIFT_NAME,
    CHANGE_RESERVAS_SHIFT_OPENTIME,
    CHANGE_RESERVAS_SHIFT_START_DATE,
    CHANGE_SECTOR_ON_SHIFT,
    CHANGE_SHIFT_CONFIRMATION_TOLERANCE,
    CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE,
    CHANGE_SHIFT_MODE,
    CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING,
    CHANGE_SHIFT_TOLERANCE,
    CHANGE_SUB_SHIFT, CHANGE_WAIT_LIST_COMMENT, CHANGE_WAIT_LIST_QUANTITY,
    CLOSE_BOOKING_ERROR,
    CLOSE_ERROR_DIALOG,
    DELETING_SHIFT_FAILURE,
    DELETING_SHIFT_SUCCESS,
    EDIT_BOOKING_HOUR_INTERVAL,
    EDIT_BOOKING_HOUR_INTERVAL_COMPLETE,
    EDIT_BOOKING_HOUR_INTERVAL_ENABLE,
    FETCH_BOOKING_ALL_SECTORS,
    FETCH_BOOKING_ALL_SECTORS_FAILURE,
    FETCH_BOOKING_ALL_SECTORS_SUCCESS,
    FETCH_BOOKING_SETTINGS,
    FETCH_BOOKING_SETTINGS_FAILURE,
    FETCH_BOOKING_SETTINGS_SUCCESS,
    FETCH_SHIFTS,
    FETCH_SHIFTS_FAILURE,
    FETCH_SHIFTS_SUCCESS,
    minutesOfDay,
    RELOAD_BOOKINGS_SUCCESS,
    REMOVE_BOOKING_HOUR_INTERVAL,
    REMOVE_SECTOR_FROM_SHIFT,
    REMOVE_SUB_SHIFT_TO_SHIFT,
    SAVING_SHIFT_FAILURE,
    SAVING_SHIFT_SUCCESS,
    ShiftMode,
    TOGGLE_SHIFT_LOADING,
} from '../../constants';
import {hhssToMinutes, openCloseTimeToOpenDuration} from "../../utils/formatters";
import moment from "moment";
import axios from "axios";
import {deepEqual} from "../../utils/objectHelpers";
import {v4 as uuidv4} from 'uuid';
import {deepCopyObject} from "../../utils/arrayHelpers";

export const reloadBookingsSuccess = () => ({
    type: RELOAD_BOOKINGS_SUCCESS
});

export const closeBookingError = () => ({
    type: CLOSE_BOOKING_ERROR
})

export const toggleShiftLoading = () => ({
    type: TOGGLE_SHIFT_LOADING
});

export const closeErrorDialog = () => ({
    type: CLOSE_ERROR_DIALOG
});

export const saveShiftSuccess = () => ({
    type: SAVING_SHIFT_SUCCESS
});

export const saveShiftFailure = (error) => ({
    type: SAVING_SHIFT_FAILURE,
    payload: error,
});

export const deleteShiftSuccess = () => ({
    type: DELETING_SHIFT_SUCCESS
});
export const deleteShiftFailure = (error) => ({
    type: DELETING_SHIFT_FAILURE,
    payload: error,
});

export const changeShiftMode = ({mode, shift}) => ({
    type: CHANGE_SHIFT_MODE,
    payload: {mode, shift}
});

// Edicion turno
export const changeShiftName = (name) => ({
    type: CHANGE_RESERVAS_SHIFT_NAME,
    payload: name
})
export const changeShiftOpenTime = (opening) => ({
    type: CHANGE_RESERVAS_SHIFT_OPENTIME,
    payload: opening,
});
export const changeShiftAdvanceStart = (advanceStart) => ({
    type: CHANGE_RESERVAS_SHIFT_ADVANCE_START,
    payload: advanceStart,
});
export const changeShiftAdvanceEnd = (advanceEnd) => ({
    type: CHANGE_RESERVAS_SHIFT_ADVANCE_END,
    payload: advanceEnd,
});
export const changeShiftDays = (days) => ({
    type: CHANGE_RESERVAS_SHIFT_DAYS,
    payload: days,
});
export const changeShiftStartDate = (startDate) => ({
    type: CHANGE_RESERVAS_SHIFT_START_DATE,
    payload: startDate
});
export const changeShiftEndDate = (endDate) => ({
    type: CHANGE_RESERVAS_SHIFT_END_DATE,
    payload: endDate
});

// Edicion subturno especifico
export const addSubShift = ({from, to, subSubShifts, type}) => ({
    type: ADD_SUB_SHIFT_TO_SHIFT,
    payload: {from, to, subSubShifts, type},
});
export const removeSubShift = (index) => ({
    type: REMOVE_SUB_SHIFT_TO_SHIFT,
    payload: index,
});
export const editSubShift = ({from, to, subSubShifts, allowsWait, index}) => ({
    type: CHANGE_SUB_SHIFT,
    payload: {from, to, subSubShifts, allowsWait, index}
});

// Edicion sectores de turno
export const addSectorToShift = ({name, alias, capacity, minAutomatic, maxAutomatic, maxCapacity}) => ({
    type: ADD_SECTOR_TO_SHIFT,
    payload: {name, alias, capacity, minAutomatic, maxAutomatic, maxCapacity},
});
export const removeSectorFromShift = (index) => ({
    type: REMOVE_SECTOR_FROM_SHIFT,
    payload: index,
});
export const changeShiftOnSector = ({name, minAutomatic, maxAutomatic}) => ({
    type: CHANGE_SECTOR_ON_SHIFT,
    payload: {name, minAutomatic, maxAutomatic},
});

// CONFIGURACION GENERAL
export const changeAutoAcceptancePercentage = (autoAcceptancePercentage) => ({
    type: CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    payload: autoAcceptancePercentage,
});
export const changeBookingNotification = (bookingNotification) => ({
    type: CHANGE_BOOKING_NOTIFICATION,
    payload: bookingNotification,
});
export const changeShiftTolerance = (shiftsTolerance) => ({
    type: CHANGE_SHIFT_TOLERANCE,
    payload: shiftsTolerance,
});
export const changeShiftConfirmationTolerance = (confirmationTolerance) => ({
    type: CHANGE_SHIFT_CONFIRMATION_TOLERANCE,
    payload: confirmationTolerance,
});
export const toggleShiftConfirmationToleranceEnable = () => ({
    type: CHANGE_SHIFT_CONFIRMATION_TOLERANCE_ENABLE,
});
export const changeShiftToKnowBeforeBooking = (toKnowBeforeBooking) => ({
    type: CHANGE_SHIFT_TO_KNOW_BEFORE_BOOKING,
    payload: toKnowBeforeBooking,
});
export const changeMaxWaitingItemsQuantity = (maxWaitingItemsQuantity) => ({
    type: CHANGE_WAIT_LIST_QUANTITY,
    payload: maxWaitingItemsQuantity,
});
export const changeWaitListComment = (waitListComment) => ({
    type: CHANGE_WAIT_LIST_COMMENT,
    payload: waitListComment,
});

// HORARIOS TOMA DE RESERVAS
export const addBookingHourInterval = ({days, open, enable, duration}) => ({
    type: ADD_BOOKING_HOUR_INTERVAL,
    payload: {days, open, enable, duration},
});
export const removeBookingHourInterval = (index) => ({
    type: REMOVE_BOOKING_HOUR_INTERVAL,
    payload: index,
});
export const editBookingHourInterval = (index) => ({
    type: EDIT_BOOKING_HOUR_INTERVAL,
    payload: index
})
export const editBookingHourIntervalComplete = ({index, open, duration, days}) => ({
    type: EDIT_BOOKING_HOUR_INTERVAL_COMPLETE,
    payload: {index, open, duration, days},
})
export const toggleBookingHourIntervalEnable = (index) => ({
    type: EDIT_BOOKING_HOUR_INTERVAL_ENABLE,
    payload: index
})

export const fetchBookingSettings = () => ({
    type: FETCH_BOOKING_SETTINGS,
});

export const fetchBookingSettingsSuccess = (settings) => ({
    type: FETCH_BOOKING_SETTINGS_SUCCESS,
    payload: settings,
});

export const fetchBookingSettingsFailure = (error) => ({
    type: FETCH_BOOKING_SETTINGS_FAILURE,
    payload: error,
});

export const fetchBookingShifts = () => ({
    type: FETCH_SHIFTS,
});

export const fetchBookingShiftsSuccess = (shifts) => ({
    type: FETCH_SHIFTS_SUCCESS,
    payload: shifts,
});

export const fetchBookingShiftsFailure = (data) => ({
    type: FETCH_SHIFTS_FAILURE,
    payload: data,
});

export const fetchBookingAllSectors = () => ({
    type: FETCH_BOOKING_ALL_SECTORS,
});

export const fetchBookingAllSectorsSuccess = (allSectors) => ({
    type: FETCH_BOOKING_ALL_SECTORS_SUCCESS,
    payload: allSectors,
});

export const fetchBookingAllSectorsFailure = (error) => ({
    type: FETCH_BOOKING_ALL_SECTORS_FAILURE,
    payload: error,
});

function ensurePartnerExists(partner) {
    if (!partner) {
        throw new Error('El usuario no tiene un partner asociado.');
    }
}

export const fetchSettings = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(fetchBookingSettings())
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/booking/settings/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}});
            const {data} = res;
            dispatch(fetchBookingSettingsSuccess(data));
        } catch (error) {
            console.log(error);
            dispatch(fetchBookingSettingsFailure(error.message));
        }
    }
}

export const fetchShifts = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(fetchBookingShifts())
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/booking/schedule/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            dispatch(fetchBookingShiftsSuccess(data));
        } catch (error) {
            console.log(error);
            dispatch(fetchBookingShiftsFailure(error.message));
        }
    }
}

export const fetchAllSectors = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(fetchBookingAllSectors())
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/sector/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            dispatch(fetchBookingAllSectorsSuccess(data));
        } catch (error) {
            console.log(error);
            dispatch(fetchBookingAllSectorsFailure(error.message));
        }
    }
}

export const checkCriticChanges = ({shifts, shift, timeZone}) => {
    return (dispatch) => {
        if (moment(shift.startDate).isAfter(moment(), 'minutes')) {
            return false;
        }
        let originalShift = shifts.find((a) => a.uuid === shift.uuid);
        let originalSectorsName = originalShift.sectors.map(sec => sec.name);
        let editSectorsName = shift.sectors.map(sec => sec.name);
        let originalSubShift = originalShift.subShifts.map(sub => hhssToMinutes(sub.from) - timeZone);
        let editSubShifts = shift.subShifts.map(sub => hhssToMinutes(sub.from) - timeZone);
        let originalDays = originalShift.days;
        let editDays = shift.days;
        return !deepEqual(originalSectorsName, editSectorsName,) || !deepEqual(originalSubShift, editSubShifts) || !deepEqual(originalDays, editDays)
    }
}

async function updateGeneralSettings(partner, auth, updateData) {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        let res = await axios.patch(`${API_URL}/booking/settings/${partner}`, updateData, {headers: {Authorization: `Bearer ${token}`}})
        if (res.status > 300) {
            throw new Error(res.data);
        }
    } catch (e) {
        if (e.response.data === 'Days length in TimeInterval can\'t be less than 1') {
            throw new Error("Los horarios de atención deben tener al menos un día seleccionado");
        }
    }
}

async function updateShift(partner, auth, editedShift) {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        await axios.patch(`${API_URL}/booking/schedule/${partner}`, editedShift, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const res = e.response;
        if (res.status > 300) {
            console.log(res.statusText);
            throw new Error(res.data);
        }
    }
}

async function createShift(partner, auth, editedShift) {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        await axios.put(`${API_URL}/booking/schedule/${partner}`, editedShift, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const res = e.response;
        if (res.status > 300) {
            console.log(res.statusText);
            throw new Error(res.data);
        }
    }
}

async function deleteShift(partner, auth, shiftID, date) {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        await axios.delete(`${API_URL}/booking/schedule/${partner}?id=${shiftID}&date=${date}`, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const response = e.response;
        if (response.status > 300) {
            throw new Error(response.data);
        }
    }
}

// Cambia el estado enable de un turno
export const changeShiftEnable = (shift, date) => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;

        dispatch(toggleShiftLoading());

        // CONTROL TURNO CON SECTORES
        if (shift.sectors.length <= 0) {
            dispatch(saveShiftFailure("No se puede habilitar un sector sin sectores."));
            return;
        }

        const editedShift = {
            uuid: shift.uuid,
            enable: shift.enable,
        };

        if (editedShift.enable) {
            editedShift.startDate = shift.startDate ?? moment().startOf('date').utc().toISOString();
        } else {
            editedShift.startDate = moment(date).startOf('date').utc().toISOString();
        }

        try {
            ensurePartnerExists( idPartnerSelected );
            const auth = getFirebase().auth();
            await updateShift( idPartnerSelected , auth, editedShift);
            dispatch(saveShiftSuccess());
            dispatch(fetchShifts());
        } catch (e) {
            dispatch(saveShiftFailure(e.message));
        }
    }
}

// Edita un turno
export const submitReservasChanges = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {reservasReducer, firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {shift, mode, shifts} = reservasReducer;

        dispatch(toggleShiftLoading());
        const editedShift = deepCopyObject(shift);

        editedShift.opening = hhssToMinutes(editedShift.opening);
        // Se dejan en el subturno los campos necesarios
        editedShift.subShifts = editedShift.subShifts.map(subShift => ({
            uuid: subShift.uuid ?? uuidv4(),
            from: hhssToMinutes(subShift.from),
            to: openCloseTimeToOpenDuration(hhssToMinutes(subShift.from), hhssToMinutes(subShift.to)),
            subSubShifts: subShift.subSubShifts,
            type: false,
            allowsWait: subShift.allowsWait,
        }))

        // Se actualiza la fecha de finalizacion
        editedShift['startDate'] = moment(editedShift["startDate"]).startOf('date').utc().toISOString();
        editedShift['endDate'] = moment({year: 3000}).startOf('date').utc().toISOString();

        try {
            ensurePartnerExists( idPartnerSelected );
            const auth = getFirebase().auth();
            if (mode === ShiftMode.EDIT) {
                // Si el turno se encontraba deshabilitado y cuando se va a guardar se iba a guardar como deshabilitado
                // se debe habilitar automaticamente
                let originalShift = shifts.find(shift => shift.uuid === editedShift.uuid);
                if (!originalShift.enable && !editedShift.enable) {
                    editedShift.enable = true
                }
                await updateShift( idPartnerSelected , auth, editedShift);
            } else if (mode === ShiftMode.CREATE) {
                await createShift( idPartnerSelected , auth, editedShift);
            }
            dispatch(saveShiftSuccess());
            dispatch(changeShiftMode({mode: ShiftMode.VIEW, shift: undefined}));
            dispatch(fetchShifts());
        } catch (e) {
            console.log("Error al crear o actualizar partner: ", e)
            dispatch(saveShiftFailure(e.message));
        }

    }
}

// Edita la configuracion general
export const submitGeneralInfoReservasChanges = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {reservasReducer, firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        const {profile} = firebase;

        const {generalInfo, originalGeneralInfo} = reservasReducer;

        const {
            autoAcceptancePercentage,
            bookingNotification,
            bookingNotificationEnabled,
            shiftsTolerance,
            confirmationTolerance,
            confirmationToleranceEnable,
            bookingHourIntervals,
            toKnowBeforeBooking,
            maxWaitingItemsQuantity,
            waitListComment
        } = generalInfo;


        dispatch(toggleShiftLoading());

        let updateData = {};

        if (autoAcceptancePercentage !== originalGeneralInfo['autoAcceptancePercentage']) updateData['autoAcceptancePercentage'] = autoAcceptancePercentage;
        if (bookingNotification !== originalGeneralInfo['bookingNotification']) updateData['bookingNotification'] = Number(bookingNotification);
        if (!bookingNotificationEnabled) updateData['bookingNotification'] = null;

        if (shiftsTolerance !== originalGeneralInfo['shiftsTolerance']) updateData['shiftsTolerance'] = isNaN(Number(shiftsTolerance)) ? null : Number(shiftsTolerance);
        if (confirmationTolerance !== originalGeneralInfo['confirmationTolerance']) updateData['confirmationTolerance'] = confirmationToleranceEnable ? Number(confirmationTolerance) : null;
        if (toKnowBeforeBooking !== originalGeneralInfo['toKnowBeforeBooking']) updateData['toKnowBeforeBooking'] = toKnowBeforeBooking.trim();
        if (bookingHourIntervals !== originalGeneralInfo['bookingHourIntervals']) updateData['attentionIntervals'] = [...bookingHourIntervals];
        if (maxWaitingItemsQuantity !== originalGeneralInfo['maxWaitingItemsQuantity']) updateData['maxWaitingItemsQuantity'] = maxWaitingItemsQuantity;
        if (waitListComment !== originalGeneralInfo['waitListComment']) updateData['waitListComment'] = waitListComment.trim();


        try {
            await updateGeneralSettings( idPartnerSelected , getFirebase().auth(), updateData);
            dispatch(saveShiftSuccess());
            dispatch(fetchSettings())
        } catch (e) {
            dispatch(saveShiftFailure(e.message));
        }
    }
}

// Eliminar turno
export const submitReservasDeleted = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {reservasReducer, firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {shift} = reservasReducer;

        dispatch(toggleShiftLoading());

        try {
            ensurePartnerExists( idPartnerSelected );

            await deleteShift( idPartnerSelected , getFirebase().auth(), shift.uuid, moment(shift.endDate).startOf('date').utc().toISOString());
            dispatch(changeShiftMode({mode: ShiftMode.VIEW, shift: undefined}));
            dispatch(fetchShifts());
            dispatch(deleteShiftSuccess());
        } catch (e) {
            dispatch(deleteShiftFailure(e.message));
        }
    }
}

/**
 * @typedef SubShifts
 * @property {String} from Horario de inicio
 * @property {String} to Horario de finalizacion
 * @property {int} subSubShifts Cantidad de sub sub turnos
 */

/**
 * Verifica si los subturnos se superponen o incluyen entre si
 * @param subShifts Subturnos del turno actual a configurar
 * @returns {number[] | undefined}
 */
export const checkSubShiftsOverlaping = (subShifts) => {
    const subShiftOverlaps = [];
    for (let i = 0; i < subShifts.length - 1; i++) {
        for (let j = i + 1; j < subShifts.length; j++) {
            let openPrev = moment().startOf('date').add(hhssToMinutes(subShifts[i].from), 'minutes');
            let closePrev = moment().startOf('date').add(hhssToMinutes(subShifts[i].to), 'minutes');
            let openCurrent = moment().startOf('date').add(hhssToMinutes(subShifts[j].from), 'minutes');
            let closeCurrent = moment().startOf('date').add(hhssToMinutes(subShifts[j].to), 'minutes');
            if (openPrev.isBetween(openCurrent, closeCurrent, "[)") ||
                closePrev.isBetween(openCurrent, closeCurrent, "(]") ||
                openCurrent.isBetween(openPrev, closePrev, "[)") ||
                closeCurrent.isBetween(openPrev, closePrev, "(]")
            ) {
                subShiftOverlaps.push(i);
                subShiftOverlaps.push(j);
            }
        }
    }
    return subShiftOverlaps.length > 0 ? subShiftOverlaps : undefined;
}

export const checkSubShiftsInRange = (opening, subShifts) => {
    const open = moment().startOf('date').add(hhssToMinutes(opening), 'minutes');
    const endDate = moment().startOf('date').add(minutesOfDay, 'minutes');
    return subShifts.every(subShift => {
        let openCurrent = moment().startOf('date').add(hhssToMinutes(subShift.from), 'minutes');
        return openCurrent.isBetween(open, endDate, "minutes", "[)");
    });
}
