import React from 'react';
import {Grid} from '@material-ui/core';
import GroupIcon from '../../SvgComponents/icons/GroupIcon';

const CantPersonas = (props) => {

    const {quantity} = props

    return (
        <div style={{width: '50px'}}>
            <Grid container>
                <Grid item xs={6} style={{paddingTop: '3px'}}>
                    <GroupIcon width={'20px'} height={'20px'}/>
                </Grid>
                <Grid item xs={6} style={{marginTop: '-1px', paddingLeft: '8px'}}>
                    <h3
                        style={{
                            margin: '0px',
                            fontFamily: 'Montserrat',
                            fontSize: '20px'
                        }}
                    >{quantity}</h3>
                </Grid>
            </Grid>
        </div>
    )
}

export default CantPersonas
