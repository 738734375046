import React, {useEffect} from 'react';
import {connect} from 'react-redux';

// Actions
import {changePrimaryTitleAppBar} from '../../actions/sidebarActions';
import {fetchAllSectors, fetchSettings, fetchShifts, reloadBookingsSuccess,} from '../../actions';

// Components
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import {ShiftMode} from "../../constants";
import ReservasView from "./Components/ReservasView";
import ReservasForm from "./Components/ReservasForm";


function Reservas(props) {

    // Variables
    const {
        idPartnerSelected,
        shiftMode,
        loading,
        reload,
    } = props;

    // Actions
    const {
        fetchSettings,
        fetchShifts,
        fetchAllSectors,
        reloadBookingsSuccess,
    } = props;


    useEffect(() => {
        changePrimaryTitleAppBar("Configuración de reservas");
        if (idPartnerSelected || reload) {
            fetchShifts();
            fetchSettings();
            fetchAllSectors();
            reloadBookingsSuccess();
        }
    }, [fetchShifts, fetchSettings, fetchAllSectors, reloadBookingsSuccess, idPartnerSelected, reload]);

    return (
        <section>
            {shiftMode === ShiftMode.VIEW
                ? <ReservasView/>
                : <ReservasForm/>
            }
            <ProgressIndicator open={loading}/>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        loading: state.reservasReducer.loading,
        shiftMode: state.reservasReducer.mode,
        reload: state.reservasReducer.reload,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSettings: () => dispatch(fetchSettings()),
        fetchShifts: () => dispatch(fetchShifts()),
        fetchAllSectors: () => dispatch(fetchAllSectors()),

        reloadBookingsSuccess: () => dispatch(reloadBookingsSuccess()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reservas);
