import {connect} from "react-redux";
import {useEffect} from "react";

// Actions
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";

// Components
import BookingClients from "../../components/BookingClients";

// Material Ui
import {Grid} from "@material-ui/core";

function BookingClientsPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Historial de reservas")
        props.changeShowSidebar(true);
    }, [props]);


    return (
        <Grid container style={{backgroundColor: 'white'}}>
            <Grid item xs={12}>
                <BookingClients/>
            </Grid>
        </Grid>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(BookingClientsPage);