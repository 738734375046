import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logsMiddleware from './reducers/logsMiddleware';

// Components
import App from './App';

// Constants
import { ENVIRONMENT, PRODUCTION } from './constants/config';

// History
import {createBrowserHistory} from 'history';

// Reducers
import rootReducer from './reducers';


// Services
import firebase, { firebaseConfig } from './services/firebase/setup';

// Styles
import './index.css';

const history = createBrowserHistory();
let store = undefined;
// En desarrollo se utiliza la herramienta 'logger' para mostrar en consola los cambios en redux. En produccion esta herramienta debe estar deshabilitada.
// if (ENVIRONMENT === PRODUCTION) {
//     store = createStore(
//         rootReducer(history),
//         compose(
//             applyMiddleware(routerMiddleware(history), thunk.withExtraArgument({ getFirestore, getFirebase })),
//             reduxFirestore(firebase)
//         )
//         );
// } else {
// }
const logger = createLogger();
store = createStore(
    rootReducer(history),
    compose(
        applyMiddleware(logger, routerMiddleware(history), thunk.withExtraArgument({ getFirestore, getFirebase })),
        // applyMiddleware(logsMiddleware, routerMiddleware(history), thunk.withExtraArgument({ getFirestore, getFirebase })),
        reduxFirestore(firebase)
    ),
);

const rrfProps = {
    firebase,
    config: firebaseConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
};

ReactDOM.render(
    <Provider store={ store }>
        <ReactReduxFirebaseProvider { ...rrfProps }>
            <App history={ history } />
        </ReactReduxFirebaseProvider>
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
