import { connect } from "react-redux";

// Components
import RedTrashIcon from "../../../SvgComponents/icons/TrashIcons/RedTrashIcon";

// Material Ui
import { Grid } from "@material-ui/core";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  changeLayoutMaxGroup,
  changeLayoutName,
  changeSectorMode,
} from "../../../../actions/V3";
import { SECTOR_MODE } from "../../../../constants";

const maxGroupOptions = Array.from(Array(50).keys()).map((x) => x + 1);

const LayoutConfigurationForm = (props) => {
  const { layout, sector } = props;
  const { changeLayoutName, changeSectorMode, changeLayoutMaxGroup } = props;

  return (
    <Grid
      container
      direction={"row"}
      spacing={2}
      style={{ padding: "0 0rem 0 1rem" }}
    >
      <Grid item xs={12} md={7}>
        <Typography fontWeight={500} variant="h6" fontSize={'1.5rem'}>
          Sector: <span style={{fontWeight: 600}}>{sector.name}</span> 
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          id="layout-name"
          label="Nombre"
          variant="outlined"
          value={layout.name}
          onChange={(e) =>
            e.target.value.includes(".")
              ? null
              : changeLayoutName(e.target.value)
          }
          error={layout.name === undefined || layout.name.trim() === ""}
          helperText={""}
          fullWidth
          InputProps={{
            style: { fontWeight: 600 },
          }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <InputLabel id="type" shrink={true}>
            Máximo Grupo
          </InputLabel>
          <Select
            id="sector-max-group"
            label="Máximo Grupo"
            name="sector-max-group"
            variant="outlined"
            fullWidth
            displayEmpty
            notched={true}
            value={layout.maxGroup}
            onChange={(e) => changeLayoutMaxGroup(e.target.value)}
            // error={layout.maxGroup === undefined || layout.maxGroup === ""}
            renderValue={(selected) => {
              if(selected === ""){
                return <em>Sin máximo</em>
              }
              return <Typography fontWeight={600}>{selected}</Typography>;
            }}
            size="small"
            MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                  },
                },
            }}
          >
            <MenuItem
              key={"Sin máximo"}
              value={""}
              sx={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
                padding={"0.5rem 1rem"}
                sx={{
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <em>Sin máximo</em>
              </Box>
            </MenuItem>
            {maxGroupOptions.map((key, idx) => (
              <MenuItem
                key={key}
                value={key}
                sx={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {idx > 0 && (
                  <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  padding={"0.5rem 1rem"}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                >
                  {key}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={1}>
        <RedTrashIcon
          onClick={() =>
            changeSectorMode({ mode: SECTOR_MODE.DELETE, sector: layout })
          }
          style={{ cursor: "pointer" }}
        />
      </Grid> */}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.v3.sectorsLayoutsTables.layout,
    sector: state.v3.sectorsLayoutsTables.sector,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLayoutName: (name) => dispatch(changeLayoutName(name)),
    changeLayoutMaxGroup: (maxGroup) =>
      dispatch(changeLayoutMaxGroup(maxGroup)),
    changeSectorMode: ({ mode, sector }) =>
      dispatch(changeSectorMode({ mode, sector })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutConfigurationForm);
