import React, { useState } from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { setPlanningRangeHours } from '../../../../actions';

// Components
import AdvancedOptionsComponent from '../AdvancedOptionsComponent';
import PunctualDatesComponent from '../PunctualDatesComponent';
import NewRangeHourComponent from '../NewRangeHourComponent';
import PlanningModal from '../PlanningModal';
import AddNewButton from '../AddNewButton';

// Helpers
import { daysArray } from '../../helpers';

// Material Ui
import { Box, Collapse, Divider, Typography } from '@mui/material';

// Styles
import { DaysContainer, OptionalSubtitleStyles, TransitionGroupStyles } from '../../Styles';

const OpenningHoursForm = ( props ) => {

    // Actions
    const { setPlanningRangeHours } = props;

    // States
    const { openningHoursForm } = props;

    const [showModal, setShowModal] = useState( false );

    const handleAddNewHour = () => {
        setPlanningRangeHours({
            ...openningHoursForm,
            rangeHours: [
                ...openningHoursForm.rangeHours,
                {
                    id: `${ openningHoursForm.rangeHours.length }`,
                    from: 540,
                    to: 960
                }
                
            ]
        });
    };

    const handleDeleteRangeHour = ( id ) => {
        let rangeHoursCopy = [ ...openningHoursForm.rangeHours ];

        rangeHoursCopy = rangeHoursCopy.filter( item => item.id !== id );

        setPlanningRangeHours({
            ...openningHoursForm,
            rangeHours: [ ...rangeHoursCopy ]
        });
    };

    const handleSelectDay = ( id ) => {
        if ( !openningHoursForm.daysSelected.includes( id ) ) {
            setPlanningRangeHours({
                ...openningHoursForm,
                daysSelected: [
                    ...openningHoursForm.daysSelected,
                    id
                ]
            }); 
        } else {
            let indexDay = openningHoursForm.daysSelected.indexOf(id);

            let daySelectedCopy = [ ...openningHoursForm.daysSelected ];

            daySelectedCopy.splice( indexDay, 1 );

            setPlanningRangeHours({
                ...openningHoursForm,
                daysSelected: daySelectedCopy
            });
        }
    };

    const handleCloseModal = () => {
        setShowModal( false );
    };
    

    return (
        <Box sx={{ paddingBottom: '2rem' }}>
            <Divider/>
            <Box sx={{ mb: '1rem', width: '50rem' }}>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Rango horario</Typography>
                <TransitionGroup style={ TransitionGroupStyles }>
                    {
                        openningHoursForm.rangeHours?.map( item =>
                            <Collapse key={ item.id } orientation='vertical'>
                                <NewRangeHourComponent
                                    onDelete={ () => handleDeleteRangeHour( item.id ) } 
                                    arrayDate='opennings'
                                    from={ item.from }
                                    id={ item.id }
                                    to={ item.to } 
                                />    
                            </Collapse>
                        )
                    }
                </TransitionGroup>
                <Box sx={{ marginTop: '.7rem' }}>
                    <AddNewButton onClick={ handleAddNewHour } type='range'/>
                </Box>
            </Box>
            <Divider/>
            <Box>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Seleccionar días</Typography>
                <Box sx={ DaysContainer }>
                    {
                        daysArray.map(({ id, day }) =>
                            <Box
                                key={ `letra-${ id }` }
                                onClick={ () => handleSelectDay( id ) }
                                sx={{
                                    backgroundColor: openningHoursForm.daysSelected.includes( id ) ? '#0B4762' : '#E7E7E7',
                                    fontFamily: 'Poppins, sans-serif',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize:'17px',
                                    color: 'white',
                                    height: '37px',
                                    width: '37px',
                                    mr: '7px',
                                }}
                            >
                                { day }
                            </Box>
                    )}
                </Box>
                <AdvancedOptionsComponent />
            </Box>
            <Divider/>
            <Box>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Fechas puntuales</Typography>
                <PunctualDatesComponent setShowModal={ setShowModal } component ='openings'/>
            </Box>
            <PlanningModal
                description='Tené en cuenta que si programas este cierre para un dia o turno en el cual ya existen reservas, tendrás que ocuparte de ellas.'
                nextButtonText='Crear horario'
                onClick={ handleCloseModal }
                setShow={ setShowModal }
                backButtonText='Volver'
                show={ showModal }
                title='Atención'
                type='exclude'
                color='red'
            />
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        openningHoursForm: state.planningReducer.openningHoursForm,
        planningSnackbar: state.planningReducer.planningSnackbar,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setPlanningRangeHours: ( data ) => dispatch( setPlanningRangeHours( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( OpenningHoursForm );