import React from 'react'

const WokiTop = () => {
    return (
        <div style={{width: '55px'}}>
            <svg id="Grupo_1" data-name="Grupo 1" xmlns="http://www.w3.org/2000/svg" width="55" height="60.071" viewBox="0 0 80 75.071">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1.001" gradientUnits="objectBoundingBox">
                    <stop offset="0.2" stopColor="#23a9fb"/>
                    <stop offset="1" stopColor="#196bfa"/>
                    </linearGradient>
                </defs>
                <path id="Trazado_6" data-name="Trazado 6" d="M507.189,340.77c-.935-2.931-2.554-4.676-4.556-4.922h-.175c-.175-8.269-.809-17-5.031-19.141a13.225,13.225,0,0,0-10.938-.1l-.306.027a126.717,126.717,0,0,0-36.193.005l-.312-.033c-1.066-.476-6.054-2.4-10.938.1-4.216,2.149-4.851,10.878-5.031,19.141H433.5c-2,.246-3.62,2-4.561,4.922a22.833,22.833,0,0,0-.64,9.461h0c.826,6.738,3.724,11.736,7.049,12.578.459,3.571.979,6.869,1.515,9.3,1.854,8.52,7.071,18.3,31.211,18.3,12.677,0,27.678-2.078,31.211-18.3.547-2.456,1.05-5.753,1.515-9.3,3.325-.853,6.224-5.852,7.049-12.578h0A22.828,22.828,0,0,0,507.189,340.77Zm-76.564,9.177h0a20.411,20.411,0,0,1,.547-8.466c.591-1.848,1.5-3.046,2.456-3.281,0,.574-.027,1.094-.055,1.449-.2,2.8.448,11.955,1.444,20.437-1.848-1.345-3.806-5.163-4.419-10.139Zm66.392,21.7c-1.094,4.922-3.582,16.467-28.93,16.472s-27.858-11.545-28.93-16.472c-1.985-9.111-3.522-28.039-3.281-31.829.055-.738.071-1.772.1-3.079.093-5.256.29-16.188,3.773-17.965a9.352,9.352,0,0,1,4.293-.99,11.962,11.962,0,0,1,1.581.109c-5.513,3-5.42,10.817-5.627,13.043-.235,2.527-.476,32.168,4.572,40.469s23.456,7.569,23.516,7.569,18.452.7,23.516-7.569,4.807-37.921,4.572-40.469c-.208-2.226-.115-10.057-5.638-13.043a9.844,9.844,0,0,1,5.863.88c3.478,1.777,3.68,12.71,3.773,17.965,0,1.307.044,2.341.1,3.079.246,3.774-1.3,22.712-3.276,31.829Zm8.531-21.7c-.634,5.152-2.565,8.9-4.408,10.183,1-8.488,1.641-17.675,1.455-20.475,0-.377-.044-.875-.06-1.449.957.23,1.87,1.427,2.461,3.281a20.5,20.5,0,0,1,.525,8.46Z" transform="translate(-428.072 -315.339)" fill="url(#linear-gradient)"/>
                <path id="Trazado_8" data-name="Trazado 8" d="M566.038,368.892c-.634,5.152-2.565,8.9-4.408,10.183,1-8.488,1.641-17.675,1.455-20.475,0-.377-.044-.875-.06-1.449.957.23,1.87,1.427,2.461,3.281a20.5,20.5,0,0,1,.552,8.46Z" transform="translate(-488.589 -334.284)" fill="#fff"/>
                <path id="Trazado_10" data-name="Trazado 10" d="M436.928,379.032c-1.832-1.351-3.79-5.168-4.375-10.145a20.41,20.41,0,0,1,.547-8.466c.591-1.848,1.5-3.046,2.455-3.281,0,.574-.033,1.094-.055,1.449C435.282,361.395,435.927,370.55,436.928,379.032Z" transform="translate(-430.005 -334.28)" fill="#fff"/>
                <path id="Trazado_11" data-name="Trazado 11" d="M466.995,348.5c-.678-6.437.984-11.889,3.713-12.179s5.469,4.7,6.163,11.14-.984,11.895-3.713,12.179S467.673,354.941,466.995,348.5Zm37.265,0c-.689,6.442-3.424,11.43-6.158,11.156s-4.375-5.737-3.713-12.179,3.434-11.424,6.163-11.14S504.932,342.062,504.259,348.5Z" transform="translate(-445.64 -324.84)" fill="#fff"/>
                <path id="Trazado_12" data-name="Trazado 12" d="M489.862,404.705c-11.042,0-13.672-11.725-13.672-11.725h27.388S500.9,404.705,489.862,404.705Z" transform="translate(-449.875 -350.519)" fill="#fff"/>
            </svg>
        </div>
    )
}

export default WokiTop;
