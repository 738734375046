import React from 'react';

const shareLinkIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3657 12.6343C15.3759 11.645 14.0338 11.0892 12.6343 11.0892C11.2349 11.0892 9.89275 11.645 8.903 12.6343L5.17046 16.3657C4.18069 17.3555 3.62464 18.6979 3.62464 20.0976C3.62464 21.4974 4.18069 22.8398 5.17046 23.8296C6.16023 24.8193 7.50265 25.3754 8.90239 25.3754C10.3021 25.3754 11.6446 24.8193 12.6343 23.8296L14.5 21.9639" stroke="white" stroke-width="2.41667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.6343 16.3657C13.6241 17.355 14.9662 17.9108 16.3657 17.9108C17.7651 17.9108 19.1072 17.355 20.097 16.3657L23.8295 12.6343C24.8193 11.6446 25.3753 10.3021 25.3753 8.90239C25.3753 7.50264 24.8193 6.16022 23.8295 5.17045C22.8398 4.18068 21.4973 3.62463 20.0976 3.62463C18.6978 3.62463 17.3554 4.18068 16.3657 5.17045L14.5 7.03612" stroke="white" stroke-width="2.41667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default shareLinkIcon;