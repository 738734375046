import React from 'react'

const MesaYaTop = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="323" height="138" viewBox="0 0 423 138">
                <defs>
                    <filter id="Rectángulo_2507" x="80" y="25" width="343" height="88" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#12b6ff"/>
                        <stop offset="1" stopColor="#7c2bff"/>
                    </linearGradient>
                    <filter id="Elipse_276" x="0" y="0" width="138" height="138" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-2"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur-2"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Grupo_1416" data-name="Grupo 1416" transform="translate(-111 -114)">
                    <g transform="matrix(1, 0, 0, 1, 111, 114)" filter="url(#Rectángulo_2507)">
                        <rect id="Rectángulo_2507-2" data-name="Rectángulo 2507" width="325" height="70" rx="35"
                              transform="translate(89 31)" fill="#fff"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 111, 114)" filter="url(#Elipse_276)">
                        <circle id="Elipse_276-2" data-name="Elipse 276" cx="60" cy="60" r="60"
                                transform="translate(9 6)" fill="url(#linear-gradient)"/>
                    </g>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(131.997 132)">
                        <g id="Layer_1" data-name="Layer 1" transform="translate(0.004)">
                            <path id="Trazado_704" data-name="Trazado 704"
                                  d="M112.247,48.315l1.595-1.593a3.876,3.876,0,0,0,0-5.481l-2.166-2.166a3.877,3.877,0,0,0-5.479,0L104.6,40.67a36.283,36.283,0,0,0-16.409-6.8V30.853H77.38v3.019a36.355,36.355,0,0,0-19.826,9.675l7.3.023a32.056,32.056,0,1,1-6.74,47.011l-5.77-.014a36.66,36.66,0,1,0,59.9-42.251Z"
                                  transform="translate(-34.251 -20.186)" fill="#fff"/>
                            <path id="Trazado_705" data-name="Trazado 705"
                                  d="M23.955,109.479h0l3.071.008a27.7,27.7,0,1,0,7.008-41.055l-2.887-.006-4.26-.009L20.522,68.4l-5.773-.013a.718.718,0,0,0-.556.262l-2.323,2.827a.718.718,0,0,0,.553,1.174l4.987.009,5.6.012,4.675.009,1.035,0a27.759,27.759,0,0,0-5.423,7.93l-.506,0h0l-4.2-.011L13.7,80.591l-6.041-.014a.718.718,0,0,0-.585.3L5.041,83.7a.718.718,0,0,0,.581,1.137l6.962.015,4.707.011,4.324.011h.156a27.782,27.782,0,0,0-.933,7.158q0,.444.014.882l-1.55,0L16.495,92.9l-4.6-.011-9.14-.024a.718.718,0,0,0-.585.3L.139,96A.718.718,0,0,0,.72,97.136l11.521.026,4.654.011,2.307.006,2.115,0a27.528,27.528,0,0,0,2.849,8.028l-1.645,0L19.31,105.2l-5-.014-5.966-.017a.718.718,0,0,0-.605.329l-1.827,2.829a.718.718,0,0,0,.6,1.108l9.765.026,5.377.015Zm9.338-17.05a2.325,2.325,0,0,1,2.569-2.152l11.266.553.037-.495s.332-2.308-.665-2.659-1.927-.42-1.861-2.379,1.861-18.334,6.247-19.1V91.013l6.195.3s.666-2.95,2.716-2.95c1.76,0,10.75.659,13.253.846a.661.661,0,0,1,.646.625.672.672,0,0,1-.708.628H64.178a.759.759,0,1,0,0,1.507H72.7a.891.891,0,1,1,0,1.769H64.178a.76.76,0,1,0,0,1.509h8.809a.671.671,0,0,1,.708.627.66.66,0,0,1-.646.625c-2.5.187-11.493.847-13.253.847-2.05,0-2.716-2.952-2.716-2.952l-6.195.3v12.133a2.273,2.273,0,0,1-2.149,2.325l-.309.009h-.27a2.281,2.281,0,0,1-2.2-2.508L46.826,94.9l-10.965.538a2.325,2.325,0,0,1-2.569-2.152Z"
                                  transform="translate(-0.004 -42.09)" fill="#fff"/>
                            <path id="Trazado_706" data-name="Trazado 706"
                                  d="M112.42,6.923h14.811a3.461,3.461,0,0,0,3.461-3.461h0A3.461,3.461,0,0,0,127.231,0H112.42a3.461,3.461,0,0,0-3.461,3.461h0a3.461,3.461,0,0,0,3.461,3.461Z"
                                  transform="translate(-71.29)" fill="#fff"/>
                        </g>
                    </g>
                    <text id="Mesa_Ya_" data-name="Mesa Ya!" transform="translate(255 198)" fill="#0076ff" fontSize="50"
                          fontFamily="Montserrat-SemiBoldItalic, Montserrat" fontWeight="600" fontStyle="italic"
                          letterSpacing="-0.03em">
                        <tspan x="0" y="0">Mesa Ya!</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

export default MesaYaTop
