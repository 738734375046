import { GetColLayouts, getSectors } from "../../../components/V3/Shifts/Wizard/utils/sectorsFunctions";
import { ShiftMode } from "../../../constants";
import {
  SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL,
  CHANGE_TURNO_MAINTAIN_BOOKINGS,
  SET_GROUPS_ON_BOOKABLE_SECTORS,
  SET_CLOSE_DELETE_TURNO_SUCCESS,
  SET_ROTATION_CONTROL_BY_GROUP,
  SET_WAITING_LIST_SECTORS_FULL,
  SET_ROTATION_CONTROL_BY_TABLE,
  SET_SHOW_EXIT_WITHOUT_SAVING,
  CHANGE_TURNO_APPLY_FROM_DATE,
  SET_ADVANCE_END_RESPECT_TO,
  CHANGE_EXTRA_EXCLUDE_DATA,
  SET_DELETE_TURNO_SUCCESS,
  CHANGE_SHIFTS_VIEW_MODE,
  SET_AUTOMATIC_WAIT_LIST,
  SET_DWELL_TIME_BY_TABLE,
  SET_DISABLED_ALL_LAYOUT,
  SET_WORK_WITH_WAIT_LIST,
  SET_NOT_BOOKABLE_GROUP,
  SET_RESERVABLE_SECTORS,
  SET_AVAILABLE_LAYOUTS,
  FETCH_SECTORS_SUCCESS,
  SET_MAX_BOOKING_GROUP,
  SET_MAX_WAITING_ITEMS,
  SET_MIN_BOOKING_GROUP,
  TOGGLE_TURNOS_LOADING,
  CHANGE_BOOKABLE_GROUP,
  FETCH_TURNOS_SUCCESS,
  SET_CALENDAR_CLOSING,
  SET_CALENDAR_OPENING,
  SET_DWELL_TIME_GROUP,
  SET_ROTATION_CONTROL,
  SET_VALIDATION_ERROR,
  SET_SHOW_EDIT_MODAL,
  SET_ADITIONAL_INFO,
  SET_WAIT_LIST_NOTE,
  SET_AUTO_ACEPTANCE,
  CLOSE_TURNOS_ERROR,
  CHANGE_SHIFT_NAME,
  CHANGE_TURNO_MODE,
  SET_ADVANCE_START,
  SET_CLOSE_SAVE_OK,
  SET_ACTIVE_GROUP,
  SHIFTS_VIEW_MODE,
  SET_EXCLUDE_DATA,
  SET_TURNOS_ERROR,
  SET_ADVANCE_END,
  SET_EXCLUDE_DAY,
  SET_SPECIAL_DAY,
  SET_EXTRA_DATA,
  SET_IS_SPECIAL,
  SET_ALL_GROUPS,
  SET_OPTIONALS,
  SET_START_DAY,
  SET_SUBSHIFT,
  RESET_GROUPS,
  RESET_TURNO,
  SET_ENABLED,
  SET_END_DAY,
  SET_SAVE_OK,
  SET_REPEAT,
  SET_GROUP,
  SET_DAYS,
  UPDATE_TABLES,
  SET_EXTRA_TABLE,
} from "../../../constants/shiftsConstants";
import { deepCopyObject } from "../../../utils/arrayHelpers";

const initialState = {
  applyFromDate: undefined,
  availableSectorsLayouts: [],
  error: "",
  loading: true,
  maintainBookings: true,
  mode: undefined,
  originalEditedShiftData: undefined,
  reservableSectors: [],
  groups: [],
  sectors: [],
  shiftArray: [],  
  shiftData: {
    id: undefined,
    uuid: undefined,
    name: '',
    advanceStart: 21600,
    advanceEnd: 0,
    enabled: true,
    advanceEndRespectTo: 0,
    minBookingGroup: 1,
    maxBookingGroup: 20,
    autoAcceptancePercentage: 100,
    isSpecial: false,
    calendar: {
      startDate: "",
      endDate: null,
      days: [0, 2, 3, 4, 5, 6],
      opening: 480,
      closing: 540,
      excludeDates: [],
      specialDates: [],
      repeat: ""
    },
    subShifts: [],
    availableLayouts: [],
    workWithWaitList: false,
    waitListNote: '',
    automaticWaitList: true,    
    maxWaitingItems: 15,
    allowWaitListWithAllSectorsFull: false,
    dwellTimeByTable: null,
    dwellTimeByGroup: {
      "-1": 120,
      "1": 120,
      "2": 120,
      "3": 120,
      "4": 120,
      "5": 120,
      "6": 120,
      "7": 120,
      "8": 120,
      "9": 120,
      "10": 120,
      "11": 120,
    },
    rotationControl: null,
    additionalInfo: '',
    optionals: [],
  },
  showSaveOk: false,
  showExitWithoutSaving: false,
  showDeleteOk: false,
  shoModalEdit: false,
  shoModalDeactivateWaiList: false,  
  validationError: '',
  viewMode: SHIFTS_VIEW_MODE.VIEW,
};

const shiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SHIFTS_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.viewMode,
        mode: action.payload.viewMode === SHIFTS_VIEW_MODE.NEW ? ShiftMode.CREATE : action.payload.viewMode === SHIFTS_VIEW_MODE.EDIT ? ShiftMode.EDIT : ShiftMode.VIEW,
      };

    case CHANGE_TURNO_MODE:
      return {
          ...state,
          mode: action.payload.mode,
          shiftData: action.payload.turno,
          validationError: '',
          shoModalEdit: false,
          originalEditedShiftData: action.payload.mode === ShiftMode.EDIT ? deepCopyObject(action.payload.turno) : undefined
      };
    
    case CHANGE_TURNO_APPLY_FROM_DATE:
        return {
            ...state,
            applyFromDate: action.payload,
        };
    
    case CHANGE_TURNO_MAINTAIN_BOOKINGS:
        return {
            ...state,
            maintainBookings: action.payload,
        };

    case SET_SHOW_EDIT_MODAL:
        return {
            ...state,
            shoModalEdit: action.payload,
        };

    case SET_DELETE_TURNO_SUCCESS:
      return {
        ...state,
        showDeleteOk: true,
      };

    case SET_CLOSE_DELETE_TURNO_SUCCESS:
      return {
        ...state,
        showDeleteOk: false,
      };

    case TOGGLE_TURNOS_LOADING:
      return {
          ...state,
          loading: true,
        };

    case FETCH_TURNOS_SUCCESS:
      return {
        ...state,
        shiftArray: action.payload,
        loading: false,
      };
    
    case FETCH_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.payload,
      };      

    case SET_TURNOS_ERROR:
      return {
          ...state,
          loading: false,
          error: action.payload,
      };
    
    case SET_VALIDATION_ERROR:
      return {
          ...state,
          loading: false,
          validationError: action.payload,
      };
    

    case CLOSE_TURNOS_ERROR:
      return {
          ...state,
          loading: false,
          error: "",
      };

    case RESET_TURNO:
      return {
        ...state,
        shiftData: initialState.shiftData,
        validationError: '',
      };
    
    case SET_SAVE_OK:
      return {
        ...state,
        showSaveOk: true,
      };

    case SET_CLOSE_SAVE_OK:
      return {
        ...state,
        showSaveOk: false,
      };

    case SET_ENABLED:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          enabled: action.payload
        },
      };      
    
    case CHANGE_SHIFT_NAME:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          name: action.payload
        },
      };

    case SET_ADVANCE_START:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          advanceStart: action.payload
        },
      };
      
    case SET_ADVANCE_END:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          advanceEnd: action.payload
        },
      };

    case SET_ADVANCE_END_RESPECT_TO:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          advanceEndRespectTo: action.payload
        },
      };

    case SET_MIN_BOOKING_GROUP:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          minBookingGroup: action.payload
        },
      };
    
    case SET_MAX_BOOKING_GROUP:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          maxBookingGroup: action.payload
        },
      };

    case SET_AUTO_ACEPTANCE:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          autoAcceptancePercentage: action.payload
        },
      };
      
    case SET_IS_SPECIAL:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          isSpecial: action.payload
        },
      };

    case SET_CALENDAR_OPENING:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            opening: action.payload
          },
        },
      }; 
      
    case SET_CALENDAR_CLOSING:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            closing: action.payload
          },
        },
      }; 
    
    case SET_DAYS:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            days: action.payload
          },
        },
      };

    case SET_START_DAY:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            startDate: action.payload
          },
        },
      };
    
    case SET_END_DAY:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            endDate: action.payload
          },
        },
      };

    case SET_SPECIAL_DAY:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            specialDates: action.payload
          },
        },
      };
    
    case SET_EXCLUDE_DAY:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            excludeDates: action.payload
          },
        },
      };

    case SET_REPEAT:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          calendar: {
            ...state.shiftData.calendar,
            repeat: action.payload
          },
        },
      };      
    
    case SET_SUBSHIFT:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: action.payload,
        },
      };

    case SET_AVAILABLE_LAYOUTS:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          availableLayouts: action.payload,
        },
      };  
      
    case SET_RESERVABLE_SECTORS:
      return {
        ...state,
        reservableSectors: action.payload,
      };  

    case SET_WORK_WITH_WAIT_LIST:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          workWithWaitList: action.payload,
        },
      };

    case SET_AUTOMATIC_WAIT_LIST:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          automaticWaitList: action.payload,
        },
      };

    case SET_WAIT_LIST_NOTE:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          waitListNote: action.payload,
        },
      };
  
    case SET_MAX_WAITING_ITEMS:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          maxWaitingItems: action.payload,
        },
      };

    case SET_WAITING_LIST_SECTORS_FULL:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          allowWaitListWithAllSectorsFull: action.payload,
        },
      };
      
    case SET_ADITIONAL_INFO:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          additionalInfo: action.payload,
        },
      };
    
    case SET_DWELL_TIME_GROUP:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          dwellTimeByGroup: action.payload,
        },
      };
  
    case SET_DWELL_TIME_BY_TABLE:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          dwellTimeByTable: action.payload,
        },
      };
    
    case SET_ROTATION_CONTROL:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          rotationControl: action.payload,
        },
      };

    case SET_OPTIONALS:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          optionals: action.payload,
        },
      };     
    
    case SET_ROTATION_CONTROL_BY_GROUP:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          rotationControl: {
            ...state.shiftData.rotationControl,
            rotationControlByGroup: action.payload
          },
        },
      };
      
    case SET_ROTATION_CONTROL_BY_TABLE:
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          rotationControl: {
            ...state.shiftData.rotationControl,
            rotationControlByTable: action.payload
          },
        },
      };

    case SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL:
      return {
        ...state,
        shoModalDeactivateWaiList: action.payload,
      };

    case SET_SHOW_EXIT_WITHOUT_SAVING:
      return {
        ...state,
        showExitWithoutSaving: action.payload,
      };

    case SET_ALL_GROUPS:
      let reservableSectorsCopy1 = [ ...state.reservableSectors ];

      const reservableIndex = reservableSectorsCopy1.findIndex( item => item.name === action.payload.layout );

      if ( reservableIndex === -1 ) {
        return state;
      };

      reservableSectorsCopy1[ reservableIndex ].groups.forEach( group => {
        group.enabled = action.payload.value
      });

      return {
        ...state,
        reservableSectors: [ ...reservableSectorsCopy1 ]
      };
    
    case SET_ACTIVE_GROUP:
      let reservableSectorsCopy2 = [ ...state.reservableSectors ];
      let subShiftsArrayCopy6 = [ ...state.shiftData.subShifts ];
      let groupsCopyArray = [ ...state.groups ];

      const reservable2Index = reservableSectorsCopy2.findIndex( item => item.name === action.payload.layout );

      if ( reservable2Index === -1 ) {
        return state;
      };

      for (let i = 0; i < reservableSectorsCopy2[ reservable2Index ].groups.length; i++) {
        if ( reservableSectorsCopy2[ reservable2Index ].groups[i].id === action.payload.amount ) {
          reservableSectorsCopy2[ reservable2Index ].groups[i].enabled = !reservableSectorsCopy2[ reservable2Index ].groups[ i ].enabled;
          reservableSectorsCopy2[ reservable2Index ].groups[0].enabled = false;
        };
      };

      const findAmountGroup = groupsCopyArray.find( item => item.groups[0].group === action.payload.amount && item.layout === action.payload.layout );

      let reservableIdsArray = [];

      reservableSectorsCopy2[ reservable2Index ].subShifts.forEach( subshift => {
        if ( reservableIdsArray.find( id => id === subshift.id ) === undefined ) {
          reservableIdsArray.push( subshift.id );
        };
      });

      console.log( findAmountGroup );
      
      let newGroup = {
        layout: action.payload.layout,
        sectorID: reservableSectorsCopy2[ reservable2Index ].id,
        groups:[{
          enabled: true,
          group: action.payload.amount,
          subShifts: []
        }]
      };

      reservableIdsArray.forEach( id => {
        if ( newGroup.groups[0].subShifts.find( subshift => subshift.subShitfId === id ) === undefined ) {
          newGroup.groups[0].subShifts.push({
            bookable: true,
            subShiftId: id
          });
        }
      });

      if ( findAmountGroup === undefined ) {
        groupsCopyArray.push( newGroup );
      } else {
        console.log( 'newGroup ---> ', newGroup, groupsCopyArray );
        groupsCopyArray = groupsCopyArray.filter( group => group.groups[0].group !== action.payload.amount );
      };

      console.log( groupsCopyArray );
      console.log( reservableSectorsCopy2 );

      subShiftsArrayCopy6.forEach( subshift => {
        reservableSectorsCopy2.forEach( sector => {
          subshift.bookableSectors.forEach( bookable => {
            if ( sector.id === bookable.id ) {
              sector.groups.forEach( group => {
                if ( bookable.excludedGroup.length > 0 ) {
                  bookable.excludedGroup.forEach( item => {
                    if ( item === group.id && !group.enabled ) {
                      bookable.excludedGroup = bookable.excludedGroup.filter( item => item !== group.id );
                    };
                  });
                };
              });
            };
          });
        });
      });

      return {
        ...state,
        reservableSectors: [ ...reservableSectorsCopy2 ],
        groups: [ ...groupsCopyArray ]
      };

    case SET_GROUP:
      let sectorArrayCopy1 = [ ...state.reservableSectors ];
      let subShiftsArray = action.payload.sectors;
      let newGroupsArrayCopy = [ ...state.groups ];
      let subShiftsEditArray = [];
      let newExcludedGroups = [];
      let subshiftIdsArray = [];
      let array = [];

      if ( state.mode === 'edit' ) {

        if ( subShiftsArray ) {

          subShiftsArray.forEach( subshift => {
            subshift.bookableSectors.forEach( bookableSector => {

              if ( bookableSector.excludedGroup.length > 0 ) {
                bookableSector.excludedGroup.forEach( group => {

                  if ( subShiftsEditArray.find( subshift => subshift.sectorId === bookableSector.id && subshift.group === group ) === undefined ) {
                    subShiftsEditArray.push({
                      sectorId: bookableSector.id,
                      subShifts: [],
                      group: group,
                    });
                  };

                  newExcludedGroups.push({
                    sectorID: bookableSector.id,
                    subShiftId: subshift.id,
                    group: group,
                  });

                });
              };
            });
          });

          newExcludedGroups.forEach( groups => {
            subShiftsEditArray.forEach( item => {
              if ( item.sectorId === groups.sectorID && item.group === groups.group ) {
                item.subShifts.push({
                  subShiftId: groups.subShiftId,
                  bookable: false 
                });
              };
            });
          });

          subShiftsArray.forEach( subshift => {
            subShiftsEditArray.forEach( item => {
              if ( item.subShifts.find( item => item.subShiftId === subshift.id ) === undefined ) {
                item.subShifts.push({
                  subShiftId: subshift.id,
                  bookable: true 
                });
              };
            });
          });

          subShiftsEditArray.forEach( subshift => {
            array.push({
              sectorID: subshift.sectorId,
              groups: [{
                enabled: true,
                group: subshift.group,
                subShifts: subshift.subShifts
              }]
            });
          });
  
          state.reservableSectors.forEach( sector => {
            array.forEach( item => {
              if ( item.sectorID === sector.id ) {
                item.layout = sector.name;
              };
            });
          });
  
          state.shiftData.subShifts.forEach( subshift => {
            array.forEach( item => {
              item.groups[0].subShifts.forEach( subshiftGroup => {
                if ( subshiftGroup.subShiftId !== subshift.id && item.groups[0].subShifts.find( sub => sub.subShiftId === subshift.id ) === undefined ) {
                  item.groups[0].subShifts.push({
                    subShiftId: subshift.id,
                    bookable: true
                  });
                };
              });
            });
          });

          state.shiftData.subShifts.forEach( subshift => {
            subshiftIdsArray.push({
              subshiftId: subshift.id,
              enabled: true,
            });
          });

          action.payload.reservableSectors.forEach( sector => {
            sector.subShifts.forEach( subshift => {
              if ( subshiftIdsArray.find( id => id.subshiftId === subshift.id ) === undefined ) {
                subshiftIdsArray.map( id => ({
                  ...subshiftIdsArray,
                  enabled: id.enabled 
                })); 
              };
            });
          });

          newGroupsArrayCopy.forEach( group => {
            group.groups[0].subShifts = subshiftIdsArray.map( Id => (
              { bookable: Id.enabled, subShiftId: Id.subshiftId }
            ))
          });

          newGroupsArrayCopy = newGroupsArrayCopy.filter(( group, index, arr ) => {
            const duplicateIndex = arr.findIndex(
              ( el ) => el.sectorID === group.sectorID && el.groups[0].group === group.groups[0].group
            );
        
            return duplicateIndex === index;
          });

        };

        if ( array.length > 0 ) {
          newGroupsArrayCopy = array;

          sectorArrayCopy1.forEach( sector => {
            array.forEach( item => {
              if ( item.sectorID === sector.id ) {
                sector.groups.forEach( group => {
                  if ( item.groups[0].group === group.id ) {
                    group.enabled = true;
                  };
                });
              };
            });
          });
        };

      } else if ( state.mode === 'create' ) {

        state.shiftData.subShifts.forEach( subshift => {
          subshiftIdsArray.push({ subShiftId: subshift.id, bookable: true });
        });

        newGroupsArrayCopy.forEach( group => {
          subshiftIdsArray.forEach( ids => {
            // console.log( ids );
            if ( group.groups[0].subShifts.find( subshift => subshift.subShiftId === ids.subShiftId ) === undefined ) {
              group.groups[0].subShifts.push({
                subShiftId: ids.subShiftId, 
                bookable: ids.bookable,
              });
            };
          });
        });
      };

      return {
        ...state,
        reservableSectors: [ ...sectorArrayCopy1 ],
        groups: [ ...newGroupsArrayCopy ],
      }
    
    case SET_DISABLED_ALL_LAYOUT:
      let reservableSectorsCopy4 = [ ...state.reservableSectors ];
      let groupArrayCopy = [ ...state.groups ];

      reservableSectorsCopy4.forEach( sector => {
        if ( sector.layout === action.payload.layoutType ) {
          sector.subShifts.forEach( subshift => {
            if ( subshift.id === action.payload.subShiftId ) {
              subshift.enabled = false;
            };
          });
        };
      });
      
      groupArrayCopy.forEach( group => {
        console.log( group );
        if ( group.layout === action.payload.layoutType ) {
          group.groups[0].subShifts.forEach( subshift => {
            if ( subshift.subShiftId === action.payload.subShiftId ) {
              subshift.bookable = false;
            }
          });
        };
      });

      return {
        ...state,
        // reservableSectors: [ ...reservableSectorsCopy4 ],
        groups: groupArrayCopy,
      }
    
    case SET_GROUPS_ON_BOOKABLE_SECTORS:
      let newShiftArrayCopy = [ ...state.shiftData.subShifts ];
      let sectorArray = [ ...state.reservableSectors ];

      for (let i = 0; i < newShiftArrayCopy.length; i++) {
        for (let j = 0; j < newShiftArrayCopy[i].bookableSectors.length; j++) {
          if ( newShiftArrayCopy[i].bookableSectors[j].id === action.payload.sectorId ) {

            const index = sectorArray.findIndex( item => item.id === newShiftArrayCopy[i].bookableSectors[j].id );

            if ( index === -1 ) {
              return state;
            };

            sectorArray[ index ].groups.forEach( item => {
              if ( sectorArray[ index ].groups.find( group => group !== item.id ) ) {
                if ( item.enabled && item.id !== 'Todos' ) {
                  newShiftArrayCopy[i].bookableSectors[j].excludedGroup?.push( item.id );
                }
              };
            });
          };
        };
      };

      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...newShiftArrayCopy ]
        },
      };

    case CHANGE_BOOKABLE_GROUP:
      let newShiftArrayCopy1 = [ ...state.shiftData.subShifts ];
      let sectorArrayCopy3 = [ ...state.reservableSectors ];
      let newGroupArrayCopy = [ ...state.groups ];

      sectorArrayCopy3.forEach( sector => {
        if ( sector.id === action.payload.sectorId ) {
          sector.groups.forEach( group => {
            if ( group.id === action.payload.group ) {
              group.bookable = !group.bookable;
            }
          });
          
          sector.subShifts.forEach( subshift => {
            if ( subshift.id === action.payload.subShiftId ) {
              sector.groups.forEach( group => {
                if ( group.id === action.payload.group ) {
                  subshift.excludedGroup.push( group );
                };
              });
            };
          });
        };
      });

      newGroupArrayCopy.forEach( group => {
        if ( group.sectorID === action.payload.sectorId && group.groups[0].group === action.payload.group ) {
          group.groups[0].subShifts.forEach( subshift => {
            if ( subshift.subShiftId === action.payload.subShiftId ) {
              subshift.bookable = !subshift.bookable
            }
          });
        };
      });

      newShiftArrayCopy1.forEach( subshift => {
        newGroupArrayCopy.forEach( group => {
          if ( group.sectorID === action.payload.sectorId ) {
            group.groups[0].subShifts.forEach( subshiftGroup => {
              if ( subshiftGroup.subShiftId === subshift.id ) {
                if ( !subshiftGroup.bookable ) {
                  subshift.bookableSectors.forEach( bookableSector => {
                    if ( bookableSector.id === action.payload.sectorId ) {
                      if ( bookableSector.excludedGroup.find( item => item === group.groups[0].group ) === undefined ) {
                        bookableSector.excludedGroup.push( group.groups[0].group );
                      };
                    };
                  });
                } else {
                  subshift.bookableSectors.forEach( bookableSector => {
                    if ( bookableSector.id === action.payload.sectorId ) {
                      bookableSector.excludedGroup = bookableSector.excludedGroup.filter( item => item !== group.groups[0].group );
                    }
                  });
                };
              };
            });
          };
        });
      });
      
      return {
        ...state,
        groups: [ ...newGroupArrayCopy ]
      }
      
    case SET_EXCLUDE_DATA:

      let subShiftCopyArray = [ ...state.shiftData.subShifts ];

      const subshiftIndex = subShiftCopyArray.findIndex( ( subshift ) => subshift.id === action.payload.subshiftId );

      if ( subshiftIndex === -1 ) {
        return state;
      };

      if ( action.payload.type === 'Mesa' ) {
        if ( !subShiftCopyArray[ subshiftIndex ].excludeTables.find( item => item === action.payload.tableId ) ) {
          subShiftCopyArray[ subshiftIndex ].excludeTables.push( action.payload.tableId );
        } else {
          let newExcludeTableArray = subShiftCopyArray[ subshiftIndex ].excludeTables.filter( item => item !== action.payload.tableId );

          if ( newExcludeTableArray.length > 0 ) {
            for (let i = 0; i < newExcludeTableArray.length; i++) {
              subShiftCopyArray[ subshiftIndex ].excludeTables.push( newExcludeTableArray[i] );
            };
          } 
        }

        return {
          ...state,
          shiftData: {
            ...state.shiftData,
            subShifts: [ ...subShiftCopyArray ]
          },
        };
      } else {

        if ( !subShiftCopyArray[ subshiftIndex ].excludeGroup.find( item => item === action.payload.groupId ) ) {
          subShiftCopyArray[ subshiftIndex ].excludeGroup.push( action.payload.groupId );
        } else {
          let newExcludeGroupArray = subShiftCopyArray[ subshiftIndex ].excludeGroup.filter( item => item !== action.payload.groupId );

          if ( newExcludeGroupArray.length > 0 ) {
            for (let i = 0; i < newExcludeGroupArray.length; i++) {
              subShiftCopyArray[ subshiftIndex ].excludeGroup.push( newExcludeGroupArray[i] );
            };
          } 
        };

        return {
          ...state,
          shiftData: {
            ...state.shiftData,
            subShifts: [ ...subShiftCopyArray ]
          },
        };
      };
    
    case SET_EXTRA_DATA:

    let subShiftCopyArray1 = [ ...state.shiftData.subShifts ];

    const subshiftIndex1 = subShiftCopyArray1.findIndex( ( subshift ) => subshift.id === action.payload.subshiftId );

    if ( subshiftIndex1 === -1 ) {
      return state;
    };

    if ( action.payload.type === 'Mesa' ) {

      let newExtraTableArray = [];

      if ( state.mode === 'edit' ) {
        
        newExtraTableArray = [...new Set( subShiftCopyArray1[ subshiftIndex1 ].extraTables )];

      } else {

        if ( subShiftCopyArray1[ subshiftIndex1 ].extraTables.find( item => item === action.payload.tableId ) === undefined ) {
          subShiftCopyArray1[ subshiftIndex1 ].extraTables.push( action.payload.tableId );
        } else {
          newExtraTableArray = subShiftCopyArray1[ subshiftIndex1 ].extraTables.filter( item => item !== action.payload.tableId );
  
          if ( newExtraTableArray.length > 0 ) {
            for (let i = 0; i < newExtraTableArray.length; i++) {
              subShiftCopyArray1[ subshiftIndex1 ].extraTables.push( newExtraTableArray[i] );
            };
          } 
        };

      };

      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...subShiftCopyArray1 ] 
        },
      };
    } else {

      let bookableArrayCopy = subShiftCopyArray1[ subshiftIndex1 ].bookableSectors;
      const bookableIndex = bookableArrayCopy.findIndex( item => item.id === action.payload.bookableId );

      if ( bookableIndex === -1 ) {
        return state;
      };

      if ( !bookableArrayCopy[ bookableIndex ].extraGroup.find( item => item === action.payload.groupId ) ) {
        bookableArrayCopy[ bookableIndex ].extraGroup.push( action.payload.groupId );
      } else {
        let newBookableExtraArray = bookableArrayCopy[ bookableIndex ].extraGroup.filter( item => item !== action.payload.groupId );

        if ( newBookableExtraArray.length > 0 ) {
          for (let i = 0; i < newBookableExtraArray.length; i++) {
            bookableArrayCopy[ bookableIndex ].extraGroup.push( newBookableExtraArray[i] );
          };
        }; 
      };

      if ( !subShiftCopyArray1[ subshiftIndex1 ].extraGroup.find( item => item === action.payload.groupId ) ) {
        subShiftCopyArray1[ subshiftIndex1 ].extraGroup.push( action.payload.groupId );
      } else {
        let newExtraGroupArray = subShiftCopyArray1[ subshiftIndex1 ].extraGroup.filter( item => item !== action.payload.groupId );

        if ( newExtraGroupArray.length > 0 ) {
          for (let i = 0; i < newExtraGroupArray.length; i++) {
            subShiftCopyArray1[ subshiftIndex1 ].extraGroup.push( newExtraGroupArray[i] );
          };
        }; 
      };

      subShiftCopyArray1[ subshiftIndex1 ].bookableSectors = bookableArrayCopy;

      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...subShiftCopyArray1 ]
        },
      };
    };
    
    case CHANGE_EXTRA_EXCLUDE_DATA:
      let subShiftArrayCopy = [ ...state.shiftData.subShifts ];
      let layoutsArrayCopy = [ ...subShiftArrayCopy ];

      const subShiftCopyIndex = subShiftArrayCopy.findIndex( subshift => subshift.id === action.payload.subshiftId );

      if ( subShiftCopyIndex === -1 ) {
        return state;
      };

      if ( action.payload.type === 'Mesa' ) {
        if ( action.payload.enabled ) {

          subShiftArrayCopy[ subShiftCopyIndex ].extraTables = subShiftArrayCopy[ subShiftCopyIndex ].extraTables.filter(
            item => item !== action.payload.tableId
          );

        } else {

          subShiftArrayCopy[ subShiftCopyIndex ].excludeTables = subShiftArrayCopy[ subShiftCopyIndex ].excludeTables.filter(
            item => item !== action.payload.tableId
          );

          subShiftArrayCopy[ subShiftCopyIndex ].extraTables.push( action.payload.tableId );
        };

      } else {
        
        if ( action.payload.enabled ) {

          subShiftArrayCopy[ subShiftCopyIndex ].extraGroup = subShiftArrayCopy[ subShiftCopyIndex ].extraGroup.filter(
            item => item !== action.payload.groupId
          );

          subShiftArrayCopy[ subShiftCopyIndex ].excludeGroup.push( parseInt( action.payload.groupId ) );

          for (let i = 0; i < layoutsArrayCopy.length; i++) {
            if ( layoutsArrayCopy[i].id === action.payload.groupId ) {
              layoutsArrayCopy[i].available = false;
            }
          };

        } else {

          subShiftArrayCopy[ subShiftCopyIndex ].excludeGroup = subShiftArrayCopy[ subShiftCopyIndex ].excludeGroup.filter(
            item => item !== action.payload.groupId
          );

          subShiftArrayCopy[ subShiftCopyIndex ].extraGroup.push( parseInt( action.payload.groupId ) );
        };

        for (let i = 0; i < layoutsArrayCopy.length; i++) {
          if ( layoutsArrayCopy[i].id === action.payload.groupId ) {
            layoutsArrayCopy[i].available = true;
          }
        };  
      };
      
      getSectors( state.shiftData.availableLayouts, subShiftArrayCopy, state.sectors, layoutsArrayCopy );

      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...subShiftArrayCopy ]
      }
    };

    case SET_NOT_BOOKABLE_GROUP:
      let reservableSectorsCopy = GetColLayouts( 
        state.reservableSectors.filter((reservableSector) => reservableSector.available ), 
        state.shiftData.subShifts
      );

      const reservableSectorsIndex = reservableSectorsCopy.findIndex( subshift => subshift.id === action.payload.groupId );

      if ( reservableSectorsIndex === -1 ) {
        return state;
      };

      let subShiftCopy = reservableSectorsCopy[ reservableSectorsIndex ].subShifts;

      for (let i = 0; i < reservableSectorsCopy[ reservableSectorsIndex ].subShifts.length; i++) {
        if ( subShiftCopy[i].id === action.payload.subshiftId ) {
          subShiftCopy[i].enabled = !action.payload.available
        };
      };

      reservableSectorsCopy[ reservableSectorsIndex ].subShifts = subShiftCopy;

      return {
        ...state,
        reservableSectors: [ ...reservableSectorsCopy ]
      };

    case RESET_GROUPS:
      return {
        ...state,
        reservableSectors: [],
        groups: [],
      };

    case SET_EXTRA_TABLE:
      let subShiftsArrayCopy4 = [ ...state.shiftData.subShifts ];

      subShiftsArrayCopy4.forEach( sub => {
        if ( action.payload.tables.length > 0 ) {
          action.payload.tables.forEach( table => {
            if ( sub.extraTables.find( extra => extra === table ) === undefined ) {
              sub.extraTables.push( table );
            };
          });
        };
      });

      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...subShiftsArrayCopy4 ]
        }
      }

    case UPDATE_TABLES:

      let reservablesSectorsCopyArray2 = [ ...state.reservableSectors ];
      let subShiftsArrayCopy7 = [ ...state.shiftData.subShifts ];
      let reservablesSectorsCopyArray = action.payload.sectors;
      let subShiftsIdCopyArray = [];
      let excludeTablesArray = [];
      let extraTablesArray = [];
      let newTablesArray = [];
      let extrasIds = [];

      if ( action.payload.reset ) {
        if ( reservablesSectorsCopyArray2.find( sector => sector.type === 'Mesa' ) === undefined ) {
          subShiftsArrayCopy7.forEach( subshift => {
            subshift.excludeTables = [];
            subshift.extraTables = [];
          });
        };
      };

      subShiftsArrayCopy7.forEach( subshift => {
        if ( subshift.excludeTables.length === 0 && subshift.extraTables.length > 0 ) {
          subshift.extraTables = [];
        };
      });
      
      subShiftsArrayCopy7.forEach( subshift => {
        if ( subshift.extraTables.length > 0 ) {
          if ( extraTablesArray.find( extra => extra.subshiftId === subshift.id ) === undefined ) {
            extraTablesArray.push({ subshiftId: subshift.id, extraTable: subshift.extraTables });
          };
        };

        if ( subshift.excludeTables.length > 0 ) {
          if ( excludeTablesArray.find( item => item.subshiftId === subshift.id ) === undefined ) {
            excludeTablesArray.push({ subshiftId: subshift.id, excludeTable: subshift.excludeTables });
          };
        };
      });

      if ( extraTablesArray.length > 0 ) {
        extraTablesArray.forEach( extraTableItem => {
          extraTableItem.extraTable = [ ...new Set( extraTableItem.extraTable ) ];
        });
      };

      if ( excludeTablesArray.length > 0 ) {
        excludeTablesArray.forEach( excludeTableItem => {
          excludeTableItem.excludeTable = [ ...new Set( excludeTableItem.excludeTable ) ];
        });
      };

      let tablesExceptions = [];

      extraTablesArray.forEach( tables => {
        tables.extraTable.forEach( extras => {
          tablesExceptions.push({
            tableId: extras,
            subshift: {
              id: tables.subshiftId,
              enabled: true
            }
          });
        });
      });

      excludeTablesArray.forEach( tables => {
        tables.excludeTable.forEach( exclude => {
          tablesExceptions.push({
            tableId: exclude,
            subshift: {
              id: tables.subshiftId,
              enabled: false
            }
          });
        });
      });

      const tableSubArray = [];

      tablesExceptions.forEach( table => {
        const { tableId } = table;

        if ( !tableSubArray[ tableId ] ) {
          tableSubArray[ tableId ] = [];
        };

        if ( tableSubArray[ tableId ].find( item => item.id === table.subshift.id ) === undefined ) {
          tableSubArray[ tableId ].push( table.subshift );
        };
      });

      if ( excludeTablesArray.length > 0 ) {
        excludeTablesArray.forEach( tables => {
          tables.excludeTable.forEach( exclude => {
            if ( extrasIds.find( item => item === exclude ) === undefined ) {
              extrasIds.push( exclude );
            };
          });
        });
      };

      extrasIds.forEach( extraTable => {
        reservablesSectorsCopyArray.forEach( sector => {
          if ( sector.tables.find( table => table.id === extraTable ) !== undefined ) {
            sector.tables.forEach( item => {
              if ( item.id === extraTable ) {
                newTablesArray.push({
                  name: `Mesa ${ item.alias }`,
                  layout: sector.name,
                  available: true,
                  id: extraTable,
                  subShifts: [],
                  type: 'Mesa',
                  tables: [],
                });
              };
            });
          };
        });
      });

      if ( tableSubArray ) {
        newTablesArray.forEach( table => {
          if ( tableSubArray[ table.id ].length > 0 ) {
            subShiftsArrayCopy7.forEach( subshift => {
              if ( tableSubArray[ table.id ].find( sub => sub.id === subshift.id ) === undefined ) {
                tableSubArray[ table.id ].push({ id: subshift.id, enabled: true });
              };
            });
          };
        });
      };

      newTablesArray.forEach( table => {
        table.subShifts = subShiftsIdCopyArray;
      });

      if ( tableSubArray ) {
        newTablesArray.forEach( table => {
          if ( tableSubArray[ table.id ].length > 0 ) {
            table.subShifts = tableSubArray[ table.id ];
          };
        });
      };

      newTablesArray.forEach( table => {
        if ( table.subShifts.length !== subShiftsArrayCopy7.length ) {
          subShiftsArrayCopy7.forEach( subshift => {
            table.subShifts = table.subShifts.filter( sub => sub.id !== subshift.id );
          });
        };
      });

      const compareNames = ( a, b ) => {
        const numberA = parseInt( a.name.match(/\d+/)[0] ); 
        const numberB = parseInt( b.name.match(/\d+/)[0] );
        
        return numberA - numberB;
      };

      newTablesArray.sort( compareNames );

      if ( newTablesArray.length > 0 ) {
        newTablesArray.forEach( table => {
          if ( reservablesSectorsCopyArray.find( sector => sector.id === table.id ) === undefined ) {
            reservablesSectorsCopyArray.push( table );
          } else {

            reservablesSectorsCopyArray.forEach( sector => {
              let tableArray = [];

              if ( table.id === sector.id ) {
                if ( table.subShifts.length < sector.subShifts.length ) {
                  if ( table.subShifts.length === 0 ) {
                    if ( tableSubArray[ table.id ].length > 0 ) {
                      console.log( tableArray );
                      tableSubArray[ table.id ].forEach( tableItem => {
                        if ( tableArray.find( item => item.id === tableItem.id ) !== undefined ) {
                          tableArray.forEach( item => {
                            if ( item.id === tableItem.id ) {
                              item.enabled = tableItem.enabled;
                            };
                          });
                        } else {
                          tableArray.push({ id: tableItem.id, enabled: tableItem.enabled });
                        };
                      })
                    };

                  } else {
                    table.subShifts.forEach( sub => {
                      console.log( sector.subShifts, sub.id );
                      sector.subShifts.forEach( sec => {
                        if ( sec.id === sub.id ) {
                          tableArray.push({ id: sub.id, enabled: sub.enabled });
                        };
                      });
                    });
                  };

                  console.log( tableArray );
                  sector.subShifts = tableArray;
                  
                };
              } else {
                subShiftsArrayCopy7.forEach( subshift => {
                  tableArray.push({ id: subshift.id, enabled: true });
                });
              };
            });
          };
        });
      } else {
        reservablesSectorsCopyArray?.forEach( sector => {
          if ( sector.type === 'Mesa' ) {
            subShiftsArrayCopy7.forEach( subshift => {
              if ( sector.subShifts.find( sub => sub.id === subshift.id ) === undefined ) {
                sector.subShifts.push({ id: subshift.id, enabled: true });
              };

              if ( subshift.extraTables.find( extra => extra === sector.id ) === undefined ) {
                subshift.extraTables.push( sector.id );
              };

              sector.subShifts = sector.subShifts.filter( sub => sub.id === subshift.id );

            });
          };
        });
      };

      let sectorIds = [];

      if ( sectorIds.length > 0 ) {
        reservablesSectorsCopyArray.forEach( sector => {
          if ( sector.type === 'Mesa' && sectorIds.find( id => id === sector.id ) === undefined ) {
            sectorIds.push( sector.id );
          };
        });
      };

      subShiftsArrayCopy7.forEach( subshift => {
        let deleteValue;
        subshift.extraTables.forEach( extra => {
            if ( sectorIds.find( id => id === extra ) === undefined ) {
              deleteValue = extra;
            };
        });

        if ( deleteValue ) {
          subshift.extraTables = subshift.extraTables.filter( extra => extra !== deleteValue );
        };
      });

      return {
        ...state,
        reservableSectors: action.payload.reset ? [ ...reservablesSectorsCopyArray2 ] : reservablesSectorsCopyArray,
        shiftData: {
          ...state.shiftData,
          subShifts: [ ...subShiftsArrayCopy7 ]
        }
      }

    // case SET_SUBSHIFT_FROM:
    //   return state.shiftData.subShifts.map(subshift => {
    //     if (subshift.id === action.payload.id) {
    //       return {...subshift, from: action.payload.from}
    //     };
    //     return subshift;
    //   });

      // case ADD_BOOKING_HOUR_INTERVAL:
      //       generalInfo = {
      //           ...state.generalInfo,
      //           bookingHourIntervals: [...state.generalInfo.bookingHourIntervals, action.payload],
      //           bookingHourIntervalIndex: state.generalInfo.bookingHourIntervals.length,
      //       };
      //       return {
      //           ...state,
      //           generalInfo: generalInfo,

      //           edited: !deepEqual(state.originalGeneralInfo, generalInfo),
      //       }

    default:
      return state;
  }
};

export default shiftReducer;
