import React from 'react';

const PlusElipseIcon = (props) => {
    const {enabled, onClick} = props;

    return (
        <div 
            onClick={ enabled ? onClick : null } 
            style={{ 
                cursor: enabled ? 'pointer' : 'default', 
                alignItems: 'center', 
                display: 'flex', 
            }}
        >
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">        
                <circle cx="24" cy="21" r="17.5" stroke={enabled ? "#0B4762" : "#D1D1D1"}/>
                <path transform='translate(-118,0)' d="M149 21.998H144V26.998C144 27.2633 143.895 27.5176 143.707 27.7052C143.52 27.8927 143.265 27.998 143 27.998C142.735 27.998 142.48 27.8927 142.293 27.7052C142.105 27.5176 142 27.2633 142 26.998V21.998H137C136.735 21.998 136.48 21.8927 136.293 21.7052C136.105 21.5176 136 21.2633 136 20.998C136 20.7328 136.105 20.4785 136.293 20.2909C136.48 20.1034 136.735 19.998 137 19.998H142V14.998C142 14.7328 142.105 14.4785 142.293 14.2909C142.48 14.1034 142.735 13.998 143 13.998C143.265 13.998 143.52 14.1034 143.707 14.2909C143.895 14.4785 144 14.7328 144 14.998V19.998H149C149.265 19.998 149.52 20.1034 149.707 20.2909C149.895 20.4785 150 20.7328 150 20.998C150 21.2633 149.895 21.5176 149.707 21.7052C149.52 21.8927 149.265 21.998 149 21.998Z" 
                    fill={enabled ? "#0B4762" : "#D1D1D1"}/>
            </svg>
        </div>            
    );
}

export default PlusElipseIcon;