import {CLOSE_ERROR_DIALOG, RESET_ALL_DATA} from "../constants";

export * from "./authActions";
export * from "./generalInfoActions";
export * from "./photosActions";
export * from "./mesayaActions";
export * from "./reservasActions";
export * from "./messagesActions";
export * from "./menuActions";
export * from "./sectorsAndTablesActions";
export * from "./financesActions";
export * from "./userAccountsActions"
export * from "./filtersActions";
export * from "./planningActions";
export * from "./homeActions";
export * from "./clientsStatisticsActions";
export * from "./bookingHistoryActions";
export * from "./statisticsResumeActions";

export const closeErrorDialog = () => ({
    type: CLOSE_ERROR_DIALOG,
});

export const resetAllData = () => ({
    type: RESET_ALL_DATA,
});
