import { Box, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PopupModal } from "react-calendly";

const useStyles = makeStyles(() => ({
  MainContainer: {
    fontFamily: "Poppins, sans-serif",
    paddingRight: "5rem",
  },
  Title: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#041C32",
    margin: "0",
    paddingBottom: "0.5rem",
  },
  InvoiceData: {
    margin: "0 0 10px 0",
    fontSize: "16px",
    fontWeight: "400",
    color: "#808080",
  },
  InvoiceCardCTA: {
    position: "absolute",
    bottom: "0",
    right: "0",
    borderRadius: "0 0 10px 10px",
    backgroundColor: "#0B4762",
    padding: "5px",
    height: "20%",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  InvoiceCardCTAText: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "15px",
    fontWeight: "500",
    color: "#FFFFFF",
    margin: "0",
  },
  TitlePlan: {
    fontSize: "25px",
    fontWeight: "600",
    color: "#0B4762",
    margin: "0",
  },
  helperText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#808080",
    margin: "0",
  },
}));

export const PlanCard = ({ plan }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Grid item xs={12} lg={3}>
      <Box
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #DBD7DC",
          borderRadius: "10px",
          padding: "0px 10px 0px 10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          height: "15em",
          position: "relative",
        }}
      >
        <Box padding={"20px"}>
          <h3 className={classes.Title}>Tu suscripción</h3>
          <Box display={"flex"} flexDirection="column" gap={1}>
            <p className={classes.TitlePlan}>Plan {plan?.name}</p>
            <Box display={"flex"} alignItems="center" gap={1}>
              <InfoOutlinedIcon sx={{ color: "#808080", fontSize: "18px" }} />
              <p className={classes.helperText}>
                Para cambiar de plan hablar con soporte
              </p>
            </Box>
          </Box>
        </Box>
        <div className={classes.InvoiceCardCTA} onClick={() => setOpen(true)}>
          <p className={classes.InvoiceCardCTAText}>
            Agendar reunión con experto
          </p>
        </div>
      </Box>
      <PopupModal
        url="https://calendly.com/capacitaciones-woki/30min"
        onModalClose={() => {
          setOpen(false);
        }}
        open={open}
        rootElement={document.getElementById("root")}
      />
    </Grid>
  );
};
