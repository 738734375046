import React from 'react';

// Components
import SimpleTrashIcon from '../../SvgComponents/icons/TrashIcons/SimpleTrashIcon';
import Input from '../../GeneralComponents/Input General/SimpleInput';
import EditIcon from '../../SvgComponents/icons/EditIcon/EditIcon';
import SaveIcon from '../../SvgComponents/icons/SaveIcon/SaveIcon';

// import AdministratorSwitchWText from './Switchs/AdministratorSwitchWText';
// import ReservationsSwitchWText from './Switchs/ReservationsSwitchWText';
// import MesaYaSwitchWText from './Switchs/MesaYaSwitchWText';
// import { BlueSwitch } from '../../GeneralComponents/blueSwitch';
// import Delete from "../../SvgComponents/delete";

// Material Ui
import { Box, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@material-ui/core';

// Styles
import { ButtonsSection, ChecksStyles, RadioTextStyles } from './Styles';

const UsersComponent = ( props ) => {

    const {
        userRole,
        editing,
        isAdmin,
        userId,
        email,
        name,
        id
    } = props;

    const {
        onStartEditing,
        onChangeMyRole,
        onEditingDone,
        onChangeEmail,
        onChangeName,
        onDelete,
    } = props;

    const media = useMediaQuery('(min-width: 1500px)');

    return (
        <div style={{ margin: '20px 0px' }}>
            <Grid
                container
                style={{
                    border: editing ? '1.37949px solid #0B4762' : '1.37949px solid #DBD7DC',
                    fontFamily: 'Poppins, sans-serif',
                    backgroundColor: '#FFFFFF',
                    position: 'relative',
                    alignItems: 'center',
                    borderRadius: '8px',
                    display: 'flex',
                    padding: '1rem',
                    height: '75px',
                    width: '100%',
                    zIndex: '3'
                }}
            >
                <Grid item xs={ media ? 3 : 2 }>
                    <Box sx={{  }}>
                        <Input 
                            onChange={ onChangeName }
                            disabled={ !editing } 
                            isAdmin={ isAdmin }
                            height={ "40px" } 
                            width={ "100%" } 
                            text={ name } 
                        />
                    </Box>
                </Grid>
                <Grid item xs={ media ? 4 : 3 }>
                    <Input 
                        onChange={ onChangeEmail }
                        disabled={ !editing } 
                        isAdmin={ isAdmin }
                        height={ "40px" } 
                        width={ "100%" } 
                        text={ email } 
                    />
                </Grid>
                <Grid item xs={ media ? 5 : 7 }>
                    <div 
                        style={{ 
                            justifyContent: isAdmin ? 'flex-end' : 'space-around',
                            alignItems: 'center', 
                            display: 'flex', 
                        }} 
                    >
                        <RadioGroup
                            onChange={ (e) => onChangeMyRole({ partnerId: id, role: e.target.value, userId: userId }) }
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={ userRole }
                            row
                        >
                            <FormControlLabel
                                labelPlacement={'start'}
                                label={
                                    <Typography sx={ RadioTextStyles }>
                                        Visión del recepciónista
                                    </Typography>
                                }
                                control={
                                    <Radio
                                        disabled={ !editing }
                                        sx={ ChecksStyles }
                                        value={'mesa-ya'}
                                        color='default'
                                    />
                                }
                            />

                            <FormControlLabel
                                labelPlacement={'start'}
                                label={
                                    <Typography sx={ RadioTextStyles }>
                                        Reservas
                                    </Typography>
                                }
                                control={
                                    <Radio
                                        disabled={ !editing }
                                        sx={ ChecksStyles }
                                        value={'reserva'}
                                        color="default"
                                    />
                                }
                            />

                            <FormControlLabel
                                labelPlacement={'start'}
                                label={
                                    <Typography sx={ RadioTextStyles }>
                                        Administrador
                                    </Typography>
                                }
                                control={
                                    <Radio
                                        disabled={ !editing }
                                        sx={ ChecksStyles }
                                        color='default'
                                        value={'admin'}
                                    />
                                }
                            />
                        </RadioGroup>
                        {/* <MesaYaSwitchWText
                            onChange={(value) => onChangeMyRole(value)}
                            checked={mesaYaCheck}
                            disabled={!editing}
                            isAdmin={isAdmin}
                            enable={enable}
                        />
                        <ReservationsSwitchWText
                            onChange={(value) => onChangeBookingRole(value)}
                            checked={bookingCheck}
                            disabled={!editing}
                            isAdmin={isAdmin}
                            enable={enable}
                        />
                        <div style={{marginLeft: '-2rem'}}>
                            <AdministratorSwitchWText
                                onChange={(value) => onChangeAdminRole(value)}
                                checked={adminCheck}
                                disabled={!editing}
                                isAdmin={isAdmin}
                                enable={enable}
                            />
                        </div> */}
                        {/* {isAdmin ?
                            <div onClick={onChangePassword}>
                                <p
                                    style={{
                                        marginRight: '-2rem',
                                        fontSize: '.8rem',
                                        cursor: 'pointer',
                                    }}
                                ><u>Cambiar contraseña</u></p>
                            </div>
                            : */}
                            {
                                !isAdmin ? 
                                    <div style={ ButtonsSection }>
                                        {/* <Button
                                            onClick={() => editing ? onEditingDone() : onStartEditing()}
                                            style={{
                                                textTransform: 'none',
                                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                                                borderRadius: '15px',
                                                padding: '0px 0px 2px 0px',
                                                color: editing ? '#15D615' : '#848282',
                                            }}
                                        >{editing ? "Listo!" : "Editar"}</Button> */}
                                        { editing ?
                                            <div
                                                onClick={() => onEditingDone()}
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    width: '90px',
                                                }}
                                            >
                                                <SaveIcon paddingTop={'.5rem'} height={'1.8rem'}/>
                                            </div>
                                            :
                                            <div style={{display: 'flex', width: '90px'}}>
                                                <div onClick={() => editing ? onEditingDone() : onStartEditing()}>
                                                    <EditIcon height={'1.5rem'} />
                                                </div>
                                                <div onClick={onDelete}>
                                                    <SimpleTrashIcon height={'1.5rem'} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                :
                                    null
                            }
                        {/* } */}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default UsersComponent;
