import {RESET_ALL_DATA} from "../../constants";
import moment from "moment";
import {
    CHANGE_STATISTICS_COUNTRY_SELECTED,
    CHANGE_STATISTICS_ROWS_PER_PAGE,
    MORE_INFO_FILTERS_SELECTED,
    EXPORT_STATISTICS_SUCCESS,
    FETCH_STATISTICS_SUCCESS,
    CHANGE_STATISTICS_ORDER,
    CHANGE_STATISTICS_QUERY,
    EXPORT_STATISTICS_ERROR,
    START_EXPORT_STATISTICS,
    START_FETCH_STATISTICS,
    CHANGE_STATISTICS_PAGE,
    FETCH_STATISTICS_ERROR,
    CHANGE_STATISTICS_DATE,
    CLOSE_ERROR,
} from "../../constants/clientsStatisticsConstants";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";

const initState = {
    statistics: [],
    period: {
        init: moment().subtract(1, 'day'),
        end: moment()
    },
    page: 0,
    rowsPerPage: 20,
    totalResults: 0,
    orderBy: 'name',
    orderType: 'asc',
    query: '',
    countrySelected: '',
    dateRangeSelected: [{
        startDate: startOfMonth( addMonths(new Date(), -1) ),
        endDate: endOfMonth( addMonths(new Date(), 1) ),
        key: 'selection',
    }],
    moreInfoFilters: [
        {
            label: 'Con tags',
            value: true,
        },
        {
            label: 'Con nota al cliente',
            value: true,
        },
        {
            label: 'Con relacion especial',
            value: true,
        },
        {
            label: 'Con restricción alimentaria',
            value: true,
        },
    ],
    loading: false,
    error: undefined,
}

export const clientsStatisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case START_FETCH_STATISTICS:
            return {...state, loading: true, error: undefined}
        case FETCH_STATISTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                statistics: [...action.payload.results],
                totalResults: action.payload.totalDocuments
            }
        case FETCH_STATISTICS_ERROR:
            return {...state, loading: false, error: action.payload, statistics: []}
        case CLOSE_ERROR:
            return {...state, error: undefined}
        case CHANGE_STATISTICS_PAGE:
            return {...state, page: action.payload}
        case CHANGE_STATISTICS_ROWS_PER_PAGE:
            return {...state, rowsPerPage: action.payload, page: 0}
        case CHANGE_STATISTICS_ORDER:
            return {...state, orderBy: action.payload.orderBy, orderType: action.payload.orderType}
        case CHANGE_STATISTICS_QUERY:
            return {...state, query: action.payload}
        case START_EXPORT_STATISTICS:
            return {...state, loading: true, error: undefined}
        case EXPORT_STATISTICS_SUCCESS:
            return {...state, loading: false, error: undefined}
        case EXPORT_STATISTICS_ERROR:
            return {...state, loading: false, error: action.payload}
        case RESET_ALL_DATA:
            return {...initState}
        case CHANGE_STATISTICS_COUNTRY_SELECTED: 
            return { ...state, loading: false, countrySelected: action.payload } 
        case CHANGE_STATISTICS_DATE: 
            return { ...state, loading: false, dateRangeSelected: action.payload }
        case MORE_INFO_FILTERS_SELECTED: 
            return { ...state, loading: false, moreInfoFilters: action.payload }    
        default:
            return {...state}
    }
}
