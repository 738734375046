import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';

//Arrows
import ArrowUpPrimary from '../SvgComponents/icons/IconsPrimary/ArrowUpPrimary';
import ArrowDownPrimary from '../SvgComponents/icons/IconsPrimary/ArrowDownPrimary';

const useStyles = makeStyles(() => ({
    priority: {
        width: '150px',
        height: '31px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '18px',
        backgroundColor: (props) => props.disabled ? '#F0F0F0' : 'white'
    },
    name: {
        fontSize: '10px',
        color: '#848282',
        margin: '0px 3px 2px 10px',
        fontFamily: 'Montserrat',
        textAlign: 'right',
    },
}));

const SettingsNumbers = (props) => {

    const classes = useStyles(props);

    const {value, disabled} = props;

    const {onChange} = props;

    return (
        <div style={{position: 'relative', zIndex: 1}}>
            <div className={classes.priority}>
                <Grid container>
                    <Grid item xs={6}>
                        <h1
                            style={{
                                marginTop: '3px',
                                marginLeft: '14px',
                                color: !disabled ? '#0076FF' : '#AFAFAF',
                                fontSize: '18px',
                            }}
                        >{value}</h1>
                    </Grid>
                    <Grid container direction={'column'} item xs={6}>
                        <Grid style={{paddingLeft: '47px', marginTop: '-1px'}}
                              onClick={() => disabled ? null : onChange(value + 1)}>
                            <ArrowUpPrimary width={"14px"} height={"7.6px"} stroke={disabled ? '#AFAFAF' : undefined}/>
                        </Grid>
                        <Grid onClick={() => disabled ? null : onChange(value - 1)}>
                            <ArrowDownPrimary width={"14px"} height={"7.6px"}
                                              stroke={disabled ? '#AFAFAF' : undefined}/>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{position: 'relative', zIndex: 2}}>
                    <p className={classes.name}>{props.name}</p>
                </div>
            </div>
        </div>
    )
}

export default SettingsNumbers
