import React, { useState, useEffect } from "react";

import { TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";

// Actions
import { setRotationControlByTable } from "../../../../../actions/shiftsActions";

// Components
import AdvancedOptionsCustomizeRotationByTableModal from "./AdvancedOptionsCustomizeRotationByTableModal";
import ShiftGroupComponent from "./General/ShiftGroupComponent";

// Icons
import { TableIcon } from "../../../../SvgComponents/icons";

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { GetAdvancedModalOptions, GetRotationControlByTable, GetTableArray, GetTablesOptions, SetRotationControlTable } from "../utils/advancedOptionsFunctions";
import { tableRotationOptionsDefaultValue } from "../utils/constants";

const AdvancedOptionsTableRotationConfig = (props) => {
    // Actions
    const { setRotationControlByTable } = props;

    // External props
    const { title, type } = props;

    // States
    const { reservableSectors, rotationControl } = props;

    const newReservableSectors = reservableSectors?.filter(
        (reservableSector) => reservableSector.available
    );

    let reservableSector = newReservableSectors?.length > 0 ? newReservableSectors[0] : null;

    const [rotationControlTable, setRotationControlTable] = useState([]);
    const [rotationArray, setRotationArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [lastName, setLastName] = useState(0);

    useEffect(() => {
        if ( type === "tables" ) {

            setRotationArray(
                GetTableArray( reservableSectors, rotationControl?.rotationControlByTable )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rotationControl?.rotationControlByTable)]);

    useEffect(() => {
        if ( rotationControl ) {
            const newArray = GetTablesOptions(
                reservableSector.tables, 
                rotationControl.rotationControlByTable, 
                tableRotationOptionsDefaultValue
            );
            
            setRotationControlTable(
                GetAdvancedModalOptions(
                    reservableSector.id, 
                    newArray, 
                    rotationControl.rotationControlByTable, 
                    tableRotationOptionsDefaultValue, 
                    'table'
                )
            );
        };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ JSON.stringify( rotationControl?.rotationControlByTable ) ]);

    const handleChangeRotationGroupActive = ( id ) => {
        console.log( id );

        console.log( 'rotationControlTable --> ', rotationControlTable );
        let newRotationControlTable = [ ...rotationControlTable ];
        
        console.log( 'newRotationControlTable --> ', newRotationControlTable );
        let rotationControlIndex = newRotationControlTable.findIndex( 
            rotation => rotation.id.toString() === id  
        );

        if ( rotationControlIndex === '-1' ) {
            return;
        };

        if ( rotationControlTable[0].active ) {
            for ( let i = 0; i < newRotationControlTable.length; i++ ) {
                newRotationControlTable[ i ].active = false;
            };
        } else {
            for (let j = 0; j < rotationControlTable.length; j++) {
                if ( rotationControlTable[j].name === id ) {
                    newRotationControlTable[j].active = false;
                };
            };
        };
        
        let newGroupArray = [];

        for (let i = 0; i < newRotationControlTable.length; i++) {
            if ( newRotationControlTable[i].active ) {
                newGroupArray.push({
                    value: newRotationControlTable[i].value,
                    id: i
                });
            };
        };

        GetRotationControlByTable(
            newRotationControlTable, 
            rotationControl?.rotationControlByTable, 
            setRotationControlByTable, 
            reservableSector.tables
        );

        console.log( 'newRotationControlGroup --> ', newRotationControlTable );
    };

    useEffect(() => {
        let newArray = [];

        if ( rotationControl ) {
            const tableArray = Object.values( GetTableArray(
                reservableSectors,
                rotationControl?.rotationControlByTable
            ));

            console.log( 'tableArray --> ', tableArray );

            for (let i = 0; i < tableArray.length; i++) {
                newArray.push({
                    value: tableArray[i].value,
                    id: tableArray[i].id,
                });
            };

            setRotationArray(newArray);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rotationControl ]);

    useEffect(() => {
        setLastName( rotationControlTable.length );
    }, [ rotationControlTable ]);

    return (
        <Box>
            {
                showModal && type === "tables" ? (
                        <AdvancedOptionsCustomizeRotationByTableModal
                            handleClose={() => setShowModal(false)}
                        />
                    ) 
                : 
                    null
            }
            <Stack
                sx={{ cursor: "pointer" }}
                alignItems="center"
                direction="row"
                spacing={ 1 }
            >
                <TableIcon />
                <Typography
                    sx={{ textDecoration: "underline" }}
                    onClick={() => setShowModal(true)}
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="15px"
                    color="#0B4762"
                    my={ 3 }
                >
                    { title }
                </Typography>
                <Box
                    borderRadius={ 50 }
                    bgcolor="#35C18F"
                    height="11.5px"
                    width="11.5px"
                ></Box>
            </Stack>
            <TransitionGroup style={{ display: "flex", flexDirection: "column" }}>
                <Collapse
                    sx={{ mb: rotationArray.length > 0 ? "1rem" : 0 }}
                    in={rotationArray.length > 0 ? true : false}
                >
                    {
                        rotationArray.length > 0
                            ? rotationArray.map((rotation, index) => (
                                    <ShiftGroupComponent
                                        handleChangeRotationActive={handleChangeRotationGroupActive}
                                        lastName={ lastName - 1 }
                                        rotation={ rotation }
                                        key={ index }
                                        type={ type }
                                        all={ false }
                                    />
                                ))
                            : 
                                null
                    }
                </Collapse>
            </TransitionGroup>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        dwellTimeByTable: state.v3.shifts.shiftData.dwellTimeByTable,
        rotationControl: state.v3.shifts.shiftData.rotationControl,
        reservableSectors: state.v3.shifts.reservableSectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRotationControlByTable: (data) => dispatch( setRotationControlByTable(data) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( AdvancedOptionsTableRotationConfig );
