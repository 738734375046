import React from 'react';

// Material Ui
import { ListItem, ListItemText, Typography } from '@mui/material';

const InnerItem = ({ title, onClick, color, backgroundColor }) => {
    return (
        <ListItem 
            sx={{
                borderLeft: '1.5px solid #E7E7E7',
                backgroundColor: backgroundColor,
                borderRadius: '0 .4rem .4rem 0',
                fontFamily: 'Poppins',
                margin: '0 0 0 auto',
                height: '2.7rem',
                width: '78%',
            }} 
            onClick={ onClick }
            key={ title } 
            button 
        >
            <ListItemText>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 500,
                        color: color,
                    }}
                >{ title }</Typography>
            </ListItemText>
        </ListItem>
    )
}

export default InnerItem;