import { useEffect, useState } from 'react';

import moment from 'moment';

// Icons
import TableFoodRestrictionIcon from '../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/TableFoodRestrictionIcon';
import BookingClientTagsIcon from '../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingClientTagsIcon';
import ClientCancellationIcon from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/ClientCancellationIcon';
import BookingCreateDateIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingCreateDateIcon';
import BookingClientNoteIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingClientNoteIcon';
import FoodRestrictionIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/FoodRestrictionIcon';
import ClientTotalBookings from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/ClientTotalBookings';
import BookingSpecialIcon from '../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingSpecialIcon';
import ClientNoShowsIcon from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/ClientNoShowsIcon';
import BookingHourIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingHourIcon';
import BookingDateIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingDateIcon';
import BookingTagsIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingTagsIcon';
import AliasClientIcon from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/AliasClientIcon';
import ClientEmailIcon from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/ClientEmailIcon';
import ClientNoteIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/ClientNoteIcon';
import SpecialIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/SpecialIcon';
import PhoneIcon from '../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/PhoneIcon';

// import ClientPunctuationIcon from '../../SvgComponents/Icons Tables/MyClients/SidebarIcons/ClientPunctuationIcon';

// Material Ui
import { Box, Typography } from '@mui/material';

// Styles
import { ClientTagTextStyles } from '../Styles/MyClientsStytles';

const handleShowClientTags = ( tags ) => {
    return (
        <Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
            {
                tags?.map( (tag, index) => 
                    <Typography key={ index } sx={ ClientTagTextStyles } >{ tag }</Typography>
                )
            }
        </Box>
    )
};

export const onSetSidebarClientData = ( userData ) => {
    return [
        {
            id: 0,
            BookingIcon: AliasClientIcon,
            name: 'Alias',
            data: userData.alias
        },
        {
            id: 1,
            BookingIcon: PhoneIcon,
            name: 'Teléfono',
            data: userData.phone,
            nationality: userData.nationality,
        },
        {
            id: 2,
            BookingIcon: ClientEmailIcon,
            name: 'Mail',
            data: userData.email
        },
        // {
        //     id: 3,
        //     BookingIcon: ClientPunctuationIcon,
        //     name: 'Puntuación',
        //     data: '5.5'
        // },
        {
            // Arreglo de bug en data
            id: 4,
            BookingIcon: ClientTotalBookings,
            name: 'Reservas totales',
            data: userData.bookings.length
        },
        {
            // Arreglo de bug en data
            id: 5,
            BookingIcon: BookingHourIcon,
            name: 'Última reserva',
            data: userData.lastVisit && moment( userData.lastVisit ).format( 'DD/MM/YYYY' )
        },
        {
            id: 6,
            BookingIcon: BookingDateIcon,
            name: 'Visitas',
            data: userData.incomes
        },
        {
            // Arreglo de bug en data
            id: 7,
            BookingIcon: ClientCancellationIcon,
            name: 'Cancelaciones',
            data: userData.cancellations
        },
        {
            id: 8,
            BookingIcon: ClientNoShowsIcon,
            name: 'No shows',
            data: userData.fouls
        },
        {
            id: 9,
            BookingIcon: BookingCreateDateIcon,
            name: 'Reservas futuras',
            data: userData.active
        },
        // {
        //     id: 10,
        //     BookingIcon: ClientReviewsIcon,
        //     name: 'Reseñas',
        //     data: '123'
        // },
        {
            id: 11,
            BookingIcon: BookingTagsIcon,
            name: 'Tags',
            data: handleShowClientTags( userData.tags )
        },
        {
            id: 12,
            BookingIcon: ClientNoteIcon,
            name: 'Nota cliente',
            data: userData.clientNote
        },
        {
            id: 13,
            BookingIcon: SpecialIcon,
            name: 'Relación especial',
            data: userData.specialRelationship
        },
        {
            id: 14,
            BookingIcon: FoodRestrictionIcon,
            name: 'Restricción alimentaria',
            data: userData.foodRestrictions
        },
    ]
};

export const ShowClientsTableIcons = ( tags, clientNote, foodRestrictions, specialRelationship ) => {

    const [FRLength, setFRLength] = useState( foodRestrictions.length );

    useEffect(() => {
        let cont = 0;
        for (let i = 0; i < foodRestrictions.length; i++) {
            // Arreglo de bug en trim
            if ( foodRestrictions[i]?.trim() === '' ) {
                cont++;
            };
        };

        setFRLength( FRLength - cont );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ foodRestrictions ]);

    return (
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', p: '.2rem 0' }}>
            { ( tags.length !== 0 ) && <BookingClientTagsIcon /> }
            { ( clientNote !== '' && clientNote !== null ) && <BookingClientNoteIcon /> }
            { ( FRLength > 0 ) && <TableFoodRestrictionIcon /> }
            {/* Arreglo de bug en trim */}
            { ( specialRelationship && specialRelationship.trim() !== '' ) && <BookingSpecialIcon /> }
        </Box>
    )
};

export const handleChangeMoreInfoFilters = ( infoArray, setMoreInfoFilters ) => {

    let newArray = [
        {
            label: 'Con tags',
            value: true,
        },
        {
            label: 'Con nota al cliente',
            value: true,
        },
        {
            label: 'Con relacion especial',
            value: true,
        },
        {
            label: 'Con restricción alimentaria',
            value: true,
        },
    ];

    for (let i = 0; i < newArray.length; i++) {
        const exist = infoArray.find( ( item ) => item === newArray[i].label );
        newArray[i].value = !!exist;
    }

    setMoreInfoFilters( newArray );
};