import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";

// Actions
import {
    addMenuItem,
    addPaymentMethod,
    changeRestoAboutUs,
    changeRestoAddress,
    changeRestoCity,
    changeRestoCountry,
    changeRestoDescription,
    changeRestoName,
    changeRestoPricelevel,
    changeRestoProvince,
    changeRestoZipcode,
    closeErrorDialog,
    fetchGeneralInfo,
    removeMenuItem,
    removePaymentMethod,
    resetAllData,
    submitGeneralInfoChanges,
} from "../../actions";

// Components
import TextAreaWithCondition from "../NewComponents/TextAreaWithCondition/TextAreaWithCondition";
import SaveChangesButton from '../NewComponents/Buttons/Save Changes/SaveChangesButton';
import InputWithCondition from "../NewComponents/InputWhitCondition/InputWithCondition";
import CardsGeneralInfo from "../NewComponents/CardsGeneralInfo/CardsGeneralInfo";
import ButtonWithIcon from "../NewComponents/ButtonWithIcon/ButtonWithIcon";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import FilterButton from "./Info General/FilterButton/FilterButton";
import UrlItem from "./Info General/UrlItem";

// Constants
// Icons
import ShareLinkIcon from "../SvgComponents/icons/shareLinkIcon";

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

// Utils
import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../constants/modalMode";
import SaveChangesModal from "../Modals/SaveChangesModal";


const useStyles = makeStyles(() => ({
    GeneralContainerInfo: {
        fontFamily: 'Poppins, sans-serif',
        paddingRight: '5rem',
        backgroundColor: '#FCFCFC',
        marginBottom: '100px',
        ['@media (min-width: 1700px)']: {
            paddingRight: '15rem',
        }
    },
    titleGeneralInfo: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.6rem',
        fontWeight: '600',
        color: '#041C32'
    },
    subtitleGeneralInfo: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32'
    },
    generalInfoBottom: {
        marginBottom: '2rem',
        marginTop: '.5rem',
    },
    urlSectionGeneralInfo: {
        marginBottom: '1.5rem',
        display: 'flex',
        gap: '15px',
    },
    butonUrlSectionGeneralInfo: {
        justifyContent: 'center',
        paddingLeft: '15px',
        display: 'flex',
        margin: 'auto'
    },
    GeneralContainerCards: {
        paddingLeft: '15px'
    },
    urlsSection: {
        border: '1px solid #DBD7DC',
        backgroundColor: 'white',
        marginBottom: '1rem',
        borderRadius: '8px',
    },
    urlItem: {
        padding: '.5rem 1rem 0rem 1rem',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        gap: '15px'
    }
}));

const countries = [
    {
        value: 'AR',
        label: 'Argentina',
    },
    {
        value: 'BR',
        label: 'Brasil',
    },
    {
        value: 'CL',
        label: 'Chile',
    },
    {
        value: 'CO',
        label: 'Colombia',
    },
    {
        value: 'BO',
        label: 'Bolivia',
    },
    {
        value: 'UY',
        label: 'Uruguay',
    },
    {
        value: 'PY',
        label: 'Paraguay',
    },
    {
        value: 'PE',
        label: 'Perú',
    },
    {
        value: 'EC',
        label: 'Ecuador',
    },
    {
        value: 'VE',
        label: 'Venezuela',
    },
    {
        value: 'MX',
        label: 'México',
    },
    {
        value: 'US',
        label: 'Estados Unidos',
    }
];


function GeneralInfoForm(props) {
        // Variables
        const {
            idPartnerSelected,
            restoDescription,
            paymentMethods,
            restoAboutUs,
            restoAddress,
            priceLevel,
            restoName,
            province,
            zipCode,
            loading,
            country,
            profile,
            edited,
            menus,
            error,
            city,
        } = props;
    
        // Actions
        const {
            changeRestoName,
            changeRestoAboutUs,
            changeRestoDescription,
            changeRestoAddress,
            changeRestoZipcode,
            changeRestoCity,
            changeRestoProvince,
            changeRestoCountry,
            changeRestoPricelevel,
            addMenuItem,
            removeMenuItem,
            addPaymentMethod,
            removePaymentMethod,
            fetchGeneralInfo,
            submitGeneralInfoChanges,
            closeErrorDialog,
            resetAllData
        } = props;
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const [nameUrl, setNameUrl] = useState('');

    const [countryOptions] = useState(countries.sort((a, b) => -b.label.localeCompare(a.label)).map((country) => country.label));
    const [countryValue, setCountryValue] = useState(countries.find(c => c.label === country || c.value === country)?.label ?? '');

    const [errorName, setErrorName] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const provincias = useMemo(() => [
        'Buenos Aires',
        'CABA',
        'Catamarca',
        'Chaco',
        'Chubut',
        'Córdoba',
        'Corrientes',
        'Entre Rios',
        'Formosa',
        'Jujuy',
        'La Pampa',
        'La Rioja',
        'Mendoza',
        'Misiones',
        'Neuquén',
        'Salta',
        'Río Negro',
        'San Juan',
        'San Luis',
        'Santa Fe',
        'Santa Cruz',
        'Santiago del Estero',
        'Tucumán',
        'Tierra del Fuego'
    ], [])

    useEffect(() => {
        if (idPartnerSelected) {
            console.log("Partner: ", profile);
            fetchGeneralInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPartnerSelected]);

    const submit = async () => {
        setErrorName(restoName.length <= 0)
        setErrorAddress(restoAddress.length <= 0)

        const thereIsError = restoName.length <= 0 || restoAddress.length <= 0;

        if (!thereIsError) {
            await submitGeneralInfoChanges();
            return true;
        }
        return false;
    };

    const getValueProcess = (value) => {
        switch (value) {
            case 1:
                return '$';
            case 2:
                return '$$'
            case 3:
                return '$$$'
            case 4:
                return '$$$$'
            case 5:
                return '$$$$$';
            default:
                return value;
        }
    }

    const setValueProcess = (value) => {
        switch (value) {
            case '$':
                return 1;
            case '$$':
                return 2;
            case '$$$':
                return 3;
            case '$$$$':
                return 4;
            case '$$$$$':
                return 5;
            default:
                return value;
        }
    }

    let handleSaveUrl = () => {
        if (url.trim().length > 0 && nameUrl.trim().length > 0) {
            addMenuItem({name: nameUrl.trim(), url: url.trim()});
            setUrl('');
            setNameUrl('');
        }
    }
    let handleClickPaymentMethods = (method) => {
        if (paymentMethods.includes(method)) {
            removePaymentMethod(method);
        } else {
            addPaymentMethod(method);
        }
    }

    const discardChanges = () => {
        resetAllData();
        return true;
    }

    const handleCountryChange = (event) => {
        const {value} = event.target;
        const country = countries.find((country) => country.label === value);
        changeRestoCountry(country.value);
    };

    useEffect(() => {
        // setCountryValue(countries.find(c => c.label === (country ?? 'AR') || c.value === (country ?? 'AR'))?.label ?? 'Argentina');
        setCountryValue(countries.find(c => c.label === country || c.value === country)?.label ?? '');
    }, [country]);

    return (
        <div className={classes.GeneralContainerInfo}>
            <SaveChangesModal
                when={edited}
                submit={submit}
                handleClose={discardChanges}
            />
            {edited ? <SaveChangesButton onClick={submit}/> : null}
            <h1 className={classes.titleGeneralInfo}>Datos del establecimiento</h1>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <InputWithCondition
                            text={'Nombre del establecimiento'}
                            helperText={'Máximo 50 caracteres'}
                            width={'90%'}
                            onChange={(e) => {
                                if (e.target.value.length <= 50) {
                                    changeRestoName(e.target.value)
                                    setErrorName(e.target.value.length <= 0)
                                }
                            }}
                            value={restoName}
                            characters={50}
                            error={errorName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputWithCondition
                            text={'Dirección del establecimiento'}
                            helperText={'Ej: Nombre de la calle 1234'}
                            width={'100%'}
                            onChange={(e) => {
                                changeRestoAddress(e.target.value)
                                setErrorAddress(e.target.value.length <= 0)
                            }}
                            value={restoAddress}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.generalInfoBottom}>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FilterButton
                                    text={'Nivel de precios'}
                                    color={'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'}
                                    fontColor={'rgb(128, 128, 128)'}
                                    options={['$', '$$', '$$$', '$$$$', '$$$$$']}
                                    value={getValueProcess(priceLevel)}
                                    onChange={(newValue) => newValue <= 0 || newValue > 5 ? null : changeRestoPricelevel(setValueProcess(newValue.target.value))}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <InputWithCondition
                                    text={'Ciudad'}
                                    helperText={''}
                                    width={'100%'}
                                    onChange={(e) => changeRestoCity(e.target.value)}
                                    value={city}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputWithCondition
                                    text={'Código postal'}
                                    helperText={''}
                                    width={'90%'}
                                    onChange={(e) => changeRestoZipcode(e.target.value.trim())}
                                    value={zipCode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FilterButton
                                    text={'Provincia'}
                                    color={'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'}
                                    fontColor={'rgb(128, 128, 128)'}
                                    options={provincias}
                                    onChange={(e) => changeRestoProvince(e.target.value)}
                                    value={province}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FilterButton
                                    text={'País'}
                                    color={'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'}
                                    fontColor={'rgb(128, 128, 128)'}
                                    options={countryOptions}
                                    onChange={handleCountryChange}
                                    value={countryValue}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <h2 className={classes.subtitleGeneralInfo}>Sobre nosotros</h2>
            <TextAreaWithCondition
                placeholder={'Por ejemplo: historia de la marca, sus principios, valores, origen, etc.'}
                helperText={'Máximo 1000 caracteres'}
                width={'100%'}
                text={''}
                onChange={(e) => e.target.value.length <= 1000 ? changeRestoAboutUs(e.target.value) : null}
                value={restoAboutUs}
            />
            <h2 className={classes.subtitleGeneralInfo}>Información del local</h2>
            <TextAreaWithCondition
                placeholder={'Por ejemplo: aclaraciones sobre las condiciones de los sectores (techado,calefaccionado, etc), aclaraciones sobre la asignación de mesas, horarios de cierre de la cocina (no del local porque eso está en horario de atención), aclarar si son turnos fijos, si existe un tiempo límite de permanencia, etc. '}
                helperText={'Máximo 1000 caracteres'}
                width={'100%'}
                text={''}
                onChange={(e) => e.target.value.length <= 1000 ? changeRestoDescription(e.target.value) : null}
                value={restoDescription}
            />
            <h2 className={classes.subtitleGeneralInfo}>Carta</h2>
            <div className={classes.urlSectionGeneralInfo}>
                <InputWithCondition
                    text={'Nombre'}
                    helperText={''}
                    width={'29%'}
                    onChange={(e) => setNameUrl(e.target.value)}
                    value={nameUrl}
                />
                <InputWithCondition
                    text={`URL de link ${menus.length}`}
                    helperText={''}
                    width={'59%'}
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                />
                <div className={classes.butonUrlSectionGeneralInfo}>
                    <ButtonWithIcon icon={<ShareLinkIcon/>} text={'Agregar link'} minWidth={'140px'}
                                    onClick={handleSaveUrl}/>
                </div>
            </div>
            <div className={classes.urlsSection} style={{paddingTop: menus.length >= 2 ? ".5rem" : "0"}}>
                {Object.keys(menus ?? []).map((key, index) =>
                    <div
                        className={classes.urlItem}
                        style={{
                            display: (key !== "" || menus[key] !== "") ? 'block' : 'none'
                        }}
                    >
                        {
                            <UrlItem index={index} urlName={key} url={menus[key]}
                                     handleDeleteUrlItem={() => removeMenuItem(key)}/>
                        }
                    </div>
                )}
            </div>
            <h2 className={classes.subtitleGeneralInfo}>Medios de pago</h2>
            <div className={classes.GeneralContainerCards}>
                <CardsGeneralInfo paymentMethods={paymentMethods} onClick={handleClickPaymentMethods}/>
            </div>
            <ErrorDialog open={error !== undefined && error !== ""} title={"Error al guardar los cambios"}
                         body={error ?? ""}
                         type={ModalMode.ERROR} onAccept={closeErrorDialog}/>
            <ProgressIndicator open={loading}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        restoName: state.generalInfoReducer.editReducer.restoName,
        restoAboutUs: state.generalInfoReducer.editReducer.restoAboutUs,
        restoDescription: state.generalInfoReducer.editReducer.restoDescription,
        restoAddress: state.generalInfoReducer.editReducer.restoAddress,
        zipCode: state.generalInfoReducer.editReducer.zipCode,
        city: state.generalInfoReducer.editReducer.city,
        province: state.generalInfoReducer.editReducer.province,
        country: state.generalInfoReducer.editReducer.country,
        priceLevel: state.generalInfoReducer.editReducer.priceLevel,
        menus: state.generalInfoReducer.editReducer.menus,
        paymentMethods: state.generalInfoReducer.editReducer.paymentMethods,

        loading: state.generalInfoReducer.editReducer.loading,
        edited: state.generalInfoReducer.editReducer.edited,
        error: state.generalInfoReducer.editReducer.error,

        actualState: state.generalInfoReducer.editReducer,
        originalInfo: state.generalInfoReducer.editReducer.originalInfo,
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        profile: state.firebase.profile,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRestoName: (data) => dispatch(changeRestoName(data)),
        changeRestoAboutUs: (data) => dispatch(changeRestoAboutUs(data)),
        changeRestoDescription: (data) => dispatch(changeRestoDescription(data)),
        changeRestoAddress: (data) => dispatch(changeRestoAddress(data)),
        changeRestoZipcode: (data) => dispatch(changeRestoZipcode(data)),
        changeRestoCity: (data) => dispatch(changeRestoCity(data)),
        changeRestoProvince: (data) => dispatch(changeRestoProvince(data)),
        changeRestoCountry: (data) => dispatch(changeRestoCountry(data)),
        changeRestoPricelevel: (data) => dispatch(changeRestoPricelevel(data)),

        addMenuItem: (data) => dispatch(addMenuItem(data)),
        removeMenuItem: (data) => dispatch(removeMenuItem(data)),
        addPaymentMethod: (data) => dispatch(addPaymentMethod(data)),
        removePaymentMethod: (data) => dispatch(removePaymentMethod(data)),

        fetchGeneralInfo: () => dispatch(fetchGeneralInfo()),
        closeErrorDialog: () => dispatch(closeErrorDialog()),
        submitGeneralInfoChanges: (data) =>
            dispatch(submitGeneralInfoChanges(data)),

        resetAllData: () => dispatch(resetAllData()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoForm);
