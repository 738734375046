import React from 'react'
import {Dialog} from "@material-ui/core";
import {CircularProgress} from '@mui/material';


const ProgressIndicator = (props) => {

    const {open} = props;

    return (
        <Dialog
            id={"loading-dialog"}
            open={open}
        >
            <CircularProgress
                style={{
                    margin: '20px',
                    color: 'white',
                    height: '55px',
                    width: '55px'
                }}
            />
        </Dialog>
    );
}

export default ProgressIndicator
