import React, { useState, useEffect } from "react";

// Components
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import SettingNumbers from "./SettingNumbers";
import Divider from "./Divider";

// Material Ui
import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

// Utils
import { ResetAllRotationControlGroup, SetRotationControlGroup } from "../utils/advancedOptionsFunctions";
import { modalStyles } from "../utils/styles/modalStyles";
import { GetHHMMWithText } from "../utils/functions";

const useStyles = makeStyles(() => ( modalStyles ));

const TableCustomizeRotationGroupItem = ({ rotationControl, rotationControlGroup, setRotationControlGroup }) => {
    
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const [controlValue, setControlValue] = useState(1);

    const handleChangeActive = ( id ) => {

        if (rotationControl.min === 'Todos') {
            ResetAllRotationControlGroup( rotationControlGroup, !active, controlValue, setRotationControlGroup );
        } else {
            SetRotationControlGroup( rotationControlGroup, id, !active, controlValue, setRotationControlGroup );
        }

        setActive( !active );
    };

    const handleChangeControlValue = (id, value) => {
        SetRotationControlGroup( rotationControlGroup, id, active, value, setRotationControlGroup );
        setControlValue(value);
    };

    useEffect(() => {
        setControlValue(rotationControl.value);
    }, [rotationControl]);

    useEffect(() => {
        const rotation = rotationControlGroup.find( rotation => rotation.id === rotationControl.id );
        setActive(rotation.active);
        setControlValue(rotation.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rotationControlGroup]);

    const GetActive = ( active, rotationControl, rotationControlGroup ) => {
        return (active && rotationControl.min !== 'Todos' && !rotationControlGroup[0].active) || (rotationControl.min === 'Todos' && rotationControlGroup[0].active);
    };

    const GetDisabled = ( rotationControl, rotationControlGroup ) => {
        return rotationControlGroup[0].active && rotationControl.min !== 'Todos';
    };

    const switchActive = GetActive( active, rotationControl, rotationControlGroup );
    const disabled = GetDisabled( rotationControl, rotationControlGroup );

    return (
        <>
            <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Divider mt={ 0 } sx={{ borderColor: "#D1D1D1", width: "50%" }} />
            </Stack>
            <Stack direction="row" mt={1} mb={1} justifyContent="center" alignItems={"center"}>
                <Box width="10%" display="flex" flexDirection="row" alignItems="center">
                    <Box marginBottom='8px'>
                        <BlueSwitch
                            onClick={() => handleChangeActive( rotationControl.id )}
                            checked={ switchActive }
                            disabled={ disabled }
                            color="default"
                        >
                        </BlueSwitch>
                    </Box>
                    <Typography className={classes.ItemStyle}>
                        { rotationControl.name }
                    </Typography>
                </Box>
                <Box sx={{ width: "25%", display: 'flex', justifyContent: 'center' }}>
                    <Typography className={classes.ItemStyle}>
                        { rotationControl.min }
                    </Typography>
                </Box>
                <Box width="25%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.ItemStyle}>
                        { GetHHMMWithText(rotationControl.max) }
                    </Typography>
                </Box>
                <Box width="40%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <SettingNumbers 
                        handleChangeControlValue={ handleChangeControlValue } 
                        value={ parseInt(controlValue) } 
                        id={ rotationControl.id } 
                        enabled={ switchActive } 
                    />
                </Box>
            </Stack>
            <Stack direction="row" justifyContent="center">
                <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }} mt={1}/>
            </Stack>
        </>
    )
}

export default TableCustomizeRotationGroupItem;