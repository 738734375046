import React from 'react';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';
// Icons
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

// Styles
import { DateButtonContainerStyles, DateButtonCrossStyles, DateButtonTextStyles } from '../../../../Plannig/Styles';

const AddDateDisplay = ({ selectedDate, formattedDate, onDelete  }) => {
  return (
      <Box sx={ DateButtonContainerStyles } >
        <Typography sx={ DateButtonTextStyles } >
            { formattedDate }
        </Typography>
        <IconButton sx={{ p: '.2rem' }} onClick={ onDelete } >
            <ClearRoundedIcon sx={ DateButtonCrossStyles } />
        </IconButton>
      </Box>
    // <Box
    //     sx={{
    //     border: '1px solid #ccc',
    //     borderRadius: '5px',
    //     padding: '10px',
    //     // marginLeft: '10px',
    //     // width: '120px',
    //     display: 'flex', 
    //     alignItems: 'center'
    //     }}
    //     className="date-display"
    // >
    //     <span>{formattedDate}</span>
    //     <button className="delete-button" onClick={onDelete} style={{ marginLeft: '5px', cursor: 'pointer' }}>
    //       &#10005; {/* Icono de cruz (X) */}
    //     </button>   
    // </Box>
  );
};

export default AddDateDisplay;
