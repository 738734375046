import React, { useEffect, useState } from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { 
    setEditingBookingPoliticHour, 
    setRemoveBookingPoliticsHour,
    setEnableBookingPoliticHour, 
    setNewBookingPoliticsHour,
    setEditDaysSelected, 
    setChangeHours,
} from '../../../actions/politicsActions';

// Components
import BookingPoliticsTimeSelector from './BookingPoliticsTimeSelector';
import AddNewButton from '../../Plannig/components/AddNewButton';

// Material Ui
import { Box, Collapse } from '@mui/material';

// Styles
import { TransitionGroupStyles } from '../../Plannig/Styles';

// Utils
import { MinutesTohhss, hhssToMinutes } from '../../../utils/formatters';
import PlanningSnackbar from '../../Plannig/components/PlanningSnackbar';

const AttentionHoursComponent = ( props ) => {

    // Actions
    const { 
        setRemoveBookingPoliticsHour, 
        setEditingBookingPoliticHour, 
        setEnableBookingPoliticHour, 
        setNewBookingPoliticsHour,
        setEditDaysSelected,
        setChangeHours
    } = props;

    // States
    const { bookingPoliticsHours } = props;

    const [hoursError, setHoursError] = useState( false );
    const [showSnackbar, setShowSnackbar] = useState( false );

    const getDays = ( days ) => {

        const res = [false, false, false, false, false, false, false];

        days.forEach( day => {
            res[day] = true;
        });

        return res;
    };

    const handleEnabledHour = ( id, enable ) => {
        setEnableBookingPoliticHour({
            id: id,
            enableHour: !enable
        });
    };

    const handleEditHour = ( id, editingValue ) => {
        setEditingBookingPoliticHour({
            id: id,
            editingHour: !editingValue
        });
    };

    const onEditingCompleted = ({ id }) => {
        if ( !hoursError ) {
            setEditingBookingPoliticHour({
                id: id,
                editingHour: false
            });
        }
    };

    const handleAddNewHour = () => {
        const data = {
            id: bookingPoliticsHours.length,
            start: 600,
            end: 1320,
            days: [0,1,2,3,4,5,6],
            enable: true,
            editing: false
        };

        setNewBookingPoliticsHour({
            newHours: data
        });
    };

    const handleChangeStartHour = ( value, id ) => {
        let hourArrayCopy = [ ...bookingPoliticsHours ];
        const hourArrayCopyIndex = hourArrayCopy.findIndex( item => item.id === id );

        if ( hourArrayCopyIndex === -1 ) {
            return;
        }

        hourArrayCopy[ hourArrayCopyIndex ].start = hhssToMinutes( value );
        setChangeHours({ newArray: [ ...hourArrayCopy ] });
    };

    const handleChangeEndHour = ( value, id ) => {
        let hourArrayCopy = [ ...bookingPoliticsHours ];
        const hourArrayCopyIndex = hourArrayCopy.findIndex( item => item.id === id );

        if ( hourArrayCopyIndex === -1 ) {
            return;
        }

        hourArrayCopy[ hourArrayCopyIndex ].end = hhssToMinutes( value );
        setChangeHours({ newArray: [ ...hourArrayCopy ] });
    };

    const handleEditDays = ( value, id ) => {
        for (let i = 0; i < bookingPoliticsHours.length; i++) {
            if ( bookingPoliticsHours[i].id === id ) {
                let daySelectedCopy = [ ...bookingPoliticsHours[i].days ];
                
                if ( !bookingPoliticsHours[i].days.includes( value ) ) {
                    daySelectedCopy.push( value );
                    
                    setEditDaysSelected({
                        id: id,
                        newDaysArray: daySelectedCopy  
                    }); 
                    
                } else {
                    let indexDay = bookingPoliticsHours[i].days.indexOf( value );

                    daySelectedCopy.splice( indexDay, 1 );
        
                    setEditDaysSelected({
                        id: id,
                        newDaysArray: daySelectedCopy,
                    });
                }

            }
            
        }
    }

    const handleRemoveHour = ( id ) => {
        if ( bookingPoliticsHours.length > 1 ) {
            setRemoveBookingPoliticsHour({
                id: id
            });
        } else {
            return;
        }
    };

    const hasDuplicates = ( array ) => {
        return new Set( array ).size !== array.length
    }

    useEffect(() => {

        let arrayWithDaysAndHours = [];

        for (let i = 0; i < bookingPoliticsHours.length; i++) {
            arrayWithDaysAndHours.push(JSON.stringify({
                days: bookingPoliticsHours[i].days.sort(function(a, b){return a - b}),
                start: bookingPoliticsHours[i].start,
                end: bookingPoliticsHours[i].end
            }));
        };

        if ( hasDuplicates( arrayWithDaysAndHours ) ) {
            setShowSnackbar( true );
            setHoursError( true );
        } else {
            setHoursError( false );
        };

    }, [ bookingPoliticsHours ]);
    
    const handleCloseSnackbar = () => {
        setShowSnackbar( false );
    }

    return (
        <Box>
            <TransitionGroup style={ TransitionGroupStyles }>
                {
                    bookingPoliticsHours.map( ({ id, start, end, days, enable, editing }) =>
                        <Collapse key={ id } orientation='vertical' >
                            <BookingPoliticsTimeSelector
                                onEditCompleted={ () => onEditingCompleted({ id: id }) }
                                onClickEdit={ () => handleEditHour( id, editing ) }
                                onEnable={ () => handleEnabledHour( id, enable ) }
                                enable={ enable !== undefined ? enable : true }
                                onChangeStartHour={ handleChangeStartHour }
                                onDelete={ () => handleRemoveHour( id ) }
                                onChangeEndHour={ handleChangeEndHour }
                                intervalName={`Horario ${ id + 1 }`}
                                initTime={ MinutesTohhss( start ) }
                                endTime={ MinutesTohhss( end ) }
                                onChangeDay={ handleEditDays }
                                editionEnable={ editing }
                                from={"opening-interval"}
                                days={ getDays( days ) }
                                errorHour={ hoursError }
                                editing={ editing }
                                id={ id }
                            />
                        </Collapse>
                    )
                }
            </TransitionGroup>
            <Box sx={{ marginTop: '.8rem' }}>
                <AddNewButton type='addDate' onClick={ handleAddNewHour } />
            </Box>
            <PlanningSnackbar 
                message='Hay superposición con un horario existente'
                handleClose={ handleCloseSnackbar } 
                open={ showSnackbar } 
                type='Error' 
            />
        </Box>
    )
};

const mapStateToProps = state => {
    return {
        bookingPoliticsHours: state.bookingPoliticsReducer.bookingPoliticsHours,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEditingBookingPoliticHour: ({ id, editingHour }) => dispatch( setEditingBookingPoliticHour({ id, editingHour }) ),
        setEnableBookingPoliticHour: ({ id, enableHour }) => dispatch( setEnableBookingPoliticHour({ id, enableHour }) ),
        setEditDaysSelected: ({ id, newDaysArray }) => dispatch( setEditDaysSelected({ id, newDaysArray }) ),
        setNewBookingPoliticsHour: ( newHours ) => dispatch( setNewBookingPoliticsHour( newHours ) ),
        setRemoveBookingPoliticsHour: ( id ) => dispatch( setRemoveBookingPoliticsHour( id ) ),
        setChangeHours: ( newArray ) => dispatch( setChangeHours( newArray ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( AttentionHoursComponent );