export const SET_OPTIONAL_ARRAY_SELECTED = 'setOptionalArraySelected';
export const OPTIONAL_REQUIRE_RESPONSE = 'optionalRequireResponse';
export const REMOVE_ONECLICK_OPTIONAL = 'removeOneClickOptional';
export const REMOVE_MULTIPLE_OPTIONAL = 'removeMultipleOptional';
export const OPTIONAL_SET_CLIENT_TEXT = 'optionalSetClientText';
export const EDIT_MULTIPLE_OPTIONAL = 'editMultipleOptional';
export const EDIT_ONECLICK_OPTIONAL = 'editOneClickOptional';
export const ADD_ONECLICK_OPTIONAL = 'addOneClickOptional';
export const ADD_MULTIPLE_OPTIONAL = 'addMultipleOptional';
export const RESTART_OPTIONAL_FORM = 'restartOptionalForm';
export const CHANGE_OPTIONAL_MODE = 'changeOptionalMode';
export const GET_OPTIONAL_SUCCESS = 'getOptionalSuccess';
export const SET_OPTIONAL_ID_EDIT = 'setOptionalIdEdit';
export const START_GET_OPTIONALS = 'startGetOptional';
export const OPTIONAL_VIEW_MODE = 'optionalViewMode';
export const OPTIONAL_SET_NAME = 'optionalSetName';
export const OPTIONAL_SET_TYPE = 'optionalSetType';
export const DELETE_OPTIONAL = 'deleteOptional';
export const SAVE_OPTIONAL = 'saveOptional';
export const EDIT_OPTIONAL = 'editOptional';

export const OPTIONAL_MODE = {
    DELETE: 'DELETE',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    NEW: 'NEW',
};