import React from 'react'

const Delete = (props) => {
    return (
        <div style={{cursor: 'pointer', width: props.width}}>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.width} viewBox="0 0 15 17.018">
                <path id="delete" d="M14.093,3.191H.968a.861.861,0,0,1-.659-.308,1.075,1.075,0,0,1-.278-.748A1.123,1.123,0,0,1,.3,1.38a.844.844,0,0,1,.666-.316H5.656A1.11,1.11,0,0,1,5.927.317.846.846,0,0,1,6.594,0H8.469a.846.846,0,0,1,.666.316,1.11,1.11,0,0,1,.271.748h4.687a.852.852,0,0,1,.659.316,1.1,1.1,0,0,1,.278.756,1.074,1.074,0,0,1-.278.748.863.863,0,0,1-.659.308Zm0,11.7a2.2,2.2,0,0,1-.549,1.5,1.7,1.7,0,0,1-1.326.623H2.843A1.707,1.707,0,0,1,1.517,16.4a2.194,2.194,0,0,1-.549-1.5V4.255H14.093V14.891ZM4.718,7.446A1.094,1.094,0,0,0,4.44,6.7a.859.859,0,0,0-.666-.316.837.837,0,0,0-.659.316,1.113,1.113,0,0,0-.271.748v6.382a1.1,1.1,0,0,0,.271.756.847.847,0,0,0,.659.308.869.869,0,0,0,.666-.308,1.084,1.084,0,0,0,.278-.756V7.446Zm3.75,0A1.094,1.094,0,0,0,8.19,6.7a.859.859,0,0,0-.666-.316.837.837,0,0,0-.659.316,1.113,1.113,0,0,0-.271.748v6.382a1.1,1.1,0,0,0,.271.756.847.847,0,0,0,.659.308.869.869,0,0,0,.666-.308,1.084,1.084,0,0,0,.278-.756V7.446Zm3.75,0A1.094,1.094,0,0,0,11.94,6.7a.859.859,0,0,0-.666-.316.837.837,0,0,0-.659.316,1.113,1.113,0,0,0-.271.748v6.382a1.1,1.1,0,0,0,.271.756.847.847,0,0,0,.659.308.869.869,0,0,0,.666-.308,1.084,1.084,0,0,0,.278-.756V7.446Z" transform="translate(-0.031 -0.001)" fill="#eb3030"/>
            </svg>
        </div>
    )
}

export default Delete;
