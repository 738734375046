import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { setGroup, setReservableSectors, setSubshift, setUpdateTables } from "../../../../../actions/shiftsActions";

// Material Ui
import { Box } from "@mui/material";

// Utils
import SectorSubShiftsTableComponent from "./SectorSubShiftsTableComponent";

const SectorsSubShiftsTable = ( props ) => {

  // Variables
  const {
    showGroupsArray, 
    colSubShifts,
    boxStyles, 
    children,
    textNoOk,
    textOk,
  } = props;

  // Actions
  const {
    setUpdateTables,
    handleClick,
    setGroup
  } = props; 

  // External props
  const { handleGroupClick } = props;
  
  // States
  const { 
    reservableSectors,
    subShifts,
    groups
  } = props;

  const [showSectors , setShowSectors ] = useState( false );
  const [groupsArray, setGroupsArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);

  useEffect(() => {
    if ( reservableSectors.length > 0 && !showSectors ) {
      setGroup({ reset: true, sectors: subShifts, reservableSectors: reservableSectors });
      setUpdateTables({ sectors: reservableSectors });
      setShowSectors( true );
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ reservableSectors, showSectors, subShifts, setGroup ]); 

  useEffect(() => {
    if ( reservableSectors.length > 0 ) {
      setGroup({ reset: true, sectors: subShifts, reservableSectors: reservableSectors });
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ subShifts, setGroup ]);

  useEffect(() => {

    if ( groups.length > 0 ) {
      groups.sort((a, b) => a.groups[0].group - b.groups[0].group);
    };

    if ( showGroupsArray ) {
      setGroupsArray( groups );
    };
  }, [showGroupsArray, groups]);

  useEffect(() => {

    let tablesArray = [];

    tablesArray = reservableSectors.filter( sector => sector.type === 'Mesa' );

    setTableArray( tablesArray );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ reservableSectors ]);
  
  useEffect(() => {

    const compareNames = ( a, b ) => {
      const numberA = parseInt( a.name.match(/\d+/)[0] ); 
      const numberB = parseInt( b.name.match(/\d+/)[0] );
      
      return numberA - numberB;
    };

    tableArray.sort( compareNames );
    
  }, [ tableArray ]);

  return (
    <Box 
      my={ 3 }
      sx={{ 
        ...boxStyles,
        border: '1px solid #E7E7E7',
        borderRadius: '8px',
        width: '100%',
        pl: '.5rem',
      }}
    >
      <Box 
        sx={{ 
          overflowX: 'auto', 
          width: '100%',
          '::-webkit-scrollbar': {
              display: 'block !important',
              height: '8px',
          },
          '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              width: '8px'
          },
          '::-webkit-scrollbar-thumb': {
              background: '#0B4762',
              borderRadius: '5px',
          }, 
        }}
      >
        <SectorSubShiftsTableComponent 
          reservableSectors={ reservableSectors } 
          handleGroupClick={ handleGroupClick }
          colSubShifts={ colSubShifts }  
          handleClick={ handleClick }
          tablesArray={ tableArray } 
          groups={ groupsArray }
          textNoOk={ textNoOk } 
          textOk={ textOk }
        />
      </Box>
      { children }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {            
      reservableSectors: state.v3.shifts.reservableSectors,
      subShifts: state.v3.shifts.shiftData.subShifts,
      groups: state.v3.shifts.groups,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGroup: ({ reset, sectors, reservableSectors }) => dispatch( setGroup({ reset, sectors, reservableSectors }) ),
    setUpdateTables: ({ sectors }) => dispatch( setUpdateTables({ sectors }) ), 
    setReservableSectors: ( data ) => dispatch( setReservableSectors(data) ),
    setSubshift: ( data ) => dispatch( setSubshift(data) ),
  }
}


export default connect( mapStateToProps, mapDispatchToProps )( SectorsSubShiftsTable );

