import { showOptionSelectedName } from "../../components/Optional/helpers";
import { 
    OPTIONAL_REQUIRE_RESPONSE, 
    OPTIONAL_SET_CLIENT_TEXT, 
    REMOVE_MULTIPLE_OPTIONAL, 
    REMOVE_ONECLICK_OPTIONAL, 
    EDIT_ONECLICK_OPTIONAL, 
    EDIT_MULTIPLE_OPTIONAL, 
    ADD_MULTIPLE_OPTIONAL, 
    ADD_ONECLICK_OPTIONAL, 
    RESTART_OPTIONAL_FORM,
    CHANGE_OPTIONAL_MODE,
    OPTIONAL_VIEW_MODE, 
    OPTIONAL_SET_NAME, 
    OPTIONAL_SET_TYPE,
    DELETE_OPTIONAL,
    SAVE_OPTIONAL,
    OPTIONAL_MODE,
    EDIT_OPTIONAL,
    GET_OPTIONAL_SUCCESS,
    START_GET_OPTIONALS,
    SET_OPTIONAL_ID_EDIT,
    SET_OPTIONAL_ARRAY_SELECTED,
} from "../../constants/optionalConstants";
import { deepCopyObject } from "../../utils/arrayHelpers";

const initialState = {
    requiredResponse: true,
    showSaveChanges: false,
    multipleOptionsArray: [
        {
            id: 0,
            text: 'Opción 1'
        },
        {
            id: 1,
            text: 'Opción 2'
        }
    ],
    optionSelected: '',
    oneClickArray: [
        {
            id: 0,
            text: 'Opción 1'
        },
        {
            id: 1,
            text: 'Opción 2'
        }
    ],
    arrayOptionalSelected: [],
    optionalIdEdit: '',
    optionalArray: [],
    viewMode: 'VIEW',
    optionalName: '',
    clientText: '',
    loading: false,
}

const optionalReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case OPTIONAL_VIEW_MODE:
            return { ...state, viewMode: action.payload }
        case OPTIONAL_REQUIRE_RESPONSE:
            return { ...state, showSaveChanges: true, requiredResponse: action.payload }
        case OPTIONAL_SET_NAME:
            return { ...state, showSaveChanges: true, optionalName: action.payload }
        case OPTIONAL_SET_TYPE:
            return { ...state, showSaveChanges: true, optionSelected: action.payload }
        case OPTIONAL_SET_CLIENT_TEXT:
            return { ...state, showSaveChanges: true, clientText: action.payload }
        case ADD_ONECLICK_OPTIONAL:
            return {
                ...state,
                showSaveChanges: true,
                oneClickArray: [
                    ...state.oneClickArray,
                    {
                        id: state.oneClickArray.length ?? 0,
                        text: `Opción ${ state.oneClickArray.length + 1 }`
                    }
                ]
            } 
        case EDIT_ONECLICK_OPTIONAL:
            let newOCArrayCopy = [ ...state.oneClickArray ];
            
            let OCArrayIndex = newOCArrayCopy.findIndex( (item) => item.id === action.payload.id );
            
            if ( OCArrayIndex === -1 ) {
                return {
                    ...state
                }
            }

            newOCArrayCopy[OCArrayIndex].text = action.payload.newText;

            return { 
                ...state,
                showSaveChanges: true, 
                oneClickArray: [ ...newOCArrayCopy ] 
            }
        case REMOVE_ONECLICK_OPTIONAL:
            let newOneClickArray = [];

            state.oneClickArray.forEach((item) => {
                if ( item.id !== action.payload.id ) {

                    item.id = newOneClickArray.length;
                    
                    if ( item.text.includes( 'Opción' ) ) {
                        item.text = `Opción ${ newOneClickArray.length + 1 }`
                    }

                    newOneClickArray.push( item );
                }
            });

            return { 
                ...state,
                showSaveChanges: true, 
                oneClickArray: [ ...newOneClickArray ] 
            }
        case ADD_MULTIPLE_OPTIONAL:
            return {
                ...state,
                showSaveChanges: true,
                multipleOptionsArray: [
                    ...state.multipleOptionsArray,
                    {
                        id: state.multipleOptionsArray.length ?? 0,
                        text: `Opción ${ state.multipleOptionsArray.length + 1 }`
                    }
                ]
            } 
        case EDIT_MULTIPLE_OPTIONAL: 
            let newMArrayCopy = [ ...state.multipleOptionsArray ];
                
            let MArrayIndex = newMArrayCopy.findIndex( (item) => item.id === action.payload.id );
            
            if ( MArrayIndex === -1 ) {
                return {
                    ...state
                }
            }

            newMArrayCopy[MArrayIndex].text = action.payload.newText;

            return { 
                ...state,
                showSaveChanges: true, 
                multipleOptionsArray: [ ...newMArrayCopy ] 
            }
        case REMOVE_MULTIPLE_OPTIONAL:
            let newMultipleArray = [];
            //push and refresh id, index and name
            state.multipleOptionsArray.forEach((item) => {
                if ( item.id !== action.payload.id ) {

                    item.id = newMultipleArray.length;
                    
                    if ( item.text.includes( 'Opción' ) ) {
                        item.text = `Opción ${ newMultipleArray.length + 1 }`
                    }

                    newMultipleArray.push( item );
                }
            });

            return { 
                ...state,
                showSaveChanges: true, 
                multipleOptionsArray: [ ...newMultipleArray ] 
            }
        case SAVE_OPTIONAL:
            return { 
                ...state,
                showSaveChanges: false, 
                optionalArray: [
                    ...state.optionalArray,
                    action.payload
                ] 
            }
        case RESTART_OPTIONAL_FORM:
            state.clientText = '';
            state.multipleOptionsArray = [
                {
                    id: 0,
                    text: 'Opción 1'
                },
                {
                    id: 1,
                    text: 'Opción 2'
                }
            ];
            state.oneClickArray = [
                {
                    id: 0,
                    text: 'Opción 1'
                },
                {
                    id: 1,
                    text: 'Opción 2'
                }
            ];
            state.requiredResponse= true;
            state.optionSelected = '';
            state.optionalName = '';
            return {
                ...state
            }
        case CHANGE_OPTIONAL_MODE:
            return {
                ...state,
                viewMode: action.payload.mode,
                optional: action.payload.optional,
                originalEditOptional: action.payload.mode === OPTIONAL_MODE.EDIT ? deepCopyObject( action.payload.optional ) : undefined
            };
        case DELETE_OPTIONAL:
            let optionalArrayCopy = [ ...state.optionalArray ];

            const indexO = optionalArrayCopy.findIndex( item => item.id === action.payload.id );
            optionalArrayCopy.splice( indexO, 1 );

            return{
                ...state,
                optionalArray: [...optionalArrayCopy]
            }
        case EDIT_OPTIONAL:
            let newOptionalArrayCopy = [ ...state.optionalArray ];
            let indexCopy = newOptionalArrayCopy.findIndex( item => item.id === action.payload.id );

            if ( indexCopy !== -1 ) {
                state.optionSelected = showOptionSelectedName(newOptionalArrayCopy[indexCopy].type);
                state.requiredResponse = newOptionalArrayCopy[indexCopy].required;
                state.clientText = newOptionalArrayCopy[indexCopy].question;
                state.optionalName = newOptionalArrayCopy[indexCopy].name;
                
                let arraySelected = [];

                newOptionalArrayCopy[indexCopy].values?.map( ( text, index ) => arraySelected.push({ id: index, text: text }) );

                if ( newOptionalArrayCopy[indexCopy].type === 'multiple_selector' ) {
                    state.multipleOptionsArray = arraySelected;
                } else if ( newOptionalArrayCopy[indexCopy].type === 'unique_selector' ) {
                    state.oneClickArray = arraySelected;
                } else {
                    state.multipleOptionsArray = [
                        {
                            id: 0,
                            text: 'Opción 1'
                        },
                        {
                            id: 1,
                            text: 'Opción 2'
                        }
                    ];
                    state.oneClickArray = [
                        {
                            id: 0,
                            text: 'Opción 1'
                        },
                        {
                            id: 1,
                            text: 'Opción 2'
                        }
                    ];
                }
            } else {
                return;
            }
            
            return {
                ...state
            }
        case START_GET_OPTIONALS:
            return { 
                ...state, 
                loading: true 
            }
        case GET_OPTIONAL_SUCCESS: 
            return {
                ...state,
                loading: false,
                optionalArray: [...action.payload]
            }
        case SET_OPTIONAL_ID_EDIT:
            return { ...state, optionalIdEdit: action.payload }
        case SET_OPTIONAL_ARRAY_SELECTED:
            let itemsArray = [];
            action.payload.map( item => itemsArray.push( item.text ) );

            return { 
                ...state, 
                arrayOptionalSelected: [...itemsArray] 
            } 
        default: 
            return state;
    }
}

export default optionalReducer;