export const MainContainer = {
    fontFamily: 'Poppins, sans-serif',
    paddingRight: '5rem',
    height: '85vh',
    width: '85%',
    '@media (min-width: 1500px)': {
        maxWidth: '51rem',
        width: '70%',
    }
};

export const OptionalTitleStyle = {
    fontFamily: 'Poppins',
    fontSize: '1.5rem',
    color: '#041C32',
    fontWeight: 500,
    mt: '2rem'
};

export const OptionalSubtitleStyle = {
    fontFamily: 'Poppins',
    fontSize: '1.3rem',
    color: '#041C32',
    fontWeight: 500,
};

export const OptionalDescriptionStyle = {
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#818181',
    mt: '.5rem'
};

export const OptionalItemsContainer = {
    padding: '1.5rem 1.5rem 1.5rem 0'
};

export const OutlinedInputStyles = {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Poppins', 
    borderRadius: '10px',
    fontSize: '.9rem', 
    color: '#041C32',
    fontWeight: 500,
    width: '100%',
    m: '1rem 0',
    '& .MuiInputBase-input': {
        p: '.7rem .875rem'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderWidth: '1px'
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } 
};

export const OutlinedTextareaStyles = {
    backgroundColor: '#FFFFFF',
    color: '#041C32',
    fontWeight: 500,
    width: '100%',
    m: '1rem 0',
    '& .MuiInputBase-root': {
        borderRadius: '10px',
        fontSize: '.9rem', 
        padding: 0
    },
    '& .MuiInputBase-input': {
        fontFamily: 'Poppins', 
        borderRadius: '10px !important',
        p: '.7rem .875rem'
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#0B4762',
            borderWidth: '1px'
        }
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } 
};

export const SwitchText = {
    fontFamily: 'Poppins',
    fontSize: '1.1rem',
    color: '#041C32',
    fontWeight: 500,
    mb: '-.3rem',
};

export const OptionalSelectStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px', 
    color: '#2A2742', 
    fontWeight: 500,
    width: '15rem',
    m: '1rem 0',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } ,
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const OptionGeneralContainerStyles = {
    m: '.5rem 0'
};

export const OptionsContainerStyles = {
    aligItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    gap: '10px',
};

export const AddNewOptionStyles = {
    textTransform: 'none',
    alignItems: 'center',
    width: 'max-content',
    cursor: 'pointer',
    display: 'flex',
    p: '.5rem',
    mt: '1rem',
    gap: '5px',
    '&:hover': {
        backgroundColor: 'transparent !important'
    }
}; 

export const TextButtonAddOptional = { 
    textDecoration: 'underline', 
    fontFamily: 'Poppins', 
    color: '#0B4762', 
    fontWeight: 500, 
};

export const SaveOptionalButtonStyles = {
    backgroundColor: '#0B4762',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '8px',
    p: '.4rem 1.2rem',
    color: 'white',
    '&:hover': {
        backgroundColor: '#0B4762 !important'
    },
    '&:disabled': {
        color: 'white !important',
    },
};

export const OptionalHomeContainerStyles = {
    border: '1px solid #D1D1D1',
    backgroundColor: 'white',
    borderRadius: '10px',
    aligItems: 'center',
    display: 'flex',
    height: '5rem',
    width: '30%',
    p: '0 1rem',
};

export const OptionalTextsStyles = {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'left',
    display: 'flex',
    width: '90%',
};

export const OptionalTitleStyles = {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    color: '#041C32',
    fontWeight: 500,
    '@media(min-width: 1400px)': {
        fontSize: '1.25rem',
    }
};

export const OptionalSubtitleStyles = {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontSize: '.7rem',
    color: '#818181',
    fontWeight: 400,
    '@media(min-width: 1400px)': {
        fontSize: '.9rem',
    }
};

export const OptionalButtonsContainerStyles = {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
};

export const OptionalsContainerStyles = {
    alignItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    gap: '10px',
};

export const DeleteOptionalModal = {
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    position: 'absolute',
    borderRadius: '12px',
    width: '30rem',
    boxShadow: 24,
    left: '50%',
    top: '50%',
    p: 4,
    '&:focus-visible': {
        outline: 'none'
    }
};

export const CrossDOModalStyles = {
    position: 'absolute',
    cursor: 'pointer',
    right: '1rem',
    top: '1rem',
};

export const DOIconModalStyles = {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
}

export const DOModalTextContainerStyles = {
    p: '.5rem 1rem 1.5rem 1rem'
}

export const DOModalTitle = {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '1.4rem',
    color: '#041C32',
    fontWeight: 600,
};

export const DOModalSubtitle = {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '.9rem',
    color: '#818181',
    fontWeight: 400,
    mt: '1rem'
};

export const DOModalButtonsContainerStyles = {
    display: 'flex',
    gap: '10px',
};

export const DOButtonBackModalStyles = {
    backgroundColor: 'transparent',
    border: '1px solid #D1D1D1',
    textTransform: 'none',
    borderRadius: '.5rem',
    fontFamily: 'Poppins',
    fontSize: '.9rem',
    color: '#041C32',
    fontWeight: 600,
    width: '100%',
    p: '.3rem 0',
    '&:hover': {
        backgroundColor: 'transparent !important',
    }
};

export const DOButtonDeleteModalStyles = { 
    backgroundColor: '#FB3F4A',  
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    fontSize: '.9rem',
    fontWeight: 600,
    color: 'white',
    width: '100%',
    p: '.3rem 0',
    '&:hover': {
        backgroundColor: '#FB3F4A !important',  
    }
};

export const ButtonsSkeletonStyles = {
    borderRadius: '50%',
    height: '1.875rem',
    width: '1.875rem',
};
