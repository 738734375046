export const modalStyles = {
  ModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "white",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px 33px",
    borderRadius: "10px",
    width: "632px",
    height:"586px",
    "&:focus-visible": {
      outline: "none !important",
    },
    overflowX: "auto",
  },
  ItemContainer: {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    height: "300px",
    overflow: "hidden",
    overflowY: "scroll",
  },
  ButtonContainer: {
    display:"flex", 
    flexDirection: 'row',
    gap: "120px",
    marginTop:"24px",
    justifyContent:"center",
  }, 
  CancelButton: {
    fontFamily: "Poppins, sans-serif !important",
    border: "1px solid #0B4762 !important",
    backgroundColor: "white !important",
    padding: ".4rem 1.5rem !important",
    textTransform: "none !important",
    borderRadius: "8px !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    color: "#0B4762 !important",
    width: '130px',
    "&:hover": {
      color: "white !important",
    },
  },
  ApplyButton: {
    backgroundColor: "#0B4762 !important",
    fontFamily: "Poppins, sans-serif !important",
    border: "1px solid #0B4762 !important",
    padding: ".4rem 1.5rem !important",
    textTransform: "none !important",
    borderRadius: "8px !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    color: "#FFF !important",
    width: '130px',
    // "&:hover": {
    //   backgroundColor: "rgba(255, 0, 0, 0.15) !important",
    // },
  },
  ItemsHeader: {
    direction:"row",
    flexDirection:"row",
    justifyContent:"center",
    margin: '16px 0',    
  },
  ItemsTitle: {
    fontSize:"18px !important",
    color:"#041C32 !important",
    fontWeight:"500 !important",
    fontFamily:"Poppins !important",    
  },
  ItemStyle: {
    fontSize:"16px !important",
    color:"#041C32 !important",
    fontWeight:"500 !important",
    fontFamily:"Poppins !important",
    lineHeight: '24px',
  }, 
  ShiftName: {
    width: "115px",
    padding: "4px 11px",
    borderRadius: "8px",
    border: "1px solid #0B4762",
    fontFamily: "Poppins, sans-serif",
    color: "#0B4762",
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center",
  },  
}

export const modalTitle = {
  fontSize: '25px',
  lineHeight: '56px',
}

export const itemStyle = {
  fontFamily: 'Poppins',  
  fontSize: '16px',
  color: '#041C32',
  fontWeight: 500,
}

export const itemType = {
  fontFamily: 'Poppins',
  fontStyle: 'italic',
  color: '#818181',
  fontSize: '16px',
  fontWeight: 400,
}

export const disabledSelect = { 
  width: "155px", 
  borderRadius: "10px", 
  height: "43px",
  border: "1px solid #E7E7E7",
}

export const enabledSelect = {
  width: "155px", 
  borderRadius: "10px", 
  height: "43px",
  border: "1px solid #0B4762",
}