import React from 'react';

import dayjs from 'dayjs';

// Material Ui
import { Box, Collapse, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styles
import { ShiftCalendarTextFieldStyles } from '../../../../Styles';
import { CheckedStyle } from '../../../../../Plannig/Styles';

const StartShiftReapeatComponent = ({ value, setValue, optionAhora, handleChange }) => {
    return (
        <Box sx={{ padding: '1rem 0' }}>
            <Typography 
                sx={{ 
                    fontFamily: 'Poppins', 
                    m: '.5rem 0 .8rem 0',
                    fontSize: '1.1rem', 
                    color: '#041C32', 
                    fontWeight: 500,
                }}
            >
                Comienza
            </Typography>
            <Box>
                <RadioGroup
                    onChange={ handleChange }
                    value={ optionAhora }
                    name="radio-ahora"
                >
                    <Stack direction="row" spacing={2}>
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='ahora' 
                            label={
                                <Typography 
                                    sx={{ 
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500 
                                    }}
                                >Ahora</Typography>
                            } 
                        />
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='el' 
                            label={
                                <Typography 
                                    sx={{ 
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500 
                                    }}
                                >El</Typography>
                            } 
                        />
                        <Collapse in={ optionAhora !== 'ahora' } orientation="horizontal" >
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale="en-gb">
                                <DatePicker
                                    renderInput={ ( params ) => <TextField { ...params } /> }
                                    disabled={ optionAhora === 'ahora' ? true : false }
                                    onChange={ ( newValue ) => setValue( newValue ) }
                                    minDate={ dayjs( Date.now() ) }
                                    sx={ ShiftCalendarTextFieldStyles }
                                    format='DD/MM/YYYY'
                                    value={ dayjs( value ) }
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiPickersCalendarHeader-label': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersYear-yearButton': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiTypography-root': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersDay-root': {
                                                    fontFamily: 'Poppins !important',
                                                }
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Collapse>
                    </Stack>
                </RadioGroup>
            </Box>
        </Box>
    )
}

export default StartShiftReapeatComponent;