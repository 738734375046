import React, {useEffect, useState} from 'react';

// Components
import HoursDropdown from '../../HoursDropdown/HoursDropdown';
import HoursPicker from '../../HoursPicker/HoursPicker';

// Material Ui
import {ClickAwayListener, makeStyles} from "@material-ui/core";
import {minutesOfDay} from "../../../../../constants";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'transparent',
        display: 'flex',
        width: '100%',
        gap: '15px',
    },
    title: {
        fontWeight: '600',
        fontSize: '14px',
        margin: '0px',
    },
    text: {
        textAlign: 'justify',
        color: '#848282',
        fontSize: '11px',
        marginTop: '3px',
        margin: '0px',
    },
    FilterButtonsContainer: {
        alignItems: 'center',
        marginTop: '2rem',
        display: 'flex',
        width: '100%',
        gap: '15px',
    }
}));

const OPTIONS_QUANTITY_ADVANCE_END = 10;
const OPTIONS_QUANTITY_ADVANCE_START = 60;

const Hours = (props) => {

    const classes = useStyles();

    const {advanceStart, advanceEnd, opening} = props;

    const [openTime, setOpenTime] = useState(opening)

    useEffect(() => {
        setOpenTime(opening)
    }, [opening])

    const {onChangeAdvanceStart, onChangeAdvanceEnd, onChangeOpening} = props;

    const getAdvanceStartOptions = () => {
        /*return [
            {
                value: 1440,
                name: "1 día"
            },
            {
                value: 2880,
                name: "2 días"
            },
            {
                value: 4320,
                name: "3 días"
            },
            {
                value: 5760,
                name: "4 días"
            },
            {
                value: 7200,
                name: "5 días"
            },
            {
                value: 8640,
                name: "6 días"
            },
            {
                value: 10080,
                name: "7 días"
            },
            {
                value: 14400,
                name: "10 días"
            },
            {
                value: 21600,
                name: "15 días"
            },
            {
                value: 28800,
                name: "20 días"
            },
            {
                value: 43200,
                name: "30 días"
            },
            {
                value: 64800,
                name: "45 días"
            },
            {
                value: 86400,
                name: "60 días"
            },
        ];*/
        let options = []
        for (let i = 0; i < OPTIONS_QUANTITY_ADVANCE_START; i++) {
            options.push({
                value: (i + 1) * minutesOfDay,
                name: i === 0 ? `${i + 1} día` : `${i + 1} días`
            })
        }
        return options;
    }

    const getAdvanceEndOptions = () => {
        let options = []
        for (let i = 0; i < OPTIONS_QUANTITY_ADVANCE_END; i++) {
            options.push({
                value: i < 3 ? (i + 1) * 15 : (i - 2) * 60,
                name: i < 3 ? `${(i + 1) * 15} min.` : `${i - 2} hs.`
            })
        }
        return options;
    }

    const handleChangeOpenTime = (value) => {
        setOpenTime(value);
        onChangeOpening(value)
    }

    return (
        <div className={classes.root}>
            {/*<ClickAwayListener onClickAway={() => onChangeOpening(openTime)}>*/}
                <div className={classes.FilterButtonsContainer}>
                    <HoursPicker
                        value={openTime}
                        onChangeValue={handleChangeOpenTime}
                        editable
                    />
                    <HoursDropdown
                        options={getAdvanceStartOptions()}
                        onChange={(value) => onChangeAdvanceStart(value)}
                        text={'Anticipo máximo'}
                        value={advanceStart}
                        minWidth={'18%'}
                        margin={'0 0 -1.6rem .5rem'}
                        helperText={'Determinar con cuánta anticipación al inicio del turno se permitirá tomar reservas.'}
                    />
                    <HoursDropdown
                        options={getAdvanceEndOptions()}
                        onChange={(value) => onChangeAdvanceEnd(value)}
                        text={'Anticipo mínimo'}
                        value={advanceEnd}
                        minWidth={'18%'}
                        margin={'0 0 -1.6rem .5rem'}
                        helperText={'Determinar con cuánta anticipación al inicio del turno se dejará de tomar reservas.'}
                    />
                </div>
            {/*</ClickAwayListener>*/}
        </div>
    )
}

export default Hours
