export const RemoveLayout = (availableLayouts, sectorId, sectors, reservableSectors, setReservableSectors, setAvailableLayout) => {
  const nextAvailableLayout = [];

  // eslint-disable-next-line array-callback-return
  availableLayouts.map((layout) => {
    if (layout.sectorId !== sectorId) {
        nextAvailableLayout.push(layout);
    }
  });    

  const removeLayout = sectors.find(sector => sector.id === sectorId).layouts;

  const nextReservableSectors = reservableSectors.map((layout) => {
    if (removeLayout.find(remove => remove.uuid === layout.id)) {
      return {
          ...layout,
          available: !layout.available
      };          
    } else {
      return layout;
    }
  })

  setReservableSectors(nextReservableSectors);
  setAvailableLayout(nextAvailableLayout);
}

export const RemoveExtraExcludeTables = (sectors, sectorId, subshifts, setSubshifts) => {
  const sector = sectors.find((sector) => sector.id === sectorId);
  const removedLayouts = sector.layouts;

  const tables = [];
  
  removedLayouts.map((removedLayout) => 
    removedLayout.tables.map((table) => (
      tables.push(table)
    ))
  );

  const newSubshits = subshifts.map((subshift) => {
      // eslint-disable-next-line array-callback-return
      const newExtraTables = subshift.extraTables.map(( extraTable ) => {
          if ( !tables.find( (table) => table.id === extraTable) ) {
            return extraTable;
          } 
      });

      // eslint-disable-next-line array-callback-return
      const newExcludeTables = subshift.excludeTables.map((excludeTable) => {
          if ( !tables.find( (table) => table.id === excludeTable ) ) {
            return excludeTable;
          } 
      });

      const newBookableSectors = [];
      
      // eslint-disable-next-line array-callback-return
      subshift.bookableSectors.map((bookableSector) => {
          if (!removedLayouts.find((removedLayout) => removedLayout.uuid === bookableSector.id)) {
            newBookableSectors.push(bookableSector);
          } 
      });

      console.log( '----->', newExtraTables );

      return {
        ...subshift,
        extraTables: newExtraTables,
        excludeTables: newExcludeTables,
        bookableSectors: newBookableSectors,
      };
  });

  setSubshifts(newSubshits);
}

export const GetSectorLayout = (layoutId, sectors) => {
let newLayout;

sectors.map((sector) => (
  // eslint-disable-next-line array-callback-return
  sector.layouts.map((layout) => {
    if (layout.uuid === layoutId) {
      newLayout = layout;
    }
  })
));

return newLayout;
}

export const GetColLayouts = ( reservableSectors, subshifts, type ) => {
  
  const newReservableSectors = reservableSectors.map(( reservableSector ) => {

    let newSubshifts;

    newSubshifts = reservableSector.subShifts.map(( subshift ) => {

        const sub = subshifts.find((sub) => sub.id === subshift.id );
        
        if ( typeof sub !== 'undefined' ) {
          let exist = false;

          if ( reservableSector.tables.length > 0 ) 
            exist = sub.bookableSectors.find((book) => book.id === reservableSector.id);
          else {          
            // Es mesa, revisar en exclude y en extra tables.
            exist = sub.excludeTables.find(( tab ) => tab === reservableSector.id );
            
            // Como existe en exclude, hay que deshabilitar el chip
            exist = exist ? false : true;
          };

          return {
            ...subshift,
            enabled: exist,
          };

        } else {

          return {
            ...subshift,
            enabled: false,
          };

        }
        
    });

    return {
      ...reservableSector,
      subShifts: newSubshifts,
    }

  })

  return newReservableSectors;
}

const getSector = (sectorId, sectors) => {
  const sector = sectors.find((sector) => sector.id === sectorId);

  return sector;
}

export const getSectors = ( availableLayouts, subShifts, sectors, reservableSectors, minGroups, maxGroups ) => {

  let groupsArray = ['Todos'];
  const sectorsArray = [];
  const res = [];

  for ( let i = minGroups; i <= maxGroups; i++ ) {
    groupsArray.push( i );
  };

  sectors.forEach((sector) => {
    sectorsArray[ sector.name ] = [];
  });

  // console.log( reservableSectors, subShifts );

  subShifts.forEach( subshift => {
    reservableSectors.forEach( sector => {
      if ( sector.type === 'Mesa' ) {
        if ( sector.subShifts.find( item => item.id === subshift.id ) === undefined ) {
          sector.subShifts.push({
            id: subshift.id,
            enabled: true
          });
        };
      };
    });
  });

  if ( reservableSectors.length > 0 ) {
    reservableSectors.forEach( ( sector ) => {
      if ( sector.type && sector.type !== 'Mesa' ) {
        sector.groups?.forEach( group => {
          if ( sectorsArray[ sector.name ].length <= ( maxGroups + 1 ) ) {
            sectorsArray[ sector.name ].push({
              bookable: group.bookable,
              enabled: group.enabled, 
              layout: group.layout,
              id: group.id,
            });
          };
        });
      };
    });

  } else {
    groupsArray.forEach(( item ) => {
      sectors.forEach( ( sector ) => {
        if ( sectorsArray[ sector.name ].length <= ( maxGroups + 1 ) ) {
          sectorsArray[ sector.name ].push({
            layout: sector.name,
            bookable: true,
            enabled: false, 
            id: item,
          });
        };
      });
    });
  };

  // eslint-disable-next-line array-callback-return
  availableLayouts.map(( layout ) => {
    
    const sector = getSector( layout.sectorId, sectors );

    let layoutInfo;

    if ( sector ) {
      layoutInfo = sector.layouts.find((lay) => lay.uuid === layout.id);
    } else {
      layoutInfo = undefined;
    };

    let enableGroupArray = [];
    
    const handleSetExcludedGroups = ( subshift ) => {
      let excludedGroups = [];

      subshift.bookableSectors.forEach( bookableSector => {
        reservableSectors.forEach( sector => {
          if ( bookableSector.id === sector.id ) {
            sector.groups.forEach( group => {
              if ( group.bookable === false ) {
                excludedGroups.push( group );
              };
            });
          };
        });
      });

      return excludedGroups;
    };

    if ( typeof layoutInfo !== 'undefined' ) {

      let allTables = layoutInfo.tables;
      layoutInfo.tables.forEach((table) => {
        if ( table.combinesWith && table.combinesWith.length > 0 ) {
          table.combinesWith.forEach((combine) => {
            const allTablesIds = allTables.map((table) => table.id);
            if (!allTablesIds.includes(combine.id)) {
              allTables.push(combine);
            }
          })
        };
      });

      res.push({
        id: layout.id,
        name: sector.name,
        tables: allTables,
        groups: sectorsArray[ sector.name ],
        type: layoutInfo.name,
        subShifts: subShifts.map(( subshift ) => (
          {
            excludedGroup: handleSetExcludedGroups( subshift ),
            id: subshift.id, 
            enabled: true,
          }
        )),
        available: true,
      });

    } else {

      sectorsArray[ sector.name ].forEach( item => {
        if ( item.enabled ) {
          enableGroupArray.push( item )
        }
      });

      res.push({
        id: sector.id,
        name: sector.name,
        tables: [],
        groups: sectorsArray[ sector.name ],
        type: sector.type,
        subShifts: subShifts.map((subshift) => (
          {
            enabled: subshift.enabled,
            excludedGroup: [],
            id: subshift.id, 
          }
        )),
        available: sector.available,
      });
    };    
  });

  if ( reservableSectors && res.length < reservableSectors.length ) {
    for (let i = res.length; i < reservableSectors.length; i++) {
      res.push( reservableSectors[i] );
    };
  };

  return res;
};

export const GetLayout = ( availableLayouts, sectorId, layouts ) => {
  if ( availableLayouts.length === 0 ) {
    return {
        id: layouts[0].uuid,
        minCapacityAuto: 1,
        maxCapacityAuto: 10,
        note: '',
    };
  } else {
    const layout = availableLayouts.find((availableLayout) => availableLayout.sectorId === sectorId);
    if (typeof layout !== 'undefined' && layout !== null) {
      return layout;
    } else {
      return {
          id: layouts[0].uuid,
          minCapacityAuto: 1,
          maxCapacityAuto: 10,
          note: '',
      };
    }
  }
};

export const AddLayoutToSubshift = (newLayoutlayout, subShifts, setSubshift) => {
  const nextSubShifts = subShifts.map(subShift => {    
      const newBookableSectors = subShift.bookableSectors;
      const existBookableSector = newBookableSectors.find((b) => b.id === newLayoutlayout.id);

      if (typeof existBookableSector === 'undefined' || existBookableSector === null) {
        newBookableSectors.push({id: newLayoutlayout.id, allowsWaitList: newLayoutlayout.activeWaitList});
      }
      
      return {
        ...subShift,
        bookableSectors: newBookableSectors,
      };        
  });

  setSubshift(nextSubShifts);
};

export const AddBookableSectorsToSubshift = (availableLayouts, workWithWaitList, subShifts, setSubshift) => {
  const nextSubShifts = subShifts.map(subShift => {    
      const newBookableSectors = availableLayouts.map((availableLayout) => {
        return {
          id: availableLayout.id,
          allowsWaitList: workWithWaitList,
        }
      })
              
      return {
        ...subShift,
        bookableSectors: newBookableSectors,
        };        
    });

  setSubshift(nextSubShifts);
};