import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  ADD_NEW_SECTOR,
  ADD_NEW_TABLE,
  ADD_TABLE_TO_COMBINATION,
  BACK_TO_VIEW_MODE,
  CANCEL_COMBINATION,
  CHANGE_EDITING_TABLE_NEW,
  CHANGE_LAYOUT_MAX_GROUP,
  CHANGE_LAYOUT_NAME,
  CHANGE_SECTOR_MODE,
  CHANGE_TABLE_LAYOUT_COORDINATES,
  CHANGE_TABLE_LAYOUT_PROPERTIES,
  LAYOUT_CREATE_SUCCESS,
  LOAD_SECTORS_FAILURE,
  LOAD_SECTORS_SUCCESS,
  REFRESH_ERROR,
  REMOVE_COMBINATION,
  REMOVE_TABLE,
  REMOVE_TABLE_FROM_COMBINATION,
  SAVE_COMBINATION,
  SECTOR_MODE,
  START_ADDING_NEW_TABLE,
  START_CREATING_LAYOUT,
  START_DUPLICATING_LAYOUT,
  START_LAYOUT_EDIT_MODE,
  START_LOAD_SECTORS,
  START_NEW_COMBINATION,
  UPDATE_COMBINATION,
  UPDATE_COMBINATION_NAME,
} from "../../../constants";
import {
  displayNotification,
  displayNotificationWithTimeout,
} from "../../notistackActions";

export const startLoadSectors = () => ({
  type: START_LOAD_SECTORS,
});

export const loadSectorsSuccess = (sectors) => ({
  type: LOAD_SECTORS_SUCCESS,
  payload: sectors,
});

export const loadSectorsFailure = (error) => ({
  type: LOAD_SECTORS_FAILURE,
  payload: error,
});

export const changeSectorMode = ({ mode, sector }) => ({
  type: CHANGE_SECTOR_MODE,
  payload: { mode, sector },
});

export const refreshError = () => ({
  type: REFRESH_ERROR,
});

export const changeLayoutName = (name) => ({
  type: CHANGE_LAYOUT_NAME,
  payload: name,
});

export const changeLayoutMaxGroup = (maxGroup) => ({
  type: CHANGE_LAYOUT_MAX_GROUP,
  payload: maxGroup,
});

export const addNewSector = (sector) => ({
  type: ADD_NEW_SECTOR,
  payload: sector,
});

export const startAddingNewTable = (table) => ({
  type: START_ADDING_NEW_TABLE,
  payload: table,
});

export const addNewTable = (table) => ({
  type: ADD_NEW_TABLE,
  payload: table,
});

export const removeTable = (table) => ({
  type: REMOVE_TABLE,
  payload: table,
});

export const changeEditingTable = (table) => ({
  type: CHANGE_EDITING_TABLE_NEW,
  payload: table,
});

export const changeTableLayoutProperties = (table) => ({
  type: CHANGE_TABLE_LAYOUT_PROPERTIES,
  payload: table,
});

export const changeTableLayoutCoordinates = (table) => ({
  type: CHANGE_TABLE_LAYOUT_COORDINATES,
  payload: table,
});

//Combinations
export const startNewCombination = (combination) => ({
  type: START_NEW_COMBINATION,
  payload: combination,
});

export const updateCombinationName = (id, alias) => ({
  type: UPDATE_COMBINATION_NAME,
  payload: { id, alias },
});

export const addTableToCombination = (table) => ({
  type: ADD_TABLE_TO_COMBINATION,
  payload: table,
});

export const removeTableFromCombination = (table) => ({
  type: REMOVE_TABLE_FROM_COMBINATION,
  payload: table,
});

export const cancelCombination = () => ({
  type: CANCEL_COMBINATION,
});

export const saveCombination = (combination) => ({
  type: SAVE_COMBINATION,
  payload: combination,
});

export const removeCombination = (combination) => ({
  type: REMOVE_COMBINATION,
  payload: combination,
});

export const updateCombination = (combination) => ({
  type: UPDATE_COMBINATION,
  payload: combination,
});

export const layoutCreateSuccess = () => ({
  type: LAYOUT_CREATE_SUCCESS,
});

export const startLayoutEditMode = (layoutId, sectorId) => ({
  type: START_LAYOUT_EDIT_MODE,
  payload: { layoutId, sectorId },
});

export const startCreatingLayout = (sectorId) => ({
  type: START_CREATING_LAYOUT,
  payload: sectorId,
});

export const startDuplicatingLayout = (layoutId, sectorId) => ({
  type: START_DUPLICATING_LAYOUT,
  payload: { layoutId, sectorId },
});

export const backToViewMode = () => ({
  type: BACK_TO_VIEW_MODE,
});

export const goBackToViewMode = () => {
  return async (dispatch) => {
    dispatch(loadSectors());
    dispatch(backToViewMode());
  };
};

export const loadSectors = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;
    
    try {
      ensurePartnerExists( idPartnerSelected );
      dispatch(startLoadSectors());

      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      const res = await axios.get(`${API_URL}/sector/${ idPartnerSelected }`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { data } = res;
      dispatch(loadSectorsSuccess(data));
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        // dispatch(loadSectorsFailure(error.response.data));
        dispatch(
          displayNotificationWithTimeout({
            type: "error",
            message: error.response.data.message,
            severity: "error",
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "loadSectors",
            message: "No se pudo cargar los sectores",
            severity: "error",
          })
        );
      }
    }
  };
};

export const updateSector = (sector) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    try {
      ensurePartnerExists( idPartnerSelected );
      dispatch(startLoadSectors());
      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      await axios.patch(
        `${API_URL}/sector/${ idPartnerSelected }/${sector.id}`,
        sector,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(loadSectors());
      dispatch(displayNotification("Se guardaron tus cambios"));
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(
          loadSectorsFailure({
            action: "updateSector",
            message: error.response.data.message,
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "updateSector",
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  };
};

export const saveOrUpdateLayout = (image) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { v3, userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;
    const { sectorsLayoutsTables } = v3;
    const firebaseReact = getFirebase();
    const { mode, layout, sector } = sectorsLayoutsTables;

    try {
      ensurePartnerExists( idPartnerSelected );
      dispatch(startLoadSectors());
      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      // Load image
      if (image) {
        try {
          const newImage = new File([image], layout.id, { type: "image/png" });
          const storageRef = firebaseReact.storage().ref();
          const imageRef = storageRef.child(
            "layoutImages/" + idPartnerSelected + "/" + layout.id
          );
          const snap = await imageRef.put(newImage);
          layout.image = await snap.ref.getDownloadURL();
        } catch (error) {
          console.log("Error al subir la image: ", error);
          dispatch(
            loadSectorsFailure({
              action: "saveOrUpdateLayout",
              message: error.message,
              severity: "error",
            })
          );
        }
      }
      if (mode === SECTOR_MODE.EDIT) {
        try {
          await axios.patch(
            `${API_URL}/sector/layout/${ idPartnerSelected }`,
            {
              ...layout,
              newId: uuidv4(),
              sectorId: sector.id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          dispatch(layoutCreateSuccess());
          dispatch(loadSectors());
          dispatch(displayNotification("Se guardaron tus cambios"));
        } catch (error) {
          const res = error.response;
          if (res.status > 300) {
            dispatch(
              loadSectorsFailure({
                action: "saveOrUpdateLayout",
                message: res.data.message,
                severity: "error",
              })
            );
          }
        }
      } else if (mode === SECTOR_MODE.NEW_LAYOUT) {
        try {
          await axios.put(
            `${API_URL}/sector/layout/${ idPartnerSelected }`,
            {
              ...layout,
              sector: sector.id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          dispatch(layoutCreateSuccess());
          dispatch(loadSectors());
          dispatch(displayNotification("Layout agregado exitosamente"));
        } catch (error) {
          const res = error.response;
          if (res.status > 300) {
            dispatch(
              loadSectorsFailure({
                action: "saveOrUpdateLayout",
                message: res.data.message,
                severity: "error",
              })
            );
          }
        }
      } else if (mode === SECTOR_MODE.NEW_SECTOR) {
        try {
          await axios.put(
            `${API_URL}/sector/${ idPartnerSelected }`,
            {
              ...sector,
              layouts: [layout],
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          dispatch(layoutCreateSuccess());
          dispatch(loadSectors());
          dispatch(
            displayNotification("Sector y layout agregado exitosamente")
          );
        } catch (error) {
          const res = error.response;
          if (res.status > 300) {
            dispatch(
              loadSectorsFailure({
                action: "saveOrUpdateLayout",
                message: res.data.message,
                severity: "error",
              })
            );
          }
        }
      }
    } catch (error) {
      if (error.response?.data) {
        dispatch(
          loadSectorsFailure({
            action: "saveOrUpdateLayout",
            message: error.response.data.message,
            severity: "error",
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "saveOrUpdateLayout",
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  };
};

export const deleteSector = (id) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    try {
      ensurePartnerExists( idPartnerSelected );
      dispatch(startLoadSectors());
      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      await axios.delete(`${API_URL}/sector/${ idPartnerSelected }/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(loadSectors());
      dispatch(displayNotificationWithTimeout("Se eliminó el sector"));
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(
          loadSectorsFailure({
            action: "deleteSector",
            message: error.response.data.message,
            severity: "error",
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "deleteSector",
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  };
};

export const deleteLayout = (layoutId, sectorId, totalLayouts = 2) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;
    
    try {
      ensurePartnerExists( idPartnerSelected );
      dispatch(startLoadSectors());
      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      await axios.delete(
        `${API_URL}/sector/layout/${ idPartnerSelected }?sector=${sectorId}&layout=${layoutId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(loadSectors());
      dispatch(
        displayNotificationWithTimeout(
          totalLayouts > 1
            ? "Se eliminó el layout"
            : "Se eliminó el layout y el sector"
        )
      );
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(
          loadSectorsFailure({
            action: "deleteLayout",
            message: error.response.data.message,
            severity: "error",
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "deleteLayout",
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  };
};

export const searchTables = (search, sector) => {
  return async (dispatch, getState, { getFirebase }) => {
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    try {
      ensurePartnerExists( idPartnerSelected );
      const API_URL = process.env.REACT_APP_API_URL_V3;
      const auth = getFirebase().auth();
      const token = await auth.currentUser.getIdToken(true);
      const res = await axios.get(
        `${API_URL}/sector/table/${ idPartnerSelected }/${sector.id}?tableName=${search}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { data } = res;
      return data;
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        dispatch(
          loadSectorsFailure({
            action: "searchTables",
            message: error.response.data.message,
            severity: "error",
          })
        );
      } else {
        dispatch(
          loadSectorsFailure({
            action: "searchTables",
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  };
};

function ensurePartnerExists( id ) {
  if (!id) {
    throw new Error("Partner does not exist");
  }
}
