import React from 'react';
// import { DateRangePicker } from '@mui/x-date-pickers-pro'
// import { LocalizationProvider } from '@mui/x-date-pickers'
// import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {Button} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

import {DateRange} from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import * as locales from 'react-date-range/dist/locale';

import moment from "moment"; // theme css file

const useStyles = makeStyles(() => ({
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        top: "50%",
        left: "50%",
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        width: '580px',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        margin: '0 0 .2rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#737171',
    },
    TopContainer: {
        padding: '.5rem 0 0 0',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '25px'
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    ApplyButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#0B4762 !important',
        }
    },
    InputDate: {
        height: '40px',
        color: '#0B4762',
        fontFamily: 'Poppins, sans-serif',

    },
    InputDateOutline: {
        borderColor: '#0B4762 !important',
        borderRadius: '8px',
        color: '#0B4762',
    },
    helperText: {
        fontSize: '20px',
        fontFamily: 'Poppins, sans-serif',
        color: '#EB252E',
    },
    calendar: {
        fontFamily: 'Poppins, sans-serif',
        width: '80%',
    }
}));

const RangeDatePicker = (props) => {
    const classes = useStyles();

    const {onCancel, submit, initDates} = props;

    const [date, setDate] = React.useState(initDates);

    const handleChangeDate = (newValue) => {
        setDate([newValue.selection.startDate, newValue.selection.endDate]);
    }

    const handleOnApply = () => {
        submit(moment(date[0]), moment(date[1]));
    }

    return (

        <Box className={classes.ModalContainer}>
            <div className={classes.TopContainer}
                 style={{
                     display: "block",
                     justifyContent: 'center',
                     alignItems: 'stretch',
                     marginBottom: '15px'
                 }}>
                <h1 className={classes.Title}>
                    Historial de reservas
                </h1>
                <DateRange
                    className={classes.calendar}
                    editableDateInputs={false}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    locale={locales.es}
                    color={'#0B4762'}
                    rangeColors={['#0B4762']}
                    onChange={handleChangeDate}
                    months={2}
                    direction="horizontal"
                    ranges={[{
                        startDate: date[0],
                        endDate: date[1],
                        key: 'selection'
                    }]}
                />
                {/*<LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateRangePicker
                        mask={"__/__/____"}
                        inputFormat={"DD/MM/YYYY"}
                        closeOnSelect={true}
                        value={date}
                        onChange={handleChangeDate}
                        InputAdornmentProps={{
                            sx: {
                                svg: {
                                    fill: '#0B4762'
                                },
                            }
                        }}
                        InputProps={{
                            classes: {notchedOutline: classes.InputDateOutline, root: classes.InputDate},
                        }}
                        renderInput={(startProps, endProps) =>
                            <React.Fragment>
                                <TextField
                                    {...startProps}
                                    label="Desde"
                                    size={'small'}
                                    InputLabelProps={{
                                        ...startProps.InputLabelProps,
                                        classes: {
                                            root: classes.InputDateOutline,
                                        }
                                    }}/>
                                <Box sx={{mx: 2}}> - </Box>
                                <TextField
                                    {...endProps}
                                    label="Hasta"
                                    size={'small'}
                                    InputLabelProps={{
                                        ...endProps.InputLabelProps,
                                        classes: {
                                            root: classes.InputDateOutline,
                                        }
                                    }}/>
                            </React.Fragment>
                        }
                    />
                </LocalizationProvider>*/}
            </div>
            <div className={classes.ButtonsComponents}>
                <Button
                    className={classes.ContinuesButton}
                    onClick={onCancel}
                >Cancelar</Button>
                <Button
                    className={classes.ApplyButton}
                    onClick={handleOnApply}
                >
                    Aplicar
                </Button>
            </div>
        </Box>
    )
}

export default RangeDatePicker;
