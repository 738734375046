import React from 'react';

const BookingCreateDateIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.86925 17.1056H2.78948C2.31488 17.1056 1.85972 16.917 1.52413 16.5814C1.18853 16.2458 1 15.7907 1 15.3161V4.57919C1 4.10458 1.18853 3.64942 1.52413 3.31383C1.85972 2.97824 2.31488 2.7897 2.78948 2.7897H13.5264C14.001 2.7897 14.4561 2.97824 14.7917 3.31383C15.1273 3.64942 15.3159 4.10458 15.3159 4.57919V8.15815" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.4208 18.0001C15.37 18.0001 16.2803 17.6231 16.9515 16.9519C17.6227 16.2807 17.9997 15.3704 17.9997 14.4212C17.9997 13.472 17.6227 12.5616 16.9515 11.8904C16.2803 11.2193 15.37 10.8422 14.4208 10.8422C13.4716 10.8422 12.5612 11.2193 11.8901 11.8904C11.2189 12.5616 10.8418 13.472 10.8418 14.4212C10.8418 15.3704 11.2189 16.2807 11.8901 16.9519C12.5612 17.6231 13.4716 18.0001 14.4208 18.0001Z" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7373 1V4.57896" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.57959 1V4.57896" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 8.15817H15.3159" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.4204 13.0719V14.4176L15.3152 15.3123" stroke="#818181" strokeWidth="1.47826" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BookingCreateDateIcon;