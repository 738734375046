import {combineReducers} from 'redux';
import createReducer from './createReducer';
import editReducer from './editReducer';
import deleteReducer from './deleteReducer';

export default combineReducers({
    createReducer,
    editReducer,
    deleteReducer,
});
