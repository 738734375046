import React from 'react';
import TimeField from "react-simple-timefield";

// Material ui
import {InputBase, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    TextTop: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#FCFCFC',
        position: 'absolute',
        padding: '0 .2rem',
        lineHeight: '.9rem',
        fontSize: '.8rem',
        color: '#808080',
        display: 'flex',
        top: '-.8rem',
        left: '.7rem',
        margin: '0',
        zIndex: '2',
    },
    Input: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #D0D0D0',
        backgroundColor: 'white',
        borderRadius: '10px',
        position: 'relative',
        padding: '0 1rem',
        height: '2.65rem',
        '&:hover': {
            border: '1px solid #0B4762',
        },
        '&:active': {
            border: '1px solid #0B4762',
        }
    }
});

const HoursPicker = (props) => {

    const classes = useStyles();
    const { value, onChangeValue, editable, disable, disableChangeFromKeyboard } = props;

    return (
        <div style={{position: 'relative'}}>
            <p className={classes.TextTop}>Horario de inicio</p>
            <TimeField
                className={classes.Input}
                style={{color: editable ? "#0B4762" : "#AFAFAF"}}
                value={value}
                onChange={(e, time) => {
                    if (!disableChangeFromKeyboard)
                        onChangeValue(time)
                }}
                input={
                    <InputBase
                        disabled={disable}
                        value={value}
                        fullWidth={false}
                    />
                }
            />
        </div>
    )
}

export default HoursPicker;
