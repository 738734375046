import {
    DISPLAY_NOTIFICATION,
    CLOSE_NOTIFICATION,
} from '../../constants';

const initState = {
    open: false,
    message: "",
    severity: "info",
}

const notistackReducer = (state = initState, action) => {
    switch (action.type) {
        case DISPLAY_NOTIFICATION:
            return {
                ...state,
                open: true,
                message: action.payload,
                severity: action.severity ?? "info",
            };
        case CLOSE_NOTIFICATION:
            return {
                ...state,
                open: false,
                message: "",
            };
        default:
            return state;
    }
}

export default notistackReducer;
