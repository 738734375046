import React from 'react'

// Components
import DaysButton from "../NewComponents/Buttons/Days/DaysButton";

// Material Ui
import {Grid} from "@material-ui/core";

// Utils
import {dayToChar, dayToString} from "../../utils/formatters";

const DaySelector = (props) => {
    const {days, rounded, enable, disable, spacing, key, star, together, char} = props;

    const {onClick} = props;

    return (
        <Grid key={key}
            container
            item={props.item}
            xs={props.item ? props.xs : undefined}
            spacing={spacing}
            style={{
                gap: '15px',
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft, 
            }}
        >
            {days ? days.map((day, index) => (
                <Grid key={`day-selector-${index}-${day}`} item xs={star ? 3 : undefined} style={{margin: '.2rem'}}>
                    <DaysButton
                        enable={day}
                        day={char ? dayToChar(index) :dayToString(index)}
                        onClick={() => onClick(index, !day)}
                        rounded={rounded}
                    />
                </Grid>
            )) : null}
        </Grid>
    );
}

DaySelector.defaultProps = {
    spacing: 1
}

export default DaySelector
