import React, {useEffect} from 'react';
import {connect} from 'react-redux';


// Actions
import {
    changeOrder,
    changePage,
    changeQuery,
    changeRowsPerPage,
    closeStatisticsError,
    fetchFileExport,
    fetchStatistics
} from '../../actions';

// Constants
// Components
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import TableUsers from './components/TableUsers';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../constants/modalMode";

const useStyles = makeStyles(() => ({
    mainContainer: {
        paddingRight: '2rem'
    },
    newUserButton: {
        marginLeft: 10,
        background: '#4caf50',
        color: '#fff',
        '&:hover': {
            background: '#357a38',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    text: {
        color: '#848282',
        fontSize: '12px',
    }
}));


function ClientsStatistics(props) {
    const classes = useStyles();

    // Variables
    const {
        statistics,
        page,
        rowsPerPage,
        totalResults,
        orderBy,
        orderType,
        idPartnerSelected,
        query,
        loading,
        error,
        profile
    } = props;

    // Actions
    const {
        fetchStatistics,
        closeErrorDialog,
        changePage,
        changeRowsPerPage,
        changeOrder,
        onSearch,
        exportStatistics
    } = props;

    useEffect(() => {
        if (idPartnerSelected) {
            fetchStatistics();
        }
    }, [idPartnerSelected, page, rowsPerPage, orderType, orderBy, query])

    const handleChangePage = (page) => {
        changePage(page);
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        changeRowsPerPage(rowsPerPage);
    }

    const handleChangeOrder = (orderBy, orderType) => {
        changeOrder({orderBy, orderType});
    }

    return (
        <section className={classes.mainContainer}>
            <TableUsers
                rows={statistics}
                page={page}
                rowsPerPage={rowsPerPage}
                totalResults={totalResults ?? statistics.length}
                orderBy={orderBy}
                orderType={orderType}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangeOrder={handleChangeOrder}
                onSearch={onSearch}
                onExport={exportStatistics}
            />
            <ProgressIndicator open={loading}/>
            <ErrorDialog
                open={error !== undefined}
                title={"Error"}
                type={ModalMode.ERROR}
                onAccept={closeErrorDialog}
                body={error ?? ""}/>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        statistics: state.clientsStatisticsReducer.statistics,
        page: state.clientsStatisticsReducer.page,
        rowsPerPage: state.clientsStatisticsReducer.rowsPerPage,
        totalResults: state.clientsStatisticsReducer.totalResults,
        orderBy: state.clientsStatisticsReducer.orderBy,
        orderType: state.clientsStatisticsReducer.orderType,
        query: state.clientsStatisticsReducer.query,
        loading: state.clientsStatisticsReducer.loading,
        error: state.clientsStatisticsReducer.error,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchStatistics: () => dispatch(fetchStatistics()),
        closeErrorDialog: () => dispatch(closeStatisticsError()),
        changePage: (page) => dispatch(changePage(page)),
        changeRowsPerPage: (rowsPerPage) => dispatch(changeRowsPerPage(rowsPerPage)),
        changeOrder: ({orderBy, orderType}) => dispatch(changeOrder({orderBy, orderType})),
        onSearch: (query) => dispatch(changeQuery(query)),
        exportStatistics: () => dispatch(fetchFileExport()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsStatistics);
