import React from 'react';

// Component
import {BlueSwitch} from '../GeneralComponents/blueSwitch';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    MainContainer: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        border: '1px solid #DBD7DC',
        flexDirection: 'column',
        borderRadius: '8px',
        padding: '.2rem',
        display: 'flex',
        height: '180px',
        width: '220px',
    },
    Top: {
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '1rem',
        marginTop: '.5rem',
        display: 'flex',
    },
    Discount: {
        fontSize: '1.9rem',
        fontWeight: '600',
        color: '#0B4762',
        margin: '0',
    },
    SwitchContainer: {
        marginTop: '-.8rem'
    }
}));

const DiscountItem = () => {
    const classes = useStyles();
    return (
        <div className={classes.MainContainer}>
            <div className={classes.Top}>
                <h2 className={classes.Discount}>20%</h2>
                <div className={classes.SwitchContainer}>
                    <BlueSwitch color="default"/>
                </div>
            </div>
        </div>
    )
}

export default DiscountItem;
