import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    color: "#041C32",
    fontSize: "22px",
    fontWeight: "500",
    lineHeight: '33px',
  },
}));

const Title = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.title} {...restProps}>
      {text}
    </Typography>
  );
};

export default Title;
