import React from 'react';

// Components
import LinearProgressSection from '../LinearProgressSection/LinearProgressSection';
import CalendaryIcon from '../../SvgComponents/icons/Home/CalendaryIcon';

// Material Ui
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    DataContainer: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #DBD7DC',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '1rem 3rem',
        textAlign: 'center',
    },
    Title: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32'
    },
    DataNumbersContainer: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        backgroundColor: '#F4F5F6',
        borderRadius: '10px',
        padding: '1rem 0',
        display: 'grid',
    },
    Bookings: {
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
    },
    Subtitle: {
        fontSize: '1.1rem',
        fontWeight: '600',
        margin: '.5rem 0',
        color: '#041C32',
        ['@media (max-width: 1600px)']: {
            fontSize: '.8rem',
        },
        ['@media (max-width: 1300px)']: {
            fontSize: '.8rem',
        }
    },
    Data: {
        fontSize: '2.5rem',
        fontWeight: '600',
        color: '#0B4762',
        margin: '0',
    },
    Subtitle2: {
        margin: '1rem 0 0 0',
        fontWeight: '500',
        color: '#808080',
        fontSize: '1rem',
    }
}));

const DataSection = (props) => {
    const classes = useStyles();

    const {data} = props;

    return (
        <section className={classes.DataContainer}>
            <h1 className={classes.Title}>Ingresos totales</h1>
            <div className={classes.DataNumbersContainer}>
                <div className={classes.Bookings}>
                    <div className={classes.IconSection}>
                        <CalendaryIcon/>
                    </div>
                    <h2 className={classes.Subtitle}>Reservas ingresadas</h2>
                    <h1 className={classes.Data}>{data.totalBookings}</h1>
                </div>
                <div className={classes.Cutlery}>
                    <div className={classes.IconSection}>
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.81769 3.17023L28.2837 27.6363C28.748 28.1005 29.0088 28.7302 29.0088 29.3868C29.0088 30.0433 28.748 30.673 28.2837 31.1372C27.8194 31.6013 27.1898 31.862 26.5333 31.862C25.8768 31.862 25.2472 31.6013 24.7828 31.1372L18.8062 25.0577C18.4151 24.6606 18.1957 24.1256 18.1953 23.5682V23.201C18.1954 22.9196 18.1395 22.6409 18.031 22.3812C17.9225 22.1215 17.7634 21.886 17.5631 21.6883L16.7915 20.9757C16.5295 20.734 16.211 20.5621 15.8652 20.4758C15.5194 20.3894 15.1574 20.3915 14.8126 20.4817C14.2688 20.6236 13.6974 20.6208 13.155 20.4737C12.6127 20.3266 12.1182 20.0402 11.7207 19.643L6.04762 13.9692C2.68215 10.6037 1.44367 5.52167 3.81769 3.17023Z"
                                stroke="#0B4762" strokeWidth="2.125" strokeLinejoin="round"/>
                            <path
                                d="M13.2812 24.4375L6.65922 31.0967C6.1611 31.5947 5.48559 31.8744 4.78125 31.8744C4.07691 31.8744 3.4014 31.5947 2.90328 31.0967V31.0967C2.40531 30.5986 2.12556 29.9231 2.12556 29.2188C2.12556 28.5144 2.40531 27.8389 2.90328 27.3408L8.5 21.7812M26.5625 2.125L21.4326 7.25488C21.0379 7.64955 20.7247 8.11811 20.5111 8.6338C20.2975 9.1495 20.1875 9.70223 20.1875 10.2604V11.2472C20.1875 11.3868 20.16 11.5251 20.1066 11.6541C20.0531 11.7831 19.9748 11.9003 19.8761 11.9989L19.125 12.75L26.5625 2.125ZM21.25 14.875L22.0011 14.1239C22.0997 14.0252 22.2169 13.9469 22.3459 13.8934C22.4749 13.84 22.6132 13.8125 22.7528 13.8125H23.7396C24.2978 13.8125 24.8505 13.7025 25.3662 13.4889C25.8819 13.2753 26.3505 12.9621 26.7451 12.5674L31.875 7.4375L21.25 14.875ZM29.2187 4.78125L23.9062 10.0938L29.2187 4.78125Z"
                                stroke="#0B4762" strokeWidth="2.125" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h2 className={classes.Subtitle}>Cubiertos ingresadas</h2>
                    <h1 className={classes.Data}>{data["totalBookings-diners"]}</h1>
                </div>
            </div>
            <h3 className={classes.Subtitle2}>Cubiertos ingresados según pricing</h3>
            <LinearProgressSection
                woki={data["woki-diners"]}
                manual={data["manual-diners"]}
                link={data["link-diners"]}
                myClients={data["myClients-diners"]}
                total={data["totalBookings-diners"]}
            />
        </section>
    )
}

export default DataSection;
