import React from 'react';

// Material Ui
import { 
    ListItemIcon, 
    ListItemText, 
    Typography, 
    ListItem, 
    Box, 
} from '@mui/material';

const ParentItem = ( props ) => {

    // External props
    const { 
        handleClickCollapse, 
        iconBackground, 
        enableOption,
        onHover,
        title, 
        color,   
        icon, 
        key, 
    } = props;

    return (
        <ListItem
            sx={{
                backgroundColor: enableOption === undefined || enableOption ? iconBackground : '#848282A0',
                cursor: enableOption === undefined || enableOption ? undefined : 'default',
                borderRadius: '.4rem',
                height: '2.5rem',
                color: color,
                mb: '.2rem',
                pl: '10px',
                pr: '10px',
            }}
            onClick={ handleClickCollapse } 
            key={ key }
            button
        >
            <Box sx={{ backgroundColor: iconBackground, borderRadius: '8px' }}>
                <ListItemIcon 
                    sx={{ 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        minWidth: '0px', 
                        display: 'flex', 
                    }} 
                    onMouseOver={ onHover }
                >
                    { icon }
                </ListItemIcon>
            </Box>
            <ListItemText>
                <Typography 
                    sx={{
                        fontFamily: 'Poppins',
                        marginLeft: '1rem',
                        fontSize: '14px',
                        fontWeight: 500,
                    }}
                >{ title }</Typography>
            </ListItemText>
        </ListItem>
    )
}

export default ParentItem;
