import React, {useEffect} from 'react'
import c3 from 'c3';
import 'c3/c3.css';
import './CurveLineChart.css'
// Ejemplos y documentacion de libreria para graficos
// https://c3js.org/examples.html

const CurveLineChart = (props) => {

    // DATA
    const {id, data, lineColor} = props;

    useEffect(() => {
        const column = data.length <= 0 ? [] : [['data', ...data]]
        c3.generate({
            bindto: document.getElementById(id),
            data: {
                columns: column,
                type: "spline",
                colors: {
                    data: lineColor
                },
                empty: {
                    label: {
                        text: "Sin datos"
                    }
                }
            },
            axis: {
                x: {
                    show: true
                },
                y: {
                    show: true
                }
            },
            legend: {
                show: false
            },
            size: {
                height: 80,
            },
            interaction: {
                enabled: false
            },

            point: {
                show: false,
                r: 30,
            }
        });
    }, [data]);

    return (
        <div
            style={{
                borderRadius: '20px',
                boxShadow: '0 3px 6px 2px rgba(0, 0, 0, .16)',
                backgroundColor: 'white',
                height: '80px',
                width: '100%',
            }}
        >
            <div id={id} />
        </div>
    );
}

CurveLineChart.defaultProps = {
    lineColor: "#0076FF"
}

export default CurveLineChart;

