import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { sectorTypes } from "../../../../../utils/sectors";
import { connect } from "react-redux";
import { addNewSector, updateSector } from "../../../../../actions/V3";
import { LoadingButton } from "@mui/lab";
import { displayNotification } from "../../../../../actions/notistackActions";

function AddOrEditSector(props) {
  const { updateSector, displayNotification, addNewSector } = props;
  const { loading, sectors} = props;
  const {
    open,
    handleClose,
    isAdding = true,
    name = "",
    description = "",
    type = "",
    id = "",
  } = props;
  const theme = useTheme();
  const validationSchema = React.useMemo(() => {
    return Yup.object({
      name: Yup.string()
        .min(2, "El nombre es muy corto")
        .max(50, "El nombre es muy largo")
        .notOneOf(sectors.filter((s) => s.id !== id).map((s) => s.name), "Ya existe un sector con ese nombre")
        .required("El nombre es requerido"),
      description: Yup.string().max(200, "La descripción es muy larga"),
      type: Yup.number()
        .oneOf(
          Object.keys(sectorTypes).map((t) => parseInt(t)),
          "Tipo de sector inválido"
        )
        .required("El tipo de sector es requerido"),
    });
  }, [sectors]);
  const formik = useFormik({
    initialValues: {
      name,
      description,
      type,
      id: id || uuidv4(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const valuesInFormat = Object.keys(values).reduce((acc, key) => {
        if(key !== 'type') {
        acc[key] = values[key].trim().replace(/\s+/g, " ");
        } else {
          acc[key] = values[key];
        }
        return acc;
      }, {});
      if(name === valuesInFormat.name) {
        delete valuesInFormat.name;
      }
      if (isAdding) {
        valuesInFormat.id = uuidv4();
        await addNewSector(valuesInFormat);
        handleCloseModal();
      } else {
        await updateSector(valuesInFormat);
        displayNotification("Se guardaron los cambios");
        handleCloseModal();
      }
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    handleClose();
  };

  React.useEffect(() => {
    formik.setValues({
      name,
      description,
      type,
      id,
    });
  }, [name, description, type, id]);

  const handleSubmitClick = () => {
    if(!formik.isValid) {
      displayNotification("Para continuar, completá los campos");
    }
    formik.handleSubmit();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff !important",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          padding: "2rem 3rem",
          fontFamily: "Poppins, sans-serif !important",
        },
      }}
      fullWidth
    >
      <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "1.5rem" }}>
        {isAdding ? "Agregar sector" : "Editar sector"}
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "#818181", fontSize: "0.8rem", marginBottom: "2em" }}
      >
        Estos datos serán visibles en tu perfil público
      </Typography>
      <TextField
        id="name"
        label="Nombre del sector"
        name="name"
        variant="outlined"
        fullWidth
        autoFocus
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{ marginBottom: "1em" }}
      />
      <FormControl fullWidth sx={{ marginBottom: "1em" }}>
        <InputLabel id="type">Tipo de sector</InputLabel>
        <Select
          id="type"
          label="Tipo de sector"
          name="type"
          variant="outlined"
          fullWidth
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          renderValue={(selected) => sectorTypes[selected]}
        >
          {Object.keys(sectorTypes).map((key, idx) => (
            <MenuItem
              key={key}
              value={parseInt(key)}
              sx={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {idx > 0 && (
                <Divider sx={{ borderColor: "#D1D1D1", width: "100%" }} />
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
                padding={"0.5rem 1rem"}
                sx={{
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1em",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                  }}
                >
                  {sectorTypes[key]}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          error={formik.touched.type && Boolean(formik.errors.type)}
        >
          {formik.touched.type && formik.errors.type}
        </FormHelperText>
      </FormControl>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        position={"relative"}
        marginBottom={"1em"}
      >
        <TextField
          id="description"
          label="Descripción del sector (Opcional)"
          name="description"
          variant="outlined"
          fullWidth
          multiline
          rows={6}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
        <Typography
          variant="body2"
          sx={{
            color:
              formik.values.description.length > 200 ? "#f44336" : "#BDBDBD",
            position: "absolute",
            bottom: "1rem",
            right: "1rem",
          }}
        >
          {formik.values.description.length}/200
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        width={"100%"}
        gap={"1em"}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
          sx={{
            borderRadius: "8px",
            fontWeight: 600,
            "&:hover": {
              color: "#fff",
            },
          }}
        >
          Cancelar
        </Button>
        {isAdding ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitClick}
            sx={{
              borderRadius: "8px",
              fontWeight: 600,
              boxShadow: "none",
              "&:hover": {
                color: "#fff",
                background: theme.palette.primary.dark,
              },
            }}
          >
            Agregar
          </Button>
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmitClick}
            loading={loading}
            disabled={loading}
            sx={{
              borderRadius: "8px",
              fontWeight: 600,
              boxShadow: "none",
              "&:hover": {
                color: "#fff",
                background: theme.palette.primary.dark,
              },
            }}
          >
            Guardar
          </LoadingButton>
        )}
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.v3.sectorsLayoutsTables.loading,
    sectors: state.v3.sectorsLayoutsTables.sectors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewSector: (sector) => dispatch(addNewSector(sector)),
    updateSector: (sector) => dispatch(updateSector(sector)),
    displayNotification: (message, severity) => dispatch(displayNotification(message, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditSector);
