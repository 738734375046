import {combineReducers} from 'redux';
import categoriesReducer from './categoriesReducer';
import productsReducer from './productsReducer';
import webReducer from './webReducer'

export default combineReducers({
    categoriesReducer,
    productsReducer,
    webReducer,
});
