export const dividerStyle = {
  marginBottom: '30px',
  marginTop: '30px',
  width: '100%',
  '@media( min-width: 1350px )': {
    width: '70%',
  }
};

export const selectStyles = {  
  letterSpacing: "0em !important",
  borderRadius: "9px !important",
  lineHeight: "27px !important",
  fontWeight: "500 !important",
  textAlign: "left !important",
  color: "#041C32 !important",  
  fontSize: "18px !important",
  height: "50px !important",
};

