import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux";
import { editBillingInfoDB, refreshEditBillingInfo } from "../../../actions/invoiceActions";

const validationSchema = yup.object({
  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email válido")
    .required("El email es obligatorio"),
  cuit: yup
    .string("Ingrese su CUIT")
    .required("El CUIT es obligatorio")
    .min(11, "El CUIT debe tener 11 caracteres")
    .max(11, "El CUIT debe tener 11 caracteres")
    .matches(/^[0-9]+$/, "El CUIT debe ser numérico"),
});

function ChangeBillingInfo(props) {
  const { open, setOpen } = props;

  const { billing_info, editBillingInfoProps } = props;

  const { editBillingInfo, refreshBillingInfo } = props;

  const formik = useFormik({
    initialValues: {
      email: billing_info.email,
      cuit: billing_info.cuit,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        const { email, cuit } = values;
        const newValues = {};
        if(email !== billing_info.email) newValues.email = email;
        if(cuit !== billing_info.cuit) newValues.cuit = cuit;
        editBillingInfo(newValues);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    formik.setValues({
      email: billing_info.email,
      cuit: billing_info.cuit,
    });
  }, [billing_info]);

  React.useEffect(() => {
    if (editBillingInfoProps.success) {
        handleClose();
        refreshBillingInfo();
    }
    }, [editBillingInfoProps]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ fontFamily: "Poppins !important" }}
    >
      <Box sx={{ background: "white", fontFamily: "Poppins !important" }}>
        <DialogTitle
          sx={{ fontFamily: "Poppins !important", color: "#041C32" }}
        >
          Editar datos de facturación
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontFamily: "Poppins !important", paddingBottom: "20px" }}
          >
            A continuación cargar o modificar los datos de email y CUIT.
          </DialogContentText>
          <form>
            <TextField
              margin="dense"
              id="email"
              label="Email de facturación"
              type="email"
              fullWidth
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ fontFamily: "Poppins !important" }}
            />
            <TextField
              margin="dense"
              id="cuit"
              label="CUIT"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.touched.cuit && Boolean(formik.errors.cuit)}
              helperText={formik.touched.cuit && formik.errors.cuit}
              sx={{ fontFamily: "Poppins !important" }}
              inputProps={{ maxLength: 11 }}
            />
          </form>
        </DialogContent>
        <Typography
          variant="body2"
          sx={{ padding: "0 20px 10px 20px", fontFamily: "Poppins !important" }}
        >
          Validaremos el nuevo CUIT con los servicios de AFIP y lo utilizaremos
          para futuras facturas
        </Typography>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              fontFamily: "Poppins !important",
              color: "#041C32",
              background: "#F5F5F5",
              borderRadius: "5px",
              "&:hover": {
                background: "#cfcfcf !important",
              },
            }}
          >
            Cancelar
          </Button>
          <LoadingButton
            onClick={formik.handleSubmit}
            variant="contained"
            loading={editBillingInfoProps.loading}
            disabled={editBillingInfoProps.loading}
            sx={{
              fontFamily: "Poppins !important",
              background: "#0B4762",
              color: "white",
              borderRadius: "5px",
              "&:hover": {
                background: "#041C32 !important",
              },
            }}
          >
            Guardar
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    editBillingInfoProps: state.invoiceReducer.editBillingInfo,
    billing_info: state.invoiceReducer.billing_info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editBillingInfo: (data) => dispatch(editBillingInfoDB(data)),
    refreshBillingInfo: () => dispatch(refreshEditBillingInfo()),
};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBillingInfo);
