import React, {useEffect, useRef} from 'react';

// Components
import {BlueSwitch} from '../../../../GeneralComponents/blueSwitch';

// Material Ui
import {Grid, makeStyles,} from '@material-ui/core';
import SmallFilterButton from '../../SmallFilterButton/SmallFilterButton';


const useStyles = makeStyles(() => ({
    Root: {
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '1rem',
        height: '130px',
        width: '260px',
    },
    TopTitle: {
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        gap: '25px',
    },
    Label: {
        fontWeight: '500',
        margin: '0px',
    },
    SwitchContainer: {
        marginTop: '-.3rem'
    },
    Text: {
        fontSize: '10px',
        color: '#848282',
        margin: '0px',
    },
    DropdownsContainer: {
        display: 'flex'
    },
    Dropdown: {
        backgroundColor: 'white',
        color: '#848282',
        height: '31px',
        width: '90px',
    }
}));

const ConfigSectors = (props) => {

    const {
        enable,
        label,
        sector,
    } = props

    const {
        onChangeEnable,
        onEdit,
    } = props

    const classes = useStyles();
    const elementRef = useRef();

    const getOptions = () => {
        let options = [];
        for (let i = 1; i <= 50; i++) {
            options.push({value: i, option: i})
        }
        return options;
    }

    useEffect(() => {
        if (label.length > 18) {
            const nameElement = elementRef.current;
            nameElement.innerHTML = nameElement.innerHTML.substring(0, 11);
            let addDot = document.createElement('span');
            addDot.innerHTML = "...";
            nameElement.appendChild(addDot);
        }
    }, [label]);

    return (
        <div className={classes.Root}>
            <div className={classes.TopTitle}>
                <h2
                    style={{
                        color: enable ? '#0B4762' : '#B9B9B9'
                    }}
                    className={classes.Label}
                    ref={elementRef}
                    title={label.length > 18 ? label : ''}
                >{label}</h2>
                <div className={classes.SwitchContainer}>
                    <BlueSwitch
                        checked={enable}
                        onChange={onChangeEnable}
                        color="default"
                    />
                </div>
            </div>
            <p className={classes.Text}>Rango de aceptación automática</p>
            <div className={classes.DropdownsContainer}>
                <div style={{marginTop: '5px', width: '50%'}}>
                    <Grid container>
                        <Grid
                            item
                            xs={3}
                            style={{display: 'flex', alignItems: 'center'}}
                        >
                            <p
                                style={{
                                    color: enable ? '#808080' : '#B9B9B9',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    margin: 'auto',
                                }}
                            >Mín. </p>
                        </Grid>
                        <Grid item xs={9}>
                            <div style={{width: '95px'}}>
                                <SmallFilterButton
                                    text={''}
                                    value={sector?.minAutomatic ?? ""}
                                    onChange={(e) => onEdit(sector.name, e.target.value, sector.maxAutomatic)}
                                    options={getOptions()}
                                    minWidth={'70%'}
                                    disabled={!enable}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div style={{marginTop: '5px', width: '50%'}}>
                    <Grid container>
                        <Grid
                            item
                            xs={3}
                            style={{display: 'flex', alignItems: 'center'}}
                        >
                            <p
                                style={{
                                    color: enable ? '#808080' : '#B9B9B9',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    margin: '0px',
                                }}
                            >Max. </p>
                        </Grid>
                        <Grid item xs={9}>
                            <div style={{width: '95px'}}>
                                <SmallFilterButton
                                    text={''}
                                    value={sector?.maxAutomatic ?? ""}
                                    onChange={(e) => onEdit(sector.name, sector.minAutomatic, e.target.value)}
                                    options={getOptions()}
                                    minWidth={'70%'}
                                    disabled={!enable}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={4}
                    style={{
                        marginTop: '10px',
                        paddingLeft: '15px',
                        marginLeft: '30px'
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={5}>

                        </Grid>
                        <Grid item xs={7} style={{paddingLeft: '28px'}}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ConfigSectors
