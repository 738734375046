import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
        fontSize: '12px',
        width: '100px',
        height: '25px',
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '25px',
        justifyContent: 'center',
    },
}))

const CategoryButton = (props) => {
    const classes = useStyles();

    const {title, isActive} = props;
    const {onClick} = props;

    return (
        <Paper className={classes.root}
               style={{backgroundColor: isActive ? 'black' : 'white', color: isActive ? 'white' : 'black'}}
               onClick={onClick}>
            <p style={{overflowX: 'hidden', overflowY: 'hidden'}}>{title}</p>
        </Paper>
    )
}

export default CategoryButton