import {PARTNERS, TOGGLE_CATEGORIES_DIALOG, TOGGLE_CATEGORIES_LOADING,} from "../../constants";

export const toggleCategoriesDialog = () => ({
  type: TOGGLE_CATEGORIES_DIALOG,
});
export const toggleCategoriesLoading = () => ({
  type: TOGGLE_CATEGORIES_LOADING,
});

export const createCategory = (name, order) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleCategoriesLoading());
    await firestoreReact
      .collection(PARTNERS)
      .doc( idPartnerSelected )
      .collection("menu")
      .doc()
      .set({ name: name, order: order, visibility: true });
    dispatch(toggleCategoriesLoading());
  };
};

export const changeVisibilityCategory = (id, visibility) => {
  return async  (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleCategoriesLoading());
    await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .collection("menu")
        .doc(id)
        .update({ visibility: visibility });
    dispatch(toggleCategoriesLoading());
  }
}

export const editCategory = (id, name) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    // dispatch(toggleCategoriesLoading());
    await firestoreReact
      .collection(PARTNERS)
      .doc( idPartnerSelected )
      .collection("menu")
      .doc(id)
      .update({ name: name });
    // dispatch(toggleCategoriesLoading());
  };
};

export const deleteCategory = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleCategoriesLoading());
    await firestoreReact
      .collection(PARTNERS)
      .doc( idPartnerSelected )
      .collection("menu")
      .doc(id)
      .delete();
    dispatch(toggleCategoriesLoading());
  };
};

export const sortCategories = (sortedList) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestoreReact = getFirestore();
    const { userAccountsReducer } = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;

    dispatch(toggleCategoriesLoading());
    let result = sortedList.map(async (value, index) => {
      await firestoreReact
        .collection(PARTNERS)
        .doc( idPartnerSelected )
        .collection("menu")
        .doc(value.id)
        .update({ order: value.rank });
    });

    await Promise.all(result);

    dispatch(toggleCategoriesLoading());
  };
};
