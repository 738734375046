import React from 'react';

// Material Ui
import { TextField } from '@mui/material';

// Styles
import { OutlinedTextareaStyles } from '../../Optional/Styles/OptionalStyles';

const PlanningTextFieldComponent = ({ value, setValue }) => {
    return (
        <TextField
            onChange={ ( e ) => setValue( e.target.value ) }
            placeholder='Motivo (Opcional)'
            sx={ OutlinedTextareaStyles } 
            value={ value } 
            multiline
            rows={4}
        />
    )
}

export default PlanningTextFieldComponent;