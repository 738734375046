import React from 'react';

const ClientCancellationIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_184_3254)">
                <path d="M9.49992 1.58333C7.40029 1.58333 5.38665 2.41741 3.90199 3.90207C2.41733 5.38673 1.58325 7.40037 1.58325 9.5C1.58325 11.5996 2.41733 13.6133 3.90199 15.0979C5.38665 16.5826 7.40029 17.4167 9.49992 17.4167C11.5995 17.4167 13.6132 16.5826 15.0978 15.0979C16.5825 13.6133 17.4166 11.5996 17.4166 9.5C17.4166 7.40037 16.5825 5.38673 15.0978 3.90207C13.6132 2.41741 11.5995 1.58333 9.49992 1.58333Z" stroke="#818181" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.875 7.125L7.125 11.875" stroke="#818181" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.125 7.125L11.875 11.875" stroke="#818181" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_184_3254">
                    <rect width="19" height="19" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ClientCancellationIcon;