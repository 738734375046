import React from 'react'
import {PHONE_NUMBER} from "../../../../constants/config";
import {connect} from "react-redux";
import {push} from "connected-react-router";

const WhatsApp = (props) => {

    const handleClick = () => {
        const url = `https://api.whatsapp.com/send/?phone=${PHONE_NUMBER}` + "&text&app_absent=0\""
        window.open(url, "_blank")
    }

    return (
        <div onClick={handleClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 145 145">
                <defs>
                    <linearGradient id="linear-gradient-wa" x1="0.518" y1="0.556" x2="0.5" y2="1"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#fff"/>
                        <stop offset="1" stopColor="#d0d0d0"/>
                    </linearGradient>
                    <filter id="Elipse_353" x="0" y="0" width="145" height="145" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="5" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g style={{cursor: 'pointer'}} id="Grupo_1706" data-name="Grupo 1706" transform="translate(5 5)">
                    <g transform="matrix(1, 0, 0, 1, -5, -5)" filter="url(#Elipse_353)">
                    <circle id="Elipse_353-2" data-name="Elipse 353" cx="57.5" cy="57.5" r="57.5" transform="translate(15 15)" fill="url(#linear-gradient-wa)"/>
                    </g>
                    <g id="Grupo_1707" data-name="Grupo 1707" transform="translate(-899.22 -307.007)">
                    <path id="Trazado_913" data-name="Trazado 913" d="M978.147,374.45l-7.6-4.489-.382-.2h-.124a4.1,4.1,0,0,0-4.037.83l-1.937,1.97c-1.878-.409-3.526-1.99-5.119-3.52-.353-.34-.7-.67-1.082-1.021a14.975,14.975,0,0,1-4.316-6.095l1.552-1.547a3.805,3.805,0,0,0,.74-4.365l-4.185-7.917a3.154,3.154,0,0,0-.7-.981,3.068,3.068,0,0,0-2.283-.884,3.145,3.145,0,0,0-2.211.963l-5.269,5.263a5.307,5.307,0,0,0-1.471,3.92,21.363,21.363,0,0,0,2.76,9.376A48.032,48.032,0,0,0,956.921,381.5a25.749,25.749,0,0,0,12.81,4.8c.072,0,.146,0,.218,0a5.3,5.3,0,0,0,3.67-1.489l4.811-4.775.459-.448a3.389,3.389,0,0,0,.517-.7A3.288,3.288,0,0,0,978.147,374.45Zm-1.352,3.1-5.22,5.182a2.259,2.259,0,0,1-1.648.659,22.8,22.8,0,0,1-11.319-4.275,45.155,45.155,0,0,1-13.586-14.81,18.447,18.447,0,0,1-2.377-8.078,2.347,2.347,0,0,1,.636-1.723l5.249-5.242.214-.11.288.232,4.192,7.93a.865.865,0,0,1-.182.985l-2.067,2.083a2.393,2.393,0,0,0-.481,1.4l0,.25.081.238a17.859,17.859,0,0,0,5.359,7.939c.324.3.654.614.99.936,1.786,1.716,3.808,3.657,6.464,4.255a2.542,2.542,0,0,0,2.218-.281l2.393-2.4a1.113,1.113,0,0,1,1.17-.171l7.536,4.435A.38.38,0,0,1,976.8,377.549Z" transform="translate(6.866 6.866)" fill="#3478f5"/>
                    <path id="Trazado_914" data-name="Trazado 914" d="M966.633,340.726a32.416,32.416,0,0,0-27.566,49.467l-4.309,16.935a1.464,1.464,0,0,0,1.417,1.824,1.5,1.5,0,0,0,.571-.117l15.545-6.628a32.413,32.413,0,1,0,14.342-61.481Zm0,61.9a29.576,29.576,0,0,1-13.613-3.322,1.446,1.446,0,0,0-1.248-.049l-13.451,5.733,3.735-14.681a1.463,1.463,0,0,0-.189-1.156,29.49,29.49,0,1,1,24.766,13.476Z" transform="translate(0 0)" fill="#3478f5"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        push: (url) => dispatch(push(url))
    }
}

export default connect(null, mapDispatchToProps)(WhatsApp)
