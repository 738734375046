import React, { useEffect } from 'react';

import PropTypes from "prop-types";

// Constants
import { ModalMode } from '../../../constants/modalMode';

// Material Ui
import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import './ErrorDialog.css';

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        minWidth: '350px',
        left: '50%',
        top: '50%',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        marginBottom: '1rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '400',
        fontSize: '.9rem',
        color: '#737171',
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '15px'
    },
    ProgChangeButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: 'white !important',
        width: '150px',
        '&:hover': {
            backgroundColor: '#0B4762 !important',
        }
    },
    list: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    listItem: {
        fontWeight: '400',
        fontSize: '.9rem',
        color: '#737171',
        margin: "0 0.5rem",
        textAlign: 'start',

    }
}));

const ErrorDialog = (props) => {

    const { open, title, body, subBody, type } = props;
    const classes = useStyles();
    const { onAccept } = props;

    useEffect(() => {
        if ( open ) {
            document.body.style.overflow = 'auto';
        };
    }, [ open ]);

    return (
        <Modal
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            slots={{ backdrop: Backdrop }}
            onClose={ () => onAccept() }
            open={ open }
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ open }>
                <Box className={classes.ModalContainer}>
                    <div className={classes.IconCross} onClick={() => onAccept()}>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <svg width="50" height="50" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z"
                                stroke="#EB252E" strokeWidth="5.25"/>
                            <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E"
                                strokeWidth="5.25" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <h1 className={classes.Title}>
                            {title}
                        </h1>
                        <h3 className={classes.Subtitle}>
                            {Array.isArray(body)
                                ?
                                <ul className="error-list">
                                    {body.map((item, index) => (
                                        <li key={index} className="error-list-item">{item}</li>
                                    ))}
                                </ul>
                                : body}
                        </h3>
                        <h3 className={classes.Subtitle}>
                            {subBody}
                        </h3>
                    </div>
                    <div className={classes.ButtonsComponents}>
                        <Button
                            className={classes.ProgChangeButton}
                            onClick={() => onAccept()}
                        >{type === ModalMode.ERROR ? 'Entendido' : 'OK'}</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

ErrorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired || PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    subBody: PropTypes.string,
}

export default ErrorDialog;
