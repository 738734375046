import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import CubiertosIcon from '../../SvgComponents/icons/IconsPrimary/CubiertosIcon';
import CurrentlySectorIcon from '../../SvgComponents/icons/IconsPrimary/CurrentlySectorIcon';
import MesaYaSwitch from '../../NewComponents/Switch with Icon/MesaYaSwitch';

const useStyles = makeStyles(() => ({
    root: {
        width: '210px',
        height: '120px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        padding: '8px 0px 8px 15px'
    },
    priority: {
        margin: '0px',
        fontSize: '11px',
        color: '#848282',
        display: 'inline'
    },
    numbers: {
        fontSize: '14px',
        color: '#0076FF',
        fontWeight: '800',
        margin: '-2px 2px 0px 0px',
    }
}));

const SectorsMesaYa = (props) => {

    const classes = useStyles();

    const {key, name, enable, priority, capacity, tables} = props;

    const {onEnableChange} = props

    return (
        <div key={key} className={classes.root}>
            <h3 style={{margin: '0px'}}>{name}</h3>
            <div style={{maring: '0px'}}>
                <Grid container>
                    <Grid item xs={4}>
                        <p className={classes.priority}>Prioridad: {priority}</p>
                    </Grid>
                    <Grid item xs={4} style={{paddingLeft: '5px'}}>
                        <div style={{display: 'inline'}}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <CubiertosIcon width={"15px"} height={"15px"}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className={classes.numbers}>{capacity}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{paddingLeft: '0px', paddingTop: '2px'}}>
                        <div style={{display: 'inline'}}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <CurrentlySectorIcon width={"20px"} height={"15px"}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className={classes.numbers}>{tables}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{width: '90px', margin: '5px 0px'}}>
                <MesaYaSwitch enable={enable} onEnableChange={onEnableChange}/>
            </div>
        </div>
    )
}

export default SectorsMesaYa;
