import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckOkIcon from "../../../SvgComponents/icons/ShiftIcons/CheckOkIcon";
import { ClickeableStep } from "./utils/wizardFunctions";

const ACTIVE_PROPS = {
  backgroundColor: "#E5F4FB !important",
  borderColor: "#041C32 !important",
  color: "#0B4762 !important",
};

const DEFAULT_PROPS = {
  borderColor: "#E7E7E7 !important",
  color: "#818181 !important",
  backgroundColor: null,
};


const WizardItem = ({isLastItem, active = false, text, Icon, stepsOk, step, onClick}) => {
  const styleProps = active ? { ...ACTIVE_PROPS } : { ...DEFAULT_PROPS };

  const stepOk = stepsOk[step-1];
  const fillColor = active ? "#0B4762" : "#818181";

  return (
    <Box display="inline-flex" alignItems="center" style={{backgroundColor: "#FCFCFC"}}>      
      <Button
        disabled={!stepOk}
        onClick={ClickeableStep(stepsOk, step) ? onClick : null}
        sx={{
          fontFamily: 'Poppins, sans-serif',
          borderRadius: '8.46316px',
          textTransform: 'none',
          border: '1px solid',
          padding: '12px 0px',
          fontStyle: 'normal',
          letterSpacing: '0',
          fontSize: '.8rem',
          fontWeight: '500',
          height: '2.2rem',
          width: '13rem',
          ...styleProps,
          ':hover': {
            backgroundColor: '#E5F4FB !important',
          },
        }}
        variant="outlined"
        startIcon={stepOk ? <CheckOkIcon fill={fillColor} /> : <Icon fill={fillColor} />}
      >
        { text }
      </Button>
      {!isLastItem && (
        <ChevronRightIcon
          style={{
            margin: "0 0 0 .5rem",
            color: "#818181",
          }}
        />
      )}
    </Box>
  );
};

export default WizardItem;
