export const sectorTypes = {
    0: 'SALON',
    1: 'VIP',
    2: 'PATIO',
    3: 'INTERIOR',
    4: 'CAVA',
    5: 'TERRAZA',
    6: 'BAR',
    7: 'VEREDA',
    8: 'DECK',
    9: 'LOUNGE',
    10: 'OTRO'
}

export const sectorTypeToString = (sectorType) => {
    if(Object.keys(sectorTypes).indexOf(sectorType.toString()) === -1) return 'OTRO';
    return sectorTypes[sectorType];
}

export const stringToSectorType = (sectorTypeString) => {
    const sectorType = Object.keys(sectorTypes).find(key => sectorTypes[key] === sectorTypeString);
    if(!sectorType) return Object.keys(sectorTypes).length - 1;
    return parseInt(sectorType);
}