import React, {useRef} from 'react';
import {connect} from 'react-redux';

// Constants
// Components
import LinkIcon from '../../SvgComponents/icons/LinkIcon/LinkIcon';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Modal} from '@mui/material';

// Icons
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import QRComponent from "../../Menu/ShareMenu/QRComponent";
import {useReactToPrint} from "react-to-print";

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '1.5rem 1.5rem 1rem 1.5rem',
        display: 'flex',
        width: '450px',
        left: '50%',
        top: '50%',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        marginBottom: '1rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
        margin: '0',
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '15px'
    },
    InfoContainer: {
        width: '100%'
    },
    ShareContainer: {
        alignItems: 'center',
        marginBottom: '1rem',
        display: 'flex',
        width: '100%',
        gap: '20px',
    },
    QRSection: {
        backgroundColor: '#F5F5F5',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '10px',
        cursor: 'pointer',
        height: '10rem',
        display: 'flex',
        width: '50%',
    },
    LinkSection: {
        backgroundColor: '#F5F5F5',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        height: '10rem',
        display: 'flex',
        width: '50%',
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.1rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    ShareType: {
        fontFamily: 'Poppins, sans-serif',
        transform: 'translateX(-50%)',
        position: 'absolute',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: '600',
        bottom: '-.5rem',
        left: '50%',
    }
}));

const ShareModal = (props) => {

    const {link} = props;
    const {onCopy} = props;
    const classes = useStyles();
    const {open, onCancel} = props;
    const componentRef = useRef();

    //Imprimir
    const printQr = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => {
            return JSON.stringify({
                size: "landscape"
            })
        }
    });

    const onShowQr = () => {
        printQr();
    }

    return (
        <Modal
            open={open}
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.ModalContainer}>
                <div className={classes.IconCross} onClick={onCancel}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className={classes.InfoContainer}>
                    <h1 className={classes.Title}>
                        Compartir perfil
                    </h1>
                    <div className={classes.ShareContainer}>
                        <div className={classes.QRSection} onClick={onShowQr}>
                            <div className={classes.QRContainer}>
                                <QrCode2OutlinedIcon
                                    sx={{
                                        transform: 'translateX(-50%)',
                                        position: 'absolute',
                                        color: '#0B4762',
                                        height: '8rem',
                                        width: '8rem',
                                        left: '50%',
                                        top: '4%',
                                    }}
                                />
                                <div style={{display: "none"}}>
                                    <QRComponent ref={componentRef} link={link}/>
                                </div>
                                <p className={classes.ShareType}>Qr</p>
                            </div>
                        </div>
                        <CopyToClipboard text={link} onCopy={onCopy}>
                            <div className={classes.LinkSection}>
                                <div
                                    style={{
                                        transform: 'translateX(-50%)',
                                        position: 'absolute',
                                        height: '8rem',
                                        width: '8rem',
                                        left: '50%',
                                        top: '17%',
                                    }}
                                >
                                    <LinkIcon width={'5rem'} height={'5rem'}/>
                                </div>
                                <p className={classes.ShareType}>Link</p>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={classes.ButtonsComponents}>
                    <Button
                        className={classes.ContinuesButton}
                        onClick={onCancel}
                    >Cerrar</Button>
                </div>
            </Box>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps, null)(ShareModal);
