import React from 'react'
import {Grid, Table, TableBody} from "@material-ui/core";
import InfoGral from "../GeneralInfo/Info General/InfoGral";
import AddButton from "../NewComponents/Buttons/Add/AddButton";
import {makeStyles} from "@material-ui/core/styles";
import Header from "./Components/Header";
import MenuRow from "./Components/MenuRow";
import {firebaseConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import {compose} from "redux";
import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import {
    addWebMenu,
    changeLinkWebMenu,
    changeNameWebMenu,
    deleteWebMenu,
    errorChange,
    submitChanges,
    toggleWebMenuActive
} from "../../actions";
import GralDialog from "../GeneralComponents/gralDialog";
import {BlueSwitch} from "../GeneralComponents/blueSwitch";

const useStyles = makeStyles(() => ({
    table: {
        position: 'relative',
        zIndex: '0'
    },
}))

const MenuWeb = (props) => {
    const classes = useStyles();

    const {menus, loading, haveChanges, error, isMenuActive} = props;

    const {
        addWebMenu,
        deleteMenu,
        changeName,
        changeLink,
        errorChange,
        submit,
        toggleWebMenuActive
    } = props;


    return (
        <Grid container style={{paddingLeft: '20px', paddingTop: '-60px'}}>
            <Grid item xs={12}>
                <div style={{width: '580px', marginLeft: '-20px'}}>
                    <InfoGral
                        firstLine={"Desde aquí podras cargar todas las categorias y platos"}
                        secondLine={"de tu menú, que los usuarios verán en la carta en tu perfil de Woki"}
                    />
                </div>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <div style={{display: 'flex'}}>
                        <p style={{fontWeight: 'bold'}}>Activar menu web</p>
                        <BlueSwitch checked={isMenuActive} onChange={toggleWebMenuActive} color="default"/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{textAlign: 'right', marginRight: '20px'}}>
                        <AddButton text={"link"} onClick={addWebMenu}/>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{marginTop: '30px'}}>
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        <Header/>
                        { menus ? Object.keys(menus)?.sort((a, b) => a.localeCompare(b)).map((key, index) =>
                            <MenuRow
                                key={key}
                                name={key}
                                link={menus[key]}
                                onChangeName={(value) => changeName({original: key, edited: value})}
                                onChangeLink={(value) => changeLink({original: key, newLink: value})}
                                onError={errorChange}
                                onDelete={() => deleteMenu(key)}
                            />
                        ) : null}
                    </TableBody>
                </Table>
            </Grid>
            {haveChanges ? <SaveChangesButton onClick={submit}/> : null}
            <GralDialog
                onAccept={() => errorChange("")}
                onAcceptsText={"OK!"}
                open={error !== ""}
                body={error}
                subBody={""}
            />
            <ProgressIndicator open={loading}/>
        </Grid>
    );
}


const mapStateToProps = (state) => {
    return {
        menus: state.menuReducer.webReducer.editReducer.menus,
        isMenuActive: state.menuReducer.webReducer.editReducer.isMenuActive,
        loading: state.menuReducer.webReducer.editReducer.loading,
        error: state.menuReducer.webReducer.editReducer.error,
        haveChanges: state.menuReducer.webReducer.editReducer.haveChanges,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addWebMenu: (data) => dispatch(addWebMenu(data)),
        deleteMenu: (data) => dispatch(deleteWebMenu(data)),
        changeName: (data) => dispatch(changeNameWebMenu(data)),
        changeLink: (data) => dispatch(changeLinkWebMenu(data)),
        toggleWebMenuActive: () => dispatch(toggleWebMenuActive()),


        errorChange: (data) => dispatch(errorChange(data)),
        submit: (data) => dispatch(submitChanges(data)),

    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firebaseConnect())(MenuWeb)
