// import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { searchTables } from "../../../../actions/V3";
import * as Yup from "yup";
import { Form, useFormik } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import { v4 as uuidv4 } from "uuid";

const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#041C32",
    marginTop: "15%",
  },
  boxAbsolute: ({ isBannerActive }) => ({
    background: "#fff !important",
    position: "fixed",
    padding: ".7rem",
    width: "20em",
    border: "none",
    height: "100%",
    zIndex: "10",
    right: "0",
    top: isBannerActive ? "16vh" : "8vh",
    textAlign: "center",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
}));

const capacityOptions = Array.from({ length: 20 }, (_, i) => i + 1);

const createValidationSchema = (tables) =>
  Yup.object().shape({
    alias: Yup.string()
      .max(4, "El máximo de caracteres es 4")
      .required("El nombre de la mesa es requerido")
      .test(
        "unique-alias",
        "Ya existe una mesa con ese nombre",
        function (alias, currentTable) {
          const existingTable = tables.find((table) => table.alias === alias);
          if (!existingTable) {
            return true;
          }
          if (currentTable && existingTable.id === currentTable.parent.id) {
            return true;
          }
          return false;
        }
      ),
    minCapacity: Yup.number()
      .min(1, "El mínimo de personas es 1")
      .max(20, "El máximo de personas es 20")
      .required("La capacidad mínima es requerida"),
    maxCapacity: Yup.number()
      .min(1, "El mínimo de personas es 1")
      .max(20, "El máximo de personas es 20")
      .required("La capacidad máxima es requerida"),
  });

const filterTableOptionsFromCurrentLayoutTables = (
  tableOptions,
  layoutTables
) => {
  return tableOptions.filter((table) => {
    const tableInLayout = layoutTables.find((t) => t.alias === table.alias);
    return !tableInLayout;
  });
};

const TableForm = (props) => {
  const classes = useStyles();

  const { open, onClose, table, onSave, onDelete } = props;

  const { searchTables } = props;

  const { sector, creatingSector, tables, sectors, layout } = props;

  const validationSchema = createValidationSchema(tables, table);

  const [tableOptions, setTableOptions] = useState([]);

  const autocompleteTables = useMemo(() => {
    const sectorNow = sectors.find((s) => s.id === sector.id);
    if (!sectorNow) {
      return true;
    }
    const layouts = sectorNow.layouts ?? [];
    const hasOneLayout = layouts.length === 1;
    const editingTheOnlyLayout = hasOneLayout && layout.id === layouts[0].id;
    return !(creatingSector || editingTheOnlyLayout);
  }, [creatingSector, sectors, sector, layout]);

  const formik = useFormik({
    initialValues: {
      id: table.id || uuidv4(),
      alias: table.alias || "",
      minCapacity: table.minCapacity || 2,
      maxCapacity: table.maxCapacity || 4,
      userCanBook: table.userCanBook || true,
      partnerCanBook: table.partnerCanBook || false,
      backup: table.backup || false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.alias = values.alias.trim();
      onSave(values);
    },
  });

  const debouncedFetchOptions = useCallback(
    debounce((searchTerm) => searchTables(searchTerm, sector), 300),
    []
  );

  const handleTableInputChange = (event, newTableValue) => {
    if(typeof newTableValue === 'string' || newTableValue === '' || newTableValue === null) {
      formik.setFieldValue("alias", newTableValue ?? "");
      if (
        tables.find(
          (t) => t.alias === newTableValue && t.alias !== table.alias
        ) !== undefined
      ) {
        formik.setFieldError("alias", "Ya existe una mesa con ese nombre");
        return;
      }
    } else{
      formik.setFieldValue("alias", newTableValue.alias);
      formik.setFieldValue("minCapacity", newTableValue.minCapacity);
      formik.setFieldValue("maxCapacity", newTableValue.maxCapacity);
      formik.setFieldValue("userCanBook", newTableValue.userCanBook);
      formik.setFieldValue("partnerCanBook", newTableValue.partnerCanBook);
      formik.setFieldValue("backup", newTableValue.backup);
      if (
        tables.find(
          (t) => t.alias === newTableValue.alias && t.alias !== table.alias
        ) !== undefined
      ) {
        formik.setFieldError("alias", "Ya existe una mesa con ese nombre");
        return;
      }
    }
  };

  const handleOpen = async () => {
    if (!autocompleteTables) return;
    try {
      let tablesBack = await debouncedFetchOptions(formik.values.alias);
      if (!Array.isArray(tablesBack)) {
        tablesBack = [];
      }
      setTableOptions(
        filterTableOptionsFromCurrentLayoutTables(tablesBack, tables)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (table) {
      formik.setFieldValue("alias", table.alias);
      formik.setFieldValue("minCapacity", table.minCapacity);
      formik.setFieldValue("maxCapacity", table.maxCapacity);
      formik.setFieldValue("userCanBook", table.userCanBook);
      formik.setFieldValue("partnerCanBook", table.partnerCanBook);
      formik.setFieldValue("backup", table.backup);
    }
  }, [table]);

  const handleRadioButtonChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("userCanBook", false);
    formik.setFieldValue("partnerCanBook", false);
    formik.setFieldValue("backup", false);
    formik.setFieldValue(value, true);
  };

  useEffect(() => {
    async function fetchOptions() {
      if (!autocompleteTables) return;
      let tablesBack = await debouncedFetchOptions(formik.values.alias);
      if (!Array.isArray(tablesBack)) {
        tablesBack = [];
      }
      setTableOptions(
        filterTableOptionsFromCurrentLayoutTables(tablesBack, layout.tables)
      );
    }
    fetchOptions();
  }, [formik.values.alias]);

  return (
    <Backdrop
      sx={{ color: "#A7A7A7", zIndex: (theme) => theme.zIndex.drawer }}
      open={open}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div className={classes.boxAbsolute}>
          <h1 className={classes.title}>Edición de mesa</h1>
          <Box
            sx={{
              width: "90%",
              maxWidth: 300,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1em",
            }}
          >
            {autocompleteTables ? (
              <FormControl fullWidth>
                <Autocomplete
                  options={tableOptions}
                  getOptionLabel={(option) => option.alias ?? option}
                  onInputChange={handleTableInputChange}
                  onChange={handleTableInputChange}
                  onOpen={handleOpen}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Nombre de la mesa"
                    />
                  )}
                  value={formik.values.alias}
                  freeSolo
                  fullWidth
                  size="small"
                />
                {formik.touched.alias && formik.errors.alias && (
                  <FormHelperText error sx={{ color: "#f44336 !important" }}>
                    {formik.errors.alias}
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <TextField
                  id="alias"
                  name="alias"
                  label="Nombre de la mesa"
                  variant="outlined"
                  size="small"
                  value={formik.values.alias}
                  onChange={formik.handleChange}
                />
                {formik.touched.alias && formik.errors.alias && (
                  <FormHelperText error sx={{ color: "#f44336 !important" }}>
                    {formik.errors.alias}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                gap: "1em",
              }}
            >
              <FormControl fullWidth>
                <Select
                  id="minCapacity"
                  placeholder="Min"
                  name="minCapacity"
                  variant="outlined"
                  fullWidth
                  value={formik.values.minCapacity}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.minCapacity && formik.errors.minCapacity
                  }
                  size="small"
                  renderValue={(selected) => selected}
                  MenuProps={{
                    disablePortal: true,
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                      },
                    },
                  }}
                >
                  {Object.values(capacityOptions).map((key, idx) => (
                    <MenuItem
                      key={key}
                      value={parseInt(key)}
                      sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {idx > 0 && (
                        <Divider
                          sx={{ borderColor: "#D1D1D1", width: "100%" }}
                        />
                      )}
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                        padding={"0.5rem 1rem"}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "1em",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                          }}
                        >
                          {key}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={
                    formik.touched.minCapacity && formik.errors.minCapacity
                  }
                >
                  {formik.touched.minCapacity && formik.errors.minCapacity}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <Select
                  id="maxCapacity"
                  name="maxCapacity"
                  placeholder="Max"
                  variant="outlined"
                  fullWidth
                  value={formik.values.maxCapacity}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.maxCapacity && formik.errors.maxCapacity
                  }
                  size="small"
                  renderValue={(selected) => selected}
                  MenuProps={{
                    disablePortal: true,
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                      },
                    },
                  }}
                >
                  {Object.values(capacityOptions).map((key, idx) => (
                    <MenuItem
                      key={key}
                      value={parseInt(key)}
                      sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {idx > 0 && (
                        <Divider
                          sx={{ borderColor: "#D1D1D1", width: "100%" }}
                        />
                      )}
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                        padding={"0.5rem 1rem"}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "1em",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                          }}
                        >
                          {key}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={
                    formik.touched.minCapacity && formik.errors.minCapacity
                  }
                >
                  {formik.touched.minCapacity && formik.errors.minCapacity}
                </FormHelperText>
              </FormControl>
            </Box>
            <MuiAccordion
              sx={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
                width: "100%",
                padding: 0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontSize={"1.3em"}>Reservabilidad</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ width: "100%", padding: 0 }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={
                      formik.values.userCanBook
                        ? "userCanBook"
                        : formik.values.partnerCanBook
                        ? "partnerCanBook"
                        : "backup"
                    }
                    name="radio-buttons-group"
                    onChange={handleRadioButtonChange}
                    value={
                      formik.values.userCanBook
                        ? "userCanBook"
                        : formik.values.partnerCanBook
                        ? "partnerCanBook"
                        : "backup"
                    }
                  >
                    <FormControlLabel
                      value="userCanBook"
                      control={<Radio />}
                      label="Reservable cliente"
                    />
                    <FormControlLabel
                      value="partnerCanBook"
                      control={<Radio />}
                      label="Reservable partner"
                    />
                    <FormControlLabel
                      value="backup"
                      control={<Radio />}
                      label="No reservable (backup)"
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </MuiAccordion>
            <Button
              variant="contained"
              size="small"
              onClick={formik.handleSubmit}
              sx={{
                fontFamily: "Poppins",
                backgroundColor: "#0B4762",
                textTransform: "none",
                fontWeight: "600",
                color: "#FFFFFF",
                fontSize: "16px",
                padding: "0.5em",
                marginTop: "1em",
                "&:hover": {
                  backgroundColor: "#0f6389",
                },
              }}
              fullWidth
            >
              Continuar
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={onDelete}
              sx={{
                backgroundColor: "#FB3F4A",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "600",
                color: "#fff",
                fontSize: "16px",
                padding: "0.5em",
                "&:hover": {
                  backgroundColor: "#FB3F4A !important",
                },
              }}
              fullWidth
            >
              Eliminar mesa
            </Button>

            <ClearIcon
              fontSize="small"
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "1.5em",
                right: "0.5em",
                cursor: "pointer",
                color: "#0B4762",
              }}
            />
          </Box>
        </div>
      </ClickAwayListener>
    </Backdrop>
  );
};

TableForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    sector: state.v3.sectorsLayoutsTables.sector,
    sectors: state.v3.sectorsLayoutsTables.sectors,
    creatingSector: state.v3.sectorsLayoutsTables.creatingSector,
    tables: state.v3.sectorsLayoutsTables.layout.tables,
    layout: state.v3.sectorsLayoutsTables.layout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchTables: (searchTerm, sector) =>
      dispatch(searchTables(searchTerm, sector)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableForm);
