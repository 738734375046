import React from 'react';

// Material Ui
import { TextField } from '@mui/material';

// Styles
import { BPOutlinedTextAreaStyles } from '../Styles';

const BookingPoliticsTextArea = ({ setValue, value, placeholder, disabled = false }) => {
    return (
        <TextField
            onChange={ ( e ) => setValue( e.target.value ) }
            sx={ BPOutlinedTextAreaStyles } 
            placeholder={ placeholder }
            disabled={ disabled }
            value={ value } 
            multiline
            rows={4}
        />
    )
}

export default BookingPoliticsTextArea