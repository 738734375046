import React, {useEffect} from "react";
import {connect} from "react-redux";

// Actions
import {changeSectorMode, loadSectors,} from "../../actions";

// Components
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";

// Constants
import {PARTNERS, SECTOR_MODE} from "../../constants";

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import SectorForm from "./Components/SectorForm";
import SectorView from "./Components/SectorView";

const useStyles = makeStyles(() => ({
    mainContainer: {
        fontFamily: 'Poppins, sans-serif',
        paddingRight: '5rem',
        height: '90vh',
    },

}));

function SectorsForm(props) {
    const classes = useStyles();

    // Variables
    const {
        loading,
        partner,
        mode,
    } = props;

    // Actions
    const {loadSectors} = props;


    useEffect(() => {
        if (partner !== undefined) {
            loadSectors(partner);
        }
    }, [partner]);


    return (
        <section className={classes.mainContainer}>
            {mode === SECTOR_MODE.VIEW
                ? <SectorView/>
                : <SectorForm/>
            }
            <ProgressIndicator open={loading}/>
        </section>
    );
}

const mapStateToProps = (state) => {
    return {
        partner: state.firestore.ordered[PARTNERS],
        loading: state.sectorsReducer.sectors.loading,
        sectors: state.sectorsReducer.sectors.sectors,
        mode: state.sectorsReducer.sectors.mode,
        edited: state.sectorsReducer.sectors.edited,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadSectors: () => dispatch(loadSectors()),
        changeSectorMode: ({mode, sector}) => dispatch(changeSectorMode({mode, sector})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorsForm);
