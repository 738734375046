import React from 'react'
import {Card, CardContent, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Circle from './Circle';
import Help from "../../SvgComponents/Icons Tables/Help";

const useStyle = makeStyles(() => ({
    root: {
        background: 'linear-gradient(0deg, #4E00CC10, #915AFF10)',
        border: 0,
        borderRadius: 20,
        boxShadow: '0 3px 6px 2px rgba(0, 0, 0, .16)',
        fontFamily: 'Montserrat',
        width: '350px'
    },
}));

const DelayCard = (props) => {
    const classes = useStyle();
    const {delay, realAverageDelay} = props;

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={3} direction={'column'}>
                    <Grid item xs={12} style={{textAlign: 'left'}}>
                        <div style={{position: 'relative', height: '60px'}}>
                            <div style={{position: 'absolute', marginTop: '-20px'}}>
                                <h3 style={{fontWeight: '800'}}>
                                    Demora
                                </h3>
                            </div>
                            <div style={{marginLeft: '-20px', position: 'absolute', zIndex: 100, marginTop: '-15px'}}>
                                <Help texts={
                                    [
                                        "En este panel puedes ver la diferencia que hay entre la demora",
                                        "promedio que sueles ofrecer y la demora real promedio que ",
                                        "experimentan tus clientes con la función Mesa Ya!"
                                    ]
                                }/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Circle
                            delay={delay}
                            realAverageDelay={realAverageDelay}
                        />
                    </Grid>
                    <Grid item style={{textAlign: 'center'}}/>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default DelayCard;