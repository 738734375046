import React, {useState,} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Grid, IconButton, Link, Typography,} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import appStoresImage from '../../assets/app-icon.png';

import {appStoreUrl, googlePlayUrl,} from '../../constants';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 120,
        width: '100%',
        background: grey[900],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    },
    mainContainerHidden: {
        display: 'none',
    },
    closeContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '80%',
        height: 'auto',
        maxWidth: 100,
        maxHeight: 100,
        borderRadius: 20,
    },
    linkContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContainer: {
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleText: {
        fontSize: 18,
        color: 'white',
    },
    subtitleText: {
        fontSize: 14,
        color: grey[400],
    },
    closeIcon: {
        color: grey[400],
    },
}));


function StoresBanner(props) {
    const classes = useStyles();

    const preventDefault = (event) => event.preventDefault();

    const [banner, setBanner] = useState(true);

    const isIOS = () => {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    return (
        <Grid container className={banner ? classes.mainContainer : classes.mainContainerHidden}>
            <Grid item xs={1} className={classes.closeContainer}>
                <IconButton onClick={() => setBanner(false)}>
                    <CloseIcon className={classes.closeIcon}/>
                </IconButton>
            </Grid>
            <Grid item xs={3} className={classes.imageContainer}>
                <img alt={"img"} src={appStoresImage} className={classes.image} />
            </Grid>
            <Grid item xs={6} className={classes.textContainer}>
                <Typography className={classes.titleText} variant="h5" component="h5">Woki: Restaurantes digitales</Typography>
                <Typography className={classes.subtitleText} variant="subtitle1" component="h2">Woki SRL</Typography>
                <Typography className={classes.subtitleText} variant="subtitle1" component="h2">OBTENER - { isIOS() ? "En App Store" : "En Google Play" }</Typography>
            </Grid>
            <Grid item xs={2} className={classes.linkContainer}>
                <Typography>
                    <Link href={ isIOS() ? appStoreUrl : googlePlayUrl } onClick={preventDefault}>Ver</Link>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default StoresBanner;
