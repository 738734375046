import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {Grid} from "@material-ui/core";
import ClientsTable from "../GeneralComponents/ClientsTable/ClientsTable";

import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {QUEUE_ITEMS} from "../../constants";
import {changeFilterDate, changeFilters,} from "../../actions/myClientsActions";
import {DYNAMIC_LINK, STREET, WOKI} from "../../constants/providence";
import {ARRIVED, DELETED_BY_PARTNER, DELETED_BY_USER} from "../../constants/queueItemsState";
import DateSelector from "../ClientsGeneral/dateSelector";
import GraphicTimeCard from "../GeneralComponents/graphicTimeCard";
import ClientsCard from "../GeneralComponents/ClientsCard";
import DelayCard from "./DelayCard/DelayCard";
import {calculateAverageDelay, calculateResponseTimeStatistic, calculateTotalClients} from "../../utils/calculateTimes";
import Ingresado from "../GeneralComponents/ClientsTable/Ingresado";
import Cancelado from "../GeneralComponents/ClientsTable/Cancelado";
import Eliminado from "../GeneralComponents/ClientsTable/Eliminado";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        fontFamily: 'Montserrat',
    },
}));

function MesaYaClients(props) {
    const classes = useStyles();

    const {
        date,
        queueItems,
        filterQueueItems,
        search,
        isFiltering,
        isSearching,
        actualQueueItems,
        prevQueueItems
    } = props;
    const {changeFilterDate, changeFilters} = props;

    const filterStateOptions = [
        ARRIVED,
        DELETED_BY_PARTNER,
        DELETED_BY_USER
    ];

    const filterOriginOptions = [
        WOKI,
        DYNAMIC_LINK,
        STREET
    ]

    const getFinalState = (item) => {
        if (item.arrivedTimestamp) {
            return (<Ingresado/>)
        } else if (item.deletedByUserTimestamp) {
            return (<Cancelado/>)
        } else {
            return (<Eliminado/>)
        }
    }

    return (
        <div className={classes.root}>
            <DateSelector isMY date={date} changeDate={(newDate) => changeFilterDate(newDate)}/>
            <div style={{width: '650px', marginTop: '-20px', marginBottom: '40px'}}>
                <Grid container>
                    <Grid item xs={4}>
                        <h1 style={{margin: '0px'}}>Estadísticas</h1>
                    </Grid>
                    <Grid item xs={8} style={{color: '#848282', fontWeight: '600'}}>
                        <p style={{margin: '0px'}}>Desde aquí podrás ver las mediciones del uso de la función</p>
                        <p style={{margin: '0px'}}>Mesa Ya! en tu establecimiento</p>
                    </Grid>
                </Grid>
            </div>
            <Grid container direction={"row"} spacing={4}>
                <Grid item>
                    <DelayCard {...{...calculateAverageDelay(actualQueueItems), id: "delay-card-mesa-ya"}}/>
                </Grid>
                <Grid item>
                    <ClientsCard {...{
                        ...calculateTotalClients(actualQueueItems, prevQueueItems, true),
                        title: "Clientes",
                        subtitle: "Cantidad de usuarios",
                        helperText: [
                            "En este panel puedes ver la cantidad de clientes que ingresaron a tu",
                            "establecimientos a través de la función Mesa Ya!"
                        ]
                    }}/>
                </Grid>
                <br/>
                <Grid item>
                    <GraphicTimeCard {...{
                        ...calculateResponseTimeStatistic(actualQueueItems, prevQueueItems, true),
                        id: "responseTimeMY",
                        title: "Respuesta de solicitudes",
                        subtitle: "Tiempo promedio",
                        helperText: [
                            "En este panel puedes ver el tiempo de respuesta promedio",
                            "que tardas en responder una solicitud de Mesa Ya!",
                            "y que porcentaje aumentó o disminuyó"
                        ]
                    }}/>
                </Grid>
            </Grid>
            <br/>
            <br/>
            <ClientsTable
                items={isSearching || isFiltering ? filterQueueItems : queueItems}
                isQueueItem
                search={search}
                changeSearchAndFilter={(searchValue, filterValues) => changeFilters(searchValue, filterValues)}
                getFinalState={(item) => getFinalState(item)}
                tableDescription={"Desde aquí podrás ver la lista de clientes que utilizaron MesaYa! en tu establecimiento"}
                filterStateOptions={filterStateOptions}
                filterOriginOptions={filterOriginOptions}
            />

        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        date: state.myClientsReducer.date,
        queueItems: state.myClientsReducer.queueItems,
        filterQueueItems: state.myClientsReducer.filterQueueItems,
        search: state.myClientsReducer.search,
        isSearching: state.myClientsReducer.isSearching,
        isFiltering: state.myClientsReducer.isFiltering,
        profile: state.firebase.profile,

        actualQueueItems: state.myClientsReducer.actualQueueItems,
        prevQueueItems: state.myClientsReducer.prevQueueItems,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilterDate: (data) => dispatch(changeFilterDate(data)),
        changeFilters: (search, filters) => dispatch(changeFilters(search, filters)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MesaYaClients);
