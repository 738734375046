import React, { useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';

// Components
import OptionsComponents from './components/OptionsComponents';

// Layout
import CreateOptionalLayout from './layout/CreateOptionalLayout';

const OptionalForm = ( props ) => {

    // Actions
    const { 
        changePrimaryTitleAppBar,
        changeTitleAppBar,
        changeShowSidebar, 
    } = props;

    useEffect(() => {
        changePrimaryTitleAppBar("Creá una politica de cancelación y no shows para tus clientes");
        changeTitleAppBar("Agregar opcional");
        changeShowSidebar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CreateOptionalLayout>
            <OptionsComponents />
        </CreateOptionalLayout>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    };
};

export default connect( null, mapDispatchToProps )( OptionalForm );