import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  Root: {
    width: "100%",
  },
  RootInput: {
    backgroundColor: "white",
    borderRadius: "8px",
    paddingTop: "8px",
  },
  name: {
    fontFamily: "Poppins, sans-serif",
    margin: "0px 0px 2px 20px",
    fontSize: "12px",
    color: "#848282",
  },
  characters: {
    fontFamily: "Poppins, sans-serif",
    margin: "-4px 5px 0px 0px",
    fontSize: "12px",
    color: "#848282",
  },
  TextAreaStyles: {
    fontFamily: 'Poppins, sans-serif',
    border: '1px solid #D1D1D1',
    backgroundColor: 'white',
    borderRadius: '10px',
    overflow: 'hidden',
    fontSize: '1rem',
    padding: '.6rem',
    fontWeight: 400,
    outline: 'none',
    resize: 'none',
    height: '100%',
    width: '100%',
  },
}));

const TextArea = (props) => {
  const classes = useStyles();

  const {
    placeholder="",
    characters,
    tabIndex,
    disabled,
    subLabel,
    text,
  } = props;
  const { onChange, onFocus, onBlur } = props;
  return (
    <div className={classes.Root}>
      <div
        className={classes.RootInput}
        style={{
          padding: "7px 5px 5px 5px",
          height: props.height,
          width: props.width,
          textAlign: "left",
        }}
      >
        <textarea
          className={classes.TextAreaStyles}
          placeholder={placeholder}
          tabIndex={tabIndex}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={text}
          type="text"
          rows="4"
        />
      </div>
      <div style={{ textAlign: "right" }}>
        <p className={classes.characters}>
          {subLabel
            ? subLabel
            : characters
            ? `Máximo ${characters} caracteres`
            : ""}
        </p>
      </div>
    </div>
  );
};

export default TextArea;
