import React from 'react';

import { connect } from 'react-redux';

// Actions
import { setPartnerSelectedId } from '../../../actions';

// Material Ui
import { Box, Typography } from '@mui/material';

const ProfilesComponent = ( props ) => {

    // Actions
    const { setPartnerSelectedId } = props;

    // External props
    const { openSidebar, profiles } = props;

    // States
    const { idPartnerSelected } = props;

    const handleSetPartnerId = ( id ) => {
        setPartnerSelectedId({
            id: id
        });
    };

    return (
        <Box>
            <Typography 
                sx={{
                    p: '.5rem 0 .5rem .5rem',
                    fontFamily: 'Poppins',
                    fontSize: '0.75rem',
                    color: '#041C32',
                    fontWeight: 700,
                }}
            >
                { openSidebar ? 'PERFILES' : '' }
            </Typography>
            <Box 
                sx={{ 
                    flexDirection: 'column',
                    display: 'flex', 
                    gap: '5px', 
                }}
            >
                {
                    profiles.map( profile =>
                        profile.role === "admin" ?
                            <Box 
                                onClick={ () => handleSetPartnerId( profile.uuid ) }
                                key={ profile.name }
                                sx={{ 
                                    backgroundColor: idPartnerSelected === profile.uuid ? '#E3F2FD' : 'transparent',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#E3F2FD',
                                    } 
                                }} 
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        p: '.3rem 0 .3rem .5rem',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color:'#808080',
                                    }}
                                >{ profile.name }</Typography>
                            </Box> 
                        :
                            null
                    )
                }
            </Box>
        </Box>
    )
};

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPartnerSelectedId: ({ id }) => dispatch( setPartnerSelectedId({ id }) ),
    }
};

export default connect( mapStateToProps, mapDispatchToProps )( ProfilesComponent );