import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    rootInput: {
        backgroundColor: 'white',
        borderRadius: '10px',
        alignItems: 'center',
        paddingLeft: '10px',
        display: 'flex',
    },
    name: {
        fontFamily: 'Poppins, sans-serif',
        margin: '0px 0px 2px 20px',
        fontSize: '1rem',
        color: '#848282',
    },
    Input: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#FFFFFF',
        fontWeight: '500',
        fontSize: '14px',
        outline: 'none',
        border: 'none',
        margin: 'auto',
        width: '100%',
    }
}));

const SimpleInput = (props) => {

    const classes = useStyles();

    const {tabIndex, text, disabled, isAdmin} = props;
    const {onChange, onBlur, onFocus} = props;

    return (
        <div style={{width: '100%'}}>
            <div>
                <p className={classes.name}>{props.description}</p>
            </div>
            <div
                className={classes.rootInput}
                style={{
                    border: disabled ? '1px solid #FFFFFF' : '1px solid #041C32',
                    height: props.height,
                    width: props.width,
                    textAlign: 'left',
                }}
                >
                <input
                    tabIndex={tabIndex}
                    type='text'
                    disabled={disabled}
                    onChange={onChange}
                    value={text}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={classes.Input}
                    style={{
                        color: isAdmin ? '#808080' : '#041C32',
                    }}
                />
            </div>
        </div>
    )
}

export default SimpleInput
