import React from 'react';

const BookingTableIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7_2111)">
                <path d="M20.4532 9.44382L19.0652 4.59088C19.0088 4.38714 18.8871 4.20753 18.7188 4.07956C18.5505 3.95159 18.3449 3.88232 18.1335 3.88235H3.43875C3.00199 3.88235 2.62346 4.17353 2.50699 4.59088L1.11905 9.44382C0.94434 10.0553 1.41022 10.6765 2.05081 10.6765H4.1861L3.0214 19.4118H4.96258L5.61287 14.5588H15.969L16.6096 19.4118H18.5508L17.3861 10.6765H19.5214C20.162 10.6765 20.6279 10.0553 20.4532 9.44382ZM5.86522 12.6176L6.12728 10.6765H15.4449L15.707 12.6176H5.86522ZM3.34169 8.7353L4.1764 5.82353H17.4055L18.2402 8.7353H3.34169Z" fill="#818181"/>
            </g>
            <defs>
                <clipPath id="clip0_7_2111">
                    <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BookingTableIcon;