import React, {useEffect, useState} from 'react';

// Material Ui
import {alpha, styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import {IconButton} from '@mui/material';
import UseDebounce from "../../hooks/useDebounce";

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
    height: '2.6rem',
    border: '1px solid #DBD7DC'
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

const SearchBar = (props) => {

    const {onSearch} = props;

    const [values, setValues] = useState('');
    const debouncedSearch = UseDebounce(values, 800);

    const handleChange = (e) => {
        setValues(e.target.value)
    }

    useEffect(() => {
        onSearch(debouncedSearch);
    }, [debouncedSearch])

    return (
        <Search>
            <SearchIconWrapper>
                <IconButton>
                    <SearchIcon/>
                </IconButton>
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Buscar por nombre, telefono, email"
                inputProps={{'aria-label': 'search'}}
                onChange={(e) => handleChange(e)}
                value={values}
            />
        </Search>
    )
}

export default SearchBar;

