import React from 'react';

const LogoutIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6113 19.8886L25.0002 13.4997L18.6113 7.11084" stroke="#818181" strokeWidth="2.55556" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.66667 25H4.55556C3.87778 25 3.22776 24.7308 2.7485 24.2515C2.26925 23.7722 2 23.1222 2 22.4444V4.55556C2 3.87778 2.26925 3.22776 2.7485 2.7485C3.22776 2.26925 3.87778 2 4.55556 2H9.66667" stroke="#818181" strokeWidth="2.55556" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25 13.5H9.66663" stroke="#818181" strokeWidth="2.55556" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default LogoutIcon;