import React, { useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { 
  changeAdvanceEndRespectTo, 
  changeMaxBookingGroup, 
  changeMinBookingGroup, 
  changeAutoAceptance, 
  changeAdvanceStart, 
  setValidationError, 
  changeAdvanceEnd, 
  changeShiftName, 
} from '../../../../../actions/shiftsActions';

// Components
import BlueRadioButton from "../../../../GeneralComponents/BlueRadioButton";
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import ShiftTextField from '../components/General/ShiftTextField';

// Material Ui
import { Box, FormControlLabel, RadioGroup, Stack, Typography, Divider, Collapse } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Styles
import { DecriptionShiftStyles, RadioShiftTextStyles, RadiosShiftTitleStyles, SubtitleShiftStyles, TitleShiftStyles } from '../../../Styles';

// Utils
import { anticipoMaximo, anticipoMinimo, anticipoMinimoRespectoA, grupo, porcentajeAutomatico } from '../utils/constants';
import ShiftGeneralSelect from '../components/General/ShiftGeneralSelect';
import ShiftSimpleSelect from '../components/General/ShiftSimpleSelect';


// const useStyles = makeStyles((theme) => ({
//   divider: dividerStyle,
//   select: {
//     ...selectStyles,
//     width: '226px',    
//   },
//   radioButtons: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: theme.spacing(1),
//   },
//   switchContainer: {
//     display: 'flex',
//     alignItems: 'left',
//     marginLeft: '-20px',
//     paddingLeft: 0  
//   },
//   switchLabel: {
//     marginLeft: theme.spacing(1),
//   },
//   customSwitch: {
//     width: 60,
//     height: 36,
//     transform: 'scale(1.5)',
//   },
//   noMargin: {
//     margin: 0,
//     padding: 0,
//   },
//   noMarginTop: {
//     marginTop: "0 !Important",
//     paddingTop: "0 !Important"
//   },
//   accordion: {
//     borderBottom: 'none',
//     '&::before': {
//       display: 'none',
//     },
//   },
//   input: {
//     width: "100%",
//     borderRadius: "20px",
//     boxSizing: "border-box",
//     background: "#FFFFFF",
//     border: "1px solid #D1D1D1",

//     fontFamily: "Poppins",
//     fontStyle: "normal",
//     fontWeight: "400",
//     fontSize: "18px",
//     lineHeight: "27px",
//   },
//   legend: {
//     borderTop: "0 !Important",
//     paddingTop: "0 !Important",
//     fontSize: '0.8rem'
//   },
// }));

const Formulario = ( props ) => {

  // Variables
  const {
    autoAcceptancePercentage,
    advanceEndRespectTo,
    minBookingGroup,
    maxBookingGroup,
    validationError,
    advanceStart,
    advanceEnd,
    name,
  } = props;

  // Acciones
  const {
    changeAdvanceEndRespectTo,
    changeMinBookingGroup,
    changeMaxBookingGroup,
    changeAutoAceptance,
    changeAdvanceStart,
    setValidationError,
    changeAdvanceEnd,
    changeShiftName,
  } = props;

  const handleChangeShiftName = ( e ) => {
    if ( e.target.value.length <= 15 ) {
      changeShiftName( e.target.value );
      setValidationError( '' );
    };
  };

  const handleSwitchChange = () => {
    setSwitchEnabled(!switchEnabled);
    if (switchEnabled) {
      changeAutoAceptance(100);
    }
  };
  
  const [switchEnabled, setSwitchEnabled] = useState(autoAcceptancePercentage === 100);
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);
  
  const handleAdvancedOptionsClick = () => {
    setAdvancedOptionsOpen(!advancedOptionsOpen);
  };

  return (
  <Box sx={{ marginBottom: '50px', maxWidth: '45rem' }}>
    <Box>
      <Typography sx={ TitleShiftStyles }>Configuración básica de un turno</Typography>
      <ShiftTextField 
        error={ validationError === 'error:name' }
        handleChange={ handleChangeShiftName }
        helperText={ 'Máx caracteres: 15' }
        placeholder='Nombre del turno*'
        value={ name }
      />
    </Box>
    <Divider sx={{ margin: '1.2rem 0' }} />
    <Box>
      <Typography sx={ SubtitleShiftStyles } >Configuración de Anticipo para Reservas</Typography>
      <Typography sx={ DecriptionShiftStyles } >
        Define el tiempo mínimo y máximo de antelación con el que los clientes pueden realizar reservas
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '1.5rem', width: '80%' }}>
        <ShiftGeneralSelect
          handleChange={ changeAdvanceEnd } 
          optionsArray={ anticipoMinimo } 
          label={ 'Anticipo mínimo' }
          value={ advanceEnd }
        />

        <ShiftGeneralSelect
          handleChange={ changeAdvanceStart } 
          optionsArray={ anticipoMaximo } 
          label={ 'Anticipo máximo' }
          value={ advanceStart }
        />
      </Box>
      <Box sx={{ mt: '1rem' }}>
        <Typography sx={ RadiosShiftTitleStyles }>Anticipo mínimo respecto a:</Typography>
        <RadioGroup
          onChange={(e) => changeAdvanceEndRespectTo(parseInt(e.target.value))}
          defaultValue={advanceEndRespectTo}
          name="radio-buttons-group"
        >
          <Stack direction="row" spacing={2}>
            {
              anticipoMinimoRespectoA.map(({ label, value }) => (
                <FormControlLabel
                  control={ <BlueRadioButton /> }
                  value={ value }
                  key={value}
                  label={
                      <Typography sx={ RadioShiftTextStyles }>
                          { label }
                      </Typography>
                  }
                />
            ))}
          </Stack>
        </RadioGroup>
      </Box>
    </Box>
    <Divider sx={{ margin: '1.2rem 0' }} />
    <Box>
      <Typography sx={ SubtitleShiftStyles } >Configuración del Tamaño de Grupo Reservable</Typography>
      <Typography sx={ DecriptionShiftStyles } >
        Establece el tamaño mínimo y máximo de grupos que pueden realizar reservas
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '1.5rem', width: '80%' }}>
        <ShiftGeneralSelect
          handleChange={ changeMinBookingGroup } 
          value={ minBookingGroup }
          label={ 'Grupo mínimo' }
          optionsArray={ grupo } 
        />

        <ShiftGeneralSelect
          handleChange={ changeMaxBookingGroup } 
          value={ maxBookingGroup }
          label={ 'Grupo máximo' }
          optionsArray={ grupo } 
        />
      </Box>
    </Box>
    <Box>
      <Box
        onClick={ () => handleAdvancedOptionsClick() }
        sx={{ 
            textDecorationLine: 'underline',
            alignItems: 'center',
            width: 'max-content',
            cursor: 'pointer', 
            display: 'flex', 
            m: '1rem 0 .2rem 0',
            gap: '5px',
        }}
      >
        <Typography
            sx={{ 
                textDecorationLine: 'underline', 
                fontFamily: 'Poppins',
                fontSize: '16px',
                color: '#0B4762',
                fontWeight: 500,
                m: 0
            }}
            my={3}
        >
          Configuración avanzada
        </Typography>
        {
            advancedOptionsOpen ? (
                <ExpandLessIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
            ) : (
                <ExpandMoreIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
            )
        }
      </Box>
    </Box>
    <Collapse in={ advancedOptionsOpen } >
      <Box 
        sx={{ 
          justifyContent: 'space-between',
          width: 'max-content', 
          alignItems: 'center', 
          display: 'flex',
          ml: '-.6rem',
          mb: '1rem'
        }}
      >
        <BlueSwitch
          onChange={() => handleSwitchChange()}
          checked={ switchEnabled }
          color="default"
        />
        <Typography 
          sx={{ 
            fontFamily: 'Poppins',
            fontSize: '16px',
            color: '#041C32', 
            fontWeight: 500,
            mb: '-.5rem'
          }}
        >Sistema de aceptación automática total</Typography>
      </Box>
      <Collapse in={ !switchEnabled }>
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'max-content', gap: '10px' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              flexWrap: 'nowrap',
              fontSize: '1rem',
              color: '#041C32',
              fontWeight: 500,
            }}
          >
            Porcentaje automático
          </Typography>
          <ShiftSimpleSelect
            optionsArray={ porcentajeAutomatico }
            handleChange={ changeAutoAceptance } 
            value={ autoAcceptancePercentage }
            type='percentage'
          />
          {/* <Select
            value={autoAcceptancePercentage}
            label=""
            onChange={(e) => changeAutoAceptance(e.target.value)}
            sx={{
              width: "5rem",
              borderRadius: "10px",
              height: "2.6rem",
              minWidth: 100,
            }}
            inputProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                color: "#041C32",
              },
            }}
            MenuProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                maxHeight: 200,
              },
            }}
            className={classes.select}
          >
            {
              porcentajeAutomatico.map((option) => (
                <MenuItem key={option.value} value={option.value}>{`${option.value} %`}</MenuItem>
              ))
            }
          </Select> */}
        </Box>
      </Collapse>
    </Collapse>
      
      {/* <Grid container spacing={2} pt={5}>          
        <Grid item xs={12} style={{ marginTop: "38px" }}>
          <Grid container>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => handleAdvancedOptionsClick()}
            >
              <Typography
                sx={{
                  textDecorationLine: 'underline', 
                  fontFamily: 'Poppins', 
                  fontSize: '18px', 
                  color: '#0B4762', 
                  fontWeight: 500, 
                }}
              >
                Configuración avanzada
              </Typography>
              {advancedOptionsOpen ? (
                <ExpandLessIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
              )}
            </Stack>
          </Grid>
          {advancedOptionsOpen && (          
              <>
                <Grid container spacing={3} style={{ marginTop: "34px" }}>
                  <Grid item xs={12} sm={12} style={{padding: 0}}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box width="500px" display="flex" flexDirection="row" alignItems="center">
                        <BlueSwitch
                          checked={switchEnabled}
                          onChange={() => handleSwitchChange()}
                          color="default"
                        />
                        <Subtitle
                          style={{ paddingTop: "12px", paddingBottom: "4px" }}
                          text="Sistema de aceptación automática total"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "24px" }}>
                  {!switchEnabled && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box>
                          <Typography
                            fontSize="1rem"
                            color="#041C32"
                            fontWeight="500"
                            fontFamily="Poppins"
                          >
                            Porcentaje automático
                          </Typography>
                        </Box>
                        <Select
                          value={autoAcceptancePercentage}
                          label=""
                          onChange={(e) => changeAutoAceptance(e.target.value)}
                          sx={{
                            width: "5rem",
                            borderRadius: "10px",
                            height: "2.6rem",
                            minWidth: 100,
                          }}
                          inputProps={{
                            style: {
                              fontFamily: "Poppins, sans-serif",
                              color: "#041C32",
                            },
                          }}
                          MenuProps={{
                            style: {
                              fontFamily: "Poppins, sans-serif",
                              maxHeight: 200,
                            },
                          }}
                          className={classes.select}
                        >
                          {
                            porcentajeAutomatico.map((option) => (
                              <MenuItem key={option.value} value={option.value}>{`${option.value} %`}</MenuItem>
                            ))
                          }
                        </Select>
                    </Stack>
                  )}
                </Grid>
              </>
          )}
        </Grid>
      </Grid> */}
  </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    autoAcceptancePercentage: state.v3.shifts.shiftData.autoAcceptancePercentage,
    advanceEndRespectTo: state.v3.shifts.shiftData.advanceEndRespectTo,
    maxBookingGroup: state.v3.shifts.shiftData.maxBookingGroup,
    minBookingGroup: state.v3.shifts.shiftData.minBookingGroup,
    advanceStart: state.v3.shifts.shiftData.advanceStart,
    advanceEnd: state.v3.shifts.shiftData.advanceEnd,
    validationError: state.v3.shifts.validationError,      
    name: state.v3.shifts.shiftData.name,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAdvanceEndRespectTo: (data) => dispatch(changeAdvanceEndRespectTo(data)),      
    changeMinBookingGroup: (data) => dispatch(changeMinBookingGroup(data)),      
    changeMaxBookingGroup: (data) => dispatch(changeMaxBookingGroup(data)),      
    changeAutoAceptance: (data) => dispatch(changeAutoAceptance(data)),
    setValidationError: (data) => dispatch(setValidationError(data)),
    changeAdvanceStart: (data) => dispatch(changeAdvanceStart(data)),
    changeAdvanceEnd: (data) => dispatch(changeAdvanceEnd(data)),
    changeShiftName: (data) => dispatch(changeShiftName(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Formulario);
