import {deepEqual} from "./objectHelpers";

export const arrayEquals = (a, b) => {
  return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
  );
};

export const arrayOfObjectsEquals = (a, b) => {
  return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => deepEqual(val, b[index]))
  );
};

export const deepCopy = (arr) => {
  let copy = [];
  if (!arr) return;
  arr.forEach((elem) => {
    if (Array.isArray(elem)) {
      copy.push(deepCopy(elem));
    } else {
      if (typeof elem === "object") {
        copy.push(deepCopyObject(elem));
      } else {
        copy.push(elem);
      }
    }
  });
  return copy;
};

export const deepCopyObject = (obj) => {
  if (obj === undefined || obj === null) return undefined;
  let tempObj = {};
  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      tempObj[key] = deepCopy(value);
    } else {
      if (value instanceof File) {
        tempObj[key] = value;
      } else if (typeof value === "object") {
        tempObj[key] = deepCopyObject(value);
      } else {
        tempObj[key] = value;
      }
    }
  }
  return tempObj;
};

export const arrayObjectsEquals = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;

  return a.every((value, index) => {
    return deepEqual(value, b[index])
  })
}

export const arrayIncludesObject = (arr, obj) => {
    return arr.some((value) => {
        return deepEqual(value, obj)
    })
}
