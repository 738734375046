import axios from "axios";
import { 
    CHANGE_TURNO_MODE, CLOSE_TURNOS_ERROR, 
    SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL, 
    CHANGE_TURNO_MAINTAIN_BOOKINGS, 
    SET_GROUPS_ON_BOOKABLE_SECTORS,  
    SET_CLOSE_DELETE_TURNO_SUCCESS, 
    SET_ROTATION_CONTROL_BY_TABLE, 
    SET_ROTATION_CONTROL_BY_GROUP, 
    SET_WAITING_LIST_SECTORS_FULL, 
    SET_SHOW_EXIT_WITHOUT_SAVING, 
    CHANGE_TURNO_APPLY_FROM_DATE, 
    SET_ADVANCE_END_RESPECT_TO, 
    CHANGE_EXTRA_EXCLUDE_DATA,
    SET_DELETE_TURNO_SUCCESS, 
    CHANGE_SHIFTS_VIEW_MODE, 
    SET_AUTOMATIC_WAIT_LIST, 
    SET_DISABLED_ALL_LAYOUT,
    SET_WORK_WITH_WAIT_LIST, 
    SET_DWELL_TIME_BY_TABLE, 
    SET_RESERVABLE_SECTORS, 
    SET_NOT_BOOKABLE_GROUP,
    FETCH_SECTORS_SUCCESS, 
    SET_AVAILABLE_LAYOUTS, 
    SET_MAX_BOOKING_GROUP, 
    SET_MAX_WAITING_ITEMS, 
    SET_MIN_BOOKING_GROUP, 
    TOGGLE_TURNOS_LOADING, 
    CHANGE_BOOKABLE_GROUP,
    FETCH_TURNOS_SUCCESS, 
    SET_CALENDAR_CLOSING, 
    SET_CALENDAR_OPENING, 
    SET_DWELL_TIME_GROUP, 
    SET_ROTATION_CONTROL, 
    SET_VALIDATION_ERROR, 
    SET_SHOW_EDIT_MODAL, 
    SET_ADITIONAL_INFO, 
    SET_AUTO_ACEPTANCE, 
    SET_WAIT_LIST_NOTE, 
    CHANGE_SHIFT_NAME, 
    SET_ADVANCE_START, 
    SET_CLOSE_SAVE_OK, 
    SET_TURNOS_ERROR, 
    SHIFTS_VIEW_MODE, 
    SET_EXCLUDE_DATA,
    SET_ACTIVE_GROUP,
    SET_ADVANCE_END, 
    SET_EXCLUDE_DAY, 
    SET_SPECIAL_DAY, 
    SET_EXTRA_TABLE,
    SET_IS_SPECIAL, 
    SET_ALL_GROUPS,
    SET_EXTRA_DATA,
    SET_START_DAY, 
    UPDATE_TABLES,
    SET_OPTIONALS, 
    RESET_GROUPS,
    SET_SUBSHIFT, 
    RESET_TURNO, 
    SET_END_DAY, 
    SET_SAVE_OK, 
    SET_ENABLED, 
    SET_REPEAT, 
    SET_GROUP,
    SET_DAYS,
} from "../../constants/shiftsConstants";

import { GetMomentDate } from "../../components/V3/Shifts/Wizard/utils/functions";
import { deepCopyObject } from "../../utils/arrayHelpers";
import { ShiftMode } from "../../constants";
import {v4 as uuidv4} from 'uuid';
import moment from "moment";

export const changeShiftsViewMode = (viewMode) => ({
    type: CHANGE_SHIFTS_VIEW_MODE,
    payload: { viewMode },
});

export const changeTurnoMode = ({mode, turno}) => ({
    type: CHANGE_TURNO_MODE,
    payload: {mode, turno}
});

export const changeTurnoApplyFromDate = (endDate) => ({
    type: CHANGE_TURNO_APPLY_FROM_DATE,
    payload: endDate
});

export const changeTurnoMaintainBookings = (data) => ({
    type: CHANGE_TURNO_MAINTAIN_BOOKINGS,
    payload: data
});

export const showEditModal = (data) => ({
    type: SET_SHOW_EDIT_MODAL,
    payload: data
});

export const setDeleteSuccess = (data) => ({
    type: SET_DELETE_TURNO_SUCCESS,
    payload: data,
});

export const setCloseDeleteSuccess = () => ({
    type: SET_CLOSE_DELETE_TURNO_SUCCESS,
});

export const fetchTurnosSuccess = (data) => ({
    type: FETCH_TURNOS_SUCCESS,
    payload: data,
});

export const fetchSectorSuccess = (data) => ({
    type: FETCH_SECTORS_SUCCESS,
    payload: data,
});

export const setTurnosError = (data) => ({
    type: SET_TURNOS_ERROR,
    payload: data,
});

export const setValidationError = (data) => ({
    type: SET_VALIDATION_ERROR,
    payload: data,
});

export const toggleTurnosLoading = () => ({
    type: TOGGLE_TURNOS_LOADING,
});

export const closeTurnosError = () => ({
    type: CLOSE_TURNOS_ERROR,
});

export const resetTurnoData = () => ({
    type: RESET_TURNO,
});

export const setSaveOk = () => ({
    type: SET_SAVE_OK,
});

export const setCloseSaveOk = () => ({
    type: SET_CLOSE_SAVE_OK,
});

// General

export const changeEnabled = (data) => ({
    type: SET_ENABLED,
    payload: data,
});

export const changeShiftName = (data) => ({
    type: CHANGE_SHIFT_NAME,
    payload: data,
});

export const changeAdvanceStart = (data) => ({
    type: SET_ADVANCE_START,
    payload: data,
});

export const changeAdvanceEnd = (data) => ({
    type: SET_ADVANCE_END,
    payload: data,
});

export const changeAdvanceEndRespectTo = (data) => ({
    type: SET_ADVANCE_END_RESPECT_TO,
    payload: data,
});

export const changeMinBookingGroup = (data) => ({
    type: SET_MIN_BOOKING_GROUP,
    payload: data,
});

export const changeMaxBookingGroup = (data) => ({
    type: SET_MAX_BOOKING_GROUP,
    payload: data,
});

export const changeAutoAceptance = (data) => ({
    type: SET_AUTO_ACEPTANCE,
    payload: data,
});

// Schedule
export const changeIsSpecial = (data) => ({
    type: SET_IS_SPECIAL,
    payload: data,
});

export const changeOpening = (data) => ({
    type: SET_CALENDAR_OPENING,
    payload: data,
});

export const changeClosing = (data) => ({
    type: SET_CALENDAR_CLOSING,
    payload: data,
});

export const changeDays = (data) => ({
    type: SET_DAYS,
    payload: data,
});

export const changeStartDay = (data) => ({
    type: SET_START_DAY,
    payload: data,
});

export const changeEndDay = (data) => ({
    type: SET_END_DAY,
    payload: data,
});

export const changeSpecialDay = (data) => ({
    type: SET_SPECIAL_DAY,
    payload: data,
});

export const changeExcludeDay = (data) => ({
    type: SET_EXCLUDE_DAY,
    payload: data,
});

export const changeRepeat = (data) => ({
    type: SET_REPEAT,
    payload: data,
});

export const setSubshift = (data) => ({
    type: SET_SUBSHIFT,
    payload: data,
});

export const setAvailableLayouts = (data) => ({
    type: SET_AVAILABLE_LAYOUTS,
    payload: data,
});

export const setReservableSectors = (data) => ({
    type: SET_RESERVABLE_SECTORS,
    payload: data,
});

export const setWithWaitList = (data) => ({
    type: SET_WORK_WITH_WAIT_LIST,
    payload: data,
});

export const setAutomaticWaitList = (data) => ({
    type: SET_AUTOMATIC_WAIT_LIST,
    payload: data,
});

export const setWaitListNote = (data) => ({
    type: SET_WAIT_LIST_NOTE,
    payload: data,
});

export const setMaxWaitingItems = (data) => ({
    type: SET_MAX_WAITING_ITEMS,
    payload: data,
});

export const setWaitingListSectorsFull = (data) => ({
    type: SET_WAITING_LIST_SECTORS_FULL,
    payload: data,
});

export const setAditionalInfo = (data) => ({
    type: SET_ADITIONAL_INFO,
    payload: data,
});

export const setDwellTimeByGroup = (data) => ({
    type: SET_DWELL_TIME_GROUP,
    payload: data,
});

export const setDwellTimeByTable = (data) => ({
    type: SET_DWELL_TIME_BY_TABLE,
    payload: data,
});

export const setRotationControl = (data) => ({
    type: SET_ROTATION_CONTROL,
    payload: data,
});

export const setOptionals = (data) => ({
    type: SET_OPTIONALS,
    payload: data,
});

export const setRotationControlByGroup = (data) => ({
    type: SET_ROTATION_CONTROL_BY_GROUP,
    payload: data,
});

export const setRotationControlByTable = (data) => ({
    type: SET_ROTATION_CONTROL_BY_TABLE,
    payload: data,
});

export const setShowDeactivateWaitListModal = (data) => ({
    type: SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL,
    payload: data,
});

export const setResetGroups = () => ({
    type: RESET_GROUPS
});

export const setShowExitWithoutSaving = (data) => ({
    type: SET_SHOW_EXIT_WITHOUT_SAVING,
    payload: data,
});

export const setExcludeIds = ({ subshiftId, tableId, groupId, type }) => ({
    type: SET_EXCLUDE_DATA,
    payload: {
        subshiftId, 
        tableId, 
        groupId,
        type
    }
});

export const setExtraIds = ({ subshiftId, tableId, groupId, type, bookableId }) => ({
    type: SET_EXTRA_DATA,
    payload: {
        bookableId,
        subshiftId, 
        tableId, 
        groupId,
        type
    }
});

export const setChangeExtraExcludeData = ({ type, subshiftId, enabled, tableId, groupId, layouts }) => ({
    type: CHANGE_EXTRA_EXCLUDE_DATA,
    payload: { 
        subshiftId,
        layouts,
        enabled,
        tableId,
        groupId,
        type 
    }
});

export const setNotBookableGroup = ({ groupId, subshiftId, available }) => ({
    type: SET_NOT_BOOKABLE_GROUP,
    payload: {
        subshiftId,
        available,
        groupId,
    }
});

export const setAllGroups = ({ value, layout }) =>({
    type: SET_ALL_GROUPS,
    payload: {
        layout,
        value
    }
});

export const setActiveGroup = ({ layout, amount }) => ({
    type: SET_ACTIVE_GROUP,
    payload: {
        layout,
        amount
    }
});

export const setGroup = ({ reset, sectors, subShiftDelete, reservableSectors }) => ({
    type: SET_GROUP,
    payload: {
        reservableSectors,
        subShiftDelete,
        sectors,
        reset,
    }
});

export const setGroupsOnReservableSector = ({ sectorId }) => ({
    type: SET_GROUPS_ON_BOOKABLE_SECTORS,
    payload: {
        sectorId,
    }
});

export const setChangeBookableGroup = ({ subShiftId, sectorId, sector, group }) => ({
    type: CHANGE_BOOKABLE_GROUP,
    payload: {
        subShiftId,
        sectorId,
        sector,
        group
    }
});

export const setDisabledAllLayout = ({ layoutType, subShiftId }) => ({
    type: SET_DISABLED_ALL_LAYOUT,
    payload: {
        layoutType,
        subShiftId
    }
});

export const setUpdateTables = ({ sectors, reset }) => ({
    type: UPDATE_TABLES,
    payload: {
        sectors,
        reset
    }
});

export const setExtraTable = ({ tables }) => ({
    type: SET_EXTRA_TABLE,
    payload: {
        tables
    }
});

export const fetchSectors = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExist( idPartnerSelected ); 
            dispatch(toggleTurnosLoading());          

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/sector/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            
            dispatch(fetchSectorSuccess(data));
            dispatch(fetchTurnos());
        } catch (error) {
            console.log(error);
            if (error.response?.data) {
                dispatch(setTurnosError(error.response.data));
            } else {
                dispatch(setTurnosError(error.message));
            }
        }
    }
};

export const fetchTurnos = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExist( idPartnerSelected );
            dispatch(toggleTurnosLoading());
            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);            
            const res = await axios.get(`${API_URL}/booking/schedule/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            // console.log( data );
            dispatch(fetchTurnosSuccess(data));
        } catch (error) {
            console.log('error fetch: ', error);
            dispatch(setTurnosError(error.message));
        }
    }
};

async function createTurno(partner, auth, editedShift) {
    // const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        const API_URL = process.env.REACT_APP_API_URL_V3;
        console.log( editedShift );
        await axios.put(`${API_URL}/booking/schedule/${partner}`, editedShift, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const res = e.response;
        if ( res.status > 300 ) {
            console.log(res.statusText);
            throw new Error(res.data);
        }
    }
};

async function updateTurno(partner, auth, editedShift) {
    // const API_URL = process.env.REACT_APP_API_URL;
    const token = await auth.currentUser.getIdToken(true);
    try {
        const API_URL = process.env.REACT_APP_API_URL_V3;
        await axios.patch(`${API_URL}/booking/schedule/${partner}/${editedShift.id}`, editedShift, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const res = e.response;
        if ( res.status > 300 ) {
            console.log(res.statusText);
            throw new Error(res.data);
        }
    }
};

const isEmpty = (value) => {
    return value === '' || typeof value === 'undefined';
};

const AddEditProprties = (editedShift, applyFrom, maintainBookings) => {

    const newPairApplyFrom = {applyFrom: moment(applyFrom).startOf('date').utc().toISOString()};
    Object.assign(editedShift, newPairApplyFrom);

    const newPairMaintainBookings= {maintainBookings: maintainBookings};
    Object.assign(editedShift, newPairMaintainBookings);

    return editedShift;
};

export const submitTurnoChanges = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { v3, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {shiftData, mode, applyFromDate, maintainBookings } = v3.shifts;

        dispatch(toggleTurnosLoading());
        const editedShift = deepCopyObject( shiftData );

        editedShift.id = typeof editedShift.id === 'undefined' ? uuidv4() : editedShift.id;
        editedShift.uuid = typeof editedShift.uuid === 'undefined' ? uuidv4() : editedShift.uuid;

        // Si startDate está vacía, cargo la de hoy
        editedShift.calendar.startDate = isEmpty(shiftData.calendar.startDate) ? moment().startOf('date').toISOString() : GetMomentDate(shiftData.calendar.startDate);
        // Si endDate está vacía, cargo una máxima
        // editedShift.calendar.endDate = isEmpty(shiftData.calendar.endDate) ? moment({year: 3000}).startOf('date').toISOString() : GetMomentDate(shiftData.calendar.endDate);
        
        try {
            ensurePartnerExist( idPartnerSelected );
            const auth = getFirebase().auth();
            if (mode === ShiftMode.EDIT || mode === ShiftMode.DISABLE) {
                const newEditShift = AddEditProprties(editedShift, applyFromDate, maintainBookings);
                
                if (mode === ShiftMode.EDIT) {
                    // Si el turno se encontraba deshabilitado y cuando se va a guardar se iba a guardar como deshabilitado
                    // se debe habilitar automaticamente
                    let originalShift = shiftData;
                    if (!originalShift.enable && !editedShift.enable) {
                        newEditShift.enable = true
                    }
                    dispatch(showEditModal(false));
                }
                await updateTurno( idPartnerSelected , auth, newEditShift);
            } else if ( mode === ShiftMode.CREATE ) {                
                await createTurno( idPartnerSelected , auth, editedShift);
            }
            dispatch(setSaveOk());
            dispatch( changeShiftsViewMode(SHIFTS_VIEW_MODE.VIEW) );
            dispatch( fetchTurnos() );
        } catch (e) {
            console.log(e);
            dispatch(setTurnosError(e.message));
        }

    }
};

async function deleteTurno(partner, auth, turnoId, date, maintainBookings) {    
    const token = await auth.currentUser.getIdToken(true);
    try {
        const API_URL = process.env.REACT_APP_API_URL_V3;
        const deleteBody = {
            maintainBookings: maintainBookings, 
            applyFrom: date,
        };

        await axios.patch(`${API_URL}/booking/schedule/${partner}/${turnoId}/delete`, deleteBody, {headers: {Authorization: `Bearer ${token}`}})
    } catch (e) {
        const response = e.response;
        if (response.status > 300) {
            throw new Error(response.data);
        }
    }
};

// Eliminar turno
export const submitTurnoDeleted = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { v3, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        
        const {shiftData, applyFromDate, maintainBookings} = v3.shifts;

        dispatch(toggleTurnosLoading());

        try {
            ensurePartnerExist( idPartnerSelected );

            await deleteTurno( idPartnerSelected , getFirebase().auth(), shiftData.id, moment(applyFromDate).startOf('date').utc().toISOString(), maintainBookings);
            dispatch(changeTurnoMode({mode: ShiftMode.VIEW, turno: null}));
            dispatch(fetchTurnos());
            dispatch(setDeleteSuccess());
        } catch (e) {
            dispatch(changeTurnoMode({mode: ShiftMode.VIEW, turno: null}));            
            dispatch(setTurnosError(e.message));
        }
    }
};

function ensurePartnerExist( id ) {
    if ( !id ) {
        throw new Error('Partner not found');
    }
};