import React from 'react';

import { connect } from 'react-redux';

// Material Ui
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

// Styles
import { ItemsSingleItemsStyles } from '../Styles';

const SingleItem = ( props ) => {

    // External props
    const { key, onClickItem, path, title, icon, iconPrimary } = props;

    // States
    const { location } = props;

    return (
        <ListItem
            sx={{
                color: location.pathname !== path ? '#848282' : '#0B4762',
                padding: '.2rem 0 .2rem .625rem',
                borderRadius: '.4rem',
                height: '2.5rem',
                mb: '.2rem',
                '&.MuiButtonBase-root': {
                    backgroundColor: location.pathname !== path ? 
                        'transparent' 
                    : 
                        '#E3F2FD !important',
                }
            }}
            onClick={ onClickItem }
            key={ key }
            button
        >
            <Box 
                sx={{ 
                    alignItems: 'center',
                    borderRadius: '8px',
                    display: 'flex', 
                }}
            >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                    {
                        location.pathname !== path ? 
                                icon 
                            :
                                iconPrimary
                    }
                </ListItemIcon>
            </Box>
            <ListItemText >
                <Typography variant='body1' sx={ ItemsSingleItemsStyles }>
                    { title }
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

const mapStateToProps = state => {
    return {
        location: state.router.location,
    }
}

export default connect( mapStateToProps, null )( SingleItem );