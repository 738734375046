import React, { useEffect, useState } from "react";

// Components
import FilterButton from "../GeneralInfo/Info General/FilterButton/FilterButton";
import SearchBar from "../GeneralComponents/SearchBar";
import TableSortableHeader from "../GeneralComponents/TableSortableHeader";
import TablePaginationActions from "../GeneralComponents/TablePaginationActions";

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";

// import jsPDF from "jspdf";
// import "jspdf-autotable";
import * as XLSX from "xlsx";

// Icons
import ShieldIcon from "../SvgComponents/icons/BookingTable/ShieldIcon";
import GroupIcon from "../SvgComponents/icons/BookingTable/GroupIcon";

// Redux
import {
  changeBookingHistoryFilterOrigin,
  changeBookingHistoryFilterState,
  changeBookingHistoryPage,
  changeBookingHistoryQuery,
  changeBookingHistoryRowsPerPage,
  fetchBookingsForToday,
} from "../../actions";
import { connect } from "react-redux";
import {
  BookingFinalStates,
  BookingOrigin,
  ReversBookingFinalStates,
  ReversBookingOrigin,
} from "../../constants/bookingHistoryConstants";
import moment from "moment";

const useStyles = makeStyles(() => ({
  HeaderTitle: {
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: "600 !important",
    color: "#041C32 !important",
    fontSize: "1rem !important",
  },
  DataTable: {
    fontFamily: "Poppins, sans-serif !important",
    fontSize: ".9rem !important",
  },
  ClientButton: {
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    cursor: "pointer",
    padding: ".4rem",
    display: "flex",
    margin: "auto",
    width: "95%",
  },
  StateBooking: {
    borderRadius: "8px",
    fontWeight: "500",
    maxWidth: "125px",
    padding: ".4rem",
    margin: "auto",
  },
  ExportButton: {
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: "600 !important",
    color: "white !important",
    backgroundColor: "#FFB800 !important",
    fontSize: ".9rem !important",
    textTransform: "none !important",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15) !important",
    borderRadius: "8px !important",
    padding: ".5rem 1rem !important",
    width: "100% !important",
  },
}));

const TableBookingClients = (props) => {
  const classes = useStyles();
  // Variables
  const {
    history,
    totalDocuments,
    rowsPerPage,
    page,
    origin,
    state,
    bookingsForToday,
  } = props;

  // Actions
  const {
    changeOrigin,
    changeState,
    changeQuery,
    changePage,
    changeRowsPerPage,
    fetchBookings,
  } = props;

  const [exportData, setExportData] = useState(false);

  const header = [
    {
      id: "origin",
      disablePadding: false,
      label: "Origen de la reserva",
      disableSorting: true,
    },
    {
      id: "date",
      disablePadding: false,
      label: "Fecha de reserva",
      disableSorting: true,
    },
    {
      id: "name",
      disablePadding: false,
      label: "Cliente",
      disableSorting: true,
    },
    {
      id: "contact",
      disablePadding: false,
      label: "Contacto",
      disableSorting: true,
    },
    {
      id: "groupSize",
      disablePadding: false,
      label: "Tamaño de grupo",
      disableSorting: true,
    },
    {
      id: "state",
      disablePadding: false,
      label: "Estado final de la reserva",
      disableSorting: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    console.log( 'NewPage --> ', newPage );
    changePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    changeRowsPerPage(parseInt(event.target.value, 10));
    changePage(0);
  };

  const handleOnSearch = (query) => {
    changeQuery(query);
  };

  const handleOnOriginChange = (e) => {
    const value = e.target.value;
    if (value === "Todos") {
      changeOrigin(null);
    } else {
      changeOrigin(BookingOrigin[value]);
    }
  };

  const handleOnStateChange = (e) => {
    const value = e.target.value;
    console.log( 'Valueee --> ', value );
    if (value === "Todos") {
      changeState(null);
    } else {
      changeState(
        Object.entries(ReversBookingFinalStates).find(
          ([key, value]) => value === e.target.value
        )[0]
      );
    }
  };

  const handleClickBookingsForToday = async () => {
    fetchBookings();
    setExportData(true);
  };

  const getOriginText = (origin) => {
    switch (origin) {
      case BookingOrigin.Manual: {
        return ReversBookingOrigin[BookingOrigin.Manual];
      }
      case BookingOrigin.Woki: {
        return ReversBookingOrigin[BookingOrigin.Woki];
      }
      case BookingOrigin.Link: {
        return ReversBookingOrigin[BookingOrigin.Link];
      }
      case BookingOrigin["Tus clientes"]: {
        return ReversBookingOrigin[BookingOrigin.Woki];
      }
      default: {
        return "";
      }
    }
  };

  const getOriginStyle = (origin) => {
    switch (origin) {
      case BookingOrigin.Manual: {
        return {
          color: "#B2B2B2",
        };
      }
      case BookingOrigin.Woki: {
        return {
          color: "#1AAFDE",
        };
      }
      case BookingOrigin.Link: {
        return {
          color: "#F97F59",
        };
      }
      case BookingOrigin["Tus clientes"]: {
        return {
          color: "#EEB115",
        };
      }
      default: {
        return {
          color: "",
        };
      }
    }
  };

  const getStateText = (state) => {
    switch (state) {
      case BookingFinalStates.ARRIVED: {
        return ReversBookingFinalStates[BookingFinalStates.ARRIVED];
      }
      case BookingFinalStates.NO_SHOW: {
        return ReversBookingFinalStates[BookingFinalStates.NO_SHOW];
      }
      case BookingFinalStates.REJECTED: {
        return ReversBookingFinalStates[BookingFinalStates.REJECTED];
      }
      case BookingFinalStates.AUTO_REJECTED: {
        return ReversBookingFinalStates[BookingFinalStates.AUTO_REJECTED];
      }
      case BookingFinalStates.DELETED_BY_PARTNER: {
        return ReversBookingFinalStates[BookingFinalStates.DELETED_BY_PARTNER];
      }
      case BookingFinalStates.DELETED_BY_USER: {
        return ReversBookingFinalStates[BookingFinalStates.DELETED_BY_USER];
      }
      default: {
        return "";
      }
    }
  };

  const getStateStyle = (state) => {
    switch (state) {
      case BookingFinalStates.ARRIVED: {
        return {
          color: "#35C18F",
          backgroundColor: "#DAFFF2",
        };
      }
      case BookingFinalStates.NO_SHOW: {
        return {
          color: "#EB252E",
          backgroundColor: "#FFD5D7",
        };
      }
      case BookingFinalStates.REJECTED: {
        return {
          color: "#EB252E",
          backgroundColor: "#FFD5D7",
        };
      }
      case BookingFinalStates.AUTO_REJECTED: {
        return {
          color: "#EB252E",
          backgroundColor: "#FFD5D7",
        };
      }
      case BookingFinalStates.DELETED_BY_PARTNER: {
        return {
          color: "#848282",
          backgroundColor: "#F0EFEF",
        };
      }
      case BookingFinalStates.DELETED_BY_USER: {
        return {
          color: "#848282",
          backgroundColor: "#F0EFEF",
        };
      }
      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    if (bookingsForToday.length > 0 && exportData) {
      //i need to export the data to pdf using jsPDF and jsPDF-AutoTable
      //   const doc = new jsPDF();
      //     doc.autoTable({
      //         head: [['Nombre', 'Email', 'Telefono', 'Cantidad', 'Hora de arribo', 'Comentarios cliente', 'Comentarios restaurante']],
      //         body: bookingsForToday.map((booking) => {
      //             return [booking.name, booking.email, booking.phone, booking.quantity, booking.showDay, booking.comment, booking.partnerComment]
      //         }
      //     )});
      //     doc.save('table.pdf');

      //now i need to export the data to excel using xlsx
      const ws = XLSX.utils.json_to_sheet(
        bookingsForToday.map((booking) => {
          return {
            Nombre: booking.name,
            Email: booking.email,
            Telefono: booking.phone,
            Cantidad: booking.quantity,
            "Hora de arribo":
              booking.showDay.split("T")[1].split("+")[0].split(":")[0] +
              ":" +
              booking.showDay.split("T")[1].split("+")[0].split(":")[1],
            "Comentarios cliente": booking.comment,
            "Comentarios restaurante": booking.partnerComment,
            Notas: "",
          };
        })
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Reservas");
      XLSX.writeFile(wb, "Reservas.xlsx");
      setExportData(false);
    }
  }, [bookingsForToday]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Grid
          container
          spacing={2}
          style={{ padding: "1.5rem 1rem 1rem 1rem" }}
        >
          <Grid item xs={6}>
            <SearchBar onSearch={handleOnSearch} />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FilterButton
                  text={"Estado final"}
                  value={
                    state !== null ? ReversBookingFinalStates[state] : "Todos"
                  }
                  options={[
                    "Todos",
                    ...Object.values(ReversBookingFinalStates),
                  ]}
                  color={"#0B4762"}
                  onChange={handleOnStateChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FilterButton
                  text={"Origen de la reserva"}
                  value={
                    origin !== null ? ReversBookingOrigin[origin] : "Todos"
                  }
                  options={["Todos", ...Object.keys(BookingOrigin)]}
                  color={"#0B4762"}
                  onChange={handleOnOriginChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  className={classes.ExportButton}
                  onClick={handleClickBookingsForToday}
                >
                  Reservas de hoy
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableSortableHeader
            onRequestSort={() => {}}
            order={"asc"}
            orderBy={"name"}
            head={header}
          />
          <TableBody>
            {history.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  align="center"
                  sx={{
                    ...getOriginStyle(row.origin),
                    fontWeight: "600",
                  }}
                  className={classes.DataTable}
                >
                  {row.origin === 3 ? <ShieldIcon /> : ""}&nbsp;{" "}
                  {getOriginText(row.origin)}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.DataTable}
                  sx={{
                    color: "#041C32",
                  }}
                >
                  {moment(row.date).utc(false).format("DD/MM/yyyy HH:ss") +
                    " hs"}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.DataTable}
                  sx={{
                    color: "#041C32",
                  }}
                >
                  <div
                    className={classes.ClientButton}
                    style={{ cursor: row.phone ? "pointer" : "default" }}
                  >
                    {row.client}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.DataTable}
                  sx={{
                    color: "#041C32",
                  }}
                >
                  {row.email ?? ""} <br />
                  {row.phone ?? ""}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.DataTable}
                  sx={{
                    fontSize: "1.3rem !important",
                    fontWeight: "600",
                    color: "#041C32",
                  }}
                >
                  {row.quantity} <GroupIcon />
                </TableCell>
                <TableCell align="center" className={classes.DataTable}>
                  <div
                    className={classes.StateBooking}
                    style={getStateStyle(row.state)}
                  >
                    {getStateText(row.state)}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={6}
                count={totalDocuments}
                rowsPerPage={rowsPerPage}
                page={totalDocuments <= 0 ? 0 : page}
                labelRowsPerPage={"Filas por página"}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    history: state.bookingHistoryReducer.history,
    totalDocuments: state.bookingHistoryReducer.totalDocuments,
    rowsPerPage: state.bookingHistoryReducer.rowsPerPage,
    page: state.bookingHistoryReducer.page,
    origin: state.bookingHistoryReducer.origin,
    state: state.bookingHistoryReducer.state,
    bookingsForToday: state.bookingHistoryReducer.bookingsForToday,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOrigin: (origin) =>
      dispatch(changeBookingHistoryFilterOrigin(origin)),
    changeState: (state) => dispatch(changeBookingHistoryFilterState(state)),
    changeQuery: (query) => dispatch(changeBookingHistoryQuery(query)),
    changePage: (page) => dispatch(changeBookingHistoryPage(page)),
    changeRowsPerPage: (rowsPerPage) =>
      dispatch(changeBookingHistoryRowsPerPage(rowsPerPage)),
    fetchBookings: () => dispatch(fetchBookingsForToday()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBookingClients);


