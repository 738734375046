import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setActiveGroup, setAllGroups, setExtraIds, setGroup, setGroupsOnReservableSector, setReservableSectors } from '../../../../../actions/shiftsActions';

// Components
import GroupsItem from './GroupsItem';
import { Title } from '../../Title';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { 
    OutlinedInput, 
    FormControl, 
    Typography, 
    Backdrop, 
    MenuItem, 
    Divider, 
    Select, 
    Button, 
    Modal, 
    Stack,
    Fade, 
    Box,
} from '@mui/material';

// Utils
import { GetStyledItem } from '../utils/modalFunctions';

// Styles
import { itemStyle, modalStyles, modalTitle } from '../utils/styles/modalStyles';
import { SettingsTablesStyles, ShiftTableSelectStyles } from '../../../Styles';

export const DividerStyles = { 
    borderColor: '#E7E7E7', 
    width: '100%',
    m: '1rem 0' 
};

const useStyles = makeStyles(() => ( modalStyles ));

const CustomizeReservableGroupSectorsModal = ( props ) => {

    // Actions
    const { 
        setActiveGroup,
        setAllGroups,
        setGroup
    } = props;

    // External props
    const { open, handleClose } = props;

    // States
    const { 
        reservableSectors, 
        maxBookingGroup,
        minBookingGroup, 
    } = props;

    const classes = useStyles();

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                width: 'max-content',
                marginTop: '.2rem',
                minWidth: '78px',
            },
        },
    };

    const newReservableSectors = reservableSectors.filter( (reservableSector) => reservableSector.available );
    
    const [reservableSector, setReservableSector] = useState( 
        newReservableSectors.length > 0 ? reservableSectors[0] : null 
    );
    const [GroupsArray, setGroupsArray] = useState([]);

    const uniqueIds = new Set();

    useEffect(() => {
        let newGroupsArray = [];
        for (let i = minBookingGroup; i <= maxBookingGroup; i++) {
            newGroupsArray.push({
                id: i,
                value: i
            });
        };

        setGroupsArray( newGroupsArray );
    }, [ minBookingGroup, maxBookingGroup ]);
    
    const handleChangeSector = ( val ) => {
        setReservableSector(
            newReservableSectors.find( reservableSector => reservableSector.id === val )
        );
    };

    const handleChangeActive = ( group, enabled, layout ) => {
        
        if ( group === 'Todos' ) {
            setAllGroups({ 
                layout: reservableSector.name, 
                value: !enabled, 
            });
        } else {
            setActiveGroup({
                layout: layout,
                amount: group
            });
        };
        
        let newGroupArray = [];

        for (let i = 0; i < GroupsArray.length; i++) {
            newGroupArray.push({
                amount: GroupsArray[i],
                enabled: false
            })
        };
    };

    const handleAccept = () => {
        setGroup({ reset: false });

        handleClose();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            slots={{ backdrop: Backdrop }}
            closeAfterTransition
            open={ open }
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ open } >
                <Box className={ classes.ModalContainer } >
                    <Title text="Excepciones por tamaño de grupo" style={ modalTitle } />
                    <Box sx={{ m: '1rem 0 2rem 0' }}>
                        <FormControl fullWidth>
                            <Select
                                onChange={(e) => handleChangeSector(e.target.value)}
                                inputProps={{ 'aria-label': 'Without label' }}
                                labelId="demo-simple-select-label"
                                IconComponent={ ExpandMoreIcon }
                                sx={ ShiftTableSelectStyles }
                                value={ reservableSector.id }
                                input={ <OutlinedInput /> }
                                MenuProps={ MenuProps }
                                renderValue={(value) => {
                                    return GetStyledItem(
                                        newReservableSectors.find((rs) => rs.id === value).name, 
                                        newReservableSectors.find((rs) => rs.id === value).type
                                    );
                                }}
                            >
                                {
                                    newReservableSectors.map((reservableSector) => (
                                        ( reservableSector.tables.length ) > 0 ? 
                                                <MenuItem 
                                                    value={ reservableSector.id } 
                                                    key={ reservableSector.id } 
                                                    sx={ itemStyle }
                                                >
                                                    { GetStyledItem(reservableSector.name, reservableSector.type) }
                                                    <Box sx={{ width: '25px' }}></Box>
                                                </MenuItem> 
                                            : 
                                                null  
                                    ))
                                }                      
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider sx={ DividerStyles } />
                    <Box sx={{ pb: '1rem' }}>
                        <Typography className={ classes.ItemsTitle }>
                            Tamaño de grupo
                        </Typography>
                    </Box>
                    <Box sx={ SettingsTablesStyles }>
                        {
                            reservableSector.groups.map(( group ) => {
                                if ( !uniqueIds.has( group.id ) ) {
                                    uniqueIds.add( group.id );
                                    return <GroupsItem 
                                                handleChangeActive={ handleChangeActive }
                                                enabled={ group.enabled } 
                                                layout={ group.layout }  
                                                amount={ group.id } 
                                                key={ group.id }
                                            />
                                } else {
                                    return null;
                                }           
                        })
                        }
                    </Box>
                    <Stack style={ modalStyles.ButtonContainer }>
                        <Button className={ classes.CancelButton } onClick={ handleClose }>
                            Cancelar
                        </Button>
                        <Button className={classes.ApplyButton} onClick={ handleAccept }>
                            Aplicar
                        </Button>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    )
};

const mapStateToProps = (state) => {
    return {   
        maxBookingGroup: state.v3.shifts.shiftData.maxBookingGroup,
        minBookingGroup: state.v3.shifts.shiftData.minBookingGroup,         
        reservableSectors: state.v3.shifts.reservableSectors,
        subShifts: state.v3.shifts.shiftData.subShifts,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setGroupsOnReservableSector: ({ sectorId }) => dispatch( setGroupsOnReservableSector({ sectorId }) ),
        setExtraIds: ({ subshiftId, tableId, groupId, type, bookableId }) => dispatch( 
            setExtraIds({ subshiftId, tableId, groupId, type, bookableId }) 
        ),
        setActiveGroup: ({ layout, amount }) => dispatch( setActiveGroup({ layout, amount }) ),
        setAllGroups: ({ value, layout }) => dispatch( setAllGroups({ value, layout }) ),
        setReservableSectors: (data) => dispatch( setReservableSectors(data) ),
        setGroup: ({ reset }) => dispatch( setGroup({ reset }) )
    }
};

export default connect( mapStateToProps, mapDispatchToProps )( CustomizeReservableGroupSectorsModal );