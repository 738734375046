import React from "react";


import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import Subscriptions from "./Suscriptions/Suscriptions";

import {GENERAL_SETTINGS, PARTNERS, PLANS} from "../../constants";
import {
    checkPaymentMethod,
    editFinancesError,
    editFinancesSuccess,
    prevStepFinances,
    updateSubscription,
} from "../../actions";

import MiPlanForm from "./Form/MiPlanForm";
import {push} from "connected-react-router";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";

function FinancesForm(props) {
    const {activeStep, loading} = props;

    const {
        updateSubscription,
        prevStepFinances,
        editFinancesSuccess,
    } = props;


    const handleDone = async () => {

        let thereNotError = await updateSubscription();

        if (thereNotError.result) {
            editFinancesSuccess()
            window.open(thereNotError.checkout,"_self");
        }
        else {
            editFinancesError("Error al finalizar suscripcion. Por favor revise los datos");
        }
    }

    const handleBack = () => {
        prevStepFinances(activeStep - 1)
    }

    const getStepContent = () => {
        // eslint-disable-next-line default-case
        switch (activeStep) {
            case 0:
                return <Subscriptions/>
            case 1:
                return <MiPlanForm onDone={handleDone}/>
        }
    }

    return (
        <div>
            {getStepContent()}
            <ProgressIndicator open={loading}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        checkout_payment: state.financesReducer.editReducer.checkout_payment,
        paymentMethodId: state.financesReducer.editReducer.paymentMethodId,
        activeStep: state.financesReducer.editReducer.activeStep,
        newPlanId: state.financesReducer.editReducer.newPlanId,
        loading: state.financesReducer.editReducer.loading,
        planId: state.financesReducer.editReducer.planId,
        error: state.financesReducer.editReducer.error,

        partner: state.firestore.ordered[PARTNERS],
        plans: state.firestore.ordered[PLANS],
        firestoreData: state.firestore.data,
        ordered: state.firestore.ordered,
        profile: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editFinancesError: (data) => dispatch(editFinancesError(data)),
        checkPaymentMethod: () => dispatch(checkPaymentMethod()),

        prevStepFinances: (data) => dispatch(prevStepFinances(data)),
        updateSubscription: () => dispatch(updateSubscription()),
        editFinancesSuccess: () => dispatch(editFinancesSuccess()),

        push: (data) => dispatch(push(data)),
    };
};

export default compose(
    connect( mapStateToProps, mapDispatchToProps ),
    firestoreConnect((props) => {
        return [
            {
                collection: PLANS
            },
            {
                collection: GENERAL_SETTINGS,
            },
            {
                collection: PARTNERS,
                doc: props.idPartnerSelected,
            },

        ];
    })
)(FinancesForm);
