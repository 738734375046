import React, { useEffect, useState } from 'react';

// Components
import InputWithCondition from '../../NewComponents/InputWhitCondition/InputWithCondition';

// Constants
import { PASSWORD_MIN_LENGTH } from "../../../constants";

// Firebase
import 'firebase/auth';

// Material Ui
import { Button, FormControlLabel, FormGroup, FormLabel, Grid } from '@material-ui/core';
import { Backdrop, Box, Fade, Modal, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import { validateEmail, validatePassword, validateUserName } from '../../../utils/validation';
import { connect } from 'react-redux';
import { setUserRole } from '../../../actions';

const useStyles = makeStyles(() => ({
    Title: {
        fontFamily: 'Poppins, sans-serif',
        margin: '0 0 .5rem 0',
        fontSize: '1.6rem',
        color: '#041C32',
    },
    textFieldContainer: {
        padding: '10px',
    },
    errorMessageContainer: {
        justifyContent: 'flex-end',
        display: 'flex',
    },
    errorMessage: {
        color: 'red',
    },
    FormGroup: {
        alignItems: 'center',
        marginLeft: '-1rem',
        display: 'flex',
    },
    Legend: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '.9rem',
        fontWeight: '500',
        color: '#808080',
        marginRight: 5,
    },
    TxtChecked: {
        fontFamily: 'Poppins, sans-serif',
        marginBottom: '.4rem',
        fontWeight: '500',
        fontSize: '.8rem',
        color: '#283149',
    },
    ButtonContainer: {
        justifyContent: 'right',
        alignItems: 'center',
        marginTop: '1rem',
        display: 'flex',
        gap: '15px'
    },
    ButtonCancel: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        backgroundColor: 'white',
        padding: '.2rem 1.6rem',
        textTransform: 'none',
        borderRadius: '10px',
        color: '#0B4762',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    ButtonCreate: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #0B4762',
        backgroundColor: '#0B4762',
        padding: '.2rem 1.4rem',
        textTransform: 'none',
        borderRadius: '10px',
        color: 'white',
        '&:hover': {
            backgroundColor: '#0B4762 !important'
        }
    }
}));

const ChecksStyles = {
    '&.Mui-checked': {
        color: '#0B4762',
    }
};

function NewUserDialog( props ) {

    // Actions
    const { setUserRole } = props;

    // External props
    const { dialogState, dialogController, onSubmit } = props;

    // States
    const { userRole, profileData, idPartnerSelected } = props;

    const classes = useStyles();

    const [userDisplayName, setUserDisplayName] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const [secondPasswordError, setSecondPasswordError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);

    const cleanForm = () => {
        setSecondPassword('');
        setUserRole({ userRole: 'admin' });
        setPassword('');
        setEmail('');
        setName('');
    };

    useEffect(() => {
        if ( !!dialogState ) {
            cleanForm();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dialogState ]);

    const submitNewUser = () => {
        // Realizar validaciones
        setSecondPasswordError(false);
        setPasswordError(false);
        setEmailError(false);
        setNameError(false);
        setErrorMessage('');

        if (!validateUserName(name)) {
            setNameError(true);
        } else if (!validateEmail(email)) {
            setPasswordError(true);
        } else if (!validatePassword(password)) {
            setPasswordError(true);
        } else if (!validatePassword(secondPassword) || secondPassword !== password) {
            setSecondPasswordError(true);
        } else {
            // No hubo problemas
            setNameError(false);
            setEmailError(false);
            setPasswordError(false);
            setSecondPasswordError(false);
            console.log( userRole, password );
            onSubmit({ 
                password, 
                email, 
                name,
                userName: userDisplayName 
            });
            cleanForm();
        }
    };

    const handleChange = ( event ) => {
        setUserRole({ userRole: event.target.value });
    };

    useEffect(() => {
        if ( profileData ) {
            setUserDisplayName( profileData[idPartnerSelected]?.displayName );
        }
    }, [ idPartnerSelected, profileData ])
    

    return (
        <Modal
            aria-describedby="modal-modal-description"
            onClose={ () => dialogController() }
            aria-labelledby="modal-modal-title"
            slots={{ backdrop: Backdrop }}
            open={ !!dialogState }
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ !!dialogState }>
                <Box
                    sx={{
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                        borderRadius: '10px',
                        bgcolor: 'white',
                        border: 'none',
                        width: '700px',
                        boxShadow: 24,
                        left: '50%',
                        top: '50%',
                        p: 4,
                        '&:focus-visible': {
                            outline: 'none'
                        }
                    }}
                >
                    <h2 className={classes.Title}>Nuevo usuario</h2>
                    <Grid container>
                        <Grid item sm={5} className={classes.textFieldContainer}>
                            <InputWithCondition
                                onChange={e => setName(e.target.value)}
                                error={nameError}
                                helperText={' '}
                                text={'Nombre'}
                                width={'100%'}
                                value={name}
                            />
                        </Grid>
                        <Grid item sm={7} className={classes.textFieldContainer}>
                            <InputWithCondition
                                onChange={e => setEmail(e.target.value)}
                                backgroundColor={'white'}
                                error={emailError}
                                helperText={' '}
                                width={'100%'}
                                text={'Email'}
                                value={email}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.textFieldContainer}>
                            <InputWithCondition
                                onChange={e => setPassword(e.target.value)}
                                backgroundColor={'white'}
                                error={passwordError}
                                text={'Contraseña'}
                                type={'password'}
                                value={password}
                                helperText={`La contraseña debe tener un mínimo de ${PASSWORD_MIN_LENGTH} caracteres`}
                                alignHelperText={true}
                                id={'password'}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.textFieldContainer}>
                            <InputWithCondition
                                onChange={e => setSecondPassword(e.target.value)}
                                text={'Repita la contraseña'}
                                error={secondPasswordError}
                                value={secondPassword}
                                id={'second-password'}
                                type={'password'}
                                helperText={' '}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item sm={12} style={{padding: '0 10px', marginTop: '-.6rem'}}>
                            <FormGroup>
                                <FormLabel className={ classes.Legend } component="legend">Roles: </FormLabel>
                                <div className={ classes.FormGroup }>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        value={ userRole || 'admin' }
                                        name="controlled-radio-buttons-group"
                                        onChange={ handleChange }
                                        row
                                    >
                                        <FormControlLabel
                                            sx={{
                                                fontFamily: 'Poppins, sans-serif', 
                                                fontWeight: '500',
                                                color: '#283149', 
                                            }}
                                            labelPlacement={'start'}
                                            control={
                                                <Radio
                                                    sx={ ChecksStyles }
                                                    color='default'
                                                    value={'mesa-ya'}
                                                />
                                            }
                                            label={<h3 className={classes.TxtChecked}>Visión del recepciónista</h3>}
                                        />

                                        <FormControlLabel
                                            labelPlacement={'start'}
                                            label={<h3 className={classes.TxtChecked}>Reservas</h3>}
                                            control={
                                                <Radio
                                                    sx={ ChecksStyles }
                                                    value={'reserva'}
                                                    color="default"
                                                />
                                            }
                                        />

                                        <FormControlLabel
                                            labelPlacement={'start'}
                                            control={
                                                <Radio
                                                    sx={ ChecksStyles }
                                                    color='default'
                                                    value={'admin'}
                                                />
                                            }
                                            label={<h3 className={classes.TxtChecked}>Administrador</h3>}
                                        />
                                    </RadioGroup>
                                </div>
                            </FormGroup>
                        </Grid>
                        <Grid item sm={12} className={classes.errorMessageContainer}>
                            <span className={classes.errorMessage}>{errorMessage}</span>
                        </Grid>
                    </Grid>
                    <div className={classes.ButtonContainer}>
                        <Button className={classes.ButtonCancel} onClick={() => dialogController()}>
                            Cancelar
                        </Button>
                        <Button className={classes.ButtonCreate} onClick={() => submitNewUser()}>
                            Guardar cambios
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        userRole: state.userAccountsReducer.editReducer.userRole,
        profileData: state.firestore.data.partners,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setUserRole: ({ userRole }) => dispatch( setUserRole({ userRole }) ),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)( NewUserDialog );
