import React from 'react'

const UpArrow = (props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 17.06 10.621">
            <path id="Trazado_324" data-name="Trazado 324" d="M7,0,0,6.717l7,6.106"
                transform="translate(14.94 1.5) rotate(90)" fill="none" stroke="#0B4762" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="3"/>
        </svg>
    )
}

UpArrow.defaultProps = {
    width: '10px',
    height: '10px'
}

export default UpArrow