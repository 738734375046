import React from 'react';

const WokiYa = (props) => {

    const {subscribe, basePrice, unitPrice} = props;

    const {onClick} = props;

    return (
        <div style={{position: 'relative', zIndex: '0', fontFamily: 'Montserrat'}}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="60vh"
                 viewBox="0 0 560 820">
                <defs>
                    <linearGradient id="wokiGradient" x1="0.5" x2="0.5" y2="0.76" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#12b6ff"/>
                        <stop offset="1" stopColor="#7c2bff"/>
                    </linearGradient>
                    <filter id="Rectángulo_2552" x="0" y="0" width="560" height="820" filterUnits="userSpaceOnUse">
                        <feOffset dy="6" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="10" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <filter id="Sustracción_54" x="15" y="9.999" width="530.002" height="740.001"
                            filterUnits="userSpaceOnUse">
                        <feOffset dy="6" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="5" result="blur-2"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur-2"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <filter id="Sustracción_47" x="29.998" y="24.003" width="500.002" height="169.996"
                            filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="5" result="blur-3"/>
                        <feFlood floodOpacity="0.251" result="color"/>
                        <feComposite operator="out" in="SourceGraphic" in2="blur-3"/>
                        <feComposite operator="in" in="color"/>
                        <feComposite operator="in" in2="SourceGraphic"/>
                    </filter>
                    <filter id="Rectángulo_4" x="171" y="713" width="218" height="63" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-4"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur-4"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <filter id="Rectángulo_4-2" x="171" y="713" width="218" height="63" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-5"/>
                        <feFlood floodOpacity="0.251" result="color-2"/>
                        <feComposite operator="out" in="SourceGraphic" in2="blur-5"/>
                        <feComposite operator="in" in="color-2"/>
                        <feComposite operator="in" in2="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Grupo_1716" data-name="Grupo 1716" transform="translate(-730 -136)">
                    <g transform="matrix(1, 0, 0, 1, 730, 136)" filter="url(#Rectángulo_2552)">
                        <rect id="Rectángulo_2552-2" data-name="Rectángulo 2552" width="500" height="760" rx="40"
                              transform="translate(30 24)" fill="#fff"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 730, 136)" filter="url(#Sustracción_54)">
                        <path id="Sustracción_54-2" data-name="Sustracción 54"
                              d="M-8037.993-12566H-8038v-619.595a39.766,39.766,0,0,0,3.142,15.163,39.9,39.9,0,0,0,8.573,12.718,39.928,39.928,0,0,0,12.717,8.572A39.754,39.754,0,0,0-7998-13146h420a40.336,40.336,0,0,1,15.57,3.095,39.947,39.947,0,0,1,12.714,8.446,39.2,39.2,0,0,1,8.569,12.521A38.647,38.647,0,0,1-7538-13106.6c0,.077,0,.151,0,.222v.1c0,.095,0,.186,0,.274h.005v460a39.76,39.76,0,0,1-3.145,15.569,39.76,39.76,0,0,1-8.573,12.714,39.823,39.823,0,0,1-12.714,8.573A39.758,39.758,0,0,1-7578-12606h-420a40.245,40.245,0,0,0-15.57,3.095,39.828,39.828,0,0,0-12.714,8.443,39.22,39.22,0,0,0-8.573,12.524A38.63,38.63,0,0,0-8038-12566.6c0,.207,0,.4,0,.6Zm497.062-685.056c-.065-.161-.135-.328-.212-.51a39.838,39.838,0,0,0-8.573-12.718,39.806,39.806,0,0,1,8.573,12.711c.081.19.147.352.214.517Zm-489.229-8.734,0,0a40.5,40.5,0,0,1,3.877-4.491A40.7,40.7,0,0,0-8030.16-13259.79Zm3.875-4.493v0a39.86,39.86,0,0,1,12.718-8.57A39.758,39.758,0,0,1-7998-13276h420a39.758,39.758,0,0,1,15.57,3.146,39.828,39.828,0,0,1,8.6,4.975,39.983,39.983,0,0,0-8.6-4.972A39.734,39.734,0,0,0-7578-13276h-420a39.736,39.736,0,0,0-15.57,3.145A39.878,39.878,0,0,0-8026.285-13264.283Z"
                              transform="translate(8068 13295)" fill="#fff"/>
                    </g>
                    <g data-type="innerShadowGroup">
                        <path id="Sustracción_47-2" data-name="Sustracción 47"
                              d="M-5525.006-13106c0-.185,0-.38,0-.6a38.645,38.645,0,0,0-3.144-15.336,39.233,39.233,0,0,0-8.572-12.522,39.881,39.881,0,0,0-12.714-8.444A40.291,40.291,0,0,0-5565-13146h-420a39.752,39.752,0,0,1-15.571-3.144,39.861,39.861,0,0,1-12.715-8.572,39.872,39.872,0,0,1-8.573-12.716A39.749,39.749,0,0,1-6025-13186V-13236a39.749,39.749,0,0,1,3.144-15.57,39.85,39.85,0,0,1,8.573-12.716,39.861,39.861,0,0,1,12.715-8.572A39.748,39.748,0,0,1-5985-13276h420a39.748,39.748,0,0,1,15.571,3.144,39.855,39.855,0,0,1,12.715,8.572,39.85,39.85,0,0,1,8.573,12.716A39.749,39.749,0,0,1-5525-13236V-13106h-.008Z"
                              transform="translate(6784.998 13436.002)" fill="url(#wokiGradient)"/>
                        <g transform="matrix(1, 0, 0, 1, 730, 136)" filter="url(#Sustracción_47)">
                            <path id="Sustracción_47-3" data-name="Sustracción 47"
                                  d="M-5525.006-13106c0-.185,0-.38,0-.6a38.645,38.645,0,0,0-3.144-15.336,39.233,39.233,0,0,0-8.572-12.522,39.881,39.881,0,0,0-12.714-8.444A40.291,40.291,0,0,0-5565-13146h-420a39.752,39.752,0,0,1-15.571-3.144,39.861,39.861,0,0,1-12.715-8.572,39.872,39.872,0,0,1-8.573-12.716A39.749,39.749,0,0,1-6025-13186V-13236a39.749,39.749,0,0,1,3.144-15.57,39.85,39.85,0,0,1,8.573-12.716,39.861,39.861,0,0,1,12.715-8.572A39.748,39.748,0,0,1-5985-13276h420a39.748,39.748,0,0,1,15.571,3.144,39.855,39.855,0,0,1,12.715,8.572,39.85,39.85,0,0,1,8.573,12.716A39.749,39.749,0,0,1-5525-13236V-13106h-.008Z"
                                  transform="translate(6055 13300)" fill="#fff"/>
                        </g>
                    </g>
                    <text id="Woki_Ya_" data-name="Woki Ya!" transform="translate(893 229)" fill="#fff" fontSize="40"
                          fontFamily="Montserrat-ExtraBold, Montserrat" fontWeight="800">
                        <tspan x="-92.8" y="0">Woki Ya!</tspan>
                    </text>
                    <g id="Grupo_1556" data-name="Grupo 1556" transform="translate(10 -88)">
                        <g id="Grupo_1543" data-name="Grupo 1543" transform="translate(-15 -1)">
                            <text id="Perfil_personalizable" data-name="Perfil personalizable"
                                  transform="translate(810 417)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Perfil personalizable</tspan>
                            </text>
                            <path id="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 401)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1544" data-name="Grupo 1544" transform="translate(-15 -1)">
                            <text id="Estadísticas" transform="translate(810 452)" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Estadísticas</tspan>
                            </text>
                            <path id="check-circle-2" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 436)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1545" data-name="Grupo 1545" transform="translate(-15 -1)">
                            <text id="Carta_digital" data-name="Carta digital" transform="translate(810 487)"
                                  fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Carta digital</tspan>
                            </text>
                            <path id="check-circle-3" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 471)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1546" data-name="Grupo 1546" transform="translate(-15)">
                            <text id="Base_de_datos_de_clientes" data-name="Base de datos de clientes"
                                  transform="translate(810 519)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Base de datos de clientes</tspan>
                            </text>
                            <path id="check-circle-4" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 505)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1547" data-name="Grupo 1547" transform="translate(-15 -1)">
                            <text id="Fila_virtual" data-name="Fila virtual" transform="translate(810 557)"
                                  fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Fila virtual</tspan>
                            </text>
                            <path id="check-circle-5" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 541)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1548" data-name="Grupo 1548" transform="translate(-15 -1)">
                            <text id="Geolocalización" transform="translate(810 592)" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Geolocalización</tspan>
                            </text>
                            <path id="check-circle-6" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 576)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1549" data-name="Grupo 1549" transform="translate(-15 6)">
                            <text id="Exposición_en_redes" data-name="Exposición en redes"
                                  transform="translate(810 620)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Exposición en redes</tspan>
                            </text>
                            <path id="check-circle-7" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 604)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1550" data-name="Grupo 1550" transform="translate(-15 6)">
                            <text id="Algoritmo_ML_para_demoras" data-name="Algoritmo ML para demoras"
                                  transform="translate(810 655)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Algoritmo ML para demoras</tspan>
                            </text>
                            <path id="check-circle-8" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 639)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1551" data-name="Grupo 1551" transform="translate(-15 4)">
                            <text id="Reservas" transform="translate(810 693)" fill="#afafaf" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Reservas</tspan>
                            </text>
                            <g id="Grupo_1614" data-name="Grupo 1614" transform="translate(838.428 -180.304)">
                                <path id="Trazado_836" data-name="Trazado 836" d="M373.291,864.3h0Z" fill="#afafaf"/>
                                <path id="Trazado_837" data-name="Trazado 837"
                                      d="M375.23,861.284a9.954,9.954,0,0,0-3.639-3.639,10.063,10.063,0,0,0-10.038,0,9.944,9.944,0,0,0-3.64,3.64,10.061,10.061,0,0,0,0,10.039,9.953,9.953,0,0,0,3.639,3.639,10.061,10.061,0,0,0,10.039,0,9.954,9.954,0,0,0,3.639-3.639,10.062,10.062,0,0,0,0-10.039Zm-3.952,7.37a.847.847,0,0,1,0,1.2l-1.156,1.157a.849.849,0,0,1-1.2,0l-2.35-2.35-2.35,2.35a.849.849,0,0,1-1.2,0l-1.157-1.157a.849.849,0,0,1,0-1.2l2.35-2.35-2.35-2.35a.849.849,0,0,1,0-1.2l1.157-1.157a.849.849,0,0,1,1.2,0l2.35,2.35,2.35-2.35a.849.849,0,0,1,1.2,0l1.156,1.157a.847.847,0,0,1,0,1.2l-2.35,2.35Z"
                                      fill="#afafaf"/>
                            </g>
                        </g>
                        <g id="Grupo_1553" data-name="Grupo 1553" transform="translate(-15 -46)">
                            <text id="Agenda_inteligente" data-name="Agenda inteligente" transform="translate(810 777)"
                                  fill="#afafaf" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Agenda inteligente</tspan>
                            </text>
                            <g id="Grupo_1615" data-name="Grupo 1615" transform="translate(838.428 -95.304)">
                                <path id="Trazado_838" data-name="Trazado 838" d="M373.291,864.3h0Z" fill="#afafaf"/>
                                <path id="Trazado_839" data-name="Trazado 839"
                                      d="M375.23,861.284a9.954,9.954,0,0,0-3.639-3.639,10.063,10.063,0,0,0-10.038,0,9.944,9.944,0,0,0-3.64,3.64,10.061,10.061,0,0,0,0,10.039,9.953,9.953,0,0,0,3.639,3.639,10.061,10.061,0,0,0,10.039,0,9.954,9.954,0,0,0,3.639-3.639,10.062,10.062,0,0,0,0-10.039Zm-3.952,7.37a.847.847,0,0,1,0,1.2l-1.156,1.157a.849.849,0,0,1-1.2,0l-2.35-2.35-2.35,2.35a.849.849,0,0,1-1.2,0l-1.157-1.157a.849.849,0,0,1,0-1.2l2.35-2.35-2.35-2.35a.849.849,0,0,1,0-1.2l1.157-1.157a.849.849,0,0,1,1.2,0l2.35,2.35,2.35-2.35a.849.849,0,0,1,1.2,0l1.156,1.157a.847.847,0,0,1,0,1.2l-2.35,2.35Z"
                                      fill="#afafaf"/>
                            </g>
                        </g>
                        <g id="Grupo_1554" data-name="Grupo 1554" transform="translate(-15 -46)">
                            <text id="Mis_clientes" data-name="Mis clientes" transform="translate(810 813)"
                                  fill="#0076ff" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Mis clientes</tspan>
                            </text>
                            <text id="_0_Cubierto" data-name="$0 / Cubierto" transform="translate(1215 813)"
                                  fontSize="18" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-112.377" y="0">$0 / Cubierto</tspan>
                            </text>
                        </g>
                        <g id="Grupo_1555" data-name="Grupo 1555" transform="translate(-15 -47)">
                            <text id="Clientes_de_Woki" data-name="Clientes de Woki" transform="translate(810 849)"
                                  fill="#0076ff" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Clientes de Woki</tspan>
                            </text>
                            <text id="_50_Cubierto" data-name="$50 / Cubierto" transform="translate(1215 849)"
                                  fontSize="18" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-122.73" y="0">{`\$${unitPrice ?? '-'} / Cubierto`}</tspan>
                            </text>
                        </g>
                    </g>
                    <g id="Layer_2" data-name="Layer 2" transform="translate(1185 192)">
                        <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
                            <path id="Trazado_704" data-name="Trazado 704"
                                  d="M84.83,40.322l.865-.864a2.1,2.1,0,0,0,0-2.972L84.52,35.312a2.1,2.1,0,0,0-2.971,0l-.865.865a19.676,19.676,0,0,0-8.9-3.686V30.853H65.923V32.49a19.715,19.715,0,0,0-10.751,5.246l3.961.012a17.383,17.383,0,1,1-3.655,25.493l-3.129-.007A19.88,19.88,0,1,0,84.83,40.322Z"
                                  transform="translate(-42.534 -25.068)" fill="#fff"/>
                            <path id="Trazado_705" data-name="Trazado 705"
                                  d="M12.992,88.814h0l1.665,0a15.02,15.02,0,1,0,3.8-22.264l-1.566,0-2.31,0-3.452-.007L8,66.532a.389.389,0,0,0-.3.142l-1.26,1.533a.389.389,0,0,0,.3.637l2.7.005,3.035.007,2.535,0h.562a15.053,15.053,0,0,0-2.941,4.3h-.275l-2.277-.006-2.651-.007-3.276-.008a.389.389,0,0,0-.317.162l-1.1,1.533a.389.389,0,0,0,.315.617l3.775.008,2.553.006,2.345.006h.085a15.066,15.066,0,0,0-.506,3.882q0,.241.007.478l-.841,0-1.523,0L6.453,79.82,1.5,79.808a.389.389,0,0,0-.317.162L.077,81.5a.389.389,0,0,0,.315.617l6.248.014,2.524.006,1.251,0,1.147,0A14.928,14.928,0,0,0,13.107,86.5l-.892,0-1.741,0L7.76,86.485l-3.235-.009a.389.389,0,0,0-.328.178l-.991,1.534a.389.389,0,0,0,.326.6l5.3.014,2.916.008Zm5.064-9.246A1.261,1.261,0,0,1,19.449,78.4l6.11.3.02-.268s.18-1.252-.36-1.442-1.045-.228-1.009-1.29,1.009-9.942,3.387-10.36V78.8l3.359.164s.361-1.6,1.473-1.6c.954,0,5.83.357,7.187.459a.359.359,0,0,1,.351.339.364.364,0,0,1-.384.341H34.8a.412.412,0,1,0,0,.817h4.619a.483.483,0,1,1,0,.959H34.8a.412.412,0,1,0,0,.818h4.777a.364.364,0,0,1,.384.34.358.358,0,0,1-.351.339c-1.357.1-6.232.459-7.187.459-1.111,0-1.473-1.6-1.473-1.6L27.6,80.8v6.58A1.233,1.233,0,0,1,26.43,88.64l-.168,0h-.146a1.237,1.237,0,0,1-1.194-1.36l.473-6.378-5.946.292a1.261,1.261,0,0,1-1.393-1.167Z"
                                  transform="translate(-0.004 -52.27)" fill="#fff"/>
                            <path id="Trazado_706" data-name="Trazado 706"
                                  d="M110.836,3.754h8.032a1.877,1.877,0,0,0,1.877-1.877h0A1.877,1.877,0,0,0,118.868,0h-8.032a1.877,1.877,0,0,0-1.877,1.877h0a1.877,1.877,0,0,0,1.877,1.877Z"
                                  transform="translate(-88.532)" fill="#fff"/>
                        </g>
                    </g>
                    <text id="Funcionalidad_Mesa_Ya_" data-name="Funcionalidad Mesa Ya!" transform="translate(810 260)"
                          fill="#fff" fontSize="16" fontFamily="SegoeUI, Segoe UI">
                        <tspan x="0" y="0">Funcionalidad Mesa Ya!</tspan>
                    </text>
                    {subscribe
                        ?
                        <g id="Grupo_1717" data-name="Grupo 1717" transform="translate(-10 -5)">
                            <path id="check-circle"
                                  d="M20.87,9.85a1.012,1.012,0,0,0-.293-.748L19.1,7.64a1.022,1.022,0,0,0-1.463,0L11,14.255,7.33,10.581a1.022,1.022,0,0,0-1.463,0L4.388,12.044a1.012,1.012,0,0,0-.293.748.993.993,0,0,0,.293.732l5.884,5.884a1,1,0,0,0,.732.309,1.018,1.018,0,0,0,.748-.309l8.826-8.826a.993.993,0,0,0,.293-.732Zm4.1,2.633a12.212,12.212,0,0,1-1.674,6.266,12.425,12.425,0,0,1-4.543,4.543,12.212,12.212,0,0,1-6.266,1.674,12.212,12.212,0,0,1-6.266-1.674,12.425,12.425,0,0,1-4.543-4.543A12.212,12.212,0,0,1,0,12.483,12.212,12.212,0,0,1,1.674,6.217,12.425,12.425,0,0,1,6.217,1.674,12.212,12.212,0,0,1,12.483,0a12.212,12.212,0,0,1,6.266,1.674,12.425,12.425,0,0,1,4.543,4.543A12.212,12.212,0,0,1,24.966,12.483Z"
                                  transform="translate(904 865)" fill="#0076ff"/>
                            <text id="Suscripcion_actual" data-name="Suscripcion actual"
                                  transform="translate(1030.966 884)" fill="#0076ff" fontSize="20"
                                  fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-84.824" y="0">Suscripcion actual</tspan>
                            </text>
                        </g>
                        :
                        <g id="Grupo_1480" data-name="Grupo 1480" transform="translate(-100 -55)" cursor={'pointer'}
                           onClick={onClick}>
                            <g data-type="innerShadowGroup">
                                <g transform="matrix(1, 0, 0, 1, 830, 191)" filter="url(#Rectángulo_4)">
                                    <rect id="Rectángulo_4-3" data-name="Rectángulo 4" width="200" height="45" rx="20"
                                          transform="translate(180 719)" fill="#0076ff"/>
                                </g>
                                <g transform="matrix(1, 0, 0, 1, 830, 191)" filter="url(#Rectángulo_4-2)">
                                    <rect id="Rectángulo_4-4" data-name="Rectángulo 4" width="200" height="45" rx="20"
                                          transform="translate(180 719)" fill="#fff"/>
                                </g>
                            </g>
                            <text id="Suscribite" transform="translate(1110 939)" fill="#fff" fontSize="20"
                                  fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-46.021" y="0">Suscribite</tspan>
                            </text>
                        </g>}
                    <text id="_3.000_mes" data-name="$ 3.000/mes" transform="translate(1230 789)" fontSize="45"
                          fontFamily="Montserrat-ExtraBold, Montserrat" fontWeight="800">
                        <tspan x="-251.775" y="0">{`\$ ${basePrice ?? '-'}`}</tspan>
                        <tspan y="0" fontSize="30">/mes</tspan>
                    </text>
                    <text id="Valor" transform="translate(805 785)" fontSize="18"
                          fontFamily="Montserrat-Medium, Montserrat" fontWeight="500">
                        <tspan x="0" y="0">Valor</tspan>
                    </text>
                </g>
            </svg>


            {/*<div style={{position: 'relative', zIndex: '1'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="360" height="10%" viewBox="0 0 560 820">
                    <defs>
                        <filter id="Rectángulo_2552" x="0" y="0" width="560" height="820" filterUnits="userSpaceOnUse">
                            <feOffset dy="6" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="10" result="blur"/>
                            <feFlood floodOpacity="0.251"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectángulo_2552)">
                        <rect id="Rectángulo_2552-2" data-name="Rectángulo 2552" width="500" height="760" rx="40"
                              transform="translate(30 24)" fill="#fff"/>
                    </g>
                </svg>
            </div>
            <div style={{position: 'absolute', zIndex: 2, top: '0px', left: '-10px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="380" height="91.5" viewBox="0 0 500.002 169.996">
                    <defs>
                        <linearGradient id="linear-gradient-wy" x1="0.5" x2="0.5" y2="0.76"
                                        gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#12b6ff"/>
                            <stop offset="1" stopColor="#7c2bff"/>
                        </linearGradient>
                        <filter id="Sustracción_47" x="0" y="0" width="500.002" height="169.996"
                                filterUnits="userSpaceOnUse">
                            <feOffset input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="5" result="blur"/>
                            <feFlood floodOpacity="0.251" result="color"/>
                            <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                            <feComposite operator="in" in="color"/>
                            <feComposite operator="in" in2="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g data-type="innerShadowGroup">
                        <path id="Sustracción_47-2" data-name="Sustracción 47"
                              d="M-5525.006-13106c0-.185,0-.38,0-.6a38.645,38.645,0,0,0-3.144-15.336,39.233,39.233,0,0,0-8.572-12.522,39.881,39.881,0,0,0-12.714-8.444A40.291,40.291,0,0,0-5565-13146h-420a39.752,39.752,0,0,1-15.571-3.144,39.861,39.861,0,0,1-12.715-8.572,39.872,39.872,0,0,1-8.573-12.716A39.749,39.749,0,0,1-6025-13186V-13236a39.749,39.749,0,0,1,3.144-15.57,39.85,39.85,0,0,1,8.573-12.716,39.861,39.861,0,0,1,12.715-8.572A39.748,39.748,0,0,1-5985-13276h420a39.748,39.748,0,0,1,15.571,3.144,39.855,39.855,0,0,1,12.715,8.572,39.85,39.85,0,0,1,8.573,12.716A39.749,39.749,0,0,1-5525-13236V-13106h-.008Z"
                              transform="translate(6025 13275.999)" fill="url(#linear-gradient-wy)"/>
                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Sustracción_47)">
                            <path id="Sustracción_47-3" data-name="Sustracción 47"
                                  d="M-5525.006-13106c0-.185,0-.38,0-.6a38.645,38.645,0,0,0-3.144-15.336,39.233,39.233,0,0,0-8.572-12.522,39.881,39.881,0,0,0-12.714-8.444A40.291,40.291,0,0,0-5565-13146h-420a39.752,39.752,0,0,1-15.571-3.144,39.861,39.861,0,0,1-12.715-8.572,39.872,39.872,0,0,1-8.573-12.716A39.749,39.749,0,0,1-6025-13186V-13236a39.749,39.749,0,0,1,3.144-15.57,39.85,39.85,0,0,1,8.573-12.716,39.861,39.861,0,0,1,12.715-8.572A39.748,39.748,0,0,1-5985-13276h420a39.748,39.748,0,0,1,15.571,3.144,39.855,39.855,0,0,1,12.715,8.572,39.85,39.85,0,0,1,8.573,12.716A39.749,39.749,0,0,1-5525-13236V-13106h-.008Z"
                                  transform="translate(6025 13276)" fill="#fff"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div style={{position: 'absolute', zIndex: 1, top: '-31px', left: '38px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="284" height="450.001" viewBox="0 0 530.002 740.001">
                    <defs>
                        <filter id="Sustracción_54" x="0" y="0" width="530.002" height="740.001"
                                filterUnits="userSpaceOnUse">
                            <feOffset dy="6" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="5" result="blur"/>
                            <feFlood floodOpacity="0.161"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Sustracción_54)">
                        <path id="Sustracción_54-2" data-name="Sustracción 54"
                              d="M-8037.993-12566H-8038v-619.595a39.766,39.766,0,0,0,3.142,15.163,39.9,39.9,0,0,0,8.573,12.718,39.928,39.928,0,0,0,12.717,8.572A39.754,39.754,0,0,0-7998-13146h420a40.336,40.336,0,0,1,15.57,3.095,39.947,39.947,0,0,1,12.714,8.446,39.2,39.2,0,0,1,8.569,12.521A38.647,38.647,0,0,1-7538-13106.6c0,.077,0,.151,0,.222v.1c0,.095,0,.186,0,.274h.005v460a39.76,39.76,0,0,1-3.145,15.569,39.76,39.76,0,0,1-8.573,12.714,39.823,39.823,0,0,1-12.714,8.573A39.758,39.758,0,0,1-7578-12606h-420a40.245,40.245,0,0,0-15.57,3.095,39.828,39.828,0,0,0-12.714,8.443,39.22,39.22,0,0,0-8.573,12.524A38.63,38.63,0,0,0-8038-12566.6c0,.207,0,.4,0,.6Zm497.062-685.056c-.065-.161-.135-.328-.212-.51a39.838,39.838,0,0,0-8.573-12.718,39.806,39.806,0,0,1,8.573,12.711c.081.19.147.352.214.517Zm-489.229-8.734,0,0a40.5,40.5,0,0,1,3.877-4.491A40.7,40.7,0,0,0-8030.16-13259.79Zm3.875-4.493v0a39.86,39.86,0,0,1,12.718-8.57A39.758,39.758,0,0,1-7998-13276h420a39.758,39.758,0,0,1,15.57,3.146,39.828,39.828,0,0,1,8.6,4.975,39.983,39.983,0,0,0-8.6-4.972A39.734,39.734,0,0,0-7578-13276h-420a39.736,39.736,0,0,0-15.57,3.145A39.878,39.878,0,0,0-8026.285-13264.283Z"
                              transform="translate(8053 13285)" fill="#fff"/>
                    </g>
                </svg>
            </div>
            <div style={{position: 'absolute', zIndex: 4, top: '20px', left: '33px', width: '310px', height: '40px'}}>
                <Grid container>
                    <Grid item xs={10} style={{paddingLeft: '26px', marginTop: '-7px'}}>
                        <h1
                            style={{
                                margin: '0px',
                                color: 'white',
                                marginBottom: '0px',
                                fontSize: '19px'
                            }}
                        >Woki Ya!</h1>
                        <p
                            style={{
                                margin: '0px',
                                color: 'white',
                                fontSize: '10px'
                            }}
                        >Funcionalidad Mesa Ya!</p>
                    </Grid>
                    <Grid item xs={2} style={{marginTop: '-5px', marginLeft: '-15px'}}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.196" height="20.957"
                                 viewBox="0 0 46.196 46.957">
                                <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
                                    <path id="Trazado_704" data-name="Trazado 704"
                                          d="M84.83,40.322l.865-.864a2.1,2.1,0,0,0,0-2.972L84.52,35.312a2.1,2.1,0,0,0-2.971,0l-.865.865a19.676,19.676,0,0,0-8.9-3.686V30.853H65.923V32.49a19.715,19.715,0,0,0-10.751,5.246l3.961.012a17.383,17.383,0,1,1-3.655,25.493l-3.129-.007A19.88,19.88,0,1,0,84.83,40.322Z"
                                          transform="translate(-42.534 -25.068)" fill="#fff"/>
                                    <path id="Trazado_705" data-name="Trazado 705"
                                          d="M12.992,88.814h0l1.665,0a15.02,15.02,0,1,0,3.8-22.264l-1.566,0-2.31,0-3.452-.007L8,66.532a.389.389,0,0,0-.3.142l-1.26,1.533a.389.389,0,0,0,.3.637l2.7.005,3.035.007,2.535,0h.562a15.053,15.053,0,0,0-2.941,4.3h-.275l-2.277-.006-2.651-.007-3.276-.008a.389.389,0,0,0-.317.162l-1.1,1.533a.389.389,0,0,0,.315.617l3.775.008,2.553.006,2.345.006h.085a15.066,15.066,0,0,0-.506,3.882q0,.241.007.478l-.841,0-1.523,0L6.453,79.82,1.5,79.808a.389.389,0,0,0-.317.162L.077,81.5a.389.389,0,0,0,.315.617l6.248.014,2.524.006,1.251,0,1.147,0A14.928,14.928,0,0,0,13.107,86.5l-.892,0-1.741,0L7.76,86.485l-3.235-.009a.389.389,0,0,0-.328.178l-.991,1.534a.389.389,0,0,0,.326.6l5.3.014,2.916.008Zm5.064-9.246A1.261,1.261,0,0,1,19.449,78.4l6.11.3.02-.268s.18-1.252-.36-1.442-1.045-.228-1.009-1.29,1.009-9.942,3.387-10.36V78.8l3.359.164s.361-1.6,1.473-1.6c.954,0,5.83.357,7.187.459a.359.359,0,0,1,.351.339.364.364,0,0,1-.384.341H34.8a.412.412,0,1,0,0,.817h4.619a.483.483,0,1,1,0,.959H34.8a.412.412,0,1,0,0,.818h4.777a.364.364,0,0,1,.384.34.358.358,0,0,1-.351.339c-1.357.1-6.232.459-7.187.459-1.111,0-1.473-1.6-1.473-1.6L27.6,80.8v6.58A1.233,1.233,0,0,1,26.43,88.64l-.168,0h-.146a1.237,1.237,0,0,1-1.194-1.36l.473-6.378-5.946.292a1.261,1.261,0,0,1-1.393-1.167Z"
                                          transform="translate(-0.004 -52.27)" fill="#fff"/>
                                    <path id="Trazado_706" data-name="Trazado 706"
                                          d="M110.836,3.754h8.032a1.877,1.877,0,0,0,1.877-1.877h0A1.877,1.877,0,0,0,118.868,0h-8.032a1.877,1.877,0,0,0-1.877,1.877h0a1.877,1.877,0,0,0,1.877,1.877Z"
                                          transform="translate(-88.532)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{position: 'absolute', zIndex: 4, top: '85px', left: '48px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="268" height="230" viewBox="0 0 405 410">
                    <g id="Grupo_1556" data-name="Grupo 1556" transform="translate(-795 -397)">
                        <g id="Grupo_1543" data-name="Grupo 1543" transform="translate(-15 -1)">
                            <text id="Perfil_personalizable" data-name="Perfil personalizable"
                                  transform="translate(810 417)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Perfil personalizable</tspan>
                            </text>
                            <path id="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 401)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1544" data-name="Grupo 1544" transform="translate(-15 -1)">
                            <text id="Estadísticas" transform="translate(810 452)" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Estadísticas</tspan>
                            </text>
                            <path id="check-circle-2" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 436)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1545" data-name="Grupo 1545" transform="translate(-15 -1)">
                            <text id="Carta_digital" data-name="Carta digital" transform="translate(810 487)"
                                  fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Carta digital</tspan>
                            </text>
                            <path id="check-circle-3" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 471)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1546" data-name="Grupo 1546" transform="translate(-15)">
                            <text id="Base_de_datos_de_clientes" data-name="Base de datos de clientes"
                                  transform="translate(810 519)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Base de datos de clientes</tspan>
                            </text>
                            <path id="check-circle-4" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 505)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1547" data-name="Grupo 1547" transform="translate(-15 -1)">
                            <text id="Fila_virtual" data-name="Fila virtual" transform="translate(810 557)"
                                  fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Fila virtual</tspan>
                            </text>
                            <path id="check-circle-5" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 541)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1548" data-name="Grupo 1548" transform="translate(-15 -1)">
                            <text id="Geolocalización" transform="translate(810 592)" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Geolocalización</tspan>
                            </text>
                            <path id="check-circle-6" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 576)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1549" data-name="Grupo 1549" transform="translate(-15 6)">
                            <text id="Exposición_en_redes" data-name="Exposición en redes"
                                  transform="translate(810 620)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Exposición en redes</tspan>
                            </text>
                            <path id="check-circle-7" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 604)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1550" data-name="Grupo 1550" transform="translate(-15 6)">
                            <text id="Algoritmo_ML_para_demoras" data-name="Algoritmo ML para demoras"
                                  transform="translate(810 655)" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Algoritmo ML para demoras</tspan>
                            </text>
                            <path id="check-circle-8" data-name="check-circle"
                                  d="M16.719,7.891a.811.811,0,0,0-.234-.6L15.3,6.12a.818.818,0,0,0-1.172,0l-5.312,5.3L5.872,8.477a.818.818,0,0,0-1.172,0L3.515,9.648a.811.811,0,0,0-.234.6.8.8,0,0,0,.234.586l4.713,4.713a.8.8,0,0,0,.586.247.816.816,0,0,0,.6-.247l7.071-7.071a.8.8,0,0,0,.234-.586ZM20,10a9.783,9.783,0,0,1-1.341,5.019,9.954,9.954,0,0,1-3.639,3.639A9.783,9.783,0,0,1,10,20a9.783,9.783,0,0,1-5.019-1.341,9.954,9.954,0,0,1-3.639-3.639A9.783,9.783,0,0,1,0,10,9.783,9.783,0,0,1,1.341,4.981,9.954,9.954,0,0,1,4.981,1.341,9.783,9.783,0,0,1,10,0a9.783,9.783,0,0,1,5.019,1.341,9.954,9.954,0,0,1,3.639,3.639A9.783,9.783,0,0,1,20,10Z"
                                  transform="translate(1195 639)" fill="#0076ff"/>
                        </g>
                        <g id="Grupo_1551" data-name="Grupo 1551" transform="translate(-15 4)">
                            <text id="Reservas" transform="translate(810 693)" fill="#afafaf" fontSize="18"
                                  fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Reservas</tspan>
                            </text>
                            <g id="Grupo_1614" data-name="Grupo 1614" transform="translate(838.428 -180.304)">
                                <path id="Trazado_836" data-name="Trazado 836" d="M373.291,864.3h0Z" fill="#afafaf"/>
                                <path id="Trazado_837" data-name="Trazado 837"
                                      d="M375.23,861.284a9.954,9.954,0,0,0-3.639-3.639,10.063,10.063,0,0,0-10.038,0,9.944,9.944,0,0,0-3.64,3.64,10.061,10.061,0,0,0,0,10.039,9.953,9.953,0,0,0,3.639,3.639,10.061,10.061,0,0,0,10.039,0,9.954,9.954,0,0,0,3.639-3.639,10.062,10.062,0,0,0,0-10.039Zm-3.952,7.37a.847.847,0,0,1,0,1.2l-1.156,1.157a.849.849,0,0,1-1.2,0l-2.35-2.35-2.35,2.35a.849.849,0,0,1-1.2,0l-1.157-1.157a.849.849,0,0,1,0-1.2l2.35-2.35-2.35-2.35a.849.849,0,0,1,0-1.2l1.157-1.157a.849.849,0,0,1,1.2,0l2.35,2.35,2.35-2.35a.849.849,0,0,1,1.2,0l1.156,1.157a.847.847,0,0,1,0,1.2l-2.35,2.35Z"
                                      fill="#afafaf"/>
                            </g>
                        </g>
                        <g id="Grupo_1553" data-name="Grupo 1553" transform="translate(-15 -46)">
                            <text id="Agenda_inteligente" data-name="Agenda inteligente" transform="translate(810 777)"
                                  fill="#afafaf" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Agenda inteligente</tspan>
                            </text>
                            <g id="Grupo_1615" data-name="Grupo 1615" transform="translate(838.428 -95.304)">
                                <path id="Trazado_838" data-name="Trazado 838" d="M373.291,864.3h0Z" fill="#afafaf"/>
                                <path id="Trazado_839" data-name="Trazado 839"
                                      d="M375.23,861.284a9.954,9.954,0,0,0-3.639-3.639,10.063,10.063,0,0,0-10.038,0,9.944,9.944,0,0,0-3.64,3.64,10.061,10.061,0,0,0,0,10.039,9.953,9.953,0,0,0,3.639,3.639,10.061,10.061,0,0,0,10.039,0,9.954,9.954,0,0,0,3.639-3.639,10.062,10.062,0,0,0,0-10.039Zm-3.952,7.37a.847.847,0,0,1,0,1.2l-1.156,1.157a.849.849,0,0,1-1.2,0l-2.35-2.35-2.35,2.35a.849.849,0,0,1-1.2,0l-1.157-1.157a.849.849,0,0,1,0-1.2l2.35-2.35-2.35-2.35a.849.849,0,0,1,0-1.2l1.157-1.157a.849.849,0,0,1,1.2,0l2.35,2.35,2.35-2.35a.849.849,0,0,1,1.2,0l1.156,1.157a.847.847,0,0,1,0,1.2l-2.35,2.35Z"
                                      fill="#afafaf"/>
                            </g>
                        </g>
                        <g id="Grupo_1554" data-name="Grupo 1554" transform="translate(-15 -46)">
                            <text id="Mis_clientes" data-name="Mis clientes" transform="translate(810 813)"
                                  fill="#0076ff" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Mis clientes</tspan>
                            </text>
                            <text id="_0_Cubierto" data-name="$0 / Cubierto" transform="translate(1215 813)"
                                  fontSize="18" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-115.377" y="0">$0 / Cubierto</tspan>
                            </text>
                        </g>
                        <g id="Grupo_1555" data-name="Grupo 1555" transform="translate(-15 -47)">
                            <text id="Clientes_de_Woki" data-name="Clientes de Woki" transform="translate(810 849)"
                                  fill="#0076ff" fontSize="18" fontFamily="SegoeUI, Segoe UI">
                                <tspan x="0" y="0">Clientes de Woki</tspan>
                            </text>
                            <text id="_50_Cubierto" data-name="$50 / Cubierto" transform="translate(1215 849)"
                                  fontSize="18" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
                                <tspan x="-126.73" y="0">{`\$${unitPrice ? unitPrice : ""} / Cubierto`}</tspan>
                            </text>
                        </g>
                    </g>
                </svg>
            </div>
            <div style={{position: 'absolute', zIndex: 4, top: '326px', left: '48px', width: '360px'}}>
                <Grid container>
                    <Grid item xs={4} style={{paddingTop: '9px', paddingLeft: '21px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 48 22">
                            <text id="Valor" transform="translate(1 17)" fontSize="18"
                                  fontFamily="Montserrat-Medium, Montserrat" fontWeight="500">
                                <tspan x="0" y="0">Valor</tspan>
                            </text>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="148" height="25" viewBox="0 0 252 55">
                            <text id="_3.000_mes" data-name="$ 3.000/mes" transform="translate(252 44)" fontSize="45"
                                  fontFamily="Montserrat-ExtraBold, Montserrat" fontWeight="800">
                                <tspan x="-251.775" y="0">{`$ ${basePrice ? basePrice : ""}`}</tspan>
                                <tspan y="0" fontSize="30">/mes</tspan>
                            </text>
                        </svg>
                    </Grid>
                </Grid>
            </div>
            {subscribe === undefined || subscribe === false ?
                <div style={{position: 'absolute', zIndex: 4, top: '375px', left: '120px'}}>
                    <div
                        onClick={onClick}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: '#0076FF',
                            borderRadius: '20px',
                            height: '30px',
                            width: '120px',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                            fontWeight: '700',
                            textAlign: 'center',
                            color: 'white',
                            paddingTop: '5px'
                        }}
                    >
                        Suscribite
                    </div>
                </div>
                :
                <div style={{position: 'absolute', zIndex: 4, top: '380px', left: '108px'}}>
                    <div style={{width: '150px'}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.966" height="15.966"
                                     viewBox="0 0 24.966 24.966">
                                    <path id="check-circle"
                                          d="M20.87,9.85a1.012,1.012,0,0,0-.293-.748L19.1,7.64a1.022,1.022,0,0,0-1.463,0L11,14.255,7.33,10.581a1.022,1.022,0,0,0-1.463,0L4.388,12.044a1.012,1.012,0,0,0-.293.748.993.993,0,0,0,.293.732l5.884,5.884a1,1,0,0,0,.732.309,1.018,1.018,0,0,0,.748-.309l8.826-8.826a.993.993,0,0,0,.293-.732Zm4.1,2.633a12.212,12.212,0,0,1-1.674,6.266,12.425,12.425,0,0,1-4.543,4.543,12.212,12.212,0,0,1-6.266,1.674,12.212,12.212,0,0,1-6.266-1.674,12.425,12.425,0,0,1-4.543-4.543A12.212,12.212,0,0,1,0,12.483,12.212,12.212,0,0,1,1.674,6.217,12.425,12.425,0,0,1,6.217,1.674,12.212,12.212,0,0,1,12.483,0a12.212,12.212,0,0,1,6.266,1.674,12.425,12.425,0,0,1,4.543,4.543A12.212,12.212,0,0,1,24.966,12.483Z"
                                          fill="#0076ff"/>
                                </svg>
                            </Grid>
                            <Grid item xs={10} style={{marginTop: '-1px'}}>
                                <p
                                    style={{
                                        margin: '0px',
                                        fontWeight: '800',
                                        color: '#0076FF',
                                        fontSize: '13px'
                                    }}
                                >Suscripción actual</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }*/}
        </div>
    )
}

export default WokiYa
