import React, { useState } from 'react';

import { connect } from 'react-redux';

// Components
import { BlueSwitch } from '../../../../GeneralComponents/blueSwitch';
import { Title } from '../../Title';

// Material Ui
import { Box, Divider, Stack } from "@mui/material";

const TableItem = ( props ) => {

    // Variables
    const {
        reservableSectors,
        layout,
        table,
    } = props;

    // Acciones
    const {
        handleChangeActive,
    } = props;

    const reservableSector = reservableSectors.find(reservableSector => reservableSector.id === table.id);

    const [active, setActive] = useState( reservableSector ? true : false );

    const changeActive = ( table ) => {
        handleChangeActive( table, active, layout );
        setActive( !active );
    };

    return (
        <Box> 
            <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Divider sx={{ borderColor: "#E7E7E7", width: "98%" }} />
            </Stack>
            <Stack direction="row" spacing="25px" mt={1} mb={1} justifyContent="center" alignItems={"center"}>
                <Box width="70%">
                    <Box display="flex" flexDirection={'row'} justifyContent={'start'} alignItems="center" marginLeft="50px">
                        <Box marginBottom='8px'>
                            <BlueSwitch
                                onChange={() => changeActive(table)}
                                checked={ active }
                                color="default"
                            />    
                        </Box>                        
                        <Title style={{fontSize: '18px'}} text={table.alias} />
                    </Box>   
                </Box>
                <Box width="30%" display="flex" flexDirection={'row'} alignItems="center" style={{marginRight: "60px"}}>
                    <Box width="50%" display="flex" flexDirection="row" justifyContent="center">
                        <Title style={{fontSize: '18px'}} text={table.minCapacity} />
                    </Box>
                    <Box width="50%" display="flex" flexDirection="row" justifyContent="center">
                        <Title style={{fontSize: '18px'}} text={table.maxCapacity} />
                    </Box>
                </Box>
            </Stack>          
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {            
        reservableSectors: state.v3.shifts.reservableSectors,
    }
}

export default connect(mapStateToProps, null)(TableItem);