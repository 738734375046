import { ModalModeTurnos } from "../../WarningPopup/WarningPopup";

export const getValidationErrorMessage = (error) => {

    switch (error) {
        case 'error:name':
            return 'No se completó el nombre del turno';
        case 'error:hours':
            return 'El tiempo entre el incio y fin del turno no puede ser menor a 60 minutos';
        case 'error:days':
            return 'No se seleccionó al menos 1 día de la semana';
        case 'error:subShift':
            return 'Agregá al menos un subturno';
        case 'error:subShiftHours':
            return 'Los horarios del subturno deben estar dentro del horario del turno';
        case 'error:subShiftSameHour':
            return 'El tiempo entre el inicio y fin del subturno no puede ser menor a 15 minutos';
        case 'error:subShiftShiftHours':
            return 'Los horarios de inicio y fin del subturno deben estar por dentro del rango del turno';
        case 'error:bookingHours':
            return 'No se seleccionó al menos un Horario reservable para el subturno';
        case 'error:subShiftOverlay':
            return 'Existen subturnos con horarios solapados'
        case 'error:subShiftFromBeforeTo':
            return 'El horario de inicio del subturno debe ser menor al horario de fin';
        case 'error:closingNextDay':
            return 'El horario de fin del turno no puede ser menor al horario de inicio';
        default:
            return 'Ha ocurrido un error...'
    }

}

export const ValidateInputs = (currentStep, shiftData, setValidationError, setShowAlert, setTurnosError, NextStep, step) => {
    if (currentStep === 1 && shiftData.name === '') {
      setValidationError('error:name');
      setShowAlert(true);
    } else if (currentStep === 2) {
        if (Math.abs(parseInt(shiftData.calendar.closing) - parseInt(shiftData.calendar.opening)) < 60) {
            setValidationError('error:hours');
            setShowAlert(true);
        } else if(parseInt(shiftData.calendar.closing) <= parseInt(shiftData.calendar.opening)) {
            setValidationError('error:closingNextDay');
            setShowAlert(true);
        } else if (!shiftData.isSpecial && shiftData.calendar.days.length === 0) {
            setValidationError('error:days');
            setShowAlert(true);
        } else if (shiftData.subShifts.length === 0) {
            setValidationError('error:subShift');
            setShowAlert(true);
        } else if (shiftData.subShifts.length > 0) {
            const fueraDelRango = ValidateShiftAndSubShiftHours(shiftData.calendar, shiftData.subShifts);
            const sinHorarioReservable = ValidateReservableHours(shiftData.subShifts);
            const conSuperposicion = ValidateSubshiftOverlay(shiftData.subShifts);

            if (fueraDelRango) {
                setValidationError('error:subShiftHours');
                setShowAlert(true);
            } else if (sinHorarioReservable) {
                setValidationError('error:bookingHours');
                setShowAlert(true);
            } else if (conSuperposicion) {
                setValidationError('error:subShiftOverlay');
                setShowAlert(true);
            } else {
                NextStep(step);          
            }
        } else {
            NextStep(step);
        }

    } else if (currentStep === 3 && shiftData.availableLayouts.length === 0) {
        setTurnosError('Para poder crear un subturno debes contar con al menos un sector');
    } else {
      NextStep(step);
    } 
}

const ValidateShiftAndSubShiftHours = (calendar, subShifts) => {
    let subshiftHoursError = false;

    subShifts.map((subShift) => {
        if (!(calendar.opening <= subShift.from && subShift.from < calendar.closing 
                && calendar.opening < subShift.to && subShift.to <= calendar.closing)) {
            subshiftHoursError = true;
        }
    })

    return subshiftHoursError;
}

const ValidateReservableHours = (subShifts) => {
    let sinHorarioReservable = false;

    subShifts.map((subShift) => {
        if (subShift.bookingHours.length === 0) {
            sinHorarioReservable = true;
        }   
    })

    return sinHorarioReservable;
}

const ValidateSubshiftOverlay = (subShifts = []) => {
    let subshiftOverlay = false;

    subShifts.forEach((subShift) => {        
        if (ExistOverlay(subShift.id, subShift.from, subShift.to, subShifts)) {
            subshiftOverlay = true;
        }
    })

    return subshiftOverlay;
}

const ExistOverlay = (subshiftId, from, to, subShifts = []) => {
    let subshiftOverlay = false;
    subShifts.forEach((subShift) => {
        if (subShift.id !== subshiftId && ((subShift.from > from && subShift.from < to) || (subShift.to > from && subShift.to < to))) {
            subshiftOverlay = true;
        }
    })

    return subshiftOverlay;
}

export const GetModalModeTurnos = (shoModalDeactivateWaiList, showExitWithoutSaving) => {
    if (shoModalDeactivateWaiList) {
        return ModalModeTurnos.DEACTIVATE_WAIT_LIST;
    } if (showExitWithoutSaving) {
        return ModalModeTurnos.EXIT_WITHOUT_SAVING;
    }else {
        return ModalModeTurnos.EDIT_SHIFT;
    }    
}

export const ClickeableStep = (steps, step) => {
    if (step > 1) {
        return steps[step-1] || steps[step-2];
    } else {
        return steps[step-1];
    }
    
}