import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { 
  setShowDeactivateWaitListModal, 
  changeTurnoMaintainBookings, 
  changeTurnoApplyFromDate, 
  setShowExitWithoutSaving, 
  changeShiftsViewMode, 
  setAvailableLayouts, 
  submitTurnoChanges, 
  setValidationError, 
  closeTurnosError, 
  setWithWaitList, 
  changeTurnoMode, 
  setTurnosError, 
  showEditModal, 
  setSubshift,
  setResetGroups, 
} from '../../../../actions/shiftsActions';

// Components
import CustomSnackbar, { AlertSeverity } from '../../Components/CustomSnackbar';
import ListoButton from '../../../NewComponents/Buttons/Listo/ListoButton';
import NextButton from '../../../NewComponents/Buttons/Next/NextButton';
import BackButton from '../../../NewComponents/Buttons/Back/BackButton';
import ErrorDialog from '../../../GeneralComponents/Modal/ErrorDialog';
import BookingModal from '../../../Reservas/Dialogs/BookingModal';
import WarningPopup from '../WarningPopup/WarningPopup';
import WizardItem from './WizardItem';

// Constants
import { SHIFTS_VIEW_MODE } from '../../../../constants/shiftsConstants';
import { ModalMode } from '../../../../constants/modalMode';
import { ShiftMode } from '../../../../constants';

// Material Ui
import { Stack, Box } from '@mui/material';

// Styles
import { LinearGradientBox } from '../../Styles/index';

// Utils
import { GetModalModeTurnos, ValidateInputs, getValidationErrorMessage } from './utils/wizardFunctions';
import { SetWaitList } from './utils/waitListFunctions';
import { SHIFTOPTIONS } from './utils/constants';
import { getModalType } from './utils/functions';

const lastStep = SHIFTOPTIONS[ SHIFTOPTIONS.length - 1 ].step;
const firstStep = SHIFTOPTIONS[ 0 ].step;

const Wizard = ( props ) => {  
  const [currentStep, setCurrentStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  const isLastStep = currentStep === lastStep;
  const isFirstStep = currentStep === firstStep;
  const { Component } = SHIFTOPTIONS.find(({ step }) => step === currentStep);

  // Variables
  const {
    shoModalDeactivateWaiList,
    showExitWithoutSaving,
    validationError,
    shoModalEdit,
    shiftData,
    isActive,
    error,
    mode,
  } = props;

  // Acciones
  const {
    setShowDeactivateWaitListModal,
    changeTurnoMaintainBookings,
    changeTurnoApplyFromDate,
    setShowExitWithoutSaving,
    changeShiftsViewMode,
    setAvailableLayouts,
    submitTurnoChanges,
    setValidationError,
    closeTurnosError,
    setWithWaitList,
    setTurnosError,
    setResetGroups,
    showEditModal,
    setSubshift,
  } = props;

  const [confirmDialogType, setConfirmDialogType] = useState( getModalType(mode, shiftData) );
  const [showConfirmDialog, setShowConfirmDialog] = useState( false );
  const [showModalSubshift, setShowModalSubshift] = useState( false );
  const [stepsOk, setStepsOk] = useState(
    mode === ShiftMode.EDIT ? 
      [true, true, true, true, true] 
    : 
      [false, false, false, false, false]
  );

  const saveInfo = () => {
    if ( mode === ShiftMode.EDIT ) {
      showEditModal( true );
    } else {
      submitTurnoChanges();
    }    
  };

  useEffect(() => {
    setResetGroups();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(() => {
        setConfirmDialogType( getModalType( mode, shiftData ) );
        if ( mode === ShiftMode.EDIT && shoModalEdit ) {
            setShowConfirmDialog( true );
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mode, shoModalEdit ]);

  if ( error !== "" ) {
    document.body.style.overflow = 'auto';
  };  

  const NextStep = ( step ) => {
    const newArr = [ ...stepsOk ];
    newArr[ step-2 ] = true;
    setStepsOk( newArr );
    setCurrentStep( step );
  };

  const handleStepChange = ( step ) => {
    if ( step < currentStep )
      NextStep( step );
    else 
      ValidateInputs( currentStep, shiftData, setValidationError, setShowAlert, setTurnosError, NextStep, step );
  };

  useEffect(() => {
    if ( validationError !== "" )
      setShowAlert( true );
    else 
      setShowAlert( false );
  }, [validationError]);

  const onCancel = () => {
        setShowConfirmDialog( false );
        setShowModalSubshift( false );
        showEditModal( false );

        if ( mode === ShiftMode.EDIT ) {
            if ( shoModalDeactivateWaiList ) {
              setShowDeactivateWaitListModal( false );
            } else if ( showExitWithoutSaving ) {
              setShowExitWithoutSaving( false );
            };

            if ( error === '' ) {
              changeTurnoMode({ mode: ShiftMode.VIEW, turno: null });
            };
        };  
    };

    const onFinish = ( date ) => {
      changeTurnoApplyFromDate(date);
      setShowConfirmDialog( false );
      // setShowModalSubshift(true);
      handleDeleteModal();
    };

    const submit = () => {           
      submitTurnoChanges();
    };

    const handleConfirmModal = () => {
      if (showExitWithoutSaving) {
        setShowExitWithoutSaving( false );        
      } else {
        changeTurnoMaintainBookings( true );
      };
      
      setShowModalSubshift( false );
      submit();
    };

    const handleDeleteModal = () => {
      if ( shoModalDeactivateWaiList ) {
        setShowDeactivateWaitListModal( false );
        setWithWaitList( false );
        SetWaitList( shiftData.availableLayouts, false, setAvailableLayouts, shiftData.subShifts, setSubshift );
      } else if ( mode === ShiftMode.EDIT ) {        
        changeTurnoMaintainBookings( false );
        submit();
      } else {        
        changeTurnoMaintainBookings( false );
      };

      setShowModalSubshift( false );
    };

    const handleBackModal = () => {
        if ( shoModalDeactivateWaiList ) {
          setShowDeactivateWaitListModal( false );
          setShowModalSubshift( false );
        } else if ( showExitWithoutSaving ) {
          changeTurnoMode({ mode: ShiftMode.VIEW, turno: null });
          changeShiftsViewMode( SHIFTS_VIEW_MODE.VIEW );
          setShowExitWithoutSaving( false );
          setShowModalSubshift( false );
        } else {
          changeTurnoMaintainBookings(true);
          submit();
        };
    };

  useEffect(() => {
    if( showExitWithoutSaving ){
      setShowModalSubshift( true );
    } else if( shoModalDeactivateWaiList ){
      handleDeleteModal();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoModalDeactivateWaiList, showExitWithoutSaving, stepsOk]);

  if ( isLastStep ) stepsOk[stepsOk.length - 1] = true;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', background: '#FCFCFC' }}>
      <Box
        sx={{
          marginTop: !isActive ? "5rem" : 0,
          padding: '1.5rem 0px 0px 45px',
          background: '#FCFCFC',
          height: 'max-content',
          alignItems: 'center',
          position: 'fixed',
          flexWrap: 'wrap',
          display: 'flex',
          width: '92%',
          left: '57px',
          top: '64px',
          gap: '10px',
          zIndex: 5,
        }}
      >
        {
          SHIFTOPTIONS.map(({ step, ...itemProps }) => {
            const isLastItem = step === lastStep;

            return (
              <WizardItem
                onClick={() => handleStepChange(step)}
                active={step === currentStep}
                isLastItem={isLastItem}
                stepsOk={stepsOk}
                step={step}
                key={step}
                {...itemProps}
              />
            );
          })
        }        
      </Box>
      <Box sx={ LinearGradientBox } ></Box>
      <Box sx={{ mb: '50px', mt: '75px' }}>
        <Component />
      </Box>
      <Box
        sx={{
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          width: 'max-content',
          position: 'fixed',
          left: '50%',
          bottom: 0,
        }}
      >
        <Stack direction="row" spacing={8} sx={{ backgroundColor: 'transparent', }}>
          {!isFirstStep && (
            <BackButton onClick={() => handleStepChange(currentStep-1)} />
          )}
          {isLastStep ? (
            <ListoButton onClick={() => saveInfo()} />
          ) : (
            <NextButton onClick={() => handleStepChange(currentStep+1)} />
          )}
        </Stack>
      </Box>
      <ErrorDialog
          onAccept={ closeTurnosError }
          type={ ModalMode.ERROR }
          onAcceptText={ "OK!" }
          open={ error !== "" }
          title={ "Atención!" }
          body={ error ?? "" }
          subBody={ "" }
      />
      <CustomSnackbar 
        message={ getValidationErrorMessage(validationError) } 
        severity={ AlertSeverity.ERROR } 
        setShowAlert={ setShowAlert } 
        showAlert={ showAlert } 
      />
      <BookingModal
        minDate={
          shiftData && moment(shiftData.startDate).isAfter(moment()) ? 
            moment(shiftData.startDate).toDate() 
          : 
            moment().toDate()
        }
        advanceStart={ shiftData && shiftData.advanceStart }
        show={ showConfirmDialog }
        type={ confirmDialogType }
        onCancel={ onCancel }
        onFinish={ onFinish }
      />
      {
          showModalSubshift ? 
            <WarningPopup 
              type={ GetModalModeTurnos(shoModalDeactivateWaiList, showExitWithoutSaving) }
              onclickConfirm={ handleConfirmModal }
              onclickDelete={ handleDeleteModal }
              onclickBack={ handleBackModal }
              onCancel={ onCancel } 
            />  
          : 
            null
      }        
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    shoModalDeactivateWaiList: state.v3.shifts.shoModalDeactivateWaiList,
    showExitWithoutSaving: state.v3.shifts.showExitWithoutSaving,
    validationError: state.v3.shifts.validationError,
    shoModalEdit: state.v3.shifts.shoModalEdit,
    isActive: state.sidebarManager.isActive,
    shiftData: state.v3.shifts.shiftData,      
    error: state.v3.shifts.error,
    mode: state.v3.shifts.mode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowDeactivateWaitListModal: (data) => dispatch(setShowDeactivateWaitListModal(data)),
    changeTurnoApplyFromDate: (endDate) => dispatch(changeTurnoApplyFromDate(endDate)),
    changeTurnoMaintainBookings: (data) => dispatch(changeTurnoMaintainBookings(data)), 
    setShowExitWithoutSaving: (data) => dispatch(setShowExitWithoutSaving(data)),         
    changeTurnoMode: ({mode, turno}) => dispatch(changeTurnoMode({mode, turno})), 
    changeShiftsViewMode: (data) => dispatch(changeShiftsViewMode(data)),                  
    setAvailableLayouts: (data) => dispatch(setAvailableLayouts(data)),
    setValidationError: (data) => dispatch(setValidationError(data)),
    setWithWaitList: (data) => dispatch(setWithWaitList(data)), 
    setTurnosError: (data) => dispatch(setTurnosError(data)),      
    submitTurnoChanges: () => dispatch(submitTurnoChanges()),
    showEditModal: (data) => dispatch(showEditModal(data)),      
    closeTurnosError: () => dispatch(closeTurnosError()),
    setSubshift: (data) => dispatch(setSubshift(data)),
    setResetGroups: () => dispatch( setResetGroups() ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
