import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {blue, green, grey, indigo} from "@material-ui/core/colors";
import {Grid, IconButton, Paper, Snackbar, Table, TableBody, TableContainer,} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import {
    addEditedImage,
    addNewProduct,
    changeEditProductDays,
    changeEditProductDescription,
    changeEditProductName,
    changeEditProductOrder,
    changeEditProductPrice,
    changeEditProductVisibility,
    changeSelectedCategory,
    editProducts,
    toggleCategoriesDialog,
    toggleDeleteProductDialog,
    toggleEditPricesDialog,
    toggleNewProductDialog,
} from "../../actions";

import {connect} from "react-redux";
import {createMenuLink} from "../../utils/dynamicLinks";

import ReactDragListView from "react-drag-listview";
import InfoGral from "../GeneralInfo/Info General/InfoGral";
import Dropdown from "../GeneralComponents/Dropdown/Dropdown";
import SaveChangesButton from "../NewComponents/Buttons/Save Changes/SaveChangesButton";
import ChangesPrice from "../NewComponents/Buttons/ChangesPrice/ChangesPrice";
import AddButton from "../NewComponents/Buttons/Add/AddButton";
import ShareMenu from "./ShareMenu/ShareMenu";
import ProductsTableHead from "./ProductsTableHead";
import ProductRow from "./ProductRow";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import {idAllCategories} from "../../constants";
import {arrayMove} from "react-sortable-hoc";

const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    categoryContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 500,
    },

    productCardImage: {
        width: "100%",
    },
    formControl: {
        marginTop: 10,
        marginBottom: 10,
        minWidth: 100,
    },
    descriptionContainer: {
        height: 40,
        overflow: "hidden",
    },
    daysContainer: {
        display: "flex",
        justifyContent: "center",
        alignContent: 'center',
        flexWrap: "wrap",
        width: "85%",
        textAlign: 'center',
        paddingLeft: '40px'
    },
    enabledDay: {
        background: indigo[600],
        borderRadius: 50,
        width: 20,
        height: 20,
        textAlign: "center",
        color: "white",
        margin: 3,
        cursor: "pointer",
    },
    disabledDay: {
        borderRadius: 50,
        width: 20,
        height: 20,
        textAlign: "center",
        margin: 3,
        cursor: "pointer",
    },
    submitButton: {
        height: 40,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },

    categoriesContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    newProductButton: {
        marginLeft: 15,
        marginTop: 10,
        marginBottom: 10,

        background: "#4caf50",
        color: "#fff",
        "&:hover": {
            background: "#357a38",
        },
    },
    editCategoriesButton: {
        height: '35px',
        width: '35px',
        background: grey[200],
        "&:hover": {
            background: grey[300],
        },
        marginLeft: '90px'
    },
    saveFloatingButton: {
        color: "white",
        background: blue[600],
        "&:hover": {
            background: blue[800],
        },
    },
    floatingButtons: {
        padding: 25,
        position: "fixed",
        bottom: 5,
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
    },
    changeAllPriceButton: {
        margin: 10,
        background: "#4caf50",
        color: "#fff",
        "&:hover": {
            background: "#357a38",
        },
    },
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 80,
        padding: "0px 30px 0px 0px",
        position: 'relative',
        zIndex: '0'
    },
    table: {
        position: 'relative',
        zIndex: '0'
    },
    dynamicContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexGrow: 2,
        marginLeft: '-40px'
    },
    dynamicTextfield: {
        flexGrow: 2,
    },

}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ProductsForm(props) {
    const classes = useStyles();

    // Variables
    const {
        categories,
        loading,
        changesInCategory,
        profile,
        selectedCategory,
        idPartnerSelected
    } = props;
    // Actions
    const {
        changeSelectedCategory,
        addNewProduct,
        addEditedImage,
        toggleCategoriesDialog,
        changeEditProductName,
        changeEditProductVisibility,
        changeEditProductDescription,
        changeEditProductPrice,
        changeEditProductDays,
        changeEditProductOrder,
        toggleDeleteProductDialog,
        editProducts,
        toggleEditPricesDialog,
    } = props;


    const getCurrentCategory = () => {
        if (
            selectedCategory !== idAllCategories &&
            selectedCategory !== undefined &&
            selectedCategory !== null
        ) {
            return categories.find((cat) => cat.id === selectedCategory);
        }
    }

    const getCurrentProducts = () => {
        if (!categories || categories.length <= 0) return undefined;
        if (
            selectedCategory !== idAllCategories &&
            selectedCategory !== undefined &&
            selectedCategory !== null
        ) {
            let cat = getCurrentCategory();
            if (cat) return [{id: cat.id, products: cat.products}];
        } else {
            let cat = [];
            categories.forEach((element) => {
                if (element.products)
                    cat = cat.concat({
                        id: element.id,
                        products: element.products,
                    });
            });
            return cat;
        }
        return undefined;
    };

    const [dynamicLink, setDynamicLink] = useState("");
    const [isInit, setIsInit] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const createDynamicLink = async () => {
        if (!isInit) {
            let link = await createMenuLink(idPartnerSelected);
            if (link !== null) {
                setDynamicLink(link);
                setIsInit(true);
            }
        }
    };

    createDynamicLink();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleDrag = (fromIndex, toIndex) => {
        let products = [
            ...categories.find((category) => category.id === selectedCategory)
                .products,
        ];
        const p = arrayMove(products, fromIndex - 1, toIndex - 1);
        changeEditProductOrder(selectedCategory, p);
    };

    const getTable = () => {
        return (
            <Table className={classes.table} aria-label="simple table">
                <TableBody>
                    <ProductsTableHead key="header-menu"
                                       showExtraColumn={!selectedCategory || selectedCategory === idAllCategories}/>
                    {
                        getCurrentProducts()?.map((category) =>
                            category.products?.map((row, index) => (
                                <ProductRow
                                    key={row.name}
                                    showSortColumn={!selectedCategory || selectedCategory === idAllCategories}
                                    row={row}
                                    index={index}
                                    category={category}

                                    onChangeName={changeEditProductName}
                                    onChangeVisibility={changeEditProductVisibility}
                                    onChangeDescription={changeEditProductDescription}
                                    onChangePrice={changeEditProductPrice}
                                    onChangeDays={changeEditProductDays}
                                    onChangeImage={addEditedImage}
                                    onDelete={toggleDeleteProductDialog}
                                />
                            ))
                        )}
                </TableBody>
            </Table>)
    };

    const getOptions = () => {
        let options = categories.map(category => ({
            id: category.id,
            value: category.id,
            name: category.name,
        }))
        options.push({
            id: idAllCategories,
            value: idAllCategories,
            name: "Todas"
        })
        return options
    }

    return (
        <Grid container style={{paddingLeft: '20px', paddingTop: '-60px'}}>
            <Grid item xs={12}>
                <div style={{width: '580px', marginLeft: '-20px'}}>
                    <InfoGral
                        firstLine={"Desde aquí podras cargar todas las categorias y platos"}
                        secondLine={"de tu menú, que los usuarios verán en la carta en tu perfil de Woki"}
                    />
                </div>
            </Grid>
            <Grid container item xs={12} justify={'flex-end'} spacing={2} style={{paddingLeft: '55px'}}>
                <Grid item>
                    <div style={{marginRight: '-15px'}}>
                        <ShareMenu
                            url={dynamicLink}
                            onCopy={(text, result) => result ? setOpenSnackBar(true) : console.log("No se copio el link")}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.header}>
                <div className={classes.categoriesContainer}>
                    <Grid container>
                        <Grid item xs={10}>
                            <div
                                style={{
                                    marginTop: '-28px',
                                    marginBottom: '10px',
                                    paddingLeft: '5px',
                                    width: '10px',
                                    color: '#848282',
                                    fontWeight: '600',
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                <p style={{margin: '0px'}}>Categorías</p>
                            </div>
                            <Dropdown
                                value={selectedCategory ? selectedCategory : idAllCategories}
                                options={getOptions()}
                                onChange={(value) => changeSelectedCategory(value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                className={classes.editCategoriesButton}
                                onClick={toggleCategoriesDialog}
                                disabled={loading}
                                aria-label="edit-categories"
                            >
                                <EditIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid container item xs={12} sm={4} className={classes.dynamicContainer} spacing={2}
                  style={{paddingRight: '2em', paddingBottom: '20px'}}>
                <Grid item xs={5}>
                    <ChangesPrice onClick={() => toggleEditPricesDialog()}/>
                </Grid>
                <Grid item xs={6}
                      style={{display: !selectedCategory || selectedCategory === idAllCategories ? 'none' : 'block'}}>
                    <AddButton text={"plato"} onClick={() => addNewProduct(selectedCategory)}/>
                </Grid>
            </Grid>

            {/**
             * Tabla de platos
             */}
            <Grid item xs={12} className={classes.tableContainer}>
                {
                    <TableContainer component={Paper} style={{borderRadius: '20px 20px 0px 0px'}}>
                        {!selectedCategory || selectedCategory === idAllCategories ? (
                            getTable()
                        ) : (
                            <ReactDragListView  handleSelector='a' onDragEnd={handleDrag}>
                                {getTable()}
                            </ReactDragListView>
                        )}
                    </TableContainer>
                }
            </Grid>

            <ProgressIndicator open={loading}/>

            <Grid container>
                {changesInCategory.includes(true) ? (
                    <SaveChangesButton onClick={editProducts}/>
                ) : null}
            </Grid>

            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Enlace a la carta copiado!
                </Alert>
            </Snackbar>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        loading: state.menuReducer.productsReducer.editReducer.loading,
        categories: state.menuReducer.productsReducer.editReducer.categories,
        selectedCategory:
        state.menuReducer.productsReducer.editReducer.selectedCategory,
        changesInCategory:
        state.menuReducer.productsReducer.editReducer.changesInCategory,
        profile: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSelectedCategory: (data) => dispatch(changeSelectedCategory(data)),

        addNewProduct: (data) => dispatch(addNewProduct(data)),

        changeEditProductName: (categoryId, productIndex, data) =>
            dispatch(changeEditProductName(categoryId, productIndex, data)),
        changeEditProductDescription: (categoryId, productIndex, data) =>
            dispatch(changeEditProductDescription(categoryId, productIndex, data)),
        changeEditProductPrice: (categoryId, productIndex, data) =>
            dispatch(changeEditProductPrice(categoryId, productIndex, data)),
        changeEditProductDays: (categoryId, productIndex, data) =>
            dispatch(changeEditProductDays(categoryId, productIndex, data)),
        changeEditProductVisibility: (categoryId, productIndex, data) =>
            dispatch(changeEditProductVisibility(categoryId, productIndex, data)),
        changeEditProductOrder: (categoryId, products) =>
            dispatch(changeEditProductOrder(categoryId, products)),
        addEditedImage: (categoryId, productIndex, data) =>
            dispatch(addEditedImage(categoryId, productIndex, data)),

        toggleDeleteProductDialog: (category, index) =>
            dispatch(toggleDeleteProductDialog(category, index)),

        editProducts: () => dispatch(editProducts()),
        toggleCategoriesDialog: () => dispatch(toggleCategoriesDialog()),
        toggleNewProductDialog: () => dispatch(toggleNewProductDialog()),

        toggleEditPricesDialog: () => dispatch(toggleEditPricesDialog()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsForm);
