import React from 'react';

const ShieldIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: '-.25rem', marginLeft: '-1.25rem'}}>
            <path d="M29.6 5.2C29.3 5 29 4.9 28.7 5.1C24.4 6.5 20 5.4 16.7 2.3C16.3 1.9 15.7 1.9 15.3 2.3C12 5.4 7.6 6.5 3.3 5.1C3 4.9 2.7 5 2.4 5.2C2.2 5.4 2 5.7 2 6C2 21.7 8.9 26.9 15.6 29.9C15.7 30 15.9 30 16 30C16.1 30 16.3 30 16.4 29.9C23.1 26.9 30 21.7 30 6C30 5.7 29.8 5.4 29.6 5.2ZM22.7 14.8L19.5 17.1L20.7 20.9C20.8 21.3 20.7 21.8 20.3 22C20.1 22.1 19.9 22.2 19.7 22.2C19.5 22.2 19.3 22.1 19.1 22L16 19.7L12.8 22C12.4 22.3 12 22.3 11.6 22C11.2 21.7 11.1 21.3 11.2 20.9L12.4 17.1L9.2 14.8C8.8 14.5 8.7 14.1 8.8 13.7C8.9 13.3 9.3 13 9.8 13H13.8L15 9.2C15.1 8.8 15.5 8.5 16 8.5C16.5 8.5 16.8 8.8 17 9.2L18.2 13H22.2C22.6 13 23 13.3 23.2 13.7C23.4 14.1 23.1 14.6 22.7 14.8Z" fill="#E7CC6E"/>
        </svg>
    )
}

export default ShieldIcon;