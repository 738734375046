import {Switch, withStyles} from "@material-ui/core";

export const DisableSwitch = withStyles({
    switchBase: {
        marginLeft: '4px',
        color: "#FFFFFF",
        marginTop: '4.5px',
        "&$checked": {
            marginLeft: '-1.5px',
            color: "#FFFFFF",
        },
        "&$checked + $track": {
            backgroundColor: '#B9B9B9',
            opacity: '100%',
        }
    },
    thumb: {
        height: 17.5,
        width: 17.5,
    },
    checked: {},
    track: {
        borderRadius: '10px',
        height: '1.27rem',
    }
})(Switch);
