export const SWITCH_SIGN_FORM = "SWITCH_SIGN_FORM";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "SIGNOUT_ERROR";
export const CHANGE_SIGNIN_EMAIL = "CHANGE_SIGNIN_EMAIL";
export const CHANGE_SIGNIN_PASSWORD = "CHANGE_SIGNIN_PASSWORD";
export const CHANGE_SIGNIN_ERROR = "CHANGE_SIGNIN_ERROR";

export const CHANGE_RESET_PASSWORD_EMAIL = "CHANGE_RESET_PASSWORD_EMAIL";
export const CHANGE_RESET_PASSWORD_ERROR = "CHANGE_RESET_PASSWORD_ERROR";


export const SWITCH_NEW_PASSWORD_DIALOG = "SWITCH_NEW_PASSWORD_DIALOG";
export const CHANGE_EDIT_USER_PASSWORD = "CHANGE_EDIT_USER_PASSWORD";
export const CHANGE_EDIT_USER_PASSWORD_TWO = "CHANGE_EDIT_USER_PASSWORD_TWO";
export const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS";
export const EDIT_PASSWORD_CANCEL = "EDIT_PASSWORD_CANCEL";
export const SET_ERROR_NEW_PASSWORD = "SET_ERROR_NEW_PASSWORD";
export const SET_ERROR_NEW_PASSWORD_TWO = "SET_ERROR_NEW_PASSWORD_TWO";