import React from 'react';

import { connect } from 'react-redux';

// Components
import { BlueSwitch } from '../../../../GeneralComponents/blueSwitch';
import { Title } from '../../Title';

// Material Ui
import { Box, Divider } from '@mui/material';

const GroupsItem = ( props ) => {

    // External props
    const { enabled, handleChangeActive, layout, amount } = props;

    const changeActive = () => {
        handleChangeActive( amount, enabled, layout );
    };

    return (
        <Box> 
            <Divider sx={{ borderColor: "#E7E7E7", width: "98%" }} />
            <Box 
                sx={{ 
                    justifyContent: 'start', 
                    flexDirection: 'row', 
                    alignItems: 'center',  
                    display: 'flex',
                    p: '.5rem 0' 
                }} 
            >
                <Box sx={{ mb: '8px' }}>
                    <BlueSwitch
                        onChange={() => changeActive( enabled )}
                        checked={ enabled }
                        color="default"
                    />    
                </Box>                        
                <Title style={{ fontSize: '18px'}} text={ amount } />
            </Box>            
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {            
        reservableSectors: state.v3.shifts.reservableSectors,
    }
};

export default connect( mapStateToProps, null )( GroupsItem );