import React from 'react';

// Material Ui
import { Box, Typography } from '@mui/material';

// Utils
import { showCountryFlag } from '../../../utils/CountryFlagsArray';

// Styles
import { PhoneTextSidebarStyles, TextDataSidebarStyles } from './Styles/StyleComponents';

const SidebarData = ({ data, nationality }) => {
    return (
        <>
            {
                nationality ?
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box className="flagContainer">{ showCountryFlag(nationality) }</Box>
                        <Typography sx={ PhoneTextSidebarStyles }>
                            { data }
                        </Typography>
                    </Box>
                :
                    <Box sx={ TextDataSidebarStyles }>{ data }</Box> 
            }
        </>
    )
}

export default SidebarData;