import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { setDwellTimeByTable } from "../../../../../actions/shiftsActions";

// Components
import TableDwellTimeItem from "./TableDwellTimeItem";
import Divider from "../components/Divider";
import { Title } from "../../Title";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { Box, Button, Modal, Stack, Typography, FormControl, Select, MenuItem, OutlinedInput} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { GetAdvancedModalOptions, SetDwellTimeTables } from "../utils/advancedOptionsFunctions";
import { itemStyle, modalStyles, modalTitle } from "../utils/styles/modalStyles";
import { dwellTimeDefaultOption } from "../utils/constants";
import { GetStyledItem } from "../utils/modalFunctions";
import { SettingsTablesStyles, ShiftTableSelectStyles } from "../../../Styles";

const useStyles = makeStyles(() => (modalStyles));

const AdvancedOptionsResidenceTimePerTableModal = (props) => {

  // Variables
  const {
    reservableSectors,
    dwellTimeByTable,
  } = props;

  // Acciones 
  const {
    setDwellTimeByTable,
    handleClose,
  } = props;
  
  const classes = useStyles();
  const newReservableSectors = reservableSectors.filter((reservableSector) => reservableSector.available);
  const [reservableSector, setReservableSector] = useState(newReservableSectors.length > 0 ? newReservableSectors[0] : null);
  const [dwellTimeTables, setDwellTimeTables] = useState([]);

  const ITEM_PADDING_TOP = 8;
  const ITEM_HEIGHT = 48;

  const MenuProps = {
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      },
      transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
      },
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              border: '1px solid #0B4762',
              fontFamily: 'Poppins',
              borderRadius: '10px',
              maxWidth: '15.75rem',
              width: 'max-content',
              marginTop: '.2rem',
              minWidth: '78px',
          },
      },
  };
  
  const handleChangeSector = (val) => {
    setReservableSector(newReservableSectors.find(reservableSector => reservableSector.id === val));
  }

  const handleApplyChanges = () => {
    SetDwellTimeTables(dwellTimeByTable, dwellTimeTables, setDwellTimeByTable);
    handleClose();
  }

  useEffect(() => {
    setDwellTimeTables(GetAdvancedModalOptions(reservableSector.id, reservableSector.tables, dwellTimeByTable, dwellTimeDefaultOption, ''));
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservableSector]);

  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dwellTimeTables)]);

  useEffect(() => {
  }, [dwellTimeByTable]);

  return (
    <Modal open>
      <Box className={classes.ModalContainer}>
        <Title text="Tiempo de permanencia por mesa" style={modalTitle} />
        <Stack direction="row" spacing="25px" mt={2} mb={2} justifyContent="left">
          <Box width="60%">
            <Box width="100%">
              <FormControl fullWidth>
                <Select
                  onChange={(e) => handleChangeSector(e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  labelId="demo-simple-select-label"
                  IconComponent={ ExpandMoreIcon }
                  sx={ ShiftTableSelectStyles }                    
                  input={ <OutlinedInput /> }
                  value={reservableSector.id}
                  MenuProps={ MenuProps }
                >
                  {
                    newReservableSectors.map((reservableSector) => (
                      <MenuItem key={reservableSector.id} value={reservableSector.id} className={itemStyle}>
                        {GetStyledItem(reservableSector.name, reservableSector.type)}
                        <Box sx={{ width: '25px' }}></Box>
                      </MenuItem> 
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Stack>     
        <Stack direction="row" justifyContent="center">
          <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }}/>
        </Stack>
        <Stack style={modalStyles.ItemsHeader}>
          <Box width="30%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle} >
                {`Mesas (${reservableSector.tables.length})`}
            </Typography>
          </Box>
          <Box width="10%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Mín
            </Typography>
          </Box>
          <Box width="10%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Máx
            </Typography>
          </Box>
          <Box width="50%" display="flex" flexDirection="row" justifyContent="center">
            <Typography className={classes.ItemsTitle}>
                Tiempo de permanencia
            </Typography>
          </Box>                
        </Stack>
        {/* <Box className={classes.ItemContainer} style={{height: "265px"}}> */}
        <Box sx={ SettingsTablesStyles }>
            {
              dwellTimeTables.map(dwellTimeTable => (
                <TableDwellTimeItem key={dwellTimeTable.id} dwellTimeTables={dwellTimeTables} dwellTimeTable={dwellTimeTable} setDwellTimeTables={setDwellTimeTables} />
              ))
            }
        </Box>
        {/* </Box><Box sx={ SettingsTablesStyles }> */}
        <Stack style={modalStyles.ButtonContainer}>
          <Button className={classes.CancelButton} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={classes.ApplyButton} onClick={handleApplyChanges}>
            Aplicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {            
    dwellTimeByTable: state.v3.shifts.shiftData.dwellTimeByTable,
    reservableSectors: state.v3.shifts.reservableSectors,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDwellTimeByTable: (data) => dispatch(setDwellTimeByTable(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)( AdvancedOptionsResidenceTimePerTableModal );
