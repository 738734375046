import React from "react";

import { connect } from "react-redux";

// Actions
import { setWaitListNote } from "../../../../../actions/shiftsActions";

// Components
import TextArea from "./General/TextArea";

// Material Ui
import { Box, Typography } from "@mui/material";

// Styles
import { ShiftSelectTitles } from "../../../Styles";

const WaitListClarifications = (props) => {

  // Variables
  const {
    waitListNote,
  } = props;

  // Acciones
  const {
    setWaitListNote,
  } = props;

  return (
    <Box mt={3} pb={2}>
      <Typography sx={ ShiftSelectTitles } >Aclaraciones de la lista de espera (Opcional)</Typography>
      <Box sx={{ maxWidth: '1220px', mt: 3, width: '98%' }}>
        <TextArea
            placeholder="Se aceptará su reserva apenas se libere un lugar para su tamaño de grupo"
            onChange={(e) => setWaitListNote(e.target.value)}
            description="Nota (Opcional)"
            text={ waitListNote }
            characters={ 300 }
            label={ "" }
          />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
      waitListNote: state.v3.shifts.shiftData.waitListNote,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWaitListNote: (data) => dispatch(setWaitListNote(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListClarifications);
