import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core';

// Utils
import {MinutesToDays, MinutesTohhss} from "../../../../../utils/formatters";

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        paddingLeft: '.8rem'
    },
    DatesContainer: {
        padding: '.1rem 0rem',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
    },
    DatesTitle: {
        fontSize: '14px',
        color: '#808080',
        width: '130px',
        margin: '0',
    },
    Span: {
        fontWeight: '700',
        textAlign: 'left',
        color: '#808080',
        fontSize: '14px',
        margin: '0',
    },
}));

const DetailsUp = (props) => {

    const classes = useStyles();

    const {
        name,
        opening,
        advanceStart,
        advanceEnd
    } = props

    return (
        <div className={classes.root}>
            <div className={classes.DatesContainer}>
                <p className={classes.DatesTitle}>Nombre del turno: </p>
                <p className={classes.Span}>{name}</p>
            </div>
            <div className={classes.DatesContainer}>
                <p className={classes.DatesTitle}>Horario de inicio: </p>
                <p className={classes.Span}>{opening + " hs."}</p>
            </div>
            <div className={classes.DatesContainer}>
                <p className={classes.DatesTitle}>Anticipo máximo: </p>
                <p className={classes.Span}>
                    {advanceStart >= 1440 ? MinutesToDays(advanceStart) + " días" : advanceStart >= 60 ? MinutesTohhss(advanceStart) + " hs." : advanceStart + " min."}
                </p>
            </div>
            <div className={classes.DatesContainer}>
                <p className={classes.DatesTitle}>Anticipo mínimo: </p>
                <p
                    className={classes.Span}
                >
                    {advanceEnd >= 1440 ? MinutesToDays(advanceEnd) + " días" : advanceEnd >= 60 ? MinutesTohhss(advanceEnd) + " hs." : advanceEnd + " min."}
                </p>
            </div>
        </div>
    )
}

export default DetailsUp
