import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { SECTOR_MODE } from "../../../../../constants";
import { LoadingButton } from "@mui/lab";

const useStyle = makeStyles(() => ({
  root: {},
}));

const FloatingButton = (props) => {
  const classes = useStyle(props);

  const { onClick } = props;
  const { mode, loading } = props;

  const [text, setText] = useState("Guardar cambios");

  useEffect(() => {
    if (mode === SECTOR_MODE.EDIT) {
      setText("Guardar cambios");
    } else if (
      mode === SECTOR_MODE.NEW_LAYOUT ||
      mode === SECTOR_MODE.NEW_SECTOR ||
      mode === SECTOR_MODE.NEW
    ) {
      setText("Agregar layout");
    }
  }, [mode]);

  return (
    <LoadingButton
      sx={{
        alignItems: "center",
        background: loading ? "white" : "#0B4762",
        border: "2px solid #0B4762",
        borderRadius: "8px",
        bottom: "10%",
        boxShadow: "15px 15px 35px rgba(11, 71, 98, 0.4)",
        color: loading ? "#0B4762" : "white",
        cursor: "pointer",
        display: "flex",
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        fontWeight: "600",
        height: "30px",
        KhtmlUserSelect: "none",
        left: "50%",
        MozUserSelect: "none",
        msUserSelect: "none",
        padding: "1.2rem",
        position: "fixed",
        textAlign: "center",
        transform: "translateX(-50%)",
        userSelect: "none",
        WebkitUserSelect: "none",
        zIndex: "3",
        textTransform: "none",
      }}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    >
      {text}
    </LoadingButton>
  );
};

FloatingButton.defaultProps = {
  index: "100000",
};

const mapStateToProps = (state) => {
  return {
    mode: state.v3.sectorsLayoutsTables.mode,
    loading: state.v3.sectorsLayoutsTables.loading,
  };
};

export default connect(mapStateToProps)(FloatingButton);
