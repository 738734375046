import React from 'react'
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    svg: {
        fill: "#848282",
        "&:hover": {
            fill: "#0076FF"
        }
    }
})

const Sort = (props) => {
    const classes = useStyles();
    return (
        <svg className={classes.svg} id="MOVE_LIST_ITEM" data-name="MOVE LIST ITEM" xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 30 35" >
            <rect  id="Rectángulo_2491" data-name="Rectángulo 2491" width="30" height="5" rx="2.5" transform="translate(0 20)"/>
            <rect  id="Rectángulo_2492" data-name="Rectángulo 2492" width="30" height="5" rx="2.5" transform="translate(0 10)"/>
            <path  id="Polígono_3" data-name="Polígono 3" d="M5,0l5,5H0Z" transform="translate(10)"/>
            <path  id="Polígono_4" data-name="Polígono 4" d="M5,0l5,5H0Z" transform="translate(20 35) rotate(180)"/>
        </svg>

    )
}

export default Sort