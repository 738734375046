import React from 'react'

const purple = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 37.452 62.058">
                <path id="walk" d="M21.633,11.493A5.606,5.606,0,0,1,17.51,9.8a5.542,5.542,0,0,1-1.69-4.057,5.524,5.524,0,0,1,1.69-4.057,5.876,5.876,0,0,1,8.246,0,5.52,5.52,0,0,1,1.69,4.057A5.538,5.538,0,0,1,25.756,9.8,5.609,5.609,0,0,1,21.633,11.493Zm-10.68,9.87-8.114,40.7H8.922L14.2,38.939l5.949,5.813V62.058h5.813V40.425l-6.083-5.813,1.758-8.654a20.025,20.025,0,0,0,15.819,7.166V27.444a13.947,13.947,0,0,1-7.234-1.96,13.689,13.689,0,0,1-5.071-5.068l-2.975-4.6a5.662,5.662,0,0,0-4.867-2.839,6.857,6.857,0,0,0-1.148.136,6.857,6.857,0,0,1-1.148.136L0,19.6V33.125H5.813V23.391l5.137-2.028Z" fill="#7c2bff"/>
            </svg>
        </div>
    )
}

export default purple
