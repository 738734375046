import React from 'react';

import moment from 'moment';

// Components

// Icons
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

// Material Ui
import { Box, Typography } from '@mui/material';

// Styles
import { PlanningDataContainerStyles, PlanningDaysTextStyles, PlanningDescriptionTextStyles, PlanningGreyDataContainerStyles, PlanningTitleDescriptionTextStyles } from './Styles';
import { DaysContainer } from '../../Styles';

const PlanningDatesView = ({
    openningExcludeDatesArray,
    excludePunctualDates,
    openningDatesArray,
    specialDatesArray,
    closingDatesArray,
    newPunctualDates,
    daysArray,
    start,
    type,
    days,
    end,
}) => {
    return (
        <>
            {
                type === 'openning' ? 
                    <Box sx={ PlanningGreyDataContainerStyles }>
                        <Typography sx={ PlanningDaysTextStyles } >Días seleccionados:</Typography>
                        <Box sx={ DaysContainer }>
                            {
                                daysArray.map(({ id, day }) =>
                                    <Box
                                        key={ `letra-${ id }` }
                                        sx={{
                                            border: days.includes( id ) ? '1px solid #041C32' : '1px solid #D1D1D1',
                                            color: days.includes( id ) ? '#041C32' : '#D1D1D1',
                                            fontFamily: 'Poppins, sans-serif',
                                            justifyContent: 'center',
                                            backgroundColor: '#FFF',
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            fontWeight: 600,
                                            fontSize:'17px',
                                            height: '37px',
                                            width: '37px',
                                            mr: '7px',
                                        }}
                                    >
                                        { day }
                                    </Box>
                            )}
                        </Box>
                    </Box>
                :
                    null
            }
            {
                ( type === 'openning' && newPunctualDates.length > 0 ) ?
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', m: '.5rem 0 .7rem 0' }}>
                        <Box sx={{ display: 'flex', mr: '.5rem', alignItems: 'center', gap: '5px' }}>
                            <ControlPointIcon sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                            <Typography sx={ PlanningTitleDescriptionTextStyles }>Fechas puntuales agregadas: </Typography>
                        </Box>
                        {
                            openningDatesArray?.map( ( item, index ) => 
                                { 
                                    return typeof item !== 'object' ? 
                                        <Typography
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        >
                                            { moment( item ).format('DD/MM/YYYY') }
                                            { 
                                                openningDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    openningDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span> 
                                            } 
                                        </Typography>
                                    :
                                        <Typography 
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        >
                                            <span>{ moment( item[0] ).format('DD/MM/YYYY') }</span>
                                            <span>&nbsp;-&nbsp;</span> 
                                            <span>{ moment( item[ item.length - 1 ] ).format('DD/MM/YYYY') }</span>
                                            { 
                                                openningDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    openningDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span> 
                                            }
                                        </Typography>
                                } 
                            )
                        }
                    </Box>
                :
                    null
            }
            {
                ( type === 'openning' && excludePunctualDates.length > 0 ) ?
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', m: '.5rem 0 .7rem 0' }}>
                        <Box sx={{ display: 'flex', mr: '.5rem', alignItems: 'center', gap: '5px' }}>
                            <RemoveCircleOutlineIcon  sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                            <Typography sx={ PlanningDescriptionTextStyles }>Fechas puntuales excluidas: </Typography>
                        </Box>
                        {
                            openningExcludeDatesArray.map( ( item, index ) => 
                                { 
                                    return typeof item !== 'object' ? 
                                        <Typography
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        >
                                            { moment( item ).format('DD/MM/YYYY') }
                                            { 
                                                openningExcludeDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    openningExcludeDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span>
                                            }
                                        </Typography>
                                    :
                                        <Typography 
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        >
                                            <span>{ moment( item[0] ).format('DD/MM/YYYY') }</span>
                                            <span>&nbsp;-&nbsp;</span> 
                                            <span>{ moment( item[ item.length - 1 ] ).format('DD/MM/YYYY') }</span>
                                            { 
                                                openningExcludeDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    openningExcludeDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span>
                                            }
                                        </Typography>
                                } 
                            )
                        }
                    </Box>
                :
                    null
            }
            {
                type === 'openning' ?
                    <Box sx={ PlanningDataContainerStyles }>
                        <Typography sx={ PlanningDescriptionTextStyles }>
                            Comienza el: { moment( start ).format('DD/MM/YYYY') } 
                            { end !== null ? ` | Finaliza el: ${ moment( end ).format('DD/MM/YYYY') }` : null }
                        </Typography>
                    </Box>
                :
                    null
            }
            {
                type === 'specialDates' ?
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', m: '.5rem 0' }}>
                        <Box sx={{ display: 'flex', mr: '.5rem', alignItems: 'center', gap: '5px' }}>
                            <StarOutlineIcon  sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                            <Typography sx={ PlanningDescriptionTextStyles }>Fechas especiales: </Typography>
                        </Box>
                        {
                            specialDatesArray.map( ( item , index ) => 
                                { 
                                    return typeof item !== 'object' ?
                                        <Typography
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        > 
                                            { moment( item ).format('DD/MM/YYYY') }
                                            { 
                                                specialDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    specialDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span> 
                                            } 
                                        </Typography>
                                    :
                                        <Typography 
                                            key={ index } 
                                            sx={{ 
                                                fontFamily: 'Poppins',
                                                fontSize: '.9rem',
                                                color: '#818181',
                                                display: 'flex', 
                                                fontWeight: 400, 
                                            }}
                                        >
                                            <span>{ moment( item[0] ).format('DD/MM/YYYY') }</span>
                                            <span>&nbsp;-&nbsp;</span> 
                                            <span>{ moment( item[ item.length - 1 ] ).format('DD/MM/YYYY') }</span>
                                            { 
                                                specialDatesArray.length === 1 ? 
                                                    null 
                                                : 
                                                    specialDatesArray.length === ( index + 1 ) ? 
                                                        null 
                                                    : 
                                                        <span>&nbsp;|&nbsp;</span>  
                                            }
                                        </Typography>
                                } 
                            )
                        }
                    </Box>
                :
                    null
            }
            {
                type === 'closing' ?
                <Box sx={{ display: 'flex', flexWrap: 'wrap', m: '.5rem 0' }}>
                    <Box sx={{ display: 'flex', mr: '.5rem', alignItems: 'center', gap: '5px' }}>
                        <CalendarTodayOutlinedIcon  sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                        <Typography sx={ PlanningDescriptionTextStyles }>Fechas puntuales excluidas: </Typography>
                    </Box>
                        {
                            closingDatesArray.map( ( item , index ) => 
                                    { 
                                        return typeof item !== 'object' ? 
                                            <Typography
                                                key={ index } 
                                                sx={{ 
                                                    fontFamily: 'Poppins',
                                                    fontSize: '.9rem',
                                                    color: '#818181',
                                                    display: 'flex', 
                                                    fontWeight: 400, 
                                                }}
                                            > 
                                                { moment( item ).format('DD/MM/YYYY') } 
                                                { 
                                                    closingDatesArray.length === 1 ? 
                                                        null 
                                                    : 
                                                        closingDatesArray.length === ( index + 1 ) ? 
                                                            null 
                                                        : 
                                                        <span>&nbsp;|&nbsp;</span>  
                                                }
                                            </Typography>
                                        :
                                            <Typography 
                                                key={ index } 
                                                sx={{ 
                                                    fontFamily: 'Poppins',
                                                    fontSize: '.9rem',
                                                    color: '#818181',
                                                    display: 'flex', 
                                                    fontWeight: 400, 
                                                }}
                                            >
                                                <span>{ moment( item[0] ).format('DD/MM/YYYY') }</span>
                                                <span>&nbsp;-&nbsp;</span> 
                                                <span>{ moment( item[ item.length - 1 ] ).format('DD/MM/YYYY') }</span>
                                                { 
                                                    closingDatesArray.length === 1 ? 
                                                        null 
                                                    : 
                                                        closingDatesArray.length === ( index + 1 ) ? 
                                                            null 
                                                        : 
                                                            <span>&nbsp;|&nbsp;</span> 
                                                }
                                            </Typography>
                                    } 
                                    
                            )
                        }
                    </Box>
                :
                    null
            }
        </>
    );
};

export default PlanningDatesView;