import React from 'react';

// Components
import DetailsMiddle from './DetailsMiddle';
import DetailsDown from './DetailsDown';
import DetailsUp from './DetailsUp';

// Material Ui
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    Root: {
        fontFamily: 'Poppins, sans-serif',
        width: '80%'
    },
    Title: {
        margin: '0rem 0rem .6rem .8rem',
        fontSize: '1.6rem',
        fontWeight: '500',
        color: '#041C32',
    },
    Subtitle: {
        color: '#0076FF',
        fontSize: '10px',
        margin: '0px',
    },
    Container: {
        borderBottom: '1px solid #D0D0D0'
    },
    Hr: {
        border: '1px solid #B9B9B9',
        margin: '1.5rem 0rem',
    }
}));

const Details = (props) => {

    const classes = useStyles();

    const {
        shift,
    } = props

    const {
        name,
        opening,
        advanceStart,
        advanceEnd,
        days,
        subShifts,
        sectors
    } = shift;

    return (
        <div className={classes.Root}>
            <h3 className={classes.Title}>Revisá el turno!</h3>
            <DetailsUp
                name={name}
                opening={opening}
                advanceStart={advanceStart}
                advanceEnd={advanceEnd}
            />
            <hr className={classes.Hr}/>
            <DetailsMiddle days={days} subShifts={subShifts}/>
            <hr className={classes.Hr}/>
            <DetailsDown sectors={sectors}/>
        </div>
    )
}

export default Details
