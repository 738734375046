import React from 'react';

import { connect } from 'react-redux';

// Icons
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Material Ui
import { Box, Typography } from '@mui/material';

// Styles
import { AddNewButtonStyles } from '../Styles';

const AddNewButton = ( props ) => {

  // External props
  const { type, onClick } = props;

  // States
  const { planningSnackbar } = props;

  const AddNewButtonIconStyles = {
    color: ( ( planningSnackbar.failedComponent === 'rangeDates' && type === 'range' ) || 
    (planningSnackbar.failedComponent === 'specialDatesArray' && type === 'addDate') ) ? '#FB3F4A' : '#0B4762', 
    fontSize: '1.1rem', 
    fontWeight: 600,
  };

  return (
    <Box sx={ AddNewButtonStyles } onClick={ onClick } >
      {
        type === 'addDate' ? <ControlPointIcon sx={ AddNewButtonIconStyles }/> : 
        type === 'range' ? <AccessTimeIcon sx={ AddNewButtonIconStyles }/> :
        <RemoveCircleOutlineIcon sx={ AddNewButtonIconStyles } />
      }
      <Typography 
        sx={{
          color: ( (planningSnackbar.failedComponent === 'rangeDates' && type === 'range') || 
          (planningSnackbar.failedComponent === 'specialDatesArray' && type === 'addDate') ) ? '#FB3F4A' : '#0B4762',
          textDecorationLine: 'underline',
          fontFamily: 'Poppins',
          fontWeight: 500,
        }}
      >
        {
          type === 'addDate' ? 'Agregar fecha' : 
          type === 'range' ? 'Agregar rango' :
          'Excluir fecha'
        }
      </Typography>
    </Box>
  )
};

const mapStateToProps = (state) => {
  return {
      planningSnackbar: state.planningReducer.planningSnackbar,
  };
};

export default connect( mapStateToProps, null )( AddNewButton );