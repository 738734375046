import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';
import MenuCategoriesForm from '../../components/Menu';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";

function MenuWokiPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Carta - Woki")
        props.changeShowSidebar(true);
    }, [])


    return (
        < >
            <Grid container>
                <Grid item xs={12}>
                    <MenuCategoriesForm/>
                </Grid>
            </Grid>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(MenuWokiPage);