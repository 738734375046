import React from 'react'
import '../css/ProductRow.css'

const ProductRow = (props) => {
    const {product} = props

    return (
        <div key={`${product.name}-${product.price}`} className='row'>
            <div className='imageContainer'>
                {product.imageUrl
                    ? <img src={product.imageUrl}
                           className='productImage' alt={product.name}/>
                    : null}
            </div>
            <div className='productContainer'>
                <div className='textContainer'>
                    <div className='titleContainer'>
                        <h3 className='title'>{product?.name}</h3>
                    </div>
                    <div className='descriptionContainer'>
                        <p className='description'>{product?.description}</p>
                    </div>
                </div>
                <div className='price'>
                    <h4>{`$${product.price}`}</h4>
                </div>
            </div>
        </div>
    )
}

export default ProductRow