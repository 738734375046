import React, {useEffect, useState} from 'react'

// Components
import InputWithCondition from '../../../../NewComponents/InputWhitCondition/InputWithCondition';
import Hours from "./Hours";

// Material Ui
import {Grid, makeStyles,} from "@material-ui/core";
import {connect} from "react-redux";
import {
    changeShiftAdvanceEnd,
    changeShiftAdvanceStart,
    changeShiftName,
    changeShiftOpenTime
} from "../../../../../actions";
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from "../../../../../actions/sidebarActions";

const useStyles = makeStyles(() => ({
    Title: {
        fontFamily: 'Poppins, sans-serif',
        margin: '0 0 2rem 0',
        fontSize: '1.4rem',
        fontWeight: '600',
    },
    InputsContainer: {
        alignItems: 'center',
        marginBottom: '1rem',
        display: 'flex',
        width: '100%',
        gap: '15px',
    }
}));

const BookingGralConfig = (props) => {

    const classes = useStyles();
    const [error, setError] = useState(false);

    // Variables
    const {shift} = props;
    const {name, opening, advanceStart, advanceEnd} = shift;

    // Metodos
    const {
        changeShiftName,
        changeShiftOpening,
        changeShiftAdvanceStart,
        changeShiftAdvanceEnd,
    } = props;

    const handleChangeName = (e) => {
        changeShiftName(e.target.value)
        if (e.target.value !== '') {
            setError(false);
        } else {
            setError(true);
        }
    }

    const handleChangeOpening = (data) => {
        if (data) {
            changeShiftOpening(data);
        }
    }

    const handleOnChangeAdvanceStart = (value) => {
        if (value < advanceEnd) {
            changeShiftAdvanceEnd(value);
        }
        changeShiftAdvanceStart(value)
    }

    const handleOnChangeAdvanceEnd = (value) => {
        if (value > advanceStart) {
            changeShiftAdvanceStart(value)
        }
        changeShiftAdvanceEnd(value);
    }

    useEffect(() => {
        props.changeTitleAppBar("Configuración del turno");
        props.changePrimaryTitleAppBar("Configuración del turno");
        props.changeShowSidebar(true);
    }, [props]);

    return (
        <Grid container spacing={0} style={{paddingTop: '1rem'}}>
            <h1 className={classes.Title}>Configuraciones generales</h1>
            <Grid item xs={12}>
                <div className={classes.InputsContainer}>
                    <InputWithCondition
                        onChange={handleChangeName}
                        text={'Nombre del turno'}
                        width={'40%'}
                        helperText={''}
                        value={name}
                        error={error}
                    />
                </div>
            </Grid>
            <Hours
                advanceStart={advanceStart}
                advanceEnd={advanceEnd}
                opening={opening}
                onChangeAdvanceStart={handleOnChangeAdvanceStart}
                onChangeAdvanceEnd={handleOnChangeAdvanceEnd}
                onChangeOpening={handleChangeOpening}
            />
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        shift: state.reservasReducer.shift,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeShiftName: (name) => dispatch(changeShiftName(name)),
        changeShiftOpening: (openTime) => dispatch(changeShiftOpenTime(openTime)),
        changeShiftAdvanceStart: (advanceStart) => dispatch(changeShiftAdvanceStart(advanceStart)),
        changeShiftAdvanceEnd: (advanceEnd) => dispatch(changeShiftAdvanceEnd(advanceEnd)),

        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingGralConfig);
