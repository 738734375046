import React from "react";

import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router-dom";

// Context
import { AuthContextProvider } from "./context";

// Styles
import "./App.css";

import {
  GENERAL_INFORMATION_ROUTE,
  CLIENTS_STATISTICS_ROUTE,
  MY_CONFIGURATION_ROUTE,
  BOOKING_POLITICS_ROUTE,
  BOOKING_CLIENTS_ROUTE,
  USER_MYINVOICES_ROUTE,
  BOOKING_SHIFTS_ROUTE,
  USER_ACCOUNT_ROUTE,
  SHARED_MENU_ROUTE,
  MY_CLIENTS2_ROUTE,
  MY_BOOKINGS_ROUTE,
  COMING_SOON_ROUTE,
  MY_CLIENTS_ROUTE,
  SECTORS_ROUTE_V3,
  MENU_WOKI_ROUTE,
  SHIFTS_ROUTE_V3,
  DISCOUNT_ROUTE,
  OPTIONAL_ROUTE,
  MENU_WEB_ROUTE,
  MESSAGES_ROUTE,
  MY_PLAN_ROUTE,
  OPENING_ROUTE,
  FILTERS_ROUTE,
  SECTORS_ROUTE,
  PHOTOS_ROUTE,
  LOGIN_ROUTE,
  USERS_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  QR_ROUTE,
} from "./constants";

// Material Ui
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

// Components
import GeneralInformationPage from "./pages/GeneralInformationPage";
import ClientsStatisticsPage from "./pages/ClientsStatisticsPage";
import SectorsAndTablePage from "./pages/V3/SectorsAndTablePage";
import BookingPoliticsPage from "./pages/BookingPoliticsPage";
import BookingClientsPage from "./pages/BookingClientsPage";
import MesaYaClientsPage from "./pages/MesaYaClientsPage";
import ShiftsPage from "./pages/V3/ShiftsPage/ShiftsPage";
import MesaYaConfigPage from "./pages/MesaYaConfigPage";
import SectorsPage from "./pages/SectorsAndTablesPage";
import UserAccountPage from "./pages/UserAccountPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import PublicMenuPage from "./pages/PublicMenuPage";
import OpeningHoursPage from "./pages/PlanningPage";
import Sidebar from "./components/Sidebar1/Sidebar";
import MyBookingsPage from "./pages/MyBookingsPage";
import MyClientsPage from "./pages/MyClientsPage";
import MessagesPage from "./pages/MessagesPage";
import ReservasPage from "./pages/ReservasPage";
import MenuWokiPage from "./pages/MenuWokiPage";
import GardRoute from "./components/GuardRoute";
import DiscountPage from "./pages/DiscountPage";
import OptionalPage from "./pages/OptionalPage";
import Notistack from "./components/Notistack";
import MenuWebPage from "./pages/MenuWebPage";
import FinancePage from "./pages/FinancePage";
import FilterPage from "./pages/FiltersPage";
import PhotosPage from "./pages/PhotosPage";
import MyInvoices from "./pages/MyInvoices";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import HelpPage from "./pages/HelpPage";
import Root from "./components/Root";
import QRPage from "./pages/QRPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0076FF",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightBold: 800,
  },
});

function App( props ) {

  // External props
  const { history } = props;

  return (
    <ConnectedRouter history={ history } >
      <Sidebar>
        <AuthContextProvider>
          <div style={{backgroundColor:"#FCFCFC"}}>
            <Root>
              <MuiThemeProvider theme={theme}>
                <Switch>
                  <GardRoute
                    type="public"
                    path={SHARED_MENU_ROUTE}
                    component={PublicMenuPage}
                  />
                  <GardRoute
                    type="public"
                    exact
                    path={LOGIN_ROUTE}
                    component={LoginPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={HOME_ROUTE}
                    component={HomePage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={GENERAL_INFORMATION_ROUTE}
                    component={GeneralInformationPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={FILTERS_ROUTE}
                    component={FilterPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={PHOTOS_ROUTE}
                    component={PhotosPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MENU_WOKI_ROUTE}
                    component={MenuWokiPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MENU_WEB_ROUTE}
                    component={MenuWebPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MY_CONFIGURATION_ROUTE}
                    component={MesaYaConfigPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MY_CLIENTS_ROUTE}
                    component={MesaYaClientsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={BOOKING_SHIFTS_ROUTE}
                    component={ReservasPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={QR_ROUTE}
                    component={QRPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={BOOKING_CLIENTS_ROUTE}
                    component={BookingClientsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MY_PLAN_ROUTE}
                    component={FinancePage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MESSAGES_ROUTE}
                    component={MessagesPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={SECTORS_ROUTE}
                    component={SectorsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={OPENING_ROUTE}
                    component={OpeningHoursPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={CLIENTS_STATISTICS_ROUTE}
                    component={ClientsStatisticsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={USER_ACCOUNT_ROUTE}
                    component={UserAccountPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={COMING_SOON_ROUTE}
                    component={ComingSoonPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={HELP_ROUTE}
                    component={HelpPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={DISCOUNT_ROUTE}
                    component={DiscountPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={USERS_ROUTE}
                    component={UsersPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={USER_MYINVOICES_ROUTE}
                    component={MyInvoices}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MY_BOOKINGS_ROUTE}
                    component={MyBookingsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={MY_CLIENTS2_ROUTE}
                    component={MyClientsPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={OPTIONAL_ROUTE}
                    component={OptionalPage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={BOOKING_POLITICS_ROUTE}
                    component={BookingPoliticsPage}
                  />
                  {/*V3 routes*/}
                  <GardRoute
                    type="private"
                    exact
                    path={SECTORS_ROUTE_V3}
                    component={SectorsAndTablePage}
                  />
                  <GardRoute
                    type="private"
                    exact
                    path={SHIFTS_ROUTE_V3}
                    component={ShiftsPage}
                  />
                  <GardRoute>
                    type={"unknown"}
                    <NotFound />
                  </GardRoute>
                </Switch>
              </MuiThemeProvider>
            </Root>
          </div>
        </AuthContextProvider>
      </Sidebar>
      <Notistack />
    </ConnectedRouter>
  );
};

export default App;
