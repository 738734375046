import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import SettingsNumbers from '../../GeneralComponents/SettingsNumbers';
import MinutesDropdown from '../../GeneralComponents/Dropdown/MinutesDropdown';

const useStyles = makeStyles(() => ({
    root: {
        width: '450px',
        height: '170px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        backgroundColor: '#F0F0F0',
        padding: '10px 0px 10px 15px',
    },
    title: {
        margin: '0px',
        fontSize: '11px',
        fontWeight: '600',
    },
    text: {
        margin: '0px',
        fontSize: '9px',
        color: '#848282',
        marginTop: '3px',
        textAlign: 'justify',
    },
    header: {
        margin: '0px',
    },
}));

const OPTIONS_QUANTITY = 10;

const SettingsTime = (props) => {

    const classes = useStyles();

    const {tolerance, closeTime,} = props;

    const {onChangeTolerance, onChangeCloseTime} = props;

    const getOptions = () => {
        let options = []
        for (let i=0; i<OPTIONS_QUANTITY; i++) {
            options.push({
                value: i < 4 ? i * 15 : (i - 3) * 60,
                name: i < 4 ? `${i * 15} min.` : `${i - 3} hs.`
            })
        }
        return options;
    }

    return (
        <div className={classes.root}>
            <h4 className={classes.header}>Ajustes de tiempo</h4>
            <Grid container spacing={2} style={{padding: '10px'}}>
                <Grid item xs={6} style={{paddingRight: '10px'}}>
                    <p className={classes.title}>Tolerancia</p>
                    <p className={classes.text}>Tiempo de espera máximo de un cliente para ingresar al establecimiento una vez llamado</p>
                    <div style={{marginTop: '10px'}}>
                        <SettingsNumbers name={"En minutos"} value={tolerance} onChange={onChangeTolerance}/>
                    </div>
                </Grid>
                <Grid item xs={6} style={{paddingRight: '25px'}}>
                    <p className={classes.title}>Cierre de Mesa Ya!</p>
                    <p className={classes.text}>Define cuanto tiempo antes del cierre se dejará de tomar solicitudes</p>
                    <div style={{marginTop: '24px'}}>
                        <MinutesDropdown value={closeTime} name={"En minutos / horas"} options={getOptions()} onSelect={(value) => onChangeCloseTime(value)}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SettingsTime
