import React from 'react';

// Components
import { BlueSwitch } from '../../GeneralComponents/blueSwitch';

// Icons
import DeleteOptionalIcon from '../../SvgComponents/icons/Optional/DeleteOptionalIcon';
import EditOptionalIcon from '../../SvgComponents/icons/Optional/EditOptionalIcon';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';

// Styles
import { PlanningNameStyles } from '../../Plannig/components/Plannings/Styles';
import { HoursStyles, TopShiftContainerStyles } from '../Styles';

// Utils
import { ConvertHHMM } from '../Shifts/Wizard/utils/functions';

const ShiftTopView = ({ turno, hasProgrammedDelete, handleDisable, handleEdit, handleDelete }) => {

    const labelStyle = {
        border: turno.isSpecial ? '1px solid #FCBF49' : '1px solid #0B4762',
        color: turno.isSpecial ? '#FCBF49' : '#0B4762',
        textTransform: 'uppercase',
        letterSpacing: '0.065em',
        justifyContent: 'center',
        boxSizing: 'border-box',
        fontFamily: 'Poppins',
        fontSize: '13.9241px',
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: '18.87',
        borderRadius: '8px',
        fontStyle: 'normal',
        fontWeight: 700,
        display: 'flex',
        height: '33px',
    };

    return (
        <Box sx={ TopShiftContainerStyles }>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                <Box sx={{ width: 'max-content', display: 'flex', alignItems: 'center', float: 'left' }}>
                    <Box sx={{ mt: '-.4rem' }}>
                        <BlueSwitch
                            checked={ hasProgrammedDelete ? false : turno.enable }
                            onChange={() => handleDisable(turno)}
                        />
                    </Box>
                    <Typography sx={ PlanningNameStyles }>{ turno.name }</Typography>
                </Box>
                <Typography sx={ HoursStyles }>
                    {`( ${ ConvertHHMM( turno.calendar.opening ) } - 
                        ${ ConvertHHMM( turno.calendar.closing ) } 
                    )`}
                </Typography>
            </Box>
            <Box display={"flex"}>
                <Box sx={{ width: '9.8rem', cursor: 'default' }}>
                    <Box sx={ labelStyle }>
                        { turno.isSpecial ? "TURNO ESPECIAL" : "TURNO NORMAL" }
                    </Box>
                </Box>
                <Box 
                    sx={{
                        pointeEvents: turno.deleted ? 'none' : 'auto',
                        alignItems: 'center', 
                        display: 'flex', 
                        gap: '10px', 
                        ml: '1rem', 
                    }}
                >
                    <IconButton onClick={ () => handleEdit( turno ) }>
                        <EditOptionalIcon />
                    </IconButton>
                    <IconButton onClick={ () => handleDelete( turno ) }>
                        <DeleteOptionalIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
};

export default ShiftTopView;