import {
    ADD_WEB_MENU,
    CHANGE_LINK_WEB_MENU,
    CHANGE_NAME_WEB_MENU,
    DELETE_WEB_MENU,
    ERROR_CHANGE_WEB_MENU,
    PARTNERS,
    SUCCESSFUL_CHANGE_WEB_MENU,
    TOGGLE_LOADING_WEB_MENU,
    TOGGLE_WEB_MENU_ACTIVE
} from "../../constants";


export const toggleLoadingWebMenu = () => ({
    type: TOGGLE_LOADING_WEB_MENU
})

export const toggleWebMenuActive = () => ({
    type: TOGGLE_WEB_MENU_ACTIVE
})

export const addWebMenu = (data) => ({
    type: ADD_WEB_MENU,
    payload: data
})

export const changeNameWebMenu = (data) => ({
    type: CHANGE_NAME_WEB_MENU,
    payload: data
})

export const changeLinkWebMenu = (data) => ({
    type: CHANGE_LINK_WEB_MENU,
    payload: data
})

export const deleteWebMenu = (data) => ({
    type: DELETE_WEB_MENU,
    payload: data
})

export const successfulChange = () => ({
    type: SUCCESSFUL_CHANGE_WEB_MENU,
})

export const errorChange = (data) => ({
    type: ERROR_CHANGE_WEB_MENU,
    payload: data
})

export const submitChanges = () => {
    return async  (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestoreReact = getFirestore();
        const { menuReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { menus, isMenuActive } = menuReducer.webReducer.editReducer


        dispatch(toggleLoadingWebMenu())

        try {
            if (Object.keys(menus).some((key) => key === undefined || menus[key] === undefined || key === "" || menus[key] === "")) {
                console.log("ERROR DE DATOS")
                throw Error("Error de datos");
            }
            await  firestoreReact.collection(PARTNERS).doc( idPartnerSelected ).update({
                menuFrom: menus,
                isMenuFromUrlEnabled: isMenuActive
            })
            dispatch(successfulChange())
        }
        catch (e) {
            dispatch(errorChange("Ocurrio un error. Por favor revise los datos y reintente mas tarde."))
        }
    }
}
