import React, {useEffect, useState} from 'react';

// Components
import {BlueSwitch} from '../../GeneralComponents/blueSwitch';

// Material UI
import {Backdrop, ClickAwayListener} from '@mui/material';
import {Button, makeStyles, TextField} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'Poppins',
        fontSize: '1.3rem',
        fontWeight: '600',
        color: '#041C32',
        marginTop: '15%',
    },
    boxAbsolute: ({isBannerActive}) => ({
        background: '#FCFCFC',
        position: 'fixed',
        padding: '.7rem',
        width: '20em',
        border: 'none',
        height: '100%',
        zIndex: '10',
        right: '0',
        top: isBannerActive ? '16vh' : '8vh',
        textAlign: 'center',
        minWidth: '320px'
    }),
    inputContainer: {
        marginBottom: '1rem',
        textAlign: 'end'
    },
    inputs: {
        backgroundColor: "white",
        marginBottom: '15px',
        fontFamily: 'Poppins',
    },
    inputGroup: {
        justifyContent: 'space-between',
        display: 'flex',
        fontFamily: 'Poppins',
    },
    arrowClose: {
        position: "absolute",
        cursor: "pointer",
        fontSize: "16px",
        color: "#041C32",
        right: "15px",
        top: "4%",
    },
    primaryButton: {
        fontFamily: 'Poppins',
        backgroundColor: '#0B4762',
        textTransform: 'none',
        fontWeight: '700',
        color: '#FFFFFF',
        fontSize: '16px',
        padding: '0.5em',
        marginTop: '3em',
        width: '90%',
        '&:hover': {
            backgroundColor: '#0f6389',
        },
    },
    secondaryButton: {
        backgroundColor: 'rgba(255, 0, 0, 0.15)',
        fontFamily: 'Poppins',
        border: '1px solid #EB252E',
        textTransform: 'none',
        fontWeight: '700',
        color: '#EB252E',
        fontSize: '16px',
        padding: '0.5em',
        marginTop: '1em',
        width: '90%',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.15) !important',
        },
    },
    helperText: {
        fontFamily: 'Poppins',
        color: 'red',
        backgroundColor: 'transparent'
    }
}));

const TableForm = (props) => {
    const classes = useStyles(props);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(undefined);
    const [minCapacity, setMinCapacity] = useState("");
    const [minCapacityError, setMinCapacityError] = useState(undefined);
    const [maxCapacity, setMaxCapacity] = useState("");
    const [maxCapacityError, setMaxCapacityError] = useState(undefined);

    const [autoAssign, setAutoAssign] = useState(false);
    const [shared, setShared] = useState(false);

    const {table, tables} = props;

    const {onClose, onSave, onDelete, open} = props;

    useEffect(() => {
        if (table) {
            setName(table.alias);
            setMinCapacity(table.minCapacity);
            setMaxCapacity(table.maxCapacity);
            setAutoAssign(table.autoAssign);
            setShared(table.shared);
        }
    }, [table]);

    function validate() {
        let errorOnName;
        let errorMinCapacity;
        let errorMaxCapacity;
        if (name.trim() === '') {
            errorOnName = "No puede ser vacio";
        } else if (tables.find(t => t.alias === name && t.alias !== table.alias) !== undefined) {
            errorOnName = "Ya existe una mesa con ese nombre";
        }
        if (String(minCapacity).trim() === '' || String(maxCapacity).trim() === '') {
            if (String(minCapacity).trim() === '') {
                errorMinCapacity = "Min no puede ser vacio";
            }
            if (String(maxCapacity).trim() === '') {
                errorMaxCapacity = "Max no puede ser vacio";
            }
        } else if (Number(minCapacity) > Number(maxCapacity)) {
            errorMinCapacity = "El minimo debe ser menor o igual al maximo";
            errorMaxCapacity = "El maximo debe ser mayor o igual al minimo";
        } else if (Number(maxCapacity) > 100) {
            errorMaxCapacity = "El maximo debe ser menor a 100";
        }

        setNameError(errorOnName);
        setMinCapacityError(errorMinCapacity);
        setMaxCapacityError(errorMaxCapacity);
        return errorOnName || errorMinCapacity || errorMaxCapacity;
    }

    const handleOnSave = () => {
        const thereAreErrors = validate();
        let minCapacityNumber = parseInt(minCapacity);
        let maxCapacityNumber = parseInt(maxCapacity);
        if (minCapacityNumber < 0) {
            minCapacityNumber = 0;
        }
        if (maxCapacityNumber < 0) {
            maxCapacityNumber = 0;
        }
        if (minCapacityNumber > maxCapacityNumber) {
            maxCapacityNumber = minCapacityNumber;
        }
        if (!thereAreErrors) {
            onSave(name.trim(), minCapacityNumber, maxCapacityNumber, autoAssign, shared)
        }
    }

    const handleChangeName = (e) => {
        setName(e.target.value);
        setNameError(undefined);
    }

    const handleChangeMinCapacity = (e) => {
        if (!isNaN(Number(e.target.value))) {
            if (!e.target.value.trim().startsWith('0')) {
                setMinCapacity(e.target.value)
                setMinCapacityError(false);
            }
        }
    }

    const handleChangeMaxCapacity = (e) => {
        if (!isNaN(Number(e.target.value))) {
            setMaxCapacity(e.target.value);
            setMaxCapacityError(false);
        }
    }

    return (
        <Backdrop
            sx={{color: '#A7A7A7', zIndex: (theme) => theme.zIndex.drawer}}
            open={open}
        >
            <ClickAwayListener onClickAway={onClose}>
                <div className={classes.boxAbsolute}>
                    <h1 className={classes.title}>
                        Edición de mesa
                    </h1>
                    <div style={{margin: '1em auto 0 auto', width: '90%', textAlign: 'center', alignItems: 'center'}}>
                        <div className={classes.inputContainer}>
                            <TextField
                                className={classes.inputs}
                                label="Número de mesa"
                                variant="outlined"
                                size='small'
                                value={name}
                                error={nameError}
                                onChange={handleChangeName}
                                fullWidth
                                style={{marginBottom: '0', paddingBottom: '0'}}
                            />
                            <span style={{
                                color: 'red',
                                fontSize: '10px',
                                fontFamily: 'Poppins',
                                backgroundColor: '#FCFCFC',

                            }}>{nameError ?? ""}</span>
                        </div>
                        <div className={classes.inputContainer} style={{textAlign: 'start'}}>
                            <div className={classes.inputGroup} style={{marginBottom: '0', paddingBottom: '0'}}>
                                <TextField
                                    className={classes.inputs}
                                    label="Min"
                                    type={'text'}
                                    variant="outlined"
                                    size='small'
                                    value={minCapacity}
                                    error={minCapacityError}
                                    onChange={handleChangeMinCapacity}
                                    style={{width: '40%', marginBottom: '0', paddingBottom: '0',}}
                                />
                                <TextField
                                    className={classes.inputs}
                                    label="Max"
                                    type={'text'}
                                    variant="outlined"
                                    size='small'
                                    value={maxCapacity}
                                    error={maxCapacityError}
                                    onChange={handleChangeMaxCapacity}
                                    style={{width: '40%', marginBottom: '0', paddingBottom: '0',}}
                                />
                            </div>
                            <span style={{
                                color: 'red',
                                fontSize: '10px',
                                fontFamily: 'Poppins',
                                backgroundColor: '#FCFCFC',
                            }}>{(minCapacityError || maxCapacityError) ?? " "}</span>
                        </div>
                    </div>
                    <div style={{paddingTop: '0', marginTop: '-10px', width: '90%', margin: "auto"}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center'
                        }}>
                            <h3 style={{
                                color: autoAssign ? '#041C32' : '#B2B2B2',
                                fontWeight: '500',
                                margin: '.8rem 0 0 0', fontFamily: 'Poppins'
                            }}>Reservable</h3>
                            <BlueSwitch checked={autoAssign} onChange={() => setAutoAssign(prevState => !prevState)}
                                        color="default"/>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center', fontFamily: 'Poppins'
                        }}>
                            <h3 style={{
                                color: shared ? '#041C32' : '#B2B2B2',
                                fontWeight: '500',
                                margin: '.8rem 0 0 0'
                            }}>Compartible</h3>
                            <BlueSwitch checked={shared} onChange={() => setShared(prevState => !prevState)}
                                        color="default"/>
                        </div>
                    </div>
                    <Button
                        className={classes.primaryButton}
                        variant="contained"
                        size='small'
                        onClick={handleOnSave}
                    >
                        Confirmar
                    </Button>
                    <Button
                        className={classes.secondaryButton}
                        variant="contained"
                        size='small'
                        onClick={onDelete}
                    >
                        Eliminar mesa
                    </Button>

                    <ClearIcon className={classes.arrowClose} fontSize='small' onClick={onClose}/>
                </div>
            </ClickAwayListener>
        </Backdrop>
    )
}

TableForm.propTypes = {
    open: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
    tables: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default TableForm;
