import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    CardIconSection: {
        border: '1px solid #0B4762',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        height: '4.5rem',
        display: 'flex',
        margin: '.2rem',
        width: '100%',
    }
}));

const PaymentIcon = (props) => {
    const classes = useStyles();
    const {index, isActive, onClick} = props;

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                fill: isActive ? '#FFFFFF' : '#0B4762'
            });
        }
        return child;
    });

    return (
        <Grid
            key={index}
            item
            xs={1}
            className={classes.CardIconSection}
            onClick={onClick}
            style={{
                backgroundColor: isActive ? '#0B4762' : '#FFFFFF'
            }}
        >
            {
                childrenWithProps
            }
        </Grid>
    )
}

export default PaymentIcon;
