import React from 'react'

const CovidSecure = (props) => {

    return (
        <div style={{position: 'absolute', bottom: '15%', left: '45%'}}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="35vw"
                 viewBox="0 0 760 100">
                <defs>
                    <filter id="Rectángulo_2680" x="0" y="0" width="760" height="100" filterUnits="userSpaceOnUse">
                        <feOffset dy="6" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="5" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Grupo_1714" data-name="Grupo 1714" transform="translate(-905 -951)">
                    <g transform="matrix(1, 0, 0, 1, 905, 951)" filter="url(#Rectángulo_2680)">
                        <rect id="Rectángulo_2680-2" data-name="Rectángulo 2680" width="730" height="70" rx="35"
                              transform="translate(15 9)" fill="#fff"/>
                    </g>
                    <path id="check-circle"
                          d="M29.287,13.822a1.421,1.421,0,0,0-.411-1.049L26.8,10.72a1.434,1.434,0,0,0-2.053,0L15.441,20l-5.155-5.155a1.434,1.434,0,0,0-2.053,0L6.157,16.9a1.421,1.421,0,0,0-.411,1.049,1.393,1.393,0,0,0,.411,1.027l8.256,8.256a1.4,1.4,0,0,0,1.027.433,1.429,1.429,0,0,0,1.049-.433L28.875,14.848a1.393,1.393,0,0,0,.411-1.027Zm5.748,3.7a17.137,17.137,0,0,1-2.349,8.793,17.436,17.436,0,0,1-6.375,6.375,17.137,17.137,0,0,1-8.793,2.349,17.137,17.137,0,0,1-8.793-2.349A17.436,17.436,0,0,1,2.349,26.31,17.137,17.137,0,0,1,0,17.517,17.137,17.137,0,0,1,2.349,8.724,17.436,17.436,0,0,1,8.724,2.349,17.137,17.137,0,0,1,17.517,0,17.137,17.137,0,0,1,26.31,2.349a17.436,17.436,0,0,1,6.375,6.375A17.137,17.137,0,0,1,35.034,17.517Z"
                          transform="translate(993 977)" fill="#0076ff"/>
                    <text id="Seguro_COVID_-_19" data-name="Seguro COVID - 19" transform="translate(1053 1002)"
                          fill="#0076ff" fontSize="22" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700">
                        <tspan x="0" y="0">Seguro COVID - 19</tspan>
                    </text>
                    <text id="Si_tu_local_cierra_por_pandemia_WOKI_no_te_va_a_generar_ningún_costo" data-name="Si tu local cierra por pandemia,
WOKI no te va a generar ningún costo" transform="translate(1310 974)" fill="#0076ff" fontSize="16"
                          fontFamily="SegoeUI, Segoe UI">
                        <tspan x="0" y="17">Si tu local cierra por pandemia,</tspan>
                        <tspan x="0" y="37">WOKI no te va a generar ningún costo</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

export default CovidSecure