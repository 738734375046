
export const SaveUserButtonStyles = {
    backgroundColor: '#0B4762',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '8px',
    p: '.4rem 1.2rem',
    color: 'white',
    zIndex: 4, 
    '&:hover': {
        backgroundColor: '#0B4762 !important'
    },
    '&:disabled': {
        color: 'white !important',
    },
};