import React from 'react'

const CheckIcons = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83334 12.8337L12.8333 19.8337L24.5 8.16699" stroke="#0B4762" stroke-width="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default CheckIcons
