import fileDownload from 'js-file-download';
import axios from 'axios';

import {
    CHANGE_FILTERS_BOOKINGSTATES_SELECTED,
    CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE,
    CHANGE_BOOKING_HISTORY_FILTER_ORIGIN,
    CHANGE_BOOKING_HISTORY_FILTER_STATE,
    BOOKINGS_MORE_INFO_FILTERS_SELECTED,
    CHANGE_BOOKING_HISTORY_FILTER_DATE,
    CHANGE_FILTERS_MOREINFO_SELECTED,
    CHANGE_BOOKINGS_COUNTRY_SELECTED,
    FETCH_BOOKINGS_FOR_TODAY_SUCCESS,
    CHANGE_BOOKINGS_ORIGIN_SELECTED,
    START_FETCHING_BOOKING_HISTORY,
    FETCH_BOOKING_HISTORY_FAILURE,
    FETCH_BOOKING_HISTORY_SUCCESS,
    CHANGE_BOOKING_HISTORY_QUERY,
    CHANGE_BOOKING_HISTORY_PAGE,
    CHANGE_BOOKINGS_ORDER,
} from '../../constants/bookingHistoryConstants';

export const startFetchingBookingHistory = () => ({
    type: START_FETCHING_BOOKING_HISTORY,
});

export const fetchBookingHistorySuccess = ({details, statistics, totalDocuments}) => ({
    type: FETCH_BOOKING_HISTORY_SUCCESS,
    payload: {details, statistics, totalDocuments},
});

export const fetchBookingsForTodaySuccess = ({details, statistics, totalDocuments}) => ({
    type: FETCH_BOOKINGS_FOR_TODAY_SUCCESS,
    payload: {details, statistics, totalDocuments},
});

export const fetchBookingHistoryFailure = (error) => ({
    type: FETCH_BOOKING_HISTORY_FAILURE,
    payload: error,
});

export const changeBookingHistoryFilterDate = ({from, to}) => ({
    type: CHANGE_BOOKING_HISTORY_FILTER_DATE,
    payload: {from, to},
});

export const changeBookingHistoryFilterOrigin = (origin) => ({
    type: CHANGE_BOOKING_HISTORY_FILTER_ORIGIN,
    payload: origin,
});

export const changeBookingHistoryFilterState = (state) => ({
    type: CHANGE_BOOKING_HISTORY_FILTER_STATE,
    payload: state,
});

export const changeBookingHistoryQuery = (query) => ({
    type: CHANGE_BOOKING_HISTORY_QUERY,
    payload: query,
});

export const changeBookingHistoryPage = (page) => ({
    type: CHANGE_BOOKING_HISTORY_PAGE,
    payload: page,
});

export const changeBookingHistoryRowsPerPage = (rowsPerPage) => ({
    type: CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE,
    payload: rowsPerPage,
});

export const setBookingCrountrySelected = ( data ) => ({
    type: CHANGE_BOOKINGS_COUNTRY_SELECTED,
    payload: data
});

export const setBookingOriginSelected = ( data ) => ({
    type: CHANGE_BOOKINGS_ORIGIN_SELECTED,
    payload: data
});

export const setMoreInfoFiltersSelect = ( data ) => ({
    type: BOOKINGS_MORE_INFO_FILTERS_SELECTED,
    payload: data
});

export const setMoreInfoBookingsFilters = ( data ) => ({
    type: CHANGE_FILTERS_MOREINFO_SELECTED,
    payload: data
});

export const setBookingStatesFilters = ( data ) => ({
    type: CHANGE_FILTERS_BOOKINGSTATES_SELECTED,
    payload: data 
});

export const changeBookingsOrder = ({ orderBy, orderType }) => ({
    type: CHANGE_BOOKINGS_ORDER,
    payload: { orderBy, orderType }
});

export const fetchBookingHistory = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { bookingHistoryReducer, userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;

        const { 
            moreInfoFiltersSelected,
            bookingStatesArray,
            dateRangeSelected, 
            countrySelected, 
            originSelected, 
            rowsPerPage,
            orderType, 
            orderBy, 
            query, 
            state, 
            page, 
        } = bookingHistoryReducer;

        dispatch( startFetchingBookingHistory() );

        try {
            const params = {
                partner: idPartnerSelected,
                orderType: orderType, 
                limit: rowsPerPage,
                orderBy: orderBy,
                page: page,
            };

            if ( query ) {
                params.query = query;
            };
            
            if ( state !== null ) {
                params.state = state;
            };

            if ( countrySelected !== undefined && countrySelected.trim() !== '' ) {
                params.country = countrySelected;
            };

            if ( dateRangeSelected[0].startDate !== undefined && dateRangeSelected[0].endDate !== undefined ) {
                params.from = dateRangeSelected[0].startDate;
                params.to = dateRangeSelected[0].endDate;
            };

            if ( originSelected !== undefined ) {
                params.origin = originSelected; 
            };

            if ( moreInfoFiltersSelected !== undefined ) {
                params.withTags = moreInfoFiltersSelected[0].value;
                params.withComment = moreInfoFiltersSelected[1].value;
                params.withPartnerComment = moreInfoFiltersSelected[2].value;
                params.withOptionals = moreInfoFiltersSelected[3].value;
                params.withClientNote = moreInfoFiltersSelected[4].value;
                params.withSpecialRelationship = moreInfoFiltersSelected[5].value;
                params.withAlimentaryRestrictions = moreInfoFiltersSelected[6].value;
                params.withLEHour = moreInfoFiltersSelected[7].value;
                params.withLESector = moreInfoFiltersSelected[8].value;
            };

            if ( bookingStatesArray !== undefined ) {
                params.bookingsArrived = bookingStatesArray[0].value;
                params.bookingsAccepted = bookingStatesArray[1].value;
                params.bookingsConfirm =  bookingStatesArray[2].value;
                params.bookingsConfirmationPending = bookingStatesArray[3].value;
                params.bookingsCreated = bookingStatesArray[4].value;
                params.bookingsEditedByClient = bookingStatesArray[5].value;
                params.bookingsEditedByPartner = bookingStatesArray[6].value;
                params.waitingActive = bookingStatesArray[7].value;
                params.waitingAccepted = bookingStatesArray[8].value;
                params.waitingExpired = bookingStatesArray[9].value;
                params.waitingDeletedByClient = bookingStatesArray[10].value;
                params.waitingDeletedByPartner = bookingStatesArray[11].value;
                params.bookingsNoArrived = bookingStatesArray[12].value;
                params.bookingsDeletedByUser = bookingStatesArray[13].value;
                params.bookingsDeletedByPartner = bookingStatesArray[14].value; 
                params.bookingsAutomaticReject = bookingStatesArray[15].value;
                params.bookingsRejected = bookingStatesArray[16].value;
                params.bookingsRejectedEdition = bookingStatesArray[17].value;
            };

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/details`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data;
            dispatch(fetchBookingHistorySuccess({details: data.details, totalDocuments: data.totalDocuments}));
        } catch (e) {
            dispatch(fetchBookingHistoryFailure(e));
        }
    }
};

export const fetchBookingTotalArrives = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { 
            bookingHistoryReducer, 
            userAccountsReducer,
        } = getState();

        const { editReducer } = userAccountsReducer;
        const { from, to } = bookingHistoryReducer;
        const { idPartnerSelected } = editReducer;

        dispatch(startFetchingBookingHistory());

        try {
            const params = {
                partner: idPartnerSelected,
                from: from.format(),
                to: to.format(),
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/total`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchBookingHistorySuccess({statistics: data}));
        } catch (e) {
            dispatch(fetchBookingHistoryFailure(e));
        }
    }
};

export const fetchBookingsForToday = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const { userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;

        dispatch(startFetchingBookingHistory());

        try {
            const params = {
                partner: idPartnerSelected,
                // startDate: startDate,
                // endDate: endDate
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/today`, {
                headers: {Authorization: `Bearer ${token}`},
                params: params
            });
            const data = res.data
            dispatch(fetchBookingsForTodaySuccess({details: data.details, totalDocuments: data.totalDocuments}));
        } catch (e) {
            dispatch(fetchBookingHistoryFailure(e));
        }
    }
};

export const fetchExportBookings = () => {
    return async ( dispatch, getState, {getFirebase} ) => {
        const { 
            bookingHistoryReducer, 
            userAccountsReducer, 
            firebase, 
        } = getState();

        const { profile } = firebase;
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;
        const { 
            // moreInfoFiltersSelected,
            dateRangeSelected, 
            countrySelected, 
            // originSelected, 
            rowsPerPage, 
            query, 
            state, 
            page, 
        } = bookingHistoryReducer;

        dispatch(startFetchingBookingHistory());

        try {
            const params = {
                partner: idPartnerSelected,
                limit: rowsPerPage,
                page,
            }

            if ( query ) {
                params.query = query;
            };
            
            if ( state !== null ) {
                params.state = state;
            };

            if ( countrySelected !== undefined && countrySelected.trim() !== '' ) {
                params.country = countrySelected;
            };

            if ( dateRangeSelected[0].startDate !== undefined && dateRangeSelected[0].endDate !== undefined ) {
                params.from = dateRangeSelected[0].startDate;
                params.to = dateRangeSelected[0].endDate;
            };

            // if ( originSelected !== undefined ) {
            //     params.origin = originSelected; 
            // };

            // if ( moreInfoFiltersSelected !== undefined ) {
            //     params.withTags = moreInfoFiltersSelected[0].value;
            //     params.withComment = moreInfoFiltersSelected[1].value;
            //     params.withPartnerComment = moreInfoFiltersSelected[2].value;
            //     params.withOptionals = moreInfoFiltersSelected[3].value;
            //     params.withClientNote = moreInfoFiltersSelected[4].value;
            //     params.withSpecialRelationship = moreInfoFiltersSelected[5].value;
            //     params.withAlimentaryRestrictions = moreInfoFiltersSelected[6].value;
            //     // params.withLEHour = moreInfoFiltersSelected[7].value;
            //     // params.withLESector = moreInfoFiltersSelected[8].value;
            // };
            
            console.log( 'PARAMS --> ', params );

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/export`, {
                headers: {Authorization: `Bearer ${token}`},
                responseType: 'blob',
                params: params
            });
            const data = res.data;

            if (res.status === 200) {
                fileDownload( res.data, `Reservas ${profile.name}.xlsx` );
            }

            dispatch(fetchBookingHistorySuccess({details: data.details, totalDocuments: data.totalDocuments}));
        } catch (e) {
            dispatch(fetchBookingHistoryFailure(e));
        }
    }
}

export const fetchExportBookingsForToday = () => {
    return async ( dispatch, getState, {getFirebase} ) => {
        const { 
            userAccountsReducer, 
            firebase, 
        } = getState();

        const { profile } = firebase;
        const { editReducer } = userAccountsReducer;
        const { idPartnerSelected } = editReducer;

        try {

            const queryParams = {
                partner: idPartnerSelected,
            }

            console.log( 'QUERY PARAMS --> ', queryParams );

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/statistics/booking/export/today`, {
                headers: {Authorization: `Bearer ${token}`},
                responseType: 'blob',
                params: queryParams,
            });
            const data = res.data;
            console.log( 'DATA --> ', data );

            if (res.status === 200) {
                fileDownload( res.data, `Reservas ${profile.name}.xlsx` );
            }

            // dispatch(fetchBookingsForTodaySuccess({details: data.details, totalDocuments: data.totalDocuments}));
        } catch (e) {
            dispatch(fetchBookingHistoryFailure(e));
        }
    }
}