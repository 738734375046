import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';

import MessagesDisplay from '../../components/Messages';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";


function MessagesPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Comunicación")
        props.changeShowSidebar(true);
    }, [props]);


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <MessagesDisplay />
                </Grid>
            </Grid>
        </>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(MessagesPage);
