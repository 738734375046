import React, { useState, useEffect } from "react";

// Components
import ShifHourWithArrowsSelect from "./General/ShifHourWithArrowsSelect";
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";
import Divider from "./Divider";

// Materila Ui
import { Box, Stack, Typography} from "@mui/material";
import { makeStyles } from "@material-ui/core";

// Utils
import { defaultDwellTimeGroupValue, dwellTimeOptions } from "../utils/constants";
import { modalStyles } from "../utils/styles/modalStyles";

const useStyles = makeStyles(() => (modalStyles));

const TableDwellTimeItem = ({dwellTimeTables, dwellTimeTable, setDwellTimeTables}) => {
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const [dwellValue, setDwellValue] = useState(dwellTimeTable.value);

    const handleChange = (active, id) => {
        const newDwellTimeTables = [...dwellTimeTables];
        const dwellTimeTable = newDwellTimeTables.find(dwellTimeTable => dwellTimeTable.id === id);
        dwellTimeTable.active = !active;

        if (!active === true) {
            if (id === 0) {
                const allDwell = newDwellTimeTables.find((newDwellTimeTable) => newDwellTimeTable.id === id);

                newDwellTimeTables.map((newDwellTimeTable) => {
                    if (newDwellTimeTable.id !== 0) {
                        newDwellTimeTable.active = false;
                        newDwellTimeTable.value = allDwell.value;
                    } else {
                        newDwellTimeTable.active = true;
                    }                        
                });
            } else {
                dwellTimeTable.value = defaultDwellTimeGroupValue;
            }
        }

        setActive(!active);
        setDwellTimeTables(newDwellTimeTables);
    }

    const handleChangeControl = ( id, value ) => {
        const newDwellTimeTables = dwellTimeTables.map(dwellTimeTable => {
            if (dwellTimeTable.id === id) {
                return {
                    ...dwellTimeTable,
                    active: active,
                    value: value,
                }
            } else {
                return dwellTimeTable;
            }
        })

        setDwellValue(value);
        setDwellTimeTables(newDwellTimeTables);
    }

    const GetDisabled = (id, dwellTimeTables) => {
        return (id !== 0 && dwellTimeTables[0].active);
    }

    const GetActive = (active, id, dwellTimeTables) => {
        return (active && id !== 0 && !dwellTimeTables[0].active) || (active && id === 0);
    }

    useEffect(() => {
        setActive(dwellTimeTable.active);
        setActive(dwellTimeTable.value);
    }, [dwellTimeTable])

    const disabled = GetDisabled(dwellTimeTable.id, dwellTimeTables);
    const switchActive = GetActive(dwellTimeTable.active, dwellTimeTable.id, dwellTimeTables);

    return (
        <>
            <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Divider mt={0} sx={{ borderColor: "#D1D1D1", width: "50%" }} />
            </Stack>
            <Stack direction="row" spacing="25px" mt={1} mb={1} justifyContent="center" alignItems={"center"}>
                <Box sx={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", pl: '1rem' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box marginBottom='8px'>
                            <BlueSwitch
                                onChange={() => handleChange(dwellTimeTable.active, dwellTimeTable.id)}
                                checked={switchActive}
                                color="default"
                                disabled={disabled}
                            />
                        </Box>
                        <Typography className={classes.ItemStyle}>
                            {dwellTimeTable.name}
                        </Typography>
                    </Box>
                </Box>
                <Box width="10%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.ItemStyle}>
                        {dwellTimeTable.min}
                    </Typography>
                </Box>
                <Box width="10%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.ItemStyle}>
                        {dwellTimeTable.max}
                    </Typography>
                </Box>
                <Box sx={{ width: '50%', pl: '1.5rem' }}>
                    <ShifHourWithArrowsSelect
                        handleChange={ handleChangeControl } 
                        optionsArray={ dwellTimeOptions }
                        groupKey={ dwellTimeTable.id } 
                        disabled={!switchActive}
                        value={ dwellValue }
                    />
                    {/* <FormControl disabled={!switchActive} >
                        <InputLabel id="demo-simple-select-label"/>
                        <Select
                            labelId="demo-simple-select-label"
                            value={dwellValue}                        
                            onChange={(e) => handleChangeControl(e.target.value, dwellTimeTable.id)}
                            sx={{ width: "155px", borderRadius: "10px", height: "43px" }}
                            inputProps={{
                                style: {
                                fontFamily: "Poppins, sans-serif",
                                color: "#041C32",
                                fontSize: "16px"
                                },
                            }}
                            MenuProps={{
                                style: {
                                fontFamily: "Poppins, sans-serif",
                                maxHeight: 200,
                                },
                            }}
                        >
                        {
                            dwellTimeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.option}</MenuItem>
                            ))
                        }                  
                        </Select>
                    </FormControl> */}
                </Box>
            </Stack>
        </>
    )
}

export default TableDwellTimeItem;