import React from 'react'

const DownArrow = (props) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width} height={props.height}
            viewBox="0 0 17.06 10.621">

            <path
                id="Trazado_324"
                data-name="Trazado 324"
                d="m 14.94,1.5000721 -6.717,7 -6.106,-7"
                fill="none"
                stroke="#0B4762"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"/>
        </svg>
    )
}

DownArrow.defaultProps = {
    width: '10px',
    height: '10px'
}

export default DownArrow
