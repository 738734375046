import {
    CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE,
    CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE,
    LOADING_USER_ACCOUNTS_SUCCESSFUL,
    CHANGE_EDIT_USER_ACCOUNT_MY_ROLE,
    CHANGE_EDIT_USER_ACCOUNT_ENABLE,
    CHANGE_EDIT_USER_ACCOUNT_EMAIL,
    CHANGE_EDIT_USER_ACCOUNT_NAME,
    EDIT_USER_ACCOUNTS_SUCCESSFUL,
    TOGGLE_LOADING_USER_ACCOUNTS,
    USER_SET_ID_PARTNER_SELECTED,
    USERS_CHANGE_USER_ROLE,
    USER_CHANGE_USER_ROLE,
    LOADING_USER_ACCOUNTS,
    SHOW_ERROR_DIALOG,
    EDIT_USER_ACCOUNT,
    SHOW_USER_ERROR_MODAL,
} from "../../constants/userAccountsConstants";
import { deepEqual } from "../../utils/objectHelpers";
import { CLOSE_ERROR_DIALOG } from "../../constants";
import { signOut } from "../authActions";
import axios from "axios";

export const editUserAccountsSuccessful = () => ({
    type: EDIT_USER_ACCOUNTS_SUCCESSFUL
});

export const toggleLoadingUserAccounts = () => ({
    type: TOGGLE_LOADING_USER_ACCOUNTS
});

export const changeEditUserAccountIndex = (data) => ({
    type: EDIT_USER_ACCOUNT,
    payload: data
});

export const changeUserAccountEnable = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_ENABLE,
    payload: data
});

export const changeUserAccountName = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_NAME,
    payload: data
});

export const changeUserAccountEmail = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_EMAIL,
    payload: data
});

export const changeUserAccountMyRole = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_MY_ROLE,
    payload: data
});

export const changeUserAccountBookingRole = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_BOOKING_ROLE,
    payload: data
});

export const changeUserAccountAdminRole = (data) => ({
    type: CHANGE_EDIT_USER_ACCOUNT_ADMIN_ROLE,
    payload: data
});

export const loadingUserAccounts = () => ({
    type: LOADING_USER_ACCOUNTS,
});

export const loadUserAccountsSuccess = (data) => ({
    type: LOADING_USER_ACCOUNTS_SUCCESSFUL,
    payload: data
});

export const showErrorDialog = (error) => ({
    type: SHOW_ERROR_DIALOG,
    payload: error
})

export const closeErrorDialog = () => ({
    type: CLOSE_ERROR_DIALOG,
});

export const setUserRole = ({ userRole }) => ({
    type: USER_CHANGE_USER_ROLE,
    payload: {
        userRole
    }
});

export const setEditUserRole = ({ partnerId, role, userId }) => ({
    type: USERS_CHANGE_USER_ROLE,
    payload: {
        partnerId,
        userId,
        role,
    }
});

export const setPartnerSelectedId = ({ id }) => ({
    type: USER_SET_ID_PARTNER_SELECTED,
    payload: {
        id
    }
});

export const setErrorModalState = ({ value }) => ({
    type: SHOW_USER_ERROR_MODAL,
    payload: {
        value
    }
});

export const addUserAccount = ({ name, email, password, userName }) => {
    return async ( dispatch, getState, { getFirebase }) => {
        const { userAccountsReducer } = getState();
        const { userRole, idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            dispatch(toggleLoadingUserAccounts());

            if (!idPartnerSelected) {
                throw new Error('Partner not found');
            };

            const newUser = {
                name: name,
                email: email,
                password: password,
                partner: {
                    role: userRole,
                    uuid: idPartnerSelected,
                    name: userName
                }
            };

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            await axios.put(`${API_URL}/users/${ idPartnerSelected }`, newUser, {headers: {Authorization: `Bearer ${token}`}});
            dispatch( toggleLoadingUserAccounts() );
            dispatch( editUserAccountsSuccessful() );
            dispatch( loadUserAccounts() );
        } catch (e) {
            console.log( e.response?.data ?? e );
            dispatch( showErrorDialog(e.response?.data ?? e) );
        };
    };
};

export const updateUsers = () => {
    return async ( dispatch, getState, { getFirebase, getFirestore } ) => {
        const { userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { originalUsers, users, idPartnerSelected } = editReducer;

        try {
            dispatch(toggleLoadingUserAccounts());

            if ( !idPartnerSelected ) {
                throw new Error('Partner not found');
            };

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            
            const usersToUpdate = users.filter( user => {
                const originalUser = originalUsers.find( originalUser => originalUser.id === user.id );
                return !deepEqual( originalUser, user );
            });

            // console.log( usersToUpdate );
            
            // for (let i = 0; i < usersToUpdate.partners.length; i++) {
            //     console.log( usersToUpdate.partners[i] );
            //     if ( usersToUpdate.partners[i].uuid === uid ) {
            //         usersToUpdate.partners[i].role = userRole;
            //     }
            // }
            await Promise.all(usersToUpdate.map(async user => {
                // user.partners[uid].role = userRole;
                console.log( user.partners );
                await axios.patch(`${API_URL}/users/${ idPartnerSelected }/${user.id}`, user, {headers: {Authorization: `Bearer ${token}`}})
            }));

            dispatch(editUserAccountsSuccessful());
            dispatch(toggleLoadingUserAccounts());
            dispatch(loadUserAccounts());
        } catch (e) {
            console.log(e.response?.data ?? e);
            dispatch(showErrorDialog(e.response?.data ?? e));
        };
    };
};

export const updateUser = ({ name, email, uid }) => {
    return async (dispatch, getState, { getFirebase }) => {
        const { userAccountsReducer } = getState();
        const { editReducer } = userAccountsReducer;
        const { userRole, idPartnerSelected } = editReducer;

        try {

            dispatch( toggleLoadingUserAccounts() );

            if ( !idPartnerSelected ) {
                throw new Error('Partner not found');
            };

            const updateUser = {
                role: userRole,
                email: email,
                name: name,
            };

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            await axios.patch(`${API_URL}/users/${idPartnerSelected}/${ uid }`, updateUser, {
                headers: { Authorization: `Bearer ${token}` }
            });

            dispatch( editUserAccountsSuccessful() );
            dispatch( toggleLoadingUserAccounts() );
            dispatch( loadUserAccounts() );

        } catch (e) {
            console.log( e.response?.data ?? e );
            dispatch( showErrorDialog( e.response?.data ?? e ) );
            dispatch( setErrorModalState({ value: true }) );
        };
    };
};

export const changeUserPassword = (user, password) => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;

        try {
            dispatch(toggleLoadingUserAccounts());

            if ( !idPartnerSelected ) {
                throw new Error('Partner not found');
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            await axios.patch(`${API_URL}/users/${ idPartnerSelected }/${user.id}`, {
                password: password
            }, {headers: {Authorization: `Bearer ${token}`}})
            dispatch(editUserAccountsSuccessful());
            dispatch(toggleLoadingUserAccounts());
            if (user.email === profile.email) {
                dispatch(signOut());
            } else {
                dispatch(loadUserAccounts());
            }
        } catch (e) {
            console.log(e.response?.data ?? e);
            dispatch(showErrorDialog(e.response?.data ?? e));
        };
    };
};

export const deleteUser = (uid) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const { firebase, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            dispatch(toggleLoadingUserAccounts());

            if (!idPartnerSelected) {
                throw new Error('Partner not found');
            }

            const API_URL = process.env.REACT_APP_API_URL;
            const firebaseAuth = getFirebase().auth();
            const token = await firebaseAuth.currentUser.getIdToken(true);
            await axios.delete(`${API_URL}/users/${idPartnerSelected}/${uid}`, {headers: {Authorization: `Bearer ${token}`}})
            dispatch(changeEditUserAccountIndex(-1));
            dispatch(editUserAccountsSuccessful());
            dispatch(toggleLoadingUserAccounts());
            if (uid === firebase.auth.uid) {
                dispatch(signOut());
            } else {
                dispatch(loadUserAccounts());
            }
        } catch (e) {
            console.log(e.response?.data ?? e);
            dispatch(showErrorDialog(e.response?.data ?? e));
        };
    };
};

export const loadUserAccounts = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            dispatch( loadingUserAccounts() );

            if ( !idPartnerSelected ) {
                throw new Error('Partner not found');
            };

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            const res = await axios.get(`${API_URL}/users/${ idPartnerSelected }`, 
                { headers: {Authorization: `Bearer ${token}`} }
            );
            const { data } = res;

            dispatch( loadUserAccountsSuccess(data) );

        } catch ( e ) {
            console.log( e.response?.data ?? e );
            dispatch( setErrorModalState({ value: true }) );
            dispatch( showErrorDialog(e.response?.data ?? e) );
        };
    };
};

