import React from "react";

const UserIcon = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1213 14.6949V13.1797C13.1213 12.3761 12.8021 11.6053 12.2338 11.037C11.6655 10.4687 10.8947 10.1494 10.091 10.1494H4.03033C3.22664 10.1494 2.45586 10.4687 1.88756 11.037C1.31927 11.6053 1 12.3761 1 13.1797V14.6949"
        stroke="#0B4762"
        strokeWidth="1.3889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0606 1.05957C6.25691 1.05957 5.48613 1.37884 4.91784 1.94713C4.34954 2.51543 4.03027 3.28621 4.03027 4.0899C4.03027 4.89359 4.34954 5.66437 4.91784 6.23267C5.48613 6.80096 6.25691 7.12023 7.0606 7.12023C7.8643 7.12023 8.63507 6.80096 9.20337 6.23267C9.77167 5.66437 10.0909 4.89359 10.0909 4.0899C10.0909 3.28621 9.77167 2.51543 9.20337 1.94713C8.63507 1.37884 7.8643 1.05957 7.0606 1.05957Z"
        stroke="#0B4762"
        strokeWidth="1.3889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

UserIcon.defaultProps = {
  width: "14",
  height: "16",
};

export default UserIcon;