import axios from "axios";

import {apiUrl} from "../constants";

const generateData = (link) => {
  return {
    dynamicLinkInfo: {
      domainUriPrefix: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN,
      link: link,
      androidInfo: {
        androidPackageName: process.env.REACT_APP_APPLICATION_ID,
        androidFallbackLink: link,
      },
      iosInfo: {
        iosBundleId: process.env.REACT_APP_APPLICATION_ID,
        iosFallbackLink: link,
      },
      navigationInfo: {
        enableForcedRedirect: false,
      },
    },
  };
}

export const createMenuLink = async (partnerId) => {
  if (!partnerId) return null;

  const link = process.env.REACT_APP_WEB_URL + "/sharepartner?type=carta&partnerid=" + partnerId;
  const requestData = generateData(link)

  try {
    const response = await axios.post(apiUrl, requestData);
    if (response && response.data && response.data.shortLink) {
      return response.data.shortLink;
    }
  }
  catch (e) {
    console.error(e)
  }

  return null;
};

export const createMesaYaLink = async (partnerId) => {
  if (!partnerId) return null;

  const link = process.env.REACT_APP_WEB_URL + "/sharepartner?type=mesa-ya&partnerid=" + partnerId;

  const requestData = generateData(link)

  try {
    const response = await axios.post(apiUrl, requestData);
    if (response && response.data && response.data.shortLink) {
      return response.data.shortLink;
    }
  }
  catch (e) {
    console.error(e)
  }

  return null;
};

export const createReservaLink = async (partnerId) => {
  if (!partnerId) return null;

  const link = process.env.REACT_APP_WEB_URL + "/sharepartner?type=reserva&partnerid=" + partnerId;

  const requestData = generateData(link)

  try {
    const response = await axios.post(apiUrl, requestData);
    if (response && response.data && response.data.shortLink) {
      return response.data.shortLink;
    }
  }
  catch (e) {
    console.error(e)
  }

  return null;
};
