import React, {useRef} from 'react'
import QRComponent from "./QRComponent";
import {useReactToPrint} from "react-to-print";
import CopyLink from "./CopyLink";
import QRIcon from "../../SvgComponents/icons/QRIcon";
import LightTooltip from "../../GeneralComponents/LightTooltip";

const ShareMenu = (props) => {
    const {url, title} = props;

    const {onCopy} = props;

    const componentRef = useRef();

    //Imprimir
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => ({
            size: "landscape"
        })
    });

    return (
        <div style={{display: 'flex', alignItem: 'center'}}>
            <div style={{width: '10%'}}>
                <p
                    style={{
                        margin: '0px',
                        marginRight: '10px',
                        fontSize: '14px',
                        color: '#848282'
                    }}
                >{title}:</p>
            </div>
            <div>
                <CopyLink
                    link={url}
                    onCopy={onCopy}
                />
            </div>
            <div style={{marginLeft: '25px', cursor: 'pointer'}}>
                <LightTooltip title="Código QR">
                    <div onClick={handlePrint}>
                        <QRIcon/>
                        <div style={{display: "none"}}>
                            <QRComponent ref={componentRef} link={url}/>
                        </div>
                    </div>
                </LightTooltip>
            </div>
        </div>
    )
}

export default ShareMenu
