export const TOGGLE_CATEGORIES_DIALOG = "TOGGLE_CATEGORIES_DIALOG";
export const TOGGLE_CATEGORIES_LOADING = "TOGGLE_CATEGORIES_LOADING";

export const CHANGE_SELECTED_CATEGORY = "CHANGE_SELECTED_CATEGORY";

export const TOGGLE_NEW_PRODUCT_DIALOG = "TOGGLE_NEW_PRODUCT_DIALOG";
export const TOGGLE_NEW_PRODUCT_LOADING = "TOGGLE_NEW_PRODUCT_LOADING";
export const TOGGLE_EDIT_PRICES_DIALOG = "TOGGLE_EDIT_PRICES_DIALOG";

export const TOGGLE_EDIT_PRODUCT_LOADING = "TOGGLE_EDIT_PRODUCT_LOADING";

export const CHANGE_EDIT_PRODUCT_NAME = "CHANGE_EDIT_PRODUCT_NAME";
export const CHANGE_EDIT_PRODUCT_VISIBILITY = "CHANGE_EDIT_PRODUCT_VISIBILITY";
export const CHANGE_EDIT_PRODUCT_DESCRIPTION = "CHANGE_EDIT_PRODUCT_DESCRIPTION";
export const CHANGE_EDIT_PRODUCT_PRICE = "CHANGE_EDIT_PRODUCT_PRICE";
export const CHANGE_EDIT_PRODUCT_DAYS = "CHANGE_EDIT_PRODUCT_DAYS";

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"

export const TOGGLE_DELETE_PRODUCT_DIALOG = "TOGGLE_DELETE_PRODUCT_DIALOG";
export const TOGGLE_DELETE_PRODUCT_LOADING = "TOGGLE_DELETE_PRODUCT_LOADING";

export const ADD_EDITED_IMAGE = "ADD_EDITED_IMAGE";


export const idAllCategories = "todas";

/* MENU WEB */

export const TOGGLE_LOADING_WEB_MENU = "TOGGLE_LOADING_WEB_MENU"
export const TOGGLE_WEB_MENU_ACTIVE = "TOGGLE_WEB_MENU_ACTIVE"
export const ADD_WEB_MENU = "ADD_WEB_MENU"
export const CHANGE_NAME_WEB_MENU = "CHANGE_NAME_WEB_MENU"
export const CHANGE_LINK_WEB_MENU = "CHANGE_LINK_WEB_MENU"
export const SUCCESSFUL_CHANGE_WEB_MENU = "SUCCESSFUL_CHANGE_WEB_MENU"
export const ERROR_CHANGE_WEB_MENU = "ERROR_CHANGE_WEB_MENU"
export const DELETE_WEB_MENU = "DELETE_WEB_MENU"