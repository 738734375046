import React from 'react';

// Material Ui
import Button from '@mui/material/Button';

const ButtonWithIcon = (props) => {

    const { icon, text, minWidth, onClick, margin } = props;

    return (
        <Button 
            style={{
                minWidth: minWidth ? minWidth : '11rem',
                fontFamily: 'Poppins, sans-serif',
                margin: margin ? margin : 'auto',
                backgroundColor: '#0B4762',
                textTransform: 'none',
                borderRadius: '10px',
                padding: '6px 12px',
                letterSpacing: '0',
                height: '2.5rem',
                color: 'white',
            }} 
            variant="contained" 
            onClick={onClick}
            startIcon={icon}
        >
            {text}
        </Button>
    )
}

export default ButtonWithIcon;