import React, {useEffect} from 'react';
import {Button} from "@mui/material";
import {connect} from "react-redux";
import {
    changeCombinationMaxCapacity,
    changeCombinationMinCapacity,
    changeCombinationName,
    editSectorCancelCombineTables,
    editSectorSaveCombineTables
} from "../../../actions";
import InputWithCondition from "../../NewComponents/InputWhitCondition/InputWithCondition";

const CombinationForm = (props) => {
    const {combineTables} = props;
    const [enableSave, setEnableSave] = React.useState(false);
    const {
        changeCombinationName,
        changeCombinationMaxCapacity,
        changeCombinationMinCapacity,
        cancelCombination,
        saveCombinedTables,
    } = props;

    const handleCancelCombination = () => {
        cancelCombination();
    }

    const handleEndCombination = () => {
        if (enableSave) {
            saveCombinedTables();
        }
    }

    useEffect(() => {
        setEnableSave(combineTables.tables.length >= 2 && !checkNameError() && !checkMaxCapacityError() && !checkMinCapacityError());
    }, [combineTables]);

    const checkNameError = () => {
        if (combineTables.tables.length === 0) {
            return false;
        }
        return combineTables.name.trim() === "";
    }

    const checkMinCapacityError = () => {
        if (combineTables.tables.length === 0) {
            return false;
        }
        return Number(combineTables.minCapacity <= 0) || Number(combineTables.minCapacity) > Number(combineTables.maxCapacity);
    }

    const checkMaxCapacityError = () => {
        if (combineTables.tables.length === 0) {
            return false;
        }
        return Number(combineTables.maxCapacity <= 0) || Number(combineTables.maxCapacity) < Number(combineTables.minCapacity);
    }

    const onChangeMinCapacity = (e) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(e.target.value) && e.target.value !== "") {
            return;
        }
        changeCombinationMinCapacity(e.target.value)
    }

    const onChangeMaxCapacity = (e) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(e.target.value) && e.target.value !== "") {
            return;
        }
        changeCombinationMaxCapacity(e.target.value)
    }

    return (
        <div style={{
            height: '62vh',
            margin: '0 10px',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
        }}>
            <div>
                <InputWithCondition
                    error={checkNameError()}
                    onChange={(e) => e.target.value.includes('.') ? null : changeCombinationName(e.target.value)}
                    value={combineTables.name}
                    id="table-combination-name"
                    helperText={""}
                    text="Nombre"
                    width="100%"
                />
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <InputWithCondition
                        error={checkMinCapacityError()}
                        onChange={onChangeMinCapacity}
                        value={combineTables.minCapacity}
                        id="table-combination-min"
                        helperText={""}
                        text="Mín"
                        width="100%"
                    />
                    <div style={{margin: '0 10px'}}/>
                    <InputWithCondition
                        error={checkMaxCapacityError()}
                        onChange={onChangeMaxCapacity}
                        value={combineTables.maxCapacity}
                        id="table-combination-max"
                        helperText={""}
                        text="Máx"
                        width="100%"
                    />
                </div>
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex', flexDirection: 'column', width: '100%'
            }}>
                <Button
                    variant="contained"
                    size='small'
                    style={{
                        backgroundColor: enableSave ? '#0B4762' : "#D8D8D8",
                        color: enableSave ? '#FFFFFF' : "#A0A0A0",
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'none',
                        fontSize: '16px',
                        padding: '0.5em',
                        marginTop: '1em',
                        width: '85%',
                        borderRadius: '8px',
                    }}
                    disabled={!enableSave}
                    onClick={handleEndCombination}
                >
                    Listo
                </Button>
                <Button
                    size={'small'}
                    variant={"text"}
                    onClick={handleCancelCombination}
                    sx={{
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'none',
                        marginTop: '.3rem',
                        fontWeight: '500',
                        fontSize: '.9rem',
                        color: '#0B4762',
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    }}
                >
                    <u>Cancelar</u>
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        combineTables: state.sectorsReducer.sectors.newCombineTables,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCombinationName: (name) => dispatch(changeCombinationName(name)),
        changeCombinationMaxCapacity: (maxCapacity) => dispatch(changeCombinationMaxCapacity(maxCapacity)),
        changeCombinationMinCapacity: (minCapacity) => dispatch(changeCombinationMinCapacity(minCapacity)),
        cancelCombination: () => dispatch(editSectorCancelCombineTables()),
        saveCombinedTables: () => dispatch(editSectorSaveCombineTables()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CombinationForm);
