import React from 'react';

const BookingDateIcon = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6001 2.69991H2.70001C1.76112 2.69991 1 3.46104 1 4.39993V16.3C1 17.2389 1.76112 18 2.70001 18H14.6001C15.539 18 16.3001 17.2389 16.3001 16.3V4.39993C16.3001 3.46104 15.539 2.69991 14.6001 2.69991Z" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0498 1V4.40003" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.25 1V4.40003" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 7.80017H16.3001" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.475 10.6336L7.65383 15.4547L5.4624 13.2633" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BookingDateIcon;