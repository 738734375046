import React, { useEffect } from "react";
import { connect } from "react-redux";

// Actions
import {
  changePrimaryTitleAppBar,
  changeShowSidebar,
  changeTitleAppBar,
} from "../../../actions/sidebarActions";
// Material Ui
import { Grid } from "@material-ui/core";

// Components
import SectorsForm from "../../../components/V3/SectorsLayoutsTables";
import { SECTOR_MODE } from "../../../constants";

function SectorsPage(props) {
  const { changeTitleAppBar, changePrimaryTitleAppBar, changeShowSidebar } =
    props;

    const { mode } = props;

  useEffect(() => {
    if (mode === SECTOR_MODE.VIEW) {
      changeTitleAppBar("Sectores y layouts");
      changeShowSidebar(true);
      changePrimaryTitleAppBar(
        "Creá los sectores de tu establecimiento con sus respectivos layouts"
      );
    }
  }, [props, changeTitleAppBar, changePrimaryTitleAppBar, changeShowSidebar, mode]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <SectorsForm />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    mode: state.v3.sectorsLayoutsTables.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
    changePrimaryTitleAppBar: (data) =>
      dispatch(changePrimaryTitleAppBar(data)),
    changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorsPage);
