import React from 'react';

// Material Ui
import Chip from '@mui/material/Chip';

const ChipsWithCross = (props) => {

    const { text, onDelete } = props;

    return (
        <Chip 
            label={text} 
            variant="outlined" 
            onDelete={onDelete} 
            sx={{
                fontFamily: 'Poppins, sans-serif',
                border: '1px solid #0B4762',
                backgroundColor: '#FFFFFF',
                fontWeight: '500',
                fontSize: '16px',
                color: '#0B4762',
                ['@media (max-width: 1700px)']: {
                    margin: '.2rem',
                }
            }}
        />
    )
}

export default ChipsWithCross;