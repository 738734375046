import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";

const SectorSkeleton = () => {
  return (
    <Box>
      <Divider sx={{ borderColor: "#E7E7E7" }} />
      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        alignItems={"flex-start"}
        sx={{ fontFamily: "Poppins !important" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={2}
          padding="0.5rem 0.5rem 0.5rem 0"
          alignItems={"center"}
        >
          <Typography variant="h4">
            <Skeleton width={100} height={30} />
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#F5F5F5",
              color: "#0B4762",
            //   border: "1.5px solid #0B4762",
              padding: "0.2rem 0.5rem",
            }}
          >
            <Typography variant="h6">
              <Skeleton width={100} height={20} />
            </Typography>
          </Box>
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
        <Typography variant="h6">
          <Skeleton width={200} height={20} />
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2} marginTop={"0.5rem"}>
          {
            new Array(5).fill(0).map((_, idx) => (
                <Skeleton width={350} height={155} sx={{transform: 'none'}} key={idx}/>
            ))
          }
        </Box>
      </Box>
    </Box>
  );
};

export default SectorSkeleton;