import React from 'react';

const ShieldDataIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.825 4.7125C26.5531 4.53125 26.2812 4.44062 26.0094 4.62187C22.1125 5.89062 18.125 4.89375 15.1344 2.08438C14.7719 1.72188 14.2281 1.72188 13.8656 2.08438C10.875 4.89375 6.8875 5.89062 2.99062 4.62187C2.71875 4.44062 2.44688 4.53125 2.175 4.7125C1.99375 4.89375 1.8125 5.16563 1.8125 5.4375C1.8125 19.6656 8.06563 24.3781 14.1375 27.0969C14.2281 27.1875 14.4094 27.1875 14.5 27.1875C14.5906 27.1875 14.7719 27.1875 14.8625 27.0969C20.9344 24.3781 27.1875 19.6656 27.1875 5.4375C27.1875 5.16563 27.0063 4.89375 26.825 4.7125ZM20.5719 13.4125L17.6719 15.4969L18.7594 18.9406C18.85 19.3031 18.7594 19.7563 18.3969 19.9375C18.2156 20.0281 18.0344 20.1188 17.8531 20.1188C17.6719 20.1188 17.4906 20.0281 17.3094 19.9375L14.5 17.8531L11.6 19.9375C11.2375 20.2094 10.875 20.2094 10.5125 19.9375C10.15 19.6656 10.0594 19.3031 10.15 18.9406L11.2375 15.4969L8.3375 13.4125C7.975 13.1406 7.88438 12.7781 7.975 12.4156C8.06563 12.0531 8.42813 11.7812 8.88125 11.7812H12.5063L13.5938 8.3375C13.6844 7.975 14.0469 7.70312 14.5 7.70312C14.9531 7.70312 15.225 7.975 15.4062 8.3375L16.4938 11.7812H20.1188C20.4813 11.7812 20.8438 12.0531 21.025 12.4156C21.2063 12.7781 20.9344 13.2312 20.5719 13.4125Z" fill="#EEB115"/>
        </svg>
    )
}

export default ShieldDataIcon;