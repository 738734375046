import React from 'react';

// Components
import InputWithCondition from "../../NewComponents/InputWhitCondition/InputWithCondition";
import SimpleTrashIcon from '../../SvgComponents/icons/TrashIcons/SimpleTrashIcon';

const UrlItem = (props) => {

    const { urlName, url, handleDeleteUrlItem, index } = props;

    return (
        <div 
            style={{
                padding: '.5rem 0', 
                display: 'flex', 
                width: '100%', 
                gap: '15px'
            }}
        >
            <InputWithCondition
                text={'Nombre'}
                helperText={''}
                width={'35%'}
                value={urlName}
            />
            <InputWithCondition
                text={`URL de link ${index}`}
                helperText={''}
                width={'58%'}
                value={url}
            />
            <SimpleTrashIcon 
                onClick={handleDeleteUrlItem}
                marginTop={'-.4rem'}
            />
        </div>
    )
}

export default UrlItem;