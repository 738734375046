import { RRule } from "rrule";
import { GetMomentDate, getRRuleDay } from "./functions";
import moment from "moment";
import { changeStartDay } from "../../../../../actions/shiftsActions";

export const getRRule = (dtstart, until, weeksNumber, days) => {
    const byweekday = [];
    days.map(day => (
        byweekday.push(getRRuleDay(day))
    ))

    return new RRule({
        dtstart: new Date(dtstart),
        until: until,
        freq: RRule.WEEKLY,
        interval: weeksNumber, 
        byweekday: byweekday
    });
}

const gettRRuleFromString = (strRule) => {
    return RRule.fromString(strRule);
}

export const getWeekInterval = (repeat) => {
    const obj = gettRRuleFromString(repeat);

    return obj.origOptions.interval;
}

export const getFormattedDate = (date) => {
    if (typeof date === 'string' && date.includes(' - ')) {
        const splittedDate = date.split(' - ');
        // Obtener la fecha formateada en "dd/MM/yyyy - dd/MM/yyyy"
        return moment(splittedDate[0]).format('DD/MM/YYYY') + ' - ' + moment(splittedDate[1]).format('DD/MM/YYYY');
    } else {
        // Obtener la fecha formateada en "dd/MM/yyyy"
        return moment(date).format('DD/MM/YYYY');
    }   
};

export const FormattedDate = (date) => {
    return moment(date).startOf('date').utc().toISOString();
}

const GetDateRange = (startDate, endDate) => {
    const dates = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    dates.push(FormattedDate(currDate.clone().toDate()));

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(FormattedDate(currDate.clone().toDate()));
    }

    dates.push(FormattedDate(lastDate.clone().toDate()));

    return dates;
}

const IsRange = (selectedDate) => {
    if (typeof selectedDate === 'string') {
        const dates = selectedDate.split(' - ');
        return dates.length === 2;
    } else {
        return false;
    }
}

export const HandleChangeDates = ( selectedDates, datesFunction ) => {
    const dateArray = [];

    // eslint-disable-next-line array-callback-return
    selectedDates.map((selectedDate) => {
        // Is range
        if ( IsRange(selectedDate) ) {
            const splittedDate = selectedDate.split(' - ');
            const dateRange = GetDateRange(splittedDate[0], splittedDate[1]);
            const newDateRange = dateRange.filter((date) => !selectedDates.includes(FormattedDate(date)));

            dateArray.push(...newDateRange);

        } else {
            dateArray.push( FormattedDate(selectedDate) );
        }
    });

    changeStartDay( GetMomentDate( dateArray[0] ) );

    datesFunction( dateArray );
}

export const DateExists = (selectedDates, date) => {
    const newDate = FormattedDate(date);
    const newSelectedDates = selectedDates.map((selectedDate) => {
        return FormattedDate(selectedDate);
    })
    return newSelectedDates.includes(newDate);
}

export const DateExistsInMultiValue = (selectedDates, date) => {
    const newDate = moment(date).startOf('date').utc().toISOString();
    const newSelectedDates = selectedDates.map((selectedDate) => {
        return moment(selectedDate.toDate()).startOf('date').utc().toISOString();
    })
    return newSelectedDates.includes(newDate);
}

export const DeleteDateFromArray = (multiDateValues, deletValue) => {
    const newDeletValue = moment(deletValue).startOf('date').utc().toISOString();

    const newMultiDate = [];

    // eslint-disable-next-line array-callback-return
    multiDateValues.map((multiDateValue) => {
        const newDate = moment(multiDateValue.toDate()).startOf('date').utc().toISOString();

        if (newDeletValue !== newDate) {
            newMultiDate.push(multiDateValue.toDate());
        }
    })

    return newMultiDate;
}

export const GetBookableSectors = (availableLayouts) => {
    return availableLayouts.map((availableLayout) => {
        return {
            id: availableLayout.id,
            allowsWaitList: availableLayout.activeWaitList,
            excludedGroup: []
        }
    })
}

export const GetNewOpeningHour = (paperList, opening) => {
    if (paperList.length === 0) {
        return opening;
    } else {
        return paperList[paperList.length -1].to;
    }    
}

export const DeleteValue = (value, dateValues, multiDateValues, rangeDateValues, setDateValues, setMultiDateValues, setRangeDateValues) => {

    if (dateValues && moment(dateValues.toDate()).startOf('date').isSame(moment(value).startOf('date'))) {
        setDateValues(null);
    }

    if (DateExistsInMultiValue(multiDateValues, value)) {
        const newMultiDateValues = multiDateValues.filter((date) => !moment(date.toDate()).startOf('date').isSame(moment(value).startOf('date')));
        setMultiDateValues(newMultiDateValues);
    }
}

export const HandleChangeHours = ( isOpening, opening, closing, validationError, changeOpeningClosing, setValidationError ) => {
    if ( Math.abs( closing - opening ) >= 60 && validationError === 'error:hours' ) {
        setValidationError( '' );
    }

    if( (closing > opening) && validationError === 'error:closingNextDay' ) {
        setValidationError( '' );
    }

    if ( isOpening ) {
        changeOpeningClosing( opening );
    } else {
        changeOpeningClosing( closing );
    };
};

export const compareDates = (a, b) => {    
    if ((typeof a === 'string' && (a.match(/-/g) || []).length === 5) || (typeof b === 'string' && (b.match(/-/g) || []).length === 5)) {
        if (typeof a === 'string' && (a.match(/-/g) || []).length === 5) {
            const newA = new Date(a.replace(' - ', "#").split('#')[0]);
            if (FormattedDate(newA) < b) {
                return -1;
            } else {
                return 1;
            }

        } else {
            const newB = new Date(b.replace(' - ', "#").split('#')[0]);
            if (a < FormattedDate(newB)) {
                return -1;
            } else {
                return 1;
            }
        }

    } else {        
        if (a < b) {
            return -1;
        } else {
            return 1;
        }
    };
};

export const GetAllSelectedDates = (date, rangeDates, multiDates, selectedDates) => {
    const newSelectedDates = selectedDates;

    if (date) {
        const exist = newSelectedDates.includes(FormattedDate(date.toDate()));

        if (!exist) {    
            newSelectedDates.push(FormattedDate(date.toDate()));    
        }
    };    

    // eslint-disable-next-line array-callback-return
    multiDates.map((date) => {
        const exist = newSelectedDates.includes(FormattedDate(date.toDate()));

        if (!exist) {
            newSelectedDates.push(FormattedDate(date.toDate()));
        }
    }); 

    if (rangeDates.length === 2) {
        const range = rangeDates[0].toDate() + ' - ' + rangeDates[1].toDate();
        if (!newSelectedDates.includes(range)) {
            newSelectedDates.push(range)
        }
    };

    newSelectedDates.sort(compareDates);

    return newSelectedDates;
};