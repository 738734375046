import React from 'react';

const FiltersButtonIcon = () => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.04001 6.93644H14.7306C15.0377 7.70592 15.7524 8.22296 16.5853 8.22296C17.6983 8.22296 18.6006 7.32421 18.6006 6.19145C18.6006 5.05869 17.6983 4.1181 16.5853 4.1181C15.7654 4.1181 15.0604 4.62798 14.7456 5.30952H5.04001C4.61705 5.30952 4.27417 5.66986 4.27417 6.12297C4.27419 6.5761 4.61707 6.93644 5.04001 6.93644Z" fill="#2A2742"/>
            <path d="M17.8481 15.0053H8.11232C7.79805 14.2359 7.09414 13.7874 6.27557 13.7874C5.16431 13.7874 4.26343 14.7031 4.26343 15.8355C4.26343 16.9679 5.16428 17.8827 6.27557 17.8827C7.10704 17.8827 7.82062 17.3138 8.12729 16.6322H17.8481C18.2704 16.6322 18.6127 16.2718 18.6127 15.8188C18.6127 15.3659 18.2704 15.0053 17.8481 15.0053ZM6.27832 16.493C5.92271 16.493 5.63442 16.1992 5.63442 15.8369C5.63442 15.4745 5.92271 15.1807 6.27832 15.1807C6.63392 15.1807 6.92221 15.4745 6.92221 15.8369C6.92221 16.1992 6.63392 16.493 6.27832 16.493Z" fill="#2A2742"/>
            <path d="M5.04001 11.8392H9.57125C9.87842 12.5208 10.5931 13.0867 11.4259 13.0867C12.2587 13.0867 12.9734 12.5208 13.2806 11.8392H17.8577C18.2806 11.8392 18.6235 11.4239 18.6235 10.9708C18.6235 10.5177 18.2806 10.1024 17.8577 10.1024H13.2656C12.9509 9.42089 12.2458 8.88867 11.426 8.88867C10.6061 8.88867 9.90106 9.42089 9.58626 10.1024H5.04001C4.61705 10.1024 4.27417 10.5177 4.27417 10.9708C4.27419 11.4239 4.61707 11.8392 5.04001 11.8392Z" fill="#2A2742"/>
        </svg>
    )
}

export default FiltersButtonIcon;