export const EDIT_GENERAL_INFO_SUCCESS = "EDIT_GENERAL_INFO_SUCCESS";
export const EDIT_GENERAL_INFO_CANCEL = "EDIT_GENERAL_INFO_CANCEL";
export const EDIT_GENERAL_INFO_ERROR = "EDIT_GENERAL_INFO_ERROR";
export const TOGGLE_GENERAL_INFO_LOADING = "TOGGLE_GENERAL_INFO_LOADING";

// General info
export const START_FETCH_GENERAL_INFO = "START_FETCH_GENERAL_INFO";
export const FETCH_GENERAL_INFO_SUCCESS = "FETCH_GENERAL_INFO_SUCCESS";
export const FETCH_GENERAL_INFO_ERROR = "FETCH_GENERAL_INFO_ERROR";

export const CHANGE_RESTO_NAME = "CHANGE_RESTO_NAME";
export const CHANGE_RESTO_ABOUT_US = "CHANGE_RESTO_ABOUT_US";
export const CHANGE_RESTO_DESCRIPTION = "CHANGE_RESTO_DESCRIPTION";
export const CHANGE_RESTO_ADDRESS = "CHANGE_RESTO_ADDRESS";
export const CHANGE_RESTO_ZIP_CODE = "CHANGE_RESTO_ZIP_CODE";
export const CHANGE_RESTO_CITY = "CHANGE_RESTO_CITY";
export const CHANGE_RESTO_PROVINCE = "CHANGE_RESTO_PROVINCE";
export const CHANGE_RESTO_COUNTRY = "CHANGE_RESTO_COUNTRY";
export const CHANGE_RESTO_PRICE_LEVEL = "CHANGE_RESTO_PRICE_LEVEL";
export const ADD_MENU_ITEM = "ADD_MENU_ITEM";
export const REMOVE_MENU_ITEM = "REMOVE_MENU_ITEM";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const REMOVE_PAYMENT_METHOD = "REMOVE_PAYMENT_METHOD";

