// Sectors
export const START_LOAD_SECTORS = 'START_LOAD_SECTORS';
export const LOAD_SECTORS_SUCCESS = 'LOAD_SECTORS_SUCCESS';
export const LOAD_SECTORS_FAILURE = 'LOAD_SECTORS_FAILURE';

export const SAVING_SECTOR = 'SAVING_SECTOR';
export const SAVING_SECTOR_SUCCESS = 'SAVING_SECTOR_SUCCESS';
export const SAVING_SECTOR_FAILURE = 'SAVING_SECTOR_FAILURE';

export const CLOSE_SECTOR_ERROR = 'CLOSE_SECTOR_ERROR';

export const CHANGE_SECTOR_MODE = 'CHANGE_SECTOR_MODE';

export const CHANGE_SECTOR_NAME = 'CHANGE_SECTOR_NAME';
export const CHANGE_SECTOR_TYPE = 'CHANGE_SECTOR_TYPE';
export const CHANGE_SECTOR_MAX_GROUP = 'CHANGE_SECTOR_MAX_GROUP';
export const CHANGE_EDITING_TABLE = 'CHANGE_EDITING_TABLE';
export const CHANGE_TABLE_PROPERTIES = 'CHANGE_TABLE_PROPERTIES';
export const SECTOR_ADD_TABLE = 'SECTOR_ADD_TABLE';
export const SECTOR_REMOVE_TABLE = 'SECTOR_REMOVE_TABLE';
export const SECTOR_NEW_TABLE = 'SECTOR_NEW_TABLE';


// COMBINACIONES DE MESAS
export const START_NEW_TABLE_COMBINATION = 'START_NEW_TABLE_COMBINATION';
export const CHANGE_COMBINATION_NAME = 'CHANGE_COMBINATION_NAME';
export const CHANGE_COMBINATION_MIN_CAPACITY = 'CHANGE_COMBINATION_MIN_CAPACITY';
export const CHANGE_COMBINATION_MAX_CAPACITY = 'CHANGE_COMBINATION_MAX_CAPACITY';
export const ADD_SECTOR_COMBINE_TABLES = 'ADD_SECTOR_COMBINE_TABLES';
export const REMOVE_SECTOR_COMBINE_TABLES = 'REMOVE_SECTOR_COMBINE_TABLES';
export const SECTOR_DELETE_COMBINE_TABLES = 'SECTOR_DELETE_COMBINE_TABLES';
export const SECTOR_EDIT_COMBINE_TABLES = 'SECTOR_EDIT_COMBINE_TABLES';
export const SECTOR_SAVE_COMBINE_TABLES = 'SECTOR_SAVE_COMBINE_TABLES';
export const SECTOR_CANCEL_COMBINE_TABLES = 'SECTOR_CANCEL_COMBINE_TABLES';

export const START_DELETE_SECTOR = 'START_DELETE_SECTOR';
export const DELETE_SECTOR_SUCCESS = 'DELETE_SECTOR_SUCCESS';
export const DELETE_SECTOR_FAILURE = 'DELETE_SECTOR_FAILURE';

export const SECTOR_MODE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  NEW: 'NEW',
  DELETE: 'DELETE',
  NEW_SECTOR: 'NEW_SECTOR',
  NEW_LAYOUT: 'NEW_LAYOUT',
  EDIT_LAYOUT: 'EDIT_LAYOUT',
};

//V3

export const CHANGE_LAYOUT_NAME = 'CHANGE_LAYOUT_NAME';
export const CHANGE_LAYOUT_MAX_GROUP = 'CHANGE_LAYOUT_MAX_GROUP';
export const ADD_NEW_SECTOR = 'ADD_NEW_SECTOR';
export const ADD_NEW_TABLE = 'ADD_NEW_TABLE';
export const REMOVE_TABLE = 'REMOVE_TABLE';
export const START_ADDING_NEW_TABLE = 'START_ADDING_NEW_TABLE';
export const CHANGE_EDITING_TABLE_NEW = 'CHANGE_EDITING_TABLE_NEW';
export const CHANGE_TABLE_LAYOUT_PROPERTIES = 'CHANGE_TABLE_LAYOUT_PROPERTIES';
export const CHANGE_TABLE_LAYOUT_COORDINATES = 'CHANGE_TABLE_LAYOUT_COORDINATES';
export const START_NEW_COMBINATION = 'START_NEW_COMBINATION';
export const CANCEL_COMBINATION = 'CANCEL_COMBINATION';
export const SAVE_COMBINATION = 'SAVE_COMBINATION';
export const ADD_TABLE_TO_COMBINATION = 'ADD_TABLE_TO_COMBINATION';
export const REMOVE_TABLE_FROM_COMBINATION = 'REMOVE_TABLE_FROM_COMBINATION';
export const REMOVE_COMBINATION = 'REMOVE_COMBINATION';
export const UPDATE_COMBINATION_NAME = 'UPDATE_COMBINATION_NAME';
export const UPDATE_COMBINATION = 'UPDATE_COMBINATION';

//TODO
export const LAYOUT_CREATE_SUCCESS = 'LAYOUT_CREATE_SUCCESS';
export const START_LAYOUT_EDIT_MODE = 'START_LAYOUT_EDIT_MODE';
export const START_CREATING_LAYOUT = 'START_CREATING_LAYOUT';
export const START_DUPLICATING_LAYOUT = 'START_DUPLICATING_LAYOUT';
export const BACK_TO_VIEW_MODE = 'BACK_TO_VIEW_MODE';