import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";

import {connect} from "react-redux";
import {changeSelectedCategory, createProduct, toggleNewProductDialog,} from "../../../actions";

const useStyles = makeStyles(() => ({
  categoryContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 500,
  },
  createContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  categorySelectControl: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  categorySelect: {
    width: "100%",
  },
  textfield:{},
  addImageButton: {}
}));

function NewProductDialog(props) {
  const classes = useStyles();
  const sundayToMonday = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];

  // Variables
  const { newProductDialog, loading, categories, selectedCategory } = props;
  //Actions
  const {
    toggleNewProductDialog,
    createProduct,
    changeSelectedCategory,
  } = props;

  const [name, setName] = useState("");

  const [description, setDescription] = useState("");
  const [price, setPrice] = useState();
  const [image, setImage] = useState();
  const [days, setDays] = useState([true, true, true, true, true, true, true]);

  const [nameError, setNameError] = useState(false);
  const [priceError, setPriceError] = useState(false);

  const changeDay = (day, newValue) => {
    let newDays = [...days];
    newDays[day] = newValue;
    setDays(newDays);
  };

  const submitProduct = () => {
    setNameError(name.length <= 0);
    setPriceError(price && price < 0);

    let thereIsError =
      name.length <= 0 || (price && price < 0) || selectedCategory.length <= 0;

    if (!thereIsError) {
      let newProduct = {
        name: name,
        description: description,
        category: selectedCategory,
        price: price,
        image: image,
        days: [...days],
      };

      createProduct(newProduct);

      setName("");
      setDescription("");
      setPrice("");
      setImage(null);
      setDays([true, true, true, true, true, true, true]);
    }
  };

  return (
    <div>
      <Dialog
        open={newProductDialog}
        onClose={toggleNewProductDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{"Nuevo plato"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.fieldContainer}>
              <TextField
                autoFocus
                disabled={loading}
                error={nameError}
                className={classes.textfield}
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="dense"
                id="txt-new-product-name"
                label="Nombre"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.fieldContainer}>
              <FormControl className={classes.categorySelectControl}>
                <InputLabel id="categories-select-label">Sección</InputLabel>
                <Select
                  disabled={loading}
                  className={classes.categorySelect}
                  labelId="categories-select-label"
                  id="categories-select"
                  value={selectedCategory}
                  onChange={(e) => changeSelectedCategory(e.target.value)}
                >
                  {categories
                    ? categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={loading}
                className={classes.textfield}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                margin="dense"
                id="txt-new-product-description"
                label="Descripción"
                type="text"
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={loading}
                error={priceError}
                className={classes.textfield}
                value={price}
                onChange={(e) =>
                  e.target.value && Number(e.target.value) >= 0
                    ? setPrice(Number(e.target.value))
                    : setPrice("")
                }
                margin="dense"
                id="txt-new-product-price"
                label="Precio"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                component="label"
                className={classes.addImageButton}
                disabled={loading}
              >
                Cargar imagen
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="productimage"
                  name="productimage"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography>Mostrar el plato:</Typography>
            </Grid>
            <Grid item xs={12}>
              {sundayToMonday.map((day, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={days[index]}
                      onChange={(event, checked) => changeDay(index, checked)}
                      name={day}
                      color="primary"
                    />
                  }
                  label={day}
                />
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleNewProductDialog}>Cerrar</Button>
          <Button onClick={submitProduct}>Guardar</Button>
        </DialogActions>
        {loading ? <LinearProgress /> : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.firestore.ordered.menu,
    selectedCategory:
      state.menuReducer.productsReducer.editReducer.selectedCategory,
    loading: state.menuReducer.productsReducer.createReducer.loading,
    newProductDialog:
      state.menuReducer.productsReducer.createReducer.newProductDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedCategory: (data) => dispatch(changeSelectedCategory(data)),
    toggleNewProductDialog: () => dispatch(toggleNewProductDialog()),
    createProduct: (data) => dispatch(createProduct(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProductDialog);
