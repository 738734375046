import React from "react";

import { connect } from "react-redux";

// Actions
import { setAutomaticWaitList } from "../../../../../actions/shiftsActions";

// Components
import ShiftSimpleSelect from "./General/ShiftSimpleSelect";

// Icons
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

// Material Ui
import { Box, Collapse, FormControl, Typography } from "@mui/material";

// Styles
import { DecriptionShiftStyles, ShiftSelectTitles } from "../../../Styles";

const WaitListOperating = (props) => {
  // Variables
  const { automaticWaitList } = props;

  // Acciones
  const { setAutomaticWaitList } = props;

  const options = [
    { value: true, option: "Automática", id: 0 },
    { value: false, option: "Manual", id: 1 },
  ];

  return (
    <Box mt={3}>
      <Typography sx={ ShiftSelectTitles } >Funcionamiento de la lista de espera</Typography>
      <div style={{ marginBottom: '.6rem' }} />
      <Typography sx={ DecriptionShiftStyles } >
        Selecciona el tipo de funcionamiento de tu lista de espera
      </Typography>
      <FormControl style={{ marginTop: "32px" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
          <ShiftSimpleSelect
            handleChange={ setAutomaticWaitList }
            value={ automaticWaitList } 
            optionsArray={ options } 
          />
          <Collapse orientation="horizontal" in={ automaticWaitList }>
            <Box
              sx={{
                backgroundColor: "#ffe2bc",
                alignItems: 'center',
                borderRadius: "6px",
                padding: "4px 8px",
                display: 'flex',
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: '#041c32',
                  fontWeight: 500
                }}
              >
                BETA
              </Typography>
              <RocketLaunchIcon sx={{ color: "#f5920d !important" }} />
            </Box>
          </Collapse>
        </Box>
      </FormControl>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    automaticWaitList: state.v3.shifts.shiftData.automaticWaitList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAutomaticWaitList: (data) => dispatch(setAutomaticWaitList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitListOperating);
