import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import SettingsNumbers from '../../GeneralComponents/SettingsNumbers';

const useStyles = makeStyles(() => ({
    root: {
        width: '450px',
        height: '170px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        backgroundColor: '#F0F0F0',
        padding: '10px 0px 10px 15px',
    },
    title: {
        margin: '0px',
        fontSize: '11px',
        fontWeight: '600',
    },
    text: {
        margin: '0px',
        fontSize: '9px',
        color: '#848282',
        marginTop: '3px',
        textAlign: 'justify',
    },
    header: {
        margin: '0px',
    },
}));

const SettingsZones = (props) => {

    const classes = useStyles();

    const { acceptanceRadius, stayingRadius, } = props

    const {onChangeAcceptanceRadius, onChangeStayingRadius} = props;

    return (
        <div className={classes.root}>
            <h4 className={classes.header}>Ajustes de zona</h4>
            <Grid container spacing={2} style={{padding: '10px'}}>
                <Grid item xs={6} style={{paddingRight: '10px'}}>
                    <p className={classes.title}>Radio de solicitud</p>
                    <p className={classes.text}>El usuario debe estar dentro de esta distancia para poder enviarte una
                        solicitud de Mesa Ya!</p>
                    <div style={{marginTop: '10px'}}>
                        <SettingsNumbers name={"En cuadras"} value={acceptanceRadius} onChange={onChangeAcceptanceRadius}/>
                    </div>
                </Grid>
                <Grid item xs={6} style={{paddingRight: '25px'}}>
                    <p className={classes.title}>Radio de permanencia</p>
                    <p className={classes.text}>El usuario debe permanecer dentro de esta distancia para mantener su
                        Mesa Ya!</p>
                    <div style={{marginTop: '10px'}}>
                        <SettingsNumbers name={"En cuadras"} value={stayingRadius} onChange={onChangeStayingRadius}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SettingsZones
