import React from "react";

import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import {GENERAL_SETTINGS, PARTNERS, PLANS} from "../../../constants";

import {
    canChangeSubscription,
    changeFinancesPlan,
    editFinancesError,
    nextStepFinances,
    updateSubscription,
} from "../../../actions";
import WokiYa from "./WokiYa";
import WokiPlus from "./WokiPlus";
import {DEVELOPMENT, ENVIRONMENT} from "../../../constants/config";
import BGMyPlan from "../../SvgComponents/images/BGMyPlan";

function Suscriptions(props) {

    const {plans, planId, activeStep, isActive, checkout} = props;
    const {changeFinancesPlan, canChangeSubscription, nextStepFinances, updateSubscription} = props;

    let planSubscripted = planId && plans
        ? plans.find((v) => {
            return v.id === planId;
        })
        : undefined

    const handleChangeSuscription = async (id) => {
        // console.log("PLAN SUSCRIPTED: ", planSubscripted)
        if (planSubscripted) {
            if (ENVIRONMENT === DEVELOPMENT) {
                await changeFinancesPlan(id)
                await updateSubscription();
            } else {
                let canChange = await canChangeSubscription();
                if (canChange) {
                    await changeFinancesPlan(id)
                    await updateSubscription();
                    if (!isActive) {
                        window.open(checkout,"_self");
                    }
                } else {
                    editFinancesError("Ya realizó un cambio de suscripción, para volver a cambiar debe esperar hasta el siguiente mes");
                }
            }
        } else {
            changeFinancesPlan(id)
            nextStepFinances(activeStep + 1)
        }
    };

    return (
        <BGMyPlan>
            <div style={{display: 'flex', position: 'absolute', justifyContent: 'flex-end', left: '41%', bottom: '25%'}}>
                <div>
                    <WokiYa
                        subscribe={planSubscripted && planSubscripted.enableBookings === false && isActive}
                        basePrice={plans?.find(plan => plan.name === "Woki Ya!")?.base_price}
                        unitPrice={plans?.find(plan => plan.name === "Woki Ya!")?.unit_price}
                        onClick={() => handleChangeSuscription(plans?.find(plan => plan.name === "Woki Ya!")?.id)}
                    />
                </div>
                <div>
                    <WokiPlus
                        subscribe={planSubscripted && planSubscripted.enableBookings === true && isActive}
                        basePrice={plans?.find(plan => plan.name === "Woki Plus")?.base_price}
                        unitPrice={plans?.find(plan => plan.name === "Woki Plus")?.unit_price}
                        onClick={() => handleChangeSuscription(plans?.find(plan => plan.name === "Woki Plus")?.id)}
                    />
                </div>
            </div>
        </BGMyPlan>
    );
}

const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        checkout_payment: state.financesReducer.editReducer.checkout_payment,
        paymentMethodId: state.financesReducer.editReducer.paymentMethodId,
        activeStep: state.financesReducer.editReducer.activeStep,
        isActive: state.financesReducer.editReducer.isActive,
        checkout: state.financesReducer.editReducer.checkout,
        planId: state.financesReducer.editReducer.planId,
        error: state.financesReducer.editReducer.error,

        clientId: state.financesReducer.editReducer.clientId,

        partner: state.firestore.ordered[PARTNERS],
        plans: state.firestore.ordered[PLANS],
        profile: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editFinancesError: (data) => dispatch(editFinancesError(data)),
        canChangeSubscription: () => dispatch(canChangeSubscription()),
        updateSubscription: () => dispatch(updateSubscription()),
        changeFinancesPlan: (data) => dispatch(changeFinancesPlan(data)),
        nextStepFinances: (data) => dispatch(nextStepFinances(data)),
    };
};

export default compose(
    connect( mapStateToProps, mapDispatchToProps ),
    firestoreConnect((props) => {
        return [
            {
                collection: PARTNERS,
                doc: props.idPartnerSelected,
            },
            {
                collection: GENERAL_SETTINGS,
            },
            {
                collection: PLANS,
            },
        ];
    })
)(Suscriptions);
