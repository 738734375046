import {
    ADD_SECTOR_COMBINE_TABLES,
    CHANGE_EDITING_TABLE,
    CHANGE_SECTOR_MAX_GROUP,
    CHANGE_SECTOR_MODE,
    CHANGE_SECTOR_NAME,
    CHANGE_SECTOR_TYPE,
    CHANGE_TABLE_PROPERTIES,
    CLOSE_SECTOR_ERROR,
    DELETE_SECTOR_FAILURE,
    DELETE_SECTOR_SUCCESS,
    LOAD_SECTORS_FAILURE,
    LOAD_SECTORS_SUCCESS,
    REMOVE_SECTOR_COMBINE_TABLES,
    SAVING_SECTOR,
    SAVING_SECTOR_FAILURE,
    SAVING_SECTOR_SUCCESS,
    SECTOR_ADD_TABLE,
    SECTOR_DELETE_COMBINE_TABLES,
    SECTOR_EDIT_COMBINE_TABLES,
    SECTOR_SAVE_COMBINE_TABLES,
    SECTOR_MODE,
    SECTOR_NEW_TABLE,
    SECTOR_REMOVE_TABLE,
    START_DELETE_SECTOR,
    START_LOAD_SECTORS,
    SECTOR_CANCEL_COMBINE_TABLES,
    START_NEW_TABLE_COMBINATION,
    CHANGE_COMBINATION_NAME,
    CHANGE_COMBINATION_MAX_CAPACITY, CHANGE_COMBINATION_MIN_CAPACITY
} from "../../constants";
import axios from "axios";

export const startLoadSectors = () => ({
    type: START_LOAD_SECTORS,
});

export const loadSectorsSuccess = (sectors) => ({
    type: LOAD_SECTORS_SUCCESS,
    payload: sectors,
});

export const loadSectorsFailure = (error) => ({
    type: LOAD_SECTORS_FAILURE,
    payload: error,
});

export const changeSectorMode = ({mode, sector}) => ({
    type: CHANGE_SECTOR_MODE,
    payload: {mode, sector}
});

export const savingSector = () => ({
    type: SAVING_SECTOR
});

export const savingSectorSuccess = () => ({
    type: SAVING_SECTOR_SUCCESS
});

export const savingSectorFailure = (error) => ({
    type: SAVING_SECTOR_FAILURE,
    payload: error,
});

export const closeError = () => ({
    type: CLOSE_SECTOR_ERROR,
})

export const changeSectorAlias = (alias) => ({
    type: CHANGE_SECTOR_NAME,
    payload: alias
});

export const changeSectorType = (sectorType) => ({
    type: CHANGE_SECTOR_TYPE,
    payload: sectorType
});

export const changeSectorMaxGroup = (maxGroup) => ({
    type: CHANGE_SECTOR_MAX_GROUP,
    payload: maxGroup
});

export const changeEditingTable = (table) => ({
    type: CHANGE_EDITING_TABLE,
    payload: table
});

export const changeSectorTableProperties =
    ({
         tableName,
         alias,
         minCapacity,
         maxCapacity,
         autoAssign,
         box,
         shared,
         point
     }) => ({
        type: CHANGE_TABLE_PROPERTIES,
        payload: {
            tableName,
            alias,
            minCapacity,
            maxCapacity,
            autoAssign,
            box,
            shared,
            point
        }
    });

export const sectorAddTable = ({i, minCapacity, maxCapacity, x, y, w, h, circle, isResizable}) => ({
    type: SECTOR_ADD_TABLE,
    payload: {i, minCapacity, maxCapacity, x, y, w, h, circle, isResizable}
});

export const sectorRemoveTable = (tableName) => ({
    type: SECTOR_REMOVE_TABLE,
    payload: tableName
});

export const sectorNewTable = ({minCapacity, maxCapacity, h, w, isResizable, circle}) => ({
    type: SECTOR_NEW_TABLE,
    payload: {minCapacity, maxCapacity, h, w, isResizable, circle}
});


export const startNewCombination = () => ({
    type: START_NEW_TABLE_COMBINATION
})

export const changeCombinationName = (name) => ({
    type: CHANGE_COMBINATION_NAME,
    payload: name
})

export const changeCombinationMinCapacity = (minCapacity) => ({
    type: CHANGE_COMBINATION_MIN_CAPACITY,
    payload: minCapacity
})

export const changeCombinationMaxCapacity = (maxCapacity) => ({
    type: CHANGE_COMBINATION_MAX_CAPACITY,
    payload: maxCapacity
})

export const addCombineTable = (data) => ({
    type: ADD_SECTOR_COMBINE_TABLES,
    payload: data
})

export const removeCombineTable = (data) => ({
    type: REMOVE_SECTOR_COMBINE_TABLES,
    payload: data
})

export const editSectorSaveCombineTables = () => ({
    type: SECTOR_SAVE_COMBINE_TABLES
})

export const editSectorCancelCombineTables = () => ({
    type: SECTOR_CANCEL_COMBINE_TABLES
})

export const deleteCombineTables = (combination) => ({
    type: SECTOR_DELETE_COMBINE_TABLES,
    payload: combination
})

export const editCombineTables = (combination) => ({
    type: SECTOR_EDIT_COMBINE_TABLES,
    payload: combination
})

export const startDeleteSector = () => ({
    type: START_DELETE_SECTOR
})

export const deleteSectorSuccess = () => ({
    type: DELETE_SECTOR_SUCCESS
})

export const deleteSectorFailure = () => ({
    type: DELETE_SECTOR_FAILURE
})

function ensurePartnerExists(partner) {
    if (!partner) {
        throw new Error("Partner does not exist");
    }
}

export const loadSectors = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(startLoadSectors())

            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.get(`${API_URL}/sector/${ idPartnerSelected }`, {headers: {Authorization: `Bearer ${token}`}})
            const {data} = res;
            dispatch(loadSectorsSuccess(data));
        } catch (error) {
            console.log(error);
            if (error.response?.data) {
                dispatch(loadSectorsFailure(error.response.data));
            } else {
                dispatch(loadSectorsFailure(error.message));
            }
        }
    }
}

export const saveSector = (image) => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, sectorsReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const firebaseReact = getFirebase();
        const {profile} = firebase;
        const {mode, sector} = sectorsReducer.sectors;
        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(savingSector())
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            if (sector.alias.trim() === "") {
                throw new Error("El nombre del sector es requerido");
            }

            // Load image
            if (image) {
                try {
                    const newImage = new File([image], sector.name, {type: 'image/png'});
                    const storageRef = firebaseReact.storage().ref();
                    const imageRef = storageRef.child('layoutImages/' + idPartnerSelected + '/' + sector.name);
                    const snap = await imageRef.put(newImage);
                    sector.image = await snap.ref.getDownloadURL();
                } catch (error) {
                    console.log("Error al subir la image: ", error);
                }
            }

            if (mode === SECTOR_MODE.EDIT) {
                try {
                    await axios.patch(
                        `${API_URL}/sector/${ idPartnerSelected }`,
                        sector,
                        {headers: {Authorization: `Bearer ${token}`}})
                } catch (e) {
                    const res = e.response;
                    if (res.status > 300) {
                        throw new Error(res.data);
                    }
                }
            }
            if (mode === SECTOR_MODE.NEW) {
                const res = await axios.put(
                    `${API_URL}/sector/${ idPartnerSelected }`,
                    sector,
                    {headers: {Authorization: `Bearer ${token}`}})
                if (res.status > 300) {                    
                    throw new Error(res.data);
                }
            }
            dispatch(savingSectorSuccess());
        } catch (error) {
            console.log(error);
            if (error.response?.data) {
                dispatch(savingSectorFailure(error.response.data));
            } else {
                dispatch(savingSectorFailure(error.message));
            }
        }
    }
}

export const deleteSector = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const {firebase, sectorsReducer, userAccountsReducer} = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const {profile} = firebase;
        const {sector} = sectorsReducer.sectors;
        try {
            ensurePartnerExists( idPartnerSelected );
            dispatch(startDeleteSector())
            const API_URL = process.env.REACT_APP_API_URL;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);
            const res = await axios.delete(`${API_URL}/sector/${ idPartnerSelected }/${sector.name}`, {headers: {Authorization: `Bearer ${token}`}})
            if (res.status > 300) {
                throw new Error(res.data);
            }
            dispatch(loadSectors());
            dispatch(deleteSectorSuccess());
        } catch (error) {
            console.log(error);
            if (error.response?.data) {
                dispatch(deleteSectorFailure(error.response.data));
            } else {
                dispatch(deleteSectorFailure(error.message));
            }
        }
    }
}
