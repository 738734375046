import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Utils
import { getScheduleOptions } from '../../V3/Shifts/Wizard/utils/functions';

// Styles
import { PlanningHoursSelectStyles } from '../Styles';

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            border: '1px solid #0B4762',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            maxWidth: '15.75rem',
            marginTop: '.2rem',
            width: '8rem',
        },
    },
};

const HoursPlanningSelect = ({ handleChange, value, error }) => {

    const hours = getScheduleOptions();

    return (
        <FormControl error={ error } sx={{ width: '100%' }}>
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                sx={ PlanningHoursSelectStyles }
                placeholder='Seleccionar'
                input={ <OutlinedInput /> }
                MenuProps={ MenuProps }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    hours.map(( item ) =>
                        <MenuItem 
                            key={ item.option } 
                            value={ item.value } 
                            sx={{ 
                                borderBottom: '1px solid #E7E7E7', 
                                padding: '.5rem', 
                                height: '2.5rem',
                            }}>
                            <ListItemText 
                                primary={ item.option }  
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default HoursPlanningSelect;