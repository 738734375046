import React from 'react';
import {Grid} from '@material-ui/core';
import OkGreenIcon from '../../SvgComponents/icons/IconsPrimary/Only Icons/OkGreenIcon';

const Ingresado = () => {
    return (
        <div style={{width: '90px'}}>
            <Grid container>
                <Grid item xs={2} style={{paddingTop: '3px'}}>
                    <OkGreenIcon width={'15px'} height={'15px'}/>
                </Grid>
                <Grid item xs={10} style={{paddingTop: '3px', paddingLeft: '12px'}}>
                    <p
                        style={{
                            margin: '0px',
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            color: '#15D615'
                        }}
                    >Ingresado</p>
                </Grid>
            </Grid>
        </div>
    )
}

export default Ingresado
