import React from "react";

const QRIcon = (props) => {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? "40"} height={props.height ?? "40"}
             viewBox="0 0 63 63">
            <defs>
                <filter id="Elipse_274" x="0" y="0" width="63" height="63"
                        filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="QR_BUTTON" data-name="QR" transform="translate(9 6)">
                <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Elipse_274)">
                    <circle id="Elipse_274-2" data-name="Elipse 274" cx="22.5" cy="22.5" r="22.5"
                            transform="translate(9 6)" fill="#fff"/>
                </g>
                <g id="qr-code" transform="translate(7.75 7.75)">
                    <path id="Trazado_480" data-name="Trazado 480" d="M27,31.082V29.25h1.818v1.832Z"
                          transform="translate(-4.75 -5.014)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_481" data-name="Trazado 481"
                          d="M20.25,26.582V24.75h1.818v1.832Z"
                          transform="translate(-3.455 -4.179)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_482" data-name="Trazado 482"
                          d="M20.25,32.914h3.636V31.082H22.068V29.25H20.25Z"
                          transform="translate(-3.455 -5.014)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_483" data-name="Trazado 483"
                          d="M29.25,28.414V24.75h1.818v3.664Z"
                          transform="translate(-5.182 -4.179)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_484" data-name="Trazado 484"
                          d="M31.068,29.25h1.818v3.664H29.25V31.082h1.818Z"
                          transform="translate(-5.182 -5.014)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_485" data-name="Trazado 485"
                          d="M29.25,22.082V20.25h3.636v3.664H31.068V22.082Z"
                          transform="translate(-5.182 -3.343)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_486" data-name="Trazado 486"
                          d="M26.136,22.5H24.318v3.664H22.5V28h3.636Z"
                          transform="translate(-3.887 -3.761)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_487" data-name="Trazado 487"
                          d="M20.25,22.082V20.25h3.636v1.832Z"
                          transform="translate(-3.455 -3.343)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_488" data-name="Trazado 488" d="M6.75,24.75h3.636v3.664H6.75Z"
                          transform="translate(-0.864 -4.179)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_489" data-name="Trazado 489"
                          d="M13.159,31.243H2.25V20.25H13.159ZM4.068,29.411h7.273V22.082H4.068Z"
                          transform="translate(0 -3.343)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_490" data-name="Trazado 490"
                          d="M24.75,6.75h3.636v3.664H24.75Z"
                          transform="translate(-4.318 -0.836)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_491" data-name="Trazado 491"
                          d="M31.159,13.243H20.25V2.25H31.159Zm-9.091-1.832h7.273V4.082H22.068Z"
                          transform="translate(-3.455 0)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_492" data-name="Trazado 492" d="M6.75,6.75h3.636v3.664H6.75Z"
                          transform="translate(-0.864 -0.836)" fill={props.fill ?? "#0076ff"}/>
                    <path id="Trazado_493" data-name="Trazado 493"
                          d="M13.159,13.243H2.25V2.25H13.159ZM4.068,11.411h7.273V4.082H4.068Z"
                          fill={props.fill ?? "#0076ff"}/>
                </g>
            </g>
        </svg>
    );
}

export default QRIcon