import React from 'react';

const BookingStateIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7_2061)">
                <path d="M9 1.5V4.5" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 13.5V16.5" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.69775 3.6975L5.82025 5.82" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.1802 12.18L14.3027 14.3025" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.5 9H4.5" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.5 9H16.5" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.69775 14.3025L5.82025 12.18" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.1802 5.82L14.3027 3.6975" stroke="#818181" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7_2061">
                    <rect width="18" height="18" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BookingStateIcon;