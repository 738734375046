import React, { useEffect } from "react";

// Components
import AdvancedOptionsRotationControl from "../components/AdvancedOptionsRotationControl";
import AdvancedOptionsAditionalInfo from "../components/AdvancedOptionsAditionalInfo";
import AdvancedOptionsExceptions from "../components/AdvancedOptionsExceptions";
import AdvancedOptionsOpcionals from "../components/AdvancedOptionsOptionals";
import AdvancedOptionsGroups from "../components/AdvancedOptionsGroups";

// Material Ui
import { Divider, makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";

// Styles
import { DecriptionShiftStyles, TitleShiftStyles } from "../../../Styles";
import { dividerStyle } from "../utils/styles/generalStyles";

const useStyles = makeStyles(() => ({
  divider: dividerStyle,  
}));

// #Todo: refactor this for atomic components!
const AdvancedOptionsStep = () => {  
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box pb={5}>
      <Typography sx={ TitleShiftStyles } >Tiempo de permanencia</Typography>
      <Typography sx={ DecriptionShiftStyles } >
        Selecciona el tiempo de permanencia promedio general o para cada tamaño de grupo.
      </Typography>   
      <AdvancedOptionsGroups />
      <AdvancedOptionsExceptions />
      <Divider className={classes.divider} style={{width: "1199px"}} />
      <AdvancedOptionsRotationControl />
      <Divider className={classes.divider} style={{width: "1199px"}} />
      <AdvancedOptionsAditionalInfo />
      <AdvancedOptionsOpcionals />
    </Box>
  );
};

export default AdvancedOptionsStep;
