import React from 'react';
import {connect} from 'react-redux';

// Actions
import {changeSectorMode, deleteSector} from "../../actions";

// Components
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import DeleteSectorModal from '../Modals/DeleteSectorModal';

// Constants
import {SECTOR_MODE} from "../../constants";

function DeleteDialog(props) {
    const {loading, sector, mode} = props;

    const {deleteSector, changeSectorMode} = props;

    return (
        <div>
            <DeleteSectorModal
                onAccept={() => deleteSector()}
                onCancel={() => changeSectorMode({mode: SECTOR_MODE.EDIT, sector: sector})}
                open={mode === SECTOR_MODE.DELETE}
                title={sector.alias}
            />
            <ProgressIndicator open={loading}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        sector: state.sectorsReducer.sectors.sector,
        mode: state.sectorsReducer.sectors.mode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteSector: () => dispatch(deleteSector()),
        changeSectorMode: ({mode, sector}) => dispatch(changeSectorMode({mode, sector}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
