import React from 'react'

const FalabellaIcon = (props) => {
    return (
        <svg width="87" height="40" viewBox="0 0 87 54" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M22.2954 48.5093H20.2444C20.0028 48.5093 19.7411 48.5233 19.4635 48.5512C19.178 48.5792 18.9183 48.6611 18.6607 48.7849C18.4151 48.9247 18.2034 49.0984 18.0436 49.3381C17.8878 49.5837 17.798 49.9232 17.798 50.3446C17.798 50.9657 18.0097 51.3911 18.4151 51.6287C18.8365 51.8764 19.3896 52.0002 20.0687 52.0002C20.1665 52.0002 20.3143 51.9942 20.49 51.9762C20.6698 51.9702 20.8635 51.9602 21.0552 51.9263C21.2569 51.8983 21.4646 51.8664 21.6903 51.8224C21.906 51.7805 22.1197 51.7226 22.2954 51.6527V48.5093ZM24.8237 52.5554C24.8237 52.8869 24.6979 53.1265 24.4143 53.2603C24.1128 53.3942 23.7613 53.522 23.4038 53.6158C23.0403 53.7157 22.6629 53.8016 22.2874 53.8495C21.89 53.9094 21.5066 53.9493 21.1331 53.9733C20.7557 53.9953 20.3922 53.9993 20.0487 53.9993C19.1121 53.9993 18.3212 53.9094 17.7081 53.7157C17.081 53.522 16.5777 53.2703 16.2223 52.9488C15.8668 52.6213 15.6092 52.2458 15.4654 51.8105C15.3216 51.3771 15.2557 50.9198 15.2557 50.4365C15.2557 49.9272 15.3376 49.4559 15.5053 48.9926C15.6611 48.5352 15.9227 48.1119 16.3002 47.7664C16.6756 47.4269 17.1509 47.1433 17.744 46.9256C18.3472 46.7099 19.0661 46.608 19.9169 46.608H22.3234V46.2446C22.3234 45.979 22.2914 45.7213 22.2375 45.4877C22.1776 45.27 22.0697 45.0703 21.902 44.8906C21.7342 44.7288 21.5265 44.591 21.227 44.5071C20.9334 44.4073 20.5759 44.3633 20.1146 44.3633C19.6273 44.3633 19.1041 44.4073 18.5309 44.4951C17.9458 44.573 17.4984 44.6729 17.1309 44.7907C16.9792 44.8286 16.8693 44.8366 16.7994 44.8147C16.7295 44.7867 16.6756 44.7028 16.6357 44.573L16.3541 43.3528C16.2882 43.1271 16.3541 42.9734 16.5378 42.9134C17.1309 42.6838 17.79 42.516 18.5049 42.4222C19.2219 42.3363 19.8929 42.2884 20.52 42.2884C21.9998 42.2884 23.0942 42.6099 23.7812 43.2709C24.4802 43.928 24.8237 44.8846 24.8237 46.1427V52.5554Z" fill={props.fill}/>
            <path d="M38.2758 48.5093H36.2287C35.9831 48.5093 35.7135 48.5233 35.4399 48.5512C35.1703 48.5792 34.9067 48.6611 34.653 48.7849C34.4034 48.9247 34.1957 49.0984 34.02 49.3381C33.8722 49.5837 33.7863 49.9232 33.7863 50.3446C33.7863 50.9657 33.99 51.3911 34.4174 51.6287C34.8228 51.8764 35.37 52.0002 36.057 52.0002C36.1469 52.0002 36.2986 51.9942 36.4744 51.9762C36.6541 51.9702 36.8478 51.9602 37.0436 51.9263C37.2373 51.8983 37.449 51.8664 37.6627 51.8224C37.9043 51.7805 38.1 51.7226 38.2758 51.6527V48.5093ZM40.8001 52.5554C40.8001 52.8869 40.6823 53.1265 40.3967 53.2603C40.0891 53.3942 39.7416 53.522 39.3841 53.6158C39.0247 53.7157 38.6432 53.8016 38.2678 53.8495C37.8664 53.9094 37.4989 53.9493 37.1055 53.9733C36.736 53.9953 36.3845 53.9993 36.045 53.9993C35.0844 53.9993 34.3135 53.9094 33.6845 53.7157C33.0614 53.522 32.5701 53.2703 32.2026 52.9488C31.8471 52.6213 31.5935 52.2458 31.4417 51.8105C31.3019 51.3771 31.244 50.9198 31.244 50.4365C31.244 49.9272 31.3219 49.4559 31.4937 48.9926C31.6494 48.5352 31.909 48.1119 32.2845 47.7664C32.656 47.4269 33.1353 47.1433 33.7244 46.9256C34.3235 46.7099 35.0385 46.608 35.8932 46.608H38.2997V46.2446C38.2997 45.979 38.2678 45.7213 38.2139 45.4877C38.1659 45.27 38.0621 45.0703 37.8903 44.8906C37.7106 44.7288 37.4989 44.591 37.2073 44.5071C36.9257 44.4073 36.5523 44.3633 36.0989 44.3633C35.6116 44.3633 35.0844 44.4073 34.5073 44.4951C33.9381 44.573 33.4668 44.6729 33.1113 44.7907C32.9595 44.8286 32.8577 44.8366 32.7798 44.8147C32.7099 44.7867 32.656 44.7028 32.628 44.573L32.3244 43.3528C32.2605 43.1271 32.3304 42.9734 32.5142 42.9134C33.1113 42.6838 33.7703 42.516 34.4853 42.4222C35.2102 42.3363 35.8852 42.2884 36.5004 42.2884C37.9862 42.2884 39.0786 42.6099 39.7776 43.2709C40.4566 43.928 40.8001 44.8846 40.8001 46.1427V52.5554Z" fill={props.fill}/>
            <path d="M58.3133 44.3581C57.9418 44.3581 57.5923 44.434 57.2828 44.5857C56.9832 44.7395 56.7256 44.9452 56.5259 45.1928C56.3122 45.4545 56.1245 45.744 55.9967 46.0776C55.8709 46.3931 55.797 46.7406 55.789 47.0961H60.7238C60.7238 46.7086 60.6818 46.3611 60.602 46.0256C60.5281 45.7021 60.4122 45.4185 60.2245 45.1649C60.0528 44.9192 59.8151 44.7155 59.5115 44.5718C59.202 44.434 58.8046 44.3581 58.3133 44.3581ZM58.2813 42.2871C59.917 42.2871 61.1332 42.7105 61.936 43.5512C62.7129 44.39 63.1063 45.5423 63.1063 47.0182C63.1063 47.3837 63.0644 47.6872 62.9905 47.9368C62.8966 48.1785 62.7748 48.3602 62.611 48.51C62.4592 48.6578 62.2835 48.7596 62.0778 48.8016C61.8621 48.8615 61.6404 48.8934 61.3868 48.8934H55.7151C55.7191 49.4546 55.797 49.912 55.9567 50.2954C56.1205 50.6749 56.3242 50.9764 56.5938 51.2121C56.8854 51.4457 57.1869 51.6175 57.5404 51.7153C57.8959 51.8112 58.2973 51.8671 58.7027 51.8671C59.1561 51.8671 59.6154 51.8112 60.0528 51.6974C60.4901 51.5855 60.8756 51.4457 61.1991 51.284C61.4327 51.1901 61.5865 51.228 61.6864 51.4198L62.2575 52.632C62.3534 52.8696 62.2995 53.0274 62.1078 53.1293C61.5666 53.4588 60.9734 53.6825 60.3264 53.8183C59.6833 53.9361 59.0622 54 58.485 54C56.7576 54 55.4235 53.5387 54.5268 52.614C53.6062 51.6834 53.1448 50.2235 53.1448 48.2124C53.1448 47.2698 53.2746 46.429 53.5463 45.7001C53.7959 44.9632 54.1554 44.3381 54.5967 43.8308C55.0521 43.3296 55.5793 42.9361 56.2184 42.6885C56.8355 42.4229 57.5404 42.2871 58.2813 42.2871Z" fill={props.fill}/>
            <path d="M81.5178 48.5093H79.4508C79.2251 48.5093 78.9595 48.5233 78.6819 48.5512C78.4003 48.5792 78.1287 48.6611 77.8791 48.7849C77.6334 48.9247 77.4257 49.0984 77.262 49.3381C77.1142 49.5837 77.0163 49.9232 77.0163 50.3446C77.0163 50.9657 77.22 51.3911 77.6374 51.6287C78.0508 51.8764 78.616 52.0002 79.291 52.0002C79.3969 52.0002 79.5327 51.9942 79.7044 51.9762C79.8921 51.9702 80.0819 51.9602 80.2736 51.9263C80.4733 51.8983 80.695 51.8664 80.9047 51.8224C81.1243 51.7805 81.33 51.7226 81.5178 51.6527V48.5093ZM84.0421 52.5554C84.0421 52.8869 83.9123 53.1265 83.6367 53.2603C83.3271 53.3942 82.9876 53.522 82.6261 53.6158C82.2667 53.7157 81.8772 53.8016 81.5018 53.8495C81.1124 53.9094 80.7189 53.9493 80.3555 53.9733C79.966 53.9953 79.6225 53.9993 79.271 53.9993C78.3264 53.9993 77.5595 53.9094 76.9145 53.7157C76.3074 53.522 75.8121 53.2703 75.4446 52.9488C75.0891 52.6213 74.8355 52.2458 74.6877 51.8105C74.5439 51.3771 74.478 50.9198 74.478 50.4365C74.478 49.9272 74.5519 49.4559 74.7197 48.9926C74.8834 48.5352 75.149 48.1119 75.5305 47.7664C75.89 47.4269 76.3733 47.1433 76.9664 46.9256C77.5635 46.7099 78.2845 46.608 79.1392 46.608H81.5377V46.2446C81.5377 45.979 81.5178 45.7213 81.4558 45.4877C81.4039 45.27 81.2841 45.0703 81.1203 44.8906C80.9566 44.7288 80.7329 44.591 80.4493 44.5071C80.1597 44.4073 79.7903 44.3633 79.3409 44.3633C78.8536 44.3633 78.3264 44.4073 77.7373 44.4951C77.1801 44.573 76.7168 44.6729 76.3613 44.7907C76.2015 44.8286 76.0797 44.8366 76.0178 44.8147C75.9479 44.7867 75.89 44.7028 75.862 44.573L75.5585 43.3528C75.4985 43.1271 75.5684 42.9734 75.7562 42.9134C76.3613 42.6838 77.0163 42.516 77.7273 42.4222C78.4363 42.3363 79.1193 42.2884 79.7344 42.2884C81.2182 42.2884 82.3126 42.6099 83.0156 43.2709C83.6946 43.928 84.0421 44.8846 84.0421 46.1427V52.5554Z" fill={props.fill}/>
            <path d="M15.688 38.8972C15.6381 38.8133 15.5082 38.7814 15.3145 38.7814H9.28732C8.58035 38.7814 7.96924 38.8872 7.48195 39.1009C6.98667 39.3126 6.59924 39.5962 6.30367 39.9496C5.99812 40.3151 5.78443 40.7265 5.6626 41.1858C5.55476 41.6512 5.48486 42.1225 5.48486 42.6377V53.3181C5.48486 53.5039 5.52281 53.6257 5.61667 53.6836C5.70255 53.7435 5.82836 53.7715 5.98813 53.7715H6.65915C7.12247 53.7395 8.09705 53.5039 8.09705 52.1099V47.1791H14.5137C14.7094 47.1791 14.8212 47.1392 14.8772 47.0533C14.9311 46.9694 14.955 46.8456 14.955 46.6898V45.4736C14.955 45.2699 14.9311 45.1501 14.8772 45.0901C14.8212 45.0183 14.7094 44.9803 14.5137 44.9803H8.09705V42.478C8.09705 42.0206 8.20689 41.6831 8.43656 41.4455C8.64825 41.2038 9.03768 41.088 9.57889 41.088H15.3145C15.5042 41.088 15.6181 41.048 15.672 40.9722C15.7299 40.9182 15.7699 40.7824 15.7699 40.5947V39.2686C15.7699 39.1009 15.7299 38.9771 15.688 38.8972Z" fill={props.fill}/>
            <path d="M49.5018 49.8726C49.35 50.3459 49.1463 50.7333 48.8967 51.0369C48.643 51.3284 48.3315 51.5421 47.962 51.6819C47.5826 51.8277 47.1812 51.8936 46.7358 51.8936C46.4602 51.8936 46.1127 51.8696 45.7533 51.8337C45.3898 51.7977 45.0403 51.7259 44.6928 51.612V44.77C45.1441 44.6182 45.5615 44.5283 45.9869 44.4984C46.4163 44.4505 46.7198 44.4265 46.9635 44.4265C47.8282 44.4265 48.4873 44.7281 48.9825 45.3492C49.4658 45.9583 49.7015 46.8909 49.7015 48.1551C49.7015 48.8281 49.6456 49.4052 49.5018 49.8726ZM51.9942 45.7446C51.8024 45.0336 51.5069 44.4265 51.1214 43.9093C50.714 43.398 50.2108 42.9966 49.6036 42.721C48.9666 42.4214 48.2336 42.2876 47.3809 42.2876C46.9775 42.2876 46.5601 42.3156 46.0608 42.3815C45.5535 42.4414 45.1142 42.5452 44.6928 42.6711V38.5251C44.6928 38.3554 44.6449 38.2475 44.5949 38.2076C44.545 38.1576 44.4392 38.1397 44.2834 38.1397H43.6004C43.1291 38.1736 42.2204 38.4193 42.2004 39.7513V52.7244C42.2004 52.8502 42.2204 52.9501 42.2623 53.0339C42.3023 53.1118 42.3942 53.1777 42.516 53.2257C43.1471 53.5212 43.854 53.7169 44.6269 53.8228C45.3978 53.9506 46.1387 53.9985 46.8696 53.9985C47.8003 53.9985 48.6211 53.8488 49.3141 53.5612C49.9891 53.2576 50.5582 52.8502 51.0036 52.303C51.433 51.7678 51.7765 51.1387 51.9742 50.4098C52.1859 49.6888 52.2837 48.896 52.2837 48.0372C52.2837 47.2144 52.1859 46.4495 51.9942 45.7446Z" fill={props.fill}/>
            <path d="M68.6222 53.2571L68.4704 52.0649C68.4624 51.941 68.4305 51.8711 68.3765 51.8312C68.3306 51.7973 68.2547 51.7913 68.1309 51.8052C68.037 51.8112 67.9052 51.8252 67.7594 51.8472C67.6077 51.8592 67.4639 51.8592 67.362 51.8592C67.0824 51.8592 66.8827 51.7973 66.7889 51.6575C66.691 51.5336 66.6451 51.274 66.6451 50.9066V38.5007C66.6451 38.3429 66.5971 38.249 66.5432 38.2051C66.4773 38.1592 66.3555 38.1412 66.1917 38.1412H65.5207C65.0454 38.1731 64.1607 38.4188 64.1088 39.6929V51.5077C64.1088 52.4263 64.2965 53.0754 64.6699 53.4469C65.0294 53.8223 65.6266 53.998 66.4254 53.998C66.703 53.998 67.0265 53.9861 67.398 53.9561C67.7594 53.9102 68.083 53.8403 68.3686 53.7205C68.5123 53.6545 68.5982 53.5906 68.6222 53.5407C68.6501 53.4968 68.6501 53.3949 68.6222 53.2571Z" fill={props.fill}/>
            <path d="M74.1182 53.2571L73.9704 52.0649C73.9544 51.941 73.9225 51.8711 73.8685 51.8312C73.8226 51.7973 73.7287 51.7913 73.6229 51.8052C73.521 51.8112 73.3932 51.8252 73.2474 51.8472C73.1077 51.8592 72.9599 51.8592 72.854 51.8592C72.5704 51.8592 72.3747 51.7973 72.2729 51.6575C72.183 51.5336 72.1331 51.274 72.1331 50.9066V38.5007C72.1331 38.3429 72.0971 38.249 72.0312 38.2051C71.9773 38.1592 71.8555 38.1412 71.6957 38.1412H71.0007C70.5374 38.1731 69.6088 38.4308 69.6008 39.7748V51.5077C69.6008 52.4263 69.7765 53.0754 70.148 53.4469C70.5294 53.8223 71.1106 53.998 71.9074 53.998C72.203 53.998 72.5185 53.9861 72.882 53.9561C73.2474 53.9102 73.573 53.8403 73.8685 53.7205C74.0123 53.6545 74.0782 53.5906 74.1182 53.5407C74.1381 53.4968 74.1381 53.3949 74.1182 53.2571Z" fill={props.fill}/>
            <path d="M30.9197 53.2571L30.7719 52.0649C30.7519 51.941 30.728 51.8711 30.678 51.8312C30.6301 51.7973 30.5382 51.7913 30.4284 51.8052C30.3306 51.8112 30.1947 51.8252 30.047 51.8472C29.8812 51.8592 29.7694 51.8592 29.6515 51.8592C29.372 51.8592 29.1682 51.7973 29.0744 51.6575C28.9645 51.5336 28.9186 51.274 28.9186 50.9066V38.5007C28.9186 38.3429 28.8986 38.249 28.8327 38.2051C28.7628 38.1592 28.661 38.1412 28.5052 38.1412H27.8062C27.3269 38.1731 26.4023 38.4308 26.4023 39.7888V51.5077C26.4023 52.4263 26.59 53.0754 26.9535 53.4469C27.3269 53.8223 27.9081 53.998 28.7129 53.998C28.9925 53.998 29.32 53.9861 29.6835 53.9561C30.047 53.9102 30.3785 53.8403 30.6661 53.7205C30.7979 53.6545 30.8877 53.5906 30.9197 53.5407C30.9397 53.4968 30.9397 53.3949 30.9197 53.2571Z" fill={props.fill}/>
            <path d="M27.9006 26.1436L27.9088 26.125C27.9088 26.125 27.9047 26.1168 27.9006 26.1436Z" fill={props.fill}/>
            <path d="M85.9213 29.381L82.6801 26.1438L77.7193 21.195L78.3664 21.0552C82.7859 20.1345 85.7496 17.3506 85.7576 12.0064C85.7496 5.7655 80.5232 2.30655 75.2968 2.29656H64.3868C64.2649 2.30655 64.1631 2.33451 64.0892 2.37445C64.0113 2.42438 63.9694 2.5442 63.9694 2.74591V6.01314V26.1438V29.9243C64.0113 30.11 64.0632 30.2278 64.1491 30.2638C64.219 30.3017 64.3668 30.3157 64.5226 30.2897L64.5845 30.2817V30.2897L69.1218 29.6327C69.2197 29.6287 69.2816 29.5947 69.3355 29.5668C69.4214 29.5208 69.4534 29.393 69.4534 29.2013V26.1438V25.9321V7.64476H75.5065C78.1287 7.65075 80.3934 9.49806 80.4014 11.9645C80.4094 14.4169 78.58 15.671 75.9858 16.3021C73.3457 16.9771 72.1514 18.8185 72.1414 20.8096C72.1414 22.0657 72.6207 23.3798 73.6113 24.4882C74.484 25.5726 74.9953 26.0539 75.0971 26.1438C75.1051 26.1558 75.1411 26.1797 75.1411 26.1797L82.3985 33.551C82.8239 33.8166 83.3271 33.9863 83.9163 33.9863C85.4341 33.9843 86.7022 32.7561 86.7022 31.2523C86.7022 30.3856 86.3427 29.7964 85.9213 29.381Z" fill={props.fill}/>
            <path d="M27.0266 22.3104C26.5433 22.0068 26.024 21.8571 25.4868 21.8571C24.7259 21.8611 24.007 22.1726 23.5077 22.7098C23.3479 22.8995 22.9925 23.2251 22.8567 23.3629C20.9654 25.1163 18.489 26.0949 15.8948 26.0949C10.2311 26.0949 5.6238 21.4816 5.6238 15.8219C5.6238 10.1701 10.2311 5.57681 15.8948 5.57681C17.7701 5.58479 20.3603 5.91432 22.603 8.06318C22.603 8.06318 22.647 8.10712 22.6769 8.11311C22.7428 8.16903 22.8806 8.17901 23.0604 8.15505L27.9073 7.44209C28.3966 7.37019 28.1569 6.99474 28.1569 6.99474C27.2962 5.42902 23.7074 -0.0809402 15.4035 0.000940323C6.64231 0.0848179 0.0818805 6.96079 0 15.8997C0.0119825 19.8041 1.44589 23.3768 3.79446 26.1428C6.71221 29.5698 11.0579 31.7566 15.8948 31.7666C21.7882 31.7666 25.6426 28.6591 27.428 26.8138C27.428 26.8138 27.6497 26.5482 27.8773 26.1428C28.103 25.7394 28.3107 25.1942 28.3027 24.617C28.2787 23.6664 27.7994 22.8157 27.0266 22.3104Z" fill={props.fill}/>
            <path d="M57.0181 1.23061C54.7554 1.23061 53.4693 2.82828 52.4587 4.93321L46.2638 18.106C46.2638 18.106 46.2138 18.2658 46.112 18.2658C46.0101 18.2658 45.9402 18.106 45.9402 18.106L40.648 9.10113V9.09714L39.0163 6.29722C38.9225 6.13745 38.8226 6.04759 38.7248 6.0396C38.6229 6.02562 38.573 6.0376 38.573 6.0376L31.3455 7.05811C31.2816 7.07409 31.1918 7.09805 31.1498 7.134C31.0759 7.17794 31.04 7.30375 31.04 7.50147V10.7647V26.1443V31.2388C31.04 32.7187 32.2922 33.9269 33.786 33.9309C35.2758 33.9269 36.516 32.7187 36.528 31.2388V26.1443V12.9855L44.4085 26.1443L46.088 28.9462C46.088 28.9462 46.1519 29.048 46.2418 29.048C46.3157 29.048 46.3656 28.9462 46.3656 28.9462L47.7356 26.1443L55.672 10.0497V26.1443V31.151L55.688 31.149C55.72 31.3287 55.7699 31.4405 55.8638 31.4845C55.9257 31.5184 56.0435 31.5244 56.1873 31.5184L56.2272 31.5164L60.7986 30.8614L60.7906 30.8574C60.8984 30.8414 60.9843 30.8214 61.0402 30.7835C61.1301 30.7336 61.1441 30.6197 61.16 30.448H61.176V26.1443V5.37657C61.168 3.11387 59.2648 1.23261 57.0181 1.23061Z" fill={props.fill}/>
        </svg>
    )
}

export default FalabellaIcon