import {
    INVOICES_LOADING_ERROR,
    INVOICES_LOADED,
    LOADING_INVOICES_DATA,
    PAYMENT_METHOD,
    EDITING_BILLING_INFO,
    EDIT_BILLING_INFO,
    ERROR_EDITING_BILLING_INFO,
    REFRESH_EDIT_BILLING_INFO
} from "../../constants/invoicesConstants";
import {CLOSE_ERROR_DIALOG, RESET_ALL_DATA} from "../../constants";

const initState = {
    invoices: [],
    loading: false,
    error: undefined,
    billing_info: {
        address: '',
        city: '',
        country: '',
        email: '',
        plan: {
            name: '',
        },
        subscriptionId: '',
        businessName: '',
    },
    paymentMethod: 'account_money',
    editBillingInfo: {
        loading: false,
        error: undefined,
        success: false,
    }
}

const homeReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_INVOICES_DATA:
            return {...state, loading: true, error: undefined};
        case INVOICES_LOADED:
            return {...state, loading: false, error: undefined, invoices: action.payload.invoices, billing_info: action.payload.billing_info};
        case INVOICES_LOADING_ERROR:
            return {...state, loading: false, error: action.payload};
        case CLOSE_ERROR_DIALOG:
            return {...state, error: undefined};
        case RESET_ALL_DATA:
            return initState;
        case PAYMENT_METHOD:
            return {...state, paymentMethod: action.payload};
        case EDITING_BILLING_INFO:
            return {...state, editBillingInfo: {...state.editBillingInfo, loading: true, error: undefined, success: false}};
        case EDIT_BILLING_INFO:
            return {...state, billing_info: {...state.billing_info, ...action.payload}, editBillingInfo: {...state.editBillingInfo, loading: false, error: undefined, success: true}};
        case ERROR_EDITING_BILLING_INFO:
            return {...state, editBillingInfo: {...state.editBillingInfo, loading: false, error: action.payload, success: false}};
        case REFRESH_EDIT_BILLING_INFO:
            return {...state, editBillingInfo: {...state.editBillingInfo, loading: false, error: undefined, success: false}};
        default:
            return state;
    }
}

export default homeReducer;