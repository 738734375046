import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import {changeShowSidebar, changeTitleAppBar} from '../../actions/sidebarActions';
import {
    changeUserAccountBookingRole,
    changeEditUserAccountIndex,
    changeUserAccountAdminRole,
    toggleLoadingUserAccounts,
    changeUserAccountEnable,
    changeUserAccountMyRole,
    changeUserAccountEmail,
    changeUserAccountName,
    setErrorModalState,
    changeUserPassword,
    closeErrorDialog,
    loadUserAccounts,
    setEditUserRole,
    addUserAccount,
    resetAllData,
    deleteUser,
    updateUser,
} from '../../actions';

// Components
import ButtonWithIcon from '../../components/NewComponents/ButtonWithIcon/ButtonWithIcon';
import ProgressIndicator from '../../components/GeneralComponents/ProgressIndicator';
import UsersComponent from '../../components/EditForms/UserAccounts/UsersComponent';
import ChangePassword from '../../components/EditForms/UserAccounts/ChangePassword';
import NewUserDialog from '../../components/EditForms/UserAccounts/newUserDialog';
import ErrorDialog from "../../components/GeneralComponents/Modal/ErrorDialog";
import SaveChangesModal from "../../components/Modals/SaveChangesModal";

// import SaveChangesButton from "../../components/NewComponents/Buttons/Save Changes/SaveChangesButton";


// Constants
// import {ADMIN_ROLE, MESAYA_ROLE, RESERVA_ROLE} from '../../constants';
import { ModalMode } from "../../constants/modalMode";

// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Material Ui
import { makeStyles } from "@material-ui/core";
import { Box, Button } from '@mui/material';

// Utils
import { deepEqual } from "../../utils/objectHelpers";

// Styles
import { SaveUserButtonStyles } from '../../components/Users/Styles';
// import { SaveOptionalButtonStyles } from '../../components/Optional/Styles/OptionalStyles';

const useStyles = makeStyles(() => ({
    MainContainer: {
        fontFamily: 'Poppins, sans-serif',
        paddingBottom: '4rem'
    },
    TopSection: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '80%',
        '@media (max-width: 1700px)': {
            width: '98%',
        },
    },
    Title: {
        fontSize: '1.6rem',
        fontWeight: '600',
    },
    Subtitle: {
        fontWeight: '400',
        fontSize: '.8rem',
        color: '#A6A6A6',
    },
    UsersContainer: {
        width: '80%',
        '@media (max-width: 1700px)': {
            width: '98%',
        },
    },
    ChangePasswordTetx: {
        fontFamily: 'Poppins, sans-serif',
        marginTop: '-.9rem',
        textAlign: 'right',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#041C32',
        cursor: 'pointer'
    }
}));

const UsersPage = (props) => {

    const classes = useStyles();

    const [showChangePass, setShowChangePass] = useState( undefined );
    const [newUserDialog, setNewUserDialog] = useState( false );
    const [admin, setAdmin] = useState( undefined );

    // Variables
    const {
        idPartnerSelected,
        showErrorModal, 
        editUserIndex,
        profileData,
        profile, 
        loading, 
        edited, 
        users, 
        error
    } = props;

    // Actions
    const {
        // changeUserAccountBookingRole,
        changeEditUserAccountIndex,
        // changeUserAccountAdminRole,
        // changeUserAccountMyRole,
        changeUserAccountEnable,
        changeUserAccountEmail,
        changeUserAccountName,
        setErrorModalState,
        changeUserPassword,
        changeTitleAppBar,
        changeShowSidebar,
        loadUserAccounts,
        // closeErrorDialog,
        exitWithoutSave,
        setEditUserRole,
        updateUser,
        addNewUser,
        deleteUser,
    } = props;

    const [userDisplayName, setUserDisplayName] = useState('');
    const [userDataEditing, setUserDataEditing] = useState({});
    const [idUserEdited, setIdUserEdited] = useState('');

    useEffect(() => {
        if ( profileData ) {
            setUserDisplayName( profileData[idPartnerSelected]?.displayName );
        }
    }, [ idPartnerSelected, profileData ]);

    const handleShowErrorModal = () => {
        setErrorModalState({
            value: !showErrorModal
        });
    };

    const handleEditing = ( index, id ) => {
        changeEditUserAccountIndex(index);
        setIdUserEdited( id );
    };

    const handleEditingDone = ({ name, email, uid }) => {
        updateUser({ name, email, uid: uid });
        changeEditUserAccountIndex(-1);
        setIdUserEdited( uid );
    };

    useEffect(() => {
        changeTitleAppBar("Usuarios");
        changeShowSidebar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ( idPartnerSelected ) {
            loadUserAccounts()
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ idPartnerSelected ]);

    useEffect(() => {
        if ( users.length > 0 ) {
            setAdmin(users[0]);
        };
    }, [ users ]);

    useEffect(() => {
        if ( users.length > 0 ) {
            for (let i = 0; i < users.length; i++) {
                if ( users[i].id === idUserEdited ) {
                    setUserDataEditing({ 
                        name: users[i].name,
                        email: users[i].email,
                        id: users[i].id
                    });
                }
            }
        };
    }, [ users, idUserEdited ]);

    const changePassword = (user) => {
        if ( deepEqual( user, admin ) ) {
            if ( profile.email === user.email ) {
                setShowChangePass(user);
            }
        } else {
            setShowChangePass(user);
        }
    };

    const onSubmit = () => {
        updateUser({ 
            email: userDataEditing.email, 
            name: userDataEditing.name, 
            uid: userDataEditing.id 
        });
    };

    const onAddNewUser = ({ name, email, password }) => {
        addNewUser({ name, email, password, userName: userDisplayName });
        setNewUserDialog( false );
    };

    const onChangePassword = (user, password) => {
        changeUserPassword(user, password);
        setShowChangePass( undefined );
    };

    const onExitWithoutSave = () => {
        exitWithoutSave();
        return true;
    };

    const handleShowUserRole = ( array, partnerId ) => {
        for (let i = 0; i < array.length; i++) {
            if ( array[i].uuid === partnerId ) return array[i].role;
        };
    };

    return (
        <main className={classes.MainContainer}>
            <div className={classes.TopSection}>
                <h1 className={classes.Title}>Usuario principal <span
                    className={classes.Subtitle}><i>(predeterminado)</i></span></h1>
                <ButtonWithIcon
                    text={"Agregar usuario"}
                    onClick={() => setNewUserDialog(true)}
                    icon={<AddCircleOutlineOutlinedIcon/>}
                    margin={'auto 0'}
                />
            </div>
            <section>
                <div className={classes.UsersContainer}>
                    {
                        admin ? 
                            <UsersComponent
                                // bookingCheck={ admin.role && admin.role.includes(RESERVA_ROLE) }
                                // mesaYaCheck={ admin.role && admin.role.includes(MESAYA_ROLE) }
                                // adminCheck={ admin.role && admin.role.includes(ADMIN_ROLE) }
                                userRole={ handleShowUserRole( admin.partners, idPartnerSelected ) }
                                id={ idPartnerSelected }
                                enable={ admin.enable }
                                email={ admin.email }
                                name={ admin.name }
                                userId={ admin.id }

                                onEditingDone={ 
                                    () => handleEditingDone({ 
                                        email: admin.email, 
                                        name: admin.name, 
                                        uid: admin.id 
                                    }) 
                                }
                                onEnableUser={(value) => changeUserAccountEnable({id: admin.id, enable: value})}
                                // onChangeBookingRole={(value) => changeUserAccountBookingRole(value)}
                                // onChangeAdminRole={(value) => changeUserAccountAdminRole(value)}
                                onChangeEmail={(e) => changeUserAccountEmail(e.target.value)}
                                onChangeName={(e) => changeUserAccountName(e.target.value)}
                                onChangePassword={() => changePassword(admin)}
                                onStartEditing={() => handleEditing('1', admin.id)}
                                onDelete={() => deleteUser(admin.id)}
                                onChangeMyRole={ setEditUserRole }
                                editing={ editUserIndex === '1' }
                                isAdmin={ true }
                            />
                        : 
                            null
                    }
                </div>
            </section>
            <h1 className={classes.Title}>Usuarios</h1>
            {
                users.length >= 1
                    ? 
                        <section>
                            {
                                users?.slice(1).map((user, index) => (
                                    <div key={ user.id } className={classes.UsersContainer}>
                                        <UsersComponent
                                            // bookingCheck={ user.role && user.role.includes(RESERVA_ROLE) }
                                            // mesaYaCheck={ user.role && user.role.includes(MESAYA_ROLE) }
                                            // adminCheck={ user.role && user.role.includes(ADMIN_ROLE) }
                                            userRole={ handleShowUserRole( user.partners, idPartnerSelected ) }
                                            id={ idPartnerSelected }
                                            enable={ user.enable }
                                            email={ user.email }
                                            userId={ user.id }
                                            name={ user.name }

                                            onEditingDone={ 
                                                () => handleEditingDone({ 
                                                        email: user.email,
                                                        name: user.name, 
                                                        uid: user.id 
                                                }) 
                                            }
                                            onEnableUser={(value) => changeUserAccountEnable({id: user.id, enable: value})}
                                            // onChangeBookingRole={(value) => changeUserAccountBookingRole(value)}
                                            // onChangeAdminRole={(value) => changeUserAccountAdminRole(value)}
                                            onChangeEmail={(e) => changeUserAccountEmail( e.target.value )}
                                            onChangeName={(e) => changeUserAccountName( e.target.value )}
                                            onStartEditing={() => handleEditing( index + 1, user.id )}
                                            editing={ editUserIndex === ( index + 1 ) }
                                            onDelete={() => deleteUser( user.id )}
                                            onChangeMyRole={ setEditUserRole }
                                            isAdmin={ false }
                                        />
                                        <div 
                                            onClick={() => changePassword(user)}
                                            style={{
                                                width: 'max-content', 
                                                float: 'right', 
                                            }} 
                                        >
                                            <p className={classes.ChangePasswordTetx}>
                                                <u>Cambiar contraseña</u>
                                            </p>
                                        </div>
                                    </div>
                            ))}
                        </section>
                    : 
                        null
            }
            <NewUserDialog 
                dialogController={ setNewUserDialog }
                partnerId={ idPartnerSelected } 
                dialogState={ newUserDialog } 
                onSubmit={ onAddNewUser }
            />
            <ChangePassword 
                dialogController={ () => setShowChangePass(undefined) } 
                dialogState={ showChangePass !== undefined }
                onSubmit={ onChangePassword }
                user={showChangePass}
            />
            <ProgressIndicator open={loading} />
            {/* { edited && <SaveChangesButton onClick={ onSubmit } /> } */}
            <Box 
                sx={{
                    display: edited ? 'block' : 'none',
                    transform: 'translateY(-50%)',
                    position: 'fixed',
                    left: '50%',
                    zIndex: 4, 
                    bottom: 0,
                }}
            >
                <Button
                    sx={ SaveUserButtonStyles } 
                    onClick={ onSubmit } 
                >
                    Guardar cambios
                </Button>
            </Box>
            <ErrorDialog 
                onAccept={ handleShowErrorModal }
                type={ ModalMode.ERROR } 
                open={ showErrorModal } 
                title={ "Error" } 
                body={ error }
            />
            <SaveChangesModal 
                handleClose={ onExitWithoutSave }
                submit={ onSubmit } 
                when={ edited } 
            />
        </main>
    )
}

const mapStateToProps = state => {
    return {
        profileData: state.firestore.data.partners,
        profile: state.firebase.profile,

        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        showErrorModal: state.userAccountsReducer.editReducer.showErrorModal,
        editUserIndex: state.userAccountsReducer.editReducer.editUserIndex,
        loading: state.userAccountsReducer.editReducer.loading,
        edited: state.userAccountsReducer.editReducer.edited,
        users: state.userAccountsReducer.editReducer.users,
        error: state.userAccountsReducer.editReducer.error,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeEditUserAccountIndex: (uid) => dispatch(changeEditUserAccountIndex(uid)),
        toggleLoadingUserAccounts: (uid) => dispatch(toggleLoadingUserAccounts(uid)),

        changeUserAccountMyRole: (data) => dispatch(changeUserAccountMyRole(data)),
        changeUserAccountBookingRole: (data) => dispatch(changeUserAccountBookingRole(data)),
        changeUserAccountAdminRole: (data) => dispatch(changeUserAccountAdminRole(data)),
        changeUserAccountName: (data) => dispatch(changeUserAccountName(data)),
        changeUserAccountEmail: (data) => dispatch(changeUserAccountEmail(data)),
        changeUserAccountEnable: (data) => dispatch(changeUserAccountEnable(data)),

        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),

        changeUserPassword: (user, password) => dispatch(changeUserPassword(user, password)),
        loadUserAccounts: () => dispatch(loadUserAccounts()),
        addNewUser: ({ name, email, password, userName }) => dispatch(addUserAccount({
            name,
            email,
            password,
            userName
        })),
        updateUser: ({ name, email, password, role, uid }) => dispatch( updateUser({ name, email, password, role, uid }) ),
        setEditUserRole: ({ partnerId, role, userId }) => dispatch( setEditUserRole({ partnerId, role, userId }) ),
        setErrorModalState: ({ value }) => dispatch( setErrorModalState({ value }) ),
        closeErrorDialog: () => dispatch(closeErrorDialog()),
        deleteUser: ( uid ) => dispatch(deleteUser( uid )),
        exitWithoutSave: () => dispatch(resetAllData()),
    }
};

export default connect( mapStateToProps, mapDispatchToProps )( UsersPage );
