import {
    ADD_EDITED_IMAGE,
    ADD_NEW_PRODUCT,
    CHANGE_EDIT_PRODUCT_DAYS,
    CHANGE_EDIT_PRODUCT_DESCRIPTION,
    CHANGE_EDIT_PRODUCT_NAME,
    CHANGE_EDIT_PRODUCT_PRICE,
    CHANGE_EDIT_PRODUCT_VISIBILITY,
    CHANGE_SELECTED_CATEGORY,
    idAllCategories,
    PARTNERS,
    RESET_ALL_DATA,
    TOGGLE_EDIT_PRICES_DIALOG,
    TOGGLE_EDIT_PRODUCT_LOADING,
} from "../../../constants";

import {deepCopy} from "../../../utils/arrayHelpers";
import {arrayMove} from "react-sortable-hoc";

const initState = {
  categories: [],
  changesInCategory: [],
  selectedCategory: undefined,
  loading: false,
  showEditDialog: false,
  error: "",
};

const editReducer = (state = initState, action) => {
  let newEditedCategories = deepCopy(state.categories);
  let index = -1;
  let newChanges = [...state.changesInCategory];

  switch (action.type) {
    case "@@reduxFirestore/LISTENER_RESPONSE":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
          action.payload.ordered
      ) {
        let changes = [];
        for (let i = 0; i < action.payload.ordered.length; i++) {
          changes[i] = false;
        }
        return {
          ...state,
          categories: deepCopy(action.payload.ordered),
          selectedCategory: action.payload.ordered.length > 0 ? action.payload.ordered[0].id : idAllCategories,
          changesInCategory: [...changes],
        };
      } else {
        return { ...state };
      }

    case "@@reduxFirestore/DOCUMENT_ADDED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.ordered
      ) {
        let newCategories = deepCopy(state.categories);
        let data = {
          id: action.meta.doc,
          name: action.payload.data["name"],
        };

        newCategories.push(data);

        return {
          ...state,
          categories: deepCopy(newCategories),
          changesInCategory: deepCopy(newCategories),
        };
      }
      return { ...state };

    case "@@reduxFirestore/DOCUMENT_MODIFIED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.data
      ) {
        index = newEditedCategories.findIndex(
          (category) => category.id === action.meta.doc
        );
        if (index === -1) {
          newEditedCategories.push({
            id: action.meta.doc,
            name: action.payload.data.name,
            products: action.payload.data.products,
          });
          newChanges.push(false);
        } else {
          newEditedCategories[index].name = action.payload.data.name;
          newEditedCategories[index].visibility = action.payload.visibility;
          newEditedCategories[index].order = action.payload.data.order;
          newEditedCategories[index].products = deepCopy(
            action.payload.data.products
          );
          let newIndex = action.payload.data.order;

          newEditedCategories = arrayMove(newEditedCategories, index, newIndex);
          newChanges[index] = false;
        }

        return {
          ...state,
          changesInCategory: [...newChanges],
          categories: deepCopy(newEditedCategories),
        };
      }
      return { ...state };

    case "@@reduxFirestore/DOCUMENT_REMOVED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.ordered
      ) {
        let newCategoriesWithoutDeleted = deepCopy(state.categories);

        newCategoriesWithoutDeleted = newCategoriesWithoutDeleted.filter(
          (category) => category["id"] !== action.meta.doc
        );

        return {
          ...state,
          categories: deepCopy(newCategoriesWithoutDeleted),
          changesInCategory: deepCopy(newCategoriesWithoutDeleted),
        };
      }
      return { ...state };

    case CHANGE_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };

    case ADD_NEW_PRODUCT:
      if (!action.payload) return {...state}
      index = newEditedCategories.findIndex(
          (category) => category.id === action.payload
      );
      if (!(newEditedCategories[index].products?.length)) {
        newEditedCategories[index].products = [{
          name: '',
          description: '',
          price: '',
          image: '',
          visibility: true,
          days: [true, true, true, true, true, true, true],
        }]
      } else {
        newEditedCategories[index].products.unshift({
          name: '',
          description: '',
          price: '',
          image: '',
          visibility: true,
          days: [true, true, true, true, true, true, true],
        })
      }
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      }

    case CHANGE_EDIT_PRODUCT_NAME:
      index = newEditedCategories.findIndex(
        (category) => category.id === action.payload.categoryId
      );
      newEditedCategories[index].products[action.payload.productIndex].name =
        action.payload.data;
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case CHANGE_EDIT_PRODUCT_VISIBILITY:
      index = newEditedCategories.findIndex(
        (category) => category.id === action.payload.categoryId
      );
      newEditedCategories[index].products[
        action.payload.productIndex
      ].visibility = action.payload.data;
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case CHANGE_EDIT_PRODUCT_DESCRIPTION:
      index = newEditedCategories.findIndex(
        (category) => category.id === action.payload.categoryId
      );
      newEditedCategories[index].products[
        action.payload.productIndex
      ].description = action.payload.data;
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case CHANGE_EDIT_PRODUCT_PRICE:
      index = newEditedCategories.findIndex(
          (category) => category?.id === action.payload.categoryId
      );
      if (action.payload.data === "") {
        delete newEditedCategories[index].products[action.payload.productIndex]
          .price;
      } else {
        if (newEditedCategories[index].products[action.payload.productIndex].price === null)
          delete newEditedCategories[index].products[action.payload.productIndex].price;
        newEditedCategories[index].products[action.payload.productIndex].price =
            action.payload.data;
      }
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case CHANGE_EDIT_PRODUCT_DAYS:
      index = newEditedCategories.findIndex(
        (category) => category.id === action.payload.categoryId
      );
      newEditedCategories[index].products[action.payload.productIndex].days =
        action.payload.data;
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case ADD_EDITED_IMAGE:
      index = newEditedCategories.findIndex(
        (category) => category.id === action.payload.categoryId
      );
      newEditedCategories[index].products[action.payload.productIndex].newImg =
        action.payload.data;
      newChanges[index] = true;
      return {
        ...state,
        changesInCategory: [...newChanges],
        categories: deepCopy(newEditedCategories),
      };

    case TOGGLE_EDIT_PRODUCT_LOADING:
      return { ...state, loading: !state.loading };
    case TOGGLE_EDIT_PRICES_DIALOG:
      return { ...state, showEditDialog: !state.showEditDialog };
    case RESET_ALL_DATA:
      return initState;
    default:
      return state;
  }
};

export default editReducer;
