import React from 'react';

const GroupIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: '-.25rem'}}>
            <path d="M10.9375 9.47949C10.9375 13.0976 13.8819 16.042 17.5 16.042C21.1181 16.042 24.0625 13.0976 24.0625 9.47949C24.0625 5.86137 21.1181 2.91699 17.5 2.91699C13.8819 2.91699 10.9375 5.86137 10.9375 9.47949ZM29.1667 30.6253H30.625V29.167C30.625 23.5393 26.0444 18.9587 20.4167 18.9587H14.5833C8.95417 18.9587 4.375 23.5393 4.375 29.167V30.6253H29.1667Z" fill="#0B4762"/>
        </svg>
    )
}

export default GroupIcon;