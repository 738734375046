import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core";
import {Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    TableHeadText: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.2rem',
        fontWeight: '600',
        margin: '0',
        textAlign: 'left'
    },
    AvatarContainer: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '50%',
        display: 'flex',
        height: '40px',
        width: '40px',
    },
    UserNameText: {
        fontFamily: 'Poppins, sans-serif',
        margin: 'auto 0 auto 1rem',
        fontWeight: '500',
        fontSize: '1rem',
        color: '#808080',
    },
    IncomeText: {
        fontFamily: 'Poppins, sans-serif',
        margin: '0',
        fontSize: '1.5rem',
        fontWeight: '600',
        color: '#0B4762',
        textAlign: 'center',
    },
}));


const ClientsTable = (props) => {

    const classes = useStyles();

    const {rows} = props;

    return (
        <TableContainer>
            <Table sx={{minWidth: '100%'}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <h2
                                className={classes.TableHeadText}
                                style={{
                                    color: '#041C32'
                                }}
                            >Mejores Clientes</h2>
                        </TableCell>
                        <TableCell align="center">
                            <h2
                                className={classes.TableHeadText}
                                style={{
                                    color: '#0B4762',
                                    // marginLeft: '7rem',
                                    textAlign: 'center'
                                }}
                            >Ingresos</h2>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.userName}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell
                                sx={{display: 'flex', justifyItems: 'center'}}
                            >
                                <Avatar
                                    sx={{bgcolor: "#0B4762"}}
                                    alt={row.userName}
                                    src={row.userAvatar}
                                >
                                    {row.userName.toUpperCase()[0]}
                                </Avatar>
                                <p
                                    className={classes.UserNameText}
                                >{row.userName}</p>
                            </TableCell>
                            <TableCell>
                                <h2
                                    className={classes.IncomeText}
                                >{row.userIncome}</h2>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ClientsTable;

