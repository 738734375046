import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { 
    setRestartOptionalForm,
    setOptionalViewMode, 
    setOptionalName, 
    setOptionalType, 
    setSaveOptional, 
    addNewOptional, 
    setClientText,
    editOptional,
    setArrayOptionalSelected,
} from '../../../actions/optionalActions';

// Components
import OptionalCheckboxComponent from '../components/OptionalCheckboxComponent';
import OptionalErrorSnackbar from '../components/OptionalErrorSnackbar';
import OptionalTextField from '../components/OptionalTextField';
import OptionalSelect from '../components/OptionalSelect';

// Helpers
import { OptionalSelectArray } from '../helpers';

// Material Ui
import { Box, Button, Divider,  Typography } from '@mui/material';

// Styles
import { 
    OptionalDescriptionStyle, 
    SaveOptionalButtonStyles,
    OptionalItemsContainer,
    OptionalSubtitleStyle, 
    OptionalTitleStyle, 
    MainContainer,
} from '../Styles/OptionalStyles';

const CreateOptionalLayout = ( props ) => {

    // Actions
    const { 
        setArrayOptionalSelected, 
        setRestartOptionalForm,
        setOptionalViewMode,
        setSaveOptional, 
        setOptionalName, 
        setOptionalType, 
        addNewOptional,
        setClientText,
        editOptional,
    } = props;

    // External props
    const { children } = props;

    // States
    const { 
        multipleOptionsArray,
        requiredResponse, 
        showSaveChanges, 
        optionSelected,
        oneClickArray,
        optionalArray, 
        optionalName,
        clientText,
        viewMode,
    } = props;

    const [showSnackbar, setShowSnackbar] = useState( false );
    const [arraySelected, setArraySelected] = useState([]);
    const [error, setError] = useState({
        typeOptionalSeleted: false,
        optionalName: false,
    });

    useEffect(() => {
        switch ( optionSelected ) {
            case 'Click único':
                setArrayOptionalSelected( oneClickArray );
                setArraySelected( oneClickArray );
                break;
            case 'Opción múltiple':
                setArrayOptionalSelected( multipleOptionsArray );
                setArraySelected( multipleOptionsArray );
                break;
            default:
                setArrayOptionalSelected([]);
                setArraySelected([]);
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionSelected, oneClickArray, multipleOptionsArray]);
    
    const handleSaveChanges = () => {
        const index = arraySelected.findIndex( item => item.text === '' );

        if ( optionSelected !== '' && optionalName !== '' && index === -1  ) {

            if ( viewMode === 'NEW' ) {
                addNewOptional( arraySelected );
            } else {
                editOptional();
            }
            setSaveOptional({
                multipleClicks: multipleOptionsArray,
                required: requiredResponse,
                id: optionalArray.length,
                oneClick: oneClickArray,
                option: optionSelected,
                clientText: clientText,
                title: optionalName,
            });
            setOptionalViewMode('VIEW');
            setRestartOptionalForm();
            setError({
                typeOptionalSeleted: false,
                optionalName: false
            });
            setShowSnackbar( false );
        } else {
            setShowSnackbar( true );
            if ( optionSelected === '' && optionalName !== '' ) {
                setError({
                    typeOptionalSeleted: true,
                    optionalName: false
                });
            } else if ( optionSelected !== '' && optionalName === '' ) {
                setError({
                    typeOptionalSeleted: false,
                    optionalName: true
                });
            } else if ( optionSelected === '' && optionalName === '' ) {
                setError({
                    typeOptionalSeleted: true,
                    optionalName: true
                });
            } else if ( optionSelected !== '' && optionalName !== '' && index !== -1 ) {
                setError({
                    typeOptionalSeleted: false,
                    optionalName: false
                });
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setShowSnackbar( false );
    };
    
    return (
        <Box sx={ MainContainer }>
            <Typography variant='h4' sx={ OptionalTitleStyle }>Configuración del opcional</Typography>
            <Box sx={ OptionalItemsContainer }>
                <Typography variant='h5' sx={ OptionalSubtitleStyle }>Nombre del opcional</Typography>
                <Typography sx={ OptionalDescriptionStyle }>
                    El nombre no será visto por el cliente. Unicamente quedará en el administrador y la app partner
                </Typography>
                <OptionalTextField 
                    handleChange={ setOptionalName }
                    error={ error.optionalName }
                    value={ optionalName }
                    placeholder=''
                />
            </Box>
            <Divider sx={{ color: '#E7E7E7' }}/>
            <Box sx={ OptionalItemsContainer }>
                <Typography sx={ OptionalSubtitleStyle }>Tipo de opcional</Typography>
                <OptionalSelect 
                    optionsArray={ OptionalSelectArray }
                    error={ error.typeOptionalSeleted }
                    handleChange={ setOptionalType }   
                    value={ optionSelected }
                />
                {
                    children
                }
                <OptionalCheckboxComponent />
            </Box>
            <Divider/>
            <Box sx={ OptionalItemsContainer }>
                <Typography sx={ OptionalSubtitleStyle }>Comunicación con el cliente</Typography>
                <Typography sx={ OptionalDescriptionStyle }>
                    Este texto se le mostrará al cliente a la hora de realizar su reserva
                </Typography>
                <OptionalTextField 
                    placeholder='Este texto se le mostrará al cliente a la hora de realizar su reserva'
                    handleChange={ setClientText }
                    value={ clientText }
                />
            </Box>
            <Box 
                sx={{
                    display: showSaveChanges ? 'block' : 'none',
                    transform: 'translateY(-50%)',
                    position: 'absolute',
                    bottom: '2rem',
                    left: '50%',
                }}
            >
                <Button
                    sx={ SaveOptionalButtonStyles } 
                    onClick={ handleSaveChanges } 
                >
                    Agregar opcional
                </Button>
            </Box>
            <OptionalErrorSnackbar 
                message={'Completá los campos obligatorios'}
                handleClose={ handleClose }
                open={ showSnackbar }
            />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        multipleOptionsArray: state.optionalReducer.multipleOptionsArray,
        requiredResponse: state.optionalReducer.requiredResponse,
        showSaveChanges: state.optionalReducer.showSaveChanges,
        optionSelected: state.optionalReducer.optionSelected,
        oneClickArray: state.optionalReducer.oneClickArray,
        optionalArray: state.optionalReducer.optionalArray,
        optionalName: state.optionalReducer.optionalName,
        clientText: state.optionalReducer.clientText,
        viewMode: state.optionalReducer.viewMode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setArrayOptionalSelected: (data) => dispatch( setArrayOptionalSelected(data) ),
        setOptionalViewMode: (data) => dispatch( setOptionalViewMode(data) ),
        setRestartOptionalForm: () => dispatch( setRestartOptionalForm() ),
        setOptionalName: (data) => dispatch( setOptionalName(data) ),
        setOptionalType: (data) => dispatch( setOptionalType(data) ),
        setSaveOptional: (data) => dispatch( setSaveOptional(data) ),
        setClientText: (data) => dispatch( setClientText(data) ),
        addNewOptional: () => dispatch( addNewOptional() ),
        editOptional: () => dispatch( editOptional() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( CreateOptionalLayout );