import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import 'moment/locale/en-gb';

// Actions
import { changeDays, changeEndDay, changeRepeat, changeStartDay } from '../../../../../actions/shiftsActions';

// Material Ui
import { Typography, Box } from "@mui/material";

// Utils
import { GetMomentDate, getLetterDay } from '../utils/functions';
import { getRRule, getWeekInterval } from '../utils/ScheduleFunctions';

// Styles
import { DaysContainer, ShiftSelectTitles } from '../../../Styles';
import ShiftMoreOptions from './General/ShiftMoreOptions';
import dayjs from 'dayjs';

const ScheduleStepSelectDays = (props) => {
  const enable = true;
  const editing = true;
  const days = [true, false, true, true, true, true, true];      
  const [optionAhora, setOptionAhora] = useState("ahora");
  const [optionNunca, setOptionNunca] = useState("nunca");

  // Variables
  const {
    calendar,
    isSpecial
  } = props;

  // Acciones
  const {
    changeDays,
    changeStartDay,
    changeEndDay,
    changeRepeat,
  } = props;

  const newDays = [false, false, false, false, false, false, false];
  const [value, setValue] = useState(calendar.startDate !== "" ? calendar.startDate : new Date());
  const [valueNunca, setValueNunca] = useState(calendar.endDate !== "" ? calendar.endDate : null);
  const [active, setActive] = useState(calendar.repeat !== '');
  const [weeksNumber, setWeeksNumber] = useState(calendar.repeat !== '' ? getWeekInterval(calendar.repeat) : 1);
  

  const GetDays = () => {
    let selectedDays = false;

    if (calendar.days.length > 0) {
      calendar.days.map((day) => {
        newDays[day] = true;
        selectedDays = true;
      });
    };

    return selectedDays;
  };

  const selectedDays = GetDays();    
  const [openDays, setOpenDays] = useState(selectedDays > 0 ? newDays : days);  

  useEffect(() => {
    if (isSpecial) {
      setOpenDays([false, false, false, false, false, false, false]);
    } else {
      if (calendar.days.length > 0) {
        calendar.days.map((day) => (
          newDays[day] = true
        ));

        setOpenDays(newDays);
      } else {
        setOpenDays([true, false, true, true, true, true, true]);
      }        
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpecial]);

  useEffect(() => {     
    const days = [];

    // eslint-disable-next-line array-callback-return
    openDays.map((day, index) => {
      if( day ) {
        days.push(index);
      }
    });

    changeDays(days);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDays]);

  useEffect(() => {

    const today = new Date();
    let dateStart = new Date(value);
    
    if ( dateStart.setHours(0,0,0,0) === today.setHours(0,0,0,0) ) {
      setOptionAhora("ahora");
    } else {
      setOptionAhora("el");
    };

    changeStartDay( GetMomentDate( dateStart ) );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // useEffect(() => {
  //   console.log( isSpecial, calendar.specialDates.length );
  //   if ( isSpecial && calendar.specialDates.length > 0 ) {
  //     changeStartDay( GetMomentDate( calendar.specialDates[ 0 ] ) );
  //   };

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ calendar.specialDates, isSpecial ]);

  useEffect(() => {
    if ( valueNunca === null ) {
      setOptionNunca("nunca");
      changeEndDay(null);
      
    } else {
      const newValue = new Date(valueNunca);

      if ( newValue.getFullYear() === 3000 ) {
        setOptionNunca("nunca");
      } else {
        setOptionNunca("el");
      }        

      changeEndDay( GetMomentDate(newValue) );
    }      
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNunca]);

  useEffect(() => {
    if( active && weeksNumber ){
      const rule = getRRule(calendar.startDate, calendar.endDate, weeksNumber, calendar.days);
      changeRepeat(rule.toString());
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar.repeat, active, weeksNumber, calendar.startDate, calendar.endDate, calendar.days]);

  const onChangeDays = (day) => {
      const cloneOpenDay = [...openDays];
      cloneOpenDay[day] = !cloneOpenDay[day];
      setOpenDays(cloneOpenDay);
  };   

  const handleChange = (event) => {
    if (event.target.value === 'ahora') {
      setValue( dayjs( Date.now() ));
    } 

    setOptionAhora( event.target.value );
  };

  const handleChangeNunca = (event) => {
    if (event.target.value === 'nunca') {
      setValueNunca( null );
    }

    setOptionNunca(event.target.value);
  };
  
  return (
    <Box>
      <Box>
        <Typography sx={ ShiftSelectTitles }>Seleccionar días</Typography>
        <Box sx={ DaysContainer }>
          {
            openDays?.map((day, index) =>
              <Box
                  key={ `letra-${ index }` }
                  onClick={() => editing ? onChangeDays(index) : null}
                  sx={{
                    backgroundColor: enable ? day ? '#0B4762' : '#E7E7E7' : day ? '#848282' : '#E7E7E7',
                    fontFamily: 'Poppins, sans-serif',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    userSelect: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    fontWeight: 600,
                    fontSize:'17px',
                    color: 'white',
                    height: '37px',
                    width: '37px',
                    mr: '10px',
                    mt: '3rem'
                  }}
              >
                {
                    getLetterDay(index)
                }
              </Box>
          )}
        </Box>          
      </Box>
      <ShiftMoreOptions
        handleChangeNunca={ handleChangeNunca }
        handleChangeStart={ handleChange }
        getWeekInterval={ getWeekInterval }  
        setValueNunca={ setValueNunca }
        changeRepeat={ changeRepeat }
        optionAhora={ optionAhora }
        optionNunca={ optionNunca }
        setStartValue={ setValue }
        valueNunca={ valueNunca } 
        calendar={ calendar }
        startValue={ value } 
      />
      {/* <Stack
          width={'180px'}
          mt={3}
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ cursor: "pointer", textDecorationLine: "underline" }}
          onClick={handleAdvancedOptionsClick}
      >
          <Typography
              fontSize="20px"
              color="#0B4762"
              fontWeight="500"
              fontFamily="Poppins"
              my={3}
              style={{ textDecorationLine: "underline" }}
            >
            Más opciones
            </Typography>
            {advancedOptionsOpen ? (
            <ExpandLessIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
            ) : (
            <ExpandMoreIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
            )}
      </Stack>
      {advancedOptionsOpen && (
          <>
            <Box width="723px">
              <Box width="100%" display="flex" flexDirection="row" alignItems={"center"} marginLeft="-13px">
                <BlueSwitch
                  checked={active}
                  onChange={() => handleChangeActive(active)}
                  color="default"
                />
                <Subtitle 
                  style={{ paddingTop: "12px", paddingBottom: "4px" }}
                  text="Configurar frecuencia de repetición" 
                />
              </Box>
              <Stack mt={3} alignItems="center" direction="row" spacing={3}>
                <Box>
                  <Subtitle 
                    style={{fontSize: "18px"}}
                    text="Repetir cada:"
                  />
                </Box>
                <Select
                  value={weeksNumber}
                  onChange={(e) => setWeeksNumber(e.target.value)}
                  disabled = {active?false:true}
                  className={classes.select}
                  sx={{
                    width: "181px"
                  }}
                >
                  {
                    weeks.map((option) => (
                      <MenuItem key={option.value} value={option.value}>{option.value} {singularPlural(option.value, 'semana', 'semanas')}</MenuItem>
                    ))
                  }
                </Select>
              </Stack>
              <Box marginTop="32px">
                <Subtitle text="Comienza" />
              </Box>                
              <Stack alignItems="center" direction="row" spacing={3} marginTop="21px">
                <RadioGroup
                  name="radio-ahora"
                  value={optionAhora}
                  onChange={handleChange}                    
                >
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel disabled={viewMode === SHIFTS_VIEW_MODE.EDIT} value="ahora" control={<Radio sx={checkedStyle} />} 
                      label={<Subtitle 
                                style={{fontSize: "18px"}}
                                text="Ahora"
                            />} />
                    <FormControlLabel disabled={viewMode === SHIFTS_VIEW_MODE.EDIT} value="el" control={<Radio sx={checkedStyle} />} 
                      label={<Subtitle 
                                style={{fontSize: "18px"}}
                                text="El"
                            />} />
                  </Stack>
                </RadioGroup>
                <DatePicker
                  format='DD/MM/YYYY'
                  disabled={comienzaDisabled}
                  render={<OutlinedInput
                    disabled={comienzaDisabled}
                    endAdornment={<InputAdornment position="end"><CalendarIcon disabled={comienzaDisabled} /></InputAdornment>}
                    style={GetCalendarStyle(optionAhora !== "ahora")}
                  />}
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </Stack>
              <Box marginTop="32px">
                <Subtitle text="Termina" />
              </Box>                
              <Stack alignItems="center" direction="row" spacing={3} marginTop="21px">
                <RadioGroup
                  name="radio-nunca"
                  value={optionNunca}
                  onChange={handleChangeNunca}                    
                >
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel disabled={viewMode === SHIFTS_VIEW_MODE.EDIT} value="nunca" 
                      control={<Radio sx={checkedStyle} />} 
                      label={<Subtitle 
                            style={{fontSize: "18px"}}
                            text="Nunca"
                        />} />
                    <FormControlLabel disabled={viewMode === SHIFTS_VIEW_MODE.EDIT} value="el" control={<Radio sx={checkedStyle} />} 
                      label={<Subtitle 
                            style={{fontSize: "18px"}}
                            text="El"
                        />} />
                  </Stack>
                </RadioGroup>
                <DatePicker
                  format='DD/MM/YYYY'
                  disabled={terminaDisabled}
                  render={<OutlinedInput
                    disabled={terminaDisabled}
                    endAdornment={<InputAdornment position="end"><CalendarIcon disabled={terminaDisabled} /></InputAdornment>}
                    style={GetCalendarStyle(optionNunca !== "nunca")}
                  />}
                  value={valueNunca ? valueNunca : new Date()}
                  onChange={(newValue) => setValueNunca(newValue)}
                />
              </Stack>              
            </Box>              
          </>
      )} */}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
      isSpecial: state.v3.shifts.shiftData.isSpecial,
      calendar: state.v3.shifts.shiftData.calendar,
      viewMode: state.v3.shifts.viewMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      changeDays: (data) => dispatch(changeDays(data)),
      changeStartDay: (data) => dispatch(changeStartDay(data)),
      changeEndDay: (data) => dispatch(changeEndDay(data)),
      changeRepeat: (data) => dispatch(changeRepeat(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleStepSelectDays);