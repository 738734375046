export const ShiftTextFieldContainerStyles = {
    maxWidth: '42rem', 
    width: '80%',
    '@media(min-width: 1500px)': {
        width: '100%',
    }
};  

export const OutlinedShiftInputStyles = {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Poppins', 
    borderRadius: '10px',
    fontSize: '.9rem',
    maxWidth: '42rem', 
    color: '#041C32',
    fontWeight: 500,
    width: '100%',
    m: 0,
    '& .MuiInputBase-input': {
        p: '.7rem .875rem'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderWidth: '1px'
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    }, 
};

export const TFHelperTextStyles = {
    fontFamily: 'Poppins, sans-serif',
    justifyContent: 'flex-end',
    fontSize: '.75rem',
    textAlign: 'right',
    color: '#B9B9B9',
    fontWeight: 400,
    display: 'flex', 
    width: '100%',
    mr: '.4rem',
    mt: '.4rem'
};

export const ShiftsMainContainer = {
    fontFamily: 'Poppins, sans-serif',
    paddingRight: '5rem',
    maxWidth: '80rem',
    minWidth: '51rem',
    height: '85vh',
    width: '100%',
};

// Shift
export const DaysContainer = {
    alignItems: 'center',
    paddingLeft: '1rem',
    marginLeft: '-10px',
    marginRight: '17px',
    display: 'flex',
    height: '35px',
};

export const CardFont = {
    fontFamily: 'Poppins',
    alignItems: 'center',
    fontStyle: 'normal',
    lineHeight: '56px',
    fontSize: '13px',
    color: '#818181',
    fontWeight: 400,
    display: 'flex',
    height: '30px',
};

export const Card = {
    flexDirection: 'column',
    borderRadius: '8px',
    marginTop: '35px',
    display: 'flex',
    width: '100%'
};

export const IconStyle = {
    color: '#818181',
    fontSize: '13px',
    fontWeight: 400,
    height: '34px',
};

export const InfoContainer = {
    alignItems: 'center',
    display: 'flex',
    height: '56px',
};

export const UpdateTabStyle = {
    borderRadius: '8.35443px 8.35443px 0px 0px',
    padding: '.45rem .5rem .45rem .6rem',
    backgroundColor: '#0B4762',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
    marginTop: '-2.2rem',
    width: 'max-content',
    color: '#FCFCFC',
    display: 'flex',
    height: '46px',
    gap: '5px'
};

export const DeleteTabStyle = {
    borderRadius: '8.35443px 8.35443px 0px 0px',
    backgroundColor: '#FB3F4A',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FCFCFC',
    display: 'flex',
    width: '138px',
    height: '42px',
};

export const TabFontStyle = {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 400,
};

export const RepeatStyle = {
    fontFamily: 'Poppins',
    lineHeight: '56px',
    marginLeft: '15px',
    fontSize: '16px',
    fontWeight: 400,
};

export const SelectedDays = {
    fontFamily: 'Poppins',
    letterSpacing: '0px',
    lineHeight: '56px',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
};

export const HoursStyles = {
    fontFamily: 'Poppins',
    letterSpacing: '0px',
    fontStyle: 'italic',
    lineHeight: '56px',
    marginLeft: '11px',
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: 400,
};

export const IconText = {
    fontFamily: 'Poppins',
    alignItems: 'center',
    fontStyle: 'normal',
    marginLeft: '7px',
    fontSize: '14px',
    color: '#818181',
    fontWeight: 400,
};

export const TextWithoutIcon = {
    fontFamily: 'Poppins',
    alignItems: 'center',
    fontStyle: 'normal',
    textAlign: 'start',
    fontSize: '14px',
    color: '#818181',
    fontWeight: 400,
};

export const ShiftMainContainerStyles = {
    border: '1px solid #D1D1D1', 
    alignItems: 'center',
    position: 'relative',
    padding: '9px 17px',
    borderRadius: '8px', 
    display: 'block',
    bgcolor: '#FFF',
    width: '1350',
    pb: 3, 
};

export const TopShiftContainerStyles = {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    ml: '-.7rem',
};

export const LinearGradientBox = {
    background: 'linear-gradient( 180deg, #FCFCFC 0%, rgba(252, 252, 252, 0.3) 100% ) !important',
    top: 'calc(64px + 104px)',
    position: 'fixed',
    width: '3000px',
    height: '40px',
    left: '57px',
    zIndex: 1,
    '@media( min-width: 1361px )': {
        top: 'calc(64px + 59px)',
    }
};

export const TitleShiftStyles = {
    fontFamily: 'Poppins',
    marginBottom: '1rem',
    fontSize: '1.6rem',
    marginTop: '2rem',
    color: '#041C32',
    fontWeight: 500,
    '@media( min-width: 1361px )': {
        marginTop: 0
    }
};

export const SubtitleShiftStyles = {
    margin: '1rem 0 .5rem 0',
    fontFamily: 'Poppins',
    fontSize: '1.3rem',
    color: '#041C32',
    fontWeight: 500
};

export const DecriptionShiftStyles = {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    color: '#818181',
    fontWeight: 400
};

export const ShiftSelectStyles = {
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    fontWeight: 500,
    width: '15rem',
    m: '1rem 0',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '&.MuiInputLabel-root': {
        fontFamily: 'Poppins',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const ShiftSimpleSelectStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px',
    width: 'max-content',
    minWidth: '10rem',
    color: '#2A2742', 
    fontWeight: 500,
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const ShiftHoursSelectStyles = {
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    fontWeight: 500,
    width: '14rem',
    m: '1rem 0',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const ShiftSelectWithArrowsStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    width: '5rem',
    fontWeight: 500,
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const ShiftHoursSelectWithArrowsStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px',
    color: '#2A2742', 
    width: '10rem',
    fontWeight: 500,
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const SelectLabelStyles = {
    fontFamily: 'Poppins',
    color: '#818181', 
    fontWeight: 400,
    top: '.8rem',
    '&.Mui-focused': {
        color: '#041C32'
    } 
};

export const RadiosShiftTitleStyles = {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    color: '#041C32',
    fontWeight: 500,
    mb: '1rem'
};

export const RadioShiftTextStyles = {
    fontFamily: 'Poppins, sans-serif', 
    fontSize: '.95rem', 
    color: '#283149',
    fontWeight: 500,
    ml: '.4rem'
};

export const ShiftSelectTitles = {
    fontFamily: 'Poppins',
    fontSize: '1.2rem',
    color: '#041C32',
    fontWeight: 500
};

export const StartEndShiftContainerStyles = {
    flexDirection: 'row',  
    marginTop: '20px',
    display: 'flex',
    gap: '32px', 
};

export const ShiftCalendarTextFieldStyles = {
    width: '165px',
    '& .MuiInputBase-root': {
        fontFamily: 'Poppins !important',
        borderRadius: '.5rem',
        fontSize: '.9rem',
        fontWeight: 500,
        color: '#041C32'
    },
    '& .MuiInputBase-input': {
        padding: '10px 8px 10px 14px'
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        color: '#041C32',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #0B4762 !important',
    }
};

export const SubshiftHoursContainerStyles = { 
    maxHeight: '300px', 
    overflowY: 'auto',
    '::-webkit-scrollbar': {
        display: 'block !important',
        width: '8px',
    },
    '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        width: '10px'
    },
    '::-webkit-scrollbar-thumb': {
        background: '#0B4762',
        borderRadius: '5px',
    },
};

export const ChipHourContainerStyle = {
    marginBottom: '16px', 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    display: 'flex', 
    width: '100%',
    gap: '10px' 
};

export const DataBookingHourContainerStyles = {
    alignItems: 'center', 
    marginLeft: '5px',
    display: 'flex',
    gap: '4px' 
};

export const ChipHourStyles = {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    color: '#0B4762',
    fontWeight: 500,
};

export const SettingsTablesStyles = { 
    maxHeight: '260px', 
    overflowY: 'auto',
    '::-webkit-scrollbar': {
        display: 'block !important',
        width: '8px',
    },
    '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        width: '10px'
    },
    '::-webkit-scrollbar-thumb': {
        background: '#0B4762',
        borderRadius: '5px',
    },
};

export const SettingsTables2Styles = { 
    maxHeight: '330px', 
    overflowY: 'auto',
    '::-webkit-scrollbar': {
        display: 'block !important',
        width: '8px',
    },
    '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        width: '10px'
    },
    '::-webkit-scrollbar-thumb': {
        background: '#0B4762',
        borderRadius: '5px',
    },
};

export const ShiftTableSelectStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px',
    width: 'max-content',
    minWidth: '10rem',
    color: '#2A2742', 
    fontWeight: 500,
    '& .MuiSelect-select': {
        padding: '10px 15px 8px 10px'
    },
    '& .MuiOutlinedInput-input': {
        paddingRight: '50px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    },
    '@media (min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const ShubShiftStyles = { 
    marginBottom: '24px !important',
    backgroundColor: 'transparent', 
    border: "1px solid #D1D1D1",
    marginTop: "0 !important",
    fontFamily: "Poppins",
    borderRadius: '8px',
    padding: '1rem',
    width: '98%',
    '@media( min-width: 1350px )': {
        width: '68%'
    } 
};

export const ShiftTitleModalStyles = {
    fontFamily: 'Poppins',
    fontSize: "20px",
    color: "#041C32",
    fontWeight: 500,    
};

export const ShiftSubtitleModalStyles = {
    fontFamily: 'Poppins',
    fontSize: "18px",
    color: "#041C32",
    fontWeight: 500,
};

export const ShiftChipHourModalStyles = {
    lineHeight: "27px",
    fontSize: "18px",
    color: "#0B4762",
    fontWeight: 500,
};