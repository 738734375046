import React from 'react'

const WokiAssistantDisabled = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 165 165">
                <defs>
                    <linearGradient id="linear-gradient-wd" x1="0.518" y1="0.556" x2="0.5" y2="1"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#fff"/>
                        <stop offset="1" stopColor="#d0d0d0"/>
                    </linearGradient>
                    <filter id="Elipse_351" x="0" y="0" width="135" height="135" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood floodOpacity="0.251" result="color"/>
                        <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                        <feComposite operator="in" in="color"/>
                        <feComposite operator="in" in2="SourceGraphic"/>
                    </filter>
                </defs>
                <g style={{cursor: 'pointer'}} id="SOPORTE_DISABLED" transform="translate(15 15)">
                    <g id="SOPORTE_DISABLED" data-name="Grupo 1705">
                    <g transform="matrix(1, 0, 0, 1, -15, -15)" filter="url(#Elipse_353)">
                        <circle id="Elipse_353-3" data-name="Elipse 353" cx="57.5" cy="57.5" r="57.5" transform="translate(25 25)" fill="url(#linear-gradient-wd)"/>
                    </g>
                    </g>
                    <g id="Grupo_1521" data-name="Grupo 1521" transform="translate(27.747 28.159)">
                    <path id="Trazado_787" data-name="Trazado 787" d="M356.167,669.233a24.859,24.859,0,0,0-24.8-23.864H319.846a24.861,24.861,0,0,0-24.8,23.864,13.348,13.348,0,0,0-8.7,12.477v8.607a13.335,13.335,0,0,0,12.345,13.259,20.168,20.168,0,0,0,19.233,14.555h6.095a5.723,5.723,0,0,0,5.393,3.861h6.753a5.731,5.731,0,0,0,0-11.462h-6.753a5.719,5.719,0,0,0-5.39,3.861h-6.1c-8.863,0-16.35-7.642-16.35-16.692V670.219c0-8.492,8.449-15.4,18.839-15.4H330.8c10.387,0,18.839,6.911,18.839,15.4v31.589a1.869,1.869,0,0,0,1.869,1.869,13.374,13.374,0,0,0,13.36-13.36V681.71A13.346,13.346,0,0,0,356.167,669.233Zm-26.754,45.038h6.753a1.992,1.992,0,1,1,0,3.984h-6.753a1.992,1.992,0,1,1,0-3.984Zm-31.575-14.512a9.643,9.643,0,0,1-7.756-9.441V681.71a9.64,9.64,0,0,1,7.756-9.441v27.49ZM330.8,651.076H320.415c-7.883,0-14.826,3.453-18.865,8.656a21.107,21.107,0,0,1,18.3-10.623h11.523a21.115,21.115,0,0,1,18.3,10.623C345.628,654.525,338.685,651.076,330.8,651.076Zm30.33,39.242a9.64,9.64,0,0,1-7.754,9.441v-27.49a9.638,9.638,0,0,1,7.754,9.441Z" transform="translate(-286.348 -645.369)" fill="#848282"/>
                    <path id="Trazado_788" data-name="Trazado 788" d="M308.141,653.7c-2.386-.247-4.81,4.108-5.387,9.743s.857,10.41,3.246,10.648,4.784-4.123,5.385-9.755h0C311.974,658.711,310.528,653.953,308.141,653.7Z" transform="translate(-255.83 -629.763)" fill="#848282"/>
                    <path id="Trazado_789" data-name="Trazado 789" d="M303.059,663.441c-.607-5.632-3-10-5.388-9.743s-3.841,5.022-3.249,10.651,3,9.99,5.39,9.74S303.666,669.073,303.059,663.441Z" transform="translate(-271.456 -629.773)" fill="#848282"/>
                    <path id="Trazado_790" data-name="Trazado 790" d="M307.8,673.367c9.651,0,11.994-10.252,11.994-10.252H295.846S298.146,673.367,307.8,673.367Z" transform="translate(-268.54 -612.097)" fill="#848282"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default WokiAssistantDisabled
