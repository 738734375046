import {
    ADD_NEW_IMAGE,
    CLOSE_ERROR_DIALOG,
    EDIT_PHOTOS_ERROR,
    EDIT_PHOTOS_SUCCESS,
    PARTNERS,
    REMOVE_IMAGE,
    REPLACE_FACADE_IMAGE,
    RESET_ALL_DATA,
    TOGGLE_PHOTOS_LOADING,
} from '../../constants';

const initState = {
    images: [],
    oldImagesToRemove: [],

    edited: false,
    loading: true,
    error: '',
}

const editReducer = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === PARTNERS &&
                action.payload.ordered &&
                action.payload.ordered.length > 0
            ) {
                console.log( action.payload );
                return {
                    ...state,
                    images: [...action.payload.ordered[0].facadeImagesUrl],
                    loading: false,
                    edited: false,
                };
            }
            return { ...state, loading: false, edited: false };

        case TOGGLE_PHOTOS_LOADING:
            return {...state, loading: !state.loading};
        case EDIT_PHOTOS_ERROR:
            return {...state, loading: false, error: action.payload};
        case EDIT_PHOTOS_SUCCESS:
            return {...state, loading: false, error: '', images: action.payload, oldImagesToRemove: [], edited: false};

        case REPLACE_FACADE_IMAGE:
            return {
                ...state,
                images: [action.payload, ...state.images.slice(1)],
                oldImagesToRemove: [...state.oldImagesToRemove, state.images[0]],
                edited: true,
            }

        case ADD_NEW_IMAGE:
            const imagesWithNewAdded = [...state.images];
            if (imagesWithNewAdded[0] === null) {
                imagesWithNewAdded[0] = action.payload;
            }
            else {
                imagesWithNewAdded.push(action.payload);
            }
            return {
                ...state,
                images: imagesWithNewAdded,
                edited: true,
            };

        case REMOVE_IMAGE:
            const newImages = [...state.images];
            let removedImage;
            if (action.payload === 0) {
                newImages[0] = null;
            } else {
                removedImage = newImages.splice(action.payload, 1);
            }
            return {
                ...state,
                images: [...newImages],
                oldImagesToRemove: removedImage === undefined ? [...state.oldImagesToRemove] : [...state.oldImagesToRemove, removedImage[0]],
                edited: true,
            };

        case CLOSE_ERROR_DIALOG:
            return {...state, error: ''};

        case RESET_ALL_DATA:
            return {...initState, loading: false};
        default:
            return state;
    }
}

export default editReducer;
