export const EstablishmentIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.31033 24.5861H21.6896C22.5675 24.5861 23.4095 24.2374 24.0304 23.6166C24.6512 22.9958 24.9999 22.1538 24.9999 21.2758V6.2684C24.9999 5.79621 24.8989 5.3295 24.7037 4.89957L23.8132 2.93986C23.5502 2.36153 23.1263 1.87112 22.5921 1.52714C22.0579 1.18317 21.4361 1.00018 20.8008 1H5.19916C4.56383 1.00018 3.94198 1.18317 3.40782 1.52714C2.87367 1.87112 2.44978 2.36153 2.18676 2.93986L1.29793 4.89957C1.10211 5.32937 1.00052 5.79609 1 6.2684V21.2758C1 22.1538 1.34877 22.9958 1.96957 23.6166C2.59038 24.2374 3.43238 24.5861 4.31033 24.5861Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.99976 5.5C8.99976 7.55737 7.34459 9.63792 4.86184 9.63792C2.37909 9.63792 1.13771 7.55737 1.13771 5.5" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.9998 6C24.9998 8.05737 23.7584 9.68968 21.2756 9.68968C18.7929 9.68968 17.1377 7.60913 17.1377 5.55176" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1381 5.55176C17.1381 7.60913 15.483 9.68968 13.0002 9.68968C10.5175 9.68968 8.8623 7.60913 8.8623 5.55176" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.27539 18.7932C9.27539 17.9153 9.62416 17.0733 10.245 16.4525C10.8658 15.8317 11.7078 15.4829 12.5857 15.4829H13.4133C14.2913 15.4829 15.1333 15.8317 15.7541 16.4525C16.3749 17.0733 16.7236 17.9153 16.7236 18.7932V24.5863H9.27539V18.7932Z" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
