import React from 'react';

const CurrentlySectorIcon = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 41.973 20">
                <g id="Capa_1" data-name="Capa 1" transform="translate(0 20) rotate(-90)">
                    <path id="Trazado_591" data-name="Trazado 591" d="M9.48,20.574a9.975,9.975,0,0,0,9.656-6.095A10.518,10.518,0,0,0,17.071,3.015,9.79,9.79,0,0,0,5.934.89,10.328,10.328,0,0,0,.014,10.83,10.151,10.151,0,0,0,9.48,20.574Z" transform="translate(0 10.569)" fill="#0076ff"/>
                    <path id="Trazado_592" data-name="Trazado 592" d="M2.283.28A.542.542,0,0,1,3.021.069a13.409,13.409,0,0,0,12.823,0,.542.542,0,0,1,.738.211L18.79,4.208a.584.584,0,0,1,.055.438.567.567,0,0,1-.265.347,18.778,18.778,0,0,1-18.3,0A.567.567,0,0,1,.02,4.645a.584.584,0,0,1,.055-.438Z" transform="translate(0.567 34.602)" fill="#0076ff"/>
                    <path id="Trazado_593" data-name="Trazado 593" d="M9.433,0a18.836,18.836,0,0,1,9.145,2.377.567.567,0,0,1,.267.347.584.584,0,0,1-.055.439L16.583,7.091a.542.542,0,0,1-.738.211,13.409,13.409,0,0,0-12.823,0,.542.542,0,0,1-.738-.211L.075,3.163A.584.584,0,0,1,.02,2.726a.567.567,0,0,1,.265-.347A18.839,18.839,0,0,1,9.433,0Z" transform="translate(0.567)" fill="#0076ff"/>
                </g>
            </svg>
        </div>
    )
}

export default CurrentlySectorIcon;
