import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { setAvailableLayouts, setReservableSectors, setSubshift } from "../../../../../actions/shiftsActions";

// Components
import { BlueSwitch } from "../../../../GeneralComponents/blueSwitch";

// Material Ui
import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

// Utils
import { GetLayoutWaitList, GetReservableSectors, SetWaitListToLayouts, SetWaitListToSubShiftBookableSectors } from "../utils/waitListFunctions";

// Styles
import { ShiftSelectTitles } from "../../../Styles";

const useGapStyles = makeStyles({
    sectorBox: {
        border: "1px solid #D1D1D1",
        backgroundColor: "#FFF",
        borderRadius: "10px",
        alignItems: "center",
        padding: ".5rem",
        display: "flex",
        width: "380px",
    }
});

const WaitListSectors = (props) => {
  const classes = useGapStyles();

  // Variables 
  const {
    reservableSectors,
    availableLayouts,
    subShifts,
    sectors,
  } = props;

  // Acciones
  const {
    setReservableSectors,
    setAvailableLayouts,
    setSubshift,
  } = props;

  const [sectores, setSectores] = useState([]);
  const newReservableSectors = GetReservableSectors(reservableSectors, availableLayouts, subShifts, sectors, setReservableSectors);  

  const handleClick = (sector, value) => {
    SetWaitListToLayouts(availableLayouts, !value, setAvailableLayouts, sector.id);
    SetWaitListToSubShiftBookableSectors(subShifts, !value, setSubshift, sector.id, 0);
    setSectores(GetLayoutWaitList(availableLayouts, newReservableSectors));
  };

  useEffect(() => {
    setSectores(GetLayoutWaitList(availableLayouts, newReservableSectors));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(availableLayouts)]);

  return (
    <>
      <Typography sx={ ShiftSelectTitles } >Sectores con lista de espera</Typography>
      <Stack display='flex' flexDirection="row" flexWrap={'wrap'} style={{ gap: "29px", marginTop: "32px" }}>
        {sectores.map((sector) => (
            <Box
              className={ classes.sectorBox }
              key={ sector.id }
            >
              <BlueSwitch
                onClick={() => handleClick(sector, sector.active)}
                checked={sector.active}
                color="default"
              />
              <Box>
                <Typography 
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '1.1rem',
                    color: '#041C32',
                    fontWeight: 500,
                  }}
                >
                  { sector.name }
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'italic',
                    fontSize: '.85rem',
                    color: '#818181',
                  }}
                >
                  { sector.type }
                </Typography>
              </Box>
            </Box>
        ))}
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      reservableSectors: state.v3.shifts.reservableSectors,
      availableLayouts: state.v3.shifts.shiftData.availableLayouts,
      subShifts: state.v3.shifts.shiftData.subShifts,
      sectors: state.v3.shifts.sectors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAvailableLayouts: (data) => dispatch(setAvailableLayouts(data)),
    setSubshift: (data) => dispatch(setSubshift(data)),
    setReservableSectors: (data) => dispatch(setReservableSectors(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListSectors);
