import React from 'react';

// Components
import { BlueSwitch } from '../../../GeneralComponents/blueSwitch';

// Helpers
import { ChangeMinutesToHHmm } from '../../helpers';

// Icons
import DeleteOptionalIcon from '../../../SvgComponents/icons/Optional/DeleteOptionalIcon';
import EditOptionalIcon from '../../../SvgComponents/icons/Optional/EditOptionalIcon';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';

// Styles
import { PlanningDataContainerStyles, PlanningHours2Styles, PlanningNameStyles } from './Styles';

const PlanningTopView = ({ 
    handleDisabledPlanning, 
    planningRangeHours,
    handleDelete,
    planningName,  
    planningGrey,
    handleEdit,
    enabled, 
    type,
}) => {
    return (
        <Box sx={ PlanningDataContainerStyles }>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                <Box sx={{ width: 'max-content', display: 'flex', alignItems: 'center', float: 'left' }}>
                    <Box sx={{ mt: '-.7rem' }}>
                        <BlueSwitch checked={ enabled } onChange={ handleDisabledPlanning } />
                    </Box>
                    <Typography sx={ PlanningNameStyles }>{ planningName }</Typography>
                </Box>
                ({
                    planningRangeHours?.map( ( range, index ) => 
                        <Typography sx={ PlanningHours2Styles } key={ index }> 
                            { ChangeMinutesToHHmm( range.start ) } -&nbsp; 
                            { ChangeMinutesToHHmm( range.end ) } 
                            { planningRangeHours.length === 1 ? null : planningRangeHours.length === ( index + 1 ) ? null : ' |' } 
                        </Typography>
                    )
                })
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box 
                    sx={{ 
                        border: `1px solid ${ planningGrey ? '#B9B9B9' : type === 'openning' ? '#35C18F' : type === 'specialDates' ? '#FCBF49' : '#FB3F4A' }`,
                        borderRadius: '0.6rem',
                        padding: '.3rem .5rem',
                        width: 'max-content', 
                        alignItems: 'center',
                        display: 'flex',
                        gap: '4px', 
                    }} 
                >
                    { 
                            type === 'openning' ? 
                                <AccessTimeIcon sx={{ color: planningGrey ? '#B9B9B9' : '#35C18F', fontSize: '1rem' }} /> 
                            : type === 'closing' ? 
                                <CalendarTodayOutlinedIcon sx={{ color: planningGrey ? '#B9B9B9' : '#FB3F4A', fontSize: '1rem' }} />
                            : 
                                <StarOutlineIcon sx={{ color: planningGrey ? '#B9B9B9' : '#FCBF49', fontSize: '1rem' }} /> 
                        }
                    <Typography 
                        sx={{ 
                            color: planningGrey ? '#B9B9B9' : type === 'openning' ? '#35C18F' : type === 'specialDates' ? '#FCBF49' : '#FB3F4A',
                            textTransform: 'uppercase',
                            fontFamily: 'Poppins',
                            fontSize: '0.7rem',
                            fontWeight: 700,
                            mb: '-.1rem'
                        }} 
                    >
                        { 
                            type === 'openning' ? 
                                'HORARIO DE APERTURA' 
                            : type === 'closing' ? 
                                'CIERRE PROGRAMADO' 
                            : 
                                'FECHAS ESPECIALES'
                        }
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <IconButton onClick={ handleEdit }>
                        <EditOptionalIcon />
                    </IconButton>
                    <IconButton onClick={ handleDelete }>
                        <DeleteOptionalIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default PlanningTopView;