export const MainPoliticsContainerStyles = {
    minWidth: '50rem',
    marginBottom: '2rem',
    '@media(min-width: 1500px)': {
        maxWidth: '51rem',
    }
};

export const DegradeComponentStyles = {
    background: 'linear-gradient(180deg, #FCFCFC 35%, rgba(252, 252, 252, 0.6) 50%, rgba(252, 252, 252, 0.6) 70%, rgba(252, 252, 252, 0.00) 100%)',
    position: 'fixed', 
    height: '5rem', 
    top: '4rem', 
    width:'91%',
    zIndex: 10, 
}

export const BookingPoliticsTitleStyles = {
    fontFamily: 'Poppins',
    fontSize: '1.3rem',
    color: '#041C32',
    margin: '1rem 0',
    fontWeight: 500,
};

export const BookingPoliticsSubtitleStyles = {
    fontFamily: 'Poppins',
    fontSize: '1.1rem',
    color: '#041C32',
    margin: '1rem 0',
    fontWeight: 500,
};

export const BookingPoliticsDescriptionStyles = {
    fontFamily: 'Poppins',
    fontSize: '.95rem',
    color: '#818181',
    margin: '0 0 1rem 0',
    fontWeight: 400,
};

export const BPOutlinedTextAreaStyles = {
    backgroundColor: '#FFFFFF',
    color: '#041C32',
    fontWeight: 500,
    width: '100%',
    m: '1rem 0',
    '& .MuiInputBase-root': {
        borderRadius: '10px',
        fontSize: '.9rem', 
        padding: 0
    },
    '& .MuiInputBase-input': {
        fontFamily: 'Poppins', 
        borderRadius: '10px !important',
        p: '.7rem .875rem'
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#0B4762',
            borderWidth: '1px'
        }
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FB3F4A',
    } 
};