export const LOAD_PLANNING_DATA_SUCCESS = 'LOAD_PLANNING_DATA_SUCCESS';
export const LOAD_PLANNING_DATA_FAIL = 'LOAD_PLANNING_DATA_FAIL';
export const LOAD_PLANNING_DATA = 'LOAD_PLANNING_DATA';

export const SAVING_PLANNING_DATA_SUCCESS = 'SAVING_PLANNING_DATA_SUCCESS';
export const SAVING_PLANNING_DATA_FAIL = 'SAVING_PLANNING_DATA_FAIL';
export const SAVING_PLANNING_DATA = 'SAVING_PLANNING_DATA';


export const EDIT_OPEN_INTERVALS_CANCEL = "EDIT_OPEN_INTERVALS_CANCEL";
export const REMOVE_OPEN_INTERVAL = "REMOVE_OPEN_INTERVAL";
export const ADD_OPEN_INTERVAL = "ADD_OPEN_INTERVAL";

export const EDIT_OPEN_HOUR_INTERVAL_COMPLETE = "EDIT_OPEN_HOUR_INTERVAL_COMPLETE";
export const EDIT_OPEN_HOUR_INTERVAL_ENABLE = "EDIT_OPEN_HOUR_INTERVAL_ENABLE";
export const EDIT_OPEN_HOUR_INTERVAL = "EDIT_OPEN_HOUR_INTERVAL";

export const EDIT_HAPPY_INTERVALS_CANCEL = "EDIT_HAPPY_INTERVALS_CANCEL";
export const REMOVE_HAPPY_INTERVAL = "REMOVE_HAPPY_INTERVAL";
export const ADD_HAPPY_INTERVAL = "ADD_HAPPY_INTERVAL";


export const EDIT_HAPPY_INTERVAL_COMPLETE = "EDIT_HAPPY_INTERVAL_COMPLETE";
export const EDIT_HAPPY_INTERVAL_ENABLE = "EDIT_HAPPY_INTERVAL_ENABLE";
export const EDIT_HAPPY_INTERVAL = "EDIT_HAPPY_INTERVAL";

export const EDIT_HAPPY_HOUR_SUCCESSFUL = "EDIT_HAPPY_HOUR_SUCCESSFUL";

export const SAVING_DELETE_PLANNING_DATA_SUCCESS = 'SAVING_DELETE_PLANNING_DATA_SUCCESS';
export const PLANNING_REMOVE_ALL_EXCLUDE_DATES = 'PLANNING_REMOVE_ALL_EXCLUDE_DATES';
export const SAVING_EDIT_PLANNING_DATA_SUCCESS = 'SAVING_EDIT_PLANNING_DATA_SUCCESS';
export const PLANNING_SET_SPECIAL_DATES_FORM = 'PLANNING_SET_SPECIAL_DATES_FORM';
export const SAVING_EDIT_PLANNING_DATA_FAIL = 'SAVING_EDIT_PLANNING_DATA_FAIL';
export const PLANNING_ADD_ALL_EXCLUDE_DATES = 'PLANNING_ADD_ALL_EXCLUDE_DATES';
export const PLANNING_CHANGE_WEEk_REPEAT = 'PLANNING_CHANGE_WEEk_REPEAT';
export const PLANNING_DELETE_SINGLE_DATE = 'PLANNING_DELETE_SINGLE_DATE';
export const PLANNING_SET_CLOSING_FORM = 'PLANNING_SET_CLOSING_FORM';
export const PLANNING_CHANGE_FROM_HOUR = 'PLANNING_CHANGE_FROM_HOUR';
export const PLANNING_REMOVE_ALL_DATES = 'PLANNING_REMOVE_ALL_DATES';
export const PLANNING_SET_RANGE_HOURS = 'PLANNING_SET_RANGE_HOURS';
export const PLANNING_ADD_SINGLE_DATE = 'PLANNING_ADD_SINGLE_DATE';
export const PLANNING_CHANGE_TO_HOUR = 'PLANNING_CHANGE_TO_HOUR';
export const PLANNINGS_SHOW_SNACKBAR = 'PLANNINGS_SHOW_SNACKBAR';
export const PLANNING_RESTART_DATES = 'PLANNING_RESTART_DATES';
export const PLANNING_SELECTED_TYPE = 'PLANNING_SELECTED_TYPE';
export const PLANNING_ADD_ALL_DATES = 'PLANNING_ADD_ALL_DATES';
export const PLANNING_ADD_NEW_DATE = 'PLANNING_ADD_NEW_DATE';
export const PLANNING_EXCLUDE_DATE = 'PLANNING_EXCLUDE_DATE';
export const PLANNING_REMOVE_DATES = 'PLANNING_REMOVE_DATES';
export const PLANNING_CHANGE_DATE = 'PLANNING_CHANGE_DATE';
export const CHANGE_PLANNING_MODE = 'CHANGE_PLANNING_MODE';
export const PLANNING_SHOW_ERRORS = 'PLANNING_SHOW_ERRORS';
export const RESET_PLANNING_DATA = 'RESET_PLANNING_DATA';
export const PLANNING_VIEW_MODE = 'PLANNING_VIEW_MODE';
export const PLANNING_SET_NAME = 'PLANNING_SET_NAME';
export const PLANNING_ID_EDIT = 'PLANNING_ID_EDIT';
export const FETCH_PLANNINGS = 'FETCH_PLANNINGS';
export const REMOVE_PLANNING = 'REMOVE_PLANNING';
export const EDIT_PLANNING = 'EDIT_PLANNING';

export const PLANNING_MODE = {
    DELETE: 'DELETE',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    NEW: 'NEW',
};
