import React from 'react';
import {Grid} from '@material-ui/core';
import DeleteIcon from '../../SvgComponents/icons/delete';

const Eliminado = () => {
    return (
        <div style={{width: '90px'}}>
            <Grid container>
                <Grid item xs={2} style={{paddingTop: '3px'}}>
                    <DeleteIcon width={'15px'} height={'15px'}/>
                </Grid>
                <Grid item xs={10} style={{paddingTop: '3px', paddingLeft: '12px'}}>
                    <p
                        style={{
                            margin: '0px',
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            color: '#EB3030'
                        }}
                    >Eliminado</p>
                </Grid>
            </Grid>
        </div>
    )
}

export default Eliminado
