import {
    CHANGE_PRIMARY_TITLE_APP_BAR,
    CHANGE_TITLE_APP_BAR,
    SUCCESS_MAKE_ACTIVE,
    CHANGE_SHOW_SIDEBAR,
    CLOSE_ERROR_DIALOG,
    ERROR_MAKE_ACTIVE,
    LOADING_SIDE_BAR,
    RESET_ALL_DATA,
    TOGGLE_DRAWER,
    PARTNERS,
} from "../../constants/index";


const initState = {
    drawerOpen: false,
    title: 'Inicio',
    primaryTitle: '',
    showSidebar: false,
    openFromButton: false,
    isActive: true,
    bannerHeight: "80px",
    error: '',
    loading: false,
}

const sidebarManager = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (action.meta && action.meta.collection && action.meta.collection === PARTNERS && action.payload.ordered) {
                let data = action.payload.ordered[0];
                return {
                    ...state,
                    isActive: data?.isActive ?? false,
                }
            }
            return {...state}
        case CHANGE_SHOW_SIDEBAR:
            return {...state, showSidebar: action.payload}
        case TOGGLE_DRAWER:
            return {...state, drawerOpen: !state.drawerOpen, openFromButton: action.payload}
        case CHANGE_TITLE_APP_BAR:
            return {...state, title: action.payload}
        case CHANGE_PRIMARY_TITLE_APP_BAR:
            return {...state, primaryTitle: action.payload}
        case LOADING_SIDE_BAR:
            return {...state, loading: true}
        case SUCCESS_MAKE_ACTIVE:
            return {...state, loading: false, isActive: true}
        case ERROR_MAKE_ACTIVE:
            return {...state, loading: false, isActive: false, error: action.payload}
        case CLOSE_ERROR_DIALOG:
            return {...state, error: ''}
        case RESET_ALL_DATA:
            return initState;
        default:
            return state
    }
}

export default sidebarManager
