import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';
import { 
    setArrivalToleranceMinutes, 
    setPenaltyToleranceMinutes, 
    setDefaultMessage1Message, 
    setDefaultMessage2Message, 
    patchBookingsPolitics, 
    setBeforeGoingMessage, 
    setCominucationWithMR, 
    setArrivalTolerance, 
    getBookingsPolitics,
    setDefaultMessage1, 
    setDefaultMessage2, 
    setBeforeGoing, 
    setFixedShift,
} from '../../actions/politicsActions';

// Components
import BookingPoliticsTextArea from '../../components/Politics/components/BookingPoliticsTextArea';
import AttentionHoursComponent from '../../components/Politics/components/AttentionHoursComponent';
import ProgressIndicator from '../../components/GeneralComponents/ProgressIndicator';
import SelectWithArrows from '../../components/Plannig/components/SelectWithArrows';
import { BlueSwitch } from '../../components/GeneralComponents/blueSwitch';

// Material Ui
import { Box, Button, Collapse, Typography } from '@mui/material';

// Styles
import { 
    BookingPoliticsDescriptionStyles,
    BookingPoliticsSubtitleStyles, 
    MainPoliticsContainerStyles,
    BookingPoliticsTitleStyles,
    DegradeComponentStyles, 
} from '../../components/Politics/Styles';
import { SaveOptionalButtonStyles } from '../../components/Optional/Styles/OptionalStyles';

const BookingPoliticsPage = ( props ) => {

    // Actions
    const { 
        setArrivalToleranceMinutes,
        setPenaltyToleranceMinutes,
        setDefaultMessage1Message,
        setDefaultMessage2Message,
        patchBookingsPolitics,
        setBeforeGoingMessage,
        setCominucationWithMR,
        setArrivalTolerance,
        getBookingsPolitics,
        setDefaultMessage1,
        setDefaultMessage2,
        changeTitleAppBar,
        changeShowSidebar,
        setBeforeGoing,
        setFixedShift,
    } = props;

    // States
    const { 
        arrivalToleranceMinutes,
        penaltyToleranceMinutes,
        defaultMessage1Message,
        defaultMessage2Message,
        beforeGoingMessage,
        cominucationWithMR,
        idPartnerSelected,
        arrivalTolerance,
        defaultMessage1,
        defaultMessage2,
        beforeGoing,
        fixedShifts,
        editing,
        loading,
    } = props;
    
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = ( event ) => {
            setScrollTop( window.scrollY );
        };

        window.addEventListener( 'scroll', handleScroll );

        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, []);

    useEffect(() => {
        changeTitleAppBar("Políticas");
        changeShowSidebar(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ( idPartnerSelected ) {
            getBookingsPolitics();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ idPartnerSelected ]);
    
    const handleIncreaseMinutes = ( type ) => {
        if ( type === 'arrive' ) {
            if ( arrivalToleranceMinutes < 100 ) {
                setArrivalToleranceMinutes({ arrivalToleranceMinutes: arrivalToleranceMinutes + 5 });
            }
        } else {
            if ( penaltyToleranceMinutes < 100 ) {
                setPenaltyToleranceMinutes({ penaltyToleranceMinutes: penaltyToleranceMinutes + 1 });
            }
        }
    };

    const handleDecreaseMinutes = ( type ) => {
        if ( type === 'arrive' ) {
            if ( arrivalToleranceMinutes > 0 ) {
                setArrivalToleranceMinutes({ arrivalToleranceMinutes: arrivalToleranceMinutes - 5 });
            }
        } else {
            if ( penaltyToleranceMinutes > 0 ) {
                setPenaltyToleranceMinutes({ penaltyToleranceMinutes: penaltyToleranceMinutes - 1 });
            }
        }
    };

    const handleSaveChanges = () => {
        patchBookingsPolitics();
    };

    return (
        <Box sx={ MainPoliticsContainerStyles }>
            { scrollTop > 50 && 
                <Collapse orientation='vertical' in={ scrollTop > 50 }>
                    <Box sx={ DegradeComponentStyles } ></Box>
                </Collapse> 
            }
            <Typography variant='h4' sx={ BookingPoliticsTitleStyles }>Políticas de reserva</Typography>
            <Box>
                <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                    Horario de atención a la toma de reservas
                </Typography>
                {/* <Typography variant='body1' sx={ BookingPoliticsDescriptionStyles }>
                    Lorem ipsum dolor sit amet consectetur. Dis vitae at vulputate pharetra feugiat urna eget cras sit.
                </Typography> */}
                <AttentionHoursComponent />
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch 
                                onChange={ () => setFixedShift({ fixedShifts: !fixedShifts }) }
                                checked={ fixedShifts } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Turnos fijos
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='body1' sx={ BookingPoliticsDescriptionStyles }>
                            Habilitá este campo si queres que se le comunique al cliente que su reserva tiene una duración determinada igual al tiempo de permanencia para el tamaño de su reserva.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch 
                                onChange={ () => setArrivalTolerance({ arrivalTolerance: !arrivalTolerance }) }
                                checked={ arrivalTolerance } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Tolerancia al arribo
                        </Typography>&nbsp;
                        <SelectWithArrows
                            handleDecrease={ () => handleDecreaseMinutes( 'arrive' ) }
                            handleIncrease={ () => handleIncreaseMinutes( 'arrive' ) }
                            amount={ arrivalToleranceMinutes }
                            disabled={ !arrivalTolerance }
                            singularText='MIN'
                            pluralText='MIN'
                        />
                    </Box>
                    <Box>
                        <Typography variant='body1' sx={ BookingPoliticsDescriptionStyles }>
                            Habilitá este campo si queres comunicarle a tus clientes una tolerancia al arribo. En caso de que se supere este tiempo de gracia, no se le guardará la mesa.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Tolerancia a la penalización
                        </Typography>&nbsp;
                        <SelectWithArrows
                            handleDecrease={ () => handleDecreaseMinutes( 'penalization' ) }
                            handleIncrease={ () => handleIncreaseMinutes( 'penalization' ) }
                            amount={ penaltyToleranceMinutes }
                            disabled={ false }
                            singularText='MIN'
                            pluralText='MIN'
                        />
                    </Box>
                    {/* <Box>
                        <Typography variant='body1' sx={ BookingPoliticsDescriptionStyles }>
                            Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                    </Box> */}
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch
                                onChange={ () => setBeforeGoing({ beforeGoing: !beforeGoing })}
                                checked={ beforeGoing } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            ¿Qué saber antes de ir?
                        </Typography>
                    </Box>
                    <Box>
                        <BookingPoliticsTextArea
                            setValue={ (e) => setBeforeGoingMessage({ beforeGoingMessage: e }) }
                            value={ beforeGoingMessage }
                            disabled={ !beforeGoing }
                            placeholder='Mensaje'
                        />
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch
                                onChange={ () => setCominucationWithMR({ cominucationWithMR: !cominucationWithMR }) }
                                checked={ cominucationWithMR } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Comunicación con reservas manuales
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='body1' sx={ BookingPoliticsDescriptionStyles }>
                            En caso de habilitar esta funcionalidad todas las reservas manuales que contengan mail se les enviará el estado de su reserva.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch
                                onChange={ () => setDefaultMessage1({ defaultMessage1: !defaultMessage1 }) }
                                checked={ defaultMessage1 } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Mensaje predeterminado para tamaño de grupo no reservable
                        </Typography>
                    </Box>
                    <Box>
                        <BookingPoliticsTextArea
                            setValue={ ( e ) => setDefaultMessage1Message({ 
                                defaultMessage1Message: e 
                            })}
                            value={ defaultMessage1Message }
                            disabled={ !defaultMessage1 }
                            placeholder='Mensaje'
                        />
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box sx={{ mt: '-.3rem', ml: '-.6rem' }}>
                            <BlueSwitch
                                onChange={ () => setDefaultMessage2({ defaultMessage2: !defaultMessage2 }) }
                                checked={ defaultMessage2 } 
                            />
                        </Box>
                        <Typography variant='h6' sx={ BookingPoliticsSubtitleStyles }>
                            Mensaje predeterminado para horarios sin turnos reservables
                        </Typography>
                    </Box>
                    <Box>
                        <BookingPoliticsTextArea
                            setValue={ ( e ) => setDefaultMessage2Message({ 
                                defaultMessage2Message: e 
                            })}
                            value={ defaultMessage2Message }
                            disabled={ !defaultMessage2 }
                            placeholder='Mensaje'
                        />
                    </Box>
                </Box>
            </Box>
            <Box 
                sx={{
                    display: editing ? 'block' : 'none',
                    transform: 'translateY(-50%)',
                    position: 'fixed',
                    bottom: '5%',
                    left: '50%',
                }}
            >
                <Button
                    sx={ SaveOptionalButtonStyles } 
                    onClick={ handleSaveChanges } 
                >
                    Guardar cambios
                </Button>
            </Box>
            <ProgressIndicator open={ loading }/>
        </Box>
    )
};

const mapStateToProps = state => {
    return {
        arrivalToleranceMinutes: state.bookingPoliticsReducer.arrivalToleranceMinutes,
        penaltyToleranceMinutes: state.bookingPoliticsReducer.penaltyToleranceMinutes,
        defaultMessage1Message: state.bookingPoliticsReducer.defaultMessage1Message,
        defaultMessage2Message: state.bookingPoliticsReducer.defaultMessage2Message,
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        beforeGoingMessage: state.bookingPoliticsReducer.beforeGoingMessage,
        cominucationWithMR: state.bookingPoliticsReducer.cominucationWithMR,
        arrivalTolerance: state.bookingPoliticsReducer.arrivalTolerance,
        defaultMessage1: state.bookingPoliticsReducer.defaultMessage1,
        defaultMessage2: state.bookingPoliticsReducer.defaultMessage2,
        beforeGoing: state.bookingPoliticsReducer.beforeGoing,
        fixedShifts: state.bookingPoliticsReducer.fixedShifts,
        editing: state.bookingPoliticsReducer.editing,
        loading: state.bookingPoliticsReducer.loading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setArrivalToleranceMinutes: ( arrivalToleranceMinutes ) => dispatch( setArrivalToleranceMinutes( arrivalToleranceMinutes ) ),
        setPenaltyToleranceMinutes: ( penaltyToleranceMinutes ) => dispatch( setPenaltyToleranceMinutes( penaltyToleranceMinutes ) ),
        setDefaultMessage1Message: ( defaultMessage1Message ) => dispatch( setDefaultMessage1Message( defaultMessage1Message ) ),
        setDefaultMessage2Message: ( defaultMessage2Message ) => dispatch( setDefaultMessage2Message( defaultMessage2Message ) ),
        setBeforeGoingMessage: ( beforeGoingMessage ) => dispatch( setBeforeGoingMessage( beforeGoingMessage ) ),
        setCominucationWithMR: ( cominucationWithMR ) => dispatch( setCominucationWithMR( cominucationWithMR ) ),
        setArrivalTolerance: ( arrivalTolerance ) => dispatch( setArrivalTolerance( arrivalTolerance ) ),
        setDefaultMessage1: ( defaultMessage1 ) => dispatch( setDefaultMessage1( defaultMessage1 ) ),
        setDefaultMessage2: ( defaultMessage2 ) => dispatch( setDefaultMessage2( defaultMessage2 ) ),
        setBeforeGoing: ( beforeGoing ) => dispatch( setBeforeGoing( beforeGoing ) ),
        setFixedShift: ( fixedShifts ) => dispatch( setFixedShift( fixedShifts ) ),
        changeTitleAppBar: ( data ) => dispatch( changeTitleAppBar( data ) ),
        changeShowSidebar: ( data ) => dispatch( changeShowSidebar( data ) ),
        patchBookingsPolitics: () => dispatch( patchBookingsPolitics() ),
        getBookingsPolitics: () => dispatch( getBookingsPolitics() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BookingPoliticsPage );