import React from 'react';

const BookingSpecialIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.469 9.41667L15.4274 8.97917L13.4586 4.34375C13.1045 3.5 11.8961 3.5 11.542 4.34375L9.5732 8.98958L4.54196 9.41667C3.62529 9.48958 3.25029 10.6354 3.94821 11.2396L7.77112 14.5521L6.62529 19.4688C6.41696 20.3646 7.38571 21.0729 8.17737 20.5938L12.5003 17.9896L16.8232 20.6042C17.6149 21.0833 18.5836 20.375 18.3753 19.4792L17.2295 14.5521L21.0524 11.2396C21.7503 10.6354 21.3857 9.48958 20.469 9.41667ZM12.5003 16.0417L8.58362 18.4063L9.62529 13.9479L6.16696 10.9479L10.7295 10.5521L12.5003 6.35417L14.2815 10.5625L18.844 10.9583L15.3857 13.9583L16.4274 18.4167L12.5003 16.0417Z" fill="#F97F59"/>
        </svg>
    )
}

export default BookingSpecialIcon;