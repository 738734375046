import React from 'react';

// Material Ui
import Chip from '@mui/material/Chip';

const ChipFilters = (props) => {

    const {text, checked, disabled, onChange} = props;

    return (
        <Chip
            label={text}
            onClick={() => onChange(text, !checked)}
            variant={checked ? 'outlined' : ''}
            disabled={disabled && !checked}
            sx={{
                backgroundColor: checked ? '#0B4762' : '#FFFFFF',
                color: checked ? '#FFFFFF' : '#0B4762',
                fontFamily: 'Poppins, sans-serif',
                border: '1px solid #0B4762',
                fontWeight: '500',
                fontSize: '16px',
                "&:hover": {
                    backgroundColor: checked ? '#0B4762 !important' : '#FFFFFF',
                    color: checked ? '#FFFFFF' : '#0B4762',
                },
                '@media (max-width: 1700px)': {
                    margin: '.2rem',
                }
            }}
            checked={checked}
        />
    )
}

export default ChipFilters;
