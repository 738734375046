import React, { useState, useEffect } from 'react';

// Components
import { BlueSwitch } from '../../../../GeneralComponents/blueSwitch';
import SettingNumbers from './SettingNumbers';
import Divider from './Divider';

// Material Ui
import { Box, Stack, FormControl, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core';

// Utils
import { ResetAllRotationControlTables, SetRotationControlTable } from '../utils/advancedOptionsFunctions';
import { modalStyles } from '../utils/styles/modalStyles';

const useStyles = makeStyles(() => (modalStyles));

const TableCustomizeRotationTableItem = ({ rotationControl, tables, rotationControlTable, setRotationControlTable }) => {
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const [controlValue, setControlValue] = useState(1);

    const handleChangeActive = (id) => {
        // Todos
        if (id === 0) {
            if (!active === true) {
                ResetAllRotationControlTables(rotationControlTable, false, controlValue, setRotationControlTable);
            } 

            const newRotationControlTable = [...rotationControlTable];
            newRotationControlTable[0].active = !active;

            setRotationControlTable(newRotationControlTable);
            
        } else {
            SetRotationControlTable(rotationControlTable, id, !active, controlValue, setRotationControlTable);
        }
        
        setActive(!active);
    }

    const handleChangeControlValue = (id, value) => {               
        SetRotationControlTable(rotationControlTable, id, active, value, setRotationControlTable);
        setControlValue(value);        
    }

    useEffect(() => {
        setActive(rotationControl.active);
    }, [rotationControl])

    useEffect(() => {
        const rotation = rotationControlTable.find(rotation => rotation.id === rotationControl.id);
        const newActive = GetActive(rotationControl.active, rotationControl, rotationControlTable);

        setActive(newActive);
        setControlValue(rotation.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rotationControlTable]);

    const GetActive = (active, rotationControl, rotationControlTable) => {
        const todos = rotationControlTable.find((rotation) => rotation.id === 0);
        return ( active && rotationControl.id !== 0 && !todos.active) || (rotationControl.id === 0 && todos.active );
    }

    const GetDisabled = (rotationControl, rotationControlTable) => {
        const todos = rotationControlTable.find((rotation) => rotation.id === 0);
        return todos.active && rotationControl.id !== 0;
    }

    const switchActive = GetActive( active, rotationControl, rotationControlTable );

    return (
        <>
            <Stack direction="row" justifyContent="center" marginBottom="14px">
                <Divider mt={0} sx={{ borderColor: "#D1D1D1", width: "50%" }} />
            </Stack>
            <Stack direction="row" mt={1} mb={1} justifyContent="center" alignItems={'center'}>
                <Box width="30%" display="flex" flexDirection="row" alignItems="center">
                    <Box marginBottom='8px'>
                        <BlueSwitch
                            checked={switchActive}
                            color="default"
                            onChange={() => handleChangeActive(rotationControl.id)}
                            disabled={GetDisabled(rotationControl, rotationControlTable)}
                        >
                        </BlueSwitch>
                    </Box>
                    <Typography className={classes.ItemStyle}>
                        {rotationControl.name}
                    </Typography> 
                </Box>
                <Box width="15%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.ItemStyle}>
                        {rotationControl.min}
                    </Typography>
                </Box>
                <Box width="15%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography className={classes.ItemStyle}>
                        {rotationControl.max}
                    </Typography>
                </Box>
                <Box width="40%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <FormControl fullWidth>
                        <SettingNumbers id={rotationControl.id} enabled={switchActive} value={parseInt(controlValue)} handleChangeControlValue={handleChangeControlValue} />
                    </FormControl>
                </Box>
            </Stack>
            <Stack direction="row" justifyContent="center">
                <Divider sx={{ borderColor: "#D1D1D1", width: "50%" }} mt={1} />
            </Stack>
        </>
    )
}

export default TableCustomizeRotationTableItem;