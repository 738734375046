import React from 'react';

import moment from 'moment';

// Icons
import VerticalLineIcon from "../../SvgComponents/icons/ShiftIcons/VerticalLineIcon";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { SectorsV2Icon } from '../../SvgComponents/icons';

// Material Ui
import { Box, Typography } from '@mui/material';

// Styles
import { DaysContainer, IconText, InfoContainer, RepeatStyle, SelectedDays, TextWithoutIcon } from '../Styles';

const ShiftDataView = ({ 
    joinArrayElements,
    sectorsLayouts,
    getLetterDay, 
    getInterval, 
    GetPublish, 
    GetDates,
    editing, 
    getDays, 
    turno, 
}) => {
    return (
        <>
            {
                !turno.isSpecial && (
                    <Box sx={{ display: 'flex' , alignItems: 'center' }}>
                        <Box>
                            <Typography sx={ SelectedDays }>
                                Dias seleccionados:
                            </Typography>
                        </Box>
                        <Box sx={ DaysContainer }>
                            {
                                getDays( turno.calendar.days ).map(( day, index ) => (
                                    <Box
                                        key={`letra-${index}`}
                                        sx={{
                                            border: day ? "1px solid #041C32" : "1px solid #D1D1D1",
                                            cursor: editing ? "pointer" : "default",
                                            color: day ? "#041C32" : "#D1D1D1",
                                            fontFamily: "Poppins, sans-serif",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            display: "flex",
                                            margin: "0 7px",
                                            fontWeight: 600,
                                            height: '37px',
                                            width: '37px',
                                        }}
                                    >
                                        { getLetterDay( index ) }
                                    </Box>
                            ))}
                        </Box>
                        <VerticalLineIcon height="25px" />
                        <Box>
                            <Typography ml={ 2 } sx={ RepeatStyle }>
                                { getInterval( turno.calendar.repeat ) }
                            </Typography>
                        </Box>
                    </Box>
                )}
                {
                    turno.calendar.specialDates.length > 0 ? 
                        (
                            <Box sx={ InfoContainer }>
                                <ControlPointIcon sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                                <Typography sx={ IconText }>
                                    Fechas puntuales agregadas:{" "}
                                    { GetDates(turno.calendar.specialDates) }
                                </Typography>
                            </Box>
                        ) 
                    : 
                        null
                }
                {
                    turno.calendar.excludeDates.length > 0 ? 
                        (
                            <Box sx={ InfoContainer }>
                                <RemoveCircleOutlineIcon  sx={{ color: '#818181', fontSize: '1.1rem' }}/>
                                <Typography sx={ IconText }>
                                    Fechas puntuales excluidas:{" "}
                                    { GetDates(turno.calendar.excludeDates) }
                                </Typography>
                            </Box>
                        ) 
                    : 
                        null
                }
                <Box sx={ InfoContainer }>
                    <SectorsV2Icon size="13px" fill="#818181" />
                    <Typography sx={ IconText }>
                        Sectores habilitados: { joinArrayElements( sectorsLayouts, " | " ) }
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={"8px"}>
                    <Box width="50%">
                        <Typography sx={ TextWithoutIcon }>
                            Publicar a partir de:{" "}
                            { GetPublish( turno.calendar.startDate, turno.calendar.endDate ) }
                        </Typography>
                    </Box>
                    <Box 
                        sx={{
                            justifyContent: 'right',
                            alignItems: 'center',
                            textAlign: 'end',
                            display: 'flex',
                            width: '50%',
                        }}
                    >
                        <Box
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirectio: 'row',
                                display: 'flex',
                            }}
                        >
                        <Typography sx={ IconText }>
                            Última edición: { moment(turno.updatedAt).format("DD/MM/YYYY") } a
                            las { moment(turno.updatedAt).format("HH:mm") }Hs
                        </Typography>
                        </Box>
                    </Box>
                </Box>
        </>
    )
};

export default ShiftDataView;