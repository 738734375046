import React from 'react'

const BookingClientNoteIcon = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.14886 8.78184C5.5402 8.78184 5.04845 9.29721 5.04845 9.91058C5.04845 10.5856 5.5402 11.1046 6.14886 11.1046C6.75753 11.1046 7.21833 10.5875 7.21833 9.94324C7.21833 9.29902 6.76097 8.78184 6.14886 8.78184ZM10.0003 8.74917C9.38992 8.74917 8.93084 9.26636 8.93084 9.91058C8.93084 10.5548 9.42086 11.0393 10.0003 11.0393C10.5797 11.0393 11.0698 10.5221 11.0698 9.91058C11.0698 9.29902 10.6124 8.74917 10.0003 8.74917ZM13.8517 8.78184C13.2413 8.78184 12.7823 9.29902 12.7823 9.94324C12.7823 10.5875 13.2723 11.1046 13.8517 11.1046C14.4621 11.1046 14.9518 10.5875 14.9518 9.94324C14.9521 9.29721 14.4638 8.78184 13.8517 8.78184ZM10.0003 2.36145C5.13786 2.36145 1.22797 5.74114 1.22797 9.91058C1.22797 11.6389 1.91263 13.2224 3.04743 14.4945C2.53608 15.9281 1.47006 17.1396 1.45287 17.153C1.22508 17.4071 1.16494 17.7791 1.29386 18.0967C1.39709 18.4433 1.69152 18.6538 2.02233 18.6538C4.13683 18.6538 5.77404 17.7192 6.80567 16.9752C7.79845 17.3018 8.87444 17.4924 10.0003 17.4924C14.8627 17.4924 18.7726 14.1123 18.7726 9.9759C18.7726 5.83949 14.8627 2.36145 10.0003 2.36145ZM10.0003 15.7503C9.08042 15.7503 8.17362 15.6005 7.30567 15.3104L6.52335 15.0518L5.85313 15.5506C5.3631 15.9179 4.68841 16.3266 3.87617 16.6032C4.12975 16.1633 4.37033 15.6686 4.55946 15.1423L4.92466 14.1261L4.21558 13.3324C3.59763 12.6326 2.8497 11.4748 2.8497 9.94324C2.8497 6.74031 6.05602 4.13622 9.97141 4.13622C13.8868 4.13622 17.0931 6.74031 17.0931 9.94324C17.0931 13.1462 13.948 15.7503 10.0003 15.7503Z" fill="#818181"/>
        </svg>
    )
}

export default BookingClientNoteIcon