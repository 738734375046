import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import VisibilityIcon from "../SvgComponents/icons/VisibilityIcon";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '60px',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10000000,
        backgroundColor: "#0B4762",
        paddingLeft: '3rem',
        paddingRight: '3rem',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.2rem',
        fontWeight: '500',
        color: '#FFFFFF',
        marginBottom: 0,
        marginTop: "5px",
        paddingBottom: 0,
        paddingTop: 0,
    },
    subtitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.7rem',
        color: '#FFFFFF',
        marginTop: 0,
        paddingTop: 0,
    },
    button: {
        background: '#FFFFFF',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        color: '#0B4762',
        borderRadius: '8px',
        maxHeight: '60px',
        textTransform: 'none',
        "&:hover": {
            background: '#FFFFFF !important',
            backgroundColor: '#FFFFFF !important',
        }
    }
}));

const Visibility = ({height, onClick}) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{height: height}}>
            <div>
                <p className={classes.title}>Visibilidad en la App Cliente</p>
                <p className={classes.subtitle}>Tu perfil aparecerá en Woki</p>
            </div>
            <Button
                className={classes.button}
                variant="contained"
                startIcon={<VisibilityIcon/>}
                onClick={onClick}>
                Hacerme visible
            </Button>
        </div>
    )
}

export default Visibility;
