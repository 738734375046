import React from 'react';

const BookingClientTagsIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.5916" cy="0.28526" r="0.270188" fill="white" stroke="#35C18F" strokeWidth="0.0300209"/>
            <path d="M5.18692 17.1852C5.72494 17.7232 6.44216 17.9921 7.15954 17.9921C7.92163 17.9921 8.68372 17.7007 9.26665 17.1179L16.2379 10.1467C16.7087 9.67587 16.9776 9.04827 17 8.37592V3.10824C17 2.54785 16.7758 2.00984 16.3724 1.6064C15.9912 1.203 15.4533 0.97876 14.8929 0.97876H9.6028C8.95283 1.00112 8.32503 1.27021 7.85438 1.74085L0.883119 8.71211C-0.260038 9.85527 -0.304909 11.6934 0.815884 12.8142L5.18692 17.1852ZM1.9814 9.83285L8.95267 2.86159C9.13201 2.68225 9.3786 2.57014 9.60264 2.57014H14.8703C15.0273 2.57014 15.1618 2.63738 15.2737 2.72713C15.3858 2.83924 15.4307 2.97371 15.4307 3.13053V8.37579C15.4307 8.62237 15.3186 8.86893 15.1392 9.0483L8.16799 16.0196C7.62997 16.5576 6.77826 16.5799 6.28511 16.0868L1.91407 11.7157C1.42092 11.2001 1.44342 10.3707 1.9813 9.83271L1.9814 9.83285Z" fill="#35C18F"/>
            <path d="M11.9787 8.60005C12.6287 8.60005 13.3012 8.35347 13.7943 7.83796C14.803 6.8293 14.803 5.19299 13.7943 4.18417C12.7857 3.17551 11.1494 3.17551 10.1405 4.18417C9.13187 5.19283 9.13187 6.82914 10.1405 7.83796C10.6562 8.35348 11.3286 8.60005 11.9787 8.60005ZM11.2613 5.30495C11.463 5.10325 11.7096 5.0135 11.9787 5.0135C12.2476 5.0135 12.4942 5.1031 12.6961 5.30495C13.0995 5.70835 13.0995 6.33615 12.6961 6.73955C12.2927 7.14295 11.6649 7.14295 11.2615 6.73955C10.8803 6.33599 10.8803 5.70835 11.2613 5.30495H11.2613Z" fill="#35C18F"/>
        </svg>
    )
}

export default BookingClientTagsIcon;