import { useEffect, useState } from 'react';

import moment from 'moment';

// Material Ui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import BookingClientNoteIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingClientNoteIcon';
import BookingCreateDateIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingCreateDateIcon';
import FoodRestrictionIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/FoodRestrictionIcon';
import BookingQuantityIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingQuantityIcon';
import BookingPartnerNote from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingPartnerNote';
import BookingOptionsIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingOptionsIcon';
import BookingSectorIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingSectorIcon';
import BookingTableIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingTableIcon';
import BookingStateIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingStateIcon';
import BookingShiftIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingShiftIcon';
import BookingDateIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingDateIcon';
import BookingTagsIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingTagsIcon';
import BookingHourIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/BookingHourIcon';
import ClientNoteIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/ClientNoteIcon';
import SpecialIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/SpecialIcon';
import PhoneIcon from '../../../SvgComponents/Icons Tables/MyBookings/SidebarIcons/PhoneIcon';

import TableFoodRestrictionIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/TableFoodRestrictionIcon';
import BookingPartnerNoteIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingPartnerNoteIcon'; 
import BookingClientTagsIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingClientTagsIcon';
import BookingOptionalsIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingOptionalsIcon';
import TableClientNoteIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/TableClientNoteIcon';
import BookingSpecialIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/BookingSpecialIcon';
import LESectorIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/LESectorIcon';
import LEHourIcon from '../../../SvgComponents/Icons Tables/MyBookings/MoreInfoIcons/LEHourIcon';

// Styles
import { OriginSidebarTextStyles, OriginTextStyles } from '../components/Table/Style/Section3TableStyles';
import { ClientTagTextStyles } from '../../../MyClients/Styles/MyClientsStytles';
import { CountryFlagsArray } from '../../../../utils/CountryFlagsArray';

export const headCellsBookingTable = [
    {
        id: 'client',
        columnName: 'Nombre del cliente',
    },
    {
        id: 'phone',
        columnName: 'Teléfono',
    },
    {
        id: 'state',
        columnName: 'Estado de la reserva',
    },
    {
        id: 'date',
        columnName: 'Fecha de reserva',
    },
    {
        id: 'shift',
        columnName: 'Turno',
    },
    {
        id: 'hour',
        columnName: 'Hora',
    },
    {
        id: 'quantity',
        columnName: 'Cantidad de personas',
    },
    {
        id: 'sector',
        columnName: 'Sector',
    },
    {
        id: 'table',
        columnName: 'Mesa',
    },
    {
        id: 'origin',
        columnName: 'Origen',
    },
    {
        id: 'createDate',
        columnName: 'Fecha de creación',
    },
];

export const headCellsClientsTable = [
    {
        id: 'name',
        columnName: 'Nombre del cliente',
    },
    {
        id: 'alias',
        columnName: 'Alias',
    },
    {
        id: 'phone',
        columnName: 'Teléfono',
    },
    {
        id: 'email',
        columnName: 'Mail',
    },
    // {
    //     id: 'punctuation',
    //     columnName: 'Puntuación',
    // },
    {
        id: 'totalBookings',
        columnName: 'Reservas totales',
    },
    {
        id: 'lastVisit',
        columnName: 'Última reserva',
    },
    {
        id: 'incomes',
        columnName: 'Visitas',
    },
    {
        id: 'cancelled',
        columnName: 'Cancelaciones',
    },
    {
        id: 'fouls',
        columnName: 'No shows',
    },
    {
        id: 'active',
        columnName: 'Reservas futuras',
    },
    // {
    //     id: 'reviews',
    //     columnName: 'Reseñas',
    // }
];

export const bookingStateArray = [
    {
        id: 0, text: 'INGRESADA', fontColor: '#40C595', backColor: '#E0FDF3', state: 'arrived'
    },
    {
        id: 1, text: 'ACEPTADA', fontColor: '#0B4762', backColor: '#EEF7FB', state: 'accepted'
    },
    {
        id: 2, text: 'CONFIRMADA', fontColor: '#0B4762', backColor: '#EEF7FB', state: 'confirmed'
    },
    {
        id: 3, text: 'EDIT. RESTAURANTE', fontColor: '#FCBF49', backColor: '#FFF4DE', state: 'editedByPartner'
    },
    {
        id: 4, text: 'LE ACTIVA', fontColor: '#1AAFDE', backColor: '#DEF7FF', state: 'leActive'
    },
    {
        id: 5, text: 'LE ACEPTADA', fontColor: '#1AAFDE', backColor: '#DEF7FF', state: 'leAccepted'
    },
    {
        id: 6, text: 'NO VINO', fontColor: '#FB3F4A', backColor: '#FFEEEF', state: 'noShow'
    },
    {
        id: 7, text: 'CANCELADA CLIENTE', fontColor: '#FB3F4A', backColor: '#FFEEEF', state: 'deletedByUser'
    },
    {
        id: 8, text: 'ELIM. RESTAURANTE', fontColor: '#FB3F4A', backColor: '#FFEEEF', state: 'deletedByPartner'
    },
    {
        id: 9, text: 'SOLICITUD VENCIDA', fontColor: '#818181', backColor: '#F1F1F1', state: 'autoRejected'
    },
    {
        id: 10, text: 'SOLICITUD RECHAZADA', fontColor: '#FB3F4A', backColor: '#FFEEEF', state: 'rejected'
    },
    {
        id: 11, text: 'LE EXPIRADA', fontColor: '#818181', backColor: '#F1F1F1', state: 'leExpired'
    },
    {
        id: 12, text: 'LE ELIM. CLIENTE', fontColor: '#818181', backColor: '#F1F1F1', state: 'leDeletedByClient'
    },
    {
        id: 13, text: 'LE ELIM. RESTAURANTE', fontColor: '#818181', backColor: '#F1F1F1', state: 'leDeletedByPartner'
    },
    {
        id: 14, text: 'PEND. CONFIRMACIÓN', fontColor: '#FCBF49', backColor: '#FFF4DE', state: 'confirmationPending'
    },
    {
        id: 15, text: 'SOLICITUD', fontColor: '#FCBF49', backColor: '#FFF4DE', state: 'created'
    },
    {
        id: 16, text: 'SOLICITUD DE EDICIÓN', fontColor: '#FCBF49', backColor: '#FFF4DE', state: 'editedByClient'
    },
    {
        id: 17, text: 'ESPERANDO RTA CLIENTE', fontColor: '#FCBF49', backColor: '#FFF4DE', state: 'rejectedEdition'
    },
];

export const bookingStatesSelectOptions = [
    {   id: 1, option: 'Ingresada', value: '' },
    {   id: 2, option: 'Aceptada', value: '' },
    {   id: 3, option: 'Confirmada', value: '' },
    {   id: 4, option: 'Pendiente de confirmación', value: '' },
    {   id: 5, option: 'Solicitud', value: '' },
    {   id: 6, option: 'Solicitud de edición', value: '' },
    {   id: 7, option: 'Editada restaurante', value: '' },
    {   id: 8, option: 'LE activa', value: '' },
    {   id: 9, option: 'LE aceptada', value: '' },
    {   id: 10, option: 'LE expirada', value: '' },
    {   id: 11, option: 'LE eliminada cliente', value: '' },
    {   id: 12, option: 'LE eliminada restaurante', value: '' },
    {   id: 13, option: 'No vino', value: '' },
    {   id: 14, option: 'Cancelada cliente', value: '' },
    {   id: 15, option: 'Eliminada restaurante', value: '' },
    {   id: 16, option: 'Solicitud vencida', value: '' },
    {   id: 17, option: 'Solicitud rechazada', value: '' },
    {   id: 18, option: 'Esperando rta cliente', value: '' },
];

export const moreInfoBookingsSelectOptions = [
    { id: 1, option: 'Con tags', value: '' },
    { id: 2, option: 'Con nota reserva cliente', value: '' },
    { id: 3, option: 'Con nota reserva partner', value: '' },
    { id: 4, option: 'Con opcionales', value: '' },
    { id: 5, option: 'Con nota al cliente', value: '' },
    { id: 6, option: 'Con relacion especial', value: '' },
    { id: 7, option: 'Con restricción alimentaria', value: '' },
    // { id: 8, option: 'Cliente especial', value: '' },
    { id: 8, option: 'LE flexible por horario', value: '' },
    { id: 9, option: 'LE flexible por sector', value: '' }
];

export const moreInfoClientsSelectOptions = [
    { id: 1, option: 'Con tags', nameObj: 'tags' },
    { id: 2, option: 'Con nota al cliente', nameObj: 'clientNote' },
    { id: 3, option: 'Con relacion especial', nameObj: 'specialRelationship' },
    { id: 4, option: 'Con restricción alimentaria', nameObj: 'foodRestrictions' },
];

export const clientsOptions = ['Con tags', 'Con nota al cliente', 'Con relacion especial', 'Con restricción alimentaria'];
export const bookingsOptions = [
    'Con tags',
    'Con nota reserva cliente',
    'Con nota reserva partner',
    'Con opcionales',
    'Con nota al cliente',
    'Con relacion especial',
    'Con restricción alimentaria',
    'Cliente especial',
    'LE flexible por horario',
    'LE flexible por sector',
];

export const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    height: 19,
    width: 19,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

export const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#0B4762',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 19,
        height: 19,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#0B4762',
    },
});

export const bookingFiltersModal = [
    { 
        id: 0, 
        name: 'País', 
        filterArray: CountryFlagsArray,
        selectName: 'country' 
    },
    { 
        id: 1, 
        name: 'Turno', 
        filterArray: [
            { id: 4, option: 'Sin filtro' }, 
            { id: 5, option: 'MAÑANA' }, 
            { id: 6, option: 'MEDIODIA' }, 
            { id: 7, option: 'TARDE' }, 
            { id: 8, option: 'NOCHE' }
        ],
        selectName: 'shift' 
    },
    { 
        id: 2, 
        name: 'Sector', 
        filterArray: [
            { id: 9, option: 'Sin filtro' }, 
            { id: 10, option: 'TERRAZA' }, 
            { id: 11, option: 'AFUERA' }, 
            { id: 12, option: 'ADENTRO' },
        ],
        selectName: 'sector' 
    },
    { 
        id: 3, 
        name: 'Origen', 
        filterArray: [
            { id: 13, option: 'Sin filtro' }, 
            { id: 14, option: 'WOKI', value: 0 }, 
            { id: 15, option: 'Restaurante', value: 1 },
        ],
        selectName: 'origin' 
    },
];

export const handleShowBookingState = ( bookingState, place ) => {
    for (let i = 0; i < bookingStateArray.length; i++) {
        if (bookingState === bookingStateArray[i].state) {
            return(
                <Box 
                    sx={{
                        width: place === 'sidebar' ? 'max-content' : 'auto',
                        backgroundColor: bookingStateArray[i].backColor, 
                        p: place === 'sidebar' ? '.2rem 1.6rem' : '.2rem .3rem', 
                        color: bookingStateArray[i].fontColor,
                        borderRadius: '6px',
                        textAlign: 'center',
                        minWidth: 150, 
                    }}
                >
                    <Typography 
                        variant='subtitle2' 
                        sx={{
                            fontFamily: 'Poppins', 
                            fontSize: '.7rem', 
                            fontWeight: 600,
                        }}
                    >
                        { bookingStateArray[i].text }
                    </Typography>
                </Box>
            )
        }
        
    }
};

export const handleShowSectorShiftState = ( data ) => {
    return (
        <>
            {
                data ? 
                    <Box
                        sx={{
                            border: '1px solid #E7E7E7', 
                            boxSizing: 'border-box', 
                            textAlign: 'center',
                            borderRadius: '8px', 
                            p: '.2rem 1.6rem',
                            width: 150, 
                        }}
                    >
                        <Typography 
                            color='#818181' 
                            sx={{ 
                                fontFamily: 'Poppins', 
                                fontSize: '.8rem', 
                                fontWeight: 600, 
                            }}
                        >{ data }</Typography>
                    </Box>
                :
                    null
            }
        </>
    )
};

export const handleShowBookingOrigin = ( origin, place ) => {
    switch ( origin ) {
        case 0:
            return <Typography sx={ place === 'sidebar' ? OriginSidebarTextStyles : OriginTextStyles } >Woki</Typography>
        default:
            return <Typography sx={ place === 'sidebar' ? OriginSidebarTextStyles : OriginTextStyles } >Restaurante</Typography>
    }
};

const handleShowBookingTags = ( tags ) => {
    return (
        <Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
            {
                tags?.map( (tag, index) => 
                    <Typography key={ index } sx={ ClientTagTextStyles } >{ tag }</Typography>
                )
            }
        </Box>
    )
};

export const onSetSidebarData = ( userData ) => {
    return [
        {
            id: 0,
            BookingIcon: PhoneIcon,
            name: 'Teléfono',
            data: userData.phone
        },
        {
            id: 1,
            BookingIcon: BookingStateIcon,
            name: 'Estado de la reserva',
            data: handleShowBookingState(userData.state, 'sidebar')
        },
        {
            id: 2,
            BookingIcon: BookingDateIcon,
            name: 'Fecha de la reserva',
            data: userData.date.substring(0,10)
        },
        {
            id: 3,
            BookingIcon: BookingShiftIcon,
            name: 'Turno',
            data: handleShowSectorShiftState('MEDIODÍA')
        },
        {
            id: 4,
            BookingIcon: BookingHourIcon,
            name: 'Hora',
            data: userData.hour
        },
        {
            id: 5,
            BookingIcon: BookingQuantityIcon,
            name: 'Cantidad de personas',
            data: userData.quantity
        },
        {
            id: 6,
            BookingIcon: BookingSectorIcon,
            name: 'Sector',
            data: handleShowSectorShiftState( userData.sector )
        },
        {
            id: 7,
            BookingIcon: BookingTableIcon,
            name: 'Mesa',
            data: userData.table
        },
        {
            id: 8,
            BookingIcon: BookingOptionsIcon,
            name: 'Origen',
            data: handleShowBookingOrigin(userData.origin, 'sidebar')
        },
        {
            id: 9,
            BookingIcon: BookingCreateDateIcon,
            name: 'Fecha de creación',
            data: moment(userData.createdAt.substring(0,10)).format('DD/MM/YYYY')
        },
        {
            id: 10,
            BookingIcon: BookingTagsIcon,
            name: 'Tags',
            data: handleShowBookingTags( userData.tags )
        },
        {
            id: 11,
            BookingIcon: BookingClientNoteIcon,
            name: 'Nota reserva cliente',
            data: userData.clientComment
        },
        {
            id: 12,
            BookingIcon: BookingPartnerNote,
            name: 'Nota reserva partner',
            data: userData.partnerNote
        },
        {
            id: 13,
            BookingIcon: BookingOptionsIcon,
            name: 'Opcionales',
            data: userData.optionals
        },
        {
            id: 14,
            BookingIcon: ClientNoteIcon,
            name: 'Nota cliente',
            data: userData.clientNote
        },
        {
            id: 15,
            BookingIcon: SpecialIcon,
            name: 'Relación especial',
            data: userData.specialRelationship
        },
        {
            id: 16,
            BookingIcon: FoodRestrictionIcon,
            name: 'Restricción alimentaria',
            data: userData.foodRestrictions
        },
    ]
};

export const MoreInfoIconsArray = [
    { id: 0, value: '', MoreInfoIcon: BookingClientTagsIcon },
    { id: 1, value: '', MoreInfoIcon: BookingClientNoteIcon },
    { id: 2, value: '', MoreInfoIcon: BookingPartnerNoteIcon },
    { id: 3, value: '', MoreInfoIcon: BookingOptionalsIcon },
    { id: 4, value: '', MoreInfoIcon: TableClientNoteIcon },
    { id: 5, value: '', MoreInfoIcon: BookingSpecialIcon },
    { id: 6, value: '', MoreInfoIcon: TableFoodRestrictionIcon },
    { id: 8, value: '', MoreInfoIcon: LEHourIcon },
    { id: 9, value: '', MoreInfoIcon: LESectorIcon },
];

export const CreateData = (
    id, 
    client, 
    phone, 
    state, 
    date, 
    shift, 
    hour, 
    quantity, 
    sector,
    table, 
    origin,
    Date
) => {
    return {
        id,
        client,
        phone,
        state,
        date,
        shift,
        hour,
        quantity,
        sector,
        table,
        origin,
        Date
    };
};

export const DescendingComparator = ( a, b, orderBy ) => {
    if ( b[orderBy] < a[orderBy] ) {
        return -1;
    }

    if ( b[orderBy] > a[orderBy] ) {
        return 1;
    }

    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'asc'
        ? (a, b) => DescendingComparator( a, b, orderBy )
        : (a, b) => -DescendingComparator( a, b, orderBy );
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
};

export const ShowBookingsTableIcons = ( 
    specialRelationship, 
    bookingPartnerNote,
    bookingClientNote, 
    foodRestrictions,
    clientNote,
    optionals,
    LEsector, 
    LEHour,
    tags, 
) => {

    const [FRLength, setFRLength] = useState( foodRestrictions.length );

    useEffect(() => {
        let cont = 0;
        for (let i = 0; i < foodRestrictions.length; i++) {
            if ( foodRestrictions[i].trim() === '' ) {
                cont++;
            }
        }

        setFRLength( FRLength - cont );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ foodRestrictions ]);

    return (
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', p: '.2rem 0' }}>
            { ( bookingClientNote && bookingClientNote !== '' && bookingClientNote !== null ) && <BookingClientNoteIcon /> }
            { ( bookingPartnerNote && bookingPartnerNote !== '' && bookingPartnerNote !== null ) && <BookingPartnerNoteIcon /> }
            { ( optionals && optionals?.length !== 0 ) && <BookingOptionalsIcon /> }
            { ( tags && tags?.length !== 0 ) && <BookingClientTagsIcon /> }
            { ( clientNote && clientNote !== '' && clientNote !== null ) && <TableClientNoteIcon /> }
            { ( foodRestrictions && foodRestrictions?.length > 0 ) && <TableFoodRestrictionIcon /> }
            { specialRelationship && <BookingSpecialIcon /> }
            { ( LEHour !== '' && LEHour !== null ) && <LEHourIcon /> }
            { ( LEsector !== '' && LEsector !== null ) && <LESectorIcon /> }
        </Box>
    )
};

export const handleChangeBookingsMoreInfoFilters = ( infoArray, setMoreInfoFilters ) => {
    let newArray = [
        {
            label: 'Con tags',
            value: true,
        },
        {
            label: 'Con nota reserva cliente',
            value: true,
        },
        {
            label: 'Con nota reserva partner',
            value: true,
        },
        {
            label: 'Con opcionales',
            value: true,
        },
        {
            label: 'Con nota al cliente',
            value: true,
        },
        {
            label: 'Con relacion especial',
            value: true,
        },
        {
            label: 'Con restricción alimentaria',
            value: true,
        },
        // {
        //     label: 'Cliente especial',
        //     value: true,
        // },
        {
            label: 'LE flexible por horario',
            value: true,
        },
        {
            label: 'LE flexible por sector',
            value: true,
        },
    ];

    if ( 
        infoArray[ infoArray.length -1 ] === 'Todos' && 
        infoArray.length > newArray.length 
    ) {
        for (let i = 0; i < newArray.length; i++) {
            newArray[i].value = false;
        };
    } else if (
        infoArray[ infoArray.length -1 ] === 'Todos' && 
        infoArray.length < newArray.length
    ) {
        for (let j = 0; j < newArray.length; j++) {
            newArray[j].value = true;
        };
    } else {
        for (let k = 0; k < newArray.length; k++) {
            const exist = infoArray.find( ( item ) => item === newArray[k].label );
            newArray[k].value = !!exist;
        };
    };

    for (let i = 0; i < newArray.length; i++) {
        const exist = infoArray.find( ( item ) => item === newArray[i].label );
        newArray[i].value = !!exist;
    };

    setMoreInfoFilters( newArray );
};

export const handleChangeBookingStateFilters = ( bookingStatesArray, setBookingState ) => {

    let newArray = [
        {
            label: 'Ingresada',
            value: true,
        },
        {
            label: 'Aceptada',
            value: true,
        },
        {
            label: 'Confirmada',
            value: true,
        },
        {
            label: 'Pendiente de confirmación',
            value: true,
        },
        {
            label: 'Solicitud',
            value: true,
        },
        {
            label: 'Solicitud de edición',
            value: true,
        },
        {
            label: 'Editada restaurante',
            value: true,
        },
        {
            label: 'LE activa',
            value: true,
        },
        {
            label: 'LE aceptada',
            value: true,
        },
        {
            label: 'LE expirada',
            value: true,
        },
        {
            label: 'LE eliminada cliente',
            value: true,
        },
        {
            label: 'LE eliminada restaurante',
            value: true,
        },
        {
            label: 'No vino',
            value: true,
        },
        {
            label: 'Cancelada cliente',
            value: true,
        },
        {
            label: 'Eliminada restaurante',
            value: true,
        },
        {
            label: 'Solicitud vencida',
            value: true,
        },
        {
            label: 'Solicitud rechazada',
            value: true,
        },
        {
            label: 'Esperando rta cliente',
            value: true
        }
    ];

    if ( 
        bookingStatesArray[ bookingStatesArray.length -1 ] === 'Todos' && 
        bookingStatesArray.length > newArray.length 
    ) {
        for (let i = 0; i < newArray.length; i++) {
            newArray[i].value = false;
        };
    } else if (
        bookingStatesArray[ bookingStatesArray.length -1 ] === 'Todos' && 
        bookingStatesArray.length < newArray.length
    ) {
        for (let j = 0; j < newArray.length; j++) {
            newArray[j].value = true;
        };
    } else {
        for (let k = 0; k < newArray.length; k++) {
            const exist = bookingStatesArray.find( ( item ) => item === newArray[k].label );
            newArray[k].value = !!exist;
        };
    };

    setBookingState( newArray );

};

