import React from "react";

const AlarmIcon = ({ fill }) => {
  return (
    <svg
      width="1rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C16.9706 22 21 17.9706 21 13C21 8.02944 16.9706 4 12 4C7.02944 4 3 8.02944 3 13C3 17.9706 7.02944 22 12 22Z"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4V2M15.5 9.5L12 13L15.5 9.5ZM19 19L20 22L19 19ZM5 19L4 22L5 19ZM2 5L5 2L2 5ZM19 2L22 5L19 2Z"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AlarmIcon.defaultProps = {
  fill: "#848282",
};

export default AlarmIcon;
