import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import 'moment/locale/en-gb';
import dayjs from 'dayjs';

// Actions
import { setPlanningRangeHours } from '../../../actions';

// Components
// import SelectWithArrows from './SelectWithArrows';

// Material Ui
import { Box, Collapse, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styles
import { CheckedStyle } from '../Styles';

const EndPlanningRepeatComponent = ( props ) => {

    // Actions
    const { setPlanningRangeHours } = props;

    // States
    const { openningHoursForm } = props;

    const [valueNunca, setValueNunca] = useState( openningHoursForm.repeatEnd !== null ? openningHoursForm.repeatEnd : null );
    // const [planningRepeats, setPlanningRepeats] = useState( 1 );
    // const [valueAfterOf, setValueAfterOf] = useState( false );
    const [optionNunca, setOptionNunca] = useState( openningHoursForm.repeatEnd === null ? 'nunca' : 'el' );

    const handleChangeNunca = (event) => {
        console.log( event.target.value );
        if (event.target.value === 'nunca') {
            setValueNunca( null );
        }

        // if ( event.target.value === 'afterOf' ) {
        //     setValueAfterOf( true );
        // } else {
        //     setValueAfterOf( false );
        // }

        setOptionNunca( event.target.value );
    };

    const TextFieldStyles = {
        width: '165px',
        '& .MuiInputBase-root': {
            fontFamily: 'Poppins !important',
            borderRadius: '.5rem',
            fontSize: '.9rem',
            fontWeight: 500,
            color: '#041C32'
        },
        '& .MuiInputBase-input': {
            padding: '10px 8px 10px 14px'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.4rem',
            color: '#041C32',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0B4762 !important',
        },
    };

    // const handleIncreaseRepeat = () => {
    //     if ( planningRepeats >= 1 && planningRepeats < 52 && valueAfterOf ) {
    //         setPlanningRepeats( planningRepeats + 1 );
    //     } else {
    //         return;
    //     }
    // };

    // const handleDecreaseRepeat = () => {
    //     if ( planningRepeats > 1 && planningRepeats <= 52 && valueAfterOf ) {
    //         setPlanningRepeats( planningRepeats - 1 );
    //     } else {
    //         return;
    //     }
    // };

    useEffect(() => {
        if ( optionNunca === 'nunca' ) {
            setPlanningRangeHours({
                ...openningHoursForm,
                repeatEnd: null
            });
        } else {
            setPlanningRangeHours({
                ...openningHoursForm,
                repeatEnd: dayjs( valueNunca )
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ optionNunca, valueNunca ]);

    return (
        <Box sx={{ padding: '1rem 0' }}>
            <Typography 
                sx={{ 
                    fontFamily: 'Poppins', 
                    m: '.5rem 0 .8rem 0',
                    fontSize: '1.1rem', 
                    color: '#041C32', 
                    fontWeight: 500,
                }}
            >
                Termina
            </Typography>
            <Box>
                <RadioGroup
                    onChange={ handleChangeNunca }                    
                    value={ optionNunca }
                    name="radio-nunca"
                >
                    <Stack direction="row" spacing={2} >
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='nunca' 
                            label={
                                <Typography 
                                    sx={{
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500, 
                                    }}
                                >Nunca</Typography>
                            } 
                        />
                        <FormControlLabel 
                            value="el" 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            label={
                                <Typography 
                                    sx={{
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500, 
                                    }}
                                >El</Typography>
                            } 
                        />
                        <Collapse 
                            sx={{ 
                                '& .MuiCollapse-wrapperInner': { 
                                    display: 'flex', 
                                    gap: '15px' 
                                } 
                            }} 
                            in={ optionNunca !== 'nunca' } 
                            orientation="horizontal" 
                        >
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale="en-gb">
                                <DatePicker
                                    onChange={ ( newValue ) => setValueNunca( newValue ) }
                                    value={ valueNunca ? valueNunca : dayjs( Date.now() ) }
                                    renderInput={ (params) => <TextField {...params} /> }
                                    disabled={ optionNunca === 'nunca' ? true : false }
                                    minDate={ dayjs( new Date() ) }
                                    sx={ TextFieldStyles }
                                    format='DD/MM/YYYY'
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiPickersCalendarHeader-label': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersYear-yearButton': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiTypography-root': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersDay-root': {
                                                    fontFamily: 'Poppins !important',
                                                }
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            {/* <Box sx={{ display: 'flex' }} >
                                <FormControlLabel 
                                    control={ <Radio sx={ CheckedStyle } /> } 
                                    value='afterOf' 
                                    label={
                                        <Typography 
                                            sx={{ 
                                                fontFamily: 'Poppins', 
                                                fontSize: '16px', 
                                                fontWeight: 500 
                                            }}
                                        >Después de</Typography>
                                    } 
                                />
                                <SelectWithArrows
                                    handleDecrease={ handleDecreaseRepeat }
                                    handleIncrease={ handleIncreaseRepeat }
                                    amount={ planningRepeats }
                                    singularText='repetición'
                                    disabled={ !valueAfterOf }
                                    pluralText='repetiones'
                                />
                            </Box> */}
                        </Collapse>
                    </Stack>
                </RadioGroup>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        openningHoursForm: state.planningReducer.openningHoursForm,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setPlanningRangeHours: ( data ) => dispatch( setPlanningRangeHours( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( EndPlanningRepeatComponent );