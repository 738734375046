import {Switch, withStyles} from "@material-ui/core";

export const BlueSwitch = withStyles({
    switchBase: {
        marginLeft: '4px',
        color: "#FFFFFF",
        marginTop: '4px',        
        "&$checked": {
            marginLeft: '-1.5px',
            color: "#FFFFFF",
        },
        "&$checked + $track": {
            backgroundColor: "#0B4762",
            opacity: '100%',
        },
        '&.MuiSwitch-colorSecondary.Mui-checked:hover': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
        }
    },
    thumb: {
        height: 17.5,
        width: 17.5,
    },
    checked: {},
    track: {
        borderRadius: '10px',
        height: '1.22rem',
    }
})(Switch);
