import React, {useEffect} from 'react'
import {changeShowSidebar} from "../../actions/sidebarActions";
import {connect} from "react-redux";

function NotFound(props) {
    useEffect(() => {
        props.changeShowSidebar(false);
    }, [])

    return (
        <div>
            <h1>Pagina no encontrada</h1>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(NotFound)