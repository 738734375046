import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setNewMultipleClicksOptional, setNewOneClickOptional } from '../../../actions/optionalActions';

// Components
import OptionComponent from './OptionComponent';

// Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Material Ui
import { Box, Button, Collapse, Typography } from '@mui/material';

// Styles
import { 
    OptionGeneralContainerStyles, 
    OptionsContainerStyles, 
    TextButtonAddOptional, 
    AddNewOptionStyles, 
} from '../Styles/OptionalStyles';

const OptionsComponents = ( props ) => {

    // Actions
    const { setNewOneClickOptional, setNewMultipleClicksOptional } = props;

    // States
    const { optionSelected, multipleOptionsArray, oneClickArray } = props;

    const [show, setShow] = useState( false );

    useEffect(() => {
        if ( optionSelected === 'Click único' || optionSelected === 'Opción múltiple' ) {
            setShow( true );
        } else {
            setShow( false );
        }
    }, [optionSelected ]);
    
    const handleAddOption = () => {
        if ( optionSelected === 'Click único' ) {
            setNewOneClickOptional();
        } else {
            setNewMultipleClicksOptional();
        }
    }

    return (
        <Collapse in={ show }>
            <Box sx={ OptionGeneralContainerStyles }>
                {
                    optionSelected === 'Click único' ?
                        <Box sx={ OptionsContainerStyles }>
                            {
                                oneClickArray.map(({ id, text }) => 
                                    <OptionComponent
                                        optionSelected={ optionSelected }  
                                        optionText={ text }
                                        key={ id }
                                        id={ id } 
                                    />
                                )
                            }
                        </Box>
                    :
                    <Box sx={ OptionsContainerStyles }>
                        {
                            multipleOptionsArray.map(({ id, text }) => 
                                <OptionComponent
                                    optionSelected={ optionSelected }
                                    optionText={ text }
                                    key={ id }
                                    id={ id } 
                                />
                            )
                        }
                    </Box>
                }
                <Button onClick={ handleAddOption } sx={ AddNewOptionStyles }>
                    <AddCircleOutlineIcon sx={{ color: '#0B4762' }} />
                    <Typography sx={ TextButtonAddOptional }>Agregar</Typography>
                </Button>
            </Box>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        multipleOptionsArray: state.optionalReducer.multipleOptionsArray,
        optionSelected: state.optionalReducer.optionSelected,
        oneClickArray: state.optionalReducer.oneClickArray,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setNewMultipleClicksOptional: () => dispatch(setNewMultipleClicksOptional()),
        setNewOneClickOptional: () => dispatch(setNewOneClickOptional()),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( OptionsComponents );