import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        boxShadow: '15px 15px 35px rgba(11, 71, 98, 0.4)',
        fontFamily: 'Poppins, sans-serif',
        transform: 'translateX(-50%)',
        border: '2px solid #0B4762',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        alignItems: 'center',
        background: 'white',
        borderRadius: '8px',
        textAlign: 'center',
        userSelect: 'none',
        cursor: 'pointer',
        fontWeight: '600',
        padding: '1.2rem',
        position: 'fixed',
        color: '#0B4762',
        fontSize: '18px',
        display: 'flex',
        height: '30px',
        bottom: '10%',
        zIndex: '3',
        left: '50%',
    }
}));

const SaveChangesButton = (props) => {

    const classes = useStyle(props);

    const {onClick} = props;

    return (
        <div className={classes.root} onClick={onClick}>
            Guardar cambios
        </div>
    )
}

SaveChangesButton.defaultProps = {
    index: '100000'
}

export default SaveChangesButton;
