import React from 'react';

import { connect } from 'react-redux';

// Actions
import { setFromRangeHours, setShowPlanningSnackbar, setToRangeHours } from '../../../actions';

// Components
import HoursPlanningSelect from './HoursPlanningSelect';

// Icons
import TrashPlanningIcon from '../../SvgComponents/icons/Planning/TrashPlanningIcon';

// Material Ui
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';

const NewRangeHourComponent = ( props ) => {

    // Actions
    const { setFromRangeHours, setToRangeHours, setShowPlanningSnackbar } = props;

    // External props
    const { from, to, id, onDelete, arrayDate } = props;

    // States
    const { planningSnackbar } = props;

    const handleChangeFromHour = ( e ) => {
        setFromRangeHours({
            arrayDate: arrayDate,  
            fromHour: e, 
            id: id, 
        });
    };

    const handleChangeToHour = ( e ) => {
        setToRangeHours({
            arrayDate: arrayDate, 
            toHour: e, 
            id: id, 
        });
    };

    useEffect(() => {
        if ( from < to ) {
            setShowPlanningSnackbar({
                ...planningSnackbar,
                failedComponent: '',
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ from, to ]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', height: '60px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
                <Typography sx={{ fontFamily: 'Poppins', color: '#041C32', fontWeight: 500 }}>Desde</Typography>
                <HoursPlanningSelect 
                    error={ planningSnackbar.failedComponent === 'hourSelect' }
                    handleChange={ handleChangeFromHour }
                    value={ from }
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
                <Typography sx={{ fontFamily: 'Poppins', color: '#041C32', fontWeight: 500 }}>Hasta</Typography>
                <HoursPlanningSelect 
                    error={ planningSnackbar.failedComponent === 'hourSelect' }
                    handleChange={ handleChangeToHour }
                    value={ to }
                />
            </Box>
            <IconButton onClick={ onDelete } sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <TrashPlanningIcon/>
            </IconButton>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        planningSnackbar: state.planningReducer.planningSnackbar,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setFromRangeHours: ({ id, fromHour, arrayDate }) => dispatch( setFromRangeHours({ id, fromHour, arrayDate }) ),
        setShowPlanningSnackbar: ( planningSnackbar ) => dispatch( setShowPlanningSnackbar( planningSnackbar ) ),
        setToRangeHours: ({ id, toHour, arrayDate }) => dispatch( setToRangeHours({ id, toHour, arrayDate }) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( NewRangeHourComponent );