import moment from "moment";
import {minutesOfDay} from "../constants";


export const getCompleteDay = (date) => {
    const time = moment(date);
    let res = "";

    switch (time.day()) {
        case 0:
            res += "Domingo ";
            break;
        case 1:
            res += "Lunes ";
            break;
        case 2:
            res += "Martes ";
            break;
        case 3:
            res += "Miercoles ";
            break;
        case 4:
            res += "Jueves ";
            break;
        case 5:
            res += "Viernes ";
            break;
        case 6:
            res += "Sábado ";
            break;
        default:
            break;
    }

    res += time.date();

    return res;
}

export const getMonthYear = (date) => {

    const time = moment(date)
    let res = "";

    switch (time.month()) {
        case 0:
            res += "Enero ";
            break;
        case 1:
            res += "Febrero ";
            break;
        case 2:
            res += "Marzo ";
            break;
        case 3:
            res += "Abril ";
            break;
        case 4:
            res += "Mayo ";
            break;
        case 5:
            res += "Junio ";
            break;
        case 6:
            res += "Julio ";
            break;
        case 7:
            res += "Agosto ";
            break;
        case 8:
            res += "Septiembre ";
            break;
        case 9:
            res += "Octubre ";
            break;
        case 10:
            res += "Noviembre ";
            break;
        case 11:
            res += "Diciembre ";
            break;
        default:
            break;
    }

    res += time.year();

    return res;
}

export const dayToChar = (day) => {
    switch (day) {
        case 0:
            return 'D';
        case 1:
            return 'L';
        case 2:
            return 'M';
        case 3:
            return 'X';
        case 4:
            return 'J';
        case 5:
            return 'V';
        case 6:
            return 'S';
    }
};

export const dayToString = (day) => {
    switch (day) {
        case 0:
            return 'Domingo';
        case 1:
            return 'Lunes';
        case 2:
            return 'Martes';
        case 3:
            return 'Miercoles';
        case 4:
            return 'Jueves';
        case 5:
            return 'Viernes';
        case 6:
            return 'Sábado';
    }
}

export const cleanString = (data) => {
    data = data.toLowerCase();
    data = data.replace('ü', 'u');
    return data.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const hhssToMinutes = (data) => {
    let parts = data.split(":");
    if (parts.length < 2) return;

    let hours = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);

    if (hours < 0 || minutes < 0) return;

    return (60 * hours) + minutes;
}

export const MinutesTohhss = (data) => {
    if (data === undefined) return 0;
    let time = data;
    if (time > minutesOfDay) {
        time = time - minutesOfDay;
    }
    let hours = Math.trunc(time / 60);
    let minutes = time % 60;
    return String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0')
}

export const MinutesToDays = (data) => {
    if (data === undefined) return 0;

    let days = Math.trunc(data / 1440);
    return String(days)
}

export const openCloseTimeToOpenDuration = (open, close) => {
    let duration;
    if (close >= open) {
        duration = close - open;
    } else {
        duration = 1440 - open + close; // 1440 son los minutos en un dia. Se calculan los minutos desde opentime hasta que termine el dia y luego se le suma lo que resta para que cierre.
    }
    return duration;
}

export const openDurationTimeToOpenClose = (open, duration) => {
    let close;

    if (open + duration >= 1440) {
        close = open + duration - 1440;
    } else {
        close = open + duration; // 1440 son los minutos en un dia. Se calculan los minutos desde opentime hasta que termine el dia y luego se le suma lo que resta para que cierre.
    }
    return close;
}

export const shiftDBtoUI = (shift) => {
    let auxShift = {...shift}
    auxShift.opening = MinutesTohhss(auxShift.opening);
    auxShift.subShifts = auxShift.subShifts.map(subshift => {
        let copySubShift = {...subshift}
        copySubShift.from = MinutesTohhss(subshift.from);
        copySubShift.to = MinutesTohhss(openDurationTimeToOpenClose(subshift.from, subshift.to));
        copySubShift.subSubShifts = subshift.subSubShifts ?? 0;
        return {...copySubShift}
    })
    return {...auxShift, sectors: shift.sectors}
}


export const scheduleToShifts = (shiftsDb) => {
    return shiftsDb.map(shift => {
        return shiftDBtoUI(shift);
    });
}
