import React, { useState } from "react";

import { connect } from "react-redux";

// Actions
import { setExtraIds, setExtraTable, setGroup, setReservableSectors, setSubshift, setUpdateTables } from "../../../../../actions/shiftsActions";

// Components
import { DividerStyles } from "./CustomizeReservableGroupSectorsModal";
import Divider from "../components/Divider";
import { Title } from "../../Title";
import TableItem from "./TableItem";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { Box, Button, Modal, Stack, Typography, FormControl, Select, MenuItem, OutlinedInput} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { itemStyle, modalStyles, modalTitle } from "../utils/styles/modalStyles";
import { SetNewBookableSubshift } from "../utils/reservableSectorsFunctions";
import { GetStyledItem } from "../utils/modalFunctions";

// Styles
import { SettingsTablesStyles, ShiftTableSelectStyles } from "../../../Styles";

const useStyles = makeStyles(() => ( modalStyles ));

const CustomizeReservableSectorsModal = (props) => {
  const classes = useStyles();

  // Variables
  const {
    reservableSectors,
    subShifts,
  } = props;

  // Acciones
  const {
    setReservableSectors,
    setUpdateTables,
    setExtraTable,
    setExtraIds,
    handleClose,
    setSubshift,
    setGroup,
  } = props;

  const newReservableSectors = reservableSectors.filter((reservableSector) => reservableSector.available); 
  
  const [reservableSector, setReservableSector] = useState( newReservableSectors.length > 0 ? reservableSectors[0] : null );
  const [deleteReservableTables, setDeleteReservableTables] = useState([]);  
  const [reservableTables, setReservableTables] = useState([]);

  const ITEM_PADDING_TOP = 8;
  const ITEM_HEIGHT = 48;

  const MenuProps = {
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      },
      transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
      },
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              border: '1px solid #0B4762',
              fontFamily: 'Poppins',
              borderRadius: '10px',
              maxWidth: '15.75rem',
              width: 'max-content',
              marginTop: '.2rem',
              minWidth: '78px',
          },
      },
  };

  const handleChangeSector = (val) => {
    setReservableSector( newReservableSectors.find(reservableSector => reservableSector.id === val) );
  };

  const handleChangeActive = ( table, enabled, layout ) => {

    const existSectors = newReservableSectors.find( reservableSector => reservableSector.id === table.id );
    const existTables = reservableTables.find( reservableTable => reservableTable.id === table.id );    

    if ( !enabled && !existSectors && !existTables ) {

      let subshiftArray = [];

      if ( subShifts.length > 0 ) {
        subShifts.forEach( subshift => {
          subshiftArray.push({ id: subshift.id, enabled: true });
        });
      };

      const reservableTable = {
        id: table.id,
        name: `Mesa ${ table.alias }`,
        tables: [],
        type: 'Mesa',
        subShifts: subshiftArray,
        available: true,
        layout: layout
      };

      setReservableTables(( reservableTables ) => [ ...reservableTables, reservableTable ]);
    } else {
      
      if ( existSectors || existTables ) {
        setDeleteReservableTables(( deleteReservableTables ) => [ ...deleteReservableTables, table ]);
      };
    };
  };


  const GetReservableSector = ( tableId ) => {
    let newReservableSector = null;
    
    // eslint-disable-next-line array-callback-return
    reservableSectors.map((reservableSector) => {
      if (reservableSector.tables.length > 0) {
        const table = reservableSector.tables.find( table => table.id === tableId );
        // Existe la mesa
        if ( typeof table !== 'undefined' ) {
          newReservableSector = reservableSector;
        }      
      }
    })

    return newReservableSector;
  };

  const GetReservableSectorSubshifts = (reservableSector) => {
    const subShifts = reservableSector.subShifts.filter((subShift) => (
      subShift.enabled === false
    ));
    return subShifts;
  };

  const SetSubShiftExtraTable = ( notEnabledSubShifts, tableId ) => {
    // eslint-disable-next-line array-callback-return
    subShifts.map(( subshift ) => {

      if ( notEnabledSubShifts.find( notEnabledSubShift => notEnabledSubShift.id === subshift.id ) ) {

        setExtraIds({ 
          subshiftId: subshift.id, 
          tableId: tableId,
          bookableId: '', 
          groupId: '' ,
          type: 'Mesa'
        });

      } else {

        setExtraIds({ 
          subshiftId: subshift.id, 
          tableId: tableId,
          bookableId: '', 
          groupId: '' ,
          type: 'Mesa'
        });

      };

    });

    setExtraTable({ tables: [tableId] });

  };

  const handleAccept = () => {

    // eslint-disable-next-line array-callback-return
    reservableTables.map(( reservableTable ) => {

        const exist = reservableSectors.find( reservableSector => reservableSector.id === reservableTable.id );      
        if ( typeof exist === 'undefined' ) {

          reservableSectors.push( reservableTable );
          const reservableSector = GetReservableSector( reservableTable.id );
          const notEnabledSubShifts = GetReservableSectorSubshifts( reservableSector );

          SetSubShiftExtraTable( notEnabledSubShifts, reservableTable.id );

          SetNewBookableSubshift( 
            subShifts, 
            reservableTable.id, 
            reservableSector, 
            false, 
            setSubshift, 
            'Mesa' 
          );
        };
    });

    const newReservableSectors = [];

    console.log( reservableSectors );

    // eslint-disable-next-line array-callback-return
    reservableSectors.map(( reservableSector ) => {
      const reservableSectorExist = deleteReservableTables.find( deleteReservableTable => deleteReservableTable.id === reservableSector.id );
      if ( !reservableSectorExist ) {
        newReservableSectors.push( reservableSector );
      };
    });

    // console.log( '-----> ', newReservableSectors );

    const compareNames = ( a, b ) => {
      let numberA;
      let numberB;
      if ( a.type === 'Mesa' &&  b.type === 'Mesa' ) {
        numberA = parseInt( a.name.match(/\d+/)[0] ); 
        numberB = parseInt( b.name.match(/\d+/)[0] );
      };
      
      return numberA - numberB; 
    };

    newReservableSectors.sort( compareNames );

    setReservableSectors( newReservableSectors );

    setGroup({ reset: false, reservableSectors: newReservableSectors });
    
    if ( newReservableSectors.find( sector => sector.type === 'Mesa' ) === undefined ) {
      setUpdateTables({ reset: true });
    };

    handleClose();
  }; 

  return (
    <Modal open>
      <Box className={classes.ModalContainer}>
        <Title text="Mesas con excepción" style={modalTitle} />
        <Stack direction="row" spacing="25px" mt={2} mb={2} justifyContent="left">
          <Box width="60%">
            <Box width="100%">
              <FormControl fullWidth>
                <Select
                  onChange={(e) => handleChangeSector(e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  labelId="demo-simple-select-label"
                  IconComponent={ ExpandMoreIcon }
                  sx={ ShiftTableSelectStyles }
                  value={ reservableSector.id }
                  input={ <OutlinedInput /> }
                  MenuProps={ MenuProps }
                  renderValue={(value) => {
                    return GetStyledItem(
                      newReservableSectors.find((rs) => rs.id === value).name, 
                      newReservableSectors.find((rs) => rs.id === value).type
                    );
                  }}
                >
                  {
                    newReservableSectors.map((reservableSector) => (
                      reservableSector.tables.length > 0 ? 
                        <MenuItem key={ reservableSector.id } value={ reservableSector.id } sx={ itemStyle }>
                          { GetStyledItem(reservableSector.name, reservableSector.type) }
                          <Box sx={{ width: '25px' }}></Box>
                        </MenuItem> 
                        : null  
                    ))
                  }                      
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Stack>     
        <Divider sx={ DividerStyles } />
        <Stack style={modalStyles.ItemsHeader}>
          <Box width="70%" display="flex" flexDirection="row" justifyContent="start" marginLeft="60px">
            <Typography className={classes.ItemsTitle}>
                {`Mesas (${reservableSector.tables.length})`}
            </Typography>
          </Box>
          <Box width="30%" display="flex" flexDirection="row" marginRight="60px">
            <Box width="50%">
              <Typography className={classes.ItemsTitle}>
                Mín
              </Typography>
            </Box>
            <Box width="50%">
              <Typography className={classes.ItemsTitle}>
                Máx
              </Typography>  
            </Box>
          </Box>
        </Stack>
        <Box sx={ SettingsTablesStyles }>
          {
            reservableSector.tables.map((table) => (            
                <TableItem 
                  handleChangeActive={ handleChangeActive } 
                  layout={ reservableSector.name } 
                  key={ table.id } 
                  table={ table }
                />
            ))
          }
        </Box>
        <Stack style={modalStyles.ButtonContainer}>
          <Button className={classes.CancelButton} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={classes.ApplyButton} onClick={handleAccept}>
            Aplicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {            
      reservableSectors: state.v3.shifts.reservableSectors,
      subShifts: state.v3.shifts.shiftData.subShifts,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGroup: ({ reset, reservableSectors }) => dispatch( setGroup({ reset, reservableSectors }) ),
    setUpdateTables: ({ reset, sectors }) => dispatch( setUpdateTables({ reset, sectors }) ),
    setExtraIds: ({ subshiftId, tableId, groupId, type, bookableId }) => dispatch( 
      setExtraIds({ subshiftId, tableId, groupId, type, bookableId }) 
      ),
    setReservableSectors: (data) => dispatch(setReservableSectors(data)),
    setExtraTable: ({ tables }) => dispatch( setExtraTable({ tables }) ),
    setSubshift: (data) => dispatch(setSubshift(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeReservableSectorsModal);

