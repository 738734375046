import {
    ADD_MESAYA_SECTOR,
    CHANGE_MESAYA_ACCEPTANCE_RADIUS,
    CHANGE_MESAYA_BIGGROUP,
    CHANGE_MESAYA_BIGGROUP_ENABLE,
    CHANGE_MESAYA_CLOSETIME,
    CHANGE_MESAYA_MAXCAPACITY,
    CHANGE_MESAYA_STAYING_RADIUS,
    CHANGE_MESAYA_TOLERANCE,
    EDIT_MESAYA_CANCEL,
    EDIT_MESAYA_ERROR,
    EDIT_MESAYA_SUCCESS,
    PARTNERS,
    REMOVE_MESAYA_SECTOR,
    RESET_ALL_DATA,
    TOGGLE_MESAYA_LOADING,
    TOGGLE_MESAYA_SECTORS_LOADING,
} from "../../constants";
import {deepEqual} from "../../utils/objectHelpers";

const initState = {
    acceptanceRadius: 1000,
    stayingRadius: 1500,
    bigGroup: 25,
    bigGroupEnable: false,
    maxCapacity: 25,
    closeTime: 30,
    tolerance: 10,
    MYsectors: [],
    sectors: [],
    baseState: {},

    haveChanges: false,
    loading: false,
    sectorsLoading: false,
    error: "",
};

const editReducer = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === PARTNERS &&
                action.payload.ordered &&
                action.payload.ordered.length === 1
            ) {
                let data = action.payload.ordered[0];
                let baseState = {}
                baseState.acceptanceRadius = data["acceptanceRadius"]
                baseState.stayingRadius = data["stayingRadius"]
                baseState.bigGroup = data["MYbigGroup"] === null ? null : isNaN(Number(data["MYbigGroup"])) ? state.bigGroup : Number(data["MYbigGroup"])
                baseState.bigGroupEnable = data["MYbigGroup"] !== null && data["MYbigGroup"] !== undefined
                baseState.maxCapacity = data["MYmaxCapacity"]
                baseState.closeTime = data["MYcloseTime"]
                baseState.tolerance = data["MYtolerance"]
                baseState.MYsectors = data["MYSectors"]
                baseState.sectors = data["sectors"]
                return {
                    ...state,
                    acceptanceRadius: data["acceptanceRadius"],
                    stayingRadius: data["stayingRadius"],
                    bigGroup:
                        data["MYbigGroup"] === null ? null : isNaN(Number(data["MYbigGroup"])) ? state.bigGroup : Number(data["MYbigGroup"]),
                    bigGroupEnable: data["MYbigGroup"] !== null && data["MYbigGroup"] !== undefined,
                    maxCapacity: data["MYmaxCapacity"],
                    closeTime: data["MYcloseTime"], //MinutesTohhss(data['MYcloseTime']),
                    tolerance: data["MYtolerance"],
                    MYsectors: data["MYSectors"],
                    sectors: data["sectors"],
                    baseState: baseState,
                    haveChanges: false,
                };
            }
            return {...state};
        case EDIT_MESAYA_CANCEL:
            let data = action.payload;
            return {
                ...state,
                acceptanceRadius: Number(data["acceptanceRadius"]),
                stayingRadius: Number(data["stayingRadius"]),
                bigGroup: data["MYbigGroup"] === null ? 0 : Number(data["MYbigGroup"]),
                maxCapacity: Number(data["MYmaxCapacity"]),
                closeTime: data["MYcloseTime"],
                tolerance: Number(data["MYtolerance"]),
                MYsectors: data["MYSectors"],
            };
        case EDIT_MESAYA_SUCCESS:
            return {...state, loading: false, error: ""};
        case EDIT_MESAYA_ERROR:
            return {...state, loading: false, error: action.payload};
        case TOGGLE_MESAYA_LOADING:
            return {...state, loading: !state.loading};
        case TOGGLE_MESAYA_SECTORS_LOADING:
            return {...state, sectorsLoading: !state.sectorsLoading,};
        case CHANGE_MESAYA_ACCEPTANCE_RADIUS:
            return {
                ...state,
                acceptanceRadius: action.payload * 100,
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    acceptanceRadius: action.payload * 100,
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case CHANGE_MESAYA_STAYING_RADIUS:
            return {
                ...state,
                stayingRadius: action.payload * 100,
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    stayingRadius: action.payload * 100,
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case CHANGE_MESAYA_BIGGROUP:
            return {
                ...state,
                bigGroup: Number(action.payload),
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    bigGroup: Number(action.payload),
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case CHANGE_MESAYA_BIGGROUP_ENABLE:
            return {
                ...state,
                bigGroupEnable: !state.bigGroupEnable,
                bigGroup: !state.bigGroupEnable ? state.baseState.bigGroup === null ? 25 : state.baseState.bigGroup : null,
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    bigGroup: !state.bigGroupEnable ? state.baseState.bigGroup === null ? 25 : state.baseState.bigGroup : null,
                    bigGroupEnable: !state.bigGroupEnable,
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            }
        case CHANGE_MESAYA_MAXCAPACITY:
            return {
                ...state,
                maxCapacity: Number(action.payload),
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    maxCapacity: Number(action.payload),
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case CHANGE_MESAYA_CLOSETIME:
            return {
                ...state,
                closeTime: action.payload,
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    closeTime: action.payload,
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case CHANGE_MESAYA_TOLERANCE:
            return {
                ...state,
                tolerance: Number(action.payload),
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    tolerance: Number(action.payload),
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case ADD_MESAYA_SECTOR:
            return {
                ...state,
                MYsectors: [...state.MYsectors, action.payload],
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    MYsectors: [...state.MYsectors, action.payload],
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };
        case REMOVE_MESAYA_SECTOR:
            return {
                ...state,
                MYsectors: state.MYsectors.filter((item) => item !== action.payload),
                haveChanges: !deepEqual(state.baseState, {
                    ...state,
                    MYsectors: state.MYsectors.filter((item) => item !== action.payload),
                }, ['baseState', 'haveChanges', 'loading', 'sectorsLoading', 'error'])
            };

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
};

export default editReducer;
