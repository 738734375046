import React from 'react'

// Components
import ConfigSectors from "./ConfigSectors";

// Material Ui
import {makeStyles} from '@material-ui/core';
import {connect} from "react-redux";
import {addSectorToShift, changeShiftOnSector, removeSectorFromShift} from "../../../../../actions";


const useStyles = makeStyles(() => ({
    SectorsContainer: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyContent: 'space-around',
        paddingTop: '1.5rem',
        display: 'grid',
        gap: '20px',
        ['@media (max-width: 1500px)']: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        ['@media (max-width: 1350px)']: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        }
    }
}));

const SectorsConfig = (props) => {

    const classes = useStyles();
    const {sectors, allSectors} = props;

    const {
        addSector,
        removeSector,
        changeSectorOnShift
    } = props

    const onChangeEnable = (sector) => {
        let sectorFind = sectors.find(s => s.name === sector.name);
        if (!sectorFind) {
            const newSector = {
                name: sector.name,
                alias: sector.alias,
                type: "reservas",
                capacity: sector.capacity,
                maxCapacity: sector.maxCapacity ?? sector.capacity,
                minAutomatic: 2,
                maxAutomatic: 10,
            };
            addSector(newSector);
        } else {
            removeSector(sector.name)
        }
    }

    const getSector = (name) => {
        return sectors?.find(s => s.name === name)
    }

    const onChangeSector = (name, minAutomatic, maxAutomatic) => {
        if (maxAutomatic < minAutomatic) {
            changeSectorOnShift({name: name, minAutomatic: maxAutomatic, maxAutomatic: maxAutomatic})
        } else {
            changeSectorOnShift({name: name, minAutomatic: minAutomatic, maxAutomatic: maxAutomatic})
        }
    }

    return (
        <div className={classes.SectorsContainer}>
            {allSectors.map((sector) => (
                <div key={sector.name}>
                    <ConfigSectors
                        sector={getSector(sector.name)}
                        label={sector.alias}
                        enable={getSector(sector.name) !== undefined}

                        onChangeEnable={() => onChangeEnable(sector)}
                        onEdit={onChangeSector}
                    />
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        sectors: state.reservasReducer.shift?.sectors,
        allSectors: state.reservasReducer.allSectors,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addSector: (data) => dispatch(addSectorToShift(data)),
        removeSector: (data) => dispatch(removeSectorFromShift(data)),
        changeSectorOnShift: (data) => dispatch(changeShiftOnSector(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsConfig)
