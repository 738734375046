export const ClientTagTextStyles = {
    border: '1px solid #E7E7E7',
    fontFamily: 'Poppins',
    borderRadius: '24px',
    width: 'max-content',
    padding: '0 .5rem',
    flexDirection: '', 
    fontSize: '.8rem',
    color: '#818181',
}

export const SpecialClientContainer = {
    border: '1px solid #FCBF49',
    borderRadius: '18px',
    alignItems: 'center',
    padding: '.2rem .5rem',
    height: 'max-content',
    display: 'flex',
}

export const SpecialClientText = {
    fontFamily: 'Poppins',
    fontSize: '.8rem',
    color: '#FCBF49',
    fontweight: 500,
}