import React, { useState } from 'react';

// Components
import SelectWithArrows from '../../../../../Plannig/components/SelectWithArrows';

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Material Ui
import { Box, Collapse, Typography } from '@mui/material';
import { BlueSwitch } from '../../../../../GeneralComponents/blueSwitch';
import StartShiftReapeatComponent from './StartShiftReapeatComponent';
import EndShiftRepeatComponent from './EndShiftRepeatComponent';

const ShiftMoreOptions = ({
    handleChangeNunca,
    handleChangeStart,
    getWeekInterval, 
    setValueNunca,
    setStartValue,
    changeRepeat, 
    optionAhora,
    optionNunca,
    valueNunca, 
    startValue, 
    calendar, 
}) => {

    const [weeksNumber, setWeeksNumber] = useState( calendar.repeat !== '' ? getWeekInterval( calendar.repeat ) : 1 );
    const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState( false ); 
    const [active, setActive] = useState( calendar.repeat !== '' );

    const handleAdvancedOptionsClick = () => {
        setAdvancedOptionsOpen( !advancedOptionsOpen );
    };

    const handleChangeActive = (active) => {
        if( active ){
            changeRepeat( '' );
        }
    
        setActive( !active );
    };

    const handleIncreaseWeek = () => {
        if ( weeksNumber >= 1 && weeksNumber < 52 ) {
            setWeeksNumber( weeksNumber + 1 );
        } else {
            return;
        }
    };

    const handleDecreaseWeek = () => {
        if ( weeksNumber > 1 && weeksNumber <= 52 ) {
            setWeeksNumber( weeksNumber - 1 );
        } else {
            return;
        }
    };

    return (
        <Box sx={{ mt: '4rem' }}>
            <Box
                onClick={ () => handleAdvancedOptionsClick() }
                sx={{ 
                    textDecorationLine: 'underline',
                    alignItems: 'center',
                    cursor: 'pointer', 
                    display: 'flex', 
                    width: '180px',
                    gap: '5px',
                }}
            >
                <Typography
                    sx={{ 
                        textDecorationLine: 'underline', 
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontSize: '16px',
                        color: '#0B4762',
                    }}
                >
                    Más opciones
                </Typography>
                {
                    advancedOptionsOpen ? (
                        <ExpandLessIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
                    ) : (
                        <ExpandMoreIcon sx={{ fontSize: "1.5rem", paddingTop: "2px" }} />
                    )
                }
            </Box>
            <Collapse in={ advancedOptionsOpen } >
                <Box
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center', 
                        display: 'flex', 
                        width: '100%',
                        mb: '1.5rem',
                        ml: '-12px',
                        mt: '1rem'
                    }}
                >
                    <BlueSwitch
                        onChange={() => handleChangeActive( active )}
                        checked={ active }
                        color="default"
                    />
                    <Typography 
                        sx={{ 
                            fontFamily: 'Poppins', 
                            color: '#041C32', 
                            fontWeight: 500,
                            mt: '.5rem' 
                        }}
                    >
                        Configurar frecuencia de repetición
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 500, fontFamily: 'Poppins' }}>
                        Repetir cada:
                    </Typography>
                    <SelectWithArrows
                        handleDecrease={ handleDecreaseWeek }
                        handleIncrease={ handleIncreaseWeek }
                        amount={ weeksNumber }
                        singularText='semana'
                        pluralText='semanas'
                        disabled={ !active }
                    />
                </Box>
                <StartShiftReapeatComponent 
                    handleChange={ handleChangeStart }
                    optionAhora={ optionAhora } 
                    setValue={ setStartValue } 
                    value={ startValue } 
                />
                <EndShiftRepeatComponent 
                    handleChangeNunca={ handleChangeNunca } 
                    setValueNunca={ setValueNunca }
                    optionNunca={ optionNunca } 
                    valueNunca={ valueNunca } 
                />
            </Collapse>
        </Box>
    )
}

export default ShiftMoreOptions;