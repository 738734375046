import React from "react";
import { Box } from "@mui/material";
import { Wizard } from "../../../components/V3/Shifts";

const ShiftsCreationPage = () => {
  return (
    <Box mr="5rem" height="80vh" style={{backgroundColor: "#FCFCFC"}}>
      <Wizard />
    </Box>
  );
};

export default ShiftsCreationPage;
