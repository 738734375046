export const CHANGE_MESAYA_ACCEPTANCE_RADIUS = "CHANGE_MESAYA_ACCEPTANCE_RADIUS";
export const CHANGE_MESAYA_STAYING_RADIUS = "CHANGE_MESAYA_STAYING_RADIUS";
export const CHANGE_MESAYA_BIGGROUP = "CHANGE_MESAYA_BIGGROUP";
export const CHANGE_MESAYA_BIGGROUP_ENABLE = "CHANGE_MESAYA_BIGGROUP_ENABLE";
export const CHANGE_MESAYA_MAXCAPACITY = "CHANGE_MESAYA_MAXCAPACITY";
export const CHANGE_MESAYA_CLOSETIME = "CHANGE_MESAYA_CLOSETIME";
export const CHANGE_MESAYA_TOLERANCE = "CHANGE_MESAYA_TOLERANCE";

export const EDIT_MESAYA_SUCCESS = "EDIT_MESAYA_SUCCESS";
export const EDIT_MESAYA_ERROR = "EDIT_MESAYA_ERROR";
export const EDIT_MESAYA_CANCEL = "EDIT_MESAYA_CANCEL";
export const TOGGLE_MESAYA_LOADING = "TOGGLE_MESAYA_LOADING";

export const ADD_MESAYA_SECTOR = "ADD_MESAYA_SECTOR";
export const REMOVE_MESAYA_SECTOR = "REMOVE_MESAYA_SECTOR";

export const TOGGLE_MESAYA_SECTORS_LOADING = "TOGGLE_MESAYA_SECTORS_LOADING";