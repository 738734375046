import React from 'react'

const OkGreenIcon = (props) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 25 25">
                <path id="check-circle" d="M20.9,9.863a1.014,1.014,0,0,0-.293-.749L19.124,7.65a1.023,1.023,0,0,0-1.465,0l-6.64,6.624L7.34,10.6a1.023,1.023,0,0,0-1.465,0L4.394,12.06a1.014,1.014,0,0,0-.293.749.994.994,0,0,0,.293.733l5.892,5.892a1,1,0,0,0,.733.309,1.02,1.02,0,0,0,.749-.309L20.6,10.6a.994.994,0,0,0,.293-.733ZM25,12.5a12.229,12.229,0,0,1-1.676,6.274,12.442,12.442,0,0,1-4.549,4.549A12.229,12.229,0,0,1,12.5,25a12.229,12.229,0,0,1-6.274-1.676,12.442,12.442,0,0,1-4.549-4.549A12.229,12.229,0,0,1,0,12.5,12.229,12.229,0,0,1,1.676,6.226,12.442,12.442,0,0,1,6.226,1.676,12.229,12.229,0,0,1,12.5,0a12.229,12.229,0,0,1,6.274,1.676,12.442,12.442,0,0,1,4.549,4.549A12.229,12.229,0,0,1,25,12.5Z" transform="translate(0 0)" fill="#15d615"/>
            </svg>
        </div>
    )
}

export default OkGreenIcon
