import React from 'react';

const DeleteOptionalIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.71647 2.51667C6.71647 1.84921 6.98162 1.20908 7.45359 0.737114C7.92555 0.265148 8.56568 0 9.23314 0H16.7831C17.4506 0 18.0907 0.265148 18.5627 0.737114C19.0347 1.20908 19.2998 1.84921 19.2998 2.51667V5.03333H24.3331C24.6669 5.03333 24.9869 5.16591 25.2229 5.40189C25.4589 5.63787 25.5915 5.95794 25.5915 6.29167C25.5915 6.6254 25.4589 6.94546 25.2229 7.18144C24.9869 7.41743 24.6669 7.55 24.3331 7.55H22.988L21.897 22.8287C21.8518 23.4636 21.5677 24.0578 21.1019 24.4917C20.6361 24.9255 20.0232 25.1667 19.3866 25.1667H6.62839C5.99185 25.1667 5.37895 24.9255 4.91313 24.4917C4.44732 24.0578 4.16321 23.4636 4.11801 22.8287L3.02955 7.55H1.68314C1.34941 7.55 1.02935 7.41743 0.793362 7.18144C0.557379 6.94546 0.424805 6.6254 0.424805 6.29167C0.424805 5.95794 0.557379 5.63787 0.793362 5.40189C1.02935 5.16591 1.34941 5.03333 1.68314 5.03333H6.71647V2.51667ZM9.23314 5.03333H16.7831V2.51667H9.23314V5.03333ZM5.55125 7.55L6.62965 22.65H19.3879L20.4663 7.55H5.55125ZM10.4915 10.0667C10.8252 10.0667 11.1453 10.1992 11.3812 10.4352C11.6172 10.6712 11.7498 10.9913 11.7498 11.325V18.875C11.7498 19.2087 11.6172 19.5288 11.3812 19.7648C11.1453 20.0008 10.8252 20.1333 10.4915 20.1333C10.1577 20.1333 9.83768 20.0008 9.6017 19.7648C9.36571 19.5288 9.23314 19.2087 9.23314 18.875V11.325C9.23314 10.9913 9.36571 10.6712 9.6017 10.4352C9.83768 10.1992 10.1577 10.0667 10.4915 10.0667ZM15.5248 10.0667C15.8585 10.0667 16.1786 10.1992 16.4146 10.4352C16.6506 10.6712 16.7831 10.9913 16.7831 11.325V18.875C16.7831 19.2087 16.6506 19.5288 16.4146 19.7648C16.1786 20.0008 15.8585 20.1333 15.5248 20.1333C15.1911 20.1333 14.871 20.0008 14.635 19.7648C14.399 19.5288 14.2665 19.2087 14.2665 18.875V11.325C14.2665 10.9913 14.399 10.6712 14.635 10.4352C14.871 10.1992 15.1911 10.0667 15.5248 10.0667Z" fill="#041C32"/>
        </svg>
    )
}

export default DeleteOptionalIcon;