import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowDownPrimary';
import ArrowUpPrimary from '../../SvgComponents/icons/IconsPrimary/ArrowUpPrimary';

const useStyles = makeStyles(() => ({
    Dropdown: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        display: 'inline-block',
        width: '180px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        position: 'absolute',
        zIndex: '20',
        marginBottom: '20px'
    },
    DropdownBtn: {
        padding: '5px 0px 0px 10px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    DropdownContent: {
        padding: '0px 10px 10px 10px',
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '0px 0px 20px 20px',
        boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.2)',
        borderTop: 'none',
        transitionDelay: '.9s'
    },
    DropdownItem: {
        padding: '3px 0px',
        cursor: 'pointer',
        transition: 'all .3s',
        "&:hover": {
            backgroundColor: '#f4f4f4'
    },
    ArrowUp: {
        paddingRight: '20px'
    }
    }
}));

const Dropdown = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState('30px');
    const [display, setDisplay] = useState('none');
    const [rotate, setRotate] = useState('rotate(0deg)');

    const handleContent = () => {
        setOpen(!open);
        setHeight('auto');
        setDisplay('block');
        setRotate('rotate(180deg)');

        if (height === "auto" || display === "block" || rotate === 'rotate(180deg)') {
            setHeight('30px');
            setDisplay('none');
            setRotate('rotate(0deg)');
        }
    }

    const {value, options} = props;

    const {onChange} = props;

    const handleSelect = (value) => {
        handleContent()
        onChange(value);
    }

    const getValue = () => {
        return options?.find(option => option.value === value)?.name
    }

    return (
        <div className={classes.Dropdown} style={{height: height}}>
            <div className={classes.DropdownBtn}>
                {getValue()}
                <div onClick={() => (handleContent())}>
                    {open ?
                        <div style={{paddingRight: '18px'}}>
                            <ArrowUpPrimary width={"20px"} height={"20px"}/>
                        </div>
                        :
                        <div style={{paddingRight: '5px'}}>
                            <ArrowDownPrimary width={"20px"} height={"20px"}/>
                        </div>
                    }
                </div>
            </div>
            <div className={classes.DropdownContent} style={{display: display}}>
                {options?.map(option => <div key={option.id} onClick={() => handleSelect(option.value)}
                                             className={classes.DropdownItem}>{option.name}</div>)}
            </div>
        </div>
    )
}

export default Dropdown
