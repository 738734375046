import React, { useState } from "react";

// Material Ui
import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";

const useStyles = makeStyles(() => ({
  Root: {
    width: "100%",
  },
  RootInput: {
    border: "1px solid #E7E7E7",
    backgroundColor: "white",
    borderRadius: "8px",
    paddingTop: "8px",
  },
  name: {
    fontFamily: "Poppins, sans-serif",
    margin: "0px 0px 2px 20px",
    fontSize: "12px",
    color: "#848282",
  },
  characters: {
    fontFamily: "Poppins, sans-serif",
    margin: "5px 5px 0px 0px",
    fontSize: "12px",
    color: "#848282",
  },
  TextAreaStyles: {
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "white",
    padding: "0px 5px",
    overflow: "hidden",
    fontWeight: "500",
    fontSize: ".9rem",
    outline: "none",
    resize: "none",
    border: "none",
    height: "100%",
    width: "100%",
  },
  TextAreaStyles2: {
    width: "100%",
    border: "1px solid #E7E7E7",
    backgroundColor: "white",
    borderRadius: "8px",
    paddingTop: "8px",
    '& .MuiOutlinedInput-root': {
        borderColor: '#D0D0D0',
        backgroundColor: 'white',
        '&:hover fieldset': {
            borderColor: '#0B4762',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0B4762',
            color: '#0B4762',
        },
        '&.Mui-focused inputLabel': {
            color: '#0B4762',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#818181',
        '&.Mui-focused': {
          color: '#818181',
        },
    }
  }
}));

const TextArea = (props) => {
  const classes = useStyles();

  const {
    placeholder = "",
    characters,
    tabIndex,
    disabled,
    subLabel,
    label,
    text,
  } = props;

  const { onChange, onFocus, onBlur } = props;

  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);

    if (typeof onFocus !== 'undefined')    
      onFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    
    if (typeof onBlur !== 'undefined')    
      onBlur();
  };

  return (
    <div className={classes.Root}>
        <TextField
          InputLabelProps={{ shrink: true }}
          id="outlined-multiline-static"
          placeholder={ placeholder }
          onFocus={ handleFocus }
          tabIndex={ tabIndex }
          onBlur={ handleBlur }
          disabled={ disabled }
          onChange={ onChange }
          label={ label }
          value={ text }
          height='78px'
          multiline
          rows={3}
          sx={{ 
            fontFamily: 'Poppins', 
            width: '100%',
            mt: '1rem',
            '.MuiOutlinedInput-root': {
              fontFamily: 'Poppins',
              borderRadius: '8px',
              fontSize: '1rem',
              fontWeight: 500, 
            },
            '.MuiInputLabel-root': {
              fontFamily: 'Poppins',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              fontFamily: 'Poppins',
              color: '#041C32'
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0B4762 !important',
              borderWidth: '1px'
            },
          }}
        />
      <div style={{ textAlign: "right" }}>
        <p className={classes.characters}>
          {subLabel
            ? subLabel
            : characters
            ? `Máximo ${characters} caracteres`
            : ""}
        </p>
      </div>
    </div>
  );
};

export default TextArea;
