import React from 'react';

// Components
import SectorsDetails from './SectorsDetails';

// Material Ui
import {Grid, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    Root: {
        fontFamily: 'Poppins, sans-serif',
        paddingLeft: '.8rem',
    },
    Title: {
        margin: '0px 0px 10px 0px',
        fontSize: '1.5rem',
        fontWeight: '500',
        color: '#041C32'
    }
}));

const DetailsDown = (props) => {

    const classes = useStyles();

    const {sectors} = props

    return (
        <div className={classes.Root}>
            <p className={classes.Title}>Sectores</p>
            <Grid container>
                <Grid container item xs={12} spacing={3}>
                    {sectors?.map(sector => (
                        <Grid key={sector.name} item md={4}>
                            <SectorsDetails
                                name={sector.alias}
                                minAutomatic={sector?.minAutomatic}
                                maxAutomatic={sector?.maxAutomatic}
                            />
                        </Grid>

                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailsDown
