import {Grid} from "@material-ui/core";
import ButtonWithIcon from "../../NewComponents/ButtonWithIcon/ButtonWithIcon";
import {SECTOR_MODE} from "../../../constants";
import {SECTOR_TYPE, typeToString} from "../../../constants/sectorsTypes";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Sectors from "./Sectors";
import React from "react";
import {changeSectorMode} from "../../../actions";
import {connect} from "react-redux";
import {grey} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/core/styles";
import {v4 as uuidv4} from 'uuid';

const useStyles = makeStyles(() => ({
    TitleContainer: {
        justifyContent: 'space-between',
        padding: '0rem 2rem 1rem .5rem',
        display: 'flex',
    },
    newSectorCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 150,
        height: "100%",
        minWidth: 180,
    },
    newSectorButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    newSectorIcon: {
        fontSize: "5em",
        color: grey,
    },
}));

const SectorView = (props) => {
    const classes = useStyles();
    const {sectors} = props;

    const {changeSectorMode} = props;

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} style={{zIndex: 1}}>
                <div className={classes.TitleContainer}>
                    <h1 style={{margin: '0px', fontWeight: '600', fontSize: '1.6rem'}}>Sectores</h1>
                    <div style={{zIndex: 10}}>
                        <ButtonWithIcon
                            text={"Agregar sector"}
                            onClick={() => changeSectorMode({
                                mode: SECTOR_MODE.NEW, sector: {
                                    name: uuidv4(),
                                    alias: "",
                                    tables: [],
                                    capacity: 0,
                                    maxCapacity: 0,
                                    type: SECTOR_TYPE.SALON,
                                    priority: 0,
                                }
                            })}
                            icon={<AddCircleOutlineOutlinedIcon/>}
                        />
                    </div>
                </div>
                <Grid container item xs={12} spacing={1} style={{marginBottom: '30px'}}>
                    {sectors
                        ? sectors.map(sector => (
                            <Grid item key={sector["name"]}>
                                <Sectors
                                    name={sector.alias ?? sector.name}
                                    priority={sector["priority"]}
                                    capacity={sector["maxCapacity"]}
                                    type={typeToString(sector["type"])}
                                    maxGroup={sector["maxGroup"]}
                                    tables={sector["tables"].length}
                                    onClick={() => changeSectorMode({mode: SECTOR_MODE.EDIT, sector: sector})}
                                />
                            </Grid>
                        ))
                        : null
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        sectors: state.sectorsReducer.sectors.sectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSectorMode: ({mode, sector}) => dispatch(changeSectorMode({mode, sector})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorView);

