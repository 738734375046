import React from 'react';

// Material Ui
import { Box, Skeleton, Typography } from '@mui/material';

// Styles
import { 
    OptionalButtonsContainerStyles, 
    OptionalHomeContainerStyles, 
    ButtonsSkeletonStyles, 
    OptionalTextsStyles, 
} from '../Styles/OptionalStyles';

const OptionalSkeleton = () => {
    return (
        <Box sx={ OptionalHomeContainerStyles }>
            <Box sx={ OptionalTextsStyles }>
                <Typography variant='subtitle1' sx={{ width: '90%' }} >
                    <Skeleton animation="wave" />
                </Typography>
                <Typography variant='string' sx={{ width: '70%' }} >
                    <Skeleton animation="wave" />
                </Typography>
            </Box>
            <Box sx={ OptionalButtonsContainerStyles }>
                <Skeleton variant='rectangular' sx={ ButtonsSkeletonStyles }/>
                <Skeleton variant='rectangular' sx={ ButtonsSkeletonStyles }/>
            </Box>
        </Box>
    )
}

export default OptionalSkeleton