import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@mui/material/styles';
import {
    TablePagination,
    TableContainer,
    TableFooter,
    IconButton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    Paper,
    Box,
} from '@mui/material';

// Icons
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

import DinnersClubIcons from '../../SvgComponents/icons/PaymentsOptions/DinnersClubIcons';
import VisaCreditoIcon from '../../SvgComponents/icons/PaymentsOptions/VisaCreditoIcon';
import MercadopagoIcon from '../../SvgComponents/icons/PaymentsOptions/MercadopagoIcon';
import Americanexpress from '../../SvgComponents/icons/PaymentsOptions/Americanexpress';
import VisaDebitoIcon from '../../SvgComponents/icons/PaymentsOptions/VisaDebitoIcon';
import MastercardIcon from '../../SvgComponents/icons/PaymentsOptions/MastercardIcon';
import CordobesaIcon from '../../SvgComponents/icons/PaymentsOptions/CordobesaIcon';
import ArgencardIcon from '../../SvgComponents/icons/PaymentsOptions/ArgencardIcon';
import FalabellaIcon from '../../SvgComponents/icons/PaymentsOptions/FalabellaIcon';
import CencosudIcon from '../../SvgComponents/icons/PaymentsOptions/CencosudIcon';
import DiscoverIcon from '../../SvgComponents/icons/PaymentsOptions/DiscoverIcon';
import MaestroIcon from '../../SvgComponents/icons/PaymentsOptions/MaestroIcon';
import NaranjaIcon from '../../SvgComponents/icons/PaymentsOptions/NaranjaIcon';
import CuentaDNI from '../../SvgComponents/icons/PaymentsOptions/CuentaDNIIcon';
import BitcoinIcon from '../../SvgComponents/icons/PaymentsOptions/BitcoinIcon';
import NativaIcon from '../../SvgComponents/icons/PaymentsOptions/NativaIcon';
import ModoIcons from '../../SvgComponents/icons/PaymentsOptions/ModoIcons';
import CabalIcon from '../../SvgComponents/icons/PaymentsOptions/CabalIcon';
import JcbIcon from '../../SvgComponents/icons/PaymentsOptions/JcbIcon';
import { providerPaymentIdsList } from '../Cards';
import moment from 'moment';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
    HeaderTitle: {
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: '600 !important',
        color: '#041C32 !important',
        fontSize: '1.1rem !important'
    },
    DataTable: {
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: '500 !important',
        fontSize: '1rem !important',
        color: '#808080 !important',
    },
    ClientButton: {
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '.4rem 1.5rem',
        display: 'flex',
        margin: 'auto',
        width: '95%',
        color: '#FFF !important',
        backgroundColor: '#0B4762 !important',
    },
    StateBooking: {
        borderRadius: '8px',
        fontWeight: '500',
        maxWidth: '125px',
        padding: '.4rem',
        margin: 'auto',
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3rem',
        width: '3rem',
        backgroundColor: '#0B4762',
        borderRadius: '10px',
        padding: '5px',
    },
}));

const headers = [
    { label: 'Período de facturación', id: 'billing_period', numeric: false },
    // { label: 'Plan', id: 'plan', numeric: false },
    { label: 'Total facturado', id: 'total', numeric: true },
    { label: 'Método de pago', id: 'payment_method', numeric: false },
    // { label: 'Estado del pago', id: 'payment_state', numeric: false },
    { label: 'Factura', id: 'url', numeric: false },
]

const paymentStatusToSpanish = (status = '') => {
    switch (status) {
        case 'pending':
            return {
                label: 'Pendiente',
                background: '#F0EFEF',
                color: '#848282'
            };
        case 'approved':
            return {
                label: 'Aprobado',
                background: '#DAFFF2',
                color: '#35C18F'
            };
        case 'rejected':
            return {
                label: 'Rechazado',
                background: '#FFD5D7',
                color: '#EB252E'
            };
        default:
            return {
                label: status,
                background: '#F0EFEF',
                color: '#848282'
            };
    }
}

const createData = (invoice) => {
    const serviceFrom = moment(invoice.serviceFrom).format('DD/MM/YYYY');
    const serviceTo = moment(invoice.serviceTo).format('DD/MM/YYYY');
    const billingPeriod = `${serviceFrom} - ${serviceTo}`;
    return {
        billing_period: billingPeriod,
        total: Math.round(invoice.total).toFixed(0),
        url: invoice.url,
        payment_method: 'account_money', //TODO: get payment method
    }
}

const TableInvoices = (props) => {

    const classes = useStyles();
    const {invoices = []} = props;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const sortedInvoices = invoices.sort((a, b) => {
            return moment(b.serviceTo).diff(moment(a.serviceTo));
        });
        setRows(sortedInvoices.map(createData));
    }, [invoices]);


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead sx={{ backgroundColor: '#FBFBFC' }}>
                        <TableRow>
                            {/* <TableCell align="center" className={classes.HeaderTitle}>Fecha</TableCell> */}
                            {
                                headers.map((header, index) => (
                                    <TableCell key={index} align={'center'} className={classes.HeaderTitle}>{header.label}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row, idx) => (
                            <TableRow key={idx}>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    {row.billing_period}
                                </TableCell>
                                {/* <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        padding={1}
                                        sx={{
                                            backgroundColor: '#0B4762',
                                            color: '#FFF',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {row.plan}
                                    </Box>
                                </TableCell> */}
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    ${row.total}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        gap={'10px'}
                                    >
                                        <Box className={classes.iconBox}>
                                            {providerPaymentIdsList[row.payment_method].icon}
                                        </Box>
                                        {providerPaymentIdsList[row.payment_method].name}
                                    </Box>
                                </TableCell>
                                {/* <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        padding={1}
                                        sx={{
                                            backgroundColor: paymentStatusToSpanish(row.payment_state).background,
                                            color: paymentStatusToSpanish(row.payment_state).color,
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {paymentStatusToSpanish(row.payment_state).label}
                                    </Box>
                                </TableCell> */}
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <Box display={'flex'} alignItems='center' justifyContent={'center'}>
                                        <FileDownloadOutlinedIcon 
                                            color={'#808080'} 
                                            sx={{ 
                                                cursor: row.url ? 'pointer' : 'not-allowed',
                                                fontSize: '28px' 
                                            }} 
                                            onClick={() => {
                                                if (row.url) {
                                                    window.open(row.url, '_blank');
                                                }
                                            }}
                                            />
                                    </Box>

                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={5}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TableInvoices;
