import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

// Styles
import { SelectLabelStyles, ShiftSelectStyles } from '../../../../Styles';
import { singularPlural } from '../../utils/functions';

const ShiftGeneralSelect = ({ optionsArray, value, handleChange, error = false, label }) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                marginTop: '.2rem',
                width: '15rem',
            },
        },
    };

    return (
        <FormControl error={ error } sx={{ width: '100%' }}>
            <InputLabel id="shift-select-label" sx={ SelectLabelStyles } >
                { label }
            </InputLabel>
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                IconComponent={ ExpandMoreIcon }
                labelId="shift-select-label"
                placeholder='Seleccionar'
                sx={ ShiftSelectStyles }
                MenuProps={ MenuProps }
                id="shift-select"
                label={ label }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    (label !== 'Anticipo máximo' && label !== 'Grupo mínimo' && label !== 'Grupo máximo') ?
                        optionsArray.map(( item ) =>
                            <MenuItem key={ item.id } value={ item.id } sx={{ padding: '.5rem', height: '2.5rem' }}>
                                <ListItemText 
                                    primary={ item.val } 
                                    sx={{ 
                                        '& .MuiTypography-root': { 
                                            fontFamily: 'Poppins !important', 
                                            fontSize: '.9rem', 
                                            color: "#041C32",
                                            fontWeight: 500
                                        } 
                                    }}
                                /> 
                            </MenuItem>
                        )
                    :
                        ( label === 'Grupo mínimo' || label === 'Grupo máximo' ) ?
                            optionsArray.map(( option ) =>
                                <MenuItem 
                                    value={ option.value }
                                    key={ option.option } 
                                >
                                    <ListItemText 
                                        primary={ `${option.option} ${singularPlural(option.option, 'persona', 'personas')}` } 
                                        sx={{ 
                                            '& .MuiTypography-root': { 
                                                fontFamily: 'Poppins !important', 
                                                fontSize: '.9rem', 
                                                color: "#041C32",
                                                fontWeight: 500
                                            } 
                                        }}
                                    /> 
                                </MenuItem>
                            )
                        :
                            optionsArray.map(( option ) =>
                                <MenuItem 
                                    value={option.value}
                                    key={option.value} 
                                >
                                    <ListItemText 
                                        primary={`${ option.option } ${ singularPlural( option.option, 'día', 'días' ) }`}
                                        sx={{ 
                                            '& .MuiTypography-root': { 
                                                fontFamily: 'Poppins !important', 
                                                fontSize: '.9rem', 
                                                color: "#041C32",
                                                fontWeight: 500
                                            } 
                                        }}
                                    /> 
                                </MenuItem>
                            )
                }
            </Select>
        </FormControl>
    )
};

export default ShiftGeneralSelect;