import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Radio } from "@mui/material";

const BlueRadioButton = withStyles({
  root: {
    color: "#0B4762 !important",
    "&$checked": {
      color: "#0B4762 !important",
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

export default BlueRadioButton;
