import {makeStyles} from "@material-ui/core";
import Requirement from "./Requirement";
import React from "react";
import SearchImgButton from "../NewComponents/Buttons/Search Image/SearchImgButton";

const useStyle = makeStyles(() => ({
    root: {
        justifyContent: 'start',
        backgroundColor: 'white',
        border: '1px solid #DBD7DC',
        borderRadius: '10px',
        paddingLeft: '48px',
        paddingTop: '2rem',
        height: '100%',
        fontFamily: 'Poppins, sans-serif',
        position: 'relative'
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: '500',
        color: '#041C32',
        margin: '0px',
    },
    textContainer: {
        padding: '1rem 1.5rem 0 1rem',
        '@media (max-width: 1100px)': {
            padding: '0 1rem 0 1rem',
        },
        margin: '0px',
    },
    text: {
        fontWeight: '500',
        fontSize: '1rem',
        color: '#041C32',
        width: '80%'
    },
    RequirementContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        margin: '0 0 auto 0',
        display: 'flex',
        width: '100%',
        position: 'relative'
    },
    AddButton: {
        position: 'absolute',
        bottom: '-5rem',
        left: '0',
    }
}));

const Requirements = (props) => {
    const classes = useStyle();

    const {onChangePhoto} = props;

    return (
        <div className={classes.root}>
            <h5 className={classes.title}>Requerimientos</h5>
            <p className={classes.text}>La foto de perfil del establecimiento
                será moderada por el equipo de Woki, para asegurarse de que cumpla con los siguientes
                parametros :</p>
            <div className={classes.RequirementContainer}>
                <Requirement fontSize='1rem' text={"Resolución mínima permitida 800x450 px"}/>
                <Requirement fontSize='1rem' text={"Resolución de la imagen"}/>
                <Requirement fontSize='1rem' text={"No deben mostrarse bebidas alcohólicas de manera explícita"}/>
                <Requirement fontSize='1rem' text={"No se admitirán fotos con material gráfico, logos o texto."}/>
                <Requirement fontSize='1rem' text={"Se admite formatos .jpeg o .png"}/>
                <div className={classes.AddButton}>
                    <SearchImgButton onClick={(e) => onChangePhoto(e.target.files[0])}/>
                </div>
            </div>
        </div>
    );
}

export default Requirements;
