import React from 'react';

const EditOptionalIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4783 5.2157L20.1243 0.861657C19.556 0.327883 18.8114 0.0216106 18.032 0.00110084C17.2526 -0.0194089 16.4929 0.247275 15.8974 0.750423L1.59575 15.052C1.08211 15.57 0.762288 16.2489 0.689978 16.9748L0.00667878 23.6012C-0.0147276 23.834 0.0154732 24.0686 0.0951282 24.2883C0.174783 24.5081 0.301931 24.7075 0.467509 24.8725C0.615991 25.0198 0.792086 25.1363 0.985694 25.2154C1.1793 25.2944 1.38662 25.3345 1.59575 25.3333H1.73876L8.36518 24.7295C9.09106 24.6572 9.76997 24.3373 10.288 23.8237L24.5896 9.52208C25.1447 8.93566 25.4446 8.15311 25.4238 7.34591C25.4029 6.5387 25.063 5.77268 24.4783 5.2157V5.2157ZM8.07915 21.5513L3.31194 21.9963L3.74099 17.2291L12.7192 8.36206L17.0097 12.6525L8.07915 21.5513ZM19.0755 10.5232L14.8168 6.26449L17.9155 3.08635L22.2536 7.42451L19.0755 10.5232Z" fill="#041C32"/>
        </svg>
    )
}

export default EditOptionalIcon;