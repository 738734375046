import React from 'react'
import {URL_WEB} from "../../../../constants/config";

const WokiWeb = () => {

    const handleOnClick = () => {
        window.open(URL_WEB, '_blank')
    }

    return (
        <div onClick={handleOnClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 145 145">
                <defs>
                    <linearGradient id="linear-gradient-ww" x1="0.518" y1="0.556" x2="0.5" y2="1"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#fff"/>
                        <stop offset="1" stopColor="#d0d0d0"/>
                    </linearGradient>
                    <filter id="Elipse_353" x="0" y="0" width="145" height="145" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="5" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g style={{cursor: 'pointer'}} id="Grupo_1705" data-name="Grupo 1705" transform="translate(5 5)">
                    <g transform="matrix(1, 0, 0, 1, -5, -5)" filter="url(#Elipse_353)">
                        <circle id="Elipse_353-2" data-name="Elipse 353" cx="57.5" cy="57.5" r="57.5"
                                transform="translate(15 15)" fill="url(#linear-gradient-ww)"/>
                    </g>
                    <text id="Woki_web" data-name="Woki
                web" transform="translate(67 63)" fill="#0076ff" fontSize="20" fontFamily="Montserrat-Bold, Montserrat"
                          fontWeight="700">
                        <tspan x="-27.2" y="0">Woki</tspan>
                        <tspan x="-22.43" y="24">web</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

export default WokiWeb
