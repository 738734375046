import React from 'react';

// Components
import SubturnosDetails from './SubturnosDetails';

// Material Ui
import {Grid, makeStyles} from '@material-ui/core';

// Utils
import {dayToChar} from "../../../../../utils/formatters";

const useStyles = makeStyles(() => ({
    Root: {
        fontFamily: 'Poppins, sans-serif',
        paddingLeft: '.8rem'
    },
    DaysTitle: {
        margin: '0px 0px 4px 0px',
        fontSize: '1.5rem',
        fontWeight: '500',
        color: '#041C32'
    }
}));

const DetailsMiddle = (props) => {
    const classes = useStyles();

    const {days, subShifts} = props

    return (
        <div className={classes.Root}>
            <Grid container>
                <Grid item xs={3}>
                    <p className={classes.DaysTitle}>Días de la semana</p>
                </Grid>
                <Grid item xs={9} style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {days?.map((day, index) =>
                        (<div
                            key={index}
                            style={{
                                border: day ? '1px solid #0B4762' : '1px solid #B2B2B2',
                                backgroundColor: day ? "#0B4762" : '#F0EFEF',
                                fontFamily: 'Poppins, sans-serif',
                                color: day ? 'white' : '#B2B2B2',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                fontWeight: '500',
                                display: 'flex',
                                margin: '.2rem',
                                height: '25px',
                                width: '25px',
                            }}
                        >
                            {dayToChar(index)}
                        </div>))}
                </Grid>
            </Grid>
            <Grid container style={{paddingTop: '1rem'}}>
                <Grid item xs={3}>
                    <p className={classes.DaysTitle}>Subturnos</p>
                </Grid>
                <Grid container xs={9}>
                    {subShifts?.map((subShift, index) => (
                        <Grid key={index} item md={2} style={{margin: '0 1.5rem 0 0'}}>
                            <SubturnosDetails
                                nro={index + 1}
                                openTime={subShift.from}
                                closeTime={subShift.to}
                                fix={subShift.type}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailsMiddle
