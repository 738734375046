import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import TutorialsItem from './TutorialsItem';
// import AccordionHelp from './AccordionHelp';

const useStyles = makeStyles(() => ({
    MainContainer: {
        fontFamily: 'Poppins, sans-serif', 
        paddingRight: '5rem',
    },
    Title: {
        margin: '0 0 1.5rem 0',
        fontSize: '1.6rem',
        fontWeight: '600',
        color: '#041C32'
    },
    TutorialsContainer: {
        paddingBottom: '2rem',
        display: 'flex',
        gap: '20px',
    }
}));

const Help = () => {

    const classes = useStyles();

    return (
        <main className={classes.MainContainer}>
            <h1 className={classes.Title}>Tutoriales</h1>
            <section className={classes.TutorialsContainer}>
                <TutorialsItem
                    url={'https://www.google.com'}
                    text={'Introducción al Administrador Web'}
                />
                <TutorialsItem
                    url={'https://www.google.com'}
                    text={'Introducción a Woki Partners App'}
                />
            </section>
            <h1 className={classes.Title}>Preguntas frecuentes</h1>
            {/* <section className={classes.QuestionsContainer}>
                <AccordionHelp
                    question={'¿Me puedo dar de baja en cualquier momento?'}
                    answer={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et scelerisque pharetra nec sit. Lacinia at ullamcorper purus consequat, at. Non sem mauris vulputate eu diam habitant id risus.'}
                />
                <AccordionHelp
                    question={'¿Me puedo dar de baja en cualquier momento?'}
                    answer={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et scelerisque pharetra nec sit. Lacinia at ullamcorper purus consequat, at. Non sem mauris vulputate eu diam habitant id risus.'}
                />
                <AccordionHelp
                    question={'¿Me puedo dar de baja en cualquier momento?'}
                    answer={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et scelerisque pharetra nec sit. Lacinia at ullamcorper purus consequat, at. Non sem mauris vulputate eu diam habitant id risus.'}
                />
            </section> */}
        </main>
    )
}

export default Help;