import {CHANGE_FILTER_DATE, FILTER_QUEUE_ITEMS} from "../../constants/mesaYaClientsConstants";
import {QUEUE_ITEMS} from "../../constants";


export const changeFilterDate = (data) => ({
    type: CHANGE_FILTER_DATE,
    payload: data,
})

export const changeFilters = (search, filters) => ({
    type: FILTER_QUEUE_ITEMS,
    payload: {search: search, filters: filters},
})

/*export const getResponseTime = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestoreReact = getFirestore();
        const { myClientsReducer, firebase } = getState();
        const { profile } = firebase;
        const { date } = myClientsReducer;



        switch (date) {
            case DATE_FILTER.Hoy:
                const now = moment().set({
                    hour: 0,
                    minute: 0,
                    seconds: 0,
                    millisecond: 0,
                });
                const yesterday = moment().set({
                    day: now.date() - 1,
                    hour: 0,
                    minute: 0,
                    seconds: 0,
                    millisecond: 0,
                });
                const value = await calculatePercentejeResponseTime(now, yesterday, firestoreReact, profile.partner);
                break;
            case DATE_FILTER.Ayer:
                break;
            case DATE_FILTER["Última semana"]:
                break;
            case DATE_FILTER["Mes corriente"]:
                break;
            case DATE_FILTER["Último trimestre"]:
                break;
            case DATE_FILTER["Año corriente"]:
                break;
            default:
                break;
        }
    }
}*/

/**
 * Calcula porcentaje de diferencia del tiempo de respuesta promedio
 * @param {Moment} actualDate Fecha actual que se esta viendo (en caso de ser un mes o mas, la fecha de inicio)
 * @param {Moment} prevDate Fecha de comparacion (en caso de ser un mes o mas, la fecha de inicio)
 * @param {String} partnerId Id del partner
 */
const calculatePercentejeResponseTime = async (actualDate, prevDate, firestore, partnerId) => {
    const actualItems = await firestore.collection(QUEUE_ITEMS).where('partnerId', '==', partnerId).where('createdTimestamp', '>=', actualDate.toDate()).get();
    const prevItems = await firestore.collection(QUEUE_ITEMS).where('partnerId', '==', partnerId).where('createdTimestamp', '>=', prevDate.toDate()).where('createdTimestamp', '<', actualDate.toDate()).get();

    // console.log(`Actual items: ${actualItems.size}`)
    // console.log(`Prev items: ${prevItems.size}`)

    if (actualItems.empty || prevItems.empty) return null;

    return 0;

}
