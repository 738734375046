import React from 'react'
import {Button, Dialog, LinearProgress, makeStyles, Paper, SvgIcon, Typography,} from "@material-ui/core";
import PropTypes from "prop-types";
import DeleteSVG from "../SvgComponents/delete";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    dialog: {
        borderRadius: 20,
        padding: 10,
    },
    title: {
        fontFamily: 'Montserrat',
        fontWeight: 'bolder',
        fontSize: 30,
        alignSelf: 'center'
    },
    body: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 15,
        color: '#EB3030',
        margin: 5,
    },
    subBody: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
        fontSize: 12,
        color: '#848282',
        alignSelf: 'center',
        margin: 5,
    },
    acceptButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        },
        color: '#EB3030',
        fontFamily: 'Assistant',
        fontWeight: 'bold',
        fontSize: 12
    },
    cancelButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        },
        color: '#0076FF',
        fontFamily: 'Assistant',
        fontWeight: 'bold',
        fontSize: 12
    },
    paper: {
        marginTop: 10,
        borderRadius: 50,
        height: '45',
        maxWidth: '255',
        justifyItems: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex'
    }
})

function GralDialog(props) {
    const classes = useStyles();

    const {title, body, subBody, open, onAcceptText, onCancelText, showIcon, loading} = props;

    const {onAccept, onCancel,} = props;

    return (
        <div>
            <Dialog open={open} onClose={onCancel} PaperProps={{style: {borderRadius: 20}}}>

                <Grid container direction={'column'} alignItems={'center'} justify={'space-between'}
                      className={classes.dialog}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>{title ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={classes.body} align={'center'}>{body}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subBody}>{subBody}</Typography>
                    </Grid>
                    {onCancel ? <Grid container item justify={onCancel ? 'space-between' : 'center'}>
                            <Grid item xs={5}>
                                <Paper className={classes.paper}>
                                    <Button className={classes.acceptButton} onClick={onAccept}
                                            startIcon={showIcon ? <SvgIcon> <DeleteSVG/> </SvgIcon> : null}>
                                        {onAcceptText}
                                    </Button>
                                </Paper>
                            </Grid>
                            {<Grid item xs={5}>
                                <Paper className={classes.paper}>
                                    <Button className={classes.cancelButton} onClick={onCancel}>
                                        {onCancelText}
                                    </Button>
                                </Paper>
                            </Grid>}
                        </Grid> :
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Button className={classes.acceptButton} onClick={onAccept}
                                        startIcon={showIcon ? <SvgIcon> <DeleteSVG/> </SvgIcon> : null}>
                                    {onAcceptText}
                                </Button>
                            </Paper>
                        </Grid>}
                    {loading ? <LinearProgress/> : null}
                </Grid>
            </Dialog>
        </div>
    )
}

GralDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    subBody: PropTypes.string.isRequired
}

GralDialog.defaultProps = {
    onAcceptText: 'Continuar',
    onCancelText: 'Cancelar',
    showIcon: false
}

export default GralDialog
