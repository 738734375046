import React from 'react';

const SpecialIcon = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4692 6.41667L12.4275 5.97917L10.4587 1.34375C10.1046 0.5 8.89624 0.5 8.54208 1.34375L6.57333 5.98958L1.54208 6.41667C0.625411 6.48958 0.250411 7.63542 0.948328 8.23958L4.77124 11.5521L3.62541 16.4688C3.41708 17.3646 4.38583 18.0729 5.17749 17.5938L9.50041 14.9896L13.8233 17.6042C14.615 18.0833 15.5837 17.375 15.3754 16.4792L14.2296 11.5521L18.0525 8.23958C18.7504 7.63542 18.3858 6.48958 17.4692 6.41667ZM9.50041 13.0417L5.58374 15.4063L6.62541 10.9479L3.16708 7.94792L7.72958 7.55208L9.50041 3.35417L11.2817 7.5625L15.8442 7.95833L12.3858 10.9583L13.4275 15.4167L9.50041 13.0417Z" fill="#818181"/>
        </svg>
    )
}

export default SpecialIcon;