import moment from "moment";
import { addMonths, startOfDay, startOfMonth } from "date-fns";

import { CHANGE_BOOKINGS_DATE } from "../../constants/myBookingsConstants";
import {CLOSE_ERROR_DIALOG, RESET_ALL_DATA} from "../../constants";
import {
    CHANGE_FILTERS_BOOKINGSTATES_SELECTED,
    CHANGE_BOOKING_HISTORY_FILTER_ORIGIN,
    CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE,
    BOOKINGS_MORE_INFO_FILTERS_SELECTED,
    CHANGE_BOOKING_HISTORY_FILTER_STATE,
    CHANGE_BOOKING_HISTORY_FILTER_DATE,
    CHANGE_FILTERS_MOREINFO_SELECTED,
    CHANGE_BOOKINGS_COUNTRY_SELECTED,
    FETCH_BOOKINGS_FOR_TODAY_SUCCESS,
    CHANGE_BOOKINGS_ORIGIN_SELECTED,
    START_FETCHING_BOOKING_HISTORY,
    FETCH_BOOKING_HISTORY_SUCCESS,
    FETCH_BOOKING_HISTORY_FAILURE,
    CHANGE_BOOKING_HISTORY_QUERY,
    CHANGE_BOOKING_HISTORY_PAGE,
    CHANGE_BOOKINGS_ORDER,
} from "../../constants/bookingHistoryConstants";

const initState = {
    statistic: {
        total: 0,
        woki: 0,
        link: 0,
        manual: 0,
        tusClientes: 0,
    },
    history: [],
    totalDocuments: 0,
    rowsPerPage: 20,
    page: 0,
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    origin: null,
    state: null,
    dateRangeSelected: [{
        startDate: startOfMonth( addMonths(new Date(), 0) ),
        endDate: startOfDay( new Date() ),
        key: 'selection',
    }],
    query: "",
    countySelected: '',
    loading: false,
    error: null,
    bookingsForToday: [],
    originSelected: '',
    moreInfoFiltersSelected: [
        {
            label: 'Con tags',
            value: true,
        },
        {
            label: 'Con nota reserva cliente',
            value: true,
        },
        {
            label: 'Con nota reserva partner',
            value: true,
        },
        {
            label: 'Con opcionales',
            value: true,
        },
        {
            label: 'Con nota al cliente',
            value: true,
        },
        {
            label: 'Con relacion especial',
            value: true,
        },
        {
            label: 'Con restricción alimentaria',
            value: true,
        },
        // {
        //     label: 'Cliente especial',
        //     value: true,
        // },
        {
            label: 'LE flexible por horario',
            value: true,
        },
        {
            label: 'LE flexible por sector',
            value: true,
        },
    ],
    bookingStatesArray: [
        {
            label: 'Ingresada',
            value: true,
        },
        {
            label: 'Aceptada',
            value: true,
        },
        {
            label: 'Confirmada',
            value: true,
        },
        {
            label: 'Pendiente de confirmación',
            value: true,
        },
        {
            label: 'Solicitud',
            value: true,
        },
        {
            label: 'Solicitud de edición',
            value: true,
        },
        {
            label: 'Editada restaurante',
            value: true,
        },
        {
            label: 'LE activa',
            value: true,
        },
        {
            label: 'LE aceptada',
            value: true,
        },
        {
            label: 'LE expirada',
            value: true,
        },
        {
            label: 'LE eliminada cliente',
            value: true,
        },
        {
            label: 'LE eliminada restaurante',
            value: true,
        },
        {
            label: 'No vino',
            value: true,
        },
        {
            label: 'Cancelada cliente',
            value: true,
        },
        {
            label: 'Eliminada restaurante',
            value: true,
        },
        {
            label: 'Solicitud vencida',
            value: true,
        },
        {
            label: 'Solicitud rechazada',
            value: true,
        },
        {
            label: 'Esperando rta cliente',
            value: true
        }
    ],
    orderType: 'asc',
    orderBy: 'name',
};

const bookingHistoryReducer = (state = initState, action) => {
    switch (action.type) {
        case START_FETCHING_BOOKING_HISTORY:
            return {...state, loading: true, error: null}
        case FETCH_BOOKING_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                history: action.payload.details ?? state.history,
                statistic: action.payload.statistics ?? state.statistic,
                totalDocuments: action.payload.totalDocuments ?? state.totalDocuments
            }
        case FETCH_BOOKING_HISTORY_FAILURE:
            return {...state, loading: false, error: action.payload, history: [], statistic: initState.statistic}
        case CHANGE_BOOKING_HISTORY_FILTER_DATE:
            return {
                ...state,
                from: action.payload.from,
                to: action.payload.to,
                page: 0,
            }
        case CHANGE_BOOKING_HISTORY_FILTER_ORIGIN:
            return {...state, origin: action.payload}
        case CHANGE_BOOKING_HISTORY_FILTER_STATE:
            return {...state, state: action.payload}
        case CHANGE_BOOKING_HISTORY_QUERY:
            return {...state, query: action.payload}
        case CHANGE_BOOKING_HISTORY_PAGE:
            return {...state, page: action.payload}
        case CHANGE_BOOKING_HISTORY_ROWS_PER_PAGE:
            return {...state, rowsPerPage: action.payload}
        case FETCH_BOOKINGS_FOR_TODAY_SUCCESS:
            const newDetails = action.payload.details.map(booking => {
                return {
                    ...booking,
                    name: booking.client ?? 'Sin nombre',
                }   
            })
            return {...state, loading: false, error: null, bookingsForToday: newDetails}
        case CLOSE_ERROR_DIALOG:
            return {...state, error: null}
        case RESET_ALL_DATA:
            return {...state}
        case CHANGE_BOOKINGS_DATE: 
            return { ...state, loading: false, dateRangeSelected: action.payload }
        case CHANGE_BOOKINGS_COUNTRY_SELECTED: 
            return { ...state, loading: false, countrySelected: action.payload } 
        case CHANGE_BOOKINGS_ORIGIN_SELECTED:
            return { ...state, loading: false, originSelected: action.payload }
        case BOOKINGS_MORE_INFO_FILTERS_SELECTED: 
            return { ...state, loading: false, moreInfoFiltersSelected: action.payload }
        case CHANGE_FILTERS_MOREINFO_SELECTED:
            return { ...state, loading: false, moreInfoBookingFilters: action.payload }
        case CHANGE_FILTERS_BOOKINGSTATES_SELECTED:
            return { ...state, loading: false, bookingStatesArray: action.payload }
        case CHANGE_BOOKINGS_ORDER:
            return {...state, orderBy: action.payload.orderBy, orderType: action.payload.orderType}
        default:
            return state;
    }
}

export default bookingHistoryReducer;
