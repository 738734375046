import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
    root: {
        border: 'solid 3px #0ff',
        padding: '3px',

        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            '&:hover fieldset': {
                borderColor: '#0B4762',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0B4762',
                color: '#0B4762',
            },
        },
    },
}))

const TextAreaWithCondition = (props) => {

    const { width, text, placeholder, helperText, onChange, value } = props;
    const classes = useStyles();

    return (
        <TextField
            helperText={`${helperText}`}
            id="outlined-textarea"
            label={text}
            placeholder={placeholder}
            rows={4}
            multiline
            sx={{
                width: { width },
                '& .MuiInputBase-root': {
                    fontFamily: 'Poppins, sans-serif !important',
                },
                '& .MuiFormHelperText-root': {
                    fontFamily: 'Poppins, sans-serif !important',
                }
            }}
            inputProps={{
                sx: {
                    "&::placeholder": {
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: '400',
                        fontSize: '.8rem',
                        color: "#808080",
                    },
                }
            }}
            classes={ classes }
            onChange={onChange}
            value={value}
        />
    )
}

export default TextAreaWithCondition;
