import React from 'react';

const LinkIcon = (props) => {

    const {width, height} = props;

    return (
        <svg width={width} height={height} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4942 11.5057C14.4362 10.4481 13.0015 9.854 11.5056 9.854C10.0096 9.854 8.57491 10.4481 7.5169 11.5057L3.52694 15.4943C2.46891 16.5524 1.87451 17.9874 1.87451 19.4837C1.87451 20.9799 2.46891 22.4149 3.52694 23.473C4.58497 24.531 6.01997 25.1254 7.51625 25.1254C9.01253 25.1254 10.4475 24.531 11.5056 23.473L13.4999 21.4786"
                stroke="#0B4762" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.5056 15.4942C12.5636 16.5518 13.9983 17.1459 15.4943 17.1459C16.9902 17.1459 18.4249 16.5518 19.4829 15.4942L23.4729 11.5056C24.5309 10.4475 25.1253 9.01253 25.1253 7.51625C25.1253 6.01997 24.5309 4.58497 23.4729 3.52694C22.4149 2.46891 20.9799 1.87451 19.4836 1.87451C17.9873 1.87451 16.5523 2.46891 15.4943 3.52694L13.4999 5.52127"
                stroke="#0B4762" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default LinkIcon;
