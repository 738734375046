import React from 'react';

// Material Ui
import { Box, OutlinedInput, Typography } from '@mui/material';

// Styles
import { OutlinedShiftInputStyles, ShiftTextFieldContainerStyles, TFHelperTextStyles } from '../../../../Styles';

const ShiftTextField = ({ placeholder, handleChange, value, error, helperText }) => {
    return (
        <Box sx={ ShiftTextFieldContainerStyles }>
            <OutlinedInput 
                sx={ OutlinedShiftInputStyles }
                placeholder={ placeholder }
                onChange={ handleChange }
                value={ value }
                error={ error } 
            />
            <Typography sx={ TFHelperTextStyles } >{ helperText }</Typography>
        </Box>
    )
};

export default ShiftTextField;