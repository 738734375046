import React from 'react'

const ModoIcons = (props) => {
    return (
        <svg width="130" height="40" viewBox="0 0 130 57" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M36.6604 17.2791C35.7145 16.9751 34.6801 17.3176 34.1015 18.1255L26.1289 29.2603L27.8934 31.7241C28.2236 32.1216 28.4221 32.6322 28.4221 33.1889C28.4221 33.6265 28.2991 34.0353 28.0865 34.3832L33.673 26.5794V37.7747C33.673 39.0373 34.6968 40.0618 35.9601 40.0618C37.2237 40.0618 38.2475 39.038 38.2475 37.7747V19.4569C38.2483 18.4627 37.6063 17.5831 36.6604 17.2791Z" fill={props.fill}/>
            <path d="M16.2975 40.0634C15.034 40.0634 14.0103 39.0399 14.0103 37.7763V30.8967C14.0103 29.6332 15.034 28.6094 16.2975 28.6094C17.5611 28.6094 18.5847 29.6332 18.5847 30.8967V37.7763C18.5847 39.0391 17.5603 40.0634 16.2975 40.0634Z" fill={props.fill}/>
            <g opacity="0.4">
                <path opacity="0.4" d="M16.2975 33.1896C17.564 33.1896 18.5907 32.1631 18.5907 30.8965C18.5907 29.63 17.564 28.6033 16.2975 28.6033C15.031 28.6033 14.0043 29.63 14.0043 30.8965C14.0043 32.1631 15.031 33.1896 16.2975 33.1896Z" fill={props.fill}/>
            </g>
            <path opacity="0.4" d="M35.9611 40.0633C37.2244 40.0633 38.2482 39.0398 38.2482 37.7762V37.6184C38.1667 36.4259 37.1741 35.4829 35.9611 35.4829C34.7479 35.4829 33.7553 36.4251 33.6737 37.6177V37.7762C33.6737 39.039 34.6975 40.0633 35.9611 40.0633Z" fill={props.fill}/>
            <path opacity="0.4" d="M35.9611 21.7501C37.1741 21.7501 38.1667 20.8079 38.2482 19.6146V19.4569C38.2482 18.4634 37.6062 17.5831 36.6603 17.2791C35.7144 16.9751 34.6801 17.3176 34.1015 18.1255L34.0035 18.2628C33.7907 18.6105 33.6686 19.0194 33.6686 19.4569C33.6678 20.7235 34.6943 21.7501 35.9611 21.7501Z" fill={props.fill}/>
            <path d="M27.8934 31.7242L26.1296 29.2604L18.157 18.1256C17.4215 17.0982 15.9927 16.8621 14.9661 17.5976C13.9387 18.3331 13.7026 19.7618 14.438 20.7884L24.1713 34.384C24.5741 35.0432 25.3006 35.483 26.1296 35.483C27.3961 35.483 28.4229 34.4563 28.4229 33.1897C28.4221 32.6323 28.2236 32.1217 27.8934 31.7242Z" fill={props.fill}/>
            <g opacity="0.4">
                <path opacity="0.4" d="M26.1289 35.483C27.3954 35.483 28.4221 34.4563 28.4221 33.1897C28.4221 31.9235 27.3954 30.8967 26.1289 30.8967C24.8623 30.8967 23.8357 31.9235 23.8357 33.1897C23.8357 34.4563 24.8623 35.483 26.1289 35.483Z" fill={props.fill}/>
            </g>
            <path d="M53.307 40.2971C53.393 40.3069 53.4798 40.3121 53.5682 40.3121C53.6564 40.3121 53.7424 40.3069 53.8284 40.2971C53.7417 40.2986 53.6549 40.3001 53.5682 40.3001C53.4805 40.3001 53.3938 40.2986 53.307 40.2971Z" fill={props.fill}/>
            <path d="M53.7581 16.9609C53.6955 16.9557 53.6321 16.9526 53.568 16.9526C52.3014 16.9526 51.2749 17.9793 51.2749 19.2458C51.2749 20.5124 52.3014 21.539 53.568 21.539C53.6134 21.539 53.6586 21.5375 53.7031 21.5345C57.554 21.6069 60.6642 24.7616 60.6642 28.629C60.6642 30.5292 59.9271 32.3134 58.5898 33.6507C57.2531 34.9875 55.4706 35.7246 53.5719 35.7253C54.8369 35.7275 55.8612 36.7535 55.8612 38.0183C55.8612 39.1966 54.9718 40.1676 53.8284 40.2966C56.8533 40.231 59.6844 39.0261 61.8244 36.8854C64.0262 34.6836 65.2385 31.7513 65.2385 28.629C65.2392 22.2579 60.106 17.0635 53.7581 16.9609Z" fill={props.fill}/>
            <path d="M53.5682 35.726C53.5665 35.726 53.5658 35.726 53.5643 35.726C49.6531 35.7237 46.4712 32.5411 46.4712 28.6297C46.4712 26.7462 47.2112 24.9569 48.5554 23.5923C49.4418 22.6924 49.4305 21.244 48.5306 20.3577C47.6306 19.4713 46.1823 19.4826 45.2958 20.3826C43.1045 22.6086 41.8977 25.537 41.8977 28.6297C41.8977 34.9776 46.9918 40.1577 53.307 40.2973C53.393 40.3071 53.4798 40.3122 53.5682 40.3122C54.8347 40.3122 55.8612 39.2858 55.8612 38.019C55.8612 36.7524 54.8347 35.726 53.5682 35.726Z" fill={props.fill}/>
            <g opacity="0.4">
                <path opacity="0.4" d="M53.568 40.3124C54.8345 40.3124 55.8612 39.2856 55.8612 38.0193C55.8612 36.7528 54.8345 35.7261 53.568 35.7261C52.3017 35.7261 51.2749 36.7528 51.2749 38.0193C51.2749 39.2856 52.3017 40.3124 53.568 40.3124Z" fill={props.fill}/>
            </g>
            <g opacity="0.4">
                <path opacity="0.4" d="M53.568 21.539C54.8345 21.539 55.8612 20.5123 55.8612 19.2458C55.8612 17.9793 54.8345 16.9526 53.568 16.9526C52.3017 16.9526 51.2749 17.9793 51.2749 19.2458C51.2749 20.5123 52.3017 21.539 53.568 21.539Z" fill={props.fill}/>
            </g>
            <path d="M85.8811 20.0535C83.7674 18.1669 80.8973 17.1697 77.5812 17.1697H70.7279C69.4651 17.1697 68.4408 18.1933 68.4408 19.4569C68.4408 20.7204 69.4643 21.744 70.7279 21.744H77.5812C79.7558 21.744 81.5732 22.3399 82.8358 23.4662C84.1627 24.6504 84.8364 26.3877 84.8364 28.6296C84.8364 30.8641 84.162 32.5953 82.8313 33.7751C81.567 34.8959 79.7521 35.489 77.5812 35.489H73.015V37.5339C73.0234 37.6117 73.0271 37.6901 73.0271 37.77C73.0271 39.0365 72.0003 40.0632 70.7338 40.0632H77.5812C80.8874 40.0632 83.7524 39.0721 85.8661 37.1975C88.1842 35.1411 89.4099 32.1788 89.4099 28.6296C89.4092 25.0789 88.1893 22.1136 85.8811 20.0535Z" fill={props.fill}/>
            <path d="M73.0143 37.5334V35.4885V26.3368C73.0143 25.0732 71.9907 24.0496 70.7272 24.0496C69.4643 24.0496 68.44 25.0732 68.44 26.3368V37.7702C68.44 39.036 69.4651 40.062 70.7301 40.0635C70.7294 40.0635 70.7279 40.0635 70.7272 40.0635H70.7331C71.9996 40.0635 73.0263 39.0368 73.0263 37.7702C73.0263 37.6904 73.0226 37.611 73.0143 37.5334Z" fill={props.fill}/>
            <g opacity="0.4">
                <path opacity="0.4" d="M70.7272 21.7443C71.9903 21.7443 73.0143 20.7203 73.0143 19.4571C73.0143 18.1939 71.9903 17.1699 70.7272 17.1699C69.4641 17.1699 68.44 18.1939 68.44 19.4571C68.44 20.7203 69.4641 21.7443 70.7272 21.7443Z" fill={props.fill}/>
            </g>
            <g opacity="0.4">
                <path opacity="0.4" d="M70.7331 40.0633C71.9996 40.0633 73.0263 39.0366 73.0263 37.7701C73.0263 36.5035 71.9996 35.4771 70.7331 35.4771C69.4665 35.4771 68.44 36.5035 68.44 37.7701C68.44 39.0366 69.4665 40.0633 70.7331 40.0633Z" fill={props.fill}/>
            </g>
            <path d="M103.839 16.9609C103.776 16.9557 103.713 16.9526 103.649 16.9526C102.382 16.9526 101.356 17.9793 101.356 19.2458C101.356 20.5124 102.382 21.539 103.649 21.539C103.694 21.539 103.74 21.5375 103.784 21.5345C107.635 21.6069 110.745 24.7616 110.745 28.629C110.745 30.5292 110.008 32.3134 108.671 33.6507C107.334 34.9875 105.551 35.7246 103.653 35.7253C104.918 35.7275 105.942 36.7535 105.942 38.0183C105.942 39.1966 105.053 40.1676 103.909 40.2966C106.934 40.231 109.765 39.0261 111.905 36.8854C114.107 34.6836 115.319 31.7513 115.319 28.629C115.32 22.2579 110.187 17.0635 103.839 16.9609Z" fill={props.fill}/>
            <path d="M103.649 35.726C103.647 35.726 103.647 35.726 103.645 35.726C99.7338 35.7237 96.5521 32.5411 96.5521 28.6297C96.5521 26.7462 97.2921 24.9569 98.6363 23.5923C99.5227 22.6924 99.5114 21.244 98.6115 20.3577C97.7115 19.4713 96.2632 19.4826 95.3767 20.3826C93.1854 22.6086 91.9786 25.537 91.9786 28.6297C91.9786 34.9776 97.0725 40.1577 103.388 40.2973C103.474 40.3071 103.561 40.3122 103.649 40.3122C104.915 40.3122 105.942 39.2858 105.942 38.019C105.942 36.7524 104.915 35.726 103.649 35.726Z" fill={props.fill}/>
            <g opacity="0.4">
                <path opacity="0.4" d="M103.649 40.3124C104.915 40.3124 105.942 39.2856 105.942 38.0193C105.942 36.7528 104.915 35.7261 103.649 35.7261C102.382 35.7261 101.356 36.7528 101.356 38.0193C101.356 39.2856 102.382 40.3124 103.649 40.3124Z" fill={props.fill}/>
            </g>
            <g opacity="0.4">
                <path opacity="0.4" d="M103.649 21.539C104.915 21.539 105.942 20.5123 105.942 19.2458C105.942 17.9793 104.915 16.9526 103.649 16.9526C102.382 16.9526 101.356 17.9793 101.356 19.2458C101.356 20.5123 102.382 21.539 103.649 21.539Z" fill={props.fill}/>
            </g>
        </svg>
    )
}

export default ModoIcons