import { 
    SIDEBAR_BOOKING_ID, 
    FILTER_SELECTED, 
    SHOW_SIDEBAR, 
    SORT_TYPE,
    CHANGE_BOOKINGS_DATE, 
} from "../../constants/myBookingsConstants";

export const setOpenSidebar = ( data ) => ({
    type: SHOW_SIDEBAR,
    payload: data
})

export const setSidebarBookingId = ( data ) => ({
    type: SIDEBAR_BOOKING_ID,
    payload: data
});

export const setFilterSelected = ( data ) => ({
    type: FILTER_SELECTED,
    payload: data
});

export const setSortType = ( data ) => ({
    type: SORT_TYPE,
    payload: data
});

export const setBookingsPeriodSelected = ( data ) => ({
    type: CHANGE_BOOKINGS_DATE,
    payload: data,
});