import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { 
  setChangeExtraExcludeData, 
  setChangeBookableGroup, 
  setDisabledAllLayout, 
  setReservableSectors, 
  setNotBookableGroup, 
  setSubshift 
} from "../../../../../actions/shiftsActions";

// Components
import CustomizeReservableGroupSectorsModal from "./CustomizeReservableGroupSectorsModal";
import CustomizeReservableSectorsModal from "./CustomizeReservableSectorsModal";
import ShiftStep3AddExceptions from "./General/ShiftStep3AddExceptions";
import SectorsSubShiftsTable from "./SectorsSubShiftsTable";

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { RemoveBookableSubshift, SetNewBookableSubshift, SetReservableSectors } from "../utils/reservableSectorsFunctions";
import { GetColLayouts } from "../utils/sectorsFunctions";
import { getSubshifts } from "../utils/functions";

// Styles
import { ShiftSelectTitles } from "../../../Styles";

const CustomizeReservableSectors = (props) => {
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);
  const [showGroupsArray, setShowGroupsArray] = useState( false );
  const [openGroupModal, setOpenGroupModal] = useState( false );
  const [openTableModal, setOpenTableModal] = useState( false );
  
  // Variables
  const {
    reservableSectors,
    availableLayouts,
    subShifts
  } = props;

  // Acciones
  const {
    setChangeExtraExcludeData,
    setChangeBookableGroup,
    setDisabledAllLayout,
    setReservableSectors,
    setNotBookableGroup,
    setSubshift,
  } = props;

  const SUB_SHIFTS = getSubshifts( subShifts );
  
  // Cambiar para armar una matriz en función a los bookeableSectors de subshifts
  const [colLayout, setColLayout] = useState([]);

  useEffect(() => {
    setColLayout( GetColLayouts( reservableSectors.filter((reservableSector) => reservableSector.available ), subShifts) );
  }, [ reservableSectors, subShifts ]);

  const handleClick = ( subShiftId, sector, enabled ) => {

    SetReservableSectors( reservableSectors, subShiftId, sector, enabled, setReservableSectors );

    // Si es enable, se va a deshabilitar, hay que quitar del array bookableSectors
    if ( enabled ) {

      RemoveBookableSubshift( reservableSectors, subShifts, subShiftId, sector, true, setSubshift, sector.type, setDisabledAllLayout );

    } else {

      // Agregar al array bookableSectors
      SetNewBookableSubshift( subShifts, subShiftId, sector, false, setSubshift, sector.type );

    };

    if ( sector.type === 'Mesa' || sector.type === 'Group' ) {
      
      if ( sector.type === 'Mesa' ) {
        setChangeExtraExcludeData({ 
          subshiftId: subShiftId, 
          tableId: sector.id, 
          type: sector.type, 
          enabled: enabled, 
          groupId: '',
        });
        
      } else {
        setNotBookableGroup({
          subshiftId: subShiftId,
          groupId: sector.id,
          available: enabled 
        });

        setChangeExtraExcludeData({
          subshiftId: subShiftId, 
          layouts: colLayout, 
          groupId: sector.id, 
          type: sector.type,
          enabled: enabled, 
          tableId: '', 
        });
      };
    };
  };

  useEffect(() => {
    if ( !openGroupModal ) {
      setShowGroupsArray( true );
    } else {
      setShowGroupsArray( false );
    }
  }, [ openGroupModal ]);
  

  const handleGroupClick = ( subShiftId, sector, bookableSector ) => {
    
    setChangeBookableGroup({
      group: sector.groups[0].group, 
      sectorId: sector.sectorID,
      subShiftId: subShiftId,
      sector: sector,
    });
  };

  return (
    <>
      <Typography sx={ ShiftSelectTitles } >Personalizar sectores reservables</Typography>
      <Stack
        onClick={() => setAdvancedOptionsOpen(!advancedOptionsOpen)}
        sx={{ cursor: "pointer", marginTop: "24px" }}
        alignItems="center"
        direction="row"
        width={'150px'}
        spacing={1}
      >
        <Stack
          sx={{ cursor: "pointer" }}
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Box
            borderRadius={50}
            bgcolor="#35C18F"
            height="11.5px"
            width="11.5px"
          ></Box>
          <Typography
            sx={{ textDecoration: "underline" }}
            fontFamily="Poppins"
            fontWeight="500"
            fontSize="1rem"
            color="#041C32"
            my={3}
            >
            Personalizar
          </Typography>
        </Stack>
        {advancedOptionsOpen ? (
          <ExpandLessIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
        )}
      </Stack>
      <Collapse in={ advancedOptionsOpen && availableLayouts.length > 0 } >
        <SectorsSubShiftsTable
          handleGroupClick={ handleGroupClick }
          showGroupsArray = { showGroupsArray }
          textNoOk={ 'No Reservable' }
          handleClick={ handleClick }
          colSubShifts={ SUB_SHIFTS }
          colSectors={ colLayout }
          textOk={ 'Reservable' }
          boxStyles={{
            maxWidth: "1220px",
          }}
        >
            <ShiftStep3AddExceptions
              setShowGroupModal={ setOpenGroupModal } 
              setShowTableModal={ setOpenTableModal }
            />
            { openTableModal && (
                <CustomizeReservableSectorsModal
                  handleClose={() => setOpenTableModal( !openTableModal )}
                  subShifts={ subShifts } 
                />
              )
            }
            {
              openGroupModal && (
                <CustomizeReservableGroupSectorsModal
                  handleClose={() => setOpenGroupModal( !openGroupModal )}
                  open={ openGroupModal }
                />
              )
            }
        </SectorsSubShiftsTable>
      </Collapse>
    </>
  );
};

const mapStateToProps = (state) => {
  return {            
    availableLayouts: state.v3.shifts.shiftData.availableLayouts,
    reservableSectors: state.v3.shifts.reservableSectors,
    subShifts: state.v3.shifts.shiftData.subShifts,
    sectors: state.v3.shifts.sectors,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChangeExtraExcludeData: ({ type, subshiftId, enabled, tableId, groupId, layouts }) => dispatch( 
      setChangeExtraExcludeData({ type, subshiftId, enabled, tableId, groupId, layouts }) 
    ),
    setNotBookableGroup: ({ groupId, subshiftId, available }) => dispatch( setNotBookableGroup({ groupId, subshiftId, available }) ),
    setChangeBookableGroup: ({ subShiftId, sectorId, sector, group }) => dispatch( 
      setChangeBookableGroup({ subShiftId, sectorId, sector, group }) 
    ),
    setDisabledAllLayout: ({ layoutType, subShiftId }) => dispatch( setDisabledAllLayout({ layoutType, subShiftId }) ),
    setReservableSectors: (data) => dispatch(setReservableSectors(data)), 
    setSubshift: (data) => dispatch(setSubshift(data)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)( CustomizeReservableSectors );
