import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
    ItemContainer: {
        backgroundColor: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Poppins, sans-serif',
        borderRadius: '12px',
        marginRight: '1rem',
        height: '180px',
        width: '250px',
    },
    Top: {
        padding: '1.5rem 1rem 1rem 1.2rem',
        borderRadius: '12px 12px 0 0',
        border: '1px solid #DBD7DC',
        backgroundColor: '#FFFFFF',
        fontWeight: '600',
        color: '#041C32',
        display: 'flex',
        height: '70%',
    },
    Bottom: {
        borderRadius: '0 0 12px 12px',
        backgroundColor: '#0B4762',
        justifyContent: 'center',
        textDecoration: 'none',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#FFFFFF',
        display: 'flex',
        height: '30%',
    }
}));

const TutorialsItem = (props) => {

    const classes = useStyles();
    const {url, text} = props;

    return (
        <div className={classes.ItemContainer}>
            <div className={classes.Top}>{text}</div>
            <Link className={classes.Bottom} to={{pathname: `${url}`}} target='_blank'>
                Ver tutorial
            </Link>
        </div>
    )
}

export default TutorialsItem;
