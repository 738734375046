import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Input,
    InputAdornment,
    LinearProgress,
} from "@material-ui/core";

import clsx from "clsx";

import {changeEditAllPrice, toggleEditPricesDialog} from "../../../actions";
import {connect} from "react-redux";
import {idAllCategories} from "../../../constants";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

function EditPricesDialog(props) {
  const classes = useStyles();

  const [percentage, setPercentage] = useState(0.0);
  const [textPercentage, setTextPercentage] = useState(0.0);

  // Variables
  const { editPriceDialog, loading, selectedCategory } = props;

  let { categories } = props;

  //Actions
  const { toggleEditPricesDialog, changeEditAllPrice } = props;

  const changePorcentaje = (value) => {
    setPercentage((100 + value) / 100);
    setTextPercentage(value);
  };

  const submit = () => {
    let categoriesId = [];
    if (!selectedCategory || selectedCategory === idAllCategories) {
      categories.forEach((category) => {
        categoriesId.push(category.id);
      });
    } else {
      categoriesId = [selectedCategory];
    }
    changeEditAllPrice(categoriesId, percentage);
    toggleEditPricesDialog();
  };

  return (
    <div>
      <Dialog
        open={editPriceDialog}
        onClose={toggleEditPricesDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{"Cambiar precios"}</DialogTitle>
        <DialogContent>
          <div>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField
              )}
            >
              <Input
                id="standard-adornment-percentage"
                value={textPercentage}
                type="number"
                onChange={(e) => changePorcentaje(Number(e.target.value))}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="standard-percentage-helper-text"
                inputProps={{
                  "aria-label": "percentege",
                }}
              />
              <FormHelperText id="standard-percentage-helper-text">
                Porcentaje
              </FormHelperText>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleEditPricesDialog}>Cerrar</Button>
          <Button onClick={submit}>Cambiar</Button>
        </DialogActions>
        {loading ? <LinearProgress /> : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.menuReducer.productsReducer.editReducer.categories,
    editPriceDialog:
      state.menuReducer.productsReducer.editReducer.showEditDialog,
    loading: state.menuReducer.categoriesReducer.loading,
    selectedCategory:
      state.menuReducer.productsReducer.editReducer.selectedCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditPricesDialog: () => dispatch(toggleEditPricesDialog()),
    changeEditAllPrice: (categoriesId, porcentaje) =>
      dispatch(changeEditAllPrice(categoriesId, porcentaje)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPricesDialog);
