import {useState} from 'react';

import {connect} from "react-redux";
import {InfoOutlined} from "@material-ui/icons";
import { styled } from '@mui/system';
import { Card, Tab, Tabs, Typography } from '@mui/material';

// Components
import DraggableTable from "./DraggableTable";
import LightTooltip from '../../../GeneralComponents/LightTooltip';
import CombinationsList from './CombinationsList';

import './DraggableMenu.css'
import { cancelCombination, startAddingNewTable } from '../../../../actions/V3';

const CardStyled = styled(Card)(({ theme }) => ({
    position: 'sticky',
    top: '0em',
    borderRadius: '8px',
    fontFamily: 'Poppins, sans-serif',
    minWidth: '250px',
  }));
  
  const TabStyled = styled(Tab)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
    borderRadius: '0.6em 0.6em 0em 0em',
    borderColor: 'DBD7DC',
    fontFamily: 'Poppins, sans-serif',
    width: '20px',
    textTransform: 'none',
    background: '#EFEFEF',
    color: '#0B4762',
    '&.MuiTab-root': {
      minWidth: '50%',
    },
    '&.Mui-selected': {
      color: '#fff',
      fontWeight: '500',
      backgroundColor: '#0B4762',
      boxShadow: '0px 2px 4px #0B476290',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
      fontWeight: '500',
    },
  }));
  
  const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  });
  
  const Flex = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.2em',
    width: '100%',
    height: '60vh',
    paddingBottom: '1em',
    overflowY: 'auto',
  });

const DraggableMenu = (props) => {

    const [currentTab, setCurrentTab] = useState('mesas');
    const {combineMode} = props;
    const {startAddingNewTable, cancelCombination} = props;

    const tableOptions = [
        {minCapacity: 1, maxCapacity: 1, w: 14, h: 3},
        {minCapacity: 1, maxCapacity: 2, w: 14, h: 3},
        {minCapacity: 2, maxCapacity: 4, w: 14, h: 3},
        {minCapacity: 3, maxCapacity: 5, w: 14, h: 3},
        {minCapacity: 4, maxCapacity: 6, w: 14, h: 3},
        {minCapacity: 6, maxCapacity: 10, w: 14, h: 3},
    ];

    const tabs = [
        {value: 'mesas', label: 'Agregar'},
        {value: 'combinaciones', label: 'Combinar'},
    ];

    const handleTabsChange = (_event, value) => {
        setCurrentTab(value);
        if (value === tabs[0].value && combineMode) {
            cancelCombination();
        }
    };

    return (
        <CardStyled>
            <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant={"standard"}
                textColor="primary"
                indicatorColor="primary"
                sx={{background: '#f5f5f5'}}
            >
                {tabs.map((tab) => (
                    <TabStyled  key={tab.value} label={tab.label} value={tab.value}/>
                ))}
            </Tabs>
            {currentTab === 'mesas' && (
                <div
                    style={{
                        height: '72vh',
                        border: '2px solid #0B4762',
                        borderRadius: '0px 0px 8px 8px',
                        position: 'relative',
                    }}
                >
                    <Container>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography
                                variant="h6"
                                component="h6"
                                gutterBottom
                                style={{
                                    padding: 0.5,
                                    color: '#0B4762',
                                    fontSize: '.9rem',
                                    margin: '1rem 0.5rem',
                                    fontFamily: 'Poppins, sans-serif',
                                }}
                            >
                                Tipos de mesas
                            </Typography>
                            <LightTooltip
                                placement='right'
                                title='Clickeá y arrastrá tu mesa hasta su posición final. Luego editá sus características.'
                            >
                                <InfoOutlined variant="small"
                                              style={{color: '#0B4762', fontSize: '18px', cursor: 'pointer'}}/>
                            </LightTooltip>
                        </div>
                        <Flex>
                            {
                                tableOptions.map((table, i) => (
                                    <DraggableTable
                                        key={i}
                                        {...table}
                                        showDivider={i !== tableOptions.length - 1}
                                        onDragStart={startAddingNewTable}
                                    />
                                ))
                            }
                        </Flex>
                    </Container>
                </div>
            )}
            {currentTab === 'combinaciones' && (
                <div
                    style={{
                        height: '70vh',
                        border: '2px solid #0B4762',
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <CombinationsList
                        combineMode={combineMode}
                    />
                </div>
            )}
        </CardStyled>
    );
}

const mapStateToProps = (state) => ({
    combineMode: state.v3.sectorsLayoutsTables.combineMode,
})

const mapDispatchToProps = dispatch => {
    return {
        startAddingNewTable: ({ minCapacity, maxCapacity, h, w, isResizable, circle }) =>
        dispatch(
          startAddingNewTable({
            minCapacity,
            maxCapacity,
            h,
            w,
            isResizable,
            circle,
          })
        ),
        cancelCombination: () => dispatch(cancelCombination()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableMenu);