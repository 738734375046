import React from 'react';

const BookingSectorIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2222 1H2.77778C1.79594 1 1 1.79594 1 2.77778V15.2222C1 16.2041 1.79594 17 2.77778 17H15.2222C16.2041 17 17 16.2041 17 15.2222V2.77778C17 1.79594 16.2041 1 15.2222 1Z" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 6.33344H17" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.33301 17.0001V6.33344" stroke="#818181" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default BookingSectorIcon;