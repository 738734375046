import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setMoreInfoBookingsFilters, setMoreInfoFilters, setMoreInfoFiltersSelect } from '../../../../../actions';

// Components
import GeneralSection3Select from './GeneralSection3Select';

// Helpers
import { bookingsOptions, clientsOptions, handleChangeBookingsMoreInfoFilters } from '../../helpers';
import { handleChangeMoreInfoFilters } from '../../../../MyClients/helpers';

// Icon
import InfoFilter from '../../../../SvgComponents/Icons Tables/MyBookings/InfoFilter';

// Material Ui
import { useMediaQuery } from '@mui/material';

const InfoSelect = ( props ) => {

    // Actions
    const { setMoreInfoFilters, setMoreInfoFiltersSelect } = props;

    // External props
    const { optionsArray, component } = props;

    // States
    const { moreInfoFilters, moreInfoFiltersSelected } = props;

    const media = useMediaQuery('(min-width:1550px)');

    const [infoState, setInfoState] = useState( component === 'clients' ? clientsOptions : bookingsOptions );
    const [closeClientSelect, setCloseClientSelect] = useState( false );

    useEffect(() => {
        let newArray = [];

        console.log( moreInfoFilters );

        if ( component === 'clients' ) {
            for (let i = 0; i < moreInfoFilters.length; i++) {
                if ( moreInfoFilters[i].value ) {
                    newArray.push( moreInfoFilters[i].label );
                }
            };
        } else {
            for (let j = 0; j < moreInfoFiltersSelected.length; j++) {
                if ( moreInfoFiltersSelected[j].value ) {
                    newArray.push( moreInfoFiltersSelected[j].label );
                };
            };
        };

        if ( component === 'clients' ) {
            handleChangeMoreInfoFilters( newArray, setMoreInfoFilters );
        } else {
            handleChangeBookingsMoreInfoFilters( newArray, setMoreInfoFiltersSelect );
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        if ( component === 'clients' ) {
            if ( 
                event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
                event.target.value.length > moreInfoFilters.length
            ) {
                setInfoState([]);
            } else if (
                event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
                event.target.value.length <= moreInfoFilters.length
            ) {
                let allClientsOptionsArray = [];

                for (let j = 0; j < moreInfoFilters.length; j++) {
                    allClientsOptionsArray.push( moreInfoFilters[j].label );
                };
                
                setInfoState( allClientsOptionsArray );
            } else {
                setInfoState( typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value );
            };
        } else {
            if ( 
                event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
                event.target.value.length > moreInfoFiltersSelected.length
            ) {
                setInfoState([]);
            } else if (
                event.target.value[ event.target.value.length - 1 ] === 'Todos' &&
                event.target.value.length < moreInfoFiltersSelected.length
            ) {
                let allOptionsArray = [];
                
                for ( let i = 0; i < moreInfoFiltersSelected.length; i++ ) {
                    allOptionsArray.push( moreInfoFiltersSelected[i].label );
                };
    
                setInfoState( allOptionsArray );
            } else {
                setInfoState( typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value );
            }
        }
    };

    useEffect(() => {

        if ( closeClientSelect ) {
            if ( component === 'clients' ) {
                handleChangeMoreInfoFilters( infoState, setMoreInfoFilters );
            } else {
                handleChangeBookingsMoreInfoFilters( infoState, setMoreInfoFiltersSelect );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ infoState, closeClientSelect ]);

    const handleClose = () => {
        setCloseClientSelect( true );
    };

    const handleOpen = () => {
        setCloseClientSelect( false );
    };

    return (
        <GeneralSection3Select
            placeholderText={ media ? 'Más info' : 'Info' }
            optionsArray={ optionsArray } 
            handleChange={ handleChange } 
            handleClose={ handleClose }
            handleOpen={ handleOpen } 
            SelectIcon={ InfoFilter }
            value={ infoState } 
        />
    )
}

const mapStateToProps = state => {
    return {
        moreInfoFiltersSelected: state.bookingHistoryReducer.moreInfoFiltersSelected,
        moreInfoFilters: state.clientsStatisticsReducer.moreInfoFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMoreInfoBookingsFilters: (data) => dispatch( setMoreInfoBookingsFilters(data) ),
        setMoreInfoFiltersSelect: (data) => dispatch( setMoreInfoFiltersSelect(data) ),
        setMoreInfoFilters: (data) => dispatch( setMoreInfoFilters(data) ),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( InfoSelect );