import React, {useState} from 'react';

import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {connect} from 'react-redux';
import {PARTNERS} from '../../constants';
import CategoriesBar from "./components/CategoriesBar";
import ProductsTable from "./components/ProductsTable";

function PublicMenu(props) {
    const {menu, partner} = props;

    const [category, setCategory] = useState('');
    const [isInit, setIsInit] = useState(false);

    const initCategory = () => {
        if (!isInit && menu && menu.length) {
            setCategory(menu[0].name);
            setIsInit(true);
        }
    }

    initCategory();

    return (
        <div>
            <div>
                <h1 style={{marginLeft: 20,}}>{partner?.displayName}</h1>
                {
                    menu && menu.length > 0
                        ?
                            <CategoriesBar 
                                onClick={(name) => setCategory(name)}
                                activeCategory={category}
                                categories={menu} 
                            />
                        : 
                            null
                }
            </div>
            <div>
                {
                    menu && menu.length > 0
                        ? menu.map(category =>
                            <div key={`div-table-${category.name}`}>
                                <ProductsTable 
                                    category={category.name}
                                    products={category.products}
                                />
                            </div>
                        )
                        : null
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        profile: state.firebase.profile,
        partner: state.firestore.ordered.partner ? state.firestore.ordered.partner[0] : undefined,
        menu: state.firestore.ordered.menu,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        return [
            {
                collection: PARTNERS,
                doc: props.partnerId,
                storeAs: "partner",
            },
            {
                collection: PARTNERS,
                doc: props.partnerId,
                subcollections: [{collection: 'menu'}],
                orderBy: ["name", "asc"],
                storeAs: "menu",
            },];
    })
)(PublicMenu);