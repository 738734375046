import React, {useState} from 'react'

const Help = (props) => {

    const [showHelp, setShowHelp] = useState(false);

    const {texts} = props

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="358" height="70%">
                <defs>
                    <filter id="Rectángulo_2586" x="0" y="0" width="358" height="83" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <filter id="Trazado_773" x="315" y="5" width="38" height="38" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-2"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur-2"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="MAS_INFO" data-name="MAS INFO" transform="translate(9 6)">
                    {showHelp ?
                        <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectángulo_2586)">
                        <rect id="Rectángulo_2586-2" data-name="Rectángulo 2586" width="340" height={texts?.length <= 2 ? 45 : 65} rx="20"
                              transform="translate(9 6)" fill="#fff"/>
                    </g> : null}
                    <g style={{cursor: 'pointer'}} onClick={() => setShowHelp(!showHelp)}>
                        <circle id="Elipse_299" data-name="Elipse 299" cx="10" cy="10" r="10"
                                transform="translate(315 5)"
                                fill="#d0d0d0"/>
                        <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Trazado_773)">
                            <path id="Trazado_773-2" data-name="Trazado 773"
                                  d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM8.213,7.541H6.563C6.525,5.184,8.35,4.375,10.008,4.375c1.746,0,3.341.913,3.341,2.8a3.051,3.051,0,0,1-1.555,2.571c-.921.7-1.262.96-1.262,1.858v.444H8.9l-.009-.579a2.848,2.848,0,0,1,1.46-2.484c.737-.555,1.206-.92,1.206-1.714a1.452,1.452,0,0,0-1.643-1.46,1.6,1.6,0,0,0-1.7,1.73ZM9.777,15.6a1.171,1.171,0,1,1,0-2.334,1.172,1.172,0,1,1,0,2.334Z"
                                  transform="translate(324 11)" fill="#fff" fillRule="evenodd"/>
                        </g>
                    </g>
                    {showHelp ? <text
                        id="En_este_panel_puedes_ver_la_cantidad_de_clientes_que_ingresaron_a_tu_establecimientos_a_través_de_la_función_Mesa_Ya_"
                        data-name="En este panel puedes ver la cantidad de clientes que ingresaron a tu establecimientos a través de la función Mesa Ya!"
                        transform="translate(15 10)" fill="#848282" fontSize="10" fontFamily="SegoeUI, Segoe UI"
                        letterSpacing="-0.02em">
                        {texts?.map((t, i) => <tspan x="0" y={i === 0 ? 11 : 11 + (i * 14)}>{t}</tspan>)}
                        {/*<tspan x="0" y="11">En este panel puedes ver la cantidad de clientes que ingresaron a tu</tspan>
                        <tspan x="0" y="25">establecimientos a través de la función Mesa Ya!</tspan>*/}
                    </text> : null}
                </g>
            </svg>


            {/*<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 38 38">
                <defs>
                    <filter id="Rectángulo_2586" x="5" y="5" width="28" height="28" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood floodOpacity="0.251"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                    <filter id="Trazado_773" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur-2"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur-2"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="MAS_INFO" data-name="MAS INFO" transform="translate(9 6)">
                    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectángulo_2586)">
                        <rect id="Rectángulo_2586-2" data-name="Rectángulo 2586" width="10" height="10" rx="5"
                              transform="translate(14 11)" fill="#fff"/>
                    </g>
                    <circle id="Elipse_299" data-name="Elipse 299" cx="10" cy="10" r="10" fill="#d0d0d0"/>
                    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Trazado_773)">
                        <path id="Trazado_773-2" data-name="Trazado 773"
                              d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM8.213,7.541H6.563C6.525,5.184,8.35,4.375,10.008,4.375c1.746,0,3.341.913,3.341,2.8a3.051,3.051,0,0,1-1.555,2.571c-.921.7-1.262.96-1.262,1.858v.444H8.9l-.009-.579a2.848,2.848,0,0,1,1.46-2.484c.737-.555,1.206-.92,1.206-1.714a1.452,1.452,0,0,0-1.643-1.46,1.6,1.6,0,0,0-1.7,1.73ZM9.777,15.6a1.171,1.171,0,1,1,0-2.334,1.172,1.172,0,1,1,0,2.334Z"
                              transform="translate(9 6)" fill="#fff" fillRule="evenodd"/>
                    </g>
                </g>
            </svg>*/}
        </div>
    )
}

export default Help
