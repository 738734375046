import React, {useEffect, useState} from 'react'
import '../Styles/Row.css'
import SimpleInput from "../../GeneralComponents/Input General/SimpleInput";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton} from "@material-ui/core";


const MenuRow = (props) => {

    const {name, link} = props

    const {onChangeName, onChangeLink, onDelete} = props;

    const [editedName, setEditedName] = useState(name);
    const [editedLink, setEditedLink] = useState(link);

    useEffect(() => {
        setEditedName(name)
        setEditedLink(link)
    }, [name, link])

    const onBlurName = () => {
        onChangeName(editedName);
    };


    const onBlurLink = () => {
        onChangeLink(
            editedLink
        );
    };

    return (
        <tr id='tr-menu-row' key={`${name}-${link}`} className="tableRow">
            <td className="nameBox">
                {/*<a className='sortBox'>
                    <Sort/>
                </a>*/}
                    <SimpleInput
                        tabIndex={0}
                        onBlur={onBlurName}
                        onChange={(e) => setEditedName(e.target.value)}
                        text={editedName}
                    />
            </td>
            <td className="linkBox">
                <SimpleInput
                    tabIndex={1}
                    onBlur={onBlurLink}
                    onChange={(e) => setEditedLink(e.target.value)}
                    text={editedLink}
                />
            </td>
            <td className='actionsRow'>
                <IconButton
                    onClick={onDelete}
                    aria-label="delete"
                >
                    <DeleteIcon/>
                </IconButton>
            </td>
        </tr>
    )
}

export default MenuRow