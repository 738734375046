import React from 'react';

const SaveIcon = (props) => {

    const {height, onClick, paddingTop} = props;

    return (
        <div
            onClick={onClick}
            style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                paddingTop: paddingTop ? paddingTop : '0',
            }}
        >
            <svg width={height} height={height} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_256_511)">
                    <circle cx="23" cy="19" r="19" fill="#0B4762"/>
                    <path d="M15.0273 17.8603L21.8607 24.6936L33.2496 13.3047" stroke="white" strokeWidth="2.27778"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <filter id="filter0_d_256_511" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_256_511"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_256_511" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </div>
    )
}

export default SaveIcon;
