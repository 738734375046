import React, {useState} from 'react';

// Material Ui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {makeStyles} from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';


const FilterButton = (props) => {
    const { text, color, fontColor, options, value, onChange, minWidth, margin, helperText } = props;

    const useStyles = makeStyles(() => ({
        customOutline: {
            "& .MuiInputLabel-root": {
                borderColor: '#D0D0D0',
                fontFamily: 'Poppins',
                color: '#808080',
                margin: 'auto',
                top: '-6px',
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${color}`,
            },
            "& .MuiSvgIcon-root": {
                color: `${color}`,
            },
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '&:hover fieldset': {
                    borderColor: '#0B4762',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0B4762',
                },
            },
            '& .MuiInputLabel-root.Mui-focused': {
                color: `${fontColor}`,
            }
        },
        HelperText: {
            paddingLeft: '.3rem',
            fontSize: '.6rem',
            color: '#B9B9B9',
            width: '200px',
            margin: '0',
        }
    }));

    const classes = useStyles();
    const [activeSelect, setActiveSelect] = useState(false);
    let arrayOptions = options;

    const handleLabel = () => {
        if (activeSelect) {
            setActiveSelect(false);
        } else {
            setActiveSelect(true);
        }
    }

    return (
        <Box sx={{ minWidth: minWidth ? minWidth : 120, margin: margin ? margin : 'auto' }}>
            <FormControl fullWidth classes={{ root: classes.customOutline }}>
                <InputLabel
                    id="filterSelect"
                    style={{
                        marginLeft: text.length > 12 ? '-4px' : '0px',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: '400',
                        color: `${color}`,
                    }}
                >{text}</InputLabel>
                <Select
                    labelId="filterSelect"
                    id="filter-select"
                    value={value}
                    label={text}
                    onChange={(e) => onChange(e)}
                    onClick={handleLabel}
                    sx={{
                        borderRadius: '10px',
                        height: '2.6rem',
                        '&.MuiInputBase-root.MuiOutlinedInput-root': {
                            fontFamily: 'Poppins, sans-serif !important',
                        }
                    }}
                    inputProps={{
                        style: {
                            color: "#041C32",
                            fontWeight: 400,
                        },
                    }}
                >
                    {
                        arrayOptions.map((option, index) =>
                            <MenuItem
                                onChange={ onChange }
                                value={ option }
                                key={ index }
                                sx={{ fontFamily: 'Poppins' }}
                            > {option} </MenuItem>
                        )
                    }
                </Select>
                <p className={classes.HelperText}>{helperText ? helperText : ''}</p>
            </FormControl>
        </Box>
    )
}

export default FilterButton;
