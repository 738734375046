import React from 'react';

const ChainDataIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4944 13.5057C16.4363 12.4481 15.0016 11.854 13.5057 11.854C12.0097 11.854 10.575 12.4481 9.51702 13.5057L5.52706 17.4943C4.46903 18.5524 3.87463 19.9874 3.87463 21.4836C3.87463 22.9799 4.46903 24.4149 5.52706 25.473C6.58509 26.531 8.02009 27.1254 9.51637 27.1254C11.0127 27.1254 12.4477 26.531 13.5057 25.473L15.5 23.4786"
                stroke="#F97F59" strokeWidth="2.58333" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M13.5057 17.4943C14.5637 18.5519 15.9984 19.146 17.4943 19.146C18.9903 19.146 20.425 18.5519 21.483 17.4943L25.473 13.5057C26.531 12.4476 27.1254 11.0126 27.1254 9.51636C27.1254 8.02008 26.531 6.58508 25.473 5.52705C24.4149 4.46901 22.9799 3.87462 21.4837 3.87462C19.9874 3.87462 18.5524 4.46901 17.4943 5.52705L15.5 7.52138"
                stroke="#F97F59" strokeWidth="2.58333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ChainDataIcon;
