import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Modal} from '@mui/material';
import PropTypes from "prop-types";

import './DeleteTableWithCombination.css'

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        width: '550px',
        left: '50%',
        top: '50%',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        fontFamily: 'Poppins, sans-serif',
        marginBottom: '1rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '400',
        fontSize: '.9rem',
        color: '#737171',
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '15px'
    },
    ProgChangeButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#fff !important',
        width: '150px',
        '&:hover': {
            backgroundColor: '#0B4762 !important',
        }
    },
    CancelChangeButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        width: '150px',
        '&:hover': {
            backgroundColor: 'white !important',
        }
    },
    list: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    listItem: {
        fontWeight: '400',
        fontSize: '.9rem',
        color: '#737171',
        margin: "0 0.5rem",
        textAlign: 'start',

    },
    ButtonsContainer: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
    }
}));

const ChangeTableNameModal = (props) => {

    const classes = useStyles();
    const {open} = props;
    const {setOpen, newTableValuesProposed, onAcceptAll, onAcceptCurrent} = props;

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.ModalContainer}>
                    <div className={classes.IconCross} onClick={() => setOpen(false)}>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <svg width="50" height="50" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z"
                                stroke="#EB252E" strokeWidth="5.25"/>
                            <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E"
                                  strokeWidth="5.25" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <h1 className={classes.Title}>
                            Atención
                        </h1>
                        <h3 className={classes.Subtitle}>
                            <span>¿Deseas cambiar el nombre de esta mesa en todos los layouts o solo en este?</span>
                        </h3>
                    </div>
                    <div className={classes.ButtonsContainer}>
                        <div className={classes.ButtonsComponents}>
                            <Button
                                className={classes.CancelChangeButton}
                                onClick={() => onAcceptCurrent(newTableValuesProposed)}
                            >Solo en este</Button>
                        </div>
                        <div className={classes.ButtonsComponents}>
                            <Button
                                className={classes.ProgChangeButton}
                                onClick={() => onAcceptAll(newTableValuesProposed)}
                            >En todos</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

ChangeTableNameModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onAcceptAll: PropTypes.func.isRequired,
    onAcceptCurrent: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ChangeTableNameModal
