import ShareMenu from "../Menu/ShareMenu/ShareMenu";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {PARTNERS} from "../../constants";
import GeneralIcon from "../SvgComponents/icons/GeneralIcon/GeneralIcon";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
    text: {
        margin: '0px',
        color: '#848282',
        fontWeight: '600',
        fontFamily: 'Montserrat'
    },
    links: {
        marginLeft: '20px',
        marginTop: '40px'
    }
}))


function QRsComponent(props) {
    const classes = useStyle();
    const {partner} = props;


    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [mesaYaLink, setMesaYaLink] = useState("");
    const [profileLink, setProfileLink] = useState("");
    const [menuLink, setMenuLink] = useState("");


    useEffect(() => {
        if (partner) {
            setMesaYaLink(partner.links?.mesaYa ? partner.links.mesaYa : "");
            setProfileLink(partner.links?.profile ? partner.links.profile : "");
            setMenuLink(partner.links?.menu ? partner.links.menu : "");
        }
    }, [partner])

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackBar(false);
    };

    return (
        <div>
            <div style={{display: 'flex'}}>
                <div>
                    <GeneralIcon name="Códigos QR" fontSize={40}/>
                </div>
                <div style={{marginLeft: '10px', paddingTop: '50px'}}>
                    <p className={classes.text}>Desde aquí podrás obtener todos los links</p>
                    <p className={classes.text}>de tu establecimiento.</p>
                </div>
            </div>
            <div className={classes.links}>
                <ShareMenu
                    url={mesaYaLink}
                    title={"Compartí para mesa ya"}
                    onCopy={(text, result) => result ? setOpenSnackBar(true) : console.log("No se copio el link")}
                />
                <ShareMenu
                    url={profileLink}
                    title={"Compartí tu perfil"}
                    onCopy={(text, result) => result ? setOpenSnackBar(true) : console.log("No se copio el link")}
                />
                <ShareMenu
                    url={menuLink}
                    title={"Compartí tu menu"}
                    onCopy={(text, result) => result ? setOpenSnackBar(true) : console.log("No se copio el link")}
                />
            </div>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Enlace a la carta copiado!
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        partner: state.firestore.ordered.partners ? state.firestore.ordered.partners[0] : null,
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        profile: state.firebase.profile,
    }
}

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => {
        return [
            {
                collection: PARTNERS,
                doc: props.idPartnerSelected,
            }];
    }))(QRsComponent)