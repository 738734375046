import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

// Actions
import {
    changeStatisticsResumeFilterDate,
    fetchStatisticsArriveResume,
    fetchStatisticsBestClients,
    fetchStatisticsResume,
    closeErrorDialog,
    fetchGraphicData,
    loadHomeData,
    resetAllData
} from "../../actions";

// Components
import ButtonWithIcon from '../NewComponents/ButtonWithIcon/ButtonWithIcon';
import CardsTop from '../NewComponents/Home/CardsTop/CardsTop';
import ClientSection from './ClientSection/ClientSection';
import DataSection from './DataSection/DataSection';
import ShareModal from './ShareModal/ShareModal';

// Icons
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

// Material Ui
import { makeStyles, Snackbar } from "@material-ui/core";
import GraphicsSection from './GraphicsSection/GraphicsSection';
import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../constants/modalMode";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import {Alert, Box} from "@mui/material";
import TimeFilterSelector from "../GeneralComponents/TimeFilterSelector/TimeFilterSelector";

const useStyles = makeStyles(() => ({
    ShareSection: {
        margin: '0rem 0 1rem 0',
        padding: '0',
        gap: '15px',
    },
    ShareContainer: {
        display: 'block',
        width: '100%',
        '@media(min-width: 1150px)': {
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
        }
    },
    CardsContainerHome: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        display: 'grid',
        gap: '20px'
    },
    DataSectionContainer: {
        gridTemplateColumns: '65% 35%',
        padding: '1.5rem 1.5rem 2rem 0rem',
        display: 'grid',
        gap: '25px',
        '@media (max-width: 1700px)': {
            gridTemplateColumns: '60% 40%',
        },
        '@media (max-width: 1300px)': {
            gridTemplateColumns: '55% 45%',
        }
    }
}));

const Home = (props) => {

    // Variables
    const { link, arriveStatistics, bestClients, statisticsResume, graphicData, from, to, error, loading, idPartnerSelected } = props;

    // Actions
    const {
        closeErrorDialog,
        loadHomeData,
        fetchStatisticsArriveResume,
        fetchStatisticsBestClients,
        fetchStatisticsResume,
        changeDate,
        fetchGraphicData,
        reset
    } = props;

    const classes = useStyles();
    const [openShareModal, setOpenShareModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (idPartnerSelected) {
            loadHomeData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPartnerSelected]);

    useEffect(() => {
        if (idPartnerSelected) {
            fetchStatisticsArriveResume()
            fetchStatisticsBestClients()
            fetchStatisticsResume()
            fetchGraphicData()
        } else {
            reset();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPartnerSelected, from, to]);

    const handleOpenCloseShareModal = () => {
        setOpenShareModal(!openShareModal);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const handleCopyLink = () => {
        setOpenSnackbar(true);
        setOpenShareModal(false);
    }

    return (
        <Box className={classes.GeneralContainerInfo}>
            <section className={classes.ShareSection}>
                <div className={classes.ShareContainer}>
                    <Box 
                        sx={{ 
                            marginBottom: '.7rem', 
                            justifyContent: 'end', 
                            padding: '.5rem 0',
                            display: 'flex', 
                            'media(min-width: 1150px)': { 
                                marginBottom: 0 
                            } 
                        }}
                    >
                        <ButtonWithIcon
                            margin="0"
                            icon={<ShareOutlinedIcon/>}
                            text={'Compartir perfil'}
                            onClick={handleOpenCloseShareModal}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TimeFilterSelector onChangeDate={(from, to) => changeDate({from: from, to: to})}/>
                    </Box>
                </div>
            </section>
            <section className={classes.CardsContainerHome}>
                <CardsTop
                    title={'Reservas totales'}
                    data={statisticsResume.total}
                    dataColor={'#35C18F'}
                    shadowColor={'rgba(53, 193, 143, 0.25)'}
                />
                <CardsTop
                    title={'Reservas ingresadas'}
                    data={statisticsResume.arrives}
                    dataColor={'#1AAFDE'}
                    shadowColor={'rgba(26, 175, 222, 0.25)'}
                />
                <CardsTop
                    title={'Solicitudes sin contestar'}
                    data={statisticsResume.noAnswer}
                    dataColor={'#F97F59'}
                    shadowColor={'rgba(249, 127, 89, 0.25)'}
                />
                <CardsTop
                    title={'No Show'}
                    data={statisticsResume.noShow}
                    dataColor={'#EB252E'}
                    shadowColor={'rgba(235, 37, 46, 0.25)'}
                />
            </section>
            <main className={classes.DataSectionContainer}>
                <DataSection data={arriveStatistics}/>
                <ClientSection clients={bestClients}/>
            </main>
            <section>
                <GraphicsSection data={graphicData}/>
            </section>
            <ShareModal
                open={openShareModal}
                link={link}
                onCopy={handleCopyLink}
                onCancel={handleOpenCloseShareModal}
            />
            <ErrorDialog 
                onAccept={closeErrorDialog}
                open={error !== undefined} 
                type={ModalMode.ERROR} 
                body={error ?? ""}
                title={"Error"} 
            />
            <ProgressIndicator open={loading}/>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Enlace copiado!
                </Alert>
            </Snackbar>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        arriveStatistics: state.statisticsResumeReducer.arriveStatistics,
        bestClients: state.statisticsResumeReducer.bestClients,
        statisticsResume: state.statisticsResumeReducer.statisticsResume,
        graphicData: state.statisticsResumeReducer.graphicData,
        from: state.statisticsResumeReducer.from,
        to: state.statisticsResumeReducer.to,

        link: state.homeReducer.link,

        error: state.homeReducer.error || state.statisticsResumeReducer.error,
        loading: state.homeReducer.loading || state.statisticsResumeReducer.loading,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadHomeData: () => dispatch(loadHomeData()),
        fetchStatisticsArriveResume: () => dispatch(fetchStatisticsArriveResume()),
        fetchStatisticsBestClients: () => dispatch(fetchStatisticsBestClients()),
        fetchStatisticsResume: () => dispatch(fetchStatisticsResume()),
        fetchGraphicData: () => dispatch(fetchGraphicData()),
        changeDate: ({from, to}) => dispatch(changeStatisticsResumeFilterDate({from, to})),
        closeErrorDialog: () => dispatch(closeErrorDialog()),
        reset: () => dispatch(resetAllData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
