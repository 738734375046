import React, { useRef, useState } from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { onDeleteDate, onDeleteSingleDate, setDeleteAllExcludeDates } from '../../../actions';

// Components
import CalendarDateButton from './CalendarDateButton';
import AddNewButton from './AddNewButton';
import DateButton from './DateButton';

// Material Ui
import { Box, Collapse, Grid, Menu, MenuItem } from '@mui/material';

// Styles
import { 
    DateButtonsContainerStyles, 
    AddDatesContainerStyles, 
    DatesContainerStyles 
} from '../Styles';
import moment from 'moment';

const RemovePunctualDate = ( props ) => {

    // Actions
    const { onDeleteDate, onDeleteSingleDate, setDeleteAllExcludeDates } = props;

    // States
    const { punctualDates } = props;

    const [typeExcludeCalendarOpen, setTypeExcludeCalendarOpen] = useState( '' );
    const [anchorEl, setAnchorEl] = useState( null );

    const excludeCalendar = useRef( null );

    const open = Boolean( anchorEl );

    const handleClick = (event) => {
        setAnchorEl( event.currentTarget );
    };
    
    const handleClose = (event) => {
        if ( event.target.innerText ) {
            setTypeExcludeCalendarOpen( event.target.innerText );
        };

        excludeCalendar.current.openCalendar();

        setAnchorEl(null);
    };
    
    const handleDeleteDate = ( date ) => {
        onDeleteDate({
            dateArray: 'excludeArray',
            id: date
        });

        if ( typeof date === 'string' ) {
            onDeleteSingleDate({
                dateArray: 'excludeArray',
                id: moment( date ).format('DD/MM/YYYY')
            });
    
            setDeleteAllExcludeDates({ 
                value: moment( date ).format('DD/MM/YYYY'), 
                typeDate: 'singleDate' 
            });
        } else {
            setDeleteAllExcludeDates({ 
                value: [ 
                    moment( date[0], 'YYYY-MM-DD' ).format(), 
                    moment( date[1], 'YYYY-MM-DD' ).format() 
                ], 
                typeDate: 'rangeDate' 
            });
        }

    };

    return (
        <Grid container spacing={ 3 } sx={ AddDatesContainerStyles }>
            <TransitionGroup style={{ display: 'flex', marginTop: '1rem' }}>
                <Box sx={ DateButtonsContainerStyles }>
                    <Box sx={ DatesContainerStyles }>
                        <CalendarDateButton
                            value={ punctualDates.newSingleDates } 
                            type={ typeExcludeCalendarOpen } 
                            reference={ excludeCalendar } 
                            dateArray='excludeArray'
                        />
                        <Menu
                            aria-labelledby="demo-positioned-button"
                            onClose={ handleClose }
                            anchorEl={ anchorEl }
                            open={ open }
                            anchorOrigin={{
                                horizontal: 'center',
                                vertical: 'top', 
                            }}
                            transformOrigin={{ 
                                horizontal: 'center', 
                                vertical: 'bottom', 
                            }}
                            sx={{
                                '& .MuiList-root': {
                                    p: '0'
                                },
                                '& .MuiPaper-root': {
                                    borderRadius: '.6rem',
                                },
                                '& .MuiButtonBase-root': {
                                    borderBottom: '1px solid #E7E7E7',
                                    fontFamily: 'Poppins',
                                    color: '#041C32',
                                    fontWeight: 400
                                }
                            }}
                        >
                            <MenuItem onClick={ handleClose } >Fecha única</MenuItem>
                            <MenuItem onClick={ handleClose } >Fechas múltiples</MenuItem>
                            <MenuItem onClick={ handleClose } >Rango</MenuItem>
                        </Menu>
                        <Box sx={{ marginTop: '-.3rem' }}>
                            <AddNewButton type='removeDate' onClick={ handleClick }/>
                        </Box>
                    </Box>
                    {
                        punctualDates.excludeDate.map( (date, index) =>
                            <Collapse key={ index } in={ index >= 0 ? true : false } orientation='horizontal' >
                                <DateButton
                                    onDelete={ () => handleDeleteDate( date ) }
                                    date={ date.length !== 2 ? 
                                        moment( date ).format('DD/MM/YYYY')
                                        :
                                        date 
                                    }
                                    key={ index }
                                />
                            </Collapse> 
                        )
                    }
                </Box>
            </TransitionGroup>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        punctualDates: state.planningReducer.punctualDates,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setDeleteAllExcludeDates: ({ value, typeDate }) => dispatch( setDeleteAllExcludeDates({ value, typeDate }) ),
        onDeleteSingleDate: ({ id, dateArray }) => dispatch( onDeleteSingleDate({ id, dateArray }) ),
        onDeleteDate: ({ dateArray, id }) => dispatch( onDeleteDate({ dateArray, id }) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( RemovePunctualDate );