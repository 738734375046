import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";

import {PARTNERS} from "../../constants";

import CategoriesDialog from "./Dialogs/CategoriesDialog";
import ProductsForm from "./ProductsForm";
import NewProductDialog from "./Dialogs/NewProductDialog";
import DeleteProductDialog from "./Dialogs/DeleteProductDialog";
import EditPricesDialog from "./Dialogs/EditPricesDialog";

const useStyles = makeStyles(() => ({
  appBar: {
    height: 56,
    background: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  headerButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  newProductButton: {
    marginTop: 10,
    marginBottom: 10,
    background: "#4caf50",
    color: "#fff",
    "&:hover": {
      background: "#357a38",
    },
  },
}));

function MenuCategoriesForm(props) {
  const classes = useStyles();

  // Variables
  const { categories, } = props;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.headerButtonContainer}/>
      <Grid item xs={12}>
        {categories ? <ProductsForm /> : null}
        <CategoriesDialog />
        <NewProductDialog />
        <DeleteProductDialog />
        <EditPricesDialog />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
    profile: state.firebase.profile,
    partner: state.firestore.ordered[PARTNERS],
    categories: state.menuReducer.categoriesReducer.categories,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    if (props.profile && props.idPartnerSelected) {
      return [
        {
          collection: PARTNERS,
          doc: props.idPartnerSelected,
          subcollections: [{ collection: "menu" }],
          orderBy: [
            ["order", "asc"],
            ["name", "asc"],
          ],
          storeAs: "menu",
        },
        {
          collection: PARTNERS,
          doc: props.idPartnerSelected,
        },
      ];
    }
    return [];
  })
)(MenuCategoriesForm);
