import React from 'react';

// Components
import IconSectionAppBar from '../../NewComponents/IconSectionAppBar/IconSectionAppBar';
import { AppBarStyled } from './StyledComponents';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// Material Ui
import { Box, Collapse, IconButton, Toolbar, Typography } from '@mui/material';

// Styles
import { 
    TitlesContainerAppbarStyles, 
    AppbarSubtitleStyles, 
    AppbarTextsContainer, 
    AppbarTitleStyles, 
} from '../Styles';

const AppBar = ( props ) => {

    const {
        primaryTitle,
        drawerOpen,
        isActive,
        iconImg,
        onClick,
        title,
        open,
    } = props;

    return (
        <AppBarStyled position="fixed" open={ open } isActive={ isActive }>
            <Toolbar sx={{ margin: 'auto 0' }}>
                <Box sx={ AppbarTextsContainer }>
                    <Collapse in={ drawerOpen } orientation='horizontal' >
                        <Box>
                            <IconButton
                                aria-label="open drawer"
                                onClick={ onClick }
                                color="inherit"
                                edge="start"
                                sx={{ 
                                    padding: '5.5px',
                                    color: '#0B4762' 
                                }}
                            >
                                <ChevronLeftIcon sx={{ color: '#0B4762', fontSize: '30px' }} />
                            </IconButton>
                        </Box>
                    </Collapse>
                    <IconSectionAppBar icon={ iconImg } />
                    <Box sx={ TitlesContainerAppbarStyles }>
                        <Typography variant="h5" sx={ AppbarTitleStyles }>
                            { title }
                        </Typography>
                        <Typography variant="h5" sx={ AppbarSubtitleStyles }>
                            { primaryTitle }
                        </Typography>
                    </Box>
                </Box>
            </Toolbar>
        </AppBarStyled>
    )
};

export default AppBar;
