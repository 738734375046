import React from 'react';

import { connect } from 'react-redux';

// Actions
import { changeShiftsViewMode, resetTurnoData } from '../../../actions/shiftsActions';

// Components
import NoData from '../../../components/V3/NoData';
import Data from '../../../components/V3/Data';

// Constants
import { SHIFTS_VIEW_MODE } from '../../../constants/shiftsConstants';

// Icon
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Material Ui
import { Box } from '@mui/material';

function ShiftsViewPage(props) {
  // Variables
  const { 
    shiftArray,
    loading,
  } = props;

  // Actions
  const {    
    changeShiftsViewMode,
    resetTurnoData,
  } = props;

  const handleCreateTurno = () => {
    changeShiftsViewMode( SHIFTS_VIEW_MODE.NEW );
    resetTurnoData();
  }

  return (
    <Box sx={{ backgroundColor: '#FCFCFC' }}>
      {
        loading ? <div></div> : ( shiftArray.length === 0 ? (
          <Box>
            <NoData
              description="Aún no tenés turnos creados. Hacé click en crear turno y configuralos"
              buttonOnClick={() => handleCreateTurno()}
              ButtonIcon={AddCircleOutlineIcon}
              title="Aún no tenés turnos"
              buttonText="Crear turno"
              collection="Shifts"
            />   
          </Box>   
      ) : (
        <Box>
          <Data
            buttonOnClick={() => handleCreateTurno()}
            ButtonIcon={ AddCircleOutlineIcon }
            buttonText="Crear turno"
          />
        </Box>
      ))
    }      
    </Box>    
  );
};

const mapStateToProps = (state) => {
  return {
    shiftArray: state.v3.shifts.shiftArray,
    loading: state.v3.shifts.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeShiftsViewMode: (data) => dispatch(changeShiftsViewMode(data)),
    resetTurnoData: () => dispatch(resetTurnoData()),    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsViewPage);
