import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Material Ui
import { FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { ShiftSelectWithArrowsStyles } from '../../../../Styles';

const ShiftSelectWithArrows = ({ error = false, handleChange, label, disabled, value, optionsArray }) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                width: 'max-content',
                marginTop: '.2rem',
                minWidth: '78px',
            },
        },
    };

    const handleIncrease = () => {
        if ( value < 100 ) {
            handleChange( value + 1 );
        };
    };

    const handleDecrease = () => {
        if ( value > 1 ) {
            handleChange( value - 1 );
        };
    };

    const handleChangeValues = () => {
        return (
            <Grid 
                item 
                sx={{ 
                    flexDirection: 'column', 
                    display: 'flex', 
                    p: '0 .2rem', 
                    gap: '2px', 
                }}
            >
                <ExpandLessIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#0B4762',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '.9rem', 
                    }}
                    onClick={ disabled ? null : handleIncrease } 
                />
                <ExpandMoreIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#0B4762',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '.9rem', 
                    }}
                    onClick={ disabled ? null : handleDecrease } 
                />
            </Grid>
        )
    };

    return (
        <FormControl error={ error } sx={{ width: '5rem' }}>
            <InputLabel 
                sx={{ 
                    fontFamily: 'Poppins',
                    zIndex: 0,
                    '&.Mui-focused': {
                        color: '#0B4762',
                    }, 
                }} 
                id="select-label"
            >
                { label }
            </InputLabel>
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                input={ <OutlinedInput label={ label }/> }
                IconComponent={ handleChangeValues }
                sx={ ShiftSelectWithArrowsStyles }
                MenuProps={ MenuProps }
                labelId='select-label'
                disabled={ disabled }
                value={ value }
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            value={ item.value } 
                            key={ item.value } 
                            sx={{ 
                                padding: '.5rem', 
                                height: '2.5rem' 
                            }}
                        >
                            <ListItemText 
                                primary={ item.option }
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ShiftSelectWithArrows;