import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid,} from '@material-ui/core';
import {connect} from 'react-redux';
import Signin from '../../components/Forms/Signin';
import ForgetPassword from '../../components/Forms/ForgetPassword';
import {changeShowSidebar} from "../../actions/sidebarActions";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        height: window.innerHeight + 'px',
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(to right, #4DB09E, #34766A) no-repeat 0 0 / cover',
        height: window.innerHeight + 'px',
    },
    textField: {
        width: '100%',
        margin: '10px',
    },
    welcomeTitle: {
        color: '#fff',
        marginBottom: '50px',
    },
    signUpButton: {
        color: '#fff',
        borderRadius: '20px',
        border: 'solid 1px #fff',
        background: 'rgba(0, 0, 0, 0)',
        height: '40px',
        width: '50%',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
        },
    },
}));

function LoginPage(props) {
    const classes = useStyles();
    const { currentForm } = props;

    useEffect(() => {
        props.changeShowSidebar(false);
    }, []);


    const getCurrentForm = (index) => {
        switch (index) {
            case 0:
                return <Signin />;
            case 2:
                return <ForgetPassword />;
            default:
                break;
        }

    }

    return (
        <Grid className={classes.mainContainer} container justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                {getCurrentForm(currentForm)}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        currentForm: state.authReducer.currentForm,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
