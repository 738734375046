import React from 'react';

const JcbIcon = (props) => {
    return (
        <svg width="70" height="40" viewBox="0 0 70 53" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M52.7285 31.8969H57.7801C57.9244 31.8969 58.2612 31.8488 58.4055 31.8488C59.3677 31.6563 60.1856 30.7903 60.1856 29.5876C60.1856 28.433 59.3677 27.567 58.4055 27.3264C58.2612 27.2783 57.9725 27.2783 57.7801 27.2783H52.7285V31.8969Z" fill={props.fill}/>
            <path d="M57.2028 0C52.3918 0 48.4467 3.89691 48.4467 8.75601V17.8488H60.811C61.0997 17.8488 61.4364 17.8488 61.677 17.8969C64.4674 18.0412 66.5361 19.4845 66.5361 21.9862C66.5361 23.9588 65.1409 25.6426 62.543 25.9794V26.0756C65.3814 26.268 67.5464 27.8557 67.5464 30.3093C67.5464 32.9553 65.1409 34.6873 61.9656 34.6873H48.3986V52.4879H61.244C66.055 52.4879 70 48.591 70 43.7319V0H57.2028Z" fill={props.fill}/>
            <path d="M59.5601 22.5634C59.5601 21.4087 58.7423 20.639 57.7801 20.4946C57.6839 20.4946 57.4433 20.4465 57.299 20.4465H52.7285V24.6802H57.299C57.4433 24.6802 57.732 24.6802 57.7801 24.6321C58.7423 24.4878 59.5601 23.718 59.5601 22.5634Z" fill={props.fill}/>
            <path d="M8.80412 0C3.99313 0 0.0481101 3.89691 0.0481101 8.75601V30.3574C2.50172 31.5601 5.05154 32.3299 7.60137 32.3299C10.6323 32.3299 12.268 30.5017 12.268 28V17.8007H19.7732V27.9519C19.7732 31.8969 17.3196 35.1203 8.99656 35.1203C3.94501 35.1203 0 34.0137 0 34.0137V52.4398H12.8454C17.6563 52.4398 21.6014 48.5429 21.6014 43.6838V0H8.80412Z" fill={props.fill}/>
            <path d="M33.0035 0C28.1925 0 24.2475 3.89691 24.2475 8.75601V20.2062C26.4605 18.3299 30.3093 17.1271 36.5155 17.4158C39.8351 17.5601 43.3952 18.4742 43.3952 18.4742V22.1787C41.6152 21.2646 39.4983 20.4467 36.7561 20.2543C32.0413 19.9175 29.2028 22.2268 29.2028 26.268C29.2028 30.3574 32.0413 32.6667 36.7561 32.2818C39.4983 32.0893 41.6152 31.2234 43.3952 30.3574V34.0618C43.3952 34.0618 39.8832 34.9759 36.5155 35.1203C30.3093 35.4089 26.4605 34.2062 24.2475 32.3299V52.5361H37.0928C41.9038 52.5361 45.8488 48.6392 45.8488 43.78V0H33.0035Z" fill={props.fill}/>
        </svg>
    )
}

export default JcbIcon;