import React from 'react';

const CordobesaIcon = (props) => {
    return (
        <svg width="115" height="23" viewBox="0 0 115 23" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_48_795)">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.9682 12.3698C20.9682 12.0292 20.9699 11.6877 20.9682 11.3462C20.9593 9.86719 20.0655 9.18328 18.6843 9.77292C17.9951 10.0673 17.3165 10.6311 16.8896 11.2501C15.6472 13.0502 15.3421 15.1499 15.2745 17.2924C15.2488 18.1212 15.4115 18.9492 16.2261 19.2569C17.0995 19.586 18.0128 19.5175 18.7643 18.7518C20.5173 16.9669 20.8703 14.7168 20.9682 12.3698ZM11.7883 20.0814C9.40129 22.2896 6.65231 23.0375 3.51825 22.351C1.98145 22.0139 0.127161 19.7478 0.0373366 18.0092C-0.0925084 15.5146 0.0684636 13.0902 1.36335 10.8126C2.61111 8.61765 4.38714 7.29697 6.78571 6.61039C8.50927 6.11681 10.0719 6.62374 11.6513 7.08264C12.5033 7.33077 12.6296 8.01557 12.1129 8.77507C12.0142 8.91914 11.9546 9.0899 11.8586 9.23575C11.3596 9.98814 11.0012 10.4782 9.8344 9.87697C8.28604 9.07923 6.44776 9.60661 5.37787 11.4058C4.18526 13.413 3.54048 15.543 4.12034 17.8758C4.40137 19.0044 5.60466 19.7808 6.62118 19.3619C8.05215 18.7722 9.38795 17.9434 10.746 17.1847C11.3321 16.8566 11.3676 16.2794 11.429 15.6426C11.8221 11.4991 14.3389 7.12088 19.2428 6.44498C21.8602 6.0839 24.2899 7.0773 24.8404 10.4284C25.4096 13.8968 24.5442 16.9464 22.3733 19.7132C20.5173 22.0779 18.0724 22.7663 15.2425 22.56C13.9636 22.4666 12.7701 21.5844 11.7883 20.0814Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M82.1153 13.4628C83.6201 13.4628 85.0199 12.971 85.743 12.0728C86.1601 11.5534 86.4838 10.9913 86.1627 10.2122C85.8372 9.42428 85.3018 9.30778 84.5797 9.36025C83.0144 9.47231 82.1722 10.3385 81.3033 12.3716C80.8507 13.4326 80.9209 13.4628 82.1153 13.4628ZM93.8476 22.5573C92.0298 22.6827 90.4405 22.1535 88.8779 21.4509C88.5809 21.3175 88.1318 21.3273 87.824 21.4501C85.4237 22.4043 82.9895 22.9282 80.4024 22.3456C79.0275 22.0361 77.1768 20.2832 76.9713 18.8709C76.3915 14.8795 77.2488 11.2795 80.2913 8.47713C82.2861 6.63973 84.7273 5.92381 87.3847 6.73311C88.765 7.15289 89.7406 8.22989 89.9407 9.71065C90.2048 11.6565 89.6694 13.4006 87.9948 14.5754C86.4358 15.6675 84.6331 16.0944 82.745 16.2011C82.1278 16.2358 81.5114 16.3221 80.9049 16.4404C80.7848 16.4635 80.6399 16.7347 80.6301 16.8984C80.5172 18.79 82.158 19.7167 83.9678 19.2889C85.1942 18.9981 86.3548 18.4361 87.5626 18.0501C87.9877 17.9149 88.4021 17.9149 88.9019 18.1559C90.2279 18.7953 91.6207 19.3103 93.0169 19.7861C94.1322 20.1667 95.6334 19.4686 96.1074 18.4645C96.4783 17.6783 96.2293 16.9775 95.4342 16.5497C94.4381 16.0144 93.4554 15.4532 92.4478 14.9409C90.6388 14.0231 90.1915 11.9251 91.0728 9.96056C92.3357 7.14488 94.6142 5.98428 97.6576 6.64062C98.3175 6.78203 98.9898 6.88075 99.631 7.08085C100.434 7.33254 100.753 7.85458 100.321 8.68168C100.208 8.89779 100.118 9.1388 100.079 9.37804C99.9361 10.2612 99.6328 10.351 98.7808 9.95522C97.9297 9.56035 96.9078 9.48476 95.9491 9.38515C95.0384 9.28999 94.4924 9.96945 94.2994 10.7049C94.1295 11.3497 94.3101 12.0274 95.1149 12.4027C96.2026 12.9114 97.2058 13.5989 98.2819 14.1369C99.4247 14.7088 99.6942 15.6453 99.7671 16.8308C99.8916 18.8478 99.2949 20.4816 97.6006 21.6279C96.4516 22.4052 95.1594 22.7014 93.8476 22.5573Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.3257 19.3789C41.256 19.2802 41.8252 18.438 42.3899 17.4579C43.6875 15.2087 43.6608 12.6127 44.4052 10.2231C44.4363 10.1235 44.2807 9.85932 44.1819 9.84242C43.4811 9.72147 42.7519 9.4938 42.0697 9.5854C40.3497 9.81574 39.6614 11.2387 39.2149 12.6341C38.7409 14.1184 38.5301 15.6943 38.2918 17.2445C38.1032 18.462 38.9161 19.3931 40.3257 19.3789ZM42.7501 20.2327C41.9079 20.8614 41.0915 21.7463 40.0812 22.1715C37.6532 23.1915 35.5215 22.577 34.9185 20.3252C34.5458 18.9351 34.8349 17.3628 34.8633 15.8731C34.8971 14.0926 35.4654 12.4251 36.2534 10.875C37.3455 8.7254 38.893 7.05254 41.433 6.51181C42.5402 6.27702 43.5274 6.50114 44.4834 6.88356C44.9912 7.08722 45.0188 7.16281 45.1264 6.56784C45.4359 4.8514 45.8183 3.14296 46.2728 1.45853C46.3769 1.07434 46.8313 0.653674 47.2217 0.504263C47.9136 0.240127 48.6767 0.15386 49.4149 0.0249046C49.8853 -0.0569155 50.2411 0.0400234 50.113 0.662567C49.8471 1.95835 49.6034 3.25946 49.3339 4.55524C48.8403 6.93247 48.3405 9.30792 47.8318 11.6825C47.4049 13.6702 46.8838 15.641 46.5565 17.6447C46.3929 18.6496 46.4889 19.7204 46.6205 20.7414C46.6855 21.2376 46.6757 21.5062 46.1954 21.6628C45.4928 21.8913 44.752 22.0416 44.1001 22.3698C43.4536 22.6953 43.1556 22.6348 43.0498 21.8975C42.9671 21.3257 42.8461 20.7601 42.7501 20.2327Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M72.4223 11.9591C72.4223 11.7696 72.4232 11.5802 72.4223 11.3916C72.4161 9.83707 71.6192 9.36749 70.0727 9.74902C68.6782 10.0923 67.996 11.0608 67.6732 12.2419C67.1129 14.2936 66.7412 16.3969 66.3098 18.4824C66.2662 18.6932 66.2778 19.0258 66.4076 19.145C67.5682 20.2113 69.7605 19.9161 70.5778 18.5749C71.8131 16.5481 72.256 14.2891 72.4223 11.9591ZM68.2788 8.62844C69.2811 7.51053 70.3092 6.87198 71.6575 6.51535C73.518 6.02176 75.7796 7.44828 76.0686 9.59339C76.4697 12.5594 75.9424 15.4106 74.6733 18.0466C73.6985 20.0726 72.2098 21.7526 69.8361 22.3609C68.1027 22.8055 66.4592 22.601 64.8362 21.9331C64.5774 21.8264 64.2937 21.7792 64.0402 21.6627C62.1717 20.8045 61.9476 20.5253 62.3789 18.655C63.31 14.6173 64.0607 10.5388 64.9109 6.48156C65.255 4.84516 65.7673 3.23721 66.0074 1.58747C66.1328 0.729251 66.598 0.606521 67.2152 0.490017C67.7808 0.383295 68.3615 0.341495 68.9192 0.205425C69.9464 -0.044482 70.1313 0.070244 69.9135 1.08588C69.5106 2.96507 69.0374 4.83004 68.6106 6.70389C68.4834 7.26418 68.4131 7.83781 68.2788 8.62844Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M104.756 17.0887C104.756 17.5432 104.673 18.0199 104.783 18.4459C104.874 18.7963 105.183 19.3477 105.414 19.3566C106.085 19.385 106.885 19.3726 107.417 19.0346C108.551 18.3161 109.186 17.1261 109.522 15.841C109.955 14.1913 110.307 12.5202 110.645 10.8482C110.691 10.6187 110.533 10.239 110.343 10.0878C109.003 9.02147 107.526 8.98679 106.576 10.4533C105.277 12.4597 104.775 14.716 104.756 17.0887ZM109.098 20.5074C107.972 21.1415 106.878 21.9268 105.663 22.4026C103.638 23.1959 101.466 21.9037 101.099 19.7968C100.46 16.1309 101.162 12.6891 103.174 9.58887C104.223 7.97204 105.732 6.68426 107.718 6.43258C109.157 6.25115 110.524 6.77587 111.482 8.04852C111.923 7.0071 113.893 6.34364 114.701 6.96174C114.924 7.13249 115.047 7.64476 114.982 7.95069C114.334 10.9798 113.575 13.9858 112.985 17.0256C112.778 18.0955 112.962 19.2392 112.947 20.3482C112.938 20.9396 113.15 21.6138 112.144 21.724C111.576 21.7854 111.015 22.1198 110.5 22.4177C109.715 22.8704 109.46 22.424 109.31 21.789C109.197 21.3132 109.148 20.8222 109.098 20.5074Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M52.3853 16.7019C52.3853 17.1573 52.2608 17.6616 52.4173 18.0546C52.6147 18.5509 52.9527 19.1939 53.3849 19.354C54.4566 19.7506 55.4909 19.1219 56.2753 18.1569C57.5213 16.6246 57.7418 14.7801 58.0602 12.9622C58.1749 12.3032 58.2746 11.5277 58.0326 10.9505C57.8005 10.3956 57.1851 9.79349 56.6186 9.6334C55.6901 9.37105 54.7634 9.84507 54.2387 10.6384C53.0345 12.4615 52.2964 14.4652 52.3853 16.7019ZM61.9502 12.2347C62.0009 15.5582 60.9293 18.4068 58.4684 20.7129C56.3998 22.6499 53.0363 23.1889 50.8102 21.9953C49.4504 21.2661 48.7345 20.1117 48.4899 18.5331C47.9768 15.2052 48.875 12.289 50.7978 9.6334C51.9869 7.99078 53.5815 6.89777 55.627 6.65231C56.5759 6.53758 57.5711 6.50557 58.5084 6.65942C60.8323 7.04006 61.6719 8.77429 61.9404 10.7558C62.0054 11.2405 61.9502 11.7412 61.9502 12.2347Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M30.7581 9.50903C31.5052 8.75042 32.0877 8.11098 32.7218 7.52667C33.5791 6.73782 34.6054 6.33495 35.7847 6.4141C36.458 6.46034 36.723 6.79207 36.4286 7.45908C36.0738 8.26216 35.7234 9.06614 35.3774 9.87277C35.1986 10.2899 35.1426 10.5967 34.456 10.3281C33.4022 9.91635 32.27 10.6234 31.426 11.8516C30.3526 13.4168 30.0066 15.2267 29.6224 17.0258C29.3023 18.5261 29.0328 20.0389 28.6699 21.5286C28.6112 21.7696 28.1799 22.0915 27.9309 22.0835C26.9117 22.0497 25.8969 21.903 24.8804 21.798C24.9409 21.268 24.9525 20.7264 25.0707 20.2097C25.8178 16.9493 26.5969 13.697 27.3404 10.4366C27.4222 10.0773 27.3759 9.67801 27.3368 9.30359C27.1669 7.68676 27.1589 7.68587 28.6557 7.02419C28.8149 6.95393 28.991 6.92103 29.1582 6.86944C29.5379 6.75205 29.9168 6.63288 30.2965 6.5146C30.4504 7.02063 30.6496 7.51867 30.7439 8.03538C30.8168 8.43737 30.7581 8.86425 30.7581 9.50903Z" fill={props.fill}/>
            </g>
            <defs>
                <clipPath id="clip0_48_795">
                    <rect width="115" height="22.6489" fill={props.fill}/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default CordobesaIcon;