import { dwellTimeGroups } from "./constants";

export const AllSameDwellTime = (dwellTimeByGroup) => {
  let val = "";
  let same = true;

  dwellTimeGroups.forEach((dwellTime) => {
    if (val === "") {
      val = dwellTimeByGroup[dwellTime.value];
    } else {
      if (val !== dwellTimeByGroup[dwellTime.value]) {
        same = false;
      }
    }
  });

  return {
    isSame: same,
    value: val,
  };
};

export const SetAllDwellTime = (
  allDwellTime,
  dwellTimeByGroup,
  setDwellTimeByGroup
) => {
  dwellTimeGroups.map(
    (group) => (dwellTimeByGroup[group.value] = allDwellTime)
  );

  setDwellTimeByGroup(dwellTimeByGroup);
};

export const GetAdvancedModalOptions = (
  layoutId,
  tables,
  object,
  defaultValue,
  type
) => {
  let existsAll = false;
  let allValue = "";
  let count = 0;

  tables.map((table) => {
    if (
      typeof object !== "undefined" &&
      object &&
      typeof object[table.id] !== "undefined"
    ) {
      if (allValue === "") {
        allValue = object[table.id];
        count++;
      } else {
        if (allValue === object[table.id]) {
          count++;
        }
      }
    }
  });

  if (tables.length === count) {
    existsAll = true;
  }

  let name = "Todos";
  let min = "";

  if (type === "group") {
    name = "";
    min = "Todos";
  }

  const resTables = [
    {
      id: 0,
      active: existsAll,
      name: name,
      min: min,
      max: "",
      value: existsAll ? allValue : defaultValue,
      layoutId: layoutId,
    },
  ];

  tables.map((table) => {
    let active = false;
    let value = "";

    if (
      typeof object !== "undefined" &&
      object &&
      typeof object[table.id] !== "undefined"
    ) {
      if (!existsAll) {
        active = true;
      }
      value = object[table.id];
    }

    resTables.push({
      id: table.id,
      active: active,
      name: table.alias,
      min: table.minCapacity,
      max: table.maxCapacity,
      value: value === "" ? defaultValue : value,
      layoutId: layoutId,
    });
  });

  return resTables;
};

export const SetDwellTimeTables = (
  dwellTimeByTable,
  dwellTimeTables,
  setDwellTimeByTable
) => {
  const todos = dwellTimeTables.find(
    (dwellTimeTable) => dwellTimeTable.id === 0
  ).active;
  const dwellTime = dwellTimeTables.find(
    (dwellTimeTable) => dwellTimeTable.id === 0
  ).value;

  let newDwellTimeByTable = dwellTimeByTable;

  dwellTimeTables.map((dwellTimeTable) => {
    if (todos) {
      if (dwellTimeTable.id !== 0) {
        if (newDwellTimeByTable !== null) {
          if (typeof newDwellTimeByTable[dwellTimeTable.id] !== "undefined") {
            newDwellTimeByTable = UpdateKey(
              dwellTimeTable.id,
              dwellTimeTable.value,
              newDwellTimeByTable
            );
          } else {
            newDwellTimeByTable = AddKey(
              dwellTimeTable.id,
              dwellTime,
              newDwellTimeByTable
            );
          }
        } else {
          newDwellTimeByTable = AddKeyToNull(dwellTimeTable.id, dwellTime);
        }
      }
    } else {
      if (dwellTimeTable.id !== 0) {
        if (newDwellTimeByTable !== null) {
          if (typeof newDwellTimeByTable[dwellTimeTable.id] !== "undefined") {
            if (dwellTimeTable.active) {
              newDwellTimeByTable = UpdateKey(
                dwellTimeTable.id,
                dwellTimeTable.value,
                newDwellTimeByTable
              );
            } else {
              newDwellTimeByTable = RemoveKey(
                newDwellTimeByTable,
                dwellTimeTable.id
              );
            }
          } else {
            if (dwellTimeTable.active) {
              newDwellTimeByTable = AddKey(
                dwellTimeTable.id,
                dwellTimeTable.value,
                newDwellTimeByTable
              );
            }
          }
        } else {
          if (dwellTimeTable.active) {
            newDwellTimeByTable = AddKeyToNull(
              dwellTimeTable.id,
              dwellTimeTable.value
            );
          }
        }
      }
    }
  });

  setDwellTimeByTable(newDwellTimeByTable);
};

const AddKeyToNull = (key, value) => {
  const newPair = { [key]: value };
  return Object.assign({}, newPair);
};

const AddKey = (key, value, dwellTimeByTable) => {
  const newPair = { [key]: value };
  return Object.assign(dwellTimeByTable, newPair);
};

const UpdateKey = (key, value, dwellTimeByTable) => {
  dwellTimeByTable[key] = value;

  return dwellTimeByTable;
};

const RemoveKey = (dwellTimeByTable, id) => {
  delete dwellTimeByTable[id];
  return dwellTimeByTable;
};

export const SetRotationControlEmpty = () => {
  return {
    maxEfficiency: false,
    rotationControlByGroup: {},
    rotationControlByTable: {},
    advanceEndRotationControl: 0,
  };
};

export const SetDwellTimeTableLabel = (reservableSectors, dwellTimeByTable) => {
  let resArray = [];

  reservableSectors.map((reservableSector) => {
    let dwellTime = "";
    let sameDwellTime = false;
    let dwellTimeTableCount = 0;
    const tableCount = reservableSector.tables.length;
    const reservableArray = [];

    reservableSector.tables.map((table) => {
      if (
        dwellTimeByTable &&
        typeof dwellTimeByTable[table.id] !== "undefined"
      ) {
        if (dwellTime === "") {
          dwellTime = dwellTimeByTable[table.id];
          sameDwellTime = true;
        } else {
          if (dwellTime !== dwellTimeByTable[table.id]) {
            sameDwellTime = false;
            dwellTime = dwellTimeByTable[table.id];
          }
        }

        dwellTimeTableCount++;

        reservableArray.push({
          id: table.id,
          layoutName: reservableSector.name,
          tableName: table.alias,
          time: dwellTime,
        });
      }
    });

    // Todos iguales
    if (sameDwellTime && dwellTimeTableCount === tableCount) {
      const todosArray = [];

      todosArray.push({
        id: reservableSector.id,
        layoutName: reservableSector.name,
        tableName: "Todas",
        time: dwellTime,
      });

      resArray = [...resArray, ...todosArray];
    } else {
      resArray = [...resArray, ...reservableArray];
    }
  });

  return resArray;
};

export const GetOptionalsArray = (allOptionals, shiftOptionals) => {
  const newArray = allOptionals.map((optional) => {
    const existOption = shiftOptionals.some(
      (shiftOptional) => shiftOptional === optional.id
    );
    return {
      id: optional.id,
      name: optional.name,
      checked: existOption,
    };
  });

  return newArray;
};

export const UpdateOptionals = (id, checked, optionals, setOptionals) => {
  const optionalsActives = optionals
    .filter((optional) => optional.checked)
    .map((optional) => optional.id);
  if (checked) {
    optionalsActives.push(id);
  } else {
    optionalsActives.splice(optionalsActives.indexOf(id), 1);
  }
  setOptionals(optionalsActives);
};

export const GetDwellTimeOptions = (
  dwellTimeByGroup,
  dwellTimeGroups,
  rotationControlByGroup,
  defaultValue
) => {
  const reservableArray = [];

  dwellTimeGroups.map((option) => {
    reservableArray.push({
      id: option.value,
      active: false,
      alias: "",
      minCapacity: option.value === "-1" ? "12" : option.value,
      maxCapacity: dwellTimeByGroup[option.value],
      value: defaultValue,
    });
  });

  let rotation = "";
  let sameRotation = false;

  const newArray = reservableArray.map((item) => {
    if (rotation === "") {
      if (
        rotationControlByGroup &&
        typeof rotationControlByGroup[item.value] !== "undefined"
      ) {
        rotation = rotationControlByGroup[item.value];
        sameRotation = true;

        return {
          ...item,
          value: rotation,
          active: true,
        };
      } else {
        return item;
      }
    } else {
      if (
        typeof rotationControlByGroup[item.value] !== "undefined" &&
        rotation !== rotationControlByGroup[item.value]
      ) {
        sameRotation = false;

        return {
          ...item,
          value: rotationControlByGroup[item.value],
          active: true,
        };
      } else {
        return item;
      }
    }
  });

  // if (sameRotation) {
  //     const todos = {
  //         id: 0,
  //         active: true,
  //         alias: '',
  //         minCapacity: 'Todos',
  //         maxCapacity: '',
  //         value: rotation,
  //     }

  //     return [todos, ...reservableArray];
  // } else {
  //     return newArray;
  // }
  return reservableArray;
};

export const SetRotationControlByGroups = (
  id,
  active,
  rotationControlByGroup,
  value
) => {
  // Todos
  if (id === 0) {
    if (active) {
      dwellTimeGroups.map((item) => {
        if (
          typeof rotationControlByGroup !== "undefined" &&
          rotationControlByGroup !== null
        ) {
          rotationControlByGroup = AddKey(
            item.value,
            value,
            rotationControlByGroup
          );
        } else {
          rotationControlByGroup = AddKeyToNull(item.value, value);
        }
      });
    } else {
      dwellTimeGroups.map((item) => {
        rotationControlByGroup = RemoveKey(rotationControlByGroup, item.value);
      });
    }
  } else {
    if (active) {
      if (
        rotationControlByGroup &&
        typeof rotationControlByGroup[id] !== "undefined"
      ) {
        rotationControlByGroup[id] = value;
      } else {
        if (
          typeof rotationControlByGroup !== "undefined" &&
          rotationControlByGroup !== null
        ) {
          rotationControlByGroup = AddKey(id, value, rotationControlByGroup);
        } else {
          rotationControlByGroup = AddKeyToNull(id, value);
        }
      }
    } else {
      rotationControlByGroup = RemoveKey(rotationControlByGroup, id);
    }
  }

  return rotationControlByGroup;
};

export const GetGroupArray = (rotationControlByGroup) => {
  const reservableArray = [];
  let rotation = "";
  let sameRotation = false;

  dwellTimeGroups.map((option) => {
    if (
      rotationControlByGroup &&
      typeof rotationControlByGroup[option.value] !== "undefined"
    ) {
      if (rotation === "") {
        rotation = rotationControlByGroup[option.value];
        sameRotation = true;
      } else {
        if (rotation !== rotationControlByGroup[option.value]) {
          sameRotation = false;
        }
      }

      reservableArray.push({
        id: option.value === "-1" ? 12 : option.value,
        value: rotationControlByGroup[option.value],
      });
    }
  });

  // console.log("reservableArray: ", reservableArray);

  if (reservableArray.length === dwellTimeGroups.length && sameRotation) {
    return [
      {
        id: "Todos",
        value: rotation,
      },
    ];
  } else {
    return reservableArray;
  }
};

export const GetTableArray = (reservableSectors, rotationControlByTable) => {
  let resArray = [];

  reservableSectors &&
    reservableSectors.map((reservableSector) => {
      const reservableArray = [];
      let rotation = "";
      let sameRotation = false;

      reservableSector.tables.map((option) => {
        if (
          rotationControlByTable &&
          typeof rotationControlByTable[option.id] !== "undefined"
        ) {
          if (rotation === "") {
            rotation = rotationControlByTable[option.id];
            sameRotation = true;
          } else {
            if (rotation !== rotationControlByTable[option.id]) {
              sameRotation = false;
            }
          }

          reservableArray.push({
            id: option.alias,
            value: rotationControlByTable[option.id],
          });
        }
      });

      if (
        reservableArray.length === reservableSector.tables.length &&
        sameRotation
      ) {
        resArray.push({
          id: "Todas",
          value: rotation,
        });
      } else {
        resArray = [...resArray, ...reservableArray];
      }
    });

  return resArray;
};

export const GetTablesOptions = (
  tables,
  rotationControlByTable,
  tableRotationOptionsDefaultValue
) => {
  const reservableArray = [];

  tables.map((option) => {
    reservableArray.push({
      id: option.id,
      active:
        rotationControlByTable &&
        typeof rotationControlByTable[option.id] !== "undefined",
      alias: option.alias,
      minCapacity: option.minCapacity,
      maxCapacity: option.maxCapacity,
      value:
        rotationControlByTable &&
        typeof rotationControlByTable[option.id] !== "undefined"
          ? rotationControlByTable[option.id]
          : tableRotationOptionsDefaultValue,
    });
  });

  return reservableArray;
};

export const SetRotationControlByTables = (
  id,
  active,
  controlTable,
  value,
  tables
) => {
  // Todos
  if (id === 0) {
    if (active) {
      tables.map((item) => {
        if (typeof controlTable !== "undefined" && controlTable !== null) {
          controlTable = AddKey(item.id, value, controlTable);
        } else {
          controlTable = AddKeyToNull(item.id, value);
        }
      });
    } else {
      tables.map((item) => {
        controlTable = RemoveKey(controlTable, item.id);
      });
    }
  } else {
    if (active) {
      if (controlTable && typeof controlTable[id] !== "undefined") {
        controlTable[id] = value;
      } else {
        if (typeof controlTable !== "undefined" && controlTable !== null) {
          controlTable = AddKey(id, value, controlTable);
        } else {
          controlTable = AddKeyToNull(id, value);
        }
      }
    } else {
      controlTable = RemoveKey(controlTable, id);
    }
  }

  return controlTable;
};

export const SetRotationControlGroup = (
  rotationControlGroup,
  id,
  active,
  controlValue,
  setRotationControlGroup
) => {
  const newRotationControlGroup = rotationControlGroup.map(
    (rotationControl) => {
      if (rotationControl.id === id) {
        return {
          ...rotationControl,
          active: active,
          value: controlValue,
        };
      } else {
        return rotationControl;
      }
    }
  );

  setRotationControlGroup(newRotationControlGroup);
};

export const ResetAllRotationControlGroup = (
  rotationControlGroup,
  active,
  controlValue,
  setRotationControlGroup
) => {
  const newRotationControlGroup = rotationControlGroup.map(
    (rotationControl) => {
      return {
        ...rotationControl,
        active: active,
        value: controlValue,
      };
    }
  );

  setRotationControlGroup(newRotationControlGroup);
};

export const ResetAllRotationControlTables = (
  rotationControlTable,
  active,
  controlValue,
  setRotationControlTable
) => {
  const newRotationControlTable = rotationControlTable.map(
    (rotationControl) => {
      return {
        ...rotationControl,
        active: active,
        value: controlValue,
      };
    }
  );

  setRotationControlTable(newRotationControlTable);
};

export const SetRotationControlTable = (
  rotationControlTable,
  id,
  active,
  controlValue,
  setRotationControlTable
) => {
  const newRotationControlTable = rotationControlTable.map((rotation) => {
    if (rotation.id === id) {
      return {
        ...rotation,
        active: active,
        value: controlValue,
      };
    } else {
      return rotation;
    }
  });
  setRotationControlTable(newRotationControlTable);
};

export const GetRotationControlByTable = (
  rotationControlTable,
  rotationControlByTable,
  setRotationControlByTable,
  tables
) => {
  let newRotationControlByTable = rotationControlByTable;
  const todos = rotationControlTable.find((rotation) => rotation.id === 0);
  if (todos.active) {
    newRotationControlByTable = SetRotationControlByTables(
      0,
      todos.active,
      newRotationControlByTable,
      todos.value,
      tables
    );
  } else {
    rotationControlTable.map((rotation) => {
      newRotationControlByTable = SetRotationControlByTables(
        rotation.id,
        rotation.active,
        newRotationControlByTable,
        rotation.value,
        tables
      );
    });
  }

  setRotationControlByTable(newRotationControlByTable);
};

export const GetRotationControlByGroups = (
  rotationControlGroup,
  rotationControlByGroup,
  setRotationControlByGroup
) => {
  let newRotationControlByGroup = rotationControlByGroup;
  const todos = rotationControlGroup.find((rotation) => rotation.id === 0);

  if (todos.active) {
    newRotationControlByGroup = SetRotationControlByGroups(
      0,
      todos.active,
      newRotationControlByGroup,
      todos.value
    );
  } else {
    rotationControlGroup.map((rotation) => {
      newRotationControlByGroup = SetRotationControlByGroups(
        rotation.id,
        rotation.active,
        newRotationControlByGroup,
        rotation.value
      );
    });
  }

  setRotationControlByGroup(newRotationControlByGroup);
};
