import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar } from '../../actions/sidebarActions';
import {  
    setClientsPeriodSelected,
    fetchClientsStatitics, 
    changeRowsPerPage, 
    fetchStatistics, 
    fetchFileExport, 
    changeOrder, 
    changeQuery, 
    changePage,
} from '../../actions';

// Components
import { BoxTableContainer, DateFilterContainer } from '../../components/MyBookings/Section3/components/Table/Style/Section3TableStyles';
import { headCellsClientsTable, moreInfoClientsSelectOptions } from '../../components/MyBookings/Section3/helpers';
import GeneralTableSection3 from '../../components/MyBookings/Section3/components/Table/GeneralTableSection3';
import TableTopSection3 from '../../components/MyBookings/Section3/components/Table/TableTopSection3';
import DateRangeSelector from '../../components/MyBookings/components/DateRangeSelector';
import Sidebar from '../../components/MyBookings/components/Sidebar';

// Material Ui
import { Box } from '@mui/material';

const MyClientsPage = ( props ) => {

    // Actions
    const { 
        changePrimaryTitleAppBar,
        setClientsPeriodSelected,
        fetchClientsStatitics,
        changeTitleAppBar, 
        changeShowSidebar, 
        changeRowsPerPage,
        exportStatistics,
        changeOrder,
        changePage, 
    } = props;

    // States
    const { 
        idPartnerSelected,
        dateRangeSelected,
        countrySelected,
        moreInfoFilters,
        totalResults,
        rowsPerPage,
        statistics,
        orderType,
        orderBy,
        loading,
        query,
        // error,
        page,
    } = props;

    const [selectedPeriod, setSelectedPeriod] = useState('');

    useEffect(() => {
        changePrimaryTitleAppBar("Visualización de tus clientes");
        changeTitleAppBar("Mis clientes");
        changeShowSidebar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if ( idPartnerSelected ) {
            fetchClientsStatitics();
            // fetchStatistics();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRangeSelected,
        idPartnerSelected, 
        moreInfoFilters,
        countrySelected, 
        rowsPerPage, 
        orderType, 
        orderBy, 
        query, 
        page, 
    ]);

    const handleChangePage = (event, newPage) => {
        changePage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        changeRowsPerPage(parseInt(event.target.value, 10));
        changePage(0);
    };

    const handleChangeOrder = (orderBy, orderType) => {
        changeOrder({orderBy, orderType});
    };

    return (
        <Box sx={ BoxTableContainer }>
            <Box sx={ DateFilterContainer }>
                <DateRangeSelector
                    setDateRange={ setClientsPeriodSelected }
                    setSelectedPeriod={ setSelectedPeriod }
                    selectedPeriod={ selectedPeriod }
                    dateRange={ dateRangeSelected }
                    component={ 'clients' }
                />
            </Box>
            <TableTopSection3
                totalResults={ totalResults ?? statistics.length } 
                handleChangeRowsPerPage={ handleChangeRowsPerPage } 
                moreInfoArray={ moreInfoClientsSelectOptions }
                handleExportStatitics={ exportStatistics }
                handleChangePage={ handleChangePage }
                rowsPerPage={ rowsPerPage }
                component={ 'clients' }
                page={ page }
            />
            <GeneralTableSection3 
                headCellsTable={ headCellsClientsTable }
                onChangeOrder={ handleChangeOrder }
                component={ 'clients' }
                items={ statistics }
                orderBy={ orderBy }
                order={ orderType }
                loading={ loading }
            />
            <Sidebar component={ 'clients' } bookings={ statistics } />
        </Box> 
    )
};

const mapStateToProps = state => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        dateRangeSelected: state.clientsStatisticsReducer.dateRangeSelected,
        moreInfoFilters: state.clientsStatisticsReducer.moreInfoFilters,
        countrySelected: state.clientsStatisticsReducer.countrySelected,
        totalResults: state.clientsStatisticsReducer.totalResults,
        rowsPerPage: state.clientsStatisticsReducer.rowsPerPage,
        statistics: state.clientsStatisticsReducer.statistics,
        orderType: state.clientsStatisticsReducer.orderType,
        loading: state.clientsStatisticsReducer.loading,
        orderBy: state.clientsStatisticsReducer.orderBy,
        error: state.clientsStatisticsReducer.error,
        query: state.clientsStatisticsReducer.query,
        page: state.clientsStatisticsReducer.page,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeOrder: ({orderBy, orderType}) => dispatch(changeOrder({orderBy, orderType})),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        changeRowsPerPage: (rowsPerPage) => dispatch(changeRowsPerPage(rowsPerPage)),
        setClientsPeriodSelected: (data) => dispatch(setClientsPeriodSelected(data)),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        fetchClientsStatitics: () => dispatch(fetchClientsStatitics()),
        exportStatistics: () => dispatch(fetchFileExport()),
        fetchStatistics: () => dispatch(fetchStatistics()),
        onSearch: (query) => dispatch(changeQuery(query)),
        changePage: (page) => dispatch(changePage(page)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( MyClientsPage );
