export const SECTOR_TYPE = {
    ADENTRO: 'ADENTRO',
    SALON: 'SALON',
    CAVA: 'CAVA',
    TERRAZA: 'TERRAZA',
    PATIO: 'PATIO',
    BAR: 'BAR',
    VIP: 'VIP',
}

export const typeToString = (value) => {
    switch (value) {
        case SECTOR_TYPE.SALON:
            return "Salón";
        case SECTOR_TYPE.ADENTRO:
            return "Adentro";
        case SECTOR_TYPE.BAR:
            return "Bar";
        case SECTOR_TYPE.TERRAZA:
            return "Terraza";
        case SECTOR_TYPE.PATIO:
            return "Patio";
        case SECTOR_TYPE.VIP:
            return "VIP";
        case SECTOR_TYPE.CAVA:
            return "Cava";
        default:
            return value;
    }
}

export const stringToType = (value) => {
    switch (value) {
        case "Salón":
            return SECTOR_TYPE.SALON;
        case "Adentro":
            return SECTOR_TYPE.ADENTRO;
        case "Bar":
            return SECTOR_TYPE.BAR;
        case "Terraza":
            return SECTOR_TYPE.TERRAZA;
        case "Patio":
            return SECTOR_TYPE.PATIO;
        case "VIP":
            return SECTOR_TYPE.VIP;
        case "Cava":
            return SECTOR_TYPE.CAVA;
        default:
            return value;
    }
}
