import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Material Ui
import { Grid, Typography } from '@mui/material';

const SelectWithArrows = ({ 
    handleIncrease, 
    handleDecrease, 
    singularText,
    pluralText,
    disabled,
    amount, 
}) => {
    return (
        <Grid 
            container 
            sx={{ 
                justifyContent: 'space-between',
                p: '.2rem .5rem .2rem .8rem',
                border: '1px solid #D1D1D1',
                borderRadius: '.5rem',
                alignItems: 'center',
                width: '150px', 
                gap: '5px',
            }}
        >
            <Grid item >
                <Typography 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#0B4762',
                        fontFamily: 'Poppins', 
                        fontSize: '.9rem',
                        fontWeight: 500 
                    }}
                >
                    { amount } { amount > 1 ? pluralText : singularText }
                </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <ExpandLessIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#0B4762',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '.9rem', 
                    }}
                    onClick={ disabled ? null : handleIncrease } 
                />
                <ExpandMoreIcon 
                    sx={{ 
                        color: disabled ? '#D1D1D1' : '#0B4762',
                        cursor: disabled ? 'auto' : 'pointer', 
                        fontSize: '.9rem', 
                    }}
                    onClick={ disabled ? null : handleDecrease } 
                />
            </Grid>
        </Grid>
    )
}

export default SelectWithArrows;