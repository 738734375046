import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// Actions
import { getAllOptionals } from "../../../../../actions/optionalActions";
import { setOptionals } from "../../../../../actions/shiftsActions";

// Material Ui
import { Box, Switch, Typography } from "@mui/material";

// Utils
import { GetOptionalsArray, UpdateOptionals } from "../utils/advancedOptionsFunctions";

// Styles
import { DecriptionShiftStyles, SubtitleShiftStyles } from "../../../Styles";

const AdvancedOptionsOptionals = (props) => {
  // Variables
  const { optionalArray, shiftOptionals } = props;

  // Acciones
  const { setOptionals, getOptionals } = props;

  const [options, setOptions] = useState(optionalArray || []);

  useEffect(() => {
    // setOption(GetOptionalsArray(optionals, OPTIONS))
    getOptionals();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (optionalArray && shiftOptionals && optionalArray.length > 0) {
      const newOptions = GetOptionalsArray(optionalArray, shiftOptionals);
      setOptions(newOptions);
    }
  }, [optionalArray, shiftOptionals]);

  const handleChangeOptional = (id, checked) => {
    UpdateOptionals(id, !checked, options, setOptionals);
  };

  return (
    <>
      <Box margin="40px 0 24px 0">
        <Typography sx={ SubtitleShiftStyles } >Opcionales de la reserva</Typography>
        <Typography sx={ DecriptionShiftStyles } >
          Activá los opcionales disponibles para las reservas (Máximo 3)
        </Typography>
      </Box>
      {options.map((option) => (
        <Box
          key={option.id}
          ml="-.7rem"
          mt={1}
          display="flex"
          alignItems="center"
        >
          <Switch
            checked={option.checked}
            onClick={() => handleChangeOptional(option.id, option.checked)}
            color="default"
            disabled={
              options.filter((option) => option.checked).length >= 3 &&
              !option.checked
            }
            sx={{
              ".MuiSwitch-switchBase": {
                marginLeft: "4px",
                color: "#FFFFFF",
                marginTop: "4px",
                "&.Mui-checked": {
                  marginLeft: "-1.5px",
                  color: "#FFFFFF",
                },
                "&.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#0B4762",
                  opacity: "100%",
                },
                "&.MuiSwitch-colorSecondary.Mui-checked:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
              ".MuiSwitch-thumb": {
                height: 17.5,
                width: 17.5,
              },
              ".MuiSwitch-track": {
                borderRadius: "10px",
                height: "1.22rem",
              },
            }}
          />
          <Box sx={{ paddingTop: "12px", paddingBottom: "4px" }}>
            <Typography 
              sx={{ 
                fontFamily: 'Poppins', 
                fontSize: '1.1rem', 
                fontWeight: 500, 
                color: '#041C32' 
              }}
            >
              { option.name }
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    optionalArray: state.optionalReducer.optionalArray,
    shiftOptionals: state.v3.shifts.shiftData.optionals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOptionals: (data) => dispatch(setOptionals(data)),
    getOptionals: () => dispatch(getAllOptionals()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedOptionsOptionals);
