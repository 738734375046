import {
    ADD_NEW_IMAGE,
    EDIT_PHOTOS_ERROR,
    EDIT_PHOTOS_SUCCESS,
    REMOVE_IMAGE,
    REPLACE_FACADE_IMAGE,
    TOGGLE_PHOTOS_LOADING,
} from '../../constants';
import axios from "axios";

export const editPhotosSuccess = (data) => ({
    type: EDIT_PHOTOS_SUCCESS,
    payload: data
});
export const editPhotosError = (data) => ({
    type: EDIT_PHOTOS_ERROR,
    payload: data,
});
export const togglePhotosLoading = () => ({
    type: TOGGLE_PHOTOS_LOADING
});

export const addNewImage = (data) => ({
    type: ADD_NEW_IMAGE,
    payload: data,
});
export const replaceFacadeImage = (data) => ({
    type: REPLACE_FACADE_IMAGE,
    payload: data,
});
export const removeImage = (data) => ({
    type: REMOVE_IMAGE,
    payload: data,
});

async function deleteImagesFromStorage(oldImagesToRemove, firebaseReact) {
    for (let i = 0; i < oldImagesToRemove.length; i++) {
        const imageRef = firebaseReact.storage().refFromURL(oldImagesToRemove[i]);
        await imageRef.delete();
    }
}

async function uploadImage( imageBits, type, name, firebaseReact, id ) {
    const newImage = new File([imageBits], name, { type: type });
    const storageRef = firebaseReact.storage().ref();
    const imageRef = storageRef.child( 'facadeImages/' + id + '/' + name );
    const snap = await imageRef.put( newImage );
    return await snap.ref.getDownloadURL();
}

export const updateImages = () => {
    return async ( dispatch, getState, { getFirebase }) => {
        const firebaseReact = getFirebase();
        const { photosReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { images, oldImagesToRemove } = photosReducer;
        const photos = [...images];
        let newImages = [];
        dispatch(togglePhotosLoading());
        try {
            // Subir imagenes nuevas
            for (let i = 0; i < images.length; i++) {
                if (images[i] && typeof images[i] !== "string") {
                    const imageName = i === 0 ? idPartnerSelected : images[i].name;
                    photos[i] = await uploadImage( images[i], images[i].type, imageName, firebaseReact, idPartnerSelected );
                    if (i === 0) {
                        await uploadImage( images[i], images[i].type, idPartnerSelected + '.png', firebaseReact, idPartnerSelected );
                    }
                    newImages.push(photos[i]);
                }
            }

            // Actualiza la BD a traves del backend
            try {
                const auth = firebaseReact.auth();
                const API_URL = process.env.REACT_APP_API_URL;
                const token = await auth.currentUser.getIdToken(true);
                await axios.patch(`${API_URL}/establishment/general-info/${ idPartnerSelected }`, {
                    photos: photos,
                }, {headers: {Authorization: `Bearer ${token}`}})
            } catch (e) {
                await deleteImagesFromStorage(newImages, firebaseReact);
                throw e;
            }

            // Eliminar posibles imagenes viejas
            await deleteImagesFromStorage(oldImagesToRemove, firebaseReact);
            dispatch(editPhotosSuccess(photos));
        } catch (e) {
            if (e.response?.data) {
                dispatch(editPhotosError(e.response.data));
            } else {
                dispatch(editPhotosError(e.message));
            }
        }
    }
}


/*export const removeTablesServerImage = (imgUrl) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const firestoreReact = getFirestore();
        const {firebase} = getState();
        const partnerId = firebase.profile.partner;
        const imageRef = firebaseReact.storage().refFromURL(imgUrl);
        dispatch(togglePhotosLoading());
        try {
            await imageRef.delete();
            await firestoreReact.collection(PARTNERS).doc(partnerId).update({
                tableImagesUrl: firebaseReact.firestore.FieldValue.arrayRemove(imgUrl),
            });
        } catch (e) {
        }
        dispatch(togglePhotosLoading());
    }
}*/

/*export const loadNewTables = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const firestoreReact = getFirestore();
        const {photosReducer, firebase} = getState();

        const partnerId = firebase.profile.partner;
        const newTables = photosReducer.editReducer.tablesNewImages;

        dispatch(toggleTablesLoading());

        try {
            if (newTables && newTables.length > 0) {
                let urls = [];
                for (let i = 0; i < newTables.length; i++) {
                    const newImage = new File([newTables[i]], newTables[i].name, {type: newTables[i].type});
                    const storageRef = firebaseReact.storage().ref();
                    const imageRef = storageRef.child('tableImages/' + partnerId + '/' + newTables[i].name);
                    const snap = await imageRef.put(newImage);
                    const url = await snap.ref.getDownloadURL();
                    urls.push(url);

                }
                await firestoreReact.collection(PARTNERS).doc(partnerId).update({
                    tableImagesUrl: firebaseReact.firestore.FieldValue.arrayUnion(...urls),
                });
            }
        } catch (e) {
            editPhotosError("Ha ocurrido un error al subir las imágenes.");
        }
    }
}*/

