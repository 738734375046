import React from 'react'

const CubiertosIcon = ( props ) => {
    const { fill } = props;

    return (
        <svg width="11" height="1.5rem" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.91667 15.0671C9.71597 15.0671 9.54763 14.9991 9.41163 14.8631C9.27563 14.7271 9.20786 14.5589 9.20833 14.3587V9.40039H8.5C8.11042 9.40039 7.77679 9.26156 7.49913 8.98389C7.22146 8.70623 7.08286 8.37284 7.08333 7.98373V4.44206C7.08333 3.4622 7.42876 2.62684 8.11963 1.93598C8.81049 1.24512 9.64561 0.899921 10.625 0.900393V14.3587C10.625 14.5594 10.557 14.7278 10.421 14.8638C10.285 14.9998 10.1169 15.0675 9.91667 15.0671ZM2.83333 15.0671C2.63264 15.0671 2.46429 14.9991 2.32829 14.8631C2.19229 14.7271 2.12453 14.5589 2.125 14.3587V8.58581C1.52292 8.42053 1.01811 8.08998 0.610584 7.59414C0.203056 7.09831 -0.000471402 6.51984 8.1983e-07 5.85873V1.60873C8.1983e-07 1.40803 0.0680009 1.23968 0.204001 1.10368C0.340001 0.967685 0.508112 0.899921 0.708334 0.900393C0.909029 0.900393 1.07738 0.968393 1.21338 1.10439C1.34938 1.24039 1.41714 1.4085 1.41667 1.60873V5.85873H2.125V1.60873C2.125 1.40803 2.193 1.23968 2.329 1.10368C2.465 0.967685 2.63311 0.899921 2.83333 0.900393C3.03403 0.900393 3.20238 0.968393 3.33838 1.10439C3.47438 1.24039 3.54214 1.4085 3.54167 1.60873V5.85873H4.25V1.60873C4.25 1.40803 4.318 1.23968 4.454 1.10368C4.59 0.967685 4.75811 0.899921 4.95833 0.900393C5.15903 0.900393 5.32738 0.968393 5.46338 1.10439C5.59938 1.24039 5.66714 1.4085 5.66667 1.60873V5.85873C5.66667 6.51984 5.4629 7.09831 5.05538 7.59414C4.64785 8.08998 4.14328 8.42053 3.54167 8.58581V14.3587C3.54167 14.5594 3.47367 14.7278 3.33767 14.8638C3.20167 14.9998 3.03356 15.0675 2.83333 15.0671Z" 
                fill={fill}
            />
        </svg>
    )
}

export default CubiertosIcon
