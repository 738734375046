import React, {useEffect, useState} from 'react';
import moment from "moment";
import {ModalMode} from "../../../constants/modalMode";

// Material Ui
import {Box, Button, Modal} from '@mui/material';
import {makeStyles} from '@material-ui/core/styles';


import CustomCalendar from "../../GeneralComponents/CustomCalendar";

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(-50%, -50%)',
        top: "50%",
        left: "50%",
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        width: '580px',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        margin: '.2rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#737171',
    },
    TopContainer: {
        padding: '.5rem 0 0 0',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
    },
    TextContainer: {
        padding: '1rem 0',
    },
    Text: {
        fontWeight: '500',
        color: '#737171',
        fontSize: '1rem',
        margin: '.2rem'
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '25px'
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    ApplyButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: '#0B4762 !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#0B4762 !important',
        }
    },
    InputDate: {
        height: '40px',
        color: '#0B4762',
        fontFamily: 'Poppins, sans-serif',

    },
    InputDateOutline: {
        borderColor: '#0B4762 !important',
        borderRadius: '8px',
        color: '#0B4762',
    },
    helperText: {
        fontSize: '20px',
        fontFamily: 'Poppins, sans-serif',
        color: '#EB252E',
    }
}));

const DatePickerDialog = (props) => {

    const classes = useStyles();
    const {open, onAccept, onCancel, advanceStart, minDate, type} = props;
    let dateAdvanceStart = moment(minDate).add(advanceStart, 'minutes');
    const [date, setDate] = useState(moment(minDate).isAfter(dateAdvanceStart) ? moment(minDate) : dateAdvanceStart);
    const [errorDate, setErrorDate] = useState(" ");
    let firstDate = moment(minDate).isSameOrBefore(dateAdvanceStart) ? moment(minDate) : dateAdvanceStart

    const handleChangeDate = (e) => {
        if (e) {
            setDate(moment(e));
            if (moment(e).isAfter(dateAdvanceStart)) {
                setErrorDate(" ");
            }
        }
    }

    const submit = () => {

        if (date.isSameOrAfter(firstDate)) {
            onAccept(moment(date).startOf('date').toISOString());
        } else {
            console.log("ERROR")
            setErrorDate("La fecha debe ser mayor o igual a " + moment(firstDate).format("DD/MM/YYYY"));
        }
    }

    useEffect(() => {
        dateAdvanceStart = moment(minDate).add(advanceStart, 'minutes');
        setDate(moment(minDate).isAfter(dateAdvanceStart) ? moment(minDate) : dateAdvanceStart)
        firstDate = moment(minDate).isSameOrBefore(dateAdvanceStart) ? moment(minDate) : dateAdvanceStart
    }, [minDate, advanceStart])

    return (
        <Modal
            open={open}
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.ModalContainer}>
                <div className={classes.IconCross} onClick={onCancel}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className={classes.TopContainer}
                     style={{
                         display: type === ModalMode.PUBLISH ? "block" : "flex",
                         justifyContent: 'center',
                         alignItems: 'stretch',
                         marginBottom: '-15px'
                     }}>
                    <h1 className={classes.Title}>
                        {type === ModalMode.PUBLISH ? "Publicar el" : "Aplicar cambio el"}
                    </h1>
                    <CustomCalendar
                        date={date}
                        minDate={minDate}
                        errorDate={errorDate}
                        onChange={handleChangeDate}
                        showLeft={true}
                    />
                    {/*<LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            mask='__/__/____'
                            inputFormat='dd/MM/yyyy'
                            minDate={minDate}
                            value={date}
                            onChange={handleChangeDate}
                            InputAdornmentProps={{
                                sx: {
                                    svg: {
                                        fill: '#0B4762'
                                    },
                                }
                            }}
                            InputProps={{
                                classes: {notchedOutline: classes.InputDateOutline, root: classes.InputDate},
                            }}
                            // onError={(err) => setErrorDate(err)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Seleccionar fecha"
                                    size={'small'}
                                    error={errorDate !== " "}
                                    helperText={errorDate}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: '#EB252E !important',
                                            fontSize: '10px',
                                            fontFamily: 'Poppins, sans-serif',
                                            marginLeft: '0px'
                                        }
                                    }}
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        classes: {
                                            root: classes.InputDateOutline,
                                        }
                                    }}
                                />
                            }
                        />
                    </LocalizationProvider>*/}
                </div>
                {type !== ModalMode.PUBLISH && <div className={classes.TextContainer}
                                                    style={{display: type === ModalMode.PUBLISH ? "none" : "block"}}>
                    <p className={classes.Text}>Recomendacion: Seleccionar una fecha posterior a</p>
                    <p className={classes.Text}>
                        <span
                            style={{fontWeight: '600', color: '#0B4762'}}>{dateAdvanceStart.format("DD-MM-YYYY")}</span>
                    </p>
                    <p className={classes.Text}>{`para no ${type === ModalMode.DELETE ? "eliminar" : "afectar"} reservas ya aceptadas.`}</p>
                </div>}
                <div className={classes.ButtonsComponents}
                     style={{marginTop: type === ModalMode.PUBLISH ? '20px' : undefined}}>
                    <Button
                        className={classes.ContinuesButton}
                        onClick={onCancel}
                    >Cancelar</Button>
                    <Button
                        className={classes.ApplyButton}
                        onClick={submit}
                    >
                        {type === ModalMode.PUBLISH ? "Publicar" : "Aplicar"}
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DatePickerDialog;
