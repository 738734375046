import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { setPlanningName, setPlanningSelected, setRestartHours, setShowPlanningSnackbar } from '../../../actions';
import { patchPlanning, putPlannings } from '../../../actions/planningActions/PlanningBackInteractions';


// Helpers
import { PlanningOptionsArray } from '../helpers';

// Components
import OptionalTextField from '../../Optional/components/OptionalTextField';
import PlanningSnackbar from '../components/PlanningSnackbar';
import PlanningSelect from '../components/PlanningSelect';

// Material Ui
import { Box, Button, Collapse, Typography } from '@mui/material';

// Styles
import { OptionalSubtitleStyles, OptionalTitleStyles, PlanningMainContainer } from '../Styles';
import { SaveOptionalButtonStyles } from '../../Optional/Styles/OptionalStyles';
import { DegradeComponentStyles } from '../../Politics/Styles';

const CreatePlanningLayout = ( props ) => {

    // Actions
    const { 
        setShowPlanningSnackbar, 
        setPlanningSelected, 
        setPlanningName, 
        setRestartHours,
        patchPlanning, 
        putPlannings, 
    } = props;

    // External props
    const { children } = props;

    // States
    const { 
        planningSelected,
        planningSnackbar, 
        showSaveChanges, 
        planningIdEdit,
        punctualDates,
        planningName,
        errorMessage,
        viewMode, 
    } = props;

    const [disabledButton, setDisabledButton] = useState( false );
    const [error, setError] = useState({
        textField: false,
        errorForm: false,
        select: false,
    });
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = (event) => {
            setScrollTop(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSetPlanningName = ( e ) => {
        setPlanningName( e );
    };

    const handleSaveChanges = () => {
        switch ( true ) {
            case ( planningName === '' && planningSelected !== '' ):
                setDisabledButton( true );
                setError({
                    ...error,
                    textField: true
                });
                break;
            case ( planningName !== '' && planningSelected === '' ):
                setDisabledButton( true );
                setError({
                    ...error,
                    select: true
                });
                break;
            case ( planningName !== '' && planningSelected !== '' && error.errorForm ):
                setDisabledButton( true );
                break;
            default:
                if ( viewMode === 'EDIT' ) {
                    patchPlanning( planningIdEdit, 'form' );
                } else {
                    putPlannings();
                };
        
                setError({
                    textField: false,
                    errorForm: false,
                    select: false
                });
                break;
        };
    };

    useEffect(() => {
        if ( typeof errorMessage === 'string' && errorMessage !== '' ) {
            if ( errorMessage.includes('Special dates cannot be empty') ) {
                setShowPlanningSnackbar({
                    snackbarMessage: 'Es necesario ingresar al menos una Fecha especial',
                    typeSnackbar: 'Error',
                    showSnackbar: true,
                });
            } else if ( errorMessage.includes('This interval overlaps with another interval') ) {
                setShowPlanningSnackbar({
                    snackbarMessage: 'Hay superposición con un horario existente',
                    typeSnackbar: 'Error',
                    showSnackbar: true,
                });
            }
        }

        if ( punctualDates.newDates === [] && planningSelected === 'Fechas especiales' ) {
            setShowPlanningSnackbar({
                planningSnackbar: {
                    snackbarMessage: 'Es necesario ingresar al menos una Fecha especial',
                    typeSnackbar: 'Error',
                    showSnackbar: true,
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ errorMessage ]);

    useEffect(() => {
        if ( planningName !== '' ) {
            setDisabledButton( false )
        }
    }, [ planningName ]);
    
    
    const handleCloseSnackbar = () => {
        setShowPlanningSnackbar({
            ...planningSnackbar,
            showSnackbar: false,
        });
    };
    
    const handleChangePlanningSelected = ( value ) => {
        setPlanningSelected( value );
        setRestartHours();
    }

    return (
        <Box sx={ PlanningMainContainer }>
            { scrollTop > 50 && 
                <Collapse orientation='vertical' in={ scrollTop > 50 }>
                    <Box sx={ DegradeComponentStyles } ></Box>
                </Collapse> 
            }
            <Typography variant='h4' sx={ OptionalTitleStyles }>Horarios de apertura y cierre</Typography>
            <Box>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Nombre y tipo de horario</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <Box sx={{ width: '65%' }}>
                        <OptionalTextField 
                            handleChange={ handleSetPlanningName }
                            error={ error.textField }
                            value={ planningName }
                            placeholder='Horario 1'
                        />

                    </Box>
                    <Box sx={{ width: '35%' }}>
                        <PlanningSelect 
                            handleChange={ handleChangePlanningSelected }   
                            optionsArray={ PlanningOptionsArray }
                            value={ planningSelected }
                            error={ error.select }
                        />
                    </Box>
                </Box>
            </Box>
            {
                children
            }
            <Box 
                sx={{
                    display: showSaveChanges ? 'block' : 'none',
                    transform: 'translateY(-50%)',
                    position: 'fixed',
                    bottom: '5%',
                    left: '50%',
                }}
            >
                <Button
                    sx={ SaveOptionalButtonStyles } 
                    onClick={ handleSaveChanges }
                    disabled={ disabledButton } 
                >
                    { viewMode === 'EDIT' ? 'Guardar cambios' : 'Agregar horario' }
                </Button>
            </Box>
            {/* <PlanningModal
                description='Si abandonas la sección, se eliminará toda tu configuración del horario'
                backButtonText='Continuar sin guardar'
                show={ showModal.editCreateModal }
                nextButtonText='Guardar cambios'
                title='¿Continuar sin guardar?'
                onClick={ discardChanges }
                setShow={ setShowModal }
                type='editCreate'
                color='blue'
            /> */}
            <PlanningSnackbar
                message={ planningSnackbar.snackbarMessage }
                handleClose={ () => handleCloseSnackbar() }
                type={ planningSnackbar.typeSnackbar }
                open={ planningSnackbar.showSnackbar }
            />
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        planningSelected: state.planningReducer.planningSelected,
        planningSnackbar: state.planningReducer.planningSnackbar,
        specialDatesForm: state.planningReducer.specialDatesForm,
        showSaveChanges: state.planningReducer.showSaveChanges,
        planningIdEdit: state.planningReducer.planningIdEdit,
        punctualDates: state.planningReducer.punctualDates,
        planningName: state.planningReducer.planningName,
        errorMessage: state.planningReducer.error,
        viewMode: state.planningReducer.viewMode,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setShowPlanningSnackbar: ( planningSnackbar ) => dispatch( setShowPlanningSnackbar( planningSnackbar ) ),
        patchPlanning: ( id, typePatch ) => dispatch( patchPlanning( id, typePatch ) ),
        setPlanningSelected: ( data ) => dispatch( setPlanningSelected( data ) ),
        setPlanningName: ( data ) => dispatch( setPlanningName( data ) ),
        setRestartHours: () => dispatch( setRestartHours() ),
        putPlannings: () => dispatch( putPlannings() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( CreatePlanningLayout );