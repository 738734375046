import { AlarmIcon, ClockIcon, GrearIcon, SectorsV2Icon, SettingsIcon } from "../../../../SvgComponents/icons";
import { AdvancedOptionsStep, GeneralStep, ScheduleStep, SectorsStep, WaitListStep } from "../wizardSteps";
import { GetHHMMWithText } from "./functions";

export const anticipoMinimo = [
    {
      id: 0,
      val: '0 min'
    },
    {
      id: 15,
      val: '15 min'
    },
    {
      id: 30,
      val: '30 min'
    },
    {
      id: 45,
      val: '45 min'
    },
    {
      id: 60,
      val: '1 h'
    },
    {
      id: 120,
      val: '2 hs'
    },
    {
      id: 180,
      val: '3 hs'
    },
    {
      id: 240,
      val: '4 hs'
    },
    {
      id: 300,
      val: '5 hs'
    },
    {
      id: 360,
      val: '6 hs'
    },
    {
      id: 420,
      val: '7 hs'
    },
    {
      id: 480,
      val: '8 hs'
    },
    {
      id: 540,
      val: '9 h'
    },
    {
      id: 600,
      val: '10 hs'
    },
    {
      id: 660,
      val: '11 hs'
    },
    {
      id: 720,
      val: '12 hs'
    },
    {
      id: 780,
      val: '13 hs'
    },
    {
      id: 840,
      val: '14 hs'
    },
    {
      id: 900,
      val: '15 hs'
    },
    {
      id: 960,
      val: '16 hs'
    },
    {
      id: 1020,
      val: '17 hs'
    },
    {
      id: 1080,
      val: '18 hs'
    },
    {
      id: 1140,
      val: '19 hs'
    },
    {
      id: 1200,
      val: '20 hs'
    },
    {
      id: 1260,
      val: '21 hs'
    },
    {
      id: 1320,
      val: '22 hs'
    },
    {
      id: 1380,
      val: '23 hs'
    },
    {
      id: 1440,
      val: '24 hs'
    },
];

const valuesAnticipo = Array.from(Array(365).keys(), (x) => x + 1);
export const anticipoMaximo = valuesAnticipo.map((value) => ({ value: value * 1440, option: value }));

const valuesGrupo = Array.from(Array(100).keys(), (x) => x + 1);
export const grupo = valuesGrupo.map((value) => ({ value, option: value }));
export const waitingList = valuesGrupo.map((value) => ({ value, option: value }));

const valuesPorcentajeAutomatico = Array.from(Array(21).keys(), (x) => x*5);
export const porcentajeAutomatico = valuesPorcentajeAutomatico.map((value) => ({ value, option: value }));

export const anticipoMinimoRespectoA = [
  {
    label: "Turno",
    value: 0,
  },
  {
    label: "Subturno",
    value: 1,
  },
  {
    label: "Horario reservable",
    value: 2,
  },
];

const valuesWeek = Array.from(Array(52).keys(),(x)=>x+1);
export const weeks = valuesWeek.map((value) => ({value, option: value}));

const valuesHorariosReservables = Array.from(Array(101).keys(), (x) => x);
export const horariosReservables = valuesHorariosReservables.map((value) => ({ value, option: value === 0 ? 'ilimitado' : value }));

const valuesAceptacionAutomatica = Array.from(Array(100).keys(), (x) => x + 1);
export const aceptacionAutomatica = valuesAceptacionAutomatica.map((value) => ({ value, option: value }));

const createRangesGroupSizes = (max = 5) => {
  const ranges = [];
  let hour = 0;
  let minutes = 15;

  while (hour < 5 || (hour === 5 && minutes === 0)) {
    // const formattedHour = hour.toString().padStart(2, "0");
    // const formattedMinute = minutes.toString().padStart(2, "0");
    //const range = `${formattedHour}:${formattedMinute}`;
    const value = hour * 60 + minutes;
    ranges.push(value);

    minutes += 15;
    if (minutes === 60) {
      minutes = 0;
      hour++;
    }
  }

  return ranges;
};

const dwellTimeValues = createRangesGroupSizes();
export const dwellTimeOptions = dwellTimeValues.map((value) => ({ value, option: GetHHMMWithText(value) }));
export const dwellTimeDefaultOption = 120;

const offRotationControlHsValues = Array.from(Array(24).keys(),(x)=>x+1);
const offRotationControlDaysValues = Array.from(Array(5).keys(),(x)=>x+2);
const offRotationControlHs = offRotationControlHsValues.map((value) => ({ value: value * 60, option: `${value}h` }));
export const offRotationControOptions = [{value: 0, option: 'No quitarlo'}].concat(offRotationControlHs.concat(offRotationControlDaysValues.map((value) => ({ value: value * 24 * 60, option: `${value} días`}))));

const dwellTimeGroupValues = Array.from(Array(12).keys(),(x)=>x+1);
export const dwellTimeGroups = dwellTimeGroupValues.map((value) => ({ value: value === 12 ? '-1' : value , option: value }));
export const defaultDwellTimeGroupValue = 120;

const rotationsValues = Array.from(Array(14).keys(),(x)=>x+1);
export const rotationOptions = rotationsValues.map((value) => ({ value, option: value }));
export const rotationOptionsDefaultValue = 1;

// const tableRotationsValues = Array.from(Array(14).keys(),(x)=>x+1);
export const tableRrotationOptions = rotationsValues.map((value) => ({ value, option: value }));
export const tableRotationOptionsDefaultValue = 1;

export const SHIFTOPTIONS = [
  {
    step: 1,
    text: "Configuración general",
    Icon: GrearIcon,
    Component: GeneralStep,
  },
  {
    step: 2,
    text: "Horarios y días habilitados",
    Icon: ClockIcon,
    Component: ScheduleStep,
  },
  {
    step: 3,
    text: "Sectores reservables",
    Icon: SectorsV2Icon,
    Component: SectorsStep,
  },
  {
    step: 4,
    text: "Lista de espera",
    Icon: AlarmIcon,
    Component: WaitListStep,
  },
  {
    step: 5,
    text: "Opciones avanzadas",
    Icon: SettingsIcon,
    Component: AdvancedOptionsStep,
  },
];

export const TYPESHIFTARRAY = [
  {
    id: 0,
    option: 'Turno Normal',
    value: false
  },
  {
    id: 1,
    option: 'Turno Especial',
    value: true
  }
];