import React from 'react';

const InfoFilter = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 1C6.51088 1 4.60322 1.79018 3.1967 3.1967C1.79018 4.60322 1 6.51088 1 8.5C1 10.4891 1.79018 12.3968 3.1967 13.8033C4.60322 15.2098 6.51088 16 8.5 16C10.4891 16 12.3968 15.2098 13.8033 13.8033C15.2098 12.3968 16 10.4891 16 8.5C16 6.51088 15.2098 4.60322 13.8033 3.1967C12.3968 1.79018 10.4891 1 8.5 1Z" stroke="#041C32" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 11.5V8.5" stroke="#041C32" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 5.5H8.5075" stroke="#041C32" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default InfoFilter;