import React, {useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';

import WokiTop from '../../SvgComponents/icons/GeneralIcon/WokiTop';
import Input from './Input';
import CheckWOption from './CheckWOption';
import WokiHelp from "../../GeneralComponents/WokiHelp";
import CountriesDropdown from './CountriesDropdown';

import {connect} from "react-redux";
import {
    changeFinancesAddress,
    changeFinancesBusinessName,
    changeFinancesCity,
    changeFinancesCountry,
    changeFinancesCuit,
    changeFinancesEmail,
    changeFinancesFantasyName,
    changeFinancesLastName,
    changeFinancesLegal,
    changeFinancesName,
    changeFinancesPhone,
    changeFinancesPlan,
    changeFinancesZipCode,
    editFinancesCancel,
    editFinancesError,
    editFinancesSuccess,
    nextStepFinances,
    prevStepFinances,
    submitFinancesChanges,
    toggleFinancesLoading
} from "../../../actions";
import {validateEmail, validatePhone} from "../../../utils/validation";
import {PLANS} from "../../../constants";

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
        width: '100%',
        position: 'relative',
        zIndex: '0',
        marginLeft: '20px',
    },
    buttonContinue: {
        width: '150px',
        height: '40px',
        backgroundColor: '#0076FF',
        color: '#fff',
        borderRadius: '6px',
        fontWeight: '800',
        fontSize: '16px',
        padding: '8px 0px 0px 34px',
        cursor: 'pointer'
    },
    rightSide: {
        boxShadow: '-10px 0px 5px -4px rgba(0, 0, 0, 0.2)',
        marginTop: '-1em',
        paddingLeft: '15px'
    }
}));

const MiPlanForm = (props) => {

    const classes = useStyles();

    // Variables form
    const {
        name,
        last_name,
        cuit,
        phone,
        country,
        city,
        address,
        zip_code,
        businessName,
        fantasyName,
        legal,
        email,
        availablesCountries,
        activeStep,
        plans,
        newPlanId
    } = props;

    const [errorName, setErrorName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorCuit, setErrorCuit] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorCity, setErrorCity] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorZipCode, setErrorZipCode] = useState(false);
    const [errorBusinessName, setErrorBusinessName] = useState(false);
    const [errorFantasyName, setErrorFantasyName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);


    // Actions
    const {
        editFinancesError,
        changeFinancesName,
        changeFinancesLastName,
        changeFinancesAddress,
        changeFinancesCuit,
        changeFinancesCity,
        changeFinancesCountry,
        changeFinancesZipCode,
        changeFinancesBusinessName,
        changeFinancesFantasyName,
        changeFinancesLegal,
        changeFinancesEmail,
        changeFinancesPhone,
        submitFinancesChanges,
    } = props;

    const {changeFinancesPlan, prevStepFinances, onDone} = props

    const newPlan = plans?.find(plan => plan.id === newPlanId);

    const submitForm = async () => {
        if (legal) {
            setErrorFantasyName(fantasyName.length <= 0)
        }
        else {
            setErrorName(name.length <= 0)
            setErrorLastName(last_name.length <= 0)
        }

        setErrorBusinessName(businessName.length <= 0)
        setErrorAddress(address.length <= 0)
        setErrorEmail(email.length <= 0 ||
            !validateEmail(email))
        setErrorPhone(phone.length <= 0 ||
            !validatePhone(phone))
        setErrorCuit(cuit.length < 8 ||
            cuit.length > 11)
        setErrorCity(city.length <= 0)
        setErrorZipCode(zip_code.length <= 0)

        let thereIsError =
            legal
                ? fantasyName.length <= 0
                : name.length <= 0 || last_name.length <= 0
        thereIsError = thereIsError || businessName.length <= 0
        thereIsError = thereIsError || address.length <= 0
        thereIsError = thereIsError || email.length <= 0 || !validateEmail(email)
        thereIsError = thereIsError || phone.length <= 0 || !validatePhone(phone)
        thereIsError = thereIsError || cuit.length < 8 || cuit.length > 11
        thereIsError = thereIsError || city.length <= 0
        thereIsError = thereIsError || country.length <= 0
        thereIsError = thereIsError || zip_code.length <= 0;

        if (!thereIsError) {
            return await submitFinancesChanges();
        } else {
            editFinancesError("Revise los datos por favor");
        }
        return false;
    };

    const handleBack = () => {
        changeFinancesPlan("")
        prevStepFinances(activeStep - 1)
    }

    const handleContinue = async () => {
        const canContinue = await submitForm()
        if (canContinue)
            onDone();
    }


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={10}>
                    <div style={{width: '140px'}}>
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '13px'}}>
                                <WokiTop/>
                            </Grid>
                            <Grid item xs={6}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="84.589"
                                     viewBox="0 0 270 114.589">
                                    <defs>
                                        <linearGradient id="linear-gradient-wk" x1="0.5" x2="0.5" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#12b6ff"/>
                                            <stop offset="1" stopColor="#0076ff"/>
                                        </linearGradient>
                                    </defs>
                                    <g id="Grupo_1589" data-name="Grupo 1589" transform="translate(-613.785 -315.318)">
                                        <g id="Grupo_1527" data-name="Grupo 1527"
                                           transform="translate(613.785 317.062)">
                                            <path id="Trazado_799" data-name="Trazado 799"
                                                  d="M713.667,342.758H694.884a1.457,1.457,0,0,0-1.4,1.065l-10.464,35.609a.251.251,0,0,1-.473.009l-11.195-35.518a1.459,1.459,0,0,0-1.4-1.041H659.936a1.457,1.457,0,0,0-1.392,1.025l-11.679,35.526a.251.251,0,0,1-.473.016l-10.468-35.626a1.457,1.457,0,0,0-1.4-1.065H615.244a1.458,1.458,0,0,0-1.364,1.973l22.135,58.591a1.458,1.458,0,0,0,1.363.943h18.439a1.457,1.457,0,0,0,1.385-1l7.18-21.892a.268.268,0,0,1,.255-.182.279.279,0,0,1,.26.189l7.057,21.877a1.458,1.458,0,0,0,1.387,1.01h18.308a1.457,1.457,0,0,0,1.364-.944l22.016-58.592A1.458,1.458,0,0,0,713.667,342.758Zm117.039,60.711-10.692-20.943a1.457,1.457,0,0,0-2.373-.321l-6.276,6.855a1.46,1.46,0,0,0-.383.984v12.762a1.457,1.457,0,0,1-1.457,1.457H790.446a1.458,1.458,0,0,1-1.458-1.457v-83.94a1.459,1.459,0,0,1,1.458-1.458h19.077a1.458,1.458,0,0,1,1.457,1.458v41.94a1.051,1.051,0,0,0,1.865.665l14.845-18.179a1.459,1.459,0,0,1,1.129-.536H851a1.458,1.458,0,0,1,1.068,2.449l-18.123,19.509a1.457,1.457,0,0,0-.2,1.708l20.119,35.666a1.458,1.458,0,0,1-1.27,2.174H832A1.456,1.456,0,0,1,830.706,403.469Zm31.007-.662V344.215a1.458,1.458,0,0,1,1.458-1.457h18.705a1.457,1.457,0,0,1,1.458,1.457v58.592a1.457,1.457,0,0,1-1.458,1.457H863.171A1.458,1.458,0,0,1,861.713,402.807ZM749.638,340.394a32.927,32.927,0,1,0,31.17,31.167A32.929,32.929,0,0,0,749.638,340.394Zm-.1,45.171a12.394,12.394,0,1,1,10.678-10.679A12.4,12.4,0,0,1,749.536,385.565Z"
                                                  transform="translate(-613.785 -317.409)"
                                                  fill="url(#linear-gradient-wk)"/>
                                        </g>
                                        <path id="Trazado_800" data-name="Trazado 800" d="M653,392Z"
                                              transform="translate(-6.502 -12.714)" fill="url(#linear-gradient-wk)"/>
                                        <line id="Línea_34" data-name="Línea 34" x1="0.585"
                                              transform="translate(646.291 379.085)" fill="none"/>
                                        <g id="Grupo_1528" data-name="Grupo 1528"
                                           transform="translate(861.623 315.345)">
                                            <circle id="Elipse_320" data-name="Elipse 320" cx="11.081" cy="11.081"
                                                    r="11.081" transform="translate(0 0)"
                                                    fill="url(#linear-gradient-wk)"/>
                                        </g>
                                        <g id="Grupo_1529" data-name="Grupo 1529"
                                           transform="translate(722.961 315.318)">
                                            <path id="Trazado_801" data-name="Trazado 801"
                                                  d="M788.548,333.549a1.457,1.457,0,0,1-1.954.542,36.407,36.407,0,0,0-33.94,0,1.457,1.457,0,0,1-1.954-.542l-5.842-10.1a1.463,1.463,0,0,1,.555-2.017,50.978,50.978,0,0,1,48.423,0,1.464,1.464,0,0,1,.555,2.017Z"
                                                  transform="translate(-744.66 -315.318)"
                                                  fill="url(#linear-gradient-wk)"/>
                                        </g>
                                        <g id="Grupo_1530" data-name="Grupo 1530"
                                           transform="translate(722.961 410.958)">
                                            <path id="Trazado_802" data-name="Trazado 802"
                                                  d="M769.625,448.916a51.008,51.008,0,0,1-24.212-6.116,1.463,1.463,0,0,1-.555-2.017l5.842-10.1a1.457,1.457,0,0,1,1.954-.542,36.406,36.406,0,0,0,33.94,0,1.457,1.457,0,0,1,1.954.542l5.843,10.1a1.463,1.463,0,0,1-.555,2.017A51.006,51.006,0,0,1,769.625,448.916Z"
                                                  transform="translate(-744.66 -429.967)"
                                                  fill="url(#linear-gradient-wk)"/>
                                        </g>
                                    </g>
                                </svg>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <h1 style={{marginBottom: '10px'}}>¡Hola!</h1>
                        <p style={{margin: '0px'}}>Seguí los pasos para continuar con el proceso de suscripción a
                            Woki</p>
                        <h2 style={{marginBottom: '10px'}}>Información personal</h2>
                        <p style={{margin: '0px'}}>Por favor, ingresa tus datos.</p>
                        <Grid container style={{marginTop: '25px'}}>
                            <Grid item xs={3}>
                                <p
                                    style={{
                                        fontSize: '9px',
                                        margin: '0px',
                                        marginBottom: '10px'
                                    }}
                                >TIPO DE PERSONA</p>
                                <CheckWOption name={'Persona física'} checked={!legal}
                                              onChange={() => changeFinancesLegal(!legal)}/>
                                <CheckWOption name={'Persona jurídica'} checked={legal}
                                              onChange={() => changeFinancesLegal(!legal)}/>
                            </Grid>
                            {legal ? <Grid item xs={3}>
                                    <Input name={"razón social"} value={fantasyName} error={errorFantasyName} helperText={"Ingrese un nombre"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesFantasyName(e.target.value) : null}/>
                                </Grid>
                                : <div>
                                    <Grid item xs={3}>
                                        <Input name={"nombre"} value={name} error={errorName} helperText={"Ingrese un nombre"}
                                               onChange={e => e.target.value.length > 0 ? changeFinancesName(e.target.value) : null}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Input name={"apellido"} value={last_name} error={errorLastName} helperText={"Ingrese un apellido"}
                                               onChange={e => e.target.value.length > 0 ? changeFinancesLastName(e.target.value) : null}/>
                                    </Grid>
                                </div>}
                            <Grid item xs={12} style={{marginTop: '30px'}}>
                                <Input name={"nombre del establecimiento"} value={businessName} error={errorBusinessName} helperText={"Ingrese un nombre del estacionamiento"}
                                       onChange={e => e.target.value.length > 0 ? changeFinancesBusinessName(e.target.value) : null}/>
                            </Grid>
                            <Grid container style={{marginTop: '30px'}}>
                                <Grid item xs={3}>
                                    <Input name={"correo electrónico"} value={email} error={errorEmail} helperText={"Ingrese un email"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesEmail(e.target.value) : null}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Input name={"cuit, cuil o dni"} value={cuit} error={errorCuit} helperText={"Ingrese entre 8 o 11 caracteres"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesCuit(e.target.value) : null}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <h2 style={{marginBottom: '10px'}}>Datos de facturación</h2>
                        <Grid container style={{marginTop: '30px'}}>
                            <Grid item xs={3}>
                                <Grid item xs={4}>
                                    <Input name={"dirección"} value={address} error={errorAddress} helperText={"Ingrese una dirección"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesAddress(e.target.value) : null}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid item xs={4}>
                                    <Input name={"ciudad"} value={city} error={errorCity} helperText={"Ingrese una ciudad"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesCity(e.target.value) : null}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid item xs={4}>
                                    <Input name={"cod. postal"} value={zip_code} error={errorZipCode} helperText={"Ingrese un código postal"}
                                           onChange={e => e.target.value.length > 0 ? changeFinancesZipCode(e.target.value) : null}/>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: '30px'}}>
                                <Grid item xs={3}>
                                    <CountriesDropdown
                                        name={"país"}
                                        value={availablesCountries[country]}
                                        options={availablesCountries}
                                        onChange={(value) => changeFinancesCountry(value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={4}>
                                        <Input name={"teléfono"} value={phone} error={errorPhone} helperText={"Ingrese un telefóno valido. Sin 0 y 15"}
                                               onChange={e => e.target.value.length > 0 ? changeFinancesPhone(e.target.value) : null}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{width: '300px', margin: '50px 0px'}}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <div className={classes.buttonContinue} onClick={handleContinue}>
                                        Continuar
                                    </div>
                                </Grid>
                                <Grid item xs={3} style={{padding: '10px 0px 0px 10px'}}
                                      onClick={handleBack}>
                                    <p

                                        style={{
                                            color: '#0076FF',
                                            fontSize: '16px',
                                            fontWeight: '800',
                                            cursor: 'pointer',
                                            margin: '0px'
                                        }}
                                    >
                                        Volver
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={2} className={classes.rightSide}>
                    <div style={{marginTop: '5em', width: '180px'}}>
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '15px',
                                color: '#848282'
                            }}
                        >Resumen de pago</p>
                        <br/>
                        <h5 style={{margin: '0px'}}>{`Producto ${newPlan?.name}`}</h5>
                        <br/>
                        <br/>
                        <Grid container style={{borderBottom: '1px solid #D0D0D0'}}>
                            <Grid item xs={9}>
                                <p
                                    style={{
                                        fontSize: '10px',
                                        color: '#848282',
                                        margin: '0px 0px 3px 3px'
                                    }}
                                >Prueba</p>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{width: 50}}>
                                    <p
                                        style={{
                                            fontSize: '10px',
                                            color: '#848282',
                                            margin: '0px 0px 3px 3px'
                                        }}
                                    >{`${newPlan?.trial_period_days} días`}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <WokiHelp/>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        name: state.financesReducer.editReducer.name,
        last_name: state.financesReducer.editReducer.last_name,
        cuit: state.financesReducer.editReducer.cuit,
        email: state.financesReducer.editReducer.email,
        phone: state.financesReducer.editReducer.phone,
        city: state.financesReducer.editReducer.city,
        country: state.financesReducer.editReducer.country,
        address: state.financesReducer.editReducer.address,
        zip_code: state.financesReducer.editReducer.zip_code,
        businessName: state.financesReducer.editReducer.businessName,
        fantasyName: state.financesReducer.editReducer.fantasyName,
        legal: state.financesReducer.editReducer.legal,
        availablesCountries: state.financesReducer.editReducer.availablesCountries,

        activeStep: state.financesReducer.editReducer.activeStep,
        newPlanId: state.financesReducer.editReducer.newPlanId,

        plans: state.firestore.ordered[PLANS],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nextStepFinances: (data) => dispatch(nextStepFinances(data)),
        prevStepFinances: (data) => dispatch(prevStepFinances(data)),

        editFinancesCancel: () => dispatch(editFinancesCancel()),
        editFinancesError: (data) => dispatch(editFinancesError(data)),
        editFinancesSuccess: () => dispatch(editFinancesSuccess()),
        toggleFinancesLoading: () => dispatch(toggleFinancesLoading()),
        changeFinancesName: (data) => dispatch(changeFinancesName(data)),
        changeFinancesLastName: (data) => dispatch(changeFinancesLastName(data)),
        changeFinancesCuit: (data) => dispatch(changeFinancesCuit(data)),
        changeFinancesAddress: (data) => dispatch(changeFinancesAddress(data)),
        changeFinancesCity: (data) => dispatch(changeFinancesCity(data)),
        changeFinancesCountry: (data) => dispatch(changeFinancesCountry(data)),
        changeFinancesZipCode: (data) => dispatch(changeFinancesZipCode(data)),
        changeFinancesBusinessName: (data) => dispatch(changeFinancesBusinessName(data)),
        changeFinancesFantasyName: (data) => dispatch(changeFinancesFantasyName(data)),
        changeFinancesLegal: (data) => dispatch(changeFinancesLegal(data)),

        changeFinancesEmail: (data) => dispatch(changeFinancesEmail(data)),
        changeFinancesPhone: (data) => dispatch(changeFinancesPhone(data)),
        submitFinancesChanges: () => dispatch(submitFinancesChanges()),

        changeFinancesPlan: (data) => dispatch(changeFinancesPlan(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiPlanForm)
