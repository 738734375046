import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

// Actions
import { deletePlanning, handleUpdateEnablePlanning } from '../../../actions/planningActions/PlanningBackInteractions';
import { 
    setShowPlanningSnackbar, 
    setPlanningViewMode, 
    setClosingDatesForm, 
    resetPlanningData, 
    setPlanningIdEdit, 
    setEditPlanning, 
} from '../../../actions';

// Components
import ButtonWithIcon from '../../NewComponents/ButtonWithIcon/ButtonWithIcon';
import PlanningViewComponent from './Plannings/PlanningViewComponent';
import PlanningSnackbar from './PlanningSnackbar';
import PlanningModal from './PlanningModal';

// Icon
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Image
import NoPlanningImg from '../../SvgComponents/images/Planning/NoPlanningImg.png';

// Material Ui
import { Box } from '@mui/material';

// Styles
import { NoPlanningContainerStyles, ShowAllPlanningsContainerStyles } from '../Styles';

const ShowPlanningsComponent = ( props ) => {

    // Actions 
    const { 
        handleUpdateEnablePlanning,
        setShowPlanningSnackbar, 
        setPlanningViewMode, 
        resetPlanningData,
        setPlanningIdEdit, 
        setEditPlanning, 
        deletePlanning,
    } = props;

    // External props
    const { 
        planningsSpecialDates, 
        planningsOpennings, 
        planningsClosings,
    } = props;

    // States
    const { planningSnackbar, isActive } = props;

    const [showModal, setShowModal] = useState({
        editCreateModal: false,
        deleteModal: false,
        excludeDate: false
    });
    const [idPlanningDelete, setIdPlanningDelete] = useState( null );
    const [typePlanningDelete, setTypePlanningDelete] = useState('');

    useEffect(() => {
        resetPlanningData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeView = () => {
        setPlanningViewMode('NEW');
    };

    const handleDisabledPlanning = (id, typePatch, typeArray) => {
        let updateArray = {};

        if ( typeArray === 'opennings' ) {
            const openningsIndex = planningsOpennings.findIndex( item => item.id === id );

            if ( openningsIndex !== -1 ) {
                updateArray = {
                    days: planningsOpennings[ openningsIndex ].days,
                    enable: !planningsOpennings[ openningsIndex ].enable,
                    endDate: planningsOpennings[ openningsIndex ].endDate,
                    id: id,
                    name: planningsOpennings[ openningsIndex ].name,
                    repeat: planningsOpennings[ openningsIndex ].repeat,
                    specialDates: planningsOpennings[ openningsIndex ].specialDates,
                    excludeDates: planningsOpennings[ openningsIndex ].excludeDates !== undefined ? 
                        planningsOpennings[ openningsIndex ].excludeDates : [],
                    startDate: planningsOpennings[ openningsIndex ].startDate,
                    timeRanges: planningsOpennings[ openningsIndex ].timeRanges,
                };
            }
        } else if ( typeArray === 'closings' ) {
            const closingsIndex = planningsClosings.findIndex( item => item.id === id );

            if ( closingsIndex !== -1 ) {
                updateArray = {
                    enable: !planningsClosings[ closingsIndex ].enable,
                    id: id,
                    name: planningsClosings[ closingsIndex ].name,
                    reason: planningsClosings[ closingsIndex ].reason,
                    specialDates: planningsClosings[ closingsIndex ].specialDates,
                    timeRanges: planningsClosings[ closingsIndex ].timeRanges,
                };
            }
        } else {
            const specialDatesIndex = planningsSpecialDates.findIndex( item => item.id === id );

            if ( specialDatesIndex !== -1 ) {
                updateArray = {
                    enable: !planningsSpecialDates[ specialDatesIndex ].enable,
                    id: id,
                    name: planningsSpecialDates[ specialDatesIndex ].name,
                    reason: planningsSpecialDates[ specialDatesIndex ].reason,
                    specialDates: planningsSpecialDates[ specialDatesIndex ].specialDates,
                    timeRanges: planningsSpecialDates[ specialDatesIndex ].timeRanges,
                };
            }
        }

        handleUpdateEnablePlanning( id, updateArray, typePatch, typeArray );
    };

    const handleDeletePlanning = ( id, type ) => {
        setShowModal( showModal => ({ ...showModal, deleteModal: true }));
        setTypePlanningDelete( type );
        setIdPlanningDelete( id );
    };

    const handleEditPlanning = ( id, typeArray ) => {
        setPlanningViewMode('EDIT');
        setPlanningIdEdit( id );
        setEditPlanning({
            id: id,
            typeArray: typeArray
        });
    };

    const handlePlanningDelete = () => {
        deletePlanning( idPlanningDelete, typePlanningDelete );
    };

    const handleCloseSnackbar = () => {
        setShowPlanningSnackbar({
            ...planningSnackbar,
            showSnackbar: !planningSnackbar.showSnackbar
        });
    };

    return (
        <>
            {
                ( planningsOpennings?.length === 0 && planningsSpecialDates?.length === 0 && planningsClosings?.length === 0 ) ?
                    <Box sx={{ height: '100%', display: 'flex' }}>
                        <Box sx={ NoPlanningContainerStyles }>
                            <img src={ NoPlanningImg } style={{ width: '15rem', marginTop: '-4rem' }} alt="NoPlanningImg" />
                            <ButtonWithIcon
                                icon={ <AddCircleOutlineIcon/> }
                                onClick={ handleChangeView }
                                text={ 'Crear horario' }
                            />
                        </Box>
                    </Box>
                :
                    <Box sx={ ShowAllPlanningsContainerStyles }>
                        <Box sx={{ position: 'absolute', right: '2rem', top: isActive ? '5rem' : '7rem' }}>
                            <ButtonWithIcon
                                icon={ <AddCircleOutlineIcon/> }
                                onClick={ handleChangeView }
                                text={ 'Crear horario' }
                            />
                        </Box>
                        <Box sx={{ pt: '2rem' }}>
                            {
                                planningsOpennings?.map( planning => 
                                    <PlanningViewComponent
                                        handleDisabledPlanning={ 
                                            () => handleDisabledPlanning( 
                                                planning.id,  
                                                'form',
                                                'opennings', 
                                            ) 
                                        }
                                        handleDelete={ () => handleDeletePlanning( planning.id, 'opennings' ) }
                                        handleEdit={ () => handleEditPlanning( planning.id, 'opennings' ) }
                                        excludePunctualDates={ planning.excludeDates }
                                        planningRangeHours={ planning.timeRanges }
                                        newPunctualDates={ planning.specialDates }
                                        planningName={ planning.name }
                                        start={ planning.startDate }
                                        enabled={ planning.enable }
                                        end={ planning.endDate }
                                        days={ planning.days }
                                        key={ planning.id }
                                        type='openning'
                                    />
                                )
                            }
                            {
                                planningsSpecialDates?.map( planning => 
                                    <PlanningViewComponent
                                        handleDisabledPlanning={ 
                                            () => handleDisabledPlanning( 
                                                planning.id, 
                                                'form',
                                                'specialDates', 
                                            ) 
                                        }
                                        handleDelete={ () => handleDeletePlanning( planning.id, 'specialDates' ) }
                                        handleEdit={ () => handleEditPlanning( planning.id, 'specialDates' ) }
                                        planningRangeHours={ planning.timeRanges }
                                        specialDates={ planning.specialDates }
                                        planningName={ planning.name }
                                        enabled={ planning.enable }
                                        key={ planning.id }
                                        type='specialDates'
                                    />
                                )
                            }
                            {
                                planningsClosings?.map( planning => 
                                    <PlanningViewComponent
                                        handleDisabledPlanning={ 
                                            () => handleDisabledPlanning( 
                                                planning.id, 
                                                'form',
                                                'closings', 
                                            ) 
                                        }
                                        handleDelete={ () => handleDeletePlanning( planning.id, 'closings' ) }
                                        handleEdit={ () => handleEditPlanning( planning.id, 'closings' ) }
                                        planningRangeHours={ planning.timeRanges }
                                        closingDates={ planning.specialDates }
                                        planningName={ planning.name }
                                        enabled={ planning.enable }
                                        key={ planning.id }
                                        type='closing'
                                    />
                                )
                            }
                        </Box>
                        <PlanningModal
                            description='Tené en cuenta que se va a eliminar este horario y toda su configuración'
                            title='Estas eliminando un horario'
                            nextButtonText='Eliminar horario'
                            onDelete={ handlePlanningDelete }
                            show={ showModal.deleteModal }
                            setShow={ setShowModal }
                            id={ idPlanningDelete }
                            backButtonText='Volver'
                            type='delete'
                            color='red'
                        />
                        <PlanningSnackbar
                            message={ planningSnackbar.snackbarMessage }
                            handleClose={ () => handleCloseSnackbar() }
                            open={ planningSnackbar.showSnackbar }
                            type={ planningSnackbar.typeSnackbar }
                        />
                    </Box>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        closingDatesForm: state.planningReducer.closingDatesForm,
        planningSnackbar: state.planningReducer.planningSnackbar,
        isActive: state.sidebarManager.isActive,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setShowPlanningSnackbar: ( planningSnackbar ) => dispatch( setShowPlanningSnackbar( planningSnackbar ) ),
        setEditPlanning: ({ id, typeArray }) => dispatch( setEditPlanning({ id, typeArray }) ),
        handleUpdateEnablePlanning: ( id, newArray, typePatch, typeArray ) => 
            dispatch( handleUpdateEnablePlanning( id, newArray, typePatch, typeArray ) ),
        setClosingDatesForm: ( data ) => dispatch( setClosingDatesForm( data ) ),
        setPlanningViewMode: ( data ) => dispatch( setPlanningViewMode( data ) ),
        deletePlanning: ( id, type ) => dispatch( deletePlanning( id, type ) ),
        setPlanningIdEdit: ( id ) => dispatch( setPlanningIdEdit( id ) ),
        resetPlanningData: () => dispatch( resetPlanningData() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ShowPlanningsComponent );