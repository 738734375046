import {Typography} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from '@emotion/styled';
import {makeStyles} from "@material-ui/core/styles";

const BoxCombination = styled(Box)(
    () => `
        border-bottom: 1px solid #B2B2B2;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: start;
        flex-drection: row;
        padding: 0.3em;
        display: flex;
        width: 90%;
    `
);

const useStyles = makeStyles((theme) => ({
    name: {
        color: '#041C32',
        fontFamily: 'Poppins, sans-serif',
        marginLeft: '.1rem',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden'
    }
}));

const CombinationItem = (props) => {
    const classes = useStyles();
    const {item, enable} = props;

    const {onEdit, onDelete} = props;

    return (
        <BoxCombination>
            <Typography classes={{root: classes.name}} variant="h6" component="h5">
                {item.alias}
            </Typography>
            <Box sx={{display: 'flex', gap: 1, marginRight: '.1rem', paddingLeft: '.3rem'}}>
                <EditIcon
                    fontSize='medium'
                    sx={{cursor: 'pointer'}}
                    onClick={() => enable ? onEdit(item) : null}
                />
                <DeleteOutlineIcon
                    fontSize='medium'
                    sx={{cursor: 'pointer'}}
                    onClick={() => enable ? onDelete(item) : null}
                />
            </Box>
        </BoxCombination>
    )
}

export default CombinationItem
