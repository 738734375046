import {CHANGE_FILTER_DATE, FILTER_QUEUE_ITEMS} from '../../constants/mesaYaClientsConstants'
import {QUEUE_ITEMS} from "../../constants";

const initState = {
    queueItems: [],
    actualQueueItems: [],
    prevQueueItems: [],
    filterQueueItems: [],
    isSearching: false,
    isFiltering: false,
};

const myClientsReducer = (state = initState, action) => {
    switch (action.type) {
        case "@@reduxFirestore/LISTENER_RESPONSE":
            if (
                action.meta &&
                action.meta.collection &&
                !action.meta.subcollections &&
                action.meta.collection === QUEUE_ITEMS &&
                action.payload.ordered
            ) {
                // Periodo actual que se ve en la tabla de clientes
                if (action.meta.storeAs === "actualQueueItems") {
                    const items = action.payload.ordered.filter((item) => item.inAcceptanceZone);
                    return {...state, queueItems: items, filterQueueItems: items, actualQueueItems: action.payload.ordered.filter((item) => item.acceptedTimestamp)}
                }
                // Periodo anterior, solo para calculo de graficos
                else {
                    return {...state, prevQueueItems: action.payload.ordered.filter((item) => item.inAcceptanceZone && item.acceptedTimestamp)}
                }
            }
            return {...state};
        case CHANGE_FILTER_DATE:
            return {...state, date: action.payload}
        case FILTER_QUEUE_ITEMS:
            if ((action.payload.search && action.payload.search !== "") || action.payload.filters) {
                let newFilterQueueItems = [];

                if (action.payload.search) {
                    newFilterQueueItems = newFilterQueueItems.concat(state.queueItems.filter((value) => {
                        const usersData = value.usersData;
                        if (usersData && usersData.length > 0) {
                            return (usersData[0].name !== null && usersData[0].name.toLowerCase().includes(action.payload.search.toLowerCase())) ||
                                (usersData[0].email !== null && usersData[0].email.toLowerCase().includes(action.payload.search.toLowerCase()))
                        }
                        return false;
                    }));
                }
                if (action.payload.filters) {
                    const filterOrigin = action.payload.filters.origin
                    const items = state.isSearching ? state.filterQueueItems : state.queueItems;
                    if (filterOrigin?.length > 0) {
                        newFilterQueueItems = items.filter((value) => filterOrigin?.some((filter) => filter === value.providence))
                    }
                }

                return {
                    ...state,
                    filterQueueItems: newFilterQueueItems,
                    isSearching: action.payload.search && action.payload.search !== "",
                    isFiltering: action.payload.filters && Object.keys(action.payload.filters).length > 0
                }
            }
            return {...state, isFiltering: false, isSearching: false}
        default:
            return state;

    }
}



export default myClientsReducer;
