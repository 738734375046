import React from 'react';

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import {TextField} from "@mui/material";
import {ClickAwayListener, InputAdornment} from "@material-ui/core";

// React date range
import {Calendar} from "react-date-range";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import CalendarIcon from "../SvgComponents/icons/CalendarIcon"; // theme css file

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    Input: {
        // marginRight: '-43px !important',
        fontFamily: 'Poppins, sans-serif !important',
        width: '70% !important',
    },
    CalendarContainer: {
        display: ({open}) => open ? 'block' : 'none',
        position: 'absolute',
        zIndex: '100',
        top: '25px',
        right: ({showLeft}) => showLeft ? '0px' : 'unset',
    },
    InputDate: {
        height: '40px',
        color: '#0B4762',
        fontFamily: 'Poppins, sans-serif',
        margin: 0
    },
    InputDateOutline: {
        borderRadius: '8px !important',
        border: "0.681661px solid #0B4762 !important",
        color: '#0B4762',
    },
    helperText: {
        fontSize: '20px',
        fontFamily: 'Poppins, sans-serif',
        color: '#EB252E',
    },
    Calendar: {
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    }
}));

const CustomCalendar = (props) => {

    const {date, minDate, errorDate} = props;

    const {onChange} = props;

    const [open, setOpen] = React.useState(false);

    const classes = useStyles({...props, open});

    const handleChangeDate = (date) => {
        setOpen(false);
        onChange(date);
    }

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classes.root}>
                <div className={classes.Input}>
                    <TextField
                        onFocus={() => setOpen(true)}
                        value={date.format('DD/MM/YYYY')}
                        size={'small'}
                        label="Seleccionar fecha"
                        error={errorDate !== " "}
                        helperText={errorDate}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.InputDateOutline,
                                root: classes.InputDate,
                                // input: classes.Input
                            },
                            sx: {
                                // fontFamily: 'Poppins, sans-serif !important',
                            },
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <div style={{cursor: 'pointer'}} onClick={() => setOpen(true)}><CalendarIcon/></div>
                                </InputAdornment>
                            )
                        }}
                        FormHelperTextProps={{
                            sx: {
                                color: '#EB252E !important',
                                fontSize: '10px',
                                fontFamily: 'Poppins, sans-serif',
                                marginLeft: '0px'
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#0B4762',
                                fontSize: '.9rem',
                                fontFamily: 'Poppins, sans-serif',
                            }
                        }}
                    />
                </div>
                <div className={classes.CalendarContainer}>
                    <Calendar
                        weekStartsOn={1}
                        date={date.toDate()}
                        minDate={minDate}
                        onChange={handleChangeDate}
                        className={classes.Calendar}
                    />
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default CustomCalendar;
