import React from 'react';

// Icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Materia Ui
import {Button, makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.1rem',
        backgroundColor: '#0B4762',
        textTransform: 'none',
        borderRadius: '10px',
        padding: '6px 12px',
        letterSpacing: '0',
        minWidth: '11rem',
        height: '2.5rem',
        margin: 'auto',
        color: 'white',
    }
}));

const SearchImgButton = (props) => {

    const classes = useStyle();

    const {onClick} = props;

    return (
        <Button
            component="label"
            className={classes.root}
            variant="contained"
            startIcon={<AddCircleOutlineOutlinedIcon fontSize={"large"}/>}
        >
            Agregar imagen
            <input
                style={{display: "none"}}
                type="file"
                id="logoimage"
                name="logoimage"
                accept="image/png, image/jpeg"
                onChange={onClick}
            />
        </Button>
    )
}

export default SearchImgButton;
