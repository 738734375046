
const isAdminRole = (profile) => {
    if(!profile) return false;
    if(profile.role && profile.role.includes("admin")) return true;
    if(!profile.partners || !profile.partners.length) return false;
    const roles = profile.partners.map(partner => partner.role);
    return roles.includes("admin");
}


module.exports = {
    isAdminRole,
}