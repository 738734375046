import React, { useEffect, useState } from 'react';

import moment from 'moment';

// Components
import PlanningDatesView from './PlanningDatesView';
import PlanningTopView from './PlanningTopView';

// Helpers
import { daysArray, handleSetNewSingleDates } from '../../helpers';

// Material Ui
import { Box } from '@mui/material';

// Styles
import { GeneralContainerStyles } from './Styles';

const PlanningViewComponent = ({ 
    handleDisabledPlanning,
    excludePunctualDates=[], 
    newPunctualDates=[],
    planningRangeHours, 
    closingDates=[], 
    specialDates=[], 
    handleDelete,
    planningName,
    handleEdit,
    start='', 
    days=[], 
    enabled,
    end='', 
    type, 
}) => {

    const [openningExcludeDatesArray, setOpenningExcludeDatesArray] = useState([]);
    const [openningDatesArray, setOpenningDatesArray] = useState([]);
    const [specialDatesArray, setSpecialDatesArray] = useState([]);
    const [closingDatesArray, setClosingDatesArray] = useState([]);
    const [planningGrey, setPlanningGrey] = useState( false );

    useEffect(() => {
        if ( start && moment().diff( start, 'days' ) < 0 ) {
            setPlanningGrey( true );
        } else {
            setPlanningGrey( false );
        }
    }, [ start ]);

    useEffect(() => {
        setOpenningDatesArray(
            handleSetNewSingleDates( newPunctualDates )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOpenningExcludeDatesArray(
            handleSetNewSingleDates( excludePunctualDates )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        setSpecialDatesArray(
            handleSetNewSingleDates( specialDates )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setClosingDatesArray( 
            handleSetNewSingleDates( closingDates ) 
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={ GeneralContainerStyles }>
            <PlanningTopView
                handleDisabledPlanning={ handleDisabledPlanning } 
                planningRangeHours={ planningRangeHours }
                handleDelete={ handleDelete }
                planningName={ planningName }
                planningGrey={ planningGrey }
                handleEdit={ handleEdit }
                enabled={ enabled }
                type={ type }
            />
            <Box sx={{ padding: '0 .6rem .3rem .7rem' }}>
                <PlanningDatesView
                    openningExcludeDatesArray={ openningExcludeDatesArray }
                    excludePunctualDates={ excludePunctualDates }
                    openningDatesArray={ openningDatesArray }
                    specialDatesArray={ specialDatesArray }
                    closingDatesArray={ closingDatesArray }
                    newPunctualDates={ newPunctualDates }
                    daysArray={ daysArray }
                    start={ start }
                    type={ type }
                    days={ days }
                    end={ end }
                />
            </Box>
        </Box>
    )
}

export default PlanningViewComponent;
