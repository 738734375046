import React from 'react';

// Material Ui
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Modal} from '@mui/material';

const useStyles = makeStyles(() => ({
    IconCross: {
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        right: '3%',
        top: '5%',
    },
    ModalContainer: {
        transform: 'translate(110%, 110%)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: 'white',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'flex',
        width: '580px',
        '&:focus-visible': {
            outline: 'none !important'
        }
    },
    Title: {
        marginBottom: '1rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
    },
    Subtitle: {
        textAlign: 'center',
        margin: '1.2rem 0',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#737171',
    },
    ButtonsComponents: {
        display: 'flex',
        gap: '25px'
    },
    ContinuesButton: {
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #0B4762 !important',
        backgroundColor: 'white !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#0B4762 !important',
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    DeleteButton: {
        backgroundColor: 'rgba(255, 0, 0, 0.15) !important',
        fontFamily: 'Poppins, sans-serif !important',
        border: '1px solid #EB252E !important',
        padding: '.4rem 1.5rem !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important',
        color: '#EB252E !important',
        '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.15) !important',
        }
    }
}));

const DeleteSectorModal = (props) => {

    const classes = useStyles();
    const { open, onAccept, onCancel, title } = props;

    return (
        <Modal
            open={open}
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.ModalContainer}>
                <div className={classes.IconCross} onClick={onCancel}>
                    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L1 1M15 1L1 15" stroke="#041C32" strokeWidth="1.75" strokeLinecap="round"/>
                    </svg>
                </div>
                <div>
                    <svg width="50" height="50" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z" stroke="#EB252E" strokeWidth="5.25"/>
                        <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E" strokeWidth="5.25" strokeLinecap="round"/>
                    </svg>
                </div>
                <div>
                    <h1 className={classes.Title}>
                        Estas eliminando el sector {title}
                    </h1>
                    <h3 className={classes.Subtitle}>
                        Tené en cuenta que todas las reservas que tengas para este sector se <span style={{color: '#EB252E', fontWeight: '700'}}>eliminarán</span>.
                    </h3>
                </div>
                <div className={classes.ButtonsComponents}>
                    <Button
                        className={classes.ContinuesButton}
                        onClick={onCancel}
                    >Volver</Button>
                    <Button
                        className={classes.DeleteButton}
                        onClick={onAccept}
                    >Eliminar</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DeleteSectorModal;
