import React from 'react';
import {InfoOutlined} from "@material-ui/icons";
import LightTooltip from "../../GeneralComponents/LightTooltip";
import {makeStyles} from "@material-ui/core/styles";
import SmallFilterButton from "./SmallFilterButton/SmallFilterButton";
import TextAreaWithCondition from "../../NewComponents/TextAreaWithCondition/TextAreaWithCondition";
import {connect} from "react-redux";
import {changeMaxWaitingItemsQuantity, changeWaitListComment} from "../../../actions";

const useStyles = makeStyles(() => ({
    Title: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.4rem',
        fontWeight: '600',
        color: '#041C32',
        margin: '0 1rem 0 0',
    },
    Subtitle: {
        fontWeight: '500',
        color: '#041C32',
        fontFamily: 'Poppins, sans-serif',
    },
    SmallFilterButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '10%',
    },
    ConfigurationContainer: {
        display: 'flex',
    }
}));

const WaitListConfiguration = (props) => {
    const classes = useStyles();

    const {waitListComment, maxWaitingItemsQuantity} = props;

    const {changeMaxWaitingItemsQuantity, changeWaitListComment} = props;

    const getPercentageOptions = () => {
        let options = [];
        for (let i = 1; i <= 100; i += 1) {
            options.push({value: i, option: `${i}`})
        }
        if (!options.some(option => option.value === maxWaitingItemsQuantity)) {
            options.push({
                value: maxWaitingItemsQuantity,
                option: `${maxWaitingItemsQuantity}`
            })
        }
        return options;
    }

    const handleMaxWaitingItemsQuantityChange = (e) => {
        changeMaxWaitingItemsQuantity(e.target.value);
    }

    const handleWaitListCommentChange = (e) => {
        changeWaitListComment(e.target.value.length > 400 ? waitListComment : e.target.value);
    }

    return (
        <div>
            <div style={{display: 'flex'}}>
                <h1 className={classes.Title}>Lista de espera</h1>
                <LightTooltip
                    placement='right'
                    title={"Los clientes pueden registrarse en la lista de espera de un turno si el mismo está lleno."}
                >
                    <InfoOutlined style={{color: '#041C32', fontSize: '16px', marginTop: '10px', cursor: 'pointer'}}/>
                </LightTooltip>
            </div>
            <div className={classes.ConfigurationContainer}>
                <h2 className={classes.Subtitle}>Máximo de reservas en lista de espera</h2>
                <div className={classes.SmallFilterButtonContainer}>
                    <SmallFilterButton
                        text={''}
                        color={'#0B4762'}
                        value={maxWaitingItemsQuantity}
                        options={getPercentageOptions()}
                        onChange={handleMaxWaitingItemsQuantityChange}
                    />
                </div>
            </div>
            <div>
                <h2 className={classes.Subtitle}>Aclaraciones de lista de espera</h2>
                <TextAreaWithCondition
                    value={waitListComment}
                    onChange={handleWaitListCommentChange}
                    width={'100%'}
                    helperText={'Máximo 400 caracteres'}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        maxWaitingItemsQuantity: state.reservasReducer.generalInfo.maxWaitingItemsQuantity,
        waitListComment: state.reservasReducer.generalInfo.waitListComment,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeMaxWaitingItemsQuantity: (value) => dispatch(changeMaxWaitingItemsQuantity(value)),
        changeWaitListComment: (value) => dispatch(changeWaitListComment(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListConfiguration);
