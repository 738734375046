import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import FinancesForm from "../../components/MiPlan";
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";
import {resetStepFinances} from "../../actions";

function FinancePage(props) {
    useEffect(() => {
        props.resetStepFinances();
        props.changeTitleAppBar("Mi plan")
        props.changeShowSidebar(true);
    })


  return (
      <Grid container>
        <FinancesForm />
      </Grid>
  );
}


const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        resetStepFinances: (data) => dispatch(resetStepFinances(data)),

    }
}

export default connect(null, mapDispatchToProps)(FinancePage);
