import React from 'react';

import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

// Actions
import { setSpecialDatesForm } from '../../../../actions';

// Components
import PlanningTextFieldComponent from '../PlanningTextAreaComponent';
import PunctualDatesComponent from '../PunctualDatesComponent';
import NewRangeHourComponent from '../NewRangeHourComponent';
import AddNewButton from '../AddNewButton';

// Material Ui
import { Box, Collapse, Divider, Typography } from '@mui/material';

// Styles
import { OptionalSubtitleStyles, TransitionGroupStyles } from '../../Styles';

const SpecialDatesForm = ( props ) => {

    // Actions
    const { setSpecialDatesForm } = props;

    // States
    const { specialDatesForm } = props;

    const handleSetReason = ( e ) => {
        setSpecialDatesForm({
            ...specialDatesForm,
            reason : e
        });
    };

    const handleAddNewHour = () => {
        setSpecialDatesForm({
            ...specialDatesForm,
            rangeHours: [
                ...specialDatesForm.rangeHours,
                {
                    id: `${ specialDatesForm.rangeHours.length }`,
                    from: 540,
                    to: 960
                }
            ]
        });
    };

    const handleDeleteRangeHour = ( id ) => {
        let rangeHoursCopy = [ ...specialDatesForm.rangeHours ];
        rangeHoursCopy = rangeHoursCopy.filter( item => item.id !== id );

        setSpecialDatesForm({
            ...specialDatesForm,
            rangeHours: [ ...rangeHoursCopy ]
        });
    };

    return (
        <Box>
            <PlanningTextFieldComponent 
                value={ specialDatesForm.reason }
                setValue={ handleSetReason }
            />
            <Divider sx={{ m: '1rem 0' }}/>
            <Box sx={{ mb: '1rem', width: '50rem' }}>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Rango horario</Typography>
                <TransitionGroup style={ TransitionGroupStyles }>
                    {
                        specialDatesForm.rangeHours?.map( item =>
                            <Collapse key={ item.id } orientation='vertical'>
                                <NewRangeHourComponent
                                    onDelete={ () => handleDeleteRangeHour( item.id ) }
                                    arrayDate='special-dates'
                                    from={ item.from }
                                    id={ item.id }
                                    to={ item.to } 
                                />    
                            </Collapse>
                        )
                    }
                </TransitionGroup>
                <Box sx={{ mt: '.5rem' }}>
                    <AddNewButton onClick={ handleAddNewHour } type='range'/>
                </Box>
            </Box>
            <Divider sx={{ m: '1rem 0 .6rem 0' }}/>
            <Box>
                <Typography variant='h6' sx={ OptionalSubtitleStyles }>Fechas especiales</Typography>
                <PunctualDatesComponent component ='special-dates' />
            </Box>
        </Box>
    )
};

const mapStateToProps = (state) => {
    return {
        specialDatesForm: state.planningReducer.specialDatesForm,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setSpecialDatesForm: ( data ) => dispatch( setSpecialDatesForm( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( SpecialDatesForm );