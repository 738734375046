import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { ShiftSimpleSelectStyles } from '../../../../Styles';

const ShiftHoursSelect = ({ error = false, handleChange, disabled = false, value, optionsArray }) => {

    const ITEM_PADDING_TOP = 8;
    const ITEM_HEIGHT = 48;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                border: '1px solid #0B4762',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                maxWidth: '15.75rem',
                marginTop: '.2rem',
                minWidth: '78px',
                width: '9.9rem',
            },
        },
    };

    return (
        <FormControl error={ error } sx={{ width: 'max-content' }}>
            <Select
                onChange={ handleChange }
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                sx={ ShiftSimpleSelectStyles }
                input={ <OutlinedInput /> }
                placeholder='Seleccionar'
                MenuProps={ MenuProps }
                disabled={ disabled }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem 
                            key={ item.value } 
                            value={ item.value } 
                            sx={{ 
                                padding: '.5rem', 
                                height: '2.5rem' 
                            }}
                        >
                            <ListItemText 
                                primary={ `${ item.option }HS` }
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontFamily: 'Poppins !important', 
                                        fontSize: '.9rem', 
                                        color: "#041C32",
                                        fontWeight: 500
                                    } 
                                }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ShiftHoursSelect;