import React from "react";

const WarningIcon = ({ size = "83", fill = '#FB3F4A' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 83 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5 0.25C33.3415 0.25 25.3663 2.66927 18.5827 7.20188C11.7992 11.7345 6.5121 18.1769 3.38999 25.7143C0.267873 33.2518 -0.549015 41.5458 1.04263 49.5475C2.63427 57.5492 6.56295 64.8992 12.3319 70.6682C18.1008 76.4371 25.4508 80.3658 33.4525 81.9574C41.4543 83.549 49.7483 82.7321 57.2857 79.61C64.8232 76.4879 71.2655 71.2008 75.7981 64.4173C80.3307 57.6337 82.75 49.6585 82.75 41.5C82.75 36.083 81.6831 30.719 79.61 25.7143C77.537 20.7096 74.4986 16.1623 70.6682 12.3318C66.8378 8.50143 62.2904 5.46298 57.2857 3.38997C52.281 1.31696 46.917 0.25 41.5 0.25ZM41.5 74.5C34.9732 74.5 28.593 72.5646 23.1662 68.9385C17.7394 65.3124 13.5097 60.1585 11.012 54.1286C8.5143 48.0986 7.86079 41.4634 9.13411 35.062C10.4074 28.6606 13.5504 22.7806 18.1655 18.1655C22.7806 13.5503 28.6607 10.4074 35.062 9.13409C41.4634 7.86077 48.0986 8.51428 54.1286 11.012C60.1585 13.5097 65.3124 17.7394 68.9385 23.1662C72.5646 28.593 74.5 34.9732 74.5 41.5C74.5 50.2521 71.0232 58.6458 64.8345 64.8345C58.6458 71.0232 50.2522 74.5 41.5 74.5Z"
        fill={fill}//"#FB3F4A"
      />
      <path
        d="M41.5 62.125C43.7782 62.125 45.625 60.2782 45.625 58C45.625 55.7218 43.7782 53.875 41.5 53.875C39.2218 53.875 37.375 55.7218 37.375 58C37.375 60.2782 39.2218 62.125 41.5 62.125Z"
        fill={fill}//"#FB3F4A"
      />
      <path
        d="M41.5 20.875C40.406 20.875 39.3568 21.3096 38.5832 22.0832C37.8096 22.8568 37.375 23.906 37.375 25V45.625C37.375 46.719 37.8096 47.7682 38.5832 48.5418C39.3568 49.3154 40.406 49.75 41.5 49.75C42.594 49.75 43.6432 49.3154 44.4168 48.5418C45.1904 47.7682 45.625 46.719 45.625 45.625V25C45.625 23.906 45.1904 22.8568 44.4168 22.0832C43.6432 21.3096 42.594 20.875 41.5 20.875Z"
        fill={fill}//"#FB3F4A"
      />
    </svg>
  );
};

export default WarningIcon;
