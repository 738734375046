import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";

// Components
import TableForm from "./TableForm";
import Table from "./Table";

// Material Ui
import { Box } from "@mui/material";

// Styles
import "./GridTable.css";
import {
  addNewTable,
  addTableToCombination,
  changeEditingTable,
  changeTableLayoutCoordinates,
  changeTableLayoutProperties,
  removeTable,
  removeTableFromCombination,
} from "../../../../actions/V3";
import { displayNotification } from "../../../../actions/notistackActions";
import DeleteTableWithCombination from "./Modals/DeleteWithCombination";
import ChangeTableNameModal from "./Modals/ChangeTableName";
import { v4 as uuidv4 } from "uuid";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const TABLE_STATE = {
  EDITING: 0,
  RESERVABLE: 1,
  NO_RESERVABLE: 2,
  SELECCIONADA: 3,
  NO_SELECCIONADA: 4,
};

const GridTable = (props) => {
  const {
    tables,
    combineMode,
    newCombineTables,
    editingTable,
    creatingSector,
    tableCreationMode,
    sectors,
    sector,
    layout,
  } = props;

  const {
    changeTableLayoutProperties,
    changeTableLayoutCoordinates,
    addNewTable,
    removeTable,
    newTable,
    addTableToCombination,
    removeTableFromCombination,
    changeEditingTable,
    displayNotification,
  } = props;

  const [ignoreClick, setIgnoreClick] = useState(false);
  const [showDeleteTableWithCombinations, setShowDeleteTableWithCombinations] =
    useState(false);

  const [showChangeTableName, setShowChangeTableName] = useState(false);
  const [newTableValuesProposed, setNewTableValuesProposed] = useState({});

  //Drag Callbacks
  const handleOnDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    setIgnoreClick(true);
  };

  const handleOnDragStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    changeTableLayoutCoordinates({
      id: newItem.i,
      point: { x: newItem.x, y: newItem.y },
    });
    e.preventDefault();
    e.stopPropagation();
  };

  //Drop Callbacks
  const handleDrop = (layout = [], table = {}, e) => {
    newTable.x = table?.x >= 7 ? table?.x - 7 : table.x ?? 0;
    newTable.y = table?.y >= 1.5 ? table?.y - 1.5 : table?.y ?? 0;
    addNewTable({ ...newTable });
  };

  // Table edit
  const handleOnTableClick = (e, table) => {
    e.preventDefault();
    e.stopPropagation();
    if (combineMode) {
      if (getTableState(table) === TABLE_STATE.NO_RESERVABLE) {
        displayNotification(
          "No se puede combinar una mesa no reservable con otra mesa",
          "error"
        );
        return;
      }
      if (newCombineTables.tables.includes(table.id)) {
        removeTableFromCombination(table);
      } else {
        addTableToCombination(table);
      }
    } else {
      changeEditingTable(table);
    }
  };

  const handleOnCloseTableForm = () => {
    //TODONOW if name is empty an all of that stuf
    changeEditingTable(undefined);
  };

  const handleOnSaveTable = ({
    alias,
    minCapacity,
    maxCapacity,
    userCanBook,
    partnerCanBook,
    backup,
    id,
  }) => {
    const newValues = {
      alias,
      minCapacity,
      maxCapacity,
      userCanBook,
      partnerCanBook,
      backup,
      id,
    };
    const currentTable = editingTable;
    const isCreationNewTableMode = tableCreationMode;
    const tableName = alias ?? currentTable.alias;
    const oldId = currentTable.id;
    const fullSector = sectors.find((s) => s.id === sector.id);
    let existThisTableByNameInOtherLayouts = false;
    let tableWithSameNameInOtherLayout = null;
    let previousTableExistsInOtherLayouts = false;
    fullSector.layouts.forEach((l) => {
      if (l.id !== layout.id) {
        l.tables.forEach((t) => {
          if (t.alias?.toLowerCase() === tableName?.toLowerCase()) {
            existThisTableByNameInOtherLayouts = true;
            tableWithSameNameInOtherLayout = t;
          }
          if (t.id === oldId) {
            previousTableExistsInOtherLayouts = true;
          }
        });
      }
    });
    if (isCreationNewTableMode) {
      if (existThisTableByNameInOtherLayouts) {
        newValues.id = tableWithSameNameInOtherLayout.id;
      } else {
        newValues.id = oldId;
      }
    } else {
      if (previousTableExistsInOtherLayouts) {
        if (existThisTableByNameInOtherLayouts) {
          newValues.id = tableWithSameNameInOtherLayout.id;
        } else {
          setNewTableValuesProposed(newValues);
          setShowChangeTableName(true);
          return;
        }
      } else {
        if (existThisTableByNameInOtherLayouts) {
          newValues.id = tableWithSameNameInOtherLayout.id;
        } else {
          newValues.id = oldId;
        }
      }
    }
    changeTableLayoutProperties(newValues);
    handleOnCloseTableForm();
  };

  const handleEditTableOnlyInThisLayout = (newTableProps) => {
    changeTableLayoutProperties({
      ...newTableProps,
      id: uuidv4(),
    });
    handleOnCloseTableForm();
    setShowChangeTableName(false);
    displayNotification(
      "Tené en cuenta que al cambiar el nombre de una mesa puede que alguna reserva quede sin mesa asignada"
    );
  };

  const handleEditTableInAllLayouts = (newTableProps) => {
    const fullSector = sectors.find((s) => s.id === sector.id);
    let tableWithSameNameInOtherLayout = null;
    fullSector.layouts.forEach((l) => {
      if (l.id !== layout.id) {
        l.tables.forEach((t) => {
          if (t.id === editingTable.id) {
            tableWithSameNameInOtherLayout = t;
          }
        });
      }
    });
    changeTableLayoutProperties({
      ...newTableProps,
      id: tableWithSameNameInOtherLayout.id,
    });
    handleOnCloseTableForm();
    setShowChangeTableName(false);
  };

  const handleOnDeleteTable = () => {
    if (editingTable.combinesWith.length > 0) {
      setShowDeleteTableWithCombinations(true);
    } else {
      onDeleteTable();
    }
  };

  const onDeleteTable = () => {
    setShowDeleteTableWithCombinations(false);
    removeTable(editingTable);
    handleOnCloseTableForm();
    if (!creatingSector) {
      displayNotification(
        "Tené en cuenta que al eliminar una mesa puede que alguna reserva quede sin mesa asignada"
      );
    }
  };

  // Render Tables
  const getTableState = (table) => {
    if (editingTable && editingTable.id === table.id) {
      return TABLE_STATE.EDITING;
    }
    if (combineMode) {
      if (newCombineTables.tables.includes(table.id)) {
        return TABLE_STATE.SELECCIONADA;
      } else {
        if (table.backup) return TABLE_STATE.NO_RESERVABLE;
        return TABLE_STATE.RESERVABLE;
      }
    } else {
      if (table.backup) {
        return TABLE_STATE.NO_RESERVABLE;
      }
      return TABLE_STATE.DEFAULT;
    }
  };

  const getTableStyle = (table) => {
    const style = {
      borderRadius: table.circle ? "50%" : "10%",
    };
    switch (getTableState(table)) {
      case TABLE_STATE.EDITING:
        return {
          ...style,
          borderColor: "#0B4762",
          backgroundColor: "#0B4762",
          color: "#FFFFFF",
        };
      case TABLE_STATE.RESERVABLE:
        return {
          ...style,
          borderColor: "#B2B2B2",
          backgroundColor: "#FFFFFF",
          color: "#B2B2B2",
        };
      case TABLE_STATE.NO_RESERVABLE:
        return {
          ...style,
          borderColor: "#B2B2B2",
          backgroundColor: "#DBD7DC",
          color: "#B2B2B2",
        };
      case TABLE_STATE.SELECCIONADA:
        return {
          ...style,
          borderColor: "#0B4762",
          backgroundColor: "#0B4762",
          color: "#FFFFFF",
        };
      case TABLE_STATE.NO_SELECCIONADA:
        return {
          ...style,
          borderColor: "#B2B2B2",
          backgroundColor: "#FFFFFF",
          color: "#B2B2B2",
        };
      default:
        return { ...style, borderColor: "#0B4762" };
    }
  };

  const children = useMemo(() => {
    return tables.map((table) => {
      return (
        <Table
          className="react-grid-inner-table"
          key={table.id}
          style={getTableStyle(table)}
          table={table}
          ignoreClick={ignoreClick}
          onTableClick={handleOnTableClick}
          resetignoreclick={() => setIgnoreClick(false)}
          data-grid={{
            i: table.alias,
            x: table.point.x,
            y: table.point.y,
            w: table.box.width,
            h: table.box.height,
            isResizable: table.isResizable,
            isDraggable: !combineMode,
          }}
        />
      );
    });
  }, [tables, editingTable, combineMode, newCombineTables, ignoreClick]);

  return (
    <Box sx={{ position: "relative", width: "98%" }}>
      <div id="sector-grid-layout">
        <ResponsiveReactGridLayout
          className="layout"
          cols={{ lg: 210, md: 180, sm: 150, xs: 125, xxs: 100 }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          rowHeight={15}
          droppingItem={newTable}
          draggableHandle=".react-grid-inner-table"
          compactType={null}
          preventCollision={true}
          isDroppable={true}
          onDrop={handleDrop}
          onDrag={handleOnDrag}
          onDragStop={handleOnDragStop}
          isDraggable={!combineMode}
        >
          {children}
        </ResponsiveReactGridLayout>
      </div>
      {editingTable && (
        <TableForm
          open={true}
          table={editingTable}
          onClose={handleOnCloseTableForm}
          onSave={handleOnSaveTable}
          onDelete={handleOnDeleteTable}
        />
      )}
      <DeleteTableWithCombination
        open={showDeleteTableWithCombinations}
        onCancel={() => setShowDeleteTableWithCombinations(false)}
        onAccept={() => onDeleteTable()}
      />
      <ChangeTableNameModal
        open={showChangeTableName}
        setOpen={setShowChangeTableName}
        onAcceptAll={handleEditTableInAllLayouts}
        onAcceptCurrent={handleEditTableOnlyInThisLayout}
        newTableValuesProposed={newTableValuesProposed}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tables: state.v3.sectorsLayoutsTables.layout.tables ?? [],
    newTable: state.v3.sectorsLayoutsTables.newTable,
    editingTable: state.v3.sectorsLayoutsTables.editingTable,
    combinedTables: state.v3.sectorsLayoutsTables.combinedTables,
    newCombineTables: state.v3.sectorsLayoutsTables.newCombineTables,
    combineMode: state.v3.sectorsLayoutsTables.combineMode,
    layout: state.v3.sectorsLayoutsTables.layout,
    creatingSector: state.v3.sectorsLayoutsTables.creatingSector,
    tableCreationMode: state.v3.sectorsLayoutsTables.tableCreationMode,
    sectors: state.v3.sectorsLayoutsTables.sectors,
    sector: state.v3.sectorsLayoutsTables.sector,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTableLayoutProperties: ({
      id,
      alias,
      minCapacity,
      maxCapacity,
      userCanBook,
      partnerCanBook,
      backup,
      circle,
      box,
      point,
      isResizable,
    }) =>
      dispatch(
        changeTableLayoutProperties({
          id,
          alias,
          minCapacity,
          maxCapacity,
          userCanBook,
          partnerCanBook,
          backup,
          circle,
          box,
          point,
          isResizable,
        })
      ),
    changeTableLayoutCoordinates: ({ id, point }) =>
      dispatch(changeTableLayoutCoordinates({ id, point })),
    addNewTable: ({
      i,
      minCapacity,
      maxCapacity,
      x,
      y,
      w,
      h,
      circle,
      isResizable,
    }) =>
      dispatch(
        addNewTable({
          i,
          minCapacity,
          maxCapacity,
          x,
          y,
          w,
          h,
          circle,
          isResizable,
        })
      ),
    removeTable: (table) => dispatch(removeTable(table)),
    changeEditingTable: (table) => dispatch(changeEditingTable(table)),
    addTableToCombination: (table) => dispatch(addTableToCombination(table)),
    removeTableFromCombination: (table) =>
      dispatch(removeTableFromCombination(table)),
    displayNotification: (message, severity) =>
      dispatch(displayNotification(message, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridTable);
