import React from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Ui
import { Box, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

// Styles
import { OptionalSelectStyles } from '../Styles/OptionalStyles';

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            border: '1px solid #0B4762',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            marginTop: '.3rem',
            width: '15rem',
        },
    },
};

const OptionalSelect = ({ handleChange, value, optionsArray, error }) => {
    return (
        <FormControl 
            sx={{ maxWidth: 240 }}
            error={ error }
        >
            <Select
                onChange={ (e) => handleChange( e.target.value ) }
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <Box>Seleccionar</Box>;
                    }
        
                    return selected;
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ ExpandMoreIcon }
                sx={ OptionalSelectStyles }
                placeholder='Seleccionar'
                input={<OutlinedInput />}
                MenuProps={ MenuProps }
                value={ value }
                displayEmpty
                autoWidth
            >
                {
                    optionsArray.map(( item ) =>
                        <MenuItem key={ item.id } value={ item.option } sx={{ padding: '.5rem', height: '2.5rem' }}>
                            <ListItemText 
                                primary={item.option} 
                                sx={{ 
                                        '& .MuiTypography-root': { 
                                            fontFamily: 'Poppins !important', 
                                            fontSize: '.9rem', 
                                            color: "#041C32",
                                            fontWeight: 500
                                        } 
                                    }}
                            /> 
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default OptionalSelect;