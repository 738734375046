import {RESET_ALL_DATA, TOGGLE_DELETE_PRODUCT_DIALOG, TOGGLE_DELETE_PRODUCT_LOADING,} from '../../../constants';

const initState = {
    deleteProductCategory: '',
    deleteProductIndex: null,
    loading: false,
    error: '',
}

const deleteReducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_DELETE_PRODUCT_DIALOG:
            return { ...state, deleteProductCategory: action.payload.categoryId, deleteProductIndex: action.payload.productIndex, };
        case TOGGLE_DELETE_PRODUCT_LOADING:

            return { ...state, loading: !state.loading, };
        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default deleteReducer;
