import React from 'react'
import CategoryButton from "./CategoryButton";
import {Link} from 'react-scroll';


import '../css/CategoriesBar.css'

const CategoriesBar = (props) => {

    const {categories, activeCategory, onClick} = props;

    return (
        <div style={{
            display: 'flex',
            zIndex: 5,
            overflow: 'auto',
            overflowY: 'hidden',
            paddingTop: '5px',
            paddingBottom: '5px',
            whiteSpace: 'nowrap',
            scrollbarColor: 'transparent'
        }}>
            {categories?.map((category, index) =>
                <div key={category.name} style={{marginLeft: '5px', marginRight: '5px'}}>
                    <Link to={category.name} spy={true} smooth={true}>
                        <CategoryButton
                            title={category.name}
                            isActive={category.name === activeCategory}
                            onClick={() => onClick(category.name)}/>
                    </Link>
                </div>
            )}
        </div>
    )
}

CategoriesBar.propsDefault = {
    categories: [],
}

export default CategoriesBar