import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { setSidebarBookingId } from '../../actions/myBookingsActions';

// Helpers
import { ShowClientsTableIcons } from './helpers';

// Material Ui
import { Box, TableCell, TableRow, Typography } from '@mui/material';

// Utils
import { showCountryFlag } from '../../utils/CountryFlagsArray';

// Styles
import { 
    CNContainerStyles,  
    TablePhoneContainerStyles,  
    TextsStyles,
    // TablePhoneContainerstyles,  
    // AmountPeopleContainer,  
    // PunctuationTextsStyles, 
    // ShiftTextStyles, 
    // FlagContainer, 
} from '../MyBookings/Section3/components/Table/Style/Section3TableStyles';

const TableRowClients = ( props ) => {

    // Actions
    const { setSidebarBookingId } = props;

    // External props
    const {
        clientSpecialRelationship,
        clientFutureReservations,
        clientFoodRestictions, 
        // clientPunctuation,
        clientTotalBookings,
        clientCancellations,
        clientLastBooking,
        clientNationality,
        // clientReviews,
        clientLastName,
        clientNoShows,
        clientVisits,
        clientEmail,
        clientPhone,
        clientAlias,
        clientNote,
        clientName,
        clientTags,
        clientId,
        onClick 
    } = props;

    const handleShowSidebar = (e, id) => {

        setSidebarBookingId( id );

        if ( e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift' )) {
            return;
        }

        onClick();
    };

    return (
        <TableRow sx={{ height: '65px' }}>
            <TableCell sx={{ p: '.5rem', boxSizing: 'border-box', width: 'max-content' }}>
                <Box 
                    onClick={ (e) => handleShowSidebar(e, clientId) } 
                    sx={ CNContainerStyles }
                >
                    <Typography variant="p" align="left" color='#2A2742' sx={ TextsStyles }>
                        { clientName } { clientLastName }
                    </Typography>
                    <Box sx={{ height: '30px' }}>
                        {
                            // Respetar orden
                            ShowClientsTableIcons(
                                clientTags, 
                                clientNote, 
                                clientFoodRestictions, 
                                clientSpecialRelationship 
                            )
                        }
                    </Box>
                </Box>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' sx={ TextsStyles }>{ clientAlias }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Box sx={ TablePhoneContainerStyles }>
                    <Box className="flagContainer">{ showCountryFlag( clientNationality ) }</Box>
                    <Typography variant="p" align="left" color='#2A2742' sx={ TextsStyles }>
                        <a 
                            href={`https://wa.me/${ clientPhone }`} 
                            rel="noreferrer"
                            target="_blank" 
                            style={{ textDecoration: 'none', color: '#2A2742' }}
                        >
                            { clientPhone }
                        </a>
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#0B4762' sx={ TextsStyles }>
                    { clientEmail }
                </Typography>
            </TableCell>
            {/* <TableCell align='center'>
                <Box sx={ AmountPeopleContainer }>
                    <PunctuationIcon/>
                    <Typography variant='subtitle2' color='#2A2742' sx={ PunctuationTextsStyles }>
                        { clientPunctuation } 
                    </Typography>
                </Box>
            </TableCell> */}
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientTotalBookings }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>
                    {/* Arreglo de bug */}
                    { clientLastBooking && moment( clientLastBooking ).format('DD/MM/YYYY') }
                </Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientVisits }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientCancellations }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientNoShows }</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientFutureReservations }</Typography>
            </TableCell>
            {/* <TableCell align='center'>
                <Typography variant='subtitle2' color='#2A2742' sx={ TextsStyles }>{ clientReviews }</Typography>
            </TableCell> */}
        </TableRow>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSidebarBookingId: ( data ) => dispatch(setSidebarBookingId( data )),
    };
};

export default connect( null, mapDispatchToProps )( TableRowClients );