import React from 'react';

const CheckOkIcon = ({ fill }) => {
    return (
        <svg width="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector" d="M22.9168 11.5412V12.4995C22.9156 14.7458 22.1882 16.9315 20.8432 18.7306C19.4983 20.5297 17.6078 21.8459 15.4537 22.4828C13.2996 23.1197 10.9973 23.0432 8.89024 22.2647C6.78316 21.4863 4.98418 20.0476 3.76158 18.1631C2.53898 16.2787 1.95828 14.0496 2.10608 11.8082C2.25387 9.56679 3.12225 7.43321 4.5817 5.72565C6.04116 4.0181 8.01348 2.82806 10.2045 2.33302C12.3956 1.83798 14.6879 2.06447 16.7398 2.9787" stroke={fill} strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_2" d="M22.9167 4.16675L12.5 14.5938L9.375 11.4688" stroke={fill} strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}

export default CheckOkIcon;