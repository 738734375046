export const DrawerHeadStyles = {
    minHeight: '4rem !important',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    height: 'max-content',
    width: '100%'
}

export const PartnerNameStyles = {
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    fontFamily: 'Poppins',
    whiteSpace: 'normal',
    padding: '.8rem 0',
    fontSize: '1rem',
    color: '#041C32',
    fontWeight: 600,
    mb: '-.1rem',
};

export const MenuNameSector = {
    p: '.5rem 0 .5rem .7rem',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    color: '#041C32',
    fontWeight: 700,
};

export const AppbarTextsContainer = {
    justifyContent: 'center', 
    alignContent: 'center',
    display: 'flex',
    gap: '10px' 
}

export const TitlesContainerAppbarStyles = {
    margin: 'auto auto auto .5rem',
    flexDirection: 'column',
    display: 'flex',
};

export const AppbarTitleStyles = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.1rem',
    textAlign: 'left',
    color: '#041C32',
    fontWeight: 500,
};

export const AppbarSubtitleStyles = {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'left',
    fontSize: '.8rem',
    color: '#B2B2B2',
    fontWeight: 400,
    flexGrow: 1,
};

export const ItemsSingleItemsStyles = {
    fontFamily: 'Poppins, sans-serif',
    marginLeft: '1rem',
    fontSize: '14px',
    fontWeight: 500,
}
