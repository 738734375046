import React, {useEffect} from "react";
import {connect} from "react-redux";

// Components
import TableBookingClients from "./TableBookingClients";
import CardsHistory from "./CardsHistory";

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@mui/material";
import TimeFilterSelector from "../GeneralComponents/TimeFilterSelector/TimeFilterSelector";

// Redux
import {
    changeBookingHistoryFilterDate,
    closeErrorDialog,
    fetchBookingHistory,
    fetchBookingTotalArrives
} from "../../actions";
import ProgressIndicator from "../GeneralComponents/ProgressIndicator";
import ErrorDialog from "../GeneralComponents/Modal/ErrorDialog";
import {ModalMode} from "../../constants/modalMode";

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#FCFCFC',
        paddingRight: '1.5rem',
        paddingTop: '1rem',
        height: '100%',
    },
}));

function BookingClients(props) {
    const classes = useStyles();

    const {
        statistic,
        rowsPerPage,
        page,
        from,
        to,
        origin,
        state,
        query,
        loading,
        error,
        profile,
        idPartnerSelected
    } = props;

    const {
        fetchStatistics,
        fetchHistory,
        changeDate,
        closeErrorDialog,
    } = props;


    useEffect(() => {
        if (idPartnerSelected) {
            fetchStatistics();
            fetchHistory();
        }
    }, [idPartnerSelected, from, to, origin, state, query, rowsPerPage, page])

    useEffect(() => {
        if (idPartnerSelected) {
            fetchStatistics();
        }
    }, [idPartnerSelected, from, to])

    return (
        <div className={classes.root}>
            <section style={{marginBottom: '1.5rem', marginTop: '-1rem'}}>
                <TimeFilterSelector onChangeDate={(from, to) => changeDate({from: from, to: to})}/>
            </section>
            <Grid container spacing={0} justifyContent={'space-between'} sx={{backgroundColor: 'rgb(245, 245, 245)'}}>
                <Grid item xs={2}>
                    <CardsHistory
                        text={'Ingresos totales'}
                        color={'#0B4762'}
                        BoxShadowColor={'1.07865px 3.23595px 4.31461px rgba(11, 71, 98, 0.25)'}
                        data={statistic["totalBookings-diners"]}
                        tootlTipText={'Cantidad de cubiertos totales ingresados exitosamente.'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CardsHistory
                        text={'Ingresos Woki'}
                        color={'#1AAFDE'}
                        BoxShadowColor={'1.07865px 3.23595px 4.31461px rgba(26, 175, 222, 0.25)'}
                        data={statistic["woki-diners"]}
                        tootlTipText={'Aquellos cubiertos ingresados, cuya reserva fue realizada vía Woki.'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CardsHistory
                        text={'Ingresos por Link'}
                        color={'#F97F59'}
                        BoxShadowColor={'1.07865px 3.23595px 4.31461px rgba(249, 127, 89, 0.25)'}
                        data={statistic["link-diners"]}
                        tootlTipText={'Cubiertos ingresados existosamente, cuya reserva fue realizada a través del link del establecimiento.'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CardsHistory
                        text={'Ingresos manuales'}
                        color={'#B2B2B2'}
                        BoxShadowColor={'1.07865px 3.23595px 4.31461px rgba(178, 178, 178, 0.25)'}
                        data={statistic["manual-diners"]}
                        tootlTipText={'Cubiertos ingresados existosamente, cuya reserva fue cargada manualmente por el establecimiento.'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CardsHistory
                        text={'Ingresos tus clientes'}
                        color={'#EEB115'}
                        BoxShadowColor={'1.07865px 3.23595px 4.31461px rgba(238, 177, 21, 0.25)'}
                        data={statistic["myClients-diners"]}
                        tootlTipText={'Cubiertos ingresados exitosamente, cuya reserva proviene de un cliente abonado del establecimiento.'}
                    />
                </Grid>
            </Grid>
            <section style={{paddingTop: '3rem'}}>
                <TableBookingClients/>
            </section>
            <ProgressIndicator open={loading}/>
            <ErrorDialog open={error !== null} title={"Error"} type={ModalMode.ERROR} onAccept={closeErrorDialog}
                         body={error ?? ""}/>

        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        history: state.bookingHistoryReducer.history,
        statistic: state.bookingHistoryReducer.statistic,
        rowsPerPage: state.bookingHistoryReducer.rowsPerPage,
        page: state.bookingHistoryReducer.page,
        from: state.bookingHistoryReducer.from,
        to: state.bookingHistoryReducer.to,
        origin: state.bookingHistoryReducer.origin,
        state: state.bookingHistoryReducer.state,
        query: state.bookingHistoryReducer.query,
        loading: state.bookingHistoryReducer.loading,
        error: state.bookingHistoryReducer.error,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHistory: () => dispatch(fetchBookingHistory()),
        fetchStatistics: () => dispatch(fetchBookingTotalArrives()),
        changeDate: ({from, to}) => dispatch(changeBookingHistoryFilterDate({
            from,
            to,
        })),


        closeErrorDialog: () => dispatch(closeErrorDialog())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingClients);
