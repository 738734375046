import { getRRule } from "../../components/V3/Shifts/Wizard/utils/ScheduleFunctions";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";

// Actions
import { ensurePartnerExist, loadPlanningDataFail, savingDeletePlanningDataSuccess, savingEditPlanningDataSuccess, savingPlanningData, savingPlanningDataFail, savingPlanningDataSuccess, setPlanningViewMode, setShowPlanningErrors, setShowPlanningSnackbar, startLoadingPlanning } from './index';

export const putPlannings = () => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { planningReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { 
            openningHoursForm, 
            planningSelected, 
            specialDatesForm,
            closingDatesForm,
            planningSnackbar, 
            punctualDates,
            planningName,
        } = planningReducer;

        let putExcludeDatesArray = punctualDates.allExcludeDates;
        let putSpecialDatesArray = punctualDates.allDates;
        let putTimeRangeArray = [];
        
        if ( planningSelected === 'Horario de apertura' ) {
            for (let i = 0; i < openningHoursForm.rangeHours.length; i++) {
                putTimeRangeArray.push({ 
                    start:  openningHoursForm.rangeHours[i].from,
                    end: openningHoursForm.rangeHours[i].to
                });
            }
        } else {
            if ( planningSelected === 'Fechas especiales' ) {
                for (let i = 0; i < specialDatesForm.rangeHours.length; i++) {
                    putTimeRangeArray.push({ 
                        start:  specialDatesForm.rangeHours[i].from,
                        end: specialDatesForm.rangeHours[i].to
                    });
                }
            } else {
                for (let i = 0; i < closingDatesForm.rangeHours.length; i++) {
                    putTimeRangeArray.push({ 
                        start:  closingDatesForm.rangeHours[i].from,
                        end: closingDatesForm.rangeHours[i].to
                    });
                }
            }
        };

        // for (let j = 0; j < punctualDates.newDates.length; j++) {
        //     if ( punctualDates.newDates[j] !== null && punctualDates.newDates[j].length !== 2 ) {
        //         putSpecialDatesArray.push( moment( punctualDates.newDates[j] ).toISOString() );
        //     }
        // };

        // if ( punctualDates.rangesSingleDates.length > 0 ) {
        //     console.log( punctualDates.rangesSingleDates );
        //     for (let k = 0; k < punctualDates.rangesSingleDates.length; k++) {
        //         for (let l = 0; l < punctualDates.rangesSingleDates[k].length; l++) {
        //             putSpecialDatesArray.push( moment( punctualDates.rangesSingleDates[k][l],'DD.MM.YYYY HH:mm' ).toISOString() );
        //         }
        //     }
        // }

        // for (let m = 0; m < punctualDates.excludeDate.length; m++) {
        //     putExcludeDatesArray.push( moment( punctualDates.excludeDate[m] ).toISOString() );
        // }

        try {

            ensurePartnerExist( idPartnerSelected );

            const rule = getRRule(
                new Date( openningHoursForm.repeatStart ), 
                openningHoursForm.repeatEnd ? new Date( openningHoursForm.repeatEnd ) : null, 
                openningHoursForm.repeatEvery, 
                openningHoursForm.daysSelected
            );

            let newPlanningOpenning = {
                id: uuidv4(),
                name: planningName,
                enable: true,
                timeRanges: putTimeRangeArray,
                specialDates: putSpecialDatesArray,
                excludeDates: putExcludeDatesArray,
                days: openningHoursForm.daysSelected,
                repeat: rule.toString(),
                startDate: openningHoursForm.repeatStart.toISOString(),
                endDate: openningHoursForm.repeatEnd
            };

            let newPlanningSpecialDates = {
                id: uuidv4(),
                name: planningName,
                enable: true,
                timeRanges: putTimeRangeArray,
                reason: specialDatesForm.reason,
                specialDates: putSpecialDatesArray,
            };

            let newPlanningClosing = {
                id: uuidv4(),
                name: planningName,
                enable: true,
                timeRanges: putTimeRangeArray,
                reason: closingDatesForm.reason,
                specialDates: putSpecialDatesArray,
            };

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            if ( planningSelected === 'Horario de apertura' ) {
                if ( planningName !== '' && putTimeRangeArray.length !== 0 ) {

                    dispatch( startLoadingPlanning() );

                    console.log( 'ARREGLO ---> ', putSpecialDatesArray );

                    await axios.put(`${API_URL}/establishment/planning/${ idPartnerSelected }/opening`, 
                        newPlanningOpenning, 
                        { headers: { Authorization: `Bearer ${token}` } }
                    );

                    // console.log( resp );

                    dispatch( setPlanningViewMode( 'VIEW' ) );
                    dispatch( savingPlanningDataSuccess() );

                } else {
                    dispatch( setShowPlanningSnackbar({
                        typeSnackbar: 'Error',
                        showSnackbar: true,
                    }));

                    if ( putTimeRangeArray.length === 0 ) {
                        dispatch( setShowPlanningSnackbar({
                            snackbarMessage: 'Completá los campos obligatorios',
                            failedComponent: 'rangeDates',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        }));
                    } else {
                        dispatch( setShowPlanningSnackbar({
                            ...planningSnackbar,
                            snackbarMessage: 'Completá los campos obligatorios',
                            failedComponent: ''
                        }));
                    };
                }
            } else {
                if ( planningSelected === 'Fechas especiales' ) {
                    if ( planningName !== '' && putTimeRangeArray.length !== 0 && putSpecialDatesArray.length !== 0 ) {
                        dispatch( startLoadingPlanning() );

                        await axios.put(`${API_URL}/establishment/planning/${ idPartnerSelected }/special-date`, 
                            newPlanningSpecialDates, 
                            { headers: { Authorization: `Bearer ${token}` } }
                        );

                        dispatch( setPlanningViewMode( 'VIEW' ) );
                        dispatch( savingPlanningDataSuccess() );
                    } else {
                        if ( putSpecialDatesArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Es necesario ingresar al menos una Fecha especial',
                                failedComponent: '',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        } else if ( putTimeRangeArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Completá los campos obligatorios',
                                failedComponent: 'rangeDates',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        } else {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Completá los campos obligatorios ',
                                failedComponent: 'rangeDates',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        }
                    }
                } else {
                    if ( planningName !== '' && putSpecialDatesArray.length !== 0 ) {
                        dispatch( startLoadingPlanning() );

                        await axios.put(`${API_URL}/establishment/planning/${ idPartnerSelected }/closing`, 
                            newPlanningClosing, 
                            { headers: { Authorization: `Bearer ${token}` } }
                        );

                        dispatch( setPlanningViewMode( 'VIEW' ) );
                        dispatch( savingPlanningDataSuccess() );
                    } else {
                        dispatch( setShowPlanningSnackbar({
                            ...planningSnackbar,
                            snackbarMessage: 'Completá los campos obligatorios ',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        }));

                        if ( putSpecialDatesArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                ...planningSnackbar,
                                failedComponent: 'specialDatesArray'
                            }));
                        };
                    }
                }
            }
        } catch (error) {
            console.log(error);
            dispatch( savingPlanningDataFail( error.message ) );
            dispatch( setShowPlanningErrors({ errorMessage: error.response.data }));
            dispatch( setShowPlanningSnackbar({
                snackbarMessage: 'Error en la creación de planificación',
                typeSnackbar: 'Error',
                failedComponent: '',
                showSnackbar: true,
            }));
        }
    }
};

export const deletePlanning = ( id, typePlanningDelete ) => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExist( idPartnerSelected );
            dispatch( startLoadingPlanning() );

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            if ( typePlanningDelete === 'opennings' ) {
                await axios.delete(`${ API_URL }/establishment/planning/${ idPartnerSelected }/opening/${ id }`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                if ( typePlanningDelete === 'specialDates' ) {
                    await axios.delete(`${ API_URL }/establishment/planning/${ idPartnerSelected }/special-date/${ id }`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                } else {
                    await axios.delete(`${ API_URL }/establishment/planning/${ idPartnerSelected }/closing/${ id }`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                };
            };

            dispatch( savingDeletePlanningDataSuccess() );

        } catch (error) {
            console.log(error);
            dispatch( loadPlanningDataFail( error.message ) );
            dispatch( setShowPlanningErrors({
                errorMessage: error.response.data,
                type: 'Error'
            }));
        }
    }
};

export const handleUpdateEnablePlanning = ( id, newArray, typePatch, typeArray ) => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;

        try {
            ensurePartnerExist( idPartnerSelected );
            dispatch( startLoadingPlanning() );

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            if ( typeArray === 'opennings' ) {
                    await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/opening/${ id }`, newArray, { 
                        headers: { Authorization: `Bearer ${ token }` } 
                    } );
            } else {
                if ( typeArray === 'specialDates' ) {
                    await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/special-date/${ id }`, newArray, { 
                        headers: { Authorization: `Bearer ${ token }` }
                    });
                } else {
                    await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/closing/${ id }`, newArray, { 
                        headers: { Authorization: `Bearer ${ token }` }
                    });
                }
            };

            dispatch( savingEditPlanningDataSuccess() );

            if ( typePatch === 'form' ) {
                dispatch( setPlanningViewMode( 'VIEW' ) );
            } else {
                return;
            }

        } catch (error) {
            console.log(error);
            dispatch( loadPlanningDataFail( error.message ) );
            dispatch( setShowPlanningSnackbar({
                snackbarMessage: error.response.data,
                typeSnackbar: 'Error',
                showSnackbar: true,
            }));
            // dispatch( setShowPlanningErrors({
            //     errorMessage: error.response.data,
            //     type: 'Error'
            // }));
        }
    }
};

export const patchPlanning = ( id, typePatch ) => {
    return async ( dispatch, getState, { getFirebase } ) => {
        const { planningReducer, userAccountsReducer } = getState();
        const { idPartnerSelected } = userAccountsReducer.editReducer;
        const { 
            openningHoursForm,
            closingDatesForm,
            specialDatesForm,
            planningSelected,
            planningSnackbar,
            punctualDates, 
            planningName,
        } = planningReducer;

        try {
            ensurePartnerExist( idPartnerSelected );

            let excludeDatesArray = punctualDates.allExcludeDates;
            let specialDatesArray = punctualDates.allDates;
            let patchTimeRangeArray = [];

            if ( planningSelected === 'Horario de apertura' ) {
                for (let i = 0; i < openningHoursForm.rangeHours.length; i++) {
                    patchTimeRangeArray.push({ 
                        start:  openningHoursForm.rangeHours[i].from,
                        end: openningHoursForm.rangeHours[i].to
                    });
                };

                // for (let j = 0; j < punctualDates.newDates.length; j++) {
                //     if ( punctualDates.newDates[j] !== null && typeof punctualDates.newDates[j] === 'string' ) {
                //         specialDatesArray.push( moment( punctualDates.newDates[j] ).toISOString() );
                //     }
                // };

                // for (let k = 0; k < punctualDates.excludeDate.length; k++) {
                //     excludeDatesArray.push( dayjs( punctualDates.excludeDate[k] ).toISOString() );
                // }
            } else {
                if ( planningSelected === 'Fechas especiales' ) {
                    for (let l = 0; l < specialDatesForm.rangeHours.length; l++) {
                        patchTimeRangeArray.push({ 
                            start:  specialDatesForm.rangeHours[l].from,
                            end: specialDatesForm.rangeHours[l].to
                        });
                    }

                    for (let m = 0; m < specialDatesForm.specialDates.length; m++) {
                        specialDatesArray.push( dayjs( specialDatesForm.specialDates[m] ).toISOString() );
                    }
                } else {
                    if ( closingDatesForm.enabledRangeHours ) {
                        for (let n = 0; n < closingDatesForm.rangeHours.length; n++) {
                            patchTimeRangeArray.push({ 
                                start:  closingDatesForm.rangeHours[n].from,
                                end: closingDatesForm.rangeHours[n].to
                            });
                        }
                    } else {
                        patchTimeRangeArray = [];
                    }

                    for (let p = 0; p < closingDatesForm.specialDates.length; p++) {
                        specialDatesArray.push( dayjs( closingDatesForm.specialDates[p] ).toISOString() );
                    }
                }
            };

            if ( punctualDates.rangesSingleDates.length > 0 ) {
                console.log( punctualDates.rangesSingleDates );
                for (let k = 0; k < punctualDates.rangesSingleDates.length; k++) {
                    if ( punctualDates.rangesSingleDates[k] !== [] ) {
                        for (let l = 0; l < punctualDates.rangesSingleDates[k].length; l++) {
                            specialDatesArray.push( moment( punctualDates.rangesSingleDates[k][l],'DD.MM.YYYY HH:mm' ).toISOString() );
                        }
                    };
                };
            };

            const rule = getRRule(
                new Date( openningHoursForm.repeatStart ), 
                openningHoursForm.repeatEnd ? new Date( openningHoursForm.repeatEnd ) : null, 
                openningHoursForm.repeatEvery, 
                openningHoursForm.daysSelected
            );

            let updateOpenningPlanning = {
                id: id,
                name: planningName,
                enable: openningHoursForm.enable,
                timeRanges: patchTimeRangeArray,
                specialDates: specialDatesArray,
                excludeDates: excludeDatesArray,
                days: openningHoursForm.daysSelected,
                repeat: rule.toString(),
                startDate: openningHoursForm.repeatStart.toISOString(),
            };

            if ( openningHoursForm.repeatEnd !== null ) {
                updateOpenningPlanning.endDate = openningHoursForm.repeatEnd.toISOString();
            };

            let updateSpecialDatesPlanning = {
                id: id,
                name: planningName,
                enable: specialDatesForm.enable,
                reason: specialDatesForm.reason,
                timeRanges: patchTimeRangeArray,
                specialDates: specialDatesArray,
            };

            let updateClosingPlanning = {
                id: id,
                name: planningName,
                enable: closingDatesForm.enable,
                reason: closingDatesForm.reason,
                timeRanges: patchTimeRangeArray,
                specialDates: specialDatesArray,
            };

            const API_URL = process.env.REACT_APP_API_URL_V3;
            const auth = getFirebase().auth();
            const token = await auth.currentUser.getIdToken(true);

            if ( planningSelected === 'Horario de apertura' ) {
                if ( planningName !== '' && patchTimeRangeArray.length !== 0 ) {
                    dispatch( savingPlanningData() );

                    console.log( updateOpenningPlanning );

                    await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/opening/${ id }`, updateOpenningPlanning, { 
                        headers: { Authorization: `Bearer ${token}` } 
                    } );

                    dispatch( savingEditPlanningDataSuccess() );

                    if ( typePatch === 'form' ) {
                        dispatch( setPlanningViewMode( 'VIEW' ) );
                    } else {
                        return;
                    }
                } else {
                    dispatch( setShowPlanningSnackbar({
                        typeSnackbar: 'Error',
                        showSnackbar: true,
                    }));

                    if ( patchTimeRangeArray.length === 0 ) {
                        dispatch( setShowPlanningSnackbar({
                            snackbarMessage: 'Completá los campos obligatorios ',
                            failedComponent: 'rangeDates',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        }));
                    } else {
                        dispatch( setShowPlanningSnackbar({
                            snackbarMessage: 'Completá los campos obligatorios',
                            typeSnackbar: 'Error',
                            failedComponent: '',
                            showSnackbar: true,
                        }));
                    };
                }
            } else {
                if ( planningSelected === 'Fechas especiales' ) {
                    if ( planningName !== '' && patchTimeRangeArray.length !== 0 && specialDatesArray.length !== 0 ) {
                        dispatch( savingPlanningData() );

                        await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/special-date/${ id }`, updateSpecialDatesPlanning, { 
                            headers: { Authorization: `Bearer ${token}` }
                        });

                        dispatch( savingPlanningDataSuccess() );

                        if ( typePatch === 'form' ) {
                            dispatch( setPlanningViewMode( 'VIEW' ) );
                        } else {
                            return;
                        }
                    } else {
                        if ( specialDatesArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Es necesario ingresar al menos una Fecha especial',
                                failedComponent: '',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        } else if ( patchTimeRangeArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Completá los campos obligatorios ',
                                failedComponent: 'rangeDates',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        } else {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Completá los campos obligatorios ',
                                failedComponent: 'rangeDates',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        }
                    }
                } else {
                    if ( planningName !== '' && specialDatesArray.length !== 0 ) {
                        dispatch( savingPlanningData() );

                        await axios.patch(`${ API_URL }/establishment/planning/${ idPartnerSelected }/closing/${ id }`, updateClosingPlanning, { 
                            headers: { Authorization: `Bearer ${token}` }
                        });

                        dispatch( savingPlanningDataSuccess() );

                        if ( typePatch === 'form' ) {
                            dispatch( setPlanningViewMode( 'VIEW' ) );
                        } else {
                            return;
                        }
                    } else {
                        dispatch( setShowPlanningSnackbar({
                            ...planningSnackbar,
                            snackbarMessage: 'Completá los campos obligatorios ',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        }));

                        if ( specialDatesArray.length === 0 ) {
                            dispatch( setShowPlanningSnackbar({
                                snackbarMessage: 'Completá los campos obligatorios ',
                                failedComponent: 'specialDatesArray',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            }));
                        };
                    }
                }
            }

        } catch (error) {
            console.log(error);
            dispatch( loadPlanningDataFail( error.message ) );
            dispatch( setShowPlanningSnackbar({
                snackbarMessage: 'Error en la edición',
                typeSnackbar: 'Error',
                failedComponent: '',
                showSnackbar: true,
            }));
            // dispatch( setShowPlanningErrors({
            //     errorMessage: error.response.data,
            //     type: 'Error'
            // }));
        }
    }
};