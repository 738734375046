import React, { useEffect } from 'react';

import { connect } from "react-redux";

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";

// Components
import Home from "../../components/Home";

function HomePage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Inicio");
        props.changeShowSidebar(true);
    }, [props])


    return ( <Home/> )
}

const mapDispatchToProps = dispatch => {
    return {
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar()),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect( null, mapDispatchToProps )( HomePage );
