import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: "Poppins",
    color: "#818181",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "8px"
  },
}));

const Description = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.subtitle} {...restProps}>
      {text}
    </Typography>
  );
};

export default Description;
