const SHIFTS_VIEW_MODE = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  NEW: "NEW",
  DELETE: "DELETE",
};

const CHANGE_SHIFTS_VIEW_MODE = "CHANGE_SHIFTS_VIEW_MODE";
const TOGGLE_TURNOS_LOADING = 'TOGGLE_TURNOS_LOADING';
const SET_TURNOS_ERROR = 'SET_TURNOS_ERROR';
const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR';
const FETCH_TURNOS_SUCCESS = "FETCH_TURNOS_SUCCESS";
const FETCH_SECTORS_SUCCESS = "FETCH_SECTORS_SUCCESS";
const CLOSE_TURNOS_ERROR = "CLOSE_TURNOS_ERROR";
const RESET_TURNO = "RESET_TURNO";
const SET_SAVE_OK = "SET_SAVE_OK";
const SET_CLOSE_SAVE_OK = "SET_CLOSE_SAVE_OK";

const CHANGE_TURNO_MODE = "CHANGE_TURNO_MODE";
const SET_DELETE_TURNO_SUCCESS = 'SET_DELETE_TURNO_SUCCESS';
const SET_CLOSE_DELETE_TURNO_SUCCESS = 'SET_CLOSE_DELETE_TURNO_SUCCESS';

const CHANGE_SHIFT_NAME = "CHANGE_SHIFT_NAME";
const SET_ADVANCE_START = "SET_ADVANCE_START";
const SET_ADVANCE_END = "SET_ADVANCE_END";
const SET_ADVANCE_END_RESPECT_TO = "SET_ADVANCE_END_RESPECT_TO";
const SET_MIN_BOOKING_GROUP = "SET_MIN_BOOKING_GROUP";
const SET_MAX_BOOKING_GROUP = "SET_MAX_BOOKING_GROUP";
const SET_AUTO_ACEPTANCE = "SET_AUTO_ACEPTANCE";

const SET_ENABLED = 'SET_ENABLED';

const SET_IS_SPECIAL = "SET_IS_SPECIAL";
const SET_CALENDAR_OPENING = "SET_CALENDAR_OPENING";
const SET_CALENDAR_CLOSING = "SET_CALENDAR_CLOSING";
const SET_DAYS = "SET_DAYS";
const SET_START_DAY = "SET_START_DAY";
const SET_END_DAY = "SET_END_DAY";
const SET_SPECIAL_DAY = "SET_SPECIAL_DAY";
const SET_EXCLUDE_DAY = "SET_EXCLUDE_DAY";
const SET_REPEAT = "SET_REPEAT";
const SET_SUBSHIFT = "SET_SUBSHIFT";

const SET_AVAILABLE_LAYOUTS = 'SET_AVAILABLE_LAYOUTS';
const SET_RESERVABLE_SECTORS = 'SET_RESERVABLE_SECTORS';

const SET_WORK_WITH_WAIT_LIST = 'SET_WORK_WITH_WAIT_LIST';
const SET_AUTOMATIC_WAIT_LIST = 'SET_AUTOMATIC_WAIT_LIST';
const SET_WAIT_LIST_NOTE = 'SET_WAIT_LIST_NOTE';
const SET_MAX_WAITING_ITEMS = 'SET_MAX_WAITING_ITEMS';
const SET_WAITING_LIST_SECTORS_FULL = 'SET_WAITING_LIST_SECTORS_FULL';

const SET_ADITIONAL_INFO = 'SET_ADITIONAL_INFO';
const SET_DWELL_TIME_GROUP = 'SET_DWELL_TIME_GROUP';
const SET_DWELL_TIME_BY_TABLE = 'SET_DWELL_TIME_BY_TABLE';
const SET_ROTATION_CONTROL = 'SET_ROTATION_CONTROL';
const SET_ROTATION_CONTROL_BY_GROUP = 'SET_ROTATION_CONTROL_BY_GROUP';
const SET_ROTATION_CONTROL_BY_TABLE = 'SET_ROTATION_CONTROL_BY_TABLE';
const SET_OPTIONALS = 'SET_OPTIONALS';

const CHANGE_TURNO_APPLY_FROM_DATE = "CHANGE_TURNO_APPLY_FROM_DATE";
const CHANGE_TURNO_MAINTAIN_BOOKINGS = 'CHANGE_TURNO_MAINTAIN_BOOKINGS';
const SET_SHOW_EDIT_MODAL = 'SET_SHOW_EDIT_MODAL';
const SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL = 'SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL';
const SET_SHOW_EXIT_WITHOUT_SAVING = 'SET_SHOW_EXIT_WITHOUT_SAVING';

const SET_GROUPS_ON_BOOKABLE_SECTORS = 'SET_GROUPS_ON_BOOKABLE_SECTORS';  
const CHANGE_EXTRA_EXCLUDE_DATA = 'CHANGE_EXTRA_EXCLUDE_DATA';
const SET_DISABLED_ALL_LAYOUT = 'SET_DISABLED_ALL_LAYOUT';
const SET_NOT_BOOKABLE_GROUP = 'SET_NOT_BOOKABLE_GROUP';
const CHANGE_BOOKABLE_GROUP = 'CHANGE_BOOKABLE_GROUP';
const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
const SET_EXCLUDE_DATA = 'SET_EXCLUDE_DATA';
const SET_EXTRA_DATA = 'SET_EXTRA_DATA';
const SET_ALL_GROUPS = 'SET_ALL_GROUPS';
const RESET_GROUPS = 'RESET_GROUPS';
const SET_GROUP = 'SET_GROUP';

const SET_EXTRA_TABLE = 'SET_EXTRA_TABLE';
const UPDATE_TABLES = 'UPDATE_TABLES';

export { 
  SET_SHOW_DEACTIVATE_WAIT_LIST_MODAL, 
  SET_GROUPS_ON_BOOKABLE_SECTORS,
  SET_CLOSE_DELETE_TURNO_SUCCESS, 
  CHANGE_TURNO_MAINTAIN_BOOKINGS, 
  SET_ROTATION_CONTROL_BY_TABLE, 
  SET_ROTATION_CONTROL_BY_GROUP, 
  SET_WAITING_LIST_SECTORS_FULL,
  CHANGE_TURNO_APPLY_FROM_DATE, 
  SET_SHOW_EXIT_WITHOUT_SAVING,
  SET_ADVANCE_END_RESPECT_TO,
  CHANGE_EXTRA_EXCLUDE_DATA,
  SET_DELETE_TURNO_SUCCESS, 
  SET_DISABLED_ALL_LAYOUT, 
  SET_WORK_WITH_WAIT_LIST, 
  SET_AUTOMATIC_WAIT_LIST, 
  CHANGE_SHIFTS_VIEW_MODE, 
  SET_DWELL_TIME_BY_TABLE,
  SET_NOT_BOOKABLE_GROUP, 
  SET_RESERVABLE_SECTORS,
  FETCH_SECTORS_SUCCESS,
  TOGGLE_TURNOS_LOADING, 
  CHANGE_BOOKABLE_GROUP, 
  SET_MIN_BOOKING_GROUP, 
  SET_MAX_BOOKING_GROUP,
  SET_AVAILABLE_LAYOUTS, 
  SET_MAX_WAITING_ITEMS, 
  FETCH_TURNOS_SUCCESS, 
  SET_VALIDATION_ERROR, 
  SET_CALENDAR_OPENING, 
  SET_CALENDAR_CLOSING, 
  SET_DWELL_TIME_GROUP, 
  SET_ROTATION_CONTROL, 
  SET_SHOW_EDIT_MODAL,
  SET_AUTO_ACEPTANCE,
  CLOSE_TURNOS_ERROR, 
  SET_WAIT_LIST_NOTE, 
  SET_ADITIONAL_INFO,
  CHANGE_TURNO_MODE, 
  CHANGE_SHIFT_NAME, 
  SET_CLOSE_SAVE_OK,
  SET_ADVANCE_START, 
  SET_ACTIVE_GROUP, 
  SET_EXCLUDE_DATA,
  SHIFTS_VIEW_MODE, 
  SET_TURNOS_ERROR, 
  SET_ADVANCE_END, 
  SET_EXCLUDE_DAY, 
  SET_SPECIAL_DAY, 
  SET_EXTRA_TABLE,
  SET_IS_SPECIAL,
  SET_ALL_GROUPS, 
  SET_EXTRA_DATA, 
  SET_START_DAY, 
  SET_OPTIONALS,
  UPDATE_TABLES,
  RESET_GROUPS,
  SET_SUBSHIFT, 
  RESET_TURNO, 
  SET_SAVE_OK, 
  SET_END_DAY, 
  SET_ENABLED,
  SET_REPEAT,
  SET_GROUP, 
  SET_DAYS, 
};
