export const START_EDITING_MESSAGE = "START_EDITING_MESSAGE"
export const END_EDITING_MESSAGE = "END_EDITING_MESSAGE"
export const CANCEL_EDITING_MESSAGE = "CANCEL_EDITING_MESSAGE"
export const DELETE_NEW_MESSAGE = "DELETE_NEW_MESSAGE"

export const EDIT_MESSAGE_SUCCESSFUL = "EDIT_MESSAGE_SUCCESSFUL"

export const START_CREATION_NEW_MESSAGE = "START_CREATION_NEW_MESSAGE"

export const TOGGLE_LOADING_CREATION = "TOGGLE_LOADING_CREATION"

export const MESAYA_TYPE = "mesaya"
export const RESERVA_TYPE = "reserva"
export const BOTH_TYPE = "ambos"

