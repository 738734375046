import React from 'react';

// Material Ui
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';

const SimpleTrashIcon = (props) => {
    const { onClick, height, marginTop, color } = props;
    return (
        <IconButton aria-label="delete" onClick={onClick} sx={{margin: 'auto'}}>
            <DeleteOutlineOutlinedIcon 
                sx={{
                    height: height !== "" ? height : '2rem',
                    width: height !== "" ? height : '2rem',
                    marginTop: marginTop ? marginTop : '',
                    fontSize: '2.5rem',
                    cursor: 'pointer', 
                    color: color !== "" ? color : '#0B4762'
                }}
            />
        </IconButton>
    )
}

export default SimpleTrashIcon;