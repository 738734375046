import React, {useState} from 'react'
import {Button, IconButton} from "@material-ui/core";
import Sort from "../SvgComponents/sort";
import SimpleInput from "../GeneralComponents/Input General/SimpleInput";
import TextArea from "../GeneralComponents/Input General/TextArea";
import ChangePrice from "./ChangePrice";
import DaySelector from "../GeneralComponents/daySelector";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
import AddImageMenu from '../../assets/images/add-image-carta.jpg';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F0F0F0',
        marginBottom: '10px'
    },
    sortRow: {
        width: '80px',
        marginLeft: '20px',
        marginRight: '20px',
        cursor: 'move'
    },
    imageRow: {
        display: 'flex',
        width: '200px',
        alignItems: 'center'
    },
    productImage: {
        maxHeight: '100px',
        maxWidth: '100px',
    },
    addImageButton: {
        width: '100px',
        height: '100px'
    },
    name: {
        width: '15%'
    },
    description: {
        width: '15%'
    },
    priceRow: {
        width: "10%",
        textAlign: 'center'
    },
    actionsRow: {
        textAlign: 'center',
        width: "10%",
    },
    daysRow: {
        width: "8%",
    },
}))

const ProductRow = (props) => {
    const classes = useStyles()

    const {row, index, showSortColumn, category} = props

    const {
        onChangeName,
        onChangeVisibility,
        onChangeDescription,
        onChangePrice,
        onChangeDays,
        onChangeImage,
        onDelete
    } = props

    const [editedNameIndex, setEditedNameIndex] = useState();
    const [editedDescriptionIndex, setEditedDescriptionIndex] = useState();
    const [editedPriceIndex, setEditedPriceIndex] = useState();
    const [editedCategoryProduct, setEditedCategoryProduct] = useState();

    const [editedName, setEditedName] = useState("");
    const [editedDescription, setEditedDescription] = useState("");
    const [editedPrice, setEditedPrice] = useState("");

    const onFocusName = (categoryId, productIndex, value) => {
        setEditedCategoryProduct(categoryId);
        setEditedNameIndex(productIndex);
        setEditedName(value);
    };
    const onBlurName = (categoryId) => {
        onChangeName(categoryId, editedNameIndex, editedName);
        setEditedNameIndex("");
    };

    const onFocusDescription = (categoryId, productIndex, value) => {
        setEditedCategoryProduct(categoryId);
        setEditedDescriptionIndex(productIndex);
        setEditedDescription(value);
    };

    const onBlurDescription = (categoryId) => {
        onChangeDescription(
            categoryId,
            editedDescriptionIndex,
            editedDescription
        );
        setEditedDescriptionIndex("");
    };

    const changeDay = (categoryId, productIndex, index, oldDays, newValue) => {
        let editedDays = [...oldDays];
        editedDays[index] = newValue;
        onChangeDays(categoryId, productIndex, editedDays);
    };

    return (
        <tr id="tr-product-row" key={row.name} className={classes.root}>
            <td className={classes.imageRow}>
                <a className={classes.sortRow}>
                    {showSortColumn
                        ? null
                        :
                        <Sort/>
                    }
                </a>
                <div>
                    <Button
                        tabIndex={index + 1}
                        component="label"
                        className={classes.addImageButton}
                    >
                        <img
                            src={
                                row.newImg
                                    ? URL.createObjectURL(row.newImg)
                                    : row.imageUrl ? row.imageUrl : AddImageMenu
                            }
                            className={classes.productImage}
                            alt={""}/>
                        <input
                            className={classes.productImage}
                            onChange={(e) =>
                                onChangeImage(
                                    category.id,
                                    index,
                                    e.target.files[0]
                                )
                            }
                            style={{display: "none"}}
                            type="file"
                            id="productimage"
                            name="productimage"
                            accept="image/png, image/jpeg"
                        />
                    </Button>
                </div>
            </td>
            <td className={classes.name}>
                <SimpleInput
                    tabIndex={index + 2}
                    onChange={(e) => setEditedName(e.target.value)}
                    onFocus={() =>
                        onFocusName(category.id, index, row.name)
                    }
                    onBlur={() => onBlurName(category.id)}
                    text={
                        editedNameIndex === index &&
                        category.id === editedCategoryProduct
                            ? editedName
                            : row.name
                    }
                />
            </td>
            <td className={classes.description}>
                <TextArea
                    tabIndex={index + 3}
                    text={
                        editedDescriptionIndex === index &&
                        category.id === editedCategoryProduct
                            ? editedDescription
                            : row.description}
                    onChange={(e) => setEditedDescription(e.target.value)}
                    onFocus={() =>
                        onFocusDescription(
                            category.id,
                            index,
                            row.description
                        )
                    }
                    onBlur={() => onBlurDescription(category.id)}
                />
            </td>
            <td className={classes.priceRow}>
                <ChangePrice
                    tabIndex={index + 4}
                    onChange={(value) => onChangePrice(category.id, index, value)}
                    value={
                        editedPriceIndex === index &&
                        category.id === editedCategoryProduct
                            ? editedPrice
                            : row.price
                    }
                />
            </td>
            <td className={classes.daysRow} tabIndex={-100}>
                <DaySelector
                    days={row.days}
                    rounded
                    char
                    enable
                    star
                    onClick={(i, newValue) =>
                        changeDay(category.id, index, i, row.days, newValue)
                    }
                />
            </td>
            <td className={classes.actionsRow} tabIndex={-100}>
                <IconButton
                    onClick={() =>
                        onChangeVisibility(
                            category.id,
                            index,
                            !row.visibility
                        )
                    }
                    aria-label="visibility"
                >
                    {!row.visibility ? (
                        <VisibilityOffIcon/>
                    ) : (
                        <VisibilityIcon/>
                    )}
                </IconButton>
                <IconButton
                    onClick={() =>
                        onDelete(category.id, index)
                    }
                    aria-label="delete"
                >
                    <DeleteIcon/>
                </IconButton>
            </td>
        </tr>
    )
}

export default ProductRow