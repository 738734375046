import React from 'react';

// Icons
import { PeopleIcon, TableIcon } from '../../../../../SvgComponents/icons';

// Material Ui
import { Box, Typography } from '@mui/material';

const GeneralContainerStyles = { 
    p: '.5rem .5rem 1rem 1rem', 
    flexDirection: 'column', 
    width: 'max-content', 
    display: 'flex', 
    gap: '8px',
};

const TextButtonContainerStyles = { 
    textDecoration: 'underline',
    fontFamily: 'Poppins',
    cursor: 'pointer', 
    color: "#0B4762",
    fontSize: '15px',
    fontWeight: 500
};

const ButtonContainerStyles = { 
    alignItems: 'center', 
    display: 'flex', 
    gap: '10px', 
};

const ShiftStep3AddExceptions = ({ setShowGroupModal, setShowTableModal }) => {
    return (
        <Box sx={ GeneralContainerStyles }>
            <Box sx={ ButtonContainerStyles } onClick={() => setShowGroupModal( true )}>
                <PeopleIcon />
                <Typography sx={ TextButtonContainerStyles } >
                    Agregar excepción por grupos
                </Typography>
            </Box>
            <Box sx={ ButtonContainerStyles } onClick={() => setShowTableModal(true)}>
                <TableIcon />
                <Typography sx={ TextButtonContainerStyles } >
                    Agregar excepción por mesa
                </Typography>
            </Box>
        </Box>
    )
}

export default ShiftStep3AddExceptions