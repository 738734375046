// Constants
import { 
    GENERAL_INFORMATION_ROUTE, 
    BOOKING_POLITICS_ROUTE,
    // CANCEL_POLITICS_ROUTE,
    USER_MYINVOICES_ROUTE,
    MY_BOOKINGS_ROUTE,
    MY_CLIENTS2_ROUTE,
    SECTORS_ROUTE_V3,
    OPTIONAL_ROUTE,
    FILTERS_ROUTE,
    OPENING_ROUTE, 
    PHOTOS_ROUTE, 
    USERS_ROUTE, 
    HOME_ROUTE,
    HELP_ROUTE,
    SHIFTS_ROUTE_V3,
} from '../../../constants';

// Icons
import { 
    EstablishmentIcon, 
    MyBookingsIcon, 
    MyClientsIcon, 
    OptionalIcon, 
    PlanningIcon, 
    PoliticsIcon, 
    BillingIcon, 
    SectorsIcon, 
    UsersIcon, 
    HelpIcon, 
    HomeIcon, 
    // MKTIcon, 
} from '../../SvgComponents/icons/NewSidebar';
import { ShiftsIcon } from '../../SvgComponents/icons/NewSidebar/ShiftsIcon';

export const drawerItems = [
    {
        title: 'Inicio',
        icon: <HomeIcon />,
        iconPrimary: <HomeIcon fill={ '#0B4762' } />,
        path: HOME_ROUTE,
    },
    {
        title: 'Establecimiento',
        icon: <EstablishmentIcon/>,
        iconPrimary: <EstablishmentIcon fill={ '#0B4762' } />,
        subItems: [
            {
                title: 'Información',
                path: GENERAL_INFORMATION_ROUTE,
            },
            {
                title: 'Filtros',
                path: FILTERS_ROUTE,
            },
            {
                title: 'Fotos',
                path: PHOTOS_ROUTE,
            }
        ],
    },
    {
        title: 'Planificación',
        icon: <PlanningIcon />,
        iconPrimary: <PlanningIcon fill={ '#0B4762' } />,
        path: OPENING_ROUTE,
    },
    {
        title: 'Sectores',
        icon: <SectorsIcon/>,
        iconPrimary: <SectorsIcon fill={ '#0B4762' } />,
        path: SECTORS_ROUTE_V3,
    },
    {
        title: 'Turnos',
        icon: <ShiftsIcon />,
        iconPrimary: <ShiftsIcon fill={ '#0B4762' } />,
        path: SHIFTS_ROUTE_V3,
        // // enableOption: partner !== undefined ? !partner[0].isActive || partner[0].enabledServices["reservas"] : undefined,
        // subItems: [
        //     {
        //         title: 'Configuración de turnos',
        //         path: SHIFTS_ROUTE_V3,
        //     },
        // ]
    },
    {
        title: 'Opcionales',
        icon: <OptionalIcon />,
        iconPrimary: <OptionalIcon fill={ '#0B4762' }/>,
        path: OPTIONAL_ROUTE,
    },
    {
        title: 'Políticas',
        icon: <PoliticsIcon />,
        iconPrimary: <PoliticsIcon fill={ '#0B4762' }/>,
        subItems: [
            {
                title: 'Políticas de reserva',
                path: BOOKING_POLITICS_ROUTE,
            },
            // {
            //     title: 'Políticas de cancelaciones',
            //     path: CANCEL_POLITICS_ROUTE,
            // },
        ]
    },
    // {
    //     title: 'MKT',
    //     icon: <MKTIcon />,
    //     iconPrimary: <MKTIcon fill={ '#0B4762' } />,
    //     // path: CLIENTS_STATISTICS_ROUTE,
    // },
    {
        title: 'Mis reservas',
        icon: <MyBookingsIcon />,
        iconPrimary: <MyBookingsIcon fill={ "#0B4762" } />,
        path: MY_BOOKINGS_ROUTE
    },
    {
        title: 'Mis clientes',
        icon: <MyClientsIcon />,
        iconPrimary: <MyClientsIcon fill={ "#0B4762" }/>,
        path: MY_CLIENTS2_ROUTE,
    },
    {
        title: 'Facturación',
        icon: <BillingIcon />,
        iconPrimary: <BillingIcon fill={ "#0B4762" }/>,
        path: USER_MYINVOICES_ROUTE
    },
    {
        title: 'Ayuda',
        icon: <HelpIcon />,
        iconPrimary: <HelpIcon fill={"#0B4762"}/>,
        path: HELP_ROUTE
    },
    {
        title: 'Usuarios',
        icon: <UsersIcon />,
        iconPrimary: <UsersIcon fill={"#0B4762"}/>,
        path: USERS_ROUTE
    },
];

export const addIconAppBar = ( path, setIconImg, changePrimaryTitleAppBar ) => {
    switch (true) {
        case (path === '/'):
            setIconImg( () => <HomeIcon fill={"#0B4762"}/> );
            changePrimaryTitleAppBar("");
            break;
        case (path === '/informacion-general' || path === '/filtros' || path === '/fotos'):
            if ( path === '/informacion-general' ) {
                changePrimaryTitleAppBar("Información general del establecimiento");
            } else if ( path === '/filtros' ) {
                changePrimaryTitleAppBar("Configuración de filtros de búsqueda");
            } else {
                changePrimaryTitleAppBar("Imágenes de tu establecimiento");
            }
            setIconImg( () => <EstablishmentIcon fill={"#0B4762"}/> );
            break;
        case (path === '/horarios'):
            setIconImg( () => <PlanningIcon fill={"#0B4762"} /> );
            changePrimaryTitleAppBar("Planificación de los horarios de atención de tu establecimiento");
            break;
        case (path === '/layout'):
            setIconImg( () => <SectorsIcon fill={"#0B4762"} /> );
            break;
        case (path === '/reservas'):
            changePrimaryTitleAppBar("Configuración de reservas");
            setIconImg( () => <ShiftsIcon fill={"#0B4762"} /> );
            break;
        // case (path === '/discount'):
        //     changePrimaryTitleAppBar("Configuración de descuentos");
        //     setIconImg(<DiscountsIcon fill={"#0B4762"}/>);
        //     break;
        case (path === '/cuentas'):
            changePrimaryTitleAppBar("Contador de clientes propios del partner");
            setIconImg( () => <UsersIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/help' ):
            changePrimaryTitleAppBar("Material de entrenamiento Woki Partners");
            setIconImg( () => <HelpIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/usuarios' ):
            changePrimaryTitleAppBar("Material de entrenamiento Woki Partners");
            setIconImg( () => <UsersIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/facturacion' ):
            changePrimaryTitleAppBar("Visualización de tus útlimas facturas");
            setIconImg( () => <UsersIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/mis-reservas' ):
            changePrimaryTitleAppBar("Visualización de tus estadisticas de Reservas");
            setIconImg( () => <MyBookingsIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/mis-clientes' ):
            changePrimaryTitleAppBar("Visualización de tus estadisticas de Reservas");
            setIconImg( () => <MyClientsIcon fill={"#0B4762"} /> );
            break;
        case ( path === '/booking-politics' ):
            changePrimaryTitleAppBar("Texto");
            setIconImg( () => <PoliticsIcon fill={"#0B4762"} /> );
            break;
        default:
            setIconImg( () => <MyClientsIcon fill={"#0B4762"}/> );
            break;
    }
}