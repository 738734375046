import React from 'react';

// Material Ui
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';

// Styles
import { 
    OptionalButtonsContainerStyles, 
    OptionalHomeContainerStyles, 
    OptionalSubtitleStyles, 
    OptionalTextsStyles, 
    OptionalTitleStyles 
} from '../Styles/OptionalStyles';
import EditOptionalIcon from '../../SvgComponents/icons/Optional/EditOptionalIcon';
import DeleteOptionalIcon from '../../SvgComponents/icons/Optional/DeleteOptionalIcon';

const OptionalHomeComponent = ({ title, subtitle, handleDelete, handleEdit }) => {

    const media = useMediaQuery('(min-width: 1400px)');
    const maxMedia = useMediaQuery('(min-width: 1800px)');

    const handleSetDots = ( text ) => {
        let newText = '';
        
        if ( media && text.length > 15 && !maxMedia ) {
            newText = text.substring( 0, 15 ) + '...';
        } else if ( !media && text.length > 10 ) {
            newText = text.substring( 0, 10 ) + '...';
        } else if ( media && maxMedia && text.length > 15 ) {
            newText = text;
        } else {
            newText = text;
        }

        return newText;
    }

    return (
        <Box sx={ OptionalHomeContainerStyles }>
            <Box sx={ OptionalTextsStyles }>
                <Typography sx={ OptionalTitleStyles } >{ handleSetDots( title ) }</Typography>
                <Typography sx={ OptionalSubtitleStyles } >{ subtitle }</Typography>
            </Box>
            <Box sx={ OptionalButtonsContainerStyles }>
                <IconButton onClick={ handleEdit }>
                    <EditOptionalIcon />
                </IconButton>
                <IconButton onClick={ handleDelete } >
                    <DeleteOptionalIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default OptionalHomeComponent;