import React from 'react';

import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

const SectorSubShiftsTableComponent = ({ 
    reservableSectors,
    handleGroupClick, 
    colSubShifts, 
    handleClick,
    tablesArray,
    textNoOk, 
    textOk,
    groups, 
}) => {
    return (
        <Table sx={{ width: 'max-content' }}>
            <TableHead sx={{ "& td, th": { border: 0 } }}>
                <TableRow>
                    <TableCell></TableCell>
                    {
                        colSubShifts.map(({ id, name, schedule }) => (
                            <TableCell key={ id } align="center">
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '.9rem',
                                            color: '#041C32',
                                            fontWeight: 500,
                                        }}
                                    >
                                        { name }
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'italic',
                                            fontSize: '.7rem',
                                            color: '#041C32',
                                            fontWeight: 400,
                                        }}
                                    >
                                        { schedule }
                                    </Typography>
                                </Stack>
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reservableSectors?.map((sector, indexSector) => (
                        sector.available && sector.type !== 'Mesa' &&
                        <TableRow key={ sector.id } sx={{ "& td, th": { border: 0 } }}>
                            <TableCell component="td" scope="row" width="140px">
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '.95rem',
                                            color: '#041C32',
                                            fontWeight: 500
                                        }}
                                    >
                                        { sector.name }
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'italic',
                                            fontSize: '.8rem',
                                            color: '#041C32',
                                            fontWeight: 400,
                                        }}
                                    >
                                        { sector.type === 'Mesa' ?
                                                sector.layout 
                                            :  
                                                sector.type  
                                        }
                                    </Typography>
                                </Stack>
                            </TableCell>
                            {
                                colSubShifts.map(( column ) => (
                                    sector.type !== 'Mesa' &&
                                    sector.subShifts.map((subshift, indexSubshift) => (
                                        subshift ?
                                            ( column.id === subshift.id ) ? ( 
                                                <TableCell key={ subshift.id }>
                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <Box
                                                            onClick={() => handleClick( subshift.id, sector, subshift.enabled )}
                                                            sx={{
                                                                border: `1px solid ${ subshift.enabled ? "#0B4762" : "#E7E7E7" }`,
                                                                bgcolor: subshift.enabled ? '#EEF7FB' : 'transparent',
                                                                color: subshift.enabled ? '#041C32' : '#818181',
                                                                justifyContent: 'center',
                                                                fontFamily: 'Poppins',
                                                                alignItems: 'center',
                                                                borderRadius: '8px',
                                                                userSelect: 'none',
                                                                cursor: 'pointer',
                                                                fontWeight: 500,
                                                                display: 'flex',
                                                                height: '40px',
                                                                width: '202px',
                                                            }}
                                                        >
                                                            {`${ subshift.enabled ? textOk : textNoOk }`}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            ) : null 
                                        :
                                            <TableCell key={indexSector.toString() + indexSubshift.toString()}></TableCell>
                                    ))
                                ))
                            }
                        </TableRow>
                    ))
                }
                {
                    tablesArray?.map((sector, indexSector) => (
                        sector.available && 
                        <TableRow key={ sector.id } sx={{ "& td, th": { border: 0 } }}>
                            <TableCell component="td" scope="row" width="140px">
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '.95rem',
                                            color: '#041C32',
                                            fontWeight: 500
                                        }}
                                    >
                                        { sector.name }
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'italic',
                                            fontSize: '.8rem',
                                            color: '#041C32',
                                            fontWeight: 400,
                                        }}
                                    >
                                        { sector.layout }
                                    </Typography>
                                </Stack>
                            </TableCell>
                            {
                                colSubShifts.map(( column ) => (
                                    sector.subShifts.map((subshift, indexSubshift) => (
                                        subshift ?
                                            ( column.id === subshift.id ) ? ( 
                                                <TableCell key={ subshift.id }>
                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <Box
                                                            onClick={() => handleClick( subshift.id, sector, subshift.enabled )}
                                                            sx={{
                                                                border: `1px solid ${ subshift.enabled ? "#0B4762" : "#E7E7E7" }`,
                                                                bgcolor: subshift.enabled ? '#EEF7FB' : 'transparent',
                                                                color: subshift.enabled ? '#041C32' : '#818181',
                                                                justifyContent: 'center',
                                                                fontFamily: 'Poppins',
                                                                alignItems: 'center',
                                                                borderRadius: '8px',
                                                                userSelect: 'none',
                                                                cursor: 'pointer',
                                                                fontWeight: 500,
                                                                display: 'flex',
                                                                height: '40px',
                                                                width: '202px',
                                                            }}
                                                        >
                                                            {`${ subshift.enabled ? textOk : textNoOk }`}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            ) : null 
                                        :
                                            <TableCell key={indexSector.toString() + indexSubshift.toString()}></TableCell>
                                    ))
                                ))
                            }
                        </TableRow>
                    ))
                }
                {
                    groups.map( ( group, indexGroup ) => (
                        <TableRow key={ indexGroup } sx={{ "& td, th": { border: 0 } }}>
                            <TableCell component="td" scope="row" width="140px">
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '.95rem',
                                            color: '#041C32',
                                            fontWeight: 500
                                        }}
                                    >
                                        Grupo de { group?.groups[0].group }
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'italic',
                                            fontSize: '.8rem',
                                            color: '#041C32',
                                            fontWeight: 400,
                                        }}
                                    >
                                        { group.layout }
                                    </Typography>
                                </Stack>
                            </TableCell>
                            {
                                colSubShifts.map(( column ) => (
                                    group?.groups[0].subShifts.map(( subshift, indexSubshift) => (
                                        subshift ? 
                                            ( column.id === subshift.subShiftId ) ? (
                                                <TableCell key={ indexSubshift }>
                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <Box
                                                            onClick={() => handleGroupClick( subshift.subShiftId, group, subshift )}
                                                            sx={{
                                                                border: `1px solid ${ subshift.bookable ? "#0B4762" : "#E7E7E7" }`,
                                                                bgcolor: subshift.bookable ? '#EEF7FB' : 'transparent',
                                                                color: subshift.bookable ? '#041C32' : '#818181',
                                                                justifyContent: 'center',
                                                                fontFamily: 'Poppins',
                                                                alignItems: 'center',
                                                                borderRadius: '8px',
                                                                userSelect: 'none',
                                                                cursor: 'pointer',
                                                                fontWeight: 500,
                                                                display: 'flex',
                                                                height: '40px',
                                                                width: '202px',
                                                            }}
                                                        >
                                                            {`${ subshift.bookable ? textOk : textNoOk }`}
                                                        </Box>
                                                    </Box>
                                                </TableCell>  
                                            ) : null
                                        :
                                            <TableCell key={ indexGroup.toString() + indexSubshift.toString() }></TableCell>
                                    ))
                                ))
                            }
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
};

export default SectorSubShiftsTableComponent;