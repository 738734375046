import {connectRouter} from "connected-react-router";
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import {combineReducers} from "redux";

import {clientsStatisticsReducer} from "./clientsStatisticsReducer";
import statisticsResumeReducer from "./statisticsResumeReducer";
import bookingPoliticsReducer from "./bookingPoliticsReducer";
import bookingHistoryReducer from "./bookingHistoryReducer";
import userAccountsReducer from './userAccountsReducer';
import sectorsReducer from "./sectorsAndTablesReducer";
import generalInfoReducer from "./generalInfoReducer";
import myBookingsReducer from "./myBookingsReducer";
import myClientsReducer from "./myClientsReducer";
import notistackReducer from "./notistackReducer";
import reservasReducer from "./reservasReducer";
import messagesReducer from "./messagesReducer";
import planningReducer from "./planningReducer";
import financesReducer from "./financesReducer";
import optionalReducer from './optionalReducer';
import sidebarManager from './sidebarReducer';
import filtersReducer from "./filtersReducer";
import invoiceReducer from './invoiceReducer';
import mesayaReducer from "./mesayaReducer";
import photosReducer from "./photosReducer";
import authReducer from "./authReducer";
import menuReducer from "./menuReducer";
import homeReducer from './homeReducer';
import v3 from "./V3";
import logsReducer from "./logsReducer";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
    combineReducers({
        router: connectRouter(history),
        firestore: firestoreReducer,
        firebase: firebaseReducer,
        clientsStatisticsReducer,
        statisticsResumeReducer,
        bookingPoliticsReducer,
        bookingHistoryReducer,
        userAccountsReducer,
        generalInfoReducer,
        myBookingsReducer,
        notistackReducer,
        myClientsReducer,
        reservasReducer,
        financesReducer,
        messagesReducer,
        planningReducer,
        optionalReducer,
        sectorsReducer,
        sidebarManager,
        filtersReducer,
        invoiceReducer,
        photosReducer,
        mesayaReducer,
        menuReducer,
        authReducer,
        homeReducer,
        v3,
        logs: logsReducer,
    });
