import DatePicker from "react-multi-date-picker";

export const SuperDatePickerType = {
  MULTIPLE: 'multiple',
  RANGE: 'range',
  BASIC: 'basic',
}

const SuperDatePicker = ({reference, value, type, setDateValue, closeDatePicker, open}) => {
    const sameDate = (selectedDate, date, isSameDate) => {
        let prop = false;

        if (type === SuperDatePickerType.MULTIPLE || type === SuperDatePickerType.RANGE) {
          selectedDate.map((sel) => {
              if (isSameDate(date, sel)) 
              prop = true;                
          })
        } else {
          if (isSameDate(date, selectedDate)) 
              prop = true; 
        }
        
        return prop;
    }

    const setValues = (items) => {
      setDateValue(items);
      if (type === SuperDatePickerType.BASIC) {
        closeDatePicker();
      }
    }

    if (open) {
        reference.current.openCalendar();
    }

    return (
        <DatePicker            
            render={(values) => {
              return (
                <input type='hidden' value={values} />
              )
            }}
            ref={ reference }
            multiple={ type === SuperDatePickerType.MULTIPLE }
            range={ type === SuperDatePickerType.RANGE }
            className="planning-calendar"
            dateSeparator={' - '}
            value={ value }
            onOpenPickNewDate={ false }
            onChange={(items) => setValues(items)}
            onClose={ closeDatePicker } 
            style={{
              width: ( type === SuperDatePickerType.BASIC || type === SuperDatePickerType.MULTIPLE ) ? '5.5rem' : `${ 90 * value.length }px`,
              backgroundColor: 'transparent',
              boxSizing: 'border-box',
              position: 'relative',
              boxShadow: 'none',
              maxWidth: '70%',
              border: 'none',
            }}
            containerStyle={{
              wordBreak: 'break-word',
              width: '100%'
            }}
            mapDays={({date, selectedDate, today, isSameDate}) => {
                
              let result = date.toDays() - today.toDays()
    
              if ( result < 0 ) 
                return {
                  style: { color: "#ccc" },
                  disabled: true,
                } 
              else {
                let props = {}

                if ( sameDate( selectedDate, date, isSameDate ) ) props.style = {
                    ...props.style,
                    backgroundColor: "#0B4762 !important",
                    border: "1px solid #777",
                    fontWeight: "bold",
                    color: "#FFF",
                  }
                // console.log('props: ', props);
                return props;
              }
            }}
          />
    );
}

export default SuperDatePicker;