import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Actions
import { getAllOptionals, getOptionalById, setOptionalIdEdit, setOptionalViewMode, setRestartOptionalForm } from '../../actions/optionalActions';
import { 
    changePrimaryTitleAppBar, 
    changeShowSidebar, 
    changeTitleAppBar 
} from '../../actions/sidebarActions';

// Components
import ButtonWithIcon from '../NewComponents/ButtonWithIcon/ButtonWithIcon';
import OptionalHomeComponent from './components/OptionalHomeComponent';
import OptionalDeleteModal from './components/OptionalDeleteModal';
import OptionalSkeleton from './components/OptionalSkeleton';

// Helpers
import { showOptionSelectedName } from './helpers';

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

// Material Ui
import { Box } from '@mui/material';

// Styles
import { OptionalsContainerStyles } from './Styles/OptionalStyles';

const OptionalHome = ( props ) => {

    // Actions
    const { 
        changePrimaryTitleAppBar,
        setRestartOptionalForm,
        setOptionalViewMode,
        changeTitleAppBar,
        setOptionalIdEdit,
        changeShowSidebar, 
        getAllOptionals,
        getOptionalById,
    } = props;

    // States
    const { optionalArray, loading, isActive, idPartnerSelected } = props;

    const skeletonsArray =[ '0', '1', '2', '3', '4', '5' ];

    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [idOptionalDelete, setIdOptionalDelete] = useState( '' );

    useEffect(() => {
        if ( idPartnerSelected ) {
            getAllOptionals();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ idPartnerSelected ]);

    useEffect(() => {
        changePrimaryTitleAppBar("Configuración de preguntas opcionales");
        changeTitleAppBar("Opcionales");
        changeShowSidebar(true);
        setOptionalIdEdit('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateNewOptional = () => {
        setOptionalViewMode('NEW');
        setRestartOptionalForm();
    }

    const handleEditOptional = ( id ) => {
        setOptionalViewMode('EDIT');
        setOptionalIdEdit( id );
        getOptionalById( id );
    };

    const handleDeleteOptional = ( id ) => {
        setShowDeleteModal( true );
        setIdOptionalDelete( id );
    };

    return (
        <Box>
            <Box 
                sx={{
                    top: !isActive ? '11rem' : '7rem',
                    position: 'absolute', 
                    right: '4rem',
                }}
            >
                <ButtonWithIcon
                    text={"Agregar opcional"}
                    onClick={ handleCreateNewOptional }
                    icon={<AddCircleOutlineOutlinedIcon/>}
                />
            </Box>
            <Box sx={ OptionalsContainerStyles }>
                {
                    loading ? 
                        skeletonsArray.map(( item ) => 
                            <OptionalSkeleton key={ item }/>
                        )
                    :
                        optionalArray.map( ({ id, name, type }) => 
                            <OptionalHomeComponent
                                handleDelete = { () => handleDeleteOptional( id ) }
                                handleEdit = { () => handleEditOptional( id ) }
                                subtitle={ showOptionSelectedName(type) }
                                title={ name }
                                key={ id }
                                id={ id }
                            />
                        )
                }
            </Box>
            <OptionalDeleteModal
                setShow={ setShowDeleteModal }
                show={ showDeleteModal }
                id={ idOptionalDelete }
            />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        idPartnerSelected: state.userAccountsReducer.editReducer.idPartnerSelected,
        optionalArray: state.optionalReducer.optionalArray,
        isActive: state.sidebarManager.isActive,
        loading: state.optionalReducer.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar(data)),
        setOptionalViewMode: (data) => dispatch(setOptionalViewMode(data)),
        setRestartOptionalForm: () => dispatch(setRestartOptionalForm()),
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
        setOptionalIdEdit: (data) => dispatch(setOptionalIdEdit(data)),
        getAllOptionals: (data) => dispatch(getAllOptionals(data)),
        getOptionalById: (id) => dispatch(getOptionalById(id)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( OptionalHome );