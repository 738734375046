import React, {useEffect} from 'react';
import {Grid,} from '@material-ui/core';
import MesaYaConfigForm from '../../components/MesaYaConfig';
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";
import {connect} from "react-redux";

function MesaYaConfigPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Configuraciones Mesa Ya!")
        props.changeShowSidebar(true);
    }, [])

    return (
        <Grid container>
            <Grid item xs={12}>
                <MesaYaConfigForm/>
            </Grid>
        </Grid>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(MesaYaConfigPage);