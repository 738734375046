import React from 'react';

// Components
import ButtonWithIcon from '../../NewComponents/ButtonWithIcon/ButtonWithIcon';
import SearchBar from '../../GeneralComponents/SearchBar';


// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow
} from '@mui/material';

// Icons
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShieldIcon from '../../SvgComponents/icons/BookingTable/ShieldIcon';
import TableSortableHeader from "../../GeneralComponents/TableSortableHeader";
import {MAX_LIMIT_QUERY} from "../../../constants";
import TablePaginationActions from "../../GeneralComponents/TablePaginationActions";


const useStyles = makeStyles(() => ({
    HeaderTitle: {
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: '600 !important',
        color: '#041C32 !important',
        fontSize: '1rem !important'
    },
    DataTable: {
        fontFamily: 'Poppins, sans-serif !important',
        fontSize: '.9rem !important',
        fontWeight: '500 !important',
        color: ({row}) => row?.blocked ? '#041C32' : '#959EA7'
    },
    ClientButton: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '.4rem',
        display: 'flex',
        margin: 'auto',
        width: '95%',
    },
    StateBooking: {
        borderRadius: '8px',
        fontWeight: '500',
        maxWidth: '125px',
        cursor: 'pointer',
        padding: '.4rem',
        margin: 'auto',
    }
}));

const TableUsers = (props) => {

    // Variables
    const {rows, page, rowsPerPage, totalResults, orderBy, orderType} = props;

    // Actions
    const {onChangePage, onChangeRowsPerPage, onChangeOrder, onSearch, onExport} = props;

    const classes = useStyles(props);

    const header = [
        {
            id: 'name',
            disablePadding: false,
            label: 'Cliente',
            disableSorting: false,
        }, {
            id: 'contact',
            disablePadding: false,
            label: 'Contacto',
            disableSorting: true,
        }, {
            id: 'arrived',
            disablePadding: false,
            label: 'Cantidad de reservas ingresadas',
            disableSorting: false,
        }, {
            id: 'noArrived',
            disablePadding: false,
            label: 'Cantidad de no-show',
            disableSorting: false,
        }, {
            id: 'cancelled',
            disablePadding: false,
            label: 'Reservas canceladas',
            disableSorting: false,
        }, {
            id: 'blocked',
            disablePadding: false,
            label: 'Estado',
            disableSorting: false,
        }]

    const handleChangePage = (event, newPage) => {
        onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        onChangeRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleBlock = (state) => {
        console.log('Estado --> ', state);
    }

    const handleRequestSort = (head, type) => {
        console.log( 'Woki --> ', head, type );
        onChangeOrder(head, type);
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Grid container spacing={2} style={{padding: '1.5rem 1rem 1rem 1rem'}}>
                    <Grid item xs={10}>
                        <SearchBar onSearch={onSearch}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'right'}}>
                                <ButtonWithIcon
                                    text={'Exportar'}
                                    icon={<FileDownloadOutlinedIcon/>}
                                    minWidth={'100%'}
                                    onClick={onExport}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                    <TableSortableHeader
                        head={header}
                        order={orderType}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={`${row.name}-${row.phone}-${row.email}-${row.arrived}-${row.noArrived}-${row.cancelled}-${row.blocked}`}>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    {row.broughtBy ? <ShieldIcon/> : ''}&nbsp;{row.name}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    {row.email} <br/>
                                    {row.phone}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    {row.arrived}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <div className={classes.ClientButton}>
                                        {row.noArrived}
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    {row.cancelled}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.DataTable}
                                >
                                    <div
                                        className={classes.StateBooking}
                                        style={{
                                            backgroundColor: row.blocked ? '#0B4762' : '#FFFFFF',
                                            border: row.blocked ? '1px solid #041C32' : '1px solid #0B4762',
                                            color: row.blocked ? '#FFFFFF' : '#0B4762',
                                        }}
                                        onClick={(e) => handleBlock(e.target.innerText)}
                                    >
                                        {row.blocked ? "Desbloquear" : "Bloquear"}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100, {label: 'Todos', value: MAX_LIMIT_QUERY}]}
                                colSpan={6}
                                count={totalResults}
                                rowsPerPage={rowsPerPage}
                                page={totalResults <= 0 ? 0 : page}
                                labelRowsPerPage={"Filas por página"}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TableUsers;


