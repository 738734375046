import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const useStyles = makeStyles(() => ({
    root: {
        width: '340px',
        height: '35px',
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        zIndex: 1,
        paddingLeft: '80%',
    },
    iconContainer: {
        width: '90px',
        height: '35px',
        position: 'absolute',
        zIndex: 2,
        backgroundColor: 'white',
        borderRadius: '0px 20px 20px 0px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer'
    }
}));

const CopyLink = (props) => {

    const classes = useStyles();

    const {link} = props

    const {onCopy} = props

    return (
        <div>
            <label
                style={{
                    width: 'auto',
                    position: 'absolute',
                    zIndex: 2,
                    marginTop: '10px',
                    color: '#AFAFAF',
                    fontSize: '11px',
                    paddingLeft: '5px',
                }}
            >{link}</label>
            <div className={classes.root}>
                <div className={classes.iconContainer}>
                    <CopyToClipboard
                        text={link}
                        onCopy={onCopy}
                    >
                        <div style={{display: 'flex', paddingTop: '5px', cursor: 'pointer'}}>
                            <div style={{paddingLeft: '5px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="23.5"
                                     viewBox="0 0 31.5 13.5">
                                    <g id="link" transform="translate(-2.25 -12.375)">
                                        <path id="Trazado_648" data-name="Trazado 648"
                                              d="M14.3,12.375H9a6.75,6.75,0,0,0,0,13.5h6.75a6.75,6.75,0,0,0,6.367-9H20.25a2.188,2.188,0,0,0-.563.07,4.5,4.5,0,0,1-3.937,6.68H9a4.5,4.5,0,0,1,0-9h3.454a9.016,9.016,0,0,1,1.845-2.25Z"
                                              fill="#0076ff"/>
                                        <path id="Trazado_649" data-name="Trazado 649"
                                              d="M20.25,12.375a6.75,6.75,0,0,0-6.367,9h2.471a4.5,4.5,0,0,1,3.9-6.75H27a4.5,4.5,0,0,1,0,9H23.546a9.045,9.045,0,0,1-1.845,2.25H27a6.75,6.75,0,0,0,0-13.5Z"
                                              fill="#0076ff"/>
                                    </g>
                                </svg>
                            </div>
                            <div style={{
                                color: '#0076FF',
                                paddingTop: '2px',
                                textAlign: 'left',
                                paddingLeft: '2px'
                            }}>
                                <h4 style={{margin: '0px'}}>Copiar</h4>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>


        </div>
    )
}

export default CopyLink
