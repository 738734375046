import React from "react";

const AlertIcon = (props) => {

    const {width, height} = props;

    return (
        <svg width={width} height={height} viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.25 55.5C43.7475 55.5 55.5 43.7475 55.5 29.25C55.5 14.7525 43.7475 3 29.25 3C14.7525 3 3 14.7525 3 29.25C3 43.7475 14.7525 55.5 29.25 55.5Z"
                stroke="#EB252E" strokeWidth="5.25"/>
            <path d="M29.25 41.0625V42.375M29.25 16.125V31.875V16.125Z" stroke="#EB252E" strokeWidth="5.25"
                  strokeLinecap="round"/>
        </svg>
    );
}

AlertIcon.defaultProps = {
    width: "50",
    height: "50",
}

export default AlertIcon;
