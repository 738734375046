import React from 'react'
import {connect} from "react-redux";

// Actions
import {
    deleteCombineTables,
    editCombineTables,
    startNewCombination
} from "../../../actions";

// Components
import CombinationItem from "./CombinationItem";

// Material Ui
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Button, styled, Typography} from '@mui/material'
import {Box} from '@mui/system';
import LightTooltip from "../../GeneralComponents/LightTooltip";
import CombinationForm from "./CombinationForm";


const BoxFlexCombination = styled(Box)(
    () => `
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        display: flex;
        height: 50vh;
        width: 100%;
    `
);

const BoxContainer = styled(Box)(
    () => `
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        display: flex;
    `
);

const CombinationsList = (props) => {

    const {combinedTables, combineMode} = props;

    const {
        startNewCombination,
        deleteCombineTables,
        editCombineTables
    } = props;

    const handleAddCombination = () => {
        startNewCombination();
    }


    const handleEditCombination = (combination) => {
        editCombineTables(combination);
    }

    return (
        <BoxContainer>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography
                    variant="h6"
                    component="h6"
                    gutterBottom
                    sx={{
                        fontFamily: 'Poppins, sans-serif',
                        margin: '1rem 0.5rem',
                        fontSize: '.9rem',
                        color: '#0B4762',
                        padding: 0.5,
                    }}
                >
                    Combinaciones
                </Typography>
                <LightTooltip
                    placement='right'
                    title='Presioná el botón Agregar combinación, seleccioná las mesas que formen parte de la combinación y finalizá con el botón Listo.'
                >
                    <HelpOutlineIcon variant="small" sx={{color: '#0B4762', fontSize: '18px', cursor: 'pointer'}}/>
                </LightTooltip>
            </Box>
            {
                !combineMode
                    ? <BoxFlexCombination>
                        {combinedTables.length > 0
                            ? combinedTables.map((combination, i) => (
                                <CombinationItem
                                    key={i}
                                    item={combination}
                                    enable={!combineMode}
                                    onEdit={handleEditCombination}
                                    onDelete={deleteCombineTables}
                                />
                            ))
                            :
                            <Typography
                                variant='h6'
                                textAlign='center'
                                sx={{color: 'gray', fontSize: '16px', marginTop: '2em'}}
                            >
                                No tenés cargadas combinaciones aún.
                            </Typography>}
                    </BoxFlexCombination>
                    :
                    <CombinationForm/>
            }
            {
                !combineMode
                    ?
                    <Button
                        variant="contained"
                        size='small'
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            border: '1px solid #0B4762',
                            backgroundColor: '#E5F4FB',
                            textTransform: 'none',
                            borderRadius: '10px',
                            alignItems: 'center',
                            fontWeight: '600',
                            fontSize: '.9rem',
                            color: '#0B4762',
                            padding: '0.5em',
                            marginTop: '1em',
                            width: '85%',
                            gap: '5px',
                            '&:hover': {
                                backgroundColor: '#E5F4FB !important',
                            },
                        }}
                        onClick={handleAddCombination}
                    >
                        <svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.0001 8.6665V12.9998M13.0001 12.9998V17.3332M13.0001 12.9998H17.3334M13.0001 12.9998H8.66675"
                                stroke="#0B4762" strokeWidth="2.16667" strokeLinecap="round"/>
                            <path
                                d="M13.0001 23.8332C18.9832 23.8332 23.8334 18.9829 23.8334 12.9998C23.8334 7.01675 18.9832 2.1665 13.0001 2.1665C7.017 2.1665 2.16675 7.01675 2.16675 12.9998C2.16675 18.9829 7.017 23.8332 13.0001 23.8332Z"
                                stroke="#0B4762" strokeWidth="2.16667"/>
                        </svg>
                        Agregar
                    </Button>
                    : null
            }
        </BoxContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        combineTable1: state.sectorsReducer.sectors.combineTable1,
        combineTable2: state.sectorsReducer.sectors.combineTable2,
        combinedTables: state.sectorsReducer.sectors.combinedTables,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCombineTables: (combination) => dispatch(deleteCombineTables(combination)),
        editCombineTables: (combination) => dispatch(editCombineTables(combination)),
        startNewCombination: (combination) => dispatch(startNewCombination(combination)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CombinationsList);
