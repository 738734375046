import React from 'react';

import { connect } from 'react-redux';

// Actions
import { deleteOptional, setDeleteOptional } from '../../../actions/optionalActions';

// Icons
import DeleteIconModal from '../../SvgComponents/icons/Optional/DeleteIconModal';
import ClearIcon from '@mui/icons-material/Clear';

// Material Ui
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';

// Styles
import { 
    DOModalButtonsContainerStyles, 
    DOModalTextContainerStyles,
    DOButtonDeleteModalStyles,
    DOButtonBackModalStyles, 
    DeleteOptionalModal, 
    CrossDOModalStyles,
    DOIconModalStyles,
    DOModalSubtitle,
    DOModalTitle,
} from '../Styles/OptionalStyles';

const OptionalDeleteModal = ( props ) => {

    // Actions
    const { setDeleteOptional, deleteOptional } = props;

    // External props
    const { show, id, setShow } = props;

    const handleOnClose = () => {
        setDeleteOptional({ id });
        deleteOptional( id )
        setShow( false );
    };

    const handleCancel = () => {
        setShow( false );
    };

    return (
        <Modal
            slots={{ backdrop: Backdrop }}
            open={ show }
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={ show }>
                <Box sx={ DeleteOptionalModal }>
                    <Box sx={ CrossDOModalStyles } onClick={ handleCancel }>
                        <ClearIcon sx={{ color: '#041C32' }}/>
                    </Box>
                    <Box sx={ DOIconModalStyles }>
                        <DeleteIconModal/>
                    </Box>
                    <Box sx={ DOModalTextContainerStyles }>
                        <Typography sx={ DOModalTitle }>
                            Estas eliminando un opcional 
                        </Typography>
                        <Typography sx={ DOModalSubtitle }>
                            Tené en cuenta que se desvinculará de todos los turnos a los que se encuentra asociado. 
                        </Typography>
                    </Box>
                    <Box sx={ DOModalButtonsContainerStyles }>
                        <Button onClick={ handleCancel } sx={ DOButtonBackModalStyles }>
                            Volver
                        </Button>
                        <Button sx={ DOButtonDeleteModalStyles } onClick={ handleOnClose }>
                            Eliminar opcional
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDeleteOptional: ({ id }) => dispatch( setDeleteOptional({ id }) ),
        deleteOptional: ( id ) => dispatch( deleteOptional( id )),
    };
};

export default connect( null, mapDispatchToProps )( OptionalDeleteModal );