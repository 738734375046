import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Actions
import { setSubshift } from "../../../../../actions/shiftsActions";

// Components
import SectorsSubShiftsTable from "./SectorsSubShiftsTable";
import Divider from "./Divider";

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Material Ui
import { Box, Collapse, Stack, Typography } from "@mui/material";

// Utils
import { GetLayoutSubShiftWaitList, GetLayoutWaitList, SetWaitListToSubShiftBookableSectors } from "../utils/waitListFunctions";
import { getSubshifts } from "../utils/functions";

const WaitListCustomOptions = (props) => {

  // Variables
  const {
    reservableSectors, 
    availableLayouts,
    subShifts,
  } = props;  

  // Acciones
  const {
    setSubshift
  } = props;
  
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);
  const [sectors, setSectors] = useState([]);
  const newReservableSectors = reservableSectors.filter((reservableSector) => reservableSector.available);

  const SUB_SHIFTS = getSubshifts(subShifts);

  const handleAdvancedOptionsClick = () => {
    setAdvancedOptionsOpen(!advancedOptionsOpen);
  };

  const handleClick = (subShiftId, sector, enabled) => {
    SetWaitListToSubShiftBookableSectors(subShifts, !enabled, setSubshift, sector.id, subShiftId);
    const layoutsWaitList = GetLayoutWaitList(availableLayouts, newReservableSectors);
    setSectors(GetLayoutSubShiftWaitList(newReservableSectors, layoutsWaitList, subShifts));
  }

  useEffect(() => {
    const layoutsWaitList = GetLayoutWaitList(availableLayouts, newReservableSectors);
    setSectors(GetLayoutSubShiftWaitList(newReservableSectors, layoutsWaitList, subShifts)); 
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subShifts), JSON.stringify(availableLayouts)]);  

  return (
    <>
      <Stack
        onClick={ handleAdvancedOptionsClick }
        direction="row"
        spacing={ 1 }
        sx={{ 
          alignItems: 'center', 
          width: 'max-content',
          cursor: 'pointer',
          mt: 3,
        }}
      >
        <Box sx={{ width: '11.5px', height: '11.5px', borderRadius: 50, bgcolor: '#35C18F' }}></Box>
        <Typography
          sx={{ 
            textDecoration: 'underline', 
            fontFamily: 'Poppins',
            fontSize: '1rem',
            color: '#041C32',
            fontWeight: 500,
            my: 3
          }}
        >
          Configuración personalizada
        </Typography>
        {advancedOptionsOpen ? (
          <ExpandLessIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: "1.8rem", paddingTop: "2px" }} />
        )}
      </Stack>
      <Collapse in={ advancedOptionsOpen && sectors.length > 0 }>
        <SectorsSubShiftsTable
          textNoOk={ 'Deshabilitado' }
          textOk={ 'Lista de espera' }
          handleClick={ handleClick }
          colSubShifts={ SUB_SHIFTS }
          colSectors={ sectors }
          boxStyles={{
            maxWidth: "1220px",
          }}
        />
        <Divider />
      </Collapse>
    </>
  );
};

const mapStateToProps = (state) => {
  return {            
    availableLayouts: state.v3.shifts.shiftData.availableLayouts,      
    reservableSectors: state.v3.shifts.reservableSectors,
    subShifts: state.v3.shifts.shiftData.subShifts,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubshift: (data) => dispatch(setSubshift(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitListCustomOptions);