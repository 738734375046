import { Box, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import ChangeBillingInfo from '../Dialogs/ChangeBillingInfo';

const useStyles = makeStyles(() => ({
    MainContainer: {
        fontFamily: 'Poppins, sans-serif',
        paddingRight: '5rem',
    },
    Title: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#041C32',
        margin: '0',
        paddingBottom: '0.5rem',
    },
    InvoiceData: {
        margin: '0 0 10px 0',
        fontSize: '16px',
        fontWeight: '400',
        color: '#808080',
    },
    InvoiceCardCTA: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        borderRadius: '0 0 10px 10px',
        backgroundColor: '#0B4762',
        padding: '5px',
        height: '20%',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    InvoiceCardCTAText: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '15px',
        fontWeight: '500',
        color: '#FFFFFF',
        margin: '0',
    },
    TitlePlan: {
        fontSize: '30px',
        fontWeight: '600',
        color: '#0B4762',
        margin: '0',
    },
    helperText: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#808080',
        margin: '0',
    },
}));

export const BillingInfoCard = ({email, address, cuit}) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    return (
        <Grid item xs={12} lg={4}>
            <Box
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #DBD7DC',
                    borderRadius: '10px',
                    padding: '0px 10px 0px 10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    height: '15em',
                    position: 'relative',
                }}
            >
                <Box padding={'20px'}>
                    <h3 className={classes.Title}>Datos de facturación</h3>
                    <Box display={'flex'} flexDirection='column'>
                        <p className={classes.InvoiceData}><span style={{ fontWeight: 500 }}>CUIT/CUIL: </span>{cuit}</p>
                        <p className={classes.InvoiceData}><span style={{ fontWeight: 500 }}>Dirección de facturación: </span>{address}</p>
                        <p className={classes.InvoiceData}><span style={{ fontWeight: 500 }}>Mail de facturación: </span>{email}</p>
                    </Box>
                </Box>
                <div 
                    className={classes.InvoiceCardCTA}
                    onClick={() => setOpen(true)}
                >
                    <p className={classes.InvoiceCardCTAText}>Editar datos de facturación</p>
                </div>
            </Box>
            <ChangeBillingInfo
                open={open}
                setOpen={setOpen}
            />
        </Grid>
    )
}
