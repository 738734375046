import React from 'react';
import {Button, makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#0B4762',
        textTransform: 'none',
        borderRadius: '10px',
        cursor: 'pointer',
        height: '2.5rem',
        color: 'white',
        padding: '1rem'
    }
}));

const AddButton = (props) => {

    const classes = useStyle();
    const { text, onClick } = props;

    return (
        <Button contained className={classes.root} onClick={onClick}>
            {text}
        </Button>
    )
}

export default AddButton
