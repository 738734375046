import React, {useEffect} from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core";
import ClientsTable from './ClientsTable';

const useStyles = makeStyles((theme) => ({
    DataContainer: {
        fontFamily: 'Poppins, sans-serif',
        border: '1px solid #DBD7DC',
        backgroundColor: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '1rem',
    },
}));

const ClientSection = (props) => {

    const classes = useStyles();

    const {clients} = props;

    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        setRows(clients.map(client => ({
            userName: client.name,
            userIncome: client.arrived,
            userAvatar: client.photo
        })));
    }, [clients]);


    return (
        <div className={classes.DataContainer}>
            <ClientsTable rows={rows}/>
        </div>
    )
}

export default ClientSection
