import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        border: '2px solid #0B4762',
        backgroundColor: '#0B4762',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '8px',
        userSelect: 'none',
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '16px',
        display: 'flex',
        width: '140px',
        height: '37px',
        color: 'white',
    }
}));

const NextButton = (props) => {
    const classes = useStyle();

    const { onClick } = props

    return (
        <div className={ classes.root } onClick={ onClick }>
            Siguiente
        </div>
    )
}

export default NextButton
