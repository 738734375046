import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TimeFilterChip from "./TimeFilterChip";
import moment from "moment/moment";
import RangeDatePicker from "../RangeDatePicker";
import GeneralModal from "../GeneralModal";


const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#FCFCFC',
        marginRight: '0',
        paddingRight: '0',
        height: '100%',
        display: 'flex',
        justifyContent: 'end',
    },
    item: {
        marginLeft: '1rem',
    }
}));

const TimeFilterSelector = (props) => {
    const classes = useStyles();
    const [selectedFilter, setSelectedFilter] = React.useState(3);
    const [from, setFrom] = React.useState(moment());
    const [to, setTo] = React.useState(moment());
    const [showCalendar, setShowCalendar] = useState(false);
    const {onChangeDate} = props;


    const getFormatterRange = () => {
        return `${moment(from).format('DD/MM/YYYY')} - ${moment(to).format('DD/MM/YYYY')}`
    }

    const updateFilterStates = (from, to, id) => {
        console.log(from.utc(false).format(), to.toISOString())
        setFrom(from);
        setTo(to);
        setSelectedFilter(id);
        onChangeDate(from, to);
    }

    const filterTimeOptions = [
        {
            id: 0,
            label: 'Hoy',
            allowReClick: false,
            onClick: () => {
                updateFilterStates(moment().utc(false).startOf('date'), moment().utc(false).endOf('date'), 0);
            }
        }, {
            id: 1,
            label: 'Ayer',
            allowReClick: false,
            onClick: () => {
                updateFilterStates(moment().utc(false).startOf('date').subtract(1, 'days'), moment().utc(false).endOf('date').subtract(1, 'days'), 1);
            }
        }, {
            id: 2,
            label: 'Esta semana',
            onClick: () => {
                updateFilterStates(moment().utc(false).startOf('week'), moment().utc(false).endOf('week'), 2);
            }
        }, {
            id: 3,
            label: 'Este mes',
            allowReClick: false,
            onClick: () => {
                updateFilterStates(moment().utc(false).startOf('month'), moment().utc(false).endOf('month'), 3);
            }
        }, {
            id: 4,
            label: 'Este año',
            allowReClick: false,
            onClick: () => {
                updateFilterStates(moment().utc(false).startOf('year'), moment().utc(false).endOf('year'), 4);
            }
        }, {
            id: 5,
            label: selectedFilter === 5 ? getFormatterRange() : 'Personalizado',
            allowReClick: true,
            onClick: () => {
                setShowCalendar(true);
            }
        }];

    const handleOnCancel = () => {
        setShowCalendar(false);
    }

    const handleOnSubmit = (from, to) => {
        updateFilterStates(moment(from).utc().startOf('date'), moment(to).utc().endOf('date'), 5);
        setShowCalendar(false);
    }

    const getInitDates = () => {
        return [from.toDate(), to.toDate()];
    }

    return (
        <div className={classes.root}>
            {
                filterTimeOptions.map((option) => (
                    <div key={option.id} className={classes.item}>
                        <TimeFilterChip 
                            selected={ selectedFilter === option.id }
                            allowReClick={ option.allowReClick } 
                            onClick={ option.onClick } 
                            label={ option.label } 
                        />
                    </div>
                ))
            }
            <GeneralModal open={showCalendar} onCancel={handleOnCancel}>
                <RangeDatePicker initDates={getInitDates()} onCancel={handleOnCancel} submit={handleOnSubmit}/>
            </GeneralModal>
        </div>
    );
}

export default TimeFilterSelector;

