import React from 'react';
import {Modal} from "@material-ui/core";


const GeneralModal = (props) => {

    const {open, onCancel, children} = props;

    return (
        <Modal
            open={open}
            onClose={onCancel}
        >
            {children}
        </Modal>
    )
}

export default GeneralModal;
