import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Components
import AdvancedOptionsResidenceTimePerTableModal from './AdvancedOptionsResidenceTimePerTableModal';
import AdvancedOptionsExceptionsItem from './AdvancedOptionsExceptionsItem';
import AddExceptionButton from './AddExceptionButton';
import { Subtitle } from '../../Subtitle';

// Material Ui
import { Box, Collapse } from "@mui/material";

// Utils
import { SetDwellTimeTableLabel } from '../utils/advancedOptionsFunctions';

const AdvancedOptionsExceptions = (props) => {

    const [modalOpen, setModalOpen] = useState(false);  
    const [dwellTimeTableItems, setDwellTimeTableItems] = useState([]);

    // Variables
    const {
        reservableSectors,
        dwellTimeByTable,
    } = props;

    const newReservableSectors = reservableSectors.filter((reservableSector) => reservableSector.available);

    useEffect(() => {
        setDwellTimeTableItems(SetDwellTimeTableLabel(newReservableSectors, dwellTimeByTable));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(dwellTimeByTable)]);
    
    return (
        <>
            {/* ///////////////////////////// */}
            <Subtitle text="Excepciones" style={{ fontSize: "20px", lineHeight: "30px", marginTop: "30px"}} />
            <Box mt={2}>
                <Collapse in={ dwellTimeTableItems.length > 0 ? true : false }>
                    {
                        dwellTimeTableItems.map((dwellTimeTableItem) => (
                            <AdvancedOptionsExceptionsItem 
                                key={ dwellTimeTableItem.id } 
                                props={ dwellTimeTableItem } 
                            />
                        ))
                    }
                </Collapse>
            </Box>
            <AddExceptionButton onClick={() => setModalOpen(!modalOpen)} style={{width: '220px'}} />
            {/* <AddDateButton onClick={() => setModalOpen(!modalOpen)} 
                titleButton={"Agregar excepción"} 
                componenteHijo={AddCircleOutlineOutlinedIcon}
                style={{width: '220px', fontSize: "18px !important"}}
            /> */}
            {
                modalOpen && (
                    <AdvancedOptionsResidenceTimePerTableModal
                        handleClose={() => setModalOpen(!modalOpen)}
                    />
                )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {            
        reservableSectors: state.v3.shifts.reservableSectors,
        dwellTimeByTable: state.v3.shifts.shiftData.dwellTimeByTable,
    }
};


export default connect( mapStateToProps, null )(AdvancedOptionsExceptions);