import React from 'react';

import dayjs from 'dayjs';

// Material Ui
import { Box, Collapse, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styles
import { ShiftCalendarTextFieldStyles } from '../../../../Styles';
import { CheckedStyle } from '../../../../../Plannig/Styles';

const EndShiftRepeatComponent = ({ handleChangeNunca, optionNunca, valueNunca, setValueNunca }) => {
    return (
        <Box sx={{ padding: '1rem 0' }}>
            <Typography 
                sx={{ 
                    fontFamily: 'Poppins', 
                    m: '.5rem 0 .8rem 0', 
                    fontSize: '1.1rem',
                    color: '#041C32',
                    fontWeight: 500, 
                }}
            >
                Termina
            </Typography>
            <Box>
                <RadioGroup
                    onChange={ handleChangeNunca }                    
                    value={ optionNunca }
                    name="radio-nunca"
                >
                    <Stack direction="row" spacing={2} >
                        <FormControlLabel 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            value='nunca' 
                            label={
                                <Typography 
                                    sx={{
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500, 
                                    }}
                                >Nunca</Typography>
                            } 
                        />
                        <FormControlLabel 
                            value="el" 
                            control={ <Radio sx={ CheckedStyle } /> } 
                            label={
                                <Typography 
                                    sx={{
                                        fontFamily: 'Poppins', 
                                        fontSize: '16px', 
                                        fontWeight: 500, 
                                    }}
                                >El</Typography>
                            } 
                        />
                        <Collapse 
                            sx={{ 
                                '& .MuiCollapse-wrapperInner': { 
                                    display: 'flex', 
                                    gap: '15px' 
                                } 
                            }} 
                            in={ optionNunca !== 'nunca' } 
                            orientation="horizontal" 
                        >
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale="en-gb">
                                <DatePicker
                                    onChange={ ( newValue ) => setValueNunca( newValue ) }
                                    value={ valueNunca ? valueNunca : dayjs( Date.now() ) }
                                    renderInput={ (params) => <TextField {...params} /> }
                                    disabled={ optionNunca === 'nunca' ? true : false }
                                    sx={ ShiftCalendarTextFieldStyles }
                                    minDate={ dayjs( new Date() ) }
                                    format='DD/MM/YYYY'
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiPickersCalendarHeader-label': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersYear-yearButton': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiTypography-root': {
                                                    fontFamily: 'Poppins !important',
                                                },
                                                '& .MuiPickersDay-root': {
                                                    fontFamily: 'Poppins !important',
                                                }
                                            },
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            {/* <Box sx={{ display: 'flex' }} >
                                <FormControlLabel 
                                    control={ <Radio sx={ CheckedStyle } /> } 
                                    value='afterOf' 
                                    label={
                                        <Typography 
                                            sx={{ 
                                                fontFamily: 'Poppins', 
                                                fontSize: '16px', 
                                                fontWeight: 500 
                                            }}
                                        >Después de</Typography>
                                    } 
                                />
                                <SelectWithArrows
                                    handleDecrease={ handleDecreaseRepeat }
                                    handleIncrease={ handleIncreaseRepeat }
                                    amount={ planningRepeats }
                                    singularText='repetición'
                                    disabled={ !valueAfterOf }
                                    pluralText='repetiones'
                                />
                            </Box> */}
                        </Collapse>
                    </Stack>
                </RadioGroup>
            </Box>
        </Box>
    )
};

export default EndShiftRepeatComponent;