import React from 'react';

// Material Ui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {makeStyles} from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SmallFilterButton = (props) => {

    const {text, color, fontColor, options, value, onChange, disabled, id, minWidth} = props;

    const useStyles = makeStyles(() => ({
        customOutline: {
            "& .MuiInputLabel-root": {
                borderColor: '#D0D0D0',
                margin: 'auto',
                top: '-6px',
                color: '#808080'
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${color}`,
            },
            "& .MuiSvgIcon-root": {
                color: `${color}`,
            },
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '&:hover fieldset': {
                    borderColor: disabled ? '#B2B2B2' : '#0B4762',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0B4762',
                },
            },
            '& .MuiInputLabel-root.Mui-focused': {
                color: `${fontColor}`,
            },
            '& .MuiPaper-root': {
                backgroundColor: 'tomato'
            }
        },
    }));

    const classes = useStyles();

    return (
        <div>
            <FormControl id={id} sx={{ m: 1, minWidth: minWidth ? minWidth : 100 }} size="small" classes={{ root: classes.customOutline }} disabled={disabled}>
                <InputLabel
                    id="SmallFilterSelect"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: '400',
                        color: disabled ? '#B2B2B2' : `${color}`,
                        marginLeft: text?.length > 12 ? '-4px' : '0px'
                    }}
                >{text ?? ''}</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={value}
                    label={text}
                    onChange={onChange}
                    style={{
                        borderRadius: '5px',
                        height: '2rem',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                    MenuProps={{
                        style: {
                            maxHeight: 200,
                        },
                    }}
                    disabled={disabled}
                >
                    {
                        options.map((item, index) =>
                            <MenuItem
                                key={index}
                                value={item.value}
                                sx={{
                                    fontFamily: 'Poppins, sans-serif',
                                }}
                            >{item.option}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </div>
    )
}

export default SmallFilterButton;
