import React from 'react';

import { connect } from 'react-redux';
import { RRule } from 'rrule';
import moment from 'moment';

// Actions
import { changeShiftsViewMode, changeTurnoMode } from "../../../actions/shiftsActions";

// Components
import ShiftDataView from './ShiftDataView';
import ShiftTopView from './ShiftTopView';

// Constants
import { SHIFTS_VIEW_MODE } from "../../../constants/shiftsConstants";
import { ShiftMode } from '../../../constants';

// Icons
import InfoIcon from '../../SvgComponents/icons/InfoIcon';

// Material Ui
import { Box, Fade, Tooltip, Typography } from '@mui/material';

// Utils
import { joinArrayElements } from "../Shifts/Wizard/utils/functions";
import { GetDates } from '../Shifts/Wizard/utils/functions';
import {
  IsProgrammedDeleteDisable,
  IsProgrammedEdit,
  GetPublish,
  GetSectors,
} from "./utils/turnoFunctions";

// Styles
import { ShiftMainContainerStyles, UpdateTabStyle, DeleteTabStyle, TabFontStyle, Card } from "../Styles";

const Turno = ( props ) => {

  // Variables
  const { turno, editing, sectors } = props;

  // Acciones
  const { changeTurnoMode, changeShiftsViewMode } = props;

  const getLetterDay = (day) => {
    switch ( day ) {
      case 0:
        return "D";
      case 1:
        return "L";
      case 2:
        return "M";
      case 3:
        return "M";
      case 4:
        return "J";
      case 5:
        return "V";
      case 6:
        return "S";
      default:
        return;
    }
  };

  const getFreq = (interval, freq) => {
    switch (freq) {
      case 0:
        return "anual";
      case 1:
        return "mensual";
      case 2:
        if (interval === 1) return "semana";
        else return `${interval} semanas`;
      case 3:
        return "diario";
      default:
        return "";
    }
  };

  const getDays = (days) => {
    const res = [false, false, false, false, false, false, false];
    days.forEach((day) => {
      res[day] = true;
    });

    return res;
  };

  const getInterval = (repeat) => {
    const rule = RRule.fromString(repeat);

    return `Se repite cada ${getFreq(
      rule.origOptions.interval,
      rule.origOptions.freq
    )}`;
  };

  const handleEdit = (turno) => {
    changeTurnoMode({ mode: ShiftMode.EDIT, turno: turno });
    changeShiftsViewMode( SHIFTS_VIEW_MODE.EDIT );
  };

  const handleDelete = (turno) => {
    changeTurnoMode({ mode: ShiftMode.DELETE, turno: turno });
  };

  const handleDisable = (turno) => {
    if (turno.enable) {
      changeTurnoMode({ mode: ShiftMode.DISABLE, turno: turno });
    };
  };

  const sectorsLayouts = GetSectors(turno.availableLayouts, sectors);
  const hasProgrammedEdit = IsProgrammedEdit(turno.calendar.startDate);
  const hasProgrammedDelete = IsProgrammedDeleteDisable(
    turno.calendar.endDate,
    turno.deleted,
    turno.enable,
    turno.successor
  );

  return (
    <Box sx={ Card }>
      {
        hasProgrammedEdit && (
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row-reverse' }}>
            <Tooltip 
              title={
                <Typography sx={{ fontSize: '.8rem' }}>Esta es la fecha en la cual entrarán en vigencia la creación o edición del turno.</Typography>
              }
              TransitionProps={{ timeout: 600 }}
              TransitionComponent={ Fade }
              placement="top"
              arrow 
            >
              <Box sx={ UpdateTabStyle }>
                <Typography sx={ TabFontStyle }>
                  { moment( turno.calendar.startDate ).format( "DD/MM/YYYY" ) }
                </Typography>
                <Box sx={{ height: 'max-content' }}>
                  <InfoIcon size="20px" fill="#FCFCFC" />
                </Box>
              </Box>
            </Tooltip>
          </Box>
      )}
      {
        hasProgrammedDelete && (
          <Box sx={{ textAlign: 'center', display: 'flex' , flexDirection: 'row-reverse' }}>
            <Tooltip
              title={
                <Typography sx={{ fontSize: '.8rem' }}>
                  Esta es la fecha en la cual el turno se eliminará.
                </Typography>
              }
              TransitionProps={{ timeout: 600 }}
              TransitionComponent={ Fade }
              placement="top"
              arrow 
            >
              <Box sx={ DeleteTabStyle }>
                <Typography sx={ TabFontStyle }>
                  { moment( turno.calendar.endDate ).format( "DD/MM/YYYY" ) }
                </Typography>
                <InfoIcon size="20px" fill="#FCFCFC" />
              </Box>
            </Tooltip>
          </Box>
      )}
      <Box key={ turno.id } sx={ ShiftMainContainerStyles } >
        <ShiftTopView
          hasProgrammedDelete={ hasProgrammedDelete } 
          handleDisable={ handleDisable } 
          handleDelete={ handleDelete }
          handleEdit={ handleEdit } 
          turno={ turno } 
        />
        <ShiftDataView
          joinArrayElements={ joinArrayElements }
          sectorsLayouts={ sectorsLayouts }
          getLetterDay={ getLetterDay }
          getInterval={ getInterval } 
          GetPublish={ GetPublish } 
          GetDates={ GetDates }
          editing={ editing } 
          getDays={ getDays } 
          turno={ turno }
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    sectors: state.v3.shifts.sectors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTurnoMode: ({ mode, turno }) => dispatch(changeTurnoMode({ mode, turno })),
    changeShiftsViewMode: ( data ) => dispatch( changeShiftsViewMode( data )),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Turno);
