import React from 'react'
import '../css/ProductsTable.css'
import ProductRow from "./ProductRow";

const ProductsTable = (props) => {

    const {category, products} = props;

    return (
        <div key={`table-${category}`}>
            <div className='category-name' id={category}>
                {category}
            </div>
            <div>
                {products?.map(product =>
                    <div key={`div-product-row-${product.name}`}>
                        <ProductRow product={product}/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductsTable