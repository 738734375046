import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Actions
import {changeShowSidebar, changeTitleAppBar} from "../../actions/sidebarActions";

// Components
import ClientsStatistics from "../../components/ClientsStatistics";

// Material Ui
import {Grid} from '@material-ui/core';

function ClientsStatisticsPage(props) {

    useEffect(() => {
        props.changeTitleAppBar("Clientes")
        props.changeShowSidebar(true);
    }, [props])

    return (
        <Grid container>
            <Grid item xs={12}>
                <ClientsStatistics/>
            </Grid>
        </Grid>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(ClientsStatisticsPage);
