import React from "react";

import { connect } from "react-redux";

// Actions
import { setWaitingListSectorsFull } from "../../../../../actions/shiftsActions";

// Components
import BlueRadioButton from "../../../../GeneralComponents/BlueRadioButton";

// Material Ui
import { Box, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";

// Styles
import { ShiftSelectTitles } from "../../../Styles";

const OPTIONS = [
  {
    label: "Todos los sectores esten completos",
    value: true,
  },
  {
    label: "Un sector este completo",
    value: false,
  },
];

const WaitListEnabling = (props) => {
  
  // Variables
  const {
    allowWaitListWithAllSectorsFull,
  } = props;

  // Acciones
  const {
    setWaitingListSectorsFull,
  } = props;

  return (
    <Box>
      <Typography sx={ ShiftSelectTitles } >Habilitar lista de espera cuando</Typography>
      <RadioGroup
        value={allowWaitListWithAllSectorsFull}
        name="radio-buttons-group"
        onChange={(e) => setWaitingListSectorsFull(e.target.value === 'true')}
        style={{ marginTop: "24px" }}
      >
        <Stack direction="row" spacing={2}>
          {OPTIONS.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "#041C32",
                fontWeight: 500,
              }}
              value={value}
              control={ <BlueRadioButton /> }
              label={
                <Typography 
                    sx={{ 
                        fontFamily: 'Poppins', 
                        fontSize: '16px', 
                        fontWeight: 500 
                    }}
                >{ label }</Typography>
              }
            />
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
      allowWaitListWithAllSectorsFull: state.v3.shifts.shiftData.allowWaitListWithAllSectorsFull,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWaitingListSectorsFull: (data) => dispatch(setWaitingListSectorsFull(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitListEnabling);
