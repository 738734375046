import {Box, Divider, Typography} from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';


const DraggableTable = ({showDivider, minCapacity, maxCapacity, w, h, onDragStart}) => {
    return (
        <Box
            display='flex'
            sx={{
                flexDirection: 'column',
                gap: '1em',
                width: '70%',
            }}
        >
            <Box
                display='flex'
                align-items='center'
                justifyContent='space-around'
                sx={{
                    gap: '1.2em',
                }}
            >
                <Box
                    data-table={JSON.stringify({minCapacity, maxCapacity, h, w})}
                    className='droppable-element react-out-table'
                    draggable={true}
                    unselectable="on"
                    onDragStart={e => {
                        e.dataTransfer.setData('text/plain', "")
                        onDragStart({
                            minCapacity: minCapacity,
                            maxCapacity: maxCapacity,
                            h: h,
                            w: w,
                            isResizable: false
                        });
                    }}
                >
                    <PersonIcon/>
                    <Typography variant='h5' sx={{fontSize: '14px'}}>
                        {minCapacity}-{maxCapacity}
                    </Typography>
                </Box>
                <Box
                    data-table={JSON.stringify({minCapacity, maxCapacity, h, w})}
                    className='droppable-element react-out-table circular'
                    draggable={true}
                    unselectable="on"
                    onDragStart={e => {
                        e.dataTransfer.setData('text/plain', "")
                        onDragStart({
                            minCapacity: minCapacity,
                            maxCapacity: maxCapacity,
                            h: h,
                            w: w,
                            circle: true,
                            isResizable: false
                        });
                    }}
                >
                    <PersonIcon/>
                    <Typography variant='h5' sx={{fontSize: '14px'}}>
                        {minCapacity}-{maxCapacity}
                    </Typography>
                </Box>
            </Box>
            {showDivider ? <Divider variant='fullWidth' sx={{borderBottomColor: '#0B4762'}}/> : null}
        </Box>
    )
}

export default DraggableTable;
