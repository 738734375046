import React from 'react'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    textHeaderTable: {
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: 'Montserrat',
        color: 'white',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '55px',
        backgroundColor: 'black'
    },
    sortRow: {
        width: '80px',
        marginLeft: '30px',
        marginRight: '20px',
        alignSelf: 'center'
    },
    imageRow: {
        textAlign: 'end',
        width: "200px",
    },
    name: {
        width: '15%'
    },
    description: {
        width: '15%'
    },
    priceRow: {
        width: "10%",
    },
    actionsRow: {
        width: "10%",
    },
    daysRow: {
        width: "8%",
    },
}))

const ProductsTableHead = () => {

    const classes = useStyles()

    return (
        <tr key="header-table-menu" className={classes.textHeaderTable}>
            <th className={classes.imageRow}>
                <div className={classes.sortRow}/>
                <div style={{paddingRight: '15%'}}>
                    Foto
                </div>
            </th>

            <th className={classes.name}>
                Nombre
            </th>
            <th className={classes.description}>
                Descripción
            </th>
            <th className={classes.priceRow}>
                Precio
            </th>
            <th className={classes.daysRow}>
                Días
            </th>
            <th className={classes.actionsRow} style={{marginRight: '30px'}}>
                Acciones
            </th>
        </tr>
    )
}

export default ProductsTableHead