export const OptionalIcon = ({ fill = '#818181' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.5H24.25" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 9H24.25" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 16.5H24.25" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.75 1.5H1.76167" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.75 9H1.76167" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.75 16.5H1.76167" stroke={ fill } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};
