import React from 'react';

// import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import DatePicker, { getAllDatesInRange } from 'react-multi-date-picker';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { setAllDates, setAllExcludeDates, setClosingDatesForm, setNewExcludeDate, setNewPunctualDate, setShowPlanningSnackbar, setSingleDate, setSpecialDatesForm } from '../../../actions';
import { handleSetDateRanges } from '../helpers';

const CalendarDateButton = ( props ) => {

    // Actions
    const { 
        setShowPlanningSnackbar, 
        setSpecialDatesForm,
        setClosingDatesForm, 
        setNewPunctualDate, 
        setAllExcludeDates,
        setNewExcludeDate,
        setSingleDate,
        setAllDates 
    } = props;

    // External props
    const { 
        component, 
        reference, 
        dateArray, 
        value, 
        type,
    } = props;

    // States
    const { punctualDates, closingDatesForm, specialDatesForm } = props;

    const handleChangeDate = ( event ) => {
        if ( type === 'Fecha única' || type === 'Fechas múltiples' ) {
            if ( dateArray === 'newDate' ) {
                if (
                    event[ event.length - 1 ] &&
                    !punctualDates.allDates.some( date =>
                        moment( date ).format('DD/MM/YYYY') === moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' ).format('DD/MM/YYYY'),  
                    ) 
                ) {
                    setNewPunctualDate( moment( event[ event.length - 1 ].toDate() ).format() );

                    setSingleDate({ 
                        newDate: moment( event[ event.length - 1 ].toDate() ).format('DD/MM/YYYY'),
                        dateArray: dateArray,
                        range: false 
                    });

                    setAllDates( 
                        moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' )
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString() 
                    );
                    
                } else {
                    setShowPlanningSnackbar({
                        snackbarMessage: 'La fecha ingresada ya se encuentra seleccionada',
                        typeSnackbar: 'Error',
                        showSnackbar: true,
                    });
                };

                if ( component === 'closings' ) {
                    if (
                        event[ event.length - 1 ] &&
                        !closingDatesForm.specialDates.some( date =>
                            moment( date ).format('DD/MM/YYYY') === moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' ).format('DD/MM/YYYY'),  
                        ) 
                    ) {
                        setClosingDatesForm({
                            ...closingDatesForm,
                            specialDates: [
                                ...closingDatesForm.specialDates,
                                moment( event[ event.length - 1 ].toDate() ).format()
                            ]
                        });
                    } else {
                        setShowPlanningSnackbar({
                            snackbarMessage: 'La fecha ingresada ya se encuentra seleccionada',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        });
                    }
                } else if (component === 'special-dates') {
                    if (
                        event[ event.length - 1 ] &&
                        !specialDatesForm.specialDates.some( date =>
                            moment( date ).format('DD/MM/YYYY') === moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' ).format('DD/MM/YYYY'),  
                        ) 
                    ) {
                        setSpecialDatesForm({
                            ...specialDatesForm,
                            specialDates: [
                                ...specialDatesForm.specialDates,
                                moment( event[ event.length - 1 ].toDate() ).format()
                            ]
                        });
                    } else {
                        setShowPlanningSnackbar({
                            snackbarMessage: 'La fecha ingresada ya se encuentra seleccionada',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        });
                    }
                }; 

            } else {
                if (
                    event[ event.length - 1 ] &&
                    !punctualDates.allExcludeDates.some( date =>
                        moment( date ).format('DD/MM/YYYY') === moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' ).format('DD/MM/YYYY'),  
                    ) 
                ) {
                    setNewExcludeDate( moment( event[ event.length -1 ].toDate() ).format() );

                    setAllExcludeDates( 
                        moment( event[ event.length - 1 ].toDate(), 'DD-MM-YYYY' )
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                        .toISOString()
                    );
    
                    setSingleDate({ 
                        newDate: moment( event[ event.length - 1 ].toDate() ).format('DD/MM/YYYY'),
                        dateArray: dateArray,
                        range: false 
                    });
                } else {
                    setShowPlanningSnackbar({
                        planningSnackbar: {
                            snackbarMessage: 'La fecha ingresada ya se encuentra seleccionada',
                            typeSnackbar: 'Error',
                            showSnackbar: true,
                        }
                    });
                };
            }

            if ( type === 'Fecha única' ) {
                reference.current.closeCalendar();
            }

        } else {
            if ( event.length > 1 ) {

                const rangeStart = new Date( event[0] );
                const rangeEnd = new Date( event[1] );
                
                const rangeArray = handleSetDateRanges( rangeStart, rangeEnd );
                
                if ( moment( rangeStart, 'DD/MM/yyyyy' ).format('DD/MM/yyyyy') !== moment( rangeEnd, 'DD/MM/yyyy' ).format('DD/MM/yyyyy') ) {
                    if ( dateArray === 'newDate' ) {
                        if (
                            !punctualDates.allDates.some( (date1) => {
                                const date = moment( date1 ).format('DD/MM/yyyy')
                                return rangeArray.includes( date )
                            })
                        ) {
                            setNewPunctualDate([ 
                                moment( getAllDatesInRange( event )[ 0 ].toDate() ).format(), 
                                moment( getAllDatesInRange( event )[getAllDatesInRange( event ).length - 1].toDate() ).format() 
                            ]);
                            
                            setSingleDate({ 
                                newDate: rangeArray,
                                dateArray: dateArray,
                                range: true 
                            });
        
                            for (let i = 0; i < rangeArray.length; i++) {
                                setAllDates( 
                                    moment( rangeArray[ i ], 'DD-MM-YYYY' )
                                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                    .toISOString()
                                );
                            };
                        } else {
                            setShowPlanningSnackbar({
                                snackbarMessage: 'El rango de fechas contiene fechas ya seleccionadas',
                                typeSnackbar: 'Error',
                                showSnackbar: true,
                            });
                        };
                    } else {
                        if (
                            !punctualDates.allExcludeDates.some( (date1) => {
                                const date = moment( date1 ).format('DD/MM/yyyy')
                                return rangeArray.includes( date )
                            })
                        ) {
                            setNewExcludeDate([ moment( event[0].toDate() ).format(), moment( event[1].toDate() ).format() ]);
    
                            for (let i = 0; i < rangeArray.length; i++) {
                                setAllExcludeDates( 
                                    moment( rangeArray[ i ], 'DD-MM-YYYY' )
                                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                    .toISOString()
                                );
                            }
                        } else {
                            setShowPlanningSnackbar({
                                planningSnackbar: {
                                    snackbarMessage: 'El rango de fechas contiene fechas ya seleccionadas',
                                    typeSnackbar: 'Error',
                                    showSnackbar: true,
                                }
                            });
                        }
                    }
                }
            }
        }
    };

    const sameDate = (selectedDate, date, isSameDate) => {
        let prop = false;

        if ( type === 'Fecha única' || type === 'Fechas múltiples' ) {
            selectedDate.map((sel) => function() {
                if (isSameDate(date, sel)) 
                    prop = true;                
            })
        } else {
            if (isSameDate(date, selectedDate)) 
                prop = true; 
        }
        
        return prop;
    };

    return (
        <DatePicker 
            value={ 
                dateArray === 'newDate' ? 
                    punctualDates.newSingleDates 
                : 
                    punctualDates.excludeSingleDates 
            }
            multiple={ type === 'Fecha única' || type === 'Fechas múltiples' }
            render={( values ) => {
                return (
                    <input type='hidden' value={ values } />
                )
            }}          
            onChange={ ( e ) => handleChangeDate( e ) }
            disabledDays={{ before: new Date() }}
            // plugins={[ <DatePanel /> ]}
            className="planning-calendar"
            calendarPosition='top-left'
            onOpenPickNewDate={ false } 
            range={ type === 'Rango' }
            dateSeparator={' - '}
            format="DD/MM/YYYY"
            ref={ reference }
            style={{
                width: ( type === 'Fecha única' || type === 'Fechas múltiples' ) ? '5.5rem' : `${ 90 * value.length }px`,
                backgroundColor: 'transparent',
                boxSizing: 'border-box',
                position: 'relative',
                boxShadow: 'none',
                maxWidth: '100%',
                border: 'none',
            }}
            containerStyle={{
                wordBreak: 'break-word',
                width: '100%'
            }}
            mapDays={({ date, selectedDate, today, isSameDate }) => {
                
                let result = date.toDays() - today.toDays()
                if (result < 0) 
                    return {
                        disabled: true,
                        style: { 
                            color: "#ccc" 
                        },
                    } 
                else {
                    let props = {}
    
                    if (sameDate(selectedDate, date, isSameDate)) props.style = {
                            ...props.style,
                            backgroundColor: "#0B4762 !important",
                            border: "1px solid #777",
                            fontWeight: "bold",
                            color: "#FFF",
                        }
                        // console.log('props: ', props);
                        return props;
                    }
                }
            }
        />
    )
};

const mapStateToProps = (state) => {
    return {
        closingDatesForm: state.planningReducer.closingDatesForm,
        specialDatesForm: state.planningReducer.specialDatesForm,
        punctualDates: state.planningReducer.punctualDates,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setSingleDate: ({ newDate, dateArray, range }) => dispatch( setSingleDate({ newDate, dateArray, range }) ),
        setShowPlanningSnackbar: ( planningSnackbar ) => dispatch( setShowPlanningSnackbar( planningSnackbar ) ),
        setSpecialDatesForm: ( data ) => dispatch( setSpecialDatesForm( data ) ),
        setClosingDatesForm: ( data ) => dispatch( setClosingDatesForm( data ) ),
        setNewPunctualDate: ( data ) => dispatch( setNewPunctualDate( data ) ),
        setAllExcludeDates: ( data ) => dispatch( setAllExcludeDates( data ) ),
        setNewExcludeDate: ( data ) => dispatch( setNewExcludeDate( data ) ),
        setAllDates: ( data ) => dispatch( setAllDates( data ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( CalendarDateButton );