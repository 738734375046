import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "500",
    color: "#041C32",
    lineHeight: "27px",
    letterSpacing: "0",
    textAalign: "left",
  },
}));

const Subtitle = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.subtitle} {...restProps}>
      {text}
    </Typography>
  );
};

export default Subtitle;
