export const DB_TEST = false;
export const LOCAL_FUNCTIONS = false;
export const LOCAL_AUTH = false;

export const PHONE_NUMBER = "5492235991811"
export const URL_WEB = "https://woki.ar"
export const URL_WEB_PARTNER = "https://woki.ar/partners/index.html"

// Tipos de Environments que existen
export const PRODUCTION = "prod";
export const DEVELOPMENT = "dev";

//Environment actual
export const ENVIRONMENT = process.env.REACT_APP_ENVIROMENT || PRODUCTION;
