import React from 'react';

// Icon
import ExportButtonIcon from '../../../../SvgComponents/Icons Tables/MyBookings/ExportButtonIcon';

// Material Ui
import { Button } from '@mui/material';

const ButtonStyles = {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '.5rem .5rem .5rem .7rem',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '10px',
    color: '#2A2742',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: 'white !important',
        border: '1px solid #0B4762',
    },
    '@media(min-width: 1500px)': {
        padding: '.5rem 1rem',
    }
}

const ExportButton = ({ handleClick, text = 'Exportar', icon = <ExportButtonIcon/> }) => {
    return (
        <Button 
            sx={ ButtonStyles } variant="outlined" startIcon={ icon }
            onClick={ handleClick }
        >
            { text }
        </Button>
    )
}

export default ExportButton;