import React from 'react'

const CalendarIcon = () => {
    return (
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.76079 11.1494H6.88622V13.024H8.76079V11.1494ZM12.5099 11.1494H10.6354V13.024H12.5099V11.1494ZM16.2591 11.1494H14.3845V13.024H16.2591V11.1494ZM18.1336 4.58843H17.1963V2.71387H15.3218V4.58843H7.8235V2.71387H5.94894V4.58843H5.01165C3.97127 4.58843 3.14646 5.43199 3.14646 6.463L3.13708 19.585C3.13708 20.0821 3.33458 20.5589 3.68613 20.9105C4.03768 21.262 4.51449 21.4595 5.01165 21.4595H18.1336C19.1646 21.4595 20.0082 20.616 20.0082 19.585V6.463C20.0082 5.43199 19.1646 4.58843 18.1336 4.58843ZM18.1336 19.585H5.01165V9.27485H18.1336V19.585Z"
                fill="#0B4762"/>
        </svg>

    );
}

export default CalendarIcon
