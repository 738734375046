import React from 'react';

import { connect } from 'react-redux';

// Actions 
import { signOut } from '../../../actions';

// Icons
import LogoutIcon from '../../SvgComponents/icons/NewSidebar/LogoutIcon';

// Material Ui
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

// Styles
import { ItemsSingleItemsStyles } from '../Styles';

const LogoutDrawerItem = ( props ) => {

    // Actions
    const { signOut } = props;

    // States
    const { drawerOpen } = props;

    const onClickItem = () => {
        signOut();
    }

    return (
        <ListItem
            sx={{
                backgroundColor: 'white',
                paddingLeft: '.625rem',
                borderRadius: '.4rem',
                position: 'absolute',
                color: '#848282',
                height: '2.8rem',
                bottom: 0,
                '&.MuiButtonBase-root': {
                    width: drawerOpen ? '96%' : '100%',
                    ml: drawerOpen ? '.4rem' : 0,
                    justifyContent: 'center',
                    padding: '0 .5rem',
                    display: 'flex',
                }
            }}
            button
            onClick={ onClickItem }
        >
            <Box 
                sx={{ 
                    alignItems: 'center',
                    borderRadius: '8px',
                    display: 'flex', 
                }}
            >
                <ListItemIcon sx={{ minWidth: '0px' }} >
                    <LogoutIcon />
                </ListItemIcon>
            </Box>
            <ListItemText sx={{ display: drawerOpen ? 'block' : 'none' }} >
                <Typography variant='body1' sx={ ItemsSingleItemsStyles }>
                    Cerrar sesión
                </Typography>
            </ListItemText>
        </ListItem>
    )
};

const mapStateToProps = state => {
    return {
        drawerOpen: state.sidebarManager.drawerOpen,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch( signOut() ),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( LogoutDrawerItem );