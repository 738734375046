import {
    CHANGE_PRIMARY_TITLE_APP_BAR,
    CHANGE_SHOW_SIDEBAR,
    CHANGE_TITLE_APP_BAR,
    CLOSE_ERROR_DIALOG,
    ERROR_MAKE_ACTIVE,
    LOADING_SIDE_BAR,
    SUCCESS_MAKE_ACTIVE,
    TOGGLE_DRAWER
} from "../../constants";
import axios from "axios";

export const changeShowSidebar = (data) => ({
    type: CHANGE_SHOW_SIDEBAR,
    payload: data
});

export const toggleDrawer = (from) => ({
    type: TOGGLE_DRAWER,
    payload: from
})

export const changeTitleAppBar = (data) => ({
    type: CHANGE_TITLE_APP_BAR,
    payload: data
})

export const changePrimaryTitleAppBar = (data) => ({
    type: CHANGE_PRIMARY_TITLE_APP_BAR,
    payload: data
})

export const successMakeActive = () => ({
    type: SUCCESS_MAKE_ACTIVE,
});

export const errorMakeActive = (error) => ({
    type: ERROR_MAKE_ACTIVE,
    payload: error
})

export const loadingSideBar = () => ({
    type: LOADING_SIDE_BAR,
})

export const closeErrorDialog = () => ({
    type: CLOSE_ERROR_DIALOG,
})

export const makeActive = () => async (dispatch, getState, {getFirebase}) => {
    const {firebase, userAccountsReducer} = getState();
    const { idPartnerSelected } = userAccountsReducer.editReducer;
    const {profile} = firebase;

    dispatch(loadingSideBar());
    try {
        const API_URL = process.env.REACT_APP_API_URL;
        const auth = await getFirebase().auth();
        const token = await auth.currentUser.getIdToken(true);
        try {
            await axios.put(`${API_URL}/establishment/can-be-made-visible/${ idPartnerSelected }`, {}, {headers: {Authorization: `Bearer ${token}`}})
            dispatch(successMakeActive());
        } catch (e) {
            const res = e.response;
            if (res.status > 300) {
                console.log(res.statusText);
                console.log(res.data);
                dispatch(errorMakeActive(res.data));
            }
        }
    } catch (e) {
        dispatch(errorMakeActive(e.message));
    }
}
