import {arrayMove} from "react-sortable-hoc";
import {PARTNERS, RESET_ALL_DATA, TOGGLE_CATEGORIES_DIALOG, TOGGLE_CATEGORIES_LOADING,} from "../../constants";
import {deepCopy} from "../../utils/arrayHelpers";

const initState = {
  categories: [],
  categoriesDialog: false,
  loading: false,
  error: "",
};

const categoriesReducer = (state = initState, action) => {
  let newEditedCategories = deepCopy(state.categories);

  switch (action.type) {
    case "@@reduxFirestore/LISTENER_RESPONSE":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.ordered
      ) {
        return { ...state, categories: action.payload.ordered };
      }
      return { ...state };
    case "@@reduxFirestore/DOCUMENT_ADDED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.ordered
      ) {
        let newCategories = deepCopy(state.categories);
        let data = {
          id: action.meta.doc,
          name: action.payload.data["name"],
          order: action.payload.data["order"],
          visibility: action.payload.data["visibility"]
        };

        newCategories.push(data);
        return { ...state, categories: deepCopy(newCategories) };
      }
      return { ...state };
    case "@@reduxFirestore/DOCUMENT_MODIFIED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.data
      ) {
        let index = newEditedCategories.findIndex(
          (category) => category.id === action.meta.doc
        );

        let newIndex = action.payload.data.order;

        newEditedCategories = arrayMove(newEditedCategories, index, newIndex);

        newEditedCategories[newIndex].name = action.payload.data.name;
        newEditedCategories[newIndex].visibility = action.payload.data.visibility;


        return { ...state, categories: deepCopy(newEditedCategories) };
      }
      return { ...state };
    case "@@reduxFirestore/DOCUMENT_REMOVED":
      if (
        action.meta &&
        action.meta.collection &&
        action.meta.subcollections &&
        action.meta.collection === PARTNERS &&
        action.meta.subcollections[0].collection === "menu" &&
        action.payload.ordered
      ) {
        let newCategoriesWithoutDeleted = deepCopy(state.categories);

        newCategoriesWithoutDeleted = newCategoriesWithoutDeleted.filter(
          (category) => category["id"] !== action.meta.doc
        );

        return {
          ...state,
          categories: deepCopy(newCategoriesWithoutDeleted),
        };
      }
      return { ...state };
    case TOGGLE_CATEGORIES_DIALOG:
      return { ...state, categoriesDialog: !state.categoriesDialog };
    case TOGGLE_CATEGORIES_LOADING:
      return { ...state, loading: !state.loading };
    case RESET_ALL_DATA:
      return initState;
    default:
      return { ...state };
  }
};

export default categoriesReducer;
