export const BoxTableContainer = {
    maxWidth: '95vw',
    pb: '1.5rem', 
    pr: '3rem',
    '& ::-webkit-scrollbar': {
        display: 'block !important',
        borderRadius: '0 0 6px 6px',
        backgroundColor: '#F0F0F0',
        height: '.6rem',
    },
    '& ::-webkit-scrollbar-track': {
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        boxSizing: 'border-box',
    },
    '& ::-webkit-scrollbar-thumb': {
        border: '1px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        backgroundColor: '#0B4762',
        borderRadius: '10px',
    }
};

export const TableContainerStyles = {
    minWidth: '1700px',
    '& .MuiTableCell-root': { 
        border: '1px solid rgba(224, 224, 224, 1)' 
    },
};

export const CNContainerStyles = {
    borderRadius: '5px', 
    cursor: 'pointer',
    p: '.1rem .5rem', 
    width: '100%',
    '&:hover': { 
        backgroundColor: '#F6F6F6' 
    } 
};

export const CNIconsContainer = {
    alignItems: 'center',
    marginTop: '.2rem',
    display: 'flex',
    gap: '5px',
};

export const AmountPeopleContainer = { 
    justifyContent: 'center', 
    alignItems: 'center', 
    display: 'flex', 
    gap: '10px', 
};

export const FlagContainer = {
    fontFamily: `'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    fontSize: '1.5rem'
};

export const TextsStyles = {
    fontFamily: 'Poppins !important',
    fontWeight: '500 !important',
    fontSize: '.75rem' 
};

export const TableTextsStyles = {
    fontFamily: 'Poppins !important',
    fontSize: '.75rem', 
    maxWidth: '120px',
    fontWeight: 500,
};

export const PunctuationTextsStyles = {
    fontFamily: 'Poppins !important',
    fontWeight: '500 !important',
    marginBottom: '-.3rem', 
    fontSize: '.75rem',
};

export const OriginTextStyles = {
    fontFamily: 'Poppins', 
    fontSize: '.8rem',
    color: '#0B4762',
    fontWeight: 600, 
};

export const OriginSidebarTextStyles = {
    fontFamily: 'Poppins', 
    fontSize: '.8rem',
    color: '#818181',
    fontWeight: 600, 
};

export const ShiftTextStyles = {
    fontFamily: 'Poppins !important',
    fontWeight: '600 !important', 
};

export const ShiftSectorContainer = { 
    border: '1px solid #E7E7E7', 
    boxSizing: 'border-box', 
    borderRadius: '8px', 
    p: '.2rem .4rem', 
};

export const TextfieldContainerStyles = {
    margin: '1rem 0',
    '@media(min-width: 960px)': {
        margin: 0,
    },
};

export const TopTableContainer = {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px 8px 0 0', 
    backgroundColor: 'white',
    borderBottom: 'none',
};

export const SearchFieldContainerStyles = {
    width: 'max-content',
};

export const FiltersContainer = {
    justifyContent: 'space-between', 
    alignItems: 'center', 
    display: 'flex', 
    gap: '10px', 
    ml: '10px',
    '@media(min-width: 1500px)': {
        gap: '15px'
    }
};

export const SearchTextFieldTable = {
    '& .MuiInputBase-root': {
        borderRadius: '10px !important',
        fontFamily: 'Poppins',
        fontSize: '.75rem', 
        color: '#2A2742', 
        fontWeight: 500,
        height: '40px',
        width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '&:hover': {
            borderColor: '#0B4762',
        },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762 !important',
    },
    '@media(min-width: 1300px)': {
        '& .MuiInputBase-root': {
            width: '300px',
        },
    },
    '@media(min-width: 1500px)': {
        '& .MuiInputBase-root': {
            fontSize: '.8rem',
        },
    },
    '@media(min-width: 1700px)': {
        '& .MuiInputBase-root': {
            width: '450px',
        },
    }  
};

export const BadgeSelectStyles = {
    '& .MuiBadge-badge': { 
        backgroundColor: '#0B4762 !important', 
        fontFamily: 'Poppins', 
        color: '#FFFFFF',
    } 
};

export const BookingStatesSelectStyles = { 
    fontFamily: 'Poppins',
    borderRadius: '10px', 
    color: '#2A2742', 
    fontWeight: 500,
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#2A2742',
    },
    '@media(min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const TablePaginationStyles = {
    fontFamily: 'Poppins', 
    '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select': { 
        fontFamily: 'Poppins',
        fontWeight: 500 
    },
    '& .MuiNativeSelect-select': {
        borderRadius: '10px',
        '& .MuiTablePagination-menuItem': {
            '&:hover': {
                backgroundColor: 'red !important',
            }
        }
    },
    '& .MuiPaper-root': {
        width: '10rem'
    },
};

export const FiltersSelectStyles = {
    fontFamily: 'Poppins',
    borderRadius: '10px', 
    color: '#041C32', 
    fontWeight: 500,
    width: '200px',
    '& .MuiSelect-select': {
        padding: '10px 10px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        '& :hover': {
            borderColor: '#0B4762',
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0B4762',
        borderRadius: '10px',
        borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
        color: '#041C32',
    },
    '@media(min-width: 1500px)': {
        '& .MuiSelect-select': {
            padding: '10px 14px 8px 10px'
        },
    }
};

export const FilterModalStyles = {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    borderRadius: '18px',
    minHeight: '500px',
    bgcolor: 'white',
    border: 'none',
    boxShadow: 24,
    left: '50%',
    width: 600,
    top: '50%',
    p: 4,
    '&:focus-visible': {
        outline: 'none'
    }
};

export const TitleFilterModal = {
    fontFamily: 'Poppins',
    fontSize: '1.6rem',
    fontWeight: 500,
};

export const SubtitleFilterModal = {
    fontFamily: 'Poppins',
    fontSize: '.95rem',
    color: '#818181',
    fontWeight: 300,
    m: '1.5rem 0'
};

export const FilterItemContainerStyles = {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    display: 'flex',
    width: '100%',
};

export const FilterNameStyles = {
    fontFamily: 'Poppins',
    color: '#041C32',
    fontWeight: 500,
};

export const ButtonContainerFilterModal = {
    justifyContent: 'flex-end',
    padding: '2.5rem 0 0 0',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    gap: '10px',
    bottom: 0, 
};

export const CancelButtonFilterModal = {
    border: '1px solid #E7E7E7',
    backgroundColor: '#FFFFFF',
    padding: '.5rem 1rem',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    fontSize: '.9rem',
    fontWeight: 500,
    color: '#0B4762',
    '&:hover': {
        backgroundColor: '#FFFFFF !important',
        color: '#0B4762',
    }
};

export const AcceptButtonFilterModal = {
    border: '1px solid #E7E7E7',
    backgroundColor: '#0B4762',
    padding: '.5rem 1rem',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    fontSize: '.9rem',
    fontWeight: 500,
    color: 'white',
    '& :hover': {
        backgroundColor: '#0B4762',
        color: 'white',
    }
};

export const DateFilterContainer = { 
    justifyContent: 'flex-end', 
    paddingBottom: '1rem', 
    display: 'flex', 
    width: '100%', 
};

export const TablePhoneContainerStyles = {
    justifyContent: 'center',
    alignItems: 'center', 
    display: 'flex', 
    gap: '8px', 
};