import React, {useEffect} from 'react';
import {connect} from "react-redux";

// Actions
import {changePrimaryTitleAppBar, changeShowSidebar, changeTitleAppBar} from '../../actions/sidebarActions';

// Components
import Help from '../../components/Help';

const HelpPage = (props) => {

    useEffect(() => {
        props.changeTitleAppBar("Ayuda");
        props.changeShowSidebar(true);
    }, [props]);

    return (
        <Help/>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        changeTitleAppBar: (data) => dispatch(changeTitleAppBar(data)),
        changePrimaryTitleAppBar: (data) => dispatch(changePrimaryTitleAppBar()),
        changeShowSidebar: (data) => dispatch(changeShowSidebar(data)),
    }
}

export default connect(null, mapDispatchToProps)(HelpPage);
