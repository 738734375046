import React from 'react';

const CuentaDNI = (props) => {
    return (
        <svg width="127" height="40" viewBox="0 0 127 57" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M25.443 45.2531H24.6776C18.9568 45.2531 13.9085 41.8139 11.8166 36.4879C11.5439 35.7969 11.8848 35.0132 12.579 34.7437C13.2701 34.4709 14.0541 34.8118 14.3237 35.5057C16.0065 39.7906 20.0724 42.5605 24.6776 42.5605H25.443C26.1869 42.5605 26.7912 43.1648 26.7912 43.9082C26.7912 44.6519 26.1869 45.2531 25.443 45.2531Z" fill={props.fill}/>
            <path d="M43.6964 21.4922C42.9528 21.4922 42.3485 20.888 42.3485 20.1444V14.0191C42.3485 13.7774 42.1408 13.5822 41.8837 13.5822H35.7104C34.9665 13.5822 34.3622 12.9781 34.3622 12.2345C34.3622 11.4909 34.9665 10.8867 35.7104 10.8867H41.8837C43.6252 10.8867 45.0415 12.2902 45.0415 14.016V20.1413C45.0447 20.8911 44.4403 21.4922 43.6964 21.4922Z" fill={props.fill}/>
            <path d="M12.2131 21.491C11.4693 21.491 10.865 20.8869 10.865 20.1433V14.0179C10.865 12.2922 12.2813 10.8887 14.023 10.8887H20.1962C20.94 10.8887 21.5443 11.4928 21.5443 12.2364C21.5443 12.98 20.94 13.5842 20.1962 13.5842H14.023C13.7657 13.5842 13.5581 13.7794 13.5581 14.021V20.1464C13.5612 20.8899 12.9569 21.491 12.2131 21.491Z" fill={props.fill}/>
            <path d="M41.8837 45.2532H35.7104C34.9665 45.2532 34.3622 44.6488 34.3622 43.9054C34.3622 43.1618 34.9665 42.5577 35.7104 42.5577H41.8837C42.1408 42.5577 42.3485 42.3623 42.3485 42.1207V35.9954C42.3485 35.2518 42.9528 34.6477 43.6964 34.6477C44.4403 34.6477 45.0447 35.2518 45.0447 35.9954V42.1207C45.0447 43.8496 43.6252 45.2532 41.8837 45.2532Z" fill={props.fill}/>
            <path d="M52.5934 18.8741C52.5934 16.5008 52.7081 15.7014 53.0273 14.6759C53.8487 12.1446 55.8568 10.865 58.5716 10.865C60.8989 10.865 62.6095 11.937 63.3875 13.3281C63.5703 13.6473 63.6849 13.9664 63.6849 14.332C63.6849 15.2212 63.0002 15.8377 62.1571 15.8377C61.587 15.8377 61.1314 15.6085 60.6727 14.9702C60.1026 14.1956 59.5541 13.8301 58.5716 13.8301C57.3166 13.8301 56.585 14.4931 56.2441 15.5403C56.0613 16.1104 55.9932 16.6588 55.9932 18.871C55.9932 21.0832 56.0613 21.6315 56.2441 22.2016C56.585 23.252 57.3166 23.9119 58.5716 23.9119C59.5541 23.9119 60.0995 23.5463 60.6727 22.7717C61.1283 22.1335 61.587 21.9042 62.1571 21.9042C63.0002 21.9042 63.6849 22.5208 63.6849 23.41C63.6849 23.7756 63.5703 24.0947 63.3875 24.4139C62.6127 25.8049 60.8989 26.8769 58.5716 26.8769C55.8568 26.8769 53.8487 25.6004 53.0273 23.066C52.7081 22.0436 52.5934 21.2474 52.5934 18.8741Z" fill={props.fill}/>
            <path d="M71.6963 25.6036H71.6746C71.126 26.3098 70.1685 26.8801 68.7521 26.8801C66.3102 26.8801 65.0551 25.1697 65.0551 22.7717V17.139C65.0551 16.0887 65.7399 15.4504 66.676 15.4504C67.5901 15.4504 68.2502 16.0887 68.2502 17.139V22.1582C68.2502 23.3666 68.7987 24.166 69.9391 24.166C71.0579 24.166 71.6282 23.3666 71.6282 22.1582V17.139C71.6282 16.0887 72.2915 15.4504 73.2242 15.4504C74.1383 15.4504 74.8201 16.0887 74.8201 17.139V25.0799C74.8201 26.1302 74.16 26.7686 73.2242 26.7686C72.4711 26.7686 71.8791 26.3347 71.6963 25.6036Z" fill={props.fill}/>
            <path d="M76.602 23.7323C76.3726 23.0476 76.2362 22.2482 76.2362 21.1081C76.2362 19.9431 76.3509 19.1468 76.5771 18.4621C77.2404 16.4544 78.9976 15.3359 81.3466 15.3359C83.7886 15.3359 85.4778 16.4761 86.1161 18.4404C86.367 19.215 86.4817 19.9927 86.4817 21.0182C86.4817 21.6348 86.0944 22.0685 85.4312 22.0685H79.5648C79.4747 22.0685 79.4284 22.115 79.4284 22.2049C79.4284 22.4341 79.4747 22.6386 79.543 22.8431C79.8405 23.7788 80.6834 24.2807 81.824 24.2807C82.577 24.2807 83.3084 24.0297 83.9002 23.7106C84.2877 23.4813 84.6749 23.3698 84.9941 23.3698C85.6574 23.3698 86.1593 23.8717 86.1593 24.5565C86.1593 25.0367 85.9302 25.424 85.521 25.6966C84.4704 26.4279 83.24 26.8833 81.5265 26.8833C78.9759 26.8801 77.2404 25.7183 76.602 23.7323ZM83.1968 19.9462C83.2866 19.9462 83.3332 19.8997 83.3332 19.8099C83.3332 19.4443 83.2866 19.215 83.2186 18.9888C82.9674 18.2143 82.2825 17.7805 81.3715 17.7805C80.4571 17.7805 79.797 18.2143 79.5462 18.9888C79.4778 19.2181 79.4315 19.4443 79.4315 19.8099C79.4315 19.8997 79.4778 19.9462 79.5679 19.9462H83.1968Z" fill={props.fill}/>
            <path d="M94.5397 25.0798V20.0607C94.5397 18.8523 93.9447 18.053 92.8041 18.053C91.6638 18.053 91.0935 18.8523 91.0935 20.0607V25.0798C91.0935 26.1301 90.4085 26.7685 89.4976 26.7685C88.5615 26.7685 87.9014 26.1301 87.9014 25.0798V17.139C87.9014 16.0887 88.5646 15.4504 89.4976 15.4504C90.2506 15.4504 90.8424 15.8842 91.0254 16.6154H91.0469C91.5955 15.9089 92.553 15.3389 93.9694 15.3389C96.4116 15.3389 97.7347 17.0491 97.7347 19.4441V25.0798C97.7347 26.1301 97.0714 26.7685 96.1387 26.7685C95.2246 26.7685 94.5397 26.1272 94.5397 25.0798Z" fill={props.fill}/>
            <path d="M103.368 26.7686C100.765 26.7686 99.8324 25.5819 99.8324 23.0724V18.0997C99.8324 18.0098 99.764 17.9633 99.6711 17.9633H99.5813C98.7601 17.9633 98.3045 17.4831 98.3045 16.7767C98.3045 16.0703 98.7601 15.59 99.5813 15.59H99.6711C99.7611 15.59 99.8324 15.5436 99.8324 15.4537V13.8116C99.8324 12.7613 100.496 12.123 101.431 12.123C102.346 12.123 103.006 12.7613 103.006 13.8116V15.4537C103.006 15.5436 103.052 15.59 103.142 15.59H104.01C104.831 15.59 105.287 16.0703 105.287 16.7767C105.287 17.4831 104.831 17.9633 104.01 17.9633H103.142C103.052 17.9633 103.006 18.0098 103.006 18.0997V22.9826C103.006 23.6456 103.257 23.9182 103.849 23.9864C104.853 24.101 105.265 24.7175 105.265 25.4023C105.265 26.1768 104.716 26.7717 103.508 26.7717H103.368V26.7686Z" fill={props.fill}/>
            <path d="M116.285 25.2846C116.285 26.1272 115.579 26.7685 114.689 26.7685C114.141 26.7685 113.685 26.564 113.388 26.1521L113.115 25.7648H113.093C112.523 26.471 111.336 26.8832 109.966 26.8832C107.137 26.8832 105.767 25.6067 105.767 23.506C105.767 21.0646 107.592 19.9461 110.332 19.9461H112.318C112.411 19.9461 112.455 19.8996 112.455 19.8098V19.6951C112.455 18.6696 111.724 18.0531 110.196 18.0531C109.511 18.0531 108.826 18.2142 108.417 18.3722C108.144 18.462 107.893 18.5333 107.664 18.5333C106.911 18.5333 106.387 18.0314 106.387 17.2568C106.387 16.7766 106.616 16.2746 107.208 15.9803C107.94 15.6147 109.055 15.342 110.586 15.342C113.918 15.342 115.517 17.0306 115.517 19.7912V22.8027C115.517 23.3263 115.563 23.5773 115.721 23.853L116.019 24.355C116.196 24.6462 116.285 24.9654 116.285 25.2846ZM112.452 22.9576V22.2047C112.452 22.1149 112.405 22.0684 112.316 22.0684H110.763C109.415 22.0684 108.801 22.4805 108.801 23.3232C108.801 24.0978 109.35 24.4883 110.397 24.4883C111.745 24.4851 112.452 23.9832 112.452 22.9576Z" fill={props.fill}/>
            <path d="M52.5471 31.0784C52.5471 30.1892 53.0957 29.5945 54.0069 29.5945H57.6821C60.9921 29.5945 63.0437 30.6448 63.868 33.1761C64.1654 34.0653 64.3236 35.1373 64.3236 37.3526C64.3236 39.5647 64.1623 40.6367 63.868 41.5291C63.0466 44.0604 60.9921 45.1107 57.6821 45.1107H54.0069C53.0925 45.1107 52.5471 44.5406 52.5471 43.6514V31.0784ZM56.0398 42.1209H57.5923C59.1451 42.1209 60.2637 41.5972 60.6512 40.3641C60.8122 39.8404 60.9238 38.9266 60.9238 37.3526C60.9238 35.7786 60.8091 34.8645 60.6512 34.341C60.2637 33.1077 59.1451 32.5843 57.5923 32.5843H56.0398C55.9498 32.5843 55.9034 32.6307 55.9034 32.7205V41.9845C55.9003 42.0743 55.9469 42.1209 56.0398 42.1209Z" fill={props.fill}/>
            <path d="M65.9661 31.4656C65.9661 30.2326 66.6977 29.458 67.838 29.458C68.7956 29.458 69.434 30.0281 70.0507 31.1001L74.7984 39.5646H74.8885V31.0783C74.8885 30.0529 75.5051 29.458 76.4161 29.458C77.3273 29.458 77.944 30.0498 77.944 31.0783V43.2392C77.944 44.4723 77.2126 45.2468 76.0723 45.2468C75.1147 45.2468 74.4761 44.6768 73.8594 43.6047L69.1117 35.1619H69.0218V43.6265C69.0218 44.6519 68.4052 45.2468 67.4908 45.2468C66.5799 45.2468 65.9632 44.655 65.9632 43.6265V31.4656H65.9661Z" fill={props.fill}/>
            <path d="M80.3055 31.2362C80.3055 30.1642 81.0123 29.458 81.9947 29.458C82.9523 29.458 83.6619 30.1642 83.6619 31.2362V43.4652C83.6619 44.5372 82.9554 45.2437 81.9947 45.2437C81.0123 45.2437 80.3055 44.5372 80.3055 43.4652V31.2362Z" fill={props.fill}/>
            <path d="M17.0606 23.8562C16.822 23.8562 16.5772 23.7911 16.3571 23.6579C15.7218 23.2706 15.5235 22.4403 15.914 21.8052C17.0668 19.9214 19.075 18.7998 21.2815 18.7998C22.0253 18.7998 22.6296 19.404 22.6296 20.1476C22.6296 20.8912 22.0253 21.4953 21.2815 21.4953C20.0202 21.4953 18.8705 22.1398 18.2104 23.218C17.9563 23.6269 17.5131 23.8562 17.0606 23.8562Z" fill={props.fill}/>
            <path d="M19.2205 25.1731C18.9602 25.1731 18.7526 24.9409 18.7991 24.6866C19.0284 23.4474 20.113 22.5117 21.4177 22.5117C22.7224 22.5117 23.8071 23.4474 24.0364 24.6866C24.0829 24.9409 23.8753 25.1731 23.615 25.1731H19.2205Z" fill={props.fill}/>
            <path d="M33.6219 25.1731C33.3616 25.1731 33.154 24.9409 33.2004 24.6866C33.4297 23.4474 34.5143 22.5117 35.8191 22.5117C37.1238 22.5117 38.2084 23.4474 38.4378 24.6866C38.4841 24.9409 38.2767 25.1731 38.0162 25.1731H33.6219Z" fill={props.fill}/>
            <path d="M28.9449 37.5878H25.6847C24.9408 37.5878 24.3367 36.9837 24.3367 36.2401C24.3367 35.4964 24.9408 34.8923 25.6847 34.8923H28.9449C29.1897 34.8923 29.3911 34.6938 29.3911 34.4461V25.0863C29.3911 21.6192 32.2143 18.7966 35.6822 18.7966C36.4258 18.7966 37.0302 19.4008 37.0302 20.1444C37.0302 20.888 36.4258 21.4921 35.6822 21.4921C33.6987 21.4921 32.0842 23.1064 32.0842 25.0892V34.4493C32.0842 36.1811 30.6741 37.5878 28.9449 37.5878Z" fill={props.fill}/>
        </svg>
    )
}

export default CuentaDNI;