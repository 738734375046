import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: '150px',
    height: '30px',
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    paddingTop: '5px',
    cursor: 'pointer'
  },
  text: {
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: '900',
    color: '#0076FF'
  }
}));

const ChangesPrice = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root} onClick={props.onClick}>
            <p className={classes.text}>Cambiar precios</p>
        </div>
    )
}

export default ChangesPrice
