import React from 'react';

const VisaDebito = (props) => {
    return (
        <svg width="98" height="30" viewBox="0 0 98 30" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M70.9387 1.39943C68.6919 0.58071 66.3169 0.169873 63.9256 0.186263C56.1922 0.186263 50.7306 4.08193 50.6919 9.67409C50.6436 13.7824 54.5731 16.0976 57.5456 17.4703C60.5954 18.8816 61.6152 19.7709 61.6056 21.0324C61.5862 22.9513 59.1696 23.8213 56.9221 23.8213C53.8336 23.8213 52.1467 23.3911 49.5512 22.3133L48.5894 21.8638L47.4922 28.3018C49.3724 29.0848 52.7606 29.7566 56.2599 29.8146C64.4814 29.8146 69.8561 25.9431 69.9189 19.9933C69.9866 16.7114 67.8599 14.2319 63.3842 12.1826C60.6631 10.8534 58.9762 9.96409 58.9762 8.61559C58.9762 7.42176 60.4214 6.14576 63.4422 6.14576C65.4527 6.09712 67.4507 6.47432 69.3051 7.2526L70.0301 7.57643L71.1272 1.35593L70.9387 1.39943ZM91.0067 0.708262H84.9651C83.0849 0.708262 81.6687 1.22543 80.8471 3.0911L69.2277 29.4134H77.4492L79.0926 25.0973L89.1266 25.1069C89.3634 26.1171 90.0884 29.4134 90.0884 29.4134H97.3384L91.0067 0.708262ZM39.5704 0.466594H47.4052L42.5042 29.1863H34.6791L39.5704 0.456928V0.466594ZM19.6812 16.2958L20.4932 20.2833L28.1492 0.708262H36.4481L24.1182 29.3748H15.8387L9.07206 5.10176C8.96247 4.70152 8.70509 4.35778 8.35189 4.13993C5.913 2.88006 3.32883 1.92398 0.657227 1.29309L0.76356 0.688925H13.3737C15.0847 0.756592 16.4622 1.29309 16.9214 3.12009L19.6764 16.3103V16.2958H19.6812ZM81.3401 19.2248L84.4624 11.1918C84.4237 11.2788 85.1052 9.53393 85.5016 8.45609L86.0381 10.9356L87.8506 19.2199H81.3352V19.2248H81.3401Z" fill={props.fill}/>
        </svg>
    )
}

export default VisaDebito;