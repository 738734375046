import React from "react";

const SectorsV2Icon = ({ fill }) => {
  return (
    <svg
      width="1rem"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6667 1H3.20833C1.9887 1 1 1.9887 1 3.20833V18.6667C1 19.8863 1.9887 20.875 3.20833 20.875H18.6667C19.8863 20.875 20.875 19.8863 20.875 18.6667V3.20833C20.875 1.9887 19.8863 1 18.6667 1Z"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.62549H20.875"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62473 20.8755V7.62549"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SectorsV2Icon.defaultProps = {
  fill: "#848282",
};

export default SectorsV2Icon;
