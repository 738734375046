import React from 'react';

const BitcoinIcon = (props) => {
    return (
        <svg width="59" height="40" viewBox="0 0 59 59" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_296_350)">
                <path d="M27.9881 37.5511C30.7193 37.5412 36.6906 37.5191 36.6218 33.721C36.5554 29.8368 30.8201 30.0704 28.025 30.1859C27.7128 30.1982 27.435 30.2105 27.2088 30.213L27.3367 37.556C27.5211 37.5511 27.7423 37.5511 27.9881 37.5511ZM27.6981 26.85C29.9769 26.8475 34.9501 26.8426 34.8911 23.3886C34.8272 19.856 30.0507 20.065 27.7177 20.1682C27.4547 20.1805 27.2261 20.1903 27.0343 20.1928L27.1498 26.8524L27.6981 26.85Z" fill={props.fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.361 58.1175C38.1681 62.0582 54.1768 52.4387 58.1175 36.6365C62.0582 20.8319 52.4363 4.82325 36.6292 0.885005C20.8319 -3.05816 4.82325 6.56376 0.885004 22.3708C-3.0557 38.173 6.56375 54.1817 22.3635 58.1199L22.361 58.1175ZM32.8876 16.2766C37.1995 16.5815 40.6313 17.845 41.0812 21.7882C41.4155 24.6743 40.2355 26.432 38.3205 27.4498C41.5163 28.1578 43.5445 30.0138 43.2249 34.2618C42.8291 39.5349 38.9277 41.0173 33.3276 41.4352L33.421 46.9665L30.0875 47.0279L29.9917 41.5704C29.1288 41.5852 28.2438 41.595 27.3268 41.5901L27.4252 47.0722L24.0917 47.1312L23.9933 41.5901L23.0518 41.5975C22.5749 41.5999 22.093 41.6024 21.6112 41.6122L17.2698 41.686L17.8623 37.6936C17.8623 37.6936 20.3304 37.6887 20.2862 37.6518C21.2302 37.6322 21.4686 36.9512 21.5203 36.5235L21.3703 27.7792L21.6088 27.7743H21.7218C21.6043 27.7608 21.4861 27.755 21.3678 27.757L21.2597 21.5129C21.1195 20.8393 20.6672 20.0625 19.3176 20.087C19.3545 20.0379 16.8937 20.1288 16.8937 20.1288L16.8298 16.5692L21.4318 16.4905V16.5077C22.1225 16.4954 22.833 16.4708 23.5533 16.4438L23.4599 10.9642L26.7934 10.9076L26.8868 16.2766C27.7767 16.2447 28.6715 16.2103 29.5492 16.1955L29.4558 10.8609L32.7917 10.8019L32.8876 16.2815V16.2766Z" fill={props.fill}/>
            </g>
            <defs>
                <clipPath id="clip0_296_350">
                    <rect width="59" height="59" fill={props.fill}/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default BitcoinIcon;