import React, {useState} from 'react'
import WokiWeb from "../NewComponents/Buttons/Home/WokiWeb";
import WhatsApp from "../NewComponents/Buttons/Home/WhatsApp";
import WokiAssistantDisabled from "../NewComponents/Buttons/Home/WokiAssistantDisabled";
import WokiAssistant from "../Home/WokiAssistant/WokiAssistant";

const WokiHelp = (props) => {

    const [showButtons, setShowButtons] = useState(false)

    return (
        <div style={{position: 'fixed', bottom: '20px', right: '15px', zIndex: 3}}>
            {showButtons ?
                <WokiWeb/>
                :
                null
            }
            {showButtons ?
                <WhatsApp/>
                :
                null
            }
            {showButtons
                ? <div onClick={() => setShowButtons(!showButtons)}>
                    <WokiAssistantDisabled/>
                </div>
                : <div onClick={() => setShowButtons(!showButtons)}>
                    <WokiAssistant/>
                </div>
            }

        </div>
    )
}

export default WokiHelp