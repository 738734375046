import React from 'react';

// Material Ui
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core';

// Icons
import DinnersClubIcons from '../../SvgComponents/icons/PaymentsOptions/DinnersClubIcons';
import VisaCreditoIcon from '../../SvgComponents/icons/PaymentsOptions/VisaCreditoIcon';
import MercadopagoIcon from '../../SvgComponents/icons/PaymentsOptions/MercadopagoIcon';
import Americanexpress from '../../SvgComponents/icons/PaymentsOptions/Americanexpress';
import VisaDebitoIcon from '../../SvgComponents/icons/PaymentsOptions/VisaDebitoIcon';
import MastercardIcon from '../../SvgComponents/icons/PaymentsOptions/MastercardIcon';
import CordobesaIcon from '../../SvgComponents/icons/PaymentsOptions/CordobesaIcon';
import ArgencardIcon from '../../SvgComponents/icons/PaymentsOptions/ArgencardIcon';
import FalabellaIcon from '../../SvgComponents/icons/PaymentsOptions/FalabellaIcon';
import CencosudIcon from '../../SvgComponents/icons/PaymentsOptions/CencosudIcon';
import DiscoverIcon from '../../SvgComponents/icons/PaymentsOptions/DiscoverIcon';
import MaestroIcon from '../../SvgComponents/icons/PaymentsOptions/MaestroIcon';
import NaranjaIcon from '../../SvgComponents/icons/PaymentsOptions/NaranjaIcon';
import CuentaDNI from '../../SvgComponents/icons/PaymentsOptions/CuentaDNIIcon';
import BitcoinIcon from '../../SvgComponents/icons/PaymentsOptions/BitcoinIcon';
import NativaIcon from '../../SvgComponents/icons/PaymentsOptions/NativaIcon';
import ModoIcons from '../../SvgComponents/icons/PaymentsOptions/ModoIcons';
import CabalIcon from '../../SvgComponents/icons/PaymentsOptions/CabalIcon';
import JcbIcon from '../../SvgComponents/icons/PaymentsOptions/JcbIcon';
import PaymentIcon from '../../GeneralInfo/PaymentMethod/PaymentIcon';
import ApplePayIcon from '../../SvgComponents/icons/PaymentsOptions/ApplePayIcon';

const useStyles = makeStyles(() => ({
    GeneralContainerCard: {
        display: 'flex',
        height: 'auto',
        width: '100%',
        gap: '10px'
    }
}));

const CardsGeneralInfo = (props) => {

    const classes = useStyles();
    let icons = [
        {name: 'amex', payment: <Americanexpress fill={'#0B4762'}/>},
        {name: 'argencard', payment: <ArgencardIcon fill={'#0B4762'}/>},
        {name: 'bitcoin', payment: <BitcoinIcon fill={'#0B4762'}/>},
        {name: 'cabal', payment: <CabalIcon fill={'#0B4762'}/>},
        {name: 'cencosud', payment: <CencosudIcon fill={'#0B4762'}/>},
        {name: 'cmr', payment: <FalabellaIcon fill={'#0B4762'}/>},
        {name: 'cordobesa', payment: <CordobesaIcon fill={'#0B4762'}/>},
        {name: 'cuentadni', payment: <CuentaDNI fill={'#0B4762'}/>},
        {name: 'diners', payment: <DinnersClubIcons fill={'#0B4762'}/>},
        {name: 'discover', payment: <DiscoverIcon fill={'#0B4762'}/>},
        {name: 'jcb', payment: <JcbIcon fill={'#0B4762'}/>},
        {name: 'maestro', payment: <MaestroIcon fill={'#0B4762'}/>},
        {name: 'mastercard', payment: <MastercardIcon fill={'#0B4762'}/>},
        {name: 'mercadopago', payment: <MercadopagoIcon fill={'#0B4762'}/>},
        {name: 'modo', payment: <ModoIcons fill={'#0B4762'}/>},
        {name: 'naranja', payment: <NaranjaIcon fill={'#0B4762'}/>},
        {name: 'nativa', payment: <NativaIcon fill={'#0B4762'}/>},
        {name: 'visa', payment: <VisaCreditoIcon fill={'#0B4762'}/>},
        {name: 'visadebito', payment: <VisaDebitoIcon fill={'#0B4762'}/>},
        {name: 'applepay', payment: <ApplePayIcon fill={'#0B4762'}/>},
    ];
    // const [activePayment, setActivePayment] = useState([]);

    const {paymentMethods, onClick} = props;

    // const handleActivePayment = (index) => {
    //     let selectedPayment = icons[index];

    //     if (activePayment.includes(selectedPayment.name)) {
    //         setActivePayment([...activePayment.filter((p) => p !== selectedPayment.name)]);

    //     } else {
    //         setActivePayment([...activePayment, selectedPayment.name]);
    //     }
    // }

    return (
        <Grid container className={classes.GeneralContainerCard}>
            { icons.map((icon, index) =>
                <PaymentIcon
                    index={index}
                    isActive={paymentMethods.includes(icon.name)}
                    onClick={() => onClick(icon.name)}
                >
                    {icon.payment}
                </PaymentIcon>
            )}
        </Grid>
    )
}

export default CardsGeneralInfo;
